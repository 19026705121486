import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation
} from "@angular/core";
import {FormControl} from "@angular/forms";
import {PaginationService} from "../../services/pagination.service";
import {distinctUntilChanged} from "rxjs";
import {takeUntil} from "rxjs/operators";

export interface IPagination<T> {
    current_page: number;
    from: number;
    last_page: number;
    per_page: number;
    to: number;
    total: number;
    next_page_url?: string;
    prev_page_url?: string;
    data: T[];
}

export interface IPaginationEvent {
    page: number;
    per_page: number;
}

@Component({
    selector: "common-pagination",
    template: `
        <div class="total" *ngIf="showTotal">
            Total: {{data.total}}
        </div>
        <div class="per-page" *ngIf="!hidePerPage && (data.total > 0 || simple)">
            Show
            <mat-select [formControl]="perPage">
                <mat-option [value]="3">3</mat-option>
                <mat-option [value]="5">5</mat-option>
                <mat-option [value]="10">10</mat-option>
                <mat-option [value]="25">25</mat-option>
                <mat-option [value]="50">50</mat-option>
                <mat-option [value]="100000">All</mat-option>
            </mat-select>
            entries
        </div>
        <ng-template [ngIf]="totalPages > 1">
            <div>
                <mat-icon (click)="goPrevPage()" *ngIf="data.current_page > 1">keyboard_arrow_left</mat-icon>

                <a (click)="goTo(1)" *ngIf="data.current_page > 1" [class.active]="data.current_page === 1">1</a>

                <span *ngIf="data.current_page > 4">...</span>

                <ng-template ngFor let-page [ngForOf]="pagesToShow">
                    <a (click)="goTo(page)" [class.active]="data.current_page === page">
                        {{page}}
                    </a>
                </ng-template>

                <span *ngIf="data.current_page <= totalPages - 4">...</span>

                <a (click)="goTo(totalPages)" *ngIf="data.current_page <= totalPages - 1"
                   [class.active]="data.current_page === totalPages">{{totalPages}}</a>

                <mat-icon (click)="goNextPage()" *ngIf="data.current_page < totalPages">keyboard_arrow_right</mat-icon>
            </div>
        </ng-template>

        <ng-template [ngIf]="simple">
            <a (click)="goPrevPage()" *ngIf="data.prev_page_url">
                <mat-icon class="vertical-middle">
                    keyboard_arrow_left
                </mat-icon>
                Prev
            </a>
            &nbsp;
            &nbsp;
            <a (click)="goNextPage()" *ngIf="data.next_page_url">
                Next
                <mat-icon class="vertical-middle">
                    keyboard_arrow_right
                </mat-icon>
            </a>
        </ng-template>
    `,
    styleUrls: [
        "pagination.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class PaginationComponent implements OnInit, OnChanges, OnDestroy {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    public totalPages: number = 1;

    public pagesToShow: number[] = [];

    public perPage: FormControl = new FormControl(10);

    @Input()
    public pid: string;

    @Input()
    public data: IPagination<any>;

    @Input()
    public hidePerPage: boolean = false;

    @Input()
    public simple: boolean = false;

    @Input()
    public showTotal: boolean = false;

    @Input()
    public disableUrlPagination: boolean = false;

    @Output()
    public goToPage: EventEmitter<IPaginationEvent> = new EventEmitter();

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private paginationService: PaginationService
    ) {
    }

    private init(): void {
        if (this.simple) {
            this.showTotal = false;
        }

        if (this.data) {
            this.perPage.setValue(this.data.per_page, {emitEvent: false});
            if (!this.simple) {
                this.totalPages = Math.ceil(this.data.total / this.data.per_page);
                this.setVisiblePages(this.data.current_page);
            }
            this.paginationService.setPageById(this.pid, this.data.current_page);
            this.paginationService.setPerPageById(this.pid, this.data.per_page);
        }
    }

    private setVisiblePages(current: number): void {
        this.pagesToShow = [];

        if (current > 3) {
            this.pagesToShow.push(current - 2);
        }

        if (current > 2) {
            this.pagesToShow.push(current - 1);
        }

        this.pagesToShow.push(current);

        if (this.totalPages === 2) {
            return;
        }

        if (current < this.totalPages - 1) {
            this.pagesToShow.push(current + 1);
        }

        if (current < this.totalPages - 2) {
            this.pagesToShow.push(current + 2);
        }

        this.changeDetectorRef.markForCheck();
    }

    public goTo(page: number): void {
        this.goToPage.emit({page, per_page: this.perPage.value});
        this.changeDetectorRef.markForCheck();
    }

    public goNextPage(): void {
        if (this.data.current_page < this.totalPages || this.data.next_page_url) {
            this.goTo(this.data.current_page + 1);
        }
    }

    public goPrevPage(): void {
        if (this.data.current_page > 1) {
            this.goTo(this.data.current_page - 1);
        }
    }

    public ngOnInit(): void {
        this.init();


        this.perPage.valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe((value: number): void => {
                this.setVisiblePages(1);
                this.goToPage.emit({page: 1, per_page: value});
            });

        setTimeout(() => {
            if (this.data && this.data.current_page > this.totalPages) {
                this.goTo(this.totalPages);
            }
        }, 1000);

    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.data) {
            this.data = changes.data.currentValue;
            this.init();
        }
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
