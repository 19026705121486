<common-confirm></common-confirm>

<h1 class="container-heading">Milestones</h1>
<mat-card>
    <mat-card-content>
        <common-table2 *ngIf="listTable" [settings]="listTable">
            <div row1>
                <mat-checkbox [formControl]="showAll" class="show-all-checkbox">Show all</mat-checkbox>

                <button mat-raised-button type="button" color="accent" (click)="reasons()">
                    Reasons
                </button>
            </div>
        </common-table2>
    </mat-card-content>
</mat-card>
