import {Component, ChangeDetectionStrategy} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";

@Component({
    selector: "section-service-request-list",
    templateUrl: "list.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceRequestListComponent implements Base.IComponent {

    public readonly state: Base.IState;

    public ngConfig(): Base.IConfig {
        return {
            name: "service-request",
            actions: {
                "browse": ["read_orders", "browse_service_requests"]
            }
        };
    }

}
