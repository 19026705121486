<common-confirm></common-confirm>


<h1 class="container-heading">Users List</h1>

<mat-card>
    <div>

        <common-table2 *ngIf="listTable" [settings]="listTable">

            <div row1 [formGroup]="filters" class="flex row align-center">
                <common-form-select
                        class="form-select margin-right-10"
                        [options]="roles"
                        [multiple]="true"
                        [selectAll]="true"
                        [search]="true"
                        (onClose)="filters.get('role_id').setValue($event)"
                        label="Role">
                </common-form-select>

                <button type="button" *userAllowed="'add_users'"
                        (click)="addUser()" mat-raised-button color="primary"
                        class="create">
                    Add
                </button>
            </div>
        </common-table2>

    </div>
</mat-card>
