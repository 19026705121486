<h1 class="container-heading">{{state.action === "add" ? "Add" : "Edit"}} partner</h1>

<mat-card>
    <mat-card-content>

        <mat-horizontal-stepper #stepper [linear]="true" (selectionChange)="handleStepChanges($event)">

            <ng-template matStepperIcon="edit">
                <mat-icon>done</mat-icon>
            </ng-template>

            <ng-template matStepperIcon="done">
                <mat-icon>done_all</mat-icon>
            </ng-template>

            <ng-template matStepperIcon="number" let-active="active" let-index="index">
                <mat-icon *ngIf="active; else elseIcon">create</mat-icon>
                <ng-template #elseIcon>{{index + 1}}</ng-template>
            </ng-template>

            <mat-step [editable]="step1.control.valid" [stepControl]="step1.control">

                <ng-template matStepLabel>Add Partner</ng-template>

                <div class="step">
                    <section-partner-form-step1 (result)="step1.control.setValue($event)"></section-partner-form-step1>
                    <div class="actions">
                        <button *ngIf="step1.control.valid"
                                matStepperNext
                                mat-raised-button
                                color="primary"
                                type="button"
                                class="main">
                            Next
                        </button>
                    </div>
                </div>

            </mat-step>


            <mat-step [editable]="step2.control.valid" [stepControl]="step2.control">

                <ng-template matStepLabel>Coverage</ng-template>

                <div class="step">
                    <section-partner-form-step2
                            (result)="step2.control.setValue($event)"
                            (authFail)="onAuthFail()"></section-partner-form-step2>
                    <div class="actions">
                        <button matStepperPrevious mat-raised-button type="button">Previous</button>
                        &nbsp;
                        <button *ngIf="step2.control.valid"
                                matStepperNext
                                mat-raised-button
                                color="primary"
                                type="button"
                                class="main">
                            Next
                        </button>
                    </div>
                </div>
            </mat-step>

            <mat-step [editable]="step3.control.valid" [stepControl]="step3.control">

                <ng-template matStepLabel>Service level</ng-template>

                <div class="step">
                    <section-partner-form-step3
                            (result)="step3.control.setValue($event)"
                            (authFail)="onAuthFail()"></section-partner-form-step3>
                    <div class="actions">
                        <button matStepperPrevious mat-raised-button type="button">Previous</button>
                        &nbsp;
                        <button *ngIf="step3.control.valid"
                                matStepperNext
                                mat-raised-button
                                color="primary"
                                type="button"
                                class="main">
                            Next
                        </button>
                    </div>
                </div>
            </mat-step>


            <mat-step [editable]="step4.control.valid" [stepControl]="step4.control">

                <ng-template matStepLabel>Hubs</ng-template>

                <div class="step">
                    <section-partner-form-step4
                            (result)="step4.control.setValue($event)"
                            (authFail)="onAuthFail()"></section-partner-form-step4>
                    <div class="actions">
                        <button matStepperPrevious mat-raised-button type="button">Previous</button>
                        &nbsp;
                        <button *ngIf="step4.control.valid"
                                matStepperNext
                                mat-raised-button
                                color="primary"
                                type="button"
                                class="main">
                            Next
                        </button>
                    </div>
                </div>
            </mat-step>

            <mat-step [editable]="step5.control.valid" [stepControl]="step5.control">

                <ng-template matStepLabel>Carriers</ng-template>

                <div class="step">
                    <section-partner-form-step5
                            (result)="step5.control.setValue($event)"
                            (authFail)="onAuthFail()"></section-partner-form-step5>
                    <div class="actions">
                        <button matStepperPrevious mat-raised-button type="button">Previous</button>
                        &nbsp;
                        <button *ngIf="step4.control.valid" (click)="finish()"
                                mat-raised-button
                                color="primary"
                                type="button"
                                class="main">
                            Finish
                        </button>
                    </div>
                </div>
            </mat-step>

        </mat-horizontal-stepper>

    </mat-card-content>
</mat-card>
