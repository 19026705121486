import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, ViewEncapsulation} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {Router} from "@angular/router";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";

import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {DragulaService} from "ng2-dragula";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {Modal, ModalService} from "../../../../../../services/modal.service";
import {CourierTransactionFormComponent} from "../../../courier-transaction";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../common/interfaces/wizard.interface";
import {Warehouse} from "../../../../../../../common/interfaces/warehouse.interface";
import {takeUntil} from "rxjs/operators";
import {IPagination} from "../../../../../../../common/components/pagination/pagination.component";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {UserService} from "../../../../../../../common/services/user.service";

@Component({
    selector: "section-warehouse-order-wizard-courier-transactions",
    templateUrl: "courier-transactions.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class WarehouseOrderWizardCourierTransactionsComponent extends AbstractWizardStepComponent implements OnDestroy {

    private state: Base.IState;

    private boundType: string;

    private order: any;

    private shipmentId: number;

    public tracks: IPagination<any>;

    public orderTransactions: IPagination<Warehouse.ITransaction>;

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private dragulaService: DragulaService,
        private modalService: ModalService,
        private spinnerService: SpinnerService,
        private userService: UserService
    ) {

        super(changeDetectorRef);

        if (!dragulaService.find("warehouse-track-bag")) {
            dragulaService.createGroup("warehouse-track-bag", {
                copy: true,
                copyItem: (item: any): any => ({...item})
            });
        }
        dragulaService.drop("warehouse-track-bag").pipe(takeUntil(this.destroy$))
            .subscribe((value: any): void => {
                this.onDrop(value);
            });
    }

    /**
     * Handle drop event
     * @returns {Promise<any>}
     */
    private async onDrop(value: any): Promise<any> {
        const {name, el, target}: any = value;

        if (name !== "warehouse-track-bag") {
            return;
        }

        let transactionId: string;
        if (el) {
            el.classList.forEach((className: string): void => {
                if (/id-[0-9]+/.test(className)) {
                    transactionId = className.replace("id-", "");
                }
            });
        }

        let targetId: number = null;
        if (target) {
            target.classList.forEach((className: string): void => {
                if (/id-[0-9]+/.test(className)) {
                    targetId = Number(className.replace("id-", ""));
                }
            });
        }

        if (target !== null && transactionId) {
            this.spinnerService.show();
            const response: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
                ["transaction", transactionId, "link_courier_transaction"],
                {courier_transaction_id: targetId});

            this.getTracks();
            this.getOrderTransactions();

            this.spinnerService.hide();
            this.toastService.show(response.message, response.type as string);
        }
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Get courier transactions
     * @returns {Promise<any>}
     */
    public async getTracks(page: number = 1, per_page: number = null): Promise<any> {
        this.spinnerService.show();
        if (per_page === null) {
            per_page = this.userService.data.settings.default_per_page;
        }
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["courier", "transactions"], {}, {
                hide_checkpoints: true,
                with_warehouse_transactions: true,
                shipment_id: this.shipmentId,
                data_structure: "paginated",
                page,
                per_page
            });
        if (data) {
            this.tracks = data;
        }
        this.spinnerService.hide();
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Get order transactions
     * @returns {Promise<any>}
     */
    public async getOrderTransactions(page: number = 1, per_page: number = null): Promise<any> {
        this.spinnerService.show();
        if (per_page === null) {
            per_page = this.userService.data.settings.default_per_page;
        }
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["transaction", "order", this.order.id], {}, {
                linked_to_courier_transaction: false,
                type: this.boundType,
                shipment_id: this.shipmentId,
                data_structure: "paginated",
                page,
                per_page
            });
        if (data) {
            this.orderTransactions = data;
        }
        this.spinnerService.hide();
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Initialize details step
     * @param data
     * @returns {Promise<any>}
     */
    public async init(data: Wizard.IData): Promise<any> {
        this.state = data.state;

        switch (data.state.params.type) {
            case "replenishment":
                this.boundType = "inbound";
                break;
            case "sales":
                this.boundType = "outbound";
                break;
            default:
                break;
        }

        this.order = data.orderData;
        this.shipmentId = data.shipmentId;
        this.getOrderTransactions();
        this.getTracks();

        this.result.next({action: "result", value: true});

        this.changeDetectorRef.markForCheck();
    }

    /**
     * Create new courier transaction
     * @returns {Promise<any>}
     */
    public async addCourierTransaction(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(CourierTransactionFormComponent, {
            type: this.boundType,
            action: "add",
            state: this.state,
            shipmentId: this.shipmentId
        });

        if (response) {
            this.getTracks();
            this.getOrderTransactions();
        }

    }

    /**
     * Edit courier transaction
     * @param transaction
     * @returns {Promise<any>}
     */
    public async editCourierTransaction(transaction: any): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(CourierTransactionFormComponent, {
            type: this.boundType,
            action: "edit",
            state: this.state,
            shipmentId: this.shipmentId,
            data: transaction
        });

        if (response) {
            this.getTracks();
            this.getOrderTransactions();
        }

    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

}
