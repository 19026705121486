import {ChangeDetectionStrategy, Component, ViewEncapsulation} from "@angular/core";
import {Modal} from "../../../../../../services/modal.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";


@Component({
    selector: "section-order-set-on-hold-modal",
    template: `

        <h1 class="container-heading">This order was set on hold</h1>

        <mat-card>
            <mat-card-content>
                <form [formGroup]="formGroup" (submit)="submit()">
                    <div class="group flex">
                        <mat-form-field class="full">
                            <mat-label>Message</mat-label>
<textarea matInput formControlName="message" required ></textarea>
                        </mat-form-field>

                        <div class="full">
                            <mat-checkbox formControlName="can_be_skipped">Can be skipped</mat-checkbox>
                        </div>
                    </div>

                    <div class="actions">
                        <button type="submit" mat-raised-button color="primary" [disabled]="!formGroup.valid">
                            Set on hold
                        </button>
                        <button type="button" mat-raised-button (click)="modal.response.emit(null)">Cancel</button>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>


    `,

    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class OrderSetOnHoldModalComponent {

    public formGroup: FormGroup = new FormGroup({
        message: new FormControl(null, [Validators.required]),
        can_be_skipped: new FormControl(true)

    });

    public modal: Modal.IModal;

    public constructor(
        private toastService: ToastService,
        private apiService: ApiService,
        private spinnerService: SpinnerService
    ) {
    }

    public async submit(): Promise<any> {
        this.spinnerService.show();

        const {message, code}: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["order", this.modal.params.order_ref, "hold"], {
                ...this.formGroup.value,
                warehouse_order_id: this.modal.params.warehouse_order_id
            });

        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.modal.response.emit({name: "result", value: "order holded"});

        }
    }
}

