<div class="form" *ngIf="services">
    <div class="group">
        <mat-form-field class="full">
            <mat-label>Courier service</mat-label>
            <mat-select [formControl]="courier_service" required>

                <mat-optgroup *ngFor="let group of services | keys" [label]="group">
                    <mat-option *ngFor="let service of services[group]" [value]="service">
                        {{ service.service_name }}
                        <small *ngIf="service.description">({{ service.description }})</small>
                    </mat-option>
                </mat-optgroup>

            </mat-select>
        </mat-form-field>
    </div>
</div>

<ng-container *ngIf="!services">
    <h3 class="text-center">Please fill previous step</h3>
</ng-container>
