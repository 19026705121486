import {Component, ChangeDetectionStrategy, OnInit} from "@angular/core";
import {Table} from "src/modules/common/interfaces/table.interface";
import {Modal} from "src/modules/section/services/modal.service";

@Component({
    selector: "partner-box-items-list",
    templateUrl: "items-list-modal.component.html",
    //styleUrls: ["form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartnerBoxItemsListModalComponent implements OnInit {
    public modal: Modal.IModal;
    public boxItemsTable: Table.ISettings;

    private prepareBoxItemsTable(): void {

        this.boxItemsTable = {
            table_id: "view-box-items-table",
            columns: [
                {
                    data: "item",
                    name: "Item",
                    title: "Item"
                },
                {
                    data: "serial",
                    name: "Serial",
                    title: "Serial"
                },
                {
                    data: "quantity",
                    name: "Quantity",
                    title: "QTY",
                },
                {
                    data: "inventory_allocations_count",
                    title: "Available",
                    sortable: false,
                    render: (row: any): string => {
                        return row.inventory_allocations_count > 0 ? "No" : "Yes";
                    }
                },
            ],
            api: {
                url: ["partner", this.modal.params.partner, "inventories"],
                query: {
                    boxes: [this.modal.params.box_ref],
                    counts: [
                        "InventoryAllocations"
                    ]
                },
                version: 3
            }
        };
    }

    ngOnInit(): void {
        this.prepareBoxItemsTable();
    }
}