import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ModalService} from "../../../../../services/modal.service";
import {GMap} from "../../../../../../common/components/gmap/gmap.component";
import {SidebarComponent} from "../../../../../../common/components/sidebar/sidebar.component";
import {FormControl} from "@angular/forms";
import {takeUntil} from "rxjs/operators";
import {StorageService} from "../../../../../../common/services/storage.service";
import {TrackingMapSidebarComponent} from "./map-sidebar.component";
import {OrderService} from "../../../../../../common/services/order.service";

@Component({
    selector: "section-tracking-map",
    templateUrl: "map.component.html",
    styleUrls: ["map.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class TrackingMapComponent implements Base.IComponent, OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input()
    public readonly state: Base.IState;

    @ViewChild(SidebarComponent, {static: false})
    public sidebar: SidebarComponent;

    /**
     * GMap params
     */
    public markers: GMap.IMarker[] = [];
    public filteredMarkers: GMap.IMarker[] = [];
    public markersFilter: FormControl = new FormControl([]);

    public markerStatuses: any[] = [];
    public markersDaysCount: FormControl = new FormControl("30");

    public orderTypes: any[] = [];
    public markersOrderType: FormControl = new FormControl([]);

    @Input()
    public embeddedMap: boolean = false;

    public hasWarnings: boolean = false;


    public constructor(
        private router: Router,
        private apiService: ApiService,
        private changeDetectorRef: ChangeDetectorRef,
        private modalService: ModalService,
        // private spinnerService: SpinnerService,
        private orderService: OrderService,
        private storageService: StorageService
    ) {
    }

    /**
     * Get transactions for GMap
     */
    private async getCourierTransactions(days_back: number, order_type: string): Promise<any> {

        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["courier", "transactions"], {}, {
                days_back,
                order_type: order_type === "All" ? null : order_type,
                hide_checkpoints: true,
                address_from: false,
                relations: [
                    "Partner",
                    "Order.ServiceLevel.orderType",
                    "Courier.ShippingMode"
                ]
            });

        const isAdmin: boolean = this.state.section === "admin";

        this.markers = [];

        if (data) {
            for (const item of data) {
                let icon: string;
                let color: string;
                switch (item.status) {
                    case "delivered":
                        color = isAdmin ? "gmap-marker-big-green_iwwh4e" : "-green";
                        break;
                    case "expired":
                    case "exception":
                        color = isAdmin ? "gmap-marker-big-red_l5t3ne" : "-red";
                        break;
                    case "in transit":
                    case "out for delivery":
                        color = isAdmin ? "gmap-marker-big_u0bjm1" : "-yellow";
                        break;
                    default:
                        color = isAdmin ? "gmap-marker-big-grey_shouje" : "";
                        break;
                }

                if (isAdmin) {
                    icon = item.partner.icon_path
                        .replace("upload/", "upload/c_scale,w_30/c_fit,h_60,u_"
                            + color + ",w_60,x_0,y_5/");
                } else {
                    switch (item.courier.shipping_mode.slug) {
                        case "courier":
                            icon = "https://app.logivice.net/assets/images/icons/car-marker" + color + ".png";
                            break;
                        case "air_freight":
                            icon = "https://app.logivice.net/assets/images/icons/plain-marker" + color + ".png";
                            break;
                        default:
                            icon = null;
                            break;
                    }
                }

                if (item.shipment
                    && item.shipment.to_address
                    && item.shipment.to_address.lat
                    && item.shipment.to_address.lng) {

                    this.markers.push({
                        lat: Number(item.shipment.to_address.lat),
                        lng: Number(item.shipment.to_address.lng),
                        iconUrl: icon,
                        data: item
                    });

                }
            }

        }

        this.filteredMarkers = this.markers;
        this.changeDetectorRef.markForCheck();
        this.getTransactionsFilters(days_back);
    }

    private async getTransactionsFilters(days_back: number): Promise<any> {
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["courier", "transactions", "filters"], {}, {
                days_back,
                filters: [
                    "statuses",
                    "order_types"
                ]
            });

        if (data) {
            this.markerStatuses = data.statuses;

            if (!this.storageService.get(this.state.section + "-" + "dash-markers-filter-status")) {
                const selected: string[] = [];
                for (const item of this.markerStatuses) {
                    if (item.value !== "delivered") {
                        selected.push(item.value);
                    }
                }
                this.markersFilter.setValue(selected);
            } else {
                this.markersFilter.setValue(
                    this.storageService.get(this.state.section + "-" + "dash-markers-filter-status")
                );
            }


            this.orderTypes = data.order_types;
            if (!this.storageService.get(this.state.section + "-" + "dash-markers-filter-type")) {
                const selected: string[] = [];
                for (const item of this.orderTypes) {
                    selected.push(item.value);
                }
                this.markersOrderType.setValue(selected);
            } else {
                this.markersOrderType.setValue(
                    this.storageService.get(this.state.section + "-" + "dash-markers-filter-type")
                );
            }


            this.changeDetectorRef.markForCheck();
        }

    }

    private gMapSetup(): void {
        this.markersFilter.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: string): void => {
            if (value.length > 0) {
                this.filteredMarkers = this.markers.filter((marker: GMap.IMarker): boolean => {
                    return value.indexOf(marker.data.status) > -1;
                });
                this.storageService.set(this.state.section + "-" + "dash-markers-filter-status", value);
            } else {
                this.filteredMarkers = this.markers;
                this.storageService.set(this.state.section + "-" + "dash-markers-filter-status", null);
            }
            this.changeDetectorRef.markForCheck();
        });

        this.markersDaysCount.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: number): void => {
            this.storageService.set(this.state.section + "-" + "dash-markers-filter-days", value);
            this.getCourierTransactions(value, this.markersOrderType.value.value);
            this.changeDetectorRef.markForCheck();
        });

        this.markersOrderType.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any): void => {
            if (value.length > 0) {
                this.filteredMarkers = this.markers.filter((marker: GMap.IMarker): boolean => {
                    return value.indexOf(marker.data.order.service_level.order_type.id) > -1;
                });
                this.storageService.set(this.state.section + "-" + "dash-markers-filter-type", value);
            } else {
                this.filteredMarkers = this.markers;
                this.storageService.set(this.state.section + "-" + "dash-markers-filter-type", null);
            }
            this.changeDetectorRef.markForCheck();
        });

        if (!this.storageService.get(this.state.section + "-" + "dash-markers-filter-days")) {
            this.storageService.set(this.state.section + "-" + "dash-markers-filter-days", "30");
            this.markersDaysCount.setValue("7");
        } else {
            this.markersDaysCount.setValue(
                this.storageService.get(this.state.section + "-" + "dash-markers-filter-days")
            );
        }
    }

    public onMarkerClick(marker: GMap.IMarker): void {
        this.sidebar.show(TrackingMapSidebarComponent, {
            marker,
            state: this.state
        });
    }

    public ngOnInit(): void {
        this.gMapSetup();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "tracking-map",
            actions: {
                "browse": ["browse_courier_transactions"]
            }
        };
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
