import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {Modal} from "../../../../../services/modal.service";
import {StorageService} from "../../../../../../common/services/storage.service";


@Component({
    selector: "section-request-form",
    templateUrl: "form.component.html",
    styleUrls: [
        "form.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class RequestFormComponent implements OnInit, OnDestroy {

    public modal: Modal.IModal;

    public action: string = "add";

    public itForm: boolean = false;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private storageService: StorageService
    ) {
    }

    /**
     * Close modal
     */
    public close(): void {
        this.storageService.set("isRequestMinimized", false);
        this.modal.response.emit();
    }

    public minimizeRequest(): void {
        this.storageService.set("isRequestMinimized", true);
        this.modal.response.emit();
    }

    public ngOnInit(): void {
        if (this.modal.params.itForm) {
            this.itForm = true;
            this.changeDetectorRef.markForCheck();
        }
    }

    public ngOnDestroy(): void {
        if (!this.storageService.get("isRequestMinimized")) {
            this.storageService.remove("requestIt");
            this.storageService.remove("requestItFiles");
            this.storageService.remove("requestOperations");
            this.storageService.remove("requestOperationsFiles");
            this.storageService.remove("showExpectedBehaviour");
        }
    }
}
