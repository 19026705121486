import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {Router} from "@angular/router";


import {Base} from "../../../../../../../../common/interfaces/base.interfaces";
import {Warehouse} from "../../../../../../../../common/interfaces/warehouse.interface";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../../common/interfaces/wizard.interface";
import {ConfirmComponent} from "../../../../../../../../common/components/confirm/confirm.component";
import {Api, ApiService} from "../../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../../common/services/toast.service";
import {SpinnerService} from "../../../../../../../../common/services/spinner.service";


@Component({
    selector: "section-warehouse-procedures-wizard-search",
    templateUrl: "search.component.html",
    styleUrls: [
        "search.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class WarehouseProceduresWizardOrderSearchComponent extends AbstractWizardStepComponent implements OnDestroy {

    private state: Base.IState;

    public wizardType: string;

    public warehouse: Warehouse.IWarehouse;

    public scannerKey: string;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild("searchinput", {static: false})
    public searchinput: ElementRef;

    public foundOrders: Warehouse.IOrder[] = [];

    public message: string = null;

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        protected spinnerService: SpinnerService
    ) {
        super(changeDetectorRef);
    }

    /**
     * Get warehouse data by url slug
     */
    private async getData(): Promise<any> {
        this.spinnerService.show();
        const [type, slug]: string[] = this.state.section.split("/");
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["warehouse", "findBySlug", slug]);
        if (data) {
            this.warehouse = data;
            this.scannerKey = this.warehouse.properties.scanner_key;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Find order by ref or redirect to next step if
     * order barcode was scanned
     */
    public async findOrder(): Promise<any> {
        const value: string = this.searchinput.nativeElement.value.trim();
        if (value === "") {
            return;
        }

        if (value.includes("WHO::")) {
            const [warehouseOrderId, ref, orderId]: string[] = value.replace("WHO::", "").split("~");
            this.goToOrder(Number(warehouseOrderId), ref, Number(orderId));
            return;
        }

        this.spinnerService.show();
        this.foundOrders = [];
        const {data, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["warehouse", "order", "find"], {ref: value}, {type: this.wizardType});
        if (data) {
            if (typeof data[0] === "string") {
                this.toastService.show(message + "<br>" + data.join("<br>"), "error");
            } else {
                this.foundOrders = data;
                this.message = message;
            }
        }
        this.searchinput.nativeElement.value = "";
        this.changeDetectorRef.markForCheck();
        this.spinnerService.hide();
        this.searchinput.nativeElement.focus();
    }

    /**
     * Listen scanner activation key event
     * @param event
     */
    public onKeydown(event: any): void {
        if (event.key === "Enter" || event.key === "Tab") {
            this.findOrder();
        }
    }

    /**
     * Emit step result
     * @param warehouseOrderId
     * @param orderRef
     * @param orderId
     */
    public async goToOrder(warehouseOrderId: number, orderRef: string, orderId: number): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["order", "" + orderId], {}, {
            relations: "Holds"
        });
        this.spinnerService.hide();

        let isViewOnly = false;
        const lockdown: any[] = [];
        if (data) {
            for (const hold of data.holds) {
                if (Number(hold.warehouse_order_id) === Number(warehouseOrderId)) {
                    lockdown.push(hold);
                }
            }

            if (Number(data.order_status_id) === 67) {
                isViewOnly = true;
                lockdown.push({
                    message: "Order is closed. Additional changes are prohibited",
                    can_be_skipped: true,
                    warehouse_order_id: warehouseOrderId,
                    order_id: orderId
                });
            }
        }
        this.result.emit({
            action: "result",
            value: {warehouseOrderId, scannerKey: this.scannerKey, orderRef, orderId, lockdown, isViewOnly},
            goToNextStep: true,
        });


    }

    /**
     * Initialize step
     * @param data
     * @returns {Promise<any>}
     */
    public async init(data: Wizard.IData): Promise<any> {
        this.state = data.state;
        this.wizardType = data.wizardType;
        await this.getData();
        if (this.state.params.order) {
            this.goToOrder(this.state.params.order, this.state.params.order_ref, this.state.params.order_id);
        } else if (this.state.params.order_ref) {
            this.searchinput.nativeElement.value = this.state.params.order_ref;
            this.findOrder();
        } else {
            this.result.emit({
                action: "result",
                value: null
            });
            setTimeout((): void => {
                this.searchinput.nativeElement.focus();
            }, 100);
        }

        this.changeDetectorRef.markForCheck();
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

}
