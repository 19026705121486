import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {ToastService} from "../../../../../../common/services/toast.service";
import {UserService} from "../../../../../../common/services/user.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";

@Component({
    selector: "section-knowledge-center-view",
    templateUrl: "view.component.html",
    styleUrls: ["view.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class KnowledgeCenterViewComponent implements Base.IComponent, OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    public readonly state: Base.IState;

    public document: any;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private userService: UserService,
        private spinnerService: SpinnerService
    ) {
    }

    /**
     * Get request data
     * @returns {Promise<any>}
     */
    public async getDocument(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["knowledge_center", "documents", this.state.params.id]);

        if (data) {
            this.document = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    public isImage(): boolean {
        return ["image/gif", "image/jpeg", "image/png"].includes(this.document.document_type);
    }

    public ngOnInit(): void {
        this.getDocument();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "support-knowledge-center",
            actions: {
                "view": ["read_knowledge_center_documents"]
            }
        };
    }
}
