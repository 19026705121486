import {Schema} from "jsonschema";
import {Warehouse} from "./warehouse.interface";

export namespace User {

    export interface IData {
        avatar: string;
        created_at: string;
        email: string;
        id: number;
        name: string;
        phone: string;
        partners?: IPartner[];
        threepls?: IThreepl[];
        permissions?: string[];
        roles?: IRole[];
        user2fa: {
            enabled: boolean;
        };
        settings: {
            default_route: string;
            threepl_default_route: string;
            default_per_page: number;
        };
        force2fa: boolean;
        force_change_password: boolean;
        nickname: string;
    }

    export interface IPartner {
        display_name: string;
        id: number;
        logo_path?: string;
        icon_path?: string;
        style_path?: string;
        slug: string;
        properties: any;
        clients?: IData[];
        managers?: IData[];
        manager_on_shift?: {
            id: number;
            name: string;
            avatar: string;
        };
        manager_in_charge?: {
            id: number;
            name: string;
            avatar: string;
        };
    }

    export interface IThreepl {
        display_name: string;
        id: number;
        slug: string;
        properties: any;
        icon_path?: string;
        type: string;
        warehouses?: Warehouse.IWarehouse[];
    }

    export interface IRole {
        id: number;
        display_name: string;
        name: string;
        order_remark_type_id: string;
        permissions: IPermission[];
    }

    export interface IPermission {
        id: number;
        key: string;
    }

    export interface IMentionUser {
        nickname: string;
        name: string;
        avatar: string;
        isTeam: boolean;
    }

    export const UserSchema: Schema = {
        properties: {
            email: {type: "string"},
            id: {type: "integer"},
            name: {type: "string"},
            partners: {
                items: {
                    properties: {
                        display_name: {type: "string"},
                        id: {type: "integer"},
                        slug: {type: "string"},
                        properties: {type: "object"}
                    },
                    required: ["display_name", "id", "slug", "properties"],
                    type: "object"
                },
                minItems: 0,
                type: "array"
            },
            threepls: {
                items: {
                    properties: {
                        display_name: {type: "string"},
                        id: {type: "integer"},
                        slug: {type: "string"},
                        properties: {type: "object"}
                    },
                    required: ["display_name", "id", "slug", "properties"],
                    type: "object"
                },
                minItems: 0,
                type: "array"
            },
            permissions: {
                items: {"type": "string"},
                minItems: 0,
                type: "array"
            }
        },
        required: ["email", "id", "name", "permissions"],
        type: "object"
    };

}
