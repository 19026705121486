import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild} from "@angular/core";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {Table2Component} from "../../../../../../common/components/table2/table.component";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {FileUploadComponent} from "../../../../../../common/components/file-upload/file-upload.component";
import {PartMaster} from "../../../../../../common/interfaces/part-master.interface";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {PartMasterService} from "../../../../../../common/services/part-master.service";

@Component({
    selector: "common-part-master-files-list",
    template: `
        <common-confirm></common-confirm>

        <mat-card>
            <mat-card-content>
                <common-table2 *ngIf="listTable" [settings]="listTable">
                    <div>
                        <button type="button" mat-raised-button color="accent" (click)="uploadFile()">
                            Upload document
                        </button>
                    </div>
                </common-table2>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonPartMasterFilesListComponent implements OnInit {

    @Input()
    public part_master: PartMaster.IItem;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.ISettings;

    /* Table options */

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private partMasterService: PartMasterService,
        private modalService: ModalService
    ) {
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        this.listTable = {
            table_id: "nOu831pUy9KFlS",
            columns: [
                {
                    data: "name",
                    title: "Name",
                    render: (row: any): string => {
                        return `<a href="${row.path}" target="_blank">${row.name}</a>`;
                    }
                },
                {
                    data: "size",
                    title: "Size",
                    render: (row: any): string => {
                        return HelpersService.formatBytes(row.size);
                    }
                },
                {
                    data: "extension",
                    title: "Extension"
                },
            ],
            api: {
                url: ["partmaster", "files"],
                query: {
                    part_master_id: this.part_master.id
                }
            },
            actions: [
                {
                    name: "download",
                    title: "Download",
                    click: (row: any): void => {
                        window.open(row.path, "_blank");
                    },
                    icon: "download"
                },
                {
                    name: "delete",
                    title: "Delete",
                    click: async (row: any): Promise<any> => {
                        if (!await this.confirmRef.confirm("You are about to delete " + row.name)) {
                            return;
                        }

                        this.partMasterService.deletePartMasterFile(row.id).then((): void => {
                            this.tableRef.reload();
                        });
                    }
                }
            ]
        };

        this.changeDetectorRef.markForCheck();
    }

    public async uploadFile(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(FileUploadComponent, {
            url: ["partmaster", "files"],
            accept: ["pdf", "doc", "docx", "jpeg", "jpg", "png"],
            footer: "<b>Note:</b> In doc files you can use variables to fill data: {$item} {$description}.",
            modalHeight: 400,
            body: {
                part_master_id: this.part_master.id
            }
        });

        if (response && response.value) {
            this.tableRef.reload();
        }
    }

    public ngOnInit(): void {
        this.prepareList();
    }
}
