<div class="shipments">
    <mat-card *ngFor="let shipment of shipments; let index = index">

        <div class="title">
            <div>
                <mat-icon>airplanemode_active</mat-icon>
                <b>{{shipment.ref}}</b>
                ({{shipment.weight}}{{shipment.mass_unit}})
            </div>

            <mat-radio-group [formControl]="selectedShipment" name="shipment_select">
                <mat-radio-button value="{{index}}"></mat-radio-button>
            </mat-radio-group>

        </div>
        <div class="margin-bottom-10">
            {{shipment.created_at}}
        </div>

        <div *ngIf="shipment.from_address" class="address">
            <img *ngIf="shipment.from_address.country_iso_2"
                 [src]="'/assets/images/flags/' + ('' + shipment.from_address.country_iso_2).toLowerCase() + '.svg'"
                 class="flag" alt="">

            <mat-icon>flight_takeoff</mat-icon>

            <ng-template [ngIf]="shipment.from_address.formatted_address">
                {{shipment.from_address.formatted_address}},
            </ng-template>

            <ng-template [ngIf]="!shipment.from_address.formatted_address">
                {{shipment.from_address.address}},
                {{shipment.from_address.city}},
                {{shipment.from_address.country}}
            </ng-template>
        </div>

        <div *ngIf="shipment.to_address" class="address">
            <img *ngIf="shipment.to_address.country_iso_2"
                 [src]="'/assets/images/flags/' + ('' + shipment.to_address.country_iso_2).toLowerCase() + '.svg'"
                 class="flag vertical-middle" alt="">

            <mat-icon>flight_land</mat-icon>

            <ng-template [ngIf]="shipment.to_address.formatted_address">
                {{shipment.to_address.formatted_address}},
            </ng-template>

            <ng-template [ngIf]="!shipment.to_address.formatted_address">
                {{shipment.to_address.address}},
                {{shipment.to_address.city}},
                {{shipment.to_address.country}}
            </ng-template>
        </div>
    </mat-card>
    <mat-card *ngIf="!shipments.length">
        <p>No shipments yet</p>
    </mat-card>
</div>
