import {ChangeDetectionStrategy, Component, OnInit, ViewChild} from "@angular/core";
import {Base} from "../../../../../common/interfaces/base.interfaces";
import {PartnerFormStep2Component} from "../../../admin/components/partner/form/step2/step2.component";

@Component({
    selector: "section-partner-coverages",
    template: `
        <h1 class="container-heading">Partner service levels</h1>
        <mat-card>
            <mat-card-content>
                <section-partner-form-step2></section-partner-form-step2>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PartnerCoveragesComponent implements Base.IComponent, OnInit {

    @ViewChild(PartnerFormStep2Component, {static: true})
    public coveragesRef: PartnerFormStep2Component;

    public readonly state: Base.IState;

    public ngOnInit(): void {
        this.coveragesRef.init(this.state.section);
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "coverage",
            actions: {
                "browse": ["browse_coverages"]
            }
        };
    }
}
