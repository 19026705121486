<ng-template [ngIf]="remark">
    <mat-list-item>
        <div matListItemTitle>
            <div class="pull-right">
                {{remark.updated_at | commonFix | date: 'dd.MM.yyyy HH:mm:ss'}}
            </div>
            <div>Order: {{remark.order ? remark.order.ref : "N/A"}}</div>
            <div><b>{{remark.subject}}</b></div>
        </div>

        <div matListItemLine [innerHTML]="remark.message"></div>
        <div matListItemLine class="text-cutter" [@slideInOut]="textAnimationState">
            <div #text class="text" [innerHTML]="remark.message | linebreaks"></div>
            <div class="text-footer" *ngIf="textCut" (click)="expandCut()">
                Read more
                <mat-icon>keyboard_arrow_down</mat-icon>
            </div>
        </div>

        <div matListItemLine class="text-right" *ngIf="remark.order">
            <a [routerLink]="[
        '/partner',
        remark.partner.slug,
        'orders',
        'view',
        'ref',
        remark.order.ref
        ]">
                View order
            </a>
        </div>
    </mat-list-item>
    <mat-divider></mat-divider>
</ng-template>
