<h1 class="container-heading">Choose Inspection</h1>
<mat-card>
    <mat-card-content>
        <div class="inspections">
            <div class="item" (click)="navTo('inspection-results')">
                <img src="../../../../../../../assets/images/add.png"/>
            </div>
            <div class="item" style="margin-left: 28px;" (click)="navTo('inspections')">
                <img src="../../../../../../../assets/images/list.png"/>
            </div>
        </div>
    </mat-card-content>
</mat-card>