import {Component, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {Subject} from "rxjs";
import {LiveChatWidgetApiModel} from "../../interfaces/livechat.interface";

@Component({
    selector: "common-livechat",
    template: ""
})
export class LivechatComponent implements OnInit, OnDestroy {

    private lc: HTMLScriptElement;

    @Input()
    public licenseId: number;

    @Input()
    public group: number;

    @Input()
    public chatBetweenGroups: boolean;

    @Input()
    public params: { name: string, value: any };

    @Input()
    public visitor: { name: string, email: string, };

    @Input()
    public gaVersion: string;

    @Output()
    public onChatLoaded: Subject<LiveChatWidgetApiModel> = new Subject<LiveChatWidgetApiModel>();

    @Output()
    public onAfterLoad: Subject<void> = new Subject();

    @Output()
    public onBeforeLoad: Subject<void> = new Subject();

    @Output()
    public onChatEnded: Subject<void> = new Subject();

    @Output()
    public onChatStarted: Subject<any> = new Subject<any>();

    @Output()
    public onChatStateChanged: Subject<any> = new Subject<any>();

    @Output()
    public onChatWindowHidden: Subject<void> = new Subject();

    @Output()
    public onChatWindowMinimized: Subject<void> = new Subject();

    @Output()
    public onChatWindowOpened: Subject<void> = new Subject();

    @Output()
    public onMessage: Subject<any> = new Subject<any>();

    @Output()
    public onPostchatSurveySubmitted: Subject<any> = new Subject<any>();

    @Output()
    public onPrechatSurveySubmitted: Subject<any> = new Subject<any>();

    @Output()
    public onRatingCommentSubmitted: Subject<any> = new Subject<any>();

    @Output()
    public onRatingSubmitted: Subject<any> = new Subject<any>();

    @Output()
    public onTicketCreated: Subject<any> = new Subject<any>();

    @Output()
    public onWidgetResize: Subject<void> = new Subject();

    public agentsAreAvailable: Function;
    public chatRunning: Function;
    public chatWindowHidden: Function;
    public chatWindowMaximized: Function;
    public chatWindowMinimized: Function;
    public closeChat: Function;
    public getChatId: Function;
    public getChatsNumber: Function;
    public getLastVisitTimestamp: Function;
    public getPageViewsNumber: Function;
    public getVisitorId: Function;
    public getVisitsNumber: Function;
    public hideChatWindow: Function;
    public minimizeChatWindow: Function;
    public openChatWindow: Function;
    public setCustomVariables: Function;
    public setVisitorEmail: Function;
    public setVisitorName: Function;
    public triggerSalesTracker: Function;
    public updateCustomVariables: Function;
    public visitorEngaged: Function;
    public visitorQueued: Function;

    public constructor() {
    }

    private _chatLoaded(LC_API: LiveChatWidgetApiModel): void {
        this._setCallbacks(LC_API);
        this._setMethods(LC_API);
        this.onChatLoaded.next(LC_API);
    }

    private _setCallbacks(LC_API: LiveChatWidgetApiModel): void {
        LC_API.on_after_load = (): any => this.onAfterLoad.next();
        LC_API.on_before_load = (): any => this.onBeforeLoad.next();
        LC_API.on_chat_ended = (): any => this.onChatEnded.next();
        LC_API.on_chat_started = (data: any): any => this.onChatStarted.next(data);
        LC_API.on_chat_state_changed = (data: any): any => this.onChatStateChanged.next(data);
        LC_API.on_chat_window_hidden = (): any => this.onChatWindowHidden.next();
        LC_API.on_chat_window_minimized = (): any => this.onChatWindowMinimized.next();
        LC_API.on_chat_window_opened = (): any => this.onChatWindowOpened.next();
        LC_API.on_message = (data: any): any => this.onMessage.next(data);
        LC_API.on_postchat_survey_submitted = (data: any): any => this.onPostchatSurveySubmitted.next(data);
        LC_API.on_prechat_survey_submitted = (data: any): any => this.onPrechatSurveySubmitted.next(data);
        LC_API.on_rating_comment_submitted = (data: any): any => this.onRatingCommentSubmitted.next(data);
        LC_API.on_rating_submitted = (data: any): any => this.onRatingSubmitted.next(data);
        LC_API.on_ticket_created = (data: any): any => this.onTicketCreated.next(data);
    }

    private _setMethods(LC_API: LiveChatWidgetApiModel): void {
        this.agentsAreAvailable = (): any => LC_API.agents_are_available();
        this.chatRunning = (): any => LC_API.chat_running();
        this.chatWindowHidden = (): any => LC_API.chat_window_hidden();
        this.chatWindowMaximized = (): any => LC_API.chat_window_maximized();
        this.chatWindowMinimized = (): any => LC_API.chat_window_minimized();
        this.closeChat = (): any => LC_API.close_chat();
        this.getChatId = (): any => LC_API.get_chat_id();
        this.getChatsNumber = (): any => LC_API.get_chats_number();
        this.getLastVisitTimestamp = (): any => LC_API.get_last_visit_timestamp();
        this.getPageViewsNumber = (): any => LC_API.get_page_views_number();
        this.getVisitorId = (): any => LC_API.get_visitor_id();
        this.getVisitsNumber = (): any => LC_API.get_visits_number();
        this.hideChatWindow = (): any => LC_API.hide_chat_window();
        this.minimizeChatWindow = (): any => LC_API.minimize_chat_window();
        this.openChatWindow = (): any => LC_API.open_chat_window();
        this.setCustomVariables = (t: any): any => LC_API.set_custom_variables(t);
        this.setVisitorEmail = (t: any): any => LC_API.set_visitor_email(t);
        this.setVisitorName = (t: any): any => LC_API.set_visitor_name(t);
        this.triggerSalesTracker = (e: any, t: any): any => LC_API.trigger_sales_tracker(e, t);
        this.updateCustomVariables = (t: any): any => LC_API.update_custom_variables(t);
        this.visitorEngaged = (): any => LC_API.visitor_engaged();
        this.visitorQueued = (): any => LC_API.visitor_queued();
    }

    public ngOnInit(): void {
        if (!window["LC_API"]) {
            window["__lc"] = window["__lc"] || {};
            window["__lc"].license = this.licenseId;
            window["__lc"].group = this.group;
            window["__lc"].chat_between_groups = this.chatBetweenGroups;
            window["__lc"].params = this.params;
            window["__lc"].visitor = this.visitor;
            window["__lc"].ga_version = this.gaVersion;
            const api: any = {
                _q: [],
                _h: null,
                call: (...args: any): void => api._h && api._h.apply(null, ["call", [].slice.call(args)])
            };
            window["LiveChatWidget"] = api;
            this.lc = document.createElement("script");
            this.lc.type = "text/javascript";
            this.lc.async = true;
            this.lc.src = "https://cdn.livechatinc.com/tracking.js";
            const s: any = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(this.lc, s);
            this.lc.addEventListener("load", (): void => {
                this._chatLoaded(window["LC_API"]);
            });
            this.lc.addEventListener("error", (error: ErrorEvent): void => {
                console.error(error);
            });
        }
    }

    public ngOnDestroy(): void {
        if (window["LiveChatWidget"] && typeof window["LiveChatWidget"].call === "function") {
            window["LiveChatWidget"].call("destroy");

            if (this.lc) {
                this.lc.remove();
            }

            ["secure.livechat.com", "secure.livechatinc.com"].forEach((domain: string): void => {
                Array.from(document.querySelectorAll(`[src*="${domain}"]`)).forEach((s: any): void => s.remove());
            });
        }
    }


}
