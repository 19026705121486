import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {UntypedFormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {ServiceLevelService} from "../../../../../../common/services/service-level.service";

@Component({
    selector: "section-reseller-form",
    templateUrl: "form.component.html",
    styleUrls: [
        "form.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class ResellerFormComponent implements OnInit {

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    /**
     * Form group / group with controls
     * @type {FormGroup}
     */
    public formGroup: FormGroup = new FormGroup({
        name: new FormControl(null, [Validators.required]),
        email: new FormControl(null),
    });

    public modal: Modal.IModal;

    public edit: boolean = false;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private formBuilder: UntypedFormBuilder,
        private apiService: ApiService,
        private toastService: ToastService,
        private modalService: ModalService,
        private serviceLevelService: ServiceLevelService,
        private spinnerService: SpinnerService
    ) {
    }


    private async getData(): Promise<any> {
        this.spinnerService.show();

        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["modix_resellers", this.modal.params.id]);
        if (data) {
            this.formGroup.patchValue(data);
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }


    /**
     * Submit form (add)
     * @returns {Promise<any>}
     */
    public async handleFormSubmit(): Promise<any> {
        if (this.formGroup && this.formGroup.valid) {
            this.spinnerService.show();
            const body: any = this.formGroup.value;

            let res: Api.IResponse;
            if (!this.edit) {
                res = await this.apiService.request(Api.EMethod.Post, "modix_resellers", body);
            } else if (this.edit) {
                res = await this.apiService.request(Api.EMethod.Put,
                    ["modix_resellers", "" + this.modal.params.id], body);
            }
            this.spinnerService.hide();

            if (res.data) {
                this.toastService.show(res.message, "success");
                this.modal.response.next({
                    value: null,
                    name: "update"
                });
            }
        }
    }

    /**
     * Delete customer
     */
    public async delete(): Promise<any> {
        if (await this.confirmRef.confirm("Are you sure want to delete this reseller?")) {
            this.spinnerService.show();
            const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
                ["modix_resellers", this.modal.params.id]);
            if (type as string === "success") {
                this.toastService.show(message, "success");
                this.modal.response.next({
                    value: null,
                    name: "delete"
                });
            }
            this.spinnerService.hide();
        }
    }


    public ngOnInit(): void {
        if (this.modal.params.id) {
            this.edit = true;
            this.getData();
        }
    }

}
