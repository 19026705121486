<mat-card>
    <h1>Select serial to remove</h1>

    <form [formGroup]="form" (submit)="submit()">
        <div class="group flex">

            <div class="half">
                <common-form-hub-select
                        label="Hub"
                        [required]="true"
                        line_type="outbound"
                        [value]="form.value.hub"
                        (onChange)="form.get('hub').setValue($event)"></common-form-hub-select>
            </div>

            <div class="half">
                <common-form-select [options]="partMasters"
                                    [required]="true"
                                    [search]="true"
                                    [disabled]="!partMasters || !partMasters.length"
                                    label="Select item"
                                    (valueChange)="setItem($event)">
                </common-form-select>
            </div>

            <ng-container *ngIf="form.controls['part_master_id']?.value">
                <mat-form-field class="full" appearance="fill">
                    <mat-label>Selected serials</mat-label>
                    <mat-chip-grid #selectedSerialschipGrid>
                        <mat-chip-row matChipRemove *ngFor="let serial of form.get('serials').value"
                                      [selectable]="false"
                                      [removable]="true" (removed)="removeSerial(serial)">
                            {{serial}}
                            <button matChipRemove>
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip-row>

                        <input placeholder="Search serial"
                               [formControl]="serialFilter"
                               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                               [matChipInputFor]="selectedSerialschipGrid"
                               (matChipInputTokenEnd)="searchSerial($event)">
                    </mat-chip-grid>
                </mat-form-field>

                <div class="full">
                    <div class="margin-bottom-10">Available serials</div>
                </div>
                <div class="full available-serial-list">
                    <mat-chip-listbox>
                        <mat-chip-option matChipRemove *ngFor="let serial of available_serials"
                                         (click)="selectSerial(serial)">
                            {{serial}}
                        </mat-chip-option>
                    </mat-chip-listbox>
                </div>
            </ng-container>

            <mat-form-field class="full">
                <mat-label>Remark</mat-label>
<textarea row="5" matInput  required formControlName="remark"></textarea>
            </mat-form-field>

        </div>
        <div class="actions">
            <button type="submit" mat-raised-button color="accent" [disabled]="!form.valid">
                Submit
            </button>
        </div>
    </form>

</mat-card>
