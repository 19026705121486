import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {UntypedFormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {debounceTime, takeUntil} from "rxjs/operators";
import {Warehouse} from "../../../../../../common/interfaces/warehouse.interface";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Modal} from "../../../../../services/modal.service";
import {CourierService} from "../../../../../../common/services/courier.service";
import {Api3Service} from "../../../../../../common/services/api3.service";
import moment from "moment";
import {Form} from "../../../../../../common/interfaces/form.interface";
import ISelectOption = Form.ISelectOption;
import {TIME_ZONES} from "../../../../../../common/common.module";

@Component({
    selector: "section-track-number-form",
    templateUrl: "form.component.html",
    styleUrls: [
        "form.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class TrackNumberFormComponent implements Base.IComponent, OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    public readonly state: Base.IState;

    public shipment: Warehouse.IShipment;

    public modal: Modal.IModal;

    public timezones: ISelectOption[] = [];

    /**
     * Form group
     * @type {FormGroup}
     */
    public formGroup: FormGroup = new FormGroup({
        courier_slug: new FormControl(null, [Validators.required]),
        courier_service_slug: new FormControl(null, [Validators.required]),
        tracking_number: new FormControl(null, [Validators.required]),
        shipped_at: new FormControl(null),
        shipped_at_tz: new FormControl("Asia/Jerusalem", [Validators.required]),
        created_at: new FormControl(null),
        eta: new FormControl(null),
    });


    public couriers: { value: string, name: string }[] = [];

    public courierServices: any[] = [];

    public suggestedcourier: string;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private spinnerService: SpinnerService,
        private courierService: CourierService
    ) {
    }

    private async getShipment(): Promise<any> {
        const params: any = (this.state && this.state.params) ? this.state.params : this.modal.params;
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["shipment", "" + params.shipment]);
        if (data) {
            this.shipment = data;
        }
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Get couriers list
     * @returns {Promise<any>}
     */
    private async getCouriers(): Promise<any> {
        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `${this.state.section}/couriers`, {}, {
                data_structure: "select"
            });
        if (data) {
            this.couriers = data;
        }
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Get services list for chosen courier
     * @param {string} slug
     * @returns {Promise<any>}
     */
    private async getCourierServices(slug: string): Promise<any> {
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["courier_service"], {}, {
            courier_slug: slug
        });
        if (data) {
            this.courierServices = data;
        }
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Find courier by track number
     * @param track
     * @private
     */
    private async findCourierByTrack(track: string): Promise<any> {
        const {data, message}: Api.IResponse = await this.courierService.findByTrack(track);

        if (data && data.length) {
            this.suggestedcourier = data[0];
            this.changeDetectorRef.markForCheck();
        }
        this.toastService.show(message, "success");
    }

    public returnToOrder(tracking_number?: string, transaction_id?: number): void {
        if (this.modal && this.modal.params) {
            this.modal.response.next({
                name: "value",
                value: {
                    controlValue: transaction_id,
                    viewValue: tracking_number
                }
            });
        } else {
            this.router.navigate([
                this.state.section, "orders", "view", "id", this.state
                    ? this.state.params.order : this.modal.params.order
            ]);
        }
    }


    public addCourier(): void {
        this.formGroup.get("courier_slug").setValue(this.suggestedcourier);
        this.changeDetectorRef.markForCheck();
    }


    /**
     * Submit courier transaction
     * @returns {Promise<any>}
     */
    public async submit(): Promise<any> {
        this.spinnerService.show();
        const params: any = (this.state && this.state.params) ? this.state.params : this.modal.params;
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["shipment", "" + params.shipment, "courier_transactions"],
            this.formGroup.value);

        this.toastService.show(response.message, response.type as string);

        if (response.type as string === "success") {
            const tracking_number: string = this.formGroup.value.tracking_number;
            this.formGroup.reset();
            this.returnToOrder(tracking_number, response.data.id);
        }

        this.spinnerService.hide();
    }

    public ngOnInit(): void {
        this.spinnerService.show();
        this.getShipment();
        this.getCouriers();
        this.spinnerService.hide();

        for (const tz of Object.keys(TIME_ZONES)) {
            this.timezones.push({
                value: tz,
                name: TIME_ZONES[tz]
            });
        }
        this.changeDetectorRef.markForCheck();

        this.formGroup.get("tracking_number").valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: string): void => {
                if (value && value.length >= 5) {
                    this.findCourierByTrack(value);
                }
            });

        this.formGroup.controls.courier_slug.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value: string): void => {
                if (value) {
                    this.getCourierServices(value);
                } else {
                    this.courierServices = [];
                    this.changeDetectorRef.markForCheck();
                }
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "transaction",
            actions: {
                "add": ["browse_shipments", "browse_parcels"],
                "edit": ["browse_shipments", "browse_parcels"]
            }
        };
    }


}
