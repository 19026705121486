import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {Table} from "../../../../../../../common/components/table/table.component";
import {ApiService} from "../../../../../../../common/services/api.service";
import {Modal} from "../../../../../../services/modal.service";

@Component({
    selector: "section-item-configurations-list",
    template: `
        <h1 class="container-heading">Item configurations</h1>
        <mat-card>
            <mat-card-content>
                <common-table *ngIf="listTable" [options]="listTable" (action)="handleListAction($event)">
                    <div data-actions>
                        <button mat-raised-button color="primary" class="main" (click)="showAddForm()">Add</button>
                    </div>
                </common-table>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemConfigurationsListComponent implements OnInit {

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.IOptions;

    public modal: Modal.IModal;

    public constructor(
        private apiService: ApiService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        const actions: Table.Action.ISettings[] = [];

        if (this.modal && this.modal.params) {
            actions.push({
                name: "select",
                title: "Select"
            });
        }

        this.listTable = {
            actions,
            api: this.apiService.getUrl(["configuration", "item", this.modal.params.item_id])
                + "?data_structure=dataTables",
            columns: [
                {
                    data: "name",
                    title: "Name"
                },
                {
                    data: "description",
                    title: "Description"
                }
            ],
            order: [],
            search: true
        };
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Handle list row action
     * @param {Table.Action.IResult} action
     * @returns {Promise<any>}
     */
    public async handleListAction(action: Table.Action.IResult): Promise<any> {
        if (action.name === "select" && action.data.id) {
            this.modal.response.next({
                name: "value",
                value: action.data.name
            });
        }
    }

    /**
     * Show add new address form
     * @returns {void}
     */
    public showAddForm(): void {
        this.modal.response.next({
            name: "form"
        });
    }

    public ngOnInit(): void {
        this.prepareList();
    }

}
