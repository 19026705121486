<mat-card>
    <h1>Box ref change</h1>

    <form [formGroup]="form" (submit)="submit()">
        <div class="group flex">
            <div class="half">
                <common-form-select [options]="boxes"
                    [required]="true"
                    [search]="true"
                    [disabled]="!boxes || !boxes.length"
                    label="Select box"
                    (valueChange)="form.get('box_id').setValue($event)">
                </common-form-select>
            </div>

            <mat-form-field class="half">
                <mat-label>New Ref</mat-label>
                <input row="5" matInput  required formControlName="ref">
            </mat-form-field>

            <mat-form-field class="full">
                <mat-label>Remark</mat-label>
                <textarea row="5" matInput  required formControlName="remark"></textarea>
            </mat-form-field>

        </div>
        <div class="actions">
            <button type="submit" mat-raised-button color="accent" [disabled]="!form.valid">
                Submit
            </button>
        </div>
    </form>

</mat-card>
