import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Field} from "../../../../../../../common/components/field/field.component";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {ModalService} from "../../../../../../services/modal.service";
import {UserService} from "../../../../../../../common/services/user.service";
import {debounceTime, takeUntil} from "rxjs/operators";
import {Wizard} from "../../../../../../../common/interfaces/wizard.interface";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {
    AbstractSinglePageWizardStepComponent
} from "../../../../../../../common/interfaces/wizard-single-page.interface";
import {ContactFormComponent, ContactListComponent} from "../../../contact";
import {AddressService} from "../../../../../../../common/services/address.service";
import {CustomerAccountFormComponent, CustomerAccountListComponent} from "../../../customer-account";

@Component({
    selector: "section-order-wizard-origin",
    template: `
        <section-order-wizard-shipment-details [state]="state"
                                               [hasBillto]="false"
                                               shipmentName="Origin"
                                               (result)="formGroupAddressUpdate($event)">
        </section-order-wizard-shipment-details>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderWizardOriginComponent extends AbstractSinglePageWizardStepComponent
    implements OnDestroy, OnInit, OnChanges {

    public state: Base.IState;


    /**
     * Detail form group
     * @type {FormGroup}
     */
    public formGroup: FormGroup = new FormGroup({
        address_id: new FormControl(null, [Validators.required]),
        contact_address_id: new FormControl(null, [Validators.required]),
        customer_id: new FormControl(null, [Validators.required])
    });

    /**
     * Custom / dynamic fields metadata
     * @type {Field.IMetadata[]}
     */
    public meta: Field.IMetadata[];

    public components: any = {
        contact: {
            form: ContactFormComponent,
            list: ContactListComponent
        },
        customer_account: {
            form: CustomerAccountFormComponent,
            list: CustomerAccountListComponent
        }
    };

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        protected apiService: ApiService,
        protected modalService: ModalService,
        protected userService: UserService,
        protected addressService: AddressService,
        protected spinnerService: SpinnerService
    ) {
        super(changeDetectorRef);
    }


    protected setup(data: Wizard.IData): void {

        this.result.next({
            action: "data",
            value: {origin_details: null}
        });

        this.result.next({
            action: "result",
            value: false
        });


        this.state = this.data.state;
    }

    public async formGroupAddressUpdate(e: any): Promise<any> {
        if (e.address_id) {
            const {data}: Api.IResponse = await this.addressService.getAddress(e.address_id);
            if (data) {
                this.result.next({
                    action: "data",
                    value: {from_address: data}
                });
            }
        }
        this.formGroup.patchValue(e);
    }


    public ngOnInit(): void {
        super.ngOnInit();

        this.formGroup.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: any): void => {
                this.result.next({
                    action: "result",
                    value: this.formGroup.valid
                });

                if (this.formGroup.valid) {
                    this.result.next({
                        action: "data",
                        value: {origin_details: value}
                    });
                } else {
                    this.result.next({
                        action: "data",
                        value: {origin_details: null}
                    });
                }

            });

    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.data) {
            const pv: any = changes.data.previousValue;
            const cv: any = changes.data.currentValue;

            if (!pv.serviceLevel || pv.serviceLevel.id !== cv.serviceLevel.id) {
                this.setup(cv);
            }
        }
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}
