<div class="row nowrap stretch flex">
    <mat-card class="col-left">
        <h2 class="border-bottom-none">Select package</h2>

        <div class="bordered-box margin-bottom-20" [class.bordered-box-active]="!selectedPackage.value">
            <table>
                <tr>
                    <td width="40">
                        <mat-icon>assignment_turned_in</mat-icon>
                    </td>
                    <td>
                        <h2>Current package</h2>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td>
                        <b class="text-success" style="font-size: 140%;">
                            {{ selectedPackageName(selectedPackage.value) }}
                        </b>
                    </td>
                </tr>
                <tr>
                    <td>
                        <mat-icon>assignment_turned_in</mat-icon>
                    </td>
                    <td>
                        <h2>Used packages</h2>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td>
                        <div *ngIf="packages">
                            <table *ngFor="let pack of packages" class="bordered">
                                <tr>
                                    <td>
                                        {{ pack.name }}
                                        ({{ pack.warehouse_package_type?.name }})
                                    </td>
                                    <td width="40">
                                        <mat-icon style="font-size: 18px;" class="pointer" (click)="editPackage(pack)">
                                            edit
                                        </mat-icon>
                                    </td>
                                    <td width="40">
                                        <div (click)="selectPackage(pack.id)" class="pointer chipsa">
                                            Select
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </td>
                </tr>
            </table>
        </div>

        <div class="text-right">
            <button type="button" mat-raised-button color="success" (click)="newPackadge()">
                Add package
                <mat-icon>add</mat-icon>
            </button>
        </div>


        <div class="padding-top-20">
            <button type="button" mat-raised-button color="primary" (click)="exportPackList('pdf')">
                Pick list PDF <i class="fa fa-file-pdf-o"></i>
            </button>
            &nbsp;
            &nbsp;
            <button type="button" class="pull-right" mat-raised-button color="primary"
                    (click)="exportPackList('excel')">
                Pick list XLS <i class="fa fa-file-excel-o"></i>
            </button>
        </div>

    </mat-card>

    <mat-card class="col-center max margin-left-10 margin-right-10">
        <h2 class="border-bottom-none">Scan item</h2>

        <div class="bordered-box margin-bottom-10" [class.bordered-box-active]="selectedPackage.value">
            <div *ngIf="!selectedPackage.value">
                <h1 class="color-warn">Please select package first</h1>
            </div>

            <table class="search-field" *ngIf="selectedPackage.value">
                <tr>
                    <td width="60">
                        <i class="fa fa-barcode fa-4x text-success"></i>
                    </td>
                    <td>
                        <mat-form-field style="width: 100%">
                            <mat-label>{{ searchInputLabel }}</mat-label>
                            <input matInput type="search" [formControl]="searchInput"
                                   [scannerOnly]="scannerOnly"
                                   (keydown)="onKeydown($event)" #searchInputRef>
                        </mat-form-field>

                    </td>
                    <td width="110">
                        <button type="button" mat-raised-button color="success" (click)="find()"
                                [disabled]="!searchInput.value">
                            <i class="fa fa-barcode"></i> Search
                        </button>
                    </td>
                </tr>
            </table>

            <div class="text-center">
                Your scanner default key set as {{ scannerKey }}.
                <span *ngIf="scannerOnly" class="color-warn">Manual input disabled</span>
            </div>

            <div *ngIf="isViewOnly">
                <span class="color-warn">Manual input disabled for closed order</span>
            </div>
        </div>


        <div class="bordered-box">
            <div class="row flex align-center">
                <h2 class="border-bottom-none max">
                    Order items list ({{ originalItemsData?.total_items }})
                </h2>

                <mat-form-field class="half">
                    <mat-label>Search by pallet/ob, boxes, items</mat-label>
                    <input type="text" matInput [formControl]="outboundItemsSearch">
                </mat-form-field>
            </div>

            <div class="clear margin-bottom-20">
                <div class="pull-right">
                    <mat-slide-toggle [formControl]="showMatchedInList">
                        Show fully scanned items
                    </mat-slide-toggle>
                </div>

                <div class="totals">
                    <div class="chipsa">
                        <mat-icon class="vertical-middle">pallet</mat-icon>
                        {{ totalToScan.pallets }} pallets/obs
                    </div>
                    <div class="chipsa">
                        <mat-icon class="vertical-middle">inventory_2</mat-icon>
                        {{ totalToScan.boxes }} boxes
                    </div>
                    <div class="chipsa">
                        <mat-icon class="vertical-middle">category</mat-icon>
                        {{ totalToScan.items }} items
                    </div>
                </div>

            </div>

            <div class="scroll-list">
                <div class="margin-bottom-20" *ngFor="let location of locations"
                     [class.hidden]="!showMatchedInList.value && location.matched">
                    <div class="margin-bottom-10 background-success location flex row space-between align-center">
                        <mat-icon *ngIf="location.location?.warehouse_location_type_id !==20"
                                  class="vertical-middle margin-right-10">
                            location_city
                        </mat-icon>
                        <mat-icon *ngIf="location.location?.warehouse_location_type_id === 20"
                                  class="vertical-middle margin-right-10">
                            table
                        </mat-icon>
                        <b class="max">Location: {{ location.location ? location.location.location : "Unknown" }}</b>

                        <button mat-raised-button color="accent" class="margin-left-10"
                                (click)="openLocationCell(location.location.id)"
                                *ngIf="location.location?.warehouse_location_type_id === 20">
                            Open cell
                        </button>
                    </div>

                    <div *ngFor="let pallet of location.pallets | search: outboundItemsSearch.value: ['ref']"
                         [class.hidden]="!showMatchedInList.value && pallet.matched"
                         class="margin-bottom-10 item">
                        <table>
                            <tr>
                                <td width="30">
                                    <mat-icon class="vertical-middle">pallet</mat-icon>
                                </td>
                                <td>
                                    <div>Pallet/ob: <b>{{ pallet.ref }}</b></div>
                                    <div>
                                        Qty: <b>{{ pallet.inventory_allocations_count }}</b>
                                    </div>
                                </td>
                                <td class="text-right">
                                    <div *ngIf="pallet.matched" style="display:inline-block" class="vertical-middle">
                                        <div class="chipsa background-success">
                                            <mat-icon>check_circle</mat-icon>
                                            Scanned
                                        </div>
                                    </div>
                                    &nbsp;
                                    <mat-icon class="pointer vertical-middle" matTooltip="Show pallet/ob contents"
                                              (click)="openPallet(pallet)">
                                        fact_check
                                    </mat-icon>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div *ngFor="let box of location.boxes  | search: outboundItemsSearch.value: ['ref']"
                         [class.hidden]="!showMatchedInList.value && box.matched"
                         class="margin-bottom-10 item">
                        <table>
                            <tr>
                                <td width="30">
                                    <mat-icon class="vertical-middle">inventory_2</mat-icon>
                                </td>
                                <td>
                                    <div>Box: <b>{{ box.ref }}</b></div>
                                    <div>
                                        Qty: <b>{{ box.outbound_inventory_allocations_count }}</b>
                                    </div>
                                    <div>
                                        Pallet/ob: {{ box.pallet?.ref }}
                                    </div>
                                </td>
                                <td class="text-right vertical-middle">
                                    <div *ngIf="box.matched" style="display:inline-block" class="vertical-middle">
                                        <div class="chipsa background-success">
                                            <mat-icon>check_circle</mat-icon>
                                            Scanned
                                        </div>
                                    </div>
                                    &nbsp;
                                    <mat-icon class="pointer vertical-middle" matTooltip="Print sticker"
                                              (click)="printBoxSticker(box)">
                                        print
                                    </mat-icon>
                                    &nbsp;
                                    <mat-icon class="pointer vertical-middle" matTooltip="Show box contents"
                                              (click)="openBox(box)">
                                        fact_check
                                    </mat-icon>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div *ngFor="let item of location.items  | search: outboundItemsSearch.value: ['item','serial']"
                         [class.hidden]="!showMatchedInList.value && item.matched"
                         class="margin-bottom-10 item">
                        <table>
                            <tr>
                                <td width="30" class="vertical-top">
                                    <mat-icon>category</mat-icon>
                                    <mat-icon (click)="printSticker(item)" style="background-color: yellow;"
                                              title="Print stickers">print
                                    </mat-icon>
                                </td>
                                <td class="vertical-top">
                                    <div>
                                        Item: <b>{{ item.item }}</b>
                                    </div>
                                    <div *ngIf="item.serial">
                                        Serial: <b>{{ item.serial }}</b>
                                    </div>
                                    <div *ngIf="item.batch">
                                        Batch: <b>{{ item.batch }}</b>
                                    </div>
                                    <div *ngIf="item.rev">
                                        Revision: <b>{{ item.rev }}</b>
                                    </div>
                                    <div *ngIf="item.lot">
                                        Lot: <b>{{ item.lot }}</b>
                                    </div>

                                    <div *ngIf="!item.serial">
                                        Qty:
                                        <b>
                                            {{ item.quantity - item.matched_qty }}
                                            <ng-container *ngIf="item.matched_qty > 0">
                                                ({{ item.quantity }})
                                            </ng-container>
                                        </b>
                                    </div>
                                    <div>
                                        Box: <b>{{ item.box?.ref }}</b>
                                    </div>
                                </td>
                                <td class="vertical-bottom text-right">
                                    <div style="display:inline-block" *ngIf="item.matched_qty > 0">
                                        <div class="chipsa"
                                             [class.background-success]="item.matched_qty === item.quantity"
                                             [class.background-danger]="item.matched_qty !== item.quantity">
                                            <mat-icon>
                                                {{ item.matched_qty === item.quantity ? "check_circle" : "error" }}
                                            </mat-icon>
                                            Scanned: {{ item.matched_qty }}
                                            &nbsp;
                                            &nbsp;
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>

                    </div>
                </div>
            </div>
        </div>


    </mat-card>

    <mat-card class="col-right">
        <h2 class="border-bottom-none">Scan feed</h2>

        <div class="bordered-box">

            <div class="text-right">
                <button mat-raised-button type="button" color="warn"
                        *ngIf="checkedFeedItems.length"
                        (click)="deleteScans(checkedFeedItems)">
                    Delete selected
                </button>
                &nbsp;
                <mat-form-field>
                    <mat-label>Search by pallet/ob, boxes, items</mat-label>
                    <input type="text" matInput #feedSearch>
                </mat-form-field>
            </div>

            <div class="margin-bottom-10">
                <mat-checkbox [formControl]="showOnlyFailedScans">
                    Show only failed scans
                </mat-checkbox>

                <mat-checkbox [formControl]="selectAll" matTooltip="Select all"
                              class="pull-right margin-right-20"></mat-checkbox>
            </div>

            <div class="totals clear margin-bottom-20">
                <div class="chipsa">
                    <mat-icon class="vertical-middle">pallet</mat-icon>
                    {{ totalScanned.pallets }} pallets/obs
                </div>
                <div class="chipsa">
                    <mat-icon class="vertical-middle">inventory_2</mat-icon>
                    {{ totalScanned.boxes }} boxes
                </div>
                <div class="chipsa">
                    <mat-icon class="vertical-middle">category</mat-icon>
                    {{ totalScanned.items }} items
                </div>
            </div>

            <div class="scroll-list clear" style="max-height:750px;">
                <div *ngIf="scanFeed.length === 0">
                    <p class="text-center">
                        Feed is empty
                    </p>
                </div>
                <ng-container
                        *ngFor="let feed_item of scanFeed | search: feedSearch.value:['item','box','serial','pallet']">
                    <div class="margin-bottom-10 item"
                         *ngIf="!showOnlyFailedScans.value || showOnlyFailedScans.value && feed_item.status ==='error'">
                        <table>
                            <tr>
                                <td width="30" rowspan="2" class="vertical-top">
                                    <div *ngIf="feed_item.status==='pending'; else scantype">
                                        <mat-icon class="status pending">settings</mat-icon>
                                    </div>
                                    <ng-template #scantype>
                                        <mat-icon *ngIf="feed_item.type==='pallet'">pallet</mat-icon>
                                        <mat-icon *ngIf="feed_item.type==='box'">inventory_2</mat-icon>
                                        <mat-icon *ngIf="feed_item.type==='item'">category</mat-icon>
                                        <mat-icon *ngIf="feed_item.type==='serial'">category</mat-icon>
                                    </ng-template>
                                </td>
                                <td class="vertical-top">
                                    <div *ngIf="feed_item.type==='pallet'">
                                        Pallet/ob: <b>{{ feed_item.pallet }}</b>
                                    </div>

                                    <div *ngIf="feed_item.type==='box'">
                                        Box: <b>{{ feed_item.box }}</b>
                                    </div>

                                    <ng-container *ngIf="feed_item.type==='item' || feed_item.type==='serial'">
                                        <div>
                                            Item: <b>{{ feed_item.item }}</b>
                                        </div>

                                        <div *ngIf="feed_item.serial">
                                            Serial: <b>{{ feed_item.serial }}</b>
                                        </div>

                                        <div *ngIf="feed_item.batch">
                                            Batch: <b>{{ feed_item.batch }}</b>
                                        </div>

                                        <div *ngIf="feed_item.rev">
                                            Revision: <b>{{ feed_item.rev }}</b>
                                        </div>

                                        <div *ngIf="feed_item.lot">
                                            Lot: <b>{{ feed_item.lot }}</b>
                                        </div>

                                        <div *ngIf="feed_item.quantity > 1">
                                            Qty: <b>{{ feed_item.quantity }}</b>
                                        </div>
                                    </ng-container>

                                    <div *ngIf="feed_item.package">
                                        Package: {{ feed_item.package }}
                                    </div>

                                </td>
                                <td width="30" class="text-center vertical-top">
                                    <mat-checkbox
                                            [checked]="checkedFeedItems.includes(feed_item.feed_id)"
                                            (change)="$event.checked ? selectScan(feed_item.feed_id): deSelectScan(feed_item.feed_id)">
                                    </mat-checkbox>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div *ngIf="feed_item.message"
                                         class="chipsa"
                                         [class.background-success]="feed_item.status==='success'"
                                         [class.background-danger]="feed_item.status==='error'">
                                        <div>
                                            <mat-icon>
                                                {{ feed_item.status === 'success' ? "check_circle" : "error" }}
                                            </mat-icon>
                                        </div>
                                        <div>
                                            {{ feed_item.message }}
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <mat-icon class="status color-warn pointer"
                                              matTooltip="Delete scan"
                                              (click)="deleteScans([feed_item.feed_id])">
                                        delete
                                    </mat-icon>
                                </td>
                            </tr>
                        </table>
                    </div>

                </ng-container>
            </div>
        </div>
    </mat-card>
</div>

<div class="margin-bottom-20"></div>
<mat-card class="text-left">
    <div>
        <div class="color-accent margin-bottom-10">
            Press "Finish scan" button to complete scanning procedure.
            Only valid scans will be converted to transactions.
        </div>
        <button mat-raised-button color="accent" (click)="completeScanning()">
            Finish scan
        </button>
    </div>
</mat-card>
<common-confirm></common-confirm>