<h1 class="container-heading">Settings</h1>

<mat-card>
    <mat-card-content>
        <div class="columns-container" *ngIf="warehouse">
            <div class="column-1-3">
                <mat-card>

                    <div class="name">
                        <div [ngSwitch]="warehouse.type" class="margin-bottom-20">
                            <mat-icon *ngSwitchCase="'warehouse'">home</mat-icon>
                            <mat-icon *ngSwitchCase="'site'">location_on</mat-icon>
                            <mat-icon *ngSwitchCase="'person'">person</mat-icon>
                            <mat-icon *ngSwitchDefault>star</mat-icon>
                        </div>
                        <div class="margin-bottom-20">{{ warehouse.name }}</div>

                        <ng-container *ngIf="warehouse.contact">
                            <div class="contact">
                                {{ warehouse.contact.first_name }}
                                {{ warehouse.contact.last_name }}
                            </div>
                            <div class="contact">{{ warehouse.contact.email }}</div>
                            <div class="contact">{{ warehouse.contact.phone }}</div>
                        </ng-container>
                    </div>


                    <mat-list class="nav-list">
                        <mat-divider></mat-divider>
                        <mat-list-item (click)="showSection = 1" [class.active]="showSection === 1">
                            <mat-icon matListItemIcon>people</mat-icon>
                            <p matListItemTitle>Contacts</p>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item (click)="showSection = 2" [class.active]="showSection === 2">
                            <mat-icon matListItemIcon>home</mat-icon>
                            <p matListItemTitle>Sub warehouses</p>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item (click)="showSection = 3" [class.active]="showSection === 3">
                            <mat-icon matListItemIcon>settings</mat-icon>
                            <p matListItemTitle>Properties</p>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                    </mat-list>
                </mat-card>
            </div>

            <div class="column-2-3" *ngIf="showSection === 1">
                <mat-card>
                    <h3 matSubheader>Contacts</h3>
                    <section-warehouse-contacts-list [state]="state"></section-warehouse-contacts-list>
                </mat-card>
            </div>

            <div class="column-2-3" *ngIf="showSection === 2">
                <mat-card>
                    <h3 matSubheader>Sub warehouses</h3>
                    <section-sub-warehouse-list></section-sub-warehouse-list>
                </mat-card>
            </div>

            <div class="column-2-3" *ngIf="showSection === 3">
                <mat-card>
                    <h3 matSubheader>Properties</h3>
                    <div class="form">
                        <common-form-fields class="full"
                                            [fields]="customFields"
                                            [values]="warehouse.properties"
                                            #propertiesFields>
                        </common-form-fields>
                        <div class="actions">
                            <button mat-raised-button type="button" (click)="saveProperties()"
                                    [disabled]="!propertiesFields.formGroup.valid">
                                Save
                            </button>
                        </div>
                    </div>
                </mat-card>
            </div>

        </div>

    </mat-card-content>
</mat-card>
