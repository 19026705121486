import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {FormControl} from "@angular/forms";
import {debounceTime, takeUntil} from "rxjs/operators";
import {User} from "../../../../../../common/interfaces/user.interface";
import {IPagination} from "../../../../../../common/components/pagination/pagination.component";
import {Router} from "@angular/router";
import {Order} from "../../../../../../common/interfaces/order.interface";
import {UserService} from "../../../../../../common/services/user.service";
import {ModalService} from "../../../../../services/modal.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {RequestsListComponent} from "../../requests";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {Table2Component} from "../../../../../../common/components/table2";

@Component({
    selector: "section-profile",
    templateUrl: "profile.component.html",
    styleUrls: [
        "profile.component.scss",
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class ProfileComponent implements Base.IComponent, OnInit, OnDestroy {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    public user: User.IData;

    public readonly state: Base.IState;

    /**
     * Selector for user info section
     * @type {number}
     */
    public showSection: number = 1;

    public userOrders: IPagination<Order.IOrderData>;

    public remarks: IPagination<Order.IRemark>;

    public orderSearch: FormControl = new FormControl(null);

    public remarkSearch: FormControl = new FormControl(null);

    public threeplsList: User.IThreepl[] = [];

    public partnersList: User.IPartner[] = [];

    public requestsListTable: Table.ISettings;

    @ViewChild("requestsTable", {static: false})
    public requestsTableRef: Table2Component;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private apiService: ApiService,
        private userService: UserService,
        private modalService: ModalService,
        private toastService: ToastService,
        private spinnerService: SpinnerService
    ) {
    }

    /**
     * Get threepls list
     * @returns {Promise<any>}
     */
    private async getThreePLsList(): Promise<any> {
        if (!this.userService.validatePermissions(["browse_threepls"])) {
            return;
        }
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["threepl", "all"]);

        if (data) {
            const exIds: number[] = this.user.threepls.map((threepl: User.IThreepl): number => {
                return threepl.id;
            });
            this.threeplsList = data.filter((threepl: User.IThreepl): boolean => {
                return exIds.indexOf(threepl.id) < 0;
            });
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get partners list
     * @returns {Promise<any>}
     */
    private async getPartnersList(): Promise<any> {
        if (!this.userService.validatePermissions(["browse_admin"])) {
            return;
        }
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["partner"]);

        if (data) {
            const exIds: number[] = this.user.partners.map((partner: User.IPartner): number => {
                return partner.id;
            });
            this.partnersList = data.filter((partner: User.IPartner): boolean => {
                return exIds.indexOf(partner.id) < 0;
            });
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Prepare list/table of user requests
     * @returns {void}
     */
    private requestsList(): void {
        const columns: Table.ICol[] = RequestsListComponent.getColumns(this.state, this.router);

        const api: any = {
            url: ["request"],
            query: {
                created_by: this.user.id
            }
        };

        columns.splice(0, 0, {
            data: "favorite",
            title: "",
            render: (data: any[]): string => {
                return `
                        <button data-action="star" type="button"
                            class="mat-mdc-mini-fab mdc-fab ${data && data.length > 0 ? "mat-accent" : ""}"
                            title="Favorite">
                            <mat-icon class="mat-icon material-icons">star</mat-icon>
                        </button>
                        `;
            },
            click: async (row: any): Promise<any> => {
                this.spinnerService.show();
                const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
                    ["request", "" + row.id, "favorite"]);
                this.spinnerService.hide();

                if (type as string === "success") {
                    this.toastService.show(message, "success");
                    this.requestsTableRef.reload(api);
                }
            }
        });

        this.requestsListTable = {
            table_id: "80xfI1Rp",
            api,
            columns
        };
    }

    /**
     * Get user orders list
     * @param {number} page
     * @param {string} search
     * @param per_page
     * @returns {Promise<any>}
     */
    public async getUserOrders(page: number = 1, search: string = null, per_page: number = null): Promise<any> {
        this.spinnerService.show();
        if (per_page === null) {
            per_page = this.userService.data.settings.default_per_page;
        }
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["order"], {}, {
                filter_by_user: this.user.id,
                data_structure: "paginated",
                page,
                per_page,
                search_by: search
            });

        if (data) {
            this.userOrders = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();

    }

    /**
     * Get user remarks
     * @param {number} page
     * @param {string} search
     * @param per_page
     * @returns {Promise<any>}
     */
    public async getUserRemarks(page: number = 1, search: string = null, per_page: number = null): Promise<any> {
        this.spinnerService.show();
        if (per_page === null) {
            per_page = this.userService.data.settings.default_per_page;
        }
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["remark", "order"], {}, {
                filter_by_user: this.user.id,
                data_structure: "paginated",
                page,
                per_page,
                search_by: search
            });

        if (data) {
            this.remarks = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();

    }

    public async ngOnInit(): Promise<any> {

        await this.userService.getUser();
        this.user = this.userService.data;

        this.getUserOrders();
        this.getUserRemarks();
        this.getThreePLsList();
        this.getPartnersList();

        this.requestsList();

        this.orderSearch.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: string): void => {
                if (this.user) {
                    this.getUserOrders(1, value);
                }
            });

        this.remarkSearch.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: string): void => {
                if (this.user) {
                    this.getUserRemarks(1, value);
                }
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "profile",
            actions: {
                "browse": "*"
            }
        };
    }

}
