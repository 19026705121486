import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, ViewEncapsulation} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../common/interfaces/wizard.interface";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {HelpersService} from "../../../../../../../common/services/helpers.service";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {Modal, ModalService} from "../../../../../../services/modal.service";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {debounceTime, takeUntil} from "rxjs/operators";
import {Warehouse} from "../../../../../../../common/interfaces/warehouse.interface";
import {ReplaySubject} from "rxjs";
import {MatChipInputEvent} from "@angular/material/chips";
import {COMMA, ENTER, SEMICOLON, TAB} from "@angular/cdk/keycodes";
import {WarehouseLocationFormComponent} from "../../../../../threepl/warehouse/locations";
import {Inventory} from "../../../../../../../common/interfaces/Inventory.interface";

@Component({
    selector: "section-partner-procedures-wizard-add-serial",
    templateUrl: "add-serial.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class PartnerProceduresWizardAddSerialComponent extends AbstractWizardStepComponent implements OnDestroy {

    private state: Base.IState;

    public readonly separatorKeysCodes: any[] = [ENTER, COMMA, SEMICOLON, TAB];

    public hubs: { group: string, hubs: Warehouse.IHub[] }[] = [];

    public form: FormGroup = new FormGroup({
        inventory_conversion_id: new FormControl(null, [Validators.required]),
        part_master_id: new FormControl(null, [Validators.required]),
        location_id: new FormControl(null, [Validators.required]),
        serials: new FormControl([], [Validators.required]),
        remark: new FormControl(null, [Validators.required]),
        batch: new FormControl(null),
    });

    public part_masters: any[];

    public serials: string[] = [];

    public locations: any[];

    public filtered_locations: ReplaySubject<any> = new ReplaySubject<any>(1);

    public location_search: FormControl = new FormControl(null);

    public part_info: Inventory.IPartMaster;

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        public helperService: HelpersService,
        private apiService: ApiService,
        private toastService: ToastService,
        private modalService: ModalService,
        private spinnerService: SpinnerService
    ) {
        super(changeDetectorRef);
    }


    private async getHubLocations(id: number): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["hub", "" + id, "locations"],
            {}, {
                data_structure: "select",
            });
        if (data) {
            this.locations = data;
            this.filtered_locations.next(this.locations.slice(0, 20));
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private async getPartMasters(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["partmaster"], {},
            {
                data_structure: "select",
                no_serial: false
            });
        if (data) {
            this.part_masters = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private async getPartMasterInfo(id: number): Promise<any> {
        this.part_info = null;

        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["partmaster", "id", "" + id]);
        this.spinnerService.hide();

        if (data && data[0]) {
            this.part_info = data[0];
        }
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Add serial chip
     * @param {MatChipInputEvent} event
     */
    public async addSerialChip(event: MatChipInputEvent): Promise<any> {
        this.spinnerService.show();
        const value: string = event.value ? event.value.trim() : null;

        if (event.input) {
            event.input.value = "";
            this.changeDetectorRef.markForCheck();
        }

        if (value) {
            const values: string[] = value.split(/[\s|\t|;|,]/);
            for (const val of values) {
                if (val && !this.form.get("serials").value?.includes(val)) {
                    this.serials.push(val);
                    this.form.get("serials").setValue(this.serials);
                }
            }
        }
        this.spinnerService.hide();
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Remove serial chip
     * @param serial
     */
    public removeSerialChip(serial: string): void {
        const index: number = this.serials.indexOf(serial);
        if (index >= 0) {
            this.serials.splice(index, 1);
            this.form.get("serial").setValue(this.serials);
        }
        this.changeDetectorRef.markForCheck();
    }


    public async addLocation(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(WarehouseLocationFormComponent, {
            inventory_conversion_id: this.form.value.inventory_conversion_id
        });

        if (response && response.value) {
            this.getHubLocations(this.form.value.inventory_conversion_id);
            this.form.get("location_id").setValue(response.value.id);
        }
    }

    /**
     * Submit form
     */
    public async submit(): Promise<any> {
        this.spinnerService.show();
        const {type, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["inventory", "serial-add"], this.form.value);
        if (type as string === "success") {
            this.toastService.show(message, "success");
            this.form.reset();
            this.serials = [];
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Initialize step
     * @param data
     * @returns {Promise<any>}
     */
    public async init(data: Wizard.IData): Promise<any> {
        this.state = data.state;

        this.result.emit({
            action: "result",
            value: true
        });

        this.getPartMasters();

        this.form.get("inventory_conversion_id").valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((id: number): void => {
                if (id) {
                    this.getHubLocations(id);
                }
            });

        this.form.get("part_master_id").valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((id: number): void => {
                if (id) {
                    this.getPartMasterInfo(id);
                }
            });

        this.location_search.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: string): void => {
                if (value) {
                    this.filtered_locations.next(this.locations.filter((location: any): boolean => {
                        return location.name.toLowerCase().includes(value.toLowerCase());
                    }));
                } else {
                    let slice = this.locations.slice(0, 20);
                    if (this.form.get("location_id").value) {
                        const selected = this.locations.find((location: any) => {
                            return Number(location.value) === this.form.get("location_id").value;
                        });
                        slice.push(selected);
                    }
                    this.filtered_locations.next(slice);
                }
                this.changeDetectorRef.markForCheck();
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
