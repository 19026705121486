import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {ConfirmComponent} from "../../../../../../../common/components/confirm/confirm.component";
import {RemarksSidebarComponent} from "../../../order";
import {Table2Component} from "../../../../../../../common/components/table2";
import {DashboardCountWidgetComponent} from "../../index";
import {FormControl} from "@angular/forms";
import {IPagination} from "../../../../../../../common/components/pagination/pagination.component";
import {Order} from "../../../../../../../common/interfaces/order.interface";
import {Table} from "../../../../../../../common/interfaces/table.interface";
import {IActivity} from "../../../../../../../common/interfaces/activity.interface";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {Router} from "@angular/router";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {UserService} from "../../../../../../../common/services/user.service";
import {takeUntil} from "rxjs/operators";
import {RequestsListComponent} from "../../../requests";
import * as _moment from "moment";
import {UcFirstPipe} from "../../../../../../../common/pipes/ucfirst.pipe";
import {HelpersService} from "../../../../../../../common/services/helpers.service";
import {AmplitudeService} from "../../../../../../../common/services/amplitude.service";


const moment: any = _moment;

export const MY_FORMATS: any = {
    parse: {
        dateInput: "MM/YYYY",
    },
    display: {
        dateInput: "MM/YYYY",
        monthYearLabel: "MMM YYYY",
        dateA11yLabel: "LL",
        monthYearA11yLabel: "MMMM YYYY",
    },
};


@Component({
    selector: "section-dashboard-template-default",
    templateUrl: "default.component.html",
    styleUrls: ["default.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})

export class DashboardDefaultComponent implements OnInit, OnDestroy {
    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild(ConfirmComponent, {static: true})
    public confirmRef: ConfirmComponent;

    @ViewChild("sidebar", {static: true})
    public orderSidebar: RemarksSidebarComponent;

    @ViewChild("favoriteRequests", {static: false})
    public favoriteTableRef: Table2Component;

    @ViewChild("exceptionTracks", {static: false})
    public exceptionTracksTableRef: Table2Component;

    @ViewChild("shipmentsCountWidget", {static: false})
    public shipmentsCountWidgetRef: DashboardCountWidgetComponent;

    @ViewChild("milestonesTableRef", {static: false})
    public milestonesTableRef: Table2Component;


    public inboundsTab: any = {
        isOpen: false,
        orders: {
            open: {
                count: 0,
                topCustomers: {}
            },
            closed: {
                count: 0,
                topCustomers: {}
            }
        },
        order_items: {
            topCount: {},
            topValue: {},
            count: 0,
            total: 0,
            currency: "USD"
        },
        isLoaded: false
    };

    public milestonesTab: any = {
        isOpen: false
    };


    @Input()
    public state: Base.IState;

    /*
     * Calendar data
     */
    public calendarSize: number[] = [];

    /**
     * Dashboard type to show
     * @type {string}
     */
    public dashType: string = "admin";

    public remarks: IPagination<any>;

    public ordersNotConfirmed: IPagination<Order.IOrderData>;


    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public favoriteRequestsListTable: Table.ISettings;

    public exceptionTracksTable: Table.ISettings;

    public showOpenRequests: FormControl = new FormControl(true);

    public statsViewType: FormControl = new FormControl("quantity");

    public settings: any = {};

    public activities: IActivity[];

    public constructor(
        private apiService: ApiService,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private toastService: ToastService,
        public userService: UserService,
        public helpersService: HelpersService,
    ) {
    }

    private async getRoles(): Promise<any> {
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["user", "roles"]);

        if (data && data[0] && data[0].settings && data[0].settings.dashboard) {
            this.settings = data[0].settings.dashboard;

            if (this.settings.awaitingConfirmationOrdersCount) {
                this.getOrdersNotConfirmed(1, Number(this.settings.awaitingConfirmationOrdersCount));
            }
            if (this.settings.latestRemarksCount) {
                this.getRemarks(1, Number(this.settings.latestRemarksCount));
            }
            if (this.settings.favoriteRequestsCount) {
                this.prepareFavoriteList(Number(this.settings.favoriteRequestsCount));
            }
            if (this.settings.lastActivitiesCount) {
                this.lastActivities(this.settings.lastActivitiesCount);
            }

            if (this.settings.exceptionTracksCount) {
                this.prepareExceptionTracksList(this.settings.exceptionTracksCount);
            }
        }
    }

    /**
     * Prepare list/table of favorite requests
     * @returns {void}
     */
    private prepareFavoriteList(per_page: number = 5): void {
        this.showOpenRequests.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((): void => {
                this.favoriteTableRef.reload({
                    url: ["request"],
                    query: {
                        only_open: this.showOpenRequests.value,
                        only_favorite: true,
                        per_page
                    }
                });
            });

        const columns: Table.ICol[] = RequestsListComponent.getColumns(this.state, this.router);

        columns.splice(0, 0, {
            data: "title",
            title: "Favorite",
            render: (row: any): string => {
                return `<button class="mat-mdc-mini-fab mdc-fab ${row.favorite.length ? "mat-accent" : ""}">
                            <mat-icon class="mat-icon material-icons">star</mat-icon>
                        </button>`;
            },
            click: async (row: any): Promise<any> => {
                const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
                    ["request", "" + row.id, "favorite"]);

                if (type as string === "success") {
                    this.toastService.show(message, "success");
                    this.favoriteTableRef.reload({
                        url: ["request"],
                        query: {
                            only_open: this.showOpenRequests.value,
                            only_favorite: true
                        }
                    });
                }
            }
        });

        if (this.state.section === "admin") {
            columns.splice(1, 0, {
                data: "partner.icon_path",
                title: "Partner",
                render: (row: any): string => {
                    return row.partner ? "<img src='" + row.partner.icon_path + "' alt=''>" : "";
                }
            });
        }

        this.favoriteRequestsListTable = {
            table_id: "2Sjs20oS",
            api: {
                url: ["request"],
                query: {
                    only_open: this.showOpenRequests.value,
                    only_favorite: true,
                    only_my: true,
                    per_page,
                    with_count: [
                        "OrderRemarks"
                    ]
                }
            },
            columns
        };
    }

    /**
     * Prepare list/table of tracks with status exception
     * @returns {void}
     */
    private prepareExceptionTracksList(per_page: number = 5): void {

        this.exceptionTracksTable = {
            table_id: "GA91yl8h1Xd",
            api: {
                url: ["courier", "transactions"],
                query: {
                    per_page,
                    statuses: [
                        "exception"
                    ],
                    with_transactions_checkpoints: true
                }
            },
            columns: [
                {
                    data: "status",
                    title: "",
                    sortable: false,
                    render: (row: any): string => {
                        return "<mat-icon class='track-status mat-icon material-icons text-error "
                            + row.status + "'>error</mat-icon> ";
                    }
                },
                {
                    data: "status",
                    title: "Status",
                    sortable: false,
                    render: (row: any): string => {
                        let str: string = "";
                        if (row.courier_transaction_checkpoints
                            && row.courier_transaction_checkpoints[0]
                            && row.courier_transaction_checkpoints[0].checkpoints) {
                            const cp: any[] = row.courier_transaction_checkpoints[0].checkpoints;
                            str += cp[cp.length - 1].message;
                        } else {
                            str += row.status;
                        }
                        return "<div style='width:250px; white-space:normal; line-height:1.2'>"
                            + str + "</div>";
                    }
                },
                {
                    data: "order.ref",
                    name: "Order.ref",
                    title: "Order",
                    sortable: false,
                    cssClass: "text-underline pointer",
                    click: (row: any): void => {
                        this.router.navigate([
                            "/partner", row.partner.slug, "orders", "view", "id", row.order.id
                        ]);
                    }
                },
                {
                    data: "tracking_number",
                    title: "Tracking number",
                },
                {
                    data: "courier_service.service_name",
                    name: "CourierService.service_name",
                    title: "Courier Service",
                    sortable: false,
                },
                {
                    data: "shipment.from_address.country",
                    name: "Shipment.FromAddress.Country",
                    title: "Origin",
                    sortable: false,
                },
                {
                    data: "shipment.to_address.country",
                    name: "Shipment.ToAddress.Country",
                    title: "Destination",
                    sortable: false,
                },
                {
                    data: "updated",
                    name: "updated_at",
                    title: "Updated at",
                    searchable: false
                },
                {
                    data: "order.created_at",
                    title: "Order created at",
                    sortable: false,
                    searchable: false

                }
            ]
        };
    }


    public async getRemarks(page: number = 1, per_page: number = null): Promise<any> {
        if (per_page === null) {
            per_page = this.userService.data.settings.default_per_page;
        }
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["remark", "order"], {}, {data_structure: "paginated", page, per_page});
        if (data.data) {
            this.remarks = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    public async lastActivities(per_page: number = 3): Promise<any> {
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["activities", "unread"], {}, {
                data_structure: "paginated",
                page: 1,
                per_page

            });
        if (data) {
            this.activities = data.data;
        }
        this.changeDetectorRef.markForCheck();
    }

    public navigateTo(url: string[]): void {
        this.router.navigate(url);
    }

    public async getOrdersNotConfirmed(page: number = 1, per_page: number = null): Promise<any> {
        if (per_page === null) {
            per_page = this.userService.data.settings.default_per_page;
        }
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["order"], {}, {
                data_structure: "paginated",
                per_page,
                confirmed: false,
                only_active: true,
                page
            });
        if (data.data) {
            this.ordersNotConfirmed = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    public goToOrder(order: Order.IOrderData): void {
        this.router.navigate([
            "/partner",
            order.partner.slug,
            "orders",
            "view",
            "id",
            order.id
        ]);
    }


    public async getMilestones(): Promise<any> {
        this.milestonesTab.listTable = {
            api: {
                url: ["milestones"]
            },
            per_page: 5,
            columns: [
                {
                    data: "milestone_type.name",
                    name: "MilestoneType.name",
                    title: "Name"
                },
                {
                    data: "notes",
                    name: "notes",
                    title: "Notes",
                },
                {
                    data: "estimated_date",
                    name: "estimated_date",
                    title: "Estimated date"
                },
                {
                    data: "complete_date",
                    name: "complete_date",
                    title: "Complete date"
                },
                {
                    data: "notified_at",
                    name: "notified_at",
                    title: "Notified at",
                },
                {
                    data: "order_ref",
                    name: "order_ref",
                    title: "Order ref",
                    render: (row: any): string => {
                        return `
                 <button class="mat-mdc-raised-button mdc-button button-200">${row.order_ref || ""}</button>
            `;
                    },
                    click: (row: any): void => {
                        this.router.navigate([
                            this.state.section,
                            "orders",
                            "view",
                            "id",
                            row.order_id
                        ]);
                    },
                    sortable: false,
                    searchable: false
                },
            ]
        };
        this.changeDetectorRef.markForCheck();
    }

    public async getDefectiveReturnsOrders(status: any): Promise<any> {
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["dashboard", "orders_defective_returns"], {}, {
                status: UcFirstPipe.transform(status),
                sort_by: status === "closed" ? "closed_on" : "sum_declared_value_ok"
            });
        if (data) {
            this.inboundsTab.orders[status].data = data.orders_value;
            this.inboundsTab.orders[status].count = data.orders_count;
            const topCustomersPie: any[] = [];
            const pieChart: any[] = [];
            topCustomersPie[0] = [
                "Customer", this.inboundsTab.order_items && this.inboundsTab.order_items.currency
                    ? this.inboundsTab.order_items.currency : "$"
            ];
            pieChart[0] = [
                "Order Ref",
                this.inboundsTab.order_items && this.inboundsTab.order_items.currency
                    ? this.inboundsTab.order_items.currency : "$"
            ];
            data.orders_value.forEach((order: any): void => {
                pieChart.push([order.ref, parseFloat(order.sum_declared_value_ok)]);
            });
            data.top_customers.forEach((customer: any): void => {
                topCustomersPie.push([customer.name, parseFloat(customer.sum_declared_value_ok)]);
            });
            this.inboundsTab.orders[status].pie = pieChart;
            this.inboundsTab.orders[status].topCustomers.pie = topCustomersPie;
            this.inboundsTab.isLoaded = true;
            this.changeDetectorRef.markForCheck();
        }
    }

    public async getAllDefectiveReturnsOrders(): Promise<any> {
        ["open", "closed"].forEach((val: string): void => {
            this.getDefectiveReturnsOrders(val);
        });
    }

    public async getDefectiveReturnsItems(): Promise<any> {
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["dashboard", "items_defective_returns"], {}, {});
        if (data) {
            this.inboundsTab.order_items.count = data.count;
            this.inboundsTab.order_items.total = data.total;
            this.inboundsTab.order_items.currency = data.currency;
            if (data.items) {
                this.inboundsTab.order_items.data = data.items;
                const totalByDaysObj: any = {};
                const defectiveItemsCount: any = {};
                const defectiveItemsValue: any = {};
                data.items.forEach((item: any): void => {
                    const created_at: any = moment(item.created_at).format("DD MMM YYYY");
                    const item_value: number = item.part_master
                        ? parseFloat(item.part_master.declared_value) * parseInt(item.quantity) : 0;
                    totalByDaysObj[created_at] =
                        totalByDaysObj[created_at]
                            ? totalByDaysObj[created_at] + item_value : item_value;
                    defectiveItemsValue[item.item] = defectiveItemsValue[item.item]
                        ? defectiveItemsValue[item.item] + item_value : item_value;
                    defectiveItemsCount[item.item] = defectiveItemsCount[item.item]
                        ? defectiveItemsCount[item.item] + 1 : 1;
                });
                const sortedDefectiveItemsCount: any[] = [];
                for (const defectiveItem in defectiveItemsCount) {
                    if (defectiveItemsCount.hasOwnProperty(defectiveItem)) {
                        sortedDefectiveItemsCount.push([defectiveItem, defectiveItemsCount[defectiveItem]]);
                    }
                }
                sortedDefectiveItemsCount.sort((a: number, b: number): number => {
                    return b[1] - a[1];
                });
                const sortedDefectiveItemsValue: any[] = [];
                for (const defectiveItem in defectiveItemsValue) {
                    if (defectiveItemsValue.hasOwnProperty(defectiveItem)) {
                        sortedDefectiveItemsValue.push([defectiveItem, defectiveItemsValue[defectiveItem]]);
                    }
                }
                sortedDefectiveItemsValue.sort((a: number, b: number): number => {
                    return b[1] - a[1];
                });
                const topCountArray: any = sortedDefectiveItemsCount.slice(0, 10);
                const topValueArray: any = sortedDefectiveItemsValue.slice(0, 10);
                this.inboundsTab.order_items.topCount.pie =
                    [["Item", "Items"], ...topCountArray];
                this.inboundsTab.order_items.topValue.pie =
                    [["Item", this.inboundsTab.order_items.currency], ...topValueArray];
                const totalByDaysArr: any = Object.keys(totalByDaysObj).map(function (key: any): any {
                    return [moment(key).format("DD MMM"), totalByDaysObj[key]];
                });
                this.inboundsTab.order_items.byDays = [["Date", "Total"]].concat(totalByDaysArr);
            }
            this.changeDetectorRef.markForCheck();
        }
    }


    /**
     * Confirm order
     * @returns {Promise<any>}
     */
    public async confirmOrder(ref: string): Promise<any> {
        if (await this.confirmRef.confirm(`Do you want to confirm current order?`)) {
            const url: string[] = ["order", ref, "confirm"];
            const response: Api.IResponse = await this.apiService.request(Api.EMethod.Post, url);
            if (response.type as string === "success") {
                this.getOrdersNotConfirmed();
            }
        }
    }

    /**
     * Show remarks in sidebar
     * @param id
     * @param ref
     */
    public showOrderSidebar(id: number, ref: string): void {
        this.orderSidebar.show(id, ref, true);
    }

    public amplitudeClick(message: string): void {
        AmplitudeService.eventClick(message);
    }

    public ngOnInit(): void {
        if (this.state.section.includes("partner")) {
            const [type, slug]: string[] = this.state.section.split("/");
            this.dashType = type;

            this.getDefectiveReturnsOrders("open");
            this.getDefectiveReturnsOrders("closed");
            // this.getDefectiveReturnsItems();
            // this.getFulfilmentOrdersCount();
            this.getMilestones();
        }

        if (this.dashType === "threepl") {
            this.router.navigate([this.state.section, "warehouses"]);
            return;
        }

        this.statsViewType.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any[]): void => {
            this.changeDetectorRef.markForCheck();
        });

        this.getRoles();
    }

    public ngOnDestroy(): void {
        setTimeout(() => {
            this.destroy$.next(true);
            this.destroy$.unsubscribe();
        }, 1000);
    }

}
