import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {MatMenu} from "@angular/material/menu";
import {ConfirmComponent} from "../../../common/components/confirm/confirm.component";
import {LoginService} from "../../../common/services/login.service";
import {UserService} from "../../../common/services/user.service";
import {User} from "../../../common/interfaces/user.interface";
import {FormControl} from "@angular/forms";
import {NavigationEnd, Router} from "@angular/router";
import {Api, ApiService} from "../../../common/services/api.service";
import {debounceTime, filter, takeUntil} from "rxjs/operators";
import {Order} from "../../../common/interfaces/order.interface";
import {NotificationService} from "../../../common/services/notification.service";
import {Push} from "../../../common/interfaces/push.interface";
import {Modal, ModalService} from "../../../section/services/modal.service";
import {ModalComponent} from "../../../common/components/modal/modal.component";
import {AttachmentsListComponent} from "../../../section/components/common/components/attachments";
import {SpinnerService} from "../../../common/services/spinner.service";
import {SupervisorModalComponent} from "./supervisor-modal/supervisor-modal.component";
import {Form} from "../../../common/interfaces/form.interface";
import {ToastService} from "../../../common/services/toast.service";
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {HelpersService} from "../../../common/services/helpers.service";
import {SoundsService} from "../../../common/services/sounds.service";
import {AmplitudeService} from "../../../common/services/amplitude.service";
import {AppStateService} from "../../../common/services/app-state.service";
import {Api3Service} from "../../../common/services/api3.service";
import {Base} from "../../../common/interfaces/base.interfaces";
import {RequestFormComponent} from "src/modules/section/components/common/components/requests";
import {StorageService} from "src/modules/common/services/storage.service";
import {MediaScreenService} from "src/modules/common/services/media-screen.service";
import {CommonFormComponent} from "../../../common/components/form";
import {Warehouse} from "../../../common/interfaces/warehouse.interface";
import ISelectOption = Form.ISelectOption;


interface FsDocument extends HTMLDocument {
    mozFullScreenElement?: Element;
    msFullscreenElement?: Element;
    msExitFullscreen?: () => void;
    mozCancelFullScreen?: () => void;
}

interface FsDocumentElement extends HTMLElement {
    msRequestFullscreen?: () => void;
    mozRequestFullScreen?: () => void;
}

@Component({
    selector: "app-toolbar",
    templateUrl: "toolbar.component.html",
    styleUrls: [
        "toolbar.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent implements OnInit, OnDestroy {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    private canSelectSupervisor: boolean = false;

    private managers: ISelectOption[] = [];

    private threepl: User.IThreepl;

    private media_interval: any;

    public media_is_recording: boolean = false;

    public partner: User.IPartner;

    public warehouse: Warehouse.IWarehouse;

    public state: Base.IState;

    @Input()
    public section: string;

    @Input()
    public livechat: boolean;

    @Input()
    public type: string;

    @Input()
    public theme: string;

    @Output()
    public openLivechat: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild("menuRef", {static: false})
    public menuRef: MatMenu;

    @ViewChild("menuTrigger", {static: false})
    public menuTrigger: ElementRef;

    @ViewChild(MatAutocompleteTrigger, {static: false})
    public autocompleteTrigger: MatAutocompleteTrigger;

    /**
     * User email
     * @type {string}
     */
    public email: string;

    /**
     * User name
     * @type {string}
     */
    public name: string;

    /**
     * Toolbar title
     * @type {string}
     */
    public title: string;

    /**
     * Menu width
     * @type {string}
     */
    public width: string;

    /**
     * Partner orders list
     * @type {any[]}
     */
    public orders: Order.IOrderData[] = [];

    public orderSearch: FormControl = new FormControl([null]);

    public searching: boolean = false;

    public noOrders: boolean = false;

    public notificationsCount: number = 0;
    public notifications: Push.IData[] = [];

    public jobTypesIcons: { [key: string]: string } = {
        "InventoryUploadCsvJob": "cloud_upload",
        "WarehouseTransactionUploadCsvJob": "cloud_upload",
        "ConfirmOrderJob": "dvr",
        "JobAdded": "track_changes",
        "WarehouseTransactionsForPartner": "arrow_downward",
        "OrderItemsWithoutWarehouseTransactions": "arrow_downward",
        "ActivityNotification": "notification_important"
    };

    public isFullScreen: boolean = false;

    public requestsCount: number = 0;

    public constructor(
        private loginService: LoginService,
        private userService: UserService,
        private router: Router,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private changeDetectorRef: ChangeDetectorRef,
        private notificationService: NotificationService,
        private modalService: ModalService,
        private toastService: ToastService,
        private spinnerService: SpinnerService,
        private amplitudeService: AmplitudeService,
        public soundsService: SoundsService,
        public storageService: StorageService,
        private mediaService: MediaScreenService,
    ) {
    }

    private getPartner(): void {
        this.partner = this.userService.data.partners
            .find((item: User.IPartner): boolean => {
                return item.slug === this.section;
            });
    }

    private getThreepl(): void {
        this.threepl = this.userService.data.threepls
            .find((item: User.IThreepl): boolean => {
                return item.slug === this.section;
            });
    }

    private getWarehouse(): void {
        this.api3Service.get(this.state.section, {
            relations: [
                "ContactOnShift"
            ]
        }).then((res: Api.IResponse) => {
            this.warehouse = res.data;
            this.changeDetectorRef.markForCheck();
        });
    }

    /**
     * Get toolbar title (by selected section)
     * @returns {string}
     */
    private getTitle(): string {
        if (this.section === "admin") {
            return "Administrator";
        }
        if (this.type === "partner") {
            return (this.partner) ? this.partner.display_name : "";
        } else if (this.type === "threepl") {
            return (this.threepl) ? this.threepl.display_name : "";
        }
    }

    /**
     * Get width for dropdown menu
     * @returns {string}
     */
    private getWidth(): string {
        const width: string = this.menuTrigger?.nativeElement.clientWidth;
        return width ? width + "px" : null;
    }


    private async getOrders(search: string): Promise<any> {
        this.searching = true;
        this.changeDetectorRef.markForCheck();

        const search_in: string[] = [
            "ref",
            "ref2",
            "ServiceRequest.ref",
        ];

        if (this.partner && [55, 60].includes(this.partner.id)) {
            search_in.push("custom_fields->SH", "custom_fields->SO");
        }


        const {data}: Api.IResponse = await this.api3Service.get(`${this.state.section}/orders`, {
            search_by: search,
            search_in,
            search_like: true,
            limit: 5,
            relations: [
                "Partner:id,slug",
                "ServiceLevel:id,order_type_id",
                "ServiceLevel.orderType:id,slug"
            ],
            is_draft: false,
            sort: {
                "data": "id",
                "dir": "desc"
            }
        });

        if (data) {
            this.orders = data;
            this.noOrders = this.orders.length === 0;
            this.autocompleteTrigger.openPanel();
        }
        this.searching = false;
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Check is page full screen
     */
    private fullScreenCheck(): void {
        const fsDoc: FsDocument = document as FsDocument;

        const full_screen_element: any =
            (fsDoc.mozFullScreenElement || fsDoc.msFullscreenElement || null);

        this.isFullScreen = full_screen_element !== null;
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Get assigned users
     * @returns {Promise<any>}
     */
    private async getPartnerManagers(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["partner", "managers"], {}, {data_structure: "select"});
        this.spinnerService.hide();

        if (data) {
            this.canSelectSupervisor = data.some((user: ISelectOption): boolean => {
                return user.value === this.userService.data.id;
            });

            this.managers = data;
        }
    }


    private async getOrderRequestsCount(id: number): Promise<any> {
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["request"], {}, {
                data_structure: "count",
                order_id: id
            });
        if (data && data[0]) {
            this.requestsCount = data[0];
            this.changeDetectorRef.markForCheck();
        }
    }

    private checkMediaRecordState(): void {
        this.media_interval = setInterval(() => {
            this.media_is_recording = MediaScreenService.recordState;
            this.changeDetectorRef.markForCheck();
        }, 2000);
    }

    /**
     * Redirect to order on dropdown selected option
     * @param {MatAutocompleteSelectedEvent} $event
     */
    public onOptionSelected($event: MatAutocompleteSelectedEvent): void {
        const [partner, id, type]: string[] = $event.option.value.split("::");
        this.router.navigate([
            "/partner", partner, "orders", "view", "id", id, "type", type
        ]);
        this.orderSearch.setValue("");
    }


    /**
     * Logout
     * @returns {Promise<any>}
     */
    public async logout(): Promise<any> {
        if (await this.confirmRef.confirm("Do you want to log out?")) {
            this.loginService.logout();
        }
    }

    /**
     * Get notifications list
     */
    public getNotifications(): void {
        this.notifications = this.notificationService.getNotifications();
        this.changeDetectorRef.markForCheck();
    }

    public redirectTo(url: string): void {
        if (url && url.indexOf("http") !== -1) {
            window.location.href = url;
        } else if (url && url.indexOf("http") === -1) {
            const redUrl: string[] = url.split("/");
            if (this.section === "admin") {
                redUrl.unshift("/admin");
            } else {
                redUrl.unshift("/" + this.type, this.section);
            }
            this.router.navigate(redUrl);
        }
    }

    /**
     * Set notifications read
     */
    public markNotificationsAsRead(): void {
        this.notificationService.markAsRead();
    }

    /**
     * Clear all notifications
     */
    public clearNotifications(): void {
        this.notificationService.clear();
        this.notifications = [];
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Show notifications errors in modal
     * @param {Push.IData} notification
     * @return {Promise<any>}
     */
    public async notificationDetails(notification: Push.IData): Promise<any> {
        let template: string = "";
        for (const msg of notification.message_Detailed) {
            template += "<p>" + msg + "</p>";
        }

        await this.modalService.open(ModalComponent, {
            title: notification.message_type === "error" ? "Errors" : "Info",
            template
        });
    }

    /**
     * Set app to full screen mode
     */
    public fullScreenOn(): void {
        const elem: FsDocumentElement = document.querySelector("html");
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
        } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
        }
        this.isFullScreen = true;
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Collapse app from full screen mode
     */
    public fullScreenOff(): void {

        const fsDoc: FsDocument = document as FsDocument;

        if (fsDoc.exitFullscreen) {
            fsDoc.exitFullscreen();
        } else if (fsDoc.mozCancelFullScreen) {
            fsDoc.mozCancelFullScreen();
        } else if (fsDoc.msExitFullscreen) {
            fsDoc.msExitFullscreen();
        }
        this.isFullScreen = false;
        this.changeDetectorRef.markForCheck();
    }

    public async showAttachments(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(AttachmentsListComponent, {
            url: ["user", "attachments"],
            title: "My files"
        });
    }

    public async setMeOnShift(): Promise<any> {

        if (!this.userService.validatePermissions("can_take_shift")) {
            return;
        }
        if (!await this.confirmRef.confirm("Do you want to take shift on this partner?")) {
            return;
        }
        this.spinnerService.show();
        const {code}: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["partner", "take-shift"]);
        this.spinnerService.hide();
        if (code === 200) {
            this.userService.getUser().then((): void => {
                this.getPartner();
                this.changeDetectorRef.markForCheck();
            });
        }
    }

    public canSeeShift(): boolean {
        return this.partner && this.userService.validatePermissions(["can_see_shift"]);
    }

    public showActivities(): void {
        if (this.type !== "admin") {
            this.router.navigate([
                this.type,
                this.section,
                "activities"
            ]);
        } else {
            this.router.navigate([
                "admin",
                "activities"
            ]);
        }
    }

    public async selectSupervisor(): Promise<any> {

        await this.getPartnerManagers();

        if (!this.canSelectSupervisor) {
            this.toastService.show("You are not permitted to change supervising manager", "warning");
            return;
        }

        const response: Modal.IResponse = await this.modalService.open(SupervisorModalComponent, {
            managers: this.managers,
            partner: this.partner
        });

        if (response) {
            this.userService.getUser().then((): void => {
                this.partner = this.userService.data.partners
                    .find((item: User.IPartner): boolean => {
                        return item.slug === this.section;
                    });
                this.changeDetectorRef.markForCheck();
            });
        }
    }

    public isExternalUrl(url: string): boolean {
        return HelpersService.isExternalUrl(url);
    }

    public openRequestForm(): void {
        this.modalService.open(RequestFormComponent);
    }

    public goToRequests(): void {
        this.router.navigate([
            this.type,
            this.section,
            "support-requests-all",
            "browse",
            "order",
            this.state.params.id
        ]);
    }

    public async makeScreenshot(): Promise<any> {
        const path: { name: string, url: string } = await this.mediaService.makeScreenshot();

        if (!path) {
            this.toastService.show("Media recording failed", "error");
            return;
        }

        this.modalService.open(RequestFormComponent, {
            data: {
                uploadedFile: [
                    path
                ]
            },
            itForm: true
        });
    }

    public async startVideoCapture(): Promise<any> {

        const path: { name: string, url: string } = await this.mediaService.startScreenRecord();

        if (!path) {
            this.toastService.show("Media recording failed", "error");
            return;
        }

        this.modalService.open(RequestFormComponent, {
            data: {
                uploadedFile: [
                    path
                ]
            },
            itForm: true
        });
    }

    public stopVideoCapture(): void {
        this.mediaService.stopScreenRecord();
    }

    public async selectWarehouseContact(): Promise<any> {
        const res: Modal.IResponse = await this.modalService.open(CommonFormComponent, {
            formConfig: {
                id: 0,
                name: "Select on shift contact",
                description: "",
                fields: [
                    {
                        label: "Select contact",
                        name: "contact_id",
                        size: "full",
                        type: "select",
                        required: true,
                        url3: "contacts"
                    },
                ]
            },
        });

        if (!res?.value?.contact_id) {
            return;
        }

        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.api3Service.post(
            `${this.state.section}/contacts/set-shift-contact`, {
                contact_id: res.value.contact_id
            });

        this.spinnerService.hide();

        if (code === 200) {
            this.toastService.show(message, "success");
            this.getWarehouse();
        }
    }

    public ngOnInit(): void {
        this.email = this.userService.data.email;
        this.name = this.userService.data.name;


        setTimeout(() => {
            this.state = AppStateService.getState();

            if (this.state.section_type === "warehouse") {
                this.getWarehouse();
            } else if (this.state.section_type === "partner") {
                this.getPartner();
            } else if (this.state.section_type === "threepl") {
                this.getThreepl();
            }

            this.title = this.getTitle();

            this.changeDetectorRef.markForCheck();
        });


        Promise.resolve(null).then((): void => {
            this.width = this.getWidth();
        });

        this.orderSearch.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: string): void => {
                if (value.trim()) {
                    this.getOrders(value.trim());
                }
            });

        this.notificationService.newNotifications.pipe(takeUntil(this.destroy$))
            .subscribe((count: number): void => {
                if (this.notificationsCount < count && this.soundsService.isSoundOn()) {
                    const audio: any = new Audio();
                    audio.src = "../../../../assets/sounds/new_notification.wav";
                    audio.load();
                    let playAttempt = setInterval(() => {
                        audio.play()
                            .then(() => {
                                clearInterval(playAttempt);
                            })
                            .catch((error) => {
                                console.warn("Unable to play the sound, User has not interacted yet.");
                            });
                    }, 1000);

                }
                this.notificationsCount = count;
                this.changeDetectorRef.markForCheck();
            });

        this.notificationService.getNotificationsCount();

        this.fullScreenCheck();

        this.router.events.pipe(filter((event: any): boolean => event instanceof NavigationEnd))
            .subscribe((observer: NavigationEnd): void => {
                this.onActivate();
            });

        this.checkMediaRecordState();

        this.onActivate();
    }

    public ngOnDestroy(): void {
        this.clearNotifications();

        if (this.media_interval) {
            clearInterval(this.media_interval);
        }

        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public onActivate(): void {
        const routeSection: any = this.router.routerState.snapshot.root.firstChild.params.url;
        if (Array.isArray(routeSection)
            && routeSection.length === 4
            && routeSection[0] === "orders"
            && routeSection[1] === "view"
            && routeSection[2] === "id") {
            this.getOrderRequestsCount(routeSection[3]);
        } else {
            this.requestsCount = 0;
            this.changeDetectorRef.markForCheck();
        }
    }
}
