import {Injectable} from "@angular/core";
import {Api, ApiService} from "./api.service";


@Injectable()
export class AttachmentService {

    public constructor(private apiService: ApiService) {
    }

    public deleteAttachment(url: string[], attachmentId: number, query: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Delete, url,
            {id: attachmentId}, query);
    }
}
