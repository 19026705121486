<ng-template>
    <h1 mat-dialog-title>{{title}}</h1>
    <div mat-dialog-content>
        <div [innerHTML]="question"></div>
    </div>
    <div mat-dialog-actions class="confirm">
        <button mat-raised-button color="primary"
                [mat-dialog-close]="true"
                [class.button-timer]="auto === true">
            <span class="button-timer-content">
                <span class="button-timer-infill"></span>
                <span class="button-timer-text">Yes</span>
            </span>
        </button>
        <button mat-button [mat-dialog-close]="false" [class.button-timer]="auto === false">
            <span class="button-timer-content">
                <span class="button-timer-infill"></span>
                <span class="button-timer-text">No</span>
            </span>
        </button>
    </div>
</ng-template>
