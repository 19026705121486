import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {User} from "../../../../../../common/interfaces/user.interface";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {takeUntil} from "rxjs/operators";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Form} from "../../../../../../common/interfaces/form.interface";
import {AdminRoleService} from "../../../services/role.service";
import ISelectOption = Form.ISelectOption;
import {Table} from "../../../../../../common/interfaces/table.interface";
import {Api3Service} from "../../../../../../common/services/api3.service";

@Component({
    selector: "section-role-form",
    templateUrl: "form.component.html",
    styleUrls: [
        "form.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class RoleFormComponent implements Base.IComponent, OnInit, OnDestroy {
    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    public readonly state: Base.IState;

    public rolePermissionsIDs: number[] = [];

    public permissions: User.IPermission[] = [];

    public formGroup: FormGroup = new FormGroup({
        "name": new FormControl(null, [Validators.required]),
        "display_name": new FormControl(null, [Validators.required]),
        "settings": new FormControl([]),
        "order_remark_type_id": new FormControl(null, [Validators.required]),
    });

    public disableSaveButton: boolean = true;

    public settingsIsValid: boolean = false;

    public settingsFields: Form.IField[] = [
        {
            url: null,
            name: "dashboard",
            size: "full",
            type: "group",
            label: "Dashboard settings",
            values: [],
            multiple: false,
            required: false,
            fields: [
                {
                    url: null,
                    name: "showOpenOrdersCount",
                    size: "half",
                    type: "checkbox",
                    label: "Show open orders count",
                    values: [],
                    multiple: false,
                    required: false,
                    fields: []
                },
                {
                    url: null,
                    name: "showOpenInboundOrdersCount",
                    size: "half",
                    type: "checkbox",
                    label: "Show open inbound orders count",
                    values: [],
                    multiple: false,
                    required: false,
                    fields: []
                },
                {
                    url: null,
                    name: "showOpenOutboundOrdersCount",
                    size: "half",
                    type: "checkbox",
                    label: "Show open outbound orders count",
                    values: [],
                    multiple: false,
                    required: false,
                    fields: []
                },
                {
                    url: null,
                    name: "showAwaitingConfirmationOrdersCount",
                    size: "half",
                    type: "checkbox",
                    label: "Show non confirmed orders count",
                    values: [],
                    multiple: false,
                    required: false,
                    fields: []
                },
                {
                    url: null,
                    name: "showOnTheWayOrdersCount",
                    size: "half",
                    type: "checkbox",
                    label: "Show on the way orders count",
                    values: [],
                    multiple: false,
                    required: false,
                    fields: []
                },
                {
                    url: null,
                    name: "showTotalOrdersCount",
                    size: "half",
                    type: "checkbox",
                    label: "Show total orders count",
                    values: [],
                    multiple: false,
                    required: false,
                    fields: []
                },
                {
                    url: null,
                    name: "showPartMastersCount",
                    size: "half",
                    type: "checkbox",
                    label: "Show part masters count",
                    values: [],
                    multiple: false,
                    required: false,
                    fields: []
                },
                {
                    url: null,
                    name: "showTransactionsWithoutItemsCount",
                    size: "half",
                    type: "checkbox",
                    label: "Show transactions without items count",
                    values: [],
                    multiple: false,
                    required: false,
                    fields: []
                },
                {
                    url: null,
                    name: "showTransactionsWithoutOrdersCount",
                    size: "half",
                    type: "checkbox",
                    label: "Show transactions without orders count",
                    values: [],
                    multiple: false,
                    required: false,
                    fields: []
                },
                {
                    url: null,
                    name: "showOpenFollowups",
                    size: "half",
                    type: "checkbox",
                    label: "Show open followups count",
                    values: [],
                    multiple: false,
                    required: false,
                    fields: []
                },
                {
                    url: null,
                    name: "showInvalidTransactionsCount",
                    size: "half",
                    type: "checkbox",
                    label: "Show invalid transactions count",
                    values: [],
                    multiple: false,
                    required: false,
                    fields: []
                },

                {
                    url: null,
                    name: "showFailedWhSla",
                    size: "half",
                    type: "checkbox",
                    label: "Show failed warehouse order SLA count",
                    values: [],
                    multiple: false,
                    required: false,
                    fields: []
                },

                {
                    url: null,
                    name: "showFailedDeliverySla",
                    size: "half",
                    type: "checkbox",
                    label: "Show failed delivery order SLA count",
                    values: [],
                    multiple: false,
                    required: false,
                    fields: []
                },

                {
                    url: null,
                    name: "filler",
                    size: "full",
                    type: "filler",
                    label: "",
                    values: [],
                    multiple: false,
                    required: false,
                    fields: []
                },
                {
                    url: null,
                    name: "awaitingConfirmationOrdersCount",
                    size: "third",
                    type: "input",
                    label: "Awaiting confirmation orders count",
                    values: [],
                    multiple: false,
                    required: true,
                    fields: []
                },
                {
                    url: null,
                    name: "latestRemarksCount",
                    size: "third",
                    type: "input",
                    label: "Latest remarks count",
                    values: [],
                    multiple: false,
                    required: true,
                    fields: []
                },
                {
                    url: null,
                    name: "favoriteRequestsCount",
                    size: "third",
                    type: "input",
                    label: "Favorite requests count",
                    values: [],
                    multiple: false,
                    required: true,
                    fields: []
                },
                {
                    url: null,
                    name: "exceptionTracksCount",
                    size: "third",
                    type: "input",
                    label: "Exception tracks count",
                    values: [],
                    multiple: false,
                    required: true,
                    fields: []
                },
                {
                    url: null,
                    name: "lastActivitiesCount",
                    size: "third",
                    type: "input",
                    label: "Last activities count",
                    values: [],
                    multiple: false,
                    required: true,
                    fields: []
                }
            ]
        }
    ];

    public settingsValues: any = null;

    public remark_types: ISelectOption[] = [];

    public logsTableSettings: Table.ISettings;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private roleService: AdminRoleService,
        private toastService: ToastService,
        private spinnerService: SpinnerService
    ) {
    }

    private prepareLogsTable(): void {
        this.logsTableSettings = {
            table_id: "userlogstable",
            api: {
                url: ["/admin", "roles", this.state.params.id, "logs"],
                query: {
                    relations: [
                        "User"
                    ],
                },
                version: 3
            },
            columns: [
                {
                    data: "subject",
                    title: "Subject"
                },
                {
                    data: "log",
                    title: "Log"
                },
                {
                    data: "user.name",
                    title: "User",
                    sortable: false
                },
                {
                    data: "created_at",
                    title: "Created at",
                    searchable: false
                },
            ],
            sort_default: {
                data: "created_at",
                dir: "desc"
            }
        };
    }

    /**
     * Get permissions list
     * @returns {Promise<any>}
     */
    private async getRole(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.get(`/admin/roles/${this.state.params.id}`, {
            relations: [
                "permissions"
            ]
        });

        if (data) {
            this.rolePermissionsIDs = data.permissions.map((permission: User.IPermission): number => permission.id);
            this.formGroup.patchValue(data);

            this.settingsValues = data.settings;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get permissions list
     * @returns {Promise<any>}
     */
    private async getPermissionsList(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["permission"]);

        if (data) {
            this.permissions = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private async submitNew(): Promise<any> {
        this.spinnerService.show();
        const {data, message}: Api.IResponse = await this.api3Service.post(`/admin/roles`, this.formGroup.value);

        this.spinnerService.hide();
        if (data) {
            this.toastService.show(message, "success");
            this.router.navigate([
                "/admin",
                "role",
                "edit",
                "id",
                data.id
            ]);
        }

    }

    private async submitUpdate(): Promise<any> {
        this.spinnerService.show();
        const response: Api.IResponse = await this.api3Service.put(`/admin/roles/${this.state.params.id}`,
            this.formGroup.value);

        if (response) {
            this.toastService.show(response.message, response.type as string);
            this.disableSaveButton = true;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private async getRemarkTypes(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["remark", "types"], {}, {
            data_structure: "select"
        });

        if (data) {
            this.remark_types = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    public checkPermission(permission: User.IPermission): boolean {
        return this.rolePermissionsIDs.indexOf(permission.id) >= 0;
    }

    public formSubmit(): void {
        if (this.state.action === "edit") {
            this.submitUpdate();
        } else {
            this.submitNew();
        }
    }

    /**
     * Handle checkbox toggle
     * @param {MatCheckboxChange} event
     * @returns {Promise<any>}
     */
    public async togglePermission(event: MatCheckboxChange): Promise<any> {
        this.spinnerService.show();
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["permission", "link_role", this.state.params.id], {permissions: [event.source.value]});

        if (response) {
            this.toastService.show(response.message, response.type as string);
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    public validateSettings(is_valid: boolean): void {
        this.settingsIsValid = is_valid;
        this.changeDetectorRef.markForCheck();
    }


    public async ngOnInit(): Promise<any> {
        this.getRemarkTypes();
        if (this.state.action === "edit") {
            this.getRole();
            this.getPermissionsList();
            this.prepareLogsTable();
        }

        this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)
        ).subscribe((value: any): void => {
            this.disableSaveButton = false;
            this.changeDetectorRef.markForCheck();
        });
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "role",
            actions: {
                "add": ["add_roles"],
                "edit": ["edit_roles"]
            }
        };
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
