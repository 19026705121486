import {ChangeDetectionStrategy, Component, OnInit, ViewChild} from "@angular/core";
import {ConfirmComponent} from "../../../../../common/components/confirm/confirm.component";
import {Base} from "../../../../../common/interfaces/base.interfaces";
import {DrawerService} from "../../../../../app/services/drawer.service";

@Component({
    selector: "section-warehouse-view",
    templateUrl: "view.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WarehouseViewComponent implements Base.IComponent, OnInit {

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;


    public readonly state: Base.IState;

    public constructor(private drawerService: DrawerService) {
    }


    public ngOnInit(): void {
        this.drawerService.setItems("warehouse");
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "warehouse",
            actions: {
                "view": ["browse_warehouses"]
            }
        };
    }

}
