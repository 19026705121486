import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Modal} from "../../../../../services/modal.service";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {SpinnerService} from "../../../../../../common/services/spinner.service";

@Component({
    selector: "section-icb-form",
    templateUrl: "form.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class InventoryConditionFormComponent implements OnInit {

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    /**
     * Form group / group with controls
     * @type {FormGroup}
     */
    public formGroup: FormGroup = new FormGroup({
        part_master_id: new FormControl(null, [Validators.required]),
        rev: new FormControl(null),
        image_version: new FormControl(null),
        is_active: new FormControl(true),
        deactivate_after_process: new FormControl(false),
        hub_from_id: new FormControl(null, [Validators.required]),
        hub_to_id: new FormControl(null, [Validators.required]),
        remark: new FormControl(null, [Validators.required]),
    });

    public modal: Modal.IModal;

    public targetHubs: any[] = [];

    public itemName: string;
    public fromHubName: string;
    public toHubName: string;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private toastService: ToastService,
        private spinnerService: SpinnerService
    ) {
    }

    private async getData(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["inventory", "condition-bias", this.modal.params.id]);
        if (data) {
            this.formGroup.patchValue(data);
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    public async submit(): Promise<any> {

        let ask: string = `Do you want to create Inventory condition for ${this.itemName}
        for the hub to check ${this.fromHubName}, hub to move ${this.toHubName}`;

        if (this.formGroup.value.rev) {
            ask += ", Rev " + this.formGroup.value.rev;
        }
        if (this.formGroup.value.image_version) {
            ask += ", Image ver " + this.formGroup.value.image_version;
        }
        ask += "?";

        if (await this.confirmRef.confirm(ask)) {

            this.spinnerService.show();
            const {code, message, data}: Api.IResponse = await this.apiService.request(
                this.modal.params.id ? Api.EMethod.Put : Api.EMethod.Post,
                ["inventory", "condition-bias", this.modal.params.id ? this.modal.params.id : ""],
                this.formGroup.value);
            this.spinnerService.hide();
            if (code === 200) {
                this.toastService.show(message, "success");
                this.modal.params.id = data.id;

                this.close();
            }
        }
    }

    public async delete(): Promise<any> {
        if (await this.confirmRef.confirm("Are you sure want to delete this customer?")) {
            this.spinnerService.show();
            const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
                ["inventory", "condition-bias", this.modal.params.id]);
            if (type as string === "success") {
                this.toastService.show(message, "success");
                this.modal.response.next({
                    value: null,
                    name: "delete"
                });
            }
            this.spinnerService.hide();
        }
    }

    public close(): void {
        this.modal.response.emit(null);
    }

    public ngOnInit(): void {
        if (this.modal.params.id) {
            this.getData();
        }

        this.formGroup.get("hub_from_id").setValue(278);
        this.fromHubName = "ASL TW FG (good)";
    }

}
