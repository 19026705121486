import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: "keys"
})
export class ObjectKeysPipe implements PipeTransform {
    public transform(value: any): string[] {
        return value ? Object.keys(value) : [];
    }
}
