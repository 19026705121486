import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: "if",
    pure: false
})
export class IfPipe implements PipeTransform {

    public constructor() {
    }

    public transform($value: any, $comparison: boolean, $else = null): any {
        if (!$value) {
            return $else;
        }

        return $comparison ? $value : $else;

    }
}
