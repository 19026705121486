import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation
} from "@angular/core";
import {FileUploadComponent} from "../../file-upload/file-upload.component";
import {Modal, ModalService} from "../../../../section/services/modal.service";

@Component({
    selector: "common-form-file-upload",
    template: `
        <button type="button" mat-raised-button [color]="required && !value ? 'warn' : 'primary'" (click)="upload()">
            {{label}} {{required ? "*" : ""}}
        </button>
        <div class="file-name" *ngIf="name">{{name}}</div>
        <div class="image text-center" *ngIf="value">
            <img [src]="value" alt="" style="max-height: 200px; max-width: 100%; height: auto; width: auto;">
        </div>
    `,
    styleUrls: [
        "file-upload.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class CommonFormFileUploadComponent {

    public name: string = null;

    @Input()
    public label: string = "Choose file";

    @Input()
    public url: string[] = ["user", "file"];

    @Input()
    public accept: string[] = ["png", "jpg", "jpeg"];

    @Input()
    public value: string;

    @Input()
    public required: boolean = false;

    @Output()
    public file: EventEmitter<string> = new EventEmitter();

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private modalService: ModalService
    ) {
    }


    public async upload(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(FileUploadComponent, {
            url: this.url,
            accept: this.accept
        });

        if (response && response.value) {
            this.file.emit(response.value.data.path);
            this.value = response.value.data.path;
            this.name = response.value.data.name;
            this.changeDetectorRef.markForCheck();
        }
    }
}
