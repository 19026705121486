import {Injectable} from "@angular/core";
import {Api, ApiService} from "./api.service";


@Injectable()
export class ContractService {

    public constructor(private apiService: ApiService) {
    }

    public getContracts(query: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Get, ["contracts"], {}, query);
    }

    public getServiceLevels($contract_id: number, query: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Get, ["contracts", "" + $contract_id, "service_levels"],
            {}, query);
    }

}
