<common-confirm></common-confirm>


<mat-accordion *ngIf="items">
    <ng-template ngFor let-item let-i="index" [ngForOf]="items">
        <mat-expansion-panel *ngIf="item">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span>
                         <b>
                            {{item.value.sub_inventory}}
                        </b>
                    </span>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <form *userAllowed="'edit_sub_warehouses'"
                  [formGroup]="item" autocomplete="off" (submit)="submitUpdate(i)">
                <div class="group">
                    <mat-form-field class="full">
                        <mat-label>Name</mat-label>
<input matInput formControlName="sub_inventory"  required>
                    </mat-form-field>


                    <button [disabled]="!item.valid || !item.dirty" mat-raised-button
                            color="primary" type="submit">
                        Update
                    </button>
                    &nbsp;
                    <button *userAllowed="'delete_sub_warehouses'"
                            mat-raised-button color="warn" class="delete-button"
                            type="button" (click)="submitDelete(i)">
                        Delete
                    </button>
                </div>
            </form>
        </mat-expansion-panel>
    </ng-template>
</mat-accordion>

<ng-template [userAllowed]="'add_sub_warehouses'">
    <h3 class="mat-h3">Add new sub-warehouse</h3>
    <form *ngIf="newForm" [formGroup]="newForm" autocomplete="off" (submit)="submitAdd()">
        <div class="group">
            <mat-form-field class="full">
                <mat-label>Name</mat-label>
<input matInput formControlName="sub_inventory"  required>
            </mat-form-field>

            <button [disabled]="!newForm.valid" mat-raised-button color="primary" type="submit">Submit</button>
            &nbsp;
            <button mat-raised-button type="button" (click)="newForm.reset()">Reset</button>
        </div>
    </form>
</ng-template>
