import {Injectable} from "@angular/core";
import {Api3Service} from "../../../../common/services/api3.service";
import {Api} from "../../../../common/services/api.service";


@Injectable()
export class AdminUserService {

    public constructor(private apiService: Api3Service) {
    }

    public deactivateUser(id: number): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Post, `admin/users/${id}/deactivate`);
    }

    public reset2FaUser(id: number): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Post, `admin/users/${id}/fa_reset`);
    }

}
