<mat-card>

    <h1>Select inventory to update</h1>

    <form [formGroup]="form" (submit)="submit()">
        <div class="group flex">

            <div class="half">
                <common-form-hub-select
                        label="Hub from"
                        [required]="true"
                        [value]="old_hub.value"
                        (onGroupSelected)="available_hubs = $event"
                        (onChange)="old_hub.setValue($event)"></common-form-hub-select>
            </div>

            <div class="half" *ngIf="available_hubs">
                <common-form-hub-select
                        label="Hub to"
                        [required]="true"
                        [value]="form.value.inventory_conversion_id"
                        [options_grouped]="available_hubs"
                        (onChange)="form.get('inventory_conversion_id').setValue($event)"></common-form-hub-select>
            </div>

            <div class="full" *ngIf="part_masters && part_masters.length">
                <common-form-select [options]="part_masters"
                                    [required]="true"
                                    [search]="true"
                                    label="Select item"
                                    (valueChange)="part_master_id.setValue($event)">
                </common-form-select>
            </div>


            <ng-container *ngIf="inventories && inventories.length; else no_stock">
                <div class="half">
                    <common-form-select [options]="inventories"
                                        [required]="true"
                                        [search]="true"
                                        label="Select inventory to move"
                                        (valueChange)="form.get('inventory_id').setValue($event)">
                    </common-form-select>
                </div>

                <div *ngIf="available_serials && available_serials.length" class="half">
                    <common-form-select [options]="available_serials"
                                        [required]="true"
                                        [search]="true"
                                        [multiple]="true"
                                        label="Select serials"
                                        (onClose)="form.get('serials').setValue($event)">
                    </common-form-select>
                </div>

                <mat-form-field *ngIf="!available_serials || !available_serials.length" class="half">
                    <mat-label>Quantity</mat-label>
                    <input matInput type="number" required formControlName="quantity"
                           [min]="1"
                           [max]="maxQty">
                </mat-form-field>
            </ng-container>

            <ng-template #no_stock>
                <div class="full" *ngIf="old_hub.value && part_master_id.value">
                    <h2 class="text-error text-center border-bottom-none">
                        No stock to move
                    </h2>
                </div>
            </ng-template>


            <mat-form-field class="full">
                <mat-label>Remark</mat-label>
                <textarea row="5" matInput required formControlName="remark"></textarea>
            </mat-form-field>

        </div>
        <div class="actions">
            <button type="submit" mat-raised-button color="accent" [disabled]="!form.valid">
                Update
            </button>
        </div>
    </form>

</mat-card>
