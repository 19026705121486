import {Directive, HostListener, Input} from "@angular/core";
import {NgControl} from "@angular/forms";
import {UserService} from "../services/user.service";


@Directive({
    selector: "[scannerOnly]"
})
export class ScannerOnlyDirective {

    private lastClick: number = null;

    @Input()
    public scannerOnly: boolean = true;

    public constructor(
        private ngControl: NgControl,
        private userService: UserService
    ) {
    }

    @HostListener("focus")
    private focus(): void {
        this.lastClick = null;
    }

    @HostListener("paste")
    private paste(): void {
        if (!this.userService.validatePermissions("browse_admin")) {
            setTimeout((): void => {
                this.ngControl.control.setValue("");
            }, 10);
        }
    }

    @HostListener("keyup", ["$event.key"])
    private keyup(key: string): void {
        if (this.scannerOnly) {

            if (key.length > 1) {
                this.lastClick = null;
                return;
            }

            if (this.lastClick && Date.now() - this.lastClick > 70) {
                this.ngControl.control.setValue("");
            }
            this.lastClick = Date.now();
        }
    }
}
