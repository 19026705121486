import {Pipe, PipeTransform} from "@angular/core";
import {HelpersService} from "../services/helpers.service";
import {Warehouse} from "../interfaces/warehouse.interface";

@Pipe({
    name: "getConfNames"
})
export class ItemConfigurationNamesPipe implements PipeTransform {

    public transform(item: Warehouse.IInventory): string {
        return HelpersService.getConfigNames(item);
    }
}
