import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation
} from "@angular/core";
import {Drawer, DrawerComponent} from "../drawer.component";
import {UserService} from "../../../../common/services/user.service";
import {ModalService} from "../../../../section/services/modal.service";
import {AmplitudeService} from "../../../../common/services/amplitude.service";

@Component({
    selector: "app-drawer-items",
    templateUrl: "items.component.html",
    styleUrls: [
        "items.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})

export class DrawerItemsComponent {

    @Input()
    public item: Drawer.IItem;

    @Input()
    public index: number;

    @Input()
    public expanded: boolean = false;

    @Input()
    public section: string;

    @Output()
    public childEvent: EventEmitter<any> = new EventEmitter<any>();

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private userService: UserService,
        private modalService: ModalService,
    ) {
    }

    /**
     * Tell parent component about menu item state change
     * @param $event
     */
    public toggle($event: Event): void {
        $event.stopPropagation();
        this.item.expanded = !this.item.expanded;
        this.childEvent.emit(this.index);
    }

    public allowed(permissions: string[]): boolean {
        return this.userService.validatePermissions(permissions);
    }

    public canSee(item: Drawer.IItem): boolean {
        if (item.canSee && item.canSee.length && !item.canSee.includes(this.section)) {
            return false;
        } else if (item.except && item.except.length && item.except.includes(this.section)) {
            return false;
        }
        return true;
    }

    /**
     * Handle state changes of menu child items
     * @param index
     * @param item
     */
    public onChild(index: number, item: Drawer.IItem): void {

        AmplitudeService.eventClick(item.link ? "Show page " + item.link : "Open menu " + item.title);

        for (const i of Object.keys(this.item.items)) {
            if (Number(i) !== index) {
                this.item.items[i].expanded = false;
                if (this.item.items[i].items) {
                    DrawerComponent.collapse(this.item.items[i].items);
                }
            }
        }
        this.changeDetectorRef.markForCheck();
    }


    public async openModal(item: Drawer.IItem): Promise<any> {
        await this.modalService.open(item.modal.component, item.modal.params);
    }
}
