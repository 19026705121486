import {ChangeDetectionStrategy, Component, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {Modal} from "../../../../../services/modal.service";
import {Warehouse} from "../../../../../../common/interfaces/warehouse.interface";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {GoogleMapsApiService} from "../../../../../../common/services/google-maps-api.service";
import {MapInfoWindow, MapMarker} from "@angular/google-maps";


@Component({
    selector: "section-hubs-map-modal",
    template: `
        <mat-card>
            <mat-card-content>
                <ng-container *ngIf="apiLoaded | async">
                    <google-map [options]="options" height="450px" width="100%">

                        <map-marker *ngIf="destination"
                                    #marker="mapMarker"
                                    [position]="{lat: destination.lat, lng: destination.lng}"
                                    [options]="{icon: {url:'/assets/images/icons/marker-red.png'}, zIndex: 2}">
                        </map-marker>


                        <ng-container *ngFor="let group of hubs">
                            <map-marker
                                #hubMarker="mapMarker"
                                [position]="{lat: getLat(group), lng:getLng(group)}"
                                [options]="{icon:
                                        {url:'/assets/images/icons/warehouse-marker-green.png'},
                                        zIndex: 2}"
                                (mapClick)="openInfoWindow(hubMarker, group)">
                            </map-marker>

                        </ng-container>

                        <map-circle *ngIf="destination"
                                    [center]="{lat: destination.lat, lng: destination.lng}"
                                    [radius]="100000">
                        </map-circle>

                        <map-info-window>
                            <ng-container *ngIf="infowindowSelectedgroup">
                                <div style="margin-bottom: 5px; font-weight: bold;font-size: 18px;">
                                    {{infowindowSelectedgroup.group}}
                                    ({{infowindowSelectedgroup.hubs[0].distance}}km)
                                </div>
                                <div *ngFor="let hub of infowindowSelectedgroup.hubs"
                                     [style.background-color]="hub.gb === 'good' ? '#80C883': '#ffbfde'"
                                     (click)="selectHub(hub)"
                                     style="padding:5px;border-bottom:1px solid #fff;font-size: 16px;">
                                    {{hub.customers_sub_inventory}}
                                    ({{hub.gb}})
                                    <b *ngIf="modal.params.part_master_id">{{hub.inventories_sum || '0'}} pc.</b>
                                </div>
                            </ng-container>
                        </map-info-window>


                    </google-map>
                </ng-container>

            </mat-card-content>
        </mat-card>
    `,
    styleUrls: [
        "map.component.scss"
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HubsMapModalComponent implements OnInit {

    public modal: Modal.IModal;

    public destination: any;

    public hubs: { group: string, hubs: Warehouse.IHub[] }[] = [];

    public center: { lat: number, lng: number } = {lat: 0, lng: 0};

    public apiLoaded: Observable<any>;

    public options: google.maps.MapOptions;

    @ViewChild(MapInfoWindow)
    public infoWindow: MapInfoWindow;

    public infowindowSelectedgroup: { group: string, hubs: Warehouse.IHub[] };

    public constructor(
        private http: HttpClient,
        private googleMapsApiService: GoogleMapsApiService
    ) {
    }


    public getLat(group: any): number {
        if (group.hubs[0].warehouse && group.hubs[0].warehouse.address) {
            return Number(group.hubs[0].warehouse.address.lat);
        }
        return 0;
    }

    public getLng(group: any): number {
        if (group.hubs[0].warehouse && group.hubs[0].warehouse.address) {
            return Number(group.hubs[0].warehouse.address.lng);
        }
        return 0;
    }

    public selectHub(hub: Warehouse.IHub): void {
        this.modal.response.emit({
            name: "response",
            value: hub
        });
    }

    public openInfoWindow(marker: MapMarker, group: { group: string, hubs: Warehouse.IHub[] }): void {
        this.infowindowSelectedgroup = group;
        this.infoWindow.open(marker);
    }


    public ngOnInit(): void {
        this.hubs = this.modal.params.hubs;
        this.destination = this.modal.params.hubs[0].hubs[0].destination;

        if (this.destination) {
            this.destination.lat = Number(this.destination.lat);
            this.destination.lng = Number(this.destination.lng);

            this.center.lat = Number(this.destination.lat);
            this.center.lng = Number(this.destination.lng);
        }


        this.options = {
            center: this.center,
            zoom: 8,
            maxZoom: 15,
            scrollwheel: false,
            gestureHandling: "cooperative",
            disableDefaultUI: true,
            streetViewControl: false,
            fullscreenControl: true,
            zoomControl: true,
        };

        this.apiLoaded = this.googleMapsApiService.load();
    }

}
