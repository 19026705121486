import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {UntypedFormBuilder, FormGroup} from "@angular/forms";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {Modal} from "../../../../../../services/modal.service";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";

@Component({
    selector: "section-item-configuration-form",
    templateUrl: "form.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class ItemConfigurationFormComponent implements OnInit {

    /**
     * Form group / group with controls
     * @type {FormGroup}
     */
    public formGroup: FormGroup;

    public countries: any[];

    public modal: Modal.IModal;

    public action: string = "add";

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private formBuilder: UntypedFormBuilder,
        private apiService: ApiService,
        private toastService: ToastService,
        private spinnerService: SpinnerService
    ) {
    }

    /**
     * Prepare form group (create)
     * @returns {void}
     */
    private prepareForm(): void {
        this.formGroup = this.formBuilder.group({
            name: [this.modal.params.name ? this.modal.params.name : null],
            description: [this.modal.params.description ? this.modal.params.description : null],
        });
        this.changeDetectorRef.markForCheck();
    }


    /**
     * Submit form (add)
     * @returns {Promise<any>}
     */
    public async handleFormSubmit(): Promise<any> {
        if (this.formGroup && this.formGroup.valid) {
            this.spinnerService.show();
            if (this.action === "add") {
                const response: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
                    ["configuration", "item", this.modal.params.item_id], this.formGroup.value);
                this.spinnerService.hide();
                if (response) {
                    this.toastService.show(response.message, response.type as string);
                    this.modal.response.next({
                        name: "value",
                        value: response.data.name,
                    });
                }
            }
        }
    }

    /**
     * Show add new address form
     * @returns {void}
     */
    public showList(): void {
        this.modal.response.next({
            name: "list"
        });
    }

    public ngOnInit(): void {
        this.action = this.modal && this.modal.params.action ? this.modal.params.action : "add";
        this.prepareForm();
    }

}
