<common-confirm></common-confirm>
<common-table2 #outboundTable *ngIf="outboundItemsList"
               [settings]="outboundItemsList"
               (gotData)="gotData($event,'outbound')">
    <div row1>
        <ng-template
                [ngIf]="createOutboundShipment && orderData.service_level.order_type.order_name !== 'assembly'">
            <button mat-raised-button color="accent"
                    *userAllowed="'browse_admin'"
                    (click)="actionHandler({name:'shipment',data: null},'outbound')">
                <mat-icon>local_shipping</mat-icon>
                Create shipment
            </button>
        </ng-template>

        <button
                *ngIf="userService.validatePermissions(['add_orders_items']) && (!orderData.confirmed || userService.validatePermissions(['edit_order_item_after_confirmation']))"
                mat-raised-button
                color="primary"
                (click)="actionHandler(null, 'outbound')">
            Add outbound item
        </button>

        <button *ngIf="stockMode === 'soft'"
                mat-raised-button color="primary" (click)="allocateItems()">
            Allocate items
        </button>

        <button *ngIf="userService.validatePermissions(['browse_reports']) && createOutboundShipment"
                mat-raised-button type="button" color="primary" (click)="onGenerateReport.emit(true)">
            Items report
        </button>

        <button mat-raised-button type="button"
                (click)="actionHandler({name: 'update_statuses',data:null},null)">
            Update statuses
        </button>
    </div>
</common-table2>
