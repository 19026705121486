<div class="margin-bottom-10" [class.unread]="is_unread">
    <div class="pull-right text-right margin-bottom-10" style="padding-left: 10px">
        <small>
            {{activity.created_at}}
        </small>
        <div *ngIf="activity.user" class="margin-bottom-10">
            <small>
                        <span class="avatar" [style.background-image]="'url(' + (activity.user.avatar
                            ? activity.user.avatar
                            : '/assets/images/default-avatar.png') + ')'"></span>
                <b>{{activity.user.name}}</b>
            </small>
        </div>

        <ng-container *ngIf="showMarkRead">
            <div>
                <button type="button" mat-raised-button color="primary" (click)="markRead()">
                    Mark read
                </button>
            </div>

            <div *ngIf="showOrderLink" class="padding-top-10">
                <button type="button" mat-raised-button color="accent"
                        (click)="goToOrder()">
                    Go to order
                </button>
            </div>
        </ng-container>
    </div>

    <h4>
        <mat-icon *ngIf="activity.properties && activity.properties.mat_icon"
                  class="vertical-bottom">
            {{activity.properties.mat_icon}}
        </mat-icon>

        <b>{{activity.name}}</b>
    </h4>

    <div [innerHTML]="activity.description"></div>
    <div class="clear"></div>
</div>
