import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {UntypedFormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ConfirmComponent} from "../../../../../../../common/components/confirm/confirm.component";
import {Modal, ModalService} from "../../../../../../services/modal.service";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {Api3Service} from "../../../../../../../common/services/api3.service";
import {PartnerService} from "../../../../../../../common/services/partner.service";


@Component({
    selector: "section-pm-conversions-form",
    templateUrl: "form.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class PartMasterConversionsFormComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<void> = new EventEmitter();

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;


    public formGroup: FormGroup = new FormGroup({
        part_master_id: new FormControl(null, [Validators.required]),
        conversion_part_master_id: new FormControl(null, [Validators.required]),
        type: new FormControl(null, [Validators.required]),
        clear_serial: new FormControl(false),
        hub: new FormControl("Leave as is", [Validators.required])
    });

    public modal: Modal.IModal;

    public action: string = "add";

    public part_masters: { name: string, value: any }[] = [];

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private formBuilder: UntypedFormBuilder,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private modalService: ModalService,
        private spinnerService: SpinnerService
    ) {
    }

    private async getPartMasters(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["partmaster"], null, {data_structure: "select"});
        if (data) {
            this.part_masters = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private async getData(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `partner/${PartnerService.partner.slug}/part-master-conversions/${this.modal.params.id}`);
        if (data) {
            this.action = "edit";

            this.formGroup.patchValue({
                part_master_id: data.part_master_id,
                conversion_part_master_id: data.conversion_part_master_id,
                type: data.type,
                clear_serial: data.clear_serial
            });
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    public async submit(): Promise<any> {
        if (this.modal.params.id) {
            this.update();
        } else {
            this.create();
        }
    }

    public async create(): Promise<any> {
        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.api3Service.request(Api.EMethod.Post,
            `partner/${PartnerService.partner.slug}/part-master-conversions`, this.formGroup.value);

        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.modal.response.emit(null);
        }
    }

    public async update(): Promise<any> {
        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.api3Service.request(Api.EMethod.Put,
            `partner/${PartnerService.partner.slug}/part-master-conversions/${this.modal.params.id}`,
            this.formGroup.value);

        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.modal.response.emit(null);
        }
    }

    public async delete(): Promise<any> {
        if (!await this.confirmRef.confirm("Are you sure want to delete this conversion?")) {
            return;
        }
        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.api3Service.request(Api.EMethod.Delete,
            `partner/${PartnerService.partner.slug}/part-master-conversions/${this.modal.params.id}`);
        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.modal.response.emit(null);
        }
    }

    public ngOnInit(): void {
        this.getPartMasters();

        if (this.modal.params.id) {
            this.getData();
        }
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

}
