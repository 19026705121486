<common-confirm></common-confirm>
<h1 class="container-heading">Announcement</h1>
<div class="heading-info" *ngIf="data">
    <b>Created at:</b> {{data.created_at}}
</div>
<mat-card>
    <mat-card-content>
        <ng-template [ngIf]="data">

            <div class="pull-right flex row top-info">
                <div class="messages-count">
                    <div class="title"><b>Messages</b></div>
                    <div>{{messagesCount}}</div>
                </div>
            </div>


            <div>
                <h1 *ngIf="!requestTitleEdit">
                    {{data.title}}
                    <mat-icon class="pointer edit-btn" (click)="requestTitleEdit = true">
                        edit
                    </mat-icon>
                </h1>
                <ng-template [ngIf]="requestTitleEdit">
                    <mat-form-field>
                        <mat-label>Title</mat-label>
<input matInput [formControl]="requestTitle"  required>
                    </mat-form-field>
                    <button mat-icon-button color="primary" matTooltip="Save"
                            (click)="requestTitleEdit = !update('title',requestTitle.value)">
                        <mat-icon color="success">check</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Cancel"
                            (click)="requestTitle.setValue(data.title);requestTitleEdit = false">
                        <mat-icon color="warn">cancel</mat-icon>
                    </button>
                </ng-template>
            </div>

            <div class="actions">


                <button mat-mini-fab class="white" matTooltip="Favorite" (click)="favorite()">
                    <mat-icon color="{{ data.favorite.length > 0 ? 'accent' : ''}}">star</mat-icon>
                </button>
                <button mat-mini-fab class="white" matTooltip="Refresh" (click)="getData()">
                    <mat-icon>autorenew</mat-icon>
                </button>
                <button mat-mini-fab class="white" matTooltip="Delete" (click)="delete()">
                    <mat-icon color="warn">delete</mat-icon>
                </button>


                <div class="pull-right">
                    <span>
                    <b>Access:</b> {{remarkTypes["" + data.order_remark_type_id]}}
                    </span>

                </div>
            </div>


            <mat-tab-group>
                <mat-tab label="Details">

                    <div class="columns">
                        <div class="main">
                            <div class="info-row">
                                <div>
                                    <div class="avatar"
                                         [ngStyle]="{'background-image': 'url(' + (data.creator.avatar || '/assets/images/default-avatar.png') + ')'}">
                                    </div>
                                    <div class="title">
                                        <b>Created by</b>
                                        <mat-icon *ngIf="!requestCreatorEdit"
                                                  class="pointer edit-btn"
                                                  (click)="requestCreatorEdit = true; getUsers()">
                                            edit
                                        </mat-icon>
                                        <ng-template [ngIf]="requestCreatorEdit">
                                            <button mat-icon-button color="primary" matTooltip="Save"
                                                    (click)="requestCreatorEdit = !update('creator',requestCreator.value)">
                                                <mat-icon color="success">check</mat-icon>
                                            </button>
                                            <button mat-icon-button matTooltip="Cancel"
                                                    (click)="requestStatus.setValue(data.status);
                                                requestCreatorEdit = false">
                                                <mat-icon color="warn">cancel</mat-icon>
                                            </button>
                                        </ng-template>
                                    </div>
                                    <div *ngIf="!requestCreatorEdit">
                                        {{data.creator.name}}
                                    </div>
                                    <div *ngIf="requestCreatorEdit">
                                        <mat-form-field>
                                            <mat-label>Creator</mat-label>
<mat-select [formControl]="requestCreator"
                                                         required>
                                                <mat-option>
                                                    <ngx-mat-select-search
                                                            [formControl]="requestUserSearch"
                                                            [placeholderLabel]="'Search'"
                                                            [clearSearchInput]="true"
                                                            [noEntriesFoundLabel]="'No items found'">
                                                    </ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option *ngFor="let user of usersFiltered | async"
                                                            [value]="user.value">
                                                    {{user.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div>
                                    <div class="title">
                                        <b>Assigned to a team</b>
                                        <mat-icon *ngIf="!requestTeamEdit"
                                                  class="pointer edit-btn"
                                                  (click)="requestTeamEdit = true; getTeams()">
                                            edit
                                        </mat-icon>
                                        <ng-template [ngIf]="requestTeamEdit">
                                            <button mat-icon-button color="primary" matTooltip="Save"
                                                    (click)="requestTeamEdit = !update('team',requestTeam.value)">
                                                <mat-icon color="success">check</mat-icon>
                                            </button>
                                            <button mat-icon-button matTooltip="Cancel"
                                                    (click)="requestStatus.setValue(data.status);
                                                requestTeamEdit = false">
                                                <mat-icon color="warn">cancel</mat-icon>
                                            </button>
                                        </ng-template>
                                    </div>
                                    <div *ngIf="!requestTeamEdit">
                                        <ng-template [ngIf]="data.team">
                                            {{data.team.name}}
                                        </ng-template>
                                        <ng-template [ngIf]="!data.team">
                                            Choose a team
                                        </ng-template>
                                    </div>

                                    <div *ngIf="requestTeamEdit">
                                        <mat-form-field>
                                            <mat-label>Team</mat-label>
<mat-select [formControl]="requestTeam"
                                                         required>
                                                <mat-option>
                                                    <ngx-mat-select-search
                                                            [formControl]="requestTeamSearch"
                                                            [placeholderLabel]="'Search'"
                                                            [clearSearchInput]="true"
                                                            [noEntriesFoundLabel]="'No items found'">
                                                    </ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option *ngFor="let team of teamsFiltered | async"
                                                            [value]="team.value">
                                                    {{team.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div>
                                    <div class="avatar">
                                        {{data.announcement_kind.name.substr(0, 3)}}
                                    </div>
                                    <div class="title"><b>Category</b></div>
                                    <div>{{data.announcement_kind.name}}</div>
                                </div>
                            </div>
                            <mat-divider class="relative"></mat-divider>

                            <div class="tags">
                                <h2 class="border-bottom-none">Tags</h2>

                            </div>

                            <mat-divider class="relative"></mat-divider>

                            <div class="description">
                                <h2 class="border-bottom-none">Description
                                    <mat-icon *ngIf="!requestDescriptionEdit" class="pointer edit-btn"
                                              (click)="requestDescriptionEdit = true">
                                        edit
                                    </mat-icon>

                                    <ng-template [ngIf]="requestDescriptionEdit">
                                        <button mat-icon-button color="primary" matTooltip="Save"
                                                (click)="requestDescriptionEdit = !update('message',requestDescription.value)">
                                            <mat-icon color="success">check</mat-icon>
                                        </button>
                                        <button mat-icon-button matTooltip="Cancel"
                                                (click)="requestDescription.setValue(data.description);requestDescriptionEdit = false">
                                            <mat-icon color="warn">cancel</mat-icon>
                                        </button>
                                    </ng-template>
                                </h2>

                                <div class="description" *ngIf="!requestDescriptionEdit"
                                     [innerHTML]="data.description | linebreaks"></div>

                                <div *ngIf="requestDescriptionEdit">
                                    <common-form-editor label="Description"
                                                        [value]="editorInitialValue"
                                                        (valueChange)="requestDescription.setValue($event)"
                                    ></common-form-editor>
                                </div>
                            </div>

                            <mat-divider class="relative"></mat-divider>

                            <div class="messages">
                                <mat-form-field class="pull-right">
                                    <mat-label>Search</mat-label>
                                    <input matInput type="search"
                                           [formControl]="messageSearch">
                                </mat-form-field>
                                <h2 class="border-bottom-none">Messages</h2>

                                <div class="text-right clear">
                                    <button type="button" mat-raised-button color="primary"
                                            (click)="newMessage()">
                                        New message
                                    </button>
                                </div>
                                <br>
                                <div *ngIf="messages">
                                    <mat-card *ngFor="let msg of messages.data"
                                              class="message"
                                              [class.solution]="msg.solution">
                                        <div class="owner pull-left">
                                            <div class="avatar" *ngIf="msg.creator"
                                                 [ngStyle]="{'background-image': 'url(' + (msg.creator.avatar || 'assets/images/default-avatar.png') + ')'}">
                                            </div>
                                            <div class="name">{{msg.creator ? msg.creator.name : msg.sender_name}}</div>
                                            <div class="date">{{msg.created_at}}</div>
                                        </div>
                                        <div class="pull-right" *ngIf="msg.order_remark_type">
                                            <div>
                                                <mat-icon class="vertical-middle">remove_red_eye</mat-icon>
                                                {{msg.order_remark_type.name}}
                                            </div>
                                            <br>
                                            <div *ngIf="msg.solution" class="solution-mark">
                                                Marked as solution
                                            </div>
                                        </div>
                                        <div class="clear message-text" [innerHTML]="msg.message | linebreaks"></div>

                                        <br>
                                        <button type="button" mat-raised-button (click)="editMessage(msg)"
                                                *ngIf="msg.creator && msg.creator.id === me.id">
                                            Edit
                                        </button>
                                    </mat-card>

                                    <common-pagination [data]="messages" [hidePerPage]="true"
                                                       pid="G5sl0Ez97R"
                                                       (goToPage)="getMessages($event.page,messageSearch)">
                                    </common-pagination>

                                    <div class="text-right clear">
                                        <button type="button" mat-raised-button color="primary"
                                                (click)="newMessage()">
                                            New message
                                        </button>
                                    </div>
                                </div>
                                <div *ngIf="!messages">
                                    No messages
                                </div>

                            </div>
                            <mat-divider class="relative"></mat-divider>
                            <div>
                                <h2 class="border-bottom-none">Attachments</h2>
                                <ng-template ngFor let-attachment [ngForOf]="data.attachments">
                                    <a [href]="attachment.url" class="attachment"
                                       target="_blank">{{attachment.name}}</a><br>
                                </ng-template>
                            </div>

                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>

        </ng-template>
    </mat-card-content>
</mat-card>
