<common-confirm></common-confirm>


<h1 class="container-heading">Warehouses List</h1>

<mat-card>
    <mat-card-content>
        <div class="actions">
            <a routerLink="add" mat-raised-button color="primary" class="create">Add</a>
            <mat-form-field class="search">
                <mat-label>Search</mat-label>
                <input matInput type="search" #warehouseSearch>
            </mat-form-field>
        </div>
        <div class="warehouses">
            <mat-card class="warehouse"
                      *ngFor="let warehouse of warehouses | filter:'name':'like' : warehouseSearch.value">
                <mat-icon>home</mat-icon>
                <div style="opacity: 0.5">
                    {{warehouse.type}}
                </div>
                <div class="title" (click)="goTo(warehouse)">
                    {{warehouse.name}}
                    <mat-icon>input</mat-icon>
                </div>

                <div class="actions">
                    <mat-icon [matMenuTriggerFor]="menu">more_vert</mat-icon>
                    <mat-menu #menu="matMenu">
                        <a (click)="edit(warehouse.id)" mat-menu-item>Edit</a>
                        <a (click)="delete(warehouse)" mat-menu-item>Delete</a>
                    </mat-menu>
                </div>

                <div style="opacity: 0.5">
                    <mat-icon>place</mat-icon>
                    {{warehouse.address.city}},
                    {{warehouse.address.country_iso_2}}

                </div>
            </mat-card>
        </div>

    </mat-card-content>
</mat-card>
