import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Warehouse} from "../../../../../../common/interfaces/warehouse.interface";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Modal} from "../../../../../services/modal.service";
import {TransactionService} from "../../../../../../common/services/transaction.service";

@Component({
    selector: "section-transaction-replenishment-status-modal",
    template: `
        <h1 class="container-heading">Transaction replenishment status</h1>
        <mat-card>
            <mat-card-content>
                <form *ngIf="formGroup" [formGroup]="formGroup" autocomplete="off" (submit)="submit()">
                    <div class="group flex">

                        <div class="full">
                            <common-form-select
                                [options]="statuses"
                                [multiple]="false"
                                [required]="true"
                                [value]="formGroup.value.status"
                                (valueChange)="formGroup.get('status').setValue($event)"
                                label="Status"></common-form-select>
                        </div>

                        <mat-form-field class="full">
                            <mat-label>Notes</mat-label>
                            <textarea matInput formControlName="notes"
                                      required></textarea>
                        </mat-form-field>
                    </div>
                    <div class="actions">
                        <button [disabled]="!formGroup.valid"
                                mat-raised-button color="primary" type="submit">
                            Submit
                        </button>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class TransactionReplenishmentStatusModalComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    public transaction: Warehouse.ITransaction;

    public modal: Modal.IModal;

    public statuses: string[] = ["pending", "skipped", "complete"];

    /**
     * Form group
     * @type {FormGroup}
     */
    public formGroup: FormGroup = new FormGroup({
        transaction_id: new FormControl(null, [Validators.required]),
        status: new FormControl(null, [Validators.required]),
        notes: new FormControl(null, [Validators.required])
    });

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private toastService: ToastService,
        private transactionService: TransactionService,
        private spinnerService: SpinnerService
    ) {
    }


    /**
     * Submit courier transaction
     * @returns {Promise<any>}
     */
    public async submit(): Promise<any> {
        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.transactionService
            .transactionReplenishmentStatusUpdate(this.formGroup.value);
        this.spinnerService.hide();

        if (code === 200) {
            this.toastService.show(message, "success");
            this.modal.response.emit({
                name: "refresh"
            });
        }
    }

    public ngOnInit(): void {
        this.formGroup.get("transaction_id").setValue(this.modal.params.transaction.id);
        this.formGroup.get("status").setValue(this.modal.params.transaction.replenishments[0].status);
        this.formGroup.get("notes").setValue(this.modal.params.transaction.replenishments[0].notes);
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
