import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {UntypedFormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Table} from "../../../../../../common/interfaces/table.interface";

@Component({
    selector: "section-threepl-form",
    templateUrl: "form.component.html",
    styleUrls: [
        "form.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class ThreeplFormComponent implements Base.IComponent, OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    private slug: string;

    public data: any;

    /**
     * Form group / group with controls
     * @type {FormGroup}
     */
    public formGroup: FormGroup = new FormGroup({
        display_name: new FormControl(null, [Validators.required]),
        type: new FormControl(null, [Validators.required]),
        address: new FormGroup({
            country: new FormControl(null, [Validators.required]),
            address: new FormControl(null, [Validators.required]),
            address2: new FormControl(null),
            city: new FormControl(null, [Validators.required]),
            state: new FormControl(null),
            zip: new FormControl(null, [Validators.required])
        }),
        contact: new FormGroup({
            first_name: new FormControl(null, [Validators.required]),
            last_name: new FormControl(null, [Validators.required]),
            email: new FormControl(null, [Validators.required, Validators.email]),
            phone: new FormControl(null, [Validators.required]),
            fax: new FormControl(null)
        })
    });

    public readonly state: Base.IState;

    public countries: any[];

    public logsTableSettings: Table.ISettings;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private helpers: HelpersService,
        private spinnerService: SpinnerService
    ) {
    }

    private prepareLogsTable(): void {
        this.logsTableSettings = {
            table_id: "whlogstable",
            api: {
                url: ["/admin", "threepls", this.state.params.id, "logs"],
                query: {
                    relations: [
                        "User"
                    ],
                },
                version: 3
            },
            columns: [
                {
                    data: "subject",
                    title: "Subject"
                },
                {
                    data: "log",
                    title: "Log"
                },
                {
                    data: "user.name",
                    name: "User.name",
                    title: "Performed by",
                    sortable: false
                },
                {
                    data: "created_at",
                    title: "Created at",
                    searchable: false
                },
            ],
            sort_default: {
                data: "created_at",
                dir: "desc"
            }
        };
    }


    /**
     * Prepare ThreePL data (by id)
     * @returns {Promise<any>}
     */
    private async prepareData(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["threepl", this.state.params.id]);
        this.spinnerService.hide();
        if (data) {
            this.data = data;
            if (this.data.address) {
                this.data.address.country = this.data.address.country_iso_2;
            }
            this.slug = data.slug;

            this.formGroup.patchValue(this.data);

            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Prepare countries (as control options)
     * @returns {Promise<any>}
     */
    private async prepareCountriesList(): Promise<any> {
        this.spinnerService.show();
        this.countries = await this.helpers.prepareCountriesList();
        this.spinnerService.hide();
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Handle autocomplete selected value event
     * @param $event
     */
    public onCountrySelected($event: any): void {
        this.formGroup.get("address").get("country").setValue($event.value);
    }

    /**
     * Submit form (add, edit)
     * @returns {Promise<any>}
     */
    public async handleFormSubmit(): Promise<any> {
        let response: Api.IResponse;
        this.spinnerService.show();
        if (this.state.action === "add") {
            response =
                await this.apiService.request(Api.EMethod.Post, "threepl", this.formGroup.value);
        }
        if (this.state.action === "edit") {
            response =
                await this.apiService.request(Api.EMethod.Put, ["threepl", this.state.params.id], this.formGroup.value);
        }
        this.spinnerService.hide();
        if (response.data) {
            this.toastService.show(response.message, "success");
            this.router.navigate([this.state.section, this.state.component]);
        }

    }

    public async ngOnInit(): Promise<any> {
        this.prepareCountriesList();

        if (this.state.params.id) {
            this.prepareData();
            this.prepareLogsTable();
        }

    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "threepl",
            actions: {
                "add": ["add_threepls"],
                "edit": ["edit_threepls"]
            }
        };
    }

}
