import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    Output,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ConfirmComponent} from "../../../../../../../common/components/confirm/confirm.component";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {Warehouse} from "../../../../../../../common/interfaces/warehouse.interface";
import {debounceTime, takeUntil} from "rxjs/operators";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {Contact} from "../../../../../../../common/interfaces/contact.interface";
import IAddress = Contact.IAddress;
import IContact = Contact.IContact;


@Component({
    selector: "section-shippy-pickup-wizard-step1",
    templateUrl: "step1.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class ShippyProPickupWizardStep1Component implements OnDestroy {

    private state: Base.IState;

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    @Output()
    public result: EventEmitter<any> = new EventEmitter();

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    public shipment: Warehouse.IShipment;

    public data: any = {};

    public parcels: Warehouse.IParcel[] = [];

    public fromAddress: FormGroup;
    public toAddress: FormGroup;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private spinnerService: SpinnerService
    ) {
    }

    private async checkValidity(): Promise<any> {
        if (this.fromAddress.valid && this.toAddress.valid) {
            this.data.from_address = this.fromAddress.value;
            this.data.to_address = this.toAddress.value;
            this.data.shipment = this.shipment;

            this.result.next(this.data);
        } else {
            this.result.next(null);
        }
    }

    private getAddressForm(
        address: any,
        fill_gaps_address: IAddress = null,
        fill_gaps_contact: IContact = null,
        company_fill: string = null
    ): FormGroup {

        let company: string = null;
        if (address.customer) {
            company = address.customer.name;
        } else if (company_fill) {
            company = company_fill;
        }


        let country: string = null;
        let in_eu: boolean = false;

        if (address.country_iso_2) {
            country = address.country_iso_2;
        } else if (address.country) {
            country = address.country;
        } else if (fill_gaps_address.country_iso_2) {
            country = fill_gaps_address.country_iso_2;
        } else if (fill_gaps_address.country) {
            country = fill_gaps_address.country;
        }

        if (address.country_detailed) {
            in_eu = address.country_detailed.in_eu;
        } else if (fill_gaps_address.country_detailed) {
            in_eu = fill_gaps_address.country_detailed.in_eu;
        }


        let name: string = null;
        let phone: string = null;
        let email: string = null;

        if (address.contacts && address.contacts.length) {

            const last_contact: Contact.IContact = address.contacts[address.contacts.length - 1];

            name = last_contact.first_name + (last_contact.last_name ? " " + last_contact.last_name : "");
            phone = last_contact.phone;
            email = last_contact.email;

        } else if (fill_gaps_contact) {
            name = fill_gaps_contact.first_name
                + (fill_gaps_contact.last_name ? " " + fill_gaps_contact.last_name : "");
            phone = fill_gaps_contact.phone;
            email = fill_gaps_contact.email;
        }

        return new FormGroup({
            name: new FormControl(name, [Validators.required, Validators.maxLength(40)]),
            company: new FormControl(company, [Validators.required, Validators.maxLength(35)]),
            street1: new FormControl(address.address, [Validators.required, Validators.maxLength(35)]),
            street2: new FormControl(address.address2, [Validators.maxLength(35)]),
            city: new FormControl(address.city, [Validators.required]),
            state: new FormControl(address.state),
            zip: new FormControl(address.zip, [Validators.required]),
            country: new FormControl(country, [Validators.required, Validators.maxLength(2)]),
            phone: new FormControl(phone, [
                Validators.required,
                Validators.pattern("\\+?[\-0-9\\s\(\)]+"),
                Validators.maxLength(20)
            ]),
            email: new FormControl(email, [
                Validators.required,
                Validators.pattern("[\\w\\-\\.]+@([\\w\\-]+\\.)+[\\w\\-]{2,4}"),
            ]),
            in_eu: new FormControl(in_eu)
        });
    }

    /**
     * Get order shipments
     * @returns {Promise<any>}
     */
    private async getShipment(updated: string = null): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["shipment", this.state.params.shipment], {}, {
                relations: [
                    "FromAddress.contacts.customer",
                    "FromAddress.CountryDetailed",
                    "FromAddress.customer",
                    "ToAddress.contacts.customer",
                    "ToAddress.CountryDetailed",
                    "ToAddress.customer",
                    "Warehouse.address",
                    "Warehouse.address.CountryDetailed",
                    "Warehouse.contact",
                    "Warehouse.Threepl",
                    "Order.MainContact",
                    "Order.MainAddress.customer",
                    "Order.ServiceLevel.orderType",
                    "OrderItems.PartMaster",
                    "Partner",
                    "SiblingShipments.Warehouse.address",
                    "SiblingShipments.Warehouse.address.CountryDetailed",
                    "SiblingShipments.Warehouse.contact",
                    "SiblingShipments.Warehouse.Threepl"
                ]
            });
        if (data) {
            this.shipment = data;

            const sibling: Warehouse.IShipment = data.sibling_shipments.find((sibling): boolean => {
                return sibling.type !== data.type
                    && data.address_to === sibling.address_to
                    && data.address_from === sibling.address_from;
            });

            if (data.from_address && updated !== "to") {

                let fill_from_gaps_address: any = null;
                let fill_from_gaps_contact: any = null;
                let fill_from_company: string = null;

                if (this.shipment.type === "outbound") {
                    fill_from_gaps_address = this.shipment.warehouse?.address;
                    fill_from_gaps_contact = this.shipment.warehouse?.contact;
                    fill_from_company = this.shipment.warehouse?.threepl?.display_name;
                } else {
                    if (this.shipment.order.service_level.order_type.slug === "transfer" && sibling) {
                        fill_from_gaps_address = sibling.warehouse?.address;
                        fill_from_gaps_contact = sibling.warehouse?.contact;
                        fill_from_company = sibling.warehouse?.threepl?.display_name;
                    } else {
                        fill_from_gaps_address = this.shipment.order?.main_address;
                        fill_from_gaps_contact = this.shipment.order?.main_contact;
                        fill_from_company = this.shipment.order?.main_address?.customer?.name;
                    }
                }

                this.fromAddress = this.getAddressForm(this.shipment.from_address,
                    fill_from_gaps_address, fill_from_gaps_contact, fill_from_company);
                this.fromAddress.markAsPristine();

            }

            if (data.to_address && updated !== "from") {

                let fill_to_gaps_address: any = null;
                let fill_to_gaps_contact: any = null;
                let fill_to_company: string = null;

                if (this.shipment.type === "inbound") {
                    fill_to_gaps_address = this.shipment.warehouse?.address;
                    fill_to_gaps_contact = this.shipment.warehouse?.contact;
                    fill_to_company = this.shipment.warehouse?.threepl?.display_name;
                } else {
                    if (this.shipment.order.service_level.order_type.slug === "transfer" && sibling) {
                        fill_to_gaps_address = sibling.warehouse?.address;
                        fill_to_gaps_contact = sibling.warehouse?.contact;
                        fill_to_company = sibling.warehouse?.threepl?.display_name;
                    } else {
                        fill_to_gaps_address = this.shipment.order?.main_address;
                        fill_to_gaps_contact = this.shipment.order?.main_contact;
                        fill_to_company = this.shipment.order?.main_address?.customer?.name;
                    }
                }

                this.toAddress = this.getAddressForm(this.shipment.to_address,
                    fill_to_gaps_address, fill_to_gaps_contact, fill_to_company);
                this.toAddress.markAsPristine();
            }


        }
        this.spinnerService.hide();
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Initialize details step
     * @param state
     * @returns {Promise<any>}
     */
    public async init(state: Base.IState): Promise<any> {
        this.state = state;
        await this.getShipment();

        this.checkValidity();

        this.fromAddress.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(1000))
            .subscribe((): void => {
                this.checkValidity();
            });

        this.toAddress.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(1000))
            .subscribe((): void => {
                this.checkValidity();
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
