import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, ViewEncapsulation} from "@angular/core";
import {FormControl} from "@angular/forms";
import {takeUntil} from "rxjs/operators";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../../common/interfaces/wizard.interface";

@Component({
    selector: "section-warehouse-procedures-wizard-scan-type",
    template: `
        <mat-card>
            <h1>{{wizardType + " procedure" | ucfirst}} type</h1>
            <div class="form" style="width: 600px;">
                <div class="group flex">
                    <mat-form-field class="full" *ngIf="template === 'select'">
                        <mat-label>Scan method</mat-label>
                        <mat-select [formControl]="scanType">
                            <mat-option *ngFor="let option of options" [value]="option.value">
                                {{option.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>


                    <mat-radio-group *ngIf="template === 'tiles'" [formControl]="scanType" class="full">
                        <mat-card class="tile mat-elevation-z5" *ngFor="let option of options"
                                  (click)="scanType.setValue(option.value)">
                            <div>
                                <mat-radio-button [value]="option.value">
                                    <b>{{option.name}}</b>
                                </mat-radio-button>
                                <div *ngIf="option.description" class="padding-top-10">
                                    {{option.description}}
                                </div>
                            </div>
                        </mat-card>
                    </mat-radio-group>

                </div>


            </div>
        </mat-card>
    `,
    styleUrls: [
        "type.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class WarehouseProceduresWizardScanTypeComponent extends AbstractWizardStepComponent implements OnDestroy {

    private data: any;

    public scanType: FormControl = new FormControl("box");

    public wizardType: string;

    public options: { value: any, name: string, default: boolean, description?: string, group?: string }[];

    public template: "select" | "tiles" = "select";

    public constructor(protected changeDetectorRef: ChangeDetectorRef) {
        super(changeDetectorRef);
    }

    private getData(value: any): any {
        switch (this.wizardType) {
            case "inbound":
                if (value === "default") {
                    return true;
                } else {
                    return {warehouseOrderId: this.data, showFreeBoxes: false};
                }
            case "outbound":
            case "count":
                return true;
            case "transfer":
                return {scanType: value};
            default:
                return null;
        }
    }

    /**
     * Initialize step
     * @param data
     * @returns {Promise<any>}
     */
    public async init(data: Wizard.IData): Promise<any> {

        this.wizardType = data.wizardType;

        if (data.stepParams.options) {
            this.options = data.stepParams.options;
        }

        if (data.stepParams.template) {
            this.template = data.stepParams.template;
        }


        this.data = data.warehouseOrderId;

        if (data.state.params.type) {
            this.result.emit({
                action: "setup",
                value: data.state.params.type
            });
            this.result.emit({
                action: "result",
                value: this.getData(data.state.params.type),
                goToNextStep: true
            });

        } else {
            this.result.emit({
                action: "setup",
                value: this.scanType.value
            });
            this.result.emit({
                action: "result",
                value: this.getData(this.scanType.value),
            });

            this.scanType.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: string): void => {
                this.result.emit({
                    action: "setup",
                    value
                });
                this.result.emit({
                    action: "result",
                    value: this.getData(value),
                });
            });

            for (const option of this.options) {
                if (option.default) {
                    this.scanType.setValue(option.value);
                }
            }
            this.changeDetectorRef.markForCheck();
        }
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

}
