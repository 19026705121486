import {ChangeDetectionStrategy, Component, ViewChild, ViewEncapsulation} from "@angular/core";
import {ConfirmComponent} from "../../common/components/confirm/confirm.component";

@Component({
    selector: "app-main",
    template: `
        <common-confirm></common-confirm>
        <common-spinner></common-spinner>
        <router-outlet></router-outlet>
    `,
    styles: [
        `
                         app-main {
                             display: block;
                             width: 100%;
                             height: 100%;
                         }
                     `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class MainComponent {

    @ViewChild(ConfirmComponent, {static: false})
    public confirm: ConfirmComponent;
}
