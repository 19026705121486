import {ChangeDetectionStrategy, Component} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";

@Component({
    selector: "section-warehouse-order-return-open-list",
    template: `
        <section-warehouse-order-list [state]="state" [type]="'return'"
                                      [status]="'open'"></section-warehouse-order-list>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WarehouseOrderReturnOpenListComponent implements Base.IComponent {


    public readonly state: Base.IState;

    public constructor() {
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "warehouse-orders-return-open",
            actions: {
                "browse": ["browse_warehouse_orders"]
            }
        };
    }

}
