import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Table2Component} from "../../../../../../common/components/table2";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {Api} from "../../../../../../common/services/api.service";
import {ModalService} from "../../../../../services/modal.service";
import {CommonFormComponent} from "../../../../../../common/components/form";
import {PartnerService} from "../../../../../../common/services/partner.service";
import {UserService} from "../../../../../../common/services/user.service";


@Component({
    selector: "section-requests-kinds-list",
    template: `
        <h1 class="container-heading">Request kinds</h1>
        <mat-card>
            <mat-card-content>
                <common-table2 #table *ngIf="listTable" [settings]="listTable"></common-table2>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class RequestsKindsListComponent implements Base.IComponent, OnInit {


    public readonly state: Base.IState;


    @ViewChild("table", {static: false})
    public tableRef: Table2Component;

    public listTable: Table.ISettings;

    public kinds: any[] = [];

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private modalService: ModalService,
        private userService: UserService,
    ) {
    }

    private prepareList(): void {

        this.listTable = {
            table_id: "5gcR8vJds67",
            api: {
                url: [this.state.section, "request-kinds"],
                version: 3
            },
            actions: [
                {
                    name: "delete",
                    title: "Delete",
                    click: (row: any): void => {
                        this.delete(row);
                    },
                    disabledFn: (row: any): boolean => {
                        return row.partner_id !== PartnerService.partner.id
                            || !this.userService.validatePermissions("delete_request_kinds");
                    },
                },

            ],
            columns: [
                {
                    data: "name",
                    title: "Name",
                },
                {
                    data: "category",
                    title: "Category",
                },
                {
                    data: "created_at",
                    title: "Created at",
                    searchable: false
                },
            ],
        };
        this.changeDetectorRef.markForCheck();
    }

    public async delete(kind: any): Promise<any> {
        await this.modalService.open(CommonFormComponent, {
            formConfig: {
                id: 0,
                name: "Delete kind",
                description: "You must reassign all requests to a different kind",
                fields: [
                    {
                        label: "Select substitute request kind",
                        name: "substitute_request_kind_id",
                        size: "full",
                        type: "select",
                        required: true,
                        url: "/request/kind?category=" + kind.category + "&exclude_id=" + kind.id
                    },
                ]
            },
            submitUrl: ["request", "kind", "" + kind.id],
            method: Api.EMethod.Delete
        });

        this.tableRef.reload();
    }

    public ngOnInit(): void {
        this.prepareList();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "support-requests-kinds",
            actions: {
                "browse": ["browse_requests"]
            }
        };
    }
}
