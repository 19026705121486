<common-confirm></common-confirm>

<h1 class="container-heading">Settings</h1>

<mat-card>
    <mat-card-content>
        <div class="columns-container" *ngIf="partner">
            <div class="column-1-3">
                <mat-card>
                    <div class="logo">
                        <img *ngIf="partner.logo_path" [src]="partner.logo_path" alt="">
                    </div>

                    <mat-list class="nav-list">
                        <mat-divider></mat-divider>
                        <mat-list-item (click)="showSection = 1" [class.active]="showSection === 1">
                            <mat-icon matListItemIcon>people</mat-icon>
                            <p matListItemTitle>Contacts</p>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item (click)="showSection = 2" [class.active]="showSection === 2">
                            <mat-icon matListItemIcon>how_to_reg</mat-icon>
                            <p matListItemTitle>Can access</p>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item (click)="showSection = 5" [class.active]="showSection === 5">
                            <mat-icon matListItemIcon>alarm</mat-icon>
                            <p matListItemTitle>Scheduled tasks</p>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item (click)="showSection = 3" [class.active]="showSection === 3">
                            <mat-icon matListItemIcon>settings</mat-icon>
                            <p matListItemTitle>Properties</p>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item (click)="showSection = 6" [class.active]="showSection === 6">
                            <mat-icon matListItemIcon>checklist</mat-icon>
                            <p matListItemTitle>Forms</p>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item (click)="showSection = 4" [class.active]="showSection === 4">
                            <mat-icon matListItemIcon>attach_file</mat-icon>
                            <p matListItemTitle>Attachments</p>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                    </mat-list>
                </mat-card>
            </div>

            <div class="column-2-3" *ngIf="showSection === 1">
                <mat-card>
                    <h3 matSubheader>Contacts</h3>
                    <mat-tab-group>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                Clients
                            </ng-template>
                            <div class="content">
                                <mat-list *ngIf="partner.clients && partner.clients.length > 0">
                                    <ng-template ngFor let-client let-i="index" [ngForOf]="partner.clients">
                                        <mat-list-item>
                                            <div matListItemTitle>
                                                <div class="flex row align-center space-between">
                                                    <div>
                                                        {{ client.name }} {{ client.last_name }} ({{ client.email }})
                                                    </div>
                                                    <button mat-icon-button color="warn" class="pull-right"
                                                            (click)="removeClient(i)">
                                                        <mat-icon>remove</mat-icon>
                                                    </button>
                                                </div>

                                            </div>
                                        </mat-list-item>
                                        <mat-divider></mat-divider>
                                    </ng-template>
                                </mat-list>

                                <h3 matSubheader>Add client</h3>
                                <mat-form-field class="search">
                                    <mat-label>Add client</mat-label>
                                    <input type="text" matInput [formControl]="clientSearch"

                                           [matAutocomplete]="cliSearch">
                                </mat-form-field>

                                <mat-autocomplete #cliSearch="matAutocomplete" (optionSelected)="addClient($event)">
                                    <mat-option *ngFor="let option of clientsList" [value]="option">
                                        {{ option.name }} {{ option.last_name }} ({{ option.email }})
                                    </mat-option>
                                </mat-autocomplete>

                            </div>
                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                Managers
                            </ng-template>
                            <div class="content">
                                <mat-list *ngIf="partner.managers && partner.managers.length > 0">
                                    <ng-template ngFor let-manager let-i="index" [ngForOf]="partner.managers">
                                        <mat-list-item>
                                            <div matListItemTitle class="flex row align-center space-between">
                                                <div>
                                                    {{ manager.name }} {{ manager.last_name }}({{ manager.email }})
                                                </div>
                                                <button mat-icon-button color="warn" class="pull-right"
                                                        (click)="removeManager(i)">
                                                    <mat-icon>remove</mat-icon>
                                                </button>

                                            </div>

                                        </mat-list-item>
                                        <mat-divider></mat-divider>
                                    </ng-template>
                                </mat-list>

                                <h3 matSubheader>Add manager</h3>
                                <mat-form-field class="search">
                                    <mat-label>Add manager</mat-label>
                                    <input type="text" matInput [formControl]="managerSearch"

                                           [matAutocomplete]="mngSearch">
                                </mat-form-field>

                                <mat-autocomplete #mngSearch="matAutocomplete" (optionSelected)="addManager($event)">
                                    <mat-option *ngFor="let option of managersList" [value]="option">
                                        {{ option.name }} {{ option.last_name }} ({{ option.email }})
                                    </mat-option>
                                </mat-autocomplete>

                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </mat-card>
            </div>

            <div class="column-2-3" *ngIf="showSection === 2">
                <mat-card>
                    <h3 matSubheader class="margin-bottom-0">Can access</h3>
                    <div class="text-right">
                        <mat-form-field class="search">
                            <mat-label>Search</mat-label>
                            <input matInput type="search" #canAccessSearch>
                        </mat-form-field>
                    </div>
                    <div class="content">
                        <mat-list *ngIf="usersHasAccess && usersHasAccess.length > 0">
                            <ng-template ngFor let-user let-i="index" [ngForOf]="usersHasAccess
                                | filter:'name' :'like' : canAccessSearch.value">
                                <mat-list-item>
                                    <div matListItemTitle class="flex row align-center space-between">
                                        <div>{{ user.name }} {{ user.last_name }} ({{ user.email }})</div>
                                        <button mat-icon-button color="warn" class="pull-right" (click)="removeUser(i)">
                                            <mat-icon>remove</mat-icon>
                                        </button>
                                    </div>
                                </mat-list-item>
                                <mat-divider></mat-divider>
                            </ng-template>
                        </mat-list>

                        <h3 matSubheader>Add user</h3>
                        <mat-form-field class="search">
                            <mat-label>Add user</mat-label>
                            <input type="text" matInput [formControl]="userSearch"

                                   [matAutocomplete]="usrSearch">
                        </mat-form-field>

                        <mat-autocomplete #usrSearch="matAutocomplete" (optionSelected)="addUser($event)">
                            <mat-option *ngFor="let option of users" [value]="option">
                                {{ option.name }} {{ option.last_name }} ({{ option.email }})
                            </mat-option>
                        </mat-autocomplete>

                    </div>

                </mat-card>
            </div>

            <div class="column-2-3" *ngIf="showSection === 5">
                <mat-card>
                    <h3 matSubheader>Scheduled tasks</h3>
                    <div class="content">

                        <common-table2 *ngIf="scheduledTasksTable" [settings]="scheduledTasksTable">
                        </common-table2>

                    </div>

                </mat-card>
            </div>

            <div class="column-2-3" *ngIf="showSection === 3">
                <mat-card>
                    <h3 matSubheader>Properties</h3>
                    <div class="form" *ngIf="partner && properties">
                        <common-form-fields [fields]="properties"
                                            [values]="partner.properties" #fields></common-form-fields>
                        <div class="actions">
                            <button type="button" mat-raised-button
                                    [disabled]="!fields.formGroup.valid"
                                    color="primary"
                                    (click)="saveProperties(fields.formGroup.value)">
                                Save
                            </button>
                        </div>
                    </div>

                    <mat-divider></mat-divider>

                    <h3 matSubheader>Reset data</h3>
                    <div class="form">
                        <div class="group flex">
                            <mat-form-field class="full">
                                <mat-label>Select data to reset</mat-label>
                                <mat-select [formControl]="resetControl" multiple>
                                    <mat-option *ngFor="let option of resetData" [value]="option.val">
                                        {{ option.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="actions">
                            <button type="button" mat-raised-button color="warn"
                                    (click)="reset()"
                                    [disabled]="!resetControl.value.length">
                                Reset data
                            </button>
                        </div>
                    </div>
                </mat-card>
            </div>

            <div class="column-2-3" *ngIf="showSection === 4">
                <mat-card>
                    <h3 matSubheader>Attachments</h3>
                    <div class="content">

                        <common-table2 *ngIf="attachmentsTable" [settings]="attachmentsTable">
                        </common-table2>

                    </div>

                </mat-card>
            </div>

            <div class="column-2-3" *ngIf="showSection === 6">
                <mat-card>
                    <h3 matSubheader>Form configurations</h3>
                    <div class="content">
                        <mat-tab-group dynamicHeight>
                            <mat-tab label="Customer form">
                                <div class="padding-bottom-10 padding-top-10 padding-left-10 padding-right-10">
                                    <common-settings-customer-form [state]="state"></common-settings-customer-form>
                                </div>
                            </mat-tab>
                            <mat-tab label="Address form">
                                <div class="padding-bottom-10 padding-top-10 padding-left-10 padding-right-10">
                                    <common-settings-address-form [state]="state"></common-settings-address-form>
                                </div>
                            </mat-tab>
                            <mat-tab label="Contact form">
                                <div class="padding-bottom-10 padding-top-10 padding-left-10 padding-right-10">
                                    <common-settings-contact-form [state]="state"></common-settings-contact-form>
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </mat-card>
            </div>

        </div>

    </mat-card-content>
</mat-card>
