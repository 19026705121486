<form *ngIf="formGroup" [formGroup]="formGroup" autocomplete="off" novalidate>

    <div class="group flex">
        <div class="full margin-bottom-10" style="font-size: 130%"
             *ngIf="courier_transaction?.params?.shippy_pro_carrier_name">
            Carrier: <b>{{courier_transaction.params.shippy_pro_carrier_name}}</b>
        </div>

        <div class="full margin-bottom-20" style="font-size: 130%" *ngIf="courier_transaction">
            Track: <b>{{courier_transaction.tracking_number}}</b>
        </div>

        <div class="full">
            <common-form-date-time
                    label="Pickup date"
                    [required]="true"
                    [min_date]="min_date"
                    [value]="formGroup.value.pickup_date"
                    (valueChange)="formGroup.get('pickup_date').setValue($event)">
            </common-form-date-time>
        </div>

        <div class="half" *ngIf="morning_time_slots.length">
            <common-form-select
                    label="Pickup morning min time"
                    [options]="morning_time_slots"
                    [required]="true"
                    [value]="formGroup.value.pickup_morning_min_time"
                    (valueChange)="formGroup.get('pickup_morning_min_time').setValue($event)">
            </common-form-select>
        </div>

        <div class="half" *ngIf="morning_time_slots.length">
            <common-form-select
                    label="Pickup morning max time"
                    [options]="morning_time_slots"
                    [required]="true"
                    [value]="formGroup.value.pickup_morning_max_time"
                    (valueChange)="formGroup.get('pickup_morning_max_time').setValue($event)">
            </common-form-select>
        </div>

        <div class="half" *ngIf="afternoon_time_slots.length">
            <common-form-select
                    label="Pickup afternoon min time"
                    [options]="afternoon_time_slots"
                    [required]="true"
                    [value]="formGroup.value.pickup_afternoon_min_time"
                    (valueChange)="formGroup.get('pickup_afternoon_min_time').setValue($event)">
            </common-form-select>
        </div>

        <div class="half" *ngIf="afternoon_time_slots.length">
            <common-form-select
                    label="Pickup afternoon max time"
                    [options]="afternoon_time_slots"
                    [required]="true"
                    [value]="formGroup.value.pickup_afternoon_max_time"
                    (valueChange)="formGroup.get('pickup_afternoon_max_time').setValue($event)">
            </common-form-select>
        </div>


        <mat-form-field class="full">
            <mat-label>Notes</mat-label>
            <textarea matInput formControlName="notes"></textarea>
        </mat-form-field>

    </div>

</form>