import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: "mentions"
})
export class MentionsPipe implements PipeTransform {

    public transform(value: string): string {
        if (!value) {
            return "";
        }
        return value.replace(/(@[a-z0-9_-]+)/gm, "<b>$1</b>");
    }

}
