import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: "replace"
})
export class ReplacePipe implements PipeTransform {
    public transform(value: string, needle: string, replacer: string, regexp?: boolean, flags?: string): string {
        let pattern: string | RegExp = needle;
        if (regexp && flags) {
            pattern = new RegExp(pattern, flags);
        } else if (regexp) {
            pattern = new RegExp(needle);
        }
        return value.replace(pattern, replacer);
    }
}