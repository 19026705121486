import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {User} from "../../../../../../common/interfaces/user.interface";
import {Order} from "../../../../../../common/interfaces/order.interface";
import {IPagination} from "../../../../../../common/components/pagination/pagination.component";
import {ToastService} from "../../../../../../common/services/toast.service";
import {UserService} from "../../../../../../common/services/user.service";
import {FormControl} from "@angular/forms";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {SpinnerService} from "../../../../../../common/services/spinner.service";

@Component({
    selector: "section-remark-type-view",
    templateUrl: "view.component.html",
    styleUrls: [
        "view.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class RemarkTypeViewComponent implements Base.IComponent, OnInit {

    public showSection: number = 1;

    public user: User.IData;

    public rolesSearch: FormControl = new FormControl(null);
    public rolesList: any[] = [];

    public remark: any;

    public readonly state: Base.IState;

    public remarks: IPagination<Order.IRemark>;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private userService: UserService,
        private spinnerService: SpinnerService
    ) {
    }

    private async getData(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["remark", "types", this.state.params.id]);

        if (data) {
            this.remark = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private async updateRemark(): Promise<any> {
        this.spinnerService.show();
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["remark", "link_remark_type", "" + this.state.params.id], {
                roles: this.remark.roles.map((role: any): number => Number(role.id))
            });
        if (response) {
            this.toastService.show(response.message, response.type as string);
            this.getData();
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private async getRoles(search_by: string): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService
            .request(Api.EMethod.Get, ["role"],
                {});
        if (data) {
            this.rolesList = data.filter((role: any): boolean => role.name.includes(search_by));
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    public addRole(event: MatAutocompleteSelectedEvent): void {
        this.remark.roles.push(event.option.value);
        this.rolesSearch.reset();
        this.updateRemark();
    }

    public removeRole(index: number): void {
        this.remark.roles.splice(index, 1);
        this.updateRemark();
    }

    public async ngOnInit(): Promise<any> {
        this.user = this.userService.data;
        this.getData();
        this.rolesSearch.valueChanges.subscribe((value: string): void => {
            this.getRoles(value);
        });
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "remark-type",
            actions: {
                "view": ["read_order_remark_types"]
            }
        };
    }


}
