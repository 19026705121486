import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, Validators} from "@angular/forms";

import {takeUntil} from "rxjs/operators";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../../common/interfaces/wizard.interface";
import {Base} from "../../../../../../../../common/interfaces/base.interfaces";
import {Warehouse} from "../../../../../../../../common/interfaces/warehouse.interface";
import {Api, ApiService} from "../../../../../../../../common/services/api.service";
import {SpinnerService} from "../../../../../../../../common/services/spinner.service";
import {SoundsService} from "../../../../../../../../common/services/sounds.service";
import {Api3Service} from "../../../../../../../../common/services/api3.service";

@Component({
    selector: "section-warehouse-order-wizard-select-shipment",
    templateUrl: "select-shipment.component.html",
    styleUrls: [
        "select-shipment.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})

export class WarehouseProceduresWizardOutboundSelectShipmentComponent extends AbstractWizardStepComponent
    implements OnInit, OnDestroy {

    private orderRef: string;

    private orderid: number;

    private warehouseOrderId: number;

    private line_type: string = "outbound";

    public state: Base.IState;

    public shipments: Warehouse.IShipment[] = [];

    public selectedShipment: FormControl = new FormControl(null, [Validators.required]);

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private api3Service: Api3Service,
        public soundsService: SoundsService,
        private spinnerService: SpinnerService
    ) {
        super(changeDetectorRef);
    }

    /**
     * Get order shipments
     * @returns {Promise<any>}
     */
    private async getOrderShipments(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `/${this.state.section}/orders/${this.orderid}/shipments`, {}, {
                type: this.line_type,
                warehouse_order_id: this.warehouseOrderId,
                relations: [
                    "ToAddress.CountryDetailed",
                    "FromAddress.CountryDetailed",
                    "Order:id,shipping_instructions"
                ]
            });
        if (data) {
            this.shipments = data;
            if (this.shipments.length === 1) {
                this.selectedShipment.setValue("0");
                this.changeDetectorRef.markForCheck();
                this.result.emit({
                    action: "setup",
                    value: {
                        shipment: this.shipments[0],
                        scanType: this.shipments[0].scan_type,
                    }
                });
                setTimeout((): void => {
                    this.result.emit({
                        action: "result",
                        value: {
                            shipment: this.shipments[0],
                            shipmentInstructions: this.shipments[0].order.shipping_instructions
                        },
                        goToNextStep: true
                    });
                }, 10);
            } else {
                if (this.state.params.shipment_id) {
                    for (const shipment of this.shipments) {
                        if (this.state.params.shipment_id === "" + shipment.id) {
                            setTimeout((): void => {
                                this.result.emit({
                                    action: "result",
                                    value: {
                                        procedureType: shipment.scan_type,
                                        shipment: shipment,
                                        shipmentInstructions: shipment.order.shipping_instructions
                                    },
                                    goToNextStep: true
                                });
                            }, 10);
                        }
                    }
                } else {
                    this.soundsService.textToSpeech("Please pick shipment");
                }
            }
        }
        this.spinnerService.hide();
        this.changeDetectorRef.markForCheck();
    }


    /**
     * Initialize details step
     * @returns {Promise<any>}
     * @param data
     */
    public async init(data: Wizard.IData): Promise<any> {
        this.state = data.state;
        this.orderRef = data.orderRef;
        this.orderid = data.orderId;
        this.warehouseOrderId = data.warehouseOrderId;
        this.line_type = data.wizardType;
        this.getOrderShipments();
    }


    public ngOnInit(): void {
        this.selectedShipment.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: string): void => {
            this.result.emit({
                action: "setup",
                value: {
                    shipment: this.shipments[value],
                    scanType: this.shipments[value].scan_type
                }
            });
            setTimeout((): void => {
                this.result.emit({
                    action: "result",
                    value: {
                        shipment: this.shipments[value],
                        shipmentInstructions: this.shipments[value].to_address.shipping_instructions
                    }
                });
            }, 10);

        });
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}
