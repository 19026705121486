<mat-card>
    <h3 matSubheader>Settings</h3>
    <form class="form" [formGroup]="proForm" (submit)="saveProfile()">
        <div class="group flex margin-bottom-0">
            <mat-form-field class="full">
                <mat-label>Full name</mat-label>
                <input matInput formControlName="name" required>
                <span matPrefix>
                    <mat-icon class="vertical-middle">person</mat-icon>&nbsp;
                </span>
            </mat-form-field>
            <mat-form-field class="full">
                <mat-label>Email</mat-label>
                <input matInput formControlName="email" required>
                <span matPrefix>
                    <mat-icon class="vertical-middle">email</mat-icon>&nbsp;
                </span>
            </mat-form-field>
            <mat-form-field class="full">
                <mat-label>Slack name</mat-label>
                <input matInput formControlName="slack_name" >
                <span matPrefix>
                    <i class="fa fa-slack"></i>&nbsp;
                </span>
            </mat-form-field>
        </div>

        <div class="group flex">
            <mat-form-field class="min phone-code">
                <mat-label>Country code</mat-label>
                <mat-select  formControlName="country">
                    <mat-option>
                        <ngx-mat-select-search
                                [formControl]="countryFilter"
                                [placeholderLabel]="'Search'"
                                [noEntriesFoundLabel]="'No items found'"
                        ></ngx-mat-select-search>
                    </mat-option>
                    <mat-select-trigger>
                        <img *ngIf="proForm.value.country" [src]="'/assets/images/flags/'
                                        + proForm.value.country.country_iso_2.toLowerCase() + '.svg'"
                             [alt]="proForm.value.country.country_name">
                        {{proForm.value.country ? "+" + proForm.value.country.phone_code : ''}}
                    </mat-select-trigger>
                    <mat-option *ngFor="let country of countriesFiltered | async"
                                [value]="country">
                        <img style="height: 20px;vertical-align: middle;" [src]="'/assets/images/flags/'
                                        + country.country_iso_2.toLowerCase() + '.svg'"
                             [alt]="country.country_name">
                        {{ country.country_name + " +" + country.phone_code }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div>&nbsp;</div>

            <mat-form-field class="max">
                <mat-label>Phone #</mat-label>
            <input matInput formControlName="phone" >
            </mat-form-field>

            <div class="full" *ngIf="state.section === 'admin'">
                <mat-checkbox formControlName="force2fa" color="accent">
                    Force use 2FA
                </mat-checkbox>
            </div>

        </div>

        <div class="group flex" formGroupName="settings">
            <mat-form-field class="full">
                <mat-label>Default route</mat-label>
                <mat-select formControlName="default_route" >
                    <mat-option value="dashboard">Dashboard</mat-option>
                    <mat-option value="orders">Orders</mat-option>
                    <mat-option value="inspections">Inspections</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="full">
                <mat-label>Threepl default route</mat-label>
                <mat-select formControlName="threepl_default_route" >
                    <mat-option value="dashboard">Dashboard</mat-option>
                    <mat-option value="warehouses">Warehouses</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="full">
                <mat-label>Table elements N of per page</mat-label>
                <mat-select formControlName="default_per_page" >
                    <mat-option [value]="3">3</mat-option>
                    <mat-option [value]="5">5</mat-option>
                    <mat-option [value]="10">10</mat-option>
                    <mat-option [value]="25">25</mat-option>
                    <mat-option [value]="50">50</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="group flex">
            <div class="half">
                <button type="button" mat-raised-button color="accent"
                        (click)="uploadAvatar()">
                    Upload avatar
                </button>
            </div>
            <div class="half">
                <img [src]="proForm.value.avatar" alt="">
            </div>
        </div>

        <div class="group flex">
            <div class="full" *ngIf="state.section === 'admin'">
                <mat-checkbox formControlName="force_change_password" color="accent">
                    Force logout and change password on next login
                </mat-checkbox>
            </div>
        </div>


        <div class="actions">

            <button type="button" mat-raised-button color="accent"
                    (click)="changePassword()">
                Change password
            </button>

            <button *ngIf="state.section === 'admin'" color="accent" type="button" mat-raised-button
                    (click)="reset2fa()">
                Reset 2FA
            </button>

            <button *ngIf="state.section === 'admin'" color="warn" type="button" mat-raised-button (click)="disable()">
                Remove user relations
            </button>
        </div>

        <div class="actions">
            <button type="submit" mat-raised-button class="accent" [disabled]="!proForm.valid">
                Save
            </button>

            <button type="button" mat-raised-button (click)="prepareProfile()">
                Cancel
            </button>


        </div>
    </form>
</mat-card>

<common-confirm></common-confirm>