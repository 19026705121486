import {Injectable, isDevMode} from "@angular/core";
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {StorageService} from "./storage.service";
import {ToastService} from "./toast.service";

import {environment} from "../../../environments/environment";
import {AppLockService} from "./app-lock.service";
import {SpinnerService} from "./spinner.service";

export namespace Api {

    export interface IResponse {
        code: number;
        type: Api.EResponseType;
        data: any;
        error: any;
        message?: string;
        id?: string;
    }

    export enum EResponseType {
        error = "error",
        success = "success",
        warning = "warning"
    }

    export enum EMethod {
        Delete = "DELETE",
        Get = "GET",
        Post = "POST",
        Put = "PUT",
        Patch = "PATCH"
    }

}

@Injectable()
export class ApiService {


    /**
     * Default headers map
     * @type {{Object}}
     */
    private staticHeaders: Object = {
        "Accept": "application/json",
        "Content-Type": "application/json; charset=UTF-8"
    };

    /**
     * Host url
     * @type {string}
     */
    private host: string = environment.apiUrl;

    /**
     * Api paths map
     * @type {any}
     */
    private paths: any = {

        /**
         * Authorization
         */
        token: this.host + "/oauth/token",

        /**
         * User
         */
        user: this.host + "/api/v2/user",

        /**
         * Logout
         */
        logout: this.host + "/api/v2/user/logout",

        /**
         * Announcement
         */
        announcement: this.host + "/api/v2/announcement",


        /**
         * Address
         */
        address: this.host + "/api/v2/address",

        /**
         * Contact
         */
        contact: this.host + "/api/v2/contact",

        /**
         * Contract
         */
        contract: this.host + "/api/v2/contract",
        /**
         * Contract
         */
        contracts: this.host + "/api/v2/contracts",

        /**
         * Customer
         */
        customer: this.host + "/api/v2/customer",

        /**
         * Customer account
         */
        customer_account: this.host + "/api/v2/customer_account",

        /**
         * Dashboard
         */
        dashboard: this.host + "/api/v2/dashboard",

        /**
         * Order
         */
        order: this.host + "/api/v2/order",

        /**
         * Order item
         */
        order_item: this.host + "/api/v2/order_item",

        /**
         * Hub
         */
        hub: this.host + "/api/v2/hub",

        /**
         * Inventory
         */
        inventory: this.host + "/api/v2/inventory",

        /**
         * Partmaster
         */
        partmaster: this.host + "/api/v2/partmaster",

        /**
         * Partner
         */
        partner: this.host + "/api/v2/partner",

        /**
         * ThreePL
         */
        threepl: this.host + "/api/v2/threepl",

        /**
         * Warehouse
         */
        warehouse: this.host + "/api/v2/warehouse",

        /**
         * Status
         */
        status: this.host + "/api/v2/status",

        /**
         * Parcel
         */
        parcel: this.host + "/api/v2/parcel",


        /**
         * Transaction
         */
        transaction: this.host + "/api/v2/transaction",

        /**
         * Courier
         */
        courier: this.host + "/api/v2/courier",

        /**
         * Service level
         */
        service_level: this.host + "/api/v2/service_level",

        /**
         * Drawer
         */
        drawer: this.host + "/api/v2/drawer",

        /**
         * Coverage
         */
        coverage: this.host + "/api/v2/coverage",

        /**
         * Courier service
         */
        courier_service: this.host + "/api/v2/courier_service",

        /**
         * Shipping mode
         */
        shipping_mode: this.host + "/api/v2/shipping_mode",

        /**
         * Order type
         */
        order_type: this.host + "/api/v2/order_type",

        /**
         * Shipment
         */
        shipment: this.host + "/api/v2/shipment",

        /**
         * Shipping
         */
        shipping: this.host + "/api/v2/shipping",

        /**
         * Service level tracking
         */
        service_level_tracking: this.host + "/api/v2/service_level_tracking",

        /**
         * Remarks
         */
        remark: this.host + "/api/v2/remark",

        /**
         * Configuration
         */
        configuration: this.host + "/api/v2/configuration",

        /**
         * Timeline
         */
        timeline: this.host + "/api/v2/timeline",

        /**
         * Role
         */
        role: this.host + "/api/v2/role",

        /**
         * Release
         */
        release: this.host + "/api/v2/release",

        /**
         * Permission
         */
        permission: this.host + "/api/v2/permission",

        /**
         * Inspection
         */
        inspection: this.host + "/api/v2/inspection",

        /**
         * Inventory allocation
         */
        inventory_allocation: this.host + "/api/v2/inventory_allocation",

        /**
         * Report
         */
        report: this.host + "/api/v2/report",

        /**
         * Model field
         */
        model_field: this.host + "/api/v2/model_field",

        /**
         * Integration
         */
        integration: this.host + "/api/v2/integration",

        /**
         * Request
         */
        request: this.host + "/api/v2/request",

        /**
         * Box
         */
        box: this.host + "/api/v2/box",

        /**
         * Warehouse location
         */
        warehouse_location: this.host + "/api/v2/warehouse_location",

        /**
         * Team
         */
        team: this.host + "/api/v2/team",

        /**
         * Warehouse package
         */
        warehouse_package: this.host + "/api/v2/warehouse_package",

        /**
         * Coverage
         */
        logs: this.host + "/api/v2/http_logs",

        /**
         * Http Logs
         */
        httplogs: this.host + "/api/v2/http_logs",

        /**
         * CSV import
         */
        csv_import: this.host + "/api/v2/csv_import",

        /**
         * Activities
         */
        activities: this.host + "/api/v2/activities",

        /**
         * Activities
         */
        notifications: this.host + "/api/v2/notifications",

        pickup_request: this.host + "/api/v2/pickup_request",

        "auto-followups": this.host + "/api/v2/auto-followups",

        jobs: this.host + "/api/v2/jobs",

        serial: this.host + "/api/v2/serial",

        "service-request": this.host + "/api/v2/service-request",

        "knowledge_center": this.host + "/api/v2/knowledge_center",

        "milestones": this.host + "/api/v2/milestones",

        "modix_resellers": this.host + "/api/v2/modix_resellers",

        "custom_labels": this.host + "/api/v2/custom_labels",

        "maps": this.host + "/api/v2/maps",

        "batches": this.host + "/api/v2/batches",

        "logs_emails": this.host + "/api/v2/logs/emails",
    };

    /**
     * Partner slug
     * @return {string}
     */
    private _partner: any = null;

    private _headers: any = null;

    private onErrorCallback: any;

    /**
     * Getter for Partner slug
     * @return {string}
     */
    public get partner(): string {
        if (this._partner) {
            const partner: string = this._partner;
            this._partner = null;
            return partner;
        }
        if (this.activatedRoute.firstChild && this.activatedRoute.firstChild.snapshot) {
            return this.activatedRoute.firstChild.snapshot.params.section;
        }
    }

    /**
     * Authorization token
     * @return {string}
     */
    private get token(): string {
        return this.storageService.get("token");
    }

    private logoutTimer: any;

    public constructor(
        private httpClient: HttpClient,
        private activatedRoute: ActivatedRoute,
        private storageService: StorageService,
        private toastService: ToastService,
        private spinnerService: SpinnerService,
        private router: Router,
        private lockService: AppLockService
    ) {
    }

    private logoutSequence(): void {
        /*if (!environment.prodServer.includes(window.location.hostname)) {
            return;
        }*/

        clearTimeout(this.logoutTimer);
        this.logoutTimer = setTimeout((): void => {
            this.request(Api.EMethod.Get, "logout");
            this.storageService.clean();
            this.router.navigateByUrl("/");
            this.toastService.show("App was logged out due long inactivity", "success");
            clearTimeout(this.logoutTimer);
        }, 3600000 * 6);
    }

    public setOnErrorCallback(callback: any): void {
        this.onErrorCallback = callback;
    }

    /**
     * Setter for Partner slug
     * @return {string}
     */
    public setPartner(value: string): void {
        this._partner = value;
    }

    /**
     * Setter for one time headers
     * @param headers
     */
    public setHeaders(headers: { [key: string]: string }): void {
        this._headers = headers;
    }

    /**
     * Get prepared http headers
     * @param {boolean} asObject
     * @returns {HttpHeaders | Object}
     */
    public getHeaders(asObject?: boolean): HttpHeaders | Object {
        const headers: Object = {...this.staticHeaders};
        if (this._headers) {
            for (const key of Object.keys(this._headers)) {
                headers[key] = this._headers[key];
            }
            this._headers = null;
        }
        const partner: string = this.partner;
        if (partner && partner.indexOf("/") !== -1) {
            const [key, val]: string[] = partner.split("/");
            const Key: string = key.charAt(0).toUpperCase() + key.substr(1, key.length - 1);
            headers[Key] = val;
        } else if (partner) {
            headers["Partner"] = partner;
        }
        if (this.token) {
            headers["Authorization"] = this.token;
        }

        headers["Front-Referer"] = window.location.href;

        if (asObject) {
            return headers;
        }
        let httpHeaders: HttpHeaders = new HttpHeaders();
        Object.keys(headers).forEach((name: string): any =>
            name && (httpHeaders = httpHeaders.set(name, headers[name])));
        return httpHeaders;
    }

    /**
     * Get prepared http url params
     * @param {Object} params
     * @returns {HttpParams}
     */
    public getParams(params?: Object): HttpParams {
        let httpParams: HttpParams = new HttpParams();
        if (params) {
            for (const name of Object.keys(params)) {
                if (typeof params[name] === "object") {
                    if (params[name]) {
                        for (const i of Object.keys(params[name])) {
                            httpParams = httpParams.set(name + "[" + i + "]", params[name][i]);
                        }
                    }
                } else {
                    httpParams = httpParams.set(name, params[name]);
                }
            }
        }
        return httpParams;
    }

    /**
     * Get prepare url
     * @param {string | string[]} url
     * @returns {string}
     */
    public getUrl(url: string | string[] = ""): string {
        if (Array.isArray(url)) {
            url[0] = this.getUrl(url[0]);
            return url.join("/");
        } else {
            return !url.startsWith("http") ? this.paths[url] : url;
        }
    }

    /**
     * Prepare http error response (with console output & toast message)
     * @param {HttpErrorResponse} response
     * @returns {Api.IResponse}
     */
    public prepareErrorResponse({error}: HttpErrorResponse): Api.IResponse {

        this.spinnerService.hide();

        if (error.code === 418) {
            this.lockService.unlock();
            this.router.navigateByUrl("/change-password");
            return;
        }

        const message: string = (error && typeof error !== "string" && !(error instanceof ProgressEvent) && !error.text)
            ? `${error.message}<br>
                ${error.data && Array.isArray(error.data) ? error.data.join("<br>") : ""}`
            : "Request error";

        if (!error.code || error.code === 500 || !error.type) {
            this.toastService.show({message, request: {type: "error"}}, "error");
        } else {
            this.toastService.show(message, error.type, this.onErrorCallback);
        }

        this.onErrorCallback = null;

        if (error.code === 401 || error.code === 403) {
            this.lockService.unlock();
            this.router.navigateByUrl("/");
        }

        if (error.code === 401) {
            this.storageService.clean();
        }

        return {data: null, error, message: error.message, type: Api.EResponseType.error, code: error.code};
    }

    /**
     * Prepare http success response (with toast message)
     * @param {HttpResponse<any>} response
     * @return {Api.IResponse}
     */
    public prepareSuccessResponse({body, headers}: HttpResponse<any>): Api.IResponse {
        this.onErrorCallback = null;

        return {
            data: (body && body.data ? body.data : body),
            error: null,
            message: (body && body.message ? body.message : ""),
            type: (body && body.type ? body.type : ""),
            code: (body && body.code ? body.code : 200),
            id: headers.get("Request-Id")
        };
    }

    /**
     * Send request (via GET, POST, PUT, DELETE methods)
     * @param {Api.EMethod} method
     * @param {string | string[]} url
     * @param {Object} body
     * @param {Object} params
     * @return {Promise<Api.IResponse>}
     */
    public async request(method: Api.EMethod, url?: string | string[],
                         body?: Object, params?: Object
    ): Promise<Api.IResponse> {
        url = this.getUrl(url);
        params = this.getParams(params) as HttpParams;
        const headers: HttpHeaders = this.getHeaders() as HttpHeaders;
        const options: Object = {body: body || {}, headers, observe: "response", params, responseType: "json"};

        this.logoutSequence();

        try {
            const response: HttpResponse<any> =
                await this.httpClient.request(method as string, url, options).toPromise() as any;
            return this.prepareSuccessResponse(response);
        } catch (response) {
            if (isDevMode()) {
                console.error(method, url, response);
            }
            return this.prepareErrorResponse(response);
        }

    }

}
