import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ConfirmComponent} from "../../../../../../../common/components/confirm/confirm.component";
import {Modal} from "../../../../../../services/modal.service";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {Contact} from "../../../../../../../common/interfaces/contact.interface";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {AppStateService} from "../../../../../../../common/services/app-state.service";
import {Api3Service} from "../../../../../../../common/services/api3.service";

@Component({
    selector: "section-shipment-form",
    templateUrl: "form.component.html",
    styleUrls: [
        "form.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})

export class ShipmentFormComponent implements OnInit {

    private orderType: string;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    /**
     * Form group
     * @type {FormGroup}
     */
    public formGroup: FormGroup = new FormGroup({
        order_id: new FormControl(null),
        ref: new FormControl(null, [Validators.required]),
        address_from: new FormControl(null, [Validators.required]),
        address_to: new FormControl(null, [Validators.required]),
        weight: new FormControl(null, [Validators.required]),
        mass_unit: new FormControl("kg", [Validators.required]),
        type: new FormControl(null, [Validators.required])
    });

    public modal: Modal.IModal;

    /**
     * Window title
     * @type {string}
     */
    public window_title: string;

    public from_address: Contact.IAddress[] = [];
    public to_address: Contact.IAddress[] = [];

    public massUnits: string[] = [
        "kg"
    ];

    public get isAddressesInvalid(): any {
        return this.orderType !== "transfer"
            && this.formGroup.get("address_to").value === this.formGroup.get("address_from").value;
    }

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private spinnerService: SpinnerService
    ) {
    }

    /**
     * Set form values (modal params data)
     * @returns {void}
     */
    private setFormValues(): void {

        if (this.modal.params.shipment) {
            this.formGroup.patchValue(this.modal.params.shipment);
        } else {
            this.formGroup.get("mass_unit").setValue("kg");
            this.formGroup.get("order_id").setValue(this.modal.params.order_id);
        }
    }

    private async getOrderAddresses(): Promise<any> {
        this.spinnerService.show();

        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `${AppStateService.getState().section}/orders/${this.modal.params.order_id}`
            + `/addresses/${this.modal.params.type}`);

        this.spinnerService.hide();
        if (data) {
            this.from_address = data["from_address"];
            this.to_address = data["to_address"];
            this.orderType = data["order_type"];

            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Add shipment (prepare & add modal response)
     */
    public async save(): Promise<any> {
        this.spinnerService.show();

        let response: Api.IResponse;
        if (this.modal.params.action === "edit" && this.modal.params.shipment) {
            response = await this.apiService.request(Api.EMethod.Put,
                ["shipment", this.modal.params.shipment.id], this.formGroup.value);
        } else if (this.modal.params.action === "add") {
            response = await this.apiService.request(Api.EMethod.Post, ["shipment"], this.formGroup.value);
        }

        this.spinnerService.hide();

        if (response) {
            this.toastService.show(response.message, response.type as string);
            if (response.type as string === "success") {
                if (this.modal.params.action === "edit") {
                    this.modal.response.next({
                        name: "value",
                        value: {
                            shipment: response.data[0],
                            action: "edit"
                        }
                    });
                } else if (this.modal.params.action === "add") {
                    this.modal.response.next({
                        name: "value",
                        value: {
                            shipment: response.data[0],
                            action: "add"
                        }
                    });
                }
            }
        }
    }

    /**
     * Delete shipment after confirmation
     * @returns {Promise<any>}
     */
    public async delete(): Promise<any> {
        if (await this.confirmRef.confirm(`Do you want to delete shipment?`)) {
            this.spinnerService.show();
            const response: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
                ["shipment", this.modal.params.shipment.id]);
            this.spinnerService.hide();
            if (response) {
                this.toastService.show(response.message, response.type as string);
                if (response.type as string === "success") {
                    this.modal.response.next({
                        name: "value",
                        value: {
                            shipment: this.modal.params.shipment,
                            action: "delete"
                        }
                    });
                }
            }
        }
    }

    public ngOnInit(): void {
        this.window_title = this.modal.params.action === "edit" ? "Edit shipment" : "Add shipment";
        this.getOrderAddresses();
        this.setFormValues();
        this.formGroup.get("type").setValue(this.modal.params.type);

        this.changeDetectorRef.markForCheck();
    }

}
