import {ChangeDetectionStrategy, ChangeDetectorRef,
    Component, OnDestroy, ViewEncapsulation} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../common/interfaces/wizard.interface";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {HelpersService} from "../../../../../../../common/services/helpers.service";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {ModalService} from "../../../../../../services/modal.service";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {debounceTime, takeUntil} from "rxjs/operators";
import {Warehouse} from "../../../../../../../common/interfaces/warehouse.interface";
import {ENTER} from "@angular/cdk/keycodes";
import {MatChipInputEvent} from "@angular/material/chips";

@Component({
    selector: "section-partner-procedures-wizard-remove-serial",
    templateUrl: "remove-serial.component.html",
    styles: [
        `
                     section-partner-procedures-wizard-remove-serial .available-serial-list {
                         max-height: 500px;
                         overflow: auto;
                         padding: 5px;
                     }

                     section-partner-procedures-wizard-remove-serial .available-serial-list .mat-chip-list-wrapper {
                         justify-content: space-evenly;
                     }

                     section-partner-procedures-wizard-remove-serial .available-serial-list .mat-chip,
                     section-partner-procedures-wizard-remove-serial .available-serial-list
                     .mat-chip:not(.mat-basic-chip) + .mat-chip:not(.mat-basic-chip) {
                         margin: 0 2px 4px !important;
                         font-family: monospace;
                         cursor: pointer;
                     }
                 `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class PartnerProceduresWizardRemoveSerialComponent extends AbstractWizardStepComponent implements OnDestroy {

    private state: Base.IState;

    private serials: string[] = [];

    public hubs: { group: string, hubs: Warehouse.IHub[] }[] = [];

    public available_serials: string[] = [];

    public serialFilter: FormControl = new FormControl(null);


    public form: FormGroup = new FormGroup({
        hub: new FormControl(null, [Validators.required]),
        part_master_id: new FormControl(null, [Validators.required]),
        serials: new FormControl([], [Validators.required]),
        remark: new FormControl(null, [Validators.required]),
    });

    public partMasters: { name: string, value: any }[] = [];

    public separatorKeysCodes: number[] = [ENTER];

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        public helperService: HelpersService,
        private apiService: ApiService,
        private toastService: ToastService,
        private modalService: ModalService,
        private spinnerService: SpinnerService
    ) {
        super(changeDetectorRef);
    }

    private filterSerials(searchVal: string = null): void {
        let serials: string[] = this.serials;
        const selected: string[] = this.form.get("serials").value;
        serials = serials.filter((serial: string) => {
            return !selected.includes(serial);
        });

        if (searchVal) {
            serials = serials.filter((serial: string) => {
                return serial.toLocaleLowerCase().includes(searchVal.toLocaleLowerCase());
            });
        }
        this.available_serials = serials.filter((serial: string) => {
            return !selected.includes(serial);
        });
        this.changeDetectorRef.markForCheck();
    }

    public setItem(id: number): void {
        this.form.get("serials").reset([]);
        const item = this.partMasters.find((item: any) => {
            return item.value == id;
        });
        if (item) {
            this.form.get("part_master_id").setValue(item.value);
            this.getSerials(this.form.get("hub").value, item.name);
        }
        this.changeDetectorRef.markForCheck();
    }

    public async getPartMasters(inventory_conversion_id: number): Promise<any> {
        this.spinnerService.show();
        const {data, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["partmaster"], {},
            {
                no_serial: false,
                available_in_hubs: [inventory_conversion_id]
            });
        this.spinnerService.hide();
        if (data) {
            this.toastService.show(message, "success");
            this.partMasters = [];

            for (const row of data) {
                this.partMasters.push({
                    name: row.item,
                    value: row.id
                });
            }
            this.changeDetectorRef.markForCheck();
        }
    }

    public async getSerials(inventory_conversion_id: number, item: string): Promise<any> {
        this.spinnerService.show();
        const {data, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["inventory"], {},
            {
                hubs: [inventory_conversion_id],
                available_only: true,
                serialized: true,
                search_by: item,
                search_in: ["item"],
            });
        if (data) { 
            this.serials = data.map((item: any) => {
                return item.serial;
            });
            this.available_serials = this.serials;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    public selectSerial(serial: string): void {
        const selected: string[] = this.form.get("serials").value;
        if (!selected.includes(serial)) {
            selected.push(serial);
            this.form.get("serials").setValue(selected);
            this.filterSerials();
        }
        this.changeDetectorRef.markForCheck();
    }

    public searchSerial(event: MatChipInputEvent): void {
        const serials: string[] = event.value.split(/[,\s;]+/);

        const selected: string[] = this.form.get("serials").value;
        for (const val of serials) {
            if (val && !selected.includes(val.trim())) {
                selected.push(val.trim());
            }
        }
        this.form.get("serials").setValue(selected);
        this.serialFilter.reset();
        this.changeDetectorRef.markForCheck();

    }

    public removeSerial(serial: string): void {
        const selected: string[] = this.form.get("serials").value;

        const index = selected.indexOf(serial);

        if (index > -1) {
            selected.splice(index, 1);
            this.form.get("serials").setValue(selected);
        }
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Submit form
     */
    public async submit(): Promise<any> {
        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["inventory", "serial-remove"], this.form.value);
        if (code === 200) {
            this.toastService.show(message, "success");
            this.form.reset();
            this.partMasters = [];
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Initialize step
     * @param data
     * @returns {Promise<any>}
     */
    public async init(data: Wizard.IData): Promise<any> {
        this.state = data.state;

        this.result.emit({
            action: "result",
            value: true
        });

        this.form.get("hub").valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((val: number): void => {
                this.form.get("part_master_id").setValue(null);
                this.form.get("serials").reset([]);
                this.serials = [];
                if (val) {
                    this.getPartMasters(val);
                }
                this.changeDetectorRef.markForCheck();
            });

        this.form.get("serials").valueChanges.pipe(takeUntil(this.destroy$), debounceTime(100))
            .subscribe((val: string[]): void => {
                if (!this.form.value.part_master_id) {
                    return;
                }
                this.filterSerials(this.serialFilter.value);
            });

        this.serialFilter.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(100))
            .subscribe((val: string): void => {
                if (!this.form.value.part_master_id || !val) {
                    return;
                }
                this.filterSerials(val);
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
