import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {Schema, Validator, ValidatorResult} from "jsonschema";
import {Api, ApiService} from "./api.service";
import {StorageService} from "./storage.service";
import {ToastService} from "./toast.service";
import {Observable, Subject} from "rxjs";

export namespace Login {

    export interface IToken {
        access_token: string;
        token_type: string;
    }

    export const TokenSchema: Schema = {
        properties: {
            access_token: {type: "string"},
            token_type: {type: "string"}
        },
        required: ["access_token", "token_type"],
        type: "object"
    };

}

@Injectable()
export class LoginService {

    private loginSubject: Subject<boolean> = new Subject();

    public loginState: Observable<boolean> = this.loginSubject.asObservable();

    /**
     * Current token
     * @returns {string}
     */
    public get token(): string {
        return this.storageService.get("token");
    }

    public constructor(private router: Router, private apiService: ApiService,
                       private storageService: StorageService, private toastService: ToastService
    ) {
    }

    /**
     * Save token to storage & return availability status
     * @param {Login.IToken} data
     * @returns {boolean}
     */
    public setToken(data: Login.IToken): boolean {
        const validator: Validator = new Validator();
        const result: ValidatorResult = validator.validate(data, Login.TokenSchema);
        if (result.valid) {
            this.storageService.set("token", data.token_type + " " + data.access_token);
            this.loginSubject.next(true);
            return true;
        }
        this.loginSubject.next(false);
        return false;
    }

    /**
     * Logout user & remove user data & auth credentials
     * @returns {Promise<any>}
     */
    public async logout(): Promise<any> {
        const timeout: any = setTimeout((): void => {
            this.storageService.clean();
            this.loginSubject.next(false);
            this.router.navigateByUrl("/");
            clearTimeout(timeout);
        });
        const {data, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, "logout");
        if (data && message) {
            this.toastService.show(message, "success");
        }
    }

}
