<h1 class="container-heading">Add tracking number</h1>
<mat-card>
    <mat-card-content>
        <form *ngIf="formGroup" [formGroup]="formGroup" autocomplete="off" (submit)="submit()">
            <div class="group">
                <div class="full">
                    <div class="shipment" *ngIf="shipment">
                        <div>
                            <mat-icon>airplanemode_active</mat-icon>
                            {{ shipment.ref }}
                            ({{ shipment.weight }}{{ shipment.mass_unit }})
                        </div>

                        <div>
                            From:
                            <ng-template [ngIf]="shipment.from_address.formatted_address">
                                {{ shipment.from_address.formatted_address }},
                            </ng-template>
                            <ng-template [ngIf]="!shipment.from_address.formatted_address">
                                {{ shipment.from_address.address }},
                                {{ shipment.from_address.city }},
                                {{ shipment.from_address.country }}
                            </ng-template>

                        </div>

                        <div>
                            To:
                            <ng-template [ngIf]="shipment.to_address.formatted_address">
                                {{ shipment.to_address.formatted_address }},
                            </ng-template>
                            <ng-template [ngIf]="!shipment.to_address.formatted_address">
                                {{ shipment.to_address.address }},
                                {{ shipment.to_address.city }},
                                {{ shipment.to_address.country }}
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="group flex">
                <mat-form-field class="full tracNumber">
                    <mat-label>Tracking number</mat-label>
                    <input matInput formControlName="tracking_number" required>
                </mat-form-field>

                <div class="full" *ngIf="suggestedcourier">
                    <small><a class="pointer" (click)="addCourier()">+Suggested
                        courier: {{ suggestedcourier }}</a></small>
                    <br>
                    <br>
                </div>

                <div class="half">
                    <common-form-select [options]="couriers"
                                        [search]="true"
                                        [required]="true"
                                        label="Courier"
                                        [value]="formGroup.value.courier_slug"
                                        (valueChange)="formGroup.get('courier_slug').setValue($event)"
                    ></common-form-select>
                </div>

                <mat-form-field class="half">
                    <mat-label>Courier service</mat-label>
                    <mat-select formControlName="courier_service_slug" required>
                        <mat-option *ngFor="let service of courierServices" [value]="service.slug">
                            {{ service.service_name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>


                <div class="half">
                    <common-form-date-time
                            label="Ship date"
                            (valueChange)="formGroup.get('shipped_at').setValue($event)"
                    ></common-form-date-time>
                </div>

                <div class="half">
                    <common-form-select
                            label="Ship date timezone"
                            [options]="timezones"
                            [search]="true"
                            [required]="true"
                            [value]="formGroup.value.shipped_at_tz"
                            (valueChange)="formGroup.get('shipped_at_tz').setValue($event)"
                    ></common-form-select>
                </div>

                <div class="full">
                    <common-form-date-time
                            label="Creation date"
                            (valueChange)="formGroup.get('created_at').setValue($event)"
                    ></common-form-date-time>
                </div>

                <div class="full">
                    <common-form-date-time
                            label="ETA"
                            (valueChange)="formGroup.get('eta').setValue($event)">
                    </common-form-date-time>
                </div>
            </div>


            <div class="actions">
                <button type="button" mat-raised-button (click)="returnToOrder()">
                    Return to order
                </button>
                &nbsp;
                &nbsp;
                <button [disabled]="!formGroup.valid" mat-raised-button color="primary" type="submit">Submit
                </button>
            </div>
        </form>
    </mat-card-content>
</mat-card>


