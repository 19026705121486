import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges, ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {debounceTime, distinctUntilChanged, takeUntil} from "rxjs/operators";
import {HelpersService} from "../../../services/helpers.service";
import * as moment from "moment";
import {Moment} from "moment";
import {FormControl, FormGroup} from "@angular/forms";
import {NgxMatDatetimePicker} from "@angular-material-components/datetime-picker";

export interface DateRangeMomentValue {
    start: Moment;
    end: Moment;
}

export interface DateRangeStringValue {
    start: string;
    end: string;
}

@Component({
    selector: "common-form-date-range",
    template: `
        <div [formGroup]="rangeForm" [class.group]="inline" [class.flex]="inline" class="space-between">
            <mat-form-field (click)="startPicker.open()" class="half min margin-right-10">
                <mat-label>{{ label }} from</mat-label>
                <input matInput
                       [ngxMatDatetimePicker]="startPicker"
                       formControlName="start"
                       [required]="required"
                       [min]="min"
                       readonly>
                <mat-icon matSuffix>today</mat-icon>
                <ngx-mat-datetime-picker #startPicker [touchUi]="true" [showSpinners]="show_time"
                                         [defaultTime]="[0,0,0]">
                    <ng-template>
                        <span>Apply</span>
                    </ng-template>
                </ngx-mat-datetime-picker>
            </mat-form-field>
            <mat-form-field (click)="endPicker.open()" class="half min">
                <mat-label>{{ label }} to</mat-label>
                <input matInput
                       [ngxMatDatetimePicker]="endPicker"
                       formControlName="end"
                       [required]="required"
                       [min]="rangeForm.value.start ? rangeForm.value.start.toDate() : null"
                       [max]="max"
                       readonly>
                <mat-icon matSuffix>event</mat-icon>
                <ngx-mat-datetime-picker #endPicker [touchUi]="true" [showSpinners]="show_time"
                                         [defaultTime]="[23,59,59]">
                    <ng-template>
                        <span>Apply</span>
                    </ng-template>
                </ngx-mat-datetime-picker>
            </mat-form-field>
        </div>
    `,
    styles: [
        `common-form-date-range .group {
            margin-bottom: 0 !important;
        }`
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class CommonFormDateRangeComponent implements OnInit, OnDestroy, OnChanges {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    private lastValidValue: string;

    public format: string = "YYYY-MM-DD HH:mm:ss";

    public rangeForm: FormGroup = new FormGroup({
        start: new FormControl(null),
        end: new FormControl(null)
    });

    @Input()
    public label: string = "Date";

    @Input()
    public value: DateRangeMomentValue = {
        start: null,
        end: null
    };

    @Input()
    public required: boolean = false;

    @Input()
    public show_time: boolean = true;

    @Output()
    public valueChangeObject: EventEmitter<DateRangeStringValue> = new EventEmitter();

    @Output()
    public valueChange: EventEmitter<string> = new EventEmitter();

    @Output()
    public valueChangeMoment: EventEmitter<DateRangeMomentValue> = new EventEmitter();

    @Input()
    public min_date: string;

    @Input()
    public max_date: string;

    @Input()
    public inline: boolean = true;

    @ViewChild("startPicker", {static: true})
    public startPicker: NgxMatDatetimePicker<any>;

    @ViewChild("endPicker", {static: true})
    public endPicker: NgxMatDatetimePicker<any>;

    public min: Date = null;

    public max: Date = null;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        public helpers: HelpersService
    ) {
    }


    public ngOnInit(): void {

        if (this.min_date) {
            this.min = new Date(this.min_date);
        }

        if (this.max_date) {
            this.max = new Date(this.max_date);
        }

        this.rangeForm.patchValue(this.value);

        this.changeDetectorRef.markForCheck();

        this.rangeForm.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(100))
            .subscribe((val: any): void => {

                if (this.startPicker.opened || this.endPicker.opened) {
                    return;
                }

                if (val.start && val.start.isValid() && val.end && val.end.isValid()) {
                    if (val.start.isAfter(val.end)) {
                        this.rangeForm.get("end").reset();
                        return;
                    }

                    const stringValue: string = val.start.format(this.format) + " ~ " + val.end.format(this.format);

                    if (stringValue === this.lastValidValue) {
                        return;
                    }

                    this.lastValidValue = stringValue;
                    this.valueChange.emit(this.lastValidValue);

                    this.valueChangeObject.emit({
                        start: val.start.format(this.format),
                        end: val.end.format(this.format)
                    });

                    this.valueChangeMoment.emit({
                        start: val.start,
                        end: val.end
                    });
                    this.changeDetectorRef.markForCheck();

                }
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.min_date && !changes.min_date.firstChange && changes.min_date.currentValue) {
            this.min = new Date(changes.min_date.currentValue);
        }

        if (changes.max_date && !changes.max_date.firstChange && changes.max_date.currentValue) {
            this.max = new Date(changes.max_date.currentValue);
        }

        if (changes.value && !changes.value.firstChange) {
            let start: string;
            let end: string;
            if (!changes.value.currentValue) {
                this.rangeForm.reset();
            } else if (typeof changes.value.currentValue === "string") {
                [start, end] = changes.value.currentValue.split(" ~ ");
            } else {
                start = changes.value.currentValue.start;
                end = changes.value.currentValue.end;
            }
            if (start && !this.rangeForm.value.start?.isSame(start)) {
                this.rangeForm.get("start").setValue(moment(start));
            }

            if (end && !this.rangeForm.value.end?.isSame(end)) {
                this.rangeForm.get("end").setValue(moment(end));
            }
        }
    }
}
