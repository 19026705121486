import {ChangeDetectionStrategy, Component, ViewEncapsulation} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";


@Component({
    selector: "section-requests-list-all",
    template: `
        <h1 class="container-heading">IT Requests</h1>
        <mat-card>
            <mat-card-content>
                <section-requests-list [state]="state" [type]="['it']"></section-requests-list>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class ItRequestsListComponent implements Base.IComponent {


    public readonly state: Base.IState;

    public constructor() {
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "support-requests-it",
            actions: {
                "browse": ["browse_it_requests"]
            }
        };
    }
}
