import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, ViewEncapsulation} from "@angular/core";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../common/interfaces/wizard.interface";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {takeUntil} from "rxjs/operators";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {HelpersService} from "../../../../../../../common/services/helpers.service";

@Component({
    selector: "section-csv-import-wizard-map-data",
    templateUrl: "map-data.component.html",
    styles: [
        `
                     section-csv-import-wizard-map-data mat-form-field.ng-invalid .mat-mdc-select-value,
                     section-csv-import-wizard-map-data mat-form-field.ng-invalid .mat-mdc-select-arrow {
                         color: #f44336 !important;
                     }

                     section-csv-import-wizard-map-data mat-form-field.ng-invalid .mat-form-field-ripple,
                     section-csv-import-wizard-map-data mat-form-field.ng-invalid .mat-form-field-underline {
                         background-color: #f44336 !important;
                     }

                 `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class CsvImportWizardMapDataComponent extends AbstractWizardStepComponent implements OnDestroy {

    public data: Wizard.IData;

    public state: Base.IState;

    public options: {
        [key: string]: { title: string, is_required: string, related_model_column: string, description: string }[]
    };

    public templateSelect: FormControl = new FormControl("Default");

    public formGroup: FormGroup = new FormGroup({
        file_name: new FormControl(null)
    });

    public drop_existing_lines: FormControl = new FormControl(false);

    public ready: boolean = false;

    public allowTest: boolean = true;

    public mappedOptions: { name: string, value: any }[] = [];

    public showReplaceDataOption: boolean = false;

    public duplicatesList: string[] = [];

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private spinnerService: SpinnerService,
        private toastService: ToastService
    ) {
        super(changeDetectorRef);
    }

    private addControls(): void {
        for (const key of Object.keys(this.data.mapData)) {
            this.formGroup.addControl(key, new FormControl(false,
                [Validators.required, HelpersService.notFalseValidator]));
        }
        this.ready = true;
        this.changeDetectorRef.markForCheck();
    }


    private mapOptions(): void {
        this.mappedOptions = (this.options[this.templateSelect.value])
            .map((option:
                      {
                          title: string,
                          is_required: string,
                          related_model_column: string,
                          description: string
                      }): any => {
                return {
                    name: option.title + (option.is_required === "1" ? "*" : ""),
                    value: option.related_model_column
                };
            });
        this.mappedOptions.push({name: "Ignore", value: false});
        this.changeDetectorRef.markForCheck();
    }


    private async getFields(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["csv_import", this.data.wizardType]);

        this.spinnerService.hide();
        if (data) {
            this.options = data;
            this.mapOptions();
            this.changeDetectorRef.markForCheck();
            this.setTemplateValues();
        }
    }

    private setTemplateValues(template: string = "Default"): void {

        const options: { related_model_column: string, title: string }[] = this.options[template] || [];

        const fields: string[] = Object.keys(this.formGroup.value);

        for (const field of fields) {
            const field_slug: string = field.trim().toLowerCase().replace(/\s/g, "_");
            options.forEach((option: { related_model_column: string }): void => {
                if (field_slug === option.related_model_column.toLowerCase()) {
                    this.formGroup.get(field).setValue(option.related_model_column);
                } else if (field_slug.startsWith("custom_field_")) {
                    this.formGroup.get(field).setValue("custom_fields");
                }
            });
        }

        this.changeDetectorRef.markForCheck();

    }

    public setValue(row_key: string, value: string): void {
        if (this.formGroup.get(row_key)) {
            this.formGroup.get(row_key).setValue(value);
        }
    }

    public getDescription(value: string): string {
        const option: any = this.options[this.templateSelect.value]
            .find((opt: { related_model_column: string, description: string }): boolean => {
                return opt.related_model_column === value;
            });
        return option ? option.description : null;
    }


    public async testRun(): Promise<any> {
        this.spinnerService.show();
        const {code, message, data}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["csv_import", this.data.wizardType], {
                ...this.formGroup.value,
                test_data: this.data.mapData
            });
        this.spinnerService.hide();
        if (code === 200) {
            this.allowTest = false;
            this.toastService.show(message, "success");
            if (data && data.duplicates && data.duplicates.length) {
                this.showReplaceDataOption = true;
                this.duplicatesList = data.duplicates;
            }
            this.changeDetectorRef.markForCheck();

            this.result.emit({
                action: "result",
                value: {
                    mappingResult: {
                        ...this.formGroup.value,
                        drop_existing_lines: this.drop_existing_lines.value
                    }
                }
            });
        }
    }


    public async init(data: Wizard.IData): Promise<any> {
        this.state = data.state;
        this.data = data;
        this.showReplaceDataOption = false;
        this.duplicatesList = [];
        this.changeDetectorRef.markForCheck();

        this.formGroup.get("file_name").setValue(data.fileName);

        this.getFields();
        this.addControls();

        this.result.emit({
            action: "result",
            value: null
        });

        this.templateSelect.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: string): void => {
            this.mapOptions();
            this.setTemplateValues(value);
        });

        this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: string): void => {
            this.allowTest = true;
            this.changeDetectorRef.markForCheck();
            this.result.emit({
                action: "result",
                value: null
            });
        });

        this.drop_existing_lines.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: boolean): void => {
            if (this.allowTest === false) {
                this.result.emit({
                    action: "result",
                    value: {
                        mappingResult: {
                            ...this.formGroup.value,
                            drop_existing_lines: value
                        }
                    }
                });
            }
        });
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

}
