import {ChangeDetectionStrategy, Component} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";

@Component({
    selector: "section-warehouse-order-open-list",
    template: `
        <section-warehouse-order-list [state]="state" [status]="'open'"
                                      header="Open orders"></section-warehouse-order-list>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WarehouseOrderOpenListComponent implements Base.IComponent {


    public readonly state: Base.IState;

    public constructor() {
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "warehouse-orders-open",
            actions: {
                "browse": ["browse_warehouse_orders"]
            }
        };
    }

}
