import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {
    WarehouseProceduresWizardCheckPackagesComponent,
    WarehouseProceduresWizardLockdownComponent,
    WarehouseProceduresWizardOrderSearchComponent,
    WarehouseProceduresWizardOutboundScanBoxesComponent, WarehouseProceduresWizardOutboundScanComponent,
    WarehouseProceduresWizardOutboundScanDefaultComponent,
    WarehouseProceduresWizardOutboundSelectShipmentComponent,
    WarehouseProceduresWizardReleaseDocumentsComponent,
    WarehouseProceduresWizardScanDefaultResultsComponent,
    WarehouseProceduresWizardShufflePackagesComponent
} from "../../index";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {
    AbstractWizardComponent,
    Wizard,
    WizardStepFactory
} from "../../../../../../../common/interfaces/wizard.interface";
import {RemarksSidebarComponent} from "../../../../../common/components/order";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {Modal, ModalService} from "../../../../../../services/modal.service";
import {ModalComponent} from "../../../../../../../common/components/modal/modal.component";
import {WarehouseOrderWizardCourierTransactionsToShipmentComponent} from "../../../order";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {WarehouseAttachmentsListComponent} from "../common/attachments/list/list.component";
import {Api3Service} from "../../../../../../../common/services/api3.service";
import {Router} from "@angular/router";


@Component({
    selector: "section-procedures-outbound",
    templateUrl: "wizard.component.html",
    styleUrls: [
        "../common/wizard.component.scss",
        "wizard.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class WarehouseProceduresWizardOutboundComponent extends AbstractWizardComponent
    implements OnInit, AfterViewInit {

    public steps: Wizard.IStep[] = [];

    @ViewChild("sidebar", {static: false})
    public sidebarRef: RemarksSidebarComponent;

    public status: any;

    public remarksCount: number = 0;

    public attachmentsCount: number = 0;

    public readonly state: Base.IState;

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private modalService: ModalService,
        private spinnerService: SpinnerService
    ) {
        super(changeDetectorRef);
    }

    protected handleSetupEvent(event: Wizard.IStepResult, stepIndex: number): void {

        this.data.procedureType = event.value.scanType;

        if (this.steps[stepIndex].title === "Select shipment") {

            switch (event.value.scanType) {
                case "universal":
                    this.steps.splice(2);
                    this.steps.push(
                        new WizardStepFactory("Scan items", WarehouseProceduresWizardOutboundScanComponent));
                    this.steps.push(
                        new WizardStepFactory("Scan results", WarehouseProceduresWizardScanDefaultResultsComponent));

                    break;

                case "box":
                    this.steps.splice(2);
                    this.steps.push(
                        new WizardStepFactory("Scan boxes", WarehouseProceduresWizardOutboundScanBoxesComponent));

                    break;

                default:
                    this.steps.splice(2);
                    this.steps.push(
                        new WizardStepFactory("Scan items", WarehouseProceduresWizardOutboundScanDefaultComponent));
                    this.steps.push(
                        new WizardStepFactory("Scan items results",
                            WarehouseProceduresWizardScanDefaultResultsComponent));

                    break;
            }

            this.steps.push(
                new WizardStepFactory("Shuffle packages", WarehouseProceduresWizardShufflePackagesComponent));
            this.steps.push(
                new WizardStepFactory("Check packages", WarehouseProceduresWizardCheckPackagesComponent));
            this.steps.push(
                new WizardStepFactory("Release documents", WarehouseProceduresWizardReleaseDocumentsComponent));
            this.steps.push(
                new WizardStepFactory("Courier transactions",
                    WarehouseOrderWizardCourierTransactionsToShipmentComponent));
        }
    }


    /**
     * Get order remarks count
     */
    private async getRemarksCount(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `${this.state.section}/orders/${this.data.orderId}/remarks`, {}, {
                data_structure: "count"
            });
        if (data) {
            this.remarksCount = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    protected async handleLockdown(event: Wizard.IStepResult, stepIndex: number): Promise<any> {
        if (!this.isLockdown) {
            this.isLockdown = true;
            const response: Modal.IResponse = await this.modalService
                .open(WarehouseProceduresWizardLockdownComponent, {lockdown: event.value.lockdown});
            if (response && response.name === "skip") {
                this.isLockdown = false;
                delete event.value.lockdown;
            }
            this.handleStepEvent(event, stepIndex);
        }
    }

    public async getAttachmentsCount(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `${this.state.section}/orders/${this.data.orderId}/attachments`, {}, {
                data_structure: "count"
            });
        if (data) {
            this.attachmentsCount = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }


    /**
     * Show remarks in sidebar
     * @param {boolean} force
     */
    public showSidebar(force: boolean = false): void {
        if (this.data.orderRef) {
            this.sidebarRef.show(this.data.orderId, this.data.orderRef, force);
        }
    }

    public async showShipmentInstructions(): Promise<any> {
        await this.modalService.open(ModalComponent, {
            template: this.data.shipmentInstructions
        });
    }

    public async showAttachments(): Promise<any> {
        await this.modalService.open(WarehouseAttachmentsListComponent, {
            orderRef: this.data.orderRef,
            modalWidth: "95%"
        });
    }

    /**
     * Step result event handler
     * @param event
     * @param stepIndex
     */
    public async handleStepEvent(event: Wizard.IStepResult, stepIndex: number): Promise<any> {
        if (event.value && event.value.orderRef) {
            setTimeout((): void => {
                this.getRemarksCount();
                this.getAttachmentsCount();
            }, 3000);
        }
        super.handleStepEvent(event, stepIndex);
    }

    /**
     * Finish wizard and redirect back to order
     */
    public finish(): void {
        this.router.navigate([
            this.state.section,
            "warehouse-orders-open"
        ]);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.data.wizardType = "outbound";
        this.data.isOnlyView = false;

        this.steps.push(
            new WizardStepFactory("Search order", WarehouseProceduresWizardOrderSearchComponent));
        this.steps.push(
            new WizardStepFactory("Select shipment", WarehouseProceduresWizardOutboundSelectShipmentComponent));

        this.changeDetectorRef.markForCheck();
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "procedures-outbound",
            actions: {
                "browse": ["read_reports"],
                "wizard": ["read_reports"],
            }
        };
    }

}
