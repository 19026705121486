import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {UntypedFormBuilder, FormControl} from "@angular/forms";
import {Router} from "@angular/router";
import {Base} from "../../../../../../../../common/interfaces/base.interfaces";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../../common/interfaces/wizard.interface";
import {User} from "../../../../../../../../common/interfaces/user.interface";
import {Api, ApiService} from "../../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../../common/services/toast.service";
import {Modal, ModalService} from "../../../../../../../services/modal.service";
import {Warehouse} from "../../../../../../../../common/interfaces/warehouse.interface";
import {HelpersService} from "../../../../../../../../common/services/helpers.service";
import {IPagination} from "../../../../../../../../common/components/pagination/pagination.component";
import {WarehouseProceduresWizardSelectOrderItemModalComponent} from "../../..";
import {WarehouseTransactionFormComponent} from "../../../../order";
import * as jQuery from "jquery";
import {SpinnerService} from "../../../../../../../../common/services/spinner.service";
import {PusherService} from "../../../../../../../../common/services/pusher.service";
import {takeUntil} from "rxjs/operators";
import {Push} from "../../../../../../../../common/interfaces/push.interface";
import {PackageFormComponent} from "../../../../package";
import {ImageUploadService} from "../../../../../../../../common/services/image-upload.service";
import {SoundsService} from "../../../../../../../../common/services/sounds.service";
import {Api3Service} from "../../../../../../../../common/services/api3.service";

@Component({
    selector: "section-warehouse-procedures-wizard-outbound-scan-default",
    templateUrl: "scan.component.html",
    styleUrls: [
        "scan.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class WarehouseProceduresWizardOutboundScanDefaultComponent extends AbstractWizardStepComponent
    implements OnDestroy {

    private state: Base.IState;

    private warehouseOrderId: number;

    private warehouse_slug: string;

    private prevKey: string = null;

    public shipment: Warehouse.IShipment;

    @ViewChild("locationCountInput", {static: false})
    public locationCountInput: ElementRef;

    @ViewChild("searchInputRef", {static: false})
    public searchInputRef: ElementRef;

    public scannerKey: string;

    public partner: User.IPartner;

    public searchInput: FormControl = new FormControl(null);

    public searchInputLabel: string = "Item";

    public selectedPackage: FormControl = new FormControl(null);

    public packages: any[] = [];

    public locations: IPagination<any>;

    public serialTemp: string = null;
    public boxTemp: string = null;
    public locationTemp: string = null;

    public orderRef: string;

    public items: any;

    public hasItems: boolean = false;

    public scannerOnly: boolean = false;

    public isViewOnly: boolean = false;

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private modalService: ModalService,
        public soundsService: SoundsService,
        private spinnerService: SpinnerService,
        private imageUploadService: ImageUploadService,
        private pusherService: PusherService
    ) {
        super(changeDetectorRef);
    }

    /**
     * Get package list
     */
    private async getPackages(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["warehouse_package", "shipment", "" + this.shipment.id]);
        if (data) {
            this.packages = data;
            if (this.packages.length === 0) {
                this.newPackadge();
                this.soundsService.textToSpeech("Please create package");
            } else {
                this.selectedPackage.setValue(this.packages[0].id);
            }
        }
        this.spinnerService.hide();
    }

    /**
     * Get shipment
     */
    private async getShipment(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["shipment", "" + this.shipment.id]);
        if (data) {
            this.shipment = data;
            this.scannerOnly = this.shipment && this.shipment.partner
                ? this.shipment.partner.properties.disable_manual_scanning
                : false;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private async addTransactionFromPartMaster(part_master: any): Promise<any> {
        await this.modalService.open(WarehouseTransactionFormComponent, {
            action: "add",
            warehouse_order_id: this.warehouseOrderId,
            data: {part_master_id: part_master.id},
            type: "outbound",
            modalWidth: 600,
            state: this.state,
            scannerKey: this.scannerKey,
            partner: part_master.partner,
            warehouse_slug: this.warehouse_slug,
            package_id: this.selectedPackage.value,
        });

        this.getAllocationsByShipment();
    }

    private async addTransactionFromItem(item: any, total_item_quantity: number): Promise<any> {
        await this.modalService.open(WarehouseTransactionFormComponent, {
            action: "add",
            order_id: item.order_id,
            data: item,
            type: "outbound",
            modalWidth: 600,
            state: this.state,
            scannerKey: this.scannerKey,
            partner: item.partner,
            warehouse_slug: this.warehouse_slug,
            package_id: this.selectedPackage.value,
            inventory_allocation_id: item.id,
            total_item_quantity
        });

        this.getAllocationsByShipment();
    }

    private async outboundByOrderItem(item_details: any): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `${this.state.section}/shipments/${this.shipment.id}/allocations`);

        this.spinnerService.hide();
        if (data) {

            let items: Warehouse.IInventoryAllocation[] =
                data.filter((item: Warehouse.IInventoryAllocation): boolean => item.item === item_details.item);

            items = items.filter((item: Warehouse.IInventoryAllocation): boolean => item.type === "outbound");

            if (items.length === 0
                && (this.shipment
                    && this.shipment.order
                    && this.shipment.order.service_level
                    && this.shipment.order.service_level.properties.stock_mode.toLowerCase() === "free")
            ) {
                item_details.order_item_id = item_details.id;
                this.addTransactionFromItem(item_details, item_details.quantity);
            } else if (items.length === 1) {
                const item: any = items[0];
                item.partner = item_details.partner;
                this.addTransactionFromItem(item, item_details.quantity);
            } else if (items.length > 1) {
                this.selectOrderItems(items, item_details.partner, item_details.quantity);
            }
        }
    }

    private async selectOrderItems(
        items: Warehouse.IInventoryAllocation[],
        partner: User.IPartner,
        total_item_quantity: number
    ): Promise<any> {
        const response: Modal.IResponse = await this.modalService
            .open(WarehouseProceduresWizardSelectOrderItemModalComponent, {
                items,
                partner
            });
        if (response && response.value) {
            const item: any = response.value;
            item.partner = partner;
            this.addTransactionFromItem(item, total_item_quantity);
        }
    }

    private async sendAvatar(pm_id: any, image_path: any): Promise<any> {
        const {data, error, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["partmaster", pm_id, "image"], {
                image_path: image_path
            });
    }


    private subscribeToUpdates(): void {
        this.pusherService.event.pipe(takeUntil(this.destroy$)).subscribe((data: Push.IData): void => {
            if (data.job_type === "action" && data.message === "inventory updated") {
                if (data.job_id) {
                    f1:
                        for (const item of Object.keys(this.items)) {
                            for (const location of Object.keys(this.items[item].locations)) {
                                for (const allocation of this.items[item].locations[location].allocations) {
                                    if (data.job_id === allocation.id) {
                                        allocation.deleted = true;
                                        this.changeDetectorRef.markForCheck();
                                        break f1;
                                    }
                                }
                            }
                        }
                }
                this.getAllocationsByShipment();
            }
        });
    }

    public async getAllocationsByShipment(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["inventory_allocation", "by_shipment", "" + this.shipment.id]);
        this.spinnerService.hide();
        if (data) {
            this.items = data;
            this.hasItems = !!Object.keys(this.items).length;
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Upload user avatar to cloudinary
     */
    public async uploadAvatar(id: any, pm_id: any): Promise<any> {
        const image_url: string = await this.imageUploadService.uploadImage();

        if (image_url) {
            jQuery("img#pmimg-" + id).attr("src", image_url);
            if (id && pm_id) {
                this.sendAvatar(pm_id, image_url);
            }
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Listen scanner activation key event
     * @param event
     */
    public onKeydown(event: any): void {
        if (this.prevKey !== "Control" && this.prevKey !== "Shift") {
            if (event.key === "Enter" || event.key === "Tab") {
                this.find();
            }
        }

        if ((this.prevKey === "Control" || this.prevKey === "Shift" || this.prevKey === "Alt")
            && event.key === "Enter") {
            this.searchInput.setValue(this.searchInput.value + ",");
        }

        this.prevKey = event.key;
    }

    /**
     * Find box
     */
    public async find(): Promise<any> {
        this.spinnerService.show();
        const {data, error, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["box", "warehouse_order", "" + this.warehouseOrderId, "scan_default"], {
                ref: this.searchInput.value,
                package_id: this.selectedPackage.value,
                shipment_id: this.shipment.id,
                location: this.locationTemp
            }, {type: "outbound"});

        this.spinnerService.hide();
        if (error) {
            this.locationTemp = null;
        } else if (data) {
            if (data.type === "order_item") {
                this.outboundByOrderItem(data.item_details);
            } else if (data.type === "part_master") {
                this.addTransactionFromPartMaster(data.part_master_details);
            } else if (data.location) {
                this.locationTemp = data.location.location;
            }
            this.toastService.show(message, "success");
        }

        this.soundsService.textToSpeech(message);
        this.searchInput.setValue(null);
        this.searchInputRef.nativeElement.focus();
    }

    /**
     * Create new package
     */
    public async newPackadge(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(PackageFormComponent,
            {
                shipmentId: this.shipment.id,
                warehouse: this.shipment.warehouse
            });

        if (response) {
            this.getPackages();
        }
    }

    public async exportPackList(type: string): Promise<any> {
        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["inventory_allocation", "by_shipment", "" + this.shipment.id], {}, {
                export: type
            });

        this.spinnerService.hide();

        if (code === 200) {
            this.toastService.show(message, "success");
        }
    }

    /**
     * Initialize step
     * @param data
     * @returns {Promise<any>}
     */
    public async init(data: Wizard.IData): Promise<any> {
        this.state = data.state;
        this.scannerKey = data.scannerKey;
        this.shipment = data.shipment;
        this.warehouseOrderId = data.warehouseOrderId;
        this.orderRef = data.orderRef;
        this.isViewOnly = data.isViewOnly;
        const [section, warehouse_slug]: string[] = this.state.section.split("/");
        this.warehouse_slug = warehouse_slug;
        this.changeDetectorRef.markForCheck();
        this.getShipment();
        this.getPackages();
        this.getAllocationsByShipment();
        this.subscribeToUpdates();

        if (this.selectedPackage.value) {
            this.searchInputRef.nativeElement.focus();
        }
        if (this.isViewOnly) {
            this.searchInput.disable();
        }

        this.result.emit({
            action: "result",
            value: true
        });
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

}
