<div class="flex">
    <h1 class="container-heading">
        {{action | ucfirst}} followup
    </h1>
    <div class="padding-top-10 padding-left-10">
        <button type="button"
                mat-icon-button
                (click)="minimizeFollowUp()"
                matTooltip="Minimize followup">
            <mat-icon class="fa-rotate-270"> call_received</mat-icon>
        </button>
    </div>
</div>

<mat-card>
    <mat-card-content>

        <form *ngIf="formGroup" [formGroup]="formGroup" (submit)="submit()">

            <div class="group flex">

                <mat-form-field class="full">
                    <mat-label>Title</mat-label>
                    <input matInput formControlName="title" required>
                </mat-form-field>

                <div class="full" *ngIf="formGroup.get('description')">
                    <common-form-editor label="Description"
                                        [value]="editorInitialValue"
                                        (valueChange)="formGroup.get('description').setValue($event)"
                    ></common-form-editor>
                </div>

                <div class="full">
                    <button type="button" mat-raised-button class="full margin-bottom-10" (click)="uploadFile()">
                        Upload a file
                    </button>
                </div>

                <div class="full">
                    <ng-template ngFor let-file let-index="index" [ngForOf]="uploadedFile">
                        {{file.name}}
                        <button mat-icon-button matTooltip="Delete" (click)="deleteFile(index)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </ng-template>
                </div>

                <div class="third" *ngIf="teams.length">
                    <common-form-select
                            label="Assigned team"
                            [options]="teams"
                            [value]="formGroup.value.team_id"
                            [search]="true"
                            [required]="true"
                            (valueChange)="formGroup.get('team_id').setValue($event)"></common-form-select>
                </div>

                <mat-form-field class="third">
                    <mat-label>Kind</mat-label>
<mat-select formControlName="request_kind_id"  required>
                        <mat-option value="add_kind">
                            <mat-icon>add_circle</mat-icon>
                            Add new kind
                        </mat-option>
                        <mat-option *ngFor="let kind of kinds" [value]="kind.value">
                            {{kind.name}}
                        </mat-option>
                    </mat-select>
                    <span matPrefix><mat-icon>ballot</mat-icon>&nbsp;</span>
                </mat-form-field>

                <mat-form-field class="third">
                    <mat-label>Priority</mat-label>
<mat-select formControlName="priority"  required>
                        <mat-option value="Low">Low</mat-option>
                        <mat-option value="Normal">Normal</mat-option>
                        <mat-option value="High">High</mat-option>
                        <mat-option value="Critical">Critical</mat-option>
                    </mat-select>
                    <span matPrefix><mat-icon>low_priority</mat-icon>&nbsp;</span>
                </mat-form-field>

                <div class="full">
                    <common-form-date-time
                            label="Due date"
                            [value]="formGroup.value.timeline_to"
                            [min_date]="minTODate"
                            (valueChangeMoment)="formGroup.get('timeline_to').setValue($event)">
                    </common-form-date-time>
                </div>
            </div>

            <div class="actions flex row space-between">
                <button mat-raised-button color="primary" type="submit" class="main" [disabled]="!formGroup.valid">
                    {{action | ucfirst}}
                </button>
                &nbsp;
                &nbsp;
                <div>
                    <button mat-button type="button" (click)="close()">Cancel</button>
                    <button mat-button type="button" (click)="minimizeFollowUp()">Minimize</button>
                </div>
            </div>

        </form>

    </mat-card-content>
</mat-card>
