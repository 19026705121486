import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, Validators} from "@angular/forms";
import {debounceTime, distinctUntilChanged, takeUntil} from "rxjs/operators";
import {Api, ApiService} from "../../../services/api.service";
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {Form} from "../../../interfaces/form.interface";
import {Api3Service} from "../../../services/api3.service";
import {AppStateService} from "../../../services/app-state.service";
import ISelectOption = Form.ISelectOption;

@Component({
    selector: "common-form-search",
    template: `
        <div *ngIf="multiple" class="multiple">
            <mat-chip-listbox>
                <mat-chip-option matChipRemove *ngFor="let chip of _value; let i = index"
                                 [removable]="true" (removed)="remove(i)">
                    {{ chip.name }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip-option>
            </mat-chip-listbox>
        </div>
        <mat-form-field style="width: 100%">
            <mat-label>{{ label }}</mat-label>
            <input type="text" #trigger="matAutocompleteTrigger" matInput
                   [formControl]="input" [matAutocomplete]="auto" [required]="required">

            <span matSuffix *ngIf="searching">
                <i class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
            </span>
            <span matSuffix *ngIf="!searching && !required && input.value">
                <mat-icon class="pointer" (click)="clearValue()">close</mat-icon>
            </span>
        </mat-form-field>

        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)">
            <mat-option *ngFor="let option of options" [value]="option">{{ option.name }}</mat-option>
            <mat-option *ngIf="noData" [disabled]="true" [value]="null">Nothing found</mat-option>
        </mat-autocomplete>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    styles: [
        `
            common-form-search .mat-form-field {
                width: 100%;
                display: block;
            }

            common-form-search .multiple {
                margin-bottom: 10px;
            }
        `
    ]
})
export class CommonFormSearchComponent implements OnInit, OnDestroy, OnChanges {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    public searching: boolean = false;

    public input: FormControl;

    public options: ISelectOption[] = [];

    @ViewChild(MatAutocompleteTrigger, {static: false})
    public autocompleteTrigger: MatAutocompleteTrigger;

    @Input()
    public label: string;

    @Input()
    public value: string = null;

    @Input()
    public multipleValue: ISelectOption[] = [];

    public _value: ISelectOption[];

    @Input()
    public required: boolean = false;

    @Input()
    public url: string;

    @Input()
    public apiVersion: number = 2;

    @Input()
    public query: any = {};

    @Input()
    public multiple: boolean = false;

    /**
     * @param {string} formSection Used for detecting specific inspections
     */
    @Input()
    public formSection: string;

    @Output()
    public valueChange: EventEmitter<any> = new EventEmitter();

    @Output()
    public valueText: EventEmitter<any> = new EventEmitter();

    public noData: boolean = false;


    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        public apiService: ApiService,
        public api3Service: Api3Service,
    ) {
    }

    private async getOptions(search_by: string): Promise<any> {
        if (!search_by) {
            return;
        }
        this.searching = true;
        this.noData = false;
        this.changeDetectorRef.markForCheck();

        const url: { path: string[], query: any } = this.prepareUrl();

        let response: Api.IResponse;
        if (this.apiVersion === 3) {
            response = await this.api3Service.get(`${AppStateService.getState().section}/${url.path.join("/")}`,
                {
                    search_by,
                    data_structure: "select",
                    ...url.query
                }
            );
        } else {
            response = await this.apiService.request(Api.EMethod.Get, url.path, {}, {
                search_by,
                data_structure: "select",
                ...url.query
            });
        }

        this.searching = false;
        if (response) {
            this.options = response.data;
            if (!this.options.length) {
                this.noData = true;
            }
            this.autocompleteTrigger.openPanel();
        }
        this.changeDetectorRef.markForCheck();
    }

    private prepareUrl(): { path: string[], query: any } {
        const url_array: string[] = this.url.split("?", 2);

        const path: string[] = url_array[0].split("/")
            .filter((val: string): boolean => val.trim() !== "");

        const query: any = {};
        if (url_array.length > 1) {
            const queryParams: string[] = url_array[1].split("&");
            for (const param of queryParams) {
                const [key, val]: string[] = param.split("=", 2);
                query[key] = val;
            }
        }
        return {path, query};
    }

    public onOptionSelected($event: MatAutocompleteSelectedEvent | { option: { value: ISelectOption } }): void {

        const option: ISelectOption = $event.option.value;

        if (this.multiple) {
            if (!this._value) {
                this._value = [];
            }
            this._value.push(option);

            this.valueChange.emit(this._value.map((chip: ISelectOption): string => chip.value));
            this.input.setValue("", {emitEvent: false});
        } else {
            this.valueChange.emit(option.value);
            this.valueText.emit(option.name);
            this.input.setValue(option.name, {emitEvent: false});
        }
        this.changeDetectorRef.markForCheck();
        this.autocompleteTrigger.closePanel();
    }

    public remove(index: number): void {
        this._value.splice(index, 1);
        this.valueChange.emit(this._value.map((chip: { value: any, name: string }): string => chip.value));
    }

    public clearValue(): void {
        this.valueChange.emit(null);
        this.valueText.emit(null);
        this.input.setValue("", {emitEvent: false});
    }

    public ngOnInit(): void {
        let inputVal: any = "";

        if (!this.multiple && this.value) {
            inputVal = this.value;
        }
        if (this.multiple && this.multipleValue) {
            this._value = this.multipleValue;
            this.valueChange.emit(this._value.map((chip: { value: any, name: string }): string => chip.value));
        }

        this.input = new FormControl(inputVal, this.required ? [Validators.required] : []);

        this.input.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
            .subscribe((value: any): void => {
                if (typeof value !== "string") {
                    return;
                }
                if (this.formSection === "inspection") {
                    this.valueChange.emit(value);
                    this.input.setValue(value);
                }
                if (value.trim()) {
                    this.getOptions(value.trim());
                }
            });
        this.input.setValue(inputVal);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.value && changes.value.currentValue === "") {
            this.options = [];
            this._value = [];
            this.input.patchValue("");
        } else if (changes && changes.value
            && changes.value.currentValue
            && changes.value.currentValue !== ""
            && (!changes.value.previousValue || changes.value.previousValue === "")) {

            this.getOptions("").then((): void => {
                const option: { value: any, name: string }
                    = this.options.find((val: any): boolean => val.value === changes.value.currentValue);
                if (option) {
                    this.onOptionSelected({option: {value: option}});
                }
            });
        }

        if (changes && changes.value && changes.value.currentValue !== changes.value.previousValue) {
            this.getOptions("").then((): void => {
                const option: { value: any, name: string }
                    = this.options.find((val: any): boolean => val.value === changes.value.currentValue);
                if (option) {
                    this.onOptionSelected({option: {value: option}});
                }
            });
        }
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
