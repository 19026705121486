<h1 class="container-heading">Box</h1>
<mat-card>
    <mat-card-content>
        <form [formGroup]="formGroup" (submit)="submit()" lang="en-US">
            <div class="group flex">
                <mat-form-field class="full">
                    <mat-label>Ref</mat-label>
                    <input matInput formControlName="ref" required>
                </mat-form-field>
            </div>
            <div class="group flex" formGroupName="properties">
                <mat-form-field class="third">
                    <mat-label>Lot</mat-label>
                    <input matInput formControlName="Lot">
                </mat-form-field>
                <mat-form-field class="third">
                    <mat-label>Po</mat-label>
                    <input matInput formControlName="Po">
                </mat-form-field>
                <mat-form-field class="third">
                    <mat-label>Pieces</mat-label>
                    <input matInput formControlName="Pieces">
                </mat-form-field>
                <mat-form-field class="half">
                    <mat-label>Weight</mat-label>
                    <input matInput formControlName="Weight">
                </mat-form-field>
                <mat-form-field class="half">
                    <mat-label>Dims</mat-label>
                    <input matInput formControlName="Dims">
                </mat-form-field>
                <mat-form-field class="half">
                    <mat-label>Carrier</mat-label>
                    <input matInput formControlName="Carrier">
                </mat-form-field>
                <mat-form-field class="half">
                    <mat-label>Pro #</mat-label>
<input matInput formControlName="Pro #" >
                </mat-form-field>
                <mat-form-field class="half">
                    <mat-label>Supplier</mat-label>
                    <input matInput formControlName="Supplier">
                </mat-form-field>
            </div>


            <div class="actions">
                <button type="submit" mat-raised-button color="primary"
                        [disabled]="!formGroup.valid">
                    Submit
                </button>
                <button type="button" mat-raised-button (click)="modal.response.emit(null)">Cancel</button>
            </div>
        </form>
    </mat-card-content>
</mat-card>
