import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Api} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {UserService} from "../../../../../../common/services/user.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {BatchService} from "../../../../../../common/services/batch.service";

@Component({
    selector: "section-batch-form",
    templateUrl: "form.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class BatchFormComponent implements OnInit {

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    public formGroup: FormGroup = new FormGroup({
        batch: new FormControl(null, [Validators.required]),
        part_master_id: new FormControl(null, [Validators.required]),
        expiry_date: new FormControl(null, [Validators.required]),
    });

    public modal: Modal.IModal;

    public action: string = "add";

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private toastService: ToastService,
        private helpers: HelpersService,
        private modalService: ModalService,
        private userService: UserService,
        private batchService: BatchService,
        private spinnerService: SpinnerService
    ) {
    }

    private async getData(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.batchService.getBatch(this.modal.params.id);
        this.spinnerService.hide();

        if (data) {
            this.formGroup.patchValue(data);
        }
    }

    public async handleFormSubmit(): Promise<any> {
        if (this.formGroup && this.formGroup.valid) {
            this.spinnerService.show();
            let res: Api.IResponse;

            if (this.action === "add") {
                res = await this.batchService.createBatch(this.formGroup.value);
            } else if (this.action === "edit") {
                res = await this.batchService.updateBatch(this.modal.params.id, this.formGroup.value);
            }

            this.spinnerService.hide();
            if (res.code === 200) {
                this.modal.params.id = res.data.id;
                this.action = "edit";
                this.toastService.show(res.message, "success");
                this.changeDetectorRef.markForCheck();
            }
        }
    }

    public async delete(): Promise<any> {
        if (await this.confirmRef.confirm("Are you sure want to delete current Batch?")) {
            this.spinnerService.show();
            const {message, type}: Api.IResponse = await this.batchService.deleteBatch(this.modal.params.data.id);
            this.spinnerService.hide();
            if (type as string === "success") {
                this.toastService.show(message, "success");
                this.modal.response.emit();
            }
        }
    }

    public ngOnInit(): void {
        if (this.modal.params.id) {
            this.action = "edit";
            this.getData();
        }
    }

}
