<h1 class="container-heading">Add</h1>

<mat-card>
    <mat-card-content>

        <form *ngIf="formGroup" [formGroup]="formGroup" (submit)="handleFormSubmit()" autocomplete="off" novalidate>

            <div class="group flex">

                <mat-form-field class="full">
                    <mat-label>Warehouse</mat-label>
                    <mat-select formControlName="inventory_conversion_id">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="inventoryConversionSearch"
                                                   [placeholderLabel]="'Search'"
                                                   [clearSearchInput]="true"
                                                   [noEntriesFoundLabel]="'No items found'">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let inventoryConversion of inventoryConversionFiltered | async"
                                    [value]="inventoryConversion.value">
                            {{ inventoryConversion.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="full">
                    <mat-label>Item</mat-label>
                    <mat-select formControlName="part_master_id">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="itemSearch" [placeholderLabel]="'Search'"
                                                   [clearSearchInput]="true"
                                                   [noEntriesFoundLabel]="'No items found'">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let item of itemsFiltered | async" [value]="item.value">
                            {{ item.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="configurations">
                    <div>
                        <label>{{ getProperty("configuration_name", "Configurations") }}</label>
                    </div>
                    <mat-chip-listbox *ngIf="configurations.length > 0">
                        <mat-chip-option matChipRemove *ngFor="let conf of configurations"
                                         [removable]="true" (remove)="removeConfiguration(conf)">
                            {{ conf }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip-option>
                    </mat-chip-listbox>
                    <button mat-raised-button color="primary" type="button"
                            [disabled]="!formGroup.get('part_master_id').value"
                            (click)="addConfiguration()">Select configurations
                    </button>
                </div>

                <mat-form-field class="full">
                    <mat-label>Batch</mat-label>
                    <input matInput formControlName="batch">
                </mat-form-field>

                <mat-form-field class="full" *ngIf="action !== 'edit'">
                    <mat-label>Serial numbers</mat-label>
                    <mat-chip-grid #chipGrid>
                        <mat-chip-row matChipRemove *ngFor="let serial of serials"
                                      [removable]="true" (removed)="removeSerial(serial)">
                            {{ serial }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip-row>
                        <input [matChipInputFor]="chipGrid"
                               [matChipInputAddOnBlur]="true"
                               (matChipInputTokenEnd)="addSerial($event)"/>
                    </mat-chip-grid>
                </mat-form-field>

                <mat-form-field class="full" *ngIf="action === 'edit'">
                    <mat-label>Serial</mat-label>
                    <input matInput formControlName="serial">
                </mat-form-field>
                <mat-form-field class="full">
                    <mat-label>Quantity</mat-label>
                    <input matInput formControlName="quantity" type="number" required>
                </mat-form-field>
                <mat-form-field class="third">
                    <mat-label>Rev</mat-label>
                    <input matInput formControlName="rev">
                </mat-form-field>

                <mat-form-field class="third">
                    <mat-label>Bin</mat-label>
                    <input matInput formControlName="bin">
                </mat-form-field>

                <mat-form-field class="third">
                    <mat-label>Lot</mat-label>
                    <input matInput formControlName="lot">
                </mat-form-field>
                <mat-form-field class="half" *ngIf="inventoryConversionWh">
                    <mat-label>Warehouse Location ref</mat-label>
                    <input matInput formControlName="location_id" required>
                </mat-form-field>
                <mat-form-field class="half" *ngIf="inventoryConversionWh">
                    <mat-label>Box ref</mat-label>
                    <input matInput formControlName="box_ref">
                </mat-form-field>
                <mat-form-field class="full" *ngIf="!whLocationExists
                    && formGroup.get('location_id').value !== null
                    && formGroup.get('location_id').value !== ''">
                    <mat-label>Location type</mat-label>
                    <mat-select formControlName="location_type">
                        <mat-option *ngFor="let item of locationTypes" [value]="item.value">
                            {{ item.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="actions">
                <button [disabled]="!formGroup.valid" mat-raised-button color="primary" type="submit" class="main">
                    {{ action === "edit" ? "Save" : "Add" }}
                </button>
            </div>

        </form>

    </mat-card-content>
</mat-card>
