import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, Validators} from "@angular/forms";
import {User} from "../../interfaces/user.interface";
import {Api, ApiService} from "../../services/api.service";
import {UserService} from "../../services/user.service";
import {ToastService} from "../../services/toast.service";
import {ConfirmComponent} from "../confirm/confirm.component";
import {LoginService} from "../../services/login.service";
import {SpinnerService} from "../../services/spinner.service";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Component({
    selector: "common-google2fa",
    templateUrl: "google2fa.component.html",
    styleUrls: [
        "google2fa.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class Google2faComponent {

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @Input()
    public user: User.IData;

    @Input()
    public showLogoutButton: boolean = false;

    @Output()
    public result: EventEmitter<boolean> = new EventEmitter<boolean>();

    public data2fa: any = {
        qrCode: null,
        secretCode: null,
        generateSuccess: null,
        authCode: new FormControl(null, [Validators.required]),
        password: new FormControl(null, [Validators.required]),
    };

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private userService: UserService,
        private toastService: ToastService,
        private loginService: LoginService,
        private spinnerService: SpinnerService,
        private sanitizer: DomSanitizer,
    ) {
    }


    /**
     * Generate 2fa qr code
     */
    public async generate2faSecret(): Promise<any> {
        this.spinnerService.show();
        const {data, type, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["user", "enable2fa"]);
        this.spinnerService.hide();
        if (type as string === "success") {
            this.data2fa.qrCode = data.url;
            this.data2fa.secretCode = data.secret;
            this.data2fa.generateSuccess = message;
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Enable 2fa
     */
    public async enable2fa(): Promise<any> {
        this.spinnerService.show();
        const {type, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["user", "verify2fa"], {
                secret: this.data2fa.authCode.value
            });
        if (type as string === "success") {
            this.result.emit(true);
            this.toastService.show(message, "success");
            this.userService.data.user2fa = {enabled: true};
            this.changeDetectorRef.markForCheck();
            await this.userService.getUser();
            this.user = this.userService.data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Disable 2fa
     */
    public async disable2fa(): Promise<any> {
        this.spinnerService.show();
        const {type, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["user", "disable2fa"], {
                password: this.data2fa.password.value
            });
        if (type as string === "success") {
            this.toastService.show(message, "success");
            this.userService.data.user2fa = {enabled: false};
            this.data2fa.qrCode = null;
            this.data2fa.generateSuccess = null;
            this.data2fa.secretCode = null;
            this.data2fa.authCode.setValue(null);
            this.data2fa.password.setValue(null);
            this.changeDetectorRef.markForCheck();
            await this.userService.getUser();
            this.user = this.userService.data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }


    /**
     * Logout
     * @returns {Promise<any>}
     */
    public async logout(): Promise<any> {
        if (await this.confirmRef.confirm("Do you want to log out?")) {
            this.loginService.logout();
        }
    }

    public trustHtml(html: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
}
