import {Injectable} from "@angular/core";
import {Api, ApiService} from "./api.service";


@Injectable()
export class AddressService {

    public constructor(private apiService: ApiService) {
    }

    public getAddress(id: number, query: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Get, ["address", "" + id], {}, query);
    }

    public linkPartMaster(address_id: number, part_master_id: number, max_qty: number = 0): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Post, ["address", "" + address_id, "toggle-part-master-link"],
            {
                part_master_id,
                max_qty
            });
    }

    public linkServiceLevel(address_id: number, service_level_id: number): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Post,
            ["address", "" + address_id, "toggle-service-level-link"],
            {
                service_level_id
            });
    }

    public getServiceLevels(id: number, query: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Get, ["address", "" + id, "service-levels"],
            {}, query);
    }

    public getCoordinatesByAddress(address: string): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Get, ["address", "coordinates"], {}, {address});
    }
}
