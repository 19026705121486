import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {UserService} from "../../../../../../common/services/user.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {Table2Component} from "../../../../../../common/components/table2";
import {AmplitudeService} from "../../../../../../common/services/amplitude.service";
import {ConfirmComponent} from "src/modules/common/components/confirm/confirm.component";
import {Api3Service} from "../../../../../../common/services/api3.service";


@Component({
    selector: "section-allocations-list",
    template: `
        <common-confirm></common-confirm>
        <common-table2 *ngIf="listTable" [settings]="listTable" (onRowsSelected)="selectedRows = $event">
            <div row1>
                <button mat-raised-button [disabled]="!selectedRows.length" color="warn"
                        (click)="deleteSelected()">Delete selected
                </button>
            </div>
        </common-table2>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AllocationsListComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @Input()
    public type: string = null;

    @Input()
    public summarized: string = null;

    @Input()
    public state: Base.IState;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.ISettings;

    public showExportButton: boolean = false;

    public selectedRows: any[] = [];

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private userService: UserService,
        private spinnerService: SpinnerService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {

        const actions: Table.IAction[] = [];
        if (!this.summarized) {
            actions.push({
                name: "view",
                title: "View order",
                click: (row: any): void => {
                    this.router.navigate([
                        this.state.section,
                        "orders",
                        "view",
                        "id",
                        row.order_id,
                        "type",
                        row.type
                    ]);
                    AmplitudeService.eventClick("Allocations view");
                }
            });
            actions.push({
                name: "delete",
                title: "Delete",
                click: async (row: any): Promise<any> => {
                    if (!await this.confirmRef.confirm("Are you sure want to delete this allocation?")) {
                        return;
                    }
                    this.spinnerService.show();
                    const response: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
                        ["inventory_allocation", "by_id", row.id]);

                    if (response) {
                        this.toastService.show(response.message, response.type as string);
                        this.tableRef.reload();
                    }

                    this.spinnerService.hide();
                    AmplitudeService.eventClick("Allocations delete");
                },
                disabledFn: (row: any): boolean => {
                    return !!row.order?.confirmed;
                }
            });
        }

        const columns: Table.ICol[] = [
            {
                data: "order.ref",
                name: "Order.ref",
                title: "Ref"
            },
            {
                data: "item",
                title: "Item"
            },
            {
                data: "batch",
                title: "Batch"
            },
            {
                data: "configurations",
                title: this.userService.getProperty("configuration_name", this.state, "Configurations"),
                sortable: false,
                searchable: false
            },
            {
                data: "quantity",
                title: "Quantity",
                render: (row: any): string => {
                    if (this.summarized) {
                        return row.sum_quantity;
                    }
                    return row.quantity;
                }
            },
            {
                data: "type",
                title: "Type"
            },
            {
                data: "inventory_conversion.customers_inventory_name",
                name: "InventoryConversion.customers_inventory_name",
                title: "Related warehouse",
                sortable: false
            }
        ];

        if (this.type === "inbound") {
            columns.push({
                data: "order.outbound_order_item.inventory_conversion.customers_inventory_name",
                name: "Order.OutboundOrderItem.InventoryConversion.customers_inventory_name",
                title: "From Warehouse",
                sortable: false
            });
        }
        let export_data: any;
        if (!this.summarized) {
            columns.splice(2, 0, {
                data: "serial",
                title: "Serial"
            });
            export_data = {
                type: "csv",
                columns: [
                    {
                        data: "order.ref",
                        name: "Order.ref",
                        title: "Ref"
                    },
                    {
                        data: "item",
                        title: "Item"
                    },
                    {
                        data: "batch",
                        title: "Batch"
                    },
                    {
                        data: "serial",
                        title: "Serial"
                    },
                    {
                        data: "configurations",
                        title: "Configurations"
                    },
                    {
                        data: "quantity",
                        title: "Quantity"
                    },
                    {
                        data: "type",
                        title: "Type"
                    },
                    {
                        data: "inventory_conversion.customers_inventory_name",
                        name: "InventoryConversion.customers_inventory_name",
                        title: "Related warehouse"
                    },
                    {
                        data: "inventory_conversion.customers_sub_inventory",
                        name: "InventoryConversion.customers_sub_inventory",
                        title: "Hub"
                    },
                    {
                        data: "order.outbound_order_item.inventory_conversion.customers_inventory_name",
                        name: "Order.OutboundOrderItem.InventoryConversion.customers_inventory_name",
                        title: "From Warehouse"
                    }
                ],
                file_name: "allocations"
            };
        } else {
            export_data = {
                type: "csv",
                columns: [
                    {
                        data: "order.ref",
                        name: "Order.ref",
                        title: "Ref"
                    },
                    {
                        data: "item",
                        title: "Item"
                    },
                    {
                        data: "batch",
                        title: "Batch"
                    },
                    {
                        data: "configurations",
                        title: "Configurations"
                    },
                    {
                        data: "sum_quantity",
                        title: "Quantity"
                    },
                    {
                        data: "inventory_conversion.customers_sub_inventory",
                        name: "InventoryConversion.customers_sub_inventory",
                        title: "Hub"
                    }
                ],
                file_name: "allocations"
            };
        }


        this.listTable = {
            table_id: "M5f4NCs53nb",
            api: {
                url: ["inventory_allocation"],
                query: {
                    type: this.type,
                    summarized: this.summarized,
                    relations: [
                        "Order.OutboundOrderItem.InventoryConversion",
                        "InventoryConversion"
                    ]
                }
            },
            actions,
            columns,
            export: export_data,
            search_default: ["Order.ref", "serial"],
            multi_select: (row: any): boolean => {
                return !row.order?.confirmed;
            }
        };
        this.changeDetectorRef.markForCheck();
    }

    public async deleteSelected(): Promise<any> {
        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.api3Service.delete(
            `${this.state.section}/inventories/allocations`, {
                allocations: this.selectedRows.map((row: any) => row.id)
            });

        this.spinnerService.hide();

        if (code === 200) {
            this.toastService.show(message, "success");
            this.tableRef.reload();
        }
    }

    public ngOnInit(): void {
        this.prepareList();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
