import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from "@angular/core";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {Table2Component} from "../../../../../../common/components/table2";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Router} from "@angular/router";
import {ModalService} from "../../../../../services/modal.service";

@Component({
    selector: "section-admin-reports-list",
    template: `
        <h1 class="container-heading">Reports</h1>
        <mat-card>
            <mat-card-content>
                <common-table2 *ngIf="listTable" [settings]="listTable"></common-table2>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminReportsListComponent implements OnInit, Base.IComponent {

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.ISettings;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    public readonly state: Base.IState;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private modalService: ModalService
    ) {
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {

        this.listTable = {
            table_id: "dfhxt87ddzfdaz",
            actions: [
                {
                    name: "settings",
                    title: "Settings",
                    click: (row: any): void => {
                        this.router.navigate([
                            "admin",
                            "reports",
                            "view",
                            "id",
                            row.id
                        ]);
                    }
                }
            ],
            api: {
                url: ["admin", "reports"],
                version: 3,
                query: {
                    counts: [
                        "Partners",
                        "Warehouses"
                    ]
                }
            },
            columns: [
                {
                    data: "id",
                    title: "ID"
                },
                {
                    data: "icon",
                    title: "",
                    render: (row: any): string => {
                        return row.icon ? "<img class='icon' src='" + row.icon + "' " + "alt=''>" : "";
                    },
                    sortable: false,
                    searchable: false,
                    exportable: false
                },
                {
                    data: "name",
                    title: "Name"
                },
                {
                    data: "key",
                    title: "Key"
                },
                {
                    data: "job",
                    title: "Job"
                },

                {
                    data: "row.partners_count",
                    title: "Partners",
                    render: (row: any): string => {
                        return `Available to ${row.partners_count} partner(s)`;
                    },
                    sortable: false,
                    searchable: false
                },
                {
                    data: "row.warehouses_count",
                    title: "Warehouses",
                    render: (row: any): string => {
                        return `Available to ${row.warehouses_count} warehouse(s)`;
                    },
                    sortable: false,
                    searchable: false
                }
            ],
            search_default: ["name"]
        };
        this.changeDetectorRef.markForCheck();
    }


    public ngOnInit(): void {
        this.prepareList();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "reports",
            actions: {
                "browse": ["browse_admin"]
            }
        };
    }

}
