import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {AddressFormComponent, AddressListModalComponent} from "../../address";
import {ContactFormComponent, ContactListComponent} from "../../contact";
import {PickupRequestService} from "../../../../../../common/services/pickup-request.service";
import {Warehouse} from "../../../../../../common/interfaces/warehouse.interface";
import {Form} from "../../../../../../common/interfaces/form.interface";
import {debounceTime, takeUntil} from "rxjs/operators";
import {CustomerFormComponent, CustomerListComponent} from "../../customer";
import {TrackNumberFormComponent} from "../../tracking/form/form.component";
import {CourierTransactionFormComponent} from "../../../../threepl/warehouse/courier-transaction";
import ISelectOption = Form.ISelectOption;
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {AppStateService} from "../../../../../../common/services/app-state.service";

@Component({
    selector: "section-pickup-request-form",
    templateUrl: "form.component.html",
    styleUrls: [
        "form.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class PickupRequestFormComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild(ConfirmComponent, {static: true})
    public confirmRef: ConfirmComponent;

    public disableTypeField: boolean = false;

    public state: Base.IState;

    /**
     * Form group / group with controls
     * @type {FormGroup}
     */
    public formGroup: FormGroup = new FormGroup({
        type: new FormControl(null, [Validators.required]),
        remarks: new FormControl(null),
        ref: new FormControl(null),
        from_date: new FormControl(null, [Validators.required]),
        to_date: new FormControl(null, [Validators.required]),
        courier_transaction_id: new FormControl(null),
        customer_id: new FormControl(null, [Validators.required]),
        address_id: new FormControl(null, [Validators.required]),
        contact_id: new FormControl(null, [Validators.required]),
        notes: new FormControl(null),
        pickup_request_status_id: new FormControl(86, [Validators.required]),
        shipment_id: new FormControl(null)
    });

    public requestTypes: string[] = [
        null,
        "Scheduled  WH  Driver",
        "Courier Pick-up",
        "Extra label",
        "Special",
        "LTL service"
    ];

    public modal: Modal.IModal;

    public action: string = "add";

    public components: any = {
        address: {
            form: AddressFormComponent,
            list: AddressListModalComponent
        },
        contact: {
            form: ContactFormComponent,
            list: ContactListComponent
        },
        transaction: {
            form: TrackNumberFormComponent,
            list: TrackNumberFormComponent
        },
        customer: {
            form: CustomerFormComponent,
            list: CustomerListComponent
        }
    };

    public values: any = {
        tracking_number: null,
        address: null,
        contact: null
    };

    public statuses: ISelectOption[];

    public tracks: any[];

    public distance: number;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private toastService: ToastService,
        private helpers: HelpersService,
        private modalService: ModalService,
        private pickupRequestService: PickupRequestService,
        private spinnerService: SpinnerService
    ) {
    }

    private async getStatuses(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.pickupRequestService.getStatusList({
            data_structure: "select"
        });

        this.spinnerService.hide();
        if (data) {
            this.statuses = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    private async getDistance(address_id: number): Promise<any> {
        if (!this.modal.params.shipment) {
            return;
        }
        const {data}: Api.IResponse = await this.pickupRequestService.getDistance({
            address_id,
            shipment_id: this.modal.params.shipment.id
        });
        if (data) {
            this.distance = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    private async getTracks(): Promise<any> {
        this.spinnerService.show();

        this.apiService.setHeaders({warehouse: this.modal.params.shipment.warehouse_slug});
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["shipment", "" + this.modal.params.shipment.id, "courier_transactions"]);
        if (data) {
            this.tracks = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private prepareForm(): void {
        if (this.modal.params.data) {
            const pickup: any = this.modal.params.data;

            this.formGroup.patchValue({
                type: pickup.type,
                remarks: pickup.remarks,
                from_date: pickup.from_date,
                to_date: pickup.to_date,
                pickup_request_status_id: pickup.status.id,
                customer_id: pickup.customer ? pickup.customer.id : null,
                address_id: pickup.address ? pickup.address.id : null,
                contact_id: pickup.contact ? pickup.contact.id : null,
                courier_transaction_id: pickup.courier_transaction_id,
                notes: pickup.notes,
                shipment_id: pickup.shipment_id,
                ref: pickup.ref,
            });

            this.disableTypeField = !!pickup.ref;

            this.values.tracking_number = pickup.courier_transaction
                ? pickup.courier_transaction.tracking_number
                : null;

            this.values.customer = pickup.customer ? pickup.customer.name : null;

            this.values.address = pickup.address
                ? pickup.address.address + " " + pickup.address.city + " " + pickup.address.country
                : null;

            this.values.contact = pickup.contact
                ? pickup.contact.first_name + " " + pickup.contact.last_name + " - " + pickup.contact.email
                : null;

        } else {

            const shipment: Warehouse.IShipment = this.modal.params.shipment;

            this.formGroup.patchValue({
                remarks: this.modal.params.order_ref,
                shipment_id: shipment.id
            });
        }
    }

    public async customerUpdate(event: any): Promise<any> {
        this.formGroup.get("address_id").reset();
    }

    public addressUpdate(event: any): void {
        this.formGroup.get("contact_id").reset();

    }

    public async submit(): Promise<any> {
        this.spinnerService.show();
        const {message, code}: Api.IResponse = (this.modal.params.data && this.modal.params.data.id)
            ? await this.pickupRequestService.update(this.modal.params.data.id, this.formGroup.value)
            : await this.pickupRequestService.create(this.formGroup.value);

        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.modal.response.emit(null);
        }
    }

    public async delete(): Promise<any> {
        if (await this.confirmRef.confirm("Are you sure want to delete this request?")) {
            this.spinnerService.show();
            const {message, code}: Api.IResponse = await this.pickupRequestService.delete(this.modal.params.data.id);
            this.spinnerService.hide();
            if (code === 200) {
                this.toastService.show(message, "success");
                this.modal.response.emit(null);
            }
        }
    }

    public async addTracking(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(CourierTransactionFormComponent, {
            action: "add",
            shipmentId: this.modal.params.shipment.id,
            orderRef: this.modal.params.order_ref
        });
        if (response && response.value && response.value.id) {
            this.getTracks();
            this.formGroup.get("courier_transaction_id").setValue(response.value.id);
        } else {
            this.formGroup.get("courier_transaction_id").setValue(null);
        }
    }

    public ngOnInit(): void {
        this.action = this.modal.params.data && this.modal.params.data.id ? "edit" : "add";
        this.state = AppStateService.getState();

        this.prepareForm();
        this.getStatuses();
        this.getTracks();

        this.formGroup.get("address_id").valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value: number): void => {
                if (value) {
                    this.getDistance(value);
                }
            });

        this.formGroup.get("ref").valueChanges.pipe(takeUntil(this.destroy$), debounceTime(200))
            .subscribe((value: string): void => {
                if (value) {
                    this.disableTypeField = true;
                    this.formGroup.get("type").setValue("Scheduled  WH  Driver");
                } else {
                    this.disableTypeField = false;
                }
                this.changeDetectorRef.markForCheck();
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
