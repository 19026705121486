<h1 class="container-heading">Location boxes</h1>
<mat-card>
    <div *ngIf="boxList" class="boxes-wrap">
        <common-pagination [data]="boxList"
                           pid="z2b3TyOA3"
                           (goToPage)="getBoxesInLocation($event.page,$event.per_page)"></common-pagination>
        <div class="boxes">
            <ng-template ngFor let-box [ngForOf]="boxList.data" let-i="index">
                <div class="box" [class.green]="box.warehouse_transactions_count > 0">
                    <div class="ref">
                        {{box.ref}}
                    </div>
                    <div class="logo" *ngIf="box.partner">
                        <span></span>
                        <img [src]="box.partner.icon_path" alt="">
                    </div>
                    <div class="items background-accent">
                        {{box.warehouse_transactions_count}} items
                    </div>
                    <div class="buttons">
                        <button mat-mini-fab color="primary"
                                type="button" (click)="openBox(box)">
                            <mat-icon>list_alt</mat-icon>
                        </button>
                    </div>
                </div>
            </ng-template>
        </div>
        <div *ngIf="location" [class.shelf]="location.type_id === 1"
             [class.palet]="location.type_id === 2"
             [ngStyle]="{'background-image': 'url('+location.image+')'}"></div>

        <div class="location-summary color-accent text-center" *ngIf="location">
            <b>{{location.name}}</b>
        </div>
    </div>
</mat-card>
