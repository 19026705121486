import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {ImageUploadService} from "../../../../../../common/services/image-upload.service";

@Component({
    selector: "section-couriers-form",
    templateUrl: "form.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class CouriersFormComponent implements Base.IComponent, OnInit, OnDestroy {
    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    public readonly state: Base.IState;


    public formGroup: FormGroup = new FormGroup({
        "display_name": new FormControl(null, [Validators.required]),
        "other_name": new FormControl(null, [Validators.required]),
        "phone": new FormControl(null, [Validators.required]),
        "web_url": new FormControl(null, [Validators.required]),
        "icon_path": new FormControl(null, [Validators.required]),
        "shipping_mode_id": new FormControl(null),
        "shippy_pro_slug": new FormControl(null),
        "aftership_slug": new FormControl(null),
    });

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private imageUploadService: ImageUploadService,
        private spinnerService: SpinnerService
    ) {
    }


    private async prepareForm(): Promise<any> {
        if (this.state.action === "edit") {
            this.spinnerService.show();
            const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
                ["courier", this.state.params.id]);

            if (data) {
                this.formGroup.patchValue(data);
                this.changeDetectorRef.markForCheck();
            }
            this.spinnerService.hide();
        } else {
            this.formGroup.get("shipping_mode_id").setValue(this.state.params["shipping-mode"]);
        }
    }

    public async uploadImage(): Promise<any> {
        const image_url: string = await this.imageUploadService.uploadLogo();

        if (image_url) {
            this.formGroup.get("icon_path").setValue(image_url);
            this.changeDetectorRef.markForCheck();
        }
    }


    /**
     * Handle form submit event
     * @returns {Promise<any>}
     */
    public async formSubmit(): Promise<any> {
        this.spinnerService.show();
        const response: Api.IResponse = await this.apiService.request(
            this.state.action === "edit" ? Api.EMethod.Put : Api.EMethod.Post,
            this.state.action === "edit" ? ["courier", this.state.params.id] : ["courier"],
            this.formGroup.value);

        if (response) {
            this.toastService.show(response.message, response.type as string);
            this.changeDetectorRef.markForCheck();
            if (response.type as string === "success") {
                this.router.navigate([
                    this.state.section,
                    this.state.component,
                    "filter",
                    "shipping-mode",
                    this.state.params["shipping-mode"]
                ]);
            }
        }
        this.spinnerService.hide();
    }

    public goBack(): void {
        this.router.navigate([
            this.state.section,
            "couriers",
            "filter",
            "shipping-mode",
            this.state.params["shipping-mode"]
        ]);
    }

    public async ngOnInit(): Promise<any> {
        this.prepareForm();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "couriers",
            actions: {
                "add": ["add_couriers"],
                "edit": ["edit_couriers"]
            }
        };
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
