<common-confirm></common-confirm>

<span class="title">{{ title }}</span>

<div class="order-search" *ngIf="['partner','admin'].includes(state?.section_type)">
    <mat-form-field>
        <mat-label>Order search</mat-label>
        <input #trigger="matAutocompleteTrigger" type="text" matInput [formControl]="orderSearch"
               [matAutocomplete]="auto">
        <span matSuffix *ngIf="searching">
            <i class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
        </span>
    </mat-form-field>

    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)" #searchAutoComplete>
        <mat-option *ngFor="let option of orders"
                    [value]="option.partner.slug + '::' + option.id + '::' + option.service_level.order_type.slug">
            {{ option.ref }}
        </mat-option>
        <mat-option *ngIf="noOrders" [disabled]="true">
            Nothing was found
        </mat-option>
    </mat-autocomplete>
</div>

<span class="spacer"></span>

<ng-container *userAllowed="'add_requests'">
    <button type="button" mat-mini-fab color="primary" class="minimize-request-button"
            *ngIf="storageService.get('isRequestMinimized')"
            matBadgeColor="accent"
            (click)="openRequestForm()"
            matTooltip="View minimized request">
        <mat-icon class="fa-rotate-90"> call_received</mat-icon>
    </button>

    <button type="button" mat-mini-fab color="primary" class="request-button" *ngIf="!media_is_recording"
            [matMenuTriggerFor]="requestMenu"
            [matBadge]="'' + requestsCount"
            [matBadgeHidden]="!requestsCount"
            matBadgeColor="accent"
            matTooltip="Add request">
        <mat-icon>headset_mic</mat-icon>
    </button>

    <button type="button" mat-mini-fab color="warn" class="request-button blink"
            *ngIf="media_is_recording"
            (click)="stopVideoCapture()"
            matTooltip="Stop video capture">
        <mat-icon>stop</mat-icon>
    </button>

</ng-container>


<mat-menu #requestMenu="matMenu">
    <a mat-menu-item (click)="openRequestForm()">
        <mat-icon>add</mat-icon>
        New request
    </a>
    <a mat-menu-item *ngIf="requestsCount" (click)="goToRequests()">
        <mat-icon>list</mat-icon>
        View requests
    </a>
    <ng-container *userAllowed="'add_it_requests'">
        <a mat-menu-item (click)="makeScreenshot()">
            <mat-icon class="color-warn">crop_original</mat-icon>
            Make screenshot
        </a>
        <a mat-menu-item (click)="startVideoCapture()">
            <mat-icon class="color-warn">videocam</mat-icon>
            Capture video
        </a>
    </ng-container>

</mat-menu>

<img class="livechat-button" style="" src="/assets/images/icons/chat.gif" (click)="openLivechat.emit()"
     *ngIf="livechat">

<div *ngIf="canSeeShift()" class="responsible-managers">
    <div matTooltip="Supervising manager" (click)="selectSupervisor()" class="pointer">
        <ng-container *ngIf="partner.manager_in_charge">
            <div class="avatar" [style.background-image]="'url(' + (partner.manager_in_charge.avatar
                            ? partner.manager_in_charge.avatar
                            : '/assets/images/default-avatar.png') + ')'"></div>
            {{ partner.manager_in_charge.name }}
        </ng-container>
        <ng-container *ngIf="!partner.manager_in_charge">
            <mat-icon class="vertical-middle">supervised_user_circle</mat-icon>
            Manager not selected
        </ng-container>
    </div>

    <div matTooltip="Duty manager" class="pointer" (click)="setMeOnShift()">
        <ng-container *ngIf="partner.manager_on_shift">
            <div class="avatar" [style.background-image]="'url(' + (partner.manager_on_shift.avatar
                            ? partner.manager_on_shift.avatar
                            : '/assets/images/default-avatar.png') + ')'"></div>
            {{ partner.manager_on_shift.name }}
        </ng-container>
        <ng-container *ngIf="!partner.manager_on_shift">
            <mat-icon class="vertical-middle">account_circle</mat-icon>
            Shift is free
        </ng-container>
    </div>
</div>

<div *ngIf="state && state.section_type ==='warehouse'" class="responsible-managers">
    <div matTooltip="Duty manager" class="pointer" (click)="selectWarehouseContact()">
        <mat-icon class="vertical-middle">account_circle</mat-icon>
        {{warehouse?.contact_on_shift?.first_name || "Select on shift contact"}}
    </div>
</div>

<button mat-icon-button (click)="!isFullScreen ? fullScreenOn() : fullScreenOff()"
        matTooltip="Toggle full screen mode" class="fullscreen-mode">
    <mat-icon>{{ !isFullScreen ? "fullscreen" : "fullscreen_exit" }}</mat-icon>
</button>
<button mat-icon-button class="notifications" matTooltip="Files">
    <mat-icon (click)="showAttachments()">attachment</mat-icon>
</button>

<button class="sounds" matTooltip="Toggle sounds"
        type="button" mat-icon-button (click)="soundsService.toggleSound()">
    <mat-icon>{{ soundsService.isSoundOn() ? 'volume_up' : 'volume_off' }}</mat-icon>
</button>

<button mat-icon-button class="notifications" mat-ripple [matMenuTriggerFor]="notificationsMenu"
        (click)="getNotifications()" matTooltip="Notifications">
    <mat-icon *ngIf="notificationsCount" matBadge="{{notificationsCount}}" matBadgeColor="warn">
        notifications_active
    </mat-icon>
    <mat-icon *ngIf="!notificationsCount">notifications</mat-icon>
</button>

<mat-menu #notificationsMenu="matMenu" [class]="'notifications-menu ' + theme"
          [overlapTrigger]="false" [xPosition]="'before'" (closed)="markNotificationsAsRead()">
    <div>
        <ng-template ngFor let-notification let-i="index" [ngForOf]="notifications">
            <ng-container [ngSwitch]="notification.job_type">

                <ng-container *ngSwitchCase="'Announcement'">
                    <div class="notification" (click)="redirectTo(notification.url)">
                        <div class="image"><img [src]="notification.user_avatar
                    ? notification.user_avatar
                    : '/assets/images/default-avatar.png'"/></div>
                        <div class="description">
                            <div class="notification-message">{{ notification.message }}</div>
                            <div class="notification-author" *ngIf="notification.user_name">
                                by {{ notification.user_name }}<br/></div>
                            <div class="notification-description" [innerHTML]="notification.message_description"></div>
                        </div>
                    </div>
                    <mat-divider *ngIf="i < notifications.length - 1"></mat-divider>
                </ng-container>

                <ng-container *ngSwitchCase="'progressbar'">
                    <div class="progressbar">
                        <div>{{ notification.message }} {{ notification.message_Detailed[0] }}%</div>
                        <div>
                            <mat-progress-bar mode="determinate" [value]="notification.message_Detailed[0]">
                            </mat-progress-bar>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    <div class="notification">
                        <i>
                            <span *ngIf="!notification.read"></span>
                        </i>
                        <mat-icon class="mat-icon material-icons {{notification.message_type}}">
                            {{ jobTypesIcons[notification.job_type] || "check_circle" }}
                        </mat-icon>

                        <a *ngIf="notification.url && isExternalUrl(notification.url)"
                           href="{{notification.url}}"
                           target="_blank">
                            {{ notification.message }}
                        </a>
                        <a *ngIf="notification.url && !isExternalUrl(notification.url)"
                           [routerLink]="notification.url">
                            {{ notification.message }}
                        </a>
                        <span *ngIf="!notification.url">{{ notification.message }}</span>
                        <mat-icon *ngIf="notification.message_Detailed && notification.message_Detailed.length"
                                  class="error-help"
                                  (click)="notificationDetails(notification)">
                            help
                        </mat-icon>
                        <br/>
                    </div>
                    <div class="notification-description" [innerHTML]="notification.message_description"></div>
                    <mat-divider *ngIf="i < notifications.length - 1"></mat-divider>
                </ng-container>
            </ng-container>
        </ng-template>
        <ng-template [ngIf]="!notifications || notifications.length === 0">
            <p class="text-center">No alerts</p>
        </ng-template>
    </div>
    <div class="clear-notifications" *ngIf="notifications && notifications.length > 0">
        <button type="button" mat-button (click)="clearNotifications()">Clear</button>
    </div>
</mat-menu>

<button mat-icon-button class="notifications"
        mat-ripple (click)="showActivities()" matTooltip="Activities">
    <mat-icon>announcement</mat-icon>
</button>

<div #menuTrigger mat-ripple [matMenuTriggerFor]="menuRef" class="user">
    <mat-icon>account_box</mat-icon>
    <div class="name">
        <span>{{ name }}</span>
        <span class="role">{{ email }}</span>
    </div>
    <mat-icon>keyboard_arrow_down</mat-icon>
</div>

<mat-menu #menuRef="matMenu" [class]="'user-menu ' + theme" [overlapTrigger]="false" [xPosition]="'before'">
    <div [style.width]="width">
        <a [routerLink]="['profile']" routerLinkActive="active" mat-menu-item class="user-menu-item">
            <mat-icon>settings</mat-icon>
            <span>Profile</span>
        </a>
        <a [routerLink]="['user-invite']" routerLinkActive="active" mat-menu-item class="user-menu-item">
            <mat-icon>share</mat-icon>
            <span>Invite</span>
        </a>
        <a [routerLink]="['/entrance']" routerLinkActive="active" mat-menu-item class="user-menu-item">
            <mat-icon>launch</mat-icon>
            <span>Enter as</span>
        </a>
        <button mat-menu-item (click)="logout()" class="user-menu-item">
            <mat-icon>exit_to_app</mat-icon>
            <span>Logout</span>
        </button>
    </div>
</mat-menu>
