import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Modal} from "../../../../../services/modal.service";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {Api3Service} from "../../../../../../common/services/api3.service";
import {AppStateService} from "../../../../../../common/services/app-state.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";

@Component({
    selector: "section-contact-form",
    templateUrl: "form.component.html",
    styleUrls: [
        "form.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class ContactFormComponent implements OnInit {

    private state: Base.IState;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    /**
     * Form group / group with controls
     * @type {FormGroup}
     */
    public formGroup: FormGroup = new FormGroup({
        first_name: new FormControl(null),
        last_name: new FormControl(null),
        email: new FormControl(null),
        phone: new FormControl(null),
        mobile_phone: new FormControl(null),
        fax: new FormControl(null),
    });

    public modal: Modal.IModal;

    public action: string = "add";

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private spinnerService: SpinnerService
    ) {
    }

    private async getValidationConfig(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.get(
            `${this.state.section}/forms-validation-rules/contact`,
        );
        this.spinnerService.hide();

        if (data) {
            HelpersService.setValidatorsFromConfig(this.formGroup, data);
            this.changeDetectorRef.markForCheck();
        }
    }


    /**
     * Get contact data
     */
    private async getData(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.get(
            `${this.state.section}/contacts/${this.modal.params.data.id}`);
        if (data) {
            this.modal.params.data = data;
            this.formGroup.patchValue(data);
        }
        this.spinnerService.hide();
    }

    /**
     * Submit form (add)
     * @returns {Promise<any>}
     */
    public async handleFormSubmit(): Promise<any> {
        this.spinnerService.show();

        if (this.action === "add") {
            const address_id: string = this.modal.params && this.modal.params.address;

            const {data, message}: Api.IResponse = await this.api3Service.post(
                `${this.state.section}/contacts`,
                {...this.formGroup.value, address_id: address_id || null});

            if (data) {
                this.toastService.show(message, "success");

                this.modal.response.next({
                    name: "value",
                    value: {
                        controlValue: data.id,
                        viewValue: data.first_name + " " + data.last_name + " - "
                            + (data.email ? data.email : "no email address")
                    }
                });
            }
        } else if (this.action === "edit") {
            const response: Api.IResponse = await this.api3Service.put(
                `${this.state.section}/contacts/${this.modal.params.data.id}`,
                this.formGroup.value);

            if (response) {
                this.toastService.show(response.message, response.type);
                if (response.data) {
                    this.modal.response.next({
                        name: "value",
                        value: response.data
                    });
                }
            }
        }
        this.spinnerService.hide();
    }

    /**
     * Delete contact
     */
    public async delete(): Promise<any> {
        if (await this.confirmRef.confirm("Are you sure want to delete this contact?")) {
            this.spinnerService.show();
            const {message, type}: Api.IResponse = await this.api3Service.delete(
                `${this.state.section}/contacts/${this.modal.params.id}`);
            if (type as string === "success") {
                this.toastService.show(message, "success");
            }
            this.spinnerService.hide();
        }
    }

    /**
     * Show add new address form
     * @returns {void}
     */
    public showList(): void {
        this.modal.response.next({
            name: "list"
        });
    }

    public ngOnInit(): void {
        this.action = this.modal && this.modal.params.action ? this.modal.params.action : "add";

        this.state = AppStateService.getState();

        this.getValidationConfig();

        if (this.modal && this.modal.params.getData) {
            this.getData();
        } else if (this.modal && this.modal.params.data) {
            this.formGroup.patchValue(this.modal.params.data);
        }
    }

}
