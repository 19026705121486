import {Pipe, PipeTransform} from "@angular/core";
import {HelpersService} from "../services/helpers.service";

@Pipe({
    name: "filter",
    pure: false
})
export class FilterPipe implements PipeTransform {

    public constructor() {
    }

    public transform(items: any[], option: string, comparison: string, otherValue: any, apply: boolean = true): any[] {
        if (!items) {
            return [];
        }
        if (!apply) {
            return items;
        }
        return items.filter((item: any): boolean => {
            const value: any = HelpersService.dotToObjectPath(item, option);

            let valid: boolean = false;
            switch (comparison) {
                case "==":
                case "===":
                    valid = value === otherValue;
                    break;
                case ">":
                    valid = value > otherValue;
                    break;
                case "<":
                    valid = value < otherValue;
                    break;
                case ">=":
                    valid = value >= otherValue;
                    break;
                case "<=":
                    valid = value <= otherValue;
                    break;
                case "!=":
                case "!==":
                    valid = value !== otherValue;
                    break;
                case "like":
                    valid = value.toLowerCase().includes(otherValue.toLowerCase());
                    break;
                default:
                    valid = false;
            }

            return valid;
        });
    }
}
