import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Order} from "../../../../../../common/interfaces/order.interface";
import {Table} from "../../../../../../common/components/table/table.component";
import {Modal, ModalService} from "../../../../../services/modal.service";
import * as jQuery from "jquery";
import {ApiService} from "../../../../../../common/services/api.service";
import {CommonFormComponent} from "../../../../../../common/components/form";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {Router} from "@angular/router";


@Component({
    selector: "section-all-by-item-modal",
    template: `
        <common-confirm></common-confirm>

        <h1 class="container-heading">All By Item Modal</h1>
        <mat-card>
            <mat-card-content>
                <common-table *ngIf="listTable" [options]="listTable" (action)="handleListAction($event)"
                              (onDtEvent)="onDtEvent($event)">
                </common-table>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AllByItemModalComponent implements OnInit {

    public modal: Modal.IModal;
    public readonly state: Base.IState;
    public listTable: Table.IOptions;
    public params: Order.IOrderListParams;
    public showExportButton: boolean = false;

    public constructor(
        private apiService: ApiService,
        private modalService: ModalService,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    private prepareList(): void {

        const columns: DataTables.ColumnSettings[] = [
            {
                data: "inventory_conversion.customers_inventory_name",
                name: "InventoryConversion.customers_inventory_name",
                title: "Inventory name"
            },
            {
                data: "inventory_conversion.customers_sub_inventory",
                name: "InventoryConversion.customers_sub_inventory",
                title: "Sub Inventory"
            },
            {
                data: "part_master.item",
                name: "PartMaster.item",
                title: "Item"
            },
            {
                data: "part_master.description",
                name: "PartMaster.description",
                title: "Description"
            },
            {
                data: "order.ref",
                name: "Order.ref",
                title: "Ref",
                render: (data: string, show: any, row: any): string => {
                    if (row.order) {
                        return data ? `<a class="tableAction button-150" data-action="goToOrder">${data}</a>` : "";
                    } else if (row.box) {
                        return row.box.ref;
                    }
                },
                orderable: false,
                searchable: false
            },
            {
                data: "quantity",
                title: "Quantity",
                render: (data: number, show: any, row: any): string => {
                    return row.quantity_sum ? row.quantity_sum : data;
                }
            },
            {
                data: "created_at",
                title: "Created At"
            }
        ];


        let url: string[] = ["order_item", "bygk", this.modal.params.globalKey, this.modal.params.type];
        if (this.modal.params.url) {
            url = this.modal.params.url;
        }

        let query: string = "?data_structure=dataTables";

        if (this.modal.params.hub_id) {
            query += "&hub_id=" + this.modal.params.hub_id;
        }

        if (this.modal.params.part_master_id) {
            query += "&part_master_id=" + this.modal.params.part_master_id;
        }


        this.listTable = {
            actions: [],
            api: this.apiService.getUrl(url) + query,
            columns,
            order: [],
            search: true,
            buttons: ["csv"],
            createdRow: (row: any, data: any, dataIndex: any): void => {
                if (!data.confirmed) {
                    jQuery(row).addClass("mat-accent");
                }
            }
        };
        this.changeDetectorRef.markForCheck();
    }

    public onDtEvent(event: Table.IDtEvent): void {
        this.showExportButton = event.hasData;
    }

    public async report(): Promise<any> {

        const response: Modal.IResponse = await this.modalService.open(CommonFormComponent, {
            configUrl: ["report", "inventories-list"],
            submitUrl: ["report", "inventories-list"],
            asyncKey: HelpersService.randomString()
        });

    }

    public async handleListAction(action: Table.Action.IResult): Promise<any> {
        if (action.name === "goToOrder") {
            this.router.navigate([
                "/partner",
                action.data.partner.slug,
                "orders",
                "view",
                "id",
                action.data.order.id
            ]);
            this.modal.response.emit(null);
        }

    }

    public ngOnInit(): void {
        this.prepareList();
    }

}
