import {Injectable, OnDestroy} from "@angular/core";
import {ReplaySubject, Subscription} from "rxjs";
import {take} from "rxjs/operators";
import {SnackbarComponent} from "../components/snackbar/snackbar.component";
import {MatBottomSheet, MatBottomSheetRef} from "@angular/material/bottom-sheet";

export interface ISnackData {
    message: string;
    url?: string | string[];
    request?: {
        type: "error";
    };
}

@Injectable()
export class ToastService implements OnDestroy {

    private subscription: Subscription;

    private snackBarRef: MatBottomSheetRef<SnackbarComponent>;

    private isInstanceVisible: boolean = false;

    private messageQueue: { message: ISnackData, type: string, onOpenCallback: any }[] = [];

    public queueUpdated: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

    public constructor(private bottomSheet: MatBottomSheet) {
    }

    private open(): void {
        this.isInstanceVisible = true;
        this.snackBarRef = this.bottomSheet.open(SnackbarComponent, {
            hasBackdrop: false,
            autoFocus: false,
            restoreFocus: false
        });

        this.snackBarRef.instance.close.pipe(take(1)).subscribe((): void => {
            this.snackBarRef.dismiss();
            this.isInstanceVisible = false;
        });
    }


    public getNextFromQueue(): { message: ISnackData, type: string, onOpenCallback: any } {
        return this.messageQueue.shift();
    }

    /**
     * Show toast message (aka snackbar)
     * @param {string | ISnackData} message
     * @param {string} type
     * @param onOpenCallback
     */
    public show(message: string | ISnackData, type: string, onOpenCallback: any = null): void {
        if (!message) {
            return;
        }

        if (typeof message === "string") {
            message = {message};
        } else if (message.url) {
            if (message.url.includes("http")) {
                message.message = `<a href="${message.url}" target="_blank">${message.message}</a>`;
                message.url = null;
            }
        }

        this.messageQueue.push({message, type, onOpenCallback});
        this.queueUpdated.next(true);

        if (!this.isInstanceVisible) {
            this.open();
        }
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
