<mat-card>
    <div class="sort pull-right">
        Sort:
        <button mat-icon-button color="primary" (click)="onMoveUp.emit(true)"
                [disabled]="index === 0"
                matTooltip="Move up" type="button">
            <mat-icon>arrow_drop_up</mat-icon>
        </button>
        <button mat-icon-button color="primary" (click)="onMoveDown.emit(true)"
                matTooltip="Move down" type="button">
            <mat-icon>arrow_drop_down</mat-icon>
        </button>


        <button mat-icon-button color="warn" (click)="onDelete.emit(true)"
                matTooltip="Remove" type="button">
            <mat-icon>remove</mat-icon>
        </button>
    </div>
    <div class="move pull-right">
        <input type="text" matInput #moveToPos/>
        <button mat-icon-button color="primary" (click)="onMoveTo.emit(moveToPos.value * 1)"
                matTooltip="Move down" type="button">
            <mat-icon>check</mat-icon>
        </button>
    </div>
    <h3>Group {{index + 1}}</h3>

    <div class="group flex">
        <mat-form-field class="full">
            <mat-label>Label</mat-label>
            <input matInput [formControl]="label" required>
        </mat-form-field>
    </div>

    <div *ngFor="let field of options.fields; let i = index">

        <common-form-builder-group
                *ngIf="field.type === 'group'"
                [index]="i"
                [options]="field"
                (onChanges)="onChangesChild(i,$event)"
                (onMoveDown)="onMoveDownChild(i)"
                (onMoveUp)="onMoveUpChild(i)"
                (onMoveTo)="onMoveToChild(i,$event)"
                (onDelete)="onDeleteChild(i)"
        ></common-form-builder-group>


        <common-form-builder-field
                *ngIf="field.type !== 'group'"
                [index]="i"
                [options]="field"
                (onChanges)="onChangesChild(i,$event)"
                (onMoveDown)="onMoveDownChild(i)"
                (onMoveUp)="onMoveUpChild(i)"
                (onMoveTo)="onMoveToChild(i,$event)"
                (onDelete)="onDeleteChild(i)"
        ></common-form-builder-field>
    </div>


    <div class="actions full">
        <button (click)="addField()"
                type="button" mat-raised-button color="primary">
            Add field
        </button>
        &nbsp;
        <button (click)="addGroup()" type="button" mat-raised-button
                color="accent">
            Add group
        </button>
    </div>

</mat-card>
