<mat-card>

    <h1>Select item to update</h1>

    <form [formGroup]="form" (submit)="submit()">
        <div class="group flex">
            <div class="half" *ngIf="part_masters">
                <common-form-select [options]="part_masters"
                                    [search]="true"
                                    [required]="true"
                                    label="Select item"
                                    [value]="form.value.part_master_id"
                                    (valueChange)="onPMSelected($event)"
                ></common-form-select>
            </div>

            <div class="half">
                <common-form-hub-select
                        label="Hub"
                        [required]="true"
                        line_type="outbound"
                        [value]="form.value.inventory_conversion_id"
                        [part_master_id]="form.value.part_master_id"
                        (onChange)="form.get('inventory_conversion_id').setValue($event)"></common-form-hub-select>
            </div>

            <div class="full margin-bottom-20" *ngIf="inventories">
                <mat-radio-group>
                    <mat-card class="tile mat-elevation-z5 margin-bottom-10"
                              *ngFor="let inventory of inventories"
                              (click)="selectInventory(inventory)">
                        <div class="flex no-wrap">
                            <div>
                                <mat-radio-button [value]="inventory"></mat-radio-button>
                            </div>
                            <div>
                                <b>{{inventory.inventory.item}}</b>
                                {{ inventory.inventory.configurations ? " | Conf: " + inventory.inventory.configurations : "" }}
                                {{ inventory.inventory.rev ? " | Rev: " + inventory.inventory.rev : ""}}
                                {{ inventory.inventory.batch ? " | Batch: " + inventory.inventory.batch : "" }}
                                {{ inventory.inventory.warehouse_location ? " | Location: " + inventory.inventory.warehouse_location.location : "" }}
                                | Qty: {{ inventory.sum }}
                            </div>
                        </div>
                    </mat-card>
                </mat-radio-group>
            </div>

            <div class="full margin-bottom-20" *ngIf="inventories && !inventories.length">
                <mat-card class="tile mat-elevation-z5">
                    <p>No items to show</p>
                </mat-card>
            </div>

            <mat-form-field class="half">
                <mat-label>Needed quantity (final result per location)</mat-label>
                <input type="number" matInput required formControlName="quantity" [min]="0">
            </mat-form-field>


            <mat-form-field class="half">
                <mat-label>Select location</mat-label>
                <mat-select formControlName="location_id">

                    <mat-option *ngIf="locations.length">
                        <ngx-mat-select-search [formControl]="location_search"
                                               [placeholderLabel]="'Search location'"
                                               noEntriesFoundLabel="0 locations found">
                        </ngx-mat-select-search>
                    </mat-option>

                    <mat-option *ngFor="let location of filtered_locations | async" [value]="location.value">
                        {{location.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="full" *ngIf="show_batch_field">
                <mat-label>New items batch</mat-label>
                <input matInput formControlName="batch">
            </mat-form-field>

            <div class="half filler" *ngIf="!show_locations.value || !locations"></div>

            <mat-form-field class="full">
                <mat-label>Remark</mat-label>
                <textarea row="5" matInput required formControlName="remark"></textarea>
            </mat-form-field>

        </div>
        <div class="actions">
            <button type="submit" mat-raised-button color="accent"
                    [disabled]="!form.valid || selected_inventory_sum === form.value.quantity">
                Update
            </button>
        </div>
    </form>

</mat-card>
