import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {UntypedFormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {UserService} from "../../../../../../common/services/user.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";

@Component({
    selector: "section-milestone-reason-form",
    templateUrl: "reason-form.component.html",
    styleUrls: [
        "reason-form.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class MilestoneReasonFormComponent implements OnInit {

    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    public modal: Modal.IModal;
    public formGroup: FormGroup;
    public edit: boolean = false;
    public types: { id: number, name: string, creation_trigger: string, complete_trigger: string }[] = [];
    public reasons: { id: number, name: string, milestone_type_id: string, type: any }[] = [];

    public data: any;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private formBuilder: UntypedFormBuilder,
        private helpers: HelpersService,
        private toastService: ToastService,
        private userService: UserService,
        private modalService: ModalService,
        private spinnerService: SpinnerService
    ) {
    }

    /**
     * Prepare form group (create)
     * @returns {void}
     */
    private prepareForm(): void {
        this.formGroup = this.formBuilder.group({
            name: new FormControl(null, [Validators.required]),
            milestone_type_id: new FormControl(null, [Validators.required])
        });

        this.changeDetectorRef.markForCheck();
    }

    /**
     * Get list of types
     * @returns {Promise<any>}
     */
    private async getMilestoneTypes(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["milestones", "types"]);
        if (data) {
            this.types = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get list of reasons
     * @returns {Promise<any>}
     */
    private async getMilestoneReasons(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["milestones", "reasons"]);
        if (data) {
            this.reasons = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Submit form
     * @returns {Promise<any>}
     */
    public async handleFormSubmit(): Promise<any> {
        this.spinnerService.show();

        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["milestones", "reasons"], this.formGroup.value);
        if (response) {
            this.toastService.show(response.message, response.type as string);
            this.reasons.push(response.data);
            this.formGroup.reset();
        }
        this.spinnerService.hide();
    }

    public ngOnInit(): void {
        this.spinnerService.show();
        this.getMilestoneReasons();
        this.getMilestoneTypes();
        this.prepareForm();
        this.spinnerService.hide();
    }

    public getByType(type: string): any[] {
        return this.reasons.filter((reason: any): boolean => {
            return +reason.milestone_type_id === +type;
        });
    }
}
