<div class="dates-filter">

    <div class="full" *ngIf="dateRange">
        <common-form-date-range
                label="Date range"
                [inline]="false"
                [value]="dateRange.value"
                [show_time]="false"
                (valueChangeMoment)="dateRange.setValue($event)">
        </common-form-date-range>
    </div>
</div>

<mat-accordion multi>
    <ng-container *ngIf="filters">
        <ng-template ngFor let-filter [ngForOf]="filters | keys">
            <ng-container *ngIf="filter !== 'date_from' && filter !== 'date_to'">
                <mat-expansion-panel class="{{filter | lowercase}}">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{ filter | replace: "_" : " " : true : "g" | ucfirst }}
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="filter-content" *ngIf="filters[filter]">
                        <mat-form-field class="filter-search" [hidden]="filters[filter].length < 10">
                            <mat-label>Search</mat-label>
                            <input matInput #search type="search">
                        </mat-form-field>
                        <ng-template ngFor let-item [ngForOf]="filters[filter] | search : search.value : ['text']">
                            <div class="filter-option">
                                <mat-checkbox [value]="item.value" [checked]="item.selected"
                                              (change)="item.selected = $event.checked;">
                                    <span class="{{filter === 'statuses' ? 'chip': '' }} {{item.text | lowercase}}"
                                          [title]="item.text">
                                        {{ item.text | ucfirst }}
                                    </span>
                                </mat-checkbox>
                                <span *ngIf="item.counted >= 0" class="filter-option-count">{{ item.counted }}</span>
                            </div>
                        </ng-template>
                    </div>

                    <div class="filter-content" *ngIf="!filters[filter]">
                        <common-simple-spinner></common-simple-spinner>
                    </div>

                </mat-expansion-panel>
            </ng-container>
        </ng-template>
    </ng-container>
</mat-accordion>

<div class="row flex space-between margin-top-20 margin-bottom-10">
    <div class="half padding-left-10">
        <common-load-config [configId]="configId"
                            label="Filter"
                            color="accent"
                            [config]="filters"
                            (onClick)="onChange()"
                            (onConfigSelect)="onChange($event)"></common-load-config>
    </div>
    <div class="half text-right padding-right-10">
        <button type="button" mat-raised-button (click)="clearFilters()">
            Clear filters
        </button>
    </div>
</div>
