<common-confirm></common-confirm>

<h1 class="container-heading">{{ action | ucfirst }} contact</h1>

<mat-card>
    <mat-card-content>

        <form *ngIf="formGroup" [formGroup]="formGroup" (submit)="handleFormSubmit()" autocomplete="off" novalidate>

            <div class="group flex">
                <mat-form-field class="half">
                    <mat-label>First name</mat-label>
                    <input matInput formControlName="first_name">
                    <common-form-error [form]="formGroup" controlName="first_name"></common-form-error>
                </mat-form-field>
                <mat-form-field class="half">
                    <mat-label>Last name</mat-label>
                    <input matInput formControlName="last_name">
                    <common-form-error [form]="formGroup" controlName="last_name"></common-form-error>
                </mat-form-field>
                <mat-form-field class="full">
                    <mat-label>Email address</mat-label>
                    <input matInput formControlName="email">
                    <common-form-error [form]="formGroup" controlName="email"></common-form-error>
                </mat-form-field>
                <mat-form-field class="half">
                    <mat-label>Phone number (with local country code)</mat-label>
                    <input matInput formControlName="phone">
                    <common-form-error [form]="formGroup" controlName="phone"></common-form-error>
                </mat-form-field>
                <mat-form-field class="half">
                    <mat-label>Mobile Phone number (with local country code)</mat-label>
                    <input matInput formControlName="mobile_phone">
                    <common-form-error [form]="formGroup" controlName="mobile_phone"></common-form-error>
                </mat-form-field>
                <mat-form-field class="half">
                    <mat-label>Fax</mat-label>
                    <input matInput formControlName="fax">
                    <common-form-error [form]="formGroup" controlName="fax"></common-form-error>
                </mat-form-field>
            </div>

            <div class="actions">
                <button mat-raised-button color="primary" type="submit" class="main" [disabled]="!formGroup.valid">
                    {{ action | ucfirst }}
                </button>
                <button mat-raised-button *ngIf="modal.params.canDelete"
                        type="button" color="warn" (click)="delete()">
                    Delete
                </button>
                <button mat-button type="button" (click)="showList()">Cancel</button>
            </div>

        </form>

    </mat-card-content>
</mat-card>
