<common-confirm></common-confirm>
<div class="heading">
    <button mat-raised-button color="primary" (click)="add()">Add box</button>
</div>
<div class="container-columns">
    <div class="left">
        <mat-card class="drag-bag order-items">
            <div class="title">
                <mat-checkbox [checked]="allFreeItemsSelected"
                              class="pull-left"
                              (change)="selectAll($event, items)">
                    Order items
                </mat-checkbox>
            </div>

            <div class="items free" *ngIf="items && items.length; else noItems"
                 [dragula]='bagName' [(dragulaModel)]="items">
                <div class="item id-{{item.id}}" *ngFor="let item of (items || [])">
                    <mat-checkbox [value]="item.id"
                                  [checked]="selectedList.indexOf(item.id) > -1"
                                  (change)="selectedListUpdate($event)">
                        {{item.item}} (sn: {{item.serial}}) (ref2: {{item.ref2}}). qty: {{item.quantity}}
                    </mat-checkbox>
                </div>
            </div>
            <ng-template #noItems>
                <div class="items free text-center padding-top-10" [dragula]='bagName' [(dragulaModel)]="items">No not
                    linked items
                </div>
            </ng-template>
        </mat-card>
    </div>
    <div class="right">
        <ng-template *ngIf="parcels" ngFor let-i="index" let-parcel [ngForOf]="parcels">
            <mat-card class="drag-bag parcel">
                <div class="title">
                    <mat-checkbox [checked]="allParcelItemsSelected[i]"
                                  class="pull-left"
                                  (change)="selectAll($event, parcel.order_items)">
                        {{parcel.ref}}
                        ({{parcel.weight}}x{{parcel.height}}x{{parcel.length}}{{parcel.distance_unit}},
                        {{parcel.weight}}kg)
                    </mat-checkbox>

                    <button mat-icon-button (click)="edit(parcel)">
                        <mat-icon title="Edit">edit</mat-icon>
                    </button>
                </div>

                <mat-divider></mat-divider>

                <div class="items box id-{{parcel.id}}" [dragula]='bagName'
                     [(dragulaModel)]="parcel.order_items">
                    <div class="item id-{{item.id}}" *ngFor="let item of parcel.order_items">
                        <mat-checkbox [value]="item.id"
                                      [checked]="selectedList.indexOf(item.id) > -1"
                                      (change)="selectedListUpdate($event)">
                            {{item.item}} (sn: {{item.serial}}) (ref2: {{item.ref2}}). qty: {{item.quantity}}
                        </mat-checkbox>

                    </div>
                </div>
            </mat-card>
        </ng-template>
    </div>
</div>
