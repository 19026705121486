import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {User} from "../../../../../../common/interfaces/user.interface";
import {FormControl, FormGroup} from "@angular/forms";
import {ToastService} from "../../../../../../common/services/toast.service";
import {ModalService} from "../../../../../services/modal.service";
import {UserService} from "../../../../../../common/services/user.service";
import {Contact} from "../../../../../../common/interfaces/contact.interface";
import {SpinnerService} from "../../../../../../common/services/spinner.service";

@Component({
    selector: "section-user-view-notifications",
    templateUrl: "notifications.component.html",
    styleUrls: [
        "notifications.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class UserViewNotificationsComponent implements OnInit, OnDestroy {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input()
    public user: User.IData;

    @Input()
    public state: Base.IState;

    public countries: Contact.ICountry[] = [];

    public proForm: FormGroup = new FormGroup({
        prevent_notify_new_remarks: new FormControl(false)
    });

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private userService: UserService,
        private modalService: ModalService,
        private spinnerService: SpinnerService
    ) {
    }

    /**
     * Fill profile form fields with user data
     */
    public async prepareProfile(): Promise<any> {
        const {data, message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["notifications"]);
        if (data) {
            const notifications: any = {};
            data.forEach((val: any): void => {
                notifications[val.name] = true;
            });
            this.proForm.patchValue(notifications);
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Save user profile
     */
    public async saveProfile(): Promise<any> {
        this.spinnerService.show();
        const body: any = {...this.proForm.value};
        const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["notifications"], body);
        if (type as string === "success") {
            this.toastService.show(message, "success");
            this.userService.getUser().then((): void => {
                this.user = this.userService.data;
                this.changeDetectorRef.markForCheck();
            });
        }
        this.spinnerService.hide();
    }

    public async ngOnInit(): Promise<any> {

        this.prepareProfile();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
