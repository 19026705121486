<common-confirm></common-confirm>

<h1 class="container-heading">{{window_title}}</h1>

<form [formGroup]="formGroup" (submit)="save()" class="shipmentForm">
    <div class="group flex">
        <mat-form-field class="full">
            <mat-label>Ref</mat-label>
            <input matInput formControlName="ref" required>
        </mat-form-field>

        <mat-form-field class="half">
            <mat-label>From address</mat-label>
            <mat-select formControlName="address_from" required>
                <mat-option *ngFor="let address of from_address" [value]="address.id">
                    <ng-template [ngIf]="address.address">
                        {{address.country}}, {{address.city}}, {{address.address}}
                    </ng-template>
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="half">
            <mat-label>To address</mat-label>
            <mat-select formControlName="address_to" required>
                <mat-option *ngFor="let address of to_address" [value]="address.id">
                    <ng-template [ngIf]="address.address">
                        {{address.country}}, {{address.city}}, {{address.address}}
                    </ng-template>
                </mat-option>
            </mat-select>
        </mat-form-field>

        <span class="full address-error-message padding-top-20" *ngIf="isAddressesInvalid">The addresses cannot be the same</span>

        <mat-form-field class="half">
            <mat-label>Weight</mat-label>
            <input matInput formControlName="weight" required>
        </mat-form-field>

        <mat-form-field class="half">
            <mat-label>Mass unit</mat-label>
            <mat-select formControlName="mass_unit" required>
                <mat-option *ngFor="let unit of massUnits" [value]="unit">
                    {{unit}}
                </mat-option>
            </mat-select>
        </mat-form-field>

    </div>


    <div class="actions">
        <button [disabled]="!formGroup.valid || isAddressesInvalid" mat-raised-button color="primary" type="submit"
                class="main">
            Save
        </button>

        <button mat-raised-button color="warn" class="main pull-right" *ngIf="modal.params.action === 'edit'"
                type="button" (click)="delete()">
            Delete
        </button>
    </div>
</form>
