import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from "@angular/core";
import {Base} from "./base.interfaces";
import {Wizard} from "./wizard.interface";

@Component({
    template: ""
})
export abstract class AbstractSinglePageWizardComponent implements OnInit {

    protected partner_slug: string;

    public wizardHeading: string;

    public readonly state: Base.IState;

    public data: Wizard.IData;

    public steps: Wizard.IStep[] = [];

    public valid: boolean = false;

    public constructor(protected changeDetectorRef: ChangeDetectorRef) {
    }

    protected validate(): void {
        let valid: boolean = true;
        for (const step of this.steps) {
            if (!step.control.valid) {
                valid = false;
            }
        }
        this.valid = valid;
        this.changeDetectorRef.markForCheck();
    }

    protected resultEvent(event: Wizard.IStepResult, stepIndex: number): void {
        this.steps[stepIndex].control.setValue(event.value ? true : null);
        this.validate();
    }

    protected dataEvent(event: Wizard.IStepResult): void {
        if (event.value && typeof event.value === "object") {
            this.data = {...this.data, ...event.value};
        }
    }

    protected componentPrepareEvent(event: Wizard.IStepResult, stepIndex: number): void {
        this.steps[stepIndex].componentRef = event.value;
        this.changeDetectorRef.markForCheck();
    }


    protected setupEvent(event: Wizard.IStepResult, stepIndex: number, config: any = {}): void {
    }


    /**
     * Step result event handler
     * @param event
     * @param stepIndex
     */
    public stepEvent(event: Wizard.IStepResult, stepIndex: number): void {
        if (event.action === "ready") {
            this.componentPrepareEvent(event, stepIndex);
        } else if (event.action === "setup") {
            this.setupEvent(event, stepIndex);
        } else if (event.action === "result") {
            this.resultEvent(event, stepIndex);
        } else if (event.action === "destroy") {
            this.steps.splice(stepIndex, 1);
        } else if (event.action === "data") {
            this.dataEvent(event);
        }
        this.changeDetectorRef.markForCheck();
    }

    public ngOnInit(): void {
        this.data = {state: this.state};
        const [section, slug]: string[] = this.state.section.split("/", 2);
        if (slug) {
            this.partner_slug = slug;
        }

        this.setupEvent({action: "setup", value: "init"}, -1);
    }
}

@Component({
    template: ""
})
export abstract class AbstractSinglePageWizardStepComponent implements OnDestroy, OnChanges, OnInit {

    protected destroy$: EventEmitter<boolean> = new EventEmitter(false);

    public data: Wizard.IData;

    @Output()
    public result: EventEmitter<Wizard.IStepResult> = new EventEmitter();

    public constructor(protected changeDetectorRef: ChangeDetectorRef) {
    }

    protected abstract setup(data: Wizard.IData): void;

    public ngOnInit(): void {
        this.setup(this.data);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.data) {
            const pv: any = changes.data.previousValue;
            const cv: any = changes.data.currentValue;
            if (!pv && cv) {
                this.setup(cv);
            } else if (pv && cv) {
                const jPv: string = JSON.stringify(pv);
                const jCv: string = JSON.stringify(cv);
                if (jPv !== jCv) {
                    this.setup(cv);
                }
            }
        }
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
