import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {Router} from "@angular/router";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Modal} from "../../../../../services/modal.service";
import {Api, ApiService} from "../../../../../../common/services/api.service";

@Component({
    selector: "section-tracking-map-sidebar",
    template: `
        <div *ngIf="data && !load">
            <h2>Order:
                <b (click)="goToOrder()" class="pointer">
                    {{ data.order.ref }}
                    <mat-icon class="vertical-middle">arrow_forward</mat-icon>
                </b>
            </h2>
            <div>
                <mat-icon>airplanemode_active</mat-icon>
                <b>{{ data.shipment.ref }}</b>
            </div>
            <div>
                <b>From:</b>
                <ng-template [ngIf]="data.shipment.from_address.formatted_address">
                    {{ data.shipment.from_address.formatted_address }},
                </ng-template>
                <ng-template [ngIf]="!data.shipment.from_address.formatted_address">
                    {{ data.shipment.from_address.address }},
                    {{ data.shipment.from_address.city }},
                    {{ data.shipment.from_address.country }}
                </ng-template>

            </div>
            <div>
                <b>To:</b>
                <ng-template [ngIf]="data.shipment.to_address.formatted_address">
                    {{ data.shipment.to_address.formatted_address }},
                </ng-template>
                <ng-template [ngIf]="!data.shipment.to_address.formatted_address">
                    {{ data.shipment.to_address.address }},
                    {{ data.shipment.to_address.city }},
                    {{ data.shipment.to_address.country }}
                </ng-template>
            </div>

            <h3>Tracking information:</h3>
            <div class="track">
                <div class="courier">
                    <div class="courier-logo">
                        <img [src]="data.courier.icon_path">
                    </div>
                    <div class="courier-name">
                        {{ data.courier.display_name }}
                    </div>
                </div>
                <div class="tracking">
                    <div class="number">Tr.№
                        <b>{{ data.tracking_number }}</b>
                    </div>
                    <div class="status">Status:
                        <span class="chip {{data.status}}">
                                {{ data.status }}
                            </span>
                    </div>
                </div>
            </div>

            <ng-template
                [ngIf]="data.courier_transaction_checkpoints && data.courier_transaction_checkpoints.length > 0">
                <h3>Shipment checkpoints:</h3>

                <div class="checkpoints">
                    <ng-template ngFor let-checkpoint [ngForOf]="data.courier_transaction_checkpoints[0].checkpoints">
                        <div class="checkpoint">
                            <div class="date" *ngIf="checkpoint.checkpoint_time">
                                <b>{{ checkpoint.checkpoint_time | commonFix | date: "dd/MM/y" }}</b>
                                <br>
                                {{ checkpoint.checkpoint_time | commonFix | date: "HH:mm" }}
                            </div>

                            <div class="icon" [ngSwitch]="checkpoint.tag">
                                <span></span>
                                <mat-icon *ngSwitchCase="'Delivered'" class="Delivered">check_circle</mat-icon>
                                <mat-icon *ngSwitchDefault>radio_button_checked</mat-icon>
                                <span></span>
                            </div>
                            <div class="status ">
                                <b>{{ checkpoint.message }}</b>
                                <br>
                                {{ checkpoint.location }}
                            </div>
                        </div>
                    </ng-template>
                </div>
            </ng-template>

        </div>
        <div *ngIf="load">
            <common-simple-spinner></common-simple-spinner>
        </div>
        <div *ngIf="!data && !load">
            <p>
                No data.
            </p>
        </div>
    `,
    styleUrls: [
        "map.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class TrackingMapSidebarComponent implements OnInit {

    public modal: Modal.IModal;

    public data: any;

    public load: boolean = false;

    public constructor(
        private router: Router,
        private spinnerService: SpinnerService,
        private apiService: ApiService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    private async getData(): Promise<any> {
        this.load = true;
        this.changeDetectorRef.markForCheck();

        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["courier", "transactions"], {}, {
                shipment_id: this.modal.params.marker.data.shipment.id
            });

        this.load = false;

        if (data && data[0]) {
            this.data = data[0];
        }
        this.changeDetectorRef.markForCheck();
    }

    public goToOrder(): void {
        this.modal.response.emit();
        this.router.navigate([
            "/partner",
            this.data.partner.slug,
            "orders",
            "view",
            "id",
            this.data.order.id
        ]);
    }


    public ngOnInit(): void {
        this.getData();
    }

}
