<div class="container-columns">
    <mat-card class="left">
        <h3>Courier transactions</h3>
        <button mat-mini-fab color="success" class="pull-right" (click)="addCourierTransaction()">
            <mat-icon>add</mat-icon>
        </button>

        <mat-form-field class="search">
            <mat-label>Search</mat-label>
<input matInput #trackSearch type="search" >
        </mat-form-field>

        <div class="items" *ngIf="tracks && tracks.data">
            <ng-template ngFor let-track
                         [ngForOf]="tracks.data | search: trackSearch.value : ['tracking_number']">
                <div class="item">
                    <mat-icon (click)="editCourierTransaction(track)" class="pull-right">edit</mat-icon>
                    <div class="title">
                        <span>Status: <b>{{track.status}}</b></span>
                    </div>
                    <div>
                        <img [src]="track.courier.icon_path" alt="" width="45">
                    </div>
                    <div>Courier: <b>{{track.courier.display_name}}</b></div>
                    <div>Tracking number: <b>{{track.tracking_number}}</b></div>
                    <div class="drag-bag transactions id-{{track.id}}"
                         *ngIf="track.warehouse_transactions"
                         [dragula]='"warehouse-track-bag"'
                         [(dragulaModel)]="track.warehouse_transactions">
                        <div *ngFor="let transaction of track.warehouse_transactions"
                             class="transaction id-{{transaction.id}}">
                            Transaction:
                            <div class="title">
                                <span>Item: <b>{{transaction.item}}</b></span>
                                <span>Serial: <b>{{transaction.serial}}</b></span>
                                <span>Qty: <b>{{transaction.quantity}}</b></span>
                                <span>Type: <b>{{transaction.type}}</b></span>
                                <span>Warehouse: <b *ngIf="transaction.inventory_conversion">
                                    {{transaction.inventory_conversion.warehouse_slug}} -
                                    {{transaction.inventory_conversion.sub_inventory}}</b>
                                </span>
                            </div>

                        </div>
                    </div>
                </div>
            </ng-template>
            <common-pagination [data]="tracks"
                               pid="716ZXlLbb"
                               (goToPage)="getTracks($event.page,$event.per_page)"></common-pagination>
        </div>
    </mat-card>
    <mat-card class="right">
        <h3>Order transactions</h3>
        <mat-form-field class="search">
            <mat-label>Search</mat-label>
<input matInput #transactionsSearch type="search" >
        </mat-form-field>
        <ng-template [ngIf]="orderTransactions && orderTransactions.data">
            <div class="transactions"
                 [dragula]='"warehouse-track-bag"'
                 [(dragulaModel)]="orderTransactions.data">
                <div class="transaction id-{{transaction.id}}"
                     *ngFor="let transaction of orderTransactions.data | search: transactionsSearch.value : ['item','serial']">
                    Transaction:
                    <div class="title">
                        <span>Item: <b>{{transaction.item}}</b></span>
                        <span>Serial: <b>{{transaction.serial}}</b></span>
                        <span>Qty: <b>{{transaction.quantity}}</b></span>
                        <span>Type: <b>{{transaction.type}}</b></span>
                        <span>Warehouse: <b *ngIf="transaction.inventory_conversion">
                    {{transaction.inventory_conversion.warehouse_slug}} -
                            {{transaction.inventory_conversion.sub_inventory}}</b></span>
                        <span>
                        GB:
                        <span class="chip {{transaction.inventory_conversion.gb}}">
                            {{transaction.inventory_conversion.gb}}
                        </span>
                    </span>
                    </div>
                    <div>Description: <b>{{transaction.part_master.description}}</b></div>
                </div>
            </div>
            <common-pagination [data]="orderTransactions"
                               pid="F3ibC30Hz"
                               (goToPage)="getOrderTransactions($event.page, $event.per_page)">
            </common-pagination>
        </ng-template>
    </mat-card>
</div>
