import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, Validators} from "@angular/forms";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {StepperSelectionEvent} from "@angular/cdk/stepper";
import {PartnerFormStep1Component} from "./step1/step1.component";
import {PartnerFormStep2Component} from "./step2/step2.component";
import {MatStepper} from "@angular/material/stepper";
import {PartnerFormStep3Component} from "./step3/step3.component";
import {PartnerFormStep4Component} from "./step4/step4.component";
import {Router} from "@angular/router";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {PartnerFormStep5Component} from "./step5/step5.component";

@Component({
    selector: "section-partner-form",
    templateUrl: "form.component.html",
    styleUrls: [
        "form.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class PartnerFormComponent implements Base.IComponent, OnInit {

    @ViewChild(PartnerFormStep1Component, {static: true})
    public step1Form: PartnerFormStep1Component;

    @ViewChild(PartnerFormStep2Component, {static: true})
    public step2Form: PartnerFormStep2Component;

    @ViewChild(PartnerFormStep3Component, {static: true})
    public step3Form: PartnerFormStep3Component;

    @ViewChild(PartnerFormStep4Component, {static: true})
    public step4Form: PartnerFormStep4Component;

    @ViewChild(PartnerFormStep5Component, {static: true})
    public step5Form: PartnerFormStep5Component;

    @ViewChild("stepper", {static: true})
    public stepper: MatStepper;

    /**
     * Step1 step state
     * @type {{control: FormControl}}
     */
    public step1: { control: FormControl } = {
        control: new FormControl(null, [Validators.required])
    };

    /**
     * Step2 step state
     * @type {{control: FormControl}}
     */
    public step2: { control: FormControl } = {
        control: new FormControl(null, [Validators.required])
    };

    /**
     * Step3 step state
     * @type {{control: FormControl}}
     */
    public step3: { control: FormControl } = {
        control: new FormControl(null, [Validators.required])
    };

    /**
     * Step4 step state
     * @type {{control: FormControl}}
     */
    public step4: { control: FormControl } = {
        control: new FormControl(null, [Validators.required])
    };
    /**
     * Step5 step state
     * @type {{control: FormControl}}
     */
    public step5: { control: FormControl } = {
        control: new FormControl(null, [Validators.required])
    };


    public readonly state: Base.IState;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private spinnerService: SpinnerService
    ) {
    }


    /**
     * Handle step changes
     * @param {StepperSelectionEvent} event
     * @returns {void}
     */
    public async handleStepChanges(event?: StepperSelectionEvent): Promise<any> {
        const step: number = event && event.selectedIndex;
        switch (step) {
            case 1:
                this.step2Form.init(this.step1.control.value.slug);
                break;
            case 2:
                this.step3Form.init(this.step1.control.value.slug);
                break;
            case 3:
                this.step4Form.init(this.step1.control.value.slug);
                break;
            case 4:
                this.step5Form.init(this.step1.control.value);
                break;
            default:
                this.step1Form.init(this.state);
                break;
        }
    }

    public onAuthFail(): void {
        this.stepper.selectedIndex = 0;
        this.step1.control.setValue(null);
        this.step2.control.setValue(null);
        this.step3.control.setValue(null);
        this.step4.control.setValue(null);
        this.step5.control.setValue(null);
        this.changeDetectorRef.markForCheck();
    }

    public finish(): void {
        this.router.navigate([
            "/admin", "partner"
        ]);
    }

    public async ngOnInit(): Promise<any> {
        this.handleStepChanges();

        this.stepper.selectedIndex = 0;
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "partner",
            actions: {
                "add": ["add_partners"],
                "edit": ["edit_partners"]
            }
        };
    }

}
