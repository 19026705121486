import {Pipe, PipeTransform} from "@angular/core";
import moment from "moment-timezone";
import {TIME_ZONES} from "../common.module";

@Pipe({
    name: "tz"
})
export class TimezonePipe implements PipeTransform {

    public transform(
        datetime: string,
        timezone1: string,
        timezone2: string,
        format: string = "yyyy-MM-DD HH:mm:ss",
        showTimezone: boolean = false
    ): string {

        timezone1 = this.checkTimezone(timezone1);

        var il_time = moment.tz(datetime, timezone1);

        if (timezone2) {
            timezone2 = this.checkTimezone(timezone2);

            return il_time.clone().tz(timezone2).format(format)
                + (showTimezone ? " (" + moment.tz.zone(timezone2).name + ")" : "");
        }

        return il_time.format(format) + (showTimezone ? " (" + moment.tz.zone(timezone1).name + ")" : "");
    }


    private checkTimezone(timezone: string): string {
        if (timezone.includes("GMT")) {
            const zoneNames: string[] = Object.values(TIME_ZONES);
            const zone: string = zoneNames.find((z: string) => {
                return z.includes(timezone) || z.includes(timezone.replace("GMT ", "GMT"));
            });

            if (zone) {
                const index: number = zoneNames.indexOf(zone);
                if (index >= 0) {
                    timezone = Object.keys(TIME_ZONES)[index];
                }
            }
        }

        return timezone;

    }
}
