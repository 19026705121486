import {Component, ElementRef, Input, OnChanges, Renderer2, ViewChild} from "@angular/core";

declare var require: any;

const jsbarcode: any = require("jsbarcode");

@Component({
    selector: "common-barcode",
    template: `
        <div #bcElement [class]="bcCssClass"></div>`
})
export class BarcodeComponent implements OnChanges {


    @Input()
    public bcElementType: "svg" | "img" | "canvas" = "svg";

    @Input()
    public bcCssClass: string = "barcode"; // this should be done more elegantly

    @Input()
    public bcFormat: "" | "CODE128" | "CODE128A" | "CODE128B"
        | "CODE128C" | "EAN" | "UPC" | "EAN8" | "EAN5" |
        "EAN2" | "CODE39" | "ITF14" | "MSI" | "MSI10" | "MSI11"
        | "MSI1010" | "MSI1110" | "pharmacode" | "codabar" = "CODE128";

    @Input()
    public bcLineColor: string = "#000000";

    @Input()
    public bcWidth: number = 2;

    @Input()
    public bcHeight: number = 100;

    @Input()
    public bcDisplayValue: boolean = false;

    @Input()
    public bcFontOptions: string = "";

    @Input()
    public bcFont: string = "monospace";

    @Input()
    public bcTextAlign: string = "center";

    @Input()
    public bcTextPosition: string = "bottom";

    @Input()
    public bcTextMargin: number = 2;

    @Input()
    public bcFontSize: number = 20;

    @Input()
    public bcBackground: string = "#ffffff";

    @Input()
    public bcMargin: number = 10;

    @Input()
    public bcMarginTop: number = 10;

    @Input()
    public bcMarginBottom: number = 10;

    @Input()
    public bcMarginLeft: number = 10;

    @Input()
    public bcMarginRight: number = 10;

    @Input()
    public bcValue: any = "";

    @ViewChild("bcElement", {static: true})
    public bcElement: ElementRef;

    public get options(): any {
        return {
            format: this.bcFormat,
            lineColor: this.bcLineColor,
            width: this.bcWidth,
            height: this.bcHeight,
            displayValue: this.bcDisplayValue,
            fontOptions: this.bcFontOptions,
            font: this.bcFont,
            textAlign: this.bcTextAlign,
            textPosition: this.bcTextPosition,
            textMargin: this.bcTextMargin,
            fontSize: this.bcFontSize,
            background: this.bcBackground,
            margin: this.bcMargin,
            marginTop: this.bcMarginTop,
            marginBottom: this.bcMarginBottom,
            marginLeft: this.bcMarginLeft,
            marginRight: this.bcMarginRight,
            // valid: this.valid,
        };
    }

    public constructor(private renderer: Renderer2) {
    }


    private createBarcode(): void {
        if (!this.bcValue) {
            return;
        }

        let element: Element;
        switch (this.bcElementType) {
            case "img":
                element = this.renderer.createElement("img");
                break;
            case "canvas":
                element = this.renderer.createElement("canvas");
                break;
            case "svg":
            default:
                element = this.renderer.createElement("svg", "svg");
        }

        jsbarcode(element, this.bcValue, this.options);

        for (const node of this.bcElement.nativeElement.childNodes) {
            this.renderer.removeChild(this.bcElement.nativeElement, node);
        }
        this.renderer.appendChild(this.bcElement.nativeElement, element);
    }

    //
    // @Input("bc-valid")
    // public valid: (): void => boolean = (): boolean => {
    //     return true;
    // };


    public ngOnChanges(): void {
        this.createBarcode();
    }
}
