<common-confirm></common-confirm>

<h1 class="container-heading pull-left">{{ action | ucfirst }} transaction</h1>

<h3 *ngIf="modal.params.location_name" class="pull-right padding-top-10">
    Location:
    <span class="color-accent">{{ modal.params.location_name }}</span>
</h3>

<div class="clear"></div>

<mat-card>
    <mat-card-content>

        <div class="form">
            <div class="group flex">
                <mat-form-field class="full">
                    <mat-label>Scan qr code to fulfill data</mat-label>
                    <input matInput [formControl]="qrScan">
                </mat-form-field>
            </div>
        </div>

        <form *ngIf="formGroup" [formGroup]="formGroup" (submit)="handleFormSubmit()" autocomplete="off" novalidate>

            <div class="group flex">

                <mat-form-field class="full" *ngIf="subWh.length">
                    <mat-label>Sub warehouse</mat-label>
                    <mat-select formControlName="sub_warehouse_id" required>
                        <mat-option *ngFor="let svh of subWh" [value]="svh.id">
                            {{ svh.sub_inventory }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="full" *ngIf="hubs.length">
                    <mat-label>Hub</mat-label>
                    <mat-select formControlName="inventory_conversion_id" required>
                        <mat-option>
                            <ngx-mat-select-search #hubSearch
                                                   placeholderLabel="Search"
                                                   noEntriesFoundLabel="no hubs found"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option
                                *ngFor="let hub of hubs | search: hubSearch.value: ['customers_inventory_name','customers_sub_inventory']"
                                [value]="hub.id">
                            {{ hub.customers_inventory_name }} - {{ hub.customers_sub_inventory }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="full">
                    <mat-label>Warehouse ref</mat-label>
                    <input matInput formControlName="wh_ref">
                </mat-form-field>


                <mat-form-field class="full">
                    <mat-label>Item</mat-label>
                    <mat-select formControlName="part_master_id" required>
                        <mat-option>
                            <ngx-mat-select-search [formControl]="partMastersSearch" [placeholderLabel]="'Search'"
                                                   [clearSearchInput]="true"
                                                   [noEntriesFoundLabel]="'No items found'">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let pm of partMastersFiltered | async" [value]="pm.id">
                            {{ pm.item }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="configurations margin-bottom-20">
                    <div class="margin-bottom-10">
                        <label>{{ getProperty("configuration_name", "Configurations") }}</label>
                    </div>
                    <div class="margin-bottom-10">
                        <mat-chip-listbox *ngIf="configurations.length > 0">
                            <mat-chip-option matChipRemove *ngFor="let conf of configurations"
                                             [removable]="true" (removed)="removeConfiguration(conf)">
                                {{ conf }}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip-option>
                        </mat-chip-listbox>
                    </div>
                    <button mat-raised-button color="primary" type="button"
                            [disabled]="!formGroup.get('part_master_id').value"
                            (click)="addConfiguration()">Select configurations
                    </button>
                </div>

                <div class="full" *ngIf="availableLocations && availableLocations.length">
                    <common-form-select
                            label="Location"
                            [search]="true"
                            [options]="availableLocationsOptions"
                            [required]="true"
                            [value]="formGroup.value.location_id"
                            (valueChange)="formGroup.get('location_id').setValue($event)"
                    ></common-form-select>
                </div>

                <div class="full" *ngIf="availableLocations && availableBatchOptions">
                    <common-form-select
                            label="Batch"
                            [search]="true"
                            [required]="true"
                            [options]="availableBatchOptions"
                            [value]="formGroup.value.batch"
                            (valueChange)="formGroup.get('batch').setValue($event)"
                    ></common-form-select>
                </div>

                <ng-container *ngIf="!availableBatchOptions">
                    <mat-form-field class="full">
                        <mat-label>Batch</mat-label>
                        <input matInput formControlName="batch"
                               [required]="!formGroup.get('batch').valid">
                    </mat-form-field>
                </ng-container>


                <div *ngIf="requiredSerialInfo" class="text-error full">
                    {{ requiredSerialInfo }}
                </div>

                <mat-form-field class="full">
                    <mat-chip-grid #chipGrid>
                        <mat-chip-row matChipRemove *ngFor="let serial of serials" [selectable]="false"
                                      [removable]="true" (removed)="removeSerial(serial)">
                            {{ serial }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip-row>
                        <input #serialsField
                               [placeholder]="'Serial numbers' + (!formGroup.get('serials').valid ? '*':'')"
                               [matChipInputFor]="chipGrid"
                               [matChipInputAddOnBlur]="true"
                               (matChipInputTokenEnd)="addSerial($event)"/>
                    </mat-chip-grid>
                </mat-form-field>

                <div *ngIf="needQty" class="text-error full">
                    Quantity needed: <b>{{ needQty }}</b>
                    &nbsp;
                    &nbsp;
                    <button mat-raised-button
                            type="button"
                            color="accent"
                            class="vertical-middle"
                            (click)="showList()">
                        Show transactions
                    </button>
                </div>

                <div class="full text-error" *ngIf="batchMaxQty >= 0">
                    Quantity available by selected batch: {{ batchMaxQty }}
                </div>

                <div class="full text-error" *ngIf="maxQty >= 0">
                    Max quantity: {{ maxQty }}
                </div>

                <mat-form-field class="full">
                    <mat-label>Quantity</mat-label>
                    <input matInput formControlName="quantity"
                           [max]="maxQty"
                           type="number" required noscroll>
                </mat-form-field>

                <mat-form-field class="full" *ngIf="formGroup.value.type === 'inbound'">
                    <mat-label>Inner boxes</mat-label>
                    <input matInput formControlName="inner_boxes"
                           type="number" noscroll>
                </mat-form-field>

                <mat-form-field class="half" *ngIf="partMaster?.revision">
                    <mat-label>Rev</mat-label>
                    <input matInput formControlName="rev">
                </mat-form-field>

                <mat-form-field class="half">
                    <mat-label>Lot</mat-label>
                    <input matInput formControlName="lot">
                </mat-form-field>

                <div class="full text-error">
                    <mat-checkbox [formControl]="rebuildInventory"><b>Rebuild inventory</b></mat-checkbox>
                </div>

            </div>

            <div class="actions">
                <button [disabled]="!formGroup.valid || submitInProgress" mat-raised-button color="primary"
                        type="submit" class="main">
                    Save
                </button>

                <button *ngIf="can_delete"
                        mat-raised-button color="warn" type="button" (click)="delete()">
                    Delete
                </button>
            </div>
            <div class="actions">
                <button *ngIf="modal.params.action === 'edit' && modal.params.data.order_id"
                        mat-raised-button color="accent" type="button"
                        (click)="unlink()">
                    Unlink from order
                </button>

                <button
                        *ngIf="modal.params.action === 'edit'
                    && modal.params.data
                    && (!modal.params.data.not_skipped_validation_errors
                        || !modal.params.data.not_skipped_validation_errors.length)"
                        mat-raised-button color="accent"
                        type="button"
                        (click)="markInvalid()">
                    Mark Invalid
                </button>

                <ng-container *ngIf="modal.params.data
                        && modal.params.data.not_skipped_validation_errors
                        && modal.params.data.not_skipped_validation_errors.length">
                    <button mat-raised-button
                            type="button"
                            (click)="showErrors()">
                        Show errors
                    </button>

                    <button mat-raised-button class="background-success"
                            type="button"
                            (click)="release()">
                        Release
                    </button>

                </ng-container>

            </div>

        </form>

    </mat-card-content>
</mat-card>
