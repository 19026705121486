import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {IPagination} from "../../../../../../common/components/pagination/pagination.component";
import {ModalService} from "../../../../../services/modal.service";
import {BoxItemsListComponent} from "../../procedures";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {UserService} from "../../../../../../common/services/user.service";

@Component({
    selector: "section-warehouse-location-view",
    templateUrl: "view.component.html",
    styleUrls: [
        "view.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class WarehouseLocationViewComponent implements Base.IComponent, OnInit {

    public readonly state: Base.IState;

    public boxList: IPagination<any>;

    public location: {
        name: string,
        id: number,
        image: string,
        type_id: number,
        warehouse_transactions_count: number
    };

    /* Table options */

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private changeDetectorRef: ChangeDetectorRef,
        private modalService: ModalService,
        private spinnerService: SpinnerService,
        private userService: UserService
    ) {
    }

    /**
     * Find location
     */
    private async findLocation(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["warehouse_location", "find"], {ref: this.state.params.location});

        if (data) {
            this.location = {
                name: data.location,
                id: data.id,
                image: data.warehouse_location_type.icon,
                type_id: data.warehouse_location_type_id,
                warehouse_transactions_count: data.warehouse_transactions_count
            };
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get box list for specified location
     *
     * @param page
     * @param per_page
     */
    public async getBoxesInLocation(page: number = 1, per_page: number = null): Promise<any> {
        this.spinnerService.show();
        if (per_page === null) {
            per_page = this.userService.data.settings.default_per_page;
        }
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["box", "warehouse_location", "" + this.state.params.id], {}, {
                data_structure: "paginated",
                page,
                per_page
            });
        if (data) {
            this.boxList = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }


    /**
     * Show items in the box
     * @param box
     */
    public async openBox(box: any): Promise<any> {
        await this.modalService.open(BoxItemsListComponent,
            {
                box,
                modalWidth: 1000,
                itemsType: "warehouse_transaction"
            });
    }

    public ngOnInit(): void {
        this.getBoxesInLocation();
        this.findLocation();
    }


    public ngConfig(): Base.IConfig {
        return {
            name: "warehouse-locations",
            actions: {
                "view": ["browse_warehouse_locations"]
            }
        };
    }
}
