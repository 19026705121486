import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, ElementRef,
    OnDestroy,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {Base} from "../../../../../../../../common/interfaces/base.interfaces";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../../common/interfaces/wizard.interface";
import {Api, ApiService} from "../../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../../common/services/toast.service";
import {HelpersService} from "../../../../../../../../common/services/helpers.service";
import {Warehouse} from "../../../../../../../../common/interfaces/warehouse.interface";
import {Modal, ModalService} from "../../../../../../../services/modal.service";
import {WarehouseLocationFormComponent} from "../../../../locations";
import {ReplaySubject} from "rxjs";
import {debounceTime, take, takeUntil} from "rxjs/operators";
import {SpinnerService} from "../../../../../../../../common/services/spinner.service";
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from "@angular/material/autocomplete";

@Component({
    selector: "section-warehouse-procedures-wizard-transfer-location-to-location",
    templateUrl: "location-to-location.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class WarehouseProceduresWizardTransferLocationToLocationComponent extends AbstractWizardStepComponent
    implements OnDestroy {

    @ViewChild("triggerFrom", {static: false})
    public autocompleteTriggerFrom: MatAutocompleteTrigger;

    @ViewChild("triggerTo", {static: false})
    public autocompleteTriggerTo: MatAutocompleteTrigger;

    private state: Base.IState;

    private needInventory: boolean = false;

    public selected_inventory_hub_id: number = null;


    public warehouse: Warehouse.IWarehouse;


    public old_locations: ReplaySubject<any> = new ReplaySubject(1);
    public old_location: FormControl = new FormControl(null);
    public new_location: FormControl = new FormControl(null);

    public new_locations: ReplaySubject<any> = new ReplaySubject(1);
    public new_location_search_input: FormControl = new FormControl(null);

    public inventories: Warehouse.IInventory[] = [];
    public inventories_filtered: ReplaySubject<any> = new ReplaySubject(1);
    public inventories_search_input: FormControl = new FormControl(null);

    public transferForm: FormGroup = new FormGroup({
        old_location_id: new FormControl(null, [Validators.required]),
        new_location_id: new FormControl(null, [Validators.required]),
    });

    public max_qty: number = 0;

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        public helperService: HelpersService,
        private apiService: ApiService,
        private toastService: ToastService,
        private router: Router,
        private modalService: ModalService,
        private spinnerService: SpinnerService
    ) {
        super(changeDetectorRef);
    }


    /**
     * Get all related locations
     */
    private async getOldLocations(search_by: string = null): Promise<any> {
        this.spinnerService.show();

        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["warehouse_location"], {}, {
                search_by,
                search_in: ["location"],
                data_structure: "paginated",
                per_page: search_by && search_by.length > 3 ? 200 : 50,
                page: 1,
                has_stock: true
            });
        if (data && data.data) {
            this.old_locations.next(data.data.filter((location: { inventories_count: number }): boolean =>
                Number(location.inventories_count) > 0));
            if (data.data.length === 1) {
                this.old_locations.pipe(take(1)).subscribe((item) => {
                    if (item[0]) {
                        this.transferForm.get("old_location_id").setValue(item[0].id);
                        this.old_location.setValue(item[0]);
                        this.autocompleteTriggerFrom.closePanel();
                    }
                });
            }
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private async getNewLocations(search_by: string = null): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["warehouse_location"], {}, {
                search_by,
                search_in: ["location"],
                data_structure: "paginated",
                per_page: search_by && search_by.length > 3 ? 200 : 50,
                page: 1
            });
        if (data && data.data) {
            this.new_locations.next(data.data);
            if (data.data.length === 1) {
                this.new_locations.pipe(take(1)).subscribe((item) => {
                    if (item[0]) {
                        this.transferForm.get("new_location_id").setValue(item[0].id);
                        this.new_location.setValue(item[0]);
                        this.autocompleteTriggerTo.closePanel();
                    }
                });
            }
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get inventories in location
     * @param location_id
     */
    private async getInventoriesInLocation(location_id: number): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["warehouse_location", "" + location_id, "inventories"]);
        if (data) {
            this.inventories = data;
            this.inventories_filtered.next(this.inventories);

            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }


    /**
     * Get max quantity of selected inventory and validate it
     * @param inventory_id
     */
    private getMaxQty(inventory_id: number): void {
        if (this.transferForm.get("inventory_ids")?.value?.length > 1) {
            this.transferForm.controls["quantity"].disable();
            this.transferForm.controls["quantity"].setValue(1);
        } else {
            this.transferForm.get("quantity").clearValidators();
            for (const inventory of this.inventories) {
                if (inventory.id === inventory_id) {
                    this.max_qty = inventory.quantity;
                    this.transferForm.get("quantity").setValidators(
                        [Validators.required, Validators.max(this.max_qty)]);
                    this.transferForm.get("quantity").updateValueAndValidity();
                    this.changeDetectorRef.markForCheck();
                    break;
                }
            }
        }
    }


    /**
     * Submit form
     */
    public async submit(): Promise<any> {
        this.transferForm.get("new_location_id").setValue(this.new_location.value.id);
        let formValues = this.transferForm.value;
        if (this.transferForm.get("inventory_ids")?.value?.length > 1) {
            formValues["quantity"] = 1;
        }
        this.spinnerService.show();
        const {type, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["inventory", "move", "location-to-location"], formValues);
        this.spinnerService.hide();
        if (type as string === "success") {
            this.toastService.show(message, "success");
            this.changeDetectorRef.markForCheck();
            this.getOldLocations();
            if (this.needInventory) {
                this.getInventoriesInLocation(this.transferForm.value.old_location_id);
            }
        }
        this.transferForm.reset();

        this.old_location.reset();
        this.new_location.reset();

        this.changeDetectorRef.markForCheck();
    }

    /**
     * Add new location and select it
     */
    public async addLocation(value: string): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(WarehouseLocationFormComponent, {
            location_name: value
        });
        if (response && response.value) {
            this.new_location.setValue(response.value);
            this.transferForm.get("new_location_id").setValue(response.value.id);
            await this.getNewLocations(value);
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Initialize step
     * @param data
     * @returns {Promise<any>}
     */
    public async init(data: Wizard.IData): Promise<any> {
        this.state = data.state;
        this.warehouse = data.warehouse;
        this.needInventory = data.scanType === "inventory-location";


        this.result.emit({
            action: "result",
            value: true
        });

        this.getOldLocations();
        this.getNewLocations();

        this.new_location.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe(value => {
                if (value && typeof (value) === "string") {
                    this.getNewLocations(value);
                } else {
                    if (value) {
                        this.transferForm.get("new_location_id").setValue(value.id);
                    }
                }
            });

        this.old_location.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value: Warehouse.ILocation): void => {
                if (value && typeof (value) === "string") {
                    this.getOldLocations(value);
                } else {
                    if (value) {
                        this.selected_inventory_hub_id = value.inventory && value.inventory.inventory_conversion_id
                            ? value.inventory.inventory_conversion_id : null;

                        this.transferForm.get("old_location_id").setValue(value.id);

                        if (this.needInventory) {
                            this.getInventoriesInLocation(value.id);
                        }

                        this.getNewLocations();
                    }
                }
            });

        if (this.needInventory) {

            this.transferForm.addControl("inventory_ids", new FormControl(null, [Validators.required]));
            this.transferForm.addControl("quantity", new FormControl(null, [Validators.required]));
            this.transferForm.updateValueAndValidity();
            this.changeDetectorRef.markForCheck();

            this.inventories_search_input.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
                .subscribe((value: string): void => {
                    this.inventories_filtered
                        .next(this.inventories.filter((inventory: Warehouse.IInventory): boolean => {
                            return inventory.item.toLowerCase().includes(value.toLowerCase());
                        }));
                });

            this.transferForm.get("inventory_ids").valueChanges.pipe(takeUntil(this.destroy$))
                .subscribe((value: any): void => {
                    if (value?.length > 1) {
                        this.transferForm.controls["quantity"].disable();
                        this.transferForm.controls["quantity"].setValue(1);
                    } else {
                        this.transferForm.controls["quantity"].enable();
                        this.getMaxQty(value[0]);
                    }
                });

        }
    }

    displayFn(option: any): string {
        let displayOption: string;
        if (option && option.location) {
            displayOption = option.location;
        } else {
            displayOption = "";
        }
        return displayOption;
    }

    /**
     * Redirect to order on dropdown selected option
     * @param {MatAutocompleteSelectedEvent} $event
     */
    public onOptionFromSelected($event: MatAutocompleteSelectedEvent): void {
        if ($event.option.value) {
            this.transferForm.get("old_location_id").setValue($event.option.value.id);
        }
    }

    public onOptionToSelected($event: MatAutocompleteSelectedEvent): void {
        if ($event.option.value) {
            this.transferForm.get("new_location_id").setValue($event.option.value.id);
        }
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

}
