import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef, EventEmitter } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { takeUntil } from "rxjs/operators";
import { Base } from "src/modules/common/interfaces/base.interfaces";
import { Form } from "src/modules/common/interfaces/form.interface";
import { Warehouse } from "src/modules/common/interfaces/warehouse.interface";
import { Api, ApiService } from "src/modules/common/services/api.service";
import { Api3Service } from "src/modules/common/services/api3.service";
import { PartnerService } from "src/modules/common/services/partner.service";
import { SpinnerService } from "src/modules/common/services/spinner.service";
import { Modal } from "src/modules/section/services/modal.service";

@Component({
    selector: "partner-packages-pallet-edit",
    templateUrl: "form.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PackagePalletEditComponent implements OnInit {
    public state: Base.IState;
    public modal: Modal.IModal;

    public hubs: any[] = [];
    public hubsFiltered: Form.ISelectOption[] = [];
    public hubsSearch: FormControl = new FormControl(null);

    public locations: any[] = [];
    public locationsFiltered: Form.ISelectOption[] = [];
    public locationsSearch: FormControl = new FormControl(null);

    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    public constructor(
        private apiV3Service: Api3Service,
        private apiService: ApiService,
        private changeDetectorRef: ChangeDetectorRef,
        private spinnerService: SpinnerService) {
            
        }

    public formGroup: FormGroup = new FormGroup({
        ref: new FormControl(null, [Validators.required]),
        hub_id: new FormControl(null, [Validators.required]),
        warehouse_location_id: new FormControl(null, [Validators.required]),
    });

    private async getHubs(): Promise<any> {
        this.spinnerService.show();

        const {data}: Api.IResponse = await this.apiV3Service.request(Api.EMethod.Get,
            `partner/${PartnerService.partner.slug}/hubs`, {}, {
                group_by: "customers_inventory_name",
                relations: [
                    "Warehouse:slug,type,address_id"
                ]
            });
        if (data) {
            const filtered_data: any = data.filter((hub: Warehouse.IHub) => {
                return hub.is_active;
            });

            this.hubs = filtered_data;
            this.hubsFiltered = this.hubs;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private async getLocations(hubId: number): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["hub", "" + hubId, "locations"], {}, {
                data_structure: "select"
            });
        if (data) {
            this.locations = data;
            this.locationsFiltered = this.locations;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    public async submit(): Promise<void> {
        await this.apiV3Service.request(Api.EMethod.Post,
            `partner/${PartnerService.partner.slug}/pallets`,
            this.formGroup.value
        );
        this.modal.response.next({name: "list"});
    }

    public close(): void {
        this.modal.response.next(null);
    }

    public ngOnInit(): void {
        this.getHubs();

        this.formGroup.controls["hub_id"].valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
            this.getLocations(value);
        });

        this.hubsSearch.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value: string): void => {
                if (value) {
                    this.hubsFiltered = this.hubs.filter((hub: { customers_inventory_name: string }): boolean =>
                        hub.customers_inventory_name.toLowerCase().indexOf(value.toLowerCase()) > -1);
                } else {
                    this.hubsFiltered = this.hubs;
                }
                this.changeDetectorRef.markForCheck();
            });
        
        this.locationsSearch.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value: string): void => {
                if (value) {
                    this.locationsFiltered = this.locations.filter((location: { name: string }): boolean =>
                        location.name.toLowerCase().indexOf(value.toLowerCase()) > -1);
                } else {
                    this.locationsFiltered = this.locations;
                }
                this.changeDetectorRef.markForCheck();
            });
    }
}