import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {SpinnerService} from "../../../../../../common/services/spinner.service";


@Component({
    selector: "section-oauth-token",
    template: `
        <mat-card>
            <mat-card-header>
                <mat-card-title>Token</mat-card-title>
                <mat-card-subtitle>Here is your new personal access token.
                    This is the only time it will be shown so don't lose it!
                    You may now use this token to make API requests.
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <code style="line-break: anywhere">{{ token }}</code>
            </mat-card-content>
        </mat-card>
    `,
    styleUrls: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class OAuthTokenComponent implements OnDestroy, OnInit {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);
    /**
     * Form group / group with controls
     * @type {FormGroup}
     */
    public formGroup: FormGroup;

    public readonly state: Base.IState;

    public modal: Modal.IModal;

    public token: string;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private toastService: ToastService,
        private modalService: ModalService,
        private router: Router,
        private http: HttpClient,
        private spinnerService: SpinnerService
    ) {
    }

    /**
     * Close modal
     */
    public close(): void {
        this.modal.response.emit();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngOnInit(): void {
        this.token = this.modal.params.token;
    }
}
