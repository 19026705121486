<h1 class="container-heading">{{action | ucfirst}} configuration</h1>

<mat-card>
    <mat-card-content>

        <form *ngIf="formGroup" [formGroup]="formGroup" (submit)="handleFormSubmit()" autocomplete="off" novalidate>

            <div class="group">

                <mat-form-field class="full">
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="name" required>
                </mat-form-field>
                <mat-form-field class="full">
                    <mat-label>Description</mat-label>
                    <input matInput formControlName="description" required>
                </mat-form-field>

            </div>
            <div class="actions">
                <button mat-raised-button color="primary" type="submit" class="main">
                    {{action | ucfirst}}
                </button>
                <button mat-button type="button" (click)="showList()">Cancel</button>
            </div>

        </form>

    </mat-card-content>
</mat-card>