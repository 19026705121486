import {Injectable} from "@angular/core";
import {Validator, ValidatorResult} from "jsonschema";
import {Api, ApiService} from "./api.service";
import {StorageService} from "./storage.service";
import {Base} from "../interfaces/base.interfaces";
import {User} from "../interfaces/user.interface";
import {PartnerService} from "./partner.service";
import {ThpeeplService} from "./thpeepl.service";
import {Warehouse} from "../interfaces/warehouse.interface";
import {WarehouseService} from "./warehouse.service";
import {Modal, ModalService} from "../../section/services/modal.service";
import {FileUploadComponent} from "../components/file-upload/file-upload.component";


@Injectable()
export class UserService {

    /**
     * Current user
     * @returns {User.IData}
     */
    public get data(): User.IData {
        return this.storageService.get("user") || {};
    }

    public constructor(
        private apiService: ApiService,
        private modalService: ModalService,
        private storageService: StorageService,
    ) {
    }

    /**
     * Save user data to storage & return availability status
     * @param {User.IData[]} data
     * @returns {void}
     */
    private set(data: User.IData[] = []): void {
        if (data) {
            const {valid}: ValidatorResult = new Validator().validate(data[0], User.UserSchema);
            if (valid) {
                this.storageService.set("user", data[0]);
            }
        }
    }

    /**
     * Get user data & navigate to selected area
     * @returns {Promise<any>}
     */
    public async getUser(): Promise<any> {
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, "user");
        this.set(data);
    }

    /**
     * Validate user permissions (is exist in list)
     * @param {string | string[]} permissions
     * @returns {boolean}
     */
    public validatePermissions(permissions?: string | string[]): boolean {
        if (!permissions) {
            return false;
        }
        if (Array.isArray(permissions) && permissions.length) {
            return permissions
                .map((item: string): any => this.data.permissions.includes(item))
                .some((item: boolean): boolean => item === true);
        }
        if (!this.data.permissions) {
            return false;
        }
        return this.data.permissions.includes(permissions as string);
    }

    public getProperty(name: string, state: Base.IState, defaultValue: any = null): any {
        if (!state) {
            return defaultValue;
        }
        const [type, slug]: string[] = state.section.split("/");

        if (type === "partner") {
            const partner: User.IPartner = this.data.partners
                .find((item: User.IPartner): boolean => {
                    return item.slug === slug;
                });
            return partner && partner.properties[name] ? partner.properties[name] : defaultValue;
        } else if (type === "threepl") {
            const threepl: User.IThreepl = this.data.threepls
                .find((item: User.IThreepl): boolean => {
                    return item.slug === slug;
                });
            return threepl && threepl.properties[name] ? threepl.properties[name] : defaultValue;
        } else {
            return defaultValue;
        }
    }


    public setPartner(partner: User.IPartner): void {
        PartnerService.partner = partner;
        ThpeeplService.threepl = null;
        WarehouseService.warehouse = null;
    }

    public setThreepl(threepl: User.IThreepl): void {
        PartnerService.partner = null;
        ThpeeplService.threepl = threepl;
        WarehouseService.warehouse = null;
    }

    public setWarehouse(warehouse: Warehouse.IWarehouse): void {
        PartnerService.partner = null;
        ThpeeplService.threepl = null;
        WarehouseService.warehouse = warehouse;
    }

    public clearSection(): void {
        PartnerService.partner = null;
        ThpeeplService.threepl = null;
        WarehouseService.warehouse = null;
    }

    public updatePassword(body: any): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Put, ["user", "update_password"], body);
    }

    public async uploadFile(file: File = null): Promise<{ name: string, url: string }> {
        const response: Modal.IResponse = await this.modalService.open(FileUploadComponent, {
            url: ["user", "file"],
            file
        });

        if (response && response.value && response.value.data && response.value.data.path) {
            return {name: response.value.data.name, url: response.value.data.path};
        } else {
            return null;
        }
    }
}
