<h1 class="container-heading">New inventory count</h1>
<mat-card>
    <mat-card-content>
        <form [formGroup]="formGroup" (submit)="submit()" lang="en-US">
            <div class="group flex">
                <mat-form-field class="full">
                    <mat-label>Ref</mat-label>
                    <input matInput formControlName="ref" required>
                </mat-form-field>

                <div class="full">
                    <common-form-select [options]="partners"
                                        [search]="true"
                                        label="Partner"
                                        [required]="true"
                                        (valueChange)="partner.setValue($event)">
                    </common-form-select>
                </div>

                <div class="full" *ngIf="partmasters.length">

                    <common-form-select [options]="partmasters"
                                        [search]="true"
                                        [multiple]="true"
                                        label="Items"
                                        (valueChange)="formGroup.get('part_master_id').setValue($event)">
                    </common-form-select>
                </div>
            </div>

            <div class="actions">
                <button type="submit" mat-raised-button color="primary"
                        [disabled]="!formGroup.valid">
                    Submit
                </button>
                <button type="button" mat-raised-button (click)="modal.response.emit(null)">Cancel</button>
            </div>
        </form>
    </mat-card-content>
</mat-card>