import {Injectable} from "@angular/core";
import {Warehouse} from "../interfaces/warehouse.interface";
import {Api, ApiService} from "./api.service";
import {StorageService} from "./storage.service";


@Injectable()
export class WarehouseService {

    private static _warehouse: Warehouse.IWarehouse;

    public constructor(private apiService: ApiService) {
    }

    public static get warehouse(): Warehouse.IWarehouse {
        return this._warehouse;
    }

    public static set warehouse(value: Warehouse.IWarehouse) {
        this._warehouse = value;
    }

    public async getWarehouseBySlug(slug: string): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Get, ["warehouse", "findBySlug", slug]);
    }

    /**
     * Get users that can access warehouse
     * @param query
     */
    public getUsers(query: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Get, ["warehouse", "users"], {}, query);
    }

    /**
     * Get list of associated partners
     * @param query
     */
    public getPartners(query: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Get, ["warehouse", "partners"], {}, query);
    }

    /**
     * Get list of loactions for OrderItem
     * @param part_master_id
     * @param query
     */
    public getLocationsForItem(part_master_id: number, query: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Get, ["warehouse_location", "by_part_master"], {}, {
            part_master_id,
            ...query
        });
    }


}
