import {Injectable} from "@angular/core";
import {Api3Service} from "../../../../common/services/api3.service";
import {Api} from "../../../../common/services/api.service";
import {PartnerService} from "../../../../common/services/partner.service";


@Injectable()
export class PartnerOrderService {

    public constructor(private apiService: Api3Service) {
    }

    public toggleAutoConfirmation(id: number): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Put,
            "partner/" + PartnerService.partner.slug + "/orders/" + id + "/toggle-auto-confirmation");
    }

}
