import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
    selector: "section-user-remark",
    templateUrl: "remark.component.html",
    styleUrls: [
        "remark.component.scss",
    ],
    animations: [
        trigger("slideInOut", [
            state("full", style({
                height: "*",
            })),
            state("cut", style({
                height: "100px",
            })),
            transition("cut => full", animate("400ms ease-in-out"))
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class UserRemarkComponent implements AfterViewInit {

    private textHeight: number = 0;

    @Input()
    public remark: any;

    @ViewChild("text", {static: false})
    public textEl: ElementRef;


    public textCut: boolean = false;

    public textAnimationState: string = "cut";

    public constructor(private changeDetectorRef: ChangeDetectorRef) {
    }


    /**
     * Expand text animation trigger
     */
    public expandCut(): void {
        this.textAnimationState = "full";
        this.textCut = false;
    }

    public ngAfterViewInit(): void {
        if (this.textEl) {
            this.textHeight = this.textEl.nativeElement.offsetHeight;
            if (this.textHeight > 100) {
                this.textCut = true;
            } else {
                this.textAnimationState = "full";
            }
            this.changeDetectorRef.markForCheck();
        }
    }

}
