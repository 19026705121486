<common-confirm></common-confirm>
<h1 class="container-heading">Adjust transactions without order</h1>

<mat-card>
    <mat-card-content>
        <div>
            <h2>Ref: {{ state.params.ref }}</h2>
            <form [formGroup]="searchForm" style="margin: 0 0 20px; width: 400px;">
                <div class="margin-bottom-10">
                    <common-form-switch [value]="false" width="250px"
                                        [labelOff]="'Search by order'"
                                        [labelOn]="'Search by item'"
                                        [trueFalse]="false"
                                        (valueChange)="searchByItem = $event; orders = [];
                                        orderItems = []; item = null; orderRef = null">
                    </common-form-switch>
                </div>

                <div *ngIf="!searchByItem" class="group flex">
                    <mat-form-field class="half">
                        <mat-label>Order search</mat-label>
                        <input #trigger="matAutocompleteTrigger"
                               type="text"
                               matInput
                               formControlName="order"
                               [matAutocomplete]="auto"
                        >
                    </mat-form-field>
                </div>

                <div *ngIf="searchByItem" class="group flex">
                    <mat-form-field class="half">
                        <mat-label>Item search</mat-label>
                        <input matInput
                               #trigger="matAutocompleteTrigger"
                               type="text"
                               formControlName="item"

                               [matAutocomplete]="auto">
                    </mat-form-field>

                    <common-form-select
                            class="half"
                            label="Status"
                            url="status?type=items"
                            [required]="true"
                            (valueChange)="searchForm.get('status').setValue($event)">
                    </common-form-select>
                </div>


            </form>

            <div *ngIf="orderRef">
                <h3>Selected order: {{ orderRef }}</h3>
            </div>


            <div class="margin-bottom-20" *ngIf="orderItems.length">
                <h3>Select order item:</h3>
                <mat-radio-group [formControl]="orderItemSelected">
                    <div *ngFor="let item of orderItems" class="radio-with-button">
                        <mat-radio-button [value]="item.id"></mat-radio-button>
                        <span>
                            <b>{{ item.item }}</b>
                            <span *ngIf="item.serial">| <b>Serial:</b> {{ item.serial }}</span>
                            | <b>Type:</b> {{ item.type }}
                            | <b>Qty.:</b> {{ item.quantity }}
                            <span *ngIf="item.status">| <b>Status:</b> {{ item.status.name }}</span>
                            <span *ngIf="item.inventory_conversion">
                                | <b>Hub:</b> {{ item.inventory_conversion.customers_inventory_name }}
                                ({{ item.inventory_conversion.customers_sub_inventory }})
                            </span>
                        </span>
                        &nbsp;
                        &nbsp;
                        <button type="button" [disabled]="orderItemSelected.value !== item.id"
                                *ngIf="state.params.ref"
                                mat-raised-button
                                color="accent"
                                (click)="linkAllTransactions()">
                            Link all
                        </button>
                    </div>
                </mat-radio-group>
            </div>
        </div>

        <div class="clear text-right">
            <mat-form-field>
                <mat-label>Search transactions</mat-label>
                <input matInput type="search"

                       [formControl]="transactionSearch">
            </mat-form-field>
        </div>
        <div class="columns" *ngIf="transactions">
            <ng-template ngFor let-transaction [ngForOf]="transactions.data">
                <mat-card class="margin-bottom-10 mat-elevation-z3">
                    <div>
                        <div>Item: <b>{{ transaction.item }}</b></div>
                        <div>Serial: <b>{{ transaction.serial }}</b></div>
                        <div>Type: <b>{{ transaction.type }}</b></div>
                        <div *ngIf="transaction.inventory_conversion">
                            Hub: <b>{{ transaction.inventory_conversion.customers_inventory_name }}
                            ({{ transaction.inventory_conversion.customers_sub_inventory }})</b>
                        </div>
                        <div>Quantity: <b>{{ transaction.quantity }}</b></div>
                        <div>Location: <b>{{ transaction.warehouse_location?.location }}</b></div>

                        <div class="text-right margin-top-10">
                            <button type="button"
                                    [disabled]="!orderItemSelected.valid"
                                    mat-raised-button
                                    color="accent"
                                    (click)="linkTransaction(transaction.id)">
                                Link
                            </button>
                            &nbsp;
                            <button type="button"
                                    [disabled]="transaction.quantity < 2"
                                    mat-raised-button
                                    color="primary"
                                    (click)="splitTransaction(transaction)">
                                Split
                            </button>
                            &nbsp;
                            <button type="button"
                                    mat-raised-button
                                    color="primary"
                                    (click)="editTransaction(transaction)">
                                Edit
                            </button>
                            &nbsp;
                            <button *ngIf="!transaction.inventory_id"
                                    mat-raised-button color="warn" type="button" (click)="delete(transaction)">
                                Delete
                            </button>
                        </div>
                    </div>
                </mat-card>
            </ng-template>

        </div>

        <common-pagination *ngIf="transactions" [data]="transactions"
                           pid="b1P64uLlY"
                           (goToPage)="getTransactions($event.page, $event.per_page, transactionSearch.value)"></common-pagination>
    </mat-card-content>
</mat-card>

<mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)" #searchAutoComplete>
    <mat-option *ngFor="let option of orders" [value]="option.ref">
        {{ option.ref }}
    </mat-option>
</mat-autocomplete>
