export const environment: any = {
    production: true,
    server: "prod",
    apiUrl: "https://logivice.net",
    prodServer: ["app.logivice.net", "my.logivice.net"],
    broadcast: "soketi",
    pusher: {
        prod: {
            key: "cabaa296953dba1af6a4",
            forceTLS: true,
        }
    },
    soketi: {
        prod: {
            key: "8b1e2a23-2040-4818-8153-b1ed72dd561c",
            wsHost: "production-dab38aa9-c454-471f-901c-94b1aecc9e39.unilog.workers.dev",
            wsPort: 443,
            wssPort: 443,
            forceTLS: true,
            encrypted: true,
            disableStats: true,
            enabledTransports: ["ws", "wss"],
        }
    },
    bugRequestParams: {
        assignee_id: 2,
        request_kind_id: 1
    },
    cloudinary: {
        cloudName: "dlmxcck1e",
        partner_logo_upload_preset: "logo_path",
        partner_icon_upload_preset: "icon_path",
        avatar_upload_preset: "avatar",
        image_upload_preset: "bojpbwqn"
    },
    livechatLicenseId: 12278583,
    gmap: {
        apiKey: "AIzaSyCOfeWH5KSdZeB8w35_EuY7zsf1E2FK_x0",
    },
    hash: {
        salt: "my-hubabubachka",
        length: 7
    },
    amplitude: {
        key: "72cedefd1d97af19a43f64e6cbd2087c"
    },
    oauth:{
        // client_id: 8,
        // client_secret: "25zJDgTE9zgSGMDDfp1sKdNCB3d9OOJrTqVE6rRK",

        client_id: 55,
        client_secret: "7HSziWhW1McI32H8xAdzyBakjmNgaBHRRWhT7g5Q",
    }
};
