<common-confirm></common-confirm>
<h1 class="container-heading">{{action | ucfirst}} courier transaction</h1>

<mat-card>
    <mat-card-content>
        <div>
            <div style="color: #4caf50" *ngIf="modal.params.orderData?.courier_service?.service_name">
                <b>Courier service: </b>
                {{modal.params.orderData.courier_service.description || modal.params.orderData.courier_service.service_name}}
            </div>

            <div *ngIf="!modal.params.orderData?.courier_service?.service_name
                && modal.params.orderData?.service_level?.courier_service">
                <b>Courier service: </b>
                {{modal.params.orderData.service_level.courier_service.service_name}}
                - {{modal.params.orderData.service_level.courier_service.description}}
            </div>
        </div>

        <form *ngIf="formGroup" [formGroup]="formGroup" (submit)="handleFormSubmit()" autocomplete="off" novalidate>

            <div class="group">

                <mat-form-field class="full">
                    <mat-label>Tracking number</mat-label>
                    <input matInput formControlName="tracking_number" required>
                </mat-form-field>

                <mat-form-field class="full">
                    <mat-label>Courier</mat-label>
                    <mat-select formControlName="courier_slug" required>
                        <mat-option>
                            <ngx-mat-select-search [formControl]="couriersSearch" [placeholderLabel]="'Search'"
                                                   [clearSearchInput]="true" [noEntriesFoundLabel]="'No items found'">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let option of couriersFiltered | async" [value]="option.value">
                            {{ option.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="full">
                    <mat-label>Courier service</mat-label>
                    <mat-select formControlName="courier_service_slug" required>
                        <mat-option>
                            <ngx-mat-select-search [formControl]="courierServicesSearch" [placeholderLabel]="'Search'"
                                                   [clearSearchInput]="true" [noEntriesFoundLabel]="'No items found'">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let option of courierServicesFiltered | async" [value]="option.value">
                            {{ option.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="full">
                    <common-form-date
                            label="ETA"
                            [value]="formGroup.value.date"
                            (valueChangeMoment)="formGroup.get('date').setValue($event)"
                    ></common-form-date>
                </div>

                <div class="full">
                    <common-form-date
                            label="Creation date"
                            [value]="formGroup.value.created"
                            (valueChangeMoment)="formGroup.get('created').setValue($event)"
                    ></common-form-date>
                </div>

                <div class="full" *ngIf="show_link_warehouse_transactions">
                    <mat-checkbox formControlName="link_unlinked_warehouse_transactions">
                        Link unlinked warehouse transactions
                    </mat-checkbox>
                </div>

            </div>
            <div class="actions">
                <button [disabled]="!formGroup.valid" mat-raised-button color="primary" type="submit" class="main">
                    {{action | ucfirst}}
                </button>

                <button mat-raised-button color="accent" type="button" (click)="createLabel()">
                    Create label
                </button>

                <button *ngIf="action === 'edit'" mat-raised-button color="warn" type="button" (click)="delete()">
                    Delete
                </button>
            </div>

        </form>

    </mat-card-content>
</mat-card>
