import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Router} from "@angular/router";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {NotificationService} from "../../../../../../common/services/notification.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {IntegrationsFunctionsFormComponent} from "./form/form.component";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {FormControl} from "@angular/forms";
import {ModalComponent} from "../../../../../../common/components/modal/modal.component";


@Component({
    selector: "section-integrations-view",
    templateUrl: "view.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class IntegrationsViewComponent implements Base.IComponent, OnInit {

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    public readonly state: Base.IState;

    public item: any;

    public connector: boolean = false;

    public showScheduler: FormControl = new FormControl(false);

    public constructor(
        private apiService: ApiService,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private toastService: ToastService,
        private notificationService: NotificationService,
        private modalService: ModalService,
        private spinnerService: SpinnerService
    ) {
    }

    private async getIntegrationsList(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["integration", this.state.params.id]);

        if (data) {
            this.item = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    public async addFunction(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(IntegrationsFunctionsFormComponent, {
            section: this.state.section,
            action: "add",
            integration_id: this.state.params.id
        });
        if (response && response.value) {
            this.getIntegrationsList();
        }
    }

    public async edit(): Promise<any> {
        this.router.navigate([this.state.section, this.state.component, "edit", "id", this.state.params.id]);
    }

    public async delete(): Promise<any> {
        if (await this.confirmRef.confirm("Do you want to delete this integration?")) {
            this.spinnerService.show();
            const response: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
                ["integration", "" + this.state.params.id]);
            if (response) {
                this.toastService.show(response.message, response.type as string);
                if (response.type === "success") {
                    this.router.navigate([this.state.section, this.state.component]);
                }
            }
            this.spinnerService.hide();
        }
    }

    public async edit_func(func: any): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(IntegrationsFunctionsFormComponent, {
            section: this.state.section,
            action: "edit",
            func: func
        });
        if (response && response.value) {
            this.getIntegrationsList();
        }
    }

    public async delete_func(id: any): Promise<any> {
        if (await this.confirmRef.confirm("Do you want to delete this function?")) {
            this.spinnerService.show();
            const response: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
                ["integration", "function", "" + id]);
            if (response) {
                this.toastService.show(response.message, response.type as string);
                this.getIntegrationsList();
            }
            this.spinnerService.hide();
        }
    }

    public async deleteConnector(id: any): Promise<any> {
        this.spinnerService.show();
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
            ["integration", "connector", "" + id]);
        if (response) {
            this.toastService.show(response.message, response.type as string);
        }
        this.spinnerService.hide();
        this.getIntegrationsList();
    }

    public async submit(type: string, func: { async: number, id: number }, value: any): Promise<any> {
        this.spinnerService.show();
        let job_id: string;
        if (func.async) {
            job_id = HelpersService.randomString();
            this.apiService.setHeaders({async: job_id});
        }
        const id: number = type === "function" ? func.id : this.state.params.id;
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["integration", type, "" + id], value);
        if (response) {
            this.toastService.show(response.message, response.type as string);
            if (response.type as string === "success") {
                if (func.async) {
                    this.notificationService.addNotification({
                        job_id,
                        job_type: "JobAdded",
                        message: response.message,
                        message_type: "success",
                        url: null,
                        read: false,
                    });
                }
                this.getIntegrationsList();
            }
        }
        this.spinnerService.hide();
    }

    public toList(): void {
        this.router.navigate([
            this.state.section,
            this.state.component
        ]);
    }

    public showInstructions(): void {
        this.modalService.open(ModalComponent, {
            template: this.item.instructions
        });
    }


    public onSchedulerResult($event: any): void {
        this.getIntegrationsList();
    }

    public ngOnInit(): void {
        this.getIntegrationsList();
    }


    public ngConfig(): Base.IConfig {
        return {
            name: "integrations",
            actions: {
                "view": ["browse_integrations"]
            }
        };
    }

}
