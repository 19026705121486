import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    Output,
    ViewEncapsulation
} from "@angular/core";
import {UntypedFormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {HelpersService} from "../../../../../../../common/services/helpers.service";
import {debounceTime, takeUntil} from "rxjs/operators";
import {ModalService} from "../../../../../../services/modal.service";
import {ReplaySubject} from "rxjs";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {ImageUploadService} from "../../../../../../../common/services/image-upload.service";

@Component({
    selector: "section-partner-form-step1",
    templateUrl: "step1.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class PartnerFormStep1Component implements OnDestroy {

    private slug: string;

    private state: Base.IState;

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    private data: any;

    public assignedSearchInput: FormControl = new FormControl(null);

    public users_filtered: ReplaySubject<any> = new ReplaySubject(1);

    @Output()
    public result: EventEmitter<any> = new EventEmitter();

    /**
     * Form group / group with controls
     * @type {FormGroup}
     */
    public formGroup: FormGroup = new FormGroup({
        full_name: new FormControl(null, [Validators.required]),
        display_name: new FormControl(null, [Validators.required]),
        logo_path: new FormControl(null),
        icon_path: new FormControl(null),
        slack_webhook_url: new FormControl(null),
        style_path: new FormControl("blue"),
        users: new FormControl([]),
        in_charge_manager_id: new FormControl(null)
    });

    /**
     * List of countriest
     * @type {any[]}
     */
    public countries: any[];

    /**
     * All available users
     * @type {any[]}
     */
    public users: { value: number, name: string }[] = [];

    /**
     * Assigned users to partner
     * @type {number[]}
     */
    public selectedUsers: number[] = [];

    public contactDetails: FormControl = new FormControl(false);

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private helpers: HelpersService,
        private modalService: ModalService,
        private imageUploadService: ImageUploadService,
        private spinnerService: SpinnerService
    ) {
    }

    /**
     * Prepare partner data (by id)
     * @returns {Promise<any>}
     */
    private async prepareData(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["partner", this.state.params.id]);
        this.spinnerService.hide();
        if (data) {
            this.data = data;
            if (this.data.address) {
                this.data.address.country = this.data.address.country_iso_2;
                this.contactDetails.setValue(true);
                this.addContactInfo();
            }
            this.slug = data.slug;

            this.result.next(this.data);

            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Prepare form values
     * @returns {void}
     */
    private prepareForm(data: any, formGroup: FormGroup): void {
        formGroup.patchValue(data);
    }

    /**
     * Add controls if contact details checked
     */
    private addContactInfo(): void {
        this.formGroup.addControl("address", new FormGroup({
            country: new FormControl(null, [Validators.required]),
            address: new FormControl(null, [Validators.required]),
            address2: new FormControl(null),
            city: new FormControl(null, [Validators.required]),
            state: new FormControl(null),
            zip: new FormControl(null, [Validators.required])
        }));
        this.formGroup.addControl("contact", new FormGroup({
            first_name: new FormControl(null, [Validators.required]),
            last_name: new FormControl(null, [Validators.required]),
            email: new FormControl(null, [Validators.required, Validators.email]),
            phone: new FormControl(null, [Validators.required]),
            fax: new FormControl(null)
        }));
        this.formGroup.updateValueAndValidity();
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Remove controls if contact details unchecked
     */
    private removeContactInfo(): void {
        this.formGroup.removeControl("address");
        this.formGroup.removeControl("contact");
        this.formGroup.updateValueAndValidity();
        this.changeDetectorRef.markForCheck();
    }


    /**
     * Prepare countries (as control options)
     * @returns {Promise<any>}
     */
    private async prepareCountriesList(): Promise<any> {
        this.spinnerService.show();
        this.countries = await this.helpers.prepareCountriesList();
        this.spinnerService.hide();
    }

    /**
     * Get assigned users
     * @returns {Promise<any>}
     */
    private async getSelectedUsers(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["partner", this.state.params.id, "users"], {},
            {
                data_structure: "select"
            });
        if (data) {
            this.selectedUsers = data.map((value: any): string => value.value);
            this.formGroup.get("users").setValue(this.selectedUsers);
        }
        this.spinnerService.hide();
    }

    /**
     * Get all available users
     * @returns {Promise<any>}
     */
    private async getAllUsers(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["user", "list"], {},
            {
                data_structure: "select"
            });
        if (data) {
            this.users = data;
            this.users_filtered.next(this.users);
        }
        this.spinnerService.hide();
    }

    /**
     * Handle autocomplete selected value event
     * @param $event
     */
    public onCountrySelected($event: any): void {
        this.formGroup.get("address").get("country").setValue($event.value);
    }

    /**
     * Submit form (add, edit)
     * @returns {Promise<any>}
     */
    public async handleFormSubmit(): Promise<any> {
        if (this.formGroup && this.formGroup.valid) {
            let response: Api.IResponse;
            this.spinnerService.show();

            if (this.state.action === "add") {
                response =
                    await this.apiService.request(Api.EMethod.Post, "partner", this.formGroup.value);
                if (response.data) {
                    this.router.navigate([this.state.section, this.state.component, "edit", "id", response.data.id]);
                }
            } else if (this.state.action === "edit") {
                response =
                    await this.apiService.request(Api.EMethod.Put,
                        ["partner", this.state.params.id], this.formGroup.value);
            }
            this.spinnerService.hide();
            this.toastService.show(response.message, response.type as string);
            if (response.data) {
                this.result.next(response.data);
            }
        }
    }

    public async uploadLogoOrIcon(type: "logo" | "icon"): Promise<any> {
        let image_url: string;
        if (type === "logo") {
            image_url = await this.imageUploadService.uploadLogo();
        } else {
            image_url = await this.imageUploadService.uploadIcon();
        }

        if (image_url) {
            this.formGroup.get(type + "_path").setValue(image_url);
            this.formGroup.markAsDirty();
            this.changeDetectorRef.markForCheck();
        }
    }


    /**
     * Initialize details step
     * @param state
     * @returns {Promise<any>}
     */
    public init(state: Base.IState): void {
        this.state = state;
        this.getAllUsers();
        this.prepareCountriesList();
        if (this.state.params.id) {
            this.prepareData().then((): void => {
                this.prepareForm(this.data, this.formGroup);
                this.getSelectedUsers();

                this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any): void => {
                    if (!this.formGroup.valid) {
                        this.result.next(null);
                    }
                });
            });
        }

        this.contactDetails.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: boolean): void => {
            if (value) {
                this.addContactInfo();
                if (this.state.params.id) {
                    this.prepareForm(this.data.address, this.formGroup.get("address") as FormGroup);
                    this.prepareForm(this.data.contact, this.formGroup.get("contact") as FormGroup);
                    this.formGroup.updateValueAndValidity();
                    this.changeDetectorRef.markForCheck();
                }
            } else {
                this.removeContactInfo();
            }
        });
        this.assignedSearchInput.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: string): void => {
                if (value) {
                    this.users_filtered.next(
                        this.users.filter((user: { name: string }): boolean =>
                            user.name.toLowerCase().indexOf(value) > -1)
                    );
                } else {
                    this.users_filtered.next(this.users);
                }
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
