import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Router} from "@angular/router";
import {Api3Service} from "../../../../../../common/services/api3.service";

@Component({
    selector: "section-inspection-edit",
    template: `
        <common-form *ngIf="values"
                     [configUrl]="['inspection', values.inspection_id]"
                     [submitUrl]="['inspection', 'form', ''+state.params.id]"
                     [method]="method"
                     [configSection]="'inspection'"
                     [values]="values"
                     (onSubmit)="submit($event)"></common-form>
    `,
    styles: [
        `
            section-inspection-edit .full button,
            section-inspection-edit .half button,
            section-inspection-edit .third button {
                width: 100%;
            }`
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class InspectionEditComponent implements Base.IComponent, OnInit {

    public readonly state: Base.IState;

    public values: { [key: string]: string };

    public method: Api.EMethod = Api.EMethod.Put;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private spinnerService: SpinnerService,
        private router: Router
    ) {
    }


    /**
     * Get submission data
     * @return {Promise<any>}
     */
    private async getData(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.get(
            `${this.state.section}/inspections/results/${this.state.params.id}`);

        if (data) {
            this.values = data;

            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }


    /**
     * Submit form
     * @return {Promise<any>}
     */
    public async submit(response: Api.IResponse): Promise<any> {
        if (response.code === 200) {
            this.router.navigate([
                this.state.section,
                "inspection-results",
                "view",
                "id",
                this.state.params.id
            ]);
        }
    }


    public ngOnInit(): void {
        this.getData();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "inspection-results",
            actions: {
                "edit": ["edit_inspections"]
            }
        };
    }
}
