import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {UserService} from "../../../../../../common/services/user.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {IPagination} from "../../../../../../common/components/pagination/pagination.component";
import {Order} from "../../../../../../common/interfaces/order.interface";
import {RemarksSidebarComponent} from "../../order";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {User} from "../../../../../../common/interfaces/user.interface";
import {FormControl, FormGroup} from "@angular/forms";
import {debounceTime, takeUntil} from "rxjs/operators";
import {Form} from "src/modules/common/interfaces/form.interface";
import ISelectOption = Form.ISelectOption;
import IRemarkClassification = Order.IRemarkClassification;


@Component({
    selector: "section-remarks",
    templateUrl: "list.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ["list.component.scss"],
    encapsulation: ViewEncapsulation.None
})

export class RemarksComponent implements Base.IComponent, OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    public remarks: IPagination<Order.IRemark[]>;

    public remarks_columns: { [key: string]: Order.IRemark[] };

    public readonly state: Base.IState;

    public refresh: number = 300;

    public mentionUsers: User.IMentionUser[] = [];

    public remarkTypes: { id: number, name: string }[];

    @ViewChild("sidebar", {static: true})
    public sidebarRef: RemarksSidebarComponent;

    public filters: FormGroup = new FormGroup({
        search_by: new FormControl(null),
        partner_id: new FormControl([]),
        order_remark_classification_ids: new FormControl([]),
        order_remark_type_ids: new FormControl([])
    });

    public partners: ISelectOption[] = [];

    private showPartnerSelectAll: boolean = false;

    public remarkClassifications: IRemarkClassification[];

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        public userService: UserService,
        private spinnerService: SpinnerService,
        private helperService: HelpersService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    private timer(): void {
        setInterval((): void => {
            if (this.refresh === 0) {
                this.refresh = 300;
                this.getRemarks(1, 15);
            } else {
                this.refresh--;
                this.changeDetectorRef.markForCheck();
            }
        }, 1000);
    }

    /**
     * Get remark types
     * @returns {Promise<any>}
     */
    private async getRemarkTypes(): Promise<any> {
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["remark", "order", "types"]);
        if (data) {
            this.remarkTypes = data;
            this.changeDetectorRef.markForCheck();
        }
    }


    private async getMentionUsers(): Promise<any> {
        this.mentionUsers = await this.helperService.getMentionUsers();
        this.changeDetectorRef.markForCheck();
    }

    private async getPartners(): Promise<any> {
        this.partners = this.userService.data.partners.map((partner: User.IPartner): any => {
            return {value: partner.id, name: partner.display_name};
        });
    }

    /**
     * Get remark types
     * @returns {Promise<any>}
     */
    private async getRemarkClassifications(): Promise<any> {
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["remark", "classification"]);
        if (data) {
            this.remarkClassifications = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    public async getRemarks(page: number = 1, per_page: number = null): Promise<any> {
        this.remarks = null;
        this.changeDetectorRef.markForCheck();

        if (per_page === null) {
            per_page = this.userService.data.settings.default_per_page;
        }

        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["remark", "system"], {}, {
                page,
                per_page,
                data_structure: "paginated",
                search_in: ["Owner.name", "Order.ref"],
                ...this.filters.value,
            });

        if (data) {
            this.remarks = data;

            this.remarks_columns = {a: [], b: [], c: []};

            if (data.data.length) {
                for (let i: number = 0; i < data.data.length; i = i + 3) {
                    if (data.data[i]) {
                        this.remarks_columns.a.push(data.data[i]);
                    }
                    if (data.data[i + 1]) {
                        this.remarks_columns.b.push(data.data[i + 1]);
                    }
                    if (data.data[i + 2]) {
                        this.remarks_columns.c.push(data.data[i + 2]);
                    }
                }
            }
            this.changeDetectorRef.markForCheck();
        }
    }

    public onAction(event: { action: string, value: any }): void {
        switch (event.action) {
            case "show_sidebar":
                this.sidebarRef.show(event.value.order.id, event.value.order.ref, true);
                break;
            default:
                break;

        }
    }

    public selectAll(): void {
        if (!this.showPartnerSelectAll) {
            this.filters.get("partner_id").setValue(this.partners.map(item => item.value));
        } else {
            this.filters.get("partner_id").setValue([]);
        }
        this.showPartnerSelectAll = !this.showPartnerSelectAll;

    }

    public ngOnInit(): void {
        if (this.state.section === "admin") {
            this.getPartners();
        }
        this.getRemarks();
        this.timer();
        this.getMentionUsers();
        this.getRemarkTypes();
        this.getRemarkClassifications();

        this.filters.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(800))
            .subscribe((value: string): void => {
                this.getRemarks(1, 15);
            });
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "remarks",
            actions: {
                "browse": ["browse_order_remarks"]
            }
        };
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
