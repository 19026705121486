import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit} from "@angular/core";
import {Modal} from "../../../../../services/modal.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Api} from "../../../../../../common/services/api.service";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {FormControl} from "@angular/forms";
import {IPagination} from "../../../../../../common/components/pagination/pagination.component";
import {AddressService} from "../../../../../../common/services/address.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {debounceTime, takeUntil} from "rxjs/operators";
import {Order} from "../../../../../../common/interfaces/order.interface";
import {ServiceLevelService} from "../../../../../../common/services/service-level.service";
import {Router} from "@angular/router";
import {UserService} from "../../../../../../common/services/user.service";

@Component({
    selector: "section-address-link-service-levels",
    template: `
        <h1 class="container-heading">
            Linked service-levels
        </h1>
        <mat-card>
            <mat-card-content>
                <div>
                    <button (click)="import()"
                            mat-raised-button type="button" color="accent">Import
                    </button>
                </div>
                <div>
                    <mat-form-field>
                        <mat-label>Search</mat-label>
<input matInput [formControl]="search"  type="search">
                    </mat-form-field>
                </div>
                <ng-container *ngIf="serviceLevels">
                    <div *ngFor="let s of serviceLevels.data; let i = index">
                        <mat-checkbox [value]="s.id" [checked]="check(s)"
                                      [disableRipple]="true"
                                      (change)="toggle($event)">
                            <b>{{ s.name}}</b> | {{ s.description}}
                        </mat-checkbox>
                    </div>
                    <common-pagination [data]="serviceLevels"
                                       pid="rbG45fXF0"
                                       (goToPage)="getSLs($event.page,$event.per_page,search.value)">
                    </common-pagination>
                </ng-container>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkServiceLevelsToAddressComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    public modal: Modal.IModal;

    public serviceLevels: IPagination<Order.IServiceLevel>;

    public search: FormControl = new FormControl(null);

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private spinnerService: SpinnerService,
        private toastService: ToastService,
        private addressService: AddressService,
        private router: Router,
        private serviceLevelService: ServiceLevelService,
        private userService: UserService,
    ) {
    }

    public async getSLs(page: number = 1, per_page: number = null, search_by: string = null): Promise<any> {
        this.spinnerService.show();
        if (per_page === null) {
            per_page = this.userService.data.settings.default_per_page;
        }
        const {data}: Api.IResponse = await this.serviceLevelService.getServiceLevels({
            data_structure: "paginated",
            page,
            per_page,
            search_by,
            search_in: ["name"],
            relations: [
                "Addresses:id"
            ]
        });

        this.spinnerService.hide();

        if (data) {
            this.serviceLevels = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    public check(service_level: Order.IServiceLevel): boolean {
        if (!service_level.addresses) {
            return false;
        }
        return !!service_level.addresses.find((address: any): boolean => {
            return address.id === this.modal.params.address_id;
        });
    }

    public async toggle(event: MatCheckboxChange): Promise<any> {
        const id: number = Number(event.source.value);
        const {code, message}: Api.IResponse =
            await this.addressService.linkServiceLevel(this.modal.params.address_id, id);

        if (code === 200) {
            this.toastService.show(message, "success");
        }
    }

    public import(): void {
        this.modal.close_all.emit(null);

        this.router.navigate([
            this.modal.params.state.section,
            "csv-import",
            "wizard",
            "type",
            "address_service_level"
        ]);
    }

    public ngOnInit(): void {
        this.getSLs();

        this.search.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: string): void => {
                this.getSLs(1, 20, value);
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
