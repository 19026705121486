import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {FormControl} from "@angular/forms";
import {debounceTime, takeUntil} from "rxjs/operators";
import {Form} from "../../../../../../common/interfaces/form.interface";
import {ReplaySubject} from "rxjs";
import {ToastService} from "../../../../../../common/services/toast.service";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import ISelectOption = Form.ISelectOption;


@Component({
    selector: "section-order-reseller-select",
    template: `
        <common-confirm></common-confirm>
        <mat-form-field style="width: 100%">
            <mat-label>Resellers</mat-label>
<input type="text"

                   matInput
                   [formControl]="reseller"
                   [matAutocomplete]="auto">
        </mat-form-field>

        <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of resellersFiltered | async" [value]="option.name">
                {{option.name}}

                <span class="pull-right line-height-inherit">
                    <mat-icon (click)="deleteReseller(option)"
                              matTooltip="Delete"
                              color="warn"
                              class="margin-right-0">close</mat-icon>
                    </span>
            </mat-option>
        </mat-autocomplete>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})

export class OrderResellerSelectComponent implements OnInit, OnChanges {

    protected destroy$: EventEmitter<boolean> = new EventEmitter(false);

    @Input()
    public value: string = null;

    @Output()
    public valueChanges: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild(ConfirmComponent)
    public confirm: ConfirmComponent;


    public resellers: ISelectOption[] = [];

    public resellersFiltered: ReplaySubject<ISelectOption[]> = new ReplaySubject(1);

    public reseller: FormControl = new FormControl(null);

    public constructor(
        private apiService: ApiService,
        private changeDetectorRef: ChangeDetectorRef,
        private toastService: ToastService,
        private spinnerService: SpinnerService
    ) {
    }

    protected async getResellers(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["modix_resellers"], {}, {
                data_structure: "select"
            });
        this.spinnerService.hide();
        if (data) {
            this.resellers = data;
            this.resellersFiltered.next(this.resellers);
        }
    }

    /**
     * Delete modix reseller
     * @param option
     */
    public async deleteReseller(option: ISelectOption): Promise<any> {

        if (!await this.confirm.confirm("Are you sure want to delete reseller? This action cannot be undone!")) {
            return;
        }

        this.spinnerService.show();
        const {message, code}: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
            ["modix_resellers", "" + option.value]
        );
        this.spinnerService.hide();

        if (code === 200) {
            this.toastService.show(message, "success");
            this.reseller.setValue(null);
            this.changeDetectorRef.markForCheck();

            this.resellersFiltered.next([]);
            this.getResellers();
        }
    }


    public ngOnInit(): void {

        this.getResellers();

        this.reseller.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: string): void => {
                const filtered: ISelectOption[] = this.resellers.filter(
                    (option: ISelectOption): boolean => {
                        if (value) {
                            return option.name.toLowerCase().includes(value.toLowerCase());
                        }
                        return true;
                    });
                this.resellersFiltered.next(filtered);

                this.valueChanges.emit(value);
            });

        this.reseller.setValue(this.value);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.value && changes.value.currentValue !== this.value) {
            this.reseller.setValue(changes.value.currentValue);
        }
    }
}
