<mat-card>
    <div>

        <div class="pull-left">
            <div class="margin-bottom-10">
                <button mat-raised-button
                        type="button"
                        color="accent"
                        class="margin-bottom-10"
                        (click)="addTracking()">
                    Add tracking
                </button>

                <div *ngIf="courier_transactions">
                    <div *ngFor="let track of courier_transactions" class="margin-bottom-10">
                        <img [src]="track.courier.icon_path" alt="" width="40" class="vertical-middle">
                        &nbsp;
                        {{track.courier.display_name}} -
                        <span class="color-accent">{{track.tracking_number}}</span>
                        ({{track.status}})
                        <span *ngIf="track.status !== 'delivered'"
                              class="icon-compose vertical-middle pointer"
                              matTooltip="Mark as delivered"
                              (click)="markDelivered(track.id)">
                    <mat-icon class="main">local_shipping</mat-icon>
                    <mat-icon class="additional text-success"
                              style="right:8px;bottom: 7px">check_circle</mat-icon>
                    </span>
                    </div>
                    <mat-divider class="relative"></mat-divider>
                </div>
            </div>
            <div class="margin-bottom-10">
                <button type="button" mat-raised-button color="primary" (click)="findLocationByItem()">
                    <mat-icon>search</mat-icon>
                    Find location by item
                </button>
            </div>
            <div class="margin-bottom-10">
                <button type="button" mat-raised-button color="primary" (click)="locationList()">
                    Available locations
                </button>
            </div>

            <div *ngIf="usedLocations.length">
                <h3>Used locations:</h3>
                <div *ngFor="let usedLocation of usedLocations">
                    <b class="color-accent pointer"
                       (click)="setLocationFromList(usedLocation)">{{usedLocation.location}}</b>
                </div>
            </div>
        </div>

        <h1 *ngIf="location" class="color-accent text-center">Scan boxes</h1>
        <h1 *ngIf="!location" class="color-accent text-center">Select location</h1>

        <div class="search margin-bottom-20">
            <div class="info" *ngIf="location">
                <mat-icon>move_to_inbox</mat-icon>
                <div>
                    Your current location is: <b class="color-accent">{{location.name}}</b>
                    <mat-icon color="warn" class="pointer clear" (click)="clearLocation()"
                              matTooltip="Clear location">
                        clear
                    </mat-icon>
                    <br>
                    Inbound items: <b class="color-accent">{{location.warehouse_transactions_count}}</b>&nbsp;
                    <mat-icon class="pointer" (click)="findLocation()" matTooltip="Refresh location">
                        refresh
                    </mat-icon>
                </div>
            </div>
            <div class="search-field">
                <mat-form-field>
                    <mat-label>{{searchInputLabel}}</mat-label>
                    <input matInput type="search"
                           [formControl]="searchInput"
                           [scannerOnly]="scannerOnly && location"
                           (keydown)="onKeydown($event)" #searchInputRef>
                </mat-form-field>
                <button type="button" mat-raised-button color="accent" (click)="find()">
                    <i class="fa fa-barcode"></i> Search
                </button>
            </div>
            Your scanner default key set as {{ scannerKey }}.
            <span *ngIf="scannerOnly" class="color-warn">
            Manual input disabled
        </span>
        </div>

        <div *ngIf="notFoundRefTemp" class="text-center margin-bottom-20">
            <button
                    type="button"
                    (click)="addLocation(notFoundRefTemp)"
                    color="accent" mat-raised-button>
                Add location
            </button>
        </div>

        <div *ngIf="location">

            <div class="text-right margin-bottom-20">
                <common-form-switch [value]="false"
                                    [trueFalse]="false"
                                    iconOff="list_alt"
                                    iconOn="featured_play_list"
                                    tipOff="Show list"
                                    tipOn="Show boxes"
                                    (valueChange)="viewSwitch.setValue($event)"></common-form-switch>
            </div>

            <ng-container *ngIf="!viewSwitch.value">
                <h2 class="color-accent border-bottom-none">Inventories:</h2>

                <mat-card class="margin-bottom-20">
                    <common-table2 *ngIf="listTable" [settings]="listTable"></common-table2>
                </mat-card>
            </ng-container>

            <ng-container *ngIf="viewSwitch.value && boxList">
                <h2 class="color-accent border-bottom-none">Boxes:</h2>

                <div class="boxes-wrap margin-bottom-20">
                    <div>
                        <button mat-raised-button type="button" color="primary" (click)="addBox()">Add box</button>
                    </div>

                    <common-pagination [data]="boxList"
                                       pid="2jXrzM24"
                                       (goToPage)="getBoxes($event.page,$event.per_page)"></common-pagination>
                    <div class="boxes">
                        <ng-template ngFor let-box [ngForOf]="boxList.data" let-i="index">
                            <div class="box">
                                <div class="ref">
                                    <div>
                                        Ref: {{box.ref}}
                                    </div>
                                </div>

                                <div class="logo" *ngIf="box.partner">
                                    <span></span>
                                    <img [src]="box.partner.icon_path" alt="">
                                </div>

                                <div class="buttons">
                                    <button mat-mini-fab color="accent" (click)="printSticker(box)">
                                        <mat-icon>print</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                    <div [class.shelf]="location.type_id === 1"
                         [class.palet]="location.type_id === 2"
                         [ngStyle]="{'background-image': 'url('+location.image+')'}"></div>
                </div>
            </ng-container>

            <div class="location-summary color-accent text-center" *ngIf="location">
                <h2 class="border-bottom-none">
                    <b>{{location.name}}</b> -
                    <span *ngIf="!location.hub" class="text-success">
                No related sub inventory
            </span>
                    <span *ngIf="location.hub" class="text-error">
               {{location.hub.customers_sub_inventory}}
            </span>
                </h2>

            </div>
        </div>
        <div class="clear"></div>

    </div>
</mat-card>
