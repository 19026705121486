import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    ViewEncapsulation
} from "@angular/core";
import {SpinnerService} from "../../services/spinner.service";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: "common-spinner",
    template: `
        <div *ngIf="visibility" class="spinner-overlay"></div>
        <mat-spinner *ngIf="visibility" [diameter]="50" mode="indeterminate" class="spinner"></mat-spinner>
    `,
    styleUrls: [
        "spinner.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class SpinnerComponent implements OnDestroy {

    /**
     * Spinner timeout (setTimeout return)
     * * @type {any}
     */
    private timeout: any;

    protected destroy$: EventEmitter<boolean> = new EventEmitter(false);

    /**
     * Spinner visibility state
     * @type {boolean}
     */
    public visibility: boolean = false;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private spinnerService: SpinnerService
    ) {

        spinnerService.state.pipe(takeUntil(this.destroy$)).subscribe((state: boolean): void => {
            if (state) {
                this.show();
            } else {
                this.hide();
            }
        });

    }

    /**
     * Show spinner
     */
    public show(): void {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.visibility = true;
        this.timeout = setTimeout((): void => this.changeDetectorRef.markForCheck(), 200);
    }

    /**
     * Hide spinner
     */
    public hide(): void {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.visibility = false;
        setTimeout((): void => this.changeDetectorRef.markForCheck(), 200);
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
