<div class="heading margin-bottom-10 padding-left-10">
    <div class="extra-content flex align-center row margin-bottom-10">
        <ng-content></ng-content>
    </div>
    <div class="flex align-center row space-between no-wrap margin-bottom-10">
        <div class="extra-content flex align-center row space-between">
            <ng-content select="[row1]"></ng-content>
            <div>
                <ng-container *ngIf="settings && settings.export && tableData && tableData.length">
                    <button *ngIf="!use_external_export; else external_export" type="button" mat-raised-button
                            color="accent"
                            [matMenuTriggerFor]="exportMenu"
                            (click)="amplitudeClick('Export')">
                        Export
                    </button>
                    <mat-menu #exportMenu="matMenu">
                        <a mat-menu-item (click)="export('csv')">as .CSV</a>
                        <a mat-menu-item (click)="export('xlsx')">as .XLSX</a>
                    </mat-menu>

                    <ng-template #external_export>
                        <button type="button" mat-raised-button color="primary"
                                (click)="settings.export.external.callback()">
                            Report
                        </button>
                    </ng-template>

                </ng-container>
            </div>
        </div>

        <form *ngIf="search" [formGroup]="searchForm" class="flex row align-center search-form no-wrap">
            <mat-form-field class="margin-right-10">
                <mat-label>Search</mat-label>
                <input matInput type="search" formControlName="search_by">
            </mat-form-field>
            <div class="margin-right-10">
                IN
            </div>
            <common-form-select class="margin-right-10"
                                [multiple]="true"
                                [selectAll]="true"
                                [required]="true"
                                [search]="true"
                                [options]="searchIn"
                                [value]="searchForm.value.search_in"
                                (onClose)="searchForm.get('search_in').setValue($event)">
            </common-form-select>
            <div class="button-group margin-right-10">
                <button type="button" mat-raised-button color="primary"
                        [disabled]="searchForm.invalid" (click)="getData(1); amplitudeClick('Search')">
                    <mat-icon class="vertical-middle">search</mat-icon>
                    {{ titleSearchMode }} search
                </button>
                <button type="button" mat-raised-button color="primary"
                        [disabled]="searchForm.invalid" [matMenuTriggerFor]="searchMenu" class="icon">
                    <mat-icon class="vertical-middle">keyboard_arrow_down</mat-icon>
                </button>
            </div>

            <button type="button" mat-mini-fab color="warn" (click)="reset()" matTooltip="Reset and refresh">
                <mat-icon>refresh</mat-icon>
            </button>
        </form>
    </div>
    <div>
        <ng-content select="[row2]"></ng-content>
    </div>

    <div class="clear"></div>
    <mat-menu #searchMenu="matMenu" xPosition="before">
        <a mat-menu-item (click)="changeSearchMode(false)">
            Strict search
        </a>
        <a mat-menu-item (click)="changeSearchMode(true)">
            Loose search
        </a>
    </mat-menu>

</div>

<div class="table-container">
    <div class="table-wrapper">
        <ng-container *ngIf="settings.infinity_scroll">
            <form [formGroup]="rowSelection" class="transparent"
                  [ngStyle]="{maxHeight:!loading && tableData && !tableData.length ? '150px'
                            : 'calc(100vh - ' + (scrollHeightValue) + 'px'}">
                <table class="table">
                    <thead>
                    <tr class="infinity-scroll-footer">
                        <td *ngIf="settings.multi_select" class="multi-select"></td>
                        <td colspan="{{tableActions.length}}"></td>
                        <th *ngFor="let col of tableColumns">
                            <div *ngIf="col.footer" [innerHTML]="renderFooter(col)"></div>
                        </th>
                    </tr>
                    <tr class="table-header-title">
                        <td *ngIf="settings.multi_select" class="multi-select" width="40">
                            <mat-checkbox formControlName="selectAll"></mat-checkbox>
                        </td>
                        <th *ngFor="let col of tableActions" class="action" width="40"></th>
                        <th *ngFor="let col of tableColumnsFiltered">
                            <span *ngIf="col.sortable !== false; else noSort" class="sort" (click)="changeSort(col)"
                                  [class.disabled]="sort.data !== col.data"
                                  [class.asc]="sort.data === col.data && sort.dir === 'asc'"
                                  [class.desc]="sort.data === col.data && sort.dir === 'desc'">
                                {{ col.title }}
                            </span>
                            <ng-template #noSort>
                                {{ col.title }}
                            </ng-template>
                        </th>
                    </tr>
                    </thead>
                    <tbody *ngIf="tableColumns" formArrayName="rows">

                    <tr *ngFor="let row of tableData;let rowIndex=index"
                        [class]="settings.rowClass ? settings.rowClass(row): ''">

                        <td *ngIf="settings.multi_select" class="multi-select">
                            <mat-checkbox *ngIf="multiselect(row)" [formControlName]="rowIndex"></mat-checkbox>
                        </td>
                        <td *ngFor="let col of tableActions" class="action">
                            <div class="text-center" *ngIf="checkAction(col,row)"
                                 (click)="actionClick(row, col)"
                                 [innerHTML]="renderAction(col, row)"></div>
                        </td>

                        <td *ngFor="let col of tableColumnsFiltered" [class]="col.cssClass || ''">
                            <common-table-cell
                                    [col]="col"
                                    [rowData]="row"
                                    [class]="getColumnRowCss(col, row)"></common-table-cell>
                        </td>

                    </tr>
                    <tr *ngIf="!loading && tableData && !tableData.length">
                        <td [colSpan]="tableActions.length + tableColumnsFiltered.length + (settings.multi_select?1:0)">
                            <div class="text-center">
                                No data to show
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="loading">
                        <td [colSpan]="tableActions.length + tableColumnsFiltered.length + (settings.multi_select?1:0)">
                            <common-simple-spinner></common-simple-spinner>
                        </td>
                    </tr>
                    <tr *ngIf="settings.infinity_scroll && !loading">
                        <td [colSpan]="tableActions.length + tableColumnsFiltered.length + (settings.multi_select?1:0)">
                            <div class="visibility-trigger"
                                 *ngIf="settings.infinity_scroll && !loading"
                                 observeVisibility
                                 [debounceTime]="50"
                                 (visible)="getData(currentPage)">
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </form>
        </ng-container>
        <ng-container *ngIf="!settings.infinity_scroll">
            <form [formGroup]="rowSelection" class="transparent">
                <table class="table">
                    <thead>
                    <tr class="table-header-title">
                        <td *ngIf="settings.multi_select" width="40" class="multi-select">
                            <mat-checkbox formControlName="selectAll"></mat-checkbox>
                        </td>
                        <th *ngFor="let col of tableActions" class="action" width="40"></th>
                        <th *ngFor="let col of tableColumnsFiltered">
                                    <span *ngIf="col.sortable !== false; else noSort" class="sort"
                                          (click)="changeSort(col)"
                                          [class.disabled]="sort.data !== col.data"
                                          [class.asc]="sort.data === col.data && sort.dir === 'asc'"
                                          [class.desc]="sort.data === col.data && sort.dir === 'desc'">
                                        {{ col.title }}
                                    </span>
                            <ng-template #noSort>
                                {{ col.title }}
                            </ng-template>
                        </th>
                    </tr>
                    </thead>
                    <tbody *ngIf="tableColumns" formArrayName="rows">

                    <tr *ngFor="let row of tableData;let rowIndex=index"
                        [class]="settings.rowClass ? settings.rowClass(row): ''">

                        <td *ngIf="settings.multi_select" class="multi-select">
                            <mat-checkbox *ngIf="multiselect(row)" [formControlName]="rowIndex"></mat-checkbox>
                        </td>
                        <td *ngFor="let col of tableActions" class="action">
                            <div class="text-center" *ngIf="checkAction(col,row)"
                                 (click)="actionClick(row, col)"
                                 [innerHTML]="renderAction(col, row)"></div>
                        </td>

                        <td *ngFor="let col of tableColumnsFiltered" [class]="col.cssClass || getColumnRowCss(col, row)">
                            <common-table-cell
                                    [col]="col"
                                    [rowData]="row"></common-table-cell>
                        </td>

                    </tr>
                    <tr *ngIf="!loading && tableData && !tableData.length">
                        <td [colSpan]="tableActions.length + tableColumnsFiltered.length + (settings.multi_select?1:0)">
                            <div class="text-center">
                                No data to show
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="loading">
                        <td [colSpan]="tableActions.length + tableColumnsFiltered.length + (settings.multi_select?1:0)">
                            <common-simple-spinner></common-simple-spinner>
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <td *ngIf="settings.multi_select"></td>
                        <td *ngFor="let col of tableActions"></td>
                        <td *ngFor="let col of tableColumnsFiltered">
                            <div *ngIf="col.footer" [innerHTML]="renderFooter(col)"></div>
                        </td>
                    </tr>
                    </tfoot>
                </table>
            </form>
        </ng-container>
    </div>
</div>
<div *ngIf="tablePagination" style="margin-top: 20px;">
    <common-pagination [data]="tablePagination" [pid]="settings.table_id" [showTotal]="settings.showTotal"
                       [simple]="!!settings.simple_pagination"
                       (goToPage)="getData($event.page,$event.per_page)">
    </common-pagination>
</div>
