import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {UntypedFormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../common/services/api.service";
import {ToastService} from "../../../../../common/services/toast.service";
import {Base} from "../../../../../common/interfaces/base.interfaces";
import {HelpersService} from "../../../../../common/services/helpers.service";
import {SpinnerService} from "../../../../../common/services/spinner.service";
import {TIME_ZONES} from "../../../../../common/common.module";
import {Table} from "../../../../../common/interfaces/table.interface";
import {Api3Service} from "../../../../../common/services/api3.service";
import {Warehouse} from "../../../../../common/interfaces/warehouse.interface";
import {ConfirmComponent} from "../../../../../common/components/confirm/confirm.component";
import {Courier} from "../../../../../common/interfaces/courier.interface";
import {MatSlideToggle, MatSlideToggleChange} from "@angular/material/slide-toggle";

@Component({
    selector: "section-warehouse-form",
    templateUrl: "form.component.html",
    styleUrls: [
        "form.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class WarehouseFormComponent implements Base.IComponent, OnInit {


    private warehouse: Warehouse.IWarehouse;

    /**
     * Form data
     * @type {any}
     */
    public formData: {
        [key: string]: any,
        name?: string
        created_at?: string,
        updated_at?: string
    };

    public time_zones: { name: string, value: string }[] = [];

    /**
     * Form group / group with controls
     * @type {FormGroup}
     */
    public formGroup: FormGroup = this.formBuilder.group({
        name: [null, [Validators.required]],
        type: ["warehouse", [Validators.required]],
        address: this.formBuilder.group({
            customer: this.formBuilder.group({
                name: [null]
            }),
            country: [null, [Validators.required]],
            address: [null, [Validators.required]],
            address2: [null],
            city: [null, [Validators.required]],
            state: [null],
            zip: [null, [Validators.required]],
            lat: [null],
            lng: [null],
        }),
        contact: this.formBuilder.group({
            first_name: [null, [Validators.required]],
            last_name: [null, [Validators.required]],
            email: [null, [Validators.required, Validators.email]],
            phone: [null, [Validators.required]],
            fax: [null]
        }),
        is_dc: [null],
        is_inspection: [null],
        is_sw_upgrade: [null],
        cut_off: [null],
        timezone: [null],
        properties: this.formBuilder.group({
            inbound_transaction_trigger: [null],
            outbound_transaction_trigger: [null],
        })
    });

    public countries: any[];

    public readonly state: Base.IState;

    public logsTableSettings: Table.ISettings;

    @ViewChild(ConfirmComponent, {static: true})
    public confirmRef: ConfirmComponent;

    public couriers: Courier.ICourier[] = [];

    public selected_couriers: number[] = [];

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private helpers: HelpersService,
        private spinnerService: SpinnerService
    ) {
    }

    /**
     * Prepare warehouse data (by id)
     * @returns {Promise<any>}
     */
    private async prepareData(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.get(
            `${this.state.section}/warehouses/${this.state.params.id}`, {
                relations: [
                    "Partners:partners.id,display_name",
                    "address.customer",
                    "contact",
                    "Contacts",
                    "contact.customer",
                    "Threepl",
                    "Couriers:id"
                ]
            });
        this.spinnerService.hide();
        if (data) {
            this.warehouse = data;

            data.address.country = data.address.country_iso_2;
            if (!data.address.customer) {
                data.address.customer = {};
            }

            this.selected_couriers = this.warehouse.couriers.map((c: Courier.ICourier) => c.id);

            this.formGroup.patchValue(data);
            this.formGroup.controls["timezone"].disable();
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Prepare countries (as control options)
     * @returns {Promise<any>}
     */
    private async prepareCountriesList(): Promise<any> {
        this.spinnerService.show();
        this.countries = await this.helpers.prepareCountriesList();
        this.spinnerService.hide();
        this.changeDetectorRef.markForCheck();
    }


    private getTimeZones(): void {
        for (const key of Object.keys(TIME_ZONES)) {
            this.time_zones.push({name: TIME_ZONES[key], value: key});
        }
    }

    private async getCouriersList(): Promise<any> {
        this.spinnerService.show();

        const {data}: Api.IResponse = await this.api3Service.get(`${this.state.section}/couriers`, {
            sort: {data: "display_name"},
        });

        this.spinnerService.hide();

        if (data) {
            this.couriers = data;
        }
        this.changeDetectorRef.markForCheck();
    }

    private prepareLogsTable(): void {
        this.logsTableSettings = {
            table_id: "whlogstable",
            api: {
                url: ["/threepl", this.state.section_slug, "warehouses", this.state.params.id, "logs"],
                query: {
                    relations: [
                        "User"
                    ],
                },
                version: 3
            },
            columns: [
                {
                    data: "subject",
                    title: "Subject"
                },
                {
                    data: "log",
                    title: "Log"
                },
                {
                    data: "user.name",
                    name: "User.name",
                    title: "Performed by",
                    sortable: false
                },
                {
                    data: "created_at",
                    title: "Created at",
                    searchable: false
                },
            ],
            sort_default: {
                data: "created_at",
                dir: "desc"
            }
        };
    }

    /**
     * Handle autocomplete selected value event
     * @param $event
     */
    public onCountrySelected($event: any): void {
        this.formGroup.get("address").get("country").setValue($event.value);
    }

    /**
     * Submit form (create, update)
     * @returns {Promise<any>}
     */
    public async handleFormSubmit(): Promise<any> {
        if (!this.formGroup || !this.formGroup.valid) {
            return;
        }

        if (this.warehouse?.partners?.length && !await this.confirmRef.confirm("This warehouse related to partner(s): "
            + (this.warehouse.partners.map(p => p.display_name).join(", "))
            + ".<br>Any changes will affect all related partners.")) {
            return;
        }


        let response: Api.IResponse;
        this.spinnerService.show();
        const body: any = this.formGroup.value;

        if (body.type !== "Virtual") {
            body.properties.inbound_transaction_trigger = null;
            body.properties.outbound_transaction_trigger = null;
        }

        if (this.state.action === "add") {
            response =
                await this.api3Service.post(`${this.state.section}/warehouses`, body);
        }
        if (this.state.action === "edit") {
            response =
                await this.api3Service.put(`${this.state.section}/warehouses/${this.warehouse.id}/`, body);
        }
        this.spinnerService.hide();
        if (response.data) {
            this.toastService.show(response.message, "success");
            this.router.navigate([this.state.section, this.state.component]);
        }
    }

    public async toggleCourier(courier_id: number, toggleEvent: MatSlideToggleChange): Promise<any> {

        const {code, message}: Api.IResponse = await this.api3Service.put(
            `${this.state.section}/warehouses/${this.warehouse.id}/`
            + `${toggleEvent.checked ? "attach" : "detach"}-courier/${courier_id}`
        );

        if (code === 200) {
            this.toastService.show(message, "success");
        }
        this.changeDetectorRef.markForCheck();
    }

    public async ngOnInit(): Promise<any> {
        this.getTimeZones();
        this.prepareCountriesList();
        if (this.state.params.id) {
            this.prepareData();
            this.prepareLogsTable();
            this.getCouriersList();
        }
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "warehouses",
            actions: {
                "add": ["browse_threepls", "add_warehouses"],
                "edit": ["browse_threepls", "edit_warehouses"]
            }
        };
    }

}
