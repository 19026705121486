import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {FormControl} from "@angular/forms";
import {Api, ApiService} from "../../../../../../../../common/services/api.service";
import {Base} from "../../../../../../../../common/interfaces/base.interfaces";
import {Router} from "@angular/router";
import {UserService} from "../../../../../../../../common/services/user.service";
import {Table2Component} from "../../../../../../../../common/components/table2";
import {Table} from "../../../../../../../../common/interfaces/table.interface";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: "section-dashboard-template-default-fulfillment-tab",
    template: `
        <div class="padding-top-20">
            <div class="margin-bottom-20">
                <mat-button-toggle-group [formControl]="activeTab">
                    <mat-button-toggle value="replenishment">Replenishment</mat-button-toggle>
                    <mat-button-toggle value="transfer">Warehouse transfer</mat-button-toggle>
                </mat-button-toggle-group>
            </div>

            <div class="margin-bottom-20">
                <mat-button-toggle-group [formControl]="statusFilter">
                    <mat-button-toggle [value]="null">
                        <b class="text-warn">{{counts.all}}</b>&nbsp;<span>All</span>
                    </mat-button-toggle>
                    <mat-button-toggle value="open">
                        <b class="text-warn">{{counts.open}}</b>&nbsp;<span>OPEN</span>
                    </mat-button-toggle>
                    <mat-button-toggle value="in_transit">
                        <b class="text-warn">{{counts.in_transit}}</b>&nbsp;<span>IN TRANSIT</span>
                    </mat-button-toggle>
                    <mat-button-toggle value="delivered">
                        <b class="text-warn">{{counts.delivered}}</b>&nbsp;<span>DELIVERED</span>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>

            <mat-card>
                <common-table2 *ngIf="listTable" [settings]="listTable" [search]="false">
                    <h2>
                        {{activeTab.value === "replenishment" ? "Replenishment" : "Warehouse transfer" }} orders
                    </h2>
                </common-table2>

            </mat-card>
        </div>
    `,
    styleUrls: ["../default.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})

export class DashboardDefaultFulfillmentTabComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();


    @Input()
    public settings: any;

    @Input()
    public state: Base.IState;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;


    public activeTab: FormControl = new FormControl(null);

    public statusFilter: FormControl = new FormControl(null);

    public listTable: Table.ISettings;

    public counts: { all: number, delivered: number, in_transit: number, open: number } = {
        all: 0, delivered: 0, in_transit: 0, open: 0
    };


    public constructor(
        private apiService: ApiService,
        private userService: UserService,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router
    ) {
    }


    private getUrl(order_type: string, status: string): Table.ITableApi {
        return {
            url: ["dashboard", "fulfillment_orders"],
            query: {
                order_type,
                status
            }
        };
    }

    private prepareTable(type: string, status: string): void {

        const columns: Table.ICol[] = [
            {
                data: "ref",
                title: "ref",
                render: (row: any): string => {
                    if (this.userService.validatePermissions("read_orders")) {
                        return "<button class='mat-mdc-raised-button mdc-button button-200' " +
                            "type='button' data-action='view'>"
                            + row.ref
                            + "</button>";
                    }
                    return row.ref;
                },
                click: (row: any): void => {
                    if (this.state) {
                        this.router.navigate([
                            (this.state.section === "admin"
                                ? "/partner/" + row.partner_slug
                                : this.state.section),
                            "orders",
                            "view",
                            "id",
                            row.id
                        ]);
                    }
                }
            },
            {
                data: "to_wh",
                title: "To"
            },
        ];

        if (type === "transfer") {
            columns.splice(1, 0, {
                data: "from_wh",
                title: "From"
            });
        }

        this.listTable = {
            table_id: "sdgpadjjy_" + type,
            columns,
            api: this.getUrl(type, status)
        };
        this.changeDetectorRef.markForCheck();

    }

    public async getFulfilmentOrdersCount(order_type: string): Promise<any> {
        this.counts = {
            all: 0, delivered: 0, in_transit: 0, open: 0
        };
        this.changeDetectorRef.markForCheck();


        const {data}: any = await this.apiService.request(Api.EMethod.Get,
            ["dashboard", "fulfillment_orders"], {}, {
                data_structure: "count",
                order_type: order_type
            });
        if (data) {
            this.counts = data;
        }
        this.changeDetectorRef.markForCheck();
    }


    public ngOnInit(): void {

        this.activeTab.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: string) => {
            this.prepareTable(value, this.statusFilter.value);
            this.getFulfilmentOrdersCount(value);
        });

        this.activeTab.setValue("replenishment");

        this.statusFilter.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: string) => {
            this.tableRef.reload(this.getUrl(this.activeTab.value, value));
        });

    }


    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
