import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {UntypedFormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Modal} from "../../../../../services/modal.service";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {DATE_FORMATS} from "../../../../../../common/common.module";
import {debounceTime, takeUntil} from "rxjs/operators";
import {ReplaySubject} from "rxjs";
import {Router} from "@angular/router";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {CourierService} from "../../../../../../common/services/courier.service";
import {ConfirmComponent} from "src/modules/common/components/confirm/confirm.component";
import {ViewChild} from "@angular/core";

@Component({
    selector: "section-courier-transaction-form",
    templateUrl: "form.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class CourierTransactionFormComponent implements OnInit, OnDestroy {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    private couriers: { value: number, name: string }[];

    private courierServices: { value: number, name: string }[];

    /**
     * Form group / group with controls
     * @type {FormGroup}
     */
    public formGroup: FormGroup;

    public modal: Modal.IModal;

    public action: string = "add";

    public couriersFiltered: ReplaySubject<{ value: number, name: string }[]> =
        new ReplaySubject<{ value: number, name: string }[]>(1);

    public couriersSearch: FormControl = new FormControl(null);

    public courierServicesFiltered: ReplaySubject<{ value: number, name: string }[]> =
        new ReplaySubject<{ value: number, name: string }[]>(1);

    public courierServicesSearch: FormControl = new FormControl(null);

    public show_link_warehouse_transactions: boolean = true;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private formBuilder: UntypedFormBuilder,
        private apiService: ApiService,
        private toastService: ToastService,
        public helpers: HelpersService,
        private router: Router,
        private spinnerService: SpinnerService,
        private courierService: CourierService
    ) {
    }

    /**
     * Prepare form group (create)
     * @returns {void}
     */
    private prepareForm(): void {
        this.formGroup = this.formBuilder.group({
            id: [null],
            courier_slug: [null, [Validators.required]],
            courier_service_slug: [null, [Validators.required]],
            tracking_number: [null, [Validators.required]],
            date: [null],
            eta: [null],
            created: [new Date(), [Validators.required]],
            created_at: [null],
            link_unlinked_warehouse_transactions: [
                this.modal.params.link_warehouse_transactions !== undefined
                    ? this.modal.params.link_warehouse_transactions : true
            ]
        });

        if (this.modal.params.data) {
            this.formGroup.patchValue(this.modal.params.data);

            if (this.modal.params.data.received_at) {
                this.formGroup.get("date").setValue(this.modal.params.data.received_at);
            } else if (this.modal.params.data.eta) {
                this.formGroup.get("date").setValue(this.modal.params.data.eta);
            }

            if (this.modal.params.data.created_at) {
                this.formGroup.get("created").setValue(this.modal.params.data.created_at);
            }
        }

        if (this.modal.params.hasOwnProperty("show_link_warehouse_transactions")) {
            this.show_link_warehouse_transactions = this.modal.params.show_link_warehouse_transactions;
        }

        this.changeDetectorRef.markForCheck();
    }


    /**
     * Get couriers list
     * @returns {Promise<any>}
     */
    private async getCouriers(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["courier"], {}, {data_structure: "select"});
        if (data) {
            this.couriers = data;
            this.couriersFiltered.next(this.couriers.slice());
            if (this.formGroup.value.courier_service_slug) {
                this.formGroup.get("courier_service_slug").reset();
                if (this.modal.params.action === "edit") {
                    this.getCourierServices(this.formGroup.get("courier_slug").value);
                }
            }
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get courier services list
     * @returns {Promise<any>}
     */
    private async getCourierServices(slug: string): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["courier_service"], {}, {courier_slug: slug});
        if (data) {
            this.courierServices = data.map((value: any): any => {
                return {
                    name: value.service_name,
                    value: value.slug
                };
            });

            this.courierServicesFiltered.next(this.courierServices.slice());

            if (this.modal.params.action === "edit") {
                this.formGroup.get("courier_service_slug").setValue(this.modal.params.data.courier_service_slug);
            }
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }


    /**
     * Get courier services after courier changed
     */
    private courierChangeSubscribe(): void {
        this.formGroup.get("courier_slug").valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((val: string): void => {
                this.formGroup.get("courier_service_slug").reset();
                this.getCourierServices(val);
            });
    }

    /**
     * Find courier by track number
     * @param track
     * @private
     */
    private async findCourierByTrack(track: string): Promise<any> {
        const {data, message}: Api.IResponse = await this.courierService.findByTrack(track);

        if (data && data.length) {
            this.formGroup.get("courier_slug").setValue(data[0]);
        }
        this.toastService.show(message, "success");
    }

    /**
     * Add/edit courier transaction
     * @returns {Promise<any>}
     */
    public async handleFormSubmit(): Promise<any> {

        this.spinnerService.show();
        if (this.formGroup.valid) {
            if (this.formGroup.value.date) {
                const date: string = this.formGroup.value.date.format(DATE_FORMATS.parse.dateInput);
                this.formGroup.get("eta").setValue(date);
            }

            if (this.formGroup.value.created) {
                const created: string = this.formGroup.value.created.format(DATE_FORMATS.parse.dateInput);
                this.formGroup.get("created_at").setValue(created);
            }

            let response: Api.IResponse;

            if (this.modal.params.action === "add") {
                const url: string[] = this.modal.params.shipmentId
                    ? ["shipment", this.modal.params.shipmentId, "courier_transactions"]
                    : ["order", this.modal.params.orderRef, "courier_transaction"];
                response = await this.apiService.request(Api.EMethod.Post, url, this.formGroup.value);
            } else if (this.modal.params.action === "edit") {
                response = await this.apiService.request(Api.EMethod.Post,
                    ["courier", "transactions", this.modal.params.data.id], this.formGroup.value);
            }

            if (response) {
                this.toastService.show(response.message, response.type as string);
                if (response.type as string === "success") {
                    this.modal.response.next({
                        name: "value",
                        value: response.data
                    });
                }
            }
        }
        this.spinnerService.hide();
    }

    /**
     * Delete courier transaction
     * @returns {Promise<any>}
     */
    public async delete(): Promise<any> {
        if (!await this.confirmRef.confirm("Are you sure want to delete this courier transaction?")) {
            return;
        }
        this.spinnerService.show();
        const response: Api.IResponse = await await this.apiService.request(Api.EMethod.Delete,
            ["courier", "transactions", this.modal.params.data.id]);

        if (response) {
            this.toastService.show(response.message, response.type as string);
            if (response.type as string === "success") {
                this.modal.response.next({
                    name: "value",
                    value: {}
                });
            }
        }
        this.spinnerService.hide();
    }

    public createLabel(): void {
        this.modal.response.next({
            name: "value",
            value: null
        });

        this.router.navigate([
            this.modal.params.state.section,
            "labels",
            "add",
            "order",
            this.modal.params.orderData.id,
            "shipment",
            this.modal.params.shipmentId,
            "type",
            this.modal.params.type,
            "partner",
            this.modal.params.partnerSlug,
            "back_to",
            btoa(this.router.url)
        ]);
    }

    public ngOnInit(): void {
        this.action = this.modal && this.modal.params.action;

        this.prepareForm();
        this.getCouriers();
        this.courierChangeSubscribe();

        this.formGroup.get("tracking_number").valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: string): void => {
                if (value && value.length >= 5) {
                    this.findCourierByTrack(value);
                }
            });

        this.couriersSearch.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(100))
            .subscribe((value: string): void => {
                if (!value) {
                    this.couriersFiltered.next(this.couriers.slice());
                } else {
                    value = value.toLowerCase();
                    this.couriersFiltered.next(
                        this.couriers.filter((item: { name: string }): boolean =>
                            item.name.toLowerCase().indexOf(value) > -1)
                    );
                }
            });

        this.courierServicesSearch.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(100))
            .subscribe((value: string): void => {
                if (!value) {
                    this.courierServicesFiltered.next(this.courierServices?.slice() || []);
                } else {
                    value = value.toLowerCase();
                    this.courierServicesFiltered.next(
                        this.courierServices.filter((item: { name: string }): boolean =>
                            item.name.toLowerCase().indexOf(value) > -1)
                    );
                }
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
