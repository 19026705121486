import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, Validators} from "@angular/forms";
import {StepperSelectionEvent} from "@angular/cdk/stepper";
import {OrderShipmentWizardStep1Component} from "./step1/step1.component";
import {OrderShipmentWizardStep2Component} from "./step2/step2.component";
import {MatStepper} from "@angular/material/stepper";
import {Router} from "@angular/router";
import {Base} from "../../../../../../common/interfaces/base.interfaces";

@Component({
    selector: "section-order-shipment-wizard",
    templateUrl: "wizard.component.html",
    styleUrls: [
        "wizard.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class OrderShipmentWizardComponent implements Base.IComponent, OnInit {

    @ViewChild(OrderShipmentWizardStep1Component, {static: true})
    public step1Form: OrderShipmentWizardStep1Component;

    @ViewChild(OrderShipmentWizardStep2Component, {static: true})
    public step2Form: OrderShipmentWizardStep2Component;

    @ViewChild("stepper", {static: true})
    public stepper: MatStepper;

    /**
     * Step1 step state
     * @type {{control: FormControl}}
     */
    public step1: { control: FormControl } = {
        control: new FormControl(null, [Validators.required])
    };

    /**
     * Step2 step state
     * @type {{control: FormControl}}
     */
    public step2: { control: FormControl } = {
        control: new FormControl(null, [Validators.required])
    };

    public readonly state: Base.IState;

    public constructor(private changeDetectorRef: ChangeDetectorRef, private router: Router) {
    }


    /**
     * Handle step changes
     * @param {StepperSelectionEvent} event
     * @returns {void}
     */
    public async handleStepChanges(event?: StepperSelectionEvent): Promise<any> {
        const step: number = event && event.selectedIndex;
        switch (step) {
            case 1:
                this.step2Form.init(this.state, this.step1.control.value);
                break;
            default:
                this.step1Form.init(this.state);
                break;
        }
    }

    /**
     * Finish wizart and redirect back to order
     */
    public finish(): void {
        this.router.navigate([
            this.state.section, "orders", "view", "id", this.state.params.order_id, "type", this.state.params.type
        ]);

    }

    public async ngOnInit(): Promise<any> {
        this.handleStepChanges();

        this.stepper.selectedIndex = 0;
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "shipments",
            actions: {
                "add": ["browse_shipments", "browse_parcels"],
                "edit": ["browse_shipments", "browse_parcels"]
            }
        };
    }

}
