import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation
} from "@angular/core";
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {distinctUntilChanged, takeUntil} from "rxjs/operators";

@Component({
    selector: "common-form-error",
    template: `
        <div class="input-form-error">{{ getErrorMessage() }}</div>
    `,
    styleUrls: [
        "./error.component.scss"
    ],
    encapsulation: ViewEncapsulation.None,
})
export class CommonFormErrorComponent implements OnInit {

    @Input()
    public form: FormGroup;

    @Input()
    public controlName: string;

    @Input()
    public control: AbstractControl;

    public constructor() {
    }

    public getErrorMessage(): string {
        if (!this.control) {
            return null;
        }

        switch (true) {
            case this.control.hasError("required"):
                return "You must enter a value";

            case this.control.hasError("email"):
                return "Not a valid email";

            case this.control.hasError("maxlength"):
                return "Max value length is " + this.control.errors?.maxlength?.requiredLength;

            case this.control.hasError("pattern"):
                return "Wrong value";

            default:
                return null;
        }
    }

    public ngOnInit(): void {
        if (!this.control && this.controlName && this.form) {
            this.control = this.form.get(this.controlName);
        }
    }
}
