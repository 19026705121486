import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {Observable} from "rxjs";
import {AbstractControl, FormControl, ValidationErrors, Validators} from "@angular/forms";
import {takeUntil, of} from "rxjs";

@Component({
    selector: "common-autocomplete",
    template: `
        <div [class]="wrapperClass ? wrapperClass : ''">
            <mat-form-field [class]="class ? class : ''">
                <mat-label>{{placeholder}}</mat-label>
                <input type="text" matInput #trigger="matAutocompleteTrigger"
                       (focus)="onFocus()" (keydown)="onKeyDown($event)" [required]="required"
                       [matAutocomplete]="auto" [formControl]="formControl"
                       [value]="valueName">
                <mat-icon *ngIf="showClear" matSuffix (click)="clearValue()">close</mat-icon>
                <mat-error *ngIf="!formControl.valid">{{errorText}}</mat-error>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option.value">
                        <img *ngIf="image.path && image.ext" height="25" width="36"
                             class="margin-right-10 vertical-middle"
                             [src]="image.path + '/' + ('' + option.value).toLowerCase() + '.' + image.ext" alt="">
                        <span>{{option.name}}</span>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
    `,
    styleUrls: [
        "autocomplete.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})

export class AutocompleteComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

    private gotOptions: boolean = false;

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild(MatAutocompleteTrigger, {static: false})
    protected autocompleteTrigger: MatAutocompleteTrigger;

    /**
     * Css class of autocomplete wrapper
     */
    public wrapperClass: string = "";

    /**
     * Options after filtering by input value
     */
    public filteredOptions: Observable<any[]>;


    public formControl: FormControl;

    public error: boolean = false;

    public showClear: boolean = false;

    /**
     * Selected option value
     * @type {string}
     */
    @Input()
    public value: string = null;

    /**
     * Default input value
     * @type {string}
     */
    @Input()
    public valueName: string = null;

    /**
     * Input placeholder
     * @type {string}
     */
    @Input()
    public placeholder: string;

    /**
     * Css class
     * @type {string}
     */
    @Input()
    public class: string = "";

    @Input()
    public image: { path: string, ext: string } = {path: null, ext: null};

    /**
     * Required flag
     * @type {boolean}
     */
    @Input()
    public required: boolean = false;

    /**
     * Message shown on any errors
     * @type {string}
     */
    @Input()
    public errorText: string = "";

    /**
     * Dropdown options
     */
    @Input()
    public options: { name: string, value: string }[] = [];

    /**
     * Is free value is allowed?
     * @type {boolean}
     */
    @Input()
    public allowFreeInput: boolean = true;

    @Input()
    public reset: boolean = false;

    /**
     * Returns result of selection/input
     * @type {EventEmitter<any>}
     */
    @Output()
    public optionSelected: EventEmitter<any> = new EventEmitter();

    @Output()
    public clear: EventEmitter<any> = new EventEmitter<any>();

    public constructor(private changeDetectorRef: ChangeDetectorRef) {
    }

    private prepareForm(): void {
        if (this.required) {
            this.formControl = new FormControl(this.valueName,
                [
                    Validators.required
                    // TODO Check how to integrate this validator without kostyly (value.toString)
                    , (control: AbstractControl): ValidationErrors | null => {
                    if (this.allowFreeInput) {
                        return null;
                    } else if (control.value) {
                        for (const option of this.options) {
                            if (option.name.toLowerCase() === control.value.toString().toLowerCase()) {
                                return null;
                            }
                        }
                    }
                    return {is_false: true};
                }
                ]);
        } else {
            this.formControl = new FormControl(this.valueName);
        }
    }

    /**
     * Error state handler
     * @param {boolean} state
     */
    private setError(state: boolean): void {
        this.error = state;
        if (state) {
            this.wrapperClass = "has-error";
        } else {
            this.wrapperClass = "";
        }
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Filtering options by input value
     * @param name
     */
    private filterOptions(name: string): Observable<any> {
        if (!name) {
            return of(this.options);
        }
        return of(this.options.filter((option: any): boolean =>
            option.name.toLowerCase().indexOf(("" + name).toLowerCase()) > -1));
    }

    /**
     * Handle dropdown selected option event
     * @param {MatAutocompleteSelectedEvent} $event
     */
    public onOptionSelected($event: MatAutocompleteSelectedEvent): void {
        this.formControl.setValue($event.option.viewValue);
        this.optionSelected.emit({name: $event.option.viewValue, value: $event.option.value});
        this.setError(false);
        this.filteredOptions = this.filterOptions($event.option.viewValue);
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Prevent form submit on enter if any errors
     * @param $event
     * @returns {any}
     */
    public onKeyDown($event: any): any {
        if ($event.keyCode === 13) {
            if (this.error) {
                return false;
            }
        }
    }

    public onFocus(): void {
        this.autocompleteTrigger.openPanel();
        this.changeDetectorRef.markForCheck();
    }

    public clearValue(): void {
        this.formControl.setValue("");
        this.filteredOptions = of(this.options);
        this.changeDetectorRef.markForCheck();
        this.clear.emit(true);
    }

    public ngOnInit(): void {
        this.prepareForm();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.gotOptions && changes.options && this.options && this.options.length > 0) {
            if (!this.valueName && this.value) {
                for (const option of this.options) {
                    if ("" + option.value === "" + this.value) {
                        this.valueName = option.name;
                    }
                }
            }
            this.gotOptions = true;
        }
        if (changes && changes.reset && this.formControl) {
            this.formControl.reset();
            this.setError(false);
        }

        if (changes && changes.value && !changes.value.firstChange && this.formControl
            && this.options && this.options.length) {
            for (const option of this.options) {
                if ("" + option.value === "" + this.value) {
                    this.formControl.setValue(option.name);
                    this.changeDetectorRef.markForCheck();
                }
            }
        }
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngAfterViewInit(): void {
        this.filteredOptions = of(this.options);
        this.formControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: string): void => {

            this.optionSelected.emit({name: "", value: null});

            this.filteredOptions = this.filterOptions(value);

            if (this.allowFreeInput) {
                this.optionSelected.emit({name: value, value: value});
                this.setError(false);
            } else if (value) {
                let error: boolean = true;
                for (const option of this.options) {
                    if (option.name.toLowerCase() === value.toString().toLowerCase()) {
                        this.optionSelected.emit({name: option.name, value: option.value});
                        error = false;
                        break;
                    }
                }
                this.setError(error);
            }
            this.showClear = !!value;
            this.changeDetectorRef.markForCheck();
        });

        this.changeDetectorRef.markForCheck();
    }
}
