import {ChangeDetectionStrategy, Component} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";

@Component({
    selector: "section-pickup-requests-list-all",
    template: `
        <h1 class="container-heading">Pickup request</h1>
        <section-pickup-requests-list [state]="state"></section-pickup-requests-list>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PickupRequestsListAllComponent implements Base.IComponent {


    public readonly state: Base.IState;

    public constructor() {
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "pickup-requests-list", // path
            actions: {
                "browse": ["browse_pickup_requests"]
            }
        };
    }

}
