import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {debounceTime, take, takeUntil} from "rxjs";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Router} from "@angular/router";
import * as Quill from "quill";
import {environment} from "../../../../../../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {FileUploadComponent} from "../../../../../../common/components/file-upload/file-upload.component";
import {UrlEntryComponent} from "../../requests/url-entry/url-entry.component";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {UserService} from "src/modules/common/services/user.service";
import {User} from "src/modules/common/interfaces/user.interface";
import {AdminThreeplService} from "src/modules/section/components/admin/services/thpeepl.service";
import {AdminPartnerService} from "src/modules/section/components/admin/services/partner.service";

const Delta: Quill.DeltaStatic = Quill.import("delta");

@Component({
    selector: "section-knowledge-center-form",
    templateUrl: "form.component.html",
    styleUrls: [
        "form.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class KnowledgeCenterFormComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);
    /**
     * Form group / group with controls
     * @type {FormGroup}
     */
    public formGroup: FormGroup = new FormGroup({
        name: new FormControl(null, [Validators.required]),
        article: new FormControl(null, []),
        link: new FormControl(null, []),
        is_visible_partners: new FormControl(false, [Validators.required]),
        is_visible_warehouses: new FormControl(true, [Validators.required]),
        is_public: new FormControl(false),
        visible_for_partners: new FormControl([]),
        visible_for_threepls: new FormControl([])
    });

    public readonly state: Base.IState;

    public modal: Modal.IModal;

    public action: string = "add";

    public selectedType: "document" | "article" | "link" = "document";

    public uploadedFile: { name: string, url: string, type: string }[] = [];

    public editorInstance: any;

    public isShowClientCanSeeCheckbox: boolean = false;

    public partners: User.IPartner[] = [];

    public partnersSearch: FormControl = new FormControl(null);

    public filteredPartners: User.IPartner[] = [];

    public threepls: User.IThreepl[] = [];

    public threeplsSearch: FormControl = new FormControl(null);

    public filteredThreepls: User.IThreepl[] = [];

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private toastService: ToastService,
        private modalService: ModalService,
        private router: Router,
        private http: HttpClient,
        private spinnerService: SpinnerService,
        private userService: UserService,
        private partnerService: AdminPartnerService,
        private threeplService: AdminThreeplService
    ) {
    }

    public async ngOnInit(): Promise<void> {
        this.formGroup.controls.is_public.disable();
        if (this.modal.params.state.section_type === "admin") {
            this.getPartners();
            this.getThreepls();
        }

        this.isShowClientCanSeeCheckbox =
            this.userService.validatePermissions("can_see_public_knowledge_center_documents");
        switch (this.modal.params.state.section_type) {
            case "partner":
                this.formGroup.controls.is_visible_partners.setValue(true);
                this.formGroup.controls.is_visible_warehouses.setValue(true);
                break;
            case "warehouse":
                this.formGroup.controls.is_visible_partners.setValue(true);
                this.formGroup.controls.is_visible_warehouses.setValue(true);
                break;
            case "admin":
                this.formGroup.controls.is_visible_partners.setValue(true);
                this.formGroup.controls.is_visible_warehouses.setValue(true);
                break;
            default:
        }
        if (this.modal.params.id) {
            this.action = "edit";
            await this.getData(this.modal.params.id);
        }

        this.partnersSearch.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: string): void => {
                    this.filteredPartners = this.partners.filter(p => {
                        return p.display_name.toLowerCase().includes(value.toLowerCase());
                    });
                    this.changeDetectorRef.markForCheck();
                }
            );

        this.threeplsSearch.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: string): void => {
                    this.filteredThreepls = this.threepls.filter(p => {
                        return p.display_name.toLowerCase().includes(value.toLowerCase());
                    });
                    this.changeDetectorRef.markForCheck();
                }
            );

        this.formGroup.controls.is_visible_partners.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value: string): void => {
                    if (value) {
                        this.formGroup.controls.is_public.enable();
                    } else {
                        this.formGroup.controls.is_public.setValue(false);
                        this.formGroup.controls.is_public.disable();
                    }
                }
            );

        this.formGroup.controls.visible_for_partners.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value: []): void => {
                    if (value.length) {
                        this.formGroup.controls.is_public.enable();
                    } else {
                        this.formGroup.controls.is_public.setValue(false);
                        this.formGroup.controls.is_public.disable();
                    }
                }
            );
    }

    private async getPartners(): Promise<any> {
        const {data}: Api.IResponse = await this.partnerService.getPartners();

        this.filteredPartners = this.partners = data;
        this.changeDetectorRef.markForCheck();
    }

    private async getThreepls(): Promise<any> {
        const {data}: Api.IResponse = await this.threeplService.getThreepls({
            relations: [
                "Warehouses:id,3pl_id,name"
            ]
        });

        this.filteredThreepls = this.threepls = data;
        this.changeDetectorRef.markForCheck();
    }

    private async getData(documentId: any): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["knowledge_center", "documents", documentId]);
        this.spinnerService.hide();
        if (data) {
            if (this.modal.params.state.section_type === "admin") {
                data.visible_for_partners = data.visible_for_partners.map(d => d.id);
                data.visible_for_threepls = data.visible_for_threepls.map(d => d.id);
            }
            this.formGroup.patchValue(data);
            if (data.is_visible_partners) {
                this.formGroup.controls.is_public.enable();
            } else {
                this.formGroup.controls.is_public.disable();
            }
            if (data.short_url_id) {
                let path = data.short_url.full_path;
                this.uploadedFile.push({
                    name: path.substring(path.lastIndexOf("/") + 1),
                    url: data.document_url,
                    type: data.document_type
                });
            }
            this.selectedType = data.type;
        }
    }

    private async createDocument(body: any): Promise<any> {
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["knowledge_center", "documents"], body);
        if (response && response.type as string === "success") {
            this.toastService.show(response.message, response.type as string);
            this.modal.response.emit({
                name: "submit"
            });
        }
    }

    private async updateDocument(id: any, body: any): Promise<any> {
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["knowledge_center", "documents", id], body);
        if (response && response.type as string === "success") {
            this.toastService.show(response.message, response.type as string);
            this.modal.response.emit({
                name: "submit"
            });
        }
    }

    public updateForm(): void {
        this.formGroup.patchValue({article: this.editorInstance.root.innerHTML});
        this.formGroup.updateValueAndValidity();
    }

    public getEditorInstance(editorInstance: any): void {
        this.editorInstance = editorInstance;
        if (this.selectedType === "article") {
            this.editorInstance.root.innerHTML = this.formGroup.get("article").value;
        }
        const toolbar: any = editorInstance.getModule("toolbar");
        const self: any = this;
        toolbar.addHandler("image", function (): void {
            let fileInput: any = toolbar.container.querySelector("input");
            if (fileInput === null) {
                fileInput = document.createElement("input");
                fileInput.setAttribute("type", "file");
                fileInput.setAttribute(
                    "accept",
                    "image/png, image/gif, image/jpeg, image/bmp, image/x-icon"
                );
                fileInput.classList.add("ql-image");
                fileInput.addEventListener("change", (): void => {
                    if (fileInput.files != null && fileInput.files[0] != null) {
                        const formData: FormData = new FormData();
                        formData.append("file", fileInput.files[0], fileInput.files[0].name);
                        formData.set("upload_preset", environment.cloudinary.image_upload_preset);
                        let headers: HttpHeaders = new HttpHeaders();
                        headers = headers.set("X-Requested-With", "XMLHttpRequest");
                        headers = headers.set("Accept", "application/json");
                        self.http.request("post",
                            self.apiService.getUrl("https://api.cloudinary.com/v1_1/"
                                + environment.cloudinary.cloudName + "/upload"),
                            {
                                headers: headers,
                                body: formData
                            })
                            .pipe(take(1))
                            .subscribe((data: any): void => {
                                const fileUrl: any = data.secure_url || data.url;
                                const range: any = this.quill.getSelection(true);
                                editorInstance.updateContents(
                                    new Delta()
                                        .retain(range.index)
                                        .delete(range.length)
                                        .insert(
                                            {
                                                image: fileUrl
                                            },
                                            {
                                                width: "351",
                                                nameClass: "else",
                                                alt: "no working",
                                                offset: 3,
                                            }
                                        ));
                            });
                    }
                });
                this.container.appendChild(fileInput);
            }
            fileInput.click();
        });
    }

    public async uploadFile(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(FileUploadComponent, {
            url: ["knowledge_center", "documents", "upload"],
            accept: [
                "gif", "png", "jpg", "jpeg", "tiff", "doc",
                "docx", "xls", "xlsx", "csv", "ppt", "pptx", "msg", "txt", "pdf", "xlsb"
            ]
        });
        if (response && response.value && response.value.data && response.value.data.path) {
            const uploaded: any = {
                name: response.value.data.name,
                url: response.value.data.path,
                type: response.value.data.type
            };
            this.uploadedFile.push(uploaded);
            this.changeDetectorRef.markForCheck();
        }
    }

    public deleteFile(index: number): void {
        this.uploadedFile.splice(index, 1);
    }

    public async insertImage(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(UrlEntryComponent, {
            state: this.state,
            title: "Insert image url"
        });
        if (response && response.name === "value" && response.value !== "") {
            const fileUrl: any = response.value;
            const range: any = this.editorInstance.getSelection(true);
            this.editorInstance.updateContents(
                new Delta()
                    .retain(range.index)
                    .delete(range.length)
                    .insert(
                        {
                            image: fileUrl
                        },
                        {
                            width: "351",
                            nameClass: "else",
                            offset: 3,
                        }
                    ));
        }
    }

    /**
     * Submit form
     */
    public async submit(): Promise<any> {
        this.spinnerService.show();

        const body: any = {...this.formGroup.value};
        body.type = this.selectedType;
        if (["article", "link"].indexOf(this.selectedType) === -1) {
            delete body.article;
            if (this.uploadedFile.length) {
                body.document_url = this.uploadedFile[0].url;
                body.document_type = this.uploadedFile[0].type;
            }
        }

        if (this.modal.params.state.section_type == "admin") {
            body.is_visible_partners = body.visible_for_partners.length > 0;
            body.is_visible_warehouses = body.visible_for_threepls.length > 0;
        }

        if (this.modal.params.id) {
            await this.updateDocument(this.modal.params.id, body);
        } else {
            await this.createDocument(body);
        }
        this.spinnerService.hide();
    }

    /**
     * Close modal
     */
    public close(): void {
        this.modal.response.emit();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
