import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ShipmentService} from "../../../../../../../../common/services/shipment.service";
import {SpinnerService} from "../../../../../../../../common/services/spinner.service";
import {ToastService} from "../../../../../../../../common/services/toast.service";
import {Api} from "../../../../../../../../common/services/api.service";
import {Modal} from "../../../../../../../services/modal.service";

@Component({
    selector: "section-shipment-edit-eta",
    template: `
        <h1 class="container-heading">Update ETA</h1>
        <mat-card>
            <mat-card-content>
                <form [formGroup]="form" (submit)="submit()">
                    <div class="group flex">
                        <div class="half">
                            <common-form-date-time
                                    [required]="true"
                                    label="Date"
                                    [min_date]="min_date"
                                    (valueChange)="form.get('estimated_delivery_date').setValue($event)">
                            </common-form-date-time>
                        </div>

                        <div class="half">
                            <mat-checkbox formControlName="inform_client">
                                Inform cilent
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="actions">
                        <button mat-raised-button color="primary"
                                type="submit" class="main" [disabled]="!form.valid">
                            Update
                        </button>

                        <button mat-button type="button" (click)="modal.response.emit(null)">
                            Cancel
                        </button>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditShipmentEtaComponent {

    public modal: Modal.IModal;

    public form: FormGroup = new FormGroup({
        estimated_delivery_date: new FormControl(null, [Validators.required]),
        inform_client: new FormControl(false)
    });

    public min_date: string = new Date().toDateString();

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private spinnerService: SpinnerService,
        private shipmentService: ShipmentService,
        private toastService: ToastService
    ) {
    }

    public async submit(): Promise<any> {
        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.shipmentService
            .update(this.modal.params.shipment.id, this.form.value);

        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.modal.response.emit({
                name: "success",
                value: true
            });
        }
    }
}
