import {Directive, ElementRef, Input} from "@angular/core";


@Directive({
    selector: "[hidden]"
})
export class HiddenDirective {
    private _context: boolean = false;

    @Input()
    public set hidden(condition: any) {
        this._context = condition as boolean;
        this._updateView();
    }

    public constructor(private el: ElementRef) {
    }

    private _updateView(): void {
        if (this._context) {
            this.el.nativeElement.style.display = "none";
        } else {
            this.el.nativeElement.style.display = null;
        }
    }
}
