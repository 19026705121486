import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {FormControl} from "@angular/forms";
import {Base} from "../../../../../../../../common/interfaces/base.interfaces";
import {ConfirmComponent} from "../../../../../../../../common/components/confirm/confirm.component";
import {Warehouse} from "../../../../../../../../common/interfaces/warehouse.interface";
import {IPagination} from "../../../../../../../../common/components/pagination/pagination.component";
import {Api, ApiService} from "../../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../../common/services/toast.service";
import {debounceTime, takeUntil} from "rxjs/operators";
import {SpinnerService} from "../../../../../../../../common/services/spinner.service";
import {UserService} from "../../../../../../../../common/services/user.service";
import {Api3Service} from "../../../../../../../../common/services/api3.service";

@Component({
    selector: "section-warehouse-order-wizard-shuffle-items",
    template: `
        <div class="text-right margin-bottom-20">
            <mat-form-field>
                <mat-label>Search transactions</mat-label>
                <input type="search" matInput [formControl]="search">
            </mat-form-field>
        </div>

        <div *ngIf="transactions" class="padding-top-20">

            <div class="columns">
                <mat-card class="margin-bottom-10 mat-elevation-z3"
                          *ngFor="let transaction of transactions.data">
                    <div>
                        <div class="pull-right">
                            <button type="button"
                                    [disabled]="(''+transaction.warehouse_package_id) === (''+warehouse_package_id)"
                                    mat-raised-button
                                    color="accent"
                                    (click)="linkTransaction(transaction)">
                                Link
                            </button>
                        </div>
                        <div>Item: <b>{{ transaction.item }}</b></div>
                        <div>Serial: <b>{{ transaction.serial }}</b></div>
                        <div>Type: <b>{{ transaction.type }}</b></div>
                        <div *ngIf="transaction.inventory_conversion">
                            Hub: <b>{{ transaction.inventory_conversion.customers_inventory_name }}
                            ({{ transaction.inventory_conversion.customers_sub_inventory }})</b>
                        </div>
                        <div>Quantity: <b>{{ transaction.quantity }}</b></div>
                        <div>Package:
                            <b *ngIf="transaction.warehouse_package">{{ transaction.warehouse_package.name }}</b>
                            <b *ngIf="!transaction.warehouse_package" class="text-error">NOT LINKED</b>
                        </div>
                    </div>
                </mat-card>
            </div>
            <div>
                <common-pagination [data]="transactions" pid="m44Y0Lzu"
                                   (goToPage)="getTransactions(search.value, $event.page, $event.per_page)">
                </common-pagination>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class WarehouseProceduresWizardShuffleItemsComponent implements OnInit, OnDestroy {

    protected destroy$: EventEmitter<boolean> = new EventEmitter(false);

    private page: number = 1;
    private per_page: number = 10;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @Input()
    public shipment_id: number;

    @Input()
    public state: Base.IState;

    public transactions: IPagination<Warehouse.ITransaction>;

    @Input()
    public warehouse_package_id: number;

    public search: FormControl = new FormControl(null);

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private spinnerService: SpinnerService,
        private userService: UserService
    ) {
    }

    public async getTransactions(search_by: string = null, page: number = 1, per_page: number = null): Promise<any> {
        this.spinnerService.show();
        if (per_page === null) {
            per_page = this.userService.data.settings.default_per_page;
        }
        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `${this.state.section}/shipments/${this.shipment_id}/transactions`, {}, {
                data_structure: "paginated",
                page,
                per_page,
                search_by,
                search_in: ["item", "serial"],
                relations: [
                    "InventoryConversion",
                    "Box",
                    "WarehouseLocation",
                    "NotSkippedValidationErrors:id,warehouse_transaction_id,validation_errors",
                    "WarehousePackage:id,name"
                ]
            });
        this.spinnerService.hide();
        if (data) {
            this.page = page;
            this.per_page = per_page;
            this.transactions = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    public async linkTransaction(transaction: Warehouse.ITransaction): Promise<any> {
        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["transaction", "" + transaction.id], {
                inventory_conversion_id: transaction.inventory_conversion_id,
                order_id: transaction.order_id,
                type: transaction.type,
                part_master_id: transaction.part_master_id,
                quantity: transaction.quantity,
                warehouse_package_id: this.warehouse_package_id
            });
        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.getTransactions(this.search.value, this.page, this.per_page);
        }
    }

    public ngOnInit(): void {

        this.getTransactions();

        this.search.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(1000))
            .subscribe((value: string): void => {
                this.getTransactions(value);
            });
    }


    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
