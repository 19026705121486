import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewEncapsulation} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";

@Component({
    selector: "section-remark-type-form",
    templateUrl: "form.component.html",
    styleUrls: [
        "form.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class RemarkTypeFormComponent implements Base.IComponent {

    public readonly state: Base.IState;

    public formGroup: FormGroup = new FormGroup({
        name: new FormControl(null, [Validators.required])
    });

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService
    ) {
    }

    public async submitForm(): Promise<any> {
        this.apiService.setHeaders({partner: "admin"});
        const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["remark", "types"], this.formGroup.value);
        if (message) {
            this.toastService.show(message, type);
            if (type === "success") {
                this.router.navigate(["admin", "remark-type"]);
            }
        }
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "remark-type",
            actions: {
                "add": ["add_order_remarks"]
            }
        };
    }

}
