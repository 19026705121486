import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    Output,
    ViewEncapsulation
} from "@angular/core";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {Warehouse} from "../../../../../../../common/interfaces/warehouse.interface";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {Modal, ModalService} from "../../../../../../services/modal.service";
import {PackageFormComponent} from "../../../../../threepl/warehouse/package";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {SoundsService} from "../../../../../../../common/services/sounds.service";
import {FormArray, FormControl, FormGroup} from "@angular/forms";
import {Order} from "../../../../../../../common/interfaces/order.interface";
import {Form} from "../../../../../../../common/interfaces/form.interface";
import {HelpersService} from "../../../../../../../common/services/helpers.service";
import {takeUntil} from "rxjs/operators";
import ISelectOption = Form.ISelectOption;

@Component({
    selector: "section-shippy-pickup-wizard-step2",
    templateUrl: "step2.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [
        "step2.component.scss"
    ],
    encapsulation: ViewEncapsulation.None
})
export class ShippyProPickupWizardStep2Component implements OnDestroy {

    private state: Base.IState;

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    @Output()
    public result: EventEmitter<any> = new EventEmitter();

    public packages: Warehouse.IPackage[];

    public data: {
        from_address: any,
        to_address: any,
        packages: number[],
        shipment_id: number,
        "customs_info"?: {
            "Description": string,
            "Weight": number,
            "Quantity": number,
            "UnitValue": number,
            "OriginCountry": string,
            "Currency": string,
            "HSCode": string
        }[]

    };

    public shipment: Warehouse.IShipment;

    public selected_packages: number[] = [];

    public needs_customs_info = false;

    public customs_form: FormGroup = new FormGroup({
        customs_items: new FormArray([])
    });

    public countries: ISelectOption[] = [];

    public get customs_items(): FormArray {
        return this.customs_form.controls["customs_items"] as FormArray<FormGroup>;
    }

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private modalService: ModalService,
        private soundsService: SoundsService,
        private spinnerService: SpinnerService,
        private helpers: HelpersService,
    ) {
    }

    private async getPackages(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["warehouse_package", "shipment", "" + this.shipment.id]);
        this.spinnerService.hide();
        if (data) {
            this.packages = data;
            if (this.packages.length === 0) {
                this.newPackage();
                this.soundsService.textToSpeech("Please create package");
            }

            this.selected_packages = [];
            this.result.next(null);
        }
    }

    private async getCountries(): Promise<any> {
        this.spinnerService.show();
        this.countries = await this.helpers.prepareCountriesList();
        this.spinnerService.hide();
    }

    private setCustomsData(): void {

        if (this.customs_items.length) {
            return;
        }
        // this.customs_items.clear();

        const ids: number[] = [];

        this.shipment.order_items.map((item: Order.IItem) => {
            if (ids.includes(item.part_master_id)) {
                return;
            }
            ids.push(item.part_master_id);

            const qty_sum: number = this.shipment.order_items.reduce((accum, itm: Order.IItem) => {
                return accum + (itm.part_master_id === item.part_master_id ? itm.quantity : 0);
            }, 0);

            this.customs_items.push(new FormGroup({
                Description: new FormControl(item.part_master.description),
                Weight: new FormControl(item.part_master.weight),
                Quantity: new FormControl(qty_sum),
                UnitValue: new FormControl(item.part_master.customs_value),
                OriginCountry: new FormControl(item.part_master.country),
                Currency: new FormControl(item.part_master?.customs_values_currency?.toUpperCase() || "USD"),
                HSCode: new FormControl(item.part_master.HSC)
            }));
        });
    }

    public deleteItem(index: number): void {
        this.customs_items.removeAt(index);
    }

    public addItem(): void {
        this.customs_items.push(new FormGroup({
            Description: new FormControl(null),
            Weight: new FormControl(null),
            Quantity: new FormControl(1),
            UnitValue: new FormControl(null),
            OriginCountry: new FormControl(null),
            Currency: new FormControl("USD"),
            HSCode: new FormControl(null)
        }));

        this.changeDetectorRef.markForCheck();
    }

    public async newPackage(): Promise<any> {

        let pack: any = null;
        if (this.shipment.order_items.length === 1) {
            pack = {};

            pack.length = this.shipment.order_items[0].part_master.length;
            pack.width = this.shipment.order_items[0].part_master.width;
            pack.height = this.shipment.order_items[0].part_master.height;
            pack.weight = this.shipment.order_items[0].part_master.weight;
        }

        const response: Modal.IResponse = await this.modalService.open(PackageFormComponent,
            {
                package: pack,
                shipmentId: this.shipment.id,
                shipment: this.shipment,
                warehouse: this.shipment.warehouse,
                require_dimensions: true,
                warehouse_id: this.shipment.warehouse?.id,
                warehouse_slug: this.shipment.warehouse_slug
            });

        if (response) {
            this.getPackages();
        }
    }

    public async editPackage(pack: any): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(PackageFormComponent, {
            package: pack,
            shipment: this.shipment,
            shipmentId: this.shipment.id,
            warehouse: this.shipment.warehouse,
            warehouse_id: this.shipment.warehouse?.id,
            warehouse_slug: this.shipment.warehouse_slug
        });

        if (response) {
            this.getPackages();
        }
    }

    public onCheckChange(event: MatCheckboxChange): void {
        const value: number = Number(event.source.value);
        const index = this.selected_packages.indexOf(value);

        if (event.checked && index === -1) {
            this.selected_packages.push(value);
        } else if (!event.checked && index > -1) {
            this.selected_packages.splice(index, 1);
        }

        if (this.selected_packages.length) {
            this.data.packages = this.selected_packages;
            this.data.customs_info = this.customs_items.value;
            this.result.next(this.data);
        } else {
            this.result.next(null);
        }
    }


    /**
     * Initialize step
     * @returns {Promise<any>}
     * @param state
     * @param data
     */
    public async init(state: Base.IState, data: {
        from_address: any,
        to_address: any,
        shipment: Warehouse.IShipment
    }): Promise<any> {
        this.state = state;
        this.shipment = data.shipment;

        this.data = {
            from_address: data.from_address,
            to_address: data.to_address,
            packages: [],
            shipment_id: data.shipment.id
        };

        this.getPackages();

        this.needs_customs_info = data.from_address.country !== data.to_address.country
            && (!data.from_address.in_eu || !data.to_address.in_eu);

        if (this.needs_customs_info) {
            this.getCountries();
            this.setCustomsData();

            this.customs_form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
                this.data.customs_info = value.customs_items;

                if (this.selected_packages.length) {
                    this.data.packages = this.selected_packages;
                    this.result.next(this.data);
                } else {
                    this.result.next(null);
                }
            });
        }
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }


}
