<common-confirm></common-confirm>

<h1 class="container-heading">Inventory condition</h1>

<mat-card>
    <mat-card-content>
        <form *ngIf="formGroup" [formGroup]="formGroup" (submit)="submit()" autocomplete="off" novalidate>

            <div class="group flex">
                <div class="full">
                    <common-form-search label="Select item"
                                        [required]="true"
                                        url="partmaster"
                                        [query]="{'no_select_description':true}"
                                        [multiple]="false"
                                        [value]="formGroup.value.part_master_id"
                                        (valueText)="itemName = $event"
                                        (valueChange)="formGroup.get('part_master_id').setValue($event)">
                    </common-form-search>
                </div>

                <div class="half">
                    <common-form-hub-select
                            label="Hub to check"
                            [required]="true"
                            [hideSingles]="true"
                            [value]="formGroup.value.hub_from_id"
                            (onGroupSelected)="targetHubs = $event"
                            (onValueName)="fromHubName = $event"
                            (onChange)="formGroup.get('hub_from_id').setValue($event)"></common-form-hub-select>
                </div>
                <div class="half" *ngIf="targetHubs && targetHubs.length">
                    <common-form-hub-select
                            label="Hub to move"
                            [options_grouped]="targetHubs"
                            [required]="true"
                            [value]="formGroup.value.hub_to_id"
                            (onValueName)="toHubName = $event"
                            (onChange)="formGroup.get('hub_to_id').setValue($event)"></common-form-hub-select>
                </div>

                <div class="full"></div>

                <mat-form-field class="half">
                    <mat-label>Rev</mat-label>
                    <input matInput formControlName="rev">
                </mat-form-field>

                <mat-form-field class="half">
                    <mat-label>Image version</mat-label>
<input matInput formControlName="image_version" >
                </mat-form-field>

                <mat-form-field class="full">
                    <mat-label>Remark</mat-label>
                    <textarea matInput formControlName="remark" rows="5" required></textarea>
                </mat-form-field>

                <div class="half">
                    <mat-checkbox formControlName="is_active">Is active</mat-checkbox>
                </div>

                <div class="half">
                    <mat-checkbox formControlName="deactivate_after_process">Deactivate after process</mat-checkbox>
                </div>


            </div>

            <div class="actions">
                <button mat-raised-button color="primary" type="submit" class="main">
                    Submit
                </button>

                <ng-container *ngIf="modal.params.id">
                    <button mat-raised-button *userAllowed="'delete_inventory_conditions'"
                            [disabled]="!formGroup.valid"
                            type="button" color="warn" (click)="delete()">
                        Delete
                    </button>
                </ng-container>

                <button mat-button type="button" (click)="close()">Cancel</button>
            </div>

        </form>

    </mat-card-content>
</mat-card>
