import {Injectable} from "@angular/core";
import {User} from "../interfaces/user.interface";
import {Api, ApiService} from "./api.service";


@Injectable()
export class PartnerService {

    private static _partner: User.IPartner;

    public constructor(private apiService: ApiService) {
    }

    public async refresh(): Promise<any> {
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, [
            "partner",
            "" + PartnerService.partner.id
        ]);
        PartnerService.partner = data;
        return data;
    }

    public static get partner(): User.IPartner {
        return this._partner;
    }

    public static set partner(value: User.IPartner) {
        this._partner = value;
    }


}
