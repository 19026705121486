import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {Modal, ModalService} from "../../../../../../services/modal.service";
import {takeUntil} from "rxjs";
import {StorageService} from "../../../../../../../common/services/storage.service";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {UserService} from "../../../../../../../common/services/user.service";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {FileUploadComponent} from "../../../../../../../common/components/file-upload/file-upload.component";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {AppStateService} from "../../../../../../../common/services/app-state.service";
import {Form} from "../../../../../../../common/interfaces/form.interface";
import ISelectOption = Form.ISelectOption;
import { Api3Service } from "src/modules/common/services/api3.service";


@Component({
    selector: "section-it-request-form",
    templateUrl: "form.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class ItRequestFormComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    @Input()
    public data: any;

    @Output()
    public onSubmit: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public onClose: EventEmitter<null> = new EventEmitter<null>();

    @Output()
    public onMinimize: EventEmitter<null> = new EventEmitter<null>();

    /**
     * Form group / group with controls
     * @type {FormGroup}
     */
    public formGroup: FormGroup = new FormGroup({
        title: new FormControl(null, [Validators.required]),
        request_kind_id: new FormControl(null, [Validators.required]),
        priority: new FormControl("Normal", [Validators.required]),
        status: new FormControl("New"),
        url: new FormControl(window.location.pathname),
        order_id: new FormControl(null),
        description: new FormControl(null, [Validators.required]),
        expectedBehaviour: new FormControl(null),
        participants: new FormControl([])
    });

    public descriptionValid: boolean = true;

    public state: Base.IState;

    public showOrderLink: boolean = false;

    public uploadedFile: { name: string, url: string }[] = [];

    public kinds: ISelectOption[] = [];

    public participants: ISelectOption[] = [];

    public selectParticipantSearch: FormControl = new FormControl(null);

    public participantsFiltered: ISelectOption[] = [];

    public editorInitialValue: string = null;

    public expectedBehaviourValue: string = null;

    public showExpectedBehaviour: boolean = false;

    public currentUser: any = null;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private modalService: ModalService,
        private storageService: StorageService,
        private userService: UserService,
        private router: Router,
        private http: HttpClient,
        private spinnerService: SpinnerService
    ) {
        this.currentUser = this.userService.data;
        this.formGroup.controls["participants"].setValue([this.currentUser.id]);
    }

    /**
     * Prepare form data
     */
    private prepareData(): void {

        if (this.data) {
            if (this.data.uploadedFile.length) {
                this.uploadedFile = this.data.uploadedFile;
            }
            this.editorInitialValue = this.data.description;

        }

        if (this.state.component === "orders" && this.state.action === "view") {
            this.showOrderLink = true;
            this.formGroup.get("order_id").setValue(this.state.params.id);
        }

        if (this.storageService.get("requestIt")) {
            this.formGroup.patchValue(this.storageService.get("requestIt"));
            this.editorInitialValue = this.formGroup.value.description;
            this.expectedBehaviourValue = this.formGroup.value.expectedBehaviour;
        }

        if (this.storageService.get("requestItFiles")) {
            this.uploadedFile = this.storageService.get("requestItFiles");
        }

        if (this.storageService.get("showExpectedBehaviour")) {
            this.showExpectedBehaviour = this.storageService.get("showExpectedBehaviour");
        }

        this.changeDetectorRef.markForCheck();
    }

    /**
     * Get list of kinds
     * @returns {Promise<any>}
     */
    private async getKinds(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["request", "kind"], {}, {
                category: "it",
                data_structure: "select",
            });
        if (data) {
            this.kinds = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private async getPaticipants(): Promise<any> {
        this.spinnerService.show();
        const [type, slug]: string[] = this.state.section.split("/", 2);
        let path: string[] = [type, "users"];
        if (type === "admin") {
            path = ["user", "list"];
        }
        this.participants = [];
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            path, {}, {
                data_structure: "select",
            });
        if (data) {
            this.participants.push(...data.filter(item => item.value != this.currentUser.id));
        }
        this.participantsFiltered = this.participants;
        this.changeDetectorRef.markForCheck();
        this.spinnerService.hide();
    }

    public async uploadFile(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(FileUploadComponent, {
            url: ["user", "file"],
            accept: [
                "gif", "png", "jpg", "jpeg", "tiff", "doc",
                "docx", "xls", "xlsx", "csv", "ppt", "pptx", "msg", "txt", "pdf", "xlsb"
            ]
        });
        if (response && response.value && response.value.data && response.value.data.path) {
            const uploaded: any = {name: response.value.data.name, url: response.value.data.path};
            this.uploadedFile.push(uploaded);
            this.changeDetectorRef.markForCheck();
        }
    }

    public deleteFile(index: number): void {
        this.uploadedFile.splice(index, 1);
    }

    public validateForm(): boolean {
        return this.formGroup.valid && !!this.formGroup.value.description && this.descriptionValid
            && ((this.showExpectedBehaviour && !!this.formGroup.value.expectedBehaviour)
                || !this.showExpectedBehaviour);
    }


    /**
     * Submit form
     */
    public async submit(): Promise<any> {
        this.spinnerService.show();

        const body: any = {...this.formGroup.getRawValue()};

        body.files = this.uploadedFile;

        body.type = "it";

        body.description =
            (this.formGroup.value.expectedBehaviour ? "Expected behavior: " + this.formGroup.value.expectedBehaviour +
                "<br><br>Description: " : "") + this.formGroup.value.description;

        delete body.expectedBehaviour;

        const {code, message, data}: Api.IResponse = await this.apiService.request(Api.EMethod.Post, ["request"], body);

        if (code === 200) {
            this.toastService.show(message, "success");
            this.onSubmit.emit(data);
        }
        this.spinnerService.hide();

        this.storageService.remove("requestIt");
        this.storageService.remove("requestItFiles");
        this.storageService.remove("showExpectedBehaviour");
    }

    public close(): void {
        this.onClose.emit();
    }

    public minimize(): void {
        this.storageService.set("isRequestMinimized", true);
        this.onMinimize.emit();
    }

    public ngOnInit(): void {
        this.state = AppStateService.getState();
        this.prepareData();
        this.getKinds();
        this.getPaticipants();

        this.formGroup.get("request_kind_id").valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value: number): void => {
                const kind: ISelectOption = this.kinds.find((k: ISelectOption) => k.value === value);
                if (["Bug", "Support"].includes(kind.name)) {
                    this.showExpectedBehaviour = true;
                } else {
                    this.showExpectedBehaviour = false;
                    this.expectedBehaviourValue = "";
                }
                this.changeDetectorRef.markForCheck();
            });

        this.selectParticipantSearch.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value: string): void => {
                if (value) {
                    this.participantsFiltered =
                        this.participants.filter((user: { name: string }): boolean =>
                            user.name.toLowerCase().indexOf(value) > -1);
                } else {
                    this.participantsFiltered = this.participants;
                }
            });
    }

    public ngOnDestroy(): void {
        this.storageService.set("requestIt", this.formGroup.value);
        this.storageService.set("requestItFiles", this.uploadedFile);
        this.storageService.set("showExpectedBehaviour", this.showExpectedBehaviour);
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
