import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Table2Component} from "../../../../../../common/components/table2/table.component";


@Component({
    selector: "section-custom-labels-list",
    template: `
        <h1 class="container-heading">Custom labels</h1>
        <mat-card>
            <mat-card-content>
                <common-table2 *ngIf="tableSettings" [settings]="tableSettings">
                    <div row1>
                        <button color="accent" mat-raised-button (click)="add()">
                            Add
                        </button>
                    </div>
                </common-table2>
            </mat-card-content>
        </mat-card>
        <common-confirm></common-confirm>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class CustomLabelsListComponent implements OnInit, Base.IComponent, OnDestroy {

    protected destroy$: EventEmitter<boolean> = new EventEmitter(false);

    public readonly state: Base.IState;

    public tableSettings: Table.ISettings;

    @ViewChild(ConfirmComponent, {static: true})
    public confirm: ConfirmComponent;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;


    public constructor(
        private router: Router,
        private apiService: ApiService,
        private spinnerService: SpinnerService,
        private toastService: ToastService
    ) {
    }

    private prepareTable(): void {
        this.tableSettings = {
            table_id: "sdf9ijkmndrt7is",
            api: {
                url: ["custom_labels"]
            },
            actions: [
                {
                    name: "view",
                    title: "View",
                    click: (row: any): void => {
                        this.router.navigate([
                            this.state.section,
                            this.state.component,
                            "view",
                            "id",
                            row.id
                        ]);
                    }
                },
                {
                    name: "delete",
                    title: "Delete",
                    click: async (row: any): Promise<any> => {
                        if (!await this.confirm
                            .confirm("Are you sure want to delete this label? This action cannot be undone!")) {
                            return;
                        }
                        this.spinnerService.show();
                        const {message, code}: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
                            ["custom_labels", "" + row.id]);

                        this.spinnerService.hide();
                        if (code === 200) {
                            this.toastService.show(message, "success");
                            this.tableRef.reload();
                        }
                    }
                }
            ],
            columns: [
                {
                    data: "name",
                    title: "Name",
                },
                {
                    data: "size.width",
                    name: "Size.width",
                    title: "Width, mm",
                    searchable: false
                },
                {
                    data: "size.height",
                    name: "Size.height",
                    title: "Height, mm",
                    searchable: false
                },
                {
                    data: "created_at",
                    title: "Created at",
                    searchable: false
                }
            ]
        };
    }

    public add(): void {
        this.router.navigate([
            this.state.section,
            this.state.component,
            "add"
        ]);
    }

    public ngOnInit(): void {
        this.prepareTable();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "custom-labels",
            actions: {
                "browse": "*"
            }
        };
    }
}
