import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnChanges,
    SimpleChanges,
    Type,
    ViewEncapsulation
} from "@angular/core";
import {ApiService} from "../../../../../../../../common/services/api.service";
import {Modal, ModalService} from "../../../../../../../services/modal.service";
import {OrderWizardItemTransferComponent} from "../../../index";
import {ToastService} from "../../../../../../../../common/services/toast.service";
import {StorageService} from "../../../../../../../../common/services/storage.service";
import {OrderWizardItemsComponent} from "../items";
import {PusherService} from "../../../../../../../../common/services/pusher.service";
import {SpinnerService} from "../../../../../../../../common/services/spinner.service";
import {PartnerPartMasterService} from "../../../../../services/part-master.service";
import {Wizard} from "../../../../../../../../common/interfaces/wizard.interface";
import {Api3Service} from "../../../../../../../../common/services/api3.service";

@Component({
    selector: "section-order-wizard-items-transfer",
    templateUrl: "../items.component.html",
    styleUrls: [
        "../items.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class OrderWizardItemsTransferComponent extends OrderWizardItemsComponent implements OnChanges {

    protected itemComponent: Type<any> = OrderWizardItemTransferComponent;

    public constructor(
        protected apiService: ApiService,
        protected api3Service: Api3Service,
        protected modalService: ModalService,
        protected changeDetectorRef: ChangeDetectorRef,
        protected toastService: ToastService,
        protected storageService: StorageService,
        protected pusherService: PusherService,
        protected partMasterService: PartnerPartMasterService,
        protected spinnerService: SpinnerService
    ) {
        super(apiService, api3Service, modalService, changeDetectorRef, toastService,
            storageService, pusherService, partMasterService, spinnerService);
    }

    protected setup(data: Wizard.IData): void {
        super.setup(data);

        this.prepareOutboundTable();
        this.prepareInboundTable();
    }

    protected async prepareInboundTable(): Promise<any> {
        const c = [...this.columns];
        c.splice(3, 1);

        this.inboundTable = {
            table_id: "newOrderInboundItemsList",
            columns: c,
            actions: [
                {
                    name: "delete",
                    title: "Delete",
                    click: (row: any): void => {
                        this.removeItem(row).then(() => {
                            this.inboundTableRef.reload();
                        });
                    }
                }
            ],
            api: this.getUrl("inbound")
        };
    }

    /**
     * Add new item
     * @returns {void}
     */
    public async addItem(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(OrderWizardItemTransferComponent, {
            partmasters: this.partmasters,
            details: this.details,
            order_id: this.data.order_id,
            serviceLevel: this.serviceLevel,
            state: this.state,
            modalWidth: "95%",
            modalHeight: "90%",
            hubs_from: this.data.hubs_from,
            hubs_to: this.data.hubs_to,

        });
        if (response && response.value) {

            this.outboundTableRef.reload(this.getUrl("outbound"));
            this.inboundTableRef.reload(this.getUrl("inbound"));

            this._skipAllocations = false;
            this.changeDetectorRef.markForCheck();
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);
    }
}
