import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild} from "@angular/core";
import {Router} from "@angular/router";
import {ConfirmComponent} from "../../../../../common/components/confirm/confirm.component";
import {Api, ApiService} from "../../../../../common/services/api.service";
import {ToastService} from "../../../../../common/services/toast.service";
import {UntypedFormBuilder, FormGroup, Validators} from "@angular/forms";
import {SpinnerService} from "../../../../../common/services/spinner.service";
import {Api3Service} from "../../../../../common/services/api3.service";
import {Base} from "../../../../../common/interfaces/base.interfaces";
import {Form} from "../../../../../common/interfaces/form.interface";
import ISelectOption = Form.ISelectOption;

@Component({
    selector: "section-warehouse-contacts-list",
    templateUrl: "contacts.component.html",
    styles: [
        "h3{margin-top:30px}"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WarehouseContactsListComponent implements OnInit {

    @Input()
    public state: Base.IState;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    public newForm: FormGroup;

    public items: any[] = [];

    public service_levels: ISelectOption[] = [];

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private formBuilder: UntypedFormBuilder,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private spinnerService: SpinnerService
    ) {
    }

    /**
     * Service level form "constructor"
     * @returns {FormGroup}
     * @param opts
     */
    private item(opts: {
        id: number,
        first_name: string,
        last_name: string,
        email: string,
        phone: string,
        fax: string,
        service_level_ids: number[],
        role: string
    }): FormGroup {
        return this.formBuilder.group({
            id: [opts.id],
            first_name: [opts.first_name, [Validators.required]],
            last_name: [opts.last_name, [Validators.required]],
            email: [opts.email, [Validators.required, Validators.email]],
            phone: [opts.phone],
            fax: [opts.fax],
            service_level_ids: [opts.service_level_ids],
            role: [opts.role],
        });
    }

    /**
     * Add service level to array
     * @param opts
     */
    private addItem(opts: {
        id: number,
        first_name: string,
        last_name: string,
        email: string,
        phone: string,
        fax: string,
        service_level_ids: number[],
        role: string
    }): void {
        this.items.push(this.item(opts));
    }

    private async getItems(): Promise<any> {
        const {data}: Api.IResponse = await this.api3Service.get(`${this.state.section}/contacts`, {
            relations: [
                "ServiceLevels:id"
            ]
        });

        this.items = [];
        if (data) {
            for (const item of data) {
                this.addItem({
                    id: item.id,
                    first_name: item.first_name,
                    last_name: item.last_name,
                    email: item.email,
                    phone: item.phone,
                    fax: item.fax,
                    service_level_ids: item.service_levels.map((sl: { id: number }) => sl.id),
                    role: item.pivot.role
                });
            }
            this.changeDetectorRef.markForCheck();
        }
    }

    private async getPartners(): Promise<any> {

        const {data}: Api.IResponse = await this.api3Service.get(`${this.state.section}/partners`, {
            relations: [
                "ServiceLevels:id,name,partner_id"
            ]
        });
        this.service_levels = [];
        if (data) {
            for (const partner of data) {
                for (const service of partner.service_levels) {
                    this.service_levels.push({
                        value: service.id,
                        name: `${partner.display_name} - ${service.name}`
                    });
                }
            }

            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Prepare form to add new service level
     */
    private prepareForm(): void {
        this.newForm = this.formBuilder.group({
            id: [null],
            first_name: [null, [Validators.required]],
            last_name: [null, [Validators.required]],
            email: [null, [Validators.required, Validators.email]],
            phone: [null],
            fax: [null],
            service_level_ids: [[]],
            role: [null]
        });
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Submit new contact
     * @returns {Promise<any>}
     */
    public async submitAdd(): Promise<any> {
        this.spinnerService.show();

        const response: Api.IResponse = await this.api3Service.post(
            `${this.state.section}/contacts`, {...this.newForm.value});

        if (response.code === 200) {

            this.toastService.show(response.message, "success");

            this.addItem({
                ...this.newForm.value,
                id: response.data.id,
            });

            this.newForm.reset();
            this.changeDetectorRef.markForCheck();
        }

        this.spinnerService.hide();
    }

    /**
     * Update existing contact
     * @param {number} index
     * @returns {Promise<any>}
     */
    public async submitUpdate(index: number): Promise<any> {
        this.spinnerService.show();
        const id: number = this.items[index].value.id;

        const {code, message}: Api.IResponse = await this.api3Service.put(
            `${this.state.section}/contacts/${id}`, {...this.items[index].value});

        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Delete contact
     * @param {number} index
     * @returns {Promise<any>}
     */
    public async submitDelete(index: number): Promise<any> {
        if (!await this.confirmRef.confirm("Are you sure want to delete this contact?")) {
            return;
        }
        this.spinnerService.show();
        const id: number = this.items[index].value.id;

        const {code, message}: Api.IResponse = await this.api3Service.delete(`${this.state.section}/contacts/${id}`);

        this.spinnerService.hide();

        if (code === 200) {
            this.toastService.show(message, "success");
            this.items.splice(index, 1);
            this.changeDetectorRef.markForCheck();
        }
    }


    public ngOnInit(): void {
        this.getPartners();
        this.prepareForm();
        this.getItems();
    }

}
