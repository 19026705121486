import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Router} from "@angular/router";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Order} from "../../../../../../common/interfaces/order.interface";
import {Warehouse} from "../../../../../../common/interfaces/warehouse.interface";
import {IPagination} from "../../../../../../common/components/pagination/pagination.component";
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {debounceTime, distinctUntilChanged, takeUntil} from "rxjs/operators";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {SectionTransactionSplitModalComponent} from "../../../../common/components/transactions";
import {UserService} from "../../../../../../common/services/user.service";
import {WarehouseTransactionFormComponent} from "../../../../threepl/warehouse/order";
import {Api3Service} from "../../../../../../common/services/api3.service";

@Component({
    selector: "section-adjust-transactions-view-no-order",
    templateUrl: "view.component.html",
    styleUrls: [
        "view.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class AdjustTransactionsViewNoOrderComponent implements Base.IComponent, OnInit, OnDestroy {

    /**
     * Component destroy event emmiter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    /**
     * Order data
     * @type {Order.IOrderData}
     */
    public orderData: Order.IOrderData;

    @Input()
    public orderRef: string;

    @Input()
    public state: Base.IState;

    public transactions: IPagination<Warehouse.ITransaction>;


    @ViewChild(MatAutocompleteTrigger, {static: false})
    public autocompleteTrigger: MatAutocompleteTrigger;

    public orders: Order.IOrderData[] = [];
    public orderItems: Order.IItem[] = [];

    public orderItemSelected: FormControl =
        new FormControl(null, [Validators.required]);

    public searchByItem: boolean = false;

    public searchForm: FormGroup = new FormGroup({
        order: new FormControl(null),
        item: new FormControl(null),
        status: new FormControl(null),
    });

    public item: string = null;

    public transactionSearch: FormControl = new FormControl(null);

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private modalService: ModalService,
        private spinnerService: SpinnerService,
        private userService: UserService
    ) {
    }

    private async getOrders(search: string): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["order"], {}, {
                search_by: search.trim(),
                limit: 5
            });

        if (data) {
            this.orders = data;
            this.changeDetectorRef.markForCheck();
            this.autocompleteTrigger.openPanel();
        }
        this.spinnerService.hide();
    }

    /**
     * Search orders by item in specific status
     */
    private async getOrdersByItem(item: string, item_status_id: number): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["order"], {}, {
                item: item.trim(),
                item_status_id,
                limit: 50
            });

        if (data) {
            this.orders = data;
            this.changeDetectorRef.markForCheck();
            this.autocompleteTrigger.openPanel();
        }
        this.spinnerService.hide();
    }


    /**
     * Get items for selected order
     */
    private async getOrderItems(orderRef: string): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["order", orderRef, "items", this.state.params.type], {}, {
                item: this.item
            });

        if (data) {
            this.orderItems = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Redirect to order on dropdown selected option
     * @param {MatAutocompleteSelectedEvent} $event
     */
    public onOptionSelected($event: MatAutocompleteSelectedEvent): void {
        this.searchForm.reset({status: this.searchForm.value.status});
        this.orders = [];
        this.orderItems = [];
        this.changeDetectorRef.markForCheck();
        this.orderRef = $event.option.value;
        this.getOrderItems($event.option.value);
    }

    public async delete(transaction: Warehouse.ITransaction): Promise<any> {
        if (!await this.confirmRef.confirm("Action cannot be reverted!", "Delete transaction?")) {
            return;
        }
        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.api3Service.delete(
            `${this.state.section}/warehouse-transactions/invalid`, {
                "transactions": [transaction.id]
            });
        this.spinnerService.hide();

        if (code === 200) {
            this.toastService.show(message, "success");
            this.getTransactions();
        }
    }

    public async getTransactions(page: number = 1, per_page: number = null, search_by: string = null): Promise<any> {
        this.spinnerService.show();
        if (per_page === null) {
            per_page = this.userService.data.settings.default_per_page;
        }
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["transaction", "no-order"], {}, {
                data_structure: "paginated",
                page,
                per_page,
                search_by,
                ref: this.state.params.ref ? this.state.params.ref : null,
                id: this.state.params.ref ? null : this.state.params.id,
                type: this.state.params.type,
                relations: [
                    "InventoryConversion",
                    "Partner",
                    "WarehouseLocation",
                    "NotSkippedValidationErrors"
                ]
            });
        this.spinnerService.hide();

        if (data) {
            this.transactions = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    public async linkTransaction(id: number = null): Promise<any> {
        this.spinnerService.show();

        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["transaction", "link_order"], {
                id,
                ref: this.state.params.ref,
                order_ref: this.orderRef,
                order_item_id: this.orderItemSelected.value
            });
        this.spinnerService.hide();
        if (data) {
            this.toastService.show(data.join(". "), "success");

            this.getTransactions();
        }
    }

    public async linkAllTransactions(): Promise<any> {
        if (await this.confirmRef.confirm(`Do you want to link all transactions to order ${this.orderRef}?`)) {
            this.linkTransaction();
        }
    }

    public splitTransaction(transaction: Warehouse.ITransaction): void {
        this.modalService.open(SectionTransactionSplitModalComponent, {
            transaction
        }).then((res: Modal.IResponse) => {
            if (res) {
                this.getTransactions();
            }
        });
    }

    public async editTransaction(transaction: Warehouse.ITransaction): Promise<any> {

        const response: Modal.IResponse = await this.modalService.open(WarehouseTransactionFormComponent, {
            action: "edit",
            order_id: transaction.order_id,
            type: transaction.type,
            modalWidth: 600,
            data: transaction,
            partner: transaction.partner,
            state: this.state,
            warehouse_slug: transaction.inventory_conversion?.warehouse_slug
        });

        if (response) {
            this.getTransactions();
        }
    }

    public ngOnInit(): void {
        if (this.state.params.ref === "_") {
            this.state.params.ref = null;
        }


        this.getTransactions();
        this.searchForm.get("order").valueChanges
            .pipe(takeUntil(this.destroy$), debounceTime(1500), distinctUntilChanged())
            .subscribe((value: string): void => {
                if (value) {
                    this.getOrders(value);
                }
            });

        this.searchForm.get("item").valueChanges
            .pipe(takeUntil(this.destroy$), debounceTime(1500), distinctUntilChanged())
            .subscribe((value: string): void => {
                this.item = value;
                if (value && this.searchForm.get("status").value) {
                    this.getOrdersByItem(this.item, this.searchForm.get("status").value);
                }
            });

        this.searchForm.get("status").valueChanges
            .pipe(takeUntil(this.destroy$), distinctUntilChanged())
            .subscribe((value: number): void => {
                if (this.searchForm.get("item").value) {
                    this.getOrdersByItem(this.item, value);
                }
            });

        this.transactionSearch.valueChanges
            .pipe(takeUntil(this.destroy$), debounceTime(1500), distinctUntilChanged())
            .subscribe((value: string): void => {
                this.getTransactions(1, 10, value);
            });
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "transactions-without-orders",
            actions: {
                "view": ["edit_warehouse_transactions"]
            }
        };
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
