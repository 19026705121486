import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Router} from "@angular/router";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {UserService} from "../../../../../../common/services/user.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Table2Component} from "../../../../../../common/components/table2/table.component";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {FormControl, FormGroup} from "@angular/forms";
import {Form} from "../../../../../../common/interfaces/form.interface";
import {WarehouseService} from "../../../../../../common/services/warehouse.service";
import {debounceTime, takeUntil} from "rxjs/operators";
import ISelectOption = Form.ISelectOption;

@Component({
    selector: "section-transfer-history-list",
    template: `
        <common-confirm></common-confirm>

        <h1 class="container-heading">Transfer history</h1>
        <mat-card>
            <mat-card-content>
                <common-table2 *ngIf="listTable" [settings]="listTable">
                    <div class="flex row align-center">
                        <div *ngIf="usersOptions">
                            <common-form-select
                                    label="Modified by"
                                    [options]="usersOptions"
                                    (onClose)="filter.get('user_id').setValue($event)"></common-form-select>
                        </div>
                        &nbsp;
                        &nbsp;
                        <div *ngIf="partnersOptions">
                            <common-form-select
                                    label="Partner"
                                    [options]="partnersOptions"
                                    (onClose)="filter.get('partner_id').setValue($event)"></common-form-select>
                        </div>
                        &nbsp;
                        &nbsp;
                        <div>
                            <common-form-select
                                    label="Type"
                                    [options]="typeOptions"
                                    (onClose)="filter.get('value_type').setValue($event)"></common-form-select>
                        </div>
                    </div>
                </common-table2>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class TransferHistoryListComponent implements Base.IComponent, OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    public readonly state: Base.IState;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    public listTable: Table.ISettings;

    public usersOptions: ISelectOption[];

    public partnersOptions: ISelectOption[];

    public typeOptions: ISelectOption[] = [
        {value: "App\\WarehouseLocation", name: "Location"},
        {value: "App\\InventoryConversion", name: "Hub"},
        {value: "App\\Inventory", name: "Quantity"},
    ];

    public filter: FormGroup = new FormGroup({
        value_type: new FormControl(null),
        partner_id: new FormControl(null),
        user_id: new FormControl(null),
    });

    public constructor(
        private router: Router,
        private apiService: ApiService,
        public userService: UserService,
        private warehouseService: WarehouseService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    private getUrl(): any {
        return {
            url: ["report", "transfer-history"],
            query: this.filter.value
        };
    }

    private async getUsers(): Promise<any> {
        const {data}: Api.IResponse = await this.warehouseService.getUsers({
            data_structure: "select"
        });
        if (data) {
            this.usersOptions = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    private async getPartners(): Promise<any> {
        const {data}: Api.IResponse = await this.warehouseService.getPartners({
            data_structure: "select"
        });
        if (data) {
            this.partnersOptions = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareTable(): void {

        this.listTable = {
            table_id: "fg57dffgs",
            actions: [],
            api: this.getUrl(),
            columns: [
                {
                    data: "partner.display_name",
                    name: "Partner.display_name",
                    title: "Partner",
                    searchable: false,
                    sortable: false,
                },
                {
                    data: "user.name",
                    name: "User.name",
                    title: "Modified by",
                    searchable: false,
                    sortable: false,
                },
                {
                    data: "subject_instance",
                    title: "Modification type",
                    searchable: false,
                    sortable: false,
                },
                {
                    data: "subject_item",
                    name: "subject_item",
                    title: "Item",
                    sortable: false,
                },
                {
                    data: "modified_instance",
                    title: "Value type",
                    searchable: false,
                    sortable: false,
                },
                {
                    data: "modified_item_old",
                    name: "modified_item_old",
                    title: "Previous value",
                    sortable: false,
                },
                {
                    data: "modified_item_new",
                    title: "New value",
                    sortable: false,
                },
                {
                    data: "created_at",
                    title: "Modified on",
                    searchable: false,
                    sortable: false,
                },
            ],
            export: {
                file_name: "Transfer history"
            }
        };

        this.changeDetectorRef.markForCheck();
    }


    public ngOnInit(): void {
        this.prepareTable();
        this.getUsers();
        this.getPartners();

        this.filter.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500)).subscribe((): void => {
            this.tableRef.reload(this.getUrl());
        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }


    public ngConfig(): Base.IConfig {
        return {
            name: "transfer-history-report",
            actions: {
                "browse": ["browse_reports"]
            }
        };
    }

}
