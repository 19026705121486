<mat-card>

    <h1>Select item to update</h1>

    <form [formGroup]="form" (submit)="submit()">
        <div class="group flex">
            <div class="half" *ngIf="part_masters">
                <common-form-select [options]="part_masters"
                                    [search]="true"
                                    [required]="true"
                                    label="Select item"
                                    [value]="form.value.part_master_id"
                                    (valueChange)="onPMSelected($event)"
                ></common-form-select>
            </div>

            <div class="half" *ngIf="form.value.part_master_id">
                <common-form-hub-select
                        label="Hub"
                        [required]="true"
                        line_type="outbound"
                        [part_master_in_stock]="true"
                        [value]="form.value.inventory_conversion_id"
                        [part_master_id]="form.value.part_master_id"
                        (onChange)="form.get('inventory_conversion_id').setValue($event)"></common-form-hub-select>
            </div>

            <mat-form-field class="half" *ngIf="!form.value.part_master_id">
                <mat-label>Hub</mat-label>
<input matInput  disabled required>
            </mat-form-field>


            <div class="full margin-bottom-20" *ngIf="inventories">
                <mat-radio-group>
                    <mat-card *ngFor="let inventory of inventories"
                              class="tile mat-elevation-z5 margin-bottom-10"
                              (click)="selectInventory(inventory)">
                         <span class="pull-right">
                                    Qty: {{ inventory.sum }}
                                </span>
                        <div class="flex no-wrap">
                            <div>
                                <mat-radio-button [value]="inventory"></mat-radio-button>
                                <b>{{inventory.inventory.item}}</b>
                            </div>
                            <div *ngIf="inventory.inventory.configurations ">
                                Conf:
                                {{ inventory.inventory.configurations }}
                            </div>
                            <div *ngIf="inventory.inventory.rev">
                                Rev:
                                {{ inventory.inventory.rev}}
                            </div>
                            <div style="padding: 0 20px">
                                Batch: {{ inventory.inventory.batch ? inventory.inventory.batch : "---" }}
                            </div>
                            <div>
                                Location:
                                <span *ngIf="inventory.inventory.warehouse_location">
                                    {{ inventory.inventory.warehouse_location.location }}
                                </span>
                            </div>
                        </div>
                    </mat-card>
                </mat-radio-group>
            </div>

            <div class="full margin-bottom-20" *ngIf="inventories && !inventories.length">
                <mat-card class="tile mat-elevation-z5">
                    <p>No items to show</p>
                </mat-card>
            </div>

            <mat-form-field class="half">
                <mat-label>Quantity</mat-label>
                <input matInput required formControlName="quantity">
            </mat-form-field>

            <mat-form-field class="half">
                <mat-label>New batch</mat-label>
                <input matInput formControlName="batch">
            </mat-form-field>


            <mat-form-field class="full">
                <mat-label>Remark</mat-label>
<textarea row="5" matInput  required formControlName="remark"></textarea>
            </mat-form-field>

        </div>
        <div class="actions">
            <button type="submit" mat-raised-button color="accent"
                    [disabled]="!form.valid || selected_inventory_sum === form.value.quantity">
                Update
            </button>
        </div>
    </form>

</mat-card>
