<h1 class="container-heading" *ngIf="modal">Add scan</h1>

<mat-card>
    <mat-card-content>
        <form [formGroup]="itemForm" (submit)="submit()" class="add-form">
            <div class="group flex">

                <mat-form-field class="half" *ngIf="!modal.params.hide_type">
                    <mat-label>Type</mat-label>
                    <mat-select formControlName="type">
                        <mat-option value="item">Item</mat-option>
                        <mat-option value="box">Box</mat-option>
                        <mat-option value="pallet">Pallet</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="half" *ngIf="itemForm.value.type !=='item'">
                    <mat-label>Ref</mat-label>
                    <input matInput type="text" formControlName="ref" required>
                </mat-form-field>

                <ng-container *ngIf="itemForm.value.type ==='item'">
                    <mat-form-field class="half">
                        <mat-label>Item</mat-label>
                        <input matInput type="text" formControlName="item" required>
                    </mat-form-field>

                    <mat-form-field class="half">
                        <mat-label>Serial</mat-label>
                        <input matInput type="text" formControlName="serial">
                    </mat-form-field>

                    <mat-form-field class="half">
                        <mat-label>Quantity</mat-label>
                        <input matInput type="number" noscroll formControlName="quantity" required>
                    </mat-form-field>
                    <mat-form-field class="half">
                        <mat-label>Configurations</mat-label>
                        <input matInput formControlName="configurations">
                    </mat-form-field>

                    <mat-form-field class="half">
                        <mat-label>Batch</mat-label>
                        <input matInput formControlName="batch">
                    </mat-form-field>

                    <mat-form-field class="half">
                        <mat-label>Rev</mat-label>
                        <input matInput formControlName="rev">
                    </mat-form-field>

                    <mat-form-field class="half">
                        <mat-label>Bin</mat-label>
                        <input matInput formControlName="bin">
                    </mat-form-field>

                    <mat-form-field class="half">
                        <mat-label>Lot</mat-label>
                        <input matInput formControlName="lot">
                    </mat-form-field>

                </ng-container>


                <mat-form-field class="full" *ngIf="!modal.params.hide_notes">
                    <mat-label>Notes</mat-label>
                    <textarea matInput formControlName="notes"></textarea>
                </mat-form-field>

            </div>

            <div class="actions">
                <button type="submit" mat-raised-button color="primary" [disabled]="!itemForm.valid">
                    Submit
                </button>

                <button type="button" mat-raised-button (click)="modal.response.emit(null)">
                    Cancel
                </button>
            </div>
        </form>
    </mat-card-content>
</mat-card>
