import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {ModalService} from "../../../../../../services/modal.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {Order} from "../../../../../../../common/interfaces/order.interface";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {ConfirmComponent} from "../../../../../../../common/components/confirm/confirm.component";
import {Api3Service} from "../../../../../../../common/services/api3.service";
import {Warehouse} from "../../../../../../../common/interfaces/warehouse.interface";

@Component({
    selector: "section-order-lifecycle",
    templateUrl: "lifecycle.component.html",
    styleUrls: [
        "lifecycle.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})

export class OrderLifecycleComponent implements OnInit {

    @Input()
    public orderData: Order.IOrderData;

    @Input()
    public state: Base.IState;


    @ViewChild(ConfirmComponent, {static: true})
    public confirmRef: ConfirmComponent;


    public timeline: any;

    public steps: { [key: string]: string }[] = [
        {
            "type": "order_created",
            "name": "Order created",
            "icon": "rocket",
        },
        {
            "type": "order_created_email_sent",
            "name": "Order created email sent",
            "icon": "mail",
            "action": "Send email"
        },
        {
            "type": "order_confirmed",
            "name": "Order confirmed",
            "icon": "rocket_launch",
        },
        {
            "type": "order_confirmed_email_sent",
            "name": "Order confirmation email sent",
            "icon": "mail",
            "action": "Send email"
        },
        {
            "type": "shipments_created",
            "name": "Shipments created",
            "icon": "trolley",
        },
        {
            "type": "outbound_complete",
            "name": "Outbound complete",
            "icon": "outbox",
        },
        {
            "type": "warehouse_document_release",
            "name": "Warehouse document release",
            "icon": "receipt_long",
            "action": "Send email"
        },
        {
            "type": "cost_calculation_email_sent",
            "name": "Cost calculation email sent",
            "icon": "mail",
            "action": "Send email"
        },
        {
            "type": "order_shipped",
            "name": "Order shipped",
            "icon": "flight_takeoff",
        },
        {
            "type": "shipment_delivered",
            "name": "Shipment delivered",
            "icon": "flight_land",
        },
        {
            "type": "inbound_complete",
            "name": "Inbound complete",
            "icon": "move_to_inbox",
        },
        {
            "type": "order_closed",
            "name": "Order closed",
            "icon": "sports_score",
        }
    ];

    public constructor(
        private apiService: ApiService,
        private api3Service: Api3Service,
        private changeDetectorRef: ChangeDetectorRef,
        private modalService: ModalService,
        private toastService: ToastService,
        private spinnerService: SpinnerService,
    ) {
    }

    public async getOrderTimeline(): Promise<any> {

        const {data}: Api.IResponse = await this.api3Service.get(
            `${this.state.section}/orders/${this.orderData.id}/timeline`);

        if (data) {
            this.timeline = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    public displayCondition(type: string): boolean {
        switch (type) {
            case "inbound_complete":
                return this.orderData.service_level.order_type.can_inbound;
            case "outbound_complete":
            case "order_shipped":
            case "warehouse_document_release":
                return this.orderData.service_level.order_type.can_outbound;
            case "cost_calculation_email_sent":
                return ["afimilk_cn", "afimilk_us"].includes(this.state.section_slug)
                    && this.orderData.service_level.order_type.can_outbound;
            default:
                return true;
        }
    }

    public async action(type: string): Promise<any> {
        if (["warehouse_document_release", "cost_calculation_email_sent"].includes(type)) {
            if (!await this.confirmRef.confirm("Emails will be issued for all outbound shipments")) {
                return;
            }
        }

        const {code, message}: Api.IResponse = await this.api3Service.post(
            `${this.state.section}/orders/${this.orderData.id}/timeline`, {
                action: type
            });

        if (code === 200) {
            this.toastService.show(message, "success");
        }

    }

    public ngOnInit(): void {
        this.getOrderTimeline();
    }
}