import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ModalService} from "../../../../../services/modal.service";
import {UserService} from "../../../../../../common/services/user.service";
import {Router} from "@angular/router";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {Table2Component} from "../../../../../../common/components/table2";
import {FormControl, FormGroup} from "@angular/forms";
import {takeUntil} from "rxjs";
import {AmplitudeService} from "../../../../../../common/services/amplitude.service";

@Component({
    selector: "section-branches",
    templateUrl: "branches.component.html",
    styleUrls: [
        "branches.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class BranchesComponent implements Base.IComponent, OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();


    public readonly state: Base.IState;

    public listTable: Table.ISettings;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    public filterForm: FormGroup = new FormGroup({
        term: new FormControl(null)
    });

    public constructor(
        private apiService: ApiService,
        private modalService: ModalService,
        private userService: UserService,
        private router: Router,
        private toastService: ToastService,
        private changeDetectorRef: ChangeDetectorRef,
        ) {
    }

    private getUrl(): Table.ITableApi {
        return {
            url: ["partner", "branches"],
            query: this.filterForm.value
        };
    }

    private prepareList(): void {

        const actions: Table.IAction[] = [];

        if (this.userService.validatePermissions("edit_partner_branches")) {
            actions.push({
                name: "edit",
                title: "Edit",
                click: (row: any): void => {
                    this.router.navigate([this.state.section, "branches", "edit", "id", row.id]);
                    AmplitudeService.eventClick("Edit branch");
                }
            });
        }

        actions.push({
            name: "star",
            title: "Make default",
            click: async (row: any): Promise<any> => {
                const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
                    ["partner", "branches", row.id, "set-default"]);
                if (type as string === "success") {
                    this.toastService.show(message, "success");
                    this.tableRef.reload();
                }
                AmplitudeService.eventClick("Favorite branch");
            },
            cssClassFn: (row: any): string => {
                return row.is_default ? "mat-accent" : "";
            }
        });

        if (this.userService.validatePermissions("delete_partner_branches")) {
            actions.push({
                name: "delete",
                title: "Delete",
                click: async (row: any): Promise<any> => {
                    if (await this.confirmRef.confirm(`Do you want to delete branch?`)) {
                        const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
                            ["partner", "branches", row.id]);
                        if (type as string === "success") {
                            this.toastService.show(message, "success");
                            this.tableRef.reload();
                        }
                    }
                    AmplitudeService.eventClick("Delete branch");
                }
            });
        }

        const columns: Table.ICol[] = [
            {
                data: "display_name",
                title: "Name"
            },
            {
                data: "countries.country_name",
                name: "Countries.country_name",
                title: "Countries",
                render: (row: any): string => {
                    let flags: string = "";
                    for (const country of row.countries) {
                        flags += "<img class='flag' src='assets/images/flags/"
                            + country.country_iso_2.toLowerCase().trim() + ".svg' alt='" + country.country_name + "'"
                            + " title='" + country.country_name + "'> ";
                    }
                    return flags;
                },
                sortable: false,
            },
            {
                data: "term",
                title: "Term",
                searchable: false
            },
            {
                data: "address.address",
                name: "Address.address",
                title: "Address",
                render: (row: any): string => {
                    if (!row.address) {
                        return "";
                    }

                    return row.address.address + " "
                        + row.address.address2 + ", "
                        + row.address.zip + " "
                        + row.address.state + " "
                        + row.address.country;
                },
                sortable: false
            }
        ];

        this.listTable = {
            table_id: "dflklskdt",
            actions,
            api: this.getUrl(),
            columns,
        };
        this.changeDetectorRef.markForCheck();
    }

    public async add_new(): Promise<any> {
        this.router.navigate([this.state.section, "branches", "add"]);
        AmplitudeService.eventClick("Add branch");
    }

    public ngOnInit(): void {
        this.prepareList();

        this.filterForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((): void => {
            setTimeout((): void => {
                this.tableRef.reload(this.getUrl());
            }, 500);
        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "branches",
            actions: {
                "browse": ["browse_partner_branches"]
            }
        };
    }
}

