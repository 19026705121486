import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewEncapsulation} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Router} from "@angular/router";
import {FormControl, Validators} from "@angular/forms";
import {StorageService} from "../../../../../../common/services/storage.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";

@Component({
    selector: "section-invite",
    templateUrl: "invite.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class InviteComponent implements Base.IComponent {

    public readonly state: Base.IState;

    public email: FormControl = new FormControl(null, [Validators.required]);

    public constructor(
        private apiService: ApiService,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private storageService: StorageService,
        private toastService: ToastService,
        private spinnerService: SpinnerService
    ) {
    }

    public async submitInvite(): Promise<any> {
        this.spinnerService.show();
        const {type, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["user", "invite"], {email: this.email.value});
        if (type as string === "success") {
            this.toastService.show(message, "success");
            this.email.reset();
        }
        this.spinnerService.hide();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "user-invite",
            actions: {
                "browse": "*"
            }
        };
    }

}
