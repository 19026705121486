<div class="title-actions relative pull-right">
    <button mat-raised-button type="button" *ngIf="data.orderRef"
            (click)="showAttachments()" matBadgeColor="accent" [matBadge]="attachmentsCount">
        Attachments
    </button>
    &nbsp;
    <button *ngIf="data.orderRef" type="button" mat-raised-button [matBadge]="remarksCount.toString()"
            (click)="showSidebar(true)">
        Remarks
    </button>
</div>


<h1 class="container-heading">Inbound procedure</h1>
<div *ngIf="data.orderRef" class="order-ref relative clear">
    Order Ref: <b>{{data.orderRef}}</b>
</div>


<div class="clear">

    <mat-horizontal-stepper #stepper [linear]="true" (selectionChange)="handleStepChanges($event)">

        <ng-template matStepperIcon="edit">
            <mat-icon>done</mat-icon>
        </ng-template>

        <ng-template matStepperIcon="done">
            <mat-icon>done_all</mat-icon>
        </ng-template>

        <ng-template matStepperIcon="number" let-active="active" let-index="index">
            <mat-icon *ngIf="active; else elseIcon">create</mat-icon>
            <ng-template #elseIcon>{{index + 1}}</ng-template>
        </ng-template>

        <ng-template ngFor [ngForOf]="steps" let-step let-i="index">
            <mat-step [editable]="steps[i].control.valid" [stepControl]="steps[i].control">
                <ng-template matStepLabel>{{step.title}}</ng-template>

                <div class="step-description" *ngIf="step.description">
                    <img *ngIf="step.icon" [src]="step.icon" alt="">
                    {{step.description}}
                </div>

                <ng-template wizard-component-factory [component]="step.component"
                             (result)="handleStepEvent($event,i)">
                </ng-template>

                <div class="actions">
                    <button *ngIf="i > 0"
                            matStepperPrevious
                            mat-raised-button
                            type="button">
                        Prev
                    </button>
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <button *ngIf="steps[i].control.valid && i < steps.length-1"
                            matStepperNext
                            mat-raised-button
                            color="primary"
                            type="button"
                            class="main">
                        Next
                    </button>

                    <button *ngIf="steps[i].control.valid && i === steps.length-1"
                            (click)="finish()"
                            mat-raised-button
                            color="primary"
                            type="button"
                            class="main">
                        Finish
                    </button>
                </div>
            </mat-step>
        </ng-template>
    </mat-horizontal-stepper>
</div>

<section-remarks-sidebar #sidebar (onNewRemark)="getAttachmentsCount()"></section-remarks-sidebar>
