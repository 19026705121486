import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Order} from "../../../../../../common/interfaces/order.interface";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {ApiService} from "../../../../../../common/services/api.service";
import {Router, UrlTree} from "@angular/router";


@Component({
    selector: "section-in-transit-modal",
    template: `
        <common-confirm></common-confirm>

        <h1 class="container-heading">In Transit Detailed</h1>
        <mat-card>
            <mat-card-content>
                <common-table2 *ngIf="listTable" [settings]="listTable"></common-table2>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InTransitModalComponent implements OnInit {

    public modal: Modal.IModal;
    public readonly state: Base.IState;
    public listTable: Table.ISettings;
    public params: Order.IOrderListParams;
    public showExportButton: boolean = false;

    public constructor(
        private apiService: ApiService,
        private modalService: ModalService,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    private prepareList(): void {

        const columns: Table.ICol[] = [
            {
                data: "order.ref",
                title: "Order ref",
                render: (row: any): string => {
                    let actions: string = "";
                    actions += `<button type="button"
                            class="mat-mdc-raised-button mdc-button" title="Go to order">
                        ${row.order.ref}
                    </button> `;
                    return actions;
                },
                click: async (row: any): Promise<any> => {
                    const url: UrlTree = this.router.createUrlTree([
                        this.modal.params.state.section,
                        "orders",
                        "view",
                        "id",
                        row.order.id
                    ]);
                    window.open(url.toString(), "_blank");
                }
            },
            {
                data: "order.service_level.name",
                title: "Service Level"
            },
            {
                data: "order.main_address.address_name",
                title: this.modal.params.transit === "wh" ? "From" : "Address"
            },
            {
                data: "courier_transaction.tracking_number",
                title: "Tracking number"
            },
            {
                data: "quantity",
                title: "Quantity"
            },
            {
                data: "status.name",
                title: "Status"
            },
        ];


        this.listTable = {
            table_id: "21h5wstHKIB",
            api: {
                url: ["order_item", "in_transit", this.modal.params.id],
                query: {
                    "transit": this.modal.params.transit,
                    "inventory_conversion_id": this.modal.params.inventory_conversion_id
                }
            },
            columns: columns,
        };
        this.changeDetectorRef.markForCheck();
    }

    public ngOnInit(): void {
        this.prepareList();
    }

}
