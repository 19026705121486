import {CommonFormComponent} from "../../../common/components/form";
import {FileUploadComponent} from "../../../common/components/file-upload/file-upload.component";
import {SpinnerComponent} from "../../../common/components/spinner/spinner.component";

export default {
    admin: [
        {
            title: "Dashboard",
            link: "/admin/dashboard",
            icon: "dashboard",
            permissions: ["browse_admin"]
        },
        {
            title: "Partners",
            link: null,
            expanded: false,
            icon: "group",
            permissions: [
                "browse_partners",
                "browse_coverages",
                "browse_inventories_conversion",
                "browse_part_masters",
                "browse_inventories"
            ],
            items: [
                {
                    title: "Manage",
                    link: "/admin/partner",
                    permissions: ["browse_partners"]
                },
                // {
                //     title: "Partner service level",
                //     link: "/admin/coverage",
                //     permissions: ["browse_coverages"]
                // },
                // {
                //     title: "Inventories Conversions",
                //     link: "/admin/inventories-conversion",
                //     permissions: ["browse_inventories_conversion"]
                // },
                {
                    title: "Part Master",
                    link: "/admin/part-master-list",
                    permissions: ["browse_part_masters"]
                },
                // {
                //     title: "Inventories",
                //     link: "/admin/inventory",
                //     permissions: ["browse_inventories"]
                // }
            ]
        },
        {
            title: "Announcements",
            link: null,
            icon: "announcement",
            expanded: false,
            permissions: ["browse_announcements"],
            items: [
                {
                    title: "My announcements",
                    link: "/admin/support-announcements-my",
                    iconFa: "user",
                    permissions: ["browse_announcements"]
                },
                {
                    title: "All",
                    iconFa: "users",
                    link: "/admin/support-announcements-all",
                    permissions: ["browse_announcements"]
                },
            ]
        },
        {
            title: "Support",
            link: null,
            icon: "headset_mic",
            expanded: false,
            permissions: ["browse_requests", "can_see_shift", "browse_order_remarks"],
            items: [
                {
                    title: "Requests",
                    link: null,
                    icon: null,
                    expanded: false,
                    permissions: ["browse_requests", "browse_it_requests"],
                    items: [
                        {
                            title: "My requests",
                            link: "/admin/support-requests-my",
                            iconFa: "user",
                            permissions: ["browse_requests"]
                        },
                        {
                            title: "All",
                            iconFa: "users",
                            link: "/admin/support-requests-all",
                            permissions: ["browse_requests"]
                        },
                        {
                            title: "IT",
                            icon: "precision_manufacturing",
                            link: "/admin/support-requests-it",
                            permissions: ["browse_it_requests"]
                        },
                    ]
                },
                {
                    title: "Followups",
                    link: null,
                    icon: null,
                    expanded: false,
                    permissions: ["can_see_shift", "see_all_followups"],
                    items: [
                        {
                            title: "My followups",
                            link: "/admin/followups-my",
                            permissions: ["can_see_shift"]
                        },
                        {
                            title: "All",
                            link: "/admin/followups-all",
                            permissions: ["see_all_followups"]
                        },
                        {
                            title: "List by Tasks",
                            link: "followups-by-tasks",
                            permissions: ["see_all_followups"]
                        },
                    ]
                },
                {
                    title: "Remarks",
                    link: "/admin/remarks",
                    icon: "sms",
                    permissions: ["browse_order_remarks"]
                },
                {
                    title: "Knowledge Center",
                    link: "support-knowledge-center-all",
                    icon: "collections_bookmark",
                    permissions: ["browse_knowledge_center_documents"]
                },
            ]
        },
        {
            title: "Couriers",
            link: null,
            expanded: false,
            icon: "local_shipping",
            permissions: [
                "browse_couriers"
            ],
            items: [
                {
                    title: "Shipping modes",
                    link: "/admin/shipping-modes",
                    permissions: ["browse_couriers"]
                }
            ]
        },
        {
            title: "ThreePLs",
            link: null,
            expanded: false,
            icon: "group_work",
            permissions: [
                "browse_threepls"
            ],
            items: [
                {
                    title: "Manage",
                    link: "/admin/threepl",
                    permissions: ["browse_threepls"]
                },
            ]
        },
        {
            title: "Order management",
            link: null,
            expanded: false,
            icon: "assignment",
            permissions: [
                "edit_warehouse_transactions",
                "browse_pickup_requests",
                "browse_orders"
            ],
            items: [
                {
                    title: "Orders",
                    link: null,
                    expanded: false,
                    icon: "dvr",
                    permissions: [
                        "browse_orders",
                    ],
                    items: [
                        {
                            title: "List",
                            link: null,
                            expanded: false,
                            icon: "filter_list",
                            permissions: ["browse_orders"],
                            items: [
                                {
                                    title: "All",
                                    link: "/admin/orders",
                                    permissions: ["browse_orders"]
                                },
                                {
                                    title: "Open",
                                    link: "/admin/orders-open",
                                    permissions: ["browse_orders"]
                                },
                                {
                                    title: "Exception",
                                    link: "/admin/orders-exception",
                                    permissions: ["browse_orders"]
                                },
                                {
                                    title: "Closed",
                                    link: "/admin/orders-closed",
                                    permissions: ["browse_orders"]
                                },
                            ]
                        }
                    ]
                },
                {
                    title: "Pickup requests",
                    link: "pickup-requests-list",
                    icon: "drive_eta",
                    permissions: ["browse_pickup_requests"]
                },
                {
                    title: "Adjust transactions",
                    link: "/admin/adjust-transactions",
                    permissions: ["edit_warehouse_transactions"]
                }
            ]
        },
        {
            title: "Tracking",
            link: null,
            expanded: false,
            icon: "gps_fixed",
            permissions: ["browse_courier_transactions"],
            items: [
                {
                    title: "List",
                    link: "/admin/tracking-list",
                    permissions: ["browse_requests"]
                },
                {
                    title: "Map",
                    link: "/admin/tracking-map",
                    permissions: ["browse_requests"]
                },
            ]
        },
        {
            title: "Recent jobs",
            link: "recent-jobs",
            icon: "assignment",
            permissions: ["browse_integrations"],
        },
        {
            title: "SLA",
            link: null,
            icon: "timer",
            permissions: ["browse_service_level_trackings"],
            items: [
                {
                    title: "Tracking",
                    link: "/admin/sla-tracking",
                    permissions: ["browse_service_level_trackings"],
                }
            ]
        },
        {
            title: "Reports",
            link: "reports",
            icon: "description",
            permissions: ["browse_admin"],
        },
        {
            title: "Users",
            link: null,
            expanded: false,
            icon: "supervised_user_circle",
            permissions: [
                "browse_users"
            ],
            items: [
                {
                    title: "Manage",
                    link: null,
                    expanded: false,
                    permissions: ["browse_users"],
                    items: [
                        {
                            title: "List",
                            link: "/admin/user",
                            permissions: ["browse_users"]
                        },
                        {
                            title: "Tasks",
                            link: "/admin/user-tasks",
                            permissions: ["browse_users"]
                        }
                    ]
                },
                {
                    title: "Roles",
                    link: "/admin/role",
                    permissions: ["browse_roles"]
                }
            ]
        },
        {
            title: "Teams",
            link: "/admin/team",
            icon: "record_voice_over",
            permissions: [
                "browse_teams"
            ]
        },
        {
            title: "Analytics",
            link: "/admin/analytics",
            icon: "multiline_chart",
            permissions: ["browse_orders"]
        },
        {
            title: "Remark Types",
            link: "/admin/remark-type",
            icon: "",
            permissions: ["browse_order_remarks"]
        },
        {
            title: "Model Fields",
            link: "/admin/model-fields",
            icon: "accessibility",
            permissions: ["browse_model_fields"]
        },
        {
            title: "Logs",
            link: null,
            icon: null,
            expanded: false,
            permissions: ["browse_admin"],
            items: [
                {
                    title: "Emails",
                    link: "/admin/email-logs",
                    icon: null,
                    permissions: ["browse_admin"]
                }
            ]
        }
    ],
    threepl: [
        {
            title: "Dashboard",
            link: "dashboard",
            icon: "dashboard",
            permissions: ["browse_threepls"]
        },
        {
            title: "Warehouses",
            link: "warehouses",
            icon: "business",
            permissions: [
                "browse_warehouses"
            ],
        },
        {
            title: "Requests",
            link: null,
            icon: "headset_mic",
            expanded: false,
            permissions: ["browse_requests"],
            items: [
                {
                    title: "My requests",
                    link: "support-requests-my",
                    iconFa: "user",
                    permissions: ["browse_requests", "browse_it_requests"]
                },
                {
                    title: "All",
                    iconFa: "users",
                    link: "support-requests-all",
                    permissions: ["browse_requests"]
                },
                {
                    title: "IT",
                    icon: "precision_manufacturing",
                    link: "support-requests-it",
                    permissions: ["browse_it_requests"]
                },
            ]
        },
        {
            title: "Inventories",
            link: null,
            expanded: false,
            permissions: [
                "browse_inventories"
            ],
            items: [
                {
                    title: "Stock",
                    link: null,
                    expanded: false,
                    permissions: [
                        "browse_inventories"
                    ],
                    items: [
                        {
                            title: "Detailed",
                            link: "stock-detailed",
                            permissions: ["browse_inventories"]
                        },
                        {
                            title: "Summarized",
                            link: "stock-summarized",
                            permissions: ["browse_inventories"]
                        }
                    ]
                },
            ]
        },
        {
            title: "Package Types",
            link: null,
            expanded: false,
            icon: "inbox",
            permissions: ["browse_warehouse_package_types"],
            items: [
                {
                    title: "List",
                    link: "package-type",
                    expanded: false,
                    permissions: [
                        "browse_warehouse_package_types"
                    ]
                }
            ]
        },
        {
            title: "Location Types",
            link: null,
            expanded: false,
            icon: "location_on",
            permissions: ["browse_warehouse_location_types"],
            items: [
                {
                    title: "List",
                    link: "location-type",
                    expanded: false,
                    permissions: [
                        "browse_warehouse_location_types"
                    ]
                }
            ]
        },
        {
            title: "Conversions",
            link: null,
            expanded: false,
            iconFa: "balance-scale",
            permissions: [
                "browse_threepls"
            ],
            items: [
                {
                    title: "Couriers services",
                    link: "courier-services-conversions",
                    permissions: ["browse_courier_service_conversions"]
                }
            ]
        },
    ],
    warehouse: [
        {
            title: "Back",
            link: "warehouses",
            icon: "business",
            permissions: [
                "browse_warehouses"
            ],
        },
        {
            title: "Dashboard",
            link: "dashboard",
            icon: "dashboard",
            permissions: ["browse_warehouse_orders"]
        },
        {
            title: "Orders",
            link: null,
            icon: "dvr",
            expanded: false,
            permissions: [
                "browse_warehouse_orders"
            ],
            items: [
                {
                    title: "List",
                    link: null,
                    expanded: false,
                    permissions: [
                        "browse_warehouse_orders"
                    ],
                    items: [
                        {
                            title: "All",
                            link: "warehouse-orders-all",
                            permissions: ["browse_warehouse_orders"]
                        },
                        {
                            title: "Open",
                            link: "warehouse-orders-open",
                            permissions: ["browse_warehouse_orders"]
                        },
                        {
                            title: "On hold",
                            link: "warehouse-orders-on-hold",
                            permissions: ["browse_warehouse_orders"]
                        },
                        {
                            title: "Pending Unilog",
                            link: "warehouse-orders-pending-unilog",
                            permissions: ["browse_warehouse_orders"]
                        },
                        {
                            title: "Exception",
                            link: "warehouse-orders-exception",
                            permissions: ["browse_warehouse_orders"]
                        },
                        {
                            title: "Packed",
                            link: "warehouse-orders-packed",
                            permissions: ["browse_warehouse_orders"]
                        },
                        {
                            title: "Pending for pick-up",
                            link: "warehouse-orders-pending-pick-up",
                            permissions: ["browse_warehouse_orders"]
                        },
                        {
                            title: "Closed",
                            link: "warehouse-orders-closed",
                            permissions: ["browse_warehouse_orders"]
                        }
                    ]
                },
                {
                    title: "By Type",
                    link: null,
                    expanded: false,
                    permissions: [
                        "browse_warehouse_orders"
                    ],
                    items: [
                        {
                            title: "Replenishment",
                            link: null,
                            expanded: false,
                            permissions: [
                                "browse_warehouse_orders"
                            ],
                            items: [
                                {
                                    title: "List",
                                    link: null,
                                    expanded: false,
                                    permissions: [
                                        "browse_warehouse_orders"
                                    ],
                                    items: [
                                        {
                                            title: "All",
                                            link: "warehouse-orders-replenishment",
                                            permissions: ["browse_warehouse_orders"]
                                        },
                                        {
                                            title: "Open",
                                            link: "warehouse-orders-replenishment-open",
                                            permissions: ["browse_warehouse_orders"]
                                        },
                                        {
                                            title: "Closed",
                                            link: "warehouse-orders-replenishment-closed",
                                            permissions: ["browse_warehouse_orders"]
                                        }
                                    ]
                                },
                            ]
                        },
                        {
                            title: "Sales",
                            link: null,
                            expanded: false,
                            permissions: [
                                "browse_warehouse_orders"
                            ],
                            items: [
                                {
                                    title: "List",
                                    link: null,
                                    expanded: false,
                                    permissions: [
                                        "browse_warehouse_orders"
                                    ],
                                    items: [
                                        {
                                            title: "All",
                                            link: "warehouse-orders-sales",
                                            permissions: ["browse_warehouse_orders"]
                                        },
                                        {
                                            title: "Open",
                                            link: "warehouse-orders-sales-open",
                                            permissions: ["browse_warehouse_orders"]
                                        },
                                        {
                                            title: "Closed",
                                            link: "warehouse-orders-sales-closed",
                                            permissions: ["browse_warehouse_orders"]
                                        }
                                    ]
                                },
                            ]
                        },
                        // {
                        //     title: "Return",
                        //     link: null,
                        //     expanded: false,
                        //     permissions: [
                        //         "browse_warehouse_orders"
                        //     ],
                        //     items: [
                        //         {
                        //             title: "List",
                        //             link: null,
                        //             expanded: false,
                        //             permissions: [
                        //                 "browse_warehouse_orders"
                        //             ],
                        //             items: [
                        //                 {
                        //                     title: "All",
                        //                     link: "warehouse-orders-return",
                        //                     permissions: ["browse_warehouse_orders"]
                        //                 },
                        //                 {
                        //                     title: "Open",
                        //                     link: "warehouse-orders-return-open",
                        //                     permissions: ["browse_warehouse_orders"]
                        //                 },
                        //                 {
                        //                     title: "Closed",
                        //                     link: "warehouse-orders-return-closed",
                        //                     permissions: ["browse_warehouse_orders"]
                        //                 }
                        //             ]
                        //         },
                        //     ]
                        // },
                        {
                            title: "RMA",
                            link: null,
                            expanded: false,
                            permissions: [
                                "browse_warehouse_orders"
                            ],
                            items: [
                                {
                                    title: "List",
                                    link: null,
                                    expanded: false,
                                    permissions: [
                                        "browse_warehouse_orders"
                                    ],
                                    items: [
                                        {
                                            title: "All",
                                            link: "warehouse-orders-rma",
                                            permissions: ["browse_warehouse_orders"]
                                        },
                                        {
                                            title: "Open",
                                            link: "warehouse-orders-rma-open",
                                            permissions: ["browse_warehouse_orders"]
                                        },
                                        {
                                            title: "Closed",
                                            link: "warehouse-orders-rma-closed",
                                            permissions: ["browse_warehouse_orders"]
                                        }
                                    ]
                                },
                            ]
                        }
                    ]
                }
            ]
        },
        {
            title: "Requests",
            link: null,
            icon: "headset_mic",
            expanded: false,
            permissions: ["browse_requests", "browse_it_requests"],
            items: [
                {
                    title: "My requests",
                    link: "support-requests-my",
                    iconFa: "user",
                    permissions: ["browse_requests"]
                },
                {
                    title: "All",
                    iconFa: "users",
                    link: "support-requests-all",
                    permissions: ["browse_requests"]
                },
                {
                    title: "IT",
                    icon: "precision_manufacturing",
                    link: "support-requests-it",
                    permissions: ["browse_it_requests"]
                },
            ]
        },
        {
            title: "Followups",
            link: null,
            icon: "update",
            expanded: false,
            permissions: ["can_see_shift", "see_all_followups"],
            items: [
                {
                    title: "My followups",
                    link: "followups-my",
                    permissions: ["can_see_shift"]
                },
                {
                    title: "All",
                    link: "followups-all",
                    permissions: ["see_all_followups"]
                },
                {
                    title: "List by Tasks",
                    link: "followups-by-tasks",
                    permissions: ["see_all_followups"]
                }
            ]
        },
        {
            title: "Announcements",
            link: null,
            icon: "announcement",
            expanded: false,
            permissions: ["browse_announcements"],
            items: [
                {
                    title: "My announcements",
                    link: "support-announcements-my",
                    iconFa: "user",
                    permissions: ["browse_announcements"]
                },
                {
                    title: "All",
                    iconFa: "users",
                    link: "support-announcements-all",
                    permissions: ["browse_announcements"]
                },
            ]
        },
        {
            title: "Tracking",
            link: null,
            expanded: false,
            icon: "gps_fixed",
            permissions: ["browse_courier_transactions"],
            items: [
                {
                    title: "List",
                    link: "tracking-list",
                    permissions: ["browse_requests"]
                },
            ]
        },
        {
            title: "Knowledge Center",
            link: "support-knowledge-center-all",
            icon: "collections_bookmark",
            permissions: ["browse_knowledge_center_documents"]
        },
        {
            title: "Procedures",
            link: null,
            iconFa: "barcode",
            expanded: false,
            permissions: ["add_warehouse_transactions", "browse_warehouse_orders", "browse_inventory_counts"],
            items: [
                {
                    title: "Inbound",
                    link: "procedures-inbound",
                    icon: "arrow_downward",
                    permissions: ["add_warehouse_transactions"],
                },
                {
                    title: "Free Inbound",
                    link: "procedures-free-inbound",
                    icon: "arrow_downward",
                    permissions: ["add_warehouse_transactions"],
                },
                {
                    title: "Outbound",
                    link: "procedures-outbound",
                    icon: "arrow_upward",
                    permissions: ["add_warehouse_transactions"],
                },
                {
                    title: "Transfer",
                    link: "procedures-transfer",
                    icon: "shuffle",
                    permissions: ["add_warehouse_transactions"],
                },
                {
                    title: "Inventory count",
                    link: "inventory-count",
                    icon: "spellcheck",
                    permissions: ["browse_inventory_counts"],
                },
                {
                    title: "Custom labels",
                    link: "custom-labels",
                    icon: "print",
                    permissions: [
                        "browse_warehouse_orders"
                    ],

                }
            ],
        },
        {
            title: "Inspection",
            icon: "ballot",
            link: null,
            expanded: false,
            permissions: ["browse_inspections"],
            items: [
                {
                    title: "Inspect",
                    link: null,
                    expanded: false,
                    permissions: ["browse_inspections"],
                    items: [
                        {
                            title: "All",
                            link: "inspections",
                            permissions: ["browse_inspections"]
                        }
                    ]
                },
                {
                    title: "List",
                    link: "inspection-results",
                    permissions: ["browse_inspections"],
                }
            ],
        },
        {
            title: "Inventories",
            link: null,
            expanded: false,
            icon: "storage",
            permissions: [
                "browse_inventories",
                "browse_warehouse_locations",
                "browse_boxes"
            ],
            items: [
                {
                    title: "Stock",
                    link: null,
                    expanded: false,
                    permissions: [
                        "browse_inventories"
                    ],
                    items: [
                        {
                            title: "Detailed",
                            link: "stock-detailed",
                            permissions: ["browse_inventories"]
                        },
                        {
                            title: "Summarized",
                            link: "stock-summarized",
                            permissions: ["browse_inventories"]
                        },
                        {
                            title: "Summarized by location",
                            link: "stock-summarized-by-location",
                            permissions: ["browse_inventories"]
                        }
                    ]
                },
                {
                    title: "Locations",
                    link: "warehouse-locations",
                    permissions: [
                        "browse_warehouse_locations"
                    ],
                },
                {
                    title: "Boxes",
                    link: "warehouse-boxes",
                    permissions: [
                        "browse_boxes"
                    ],
                },
                {
                    title: "Pallets",
                    link: "warehouse-pallets",
                    permissions: [
                        "browse_boxes"
                    ],
                },
            ]
        },
        {
            title: "Reports",
            link: null,
            icon: "description",
            expanded: false,
            permissions: ["read_reports"],
            items: [
                {
                    title: "All",
                    link: "reports",
                    permissions: ["browse_reports"],
                },
                {
                    title: "Favorite",
                    icon: "star",
                    link: "reports/favorite",
                    permissions: ["browse_reports"],
                },
                {
                    title: "Expected items",
                    link: null,
                    icon: "exposure_neg_1",
                    modal: {
                        component: CommonFormComponent,
                        params: {
                            configUrl: ["report", "expected-items"],
                            submitUrl: ["report", "expected-items"],
                            asyncKey: Math.random().toString(36).replace(/[^a-z]+/g, "")
                        }
                    },
                    permissions: ["read_reports"],
                },
                {
                    title: "Transfer history",
                    link: "transfer-history-report",
                    permissions: ["read_reports"],
                },
            ],
        },
        {
            title: "Transactions",
            link: null,
            icon: "swap_horiz",
            expanded: false,
            permissions: [
                "browse_warehouse_transactions"
            ],
            items: [
                {
                    title: "List",
                    link: null,
                    expanded: false,
                    permissions: [
                        "browse_warehouse_transactions"
                    ],
                    items: [
                        {
                            title: "All",
                            link: "transactions-all",
                            permissions: ["browse_warehouse_transactions"]
                        },
                        {
                            title: "Outbound",
                            link: "transactions-outbound",
                            permissions: ["browse_warehouse_transactions"]
                        },
                        {
                            title: "Inbound",
                            link: "transactions-inbound",
                            permissions: ["browse_warehouse_transactions"]
                        },
                        {
                            title: "Invalid",
                            link: "transactions-invalid",
                            permissions: ["browse_warehouse_transactions"]
                        },
                        {
                            title: "Replenishment",
                            link: "transactions-replenishment",
                            permissions: ["browse_warehouse_transactions"]
                        },
                        {
                            title: "Deleted",
                            link: "transactions-deleted",
                            permissions: ["browse_deleted_warehouse_transactions"]
                        }
                    ]
                },
                {
                    title: "Upload transactions",
                    link: null,
                    icon: "exposure_plus_1",
                    modal: {
                        component: FileUploadComponent,
                        params: {
                            templatePath:
                                async (spinnerRef: SpinnerComponent, button: HTMLElement): Promise<any> => {
                                    const url: string = "https://s3-eu-west-1.amazonaws.com/logivicev2.net/" +
                                        "partner/transactions_example_{{slug}}.xlsx";
                                    const path: string[] = window.location.pathname.split("/");
                                    button.innerHTML =
                                        "<a href='" + url.replace("{{slug}}", path[2])
                                        + "' target='_blank' " +
                                        "class='mat-mdc-raised-button mdc-button mat-accent'>Download</a>";
                                },
                            templateLabel: "Get template",
                            accept: ".csv",
                            async: true,
                            url: ["transaction"]
                        }
                    },
                    permissions: ["browse_warehouse_transactions"],
                },
            ],
        },
        {
            title: "Teams",
            link: "team",
            icon: "record_voice_over",
            permissions: [
                "browse_teams"
            ]
        },
        {
            title: "Settings",
            link: "warehouse-settings",
            icon: "settings",
            permissions: [
                "browse_settings"
            ],
        },
    ],
    partner: [
        {
            title: "Dashboard",
            link: "dashboard",
            icon: "assessment",
            permissions: ["browse_orders"]
        },
        {
            title: "Orders",
            link: null,
            expanded: false,
            icon: "dvr",
            permissions: [
                "browse_orders",
                "browse_service_requests",
                "edit_warehouse_transactions",
                "browse_requests"
            ],
            items: [
                {
                    title: "Add order",
                    link: "orders/add",
                    icon: "add_box",
                    permissions: ["add_orders"]
                },
                {
                    title: "Import orders",
                    link: "csv-import/wizard/type/order",
                    iconFa: "file-excel-o",
                    permissions: ["add_orders"]
                },
                {
                    title: "Pickup requests",
                    link: "pickup-requests-list",
                    icon: "drive_eta",
                    permissions: ["browse_pickup_requests"]
                },
                {
                    title: "List",
                    link: null,
                    expanded: false,
                    icon: "filter_list",
                    permissions: ["browse_orders"],
                    items: [
                        {
                            title: "All",
                            link: "orders",
                            icon: "view_comfy",
                            permissions: ["browse_orders"]
                        },
                        {
                            title: "Open",
                            link: "orders-open",
                            icon: "check_box_outline_blank",
                            permissions: ["browse_orders"]
                        },
                        {
                            title: "Closed",
                            link: "orders-closed",
                            icon: "check_box",
                            permissions: ["browse_orders"]
                        },
                        {
                            title: "On Hold",
                            link: "orders-on-hold",
                            icon: "pause",
                            permissions: ["browse_orders"]
                        },
                        {
                            title: "Exception",
                            link: "orders-exception",
                            icon: "priority_high",
                            permissions: ["browse_orders"]
                        },
                        {
                            title: "Canceled",
                            link: "orders-canceled",
                            icon: "delete",
                            permissions: ["browse_orders"]
                        },
                    ]
                },
                {
                    title: "List by order type",
                    link: null,
                    expanded: false,
                    icon: "filter_list",
                    permissions: ["browse_orders"],
                    items: [
                        {
                            title: "Sales",
                            link: "orders-type-sales",
                            permissions: ["browse_orders"]
                        },
                        {
                            title: "Replenishment",
                            link: "orders-type-replenishment",
                            permissions: ["browse_orders"]
                        },
                        // {
                        //     title: "Return",
                        //     link: "orders-type-return",
                        //     permissions: ["browse_orders"]
                        // },
                        {
                            title: "RMA",
                            link: "orders-type-rma",
                            permissions: ["browse_orders"]
                        },
                        {
                            title: "Assembly",
                            link: "orders-type-assembly",
                            permissions: ["browse_orders"]
                        },
                        {
                            title: "Warehouse Transafer",
                            link: "orders-type-transfer",
                            permissions: ["browse_orders"]
                        },
                        {
                            title: "Informative",
                            link: "orders-type-informative",
                            permissions: ["browse_orders"]
                        },
                    ]
                },
                {
                    title: "List by items",
                    link: null,
                    expanded: false,
                    icon: "filter_list",
                    permissions: ["browse_orders"],
                    items: [
                        {
                            title: "All",
                            link: "orders-by-item",
                            icon: "view_comfy",
                            permissions: ["browse_orders"]
                        },
                    ]
                }
            ]
        },

        {
            title: "Support",
            link: null,
            icon: "headset_mic",
            expanded: false,
            permissions: ["browse_requests", "can_see_shift", "browse_order_remarks"],
            items: [
                {
                    title: "Followups",
                    link: null,
                    icon: "update",
                    expanded: false,
                    permissions: ["can_see_shift", "browse_auto_followups", "see_all_followups"],
                    items: [
                        {
                            title: "Automatic",
                            link: "followups-auto",
                            permissions: ["browse_auto_followups"]
                        },
                        {
                            title: "My followups",
                            link: "followups-my",
                            permissions: ["can_see_shift"]
                        },
                        {
                            title: "All",
                            link: "followups-all",
                            permissions: ["see_all_followups"]
                        },
                        {
                            title: "List by Tasks",
                            link: "followups-by-tasks",
                            permissions: ["see_all_followups"]
                        }
                    ]
                },
                {
                    title: "Requests",
                    link: null,
                    icon: "headset_mic",
                    expanded: false,
                    permissions: ["browse_requests", "browse_it_requests"],
                    items: [
                        {
                            title: "My requests",
                            link: "support-requests-my",
                            iconFa: "user",
                            permissions: ["browse_requests"]
                        },
                        {
                            title: "All",
                            iconFa: "users",
                            link: "support-requests-all",
                            permissions: ["browse_requests"]
                        },
                        {
                            title: "IT",
                            icon: "precision_manufacturing",
                            link: "support-requests-it",
                            permissions: ["browse_it_requests"]
                        },

                        {
                            title: "Kinds",
                            link: "support-requests-kinds",
                            iconFa: "list",
                            permissions: ["browse_request_kinds"]
                        },
                    ]
                },
                {
                    title: "Remarks",
                    link: null,
                    icon: "sms",
                    expanded: false,
                    permissions: ["browse_order_remarks"],
                    items: [
                        {
                            title: "All",
                            link: "remarks",
                            iconFa: "sms",
                            permissions: ["browse_order_remarks"]
                        },
                        {
                            title: "Classifications",
                            iconFa: "low_priority",
                            link: "remarks-classifications",
                            permissions: ["browse_order_remark_classifications"]
                        },
                    ]
                },
                {
                    title: "Knowledge Center",
                    link: "support-knowledge-center-all",
                    icon: "collections_bookmark",
                    permissions: ["browse_knowledge_center_documents"]
                },
                {
                    title: "Announcements",
                    link: null,
                    icon: "announcement",
                    expanded: false,
                    permissions: ["browse_announcements"],
                    items: [
                        {
                            title: "My announcements",
                            link: "support-announcements-my",
                            iconFa: "user",
                            permissions: ["browse_announcements"]
                        },
                        {
                            title: "All",
                            iconFa: "users",
                            link: "support-announcements-all",
                            permissions: ["browse_announcements"]
                        },
                    ]
                },
            ]
        },
        {
            title: "Services",
            link: null,
            expanded: false,
            icon: "apps",
            permissions: [
                "browse_service_requests",
                "edit_warehouse_transactions",
                "browse_service_levels",
                "browse_coverages"
            ],
            items: [
                {
                    title: "Service Levels",
                    link: "service-level",
                    icon: "highlight",
                    permissions: ["edit_service_levels"]
                },
                {
                    title: "Partner service level",
                    link: "coverage",
                    icon: "dvr",
                    permissions: ["browse_coverages"]
                }
            ]
        },
        {
            title: "Tracking",
            link: null,
            expanded: false,
            icon: "gps_fixed",
            permissions: ["browse_courier_transactions"],
            items: [
                {
                    title: "List",
                    link: "tracking-list",
                    permissions: ["browse_requests"]
                },
                {
                    title: "Map",
                    link: "tracking-map",
                    permissions: ["browse_requests"]
                },
            ]
        },
        {
            title: "Inventories",
            link: null,
            expanded: false,
            icon: "storage",
            permissions: [
                "browse_inventories",
                "browse_inventories_conversion",
                "browse_part_masters",
                "browse_warehouse_transactions",
                "browse_inventory_conditions"
            ],
            items: [
                {
                    title: "Stock",
                    link: null,
                    expanded: false,
                    icon: "move_to_inbox",
                    permissions: [
                        "browse_inventories"
                    ],
                    items: [
                        {
                            title: "Detailed",
                            link: "stock-detailed",
                            permissions: ["browse_inventories"]
                        },
                        {
                            title: "Summarized",
                            link: "stock-summarized",
                            permissions: ["browse_inventories"],
                            except: ["annapurna_labs"]
                        },
                        {
                            title: "Summarized",
                            link: "stock-summarized-for-annapurna",
                            permissions: ["browse_inventories"],
                            canSee: ["annapurna_labs"]
                        },
                        {
                            title: "Map",
                            link: "stock-map",
                            permissions: ["browse_inventories"],
                        },
                    ]
                },
                {
                    title: "Batches",
                    link: "batches",
                    icon: "list",
                    permissions: ["browse_batches"]
                },
                {
                    title: "Hubs",
                    link: null,
                    expanded: false,
                    icon: "home",
                    permissions: [
                        "add_inventories_conversion"
                    ],
                    items: [
                        {
                            title: "Manage",
                            link: "hubs",
                            permissions: ["browse_inventories_conversion"]
                        },
                        {
                            title: "Groups",
                            link: "hub-groups",
                            permissions: ["browse_inventories_conversion"]
                        }
                    ]
                },
                {
                    title: "Part Masters",
                    link: null,
                    icon: "art_track",
                    permissions: ["browse_part_masters"],
                    items: [
                        {
                            title: "Manage",
                            link: "part-master-list",
                            permissions: ["browse_part_masters"]
                        }
                    ]
                },
                {
                    title: "Packages",
                    link: null,
                    icon: null,
                    permissions: ["manage_partner_packages"],
                    items: [
                        {
                            title: "Boxes",
                            link: "packages-boxes",
                            icon: null,
                            permissions: ["manage_partner_packages"],
                        },
                        {
                            title: "Pallets",
                            link: "packages-pallets-list",
                            icon: null,
                            permissions: ["manage_partner_packages"],
                        }
                    ]
                },
                {
                    title: "Inventory Condition",
                    icon: "find_replace",
                    link: "inventory-condition-bias",
                    permissions: ["browse_inventory_conditions"],
                    canSee: ["annapurna_labs"]
                },
                {
                    title: "MRB",
                    link: null,
                    expanded: false,
                    icon: "shuffle",
                    permissions: ["edit_warehouse_transactions", "read_reports"],
                    items: [
                        {
                            title: "Adjust transactions",
                            link: "adjust-transactions",
                            permissions: ["edit_warehouse_transactions"],
                        },
                        {
                            title: "Transactions w/o items",
                            link: "transactions-without-items",
                            permissions: ["edit_warehouse_transactions"],
                        },
                        {
                            title: "Transactions w/o orders",
                            link: "transactions-without-orders",
                            permissions: ["edit_warehouse_transactions"],
                        },
                        {
                            title: "Procedures",
                            link: "procedures",
                            iconFa: "barcode",
                            permissions: ["read_reports"],
                        },
                    ]
                },
                {
                    title: "Allocations",
                    link: "allocations",
                    icon: "security",
                    permissions: ["browse_inventory_allocations"],
                },
                {
                    title: "In transit",
                    link: null,
                    expanded: false,
                    icon: "local_shipping",
                    permissions: ["browse_inventory_allocations"],
                    items: [
                        {
                            title: "In transit",
                            link: "in-transit",
                            permissions: ["browse_orders_items"],
                        },
                        {
                            title: "Detailed",
                            link: "allocations-in-transit-detailed",
                            permissions: ["browse_inventory_allocations"],
                        },
                        {
                            title: "Summarized",
                            link: "allocations-in-transit-summarized",
                            permissions: ["browse_inventory_allocations"],
                        },
                    ]
                },
                {
                    title: "Transactions",
                    link: null,
                    expanded: false,
                    icon: "compare_arrows",
                    permissions: [
                        "browse_warehouse_transactions",
                        "browse_warehouse_transaction_replenishes"
                    ],
                    items: [
                        {
                            title: "Warehouse transactions",
                            link: "transactions-stock-all",
                            permissions: ["browse_warehouse_transactions"]
                        },
                        {
                            title: "Invalid transaction",
                            link: "transactions-invalid",
                            permissions: ["browse_warehouse_transactions"]
                        },
                        {
                            title: "Transaction replenishment",
                            link: "transactions-replenishment",
                            permissions: ["browse_warehouse_transaction_replenishes"]
                        },
                        {
                            title: "Deleted transactions",
                            link: "transactions-deleted",
                            permissions: ["browse_deleted_warehouse_transactions"]
                        }
                    ]
                },
            ]
        },
        {
            title: "Docs",
            link: null,
            expanded: false,
            icon: "assignment",
            permissions: [
                "browse_country_special_instructions",
                "browse_partner_branches"
            ],
            items: [
                {
                    title: "Branches",
                    link: "branches",
                    icon: "share",
                    permissions: ["browse_partner_branches"]
                },
                {
                    title: "Countries instructions",
                    link: "countries-instructions",
                    icon: "assignment",
                    permissions: ["browse_country_special_instructions"]
                },
            ]
        },
        {
            title: "SLA",
            link: null,
            icon: "timer",
            permissions: ["browse_service_level_trackings"],
            items: [
                {
                    title: "Tracking",
                    link: "sla-tracking",
                    icon: "timelapse",
                    permissions: ["browse_service_level_trackings"],
                },
                {
                    title: "Milestones",
                    link: "milestones",
                    icon: "flag",
                    permissions: ["browse_milestones"],
                }
            ]
        },
        {
            title: "Inspection",
            link: null,
            expanded: false,
            icon: "ballot",
            permissions: ["browse_inspections", "show_inspection_results", "show_inspections_choose"],
            items: [
                {
                    title: "Results",
                    link: "inspection-results",
                    permissions: ["show_inspection_results"],
                },
                {
                    title: "List",
                    link: "inspections",
                    permissions: ["browse_inspections"],
                },
                {
                    title: "Choose",
                    link: "inspection-choose",
                    permissions: ["show_inspections_choose"],
                }
            ]
        },
        {
            title: "Reports",
            link: null,
            expanded: false,
            icon: "description",
            permissions: ["browse_reports"],
            items: [
                {
                    title: "All",
                    link: "reports",
                    permissions: ["browse_reports"],
                },
                {
                    title: "Favorite",
                    icon: "star",
                    link: "reports/favorite",
                    permissions: ["browse_reports"],
                },
            ]
        },
        {
            title: "Settings",
            link: "settings",
            icon: "settings",
            permissions: ["browse_admin"],
        },
        {
            title: "Integrations",
            link: null,
            expanded: false,
            icon: "extension",
            permissions: ["browse_integrations"],
            items: [
                {
                    title: "Browse",
                    link: "integrations",
                    permissions: ["browse_integrations"]
                },
                {
                    title: "Add",
                    link: "integrations/add",
                    permissions: ["add_integrations"]
                },
                {
                    title: "API",
                    link: "recent-jobs",
                    permissions: ["browse_integrations"]
                }
            ]
        },
        {
            title: "Analytics",
            link: "analytics",
            icon: "multiline_chart",
            permissions: ["browse_orders"]
        },
        {
            title: "Customers",
            link: null,
            icon: "supervisor_account",
            permissions: ["browse_customers", "browse_modix_reseller"],
            items: [
                {
                    title: "Manage",
                    link: "customers",
                    icon: null,
                    permissions: ["browse_customers"]
                },
                {
                    title: "Resellers",
                    link: "resellers",
                    icon: null,
                    canSee: ["modix"],
                    permissions: ["browse_modix_resellers"],

                },
            ]
        },
        {
            title: "Teams",
            link: "team",
            icon: "record_voice_over",
            permissions: [
                "browse_teams"
            ]
        },
        {
            title: "Conversions",
            link: null,
            expanded: false,
            iconFa: "balance-scale",
            permissions: [
                "browse_part_master_conversions"
            ],
            items: [
                {
                    title: "PartMasters analogs",
                    link: "part-master-analogs",
                    permissions: ["browse_part_master_conversions"]
                },
                {
                    title: "PartMasters Kits",
                    link: "part-master-kits",
                    permissions: ["browse_part_master_conversions"]
                },
                {
                    title: "PartMaster conversions",
                    link: "part-master-conversions",
                    permissions: ["browse_part_master_conversions"],
                    canSee: ["infinidat","mellanox"],
                },
                {
                    title: "Couriers services",
                    link: "courier-services-conversions",
                    permissions: ["browse_courier_service_conversions"]
                }
            ]
        },
    ]
};
