import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: "strPad"
})
export class StrPadPipe implements PipeTransform {

    public transform(value: string | number, type: "start" | "end", length: number, pad: string): string {
        if (type === "start") {
            return ("" + value).padStart(length, pad);
        } else if (type === "end") {
            return ("" + value).padEnd(length, pad);
        }
        return "" + value;
    }
}
