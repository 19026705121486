<common-confirm></common-confirm>
<div class="margin-bottom-20" [dragula]="bagName" [(dragulaModel)]="tasks" [draggable]="editable">
    <ng-container *ngFor="let task of tasks">
        <mat-card class="task drag-bag" [class.edit]="task.edit">
            <div style="width: 100%">

                <div class="text">
                    <ng-container *ngIf="!task.edit">
                        <div class="flex align-center">
                            <span class="drag-handle material-icons" style="cursor: move;">drag_indicator</span>
                            <mat-checkbox [value]="task.complete" [checked]="task.complete"
                                          (change)="checkTask($event, task)"></mat-checkbox>

                            <div class="label" style="font-size: 120%;" (dblclick)="setTaskEdit(task)"
                                 [class.complete]="task.complete" [innerHTML]="task.text | mentions | linebreaks">
                            </div>
                        </div>

                        <mat-divider></mat-divider>

                        <table class="margin-bottom-10 margin-top-10">
                            <tr class="due-date">
                                <td>
                                    <b>Created at:</b>
                                </td>
                                <td>
                                     <span class="grey">
                                        {{task.created_at | tz: "Asia/Jerusalem": null :"DD.MM.yyyy HH:mm": true}}
                                         <ng-container *ngIf="timezone !=='Asia/Jerusalem'">
                                            /
                                             {{task.created_at | tz: "Asia/Jerusalem": timezone :"DD.MM.yyyy HH:mm": true}}
                                        </ng-container>
                                    </span>
                                </td>

                            </tr>

                            <tr *ngIf="task.due_date" class="due-date">
                                <td>
                                    <b>Due date:</b>
                                </td>
                                <td>
                                     <span [class]="dueDateClass(task.due_date)">
                                        {{task.due_date | tz: "Asia/Jerusalem": null :"DD.MM.yyyy HH:mm":true}}
                                         <ng-container *ngIf="timezone !=='Asia/Jerusalem'">
                                            /
                                             {{task.due_date | tz: "Asia/Jerusalem": timezone :"DD.MM.yyyy HH:mm":true}}
                                         </ng-container>
                                     </span>
                                </td>

                            </tr>

                            <tr *ngIf="task.complete_at" class="due-date">
                                <td>
                                    <b>Complete at:</b>

                                </td>
                                <td>
                                     <span class="grey">
                                        {{task.complete_at | tz: "Asia/Jerusalem": null :"DD.MM.yyyy HH:mm":true}}
                                         <ng-container *ngIf="timezone !=='Asia/Jerusalem'">
                                            /
                                             {{task.complete_at | tz: "Asia/Jerusalem": timezone :"DD.MM.yyyy HH:mm":true}}
                                         </ng-container>
                                    </span>
                                </td>
                            </tr>
                        </table>

                        <div *ngIf="task.comment" class="margin-bottom-10">
                            <b>Comment: {{task.comment}}</b>
                        </div>

                        <div>
                            <ng-template ngFor let-activity [ngForOf]="task.activities">
                                <mat-divider></mat-divider>
                                <div class="margin-top-10 margin-bottom-10">
                                    <b>Activity:</b> {{ activity.user.name }} Changed Due Date from
                                    {{ activity.previous_due_date | tz: "Asia/Jerusalem": timezone :"DD.MM.yyyy HH:mm":true}}
                                    To {{ activity.due_date |tz: "Asia/Jerusalem": timezone :"DD.MM.yyyy HH:mm":true}}
                                    On {{ activity.created_at | tz: "Asia/Jerusalem": timezone :"DD.MM.yyyy HH:mm":true}}
                                    <br>
                                    <div *ngIf="activity?.comment">
                                        <b>Comment: {{ activity?.comment }}</b>
                                    </div>
                                </div>
                            </ng-template>
                        </div>

                    </ng-container>


                </div>

                <ng-container *ngIf="editable">
                    <div class="menu pointer" [matMenuTriggerFor]="menu">
                        <mat-icon>more_horiz</mat-icon>
                    </div>

                    <mat-menu #menu="matMenu">
                        <a mat-menu-item (click)="setTaskEdit(task)">Edit</a>
                        <a mat-menu-item (click)="delete(task, true)">Convert into message</a>
                        <a mat-menu-item (click)="delete(task)">Delete</a>
                    </mat-menu>
                </ng-container>
            </div>
        </mat-card>
    </ng-container>
</div>
<div *ngIf="editable" class="margin-bottom-20">
    <button *ngIf="!showTaskForm" mat-raised-button color="accent"
            (click)="setTaskNew()">
        Add task
    </button>
</div>
