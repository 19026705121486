import {Injectable} from "@angular/core";
import {Api, ApiService} from "./api.service";


@Injectable()
export class TransactionService {

    public constructor(private apiService: ApiService) {
    }

    public linkOrderItem(transaction_id: number, order_item_id: number
        , force: boolean = false): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Put, ["transaction", transaction_id + "", "link_order_item"],
            {
                order_item_id,
                force
            });
    }

    public releaseInvalidTransactions(warehouse_transactions_ids: number[]): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Post, ["transaction", "invalid", "update-inventory"], {
            warehouse_transactions_ids
        });
    }

    public transactionReplenishmentCreate(body: {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Post, ["transaction", "replenishment"], body);
    }

    public transactionReplenishmentStatusUpdate(body: {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Put, ["transaction", "replenishment"], body);
    }

    public splitTransaction(id: number, quantity: number): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Put, ["transaction", "" + id, "split"], {quantity});
    }

}
