import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ModalService} from "../../../../../services/modal.service";
import {AddressFormComponent} from "../index";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {ToastService} from "../../../../../../common/services/toast.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {MatSlideToggle} from "@angular/material/slide-toggle";
import {Table2Component, TableCellRowValue, TableCellSubscriber} from "../../../../../../common/components/table2";
import {FormControl} from "@angular/forms";
import {debounceTime, takeUntil} from "rxjs/operators";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {UserService} from "../../../../../../common/services/user.service";
import {Contact} from "../../../../../../common/interfaces/contact.interface";
import {Api3Service} from "../../../../../../common/services/api3.service";
import {PartnerService} from "../../../../../../common/services/partner.service";
import {Router} from "@angular/router";

@Component({
    selector: "section-address-list",
    template: `
        <common-confirm></common-confirm>
        <div class="pull-right margin-right-20 margin-top-5" *ngIf="state.params.customer">
            <button mat-raised-button type="button" color="accent" (click)="backToCustomer()">
                Back to customer list
            </button>
        </div>
        <h1 class="container-heading">Addresses list</h1>
        <mat-card>
            <div>
                <mat-tab-group dynamicHeight>
                    <mat-tab label="List">
                        <common-table2 #listTableRef *ngIf="listTable" [settings]="listTable">
                            <div row1 class="row flex align-center">
                                <button mat-raised-button color="primary" class="main margin-right-10"
                                        (click)="showAddForm()">
                                    Add
                                </button>

                                <mat-checkbox class="margin-right-10" [formControl]="show_only_active">
                                    Only active
                                </mat-checkbox>
                            </div>

                        </common-table2>
                    </mat-tab>

                    <mat-tab label="Logs">
                        <common-table2 #logTableRef *ngIf="listLogsTable" [settings]="listLogsTable">

                        </common-table2>
                    </mat-tab>
                </mat-tab-group>

            </div>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressListComponent implements OnInit, OnDestroy {

    protected destroy$: EventEmitter<boolean> = new EventEmitter(false);

    public readonly state: Base.IState;

    public listTable: Table.ISettings;

    public listLogsTable: Table.ISettings;

    public show_only_active: FormControl = new FormControl(true);

    @ViewChild("listTableRef", {static: false})
    public listTableRef: Table2Component;

    @ViewChild("logTableRef", {static: false})
    public logTableRef: Table2Component;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    public customer: Contact.ICustomer;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private modalService: ModalService,
        private toastService: ToastService,
        private spinnerService: SpinnerService,
        private userService: UserService,
        private router: Router
    ) {
    }

    private async getCustomer(): Promise<any> {
        const {data}: Api.IResponse = await this.api3Service.get(
            `${this.state.section}/customers/${this.state.params.customer}`);

        if (data) {
            this.customer = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    private getUrl(): Table.ITableApi {
        return {
            url: [this.state.section, "addresses"],
            query: {
                customer_id: this.state.params.customer ? this.state.params.customer : null,
                only_active: this.show_only_active.value,
                relations: [
                    "Author:id,name"
                ]
            },
            version: 3
        };
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        const actions: Table.IAction[] = [];

        const columns: Table.ICol[] = [
            {
                data: "id",
                title: "ID"
            },
            {
                data: "address_name",
                title: "Site name"
            },
            {
                data: "ref",
                title: "Ref"
            },
            {
                data: "ref2",
                title: "Ref2"
            },
            {
                data: "address",
                title: "Address"
            },
            {
                data: "city",
                title: "City"
            },
            {
                data: "zip",
                title: "ZIP code"
            },
            {
                data: "state",
                title: "State"
            },
            {
                data: "country",
                title: "Country"
            },
            {
                data: "author.name",
                name: "Author.name",
                title: "Created/Changed by"
            },
            {
                data: "created_at",
                title: "Created at"
            },
            {
                data: "updated_at",
                title: "Updated at"
            },
        ];


        if (this.userService.validatePermissions("edit_addresses")) {
            actions.push({
                name: "view",
                title: "View",
                click: (row: any): void => {
                    this.showEditForm(row);
                }
            });

            columns.splice(0, 0, {
                data: "is_visible",
                title: "Active",
                factory: {
                    component: MatSlideToggle,
                    properties: {
                        color: "primary",
                        checked: new TableCellRowValue("is_visible"),
                        change: new TableCellSubscriber((row: any, event: any) => {
                            this.spinnerService.show();
                            this.api3Service.put(`${this.state.section}/addresses/${row.id}/toggle-visibility`)
                                .then(() => {
                                    this.spinnerService.hide();
                                    this.listTableRef.reload(this.getUrl());
                                });
                        })
                    }
                }
            });

        }

        if (this.userService.validatePermissions("delete_adresses")) {
            actions.push({
                name: "delete",
                title: "Delete",
                click: (row: any): void => {
                    this.delete(row);
                }
            });
        }

        this.listTable = {
            table_id: "sndukjdrgyklsd",
            actions,
            api: this.getUrl(),
            columns,
            multi_select: false
        };
        this.changeDetectorRef.markForCheck();
    }

    private prepareLogs(): void {
        this.listLogsTable = {
            api: {
                url: [
                    this.state.section,
                    "addresses",
                    "activities"
                ],
                query: {
                    partner_id: PartnerService.partner.id,
                    customer_id: this.customer ? this.customer.id : null,
                    relations: [
                        "User:id,name"
                    ]
                },
                version: 3
            },
            columns: [
                {
                    data: "name",
                    title: "Log"
                },
                {
                    data: "description",
                    title: "Description"
                },
                {
                    data: "properties.previous_state",
                    title: "From"
                },
                {
                    data: "properties.current_state",
                    title: "To"
                },
                {
                    data: "user.name",
                    name: "User.name",
                    title: "By"
                },
                {
                    data: "created_at",
                    title: "At"
                },
            ],
            sort_default: {
                data: "created_at",
                dir: "desc"
            }
        };
        this.changeDetectorRef.markForCheck();
    }


    /**
     * Show edit form
     * @param address
     */
    private async showEditForm(address: Contact.IAddress): Promise<any> {
        await this.modalService.open(AddressFormComponent, {
            data: address,
            customer: this.customer,
            action: "edit",
            getData: true,
            canEdit: true,
            canDelete: true,
            state: this.state,
        });

        this.listTableRef.reload();
    }

    /**
     * Delete address
     * @param address
     */
    public async delete(address: Contact.IAddress): Promise<any> {
        if (await this.confirmRef.confirm("Are you sure want to delete current address?")) {
            this.spinnerService.show();
            const {message, type}: Api.IResponse = await this.api3Service.delete(
                `${this.state.section}/addresses/${address.id}`, {
                    exclude_instance: "customer",
                    exclude_instance_id: this.customer.id
                });
            if (type as string === "success") {
                this.toastService.show(message, "success");
                this.listTableRef.reload();
            }
            this.spinnerService.hide();
        }
    }

    /**
     * Show add new address form
     * @returns {void}
     */
    public async showAddForm(): Promise<any> {

        await this.modalService.open(AddressFormComponent, {
            action: "add",
            customer: this.customer,
            canEdit: true,
            state: this.state,
        });
        this.listTableRef.reload();

    }

    public backToCustomer(): void {
        this.router.navigate([
            this.state.section,
            "customers"
        ]);
    }

    public ngOnInit(): void {

        if (this.state.params.customer && this.userService.validatePermissions("browse_customers")) {
            this.getCustomer();
        }

        this.prepareList();
        this.prepareLogs();

        this.show_only_active.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe(() => {
                this.listTableRef.reload(this.getUrl());
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }


    public ngConfig(): Base.IConfig {
        return {
            name: "addresses",
            actions: {
                "list": ["browse_addresses"]
            }
        };
    }
}
