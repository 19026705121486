<common-confirm></common-confirm>

<h1 class="container-heading">{{edit ? "Edit" : "Add"}} reseller</h1>

<mat-card>
    <mat-card-content>

        <form *ngIf="formGroup" [formGroup]="formGroup" (submit)="handleFormSubmit()" autocomplete="off" novalidate>

            <div class="group flex">
                <mat-form-field class="half">
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="name" required>
                </mat-form-field>

                <mat-form-field class="half">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email">
                </mat-form-field>
            </div>
            <div class="actions">
                <button mat-raised-button color="primary" type="submit" class="main" [disabled]="!formGroup.valid">
                    {{edit ? "Save" : "Add"}}
                </button>
                <button mat-raised-button *ngIf="modal.params.canDelete && edit"
                        type="button" color="warn" (click)="delete()">
                    Delete
                </button>
            </div>

        </form>

    </mat-card-content>
</mat-card>
