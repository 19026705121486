<common-confirm></common-confirm>
<common-table2 #inboundTable *ngIf="inboundItemsList"
               [settings]="inboundItemsList"
               (gotData)="gotData($event)">
    <div row1>
        <ng-template
                [ngIf]="createInboundShipment && orderData.service_level.order_type.order_name !== 'assembly'">
            <button mat-raised-button color="accent"
                    [disabled]="orderData.service_level.order_type.slug === 'transfer' && orderData.service_level.properties?.sync_items_lines"
                    *userAllowed="'browse_admin'"
                    (click)="actionHandler({name:'shipment',data: null},'inbound')">
                <mat-icon>local_shipping</mat-icon>
                Create shipment
            </button>
        </ng-template>

        <button
                *ngIf="userService.validatePermissions(['add_orders_items']) && (!orderData.confirmed || userService.validatePermissions(['edit_order_item_after_confirmation']))"
                mat-raised-button
                color="primary"
                [disabled]="orderData.service_level.order_type.slug === 'transfer' && orderData.service_level.properties?.sync_items_lines"
                (click)="actionHandler(null, 'inbound')">
            Add inbound item
        </button>

        <button *ngIf="userService.validatePermissions(['browse_reports'])&& createInboundShipment"
                mat-raised-button type="button" color="primary"
                (click)="onGenerateReport.emit(true)">
            Items report
        </button>

        <button mat-raised-button type="button"
                (click)="actionHandler({name: 'update_statuses',data:null},null)">
            Update statuses
        </button>
    </div>
</common-table2>
