<ng-template [ngIf]="!force2fa">
    <div id="login">
        <mat-card class="mat-elevation-z2">

            <ng-template [ngIf]="progress">
                <mat-progress-bar [color]="'primary'" [mode]="'indeterminate'" class="progress"></mat-progress-bar>
            </ng-template>

            <img src="assets/images/logo.png" class="logo">

            <mat-card-content>
                <form [formGroup]="form" (submit)="login()">
                    <div class="group flex" *ngIf="!is2fa">
                        <mat-form-field class="full">
                            <mat-label>Username / Email</mat-label>
                            <input matInput formControlName="username" type="text" data-required>
                        </mat-form-field>

                        <mat-form-field class="full">
                            <mat-label>Password</mat-label>
                            <input matInput formControlName="password" [type]="showPass ? 'text':'password'"
                                   data-required>
                            <mat-icon matIconSuffix (click)="showPass = !showPass">
                                {{showPass ? 'visibility' : 'visibility_off'}}
                            </mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="group flex" *ngIf="is2fa">
                        <div class="full text-center margin-bottom-20">
                            <img src="/assets/images/google2fa.png" alt="">
                        </div>
                        <mat-form-field class="full">
                            <mat-label>Secret code</mat-label>
                            <input matInput formControlName="secret" type="text" autocomplete="off"
                            >
                        </mat-form-field>
                    </div>
                    <div class="actions">
                        <button mat-raised-button color="primary" type="submit">
                            {{is2fa ? 'Submit' : 'Login'}}
                        </button>
                    </div>

                    <div *ngIf="is2fa">
                        <span class="pointer" (click)="sendCodeToEmail()">Send code to email</span>
                    </div>

                    <div *ngIf="!is2fa">
                        <span class="pointer" (click)="openForgotPasswordModal()">Forgot password?</span>
                    </div>
                </form>
            </mat-card-content>

        </mat-card>
    </div>
</ng-template>
<div id="force2fa" *ngIf="force2fa && user">
    <common-google2fa [user]="user" (result)="goToEntrance()" [showLogoutButton]="true"></common-google2fa>
</div>
<div id="app-install-modal" *ngIf="iosOn">
    <mat-icon>add_box</mat-icon>
    Install this webapp: tap <img src="../../../../assets/icons/ios/share-52.png" alt="share button"/> and then Add to
    homescreen
</div>

