<mat-card class="mat-elevation-z2">

    <ng-template [ngIf]="progress">
        <mat-progress-bar [color]="'primary'" [mode]="'indeterminate'" class="progress"></mat-progress-bar>
    </ng-template>

    <img src="assets/images/logo.png" class="logo">

    <mat-card-content>
        <h2>
            Create account
        </h2>
        <form [formGroup]="form" (submit)="submit()">
            <div class="group flex">
                <mat-form-field class="full">
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="name" type="text">
                </mat-form-field>

                <mat-form-field class="full">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" type="email">
                </mat-form-field>


                <mat-form-field class="full">
                    <mat-label>Password</mat-label>
                    <input matInput formControlName="password" [type]="showPass ? 'text':'password'">
                    <mat-icon matIconSuffix (click)="showPass = !showPass">
                        {{showPass ? 'visibility' : 'visibility_off'}}
                    </mat-icon>
                </mat-form-field>

                <mat-form-field class="full">
                    <mat-label>Password confirmation</mat-label>
                    <input matInput formControlName="password_confirmation" [type]="showConfirm ? 'text':'password'">
                    <mat-icon matIconSuffix (click)="showConfirm = !showConfirm">
                        {{showConfirm ? 'visibility' : 'visibility_off'}}
                    </mat-icon>
                </mat-form-field>
            </div>

            <div class="actions">
                <button mat-raised-button color="primary" type="submit">
                    Create account
                </button>
            </div>
            <div>
                <a [routerLink]="['/login']">Already have account?</a>
            </div>
        </form>
    </mat-card-content>

</mat-card>
