import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Table2Component} from "../../../../../../common/components/table2/table.component";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {ReportService} from "../../../../../../common/services/report.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {ToastService} from "../../../../../../common/services/toast.service";


@Component({
    selector: "section-shipping-mode-list",
    template: `
        <common-confirm></common-confirm>
        <h1 class="container-heading">Shipping modes</h1>

        <mat-card>
            <mat-card-content>
                <common-table2 *ngIf="listTable" [settings]="listTable">
                    <div row1>
                        <button type="button" mat-raised-button color="primary" (click)="exportServices()">
                            Export all services
                        </button>

                        <button type="button" mat-raised-button color="accent" (click)="importServices()">
                            Import couriers services
                        </button>
                    </div>
                </common-table2>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShippingModeListComponent implements Base.IComponent, OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    public listTable: Table.ISettings;

    public showExportButton: boolean = false;

    public readonly state: Base.IState;

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private reportService: ReportService,
        private spinnerService: SpinnerService,
        private toastService: ToastService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {

        this.listTable = {
            table_id: "Et3ydK93",
            api: {
                url: ["shipping_mode"]
            },
            actions: [],
            columns: [
                {
                    data: "display_name",
                    title: "Name"
                },
                {
                    data: "slug",
                    title: "Slug"
                },
                {
                    data: "id",
                    title: "",
                    render: (): string => {
                        return "<button class='mat-mdc-raised-button mdc-button button-150' " +
                            "data-action='couriers' type='button'>"
                            + "Couriers"
                            + "</button>";
                    },
                    click: (row: any): void => {
                        this.router.navigate([
                            this.state.section,
                            "couriers",
                            "filter",
                            "shipping-mode",
                            row.id
                        ]);
                    },
                    searchable: false,
                    sortable: false
                },
            ]
        };
        this.changeDetectorRef.markForCheck();
    }


    public importServices(): void {
        this.router.navigate([
            this.state.section,
            "csv-import",
            "wizard",
            "type",
            "courier_service",
            "back_to",
            btoa(this.router.url)
        ]);
    }

    public async exportServices(): Promise<any> {
        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.reportService
            .generateReport("couriers-services-export");
        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
        }
    }

    public ngOnInit(): void {
        this.prepareList();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "shipping-modes",
            actions: {
                "browse": ["browse_shipping_modes"]
            }
        };
    }
}
