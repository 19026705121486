import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Router} from "@angular/router";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {UserService} from "../../../../../../common/services/user.service";

import {HelpersService} from "../../../../../../common/services/helpers.service";

import * as moment from "moment";
import {Moment} from "moment";
import {Order} from "../../../../../../common/interfaces/order.interface";
import {ModalService} from "../../../../../services/modal.service";
import {CommonFormComponent} from "../../../../../../common/components/form";
import {FilterComponent} from "../../../../../../common/components/filter/filter.component";
import {StorageService} from "../../../../../../common/services/storage.service";
import {AllByItemModalComponent} from "./all-by-item-modal.component";
import {FormControl} from "@angular/forms";
import {Subscription} from "rxjs";
import {take} from "rxjs/operators";
import {Table2Component} from "../../../../../../common/components/table2";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {MatDatepicker} from "@angular/material/datepicker";
import {AppStateService} from "../../../../../../common/services/app-state.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {RemarksSidebarComponent} from "../../../../common/components/order";
import {Api3Service} from "../../../../../../common/services/api3.service";
import {AmplitudeService} from "../../../../../../common/services/amplitude.service";

@Component({
    selector: "section-order-list-by-item",
    template: `
        <common-confirm></common-confirm>

        <h1 class="container-heading">{{params.title}}</h1>
        <mat-card>
            <div class="filter-plus-content">
                <div class="filter-container" [class.open]="showFilter">
                    <div>
                        <common-filter *ngIf="filters" [filters]="filters"
                        [componentName]="'List by items'" (changeEvent)="getData($event)"
                                       configId="order-list-by-item-filter"></common-filter>
                        <common-simple-spinner *ngIf="!filters"></common-simple-spinner>
                    </div>
                </div>
                <div>
                    <common-table2 *ngIf="listTable" [settings]="listTable"
                                   [scrollHeightValue]="290"
                                   (gotData)="showExportButton = $event.hasData">
                        <div class="flex row align-center">
                            <button mat-mini-fab (click)="toggleFilter()" class="margin-right-10">
                                <mat-icon title="Filters">filter_list</mat-icon>
                            </button>
                            <ng-template [ngIf]="params.actions.add">
                                <a *userAllowed="'add_orders'" routerLink="add" mat-raised-button
                                   color="primary" class="create">Add</a>
                            </ng-template>
                            <ng-template [ngIf]="params.actions.export && showExportButton">
                                <button mat-raised-button type="button" color="primary" (click)="report()">
                                    Orders report
                                </button>
                            </ng-template>
                        </div>
                    </common-table2>
                </div>
            </div>
        </mat-card>
        <section-remarks-sidebar #sidebar></section-remarks-sidebar>

        <input [ngxMatDatetimePicker]="confirmationDatePicker" type="hidden" [formControl]="confirmationDate">
        <ngx-mat-datetime-picker #confirmationDatePicker [touchUi]="true" [showSpinners]="true">
            <ng-template>
                <span>Apply</span>
            </ng-template>
        </ngx-mat-datetime-picker>

        <input [ngxMatDatetimePicker]="requestDatePicker" type="hidden" [formControl]="requestDate">
        <ngx-mat-datetime-picker #requestDatePicker [touchUi]="true" [showSpinners]="true">
            <ng-template>
                <span>Apply</span>
            </ng-template>
        </ngx-mat-datetime-picker>

    `,
    styleUrls: ["list-by-item.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class OrderListByItemComponent implements OnInit {

    /**
     * Is table ready?
     * @type {boolean}
     */
    private ready: boolean = false;

    private state: Base.IState;

    @Input()
    public params: Order.IOrderListParams;

    @Output()
    public result: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    @ViewChild("sidebar", {static: false})
    public sidebarRef: RemarksSidebarComponent;

    @ViewChild(FilterComponent, {static: false})
    public filterRef: FilterComponent;

    @ViewChild("confirmationDatePicker", {static: false})
    public confirmationDatePicker: MatDatepicker<any>;

    @ViewChild("requestDatePicker", {static: false})
    public requestDatePicker: MatDatepicker<any>;

    public partnerSlug: string;

    /**
     * Filters list
     */
    public filters: { [key: string]: any };

    public showFilter: boolean = false;


    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.ISettings;

    public showExportButton: boolean = false;

    public confirmationDate: FormControl = new FormControl(null);
    public requestDate: FormControl = new FormControl(null);

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private userService: UserService,
        private modalService: ModalService,
        private storageService: StorageService,
        private spinnerService: SpinnerService,

    ) {
    }

    private getColumns(): Table.ICol[] {
        switch (this.partnerSlug) {
            case "mellanox":
                return [
                    {
                        data: "id",
                        title: "Remarks",
                        render: (row: any): string => {
                            return `<button class="mat-mdc-mini-fab mdc-fab mat-badge mat-badge-overlap
                                    mat-badge-below mat-badge-after mat-badge-small">
                                <span class="mat-badge-content mat-badge-active">
                                    ${row.order.order_remarks ? row.order.order_remarks.length : 0}
                                </span>
                                <mat-icon class="material-icons mat-icon">
                                speaker_notes
                                </mat-icon>
                                </button>`;
                        },
                        click: async (row: any): Promise<any> => {
                            this.sidebarRef.show(row.order.id, row.order.ref, true);
                        },
                        sortable: false,
                        searchable: false
                    },
                    {
                        data: "id",
                        title: "Followups",
                        render: (row: any): string => {
                            return `<button class="mat-mdc-mini-fab mdc-fab  mat-badge
                                    mat-badge-overlap mat-badge-below
                                    mat-badge-after mat-badge-small mat-badge-accent">
                                <span class="mat-badge-content mat-badge-active">
                                    ${row.order.followups ? row.order.followups.length : 0}
                                </span>
                                <mat-icon class="material-icons mat-icon">
                                update
                                </mat-icon>
                                </button>`;
                        },
                        click: async (row: any): Promise<any> => {
                            this.router.navigate([
                                this.state.section,
                                "followups-all",
                                "search",
                                "order",
                                row.order.id
                            ]);
                        },
                        sortable: false,
                        searchable: false
                    },
                    {
                        data: "status.name",
                        name: "Status.name",
                        title: "Status",
                        render: (row: any): string => {
                            return "<div class='status " + row.status.name.replace(/[^\w\s]/, "") + "'>"
                                + row.status.name + "</div>";
                        },
                        sortable: false
                    },
                    {
                        data: "item",
                        title: "Item",
                        render: (row: any): string => {
                            return `
                        <div class="multicol">
                            <span class="tableAction title pointer">
                                ${row.item}
                            </span>
                            <div title="${row.part_master ? row.part_master.description : ""}" class="description">
                                <small>
                                    ${row.part_master ? row.part_master.description.substring(0, 30) : ""}
                                </small>
                            </div>
                            <div>
                                <small>
                                    ${row.order ? " Order: " +
                                "<b><a style='cursor: pointer' class='tableAction' " +
                                "data-action='go_to_order'>" + row.order.ref + "</a></b>&nbsp;" : ""}
                                    <br />
                                    <span class="status success"><a class="tableAction">
                                    ${row.order && row.order.ref2 ? " Ref2: <b>" + row.order.ref2 + "</b>" : ""}
                                     </a></span>
                                </small>
                            </div>
                        </div>
                        `;
                        },
                        click: (row: any): void => {
                            this.router.navigate([this.params.state.section, "orders", "view", "id", row.order.id]);
                        }
                    },
                    {
                        data: "order.ref",
                        title: "Order ref",
                        name: "Order.ref",
                        sortable: false
                    },
                    {
                        data: "ref2",
                        title: "Ref2",
                    },
                    {
                        data: "quantity",
                        title: "Quantity",
                        searchable: false
                    },
                    {
                        data: "order.customer.name",
                        name: "Order.Customer.name",
                        title: "Customer",
                        render: (row: any): string => {
                            let string: string = "";
                            if (row.order
                                && row.order.main_address
                                && row.order.main_address.country_iso_2) {
                                string += "<img class='flag' src='assets/images/flags/"
                                    + row.order.main_address.country_iso_2.toLowerCase().trim() + ".svg' " +
                                    "title='" +
                                    row.order.main_address.country.trim() +
                                    "'> ";
                            }
                            if (row.order && row.order.customer && row.order.customer.name) {
                                string += "<span title='" + row.order.customer.name + "'>"
                                    + row.order.customer.name.substr(0, 20);
                            } else if (row.order && row.order.ref2) {
                                string += "<span title='" + row.order.ref2 + "'>" + row.order.ref2 + "</span>";
                            }
                            return string;
                        },
                        sortable: false
                    },
                    {
                        data: "inventory_conversion.customers_inventory_name",
                        name: "InventoryConversion.customers_inventory_name",
                        title: "Origin WH",
                        sortable: false,
                    },
                    {
                        data: "release_date",
                        title: "Scheduled date",
                        render: (row: any): string => {
                            let tAction: string = "distance";
                            if (Number(row.release) !== 1) {
                                tAction = "distance tableAction";
                            }
                            if (row.created_at && row.release_date) {
                                const from: Moment = moment(row.created_at).startOf("day");
                                const to: Moment = moment(row.release_date).startOf("day");
                                const now: Moment = moment().startOf("day");
                                const diff100: number = to.diff(from, "days");
                                const diffNow: number = now.diff(from, "days");
                                return `
                        <div class="timeline">
                            <div class="dates">${to.format("D MMM YY")}</div>
                            <div class="${tAction}">
                                ${diff100 - diffNow > 0 ? diff100 - diffNow : 0}d left
                            </div>
                        </div>`;
                            } else {
                                return `
                        <div class="timeline">
                            <div class="dates">-</div>
                            <div class="${tAction}">-</div>
                        </div>`;
                            }
                        },
                        click: async (row: any): Promise<any> => {
                            if (Number(row.release) !== 1) {
                                this.setConfirmationDate(row.id);
                            }
                        },
                        sortable: true,
                        searchable: false
                    },
                    {
                        data: "customer_request_date",
                        title: "Customer Request Date",
                        render: (row: any): string => {
                            if (row.customer_request_date) {
                                const to: Moment = moment(row.customer_request_date).startOf("day");
                                const from: Moment = moment(row.release_date).startOf("day");
                                const now: Moment = moment().startOf("day");
                                const diff100: number = to.diff(from, "days");
                                const diffNow: number = now.diff(from, "days");
                                let progress: number = diffNow / (diff100 / 100);
                                progress = progress > 0 ? Math.ceil(progress) : 0;
                                progress = progress < 100 ? progress : 100;
                                return `
                    <div class="timeline">
                        <!--<div class="progress" style="width:${progress}%"></div>-->
                        <div class="dates">
                            ${to.format("D MMM YY")}
                        </div>
                        <div class="distance">
                            <!--${diff100 - diffNow > 0 ? diff100 - diffNow : 0}d left-->
                            ${to.format("D MMM YY")}
                        </div>
                    </div>
                `;
                            } else {
                                return `
                     <div class="timeline">
                     <div class="dates">
                            -
                        </div>
                        <div class="distance">
                            -
                        </div>
                    </div>
                `;
                            }
                        },
                        click: async (row: any): Promise<any> => {
                            this.setRequestDate(row.id);
                        },
                        sortable: true,
                        searchable: false
                    },
                    {
                        data: "parcel.shipment.courier_transaction.created_at",
                        name: "Parcel.Shipment.CourierTransaction.created_at",
                        title: "Ship Date",
                        render: (row: any): string => {
                            if (row.parcel && row.parcel.shipment && row.parcel.shipment.courier_transaction
                                && row.parcel.shipment.courier_transaction.created_at) {
                                const to: Moment = moment(row.parcel.shipment.courier_transaction.created_at)
                                    .startOf("day");
                                const from: Moment = moment(row.release_date).startOf("day");
                                const now: Moment = moment().startOf("day");
                                const diff100: number = to.diff(from, "days");
                                const diffNow: number = now.diff(from, "days");
                                let progress: number = diffNow / (diff100 / 100);
                                progress = progress > 0 ? Math.ceil(progress) : 0;
                                progress = progress < 100 ? progress : 100;
                                return `
                    <div class="timeline">
                        <!--<div class="progress" style="width:${progress}%"></div>-->
                        <div class="dates">
                            ${to.format("D MMM YY")}
                        </div>
                        <div class="distance" data-action="confirm_on">
                            ${diff100 - diffNow > 0 ? diff100 - diffNow : 0}d left
                        </div>
                    </div>
                `;
                            } else {
                                return `
                     <div class="timeline">
                     <div class="dates">
                            -
                        </div>
                        <div class="distance" data-action="confirm_on">
                            -
                        </div>
                    </div>
                `;
                            }
                        },
                        sortable: false,
                        searchable: false
                    },
                    {
                        data: "ref",
                        title: "Line Ref",
                        name: "ref",
                        sortable: false
                    },
                ];
            default:
                return [
                    {
                        data: "id",
                        title: "Remarks",
                        render: (row: any): string => {
                            return `<button class="mat-mdc-mini-fab mdc-fab mat-badge mat-badge-overlap
                                    mat-badge-below mat-badge-after mat-badge-small">
                                <span class="mat-badge-content mat-badge-active">
                                    ${row.order && row.order.order_remarks ? row.order.order_remarks.length : 0}
                                </span>
                                <mat-icon class="material-icons mat-icon">
                                speaker_notes
                                </mat-icon>
                                </button>`;
                        },
                        click: async (row: any): Promise<any> => {
                            this.sidebarRef.show(row.order.id, row.order.ref, true);
                            AmplitudeService.eventClick("Orders list by items remarks");
                        },
                        sortable: false,
                        searchable: false
                    },
                    {
                        name: "item",
                        data: "order",
                        title: "",
                        sortable: false,
                        searchable: false,
                        render: (row: any): string => {
                            let actions: string = "";
                            if (this.params.actions.delete && this.userService
                                    .validatePermissions("delete_orders_items")
                                && !row.confirmed) {
                                actions += `<button type="button"
                                        class="mat-mdc-mini-fab mdc-fab mat-warn" title="Delete">
                        <mat-icon class="mat-icon material-icons">delete</mat-icon>
                    </button>`;
                            }
                            return actions;
                        },
                        click: async (row: any): Promise<any> => {
                            if (await this.confirmRef.confirm(`Do you want to delete item "${row.item}?"`)) {
                                const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
                                    ["order_item"], [
                                        {
                                            order_item_id: row.id,
                                        }
                                    ]);
                                if (type as string === "success") {
                                    this.toastService.show(message, "success");
                                    this.tableRef.reload();
                                }
                            }
                        }
                    },
                    {
                        data: "status.name",
                        name: "Status.name",
                        title: "Status",
                        render: (row: any): string => {
                            if (!row.status) {
                                return "";
                            }
                            return "<div class='status " + row.status.name.replace(/[^\w\s]/, "") + "'>"
                                + row.status.name + "</div>";
                        },
                        sortable: false
                    },
                    {
                        data: "item",
                        title: "Item",
                        render: (row: any): string => {
                            return `
                        <div class="multicol">
                            <span class="tableAction title pointer">
                                ${row.item}
                            </span>
                            <div title="${row.part_master ? row.part_master.description : ""}" class="description">
                                <small>
                                    ${row.part_master ? row.part_master.description.substring(0, 30) : ""}
                                </small>
                            </div>
                            <div>
                                <small>
                                    ${row.order ? " Order: " +
                                "<b><a style='cursor: pointer' class='tableAction' " +
                                "data-action='go_to_order'>" + row.order.ref + "</a></b>&nbsp;" : ""}
                                    <br />
                                    <span class="status success"><a class="tableAction">
                                    ${row.order && row.order.ref2 ?
                                (this.partnerSlug === "annapurna_labs" ? " PO: <b>" : " Ref: <b>")
                                + row.order.ref2 + "</b>" : ""} </a></span>
                                </small>
                            </div>
                        </div>
                        `;
                        },
                        click: (row: any): void => {
                            this.router.navigate([this.params.state.section, "orders", "view", "id", row.order.id]);
                        }
                    },
                    {
                        data: "ref",
                        title: "Line Ref",
                        name: "ref",
                        sortable: false
                    },
                    {
                        data: "order.ref",
                        title: "Order ref",
                        name: "Order.ref",
                        sortable: false
                    },
                    {
                        data: "order.ref2",
                        title: "Order ref2",
                        name: "Order.ref2",
                        hidden: true
                    },
                    {
                        data: "order.customer.name",
                        name: "Order.Customer.name",
                        title: "Customer",
                        render: (row: any): string => {
                            let string: string = "";
                            if (row.order
                                && row.order.main_address
                                && row.order.main_address.country_iso_2) {
                                string += "<img class='flag' src='assets/images/flags/"
                                    + row.order.main_address.country_iso_2.toLowerCase().trim() + ".svg' " +
                                    "title='" +
                                    row.order.main_address.country.trim() +
                                    "'> ";
                            }
                            if (row.order && row.order.customer && row.order.customer.name) {
                                string += "<span title='" + row.order.customer.name + "'>"
                                    + row.order.customer.name.substr(0, 20);
                            } else if (row.order && row.order.ref2) {
                                string += "<span title='" + row.order.ref2 + "'>" + row.order.ref2 + "</span>";
                            }
                            return string;
                        },
                        sortable: false
                    },
                    {
                        data: "order.main_address.address_name",
                        title: "Site name",
                        name: "Order.MainAddress.address_name",
                        render: (row: any): string => {
                            return row.order && row.order.main_address
                            && row.order.main_address ? row.order.main_address.address_name : "";
                        },
                        sortable: false
                    },
                    {
                        data: "inventory_conversion.customers_inventory_name",
                        name: "InventoryConversion.customers_inventory_name",
                        title: "Origin WH",
                        sortable: false
                    },
                    {
                        data: "inventory_conversion.customers_sub_inventory",
                        name: "InventoryConversion.customers_sub_inventory",
                        title: "Sub-inventory",
                        sortable: false
                    },
                    {
                        data: "quantity",
                        title: "Quantity",
                        searchable: false
                    },
                    {
                        data: "allocated_item",
                        title: "Allocated",
                        searchable: false
                    },
                    {
                        data: "available",
                        title: "Available",
                        searchable: false
                    },
                    {
                        data: "reserved_items_sum",
                        title: "Total Scheduled",
                        sortable: false,
                        searchable: false,
                        render: (row: any): string => {
                            return "<button class='mat-mdc-raised-button mdc-button' " +
                                "data-action='reserved_items_sum' type='button'>"
                                + (row.reserved_items_sum || 0)
                                + "</button>";
                        },
                        click: (row: any): void => {
                            this.reserved_items_sum_modal(row);
                        }
                    },
                    {
                        data: "allocated_sum",
                        title: "Total Allocated",
                        sortable: false,
                        searchable: false,
                        render: (row: any): string => {
                            return "<button class='mat-mdc-raised-button mdc-button' type='button'>"
                                + (row.allocated_sum || 0)
                                + "</button>";
                        },
                        click: async (row: any): Promise<any> => {
                            this.allocates_items_modal(row);
                        }
                    },
                    {
                        data: "available_sum",
                        title: "In Inventory",
                        sortable: false,
                        searchable: false,
                        render: (row: any): string => {
                            return "<button class='mat-mdc-raised-button mdc-button' type='button'>"
                                + (row.available_sum || 0)
                                + "</button>";
                        },
                        click: async (row: any): Promise<any> => {
                            this.available_inventory_modal(row);
                        }
                    },
                    {
                        data: "scheduled_sum",
                        title: "In Transit",
                        sortable: false,
                        searchable: false,
                        render: (row: any): string => {
                            return "<button class='mat-mdc-raised-button mdc-button' " +
                                "data-action='scheduled_sum' type='button'>"
                                + (row.scheduled_sum || 0)
                                + "</button>";
                        },
                        click: async (row: any): Promise<any> => {
                            this.scheduled_sum_modal(row);
                        }
                    },
                    {
                        data: "release_date",
                        title: "Scheduled date",
                        render: (row: any): string => {
                            let tAction: string = "distance tableAction";
                            if (row.created_at && row.release_date) {
                                const from: Moment = moment(row.created_at).startOf("day");
                                const to: Moment = moment(row.release_date).startOf("day");
                                const now: Moment = moment().startOf("day");
                                const diff100: number = to.diff(from, "days");
                                const diffNow: number = now.diff(from, "days");
                                return `
                        <div class="timeline">
                            <div class="dates">${to.format("D MMM YY")}</div>
                            <div class="${tAction}">
                                ${diff100 - diffNow > 0 ? diff100 - diffNow : 0}d left
                            </div>
                        </div>`;
                            } else {
                                return `
                        <div class="timeline">
                            <div class="dates">-</div>
                            <div class="${tAction}">-</div>
                        </div>`;
                            }
                        },
                        click: async (row: any): Promise<any> => {
                            this.setConfirmationDate(row.id);
                        },
                        sortable: true,
                        searchable: false
                    },
                    {
                        data: "customer_request_date",
                        title: "Customer Request Date",
                        render: (row: any): string => {
                            if (row.customer_request_date) {
                                const to: Moment = moment(row.customer_request_date).startOf("day");
                                const from: Moment = moment(row.release_date).startOf("day");
                                const now: Moment = moment().startOf("day");
                                const diff100: number = to.diff(from, "days");
                                const diffNow: number = now.diff(from, "days");
                                let progress: number = diffNow / (diff100 / 100);
                                progress = progress > 0 ? Math.ceil(progress) : 0;
                                progress = progress < 100 ? progress : 100;
                                return `
                    <div class="timeline">
                        <!--<div class="progress" style="width:${progress}%"></div>-->
                        <div class="dates">
                            ${to.format("D MMM YY")}
                        </div>
                        <div class="distance">
                            <!--${diff100 - diffNow > 0 ? diff100 - diffNow : 0}d left-->
                            ${to.format("D MMM YY")}
                        </div>
                    </div>
                `;
                            } else {
                                return `
                     <div class="timeline">
                     <div class="dates">
                            -
                        </div>
                        <div class="distance">
                            -
                        </div>
                    </div>
                `;
                            }
                        },
                        click: async (row: any): Promise<any> => {
                            this.setRequestDate(row.id);
                        },
                        sortable: false,
                        searchable: false
                    },
                    {
                        data: "parcel.shipment.courier_transaction.created_at",
                        name: "Parcel.Shipment.CourierTransaction.created_at",
                        title: "Ship Date",
                        render: (row: any): string => {
                            if (row.parcel && row.parcel.shipment && row.parcel.shipment.courier_transaction
                                && row.parcel.shipment.courier_transaction.created_at) {
                                const to: Moment = moment(row.parcel.shipment.courier_transaction.created_at)
                                    .startOf("day");
                                const from: Moment = moment(row.release_date).startOf("day");
                                const now: Moment = moment().startOf("day");
                                const diff100: number = to.diff(from, "days");
                                const diffNow: number = now.diff(from, "days");
                                let progress: number = diffNow / (diff100 / 100);
                                progress = progress > 0 ? Math.ceil(progress) : 0;
                                progress = progress < 100 ? progress : 100;
                                return `
                    <div class="timeline">
                        <!--<div class="progress" style="width:${progress}%"></div>-->
                        <div class="dates">
                            ${to.format("D MMM YY")}
                        </div>
                        <div class="distance" data-action="confirm_on">
                            ${diff100 - diffNow > 0 ? diff100 - diffNow : 0}d left
                        </div>
                    </div>
                `;
                            } else {
                                return `
                     <div class="timeline">
                     <div class="dates">
                            -
                        </div>
                        <div class="distance" data-action="confirm_on">
                            -
                        </div>
                    </div>
                `;
                            }
                        },
                        sortable: false,
                        searchable: false
                    },
                    {
                        data: "ref2",
                        title: "Ref2"
                    },
                    {
                        data: "order.ref2",
                        name: "Order.ref2",
                        title: "PO",
                        hidden: true
                    }
                ];
        }
    }

    private getUrl(query: any[] = []): Table.ITableApi {
        let relations: string[] = [];
        let appends: string[] = [];
        switch (this.partnerSlug) {
            case "mellanox":
                relations = [
                    "Status:id,name",
                    "PartMaster:id,item,description",
                    "Order:id,address_id,customer_id,ref,ref2",
                    "Order.Customer:id,name",
                    "Order.MainAddress:id,country_iso_2,country,address_name",
                    "Parcel:id,order_id,shipment_id",
                    "Parcel.Shipment:id",
                    "Parcel.Shipment.CourierTransaction:id,created_at,shipment_id",
                    "InventoryConversion:id,customers_inventory_name",
                    "Order.Followups:id,order_id",
                    "Order.OrderRemarks:id,order_id"
                ];
                break;
            default:
                relations = [
                    "Status:id,name",
                    "PartMaster:id,item,description",
                    "InventoryConversion:id,customers_inventory_name,customers_sub_inventory",
                    "Order:id,address_id,customer_id,ref,ref2",
                    "Order.Customer:id,name",
                    "Order.MainAddress:id,country_iso_2,country,address_name",
                    "Order.OrderRemarks:id,order_id",
                    "Parcel:id,order_id,shipment_id",
                    "Parcel.Shipment:id",
                    "Parcel.Shipment.CourierTransaction:id,created_at,shipment_id",
                ];
                appends = [
                    "allocated_sum",
                    "scheduled_sum",
                    "available_sum",
                    "available",
                    "allocated_item",
                    "reserved_items_sum"
                ];
                break;
        }


        return {
            url: ["partner", this.partnerSlug, "order-items"],
            query: {
                released: "false",
                ...query,
                relations,
                appends
            },
            version: 3
        };
    }

    private getDefaultSorting(): Table.ISort {
        const sort: Table.ISort = {
            data: null,
            dir: null
        };

        switch (this.partnerSlug) {
            case "annapurna_labs":
                sort.data = "release_date";
                sort.dir = "desc";
                break;
            default:
                break;
        }

        return sort;
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(query: any[] = []): void {

        const columns: Table.ICol[] = this.getColumns();

        const exportColumns: any = [...columns];
        exportColumns.splice(0, 2);

        this.listTable = {
            table_id: "kctH6JJ49y0",
            columns,
            search_default: ["item", "Order.ref2", "Order.Customer.name", "Order.ref"],
            api: this.getUrl(query),
            export: {
                file_name: "Orders list by item",
                columns: exportColumns
            },
            infinity_scroll: true,
            sort_default: this.getDefaultSorting()
        };
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Set confirm date for selected row
     */
    private setConfirmationDate(item_id: number): void {
        this.confirmationDatePicker.open();
        const subs: Subscription = this.confirmationDate.valueChanges.pipe(take(1))
            .subscribe(async (value: Moment): Promise<any> => {
                this.spinnerService.show();
                const {message, type, code}: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
                    ["order_item", "confirm"], {
                        id: [item_id],
                        release_date: value.format("DD/MM/YYYY")
                    });
                this.spinnerService.hide();
                if (type as string === "success") {
                    this.toastService.show(message, "success");
                    this.getData();
                }
                if (code === 424) {
                    this.getData();
                }
            });
        this.confirmationDatePicker.closedStream.pipe(take(1))
            .subscribe((val: any): void => {
                subs.unsubscribe();
                this.confirmationDate.setValue(null);
            });
    }

    /**
     * Set request date for selected row
     */
    private setRequestDate(item_id: number): void {
        this.requestDatePicker.open();
        const subs: Subscription = this.requestDate.valueChanges.pipe(take(1))
            .subscribe(async (value: Moment): Promise<any> => {
                const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
                    ["order_item"], [
                        {
                            order_item_id: item_id.toString(),
                            customer_request_date: value.format("DD/MM/YYYY")
                        }
                    ]);
                if (type as string === "success") {
                    this.toastService.show(message, "success");
                    this.tableRef.reload();
                }
            });
        this.requestDatePicker.closedStream.pipe(take(1))
            .subscribe((val: any): void => {
                subs.unsubscribe();
                this.requestDate.setValue(null);
            });
    }

    private async reserved_items_sum_modal(row: any): Promise<any> {
        await this.modalService.open(AllByItemModalComponent, {
            state: this.params.state,
            url: ["order_item", "reserved"],
            hub_id: row.inventory_conversion_id,
            part_master_id: row.part_master.id,
            modalWidth: 1000
        });
    }

    private async allocates_items_modal(row: any): Promise<any> {
        await this.modalService.open(AllByItemModalComponent, {
            state: this.params.state,
            url: ["order_item", "allocated"],
            hub_id: row.inventory_conversion_id,
            part_master_id: row.part_master.id,
            modalWidth: 1000
        });
    }

    private async available_inventory_modal(row: any): Promise<any> {
        await this.modalService.open(AllByItemModalComponent, {
            state: this.params.state,
            url: ["order_item", "available"],
            hub_id: row.inventory_conversion_id,
            part_master_id: row.part_master.id,
            modalWidth: 1000
        });
    }

    private async scheduled_sum_modal(row: any): Promise<any> {
        await this.modalService.open(AllByItemModalComponent, {
            state: this.params.state,
            url: ["order_item", "scheduled"],
            hub_id: row.inventory_conversion_id,
            part_master_id: row.part_master.id,
            modalWidth: 1000
        });
    }

    private buildFilterQuery(filters = null): any {
        if (filters) {
            this.filters = filters;
        }

        const params: { [key: string]: any[] } = {};
        if (this.filters) {
            for (const filter of Object.keys(this.filters)) {
                params[filter] = [];
                for (const option of this.filters[filter]) {
                    if (option.selected) {
                        params[filter].push(option.value);
                    }
                }
            }
        }
        return params;
    }

    /**
     * Get data from api
     */
    public getData(filters = null): void {

        const filter_query: any = this.buildFilterQuery(filters);

        this.filters = null;
        this.changeDetectorRef.markForCheck();

        this.api3Service.request(Api.EMethod.Get, "partner/" + this.partnerSlug + "/order-items/filters",
            {}, filter_query)
            .then(({data}: Api.IResponse): void => {
                this.filters = data;

                this.changeDetectorRef.markForCheck();

                this.storageService.set("Filters_" + window.location.pathname, this.filters);

                if (this.ready) {
                    this.tableRef.reload(this.getUrl(this.buildFilterQuery()));
                } else {
                    this.prepareList(this.buildFilterQuery());
                    this.ready = true;
                }
                this.spinnerService.hide();
            });
    }

    public async report(): Promise<any> {

        this.modalService.open(CommonFormComponent, {
            configUrl: ["report", "orders-list"],
            submitUrl: ["report", "orders-list"],
            asyncKey: HelpersService.randomString()
        });
        AmplitudeService.eventClick("Orders report");
    }

    public toggleFilter(): void {
        this.storageService.set("showFilter", !this.showFilter);
        this.showFilter = this.storageService.get("showFilter");
    }

    public ngOnInit(): void {
        this.filters = this.storageService.get("Filters_" + window.location.pathname);
        this.state = AppStateService.getState();

        if (this.params && this.params.state && this.params.state.section) {
            this.partnerSlug = this.params.state.section_slug;
        }
        this.getData();
        this.showFilter = this.storageService.get("showFilter", false);
        this.changeDetectorRef.markForCheck();
    }
}
