import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Base} from "../../../../../../../../common/interfaces/base.interfaces";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../../common/interfaces/wizard.interface";
import {Api} from "../../../../../../../../common/services/api.service";
import {SpinnerService} from "../../../../../../../../common/services/spinner.service";
import {Api3Service} from "../../../../../../../../common/services/api3.service";
import {Inventory} from "../../../../../../../../common/interfaces/Inventory.interface";
import {Table} from "../../../../../../../../common/interfaces/table.interface";
import {Table2Component} from "../../../../../../../../common/components/table2";
import {HelpersService} from "../../../../../../../../common/services/helpers.service";
import {UcFirstPipe} from "../../../../../../../../common/pipes/ucfirst.pipe";
import { FormControl } from "@angular/forms";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "section-warehouse-procedures-inventory-count-scan-results",
    templateUrl: "results.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    styleUrls: [
        "results.component.scss"
    ]
})
export class WarehouseProceduresInventoryCountScanResultsComponent extends AbstractWizardStepComponent
    implements OnDestroy {

    private state: Base.IState;


    @ViewChild(Table2Component, {static: false})
    public scanFeedTableRef: Table2Component;

    @ViewChild("resultsTable", {static: false})
    public resultsTableRef: Table2Component;
    
    @ViewChild("exceptionsTable", {static: false})
    public exceptionsTableRef: Table2Component;


    public resultsTableSettings: Table.ISettings;
    public exceptionsTableSettings: Table.ISettings;

    public inventoryCount: any;

    public itemsList: string[] = [];

    public onlyExceptions: FormControl = new FormControl(true);

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        private api3Service: Api3Service,
        private spinnerService: SpinnerService,
    ) {
        super(changeDetectorRef);
    }

    private resultsTableApi(): Table.ITableApi {
        return {
            url: [
                this.state.section,
                "inventory-counts",
                this.state.params.count,
                "results"
            ],
            query: {
                relations: [
                    "WarehouseLocation:id,location",
                    "ActualWarehouseLocation:id,location",
                    "Box:id,ref",
                    "Pallet:id,ref",
                ],
                only_exceptions: this.onlyExceptions.value
            },
            version: 3
        };
    }

    private exceptionsTableApi(): Table.ITableApi {
        return {
            url: [
                this.state.section,
                "inventory-counts",
                this.state.params.count,
                "exceptions"
            ],
            version: 3
        };
    }

    private prepareResultsTable(): void {
        this.resultsTableSettings = {
            api: this.resultsTableApi(),
            columns: [
                {
                    data: "item",
                    title: "Item"
                },
                {
                    data: "serial",
                    title: "Serial"
                },
                {
                    data: "quantity",
                    title: "Quantity",
                    searchable: false,
                    cssClassFn: (row: any): string => {
                        return row.quantity !== row.counted ? "error" : "";
                    }
                },
                {
                    data: "counted",
                    title: "Counted",
                    searchable: false,
                    cssClassFn: (row: any): string => {
                        return row.quantity !== row.counted ? "error" : "";
                    }
                },
                {
                    data: "warehouse_location.location",
                    name: "WarehouseLocation.location",
                    title: "Snapshot location",
                    render: (row: any): string => {
                        return row.warehouse_location?.location || "---";
                    },
                    cssClassFn: (row: any): string => {
                        return row.warehouse_location_id !== row.actual_warehouse_location_id ? "error" : "";
                    }
                },
                {
                    data: "actual_warehouse_location.location",
                    name: "ActualWarehouseLocation.location",
                    title: "Scanned location",
                    render: (row: any): string => {
                        return row.actual_warehouse_location?.location || "---";
                    },
                    cssClassFn: (row: any): string => {
                        return row.warehouse_location_id !== row.actual_warehouse_location_id ? "error" : "";
                    }
                },
                {
                    data: "box.ref",
                    name: "Box.ref",
                    title: "Box"
                },
                {
                    data: "pallet.ref",
                    name: "Pallet.ref",
                    title: "Pallet"
                },

            ],
        };
    }

    private prepareExceptionsTable(): void {
        this.exceptionsTableSettings = {
            api: this.exceptionsTableApi(),
            columns: [
                {
                    data: "type",
                    title: "Type"
                },
                {
                    data: "params",
                    title: "Info",
                    render: (row: any): string => {
                        if (row.params.type === "item") {
                            return `Item: ${row.params.item}${row.params.serial ? " | SN: " + row.params.serial : ""}
                             | Qty: ${row.params.quantity}`;
                        } else {
                            return `${UcFirstPipe.transform(row.params.type)}: ${row.params.ref}`;
                        }
                    },
                    searchable: false,
                    sortable: false
                },
                {
                    data: "note",
                    title: "Notes",
                    searchable: false,
                    sortable: false
                },
            ],
        };
    }


    /**
     * Initialize step
     * @param data
     * @returns {Promise<any>}
     */
    public async init(data: Wizard.IData): Promise<any> {
        this.state = data.state;

        this.changeDetectorRef.markForCheck();

        this.prepareResultsTable();
        this.prepareExceptionsTable();

        this.onlyExceptions.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: string) => {
            this.resultsTableRef.reload(this.resultsTableApi());
        });

        this.result.emit({
            action: "result",
            value: true
        });
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

}
