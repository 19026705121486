import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {Modal} from "../../../../../services/modal.service";
import {Table} from "../../../../../../common/components/table/table.component";

@Component({
    selector: "section-parcel-items-list-modal",
    template: `
        <common-confirm></common-confirm>

        <h1 class="container-heading">Items list</h1>
        <mat-card>
            <mat-card-content>
                <common-table *ngIf="listTable" [data]="data" [options]="listTable" (action)="handleListAction($event)">
                </common-table>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParcelItemsListModalComponent implements OnInit {

    public modal: Modal.IModal;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.IOptions;

    public data: any = null;

    public constructor(private changeDetectorRef: ChangeDetectorRef) {
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        const actions: Table.Action.ISettings[] = [];
        this.listTable = {
            actions,
            columns: [
                {
                    data: "ref",
                    title: "Ref"
                },
                {
                    data: "item",
                    title: "Item"
                },
                {
                    data: "serial",
                    title: "Serial"
                },
                {
                    data: "quantity",
                    title: "Quantity"
                }
            ]
        };
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Handle list row action
     * @param {Table.Action.IResult} action
     * @returns {Promise<any>}
     */
    public async handleListAction(action: Table.Action.IResult): Promise<any> {

    }

    public ngOnInit(): void {
        if (this.modal.params.data) {
            this.data = this.modal.params.data;
        }
        this.prepareList();
        this.changeDetectorRef.markForCheck();
    }
}
