import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {ApiService} from "../../../../../../common/services/api.service";
import {Router} from "@angular/router";
import {Table2Component} from "../../../../../../common/components/table2";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {AmplitudeService} from "../../../../../../common/services/amplitude.service";

@Component({
    selector: "section-unused-transactions",
    template: `
        <h1 class="container-heading">Transactions without items</h1>
        <mat-card>
            <mat-card-content>
                <common-table2 *ngIf="listTable" [settings]="listTable"></common-table2>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnusedTransactionsComponent implements Base.IComponent, OnInit {

    public readonly state: Base.IState;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    public listTable: Table.ISettings;

    public constructor(
        private apiService: ApiService,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        const actions: Table.IAction[] = [
            {
                name: "view",
                title: "View",
                click: (row: any): void => {
                    this.router.navigate([
                        "partner",
                        row.partner.slug,
                        "adjust-transactions",
                        "view",
                        "id",
                        row.order.id,
                        "type",
                        "unused"
                    ]);
                    AmplitudeService.eventClick("Transactions without items view");
                }
            }
        ];
        const columns: Table.ICol[] = [
            {
                data: "order.ref",
                name: "Order.ref",
                title: "Ref",
                sortable: false
            },
            {
                data: "order.status.name",
                name: "Order.Status.name",
                title: "Status",
                sortable: false
            },
            {
                data: "order.service_level.name",
                name: "Order.ServiceLevel.name",
                title: "Service level",
                sortable: false
            },
            {
                data: "order.service_level.order_type.display_name",
                name: "Order.ServiceLevel.orderType.display_name",
                title: "Order type",
                sortable: false
            },
            {
                data: "order.service_level.shipping_mode.display_name",
                name: "Order.ServiceLevel.shippingMode.display_name",
                title: "Shipping mode",
                sortable: false
            }
        ];


        if (this.state.section === "admin") {
            columns.unshift({
                data: "partner.full_name",
                name: "Partner.full_name",
                title: "Partner",
                sortable: false
            });
        }


        this.listTable = {
            table_id: "Amz14P6Dmn4",
            actions,
            api: {
                url: ["transaction", "no-item"]
            },

            columns,
            export: {
                file_name: "unused transactions"
            }
        };

        this.changeDetectorRef.markForCheck();
    }


    public ngOnInit(): void {
        this.prepareList();
    }


    public ngConfig(): Base.IConfig {
        return {
            name: "transactions-without-items",
            actions: {
                "browse": ["read_warehouse_transactions"]
            }
        };
    }

}
