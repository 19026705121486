import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";

@Component({
    selector: "section-team-form",
    templateUrl: "form.component.html",
    styleUrls: [
        "form.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class TeamFormComponent implements Base.IComponent, OnInit {


    public readonly state: Base.IState;

    public formGroup: FormGroup = new FormGroup({
        name: new FormControl(null, [Validators.required]),
        description: new FormControl(null, [Validators.required]),
        category: new FormControl(null),
    });

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private spinnerService: SpinnerService
    ) {
    }

    private async getData(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["team", this.state.params.id]);
        this.spinnerService.hide();
        if (data) {
            this.formGroup.patchValue(data);

        }
    }

    public async createTeam(): Promise<any> {
        this.spinnerService.show();
        const {message, code}: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["team"], this.formGroup.value);
        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.cancel();
        }
    }

    public async updateTeam(): Promise<any> {
        this.spinnerService.show();
        const {message, code}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["team", this.state.params.id], this.formGroup.value);
        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.cancel();
        }
    }

    public async submitForm(): Promise<any> {
        if (this.state.action === "edit") {
            this.updateTeam();
        } else {
            this.createTeam();
        }
    }

    public cancel(): void {
        this.router.navigate([
            this.state.section,
            this.state.component
        ]);
    }

    public async ngOnInit(): Promise<any> {
        if (this.state.action === "edit") {
            this.getData();
        }
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "team",
            actions: {
                "add": ["add_teams"],
                "edit": ["edit_teams"]
            }
        };
    }

}
