import {Injectable} from "@angular/core";
import {Api, ApiService} from "./api.service";


@Injectable()
export class MilestonesService {

    public constructor(private apiService: ApiService) {
    }

    public updateMilestone(id: number, body: any, query: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Put, ["milestones", "order", id + ""], body, query);
    }

}
