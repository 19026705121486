import {Injectable} from "@angular/core";
import {ReplaySubject} from "rxjs";

@Injectable()
export class SpinnerService {

    private counter: number = 0;

    public state: ReplaySubject<boolean> = new ReplaySubject(1);

    public show(): void {
        this.counter++;
        this.state.next(this.counter > 0);
    }

    public hide(): void {
        this.counter--;
        this.counter = this.counter < 0 ? 0 : this.counter;
        this.state.next(this.counter > 0);
    }
}