import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../common/services/api.service";
import {ToastService} from "../../../../../common/services/toast.service";
import {Modal, ModalService} from "../../../../services/modal.service";
import {SpinnerService} from "../../../../../common/services/spinner.service";
import {User} from "../../../../../common/interfaces/user.interface";
import {Base} from "../../../../../common/interfaces/base.interfaces";
import {OAuthClientFormComponent} from "./client-form/client-form.component";
import {OAuthTokenFormComponent} from "./token-form/token-form.component";
import {OAuthTokenComponent} from "./token-form/token.component";

@Component({
    selector: "section-tokens-settings",
    templateUrl: "tokens.component.html",
    styleUrls: [
        "tokens.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class TokensComponent implements OnInit, OnDestroy {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input()
    public user: User.IData;

    @Input()
    public state: Base.IState;

    public oAuthDisplayedColumns: string[] = ["client_id", "name", "secret", "actions"];
    public oAuthDataSource: any[];
    public oAuthLoading: boolean = false;

    public applicationsDisplayedColumns: string[] = ["name", "scopes", "actions"];
    public applicationsDataSource: any[];
    public applicationsLoading: boolean = false;

    public personalDisplayedColumns: string[] = ["name", "actions"];
    public personalDataSource: any[];
    public personalLoading: boolean = false;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private modalService: ModalService,
        private spinnerService: SpinnerService
    ) {
    }

    /**
     * Get OAuth clients list
     * @returns {Promise<any>}
     */
    private async getOAuthClients(): Promise<any> {
        this.oAuthLoading = true;
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["user", String(this.user.id), "oauth/clients"]);
        this.oAuthLoading = false;
        if (data) {
            this.oAuthDataSource = data;
            this.changeDetectorRef.markForCheck();
        } else {
            this.oAuthDataSource = [];
        }
    }

    /**
     * Get Authorized applications list
     * @returns {Promise<any>}
     */
    private async getAuthorizedApplications(): Promise<any> {
        this.applicationsLoading = true;
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["user", String(this.user.id), "oauth/tokens"]);
        this.applicationsLoading = false;
        if (data) {
            this.applicationsDataSource = data;
            this.changeDetectorRef.markForCheck();
        } else {
            this.applicationsDataSource = [];
        }
    }

    /**
     * Get Authorized applications list
     * @returns {Promise<any>}
     */
    private async getPersonalAccessTokens(): Promise<any> {
        this.personalLoading = true;
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["user", String(this.user.id), "oauth/oauth_personal_access_tokens"]);
        this.personalLoading = false;
        if (data) {
            this.personalDataSource = data;
            this.changeDetectorRef.markForCheck();
        } else {
            this.personalDataSource = [];
        }
    }

    public async editClient(data: any): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(OAuthClientFormComponent, {
            add: false,
            state: this.state,
            action: "edit",
            data: data,
            user: this.user,
        });
        if (response) {
            this.getOAuthClients();
        }
    }

    public async createClient(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(OAuthClientFormComponent, {
            add: false,
            state: this.state,
            action: "add",
            user: this.user,
        });
        if (response) {
            this.getOAuthClients();
        }
    }

    public async deleteClient(data: any): Promise<any> {
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
            ["user", String(this.user.id), "oauth/clients", data.id]);
        this.getOAuthClients();
    }

    public async createPersonalAccessToken(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(OAuthTokenFormComponent, {
            user: this.user,
            state: this.state
        });
        if (response) {
            this.getPersonalAccessTokens();
            if (response.value) {
                await this.modalService.open(OAuthTokenComponent, {
                    state: this.state,
                    token: response.value
                });
            }
        }
    }

    public async deletePersonalAccessToken(token: any): Promise<any> {
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
            ["user", String(this.user.id), "oauth/oauth_personal_access_tokens", token.id]);
        this.getPersonalAccessTokens();
    }

    public async revokeApplication(application: any): Promise<any> {
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
            ["user", String(this.user.id), "oauth/tokens", application.id]);
        this.getAuthorizedApplications();
    }

    public ngOnInit(): void {
        this.getOAuthClients();
        this.getAuthorizedApplications();
        this.getPersonalAccessTokens();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
