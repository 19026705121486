import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {Modal, ModalService} from "../../../../../../../services/modal.service";
import {ApiService} from "../../../../../../../../common/services/api.service";
import {HelpersService} from "../../../../../../../../common/services/helpers.service";
import {Table} from "../../../../../../../../common/interfaces/table.interface";
import {AppStateService} from "../../../../../../../../common/services/app-state.service";
import {BoxItemsListComponent} from "../box-items/items.component";

@Component({
    selector: "section-pallet-items-list",
    template: `
        <h1 class="container-heading">Pallet content</h1>
        <mat-card>
            <mat-card-content>
                <common-table2 *ngIf="listTable" [settings]="listTable"></common-table2>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PalletItemsListComponent implements OnInit {

    public listTable: Table.ISettings;

    public modal: Modal.IModal;

    public constructor(
        private apiService: ApiService,
        private modalService: ModalService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {

        this.listTable = {
            table_id: "di7iuks711",
            actions: [
                {
                    name: "wizard",
                    title: "Open box",
                    click: (row: any): void => {
                        this.openBox(row);
                    }
                }
            ],
            api: {
                url: [
                    AppStateService.getState().section,
                    "pallets",
                    this.modal.params.pallet.id,
                    this.modal.params.itemsType
                ],
                version: 3
            },
            columns: [
                {
                    data: "ref",
                    title: "Box"
                },
                {
                    data: "inventory_allocation.item",
                    name: "InventoryAllocation.item",
                    title: "Items",
                    render: (row: any): string => {
                        const items = row.inventory_allocations.map(function (allocation: any) {
                            return allocation.item;
                        });
                        return (HelpersService.arrayDistinct(items)).join(", ");
                    },
                    sortable: false,
                    searchable: false,
                },
                {
                    data: "inventory_allocations_count",
                    title: "Items quantity",
                    sortable: false,
                    searchable: false
                }
            ],
            export: {
                file_name: "pallet_boxes"
            }
        };
        this.changeDetectorRef.markForCheck();
    }

    public async openBox(box: any): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(BoxItemsListComponent,
            {
                box,
                modalWidth: 1000,
                itemsType: "allocations"
            });
    }

    public ngOnInit(): void {
        this.prepareList();
    }

}
