import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Form} from "../../../../../../common/interfaces/form.interface";
import {INCOTERMS} from "../../../../../../common/constants/incoterms-list.constant";
import {ServiceLevelService} from "../../../../../../common/services/service-level.service";
import {ContactListComponent} from "../../contact";
import {ReplaySubject} from "rxjs/internal/ReplaySubject";
import {debounceTime, takeUntil} from "rxjs/operators";
import {Contact} from "../../../../../../common/interfaces/contact.interface";
import {PartnerService} from "../../../../../../common/services/partner.service";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {Api3Service} from "../../../../../../common/services/api3.service";
import ISelectOption = Form.ISelectOption;

@Component({
    selector: "section-customer-form",
    templateUrl: "form.component.html",
    styleUrls: [
        "form.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class CustomerFormComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    private customer: Contact.ICustomer;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    /**
     * Form group / group with controls
     * @type {FormGroup}
     */
    public formGroup: FormGroup = new FormGroup({
        name: new FormControl(null),
        vat: new FormControl(null),
        ref: new FormControl(null),
        inventory_conversion_id: new FormControl(null),
        out_incoterms: new FormControl(null),
        in_incoterms: new FormControl(null),
        default_service_level_id: new FormControl(null),
        default_courier_service_id: new FormControl(null),
        is_active: new FormControl(true),
        delivery_ref: new FormControl(false),
    });

    public modal: Modal.IModal;

    public edit: boolean = false;

    public incoterms: ISelectOption[] = INCOTERMS;

    public serviceLevels: ISelectOption[];

    public courierServices: { value: number, name: string }[] = [];

    public courierServicesFiltered: ReplaySubject<{ value: number, name: string }[]> =
        new ReplaySubject<{ value: number, name: string }[]>(1);

    public selectSearch: FormControl = new FormControl(null);

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private modalService: ModalService,
        private serviceLevelService: ServiceLevelService,
        private spinnerService: SpinnerService
    ) {
    }

    private async getValidationConfig(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.get(
            `${this.modal.params.state.section}/forms-validation-rules/customer`,
        );
        this.spinnerService.hide();

        if (data) {
            HelpersService.setValidatorsFromConfig(this.formGroup, data);
            this.changeDetectorRef.markForCheck();
        }
    }

    private async getData(): Promise<any> {
        this.spinnerService.show();

        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["customer", this.modal.params.id]);
        if (data) {
            this.customer = data;

            if (PartnerService.partner.properties.require_customer_ref) {
                this.formGroup.get("ref").setValidators([Validators.required]);
                this.formGroup.get("ref").updateValueAndValidity();
            }

            this.formGroup.patchValue(data);
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private async getServiceLevels(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.serviceLevelService.getServiceLevels();
        if (data) {
            this.serviceLevels = this.serviceLevelService.mapServiceLevelsToSelect(data);
        }
        this.spinnerService.hide();
    }

    private async getCourierServices(): Promise<any> {
        this.spinnerService.show();
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["courier_service"],
            {}, {data_structure: "select"});
        if (response.data) {
            this.courierServices = response.data;
            this.courierServicesFiltered.next(this.courierServices.slice());
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Submit form (add)
     * @returns {Promise<any>}
     */
    public async handleFormSubmit(): Promise<any> {
        if (this.formGroup && this.formGroup.valid) {
            this.spinnerService.show();
            const body: any = this.formGroup.value;

            if (!this.edit) {
                const {data, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
                    "customer", body);
                this.spinnerService.hide();
                if (data) {
                    this.toastService.show(message, "success");
                    if (this.modal.params.canEdit) {
                        this.modal.params.action = "edit";
                        this.edit = true;
                        this.modal.params.id = data.id;
                        this.customer = data;
                        this.formGroup.patchValue(data);
                        this.changeDetectorRef.markForCheck();
                    } else {
                        this.modal.response.next({
                            name: "value",
                            value: {
                                controlValue: data.id,
                                viewValue: data.name,
                                refValue: data.name
                            }
                        });
                    }
                }
            } else if (this.edit) {
                const {data, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
                    ["customer", "" + this.modal.params.id], body);
                this.spinnerService.hide();
                if (data) {
                    this.customer = data;
                    this.toastService.show(message, "success");
                }
            }


        }
    }

    /**
     * Show add new address form
     * @returns {void}
     */
    public showList(): void {
        this.modal.response.next({
            name: "list"
        });
    }

    /**
     * Delete customer
     */
    public async delete(): Promise<any> {
        if (await this.confirmRef.confirm("Are you sure want to delete this customer?")) {
            this.spinnerService.show();
            const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
                ["customer", this.modal.params.id]);
            if (type as string === "success") {
                this.toastService.show(message, "success");
                this.modal.response.next({
                    value: null,
                    name: "delete"
                });
            }
            this.spinnerService.hide();
        }
    }


    public async showContacts(): Promise<any> {
        await this.modalService.open(ContactListComponent, {
            customer: this.modal.params.id,
            canEdit: true,
            canDelete: true
        });
    }

    public ngOnInit(): void {
        this.getValidationConfig();
        this.getServiceLevels();
        this.getCourierServices();
        if (this.modal.params.id) {
            this.edit = true;
            this.getData();
        }

        this.selectSearch.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: string): void => {
                if (!value) {
                    this.courierServicesFiltered.next(this.courierServices.slice());
                } else {
                    value = value.toLowerCase();
                    this.courierServicesFiltered.next(
                        this.courierServices.filter((service: { name: string }): boolean =>
                            service.name.toLowerCase().indexOf(value) > -1)
                    );
                }
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    protected readonly Validators = Validators;
}
