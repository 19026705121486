<h1 class="container-heading">Add Remark Type</h1>

<mat-card>
    <mat-card-content>

        <form *ngIf="formGroup" [formGroup]="formGroup" (submit)="submitForm()">
            <div class="group custom flex">
                <mat-form-field class="full">
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="name">
                </mat-form-field>
                <button mat-raised-button type="submit">Submit</button>
            </div>
        </form>
    </mat-card-content>
</mat-card>