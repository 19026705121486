import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: "values"
})
export class ObjectValuesPipe implements PipeTransform {
    public transform(value: any): string[] {
        return value ? Object.values(value) : [];
    }
}
