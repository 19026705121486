import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {Api3Service} from "../../../../../../common/services/api3.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Api} from "../../../../../../common/services/api.service";

@Component({
    selector: "common-settings-contact-form",
    template: `
        <div [formGroup]="form">
            <div class="flex row align-top space-between">
                <mat-card class="half">
                    <mat-card-title>First name</mat-card-title>
                    <div formGroupName="first_name" class="row flex align-center">
                        <div class="third">
                            <mat-checkbox formControlName="required">Required</mat-checkbox>
                        </div>
                        <div class="third">
                            <mat-checkbox formControlName="unique">Unique</mat-checkbox>
                        </div>
                        <mat-form-field class="third">
                            <mat-label>Max length</mat-label>
                            <input matInput formControlName="maxLength" type="number">
                        </mat-form-field>
                    </div>
                </mat-card>

                <mat-card class="half">
                    <mat-card-title>Last name</mat-card-title>
                    <div formGroupName="last_name" class="row flex align-center">
                        <div class="third">
                            <mat-checkbox formControlName="required">Required</mat-checkbox>
                        </div>
                        <div class="third">
                            <mat-checkbox formControlName="unique">Unique</mat-checkbox>
                        </div>

                        <mat-form-field class="third">
                            <mat-label>Max length</mat-label>
                            <input matInput formControlName="maxLength" type="number">
                        </mat-form-field>
                    </div>
                </mat-card>

                <mat-card class="half">
                    <mat-card-title>Email</mat-card-title>
                    <div formGroupName="email" class="row flex align-center">
                        <div class="third">
                            <mat-checkbox formControlName="required">Required</mat-checkbox>
                        </div>

                        <div class="third">
                            <mat-checkbox formControlName="unique">Unique</mat-checkbox>
                        </div>

                        <mat-form-field class="third">
                            <mat-label>Max length</mat-label>
                            <input matInput formControlName="maxLength" type="number">
                        </mat-form-field>

                        <mat-form-field class="full">
                            <mat-label>Regex</mat-label>
                            <input matInput formControlName="regex">
                        </mat-form-field>
                    </div>
                </mat-card>

                <mat-card class="half">
                    <mat-card-title>Phone</mat-card-title>
                    <div formGroupName="phone" class="row flex align-center">
                        <div class="third">
                            <mat-checkbox formControlName="required">Required</mat-checkbox>
                        </div>

                        <div class="third">
                            <mat-checkbox formControlName="unique">Unique</mat-checkbox>
                        </div>

                        <mat-form-field class="third">
                            <mat-label>Max length</mat-label>
                            <input matInput formControlName="maxLength" type="number">
                        </mat-form-field>

                        <mat-form-field class="full">
                            <mat-label>Regex</mat-label>
                            <input matInput formControlName="regex">
                        </mat-form-field>
                    </div>
                </mat-card>

                <mat-card class="half">
                    <mat-card-title>Mobile Phone</mat-card-title>
                    <div formGroupName="mobile_phone" class="row flex align-center">
                        <div class="third">
                            <mat-checkbox formControlName="required">Required</mat-checkbox>
                        </div>

                        <div class="third">
                            <mat-checkbox formControlName="unique">Unique</mat-checkbox>
                        </div>

                        <mat-form-field class="third">
                            <mat-label>Max length</mat-label>
                            <input matInput formControlName="maxLength" type="number">
                        </mat-form-field>

                        <mat-form-field class="full">
                            <mat-label>Regex</mat-label>
                            <input matInput formControlName="regex">
                        </mat-form-field>
                    </div>
                </mat-card>

                <mat-card class="half">
                    <mat-card-title>Fax</mat-card-title>
                    <div formGroupName="fax" class="row flex align-center">
                        <div class="third">
                            <mat-checkbox formControlName="required">Required</mat-checkbox>
                        </div>

                        <div class="third">
                            <mat-checkbox formControlName="unique">Unique</mat-checkbox>
                        </div>

                        <mat-form-field class="third">
                            <mat-label>Max length</mat-label>
                            <input matInput formControlName="maxLength" type="number">
                        </mat-form-field>

                        <mat-form-field class="full">
                            <mat-label>Regex</mat-label>
                            <input matInput formControlName="regex">
                        </mat-form-field>
                    </div>
                </mat-card>

            </div>

            <div>
                <button type="button" mat-raised-button (click)="updateConfig()" color="accent">
                    Save
                </button>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    styleUrls: [
        "./form.scss"
    ]

})
export class SettingsContactFormComponent implements OnInit, OnDestroy {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input()
    public state: Base.IState;

    public form: FormGroup = new FormGroup({
        first_name: new FormGroup({
            required: new FormControl({value: true, disabled: true}, [Validators.required]),
            unique: new FormControl(false),
            maxLength: new FormControl(null),
        }),
        last_name: new FormGroup({
            required: new FormControl({value: true, disabled: true}, [Validators.required]),
            unique: new FormControl(false),
            maxLength: new FormControl(null),
        }),
        email: new FormGroup({
            required: new FormControl(false),
            unique: new FormControl(false),
            maxLength: new FormControl(null),
            regex: new FormControl(null),
        }),
        phone: new FormGroup({
            required: new FormControl(false),
            unique: new FormControl(false),
            maxLength: new FormControl(null),
            regex: new FormControl(null),
        }),
        mobile_phone: new FormGroup({
            required: new FormControl(false),
            unique: new FormControl(false),
            maxLength: new FormControl(null),
            regex: new FormControl(null),
        }),
        fax: new FormGroup({
            required: new FormControl(false),
            unique: new FormControl(false),
            maxLength: new FormControl(null),
            regex: new FormControl(null),
        }),

    });

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private spinnerService: SpinnerService
    ) {
    }

    private async getConfig(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.get(
            `${this.state.section}/forms-validation-rules/contact`,
        );
        this.spinnerService.hide();

        if (data) {
            this.form.patchValue(data);
            this.changeDetectorRef.markForCheck();
        }
    }

    public async updateConfig(): Promise<any> {
        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.api3Service.put(
            `${this.state.section}/forms-validation-rules/contact`, this.form.getRawValue(),
        );
        this.spinnerService.hide();

        if (code === 200) {
            this.toastService.show(message, "success");
        }
    }

    public async ngOnInit(): Promise<any> {
        this.getConfig();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
