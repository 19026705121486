<mat-card>
    <mat-card-content>
    <form [formGroup]="formGroup" (submit)="submit()">
        <div class="group flex">
            <div class="full">
                <h1>Add Item to Box</h1>
            </div>
        
                        <mat-form-field class="full">
                            <mat-label>Item</mat-label>
                            <mat-select formControlName="item">
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="inventoriesSearch"
                                                            [placeholderLabel]="'Search'"
                                                            [clearSearchInput]="true"
                                                            [noEntriesFoundLabel]="'nothing found'">
                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option [value]="null">
                                    None
                                </mat-option>
                                <mat-option *ngFor="let item of inventoriesFiltered" [value]="item.id">
                                    {{item.item}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div class="full" *ngIf="(availableSerials?.length > 0
                            || serials?.length > 0)
                            && formGroup.controls['item'].value">

                        <mat-form-field class="full">
                            <mat-label>Serial</mat-label>
                            <mat-chip-grid #itemchipGrid formControlName="serials">
                                <mat-chip-row matChipRemove *ngFor="let serial of serials" [selectable]="false"
                                              [removable]="true" (removed)="removeSerialChip(serial)">
                                    {{serial}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip-row>
                                <input [matChipInputFor]="itemchipGrid"
                                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                       [matChipInputAddOnBlur]="true"
                                       (matChipInputTokenEnd)="addSerialChip($event)">
                            </mat-chip-grid>
                        </mat-form-field>

                        <div class="full">
                            <div class="margin-bottom-10">Available serials: ({{availableSerials[formGroup.controls['item'].value].length}})</div>
                        </div>
                        <div class="full available-serial-list">
                            <mat-chip-listbox>
                                <mat-chip-option matChipRemove *ngFor="let serial of availableSerials[formGroup.controls['item'].value]"
                                                 (click)="selectSerial(serial)">
                                    {{serial}}
                                </mat-chip-option>
                            </mat-chip-listbox>
                        </div>

                        </div>

                        <mat-form-field class="half">
                            <mat-label>QTY</mat-label>
                            <input matInput formControlName="qty" type="number" [min]="1">
                        </mat-form-field>
                   </div>

        <div class="actions">
            <button mat-raised-button type="submit" color="primary"
                [disabled]="!formGroup.valid">
                    Add
            </button>
            <button mat-raised-button type="button" (click)="close()">
                Cancel
            </button>
        </div>
    </form>
    </mat-card-content>
</mat-card>