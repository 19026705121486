import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    Output,
    ViewEncapsulation
} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {Router} from "@angular/router";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {DragulaService} from "ng2-dragula";
import {Modal, ModalService} from "../../../../../../services/modal.service";
import {Warehouse} from "../../../../../../../common/interfaces/warehouse.interface";
import {takeUntil} from "rxjs/operators";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {ShipmentFormComponent} from "../form/form.component";

@Component({
    selector: "section-order-shipment-wizard-step2",
    templateUrl: "step2.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class OrderShipmentWizardStep2Component implements OnDestroy {

    private state: Base.IState;

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    @Output()
    public result: EventEmitter<any> = new EventEmitter();

    public parcels: Warehouse.IParcel[] = [];

    public shipments: any[] = [];

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private dragulaService: DragulaService,
        private modalService: ModalService,
        private spinnerService: SpinnerService
    ) {

        if (!dragulaService.find("shipment-bag")) {
            dragulaService.createGroup("shipment-bag", {});
        }
        dragulaService.dropModel("shipment-bag").pipe(takeUntil(this.destroy$))
            .subscribe((value: any): void => {
                this.onDropModel(value);
            });
    }


    /**
     * Handle drop event
     * @returns {Promise<any>}
     */
    private async onDropModel(value: any): Promise<any> {

        const item: any = value.el;
        const target: any = value.target;
        const bag_name: any = value.name;

        if (bag_name !== "shipment-bag") {
            return;
        }
        let itemId: string;
        item.classList.forEach((className: string): void => {
            if (/id-[0-9]+/.test(className)) {
                itemId = className.replace("id-", "");
            }
        });

        let targetId: number = null;
        target.classList.forEach((className: string): void => {
            if (/id-[0-9]+/.test(className)) {
                targetId = Number(className.replace("id-", ""));
            }
        });

        if (itemId) {
            this.spinnerService.show();
            const {code, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
                ["shipment"], {shipment_id: targetId, parcel_id: itemId});
            this.spinnerService.hide();

            if (code === 200) {
                this.toastService.show(message, "success");
                this.changeDetectorRef.markForCheck();
            } else {
                this.getOrderShipments();
                this.getOrderParcels();
            }
        }
    }


    /**
     * Get order shipments
     * @returns {Promise<any>}
     */
    private async getOrderShipments(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["order", this.state.params.order_id, "shipments"], {}, {
                type: this.state.params.type,
                with_parcel: true
            });
        this.spinnerService.hide();
        if (data) {
            this.shipments = data;
            this.shipments.forEach((elem: any, index: any): void => {
                if (!elem.hasOwnProperty("parcels")) {
                    this.shipments[index].parcels = [];
                }
            });
            if (this.shipments.length > 0) {
                this.result.next(this.shipments);
            }
        }
    }

    private async getOrderParcels(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["order", this.state.params.order_id, "parcels", this.state.params.type], {}, {
                linked: false
            });
        this.spinnerService.hide();
        if (data) {
            this.parcels = data;
            this.changeDetectorRef.markForCheck();
        }
    }


    /**
     * Initialize step
     * @returns {Promise<any>}
     * @param state
     * @param parcels
     */
    public async init(state: Base.IState, parcels: Warehouse.IParcel[]): Promise<any> {
        this.state = state;
        this.getOrderShipments();
        this.getOrderParcels();
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Add new shipment
     * @returns {Promise<any>}
     */
    public async add(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(ShipmentFormComponent, {
            action: "add",
            order_id: this.state.params.order_id,
            type: this.state.params.type,
            modalWidth: "1000px"
        });
        if (response) {
            this.shipments.push(response.value.shipment);
            this.changeDetectorRef.markForCheck();
            this.result.next(this.shipments);
        }
    }

    /**
     * Edit shipment
     * @returns {Promise<any>}
     * @param shipment
     */
    public async edit(shipment: Warehouse.IShipment): Promise<any> {

        const response: Modal.IResponse = await this.modalService.open(ShipmentFormComponent, {
            action: "edit",
            shipment: shipment,
            order_id: this.state.params.order_id,
            modalWidth: "1000px",
            type: this.state.params.type,
        });
        // console.warn("edit", response);
        if (response) {
            for (const index of Object.keys(this.shipments)) {
                if (this.shipments[index].id === response.value.shipment.id) {
                    if (response.value.action === "edit") {
                        this.shipments[index] = response.value.shipment;

                    } else if (response.value.action === "delete") {
                        this.parcels = this.parcels.concat(this.shipments[index].parcels);

                        this.spinnerService.show();
                        this.shipments[index].parcels.forEach(async (item: any): Promise<any> => {
                            const unlinkParcel: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
                                ["shipment"], {shipment_id: null, parcel_id: item.id});
                            this.toastService.show(unlinkParcel.message, unlinkParcel.type as string);
                        });
                        this.spinnerService.hide();
                        this.shipments.splice(Number(index), 1);
                        if (this.parcels.length < 1) {
                            this.result.next(false);
                        }
                    }
                    this.changeDetectorRef.markForCheck();
                    break;
                }
            }
        }
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }


}
