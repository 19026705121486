import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from "@angular/core";
import {Router} from "@angular/router";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {Table, TableComponent} from "../../../../../../common/components/table/table.component";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {UserService} from "../../../../../../common/services/user.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {SpinnerService} from "../../../../../../common/services/spinner.service";


@Component({
    selector: "section-remark-type-list",
    templateUrl: "list.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class RemarkTypeListComponent implements Base.IComponent, OnInit {

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild(TableComponent, {static: false})
    public tableRef: TableComponent;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.IOptions;

    public readonly state: Base.IState;

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        public userService: UserService,
        private spinnerService: SpinnerService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        const actions: Table.Action.ISettings[] = [];
        actions.push({
            name: "view",
            title: "View"
        });
        this.listTable = {
            actions,
            api: this.apiService.getUrl(["remark", "types"]) + "?data_structure=dataTables",
            columns: [
                {
                    data: "name",
                    title: "Name"
                }
            ],
            order: [],
            search: true
        };
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Handle list row action
     * @param {Table.Action.IResult} action
     * @returns {Promise<any>}
     */
    public async handleListAction(action: Table.Action.IResult): Promise<any> {
        if (action.name === "view" && action.data.id) {
            this.router.navigate([this.state.section, this.state.component, "view", "id", action.data.id]);
        }
        if (action.name === "edit" && action.data.id) {
            this.router.navigate([this.state.section, this.state.component, "edit", "id", action.data.id]);
        }
        if (action.name === "delete" && action.data.id) {
            if (await this.confirmRef.confirm(`Do you want to delete "${action.data.name}?"`)) {
                this.spinnerService.show();
                const {data, message}: Api.IResponse =
                    await this.apiService.request(Api.EMethod.Delete, ["partner", action.data.id]);
                this.spinnerService.hide();
                if (data) {
                    this.toastService.show(message, "success");
                    this.tableRef.tableRef.draw();
                }
            }
        }
    }

    public ngOnInit(): void {
        this.prepareList();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "remark-type",
            actions: {
                "browse": ["browse_order_remarks"]
            }
        };
    }

}
