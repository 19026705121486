import {ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import * as jQuery from "jquery";
import {Router} from "@angular/router";

@Component({
    selector: "section-inspection-choose",
    templateUrl: "inspection-choose.component.html",
    styleUrls: [
        "inspection-choose.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class InspectionChooseComponent implements Base.IComponent, OnInit {
    public readonly state: Base.IState;

    public constructor(
        private router: Router
    ) {
    }

    public navTo(path: string): void {
        const slug: string = this.state.section.split("/")[1];
        this.router.navigate(["/partner", slug, path]);
    }

    public ngOnInit(): void {
        setTimeout((): void => {
            jQuery("#main-card").height(jQuery("mat-drawer-content").height() - 300);
        }, 500);
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "inspection-choose",
            actions: {
                "browse": ["show_inspections_choose"]
            }
        };
    }
}
