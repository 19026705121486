import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from "@angular/core";
import {Api} from "../../../../../../common/services/api.service";
import {ModalService} from "../../../../../services/modal.service";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {ToastService} from "../../../../../../common/services/toast.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {Table2Component} from "../../../../../../common/components/table2";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {UserService} from "../../../../../../common/services/user.service";
import {BatchFormComponent} from "../form/form.component";
import {BatchService} from "../../../../../../common/services/batch.service";
import {AmplitudeService} from "../../../../../../common/services/amplitude.service";

@Component({
    selector: "section-batch-list",
    template: `
        <common-confirm></common-confirm>
        <h1 class="container-heading">Batches list</h1>
        <mat-card>
            <mat-card-content>
                <common-table2 *ngIf="listTable" [settings]="listTable">
                    <div row1>
                        <button mat-raised-button color="primary" class="main" (click)="showAddForm()">Add</button>
                    </div>
                </common-table2>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BatchListComponent implements OnInit, Base.IComponent {

    public state: Base.IState;

    public listTable: Table.ISettings;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private modalService: ModalService,
        private toastService: ToastService,
        private userService: UserService,
        private batchService: BatchService,
        private spinnerService: SpinnerService,

    ) {
    }

    private getUrl(): Table.ITableApi {
        return {
            url: ["batches"],
            query: {
                relations: [
                    "PartMaster"
                ]
            }
        };
    }

    private prepareList(): void {
        const actions: Table.IAction[] = [
            {
                name: "view",
                title: "View",
                click: (row: any): void => {
                    this.showEditForm(row.id);
                }
            },
        ];

        if (this.userService.validatePermissions(["delete_batches"])) {
            actions.push({
                name: "delete",
                title: "Delete",
                click: (row: any): void => {
                    this.deleteBatch(row.id);
                }
            });
        }

        this.listTable = {
            table_id: "sdufilLKYJ7aezdfy",
            actions,
            api: this.getUrl(),
            columns: [
                {
                    data: "batch",
                    title: "Batch"
                },
                {
                    data: "part_master.item",
                    name: "PartMaster.item",
                    title: "Item"
                },
                {
                    data: "expiry_date",
                    title: "Expiry date"
                },
                {
                    data: "created_at",
                    title: "Created at"
                },
                {
                    data: "updated_at",
                    title: "Updated at"
                },
            ],
        };
        this.changeDetectorRef.markForCheck();
    }

    private async showEditForm(id: number): Promise<any> {
        await this.modalService.open(BatchFormComponent, {id});
        this.tableRef.reload();
    }

    public async showAddForm(): Promise<any> {
        await this.modalService.open(BatchFormComponent);
        this.tableRef.reload();
        AmplitudeService.eventClick("Add batches");
    }

    public async deleteBatch(id: number): Promise<any> {
        if (await this.confirmRef.confirm("Are you sure want to delete current Batch?")) {
            this.spinnerService.show();
            const {message, type}: Api.IResponse = await this.batchService.deleteBatch(id);
            this.spinnerService.hide();
            if (type as string === "success") {
                this.toastService.show(message, "success");
                this.tableRef.reload();
            }
        }
    }

    public ngOnInit(): void {
        this.prepareList();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "batches",
            actions: {
                "browse": ["browse_batches"]
            }
        };
    }

}
