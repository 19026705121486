import {Injectable} from "@angular/core";
import {Modal, ModalService} from "../../section/services/modal.service";
import {FileUploadComponent} from "../components/file-upload/file-upload.component";
import {environment} from "../../../environments/environment";

@Injectable()
export class ImageUploadService {

    public constructor(private modalService: ModalService) {
    }

    private upload(preset: string = "image_upload_preset"): Promise<string> {
        return new Promise<string>((resolve: any): void => {
            this.modalService.open(FileUploadComponent, {
                headers: {"X-Requested-With": "XMLHttpRequest"},
                removeDefaultHeaders: true,
                url: "https://api.cloudinary.com/v1_1/" + environment.cloudinary.cloudName + "/upload",
                body: {
                    upload_preset: environment.cloudinary[preset]
                },
                fileInputName: "file"
            }).then((response: Modal.IResponse): void => {
                if (response && response.value && response.value.secure_url) {
                    resolve(response.value.secure_url);
                } else {
                    resolve(null);
                }
            });
        });
    }

    public uploadImage(): Promise<string> {
        return this.upload();
    }

    public uploadAvatar(): Promise<string> {
        return this.upload("avatar_upload_preset");
    }

    public uploadLogo(): Promise<string> {
        return this.upload("partner_logo_upload_preset");
    }

    public uploadIcon(): Promise<string> {
        return this.upload("partner_icon_upload_preset");
    }
}
