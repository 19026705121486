<mat-card>

    <h1>Select item to update</h1>

    <form [formGroup]="form" (submit)="submit()">
        <div class="group flex">
            <div class="half">
                <common-form-select
                        [search]="true"
                        [required]="true"
                        url="/partmaster?no_serial=true&in_stock=true"
                        label="Select item"
                        [value]="form.value.part_master_id"
                        (valueChange)="form.get('part_master_id').setValue($event)"
                ></common-form-select>
            </div>

            <div class="half">
                <common-form-hub-select
                        label="Hub"
                        [required]="true"
                        line_type="outbound"
                        [value]="form.value.inventory_conversion_id"
                        [part_master_id]="form.value.part_master_id"
                        [part_master_in_stock]="true"
                        (onChange)="form.get('inventory_conversion_id').setValue($event)"></common-form-hub-select>
            </div>

            <div class="full margin-bottom-20" *ngIf="inventories">
                <mat-radio-group>
                    <mat-card class="tile mat-elevation-z5 margin-bottom-10"
                              *ngFor="let inventory of inventories"
                              (click)="selectInventory(inventory)">
                        <div class="flex no-wrap">
                            <div>
                                <mat-radio-button [value]="inventory"></mat-radio-button>
                            </div>
                            <div>
                                <b>{{inventory.inventory.item}}</b>
                                {{ inventory.inventory.configurations ? " | Conf: " + inventory.inventory.configurations : "" }}
                                {{ inventory.inventory.rev ? " | Rev: " + inventory.inventory.rev : ""}}
                                {{ inventory.inventory.batch ? " | Batch: " + inventory.inventory.batch : "" }}
                                {{ inventory.inventory.warehouse_location ? " | Location: " + inventory.inventory.warehouse_location.location : "" }}
                                | Qty: {{ inventory.sum }}
                            </div>
                        </div>
                    </mat-card>
                </mat-radio-group>
            </div>

            <div class="full margin-bottom-20" *ngIf="inventories && !inventories.length">
                <mat-card class="tile mat-elevation-z5">
                    <p>No items to show</p>
                </mat-card>
            </div>

            <div class="half">
                <common-form-select url="/partmaster?no_serial=true"
                                    [search]="true"
                                    [required]="true"
                                    label="New item"
                                    [value]="form.value.new_part_master_id"
                                    (valueChange)="form.get('new_part_master_id').setValue($event)"
                ></common-form-select>
            </div>

            <mat-form-field class="half">
                <mat-label>Quantity</mat-label>
                <input matInput required [max]="maxQty" type="number" formControlName="quantity">
            </mat-form-field>

            <mat-form-field class="full">
                <mat-label>Remark</mat-label>
<textarea row="5" matInput  required formControlName="remark"></textarea>
            </mat-form-field>

        </div>
        <div class="actions">
            <button type="submit" mat-raised-button color="accent" [disabled]="!form.valid">
                Update
            </button>
        </div>
    </form>

</mat-card>
