import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {ModalService} from "../../../../../../../services/modal.service";
import {Api, ApiService} from "../../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../../common/services/toast.service";
import {UserService} from "../../../../../../../../common/services/user.service";
import {StorageService} from "../../../../../../../../common/services/storage.service";
import {AbstractOrderItemComponent} from "../../../item/item";
import {SpinnerService} from "../../../../../../../../common/services/spinner.service";
import {Api3Service} from "../../../../../../../../common/services/api3.service";


@Component({
    selector: "section-order-wizard-item-replenishment",
    templateUrl: "../../../item/item.component.html",
    styleUrls: [
        "../../../item/item.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class OrderWizardItemReplenishmentComponent extends AbstractOrderItemComponent implements OnInit {

    public itemType: string = "inbound";

    public formType: string = "replenishment";

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        protected apiService: ApiService,
        protected api3Service: Api3Service,
        protected toastService: ToastService,
        protected modalService: ModalService,
        protected userService: UserService,
        protected storageService: StorageService,
        protected spinnerService: SpinnerService
    ) {
        super(changeDetectorRef, apiService, api3Service, toastService, modalService, userService, storageService,
            spinnerService);
    }

    public onItemSelected($event: any): void {
        super.onItemSelected($event);
        if ($event && $event.value && this.inboundHubRef
        ) {
            this.inboundHubRef.getHubs();
        }
    }

    public showOutboundHubSelect(): boolean {
        return false;
    }

    public showInboundHubSelect(): boolean {
        return true;
    }

    /**
     * Add item (prepare & add modal response)
     */
    public async save(): Promise<any> {
        this.spinnerService.show();

        const {code, message}: Api.IResponse = await this.api3Service.post(
            `${this.modal.params.state.section}/order-items`, {
                items: [
                    {
                        ...this.formGroup.value,
                        hub_id: this.formGroup.value.inbound_hub_id,
                        order_id: this.modal.params.order_id,
                        type: "inbound"
                    }
                ]
            });

        this.spinnerService.hide();

        this.storageService.set("add_order", {
            hub_id: this.formGroup.value.inbound_hub_id
        });

        this.modal.response.next({
            name: "value",
            value: true
        });

        if (code === 200) {
            this.toastService.show(message, "success");
        }

    }


    public ngOnInit(): void {
        this.formGroup.get("outbound_hub_id").clearValidators();
        this.formGroup.get("outbound_hub_id").updateValueAndValidity();
        super.ngOnInit();
        this.disable_hub_select = !!this.modal.params.disable_hub_select && this.formGroup.value.inbound_hub_id;

    }
}
