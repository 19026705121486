import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Router} from "@angular/router";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {Table2Component} from "../../../../../../common/components/table2";
import {FormControl} from "@angular/forms";
import {StorageService} from "../../../../../../common/services/storage.service";
import {takeUntil} from "rxjs/operators";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {Form} from "../../../../../../common/interfaces/form.interface";
import ISelectOption = Form.ISelectOption;
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {User} from "../../../../../../common/interfaces/user.interface";
import * as moment from "moment-timezone";
import {Moment} from "moment-timezone";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {CommonFormComponent} from "../../../../../../common/components/form";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Api3Service} from "../../../../../../common/services/api3.service";
import {Request} from "../../../../../../common/interfaces/request.interface";
import ITask = Request.ITask;


@Component({
    selector: "section-followups-task-list",
    templateUrl: "list.component.html",
    styleUrls: ["list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class FollowupsTasksListComponent implements OnInit, OnDestroy, Base.IComponent {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    @Input()
    public state: Base.IState;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.ISettings;

    public showExportButton: boolean = false;

    public groupByOrder: FormControl =
        new FormControl(this.storageService.get("followups_by_tasks_group_by_order", true));

    public onlyOpen: FormControl =
        new FormControl(this.storageService.get("followups_by_tasks_only_open", true));

    public partner_ids: FormControl = new FormControl(null);

    public partners: ISelectOption[] = [];

    public request_kind_id: FormControl =
        new FormControl(this.storageService.get("followups_by_tasks_request_kind_id"));

    public team_id: FormControl = new FormControl(this.storageService.get("followups_by_tasks_team_id"));

    public kinds: ISelectOption[] = [];
    public teams: ISelectOption[] = [];

    public constructor(
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        private storageService: StorageService,
        private apiService: ApiService,
        private modalService: ModalService,
        private spinnerService: SpinnerService,
        private toastService: ToastService,
        private api3Service: Api3Service
    ) {
    }

    private async getKinds(): Promise<any> {
        const {data}: Api.IResponse = await this.api3Service.get(`${this.state.section}/request-kinds`, {
            relations: [
                "Partner:id,display_name",
            ],
            category: "followup"
        });

        if (data) {
            this.kinds = data.map((kind: any) => {
                return {
                    value: kind.id,
                    name: (this.state.section_type === "admin"
                        ? (kind.partner ? kind.partner.display_name + " - " : "")
                        : "") + kind.name
                };
            });
            this.changeDetectorRef.markForCheck();
        }
    }

    private async getTeams(): Promise<any> {
        const {data}: Api.IResponse = await this.api3Service.get(`${this.state.section}/teams`, {
            relations: [
                "Partner:id,display_name",
                "Warehouse:id,name",
            ],
            category: "followup"
        });

        if (data) {
            this.teams = data.map((team: any) => {
                return {
                    value: team.id,
                    name: (this.state.section_type === "admin"
                        ? team.partner
                            ? team.partner.display_name + " - "
                            : (team.warehouse ? team.warehouse.name + " - " : "") : "") + team.name
                };
            });
            this.changeDetectorRef.markForCheck();
        }
    }

    private getApiData(): any {
        return {
            url: [this.state.section, "followups-tasks"],
            query: {
                group_by_order: this.groupByOrder.value,
                only_open: this.onlyOpen.value,
                partner_ids: this.partner_ids.value,
                request_kind_id: this.request_kind_id.value,
                team_id: this.team_id.value,
                relations: [
                    "Request:id,request_kind_id,order_id,partner_id,team_id",
                    "Request.Partner:id,slug,display_name,icon_path",
                    "Request.RequestKind:id,name",

                    "Request.Order:id,ref,address_id",
                    "Request.Order.MainAddress:id,country,country_iso_2",

                    "Request.Order.OrderItem:id,order_id,inventory_conversion_id",
                    "Request.Order.OrderItem.InventoryConversion:id,customers_inventory_name",

                    "Request.Order.Shipments:id,order_id",
                    "Request.Order.Shipments.CourierTransaction:id,shipment_id,tracking_number,courier_slug,status",
                    "Request.Order.Shipments.CourierTransaction.Courier:id,slug,display_name,web_url",

                    "Request.Order.OutboundShipment:id",
                    "Request.Order.OutboundShipment.CourierTransaction:id,tracking_number,shipment_id,status",

                    "Request.Team:id,name",
                    "Team:id,name",
                    "RequestKind:id,name",
                    "Request.Order.OrderRemarks:id,order_id",
                ],
            },
            version: 3
        };
    }

    private getColumns(state: Base.IState, router: Router): Table.ICol[] {
        return [
            {
                data: "id",
                title: "",
                sortable: false,
                searchable: false,
                render: (row: any): string => {
                    if (this.state.section_type !== "admin") {
                        return "";
                    }
                    return row.request?.partner?.icon_path ? `<img src="${row.request?.partner?.icon_path}">` : "";
                }
            },
            {
                data: "request.order.ref",
                name: "Request.Order.ref",
                title: "Order",
                sortable: false
            },
            {
                data: "id",
                title: "",
                render: (row: any): string => {
                    if (row.request.order) {
                        return `
                        <mat-icon class="mat-icon material-icons pointer" title="Open in new tab">
                             open_in_new
                        </mat-icon>`;
                    }
                    return "";
                },
                click: (row: any): void => {
                    if (row.request.order) {
                        window.open(
                            "/partner/" + row.request.partner.slug + "/orders/view/id/" + row.request.order.id,
                            "_blank"
                        );
                    }
                },
                sortable: false,
                exportable: false
            },
            {
                data: "text",
                title: "Text",
                sortable: false
            },
            {
                data: "comment",
                title: "Comment",
                render: (row: any): string => {
                    return (row.comment ? row.comment + " " : "")
                        + "<mat-icon class='material-icons mat-icon pointer'>edit</mat-icon>";
                },
                click: (row: any): void => {
                    this.editParam(row, "comment", "Comment", row.comment);
                },
                sortable: false,
            },
            {
                data: "due_date",
                title: "Due Date",
                render: (row: any): string => {
                    return (row.due_date ? moment(row.due_date).format("DD.MM.YYYY HH:mm") + " " : "")
                        + "<mat-icon class='material-icons mat-icon pointer'>edit</mat-icon>";
                },
                cssClassFn(row: any) {
                    if (!row.due_date) {
                        return "";
                    }

                    const to: Moment = moment.tz(row.due_date, "YYYY-MM-DD HH-mm-ss", "Asia/Jerusalem");
                    const now: Moment = moment.tz("Asia/Jerusalem");
                    const left: number = to.diff(now, "minutes");

                    if (left > 60) {
                        return "due-date-green";
                    } else if (left > 30) {
                        return "due-date-yellow";
                    }
                    return "due-date-red";
                },
                click: (row: any): void => {
                    this.editParam(row, "due_date", "Due Date", row.due_date);
                }
            },
            {
                data: "complete",
                title: "Is Complete",
                render: (row: any) => {
                    return (row.complete ? "Yes" : "No")
                        + "<mat-icon class='material-icons mat-icon pointer'>edit</mat-icon>";
                },
                click: (row: any): void => {
                    this.editParam(row, "complete", "Complete", row.complete);
                },
                sortable: false
            },
            {
                data: "request_kind.name",
                name: "RequestKind.name",
                title: "Kind",
                render: (row: any) => {
                    return (row.request_kind_id ? row.request_kind?.name : row.request.request_kind?.name)
                        + "<mat-icon class='material-icons mat-icon pointer'>edit</mat-icon>";
                },
                click: (row: any): void => {
                    this.editParam(row, "request_kind_id", "Kind",
                        row.request_kind_id ? row.request_kind_id : row.request?.request_kind_id);
                },
                sortable: false
            },
            {
                data: "team.name",
                name: "Team.name",
                title: "Team",
                render: (row: any) => {
                    return (row.team_id ? row.team?.name : (row.request.team ? row.request.team.name : ""))
                        + "<mat-icon class='material-icons mat-icon pointer'>edit</mat-icon>";
                },
                click: (row: any): void => {
                    this.editParam(row, "team_id", "Team",
                        row.team_id ? row.team_id : row.request?.team_id);
                },
                sortable: false
            },
            {
                data: "request.order.main_address.country",
                name: "Request.Order.MainAddress.country",
                title: "Country",
                render: (row: any): string => {
                    if (row.request.order && row.request.order.main_address
                        && row.request.order.main_address.country_iso_2) {
                        return "<img class='flag' src='assets/images/flags/"
                            + row.request.order.main_address.country_iso_2.toLowerCase().trim() + ".svg' "
                            + "alt='" + row.request.order.main_address.country + "'>";
                    } else {
                        return "";
                    }
                },
                sortable: false
            },
            {
                data: "request.order.order_item.inventory_conversion.customers_inventory_name",
                name: "Request.Order.OrderItem.InventoryConversion.customers_inventory_name",
                title: "Warehouse",
                sortable: false
            },
            {
                data: "request.order.outbound_shipment.courier_transaction.tracking_number",
                name: "Request.Order.OutboundShipment.CourierTransaction.tracking_number",
                title: "Tracking #",
                render: (row: any): string => {
                    if (row.request.order?.shipments?.length > 0
                        && row.request.order.shipments[0]?.courier_transaction) {
                        const outboundShipment = row.request.order.shipments.find(s => s.type == "outbound");
                        return outboundShipment?.courier_transaction?.tracking_number
                            ?? row.request.order.shipments[0]?.courier_transaction?.tracking_number;
                    } else {
                        return "";
                    }

                },
                sortable: false
            },
            {
                data: "id",
                title: "",
                render: (row: any): string => {
                    const outboundShipment = row.request.order?.shipments?.find(s => s.type == "outbound");
                    const web_url = outboundShipment?.courier_transaction?.courier?.web_url
                        ?? row.request.order?.shipments[0]?.courier_transaction?.courier?.web_url;

                    if (web_url) {
                        return `<button type="button"
                        class="mat-mdc-mini-fab mdc-fab mat-primary" title="Go to Courier site">
                            <mat-icon class="mat-icon material-icons">arrow_forward</mat-icon>
                        </button>`;
                    }

                    return "";

                },
                click: (row: any): void => {
                    if (row.request.order?.shipments?.length > 0
                        && row.request.order.shipments[0]?.courier_transaction) {
                        const outboundShipment = row.request.order.shipments.find(s => s.type == "outbound");
                        const tracking_number = outboundShipment?.courier_transaction?.tracking_number
                            ?? row.request.order.shipments[0]?.courier_transaction?.tracking_number;
                        const web_url = outboundShipment?.courier_transaction?.courier?.web_url
                            ?? row.request.order.shipments[0]?.courier_transaction?.courier?.web_url;
                        window.open(
                            HelpersService.getTrackingUrl(web_url, tracking_number)
                        );
                    }
                },
                sortable: false,
                exportable: false
            },
            {
                data: "request.order.outbound_shipment.courier_transaction.status",
                name: "Request.Order.OutboundShipment.CourierTransaction.status",
                title: "Tracking status",
                render: (row: any): string => {
                    if (row.request.order?.shipments?.length > 0
                        && row.request.order.shipments[0]?.courier_transaction) {
                        const outboundShipment = row.request.order.shipments.find(s => s.type == "outbound");
                        return outboundShipment?.courier_transaction?.status
                            ?? row.request.order.shipments[0]?.courier_transaction?.status;
                    } else {
                        return "";
                    }

                },
                sortable: false
            },
        ];
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        const columns: Table.ICol[] = this.getColumns(this.state, this.router);

        this.listTable = {
            table_id: "followups-tasks-list",
            api: this.getApiData(),
            per_page: 50,
            actions: [
                {
                    name: "wizard",
                    title: "",
                    click: (row: any) => {
                        this.router.navigate([
                            this.state.section,
                            "followups",
                            "view",
                            "id",
                            row.request.id
                        ]);
                    }
                },
                {
                    name: "remarks",
                    title: "Remarks",
                    click: async (row: any): Promise<any> => {
                        this.router.navigate([
                            this.state.section,
                            "followups",
                            "view",
                            "id",
                            row.request.id
                        ]);
                    },
                    cssClassFn: (row: any): string => {
                        return row.request?.order?.order_remarks?.length > 0 ? "mat-accent" : "";
                    },
                    badgeFn: (row: any): string => {
                        return row.request?.order?.order_remarks?.length > 0
                            ? row.request.order.order_remarks.length : null;
                    }
                }
            ],
            columns,
            export: {
                file_name: "followups tasks list"
            },
            sort_default: {
                data: "due_date",
                dir: "desc"
            }

        };
        this.changeDetectorRef.markForCheck();
    }

    private async getPartners(): Promise<any> {
        if (this.state.section_type != "admin") {
            return;
        }
        const data = (await this.apiService.request(Api.EMethod.Get, "partner")).data;
        this.partners = data.map((partner: User.IPartner): any => {
            return {value: partner.id, name: partner.display_name};
        });
    }

    private async editParam(task: ITask, field: string, fieldTitle: string, old_value: any = null): Promise<any> {
        let type = "input";
        let values: any = null;
        let res: Api.IResponse;

        switch (field) {
            case "due_date":
                type = "date_time";
                break;
            case "complete":
                type = "checkbox";
                break;
            case "team_id":
                this.spinnerService.show();
                res = await this.api3Service.get(`${this.state.section}/teams`, {
                    data_structure: "select",
                    partner_id: task.request.partner_id,
                    category: "followup"
                });
                this.spinnerService.hide();

                type = "select";
                values = res.data;
                break;
            case "request_kind_id":
                this.spinnerService.show();
                res = await this.api3Service.get(`${this.state.section}/request-kinds`, {
                    data_structure: "select",
                    partner_id: task.request.partner_id,
                    category: "followup"
                });
                this.spinnerService.hide();


                type = "select";
                values = res.data;
                break;
            default:
                break;
        }

        const modalResponse: Modal.IResponse = await this.modalService.open(CommonFormComponent, {
            formConfig: {
                id: 0,
                name: "Edit " + fieldTitle,
                description: "",
                fields: [
                    {
                        label: fieldTitle,
                        name: field,
                        size: "full",
                        type,
                        required: true,
                        values
                    }
                ]
            },
            values: {
                [field]: old_value
            }
        });

        if (modalResponse != null && modalResponse.value != null && modalResponse.value[field] != null) {
            this.spinnerService.show();
            const {code, message}: Api.IResponse = await this.api3Service.patch(
                `${this.state.section}/followups-tasks/${task.id}`, modalResponse.value);
            this.spinnerService.hide();

            if (code === 200) {
                this.toastService.show(message, "success");
                this.tableRef.reload(this.getApiData());
            }
        }
    }


    public ngOnInit(): void {
        this.getPartners();
        this.prepareList();
        this.getKinds();
        this.getTeams();

        this.groupByOrder.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: boolean): void => {
            this.storageService.set("followups_by_tasks_group_by_order", value);
            this.tableRef.reload(this.getApiData());
        });

        this.onlyOpen.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: boolean): void => {
            this.storageService.set("followups_by_tasks_only_open", value);
            this.tableRef.reload(this.getApiData());
        });

        this.request_kind_id.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: boolean): void => {
            this.storageService.set("followups_by_tasks_request_kind_id", value);
            this.tableRef.reload(this.getApiData());
        });

        this.team_id.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: boolean): void => {
            this.storageService.set("followups_by_tasks_team_id", value);
            this.tableRef.reload(this.getApiData());
        });

        this.partner_ids.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: boolean): void => {
            this.tableRef.reload(this.getApiData());
        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "followups-by-tasks",
            actions: {
                "browse": ["browse_requests"]
            }
        };
    }
}
