import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {UntypedFormBuilder, FormControl} from "@angular/forms";
import {Modal, ModalService} from "../../../../../../services/modal.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {HelpersService} from "../../../../../../../common/services/helpers.service";
import {BarcodeReaderComponent} from "../../../../../../../common/components/barcode-reader/barcode-reader.component";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {debounceTime, takeUntil} from "rxjs/operators";

@Component({
    selector: "section-warehouse-transaction-serial",
    template: `


        <h1 class="container-heading">Add by serial</h1>

        <mat-card>
            <mat-card-content>

                <mat-form-field class="full">
                    <mat-label>Serial</mat-label>
                    <input matInput required [formControl]="serial">
                    <mat-icon matSuffix (click)="barcodeRead()">
                        photo_camera
                    </mat-icon>
                </mat-form-field>
                <div *ngIf="item">
                    <div>Item: <b>{{item.item}}</b></div>
                    <div>Serial: <b>{{item.serial}}</b></div>
                    <div>Batch: <b>{{item.batch}}</b></div>
                    <div>Configurations: <b>{{item.configurations}}</b></div>
                    <div>Quantity: <b>{{item.quantity}}</b></div>
                </div>
                <div class="actions" style="margin-top: 20px;">
                    <button [disabled]="!item" mat-raised-button color="primary" type="button"
                            (click)="accept()" class="main">
                        Accept
                    </button>
                </div>
            </mat-card-content>
        </mat-card>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class WarehouseTransactionSerialComponent implements OnInit, OnDestroy {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    public serial: FormControl = new FormControl(null);
    public modal: Modal.IModal;

    public item: any;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private formBuilder: UntypedFormBuilder,
        private modalService: ModalService,
        private toastService: ToastService,
        private apiService: ApiService,
        public helpers: HelpersService
    ) {
    }

    /**
     * Search fot item by its serial
     * @param {string} serial
     * @returns {Promise<any>}
     */
    private async search(serial: string): Promise<any> {
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["order_item", "by_sn", serial.trim()]);

        if (response) {
            this.toastService.show(response.message, response.type as string);
            if (response.type === "success") {
                this.item = response.data;
            } else {
                this.item = null;
            }
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Open barcode reader
     * @returns {Promise<any>}
     */
    public async barcodeRead(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(BarcodeReaderComponent, {
            modalWidth: 680,
            modalHeight: 530
        });
        if (response) {
            this.search(response.value);
        }
    }

    /**
     * Accept founded item and close window
     */
    public accept(): void {
        this.modal.response.next({
            name: "value",
            value: this.item
        });
    }


    public ngOnInit(): void {
        this.serial.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500)).subscribe((value: string): void => {
            if (value) {
                this.search(value);
            } else {
                this.item = null;
                this.changeDetectorRef.markForCheck();
            }
        });
    }


    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
