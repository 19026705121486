import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Modal} from "../../../../../services/modal.service";
import {takeUntil} from "rxjs/operators";
import {SpinnerService} from "../../../../../../common/services/spinner.service";


@Component({
    selector: "section-parcel-form",
    templateUrl: "form.component.html",
    styleUrls: [
        "form.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})

export class ParcelFormComponent implements OnInit {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    private dimensionsControls: { [key: string]: FormControl } = {
        length: new FormControl(null, [Validators.required]),
        width: new FormControl(null, [Validators.required]),
        height: new FormControl(null, [Validators.required]),
        distance_unit: new FormControl("cm", [Validators.required]),
        weight: new FormControl(null, [Validators.required]),
        mass_unit: new FormControl("kg", [Validators.required]),
    };

    /**
     * Form group
     * @type {FormGroup}
     */
    public formGroup: FormGroup = new FormGroup({
        ref: new FormControl(null, [Validators.required]),
        shipment_id: new FormControl(null)
    });

    public addDims: FormControl = new FormControl(false);


    public distanceUnits: string [] = [
        "cm"
    ];

    public massUnits: string[] = [
        "kg"
    ];

    public modal: Modal.IModal;

    /**
     * Window title
     * @type {string}
     */
    public window_title: string;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private toastService: ToastService,
        private spinnerService: SpinnerService
    ) {
    }

    /**
     * Set form values (modal params data)
     * @returns {void}
     */
    private setFormValues(): void {
        if (this.modal.params.parcel) {
            this.formGroup.patchValue(this.modal.params.parcel);
        }
        if (this.modal.params.shipmentId) {
            this.formGroup.get("shipment_id").setValue(this.modal.params.shipmentId);
        }
    }


    /**
     * Add item (prepare & add modal response)
     */
    public async save(): Promise<any> {
        this.spinnerService.show();

        let response: Api.IResponse;
        if (this.modal.params.action === "edit" && this.modal.params.parcel) {
            response = await this.apiService.request(Api.EMethod.Put,
                ["parcel", this.modal.params.parcel.id], this.formGroup.value);
        } else if (this.modal.params.action === "add") {
            this.formGroup.value.order_id = this.modal.params.order_id;
            this.formGroup.value.type = this.modal.params.type;
            response = await this.apiService.request(Api.EMethod.Post, ["parcel"], this.formGroup.value);
        }

        this.spinnerService.hide();

        if (response) {
            this.toastService.show(response.message, response.type as string);
            if (response.type as string === "success") {
                if (this.modal.params.action === "edit") {
                    Object.keys(this.formGroup.controls).forEach((name: string): void => {
                        if (this.modal.params.parcel[name]) {
                            this.modal.params.parcel[name] = this.formGroup.get(name).value;
                        }
                    });
                    this.modal.response.next({
                        name: "value",
                        value: {
                            parcel: this.modal.params.parcel,
                            action: "edit"
                        }
                    });
                } else if (this.modal.params.action === "add") {
                    this.formGroup.value.id = response.data.id;
                    this.formGroup.value.order_item = [];
                    this.modal.response.next({
                        name: "value",
                        value: {
                            parcel: this.formGroup.value,
                            action: "add"
                        }
                    });
                }
            }
        }

    }

    public async delete(): Promise<any> {
        this.spinnerService.show();
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
            ["parcel", this.modal.params.parcel.id]);
        this.spinnerService.hide();
        if (response) {
            this.toastService.show(response.message, response.type as string);
            if (response.type as string === "success") {
                this.modal.response.next({
                    name: "value",
                    value: {
                        parcel: this.modal.params.parcel,
                        action: "delete"
                    }
                });
            }
        }
    }

    public ngOnInit(): void {
        this.window_title = this.modal.params.action === "edit" ? "Edit parcel" : "Add parcel";
        this.addDims.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((checked: boolean): void => {
            if (checked) {
                for (const name of Object.keys(this.dimensionsControls)) {
                    this.formGroup.addControl(name, this.dimensionsControls[name]);
                }
            } else {
                for (const name of Object.keys(this.dimensionsControls)) {
                    this.formGroup.removeControl(name);
                    this.formGroup.updateValueAndValidity();
                }
            }
            this.changeDetectorRef.markForCheck();
        });
        if (this.modal.params.action === "edit") {
            this.addDims.setValue(true);
        }
        this.setFormValues();
    }
}
