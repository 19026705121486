import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, ViewEncapsulation} from "@angular/core";
import {FormControl, Validators} from "@angular/forms";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../common/interfaces/wizard.interface";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: "section-warehouse-order-wizard-shipments-type",
    templateUrl: "shipments-type.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})

export class WarehouseOrderWizardShipmentsTypeComponent extends AbstractWizardStepComponent implements OnDestroy {

    public shipmentsType: FormControl = new FormControl(null, [Validators.required]);

    public constructor(protected changeDetectorRef: ChangeDetectorRef) {
        super(changeDetectorRef);
    }


    /**
     * Initialize details step
     * @param data
     * @returns {Promise<any>}
     */
    public async init(data: Wizard.IData): Promise<any> {
        this.shipmentsType.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: string): void => {
            this.result.next({
                action: "setup",
                value
            });
            this.result.next({
                action: "result",
                value: {lineType: value}
            });
        });
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}
