<common-confirm></common-confirm>
<a class="mat-mdc-raised-button mdc-button pull-right" (click)="toList()">Back to list</a>
<a class="mat-mdc-raised-button mdc-button pull-right mat-warn" style="margin-right: 10px" (click)="delete()">Delete</a>
<a class="mat-mdc-raised-button mdc-button pull-right mat-accent" style="margin-right: 10px" (click)="edit()">Edit</a>
<h1 class="container-heading" *ngIf="item">
    {{item.name}} integration
</h1>
<mat-card>
    <mat-card-content>
        <a class="mat-mdc-raised-button mdc-button mat-primary"
           *userAllowed="'browse_admin'"
           (click)="addFunction()">Add function</a>&nbsp;
        <a class="mat-mdc-raised-button mdc-button mat-primary" *ngIf="item && item.integration_connector"
           (click)="connector = !connector">{{connector ? 'Go to functions' : 'Edit connector'}}</a>

        <a class="mat-mdc-raised-button mdc-button mat-accent" *ngIf="item && item.instructions"
           (click)="showInstructions()">
            Show instructions
        </a>
        <br/>
        <br/>
        <mat-accordion
                *ngIf="!connector && item && (!item.connector || (item.connector && item.integration_connector))">
            <ng-template ngFor let-func [ngForOf]="item.integration_functions">
                <mat-expansion-panel [expanded]="state.params.function === func.id.toString()">
                    <mat-expansion-panel-header>
                        <mat-panel-title>

                            <mat-icon matTooltip="Delete" *userAllowed="'browse_admin'"
                                      (click)="delete_func(func.id)"
                                      color="warn">delete
                            </mat-icon>

                            <mat-icon matTooltip="Edit" *userAllowed="'browse_admin'"
                                      (click)="edit_func(func)"
                                      color="primary">edit
                            </mat-icon>

                            <mat-icon>extension</mat-icon>
                            &nbsp;
                            <b>{{func.name}}</b>
                        </mat-panel-title>
                        <mat-panel-description>
                            {{func.description}}
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div class="form">
                        <common-form-fields [fields]="func.fields"
                                            [values]="func.values"
                                            #fields></common-form-fields>


                        <ng-container *ngIf="func.can_be_scheduled">
                            <div class="group flex">
                                <div class="full">
                                    <mat-checkbox [formControl]="showScheduler">Schedule execution</mat-checkbox>
                                </div>
                            </div>
                            <common-scheduler-form *ngIf="showScheduler.value"
                                                   [ref]="func.name"
                                                   type="App\IntegrationFunction"
                                                   [type_id]="func.id"
                                                   [params]="fields.formGroup.value"
                                                   (result)="onSchedulerResult($event)"
                            ></common-scheduler-form>
                        </ng-container>

                        <div class="actions" *ngIf="!showScheduler.value">
                            <button type="button" mat-raised-button [disabled]="!fields.formGroup.valid"
                                    color="primary" (click)="submit('function', func, fields.formGroup.value)">
                                Send
                            </button>
                        </div>
                    </div>
                </mat-expansion-panel>
            </ng-template>
            <div *ngIf="!item.integration_functions.length">
                <p>
                    Nothing is here yet
                </p>
            </div>
        </mat-accordion>

        <div *ngIf="(item && item.connector && !item.integration_connector) || connector">
            <div class="form">
                <common-form-fields [fields]="item.properties"
                                    [values]="item.integration_connector ? item.integration_connector.details : null"
                                    #fields></common-form-fields>
                <div class="actions">
                    <button type="button" mat-raised-button [disabled]="!fields.formGroup.valid"
                            color="primary" (click)="submit('connector', {async: 0}, fields.formGroup.value)">
                        Submit
                    </button>
                    <button type="button" mat-raised-button
                            color="warn" *ngIf="item.integration_connector"
                            (click)="deleteConnector(item.integration_connector.id)">
                        Clean connector
                    </button>
                </div>
            </div>
        </div>

    </mat-card-content>
</mat-card>
