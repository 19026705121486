import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation
} from "@angular/core";
import {FormControl} from "@angular/forms";
import * as Quill from "quill";
import {Modal, ModalService} from "../../../../section/services/modal.service";
import {FileUploadComponent} from "../../file-upload/file-upload.component";

const Delta: Quill.DeltaStatic = Quill.import("delta");
const BaseImageFormat: any = Quill.import("formats/image");
const ATTRIBUTES: string[] = ["src", "alt", "height", "width", "style", "class"];

class ImageFormat extends BaseImageFormat {

    static formats(domNode): any {
        return ATTRIBUTES.reduce(function (formats, attribute) {
            if (domNode.hasAttribute(attribute)) {
                formats[attribute] = domNode.getAttribute(attribute);
            }
            return formats;
        }, {});
    }

    format(name, value): void {
        if (ATTRIBUTES.indexOf(name) > -1) {
            if (value) {
                this.domNode.setAttribute(name, value);
            } else {
                this.domNode.removeAttribute(name);
            }
        } else {
            super.format(name, value);
        }
    }
}

Quill.register(ImageFormat, true);


@Component({
    selector: "common-form-editor",
    template: `
        <quill-editor [placeholder]="label" [formControl]="editorInput"
                      [modules]="quillModules"
                      (onContentChanged)="update($event)"
                      [minLength]="minLength"
                      (onEditorCreated)="getEditorInstance($event)">

        </quill-editor>
    `,
    styles: [
        `
                     common-form-editor .ql-toolbar {
                         background-color: #fff;
                     }
                 `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class CommonFormEditorComponent implements OnInit, OnDestroy, OnChanges {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input()
    public label: string;

    @Input()
    public value: string = null;

    @Input()
    public required: boolean = false;

    @Input()
    public minLength: number;

    @Output()
    public valueChange: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    public minLengthValid: EventEmitter<boolean> = new EventEmitter<boolean>();

    public editorInput: FormControl = new FormControl(null);

    public quillModules: { [key: string]: any } = {
        toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{"list": "ordered"}, {"list": "bullet"}],
            [{"script": "sub"}, {"script": "super"}],
            [{"indent": "-1"}, {"indent": "+1"}],
            [{"header": [1, 2, 3, 4, 5, 6, false]}],
            [{"color": []}, {"background": []}],
            [{"align": []}],
            ["link", "image"],
            ["clean"],
        ],
    };

    public constructor(
        private modalService: ModalService,
        private ngZone: NgZone
    ) {
    }

    private async uploadFile(editorInstance: any): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(FileUploadComponent, {
            url: ["user", "file"],
            accept: ["gif", "png", "jpg", "jpeg", "webp", "bmp"]
        });

        if (response && response.value && response.value.data && response.value.data.path) {

            const fileUrl: any = response.value.data.path;
            const range: any = editorInstance.getSelection(true);
            editorInstance.updateContents(
                new Delta()
                    .retain(range.index)
                    .delete(range.length)
                    .insert({
                        image: fileUrl
                    }, {
                        class: "responsive",
                    })
            );
        }
    }

    public getEditorInstance(editorInstance: any): void {
        editorInstance.getModule("toolbar").addHandler("image", () => {
            this.ngZone.run(() => {
                this.uploadFile(editorInstance);
            });
        });
    }

    public update(event: any): void {
        if (!this.required || event.text.trim()) {
            this.valueChange.emit(event.html);
        } else {
            this.valueChange.emit(null);
        }

        if (this.minLength) {
            this.minLengthValid.emit(this.editorInput.valid);
        }
    }

    public ngOnInit(): void {
        this.editorInput.setValue(this.value);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.value && !changes.value.firstChange && !changes.value.previousValue) {
            this.editorInput.setValue(changes.value.currentValue, {emitEvent: false, onlySelf: false});
        }
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
