import {Component, OnInit} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Order} from "../../../../../../common/interfaces/order.interface";


@Component({
    selector: "section-order-list-closed",
    template: `
        <section-order-list [params]="params"></section-order-list>`
})
export class OrderListClosedComponent implements Base.IComponent, OnInit {

    public readonly state: Base.IState;

    public params: Order.IOrderListParams;

    public constructor() {
    }

    public ngOnInit(): void {
        this.params = {
            title: "Closed orders list",
            state: this.state,
            actions: {
                view: true,
                delete: true,
                add: false,
                export: true,
                remarks: true
            },
            query: {status_parameter_closed: true}
        };
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "orders-closed",
            actions: {
                "browse": ["browse_orders"]
            }
        };
    }

}
