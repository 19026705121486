import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Type,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from "@angular/core";
import {Router} from "@angular/router";
import {UserService} from "../../../common/services/user.service";
import {StorageService} from "../../../common/services/storage.service";
import {DrawerService} from "../../services/drawer.service";
import {takeUntil} from "rxjs/operators";
import {fromEvent} from "rxjs";
import {ModalService} from "../../../section/services/modal.service";
import {AppStateService} from "../../../common/services/app-state.service";
import {Base} from "../../../common/interfaces/base.interfaces";
import {AmplitudeService} from "../../../common/services/amplitude.service";

export namespace Drawer {

    export interface IItem {
        link: string;
        title: string;
        expanded?: boolean;
        icon?: string;
        iconFa?: string;
        items?: IItem[];
        permissions: string[];
        canSee?: string[];
        except?: string[];
        modal?: { component: Type<any>, params: any };
    }

}

@Component({
    selector: "app-drawer",
    templateUrl: "drawer.component.html",
    styleUrls: [
        "drawer.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class DrawerComponent implements OnInit, OnDestroy {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();
    public section: string;
    public type: string;

    @ViewChild("drawer", {read: ViewContainerRef, static: true})
    public drawer: ViewContainerRef;

    /**
     * Drawer items
     * @type {Drawer.IItem[]}
     */
    public items: Drawer.IItem[] = [];

    public logo: string;
    public logo_icon: string;

    public lockDrawer: boolean = false;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private userService: UserService,
        private storageService: StorageService,
        private drawerService: DrawerService,
        private modalService: ModalService
    ) {
    }

    /**
     * Collapse sibling child items
     * @param items
     * @returns {void}
     */
    public static collapse(items: Drawer.IItem[]): void {
        for (const item of items) {
            if (item.items) {
                item.expanded = false;
                DrawerComponent.collapse(item.items);
            }
        }
    }

    /**
     * Expand item (by active link)
     * @returns {void}
     */
    private expandItem(): void {
        const segments: string[] = this.router.url.split("/");
        let url: string = segments[3];
        if (segments[1] === "admin") {
            url = "/admin/" + segments[2];
        }
        for (const item of this.items) {
            if (item.items) {
                item.expanded = this.checkRoute(item.items, url);
            }
        }
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Check if any child items is active & expand it
     * @param items
     * @param {string} url
     * @returns {boolean}
     */
    private checkRoute(items: Drawer.IItem[], url: string): boolean {
        for (const item of items) {
            if (item.items) {
                item.expanded = this.checkRoute(item.items, url);
                if (item.expanded) {
                    return true;
                }
            } else if (item.link === url) {
                return true;
            }
        }
        return false;
    }

    private toggleDrawer(): void {
        const element: Element = this.drawer.element.nativeElement;
        fromEvent(element, "mouseenter").pipe(takeUntil(this.destroy$))
            .subscribe((): void => element.classList.add("full"));
        fromEvent(element, "mouseleave").pipe(takeUntil(this.destroy$))
            .subscribe((): void => element.classList.remove("full"));
    }


    /**
     * Get partner icon & logo (by selected section)
     */
    private getLogo(): void {
        if (this.section === "admin") {
            return;
        }
        if (!this.userService.data[this.type + "s"]) {
            return;
        }
        for (const item of this.userService.data[this.type + "s"]) {
            if (item.slug === this.section) {
                if (item.icon_path) {
                    this.logo_icon = item.icon_path;
                }
                if (item.logo_path) {
                    this.logo = item.logo_path;
                }
            }
        }


        this.changeDetectorRef.markForCheck();
    }

    /**
     * Handle state changes of menu root items
     * @param index
     * @param item
     * @returns {void}
     */
    public onChildEvent(index: number, item: Drawer.IItem): void {

        if (item) {
            AmplitudeService.eventClick(item.link ? "Show page " + item.link : "Open menu " + item.title);
        }

        for (const i of Object.keys(this.items)) {
            if (Number(i) !== index) {
                this.items[i].expanded = false;
                if (this.items[i].items) {
                    DrawerComponent.collapse(this.items[i].items);
                }
            }
        }
        this.changeDetectorRef.markForCheck();
    }

    public allowed(permissions: string[]): boolean {
        return this.userService.validatePermissions(permissions);
    }

    public canSee(item: Drawer.IItem): boolean {
        if (item.canSee && item.canSee.length && !item.canSee.includes(this.section)) {
            return false;
        } else if (item.except && item.except.length && item.except.includes(this.section)) {
            return false;
        }
        return true;
    }

    public onLockDrawer(): void {
        this.drawerService.drawerLock(!this.lockDrawer);
    }

    public async openModal(item: Drawer.IItem): Promise<any> {
        await this.modalService.open(item.modal.component, item.modal.params);
    }

    public ngOnInit(): void {
        let firstBoot: boolean = true;
        this.drawerService.drawerItems.pipe(takeUntil(this.destroy$)).subscribe((items: Drawer.IItem[]): void => {
            if (!items) {
                return;
            }
            this.items = items;
            this.changeDetectorRef.markForCheck();
            if (firstBoot) {
                this.expandItem();
                firstBoot = false;
            }
        });


        this.lockDrawer = Boolean(this.storageService.get("lockDrawer"));
        this.drawerService.drawerLockState.pipe(takeUntil(this.destroy$)).subscribe((state: boolean): void => {
            this.lockDrawer = state;
            this.changeDetectorRef.markForCheck();
        });

        setTimeout(() => {
            const state: Base.IState = AppStateService.getState();
            this.section = state.section_slug;
            this.type = state.section_type;
            this.getLogo();

            this.drawerService.setItems(this.type, this.type === "partner" ? this.section : null);

            this.toggleDrawer();
        }, 100);

    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
