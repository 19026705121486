import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {Modal} from "../../../../../services/modal.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Api3Service} from "../../../../../../common/services/api3.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {AppStateService} from "../../../../../../common/services/app-state.service";

@Component({
    selector: "section-warehouse-location-form",
    templateUrl: "form.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class WarehouseLocationFormComponent implements OnDestroy, OnInit, AfterViewInit {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    private state: Base.IState;

    public modal: Modal.IModal;

    @ViewChild("locationInputRef", {static: false})
    public locationInputRef: ElementRef;

    @Output()
    public result: EventEmitter<any> = new EventEmitter();

    @Output()
    public reset: EventEmitter<any> = new EventEmitter();

    @Input()
    public location_name: string = null;

    public locationTypes: any[];

    public locationFrom: FormGroup = new FormGroup({
        location: new FormControl(null, [Validators.required]),
        warehouse_location_type_id: new FormControl(null, [Validators.required]),
        inventory_conversion_id: new FormControl(null)
    });

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        public helperService: HelpersService,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private spinnerService: SpinnerService
    ) {
    }

    private async getLocationTypes(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["warehouse_location", "type"], {}, {
                data_structure: "select"
            });
        if (data) {
            this.locationTypes = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Add new location and select it
     */
    private async addLocation(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["warehouse_location"], this.locationFrom.value);
        if (data) {
            this.result.emit(data);
            if (this.modal) {
                this.modal.response.emit({
                    name: "response",
                    value: data
                });
            }
            this.locationFrom.reset();
        }
        this.spinnerService.hide();
    }

    private async editLocation(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.put(
            `${this.state.section}/locations/${this.modal.params.data.id}`, this.locationFrom.value);

        this.spinnerService.hide();

        if (data) {
            this.result.emit(data);
            if (this.modal) {
                this.modal.response.emit({
                    name: "response",
                    value: data
                });
            }
            this.locationFrom.reset();
        }
    }

    public submit(): void {
        if (this.modal.params.data) {
            this.editLocation();
        } else {
            this.addLocation();
        }
    }

    public ngOnInit(): void {
        this.state = AppStateService.getState();

        this.getLocationTypes();

        if (this.modal) {
            if (this.modal.params.data) {
                this.locationFrom.patchValue(this.modal.params.data);
            }

            if (this.modal.params.location_name) {
                this.location_name = this.modal.params.location_name;
            }

            if (this.modal.params.inventory_conversion_id) {
                this.locationFrom.get("inventory_conversion_id").setValue(this.modal.params.inventory_conversion_id);
            }
        }
        if (this.location_name) {
            this.locationFrom.get("location").setValue(this.location_name);
        }

        this.changeDetectorRef.markForCheck();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngAfterViewInit(): void {
        this.locationInputRef.nativeElement.focus();
    }
}
