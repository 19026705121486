import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {FormControl} from "@angular/forms";
import {Base} from "../../../../../../../../common/interfaces/base.interfaces";
import {ConfirmComponent} from "../../../../../../../../common/components/confirm/confirm.component";
import {Warehouse} from "../../../../../../../../common/interfaces/warehouse.interface";
import {Api, ApiService} from "../../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../../common/services/toast.service";
import {debounceTime, takeUntil} from "rxjs/operators";
import {SpinnerService} from "../../../../../../../../common/services/spinner.service";
import {UserService} from "../../../../../../../../common/services/user.service";
import {Api3Service} from "../../../../../../../../common/services/api3.service";
import {IPagination} from "../../../../../../../../common/components/pagination/pagination.component";
import IBox = Warehouse.IBox;

@Component({
    selector: "section-warehouse-order-wizard-shuffle-boxes",
    template: `
        <div class="text-right">
            <mat-form-field>
                <mat-label>Search boxes</mat-label>
                <input type="search" matInput [formControl]="search">
            </mat-form-field>
        </div>

        <div *ngIf="boxes">
            <div class="columns">
                <mat-card class="margin-bottom-10 mat-elevation-z3" *ngFor="let box of boxes.data">
                    <div>
                        <div class="pull-right">
                            <button type="button"
                                    [disabled]="(''+box.warehouse_package_id) === (''+warehouse_package_id)"
                                    mat-raised-button
                                    color="accent"
                                    (click)="linkBox(box)">
                                Link
                            </button>
                        </div>
                        <div>Box Ref: <b>{{ box.ref }}</b></div>
                        <div>Item: <b>{{ box.properties ? box.properties.item : '' }}</b></div>
                        <div>Box Qty: <b>{{ box.warehouse_transactions_sum_quantity }}</b></div>
                        <div>Package: <b>{{ box.warehouse_package.name }}</b></div>
                    </div>
                </mat-card>
            </div>

            <div>
                <common-pagination [data]="boxes" pid="m44Y0Lzu"
                                   (goToPage)="getBoxes(search.value, $event.page, $event.per_page)">
                </common-pagination>
            </div>

        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class WarehouseProceduresWizardShuffleBoxesComponent implements OnInit, OnDestroy {

    protected destroy$: EventEmitter<boolean> = new EventEmitter(false);

    private page: number = 1;
    private per_page: number = 10;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @Input()
    public shipment_id: number;

    @Input()
    public state: Base.IState;

    public boxes: IPagination<IBox>;

    public search: FormControl = new FormControl(null);

    @Input()
    public warehouse_package_id: number;

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private spinnerService: SpinnerService,
        private userService: UserService
    ) {
    }


    /**
     * Get package list
     */
    public async getBoxes(search_by: string = null, page: number = 1, per_page: number = null): Promise<any> {
        this.spinnerService.show();
        if (per_page === null) {
            per_page = this.userService.data.settings.default_per_page;
        }

        const {data}: Api.IResponse = await this.api3Service.get(
            `${this.state.section}/shipments/${this.shipment_id}/transactions/boxes`, {
                data_structure: "paginated",
                page,
                per_page,
                search_by,
                search_in: ["ref"],
                with_transaction_qty: true,
                relations: [
                    "WarehouseLocation",
                    "WarehousePackage:id,name"
                ],
            });
        this.spinnerService.hide();

        if (data) {
            this.boxes = data;
            this.changeDetectorRef.markForCheck();
        }
    }


    public async linkBox(box: IBox): Promise<any> {
        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["box", "" + box.id], {
                warehouse_package_id: this.warehouse_package_id
            });
        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.getBoxes(this.search.value, this.page, this.per_page);
        }
    }


    public ngOnInit(): void {

        this.getBoxes();

        this.search.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(1000))
            .subscribe((value: string): void => {
                this.getBoxes(value);
            });
    }


    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
