import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {FormControl, Validators} from "@angular/forms";
import {ToastService} from "../../../../../../common/services/toast.service";
import {ModalService} from "../../../../../services/modal.service";
import {UserService} from "../../../../../../common/services/user.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {RequestsViewComponent} from "../../requests";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {User} from "../../../../../../common/interfaces/user.interface";
import {Api3Service} from "../../../../../../common/services/api3.service";

@Component({
    selector: "section-followups-view",
    templateUrl: "view.component.html",
    styleUrls: ["view.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class FollowupsViewComponent extends RequestsViewComponent implements Base.IComponent, OnInit, OnDestroy {

    public orderLinkEdit: boolean = false;

    public orderRefInput: FormControl = new FormControl(null, [Validators.required]);

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        protected router: Router,
        protected apiService: ApiService,
        protected api3Service: Api3Service,
        protected toastService: ToastService,
        protected modalService: ModalService,
        protected userService: UserService,
        protected helperService: HelpersService,
        protected spinnerService: SpinnerService
    ) {
        super(changeDetectorRef, router, apiService, api3Service, toastService, modalService, userService,
            helperService, spinnerService);
        this.me = userService.data;
    }

    protected customSetup(): any {
        this.orderRefInput.setValue(this.data.order.ref);
    }

    /**
     * Get teams list
     * @returns {Promise<any>}
     */
    public async getTeams(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["team"], {}, {
                category: "followup"
            });
        if (data) {
            this.teams = data.map((user: User.IData): any => {
                return {name: user.name, value: user.id};
            });
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    public async getKinds(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["request", "kind"], {}, {
                data_structure: "select",
                category: "followup"
            });
        if (data) {
            this.kinds = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "followups",
            actions: {
                "view": ["read_requests"]
            }
        };
    }
}
