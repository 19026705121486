import {ChangeDetectionStrategy, Component, TemplateRef, ViewChild, ViewEncapsulation} from "@angular/core";
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {take} from "rxjs/operators";

@Component({
    selector: "common-confirm",
    templateUrl: "confirm.component.html",
    styleUrls: [
        "confirm.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class ConfirmComponent {

    /**
     * Config
     * @type {MatDialogConfig}
     */
    private config: MatDialogConfig = {
        minWidth: "300px"
    };

    @ViewChild(TemplateRef, {static: false})
    public confirmTemplateRef: TemplateRef<any>;

    /**
     * Dialog component reference
     * @type {MatDialogRef<boolean>}
     */
    public dialogRef: MatDialogRef<boolean>;

    /**
     * Question
     * @type {string}
     */
    public question: string = "";
    public title: string = "Confirm";
    public auto: boolean = null;

    public constructor(public matDialog: MatDialog) {
    }

    /**
     * Execute confirmation
     * @param {string} question
     * @param title
     * @param auto
     * @returns {Promise<boolean>}
     */
    public confirm(question: string, title: string = "Confirm", auto: boolean = null): Promise<boolean> {
        this.question = question;
        this.title = title;
        this.auto = auto;
        this.dialogRef = this.matDialog.open(this.confirmTemplateRef, this.config);
        return new Promise((resolve: Function): void => {
            let timeout: any;
            if (auto !== null) {
                timeout = setTimeout((): void => {
                    this.dialogRef.close(auto);
                }, 20000);
            }


            this.dialogRef.beforeClosed().pipe(take(1)).subscribe((value: boolean): void => {
                clearTimeout(timeout);
                resolve(value);
            });
        });
    }

}
