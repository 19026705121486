<div class="pull-right info" *ngIf="submission">
    <div *ngIf="submission.pdf_url">
        <b>Get PDF:</b>&nbsp;<a [href]="submission.pdf_url" target="_blank">Download</a>
    </div>
    <div>
        <b>Filled by:</b>
        {{submission.user.name}}
    </div>
    <div>
        <b>Created at:</b>
        {{submission.created_at}}
    </div>
    <div *userAllowed="['edit_inspections']" class="margin-bottom-20">
        <button mat-raised-button (click)="edit()" color="accent">
            Edit inspection
        </button>
    </div>
</div>

<h1 *ngIf="formConfig" class="container-heading">{{formConfig.name}}</h1>

<mat-card>
    <mat-card-content *ngIf="formConfig">
        <div class="description">
            {{formConfig.description}}

            <div class="pull-right">
                <a *ngIf="submission.pdf_url" [href]="submission.pdf_url" target="_blank">
                    <button mat-raised-button color="accent">
                        Download PDF
                    </button>
                </a>
                &nbsp;
                &nbsp;
                <button mat-raised-button color="warn" (click)="recreatePdf()">
                    Recreate PDF
                </button>
            </div>

        </div>

        <mat-list>
            <ng-template ngFor let-field [ngForOf]="formData | keys">
                <div class="field">
                    <b>{{formData[field].label}}:</b>
                    <span>
                        <ng-template [ngIf]="formData[field].type === 'signature'
                        || (formData[field].type === 'file' && is_image(formData[field].value))">
                            <div style="width: 600px">
                                <img [src]="formData[field].value" alt="">
                            </div>
                        </ng-template>
                        <ng-template [ngIf]="formData[field].type === 'file' && !is_image(formData[field].value)">
                           <a [href]="formData[field].value" target="_blank" [download]="true">Download</a>
                        </ng-template>
                        <ng-template [ngIf]="formData[field].type === 'text'">
                            <span style="white-space: pre-line">{{formData[field].value}}</span>
                        </ng-template>
                        <ng-template
                                [ngIf]="formData[field].type !== 'signature' && formData[field].type !== 'file' && formData[field].type !== 'text'">
                            {{formData[field].value}}
                        </ng-template>
                    </span>
                </div>
                <mat-divider></mat-divider>
            </ng-template>
        </mat-list>
    </mat-card-content>
</mat-card>
