import {Wizard} from "../../../../../../../common/interfaces/wizard.interface";
import {Order} from "../../../../../../../common/interfaces/order.interface";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from "@angular/core";
import {ConfirmComponent} from "../../../../../../../common/components/confirm/confirm.component";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {ModalService} from "../../../../../../services/modal.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {StorageService} from "../../../../../../../common/services/storage.service";
import {PusherService} from "../../../../../../../common/services/pusher.service";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {AbstractSinglePageWizardStepComponent}
    from "../../../../../../../common/interfaces/wizard-single-page.interface";
import {Contact} from "../../../../../../../common/interfaces/contact.interface";
import {PartnerPartMasterService} from "../../../../services/part-master.service";
import {Form} from "../../../../../../../common/interfaces/form.interface";
import {Table} from "../../../../../../../common/interfaces/table.interface";
import {Table2Component} from "../../../../../../../common/components/table2";
import {OrderItemsInfoComponent} from "../../view/items/info/info.component";
import {PartnerService} from "../../../../../../../common/services/partner.service";
import {Api3Service} from "../../../../../../../common/services/api3.service";
import {AppStateService} from "../../../../../../../common/services/app-state.service";
import ISelectOption = Form.ISelectOption;

@Component({
    template: ""
})
export class OrderWizardItemsComponent extends AbstractSinglePageWizardStepComponent
    implements OnDestroy, OnInit, OnChanges {

    protected serviceLevel: Order.IServiceLevel;

    protected state: Base.IState;

    protected columns: Table.ICol[] = [
        {
            data: "item",
            title: "Item"
        },
        {
            data: "inventory_conversion.customers_inventory_full_name",
            title: "Hub"
        },
        {
            data: "quantity",
            title: "Quantity"
        },
        {
            data: "inventory_allocations_sum_quantity",
            title: "Allocations",
            render: (row: any): string => {
                if (row.allocation_in_progress) {
                    return `<button class="mat-mdc-mini-fab mdc-fab mat-warn spin" disabled>
                                <mat-icon class="mat-icon material-icons">autorenew</mat-icon>
                            </button>`;
                }
                if (row.inventory_allocations_sum_quantity > 0) {
                    return "<button class='mat-mdc-raised-button mdc-button mat-badge mat-badge-accent"
                        + " mat-badge-overlap mat-badge-above mat-badge-after mat-badge-medium' "
                        + "type='button'>"
                        + "<span class='mat-badge-content mat-badge-active'>"
                        + row.inventory_allocations_sum_quantity + "</span>"
                        + "Allocations"
                        + "</button>";
                }
                return ``;
            },
            click: (row: any): void => {
                if (row.allocation_in_progress) {
                    return;
                }
                if (row.inventory_allocations_sum_quantity) {
                    this.showAllocationsForItem(row);
                }

                return;
            },
            cssClass: "text-center",
            sortable: false,
            searchable: false
        },
        {
            data: "batch",
            title: "Batch"
        },
        {
            data: "type",
            title: "Type"
        }
    ];

    protected allocationTimeout: any = null;

    protected address: Contact.IAddress;

    public partmasters: ISelectOption[] = [];

    public _skipAllocations: boolean = false;

    public details: any;

    public stockMode: string;

    @Output()
    public result: EventEmitter<Wizard.IStepResult> = new EventEmitter();

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild("outboundTableRef", {static: false})
    public outboundTableRef: Table2Component;

    @ViewChild("inboundTableRef", {static: false})
    public inboundTableRef: Table2Component;

    public outboundTable: Table.ISettings;
    public inboundTable: Table.ISettings;

    public allocationReleaseDate: string;

    public constructor(
        protected apiService: ApiService,
        protected api3Service: Api3Service,
        protected modalService: ModalService,
        protected changeDetectorRef: ChangeDetectorRef,
        protected toastService: ToastService,
        protected storageService: StorageService,
        protected pusherService: PusherService,
        protected partMasterService: PartnerPartMasterService,
        protected spinnerService: SpinnerService
    ) {
        super(changeDetectorRef);
    }

    private connectToSockets(): void {

        this.pusherService.echo.private("broadcast-partner-" + AppStateService.getState().section_slug)
            .listen(".App\\Events\\AllocationStartEvent", (data: any): void => {
                console.warn("AllocationStartEvent", data);

                // if (data.order_id == this.data.order_id) {
                //     this.outboundTableRef?.reload();
                // }
            })
            .listen(".App\\Events\\AllocationFinishEvent", (data: any): void => {
                console.warn("AllocationFinishEvent", data);

                if (data.order_id == this.data.order_id) {
                    this.outboundTableRef?.reload();
                    this.inboundTableRef?.reload();
                }
            });
    }

    protected getUrl(type: string): Table.ITableApi {
        return {
            url: [this.state.section, "order-items"],
            query: {
                order_id: this.data.order_id ?? 0,
                type,
                relations: [
                    "InventoryConversion:id,customers_inventory_name,customers_sub_inventory",
                ],
                relation_sum: {
                    "InventoryAllocations": "quantity",
                },
            },
            version: 3
        };
    }

    /**
     * Prepare table columns
     */
    protected async prepareOutboundTable(): Promise<any> {
        this.outboundTable = {
            table_id: "newOrderOutboundItemsList",
            columns: this.columns,
            actions: [
                {
                    name: "delete",
                    title: "Delete",
                    click: (row: any): void => {
                        this.removeItem(row).then(() => {
                            this.outboundTableRef.reload();
                            if (this.serviceLevel.order_type.slug === "transfer") {
                                this.inboundTableRef.reload();
                            }
                        });
                    }
                },
            ],
            api: this.getUrl("outbound")
        };
    }

    protected async prepareInboundTable(): Promise<any> {
        this.inboundTable = {
            table_id: "newOrderInboundItemsList",
            columns: this.columns,
            actions: [
                {
                    name: "delete",
                    title: "Delete",
                    click: (row: any): void => {
                        this.removeItem(row).then(() => {
                            this.inboundTableRef.reload();
                            if (this.serviceLevel.order_type.slug === "transfer") {
                                this.outboundTableRef.reload();
                            }
                        });
                    }
                }
            ],
            api: this.getUrl("inbound")
        };
    }

    /**
     * Get partmasters to add
     */
    protected async getPartMasters(use_address: boolean = true): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.partMasterService.getPartMasters({
            address_id: this.details && use_address ? this.details.address_id : null,
        });
        this.spinnerService.hide();

        if (data && data.length) {
            this.partmasters = data.map((part: any): ISelectOption => {
                return {
                    name: part.item + " - " + part.description + (!part.is_active ? " (Inactive)" : ""),
                    value: part.id,
                    disabled: !part.is_active
                };
            });

            this.changeDetectorRef.markForCheck();

        } else if (use_address) {
            this.getPartMasters(false);
        }
    }

    protected async checkAllOutboundIsAllocated(): Promise<any> {
        if (this._skipAllocations) {
            return;
        }

        const {data}: Api.IResponse = await this.api3Service.get(`${this.state.section}/order-items`, {
            order_id: this.data.order_id,
            type: "outbound",
            not_allocated: true
        });

        if (this.outboundTableRef && this._skipAllocations !== !data.length) {
            this.outboundTableRef.reload();
        }

        this._skipAllocations = !data.length;

        this.setResults();
        this.changeDetectorRef.markForCheck();
    }

    protected async getAddress(): Promise<any> {
        if (this.data.address) {
            this.address = this.data.address;
        } else {
            this.spinnerService.show();
            const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
                ["address", "" + this.details.address_id]);
            this.spinnerService.hide();
            if (data) {
                this.address = data;
            }
        }
    }

    protected getDefaultHubId(): number {
        if (this.address.customer && this.address.customer.inventory_conversion_id) {
            return this.address.customer.inventory_conversion_id;
        }
        return null;
    }

    /**
     * Remove data (item)
     * @param data
     * @returns {Promise}
     */
    protected async removeItem(data: any): Promise<any> {
        let params = {
            use_sync: this.serviceLevel.properties?.sync_items_lines ?? false
        };
        if (this.serviceLevel.order_type.slug === "transfer"
            && !this.serviceLevel.properties?.sync_items_lines) {
                const confirmSync = await this.confirmRef.confirm("Do you want sync lines?");
                if (confirmSync) {
                    params.use_sync = true;
                }
            }
        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.api3Service.delete(
            `${this.state.section}/order-items/${data.id}`, params);

        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
        }
    }

    protected async showAllocationsForItem(item): Promise<any> {
        await this.modalService.open(OrderItemsInfoComponent, {
            data: item,
            state: this.state,
            order: this.details,
            partner: PartnerService.partner,
            modalWidth: "80%",
            modalHeight: "75%",
        });

        if (this.outboundTableRef) {
            this.outboundTableRef.reload();
        }

        if (this.inboundTableRef) {
            this.inboundTableRef.reload();
        }
        this._skipAllocations = false;
        this.checkAllOutboundIsAllocated();
    }

    /**
     * Set results
     * @returns {void}
     */
    protected setResults(): void {

        this.changeDetectorRef.markForCheck();

        if (this._skipAllocations) {
            this.result.next({
                action: "result",
                value: true,
            });
        } else {
            this.result.next({
                action: "result",
                value: null,
            });
        }
    }


    protected setup(data: Wizard.IData): void {
        this.state = data.state;
        this.details = data.details;
        this.serviceLevel = data.serviceLevel;

        if (!this.details || !this.serviceLevel) {
            return;
        }

        if (!this.serviceLevel.properties.stock_mode) {
            this.serviceLevel.properties.stock_mode = "free";
        }
        this.stockMode = this.serviceLevel.properties.stock_mode.toLowerCase();

        this.changeDetectorRef.markForCheck();

        if (this.details && this.details.address_id) {
            this.getAddress();
        }

        this.getPartMasters();

        this.allocationTimeout = setInterval(() => {
            this.checkAllOutboundIsAllocated();
        }, 5000);
    }

    public async allocateItem(item: any): Promise<any> {
        const {code, message}: Api.IResponse = await this.api3Service.post(
            `${this.state.section}/order-items/${item.id}/allocate`, {
                pallet: item.stock?.pallet,
                box: item.stock?.box,
                serial: item.stock?.serial,
            });

        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");

            if (this.outboundTableRef) {
                this.outboundTableRef.reload();
            }

            if (this.inboundTableRef) {
                this.inboundTableRef.reload();
            }
        }
    }

    public async allocateInventory(): Promise<any> {
        const {code, message}: Api.IResponse = await this.api3Service.post(
            `${this.state.section}/orders/${this.data.order_id}/allocate`, {});

        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            if (this.outboundTableRef) {
                this.outboundTableRef.reload();
            }
            if (this.inboundTableRef) {
                this.inboundTableRef.reload();
            }
        }
    }


    /**
     * Allow to skip allocations
     */
    public async skipAllocations(): Promise<any> {
        if (await this.confirmRef.confirm("Are you sure want to proceed without allocations?")) {
            this._skipAllocations = true;
            this.setResults();
        }
    }


    public ngOnInit(): void {
        this._skipAllocations = false;
        this.setup(this.data);

        this.connectToSockets();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.data) {
            const pv: any = changes.data.previousValue;
            const cv: any = changes.data.currentValue;

            if (
                (!pv.details && cv.details)
                || (pv.details && !cv.details)
                || (JSON.stringify(pv.details) !== JSON.stringify(cv.details))
            ) {
                this.setup(cv);
            }
        }
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();

        if (this.allocationTimeout) {
            clearTimeout(this.allocationTimeout);
        }

        this.pusherService.echo.private("broadcast-partner-" + AppStateService.getState().section_slug)
            .stopListening(".App\\Events\\AllocationStartEvent")
            .stopListening(".App\\Events\\AllocationFinishEvent");

    }

}
