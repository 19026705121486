<common-confirm></common-confirm>
<div class="flex-row" *ngIf="inboundsTab.isOpen">
    <mat-form-field>
        <mat-label>View type</mat-label>
        <mat-select [formControl]="statsViewType">
            <mat-option value="quantity">By quantity</mat-option>
            <mat-option value="value">By value</mat-option>
        </mat-select>
    </mat-form-field>
</div>

<div class="top-stats" *ngIf="state.section.includes('partner')">

    <mat-tab-group dynamicHeight (selectedIndexChange)="$event">
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon color="primary">person</mat-icon>
                Personal
            </ng-template>
            <ng-template matTabContent>
                <section-dashboard-template-default-personal-tab [settings]="settings" [state]="state">
                </section-dashboard-template-default-personal-tab>
            </ng-template>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon color="primary">low_priority</mat-icon>
                <span (click)="amplitudeClick('Fulfillment')">Fulfillment</span>
            </ng-template>
            <ng-template matTabContent>
                <section-dashboard-template-default-fulfillment-tab [state]="state">
                </section-dashboard-template-default-fulfillment-tab>
            </ng-template>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon color="primary">local_shipping</mat-icon>
                <span (click)="amplitudeClick('Shipments')">Shipments</span>
            </ng-template>

            <ng-template matTabContent>
                <section-dashboard-template-default-shipments-tab [state]="state">
                </section-dashboard-template-default-shipments-tab>
            </ng-template>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon color="primary">phonelink_erase</mat-icon>
                <span (click)="amplitudeClick('Defective returns')">Defective returns</span>
            </ng-template>

            <ng-template matTabContent>
                <div class="flex-row" style="justify-content: space-evenly">
                    <div style="justify-content: space-evenly; display: flex; flex-direction: column">
                        <mat-card class="shipment-by-status" style="min-width:350px; margin-right: 0;"
                                  *ngIf="inboundsTab.orders.open && inboundsTab.orders.open.pie">
                            <h3>Open defective return orders</h3>
                            <div style="font-size: 30px"><b>{{ inboundsTab.orders.open.count || 0 }}</b>
                            </div>
                            <br/>
                            <ng-template
                                    [ngIf]="inboundsTab.orders.open.pie && inboundsTab.orders.open.pie.length > 1">
                                <mat-divider [inset]="true"></mat-divider>
                                <br/>
                                <div>
                                    <common-donut-chart
                                            [data]="inboundsTab.orders.open.pie"></common-donut-chart>
                                </div>
                            </ng-template>
                        </mat-card>
                        <mat-card class="shipment-by-status" style="min-width:350px; margin-right: 0;"
                                  *ngIf="inboundsTab.orders.closed && inboundsTab.orders.closed.data">
                            <h3>Last closed orders</h3>
                            <mat-divider [inset]="true"></mat-divider>
                            <br/>
                            <table class="normal table">
                                <tr style="font-weight: bold">
                                    <td>Ref</td>
                                    <td>Created on</td>
                                    <td>Inbound date</td>
                                    <td>Days taken</td>
                                    <td>Closed on</td>
                                </tr>
                                <tr *ngFor="let order of inboundsTab.orders.closed.data">
                                    <td>{{ order.ref }}</td>
                                    <td style="cursor: pointer" (click)="goToOrder(order.ref)">{{ order.ref }}</td>
                                    <td>{{ order.created_at | date }}</td>
                                    <td>{{ order.inbound_transaction_created_at | date }}</td>
                                    <td>{{ helpersService.datesDiff(order.inbound_transaction_created_at, order.created_at, 'days') }}</td>
                                    <td>{{ order.closed_on | date }}</td>
                                </tr>
                            </table>
                        </mat-card>
                    </div>
                    <mat-card class="shipment-by-status" style="min-width:350px; margin-right: 0;"
                              *ngIf="inboundsTab.order_items.count">
                        <h3>Open defective return order items</h3>
                        <div style="font-size: 30px; margin-bottom: 16px">
                            <b>{{ inboundsTab.order_items.count || 0 }}</b>
                        </div>
                        <div>
                            <div>Total price</div>
                            <div>{{ inboundsTab.order_items.total }} {{ inboundsTab.order_items.currency }}
                            </div>
                        </div>
                        <br/>
                        <mat-divider [inset]="true"></mat-divider>
                        <br/>
                        <div *ngIf="this.inboundsTab.order_items.byDays">
                            <common-line-chart [data]="this.inboundsTab.order_items.byDays"
                                               title="TOTAL PRICE"
                                               [vAxis]="{viewWindowMode: 'explicit', viewWindow:{min: 0}}"></common-line-chart>
                        </div>
                        <br/>
                        <mat-divider [inset]="true"></mat-divider>
                        <br/>
                        <h4>TOP 10 DEFECTIVE ITEMS</h4>
                        <div *ngIf="inboundsTab.order_items.topCount.pie">
                            <common-donut-chart
                                    [data]="statsViewType.value === 'quantity' ? inboundsTab.order_items.topCount.pie : inboundsTab.order_items.topValue.pie"></common-donut-chart>
                        </div>
                        <ng-template
                                [ngIf]="inboundsTab.orders.open.topCustomers.pie && inboundsTab.orders.open.topCustomers.pie.length > 1">
                            <br/>
                            <mat-divider [inset]="true"></mat-divider>
                            <h3>Top customers</h3>
                            <br/>
                            <ng-template [ngIf]="inboundsTab.orders.open.topCustomers.pie">
                                <mat-divider [inset]="true"></mat-divider>
                                <br/>
                                <div>
                                    <common-donut-chart
                                            [data]="inboundsTab.orders.open.topCustomers.pie"></common-donut-chart>
                                </div>
                            </ng-template>
                        </ng-template>
                    </mat-card>
                </div>
            </ng-template>
        </mat-tab>

        <mat-tab *userAllowed="'browse_milestones'">
            <ng-template mat-tab-label>
                <mat-icon color="primary">flag</mat-icon>
                <span (click)="amplitudeClick('Milestones')">Milestones</span>
            </ng-template>

            <ng-template matTabContent>
                <div class="flex-row" style="justify-content: space-evenly">
                    <mat-card style="width:100%;">
                        <div>
                            <common-table2 #milestonesTableRef
                                           *ngIf="milestonesTab.listTable"
                                           [settings]="milestonesTab.listTable"
                                           [search]="false">
                            </common-table2>
                        </div>
                    </mat-card>
                </div>
            </ng-template>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon color="primary">location_on</mat-icon>
                <span (click)="amplitudeClick('Tracking map')">Tracking map</span>
            </ng-template>
            <ng-template matTabContent>
                <section-tracking-map [embeddedMap]="true" [state]="state"></section-tracking-map>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>

<br>
<mat-divider></mat-divider>
<br>

<div class="orders-and-remarks">
    <div class="left">
        <div class="orders">
            <h3 class="graph-title">Awaiting confirmation orders</h3>
            <div *ngIf="ordersNotConfirmed">
                <mat-card *ngFor="let order of ordersNotConfirmed.data">
                    <div>
                        <div>
                            <b>Order ref: </b> {{ order.ref }}
                        </div>
                        <div>
                            <b>Service level:</b> {{ order.service_level.name }}
                        </div>
                    </div>

                    <div *ngIf="order.orders_address && order.orders_address.length > 0">
                        <b>Client:</b>
                        <div class="flag" *ngIf="order.orders_address[0].country_iso_2">
                            <img [src]="'assets/images/flags/'+order.orders_address[0]
                                .country_iso_2.toLowerCase().trim()+'.svg'">
                        </div>
                        <div>
                            <ng-template *ngIf="order.orders_address[0] && order.orders_address[0].customer">
                                {{ order.orders_address[0].customer.name }}
                            </ng-template>
                        </div>
                    </div>

                    <div *ngIf="order.orders_address && order.orders_address.length === 0"></div>

                    <div class="clear">
                        <b>Created at:</b> {{ order.created_at }}
                    </div>
                    <div class="actions">
                        <button mat-mini-fab (click)="goToOrder(order)">
                            <mat-icon>arrow_forward</mat-icon>
                        </button>
                        <button *ngIf="userService.validatePermissions(['add_orders'])"
                                mat-mini-fab class="green" (click)="confirmOrder(order.ref)">
                            <mat-icon>donee</mat-icon>
                        </button>
                        <button mat-mini-fab matBadge="{{order.remarks_count}}"
                                (click)="showOrderSidebar(order.id, order.ref)">
                            <mat-icon>speaker_notes</mat-icon>
                        </button>
                    </div>
                </mat-card>

                <common-pagination [data]="ordersNotConfirmed"
                                   [hidePerPage]="true"
                                   pid="Z40XP5cfy2"
                                   (goToPage)="getOrdersNotConfirmed($event.page,$event.per_page)"></common-pagination>
            </div>

            <mat-card *ngIf="!ordersNotConfirmed">
                <common-simple-spinner></common-simple-spinner>
            </mat-card>
        </div>
        <div class="activities" *ngIf="settings.lastActivitiesCount">
            <h3 class="graph-title">Latest activities</h3>
            <mat-list *ngIf="activities">
                <ng-template ngFor let-activity [ngForOf]="activities">
                    <section-activity-view [activity]="activity"
                                           [showMarkRead]="true"
                                           [state]="state"
                                           (updates)="lastActivities(settings.lastActivitiesCount)">
                    </section-activity-view>
                    <mat-divider class="relative"></mat-divider>
                </ng-template>
            </mat-list>
            <mat-card *ngIf="!activities">
                <common-simple-spinner></common-simple-spinner>
            </mat-card>
        </div>
    </div>

    <div class="remarks">
        <h3 class="graph-title">Latest remarks</h3>
        <div *ngIf="remarks">

            <mat-card class="remark" *ngFor="let data of remarks.data"
                      (click)="navigateTo(['/partner',data.partner.slug,'orders','view','id',data.order.id])">
                <div class="owner" *ngIf="data.owner">
                    <div class="avatar"
                         [ngStyle]="{'background-image': 'url(' + (data.owner.avatar || 'assets/images/default-avatar.png') + ')'}">
                    </div>
                    <div class="name">
                        {{ data.owner.name }}
                    </div>
                </div>
                <div class="owner" *ngIf="!data.owner">
                    <div class="avatar"
                         style="background-image: url('../../../../../../../../assets/images/default-avatar.png')"></div>
                    <div class="name">{{ data.sender_name }}</div>
                </div>
                <div class="text">
                    <div class="order">Order: {{ data.order ? data.order.ref : "N/A" }}</div>
                    <div class="subject"><b>{{ data.subject }}</b></div>
                    <div [innerHTML]="data.message"></div>
                </div>
                <div class="date">{{ data.updated_at | date: 'dd.MM.yyyy HH:mm:ss' }}</div>
            </mat-card>

            <common-pagination [data]="remarks"
                               [hidePerPage]="true"
                               pid="g0u38YS1xX"
                               (goToPage)="getRemarks($event.page,$event.per_page)"></common-pagination>
        </div>
        <mat-card *ngIf="!remarks">
            <common-simple-spinner></common-simple-spinner>
        </mat-card>
    </div>
</div>

<br>
<mat-divider></mat-divider>
<br>

<div class="requests" *ngIf="favoriteRequestsListTable">
    <h3 class="graph-title">Favorite requests</h3>
    <common-table2 #favoriteRequests [settings]="favoriteRequestsListTable">
        <div row1>
            <mat-form-field>
                <mat-label>Show only open</mat-label>
                <mat-select [formControl]="showOpenRequests">
                    <mat-option [value]="true">Open</mat-option>
                    <mat-option [value]="false">All</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </common-table2>
</div>

<ng-container *ngIf="settings.showOpenFollowups">
    <br>
    <mat-divider></mat-divider>
    <br>

    <div class="requests">
        <h3 class="graph-title">My followups</h3>
        <section-followups-list [only_my]="true" [state]="state"
                                [showFilters]="false"></section-followups-list>
    </div>
</ng-container>


<br>
<mat-divider></mat-divider>
<br>

<div class="requests" *ngIf="exceptionTracksTable">
    <h3 class="graph-title">Tracks with exception</h3>
    <common-table2 #exceptionTracks [settings]="exceptionTracksTable"></common-table2>
</div>

<section-remarks-sidebar #sidebar></section-remarks-sidebar>
