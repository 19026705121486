<form [formGroup]="formGroup">

    <div class="group">

        <div class="full">
            <button type="button" class="pull-right" mat-raised-button (click)="setFromPrevOrders()">
                Select from previous orders
            </button>
            <h3 class="mat-h3">{{ shipmentName | ucfirst }} details</h3>
        </div>


        <mat-form-field class="full">
            <mat-label>Customer</mat-label>
            <input matInput #customer_id
                   [sectionControl]="{components: components.customer,
                   params:{address: {id: formGroup.get('address_id').value},state: state}}"
                   formControlName="customer_id"
                   (update)="customerUpdate($event)"
                   readonly
                   required>
        </mat-form-field>

        <mat-form-field class="full">
            <mat-label>{{ shipmentName | ucfirst }} address</mat-label>
            <input matInput #address_id
                   [sectionControl]="{components: components.address,
                   params: {type: 'ship to', customer: {id: formGroup.get('customer_id').value}, state: state}}"
                   formControlName="address_id"
                   (update)="addressUpdate($event)"
                   readonly
                   required>
        </mat-form-field>

        <mat-form-field class="full">
            <mat-label>{{ shipmentName | ucfirst }} contact</mat-label>
            <input matInput #contact_address_id
                   [sectionControl]="{components: components.contact,
                   params: {address: formGroup.get('address_id').value, state: state, canEdit: true}}"
                   formControlName="contact_address_id"
                   readonly
                   required>
        </mat-form-field>

        <mat-checkbox *ngIf="hasBillto" [formControl]="billToggle" class="full">
            Add Bill to (Select only in case of difference with shipping details)
        </mat-checkbox>

        <h3 class="mat-h3 padding-top-10" [class.hidden]="!visibility.address_billto_id">Bill to details</h3>

        <mat-form-field [class.hidden]="!visibility.address_billto_id" class="full">
            <mat-label>Bill to address</mat-label>
            <input matInput #address_bill_id
                   [sectionControl]="{components: components.address,
                   params: {type: 'bill to',  customer: {id: formGroup.get('customer_id').value}, state: state}}"
                   formControlName="address_billto_id"
                   readonly>
        </mat-form-field>

        <mat-form-field [class.hidden]="!visibility.contact_address_billto_id" class="full">
            <mat-label>Bill to contact</mat-label>
            <input matInput #contact_address_bill_id
                   [sectionControl]="{components: components.contact,
                   params: {address: formGroup.get('address_billto_id').value, state: state, canEdit: true}}"
                   formControlName="contact_address_billto_id"
                   readonly>
        </mat-form-field>

    </div>

</form>
