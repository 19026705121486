<common-confirm></common-confirm>
<h1>{{edit ? 'Edit' : 'Create'}} Task</h1>
<div class="margin-bottom-20">
        <mat-card>
            <div style="width: 100%">
                <form [formGroup]="taskEdit">
                    <div class="row flex space-between">
                        <mat-form-field class="full">
                            <mat-label>Task</mat-label>
                            <textarea formControlName="text" matInput
                                      cdkTextareaAutosize
                                      cdkAutosizeMinRows="1"
                                      cdkAutosizeMaxRows="5"
                                      required></textarea>
                        </mat-form-field>

                        <mat-form-field class="full">
                            <mat-label>Comment</mat-label>
                            <textarea formControlName="comment" matInput cdkTextareaAutosize
                                      cdkAutosizeMinRows="1"
                                      cdkAutosizeMaxRows="5"></textarea>
                        </mat-form-field>

                        <div class="half">
                            <common-form-date-time
                                    label="Due date"
                                    [value]="taskEdit.value.due_date"
                                    [min_date]="now"
                                    (valueChangeMoment)="taskEdit.get('due_date').setValue($event)"
                            ></common-form-date-time>
                        </div>

                        <div class="half">
                            <common-form-select
                                    label="Timezone"
                                    [value]="timezone"
                                    [options]="timeZones"
                                    [search]="true"
                                    [required]="true"
                                    (valueChange)="taskEdit.get('due_date_tz').setValue($event)">
                            </common-form-select>
                        </div>

                        <mat-form-field class="full">
                            <mat-label>Event for close</mat-label>
                            <mat-select formControlName="event_close">
                                <mat-option [value]="null">None</mat-option>
                                <mat-option *ngFor="let option of closeTaskEvents" [value]="option.value">
                                    {{option.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <ng-container formGroupName="params" *ngIf="getTaskParamsForEvent() as params">
                            <div *ngFor="let parameter of params" class="full">
                                <mat-form-field class="full" *ngIf="parameter.input_type === 'number'">
                                    <mat-label>{{parameter.label}}</mat-label>
                                    <input matInput type="number" [formControlName]="parameter.name" [required]="parameter.required">
                                </mat-form-field>
                        
                                <mat-checkbox class="full" *ngIf="parameter.input_type === 'checkbox'"
                                    [formControlName]="parameter.name"
                                    [required]="parameter.required">
                                    {{parameter.label}}
                                </mat-checkbox>
                        
                                <common-form-select class="full" *ngIf="parameter.input_type === 'dropdown'"
                                    [label]="parameter.label"
                                    [options]="parameter.options"
                                    [value]="taskEdit.get('params').get(parameter.name).value" [required]="parameter.required"
                                    (valueChange)="taskEdit.get('params').get(parameter.name).setValue($event)">
                                </common-form-select>
                            </div>
                        </ng-container>

                        <div class="full">
                            <mat-checkbox formControlName="send_email">
                                Send email on due date arrival
                            </mat-checkbox>
                        </div>

                        <mat-form-field class="full">
                            <mat-label>Deadline in minutes</mat-label>
                            <mat-select formControlName="send_email_deadline">
                                <mat-option *ngFor="let option of taskDeadlineOptions" [value]="option">
                                    {{option}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="actions">
                        <button type="button" mat-raised-button color="accent"
                                [disabled]="!taskEdit.valid"
                                (click)="submit()">
                            Save
                        </button>
                        &nbsp;
                        &nbsp;
                        <button type="button" mat-raised-button
                                (click)="close()">
                            Close
                        </button>
                    </div>
                </form>
            </div>
        </mat-card>
</div>