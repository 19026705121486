import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation
} from "@angular/core";
import {FormControl} from "@angular/forms";
import {ReplaySubject} from "rxjs";
import {Api, ApiService} from "../../../services/api.service";
import {Warehouse} from "../../../interfaces/warehouse.interface";
import {Modal, ModalService} from "../../../../section/services/modal.service";
import {SpinnerService} from "../../../services/spinner.service";
import {debounceTime, distinctUntilChanged, takeUntil} from "rxjs/operators";
import {HubsMapModalComponent} from "../../../../section/components/partner/components/hubs";

interface IHubGroup {
    name: string;
    checked: boolean;
}

@Component({
    selector: "common-form-hub-select",
    template: `
        <div *ngIf="disabled && hubs" class="margin-bottom-10">
            <span class="label">
                {{label}}
            </span>
            <div>
                {{getHubNameById(hub.value)}}
            </div>
        </div>

        <mat-form-field *ngIf="!disabled && hubs" style="width: 100%">
            <mat-label>{{label}}</mat-label>
            <mat-select [formControl]="hub"
                        [multiple]="multiple"
                        [required]="required"
                        (closed)="closed()">

                <mat-select-trigger>
                    {{getHubNameById(hub.value)}}
                </mat-select-trigger>

                <mat-option>
                    <ngx-mat-select-search [formControl]="hubSearch"
                                           placeholderLabel="Search"
                                           [showToggleAllCheckbox]="selectAll"
                                           (toggleAll)="allSelect()"
                                           noEntriesFoundLabel="no hubs found"></ngx-mat-select-search>
                </mat-option>

                <mat-option *ngIf="!required && !multiple" [value]="null">None</mat-option>
                <mat-option *ngIf="!hubs.length" [value]="null">
                    {{empty_list_message}}
                </mat-option>

                <ng-template [ngIf]="multiple" [ngIfElse]="single">
                    <div *ngFor="let row of hubsFiltered | async">
                    <mat-checkbox class="hub-group"
                        [(checked)]="row.group.checked"
                        (change)="groupSelectAll(row, $event.checked)">
                        {{row.group.name}}
                    </mat-checkbox>
                    <mat-option *ngFor="let option of row.hubs" [value]="option.id"
                                class="{{option.gb}} mat-option group-item">
                        {{option.customers_sub_inventory}}
                        ({{option.gb}})
                        <ng-container *ngIf="option.distance">
                            {{option.distance}}km
                        </ng-container>
                    </mat-option>
                    </div>
                </ng-template>

                <ng-template #single>
                    <mat-optgroup *ngFor="let row of hubsFiltered | async" [label]="row.group.name">
                        <mat-option *ngFor="let option of row.hubs" [value]="option.id"
                            class="{{option.gb}} mat-option group-item">
                            {{option.customers_sub_inventory}}
                            ({{option.gb}})
                            <ng-container *ngIf="option.distance">
                                {{option.distance}}km
                            </ng-container>
                        </mat-option>
                    </mat-optgroup>
                </ng-template>

            </mat-select>
            <mat-icon matSuffix (click)="openMap();$event.stopPropagation()">
                place
            </mat-icon>

            <common-form-error [control]="hub" controlName="hub"></common-form-error>
        </mat-form-field>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    styleUrls: ["hub-select.component.scss"],
})

export class HubSelectComponent implements OnInit, OnDestroy, OnChanges {

    protected destroy$: EventEmitter<boolean> = new EventEmitter(false);

    public hubs: { group: IHubGroup, hubs: Warehouse.IHub[] }[];

    public hub: FormControl = new FormControl(null);

    public hubSearch: FormControl = new FormControl(null);

    public hubsFiltered: ReplaySubject<any> = new ReplaySubject(1);

    @Input()
    public options: { [key: string]: Warehouse.IHub[] } = null;

    @Input()
    public options_grouped: { group: string, hubs: Warehouse.IHub[] }[] = null;

    @Input()
    public value: number = null;

    @Input()
    public url: string = null;

    @Input()
    public address_id: number = null;

    @Input()
    public line_type: string = null;

    @Input()
    public label: string = "Hub";

    @Input()
    public service_level_id: number = null;

    @Input()
    public part_master_id: number = null;

    @Input()
    public part_master_in_stock: boolean = false;

    @Input()
    public required: boolean = false;

    @Input()
    public disabled: boolean = false;

    @Input()
    public multiple: boolean = false;

    @Input()
    public selectAll: boolean = false;

    @Input()
    public hideSingles: boolean = false;

    @Input()
    public empty_list_message: string = "Hubs not found";

    @Output()
    public onChange: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public onClose: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public onValueName: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public onGetHubs: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public onGroupSelected: EventEmitter<{ group: string, hubs: Warehouse.IHub[] }[]> =
        new EventEmitter<{ group: string, hubs: Warehouse.IHub[] }[]>();


    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private modalService: ModalService,
        private spinnerService: SpinnerService
    ) {

    }

    /**
     * Map hubs
     * @param hubs
     */
    private mapHubs(hubs: { [key: string]: Warehouse.IHub[] }): { group: IHubGroup, hubs: Warehouse.IHub[] }[] {
        const mappedHubs: { group: IHubGroup, hubs: Warehouse.IHub[] }[] = [];
        for (const group_name of Object.keys(hubs)) {
            if (this.hideSingles && hubs[group_name].length < 2) {
                continue;
            }
            mappedHubs.push({
                group: {
                    name: group_name,
                    checked: false
                },
                hubs: hubs[group_name]
            });
        }
        return mappedHubs;
    }

    private filterHubs(hubsList: { group: IHubGroup, hubs: Warehouse.IHub[] }[], value: string): any {
        value = value.toLowerCase();
        const filtered: { group: IHubGroup, hubs: Warehouse.IHub[] }[] = [];
        for (const row of hubsList) {
            if (row.group && row.group.name.toLowerCase().includes(value)) {
                filtered.push(row);
            } else {
                const hubs: Warehouse.IHub[] = [];
                for (const hub of row.hubs) {
                    if (hub.customers_sub_inventory && hub.customers_sub_inventory.toLowerCase().includes(value)) {
                        hubs.push(hub);
                    }
                }
                if (hubs.length) {
                    filtered.push({group: row.group, hubs});
                }
            }
        }
        return filtered;
    }

    private setSelectedGroup(id: number | number[]): void {
        if (!Array.isArray(id)) {
            id = [id];
        }

        const ids: number[] = id;

        const selected_groups: { group: string, hubs: Warehouse.IHub[] }[] = [];

        for (const row of this.hubs) {
            const selectedHubs: Warehouse.IHub[] = row.hubs.filter((sHub: Warehouse.IHub): boolean => {
                return ids.includes(sHub.id);
            });

            if (selectedHubs.length) {
                selected_groups.push({
                    group: row.group.name, hubs: row.hubs.filter((sHub: Warehouse.IHub): boolean => {
                        return !ids.includes(sHub.id);
                    })
                });
            }
        }

        this.onGroupSelected.emit(selected_groups);
    }

    public groupSelectAll(row: any, isSelected: boolean): void {
        const oldValues = this.hub.value;
        const groupHubsIds = row.hubs.map(item => item.id);

        if (isSelected) {
            const newValues = [...new Set([...oldValues, ...groupHubsIds])];
            this.hub.setValue(newValues);
        } else {
            const newValues = oldValues.filter(item1 => !groupHubsIds.includes(item1));
            this.hub.setValue(newValues);
        }
        row.group.checked = isSelected;
        this.changeDetectorRef.markForCheck();
    }

    public checkSelectGroups(id: number | number[]): void {
        if (!Array.isArray(id)) {
            id = [id];
        }

        const ids: number[] = id;

        const selected_groups: { group: IHubGroup, hubs: Warehouse.IHub[] }[] = [];

        for (const row of this.hubs) {
            const selectedHubs: Warehouse.IHub[] = row.hubs.filter((sHub: Warehouse.IHub): boolean => {
                return ids.includes(sHub.id);
            });

            if (selectedHubs.length) {
                selected_groups.push({
                    group: row.group, hubs: row.hubs.filter((sHub: Warehouse.IHub): boolean => {
                        return !ids.includes(sHub.id);
                    })
                });
            }
            row.group.checked = selectedHubs.length > 0;
        }
        this.changeDetectorRef.markForCheck();
    }

    public allSelect(): void {
        const select: number[] = [];

        const search: string = this.hubSearch.value ? this.hubSearch.value : "";

        const filtered: { group: IHubGroup, hubs: Warehouse.IHub[] }[] = this.filterHubs(this.hubs, search);
        for (const list of filtered) {
            for (const hub of list.hubs) {
                select.push(hub.id);
            }
        }

        if (Array.isArray(this.hub.value) && select.length === this.hub.value.length) {
            this.hub.setValue([]);
        } else {
            this.hub.setValue(select);
        }

        this.changeDetectorRef.markForCheck();
    }

    /**
     * Get hub list (as select options)
     * @returns {Promise<any>}
     */
    public async getHubs(): Promise<any> {
        if (this.options_grouped) {
            this.hubs = this.options_grouped.map(opt => {
                return {
                    group: {
                        name: opt.group,
                        checked: false
                    },
                    hubs: opt.hubs
                };
            });
        } else if (this.options) {
            this.hubs = this.mapHubs(this.options);
        } else {
            this.spinnerService.show();

            const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["hub", this.url], null, {
                address_id: this.address_id,
                data_structure: "grouped",
                line_type: this.line_type,
                service_level_id: this.service_level_id,
                part_master_id: this.part_master_id,
                part_master_in_stock: this.part_master_in_stock

            });
            this.spinnerService.hide();
            if (data) {
                this.hubs = this.mapHubs(data);

            }
        }

        if (this.hubs) {
            this.hubsFiltered.next(this.hubs);
            this.onGetHubs.emit(this.hubs);

            if (this.hub.value) {
                this.setSelectedGroup(this.hub.value);
            }
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Get Hub name by id
     * @param id
     */
    public getHubNameById(id: number | number[]): string {
        if (!Array.isArray(id)) {
            id = [id];
        }
        const ids: number[] = id;
        const names: string[] = [];

        for (const row of this.hubs) {
            for (const hub of row.hubs) {
                if (ids.includes(hub.id)) {
                    names.push((hub.customers_inventory_name ? hub.customers_inventory_name + " " : "")
                        + (hub.customers_sub_inventory || "") + " (" + hub.gb + ") "
                        + (hub.distance ? " - " + hub.distance + "km" : ""));
                }
            }
        }
        return names.join(", ");
    }

    /**
     * Show hubs on google map
     */
    public async openMap(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(HubsMapModalComponent, {
            hubs: this.hubs,
            modalHeight: 500,
            modalWidth: 1200,
            part_master_id: this.part_master_id
        });

        if (response && response.value) {
            this.hub.setValue(response.value.id);
            this.changeDetectorRef.markForCheck();
        }
    }

    public closed(): void {
        setTimeout((): void => {
            this.onClose.emit(this.hub.value);
        }, 10);
    }

    public ngOnInit(): void {
        this.getHubs();

        this.hub.setValue(this.value);

        this.changeDetectorRef.markForCheck();

        this.hubSearch.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: string): void => {
                if (value) {
                    this.hubsFiltered.next(this.filterHubs(this.hubs, value));
                } else {
                    this.hubsFiltered.next(this.hubs);
                }
                this.changeDetectorRef.markForCheck();
            });

        this.hub.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
            .subscribe((value: number): void => {
                this.onChange.emit(value);
                this.setSelectedGroup(value);
                this.checkSelectGroups(value);
                this.onValueName.emit(this.getHubNameById(value));
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.value && !changes.value.firstChange) {
            this.hub.setValue(changes.value.currentValue);
            this.changeDetectorRef.markForCheck();
        }

        if (changes.options_grouped && !changes.options_grouped.firstChange) {
            this.hubs = changes.options_grouped.currentValue;
            if (this.hubs) {
                this.hubsFiltered.next(this.hubs);
                this.onGetHubs.emit(this.hubs);
                this.hub.setValue(this.value);
                this.changeDetectorRef.markForCheck();
            }
        }

        if (
            (changes.address_id && !changes.address_id.firstChange)
            || (changes.line_type && !changes.line_type.firstChange)
            || (changes.service_level_id && !changes.service_level_id.firstChange)
            || (changes.part_master_id && !changes.part_master_id.firstChange)
            || (changes.part_master_in_stock
                && changes.part_master_in_stock.currentValue !== changes.part_master_in_stock.previousValue)
        ) {
            this.getHubs();
        }
    }

}

