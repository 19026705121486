<common-confirm></common-confirm>

<h1 class="container-heading">Request pickup</h1>

<mat-card>
    <mat-card-content>

        <form *ngIf="formGroup" [formGroup]="formGroup" (submit)="submit()" autocomplete="off" novalidate>

            <div class="group flex">

                <mat-form-field class="full">
                    <mat-label>Track</mat-label>
                    <mat-select formControlName="courier_transaction_id">
                        <mat-option (click)="addTracking()">
                            <mat-icon>add_circle</mat-icon>
                            Add new tracking
                        </mat-option>
                        <mat-option *ngFor="let track of tracks" [value]="track.id">
                            {{ track.tracking_number }}
                        </mat-option>
                    </mat-select>
                    <span matPrefix class="selectIcon"><mat-icon>ballot</mat-icon>&nbsp;</span>
                </mat-form-field>

                <mat-form-field class="full">
                    <mat-label>Pickup reference</mat-label>
                    <textarea matInput formControlName="ref"></textarea>
                </mat-form-field>

                <div class="full">
                    <common-form-select
                            label="Type"
                            [required]="true"
                            [value]="formGroup.value.type"
                            [options]="requestTypes"
                            [disabled]="disableTypeField"
                            (onClose)="formGroup.get('type').setValue($event)"
                    ></common-form-select>
                </div>

                <mat-form-field class="full">
                    <mat-label>Order ref</mat-label>
                    <textarea matInput formControlName="remarks"></textarea>
                </mat-form-field>

                <div class="full" *ngIf="statuses && statuses.length">
                    <common-form-select
                            label="Status"
                            [options]="statuses"
                            [required]="true"
                            [value]="formGroup.value.pickup_request_status_id"
                            (onClose)="formGroup.get('pickup_request_status_id').setValue($event)"
                    ></common-form-select>
                </div>


                <div class="half">
                    <common-form-date
                            label="Date from"
                            [required]="true"
                            [value]="formGroup.value.from_date"
                            (valueChange)="formGroup.get('from_date').setValue($event)"
                    ></common-form-date>
                </div>

                <div class="half">
                    <common-form-date
                            label="Date to"
                            [min_date]="formGroup.value.from_date"
                            [required]="true"
                            [value]="formGroup.value.to_date"
                            (valueChange)="formGroup.get('to_date').setValue($event)"
                    ></common-form-date>
                </div>

                <mat-form-field class="full" *ngIf="modal.params.data && modal.params.shipment">
                    <mat-label>Tracking number</mat-label>
                    <input matInput
                           [sectionControl]="{components: components.transaction, params: {shipment: modal.params.shipment.id}}"
                           [value]="values.tracking_number"
                           formControlName="courier_transaction_id"

                           readonly>
                </mat-form-field>

                <mat-form-field class="full">
                    <mat-label>Shipment customer</mat-label>
                    <input matInput
                           [sectionControl]="{components: components.customer,
                           params:{address: {id: formGroup.get('address_id').value},state: state}}"
                           [value]="values.address"
                           (update)="customerUpdate($event)"
                           formControlName="customer_id"
                           readonly
                           required>
                </mat-form-field>

                <mat-form-field class="full">
                    <mat-label>Shipment address</mat-label>
                    <input matInput
                           [sectionControl]="{components: components.address,
                           params: {type: 'ship to', customer: formGroup.get('customer_id').value, state: state}}"
                           [value]="values.address"
                           (update)="addressUpdate($event)"
                           formControlName="address_id"
                           readonly
                           required>
                </mat-form-field>

                <mat-form-field class="full">
                    <mat-label>Shipment contact</mat-label>
                    <input matInput
                           [sectionControl]="{components: components.contact,
                            params: {address: formGroup.get('address_id').value, state: state}}"
                           [value]="values.contact"
                           formControlName="contact_id"
                           readonly
                           required>
                </mat-form-field>

                <mat-form-field class="full">
                    <mat-label>Notes</mat-label>
                    <textarea matInput formControlName="notes"></textarea>
                </mat-form-field>

            </div>

            <div class="group flex" *ngIf="distance">
                <div class="full">Distance to warehouse: <b>{{ distance }} km</b></div>
            </div>

            <div class="actions">
                <button mat-raised-button color="primary" type="submit" class="main"
                        [disabled]="!formGroup.valid">
                    Submit
                </button>
                <button mat-raised-button *ngIf="action === 'edit'"
                        type="button" color="warn" (click)="delete()">
                    Delete
                </button>

            </div>

        </form>

    </mat-card-content>
</mat-card>
