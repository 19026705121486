import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Field} from "../../../../../../../../common/components/field/field.component";
import {Api, ApiService} from "../../../../../../../../common/services/api.service";
import {AddressFormComponent, AddressListModalComponent} from "../../../../address";
import {ContactFormComponent, ContactListComponent} from "../../../../contact";
import {CustomerFormComponent, CustomerListComponent} from "../../../../customer";
import {Modal, ModalService} from "../../../../../../../services/modal.service";
import {UserService} from "../../../../../../../../common/services/user.service";
import {Base} from "../../../../../../../../common/interfaces/base.interfaces";
import {SpinnerService} from "../../../../../../../../common/services/spinner.service";

import {debounceTime, takeUntil} from "rxjs/operators";
import {ModalComponent} from "../../../../../../../../common/components/modal/modal.component";
import {PartnerService} from "../../../../../../../../common/services/partner.service";
import {OrderListModalComponent} from "../../../../../../common/components/order";

@Component({
    selector: "section-order-wizard-shipment-details",
    templateUrl: "shipment-details.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderWizardShipmentDetailsComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input()
    public state: Base.IState;


    @Input()
    public shipmentName: string = "Shipping";

    @Input()
    public hasBillto: boolean = true;

    @Input()
    public values: Modal.IResponse;

    @Output()
    public result: EventEmitter<any> = new EventEmitter();

    @Output()
    public updateCountry: EventEmitter<any> = new EventEmitter();

    @Output()
    public updateCustomer: EventEmitter<any> = new EventEmitter();


    @ViewChild("customer_id", {static: false})
    public customerIdRef: ElementRef;

    @ViewChild("address_id", {static: false})
    public addressIdRef: ElementRef;

    @ViewChild("contact_address_id", {static: false})
    public contactAddressIdRef: ElementRef;

    @ViewChild("address_bill_id", {static: false})
    public addressBillIdRef: ElementRef;

    @ViewChild("contact_address_bill_id", {static: false})
    public contactAddressBillIdRef: ElementRef;

    /**
     * Components for control (with selection in modal)
     * @type {any}
     */
    public components: any = {
        address: {
            form: AddressFormComponent,
            list: AddressListModalComponent
        },
        contact: {
            form: ContactFormComponent,
            list: ContactListComponent
        },
        customer: {
            form: CustomerFormComponent,
            list: CustomerListComponent
        }
    };

    /**
     * Detail form group
     * @type {FormGroup}
     */
    public formGroup: FormGroup = new FormGroup({
        address_id: new FormControl(null, [Validators.required]),
        address_billto_id: new FormControl(null),
        contact_address_id: new FormControl(null, [Validators.required]),
        contact_address_billto_id: new FormControl(null),
        customer_id: new FormControl(null, [Validators.required])
    });


    public billToggle: FormControl = new FormControl(false);

    /**
     * Custom / dynamic fields metadata
     * @type {Field.IMetadata[]}
     */
    public meta: Field.IMetadata[];

    /**
     * Visibility of unrequired controls
     * @type {any}
     */
    public visibility: any = {
        address_billto_id: false,
        contact_address_billto_id: false
    };

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private modalService: ModalService,
        private userService: UserService,
        private spinnerService: SpinnerService
    ) {
    }


    /**
     * Auto fill address if customer has only one address
     * @param {string} customer_id
     * @returns {Promise<any>}
     */
    private async fillCustomerAddress(customer_id: string): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["address", "type", "ship to"], {}, {customer_id});
        if (data && data.length === 1) {
            this.formGroup.get("address_id").setValue(data[0].id);
            this.addressIdRef.nativeElement.value =
                data[0].address + " " + data[0].city + " " + data[0].country;

            this.updateCountry.emit(data[0].country_iso_2);

            if (data[0].note) {
                this.showAddressNote(data[0].note);
            }

            this.fillCustomerContact("" + data[0].id);
        }
        this.spinnerService.hide();
    }

    /**
     * Auto fill contact if customer address has only one contact
     * @param {string} address_id
     * @returns {Promise<any>}
     */
    private async fillCustomerContact(address_id: string): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["contact"], {}, {address_id});
        if (data && data.length === 1) {
            this.formGroup.get("contact_address_id").setValue(data[0].id);
            this.contactAddressIdRef.nativeElement.value =
                data[0].first_name + " " + data[0].last_name + (data[0].email ? " - " + data[0].email : "");
        }
        this.spinnerService.hide();
    }

    private setValues(response: Modal.IResponse): void {
        if (response.value.customer && response.value.customer.controlValue) {
            this.formGroup.get("customer_id").setValue(response.value.customer.controlValue);
            setTimeout((): void => {
                this.customerIdRef.nativeElement.value = response.value.customer.viewValue;
            }, 10);
        }

        if (response.value.address && response.value.address.controlValue) {
            this.formGroup.get("address_id").setValue(response.value.address.controlValue);
            setTimeout((): void => {
                this.addressIdRef.nativeElement.value = response.value.address.viewValue;
            }, 10);
        }

        if (response.value.contact && response.value.contact.controlValue) {
            this.formGroup.get("contact_address_id").setValue(response.value.contact.controlValue);
            setTimeout((): void => {
                this.contactAddressIdRef.nativeElement.value = response.value.contact.viewValue;
            }, 10);
        }

        if (response.value.address_bill && response.value.address_bill.controlValue) {
            this.billToggle.setValue(true);
            this.formGroup.get("address_billto_id").setValue(response.value.address_bill.controlValue);
            setTimeout((): void => {
                this.addressBillIdRef.nativeElement.value = response.value.address_bill.viewValue;
            }, 10);
        }

        if (response.value.contact_bill && response.value.contact_bill.controlValue) {
            this.billToggle.setValue(true);
            this.formGroup.get("contact_address_billto_id").setValue(response.value.contact_bill.controlValue);
            setTimeout((): void => {
                this.contactAddressBillIdRef.nativeElement.value = response.value.contact_bill.viewValue;
            }, 10);
        }

        this.changeDetectorRef.markForCheck();
    }

    private async showAddressNote(message: string): Promise<any> {
        await this.modalService.open(ModalComponent, {
            template: message,
            title: "Address notes",
            titleStyles: {
                "text-align": "center",
                "color": "red"
            }
        });
    }

    /**
     * Toggle field
     * @param state
     * @param {string} name
     * @returns {void}
     */
    public toggle(state: boolean, name: string): void {
        const control: FormControl = this.formGroup.get(name) as FormControl;
        this.visibility[name] = state;
        if (state) {
            control.setValidators([Validators.required]);
        } else {
            control.clearValidators();
            control.setValue(null);
        }
        control.setErrors(null);
    }


    /**
     * Add ref value part on customer field update
     * @param event
     */
    public async customerUpdate(event: any): Promise<any> {
        if (event.refValue) {
            this.updateCustomer.emit(event.refValue);
        }
        this.formGroup.get("address_id").reset();
        this.formGroup.get("address_billto_id").reset();
        this.formGroup.get("contact_address_id").reset();
        this.formGroup.get("contact_address_id").reset();
        this.formGroup.get("contact_address_billto_id").reset();

        if (!PartnerService.partner.properties["disable_autofill_address_in_order"]) {
            this.fillCustomerAddress(event.controlValue);
        }
    }

    /**
     * Add ref value part on address field update
     * @param event
     */
    public addressUpdate(event: any): void {
        if (!this.formGroup.get("customer_id").value && event.address.customer) {
            this.formGroup.get("customer_id").setValue(event.address.customer.id, {emitEvent: false});
            this.customerIdRef.nativeElement.value = event.address.customer.name;
        }

        if (!!event.address?.note) {
            this.showAddressNote(event.address.note);
        }

        if (event.refValue) {
            this.updateCountry.emit(event.refValue);
        }
    }

    public async setFromPrevOrders(): Promise<any> {
        this.spinnerService.show();
        const response: Modal.IResponse = await this.modalService.open(OrderListModalComponent, {
            modalWidth: "98%"
        });
        this.spinnerService.hide();
        if (response) {
            this.setValues(response);
        }
    }

    public ngOnInit(): void {
        this.formGroup.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: any): void => {
                this.result.emit(value);
            });

        this.billToggle.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: boolean): void => {
            this.toggle(value, "address_billto_id");
            this.toggle(value, "contact_address_billto_id");
        });

        if (this.values) {
            this.setValues(this.values);
        }

    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
