<common-confirm></common-confirm>

<h1 class="container-heading">{{ action | ucfirst }} address</h1>

<mat-tab-group>
<mat-tab label="Address">
<mat-card>
    <mat-card-content>

        <form *ngIf="formGroup" [formGroup]="formGroup" (submit)="handleFormSubmit()" autocomplete="off" novalidate>

            <div class="group flex">

                <common-gmap-places-autocomplete *ngIf="showAddressAc"
                                                 [addressModel]="addressAcModel"
                                                 (result)="onAddressAutocompleteResult($event)">
                </common-gmap-places-autocomplete>


                <mat-form-field class="full">
                    <mat-label>Address</mat-label>
                    <input matInput formControlName="address">
                    <common-form-error [form]="formGroup" controlName="address"></common-form-error>
                </mat-form-field>

                <mat-form-field class="full">
                    <mat-label>Additional address information</mat-label>
                    <input matInput formControlName="address2">
                    <common-form-error [form]="formGroup" controlName="address2"></common-form-error>
                </mat-form-field>

                <mat-form-field class="third">
                    <mat-label>City</mat-label>
                    <input matInput formControlName="city">
                    <common-form-error [form]="formGroup" controlName="city"></common-form-error>
                </mat-form-field>

                <mat-form-field class="third">
                    <mat-label>ZIP code</mat-label>
                    <input matInput formControlName="zip">
                    <common-form-error [form]="formGroup" controlName="zip"></common-form-error>
                </mat-form-field>

                <mat-form-field class="third">
                    <mat-label>State or district (if available)</mat-label>
                    <input matInput formControlName="state">
                    <common-form-error [form]="formGroup" controlName="state"></common-form-error>
                </mat-form-field>


                <common-autocomplete [options]="countries" placeholder="Country" [allowFreeInput]="false"
                                     class="full" (optionSelected)="onCountrySelected($event)"
                                     [errorText]="'Select country'" [required]="true"
                                     [image]="{path:'assets/images/flags',ext:'svg'}"
                                     [value]="formGroup ? formGroup.value.country : null"></common-autocomplete>

                <mat-form-field class="half">
                    <mat-label>Latitude</mat-label>
                    <input matInput formControlName="lat">
                    <common-form-error [form]="formGroup" controlName="lat"></common-form-error>
                </mat-form-field>

                <mat-form-field class="half">
                    <mat-label>Longitude</mat-label>
                    <input matInput formControlName="lng">
                    <common-form-error [form]="formGroup" controlName="lng"></common-form-error>
                </mat-form-field>

                <mat-form-field class="third">
                    <mat-label>Ref</mat-label>
                    <input matInput formControlName="ref">
                    <common-form-error [form]="formGroup" controlName="ref"></common-form-error>
                </mat-form-field>

                <mat-form-field class="third">
                    <mat-label>Ref2</mat-label>
                    <input matInput formControlName="ref2">
                    <common-form-error [form]="formGroup" controlName="ref2"></common-form-error>
                </mat-form-field>

                <mat-form-field class="third">
                    <mat-label>Site name</mat-label>
                    <input matInput formControlName="address_name">
                    <common-form-error [form]="formGroup" controlName="address_name"></common-form-error>
                </mat-form-field>

                <mat-form-field class="third" *ngIf="!modal.params.type">
                    <mat-label>Address type</mat-label>
                    <mat-select formControlName="address_type">
                        <mat-option value="main">
                            Ship to
                        </mat-option>
                        <mat-option value="bill to">
                            Bill to
                        </mat-option>
                    </mat-select>
                    <common-form-error [form]="formGroup" controlName="address_type"></common-form-error>
                </mat-form-field>

                <mat-form-field class="third">
                    <mat-label>Out Incoterms</mat-label>
                    <mat-select formControlName="out_incoterms">
                        <mat-option [value]="null">None</mat-option>
                        <mat-option *ngFor="let incoterm of incoterms" [value]="incoterm.value">
                            {{ incoterm.name }}
                        </mat-option>
                    </mat-select>
                    <common-form-error [form]="formGroup" controlName="out_incoterms"></common-form-error>
                </mat-form-field>

                <mat-form-field class="third">
                    <mat-label>In Incoterms</mat-label>
                    <mat-select formControlName="in_incoterms">
                        <mat-option [value]="null">None</mat-option>
                        <mat-option *ngFor="let incoterm of incoterms" [value]="incoterm.value">
                            {{ incoterm.name }}
                        </mat-option>
                    </mat-select>
                    <common-form-error [form]="formGroup" controlName="in_incoterms"></common-form-error>
                </mat-form-field>

                <mat-form-field class="full">
                    <mat-label>Note</mat-label>
                    <input matInput formControlName="note">
                    <common-form-error [form]="formGroup" controlName="note"></common-form-error>
                </mat-form-field>

                <div class="full">
                    <common-form-hub-select
                            label="Default outbound hub"
                            [value]="formGroup.value.outbound_hub_id"
                            [required]="formGroup.get('outbound_hub_id').hasValidator(Validators.required)"
                            (onChange)="formGroup.get('outbound_hub_id').setValue($event)"
                    ></common-form-hub-select>
                </div>

                <div class="full">
                    <common-form-hub-select
                            label="Default inbound hub"
                            [value]="formGroup.value.inbound_hub_id"
                            [required]="formGroup.get('inbound_hub_id').hasValidator(Validators.required)"
                            (onChange)="formGroup.get('inbound_hub_id').setValue($event)"
                    ></common-form-hub-select>
                </div>

                <div class="full" *ngIf="serviceLevels">
                    <common-form-select
                            label="Default service level"
                            [value]="formGroup.value.default_service_level_id"
                            [options]="serviceLevels"
                            [required]="formGroup.get('default_service_level_id').hasValidator(Validators.required)"
                            (valueChange)="formGroup.get('default_service_level_id').setValue($event)"
                    ></common-form-select>
                </div>

                <mat-form-field class="full">
                    <mat-label>Courier service default</mat-label>
                    <mat-select formControlName="default_courier_service_id">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="selectSearch" [placeholderLabel]="'Search'"
                                                   [clearSearchInput]="true" [noEntriesFoundLabel]="'No items found'">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let courierService of courierServicesFiltered | async"
                                    [value]="courierService.value">
                            {{ courierService.name }}
                        </mat-option>
                    </mat-select>
                    <common-form-error [form]="formGroup" controlName="default_courier_service_id"></common-form-error>
                </mat-form-field>

                <div class="third">
                    <mat-checkbox formControlName="is_visible">Is active</mat-checkbox>
                </div>

                <div class="third">
                    <mat-checkbox formControlName="delivery_ref">Delivery ref required</mat-checkbox>
                </div>

                <div class="third">
                    &nbsp;
                </div>

            </div>

            <div class="actions flex-actions" *ngIf="modal.params.canEdit && action === 'edit'">
                <button mat-raised-button color="accent" type="button" (click)="showContacts()">
                    Show contacts
                </button>

                <button mat-raised-button color="accent" type="button" (click)="showPMs()">
                    Show linked PMs
                </button>

                <button mat-raised-button color="accent" type="button" (click)="showSLs()">
                    Show linked service levels
                </button>

                <button mat-raised-button color="accent" type="button" (click)="checkCoords()"
                        [disabled]="!formGroup.valid">
                    Check coordinates
                </button>
            </div>

            <div class="actions">
                <button mat-raised-button color="primary" type="submit" class="main" [disabled]="!formGroup.valid">
                    {{ action | ucfirst }}
                </button>
                <button mat-raised-button *ngIf="modal.params.canDelete"
                        type="button" color="warn" (click)="delete()">
                    Delete
                </button>
                <button mat-button type="button" (click)="showList()">Cancel</button>
            </div>

        </form>

    </mat-card-content>
</mat-card>
</mat-tab>

<mat-tab label="Notes" [disabled]="action !== 'edit'">
    <form [formGroup]="addressNotesForm">
      <mat-card formArrayName="notes">
        <div class="group flex"*ngFor="let note of notes.controls; let i=index" [formGroupName]="i">
          <mat-card-content>
  
            <mat-form-field class="full">
              <mat-label>Content</mat-label>
              <textarea matInput formControlName="content" placeholder="Note Content"></textarea>
            </mat-form-field>
  
            <mat-form-field class="full">
              <mat-label>Usage</mat-label>
              <mat-select formControlName="usage" multiple>
                <mat-option *ngFor="let option of noteUsage" [value]="option">{{ option }}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="full" *ngIf="isUsageIncluded(note.get('usage').value, 'Remarks')">
                <div formGroupName="parameters">
                <mat-label>Remark Type</mat-label>
                <mat-select formControlName="remark_type">
                    <mat-option *ngFor="let option of remarkTypes" [value]="option.id">
                        {{option.name}}
                    </mat-option>
                </mat-select>
                </div>
            </mat-form-field>

            <div class="pull-right">
                <button mat-raised-button color="accent" type="button"
                    (click)="handleSaveNote(i)"
                    [disabled]="!note.dirty">
                    Save Note
                </button>

                <button mat-raised-button color="warn" type="button"
                    (click)="handleRemoveNote(i)">
                    Remove Note
                </button>
            </div>
  
          </mat-card-content>
          <mat-divider></mat-divider>
        </div>
      </mat-card>

      <div class="actions flex-actions">
        <button mat-raised-button color="accent" type="button" (click)="addNote()">Add Note</button>
        <button mat-button type="button" (click)="showList()">Cancel</button>
      </div>
      
    </form>
  </mat-tab>
  

</mat-tab-group>