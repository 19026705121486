import {AfterViewInit, Directive, ElementRef} from "@angular/core";

// TODO: Remove this directive once the bug is fixed
/**
 * Temporary code to fix Chrome browser crashing.
 * See: https://issues.chromium.org/issues/335553723?pli=1
 */
@Directive({
    selector: "mat-select,input",
})
export class MatSelectRemoveAriaOwnsDirective implements AfterViewInit {
    constructor(private elementRef: ElementRef) {
    }

    ngAfterViewInit(): void {
        this.removeAriaOwns();
    }

    private removeAriaOwns(): void {

        // Get the parent element of mat-select
        const parentElement = this.elementRef.nativeElement.parentElement;

        // Find the label element within the parent span
        const labelElement = parentElement.querySelector("div > label");

        if (labelElement) {
            // Remove the aria-owns attribute from the label element
            labelElement.removeAttribute("aria-owns");
        }

    }
}