import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from "@angular/core";
import {Router} from "@angular/router";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {Table} from "../../../../../../../common/interfaces/table.interface";
import {UserService} from "../../../../../../../common/services/user.service";
import {ConfirmComponent} from "../../../../../../../common/components/confirm/confirm.component";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {Table2Component} from "../../../../../../../common/components/table2";
import {ModalService} from "../../../../../../services/modal.service";
import {PartMasterKitFormComponent} from "../..";

@Component({
    selector: "section-pm-kits-list",
    templateUrl: "list.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PartMasterKitsListComponent implements Base.IComponent, OnInit {

    @ViewChild(ConfirmComponent, {static: true})
    public confirmRef: ConfirmComponent;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    public listTable: Table.ISettings;

    public readonly state: Base.IState;

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private userService: UserService,
        private spinnerService: SpinnerService,
        private modalService: ModalService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {

        const actions: Table.IAction[] = [];

        if (this.userService.validatePermissions("edit_part_master_conversions")) {
            actions.push({
                name: "edit",
                title: "Edit",
                click: async (row: any): Promise<any> => {
                    await this.modalService.open(PartMasterKitFormComponent, {
                        part_master: row,
                        action: "edit"
                    });

                    this.tableRef.reload();
                }
            });
        }

        if (this.userService.validatePermissions("delete_part_master_conversions")) {
            actions.push({
                name: "delete",
                title: "Delete",
                click: async (row: any): Promise<any> => {
                    if (!await this.confirmRef.confirm("Are you sure want to delete this kit?")) {
                        return;
                    }
                    this.spinnerService.show();
                    const {code, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
                        ["partmaster", "kits", "" + row.id]);
                    this.spinnerService.hide();
                    if (code === 200) {
                        this.toastService.show(message, "success");
                        this.tableRef.reload();
                    }
                }
            });
        }

        this.listTable = {
            table_id: "9YMkil4o5P8cWS",
            columns: [
                {
                    data: "item",
                    title: "Item"
                },
                {
                    data: "child_parts",
                    title: "Kit parts",
                    searchable: false,
                    sortable: false,
                    render: (row: any): string => {
                        return row.kit_child_parts
                            .map((a: any): string => a.child_quantity + " x " + a.part_master.item)
                            .join(", ");
                    }
                }
            ],
            actions,
            api: {
                url: ["partmaster", "kits"]
            }
        };

        this.changeDetectorRef.markForCheck();
    }

    public async add(): Promise<any> {
        await this.modalService.open(PartMasterKitFormComponent);

        this.tableRef.reload();
    }

    public import(): void {
        this.router.navigate([
            this.state.section,
            "csv-import",
            "wizard",
            "type",
            "partmaster_kit",
            "back_to",
            btoa(this.router.url)
        ]);
    }

    public ngOnInit(): void {
        this.prepareList();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "part-master-kits",
            actions: {
                "browse": ["browse_part_master_conversions"]
            }
        };
    }

}
