import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Base} from "../../../../../../../../common/interfaces/base.interfaces";
import {Order} from "../../../../../../../../common/interfaces/order.interface";
import {Table2Component} from "../../../../../../../../common/components/table2";
import {Table} from "../../../../../../../../common/interfaces/table.interface";
import {Api} from "../../../../../../../../common/services/api.service";
import {Modal, ModalService} from "../../../../../../../services/modal.service";
import {UserService} from "../../../../../../../../common/services/user.service";
import {Router} from "@angular/router";
import {ConfirmComponent} from "../../../../../../../../common/components/confirm/confirm.component";
import {CommonFormComponent} from "../../../../../../../../common/components/form";
import {SpinnerService} from "../../../../../../../../common/services/spinner.service";
import {Api3Service} from "../../../../../../../../common/services/api3.service";
import {ToastService} from "../../../../../../../../common/services/toast.service";

@Component({
    selector: "section-order-view-items-inbound",
    templateUrl: "items-inbound.component.html",
    styleUrls: [
        "../items.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})

export class OrderItemsInboundComponent implements OnInit {

    @Input()
    public state: Base.IState;

    @Input()
    public set order(orderData: Order.IOrderData) {
        if (orderData) {
            this.orderData = orderData;
        }
    }

    public orderData: Order.IOrderData;

    @Input()
    public partmasters: any[] = [];

    @Output()
    public itemsUpdate: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public canFindBySlug: EventEmitter<any> = new EventEmitter<boolean>();

    @Output()
    public onGenerateReport: EventEmitter<any> = new EventEmitter<boolean>();

    @Output()
    public action: EventEmitter<{ action: { name: string, data: any }, type: string }>
        = new EventEmitter<{ action: { name: string, data: any }; type: string }>();

    @ViewChild(Table2Component, {static: false})
    public inboundTable: Table2Component;

    @ViewChild(ConfirmComponent, {static: true})
    public confirmRef: ConfirmComponent;

    public inboundItemsList: Table.ISettings;

    public createInboundShipment: boolean = false;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private api3Service: Api3Service,
        private spinnerService: SpinnerService,
        private modalService: ModalService,
        private toastService: ToastService,
        public router: Router,
        public userService: UserService
    ) {
    }


    /**
     * Get property from user
     * @param {string} name
     * @param defaultValue
     * @returns {any}
     */
    private getProperty(name: string, defaultValue: any): any {
        return this.userService.getProperty(name, this.state, defaultValue);
    }

    /**
     * Get items
     * @returns {void}
     */
    private prepareItemsList(): void {
        const columns = [
            {
                data: "serial",
                title: "Serial number"
            },
            {
                data: "item",
                title: "Items",
                render: (row: any): string => {
                    return "<a class='tableAction' data-action='item'>" + row.item + "</a>";
                },
                click: (row: any) => {
                    this.actionHandler({name: "item", data: row}, row.type);
                },
            },
            {
                data: "part_master.description",
                name: "PartMaster.description",
                title: "Description"
            },
            {
                data: "item",
                title: "",
                render: (row: any): string => {
                    if (row.inventory_allocations_sum_quantity > 0) {
                        return "<button class='mat-mdc-raised-button mdc-button mat-badge mat-badge-accent"
                            + " mat-badge-overlap mat-badge-above mat-badge-after mat-badge-medium' "
                            + "type='button'>"
                            + "<span class='mat-badge-content mat-badge-active'>"
                            + row.inventory_allocations_sum_quantity + "</span>"
                            + "Allocations"
                            + "</button>";
                    } else {
                        return "";
                    }
                },
                click: (row: any) => {
                    this.actionHandler({name: "allocations", data: row}, row.type);
                },
                searchable: false,
                sortable: false
            },
            {
                data: "item",
                title: "",
                render: (row: any): string => {
                    if (row.warehouse_transactions_sum_quantity > 0) {
                        return "<button class='mat-mdc-raised-button mdc-button mat-badge mat-badge-accent"
                            + " mat-badge-overlap mat-badge-above mat-badge-after mat-badge-medium status "
                            + ((row.status && row.status.name && row.status.name !== "")
                                ? row.status.name.toLowerCase() : "")
                            + "' "
                            + "type='button'>"
                            + "<span class='mat-badge-content mat-badge-active'>"
                            + row.warehouse_transactions_sum_quantity + "</span>"
                            + "Transactions"
                            + "</button>";
                    } else {
                        return "";
                    }
                },
                click: (row: any) => {
                    this.actionHandler({name: "transactions", data: row}, row.type);
                },
                searchable: false,
                sortable: false
            },
            {
                data: "batch",
                title: "Batch"
            },
            {
                data: "configurations",
                title: this.getProperty("configuration_name", "Configurations")
            },
            {
                data: "quantity",
                title: "Quantity"
            },
            {
                data: "inventory_conversion.customers_inventory_name",
                name: "InventoryConversion.customers_inventory_name",
                title: "Hub",
                render: (row: any): string => {
                    return row.inventory_conversion.customers_inventory_name
                        + " " + row.inventory_conversion.customers_sub_inventory
                        + (!row.warehouse_transactions_sum_quantity
                            ? " <mat-icon class='material-icons mat-icon pointer'>edit</mat-icon>" : "");
                },
                click: (row: any): void => {
                    if (row.warehouse_transactions_sum_quantity) {
                        return;
                    }
                    this.editParam(row.id, "hub", row.inventory_conversion_id, "hub_select");
                }
            },
            {
                data: "ref",
                title: "Ref",
                render: (row: any): string => {
                    return (row.ref ? row.ref + " " : "")
                        + "<mat-icon class='material-icons mat-icon pointer'>edit</mat-icon>";
                },
                click: (row: any): void => {
                    this.editParam(row.id, "ref", row.ref);
                }
            },
            {
                data: "ref2",
                title: "Ref2",
                render: (row: any): string => {
                    return (row.ref2 ? row.ref2 + " " : "")
                        + "<mat-icon class='material-icons mat-icon pointer'>edit</mat-icon>";
                },
                click: (row: any): void => {
                    this.editParam(row.id, "ref2", row.ref2);
                }
            },
            {
                data: "status.name",
                name: "Status.name",
                title: "Status"
            }
        ];

        this.inboundItemsList = {
            actions: [
                {
                    name: "edit",
                    title: "Edit",
                    click: (row: any) => {
                        this.actionHandler({name: "edit", data: row}, row.type);
                    },
                    disabledFn: (): boolean => {
                        return (this.orderData.confirmed
                                && !this.userService.validatePermissions("edit_order_item_after_confirmation"))
                            || (this.orderData.service_level.order_type.slug === "transfer"
                                && this.orderData.service_level.properties?.sync_items_lines);
                    }
                },
                {
                    name: "allocate",
                    title: "Allocate",
                    click: (row: any) => {
                        this.allocateItem(row);
                        row.allocation_in_progress = true;
                    },
                    disabledFn: (row: any): boolean => {
                        return Number(row.warehouse_transactions_sum_quantity)
                            + Number(row.inventory_allocations_sum_quantity) === Number(row.quantity)
                            || row.allocation_in_progress;
                    },
                    cssClassFn: (row: any): string => {
                        return "mat-accent" + (row.allocation_in_progress ? " spin" : "");
                    },

                },
                {
                    name: "delete",
                    title: "delete",
                    click: (row: any) => {
                        this.actionHandler({name: "delete", data: row}, row.type);
                    },
                    disabledFn: (row: any): boolean => {
                        return row.warehouse_transactions_sum_quantity ||
                            (this.orderData.confirmed
                                && !this.userService.validatePermissions("edit_order_item_after_confirmation")
                            )
                            || (this.orderData.service_level.order_type.slug === "transfer"
                                && this.orderData.service_level.properties?.sync_items_lines);
                    }
                }
            ],
            api: {
                url: [
                    this.state.section,
                    "order-items",
                ],
                query: {
                    order_id: this.orderData.id,
                    type: "inbound",
                    relations: [
                        "PartMaster:id,description",
                        "InventoryConversion:id,customers_inventory_name,customers_sub_inventory",
                        "Status:id,name"
                    ],

                    relation_sum: {
                        "InventoryAllocations": "quantity",
                        "WarehouseTransactions": "quantity",
                    },
                    appends: [
                        "allocation_in_progress"
                    ]
                },
                version: 3
            },
            columns,
            search_default: ["item"],
            export: {
                file_name: "inbound items"
            },
            showTotal: true
        };

        this.changeDetectorRef.markForCheck();
    }

    private async editParam(
        order_item_id: number,
        field: string,
        old_value: any = null,
        field_type = "input"
    ): Promise<any> {

        const {value}: Modal.IResponse = await this.modalService.open(CommonFormComponent, {
            formConfig: {
                id: 0,
                name: "Edit " + field,
                description: "",
                fields: [
                    {
                        label: "Value",
                        name: field,
                        size: "full",
                        type: field_type,

                        required: true,
                    }
                ]
            },
            values: {
                [field]: old_value
            }
        });

        if (value && value[field]) {
            this.spinnerService.show();
            const {code, message}: Api.IResponse = await this.api3Service.patch(
                `${this.state.section}/order-items/${order_item_id}`, value);
            this.spinnerService.hide();

            if (code === 200) {
                this.toastService.show(message, "success");
                this.reload();
            }
        }
    }

    public async allocateItem(item: any): Promise<any> {
        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.api3Service.post(
            `${this.state.section}/order-items/${item.id}/allocate`, {
                pallet: item.stock?.pallet,
                box: item.stock?.box,
                serial: item.stock?.serial,
            });

        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
        }
        this.reload(5000);
    }

    public reload(delay: number = 500): void {
        setTimeout(() => {
            this.inboundTable.reload();
        }, delay);
    }

    public actionHandler(action: { name: string, data: any }, type: string): void {
        this.action.emit({action, type});
    }

    public gotData(event: any): void {
        this.createInboundShipment = event.hasData && this.orderData.confirmed;
        if (this.inboundTable && this.inboundTable.tableData.length !== 0) {
            this.canFindBySlug.emit(true);
        }
    }

    public ngOnInit(): void {
        this.prepareItemsList();
    }

}
