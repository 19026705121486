<div class="form">
    <div class="group flex">
        <div class="full">
            <common-form-select *ngIf="contracts" [options]="contracts"
                                [required]="true"
                                label="Contracts"
                                (valueChange)="selectedContract.setValue($event)">
            </common-form-select>
        </div>
    </div>
</div>
