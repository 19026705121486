import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {UntypedFormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {UserService} from "../../../../../../common/services/user.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import * as moment from "moment";
import {Moment} from "moment";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: "section-milestone-form",
    templateUrl: "form.component.html",
    styleUrls: [
        "form.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class MilestoneFormComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    public modal: Modal.IModal;
    public formGroup: FormGroup;
    public edit: boolean = false;

    public reasons: { id: number, name: string, milestone_type_id: string }[] = [];

    public data: any;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private formBuilder: UntypedFormBuilder,
        private helpers: HelpersService,
        private toastService: ToastService,
        private userService: UserService,
        private modalService: ModalService,
        private spinnerService: SpinnerService
    ) {
    }

    /**
     * Prepare form group (create)
     * @returns {void}
     */
    private prepareForm(): void {
        this.formGroup = this.formBuilder.group({
            notes: new FormControl(null, [Validators.required]),
            complete_date: new FormControl(null),
            estimated_date: new FormControl(null),
            reason: new FormControl(null)
        });
        if (this.data) {
            for (const name of Object.keys(this.data)) {
                if (this.formGroup.value.hasOwnProperty(name)) {
                    this.formGroup.get(name).setValue(this.data[name]);
                }
            }
            if (this.modal.params.action === "edit" && this.data.id) {
                this.formGroup.addControl("id", new FormControl(this.data.id));
            }
        }

        this.changeDetectorRef.markForCheck();
    }

    /**
     * Get list of reasons
     * @returns {Promise<any>}
     */
    private async getReasons(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["milestones", "reasons"], {}, {type: this.data.milestone_type_id});
        if (data) {
            this.reasons = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Submit form
     * @returns {Promise<any>}
     */
    public async handleFormSubmit(): Promise<any> {
        this.spinnerService.show();
        this.apiService.setHeaders({partner: this.modal.params.partner.slug});
        const body: any = {
            notes: this.formGroup.value.notes,
            estimated_date: (this.formGroup.value.estimated_date
                ? moment(this.formGroup.value.estimated_date).format("YYYY-MM-DD HH:mm:ss")
                : null),
            complete_date: (this.formGroup.value.complete_date
                ? moment(this.formGroup.value.complete_date).format("YYYY-MM-DD HH:mm:ss")
                : null),
            reason: this.formGroup.value.reason
        };

        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["milestones", this.formGroup.value.id], body);
        if (response) {
            this.toastService.show(response.message, response.type as string);
            if (response.type as string === "success") {
                this.modal.response.next({
                    name: "value",
                    value: {}
                });
            }
        }
        this.spinnerService.hide();
    }

    public ngOnInit(): void {
        if (this.modal.params.data) {
            this.data = this.modal.params.data;
        }

        this.prepareForm();
        this.getReasons();
        this.formGroup.get("complete_date").valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val: Moment) => {
            if (val) {
                this.formGroup.get("reason").setValidators([Validators.required]);
            } else {
                this.formGroup.get("reason").clearValidators();
            }
            this.formGroup.get("reason").updateValueAndValidity();
        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
