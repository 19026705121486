import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    Type,
    ViewChild
} from "@angular/core";
import {FormControl, Validators} from "@angular/forms";
import {Base} from "./base.interfaces";
import {MatStepper} from "@angular/material/stepper";
import {StepperSelectionEvent} from "@angular/cdk/stepper";

export namespace Wizard {
    export interface IComponent {
        init(data: IData): void;
    }

    export interface IStep {
        component: Type<any>;
        title: string;
        description?: string;
        icon?: string;
        control: FormControl;
        componentRef: any;
        params?: any;
    }

    export interface IStepResult {
        action: string;
        value?: any;
        goToNextStep?: boolean;
    }

    export interface IData {
        state: Base.IState;

        [key: string]: any;
    }
}

@Component({
    template: ""
})
export abstract class AbstractWizardComponent implements AfterViewInit, OnInit {

    protected isLockdown: boolean = false;

    public wizardHeading: string;

    public readonly state: Base.IState;

    public data: Wizard.IData;

    public steps: Wizard.IStep[] = [];

    @ViewChild("stepper", {static: true})
    public stepper: MatStepper;

    public constructor(protected changeDetectorRef: ChangeDetectorRef) {
    }

    protected handleSetupEvent(event: Wizard.IStepResult, stepIndex: number): void {
    }

    protected async handleLockdown(event: Wizard.IStepResult, stepIndex: number): Promise<any> {
    }

    protected handleResultEvent(event: Wizard.IStepResult, stepIndex: number): void {

        if (event.value && typeof event.value === "object") {
            this.data = {...this.data, ...event.value};
        }

        if (event.value && event.value.lockdown && event.value.lockdown.length) {
            this.handleLockdown(event, stepIndex);
            return;
        }

        this.steps[stepIndex].control.setValue(event.value);

        if (event.goToNextStep && event.goToNextStep && this.steps[stepIndex].control.valid) {
            setTimeout((): void => {
                this.stepper.selectedIndex = this.stepper.selectedIndex + 1;
            }, 50);
        }
    }

    /**
     * Handle step changes
     * @param {StepperSelectionEvent} event
     * @returns {void}
     */
    public async handleStepChanges(event?: StepperSelectionEvent): Promise<any> {
        const step: number = event && event.selectedIndex ? event.selectedIndex : 0;
        this.data.stepParams = this.steps[step].params || null;
        this.steps[step].componentRef.init(this.data);
    }

    /**
     * Step result event handler
     * @param event
     * @param stepIndex
     */
    public handleStepEvent(event: Wizard.IStepResult, stepIndex: number): void {
        if (event.action === "ready") {
            this.steps[stepIndex].componentRef = event.value;
            this.changeDetectorRef.markForCheck();
        } else if (event.action === "setup") {
            this.handleSetupEvent(event, stepIndex);
        } else if (event.action === "result") {
            this.handleResultEvent(event, stepIndex);
        }
        this.changeDetectorRef.markForCheck();
    }

    public ngOnInit(): void {
        this.data = {state: this.state};
    }

    public ngAfterViewInit(): void {
        setTimeout((): void => {
            this.stepper.selectedIndex = 0;
            this.handleStepChanges();
        }, 10);
    }
}

@Component({
    template: ""
})
export abstract class AbstractWizardStepComponent implements Wizard.IComponent, OnDestroy {

    protected destroy$: EventEmitter<boolean> = new EventEmitter(false);

    @Output()
    public result: EventEmitter<Wizard.IStepResult> = new EventEmitter();

    public constructor(protected changeDetectorRef: ChangeDetectorRef) {
    }

    /**
     * Initialize step
     * @param data
     * @returns {Promise<any>}
     */
    public abstract init(data: Wizard.IData): Promise<any>;

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}


export class WizardStepFactory implements Wizard.IStep {

    private _component: Type<any>;
    private _title: string;
    private _description?: string;
    private _icon: string;
    private _control: FormControl = new FormControl(null, [Validators.required]);
    private _componentRef: any = null;
    private _params: any;

    public constructor(
        title: string,
        component: Type<any>,
        required: boolean = true,
        description: string = null,
        icon: string = null,
        params: any = null
    ) {
        this._title = title;
        this._component = component;
        this._description = description;
        this._icon = icon;
        this._params = params;
        if (!required) {
            this._control.clearValidators();
            this._control.updateValueAndValidity();
        }
    }

    public get params(): any {
        return this._params;
    }

    public get componentRef(): any {
        return this._componentRef;
    }

    public set componentRef(value: any) {
        this._componentRef = value;
    }

    public get control(): FormControl {
        return this._control;
    }

    public set control(value: FormControl) {
        this._control = value;
    }

    public get icon(): string {
        return this._icon;
    }

    public get description(): string {
        return this._description;
    }

    public get title(): string {
        return this._title;
    }

    public get component(): Type<any> {
        return this._component;
    }

    public set icon(value: string) {
        this._icon = value;
    }

    public set description(value: string) {
        this._description = value;
    }

    public set title(value: string) {
        this._title = value;
    }
}
