import {Form} from "../interfaces/form.interface";
import ISelectOption = Form.ISelectOption;

export const INCOTERMS: ISelectOption[] = [
    {
        value: "EXW",
        name: "EXW : EX WORKS",
    },
    {
        value: "FCA",
        name: "FCA : FREE CARRIER",
    },
    {
        value: "FAS",
        name: "FAS : FREE ALONG SIDE SHIP",
    },
    {
        value: "FOB",
        name: "FOB : FREE ON BOARD",
    },
    {
        value: "CFR",
        name: "CFR : COST AND FREIGHT",
    },
    {
        value: "CIF",
        name: "CIF: COST, INSURANCE AND FREIGHT",
    },
    {
        value: "CPT",
        name: "CPT : CARRIAGE PAID TO",
    },
    {
        value: "CIP",
        name: "CIP : CARRIAGE AND INSURANCE PAID TO",
    },
    {
        value: "DAT",
        name: "DAT : DELIVERED AT TERMINAL",
    },
    {
        value: "DAP",
        name: "DAP : DELIVERED AT PLACE",
    },
    {
        value: "DDP",
        name: "DDP : DELIVERED DUTY PAID",
    },
];
