<h1 *ngIf="state.action === 'add'" class="container-heading">Add ThreePL</h1>
<h1 *ngIf="state.action === 'edit'" [class.no-margin]="data" class="container-heading">
    Edit ThreePL
    <span *ngIf="data" class="container-heading-name">{{data.display_name}}</span>
</h1>

<div *ngIf="data" class="info">
    <span *ngIf="data.created_at" class="item">
        Created at:
        <span class="value">{{data.created_at | commonFix | date: "dd/MM/y HH:mm"}}</span>
    </span>
    <span *ngIf="data.updated_at" class="item">
        Updated at:
        <span class="value">{{data.updated_at | commonFix | date: "dd/MM/y HH:mm"}}</span>
    </span>
</div>

<mat-card>
    <mat-card-content>
        <mat-tab-group>
            <mat-tab label="Settings">
                <form *ngIf="formGroup" [formGroup]="formGroup" (submit)="handleFormSubmit()" autocomplete="off"
                      novalidate>

                    <div class="group custom flex">
                        <mat-form-field class="half">
                            <mat-label>Display name</mat-label>
                            <input matInput formControlName="display_name">
                        </mat-form-field>

                        <mat-form-field class="half">
                            <mat-label>Type</mat-label>
                            <mat-select formControlName="type">
                                <mat-option value="ThreePL">ThreePL</mat-option>
                                <mat-option value="OEM">OEM</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <h3 class="mat-h3">Address</h3>
                    <div formGroupName="address" class="group address flex">
                        <common-autocomplete [options]="countries" placeholder="Country" [allowFreeInput]="false"
                                             class="full" (optionSelected)="onCountrySelected($event)"
                                             [errorText]="'Select country'" [required]="true"
                                             [image]="{path:'assets/images/flags',ext:'svg'}"
                                             [value]="formGroup ? formGroup.value.address.country : null"></common-autocomplete>

                        <mat-form-field class="full">
                            <mat-label>Address</mat-label>
                            <input matInput formControlName="address">
                        </mat-form-field>
                        <mat-form-field class="full">
                            <mat-label>Additional address information</mat-label>
                            <input matInput formControlName="address2">
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>City</mat-label>
                            <input matInput formControlName="city">
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>ZIP code</mat-label>
                            <input matInput formControlName="zip">
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>State or district (if available)</mat-label>
                            <input matInput formControlName="state">
                        </mat-form-field>
                    </div>

                    <h3 class="mat-h3">Contact / Contact person</h3>
                    <div formGroupName="contact" class="group custom flex">
                        <mat-form-field>
                            <mat-label>First name</mat-label>
                            <input matInput formControlName="first_name">
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Last name</mat-label>
                            <input matInput formControlName="last_name">
                        </mat-form-field>
                        <mat-form-field class="full">
                            <mat-label>Email address</mat-label>
                            <input matInput formControlName="email">
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Phone number (with local country code)</mat-label>
                            <input matInput formControlName="phone">
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Fax</mat-label>
                            <input matInput formControlName="fax">
                        </mat-form-field>
                    </div>

                    <div class="actions">
                        <button mat-raised-button color="primary" type="submit" class="main"
                                [disabled]="!formGroup.valid">
                            <ng-template [ngIf]="state.action === 'add'">Add</ng-template>
                            <ng-template [ngIf]="state.action === 'edit'">Edit</ng-template>
                        </button>
                        <a [routerLink]="['/',state.section,state.component]" mat-button type="button">
                            Cancel
                        </a>
                    </div>

                </form>
            </mat-tab>
            <mat-tab label="Logs">
                <common-table2 *ngIf="logsTableSettings" [settings]="logsTableSettings"></common-table2>
            </mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>
