import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnChanges,
    SimpleChanges,
    ViewEncapsulation
} from "@angular/core";
import {ApiService} from "../../../../../../../../common/services/api.service";
import {Modal, ModalService} from "../../../../../../../services/modal.service";
import {OrderWizardItemRmaComponent} from "../../../index";
import {ToastService} from "../../../../../../../../common/services/toast.service";
import {StorageService} from "../../../../../../../../common/services/storage.service";
// Do NOT short this import statement!!!
// noinspection TypeScriptPreferShortImport
import {PusherService} from "../../../../../../../../common/services/pusher.service";
import {SpinnerService} from "../../../../../../../../common/services/spinner.service";
import {PartnerService} from "../../../../../../../../common/services/partner.service";
import {PartnerPartMasterService} from "../../../../../services/part-master.service";
import {Wizard} from "../../../../../../../../common/interfaces/wizard.interface";
import {OrderWizardItemsComponent} from "../items";
import {Api3Service} from "../../../../../../../../common/services/api3.service";

@Component({
    selector: "section-order-wizard-items-rma",
    templateUrl: "../items.component.html",
    styleUrls: [
        "../items.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class OrderWizardItemsRmaComponent extends OrderWizardItemsComponent implements OnChanges {

    public constructor(
        protected apiService: ApiService,
        protected api3Service: Api3Service,
        protected modalService: ModalService,
        protected changeDetectorRef: ChangeDetectorRef,
        protected toastService: ToastService,
        protected storageService: StorageService,
        protected pusherService: PusherService,
        protected partMasterService: PartnerPartMasterService,
        protected spinnerService: SpinnerService
    ) {
        super(apiService, api3Service, modalService, changeDetectorRef, toastService,
            storageService, pusherService, partMasterService, spinnerService);
    }

    protected getDefaultOutboundHubId(): number {
        if (this.address?.outbound_inventory_conversions?.length) {
            return this.address.outbound_inventory_conversions[0].id;
        }
        return null;
    }

    protected getDefaultInboundHubId(): number {
        if (this.address?.inbound_inventory_conversions?.length) {
            return this.address.inbound_inventory_conversions[0].id;
        }
        return null;
    }

    protected setup(data: Wizard.IData): void {
        super.setup(data);

        this.prepareOutboundTable();
        this.prepareInboundTable();
    }


    /**
     * Add new item
     * @returns {void}
     */
    public async addItem(type: any = null): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(OrderWizardItemRmaComponent, {
            partmasters: this.partmasters,
            details: this.details,
            order_id: this.data.order_id,
            serviceLevel: this.serviceLevel,
            state: this.state,
            modalWidth: type === "inbound" ? "600px" : "95%",
            modalHeight: type === "inbound" ? "auto" : "90%",
            default_outbound_hub_id: this.getDefaultOutboundHubId(),
            default_inbound_hub_id: this.getDefaultInboundHubId(),
            disable_hub_select:
                this.getDefaultOutboundHubId()
                && this.getDefaultInboundHubId()
                && PartnerService.partner.properties["lock_hub_in_order"],
            type: type
        });
        if (response && response.value) {
            this.outboundTableRef.reload(this.getUrl("outbound"));
            this.inboundTableRef.reload(this.getUrl("inbound"));
            this._skipAllocations = false;
            this.changeDetectorRef.markForCheck();
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);
    }

}
