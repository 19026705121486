import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    ViewEncapsulation
} from "@angular/core";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Order} from "../../../../../../common/interfaces/order.interface";
import {IPagination} from "../../../../../../common/components/pagination/pagination.component";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {IActivity} from "../../../../../../common/interfaces/activity.interface";
import {Api3Service} from "../../../../../../common/services/api3.service";
import {AppStateService} from "../../../../../../common/services/app-state.service";


@Component({
    selector: "section-order-activities",
    template: `

        <div *ngIf="activities && activities.length > 0; else elseBlock">
            <h2 matSubheader class="activities-header">Activities</h2>
            <div>
                <ng-template ngFor let-activity [ngForOf]="activities">
                    <section-activity-view [activity]="activity"></section-activity-view>
                    <mat-divider></mat-divider>
                </ng-template>
            </div>
            <common-pagination *ngIf="pagination" [data]="pagination"
                               pid="k0O7pXvO3"
                               (goToPage)="getData($event.page, $event.per_page)"></common-pagination>
        </div>
        <ng-template #elseBlock>
            <p>There is no any activity yet</p>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})

export class OrderActivitiesComponent implements AfterViewInit, OnChanges {

    @Input()
    public listType: string = "dataOnly";

    @Input()
    public perPage: number = 10;

    @Input()
    public orderData: Order.IOrderData;

    @Input()
    public activities: IActivity[] = [];

    public pagination: IPagination<IActivity>;

    public constructor(
        private apiService: ApiService,
        private api3Service: Api3Service,
        private changeDetectorRef: ChangeDetectorRef,
        private spinnerService: SpinnerService
    ) {
    }

    private markRead(): void {
        this.apiService.request(Api.EMethod.Delete,
            ["activities", "unread"], {
                related_model: "App\\Order",
                related_model_id: this.orderData.id
            });
    }

    /**
     * Get order shipments
     * @returns {Promise<any>}
     */
    public async getData(page: number = 1, per_page: number = null): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `${AppStateService.getState().section}/orders/${this.orderData.id}/activities`, {}, {
                data_structure: this.listType,
                page,
                per_page: per_page ? per_page : this.perPage,
                appends: [
                    "activity_model",
                    "activity_id"
                ],
                relations: [
                    "User"
                ],
                sort: {
                    data: "created_at",
                    dir: "desc"
                }
            });
        if (data) {
            switch (this.listType) {
                case "paginated":
                    this.activities = data.data;
                    this.pagination = data;
                    break;
                default:
                    this.activities = data;
                    break;
            }
        }
        this.spinnerService.hide();
        this.changeDetectorRef.markForCheck();
    }

    public ngAfterViewInit(): void {
        if (this.activities.length === 0 && this.orderData) {
            this.getData();
            this.markRead();
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.orderData) {
            this.getData();
        }
    }
}
