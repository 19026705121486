import {Injectable} from "@angular/core";
import {Api3Service} from "../../../../common/services/api3.service";
import {Api} from "../../../../common/services/api.service";

@Injectable()
export class AdminRoleService {

    public constructor(private apiService: Api3Service) {
    }

    public getRoles(query: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Get, "admin/roles", {}, query);
    }


    public getRole(id: number, query: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Get, "admin/roles/" + id, {}, query);
    }

    public addRole(body: any = {}, query: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Post, "admin/roles", body, query);
    }


}
