<h1 class="container-heading">Tracking list</h1>
<mat-card>
    <div class="filter-plus-content">
        <div class="filter-container" [class.open]="showFilter">
            <div class="padding-right-20">
                <common-filter *ngIf="filters" [filters]="filters" [componentName]="'Tracking list'"
                               (changeEvent)="getData($event)" configId="tracking-list-filter"></common-filter>
                <common-simple-spinner *ngIf="!filters"></common-simple-spinner>
            </div>
        </div>
        <div>
            <common-table2 *ngIf="listTable" [settings]="listTable">
                <div row1 class="flex row align-center">
                    <button mat-mini-fab (click)="toggleFilter()" class="margin-right-10">
                        <mat-icon title="Filters">filter_list</mat-icon>
                    </button>
                    <a routerLink="add" mat-raised-button color="primary" class="create">Add</a>
                    <button *ngIf="showExportButton" mat-raised-button type="button" color="primary"
                            (click)="report()">
                        Tracking report
                    </button>
                    <button *userAllowed="'edit_tracking_price'" mat-raised-button type="button" color="accent"
                            (click)="importPrice()">
                        Update prices
                    </button>
                </div>
            </common-table2>
        </div>

    </div>
</mat-card>
<section-tracking-sidebar (onUpdate)="update()"></section-tracking-sidebar>
