<ng-template #mapTemplate>
    <div>
        <b>Filter:</b>&nbsp;
        <mat-form-field>
            <mat-label>Status</mat-label>
            <mat-select [formControl]="markersFilter" [multiple]="true">
                <mat-select-trigger>
                    <ng-template ngFor let-val let-i="index" [ngForOf]="markersFilter.value">
                        {{val | ucfirst}}
                        <ng-template [ngIf]="i+1 < markersFilter.value.length">,</ng-template>
                    </ng-template>
                </mat-select-trigger>
                <mat-option *ngFor="let item of markerStatuses" [value]="item.value">
                    <span class="dash-chip {{item.value}}">{{item.text | ucfirst}}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
        &nbsp;
        <mat-form-field>
            <mat-label>Order type</mat-label>
            <mat-select [formControl]="markersOrderType" [multiple]="true">
                <mat-option *ngFor="let item of orderTypes" [value]="item.value">
                    <span class="dash-chip">{{item.text | ucfirst}}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
        &nbsp;
        <mat-form-field>
            <mat-label>Show</mat-label>
            <mat-select [formControl]="markersDaysCount">
                <mat-option value="7">last 7 days</mat-option>
                <mat-option value="14">last 14 days</mat-option>
                <mat-option value="30">last 30 days</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <common-gmap [center]="{lat:0,lng:0}"
                 [markers]="filteredMarkers"
                 [height]="600"
                 [zoom]="2"
                 (markerClick)="onMarkerClick($event)"
    ></common-gmap>

</ng-template>


<ng-container *ngIf="!embeddedMap">
    <h1 class="container-heading">Tracking map</h1>
    <mat-card>
        <mat-card-content>
            <ng-container *ngTemplateOutlet="mapTemplate"></ng-container>
        </mat-card-content>
    </mat-card>
</ng-container>

<ng-container *ngIf="embeddedMap">
    <h3 class="graph-title">Tracking map</h3>
    <ng-container *ngTemplateOutlet="mapTemplate"></ng-container>
</ng-container>

<common-sidebar></common-sidebar>
