import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {StorageService} from "../../../../../../../common/services/storage.service";
import {UserService} from "../../../../../../../common/services/user.service";
import {ModalService} from "../../../../../../services/modal.service";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {AbstractOrderItemComponent} from "../../item/item";
import {distinctUntilChanged, takeUntil} from "rxjs/operators";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {Api3Service} from "../../../../../../../common/services/api3.service";
import {AppStateService} from "../../../../../../../common/services/app-state.service";
import {Warehouse} from "../../../../../../../common/interfaces/warehouse.interface";
import {FormControl} from "@angular/forms";
import {HelpersService} from "src/modules/common/services/helpers.service";


@Component({
    selector: "section-order-item",
    templateUrl: "../../item/item.component.html",
    styleUrls: [
        "../../item/item.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class OrderItemComponent extends AbstractOrderItemComponent implements OnInit {

    public allocation_ref: string = null;
    public allocate_button_allocation_ref: string = null;

    public recreate_allocations: FormControl = new FormControl(false);

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        protected apiService: ApiService,
        protected api3Service: Api3Service,
        protected toastService: ToastService,
        protected modalService: ModalService,
        protected userService: UserService,
        protected storageService: StorageService,
        protected spinnerService: SpinnerService,
        protected helperService: HelpersService
    ) {
        super(changeDetectorRef, apiService, api3Service, toastService, modalService, userService, storageService,
            spinnerService);
    }

    protected async setFormValues(): Promise<any> {
        await super.setFormValues();

        if (this.modal.params.data) {
            // this.getSerialAllocations();
            // this.getBoxAllocations();
            // this.getPalletAllocations();
            this.getTransactions();
        }
    }

    private async getSerialAllocations(): Promise<any> {
        if (!this.modal.params.data) {
            return;
        }

        this.spinnerService.show();

        const {data}: Api.IResponse = await this.api3Service.get(
            `${this.modal.params.state.section}/order-items/${this.modal.params.data.id}/allocations`, {
                unboxed: true
            });

        this.spinnerService.hide();

        if (data) {
            data.forEach((row: Warehouse.IInventoryAllocation) => {
                if (row.serial) {
                    this.addSerialFromTable({
                        id: row.type === "outbound" ? row.inventory_id : (Math.random() + 1) * 1000,
                        serial: row.serial,
                        quantity: 1,
                        inventory_conversion_id: row.inventory_conversion_id,
                        part_master_id: row.part_master_id,
                        item: row.item
                    });
                }
            });

            this.changeDetectorRef.markForCheck();
        }
    }

    private async getBoxAllocations(): Promise<any> {
        if (!this.modal.params.data) {
            return;
        }

        this.spinnerService.show();

        const {data}: Api.IResponse = await this.api3Service.get(
            `${this.modal.params.state.section}/order-items/${this.modal.params.data.id}/box-allocations`, {
                off_pallet: true
            });

        this.spinnerService.hide();

        if (data) {
            data.forEach((row: any) => {

                this.addBoxFromTable({
                    id: row.box_id,
                    ref: row.box_ref,
                    inventories_free_count: row.quantity,
                    pallet_id: row.pallet_id
                });
            });

            this.changeDetectorRef.markForCheck();
        }

    }

    private async getPalletAllocations(): Promise<any> {
        if (!this.modal.params.data) {
            return;
        }

        this.spinnerService.show();

        const {data}: Api.IResponse = await this.api3Service.get(
            `${this.modal.params.state.section}/order-items/${this.modal.params.data.id}/pallet-allocations`);

        this.spinnerService.hide();

        if (data) {
            data.forEach((row: any) => {
                this.addPalletFromTable({
                    id: row.pallet_id,
                    ref: row.pallet_ref,
                    inventories_free_count: row.quantity
                });
            });

            this.changeDetectorRef.markForCheck();
        }
    }

    private async getTransactions(): Promise<any> {
        if (!this.modal.params.data) {
            return;
        }

        this.spinnerService.show();

        const {data}: Api.IResponse = await this.api3Service.get(
            `${this.modal.params.state.section}/order-items/${this.modal.params.data.id}/transactions`);

        this.spinnerService.hide();

        if (data) {
            data.forEach((row: Warehouse.ITransaction) => {
                if (row.serial) {
                    const exists = this.selectedInventory.serial
                        .findIndex((v) => v.serial === row.serial);

                    if (exists > -1) {
                        this.selectedInventory.serial[exists].type = "transaction";
                        return;
                    }

                    this.addSerialFromTable({
                        id: row.inventory_id ? row.inventory_id : (Math.random() + 1) * 1000,
                        serial: row.serial,
                        quantity: 1,
                        inventory_conversion_id: row.inventory_conversion_id,
                        part_master_id: row.part_master_id,
                        item: row.item,
                        type: "transaction"
                    });
                }
            });

            this.changeDetectorRef.markForCheck();
        }
    }


    private setValidators(): void {
        if (this.itemType === "outbound") {
            this.formGroup.get("inbound_hub_id").clearValidators();
            this.formGroup.get("inbound_hub_id").updateValueAndValidity();
        }
        if (this.itemType === "inbound") {
            this.formGroup.get("outbound_hub_id").clearValidators();
            this.formGroup.get("outbound_hub_id").updateValueAndValidity();
        }
    }

    private async getItemsToAdd(): Promise<any> {
        const items: any[] = [
            {
                order_id: this.modal.params.details ? this.modal.params.details.id : this.modal.params.order_id,
                order_ref: this.modal.params.details ? this.modal.params.details.ref : this.modal.params.orderRef,
                part_master_id: this.formGroup.get("part_master_id").value,
                ref: this.formGroup.get("ref").value,
                ref2: this.formGroup.get("ref2").value,
                status_id: this.formGroup.get("status_id").value,
                serial: this.formGroup.get("serial").value,
                pallet: this.formGroup.get("pallet").value,
                box: this.formGroup.get("box").value,
                hub_id: this.formGroup.get(this.itemType + "_hub_id").value,
                batch: this.formGroup.get("batch").value,
                rev: this.formGroup.get("rev").value,
                lot: this.formGroup.get("lot").value,
                configurations: this.formGroup.get("configurations").value,
                warehouse_location_id: this.formGroup.get("warehouse_location_id").value,
                quantity: this.formGroup.get("quantity").value,
                type: this.itemType,
                allocation_ref: this.allocate_button_allocation_ref
                    ? this.allocate_button_allocation_ref : this.allocation_ref,
                price: this.formGroup.get("price").value
            }
        ];

        if (this.modal.params.serviceLevel.order_type.slug === "transfer") {
            if (!this.modal.params.serviceLevel.properties?.sync_items_lines) {
                const confirmSync = await this.confirm.confirm("Do you want sync lines?");
                if (!confirmSync) {
                    return items;
                }
            }
            if (!items[0].item_key) {
                items[0].item_key = HelpersService.randomString();
            }
            const copy: any = {...items[0]};
            copy.type = items[0].type === "outbound" ? "inbound" : "outbound";
            copy.hub_id = items[0].type === "outbound"
                ? this.modal.params.transferDir.inbound.hub_id
                : this.modal.params.transferDir.outbound.hub_id;

            items.push(copy);
        }

        return items;
    }

    public showOutboundHubSelect(): boolean {
        return this.itemType === "outbound";
    }

    public showInboundHubSelect(): boolean {
        return this.itemType === "inbound";
    }

    /**
     * Add item (prepare & add modal response)
     */
    public async save(): Promise<any> {
        let response: Api.IResponse;
        const items = (await this.getItemsToAdd());
        if (this.modal.params.action === "edit" && this.modal.params.data) {
            this.spinnerService.show();

            response = await this.api3Service.put(
                `${AppStateService.getState().section}/order-items/${this.modal.params.data.id}`,
                {
                    ...items[0],
                    recreate_allocations: this.recreate_allocations.value,
                    use_sync: items[0].item_key !== null
                });
        } else if (this.modal.params.action === "add") {

            this.spinnerService.show();

            response = await this.api3Service.post(`${AppStateService.getState().section}/order-items`,
                {
                    items
                });
        }

        this.spinnerService.hide();
        if (response) {
            this.toastService.show(response.message, response.type as string);
            this.modal.response.emit({
                name: "response",
                value: response.data
            });
        }
    }

    public async ngOnInit(): Promise<any> {

        this.itemType = this.modal.params.lineType;

        this.setValidators();


        this.state = this.modal.params.state;

        if (!this.modal.params.serviceLevel.properties.stock_mode) {
            this.modal.params.serviceLevel.properties.stock_mode = "free";
            this.stockMode.setValue("free");
        }

        this.disable_hub_select = !!this.modal.params.disable_hub_select;

        this.stockMode.setValue(this.modal.params.serviceLevel.properties.stock_mode.toLowerCase());

        this.changeDetectorRef.markForCheck();
        this.getPartner();

        this.setFormValues().then(() => {
            setTimeout(() => {
                this.prepareFormListeners();
            }, 1500);
        });


        // show statuses only when item edit
        if (this.modal.params.data) {
            this.getItemStatuses();
        }


        this.window_title = this.modal.params.action === "edit" ? "Edit item" : "Add item";
        this.orderRef = this.modal.params.orderRef;

        this.formGroup.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
            .subscribe((value: string): void => {
                this.allocation_ref = null;
            });
    }

    protected readonly location = location;
}
