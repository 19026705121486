<div class="pull-right">
    <mat-form-field>
        <input type="search" #itemSearch matInput placeholder="Search">
    </mat-form-field>
</div>

<h1 class="container-heading margin-bottom-10">Select order item</h1>

<div class="clear">

    <div class="items">
        <div *ngFor="let item of modal.params.items | search: itemSearch.value : ['serial','box_ref','warehouse_location.location','pallet_ref']"
             class="pointer"
             [class.selected]="select.value && item.id === select.value.id"
             (click)="select.setValue(item)">
            <div>
                Item: <b>{{item.item}}</b>
            </div>

            <div *ngIf="item.serial">
                Serial: <b>{{item.serial}}</b>
            </div>

            <div *ngIf="item.batch">
                Batch: <b>{{item.batch}}</b>
            </div>

            <div *ngIf="item.warehouse_location && modal.params.type ==='outbound'">
                Location: <b>{{item.warehouse_location.location}}</b>
            </div>

            <div *ngIf="item.pallet_ref">
                Pallet: <b>{{item.pallet_ref}}</b>
            </div>

            <div *ngIf="item.box_ref">
                Box: <b>{{item.box_ref}}</b>
            </div>

            <div *ngIf="item.configurations">
                Configurations: <b>{{item.configurations}}</b>
            </div>

            <div *ngIf="item.inventory_conversion">
                Customers sub-inventory: <b>{{item.inventory_conversion.customers_sub_inventory}}</b>
            </div>

            <div>
                Quantity: <b>{{item.quantity}}</b>
            </div>

            <div *ngIf="item.warehouse_transactions_count && item.quantity > item.warehouse_transactions_count"
                 class="text-error">
                Existing transactions: <b>{{item.warehouse_transactions_count}}</b>
            </div>
        </div>
    </div>

    <div class="padding-top-10">
        <button type="button"
                mat-raised-button
                color="accent"
                [disabled]="!select.valid"
                (click)="selectItem()">
            Select item
        </button>
    </div>
</div>