import {
    ChangeDetectionStrategy,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    EventEmitter,
    OnDestroy,
    OnInit,
    Type,
    ViewContainerRef
} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {ActivatedRoute, Event, NavigationEnd, Router, UrlSegment} from "@angular/router";
import {filter, takeUntil} from "rxjs/operators";
import {Base} from "../../common/interfaces/base.interfaces";
import * as jQuery from "jquery";
import {UserService} from "../../common/services/user.service";
import {User} from "../../common/interfaces/user.interface";
import {PartnerService} from "../../common/services/partner.service";
import {ThpeeplService} from "../../common/services/thpeepl.service";
import {AppStateService} from "../../common/services/app-state.service";
import {adminRoutingComponents} from "./admin/admin-section.module";
import {routingComponents} from "../section.module";
import {ErrorComponent} from "./common/components/error/error.component";
import {commonRoutingComponents} from "./common/common-section.module";
import {partnerRoutingComponents} from "./partner/partner-section.module";
import {WarehouseService} from "../../common/services/warehouse.service";
import {Api3Service} from "../../common/services/api3.service";
import {Warehouse} from "../../common/interfaces/warehouse.interface";
import {Api} from "../../common/services/api.service";


export namespace Entry {

    export interface IMap {
        [key: string]: {
            [key: string]: Type<any>;
        };
    }

}


@Component({
    selector: "section-outlet",
    template: ``,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OutletComponent implements OnInit, OnDestroy {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    /**
     * Component reference
     * @type {ComponentRef<any>}
     */
    private reference: ComponentRef<any>;

    public constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private componentFactoryResolver: ComponentFactoryResolver,
        private viewContainerRef: ViewContainerRef,
        private title: Title,
        private userService: UserService,
        private api3Service: Api3Service
    ) {
    }

    private prepareComponents(): Entry.IMap {


        const state: Base.IState = AppStateService.getState();

        let routes: Type<any>[] = [];
        switch (state.section_type) {
            case "admin":
                routes = [
                    ...commonRoutingComponents,
                    ...adminRoutingComponents,
                ];
                break;
            case "partner":
                routes = [
                    ...commonRoutingComponents,
                    ...partnerRoutingComponents,
                ];
                break;
            default:
                routes = [
                    ...commonRoutingComponents,
                    ...routingComponents
                ];
                break;
        }

        const components: Entry.IMap = {};

        routes.forEach((type: Type<any>): void => {
            const config: Base.IConfig = typeof type.prototype.ngConfig === "function" && type.prototype.ngConfig();
            if (config) {
                let result: { [key: string]: Type<any> } = {};
                Object.keys(config.actions).forEach((name: string): void => {
                    if (name) {
                        result[name] = this.validatePermissions(config.actions[name]) ? type : null;
                    }
                });
                if (components[config.name]) {
                    result = {...components[config.name], ...result};
                }
                components[config.name] = result;
            }
        });

        return components;
    }

    /**
     * Create component instance & insert to view
     * @param {Base.IState} state
     * @returns {void}
     */
    private createComponent(state?: Base.IState): void {
        this.destroyComponent();

        if (!state || !state.component) {
            state = this.prepareState();
        }

        let componentType: Type<any>;
        const componentMap: Entry.IMap = this.prepareComponents();

        const currentAction: string = state.action || "browse";
        const actionList: { [key: string]: Type<any> } = componentMap[state.component];

        componentType = actionList && (actionList["*"] || actionList[currentAction]);

        if (componentType) {
            this.reference = this.viewContainerRef.createComponent(
                this.componentFactoryResolver.resolveComponentFactory(componentType)
            );
        } else {
            this.reference = this.viewContainerRef.createComponent(
                this.componentFactoryResolver.resolveComponentFactory(ErrorComponent)
            );
        }

        (this.reference.instance as any).state = state;
        this.reference.location.nativeElement.classList.add(currentAction);
        this.reference.changeDetectorRef.detectChanges();
        this.setTitle();
    }

    /**
     * Destroy component instance / reference
     * @returns {void}
     */
    private destroyComponent(): void {
        if (this.reference) {
            this.reference.destroy();
            this.reference = null;
            this.viewContainerRef.clear();
        }
    }

    /**
     * Prepare state (by current url)
     * @returns {void}
     */
    private prepareState(): Base.IState {
        const state: Base.IState = {};
        const urlSegments: string[] = this.activatedRoute.snapshot.url
            .map((segment: UrlSegment): string => segment.path);
        state.action = urlSegments[1];
        state.component = urlSegments[0];
        state.params = {};
        urlSegments.slice(2).forEach((segment: string, index: number, self: any): void => {
            if (segment) {
                state.params[segment] = self[index + 1];
                self[index + 1] = null;
            }
        });
        state.section = this.activatedRoute.parent.snapshot.params.section;
        const [type, slug]: string[] = state.section.split("/", 2);
        state.section_type = type;
        state.section_slug = slug;

        this.prepareSectionData(state);

        AppStateService.setState(state);
        return state;
    }

    private prepareSectionData(state: Base.IState): void {
        switch (state.section_type) {
            case "partner":
                if (!PartnerService.partner || PartnerService.partner.slug !== state.section_slug) {
                    const partner: User.IPartner = this.userService.data.partners
                        .find((item: User.IPartner): boolean => {
                            return item.slug === state.section_slug;
                        });
                    this.userService.setPartner(partner);

                    this.api3Service.get(state.section).then((res: Api.IResponse) => {
                        this.userService.setPartner(res.data);
                    });
                }
                break;
            case "threepl":
                if (!ThpeeplService.threepl || ThpeeplService.threepl.slug !== state.section_slug) {
                    const threepl: User.IThreepl = this.userService.data.threepls
                        .find((item: User.IThreepl): boolean => {
                            return item.slug === state.section_slug;
                        });
                    this.userService.setThreepl(threepl);

                    this.api3Service.get(state.section).then((res: Api.IResponse) => {
                        this.userService.setThreepl(res.data);
                    });
                }
                break;
            case "warehouse":
                if (!WarehouseService.warehouse || WarehouseService.warehouse.slug !== state.section_slug) {
                    this.api3Service.get(state.section).then((res: Api.IResponse) => {
                        this.userService.setWarehouse(res.data);
                    });
                }
                break;
            default:
                this.userService.clearSection();
                break;
        }

    }

    /**
     * Set browser title (by h1 tag on page)
     * @returns {void}
     */
    private setTitle(): void {
        const timeout: any = setTimeout((): void => {
            const element: HTMLElement = jQuery("h1")[0] as HTMLElement;
            this.title.setTitle("Logivice" + ((element && element.textContent) ? (" / " + element.textContent) : ""));
            clearTimeout(timeout);
        });
    }

    private validatePermissions(permissions: "*" | string[]): boolean {
        if (permissions === "*") {
            return true;
        }
        return this.userService.validatePermissions(permissions);
    }

    public ngOnInit(): void {
        this.createComponent();
        this.router.events
            .pipe(
                filter((event: Event): boolean => event && event instanceof NavigationEnd),
                takeUntil(this.destroy$)
            ).subscribe((): void => this.createComponent());
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
        this.destroyComponent();
    }

}
