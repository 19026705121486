import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, ViewEncapsulation} from "@angular/core";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {FormControl, Validators} from "@angular/forms";
import {Warehouse} from "../../../../../../../common/interfaces/warehouse.interface";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../common/interfaces/wizard.interface";
import {takeUntil} from "rxjs/operators";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";

@Component({
    selector: "section-warehouse-order-wizard-select-shipment",
    templateUrl: "select-shipment.component.html",
    styleUrls: [
        "select-shipment.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})

export class WarehouseOrderWizardSelectShipmentComponent extends AbstractWizardStepComponent implements OnDestroy {

    private orderRef: string;

    public state: Base.IState;

    public shipments: Warehouse.IShipment[] = [];

    public selectedShipment: FormControl = new FormControl(null, [Validators.required]);

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private spinnerService: SpinnerService
    ) {
        super(changeDetectorRef);
    }

    /**
     * Get order shipments
     * @returns {Promise<any>}
     */
    private async getOrderShipments(type?: string): Promise<any> {
        this.spinnerService.show();

        const params: any = type ? {type} : {};

        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["order", this.orderRef, "shipments"], {}, params);
        if (data) {
            this.shipments = data;
            if (this.shipments.length === 1) {
                this.result.emit({
                    action: "setup",
                    value: this.shipments[0].type
                });
                this.result.emit({
                    action: "result",
                    value: {shipmentId: this.shipments[0].id},
                    goToNextStep: true
                });
            }
        }
        this.spinnerService.hide();
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Initialize details step
     * @param data
     * @returns {Promise<any>}
     */
    public async init(data: Wizard.IData): Promise<any> {
        this.state = data.state;
        this.orderRef = data.orderData.ref;
        this.getOrderShipments(data.lineType);

        this.selectedShipment.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: string): void => {
            this.result.emit({
                action: "setup",
                value: this.shipments[value].type
            });
            this.result.emit({
                action: "result",
                value: {shipmentId: this.shipments[value].id}
            });
        });
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}
