import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {NavigationEnd, Router, RouterOutlet} from "@angular/router";
import {StorageService} from "../../../common/services/storage.service";
import {Subscription} from "rxjs";
import {UserService} from "../../../common/services/user.service";
import {User} from "../../../common/interfaces/user.interface";
import {DrawerService} from "../../services/drawer.service";
import {PusherService} from "../../../common/services/pusher.service";
import {ModalService} from "../../../section/services/modal.service";
import {RequestFormComponent} from "../../../section/components/common/components/requests";
import {environment} from "../../../../environments/environment";
import {Base} from "../../../common/interfaces/base.interfaces";
import {Api, ApiService} from "../../../common/services/api.service";
import {ToolbarComponent} from "../toolbar/toolbar.component";
import {LiveChatWidgetModel} from "../../../common/interfaces/livechat.interface";
import {filter} from "rxjs/operators";
import {MediaScreenService} from "../../../common/services/media-screen.service";
import {ToastService} from "../../../common/services/toast.service";

@Component({
    selector: "app-section",
    templateUrl: "section.component.html",
    styleUrls: [
        "section.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None

})
export class SectionComponent implements OnInit, OnDestroy, AfterViewInit {

    private subscription: Subscription;

    private media_interval: any;

    @ViewChild("outlet", {static: true})
    public outlet: RouterOutlet;


    @ViewChild("liveChatWidget", {static: false})
    public liveChatWidget: LiveChatWidgetModel;

    @ViewChild("toolbarComponent", {static: false})
    public toolbar: ToolbarComponent;

    /**
     * LiveChat variables
     */
    public visitor: { name: string; email: string };
    public liveChatLicenseId: number = environment.livechatLicenseId;
    public liveChat: boolean = false;

    /**
     * Current section name
     * @type {string}
     */
    public section: string;
    /**
     * Current section type
     * @type {string}
     */
    public type: string;

    /**
     * Current url segments
     *
     */
    public state: Base.IState = {
        params: {}
    };

    public lockDrawer: boolean = false;

    public theme: string = "theme-blue-grey";

    public isProd: boolean = environment.prodServer.indexOf(window.location.hostname) !== -1;

    public particleStyle: any = {
        "position": "absolute",
        "width": "100%",
        "height": "100%",
        "z-index": -1,
        "top": 0,
        "left": 0,
        "right": 0,
        "bottom": 0,
    };

    public particlesParams: any = {
        "particles": {
            "number": {
                "value": 400,
                "density": {
                    "enable": true,
                    "value_area": 800
                }
            },
            "color": {
                "value": this.isProd ? "#ffffff" : "#FFEB3B"
            },
            "shape": {
                "type": "circle",
                "stroke": {
                    "width": 0,
                    "color": "#000000"
                },
                "polygon": {
                    "nb_sides": 5
                },
                "image": {
                    "src": "img/github.svg",
                    "width": 100,
                    "height": 100
                }
            },
            "opacity": {
                "value": 0.5,
                "random": true,
                "anim": {
                    "enable": false,
                    "speed": 1,
                    "opacity_min": 0.1,
                    "sync": false
                }
            },
            "size": {
                "value": 10,
                "random": true,
                "anim": {
                    "enable": false,
                    "speed": 40,
                    "size_min": 0.1,
                    "sync": false
                }
            },
            "line_linked": {
                "enable": false,
                "distance": 500,
                "color": this.isProd ? "#ffffff" : "#FFEB3B",
                "opacity": 0.4,
                "width": 2
            },
            "move": {
                "enable": true,
                "speed": 3,
                "direction": "bottom",
                "random": false,
                "straight": false,
                "out_mode": "out",
                "bounce": false,
                "attract": {
                    "enable": false,
                    "rotateX": 600,
                    "rotateY": 1200
                }
            }
        },
        "interactivity": {
            "detect_on": "canvas",
            "events": {
                "onhover": {
                    "enable": true,
                    "mode": "bubble"
                },
                "onclick": {
                    "enable": true,
                    "mode": "repulse"
                },
                "resize": true
            },
            "modes": {
                "grab": {
                    "distance": 400,
                    "line_linked": {
                        "opacity": 0.5
                    }
                },
                "bubble": {
                    "distance": 400,
                    "size": 4,
                    "duration": 0.3,
                    "opacity": 1,
                    "speed": 3
                },
                "repulse": {
                    "distance": 200,
                    "duration": 0.4
                },
                "push": {
                    "particles_nb": 4
                },
                "remove": {
                    "particles_nb": 2
                }
            }
        },
        "retina_detect": true
    };

    public requestsCount: number = 0;

    public media_is_recording: boolean = false;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private drawerService: DrawerService,
        private userService: UserService,
        private pusherService: PusherService,
        private router: Router,
        private modalService: ModalService,
        private apiService: ApiService,
        private toastService: ToastService,
        public storageService: StorageService,
    ) {
    }

    /**
     * Set partner css-theme
     */
    private setTheme(): void {
        if (this.type === "partner") {
            const partner: User.IPartner = this.userService.data.partners
                .find((item: User.IPartner): boolean => {
                    return item.slug === this.section;
                });
            this.theme = "theme-" + (partner ? partner.style_path : "blue-grey");
        } else if (this.type === "admin") {
            this.theme = "theme-grey";
        }
        this.changeDetectorRef.markForCheck();
    }

    private parseUrl(url: string[]): void {
        this.state.component = url[0];
        this.state.action = url[1] || null;
        url.slice(2).forEach((segment: string, index: number, self: any): void => {
            if (segment) {
                this.state.params[segment] = self[index + 1];
                self[index + 1] = null;
            }
        });
    }

    private async getOrderRequestsCount(id: number): Promise<any> {
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["request"], {}, {
                data_structure: "count",
                order_id: id
            });
        if (data && data[0]) {
            this.requestsCount = data[0];
            this.changeDetectorRef.markForCheck();
        }
    }

    public openRequestForm(): void {
        this.modalService.open(RequestFormComponent);
    }

    public goToRequests(): void {
        this.router.navigate([
            this.type,
            this.section,
            "support-requests-all",
            "browse",
            "order",
            this.state.params.id
        ]);
    }

    public ngOnInit(): void {
        this.lockDrawer = Boolean(this.storageService.get("lockDrawer"));
        this.subscription = this.drawerService.drawerLockState.subscribe((state: boolean): void => {
            this.lockDrawer = state;
            this.changeDetectorRef.markForCheck();
        });

        this.pusherService.joinUserChannel();
        this.pusherService.joinBroadcastChannel();

        this.router.events.pipe(filter((event: any): boolean => event instanceof NavigationEnd))
            .subscribe((observer: NavigationEnd): void => {
                if (this.outlet.isActivated) {
                    this.onActivate();
                    this.setTheme();
                }
            });        
    }

    /**
     * Handle router outlet activate
     * @returns {void}
     */
    public onActivate(): void {
        const routeSection: string = this.outlet.activatedRoute.parent.snapshot.params.section;
        if (routeSection.indexOf("/") !== -1) {
            const [type, section]: string[] = routeSection.split("/");
            this.section = section;
            this.type = type;
        } else {
            this.section = this.type = routeSection;
        }

        this.setTheme();

        this.parseUrl(this.outlet.activatedRoute.parent.snapshot.params.url);

        if (this.state.component === "orders"
            && this.state.action === "view"
            && this.state.params.id) {
            this.getOrderRequestsCount(this.state.params.id);
        } else {
            this.requestsCount = 0;
            this.changeDetectorRef.markForCheck();
        }

        if (this.type === "partner") {
            this.pusherService.joinPartnerChannel(this.section);
        }
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();

        this.pusherService.leaveUserChannel();
        this.pusherService.leaveBroadcastChannel();
        if (this.type === "partner") {
            this.pusherService.leavePartnerChannel(this.section);
        }

        if (this.media_interval) {
            clearInterval(this.media_interval);
        }
    }

    public ngAfterViewInit(): void {

        this.visitor = {
            name: `${this.toolbar.name} (${this.toolbar.title})`,
            email: this.toolbar.email
        };

        this.liveChat = this.toolbar.partner ? this.toolbar.partner.properties.enable_livechat_support : true;
    }

    public setOrderRefIfExistsInUrl(): void {

        // if (this.userService.data.phone) {
        //     this.liveChatWidget.updateCustomVariables([
        //         {name: "User Phone", value: this.userService.data.phone}
        //     ]);
        // }
        //
        // if (this.router.url.includes("orders/view/ref/")) {
        //     const parts: string[] = this.router.url.split("/");
        //     this.liveChatWidget.updateCustomVariables([
        //         {name: "Order Ref", value: parts[parts.indexOf("ref") + 1]}
        //     ]);
        // }
    }
}
