import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {Modal} from "../../../../../services/modal.service";
import {Table} from "../../../../../../common/components/table/table.component";
import {ApiService} from "../../../../../../common/services/api.service";
import {Router} from "@angular/router";

@Component({
    selector: "section-order-list-modal",
    template: `
        <common-confirm></common-confirm>

        <h1 class="container-heading">Orders list</h1>
        <mat-card>
            <mat-card-content>
                <common-table *ngIf="listTable" [options]="listTable" (action)="handleListAction($event)">
                </common-table>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderListModalComponent implements OnInit {

    public modal: Modal.IModal;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.IOptions;

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        const actions: Table.Action.ISettings[] = [];
        actions.push({
            name: "select",
            title: "Select"
        });

        this.listTable = {
            actions,
            api: this.apiService.getUrl(["address", "orders"]) + "?data_structure=dataTables",
            columns: [
                {
                    data: "ref",
                    title: "Ref"
                },
                {
                    data: "main_address.address",
                    name: "MainAddress.address",
                    title: "Address",
                    orderable: false,
                },
                {
                    data: "main_address.city",
                    name: "MainAddress.city",
                    title: "City",
                    orderable: false,

                },
                {
                    data: "main_address.country",
                    name: "MainAddress.country",
                    title: "Country",
                    orderable: false,
                },
                {
                    data: "main_address.customer.name",
                    name: "MainAddress.customer.name",
                    title: "Customer",
                    orderable: false,
                },
                {
                    data: "main_address.contacts[0].first_name",
                    name: "MainAddress.contacts.*.first_name",
                    title: "Contact",
                    searchable: false
                }
            ],
            order: [],
            search: true,
            length: 5
        };
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Handle list row action
     * @param {Table.Action.IResult} action
     * @returns {Promise<any>}
     */
    public async handleListAction(action: Table.Action.IResult): Promise<any> {

        if (action.name === "select") {

            const value: any = {};

            if (action.data.main_address) {
                value.address = {
                    controlValue: action.data.main_address.id || null,
                    viewValue: action.data.main_address.address
                        + " " + action.data.main_address.city
                        + " " + action.data.main_address.country || null,
                    refValue: action.data.main_address.country_iso_2 || null
                };

                if (action.data.main_address.customer) {
                    value.customer = {
                        controlValue: action.data.main_address.customer.id || null,
                        viewValue: action.data.main_address.customer.name || null,
                        refValue: action.data.main_address.customer.name || null
                    };
                }

                if (action.data.main_address.contacts[0]) {
                    value.contact = {
                        controlValue: action.data.main_address.contacts[0].id || null,
                        viewValue: action.data.main_address.contacts[0].first_name || null
                    };
                }
            }


            this.modal.response.next({
                name: "value",
                value: value
            });
        }
    }

    public ngOnInit(): void {
        this.prepareList();
    }
}
