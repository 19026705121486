import {Injectable} from "@angular/core";
import {Api, ApiService} from "./api.service";
import {HelpersService} from "./helpers.service";

@Injectable()
export class ReportService {

    public constructor(private apiService: ApiService) {
    }

    public generateReport(key: string, body: any = null): Promise<Api.IResponse> {
        this.apiService.setHeaders({async: HelpersService.randomString()});
        return this.apiService.request(Api.EMethod.Post, ["report", key], body);
    }

}
