import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, ViewEncapsulation} from "@angular/core";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../../common/interfaces/wizard.interface";
import {ApiService} from "../../../../../../../../common/services/api.service";
import {Modal, ModalService} from "../../../../../../../services/modal.service";
import {BoxItemsListComponent} from "../../../index";
import {Table} from "../../../../../../../../common/interfaces/table.interface";

@Component({
    selector: "section-warehouse-procedures-wizard-scan-box-results",
    template: `

        <mat-card>
            <h1 class="color-accent">Scan results</h1>
            <common-table2 *ngIf="listTable" [settings]="listTable"></common-table2>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class WarehouseProceduresWizardScanBoxResultsComponent extends AbstractWizardStepComponent implements OnDestroy {

    private warehouseOrderId: number;

    private orderId: number;

    public listTable: Table.ISettings;

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private modalService: ModalService
    ) {
        super(changeDetectorRef);
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        this.listTable = {
            table_id: "sdoi46sdg",
            api: {
                url: ["box", "warehouse_order", "" + this.warehouseOrderId],
                query: {
                    with_location: true,
                    type: "inbound",
                    relations: [
                        "InboundWarehouseTransactions:id,quantity,order_id,box_id",
                        "WarehouseLocation:id,location",
                        "Partner:id,icon_path,slug",
                    ]
                }
            },
            columns: [
                {
                    data: "warehouse_location.location",
                    name: "WarehouseLocation.location",
                    title: "Location"
                },
                {
                    data: "ref",
                    title: "Box"
                },
                {
                    data: "inbound_warehouse_transactions_count",
                    title: "Items",
                    render: (row: any): string => {
                        let sum: number = 0;
                        for (const t of row.inbound_warehouse_transactions) {
                            if (t.order_id === this.orderId) {
                                sum += t.quantity;
                            }
                        }
                        return "" + sum;
                    },
                    searchable: false
                },
                {
                    data: "inbound_warehouse_transactions_count",
                    title: "",
                    render: (row: any): string => {
                        return "<button type='button' class='mat-mdc-raised-button mdc-button'>Show items</button>";
                    },
                    click: (row: any): void => {
                        this.openBox(row);
                    },
                    sortable: false,
                    searchable: false
                },
                {
                    data: "partner.icon_path",
                    title: "Partner",
                    render: (data: string): string => {
                        return "<img src='" + data + "' alt=''>";
                    },
                    sortable: false,
                    searchable: false
                }
            ],
        };

        this.changeDetectorRef.markForCheck();
    }

    /**
     * Show items in the box
     * @param box
     */
    private async openBox(box: any): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(BoxItemsListComponent,
            {
                box,
                orderId: this.orderId,
                itemsType: "inbound",
                modalWidth: 1000
            });
    }


    /**
     * Initialize step
     * @returns {Promise<any>}
     * @param data
     */
    public async init(data: Wizard.IData): Promise<any> {
        this.orderId = Number(data.orderId);
        this.warehouseOrderId = Number(data.warehouseOrderId);
        this.prepareList();

        this.result.emit({
            action: "result",
            value: true
        });
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

}
