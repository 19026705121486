import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from "@angular/core";
import {Router} from "@angular/router";
import {Table, TableComponent} from "../../../../../../common/components/table/table.component";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {ToastService} from "../../../../../../common/services/toast.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";


@Component({
    selector: "section-report-view",
    template: `
        <common-confirm></common-confirm>

        <h1 class="container-heading">Report {{ report ? report.name : ""}}</h1>
        <mat-card>
            <mat-card-content>
                <common-table *ngIf="listTable" [options]="listTable">
                    <div data-actions>
                        <button mat-mini-fab (click)="favorite()" [class.mat-accent]="report && report.is_favorite">
                            <mat-icon>star</mat-icon>
                        </button>
                    </div>
                </common-table>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportViewComponent implements Base.IComponent, OnInit {

    private columns: DataTables.ColumnSettings[];

    public report: { is_favorite: boolean, name: string };

    @ViewChild(TableComponent, {static: false})
    public tableRef: TableComponent;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.IOptions;

    public showExportButton: boolean = false;

    public readonly state: Base.IState;

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private spinnerService: SpinnerService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    /**
     * Get report params
     */
    private async getReportParams(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["report", this.state.params.key]);
        if (data) {
            this.report = data;
        }
        this.spinnerService.hide();
    }

    /**
     * Get datatable settings
     */
    private async getTableSettings(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["report", "datatable", this.state.params.id], {}, {
                data_structure: "datatables_columns"
            });
        if (data) {
            this.columns = data;
            this.prepareList();
        }
        this.spinnerService.hide();
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {

        this.listTable = {
            api: this.apiService.getUrl(["report", "datatable", this.state.params.id]) + "?data_structure=dataTables",
            actions: [],
            columns: this.columns,
            order: [],
            search: true,
            buttons: [
                "csv"
            ]
        };
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Add/Remove favorite report
     */
    public async favorite(): Promise<any> {
        this.spinnerService.show();
        const {message, type, data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["report", "favorite", this.state.params.id, this.report.is_favorite ? "remove" : "add"]);
        if (type as string === "success") {
            this.toastService.show(message, "success");
            this.report.is_favorite = data;
        }
        this.spinnerService.hide();
    }


    public ngOnInit(): void {
        this.getReportParams();
        this.getTableSettings();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "reports",
            actions: {
                "view": ["browse_reports"]
            }
        };
    }
}
