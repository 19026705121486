import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {FormControl} from "@angular/forms";
import * as _moment from "moment";
import {Moment} from "moment";
import {Api, ApiService} from "../../../../../../../../common/services/api.service";
import {MatDatepicker} from "@angular/material/datepicker";
import {Router} from "@angular/router";
import {Base} from "../../../../../../../../common/interfaces/base.interfaces";

const moment: any = _moment;

@Component({
    selector: "section-dashboard-template-default-shipments-tab",
    template: `
        <div class="padding-top-20">
            <mat-form-field (click)="stdf.open()">
                <mat-label>Month from</mat-label>
                <input matInput [ngxMatDatetimePicker]="stdf" [formControl]="dateFrom" readonly>
                <mat-icon matSuffix>today</mat-icon>
                <ngx-mat-datetime-picker
                    #stdf
                    [touchUi]="true"
                    [showSpinners]="true"
                    startView="year"
                    panelClass="month-picker"
                    (monthSelected)="chosenMonthHandler($event, stdf, dateFrom, true)">
                    <ng-template>
                        <span>Apply</span>
                    </ng-template>
                </ngx-mat-datetime-picker>
            </mat-form-field>
            &nbsp;
            &nbsp;
            <mat-form-field (click)="stdt.open()">
                <mat-label>Month to</mat-label>
                <input matInput [ngxMatDatetimePicker]="stdt" [formControl]="dateTo" readonly>
                <mat-icon matSuffix>event</mat-icon>
                <ngx-mat-datetime-picker
                    #stdt
                    [touchUi]="true"
                    [showSpinners]="true"
                    startView="year"
                    panelClass="month-picker"
                    (monthSelected)="chosenMonthHandler($event, stdt, dateTo, false)">
                    <ng-template>
                        <span>Apply</span>
                    </ng-template>
                </ngx-mat-datetime-picker>
            </mat-form-field>
        </div>

        <common-simple-spinner *ngIf="!shipments.data"></common-simple-spinner>

        <div style="display:flex; flex-flow: row;flex-wrap: wrap; justify-content: space-evenly" *ngIf="shipments.data">
            <div class="shipment-by-status" style="flex-direction: column; max-width:420px">

                <mat-card class="shipment-by-status" *ngIf="shipments.count && shipments.counts">
                    <h3>Total shipments</h3>
                    <div style="font-size: 30px; margin-bottom: 16px">
                        <b>{{shipments.count}}</b>
                    </div>
                    <table class="table">
                        <tr>
                            <td>Delivery rate</td>
                            <td>{{((shipments.counts['delivered'] || 0) / shipments.count * 100).toFixed(2)}}
                                %
                            </td>
                        </tr>
                        <tr>
                            <td>Valid tracking rate</td>
                            <td>{{((shipments.counts['delivered'] || 0) / shipments.count * 100).toFixed(2)}}
                                %
                            </td>
                        </tr>
                        <tr>
                            <td>Return to sender rate</td>
                            <td>{{((shipments.counts['failed attempt'] || 0) / shipments.count * 100).toFixed(2)}}
                                %
                            </td>
                        </tr>
                    </table>

                    <br/>
                    <mat-divider></mat-divider>
                    <br/>

                    <div *ngIf="this.shipments.byDays">
                        <common-line-chart [data]="this.shipments.byDays"
                                           title="TOTAL SHIPMENTS OVER TIME"
                                           [vAxis]="{viewWindowMode: 'explicit', viewWindow:{min: 0}}">
                        </common-line-chart>
                    </div>

                    <br/>
                    <mat-divider></mat-divider>
                    <br/>

                    <h4>SHIPMENT BY STATUS</h4>
                    <table class="table">
                        <tr>
                            <td><span class="circle" style="background: #254974"></span></td>
                            <td>Info received</td>
                            <td>{{shipments.counts['info received'] || 0}}</td>
                        </tr>
                        <tr>
                            <td><span class="circle" style="background: #4598d6"></span></td>
                            <td>In transit</td>
                            <td>{{shipments.counts['in transit'] || 0}}</td>
                        </tr>
                        <tr>
                            <td><span class="circle" style="background: #4baa67"></span></td>
                            <td>Delivered</td>
                            <td>{{shipments.counts['delivered'] || 0}}</td>
                        </tr>
                        <tr>
                            <td><span class="circle" style="background: #cccccc"></span></td>
                            <td>Pending</td>
                            <td>{{shipments.counts['pending'] || 0}}</td>
                        </tr>
                        <tr>
                            <td><span class="circle" style="background: #b74432"></span></td>
                            <td>Exception</td>
                            <td>{{shipments.counts['exception'] || 0}}</td>
                        </tr>
                        <tr>
                            <td><span class="circle" style="background: #e77f12"></span></td>
                            <td>Out for delivery</td>
                            <td>{{shipments.counts['out for delivery'] || 0}}</td>
                        </tr>
                        <tr>
                            <td><span class="circle" style="background: #9b59b6"></span></td>
                            <td>Failed attempt</td>
                            <td>{{shipments.counts['failed attempt'] || 0}}</td>
                        </tr>
                        <tr>
                            <td><span class="circle" style="background: #919eab"></span></td>
                            <td>Expired</td>
                            <td>25</td>
                        </tr>
                    </table>

                </mat-card>

                <mat-card class="shipment-by-status"
                          *ngIf="shipments.exceptions">
                    <h3 style="color: darkred">Shipments exception messages</h3>
                    <ng-template ngFor let-exception [ngForOf]="shipments.exceptions">
                                    <span style="cursor: pointer"
                                          (click)="navigateTo([state.section,'orders','view', 'ref', exception.ref])">
                                        <b>{{exception.ref}}</b>:<br/>
                                        {{exception.message}}
                                    </span>
                    </ng-template>
                </mat-card>
            </div>

            <div class="shipment-by-status" style="flex-direction: column;">
                <mat-card class="shipment-by-status" style="min-width:350px; margin-right: 0;"
                          *ngIf="shipments.couriers && shipments.addresses && shipments.couriers.exceptions
                          && shipments.addresses.exceptions">
                    <h3>Exception shipments</h3>
                    <div style="font-size: 30px"><b>{{shipments.counts['exception'] || 0}}</b>
                    </div>
                    <h4>TOP COURIERS</h4>
                    <table class="table">
                        <tr *ngFor="let courier of shipments.couriers.exceptions | slice:0:3; let i = index">
                            <td>{{courier[0]}}</td>
                            <td>{{courier[1]}}</td>
                            <td>{{courier[2].toFixed(2)}} %</td>
                        </tr>
                    </table>
                    <br/>
                    <mat-divider></mat-divider>
                    <br/>
                    <h4>TOP DESTINATION</h4>
                    <table class="table">
                        <tr *ngFor="let destination of shipments.addresses.exceptions | slice:0:3; let i = index">
                            <td>{{destination[0]}}</td>
                            <td>{{destination[1]}}</td>
                            <td>{{destination[2].toFixed(2)}} %</td>
                        </tr>
                    </table>
                </mat-card>
                <mat-card class="shipment-by-status" style="min-width:350px; margin-right: 0"
                          *ngIf="shipments.delivery && shipments.addresses && shipments.delivery.normal
                          && shipments.addresses.normal">
                    <h3>Shipments by transit time</h3>
                    <mat-divider></mat-divider>
                    <br/>
                    <h4>DELIVERED SHIPMENTS</h4>
                    <table class="table">
                        <tr *ngFor="let time of shipments.delivery.normal | keys">
                            <ng-template [ngIf]="time !== 'total'">
                                <td>{{time}} days</td>
                                <td>{{shipments.delivery.normal[time]}}</td>
                                <td>{{shipments.delivery.normal['total'] > 0
                                    ? (shipments.delivery.normal[time]
                                        / shipments.delivery.normal['total'] * 100).toFixed(2) : '0'}}
                                    %
                                </td>
                            </ng-template>
                        </tr>
                    </table>
                    <br/>
                    <mat-divider></mat-divider>
                    <br/>
                    <h4>NON-DELIVERED SHIPMENTS</h4>
                    <table class="table">
                        <tr *ngFor="let time of shipments.delivery.returned | keys">
                            <ng-template [ngIf]="time !== 'total'">
                                <td>{{time}} days</td>
                                <td>{{shipments.delivery.returned[time]}}</td>
                                <td>{{shipments.delivery.returned['total'] > 0
                                    ? (shipments.delivery.returned[time]
                                        / shipments.delivery.returned['total'] * 100).toFixed(2) : '0'}}
                                    %
                                </td>
                            </ng-template>
                        </tr>
                    </table>
                </mat-card>
            </div>
            <mat-card class="shipment-by-status" style="min-width:400px"
                      *ngIf="shipments.couriers && shipments.addresses && shipments.couriers.normal
                      && shipments.addresses.normal">
                <h3>Shipments by couriers and destination</h3>
                <mat-divider></mat-divider>
                <br/>
                <h4 style="margin-top: -4px">TOP COURIERS</h4>
                <table class="table">
                    <tr *ngFor="let courier of shipments.couriers.normal | slice:0:3; let i = index">
                        <td>{{courier[0]}}</td>
                        <td>{{courier[1]}}</td>
                        <td>{{courier[2].toFixed(2)}} %</td>
                    </tr>
                </table>
                <br/>
                <mat-divider></mat-divider>
                <br/>
                <h4>TOP DESTINATION</h4>
                <table class="table">
                    <tr *ngFor="let destination of shipments.addresses.normal | slice:0:3; let i = index">
                        <td>{{destination[0]}}</td>
                        <td>{{destination[1]}}</td>
                        <td>{{destination[2].toFixed(2)}} %</td>
                    </tr>
                </table>
            </mat-card>
            <mat-card class="shipment-by-status" style="min-width:400px" *ngIf="orders && orders.topCustomersCount">
                <h3>Top 10 customers</h3>
                <mat-divider></mat-divider>
                <br/>
                <table class="table">
                    <tr *ngFor="let customer of orders.topCustomersCount">
                        <td>{{customer.customer.name}}</td>
                        <td>{{customer.orders_count}}</td>
                    </tr>
                </table>
            </mat-card>
        </div>
    `,
    styleUrls: ["../default.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})

export class DashboardDefaultShipmentsTabComponent implements OnInit {

    @Input()
    public state: Base.IState;

    public showOrders: boolean = false;

    public dateFrom: FormControl = new FormControl(moment().subtract(1, "months").startOf("month"));

    public dateTo: FormControl = new FormControl(moment());

    public shipments: {
        data: any,
        counts: any,
        delivery: any,
        couriers: any,
        addresses: any,
        exceptions: any[],
        count: number,
        byDays: any
    } = {
        data: null,
        counts: {},
        delivery: {},
        couriers: [],
        addresses: [],
        exceptions: [],
        count: 0,
        byDays: []
    };

    public orders: any;

    public topCustomersCount: number;

    public constructor(
        private apiService: ApiService,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router
    ) {
    }

    private setDeliveryTime(time: string, shipment_returned: any): void {
        this.shipments.delivery.normal[time] += 1;
        this.shipments.delivery.returned[time] += shipment_returned;
    }

    private sortPercentageCounts(sArray: any, dataLength: number): any {
        const shipmentsCouriersArr: any = Object.keys(sArray).map(function (key: any): any {
            return [key, sArray[key], sArray[key] / dataLength * 100];
        });

        shipmentsCouriersArr.sort(function (a: any, b: any): any {
            return b[1] - a[1];
        });

        return shipmentsCouriersArr;
    }


    public async getShipments(): Promise<any> {
        this.shipments = {
            data: null,
            counts: {},
            delivery: {
                normal: {
                    "1-3": 0,
                    "4-7": 0,
                    "8-11": 0,
                    "12-15": 0,
                    "16+": 0,
                    "total": 0
                }
            },
            couriers: [],
            addresses: [],
            exceptions: [],
            count: 0,
            byDays: []
        };

        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["shipment"], {}, {
                date_from: this.dateFrom.value.format("DD/MM/YYYY"),
                date_to: this.dateTo.value.format("DD/MM/YYYY")
            });

        this.shipments.delivery.returned = {...this.shipments.delivery.normal};
        if (data) {
            this.shipments.data = data;
            this.shipments.count = data.length;

            const shipmentsByDaysObj: any = {};
            const shipmentsCouriers: any = {};
            const exceptionShipmentsCouriers: any = {};
            const shipmentsAddresses: any = {};
            const exceptionShipmentsAddresses: any = {};

            this.shipments.data.forEach((shipment: any): void => {

                const shipment_status: string = shipment.courier_transaction
                    ? shipment.courier_transaction.status : null;

                const shipment_returned: number = shipment.courier_transaction
                    ? parseInt(shipment.courier_transaction.return_to_sender) : 0;

                this.shipments.counts["returned"] = this.shipments.counts["returned"]
                    ? this.shipments.counts["returned"] + shipment_returned : shipment_returned;

                if (shipment.courier_transaction
                    && shipment_status
                    && shipment.courier_transaction.delivered_at) {
                    const time_difference: any = moment(shipment.courier_transaction.delivered_at)
                        .diff(moment(shipment.created_at), "days");
                    this.shipments.delivery.normal.total += 1;
                    this.shipments.delivery.returned.total += shipment_returned;
                    if (time_difference <= 3) {
                        this.setDeliveryTime("1-3", shipment_returned);
                    } else if (time_difference <= 7) {
                        this.setDeliveryTime("4-7", shipment_returned);
                    } else if (time_difference <= 11) {
                        this.setDeliveryTime("8-11", shipment_returned);
                    } else if (time_difference <= 15) {
                        this.setDeliveryTime("12-15", shipment_returned);
                    } else {
                        this.setDeliveryTime("16+", shipment_returned);
                    }
                }

                if (shipment.courier_transaction) {
                    if (this.shipments.counts[shipment_status]) {
                        this.shipments.counts[shipment_status] += 1;
                    } else {
                        this.shipments.counts[shipment_status] = 1;
                    }
                    if (shipment.courier_transaction.courier) {
                        if (shipmentsCouriers[shipment.courier_transaction.courier.display_name]) {
                            shipmentsCouriers[shipment.courier_transaction.courier.display_name] += 1;
                        } else {
                            shipmentsCouriers[shipment.courier_transaction.courier.display_name] = 1;
                        }
                        if (shipment_status === "exception") {
                            if (shipment.courier_transaction.messages) {
                                this.shipments.exceptions.push({
                                    ref: shipment.order_ref,
                                    message: shipment.courier_transaction.messages
                                });
                            }
                            if (exceptionShipmentsCouriers[shipment.courier_transaction.courier.display_name]) {
                                exceptionShipmentsCouriers[shipment.courier_transaction.courier.display_name] += 1;
                            } else {
                                exceptionShipmentsCouriers[shipment.courier_transaction.courier.display_name] = 1;
                            }
                        }
                    }
                }
                if (shipment.to_address) {
                    if (shipmentsAddresses[shipment.to_address.country]) {
                        shipmentsAddresses[shipment.to_address.country] += 1;
                    } else {
                        shipmentsAddresses[shipment.to_address.country] = 1;
                    }
                    if (shipment.courier_transaction && shipment_status === "exception") {
                        if (exceptionShipmentsAddresses[shipment.to_address.country]) {
                            exceptionShipmentsAddresses[shipment.to_address.country] += 1;
                        } else {
                            exceptionShipmentsAddresses[shipment.to_address.country] = 1;
                        }
                    }
                }
                const created_at: any = moment(shipment.created_at).format("DD MMM YYYY");
                shipmentsByDaysObj[created_at] =
                    shipmentsByDaysObj[created_at]
                        ? shipmentsByDaysObj[created_at] + 1 : 1;
            });
            const shipmentsByDaysArr: any = Object.keys(shipmentsByDaysObj).map(function (key: any): any {
                return [moment(key, "DD MMM YYYY").format("DD MMM"), shipmentsByDaysObj[key]];
            });

            this.shipments.couriers.normal = this.sortPercentageCounts(shipmentsCouriers, data.length);

            this.shipments.couriers.exceptions
                = this.sortPercentageCounts(exceptionShipmentsCouriers, data.length);

            this.shipments.addresses.normal = this.sortPercentageCounts(shipmentsAddresses, data.length);

            this.shipments.addresses.exceptions
                = this.sortPercentageCounts(exceptionShipmentsAddresses, data.length);

            this.shipments.byDays = [["Date", "Shipments"]].concat(shipmentsByDaysArr);
            this.changeDetectorRef.markForCheck();
        }
    }

    public async getOrdersCustomers(): Promise<any> {
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["dashboard", "customers"], {}, {
                date_from: this.dateFrom.value.format("DD/MM/YYYY"),
                date_to: this.dateTo.value.format("DD/MM/YYYY")
            });
        if (data) {
            this.topCustomersCount = data;
        }
    }


    public chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<any>,
                              formControl: FormControl, from: boolean
    ): any {
        const ctrlValue: any = formControl.value;

        ctrlValue.month(normalizedMonth.month());
        ctrlValue.year(normalizedMonth.year());

        if (from) {
            formControl.setValue(ctrlValue.startOf("month"));
        } else {
            formControl.setValue(ctrlValue.endOf("month"));
        }

        datepicker.close();

        this.getShipments();
        this.getOrdersCustomers();
    }


    public navigateTo(url: string[]): void {
        this.router.navigate(url);
    }

    public ngOnInit(): void {
        this.getShipments();
        this.getOrdersCustomers();
    }
}
