<common-confirm></common-confirm>

<h1 class="container-heading">Add scan</h1>

<form *ngIf="formGroup" [formGroup]="formGroup" (submit)="submitInBuffer()" autocomplete="off" novalidate>

    <div class="group flex">

        <div class="full margin-bottom-10" *ngIf="modal.params.location_name" style="font-size: 120%;">
            Location: <b>{{ modal.params.location_name }}</b>
        </div>

        <div *ngIf="modal.params.data.pallet_ref" class="full margin-bottom-10" style="font-size: 120%;">
            Pallet: <b>{{ modal.params.data.pallet_ref }}</b>
            &nbsp;
            <button type="button" (click)="makeScanOfFullPallet()" mat-raised-button color="accent">
                Scan entire pallet
            </button>
        </div>

        <div *ngIf="modal.params.data.box_ref" class="full" style="font-size: 120%;">
            Box: <b>{{ modal.params.data.box_ref }}</b>
            &nbsp;
            <button type="button" (click)="makeScanOfFullBox()" mat-raised-button color="accent">
                Scan entire box
            </button>
        </div>

        <div class="full margin-bottom-10" *ngIf="modal.params.data" style="font-size: 120%;">
            Item: <b>{{ modal.params.data.item }}</b>
        </div>

        <div *ngIf="requiredSerialInfo" class="text-error full">
            {{ requiredSerialInfo }}
        </div>

        <mat-form-field class="full" *ngIf="modal.params.data.has_serial">
            <mat-label [style.color]="modal.params.data.serial_required ? '#f44336' : 'inherit'">
                Serial numbers {{ modal.params.data.serial_required ? '*' : '' }}
            </mat-label>
            <mat-chip-grid #chipList>
                <mat-chip-row *ngFor="let serial of serials" [selectable]="false" [removable]="true"
                              (removed)="removeSerial(serial)">
                    {{ serial }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip-row>
                <input #serialsField matInput [matChipInputFor]="chipList"
                       [matChipInputAddOnBlur]="true"
                       (matChipInputTokenEnd)="addSerial($event)" (focus)="setCursorTo($event.target)">
            </mat-chip-grid>
        </mat-form-field>


        <div *ngIf="needQty" class="text-error full">
            Quantity needed: <b>{{ needQty }}</b>
        </div>

        <div class="full text-error" *ngIf="batchMaxQty >= 0">
            Quantity available by selected batch: {{ batchMaxQty }}
        </div>

        <div class="full text-error" *ngIf="maxQty >= 0">
            Max quantity: {{ maxQty }}
        </div>

        <mat-form-field class="full">
            <mat-label>Quantity</mat-label>
            <input matInput formControlName="quantity" [max]="maxQty" type="number"
                   required noscroll min="1" (focus)="setCursorTo($event.target)">
        </mat-form-field>

        <mat-form-field class="full" *ngIf="formGroup.value.type === 'inbound'">
            <mat-label>Inner boxes</mat-label>
            <input matInput formControlName="inner_boxes" type="number" noscroll (focus)="setCursorTo($event.target)">
        </mat-form-field>

        <mat-form-field class="half">
            <mat-label>Configurations</mat-label>
            <input matInput formControlName="configurations" (focus)="setCursorTo($event.target)">
        </mat-form-field>

        <mat-form-field class="half" *ngIf="modal.params.data.has_batch">
            <mat-label>Batch</mat-label>
            <input matInput formControlName="batch" (focus)="setCursorTo($event.target)">
        </mat-form-field>

        <mat-form-field class="half" *ngIf="modal.params.data.has_revision">
            <mat-label>Rev</mat-label>
            <input matInput formControlName="rev" (focus)="setCursorTo($event.target)">
        </mat-form-field>

        <mat-form-field class="half">
            <mat-label>Lot</mat-label>
            <input matInput formControlName="lot" (focus)="setCursorTo($event.target)">
        </mat-form-field>

        <mat-form-field class="full">
            <mat-label>Warehouse Ref</mat-label>
            <input matInput formControlName="wh_ref" (focus)="setCursorTo($event.target)">
        </mat-form-field>
    </div>

    <div class="actions">
        <button [disabled]="!formGroup.valid || submitInProgress"
                mat-raised-button color="primary" type="submit" class="main">
            Save
        </button>
    </div>
</form>
