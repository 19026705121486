import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {Order} from "../../../../../../../common/interfaces/order.interface";
import {Wizard} from "../../../../../../../common/interfaces/wizard.interface";
import {debounceTime, takeUntil} from "rxjs/operators";
import {AbstractSinglePageWizardStepComponent}
    from "../../../../../../../common/interfaces/wizard-single-page.interface";

@Component({
    selector: "section-order-wizard-instructions",
    templateUrl: "instructions.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderWizardInstructionsComponent extends AbstractSinglePageWizardStepComponent
    implements OnDestroy, OnInit, OnChanges {

    public service_level: Order.IServiceLevel;


    public formGroup: FormGroup = new FormGroup({
        warehouse_instructions: new FormControl(null),
        shipping_instructions: new FormControl(null),
        // confirm_on: new FormControl(null),
        order_remarks: new FormControl(null),
        deliver_on: new FormControl(null)
    });

    public confirm_on: string;

    public min: string = new Date().toDateString();

    public constructor(protected changeDetectorRef: ChangeDetectorRef) {
        super(changeDetectorRef);
    }

    protected setup(data: Wizard.IData): void {
        this.service_level = data.serviceLevel;

        this.result.next({
            action: "result",
            value: this.formGroup.value
        });

        this.formGroup.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(100))
            .subscribe((value: any): void => {
                this.result.next({
                    action: "data",
                    value
                });
            });

        // if (this.service_level.properties.confirm === "immediate") {
        //     if (data.confirm_on) {
        //         this.confirm_on = data.confirm_on;
        //     } else {
        //         this.confirm_on = moment().format("YYYY-MM-DD HH:mm:ss");
        //     }
        // }
        this.changeDetectorRef.markForCheck();
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public ngOnChanges(changes: SimpleChanges): void {

        return;
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}
