import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Api, ApiService} from "../../services/api.service";
import {ToastService} from "../../services/toast.service";
import {Modal, ModalService} from "../../../section/services/modal.service";
import {StorageService} from "../../services/storage.service";
import {UserService} from "../../services/user.service";
import {ENTER, SEMICOLON} from "@angular/cdk/keycodes";
import {MatChipInputEvent} from "@angular/material/chips";
import {SpinnerService} from "../../services/spinner.service";


@Component({
    selector: "common-message-form",
    templateUrl: "form.component.html",
    styleUrls: [
        "form.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class MessageFormComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    /**
     * Form group / group with controls
     * @type {FormGroup}
     */
    public formGroup: FormGroup = new FormGroup({
        message: new FormControl(null, [Validators.required]),
        order_remark_type_id: new FormControl(null, [Validators.required]),
        to: new FormControl([]),
        cc: new FormControl([]),
        bcc: new FormControl([]),
        creator_id: new FormControl(null),
        request_id: new FormControl(null),
    });

    public editorInitialValue: string = null;

    public modal: Modal.IModal;

    public remarkTypes: { name: string, value: any }[] = [];

    public separatorKeysCodes: number[] = [ENTER, SEMICOLON];

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private toastService: ToastService,
        private modalService: ModalService,
        private storageService: StorageService,
        private userService: UserService,
        private spinnerService: SpinnerService
    ) {
    }

    /**
     * Prepare form data
     */
    private prepareForm(): void {
        if (this.modal.params) {
            for (const param of Object.keys(this.modal.params)) {
                if (this.formGroup.value.hasOwnProperty(param)) {
                    this.formGroup.get(param).setValue(this.modal.params[param]);
                }
            }
        }

        this.formGroup.get("creator_id").setValue(this.userService.data.id);
        if (this.modal.params.to) {
            this.formGroup.get("to").setValue(this.modal.params.to.split(";").map((val: string): string => val.trim()));
        }
        if (this.modal.params.cc) {
            this.formGroup.get("cc")
                .setValue(this.modal.params.cc.split(";").map((val: string): string => val.trim()));
        }
        if (this.modal.params.bcc) {
            this.formGroup.get("bcc")
                .setValue(this.modal.params.bcc.split(";").map((val: string): string => val.trim()));
        }
        if (this.modal.params.message) {
            this.formGroup.get("message").setValue(this.modal.params.message);
            this.editorInitialValue = this.modal.params.message;
        }
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Get remark types
     * @returns {Promise<any>}
     */
    private async getRemarkTypes(): Promise<any> {
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["remark", "order", "types"]);
        if (data) {
            this.remarkTypes = data;
            this.formGroup.get("order_remark_type_id").setValue(this.modal.params.order_remark_type_id);
            this.changeDetectorRef.markForCheck();
        }
    }


    /**
     * Create new message
     */
    private async createMessage(): Promise<any> {
        this.spinnerService.show();
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            [this.modal.params.url, this.modal.params.item_id, "message"], this.formGroup.value);
        if (response && response.type as string === "success") {
            this.toastService.show(response.message, response.type as string);
            this.modal.response.emit({
                name: "submit"
            });
        }
        this.spinnerService.hide();
    }

    /**
     * Update messaage
     */
    private async updateMessage(): Promise<any> {
        this.spinnerService.show();
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            [this.modal.params.url, "message", this.modal.params.id, "message"], this.formGroup.value);
        if (response && response.type as string === "success") {
            this.toastService.show(response.message, response.type as string);
            this.modal.response.emit({
                name: "submit"
            });
        }
        this.spinnerService.hide();
    }

    /**
     * Add email to specified formControl
     * @param {MatChipInputEvent} event
     * @param {string} control_name
     */
    public addChip(event: MatChipInputEvent, control_name: string): void {
        const input: any = event.input;
        const value: string = event.value;

        if ((value || "").trim()) {
            const fcValue: string[] = this.formGroup.get(control_name).value;
            const value_array: string[] = value.trim().split(";");
            const values: string[] = [];
            values.push(...fcValue);
            for (let val of value_array) {
                val = val.trim().replace(/^.*[<(]|[)>].*/g, "").trim();
                if (/([\d\w]+\@[\w\d]+\.[\w\.]{2,})/.test(val) && (!fcValue || fcValue.indexOf(val) < 0)) {
                    values.push(val);
                }
            }
            this.formGroup.get(control_name).setValue(values);
        }
        if (input) {
            input.value = "";
        }
    }


    /**
     * Remove email from specified formControl
     * @param {string} email
     * @param {string} control_name
     */
    public removeChip(email: string, control_name: string): void {
        const index: number = this.formGroup.get(control_name).value.indexOf(email);

        if (index >= 0) {
            const fcValue: string[] = this.formGroup.get(control_name).value;
            fcValue.splice(index, 1);
            this.formGroup.get(control_name).setValue(fcValue);
        }
    }


    /**
     * Submit form
     */
    public async submit(): Promise<any> {
        if (this.modal.params.id) {
            this.updateMessage();
        } else {
            this.createMessage();
        }
    }

    /**
     * Close modal
     */
    public close(): void {
        this.modal.response.emit();
    }

    public ngOnInit(): void {
        if (this.modal.params.url === "request") {
            this.formGroup.addControl("solution", new FormControl(false));
        }
        this.prepareForm();
        this.getRemarkTypes();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
