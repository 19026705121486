<div style="max-width:600px; margin: 0 auto" class="padding-top-20">
    <h2>
        Select packages:
    </h2>

    <mat-card *ngFor="let pack of packages" class="margin-bottom-10">
        <div>
            <mat-checkbox value="{{pack.id}}" (change)="onCheckChange($event)">
                {{pack.name}}
                [
                {{pack.height}}x{{pack.width}}x{{pack.length}}{{pack.distance_unit}}
                , {{pack.weight > pack.gross_weight ? pack.weight : pack.gross_weight}}
                {{pack.weight > pack.gross_weight ? pack.mass_unit : pack.gross_unit}}
                ]
            </mat-checkbox>
            <mat-icon class="pull-right pointer" aria-hidden="true" (click)="editPackage(pack)">create</mat-icon>
        </div>
    </mat-card>

    <div class="actions">
        <button type="button" mat-raised-button (click)="newPackage()" color="accent">
            Create package
        </button>
    </div>

</div>

<div class="padding-top-20" *ngIf="needs_customs_info">
    <h2>
        Customs info:
    </h2>

    <div>
        <table class="table customs-table" [formGroup]="customs_form">
            <tr>
                <th>#</th>
                <th>Desc</th>
                <th>Weight</th>
                <th>Qty</th>
                <th>Unit value</th>
                <th>Origin</th>
                <th>Currency</th>
                <th>HS Code</th>
                <th></th>
            </tr>
            <ng-container formArrayName="customs_items">
                <ng-container *ngFor="let customs_item of customs_items.controls; let i = index">
                    <tr [formGroup]="customs_item">
                        <td class="text-center">
                            {{i + 1}}
                        </td>
                        <td>
                            <mat-form-field>
                                <input matInput formControlName="Description">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field>
                                <input matInput formControlName="Weight">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field>
                                <input matInput formControlName="Quantity">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field>
                                <input matInput formControlName="UnitValue">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field>
                                <mat-select formControlName="OriginCountry">
                                    <mat-option *ngFor="let country of countries" [value]="country.value">
                                        {{country.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field>
                                <mat-select formControlName="Currency">
                                    <mat-option value="USD">USD</mat-option>
                                    <mat-option value="EUR">EUR</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field>
                                <input matInput formControlName="HSCode">
                            </mat-form-field>
                        </td>
                        <td class="text-center">
                            <mat-icon (click)="deleteItem(i)" class="pointer color-warn">delete</mat-icon>
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
            <tr>
                <td colspan="9" class="text-right">
                    <button mat-raised-button type="button" color="accent" (click)="addItem()">
                        <mat-icon>add</mat-icon>
                        Add item
                    </button>
                </td>
            </tr>
        </table>
    </div>
</div>