import {ChangeDetectionStrategy, Component, ViewEncapsulation} from "@angular/core";

@Component({
    selector: "common-simple-spinner",
    template: `
        <mat-spinner [diameter]="50" mode="indeterminate" class="spinner"></mat-spinner>
        <div class="text-center">
            <ng-content></ng-content>
        </div>
    `,
    styleUrls: [
        "spinner.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class SimpleSpinnerComponent {
}
