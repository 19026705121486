import {ChangeDetectionStrategy, ChangeDetectorRef, Component,
    EventEmitter, OnDestroy, OnInit, ViewChild
} from "@angular/core";
import {Router} from "@angular/router";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {UserService} from "../../../../../../common/services/user.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {CommonFormComponent} from "../../../../../../common/components/form";
import {ModalService} from "../../../../../services/modal.service";
import {Table2Component} from "../../../../../../common/components/table2";
import {Table} from "../../../../../../common/interfaces/table.interface";
import { FormGroup, FormControl } from "@angular/forms";
import { takeUntil } from "rxjs/operators";


@Component({
    selector: "section-user-list",
    templateUrl: "list.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class UserListComponent implements Base.IComponent, OnInit, OnDestroy {
    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.ISettings;

    public readonly state: Base.IState;

    public filters: FormGroup = new FormGroup({
        role_id: new FormControl(null)
    });

    public roles: any[] = [];

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private userService: UserService,
        private changeDetectorRef: ChangeDetectorRef,
        private modalService: ModalService
    ) {
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        const actions: Table.IAction[] = [];
        if (this.userService.validatePermissions("read_users")) {
            actions.push({
                name: "view",
                title: "View",
                click: (row: any): void => {
                    this.router.navigate([this.state.section, this.state.component, "view", "id", row.id]);
                }
            });
        }

        this.listTable = {
            table_id: "ertaezdfy",
            actions,
            api: {
                url: ["admin", "users"],
                query: {
                    relations: ["roles"],
                    counts: [
                        "Partners",
                        "Threepls"
                    ],
                    ...this.filters.value
                },
                version: 3
            },
            columns: [
                {
                    data: "id",
                    title: "ID"
                },
                {
                    data: "name",
                    title: "Full name"
                },
                {
                    data: "email",
                    title: "E-mail"
                },
                {
                    data: "phone",
                    title: "Phone"
                },
                {
                    data: "roles.display_name",
                    name: "Roles.display_name",
                    title: "Roles",
                    render: (row: any): string => {
                        return row.roles.map((role: any) =>
                            role.display_name
                        ).join(", ");
                    }
                },
                {
                    data: "id",
                    title: "Partners / Threepls",
                    render: (row: any): string => {
                        return row.partners_count + " / " + row.threepls_count;
                    },
                    searchable: false,
                    sortable: false
                },
                {
                    data: "created_at",
                    title: "Creation date"
                }
            ],
            search_default: [
                "id", "name", "email"
            ]
        };
        this.changeDetectorRef.markForCheck();
    }

    private async getRoles(): Promise<any> {
        const result = await this.apiService.request(Api.EMethod.Get,
            ["role"], {},
            {
                data_structure: "select"
            }
        );
        if (result?.data) {
            this.roles = result.data;
        }
    }

    public async addUser(): Promise<any> {
        const response: any = await this.modalService.open(CommonFormComponent, {
            formConfig: {
                id: 0,
                name: "Add User",
                description: "Add a new user into the system",
                fields: [
                    {
                        label: "Full name",
                        name: "name",
                        size: "full",
                        type: "input",
                        required: true,
                    },
                    {
                        label: "Nickname",
                        name: "nickname",
                        size: "full",
                        type: "input",
                        required: true,
                    },
                    {
                        label: "Email",
                        name: "email",
                        size: "full",
                        type: "input",
                        required: true,
                    },
                    {
                        label: "Password",
                        name: "password",
                        size: "full",
                        type: "password",
                        required: true,
                    },
                    {
                        label: "Repeat password",
                        name: "password_confirmation",
                        size: "full",
                        type: "password",
                        required: true,
                    }
                ]
            },
            submitUrl: ["user"],
            method: Api.EMethod.Post
        });
        if (response) {
            this.tableRef.reload();
        }
    }

    public ngOnInit(): void {
        this.getRoles();
        this.prepareList();

        this.filters.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any): void => {
            this.prepareList();
        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "user",
            actions: {
                "browse": ["browse_users"]
            }
        };
    }

}
