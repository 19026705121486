import {ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Modal} from "../../../../../../../services/modal.service";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: "section-warehouse-procedures-inventory-count-item-form",
    templateUrl: "form.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class InventoryCountItemFormComponent implements OnDestroy, OnInit {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    public modal: Modal.IModal;

    public itemForm: FormGroup = new FormGroup({
        type: new FormControl("item", [Validators.required]),
        warehouse_location_id: new FormControl(null),
        ref: new FormControl(null),
        item: new FormControl(null, [Validators.required]),
        serial: new FormControl(null),
        configurations: new FormControl(null),
        batch: new FormControl(null),
        rev: new FormControl(null),
        bin: new FormControl(null),
        lot: new FormControl(null),
        quantity: new FormControl(null, [Validators.required, Validators.min(1)]),
        notes: new FormControl(null, [Validators.required])
    });

    public constructor() {
    }

    /**
     * Add new item in specified location
     */
    public async submit(): Promise<any> {
        this.modal.response.emit({
            name: "submit",
            value: this.itemForm.value
        });
    }

    public ngOnInit(): void {
        if (this.modal.params.data) {
            this.itemForm.patchValue(this.modal.params.data);
        }

        if(this.modal.params.hide_notes){
            this.itemForm.get("notes").clearValidators();
            this.itemForm.get("notes").updateValueAndValidity();
        }

        this.itemForm.get("type").valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: string) => {
            this.itemForm.get("ref").reset();
            this.itemForm.get("item").reset();
            this.itemForm.get("quantity").reset();

            if (value === "item") {
                this.itemForm.get("ref").clearValidators();
                this.itemForm.get("item").setValidators([Validators.required]);
                this.itemForm.get("quantity").setValidators([Validators.required]);
            } else {
                this.itemForm.get("ref").setValidators([Validators.required]);
                this.itemForm.get("item").clearValidators();
                this.itemForm.get("quantity").clearValidators();
            }
            this.itemForm.get("ref").updateValueAndValidity();
            this.itemForm.get("item").updateValueAndValidity();
            this.itemForm.get("quantity").updateValueAndValidity();
        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
