<h1 class="container-heading">Edit milestone [{{data.order_ref}}]</h1>

<mat-card>
    <mat-card-content>

        <form *ngIf="formGroup" [formGroup]="formGroup" (submit)="handleFormSubmit()" autocomplete="off" novalidate>

            <div class="group flex">

                <mat-form-field class="full">
                    <mat-label>Notes</mat-label>
                    <textarea matInput formControlName="notes" required></textarea>
                </mat-form-field>

                <div class="half">
                    <common-form-date
                            label="Complete date"
                            [value]="formGroup.value.complete_date"
                            (valueChangeMoment)="formGroup.get('complete_date').setValue($event)"
                    ></common-form-date>
                </div>

                <div class="half">
                    <common-form-date
                            label="Estimated date"
                            [value]="formGroup.value.estimated_date"
                            (valueChangeMoment)="formGroup.get('estimated_date').setValue($event)"
                    ></common-form-date>
                </div>

                <mat-form-field class="full">
                    <mat-label>Reason</mat-label>
<mat-select  formControlName="reason"
                                [required]="formGroup.value.complete_date">
                        <mat-option *ngFor="let reason of reasons" [value]="reason.name">
                            {{ reason.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            </div>

            <div class="actions">
                <button [disabled]="!formGroup.valid" mat-raised-button color="primary" type="submit" class="main">
                    Save
                </button>
            </div>

        </form>

    </mat-card-content>
</mat-card>
