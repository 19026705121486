import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from "@angular/core";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {CustomerFormComponent} from "../index";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {ToastService} from "../../../../../../common/services/toast.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {Table2Component, TableCellRowValue, TableCellSubscriber} from "../../../../../../common/components/table2";
import {MatSlideToggle} from "@angular/material/slide-toggle";
import {UserService} from "../../../../../../common/services/user.service";
import {FormControl} from "@angular/forms";
import {StorageService} from "../../../../../../common/services/storage.service";
import {ModalComponent} from "src/modules/common/components/modal/modal.component";
import {Contact} from "../../../../../../common/interfaces/contact.interface";
import {Router} from "@angular/router";

@Component({
    selector: "section-customer-list",
    template: `
        <common-confirm></common-confirm>
        <h1 class="container-heading">Customers list</h1>
        <mat-card>
            <mat-card-content>
                <common-table2 *ngIf="listTable" [settings]="listTable">
                    <div row1>
                        <button mat-raised-button color="primary" class="main" (click)="showAddForm()">Add</button>
                        <a *ngIf="!modal" mat-raised-button color="accent" class="main"
                           [routerLink]="['/partner', state.section.split('/')[1],
                                 'csv-import', 'wizard', 'type', 'customer']">
                            Import
                        </a>
                        <span *ngIf="!modal" style="margin-right: 10px">
                            <mat-checkbox [formControl]="onlyActive"> Show only active</mat-checkbox>
                        </span>
                    </div>
                </common-table2>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerListComponent implements OnInit, Base.IComponent {

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.ISettings;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    public modal: Modal.IModal;

    public readonly state: Base.IState;

    public onlyActive: FormControl =
        new FormControl(true);

    public constructor(
        private apiService: ApiService,
        private modalService: ModalService,
        private toastService: ToastService,
        private spinnerService: SpinnerService,
        private storageService: StorageService,
        private userService: UserService,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router
    ) {
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        const actions: Table.IAction[] = [];
        const columns: Table.ICol[] = [
            {
                data: "name",
                title: "Name"
            },
            {
                data: "addresses.country",
                name: "Addresses.Country",
                title: "Country",
                sortable: false,
                render: (row: any): string => {
                    if (!row.addresses || row.addresses.length == 0) {
                        return "";
                    }
                    if (this.hasSeveralCountries(row.addresses)) {
                        return "<button type='button' class='mat-mdc-raised-button mdc-button button-200'>" +
                            "Show countries</button>";
                    }
                    return row.addresses[0].country;
                },
                click: (row: any): void => {
                    if (!this.hasSeveralCountries(row.addresses)) {
                        return;
                    }
                    this.showCountries(row.addresses);
                }
            },
            {
                data: "vat",
                title: "VAT"
            },
            {
                data: "ref",
                title: "Ref"
            },
            {
                data: "created_at",
                title: "Created at",
                searchable: false
            },
            {
                data: "author.name",
                name: "Author.name",
                title: "Created/Changed by"
            }
        ];

        if (this.modal && this.modal.params) {
            if (this.modal?.params?.state) {
                actions.push({
                    name: "edit",
                    title: "Edit",
                    click: (row: any): void => {
                        this.showEditForm(row.id);
                    }
                });
            }
            actions.push({
                name: "select",
                title: "Select",
                click: (row: any): void => {
                    this.modal.response.next({
                        name: "value",
                        value: {
                            controlValue: row.id,
                            viewValue: row.name,
                            refValue: row.name
                        }
                    });
                }
            });
        } else {
            actions.push({
                name: "view",
                title: "View",
                click: (row: any): void => {
                    this.showEditForm(row.id);
                }
            });
            actions.push({
                name: "location_on",
                title: "Show addresses",
                click: (row: any): void => {
                    this.showAddresses(row.id);
                }
            });
            if (this.userService.validatePermissions("delete_customers")) {
                actions.push({
                    name: "delete",
                    title: "Delete",
                    click: (row: any): void => {
                        this.delete(row.id);
                    }
                });
            }

            if (this.userService.validatePermissions("edit_customers")) {
                columns.splice(0, 0, {
                    data: "is_active",
                    title: "Active",
                    factory: {
                        component: MatSlideToggle,
                        properties: {
                            color: "primary",
                            checked: new TableCellRowValue("is_active"),
                            change: new TableCellSubscriber((row: any, event: any) => {
                                this.spinnerService.show();
                                this.apiService.request(Api.EMethod.Put,
                                    ["customer", "" + row.id], {is_active: event.checked}).then(() => {
                                    this.spinnerService.hide();
                                });
                            })
                        }
                    }
                });
            }
        }


        this.listTable = {
            table_id: "byilmrg7547",
            actions,
            api: {
                url: ["customer"],
                query: {
                    only_active: this.onlyActive.value || !!this.modal
                }
            },
            columns,
            search_in: {
                "Name": "name",
                "Country": "Addresses.Country",
                "VAT": "vat",
                "Ref": "ref",
                "Created/Changed by": "Author.name"
            }
        };
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Show customer form
     * @returns {void}
     */
    private async showEditForm(id: number): Promise<any> {
        await this.modalService.open(CustomerFormComponent, {
            id,
            canDelete: true,
            state: this.modal?.params?.state ?? this.state
        });
        this.tableRef.reload();
    }

    private showAddresses(customer_id: number): void {
        this.router.navigate([
            this.state.section,
            "addresses",
            "list",
            "customer",
            customer_id
        ]);
    }

    private getDistinctCountriesNames(items: Contact.IAddress[]): string[] {
        let countriesNames: string[] = items.filter((address: Contact.IAddress) => address.is_visible)
            .map((item: Contact.IAddress) => item.country);
        return Array.from(new Set(countriesNames));
    }

    /**
     * Delete customer
     * @param id
     */
    public async delete(id: number): Promise<any> {
        if (await this.confirmRef.confirm("Are you sure want to delete this customer?")) {
            this.spinnerService.show();
            const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
                ["customer", "" + id]);
            if (type as string === "success") {
                this.toastService.show(message, "success");
                this.tableRef.reload();
            }
            this.spinnerService.hide();
        }
    }

    /**
     * Show new customer form
     * @returns {void}
     */
    public async showAddForm(): Promise<any> {
        if (this.modal) {
            this.modal.response.next({
                name: "form"
            });
        } else {
            await this.modalService.open(CustomerFormComponent, {
                canEdit: true,
                canDelete: true,
                state: this.state
            });
            this.tableRef.reload();
        }
    }

    public async showCountries(items: any): Promise<any> {
        const distinctCountriesNames = this.getDistinctCountriesNames(items);
        await this.modalService.open(ModalComponent, {
            title: "Countries",
            template: distinctCountriesNames.join("<br>")
        });
    }

    public hasSeveralCountries(items: any): boolean {
        const distinctCountriesNames = this.getDistinctCountriesNames(items);
        if (!distinctCountriesNames || distinctCountriesNames.length < 2) {
            return false;
        }
        return true;
    }


    public ngOnInit(): void {
        this.prepareList();

        this.onlyActive.valueChanges.subscribe((value: boolean): void => {
            this.storageService.set("customers_list_only_active", value);
            this.tableRef.reload({
                url: ["customer"],
                query: {
                    only_active: this.onlyActive.value || !!this.modal
                }
            });
        });
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "customers",
            actions: {
                "browse": "*"
            }
        };
    }

}
