<form *ngIf="formGroup" [formGroup]="formGroup" (submit)="handleFormSubmit()" autocomplete="off">

    <div class="group custom flex">
        <mat-form-field class="full">
            <mat-label>Full name</mat-label>
<input matInput formControlName="full_name"  required>
        </mat-form-field>
        <mat-form-field class="full">
            <mat-label>Display name</mat-label>
<input matInput formControlName="display_name"  required>
        </mat-form-field>
        <mat-form-field class="full">
            <mat-label>Slack webhook url</mat-label>
<input matInput formControlName="slack_webhook_url" >
        </mat-form-field>
        <div class="full">
            <small>Optional - please leave blank if you're not sure</small>
        </div>

        <div class="full margin-bottom-20">
            <button type="button" mat-raised-button color="accent" (click)="uploadLogoOrIcon('logo')"
                    class="vertical-middle">
                Upload logo
            </button>
            <img *ngIf="formGroup.value.logo_path" class="logo-image" [src]="formGroup.value.logo_path" alt="">
            <div>
                <small>
                    Image must be in png or gif format with transparent background
                </small>
            </div>
        </div>

        <div class="full">
            <button type="button" mat-raised-button color="accent" (click)="uploadLogoOrIcon('icon')"
                    class="vertical-middle">
                Upload icon
            </button>
            <img *ngIf="formGroup.value.icon_path" class="icon-image" [src]="formGroup.value.icon_path" alt="">
            <div>
                <small>
                    Image must be in png or gif format with transparent background
                </small>
            </div>
        </div>


    </div>

    <div class="group">
        <div>
            <mat-checkbox [formControl]="contactDetails">Contact details</mat-checkbox>
        </div>
    </div>

    <ng-template [ngIf]="contactDetails.value">
        <h3 class="mat-h3">Address</h3>
        <div formGroupName="address" class="group address flex">
            <common-autocomplete [options]="countries" placeholder="Country" [allowFreeInput]="false"
                                 class="full" (optionSelected)="onCountrySelected($event)"
                                 [errorText]="'Select country'" [required]="true"
                                 [image]="{path:'assets/images/flags',ext:'svg'}"
                                 [value]="formGroup ? formGroup.value.address.country : null"></common-autocomplete>

            <mat-form-field class="full">
                <mat-label>Address</mat-label>
                <input matInput formControlName="address" required>
            </mat-form-field>
            <mat-form-field class="full">
                <mat-label>Additional address information</mat-label>
<input matInput formControlName="address2" >
            </mat-form-field>
            <mat-form-field>
                <mat-label>City</mat-label>
<input matInput formControlName="city"  required>
            </mat-form-field>
            <mat-form-field>
                <mat-label>ZIP code</mat-label>
<input matInput formControlName="zip"  required>
            </mat-form-field>
            <mat-form-field>
                <mat-label>State or district (if available)</mat-label>
<input matInput formControlName="state" >
            </mat-form-field>
        </div>

        <h3 class="mat-h3">Contact / Contact person</h3>
        <div formGroupName="contact" class="group contact flex">
            <mat-form-field>
                <mat-label>First name</mat-label>
<input matInput formControlName="first_name"  required>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Last name</mat-label>
<input matInput formControlName="last_name"  required>
            </mat-form-field>
            <mat-form-field class="full">
                <mat-label>Email address</mat-label>
                <input matInput formControlName="email" required>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Phone number (with local country code)</mat-label>
<input matInput formControlName="phone"  required>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Fax</mat-label>
<input matInput formControlName="fax" >
            </mat-form-field>
        </div>
    </ng-template>

    <div class="group custom flex">
        <mat-form-field class="full">
            <mat-label>Theme</mat-label>
<mat-select  formControlName="style_path">
                <mat-option value="grey">Grey</mat-option>
                <mat-option value="purple">Purple</mat-option>
                <mat-option value="blue">Blue</mat-option>
                <mat-option value="light-blue">Light blue</mat-option>
                <mat-option value="teal">Teal</mat-option>
                <mat-option value="green">Green</mat-option>
                <mat-option value="brown">Brown</mat-option>
                <mat-option value="blue-grey">Blue grey</mat-option>
            </mat-select>
        </mat-form-field>
    </div>


    <div class="group custom flex">
        <mat-form-field class="full">
            <mat-label>Assigned users</mat-label>
<mat-select  multiple formControlName="users">
                <mat-option>
                    <ngx-mat-select-search
                            [formControl]="assignedSearchInput"
                            [placeholderLabel]="'Find user'"
                            [clearSearchInput]="true"
                            [noEntriesFoundLabel]="'no matching users found'">
                    </ngx-mat-select-search>
                </mat-option>

                <mat-option *ngFor="let user of users_filtered | async" [value]="user.value">
                    {{user.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="group custom flex">
        <mat-form-field class="full">
            <mat-label>Supervising manager</mat-label>
<mat-select  formControlName="in_charge_manager_id">
                <mat-option>
                    <ngx-mat-select-search
                            [formControl]="assignedSearchInput"
                            [placeholderLabel]="'Find user'"
                            [clearSearchInput]="true"
                            [noEntriesFoundLabel]="'no matching users found'">
                    </ngx-mat-select-search>
                </mat-option>

                <mat-option *ngFor="let user of users_filtered | async" [value]="user.value">
                    {{user.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>


    <div class="actions">
        <button [disabled]="!formGroup.valid  || !formGroup.dirty" mat-raised-button color="primary"
                type="submit" class="main">
            Save
        </button>
    </div>

</form>
