<h1 class="container-heading">Courier services conversions</h1>
<mat-card>
    <mat-card-content>

        <common-confirm></common-confirm>

        <mat-form-field class="pull-right">
            <mat-label>Search</mat-label>
            <input matInput type="search" [formControl]="conversionSearch">
        </mat-form-field>
        <h3 class="mat-h3">Conversions</h3>
        <div class="clear"></div>
        <div *ngIf="conversions && conversions?.length < 1">
            No any data
            <br>
            <br>
        </div>
        <mat-accordion *ngIf="conversions && conversions?.length > 0">
            <ng-template ngFor let-item let-i="index" [ngForOf]="conversions">
                <mat-expansion-panel *ngIf="item" (opened)="itemForm.patchValue(item)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <b>{{item.name}}</b>
                            &nbsp;|&nbsp;
                            <small>
                                {{item.courier.display_name}} /  {{item.courier_service.service_name}}
                            </small>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <form [formGroup]="itemForm" autocomplete="off" (submit)="submitUpdate()">
                        <div class="group flex">
                            <mat-form-field class="full">
                                <mat-label>Name</mat-label>
                                <input matInput formControlName="name" required>
                            </mat-form-field>

                            <div class="full">
                                <common-form-select
                                        label="Courier"
                                        [options]="couriers"
                                        [search]="true"
                                        [required]="true"
                                        [value]="itemForm.value.courier_id"
                                        (valueChange)="itemForm.get('courier_id').setValue($event)">
                                </common-form-select>
                            </div>

                            <div class="full">
                                <common-form-select
                                        label="Courier service"
                                        [options]="couriers_services.item"
                                        [search]="true"
                                        [required]="true"
                                        [value]="itemForm.value.courier_service_id"
                                        (valueChange)="itemForm.get('courier_service_id').setValue($event)">
                                </common-form-select>
                            </div>

                            <div class="full">
                                <common-form-select
                                        label="Direction"
                                        [options]="directionOptions"
                                        [required]="true"
                                        [value]="itemForm.value.direction"
                                        (valueChange)="itemForm.get('direction').setValue($event)">
                                </common-form-select>
                            </div>

                            <div class="full">
                                <common-autocomplete [options]="countries" placeholder="Country" [allowFreeInput]="false"
                                     class="margin-right-10" style="width: 250px"
                                     (optionSelected)="itemForm.get('country').setValue($event.value)"
                                     [errorText]="'Select country'" [required]="false"
                                     [image]="{path:'assets/images/flags',ext:'svg'}"
                                     [value]="itemForm.get('country').value"></common-autocomplete>
                            </div>
                        </div>
                        <div class="actions">
                            <button [disabled]="!itemForm.valid" mat-raised-button color="primary" type="submit">
                                Update
                            </button>

                            <button mat-raised-button color="warn" class="delete-button" type="button"
                                    (click)="submitDelete()">
                                Delete
                            </button>
                        </div>
                    </form>

                </mat-expansion-panel>
            </ng-template>
            <common-pagination [data]="conversions"
                               pid="iYKs40b7G"
                               (goToPage)="getItems($event.page,$event.per_page, conversionSearch.value)">
            </common-pagination>
        </mat-accordion>

        <br>
        <h3 class="mat-h3">Add new conversion</h3>
        <form *ngIf="newForm" [formGroup]="newForm" autocomplete="off" (submit)="submitNew()">
            <div class="group flex">
                <mat-form-field class="full">
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="name"
                           required>
                </mat-form-field>

                <div class="full">
                    <common-form-select
                            label="Courier"
                            [options]="couriers"
                            [search]="true"
                            [required]="true"
                            [value]="newForm.value.courier_id"
                            (valueChange)="newForm.get('courier_id').setValue($event)">
                    </common-form-select>
                </div>


                <div class="full">
                    <common-form-select
                            label="Courier service"
                            [options]="couriers_services.new"
                            [search]="true"
                            [required]="true"
                            [value]="newForm.value.courier_service_id"
                            (valueChange)="newForm.get('courier_service_id').setValue($event)">
                    </common-form-select>
                </div>

                <div class="full">
                    <common-form-select
                            label="Direction"
                            [options]="directionOptions"
                            [required]="true"
                            [value]="newForm.value.direction"
                            (valueChange)="newForm.get('direction').setValue($event)">
                    </common-form-select>
                </div>

                <div class="full">
                    <common-autocomplete [options]="countries" placeholder="Country" [allowFreeInput]="false"
                         class="margin-right-10" style="width: 250px"
                         (optionSelected)="newForm.get('country').setValue($event.value)"
                         [errorText]="'Select country'" [required]="false"
                         [image]="{path:'assets/images/flags',ext:'svg'}"
                         [value]="newForm.get('country').value"></common-autocomplete>
                </div>
            </div>

            <div class="actions">
                <button [disabled]="!newForm.valid" mat-raised-button color="primary" type="submit">Submit</button>
                <button mat-raised-button type="button" (click)="newForm.reset()">Reset</button>
            </div>

        </form>

    </mat-card-content>
</mat-card>

