import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {Modal} from "../../../../../../../services/modal.service";

@Component({
    selector: "section-warehouse-procedures-wizard-lockdown",
    template: `
        <h1 class="container-heading">This order was set on hold</h1>

        <mat-card>
            <div style="min-height: 100px">
                <ng-template ngFor let-lock [ngForOf]="modal.params.lockdown">
                    <div class="margin-bottom-10" style="display:block" [innerHTML]="lock.message">
                    </div>
                    <div class="text-right margin-bottom-20" *ngIf="lock.user">
                        <i>{{lock.user.name}} ({{lock.user.email}})</i>
                    </div>
                    <mat-divider class="margin-bottom-10" [inset]="true"></mat-divider>
                </ng-template>
            </div>
            <div class="text-center" *ngIf="can_be_skipped">
                <button mat-raised-button color="warn" type="button" (click)="skip()">Skip</button>
            </div>
        </mat-card>
    `,

    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class WarehouseProceduresWizardLockdownComponent implements OnInit {

    public can_be_skipped: boolean = true;

    public modal: Modal.IModal;

    public constructor(private changeDetectorRef: ChangeDetectorRef) {
    }

    public skip(): void {
        this.modal.response.emit({
            name: "skip"
        });
    }

    public ngOnInit(): void {
        for (const lock of this.modal.params.lockdown) {
            if (!lock.can_be_skipped) {
                this.can_be_skipped = false;
                this.changeDetectorRef.markForCheck();
            }
        }
    }
}
