import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation
} from "@angular/core";
import {takeUntil} from "rxjs/operators";
import {HelpersService} from "../../../services/helpers.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
    selector: "common-form-date-period",
    template: `
        <div class="group flex" [formGroup]="formGroup">
            <mat-form-field class="full">
                <mat-label>Period</mat-label>
                <mat-select formControlName="period">
                    <mat-option *ngIf="!required" [value]="null">None</mat-option>
                    <mat-option value="day">Day</mat-option>
                    <mat-option value="week">Week</mat-option>
                    <mat-option value="month">Previous Month</mat-option>
                    <mat-option value="custom">Custom</mat-option>
                </mat-select>
            </mat-form-field>
            <ng-container *ngIf="formGroup.value.period === 'custom'">
                <mat-form-field (click)="startPicker.open()" class="half">
                    <mat-label>Date from</mat-label>
                    <input matInput
                           [ngxMatDatetimePicker]="startPicker"
                           formControlName="start"
                           [required]="required"
                           readonly>
                    <mat-icon matSuffix>today</mat-icon>
                    <ngx-mat-datetime-picker #startPicker [touchUi]="true" [showSpinners]="true" [hideTime]="true"
                                             [defaultTime]="[0,0,0]">
                        <ng-template>
                            <span>Apply</span>
                        </ng-template>
                    </ngx-mat-datetime-picker>
                </mat-form-field>

                <mat-form-field (click)="endPicker.open()" class="half">
                    <mat-label>Date to</mat-label>
                    <input matInput
                           [ngxMatDatetimePicker]="endPicker"
                           formControlName="end"
                           [required]="required"
                           [min]="formGroup.value.start"
                           readonly>
                    <mat-icon matSuffix>event</mat-icon>
                    <ngx-mat-datetime-picker #endPicker [touchUi]="true" [showSpinners]="true" [hideTime]="true"
                                             [defaultTime]="[23,59,59]">
                        <ng-template>
                            <span>Apply</span>
                        </ng-template>
                    </ngx-mat-datetime-picker>
                </mat-form-field>
            </ng-container>
        </div>
    `,
    styles: [
        `common-form-date-period .group {
            margin-bottom: 0 !important;
        }`
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class CommonFormDatePeriodComponent implements OnInit, OnDestroy {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    public formGroup: FormGroup = new FormGroup({
        period: new FormControl(null),
        start: new FormControl({value: null, disabled: true}, Validators.required),
        end: new FormControl({value: null, disabled: true}, Validators.required)
    });


    @Input()
    public required: boolean = false;

    @Output()
    public valueChange: EventEmitter<string> = new EventEmitter();

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        public helpers: HelpersService
    ) {
    }


    public ngOnInit(): void {

        if (this.required) {
            this.formGroup.get("period").setValidators([Validators.required]);
            this.formGroup.get("period").setValue("day");
            this.formGroup.get("period").updateValueAndValidity();
            this.valueChange.emit("day");
        }

        this.formGroup.get("period").valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val: string): void => {
            if (val === "custom") {
                this.formGroup.get("start").enable();
                this.formGroup.get("end").enable();
            } else {
                this.formGroup.get("start").disable();
                this.formGroup.get("end").disable();
            }
            this.changeDetectorRef.markForCheck();
        });

        this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val: any): void => {

            if (val.period === "custom") {

                if (val.start && val.end) {
                    this.valueChange.emit(
                        val.start.startOf("day").format("YYYY-MM-DD HH:mm:ss")
                        + " ~ " + val.end.endOf("day").format("YYYY-MM-DD HH:mm:ss")
                    );
                } else {
                    this.valueChange.emit(null);
                }
            } else {

                this.valueChange.emit(val.period);
            }

            this.changeDetectorRef.markForCheck();
        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
