import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {takeUntil} from "rxjs/operators";
import {Wizard} from "../../../../../../../common/interfaces/wizard.interface";
import {Order} from "../../../../../../../common/interfaces/order.interface";
import {StorageService} from "../../../../../../../common/services/storage.service";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {
    AbstractSinglePageWizardStepComponent
} from "../../../../../../../common/interfaces/wizard-single-page.interface";
import {ContractService} from "../../../../../../../common/services/contract.service";

@Component({
    selector: "section-order-wizard-service-level",
    templateUrl: "service-level.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderWizardServiceLevelComponent extends AbstractSinglePageWizardStepComponent
    implements OnInit, OnDestroy, OnChanges {

    protected setupIndex: number = 0;
    /**
     * Detail form group
     * @type {FormGroup}
     */
    public service_level: FormControl = new FormControl(null, [Validators.required]);


    /**
     * Service levels list for select control
     * @type {any[]}
     */
    public serviceLevels: { [key: string]: Order.IServiceLevel[] };


    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        protected apiService: ApiService,
        protected storageService: StorageService,
        protected contractService: ContractService,
        protected spinnerService: SpinnerService
    ) {
        super(changeDetectorRef);
    }

    protected async getServiceLevels(contract_id: number): Promise<any> {
        this.spinnerService.show();

        let response: Api.IResponse;
        if (contract_id) {
            response = await this.contractService.getServiceLevels(contract_id);
        } else {
            response = await this.apiService.request(Api.EMethod.Put, ["order"]);
        }

        if (response.data) {
            this.serviceLevels = response.data.service_levels;
            if (Object.values(this.serviceLevels).length === 1 && Object.values(this.serviceLevels)[0].length === 1) {
                this.service_level.setValue(Object.values(this.serviceLevels)[0][0]);
            }
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    protected setup(data: Wizard.IData): void {
        this.getServiceLevels(data.contract_id);

        this.storageService.remove("preferredHub");
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this.service_level.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((level: Order.IServiceLevel): void => {
                if (level) {
                    if (level.order_type && level.order_type.slug) {
                        this.result.next({
                            action: "setup",
                            value: {
                                path: "serviceLevel." + level.order_type.slug
                            }
                        });
                    }

                    this.result.next({
                        action: "data",
                        value: {serviceLevel: level, order_id: null}
                    });

                    this.result.next({
                        action: "result",
                        value: true
                    });
                } else {
                    this.result.next({action: "result", value: null});
                }
            });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.data) {
            const pv: any = changes.data.previousValue;
            const cv: any = changes.data.currentValue;

            if (pv.contract_id !== cv.contract_id) {
                this.setup(cv);
            }
        }
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

}
