import {Injectable} from "@angular/core";
import {Api, ApiService} from "./api.service";
import {Api3Service} from "./api3.service";
import {AppStateService} from "./app-state.service";


@Injectable()
export class PartMasterService {

    public constructor(
        private apiService: ApiService,
        private api3Service: Api3Service
    ) {
    }

    public getPartMasters(query: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Get, ["partmaster"], {}, query);
    }

    public deletePartMasterFile(id: number): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Delete, ["partmaster", "files", "" + id]);
    }

    public getPartMaster(item: string, query: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Get, ["partmaster", item], {}, query);
    }

    public createPartMaster(body: any = {}, query: any = {}): Promise<Api.IResponse> {
        return this.api3Service.request(Api.EMethod.Post,
            `/${AppStateService.getState().section}/part-masters`, body, query);
    }

    public updatePartMaster(id: number, body: any = {}, query: any = {}): Promise<Api.IResponse> {
        return this.api3Service.request(Api.EMethod.Put,
            `/${AppStateService.getState().section}/part-masters/${id}`, body, query);
    }


    public getSerial(serial: string, query: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Get, ["serial", serial], {}, query);
    }

    public updateSerial(serial: string, body: any = {}, query: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Put, ["serial", serial], body, query);
    }

    public getPartMasterUoms(query: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Get, ["partmaster", "uom"], {}, query);
    }
}
