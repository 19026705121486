import {ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, ViewEncapsulation} from "@angular/core";
import {Modal} from "../../../../../services/modal.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
    selector: "section-request-form-url-entry",
    template: `

        <h1 class="container-heading">Insert image url</h1>
        <form *ngIf="formGroup" [formGroup]="formGroup"
              (submit)="submit()">
            <div class="group flex">
                <mat-form-field class="full">
                    <mat-label>File Url</mat-label>
<input class="file-input" matInput formControlName="fileUrl" />
                </mat-form-field>
            </div>
            <div class="actions">
                <button mat-raised-button color="primary" type="submit" class="main" [disabled]="!formGroup.valid">
                    Submit
                </button>
                <button mat-button type="button" (click)="close()">Cancel</button>
            </div>
        </form>
    `,
    styleUrls: ["url-entry.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class UrlEntryComponent implements OnDestroy {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    public modal: Modal.IModal;

    public formGroup: FormGroup = new FormGroup({
        fileUrl: new FormControl(null, [Validators.required]),
    });

    public constructor() {
    }

    public submit(): void {
        this.modal.response.emit({
            name: "value",
            value: this.formGroup.get("fileUrl").value
        });
    }

    public close(): void {
        this.modal.response.emit();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
