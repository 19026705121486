import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    Output,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {UntypedFormBuilder, FormControl} from "@angular/forms";
import {Router} from "@angular/router";
import {Api} from "../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {ConfirmComponent} from "../../../../../../../common/components/confirm/confirm.component";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {User} from "../../../../../../../common/interfaces/user.interface";
import {Api3Service} from "../../../../../../../common/services/api3.service";
import {Warehouse} from "../../../../../../../common/interfaces/warehouse.interface";
import {AdminPartnerCarrierService} from "../../../../services/partner-carrier.service";
import {Table} from "../../../../../../../common/interfaces/table.interface";
import {AppStateService} from "../../../../../../../common/services/app-state.service";

@Component({
    selector: "section-partner-form-step5",
    templateUrl: "step5.component.html",
    styleUrls: [
        "step5.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class PartnerFormStep5Component implements OnDestroy {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    private partner: User.IPartner;

    @Output()
    public result: EventEmitter<any> = new EventEmitter();

    @Output()
    public authFail: EventEmitter<any> = new EventEmitter();

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    public carriers: any[] = [];

    public showSpinner: boolean = false;

    public showWarehouseSpinner: boolean = false;

    public search: FormControl = new FormControl(null);

    public warehouses: Warehouse.IWarehouse[] = [];

    public warehousesGrouped: { [key: string]: { threepl: any, warehouses: any[] } } = {};

    public selected_warehouses: any = {};

    public selected_warehouses_for_partner: any = {};

    public selected_threepl: any = {};

    public logsTableSettings: Table.ISettings;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private api3Service: Api3Service,
        private spinnerService: SpinnerService,
        private partnerCarrierService: AdminPartnerCarrierService
    ) {
    }

    private async getPartnerCarriers(): Promise<any> {
        const {data}: Api.IResponse = await this.partnerCarrierService.getList(this.partner.id);

        if (data) {
            this.selected_threepl = {};
            this.selected_warehouses = {};

            for (const row of data) {
                this.selected_warehouses[row.carrier_id + "-" + row.warehouse_id] = true;
                this.selected_warehouses_for_partner[row.carrier_id + "-" + row.warehouse_id] = row.partner_only;

                for (const warehouse of this.warehouses) {
                    if (this.selected_threepl[row.carrier_id + "-" + warehouse.threepl.display_name] === undefined) {
                        this.selected_threepl[row.carrier_id + "-" + warehouse.threepl.display_name] = 0;
                    }
                    if (row.warehouse_id === warehouse.id) {
                        this.selected_threepl[row.carrier_id + "-" + warehouse.threepl.display_name]++;
                    }
                }
            }
        }
        this.changeDetectorRef.markForCheck();
    }

    private async getPartnerWarehouses(): Promise<any> {
        this.showWarehouseSpinner = true;
        this.changeDetectorRef.markForCheck();

        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `admin/partners/${this.partner.id}/warehouses`, {}, {
                sort: {
                    data: "3pl_id"
                },
                relations: [
                    "Threepl:id,display_name"
                ]
            });

        if (data) {
            this.warehouses = data;

            for (const warehouse of this.warehouses) {
                this.warehousesGrouped[warehouse.threepl.display_name] = {
                    threepl: warehouse.threepl,
                    warehouses: []
                };
            }

            for (const warehouse of this.warehouses) {
                this.warehousesGrouped[warehouse.threepl.display_name].warehouses.push(warehouse);
            }
        }
        this.showWarehouseSpinner = false;
        this.changeDetectorRef.markForCheck();
        this.getPartnerCarriers();
    }

    private async updateCarriersList(state: boolean, body: any): Promise<any> {
        this.spinnerService.show();

        const {code, message}: Api.IResponse =
            await this.partnerCarrierService.updateList(this.partner.id, state, body);

        this.spinnerService.hide();
        if (code === 200) {
            this.getPartnerCarriers();
        }
    };

    private async getCarriers(): Promise<any> {
        this.showSpinner = true;
        this.carriers = [];
        this.changeDetectorRef.markForCheck();

        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get, "admin/partners/carriers");

        this.showSpinner = false;
        this.changeDetectorRef.markForCheck();

        if (data) {
            const list: any = data.Carriers;
            for (const carrier of Object.keys(list)) {
                for (const item of list[carrier]) {
                    this.carriers.push({
                        name: carrier,
                        label: item.Label,
                        logo: item.CarrierLogo,
                        id: Number(item.CarrierID),
                        service_name: item.CarrierService,
                    });
                }
            }
        }
    }

    private async prepareLogsTable(): Promise<any> {

        this.logsTableSettings = {
            columns: [
                {
                    data: "name",
                    title: "Log"
                },
                {
                    data: "description",
                    title: "Description"
                },
                {
                    data: "user.name",
                    name: "User.name",
                    title: "By"
                },
                {
                    data: "created_at",
                    title: "At"
                },

            ],
            api: this.getLogsUrl(),
            sort_default: {
                data: "created_at",
                dir: "desc"
            }
        };
    }

    private getLogsUrl(): Table.ITableApi {
        return {
            url: ["admin", "partners", "" + this.partner.id, "carriers", "logs"],
            query: {
                relations: [
                    "User:id,name"
                ]
            },
            version: 3
        };
    }

    public checkThreepl(state: boolean, carrier: any, threepl: string): void {
        const body: any = {carriers: []};

        for (const warehouse of this.warehousesGrouped[threepl].warehouses) {
            body.carriers.push({
                carrier_id: carrier.id,
                carrier_name: `${carrier.name} (${carrier.label}) ${carrier.service_name}`,
                warehouse_id: warehouse.id
            });
        }

        this.updateCarriersList(state, body);
    }

    public checkWarehouse(state: boolean, carrier: any, warehouse_id: number, partner_only = false): void {
        const body: any = {
            carriers: [
                {
                    carrier_id: carrier.id,
                    carrier_name: `${carrier.name} (${carrier.label}) ${carrier.service_name}`,
                    warehouse_id,
                    partner_only
                }
            ]
        };

        this.updateCarriersList(state, body);
    }

    /**
     * Initialize step
     * @returns {Promise<any>}
     * @param partner
     */
    public async init(partner: User.IPartner): Promise<any> {
        this.partner = partner;

        this.getCarriers();
        this.getPartnerWarehouses();

        this.prepareLogsTable();

    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
