<ng-template [ngIf]="state.component !== 'orders'">
    <h1 class="container-heading">{{tpl_title}}</h1>

    <div class="title-options">

        <button mat-raised-button type="button" *ngIf="orderData"
                (click)="showSidebar(true)" matBadgeColor="accent" [matBadge]="orderData.remarks_count">
            Remarks
        </button>
        &nbsp;
        <button mat-raised-button (click)="goToOrder()" color="accent">
            Go to order
        </button>
    </div>
</ng-template>

<mat-card>
    <mat-card-title>
        <div class="margin-bottom-20">

            <common-form-switch [value]="false" width="250px"
                                [labelOff]="'Outbound'"
                                [labelOn]="'Inbound'"
                                (valueChange)="showInbound.setValue($event)">
            </common-form-switch>
        </div>
    </mat-card-title>
    <mat-card-content>
        <div class="columns">
            <mat-card class="left">
                <h3>Order items</h3>
                <mat-form-field class="search">
                    <mat-label>Search</mat-label>
                    <input matInput #itemsSearch type="search">
                </mat-form-field>
                <div class="items">
                    <ng-template ngFor let-item let-ii="index"
                                 [ngForOf]="orderItems | search: itemsSearch.value : ['item','serial']">
                        <div class="item {{item.status ? item.status.name.toLowerCase() : ''}}">
                            <div class="title flex row">

                                <div class="margin-right-10">
                                    <mat-checkbox [checked]="allOrderItemTransactionSelected[ii]"
                                                  (change)="selectAll($event, item.warehouse_transactions)"></mat-checkbox>
                                </div>

                                <div>
                                    Item: <b>{{item.item}}</b>
                                    Serial: <b>{{item.serial}}</b>
                                    Qty: <b>{{item.quantity}}</b>
                                    Type: <b>{{item.type}}</b>
                                    Status: <b>{{item.status ? item.status.name : ''}}</b>
                                    <br>
                                    Hub: <b *ngIf="item.inventory_conversion">
                                    {{item.inventory_conversion.customers_inventory_name}} -
                                    {{item.inventory_conversion.customers_sub_inventory}}</b>
                                </div>
                            </div>
                            <div class="drag-bag transactions id-{{item.id}}"
                                 *ngIf="item.warehouse_transactions"
                                 [dragula]='"transaction-bag"'
                                 [(dragulaModel)]="item.warehouse_transactions">
                                <div class="transaction id-{{transaction.id}}"
                                     style="cursor: text;"
                                     *ngFor="let transaction of item.warehouse_transactions">
                                    <div class="flex row">
                                        <span class="handle material-icons" style="cursor: move;">drag_indicator</span>
                                        <div class="margin-right-10">
                                            <mat-checkbox [value]="transaction.id"
                                                          [checked]="selectedList.indexOf(transaction.id) > -1"
                                                          (change)="selectedListUpdate($event)"></mat-checkbox>
                                        </div>
                                        <div>
                                            Transaction:
                                            Item: <b>{{transaction.item}}</b>
                                            Serial: <b>{{transaction.serial}}</b>
                                            Qty: <b>{{transaction.quantity}}</b>
                                            Type: <b>{{transaction.type}}</b>
                                            Hub: <b *ngIf="transaction.inventory_conversion">
                                            {{transaction.inventory_conversion.customers_inventory_name}} -
                                            {{transaction.inventory_conversion.customers_sub_inventory}}</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </mat-card>
            <mat-card class="right">
                <h3>Unlinked transaction</h3>

                <div class="flex row" style="align-items: center">
                    <div class="margin-right-10">
                        <mat-checkbox [checked]="allUnlinkedTransactionsSelected"
                                      class="pull-left"
                                      (change)="selectAll($event, orderTransactions)">
                            Select all
                        </mat-checkbox>
                    </div>
                    <div>
                        <mat-form-field class="search">
                            <mat-label>Search</mat-label>
                            <input matInput #transactionsSearch type="search">
                        </mat-form-field>
                    </div>
                </div>


                <div class="transactions" *ngIf="orderTransactions"
                     [dragula]='"transaction-bag"'
                     [(dragulaModel)]="orderTransactions">
                    <div class="transaction id-{{transaction.id}}"
                         style="cursor: text;"
                         *ngFor="let transaction of orderTransactions | search: transactionsSearch.value : ['item','serial']">
                        <div class="flex row">
                            <span class="handle material-icons" style="cursor: move;">drag_indicator</span>
                            <div class="margin-right-10">
                                <mat-checkbox [value]="transaction.id"
                                              [checked]="selectedList.indexOf(transaction.id) > -1"
                                              (change)="selectedListUpdate($event)"></mat-checkbox>

                            </div>

                            <div>
                                Transaction:
                                Item: <b>{{transaction.item}}</b>
                                Serial: <b>{{transaction.serial}}</b>
                                Qty: <b>{{transaction.quantity}}</b>
                                Type: <b>{{transaction.type}}</b>
                                Hub: <b *ngIf="transaction.inventory_conversion">
                                {{transaction.inventory_conversion.customers_inventory_name}} -
                                {{transaction.inventory_conversion.customers_sub_inventory}}</b>
                            </div>

                            <div class="pull-right">
                                <mat-icon *ngIf="transaction.invalid_state
                        && transaction.invalid_state.validation_errors
                        && !transaction.invalid_state.is_validation_skipped"
                                          color="warn" (click)="showErrors(transaction)"
                                          style="margin-right: 5px;">
                                    warning
                                </mat-icon>

                                <mat-icon color="warn" matTooltip="Validation error"
                                          *ngIf="transaction.not_skipped_validation_errors && transaction.not_skipped_validation_errors.length">
                                    warning
                                </mat-icon>
                                &nbsp;
                                <mat-icon (click)="editTransaction(transaction)">
                                    edit
                                </mat-icon>
                            </div>
                        </div>


                    </div>
                </div>
            </mat-card>
        </div>
    </mat-card-content>
</mat-card>

<section-remarks-sidebar #sidebar *ngIf="state.component !== 'orders'"></section-remarks-sidebar>
