<section-order-wizard-shipment-details *ngIf="haveShipment"
                                       [state]="state"
                                       [shipmentName]="shipmentName"
                                       (updateCountry)="refObject.country = $event"
                                       (updateCustomer)="refObject.customer = $event"
                                       (result)="formGroupAddressUpdate($event)"></section-order-wizard-shipment-details>

<form *ngIf="ready" [formGroup]="formGroup">
    <div class="group flex">

        <div class="full">
            <h3 class="mat-h3">Order details</h3>
        </div>

        <mat-form-field class="full" *ngIf="showRequestor">
            <mat-label>Requestor contact</mat-label>
            <input matInput #contact_address_id
                   [sectionControl]="{components: components.contact,
                   params: {address: formGroup.get('requestor_contact_id').value}}"
                   formControlName="requestor_id"
                   readonly>
        </mat-form-field>

        <ng-container *ngIf="showIncoterms">
            <mat-form-field class="half" *ngIf="canOutbound">
                <mat-label>Out Incoterms</mat-label>
                <mat-select formControlName="out_incoterms">
                    <mat-option [value]="null">None</mat-option>
                    <mat-option *ngFor="let incoterm of incoterms" [value]="incoterm.value">
                        {{incoterm.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="half" *ngIf="canInbound">
                <mat-label>In Incoterms</mat-label>
                <mat-select formControlName="in_incoterms">
                    <mat-option [value]="null">None</mat-option>
                    <mat-option *ngFor="let incoterm of incoterms" [value]="incoterm.value">
                        {{incoterm.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div class="full">
                <span (click)="whatIsIncoterm()" class="pointer">
                    <mat-icon class="vertical-middle">help</mat-icon>
                    What is Incoterm?
                </span>
            </div>
        </ng-container>

        <common-field class="half" *ngFor="let item of (meta || [])"
                      [control]="formGroup.get('custom_fields.' + item.name)"
                      [meta]="item">
        </common-field>

        <mat-form-field class="half">
            <mat-label>{{refPlaceholder}}</mat-label>
            <input matInput formControlName="ref" maxlength="32" required>
            <span matSuffix role="button">
                <mat-icon (click)="randomRef()" title="Generate">autorenew</mat-icon>
            </span>
            <mat-error *ngIf="!formGroup.get('ref').valid && formGroup.get('ref').errors.refPattern">
                Special chars are not allowed
            </mat-error>
            <mat-error *ngIf="!formGroup.get('ref').valid && formGroup.get('ref').errors.refExists">
                {{formGroup.get('ref').errors.refExists}}
            </mat-error>
        </mat-form-field>

        <mat-form-field class="half">
            <mat-label>Ref2</mat-label>
            <input matInput formControlName="ref2" maxlength="32">
        </mat-form-field>

        <mat-form-field class="half">
            <mat-label>{{srPlaceholder}}</mat-label>
            <input matInput formControlName="sr">
        </mat-form-field>


        <section-order-reseller-select class="half"
                                       *ngIf="partner.slug === 'modix'; else notModix"
                                       (valueChanges)="formGroup.get('modix_reseller').setValue($event)"
        ></section-order-reseller-select>

        <ng-template #notModix>
            <div class="half spacer"></div>
        </ng-template>

        <div class="half">
            <mat-error *ngIf="srExists">
                This {{srPlaceholder}} already used in оrder(s) {{srExists}}.
            </mat-error>

        </div>

        <ng-template [ngIf]="showExw">

            <mat-form-field class="full">
                <mat-label>Customer account</mat-label>
                <input matInput #exw_account_id
                       [sectionControl]="{components: components.customer_account}"
                       formControlName="customer_account_id"

                       readonly>
            </mat-form-field>

            <mat-form-field class="full" *ngIf="formGroup.value.customer_account_id">
                <mat-label>Customer courier company</mat-label>
                <input matInput #exw_account_courier_company
                       [sectionControl]="{components: components.customer_account, params: {type: 'company'}}"
                       formControlName="customer_account_courier_company_id"
                       readonly>
            </mat-form-field>

            <mat-form-field class="full" *ngIf="formGroup.value.customer_account_courier_company_id">
                <mat-label>Customer service level</mat-label>
                <input matInput #exw_account_service_level
                       [sectionControl]="{components: components.customer_account, params: {type: 'service'}}"
                       formControlName="customer_account_service_level_id"
                       readonly>
            </mat-form-field>

        </ng-template>

    </div>

</form>
