import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Modal} from "../../../../../services/modal.service";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Warehouse} from "../../../../../../common/interfaces/warehouse.interface";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Order} from "../../../../../../common/interfaces/order.interface";


@Component({
    selector: "section-box-sticker-2",
    template: `

        <div class="pull-right">
            <button mat-raised-button color="accent" (click)="print()">
                <mat-icon>print</mat-icon>
                Print
            </button>
        </div>
        <h1 class="container-heading">Box sticker</h1>
        <mat-card>
            <mat-card-content #sticker>

                <div class="sticker" style="padding-top: 10px; width: 10.16cm; height: 15.24cm;">
                    <ng-container *ngIf="order">
                        <div style="border: 3px solid #000; padding: 0 10px">
                            <div>
                                {{order.main_address.ref}}
                            </div>
                            <!--                        <div>-->
                            <!--                            {{order.main_address.address}}-->
                            <!--                            {{order.main_address.city}}-->
                            <!--                            {{order.main_address.country}}-->
                            <!--                            {{order.main_address.zip}}-->
                            <!--                        </div>-->
                        </div>
                        <div>
                            Customer: {{order.partner.display_name}}
                        </div>
                        <div>
                            Reference: {{order.ref}}
                        </div>

                    </ng-container>

                    <div class="text-center" style="margin-bottom: 10px;">
                        <common-barcode [bcValue]="box.ref" [bcDisplayValue]="true"
                                        bcWidth="1"
                                        [bcMarginTop]="1"
                                        [bcMarginLeft]="1"
                                        [bcMarginRight]="1"
                                        [bcMarginBottom]="1"
                                        bcHeight="30"></common-barcode>
                    </div>

                    <div *ngIf="box.properties && box.properties.Lot"
                         style="border: 3px solid #000; padding: 0 10px">
                        <div>
                            Lot No.:
                        </div>
                        <div class="text-center" style="font-size: 300%; line-height:1.2">
                            {{box.properties.Lot}}
                        </div>
                    </div>

                    <table>
                        <tr>
                            <td>Warehouse:</td>
                            <td colspan="3">{{modal.params.warehouseName}}</td>
                        </tr>
                        <tr>
                            <td>Location:</td>
                            <td colspan="3">{{modal.params.locationName}}</td>
                        </tr>
                        <tr>
                            <td>Receipt Dt.:</td>
                            <td colspan="3">{{box.created_at | date: 'MM/dd/yyyy'}}</td>
                        </tr>
                        <ng-container *ngIf="box.properties">
                            <tr>
                                <td>Supplier:</td>
                                <td colspan="3">{{box.properties.Supplier || ''}}</td>
                            </tr>
                            <tr>
                                <td>Po Number:</td>
                                <td colspan="3">{{box.properties.Po || ''}}</td>
                            </tr>

                            <tr>
                                <td>No Pieces:</td>
                                <td>{{box.properties.Pieces || ''}}</td>
                                <td>WT:</td>
                                <td>{{box.properties.Weight || ''}}</td>
                            </tr>
                            <tr>
                                <td>Carrier Nm:</td>
                                <td colspan="3">{{box.properties.Carrier || ''}}</td>
                            </tr>
                            <tr>
                                <td>ProNumber:</td>
                                <td colspan="3">{{box.properties['Pro #'] || ''}}</td>
                            </tr>

                            <tr>
                                <td>DimS:</td>
                                <td colspan="3">{{box.properties.Dims || ''}}</td>
                            </tr>
                        </ng-container>

                    </table>

                </div>
            </mat-card-content>
        </mat-card>
    `,
    styleUrls: [
        "sticker.component.scss"
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoxSticker2Component implements OnInit {

    public modal: Modal.IModal;

    public box: Warehouse.IBox;

    public order: Order.IOrderData;

    @ViewChild("sticker", {static: false})
    public sticker: ElementRef;

    public constructor(
        private apiService: ApiService,
        private changeDetectorRef: ChangeDetectorRef,
        private spinnerService: SpinnerService
    ) {
    }


    private async getOrder(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["order", "" + this.modal.params.orderRef]);
        this.spinnerService.hide();
        if (data) {
            this.order = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    public print(): void {
        const printContents: any = this.sticker.nativeElement.innerHTML;
        HelpersService.stickerPopUp(printContents);
    }


    public ngOnInit(): void {
        this.box = this.modal.params.box;
        if (this.modal.params.orderRef) {
            this.getOrder();
        }
    }

}
