import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Router} from "@angular/router";
import {Location} from "@angular/common";
import {Order} from "../../../../../../common/interfaces/order.interface";
import {IPagination} from "../../../../../../common/components/pagination/pagination.component";
import {FormControl} from "@angular/forms";
import {debounceTime, takeUntil} from "rxjs/operators";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {Table, TableComponent} from "../../../../../../common/components/table/table.component";
import {UserService} from "../../../../../../common/services/user.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import { ConfirmComponent } from "src/modules/common/components/confirm/confirm.component";

@Component({
    selector: "section-inventory-view",
    templateUrl: "view.component.html",
    styleUrls: [
        "view.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class InventoryViewComponent implements Base.IComponent, OnInit, OnDestroy {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    private inventoryKey: string;

    public modal: Modal.IModal;

    public state: Base.IState;

    public inventory: IPagination<any>;

    public data: any;

    public transactions: { inbound: IPagination<any>, outbound: IPagination<any>, history: IPagination<any> } = {
        inbound: null,
        outbound: null,
        history: null
    };

    public allocations: Table.IOptions;
    public allocationsHide: boolean = false;

    @ViewChild(TableComponent, {static: false})
    public allocDt: TableComponent;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    public qty: { inbound: number, outbound: number } = {inbound: 0, outbound: 0};

    public total: { inbound: number, outbound: number } = {inbound: 0, outbound: 0};

    public sum_inventory: number;

    public inventorySearch: FormControl = new FormControl(null);

    public inboundSearch: FormControl = new FormControl(null);
    public outboundSearch: FormControl = new FormControl(null);

    public historySearch: FormControl = new FormControl(null);

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private title: Title,
        private apiService: ApiService,
        private modalService: ModalService,
        private toastService: ToastService,
        private location: Location,
        private router: Router,
        private userService: UserService,
        private spinnerService: SpinnerService
    ) {
    }

    /**
     * Get inventory data
     * @param {number} page
     * @param {string} search_by
     * @param {number} per_page
     * @return {Promise<any>}
     */
    private async getInventory(page: number = 1, search_by: string = null, per_page: number = null): Promise<any> {
        this.spinnerService.show();
        if (per_page === null) {
            per_page = this.userService.data.settings.default_per_page;
        }
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["inventory", "key", this.inventoryKey], {}, {
                data_structure: "paginated",
                page,
                per_page,
                search_by
            });
        if (data) {
            this.inventory = data;
            this.data = data.data[0];
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get transactions of inventory
     * @param {string} type
     * @param {number} page
     * @param {string} search_by
     * @param {number} per_page
     * @return {Promise<any>}
     */
    private async getTransactions(
        type: string = null,
        page: number = 1,
        search_by: string = null,
        per_page: number = null
    ): Promise<any> {
        this.spinnerService.show();
        if (per_page === null) {
            per_page = this.userService.data.settings.default_per_page;
        }
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["transaction", "by_key", this.inventoryKey], {}, {
                data_structure: "paginated",
                page,
                per_page,
                type,
                search_by
            });
        if (data) {
            if (!type) {
                type = "history";
            }
            this.transactions[type] = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get transactions counts
     * @param {string} type
     * @return {Promise<any>}
     */
    private async getTransactionsCount(type: string): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["transaction", "by_key", this.inventoryKey], {}, {
                data_structure: "count",
                type
            });
        if (data) {
            this.qty[type] = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get sum inventory in stock
     * @return {Promise<any>}
     */
    private async getSumInventory(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["inventory", "key", this.inventoryKey], {}, {
                data_structure: "sum",
                column: "quantity"
            });
        if (data) {
            this.sum_inventory = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get total
     * @param {string} type
     * @return {Promise<any>}
     */
    private async getTotal(type: string): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["transaction", "by_key", this.inventoryKey], {}, {
                data_structure: "sum",
                column: "quantity",
                type
            });
        if (data) {
            this.total[type] = data;
        }
        this.spinnerService.hide();
    }

    /**
     * Get inventory allocations
     * @return {Promise<any>}
     */
    private async getAllocations(): Promise<any> {
        this.allocations = {
            api: this.apiService.getUrl(["inventory_allocation", "by_key", this.inventoryKey])
                + "?data_structure=dataTables",
            actions: [
                {
                    name: "view",
                    title: "View order"
                },
                {
                    name: "delete",
                    title: "Delete"
                }
            ],
            columns: [
                {
                    data: "ref",
                    title: "Ref"
                },
                {
                    data: "item",
                    title: "Item"
                },
                {
                    data: "batch",
                    title: "Batch"
                },
                {
                    data: "serial",
                    title: "Serial"
                },
                {
                    data: "configurations",
                    title: this.userService.getProperty(
                        "configuration_name",
                        this.state,
                        "Configurations"
                    ),
                    render: (data: any): string => {
                        return data.map((conf: any): string => conf.name).join(", ");
                    }
                },
                {
                    data: "quantity",
                    title: "Quantity"
                },
                {
                    data: "inventory_conversion.customers_sub_inventory",
                    title: "Hub"
                }
            ],
            order: [],
            search: false
        };
    }

    /**
     * Hide allocations table if no data
     * @param {Table.IDtEvent} event
     */
    public onAllocationsDtEvent(event: Table.IDtEvent): void {
        this.allocationsHide = !event.hasData;
    }

    /**
     * Handle allocations row action
     * @param {Table.Action.IResult} action
     * @returns {Promise<any>}
     */
    public async handleAllocationsAction(action: Table.Action.IResult): Promise<any> {
        if (action.name === "view") {
            this.router.navigate([
                this.state.section,
                "orders",
                "view",
                "id",
                action.data.id,
                "type",
                action.data.type
            ]);
            this.modal.response.emit(null);
        } else if (action.name === "delete") {
            if (!await this.confirmRef.confirm("Are you sure want to delete this Service Level?")) {
                return;
            }
            this.spinnerService.show();
            const response: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
                ["inventory_allocation", action.data.id]);

            if (response) {
                this.toastService.show(response.message, response.type as string);
                this.allocDt.reload();
            }

            this.spinnerService.hide();
        }
    }

    public goToOrder(order: Order.IOrderData): void {
        this.router.navigate([
            this.state.section, "orders", "view", "id", order.id
        ]);
    }

    /**
     * Export history to csv
     * @return {Promise<any>}
     */
    public async exportCsv(): Promise<any> {
        this.spinnerService.show();

        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["transaction", "by_key", this.inventoryKey]);
        this.spinnerService.hide();
        if (!response.data) {
            this.toastService.show(response.message, response.type as string);
            return;
        }
        const toCsv: { [key: string]: string }[] = [];

        for (const i in response.data) {
            if (!response.data.hasOwnProperty(i)) {
                continue;
            }
            const row: any = response.data[i];

            const csvObj: any = {
                "Ref": "" + row.ref,
                "Type": "" + row.type,
                "Item": "" + row.item,
                "Serial": "" + (row.serial || ""),
                "Configurations": "" + (row.configurations || ""),
                "Batch": "" + (row.batch || ""),
                "Quantity": "" + row.quantity,
                "Received at": "" + row.received_at
            };
            toCsv.push(csvObj);
        }

        HelpersService.exportCsv(toCsv, "Inventory transactions");
    }

    public ngOnInit(): void {
        if (this.modal.params.state) {
            this.state = this.modal.params.state;
        }
        if (this.modal.params.inventoryKey) {
            this.inventoryKey = this.modal.params.inventoryKey;
        } else {
            this.inventoryKey = this.state.params.key;
        }

        this.getInventory();

        this.getTransactions("inbound");
        this.getTransactions("outbound");

        this.getTransactionsCount("inbound");
        this.getTransactionsCount("outbound");

        this.getAllocations();

        this.getTotal("inbound");
        this.getTotal("outbound");

        this.getSumInventory();
        this.getTransactions();


        this.inboundSearch.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: string): void => {
                this.getTransactions("inbound", 1, value);
            });

        this.outboundSearch.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: string): void => {
                this.getTransactions("outbound", 1, value);
            });

        this.inventorySearch.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: string): void => {
                this.getInventory(1, value);
            });

        this.historySearch.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: string): void => {
                this.getInventory(1, value);
            });


    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "inventory",
            actions: {
                "view": ["browse_inventories"]
            }
        };
    }

}
