import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Router} from "@angular/router";
import {ConfirmComponent} from "../../../../../common/components/confirm/confirm.component";
import {Api, ApiService} from "../../../../../common/services/api.service";
import {ToastService} from "../../../../../common/services/toast.service";
import {UserService} from "../../../../../common/services/user.service";
import {Base} from "../../../../../common/interfaces/base.interfaces";
import {DrawerService} from "../../../../../app/services/drawer.service";
import {SpinnerService} from "../../../../../common/services/spinner.service";
import {WarehouseService} from "src/modules/common/services/warehouse.service";
import {Warehouse} from "src/modules/common/interfaces/warehouse.interface";
import {Api3Service} from "../../../../../common/services/api3.service";

@Component({
    selector: "section-warehouse-list",
    templateUrl: "list.component.html",
    styleUrls: [
        "list.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class WarehouseListComponent implements Base.IComponent, OnInit {

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    public readonly state: Base.IState;

    public warehouses: any[];

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private userService: UserService,
        private drawerService: DrawerService,
        private changeDetectorRef: ChangeDetectorRef,
        private spinnerService: SpinnerService
    ) {
    }

    private async getWarehouses(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `/threepl/${this.state.section_slug}/warehouses`, {}, {
                relations: [
                    "Address:id,city,country_iso_2"
                ]
            });
        this.spinnerService.hide();
        if (data) {
            this.warehouses = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    private async getThreeplSlugByWarehouseSlug(slug: string): Promise<any> {
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["warehouse", "findBySlug", slug]);

        if (data) {
            this.router.navigate([
                "/threepl", data.threepl.slug, "warehouses",
            ]);
        }
    }

    public goTo(warehouse: Warehouse.IWarehouse): void {
        this.drawerService.setItems("warehouse");
        WarehouseService.warehouse = warehouse;
        this.router.navigate([
            "/warehouse", warehouse.slug, "warehouse-orders-open"
        ]);
    }

    public edit(id: number): void {
        this.router.navigate([this.state.section, this.state.component, "edit", "id", "" + id]);
    }

    public async delete(warehouse: { name: string, id: number }): Promise<any> {
        if (await this.confirmRef.confirm(`Do you want to delete "${warehouse.name}?"`)) {
            this.spinnerService.show();
            const {data, message}: Api.IResponse =
                await this.apiService.request(Api.EMethod.Delete, ["warehouse", "" + warehouse.id]);
            this.spinnerService.hide();
            if (data) {
                this.toastService.show(message, "success");
            }
        }
    }

    public ngOnInit(): void {
        this.drawerService.setItems("threepl");
        const [type, slug]: string[] = this.state.section.split("/");
        if (type === "warehouse") {
            this.getThreeplSlugByWarehouseSlug(slug);
        } else {
            this.getWarehouses();
            this.spinnerService.hide();
        }
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "warehouses",
            actions: {
                "browse": ["browse_warehouses"]
            }
        };
    }

}
