import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {Base} from "../../../../../common/interfaces/base.interfaces";
import {HelpersService} from "../../../../../common/services/helpers.service";
import {Router} from "@angular/router";
import { UserService } from "src/modules/common/services/user.service";

@Component({
    selector: "section-dashboard",
    templateUrl: "dashboard.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements Base.IComponent, OnInit {

    private config: any = {
        "partner": {},
        "threepl": {},
        "warehouse": {}
    };

    public readonly state: Base.IState;

    public templateType: number = null;


    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private userService: UserService
    ) {
    }

    private initTemplate(): void {
        const [type, slug]: string[] = this.state.section.split("/");
        if (type === "admin") {
            return;
        }

        this.templateType = HelpersService.dotToObjectPath(this.config, type + "." + slug, type);
        this.changeDetectorRef.markForCheck();
    }


    public ngOnInit(): void {
        this.initTemplate();
    }


    public ngConfig(): Base.IConfig {
        return {
            name: "dashboard",
            actions: {
                "browse": "*"
            }
        };
    }

}
