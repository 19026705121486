import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit, ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {UserService} from "../../../../../../../common/services/user.service";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {Order} from "../../../../../../../common/interfaces/order.interface";
import {HelpersService} from "../../../../../../../common/services/helpers.service";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import IRemarkClassification = Order.IRemarkClassification;
import {ModalService} from "../../../../../../services/modal.service";
import {RemarksClassificationsFormComponent} from "../form/form.component";
import {ConfirmComponent} from "../../../../../../../common/components/confirm/confirm.component";


@Component({
    selector: "section-remarks-classifications-list",
    templateUrl: "list.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    styleUrls: ["list.component.scss"]
})

export class RemarksClassificationsListComponent implements Base.IComponent, OnDestroy, OnInit {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    public readonly state: Base.IState;

    public remarkClassifications: IRemarkClassification[] = null;

    public canEdit: boolean = false;

    public canDelete: boolean = false;

    @ViewChild(ConfirmComponent, {static: true})
    public confirm: ConfirmComponent;

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        public userService: UserService,
        private spinnerService: SpinnerService,
        private helperService: HelpersService,
        private changeDetectorRef: ChangeDetectorRef,
        protected modalService: ModalService
    ) {
    }

    public async prepareData(page: number = 1, per_page: number = 25): Promise<any> {
        this.remarkClassifications = null;
        this.changeDetectorRef.markForCheck();

        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["remark", "classification"]);

        if (data) {
            this.remarkClassifications = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    public drop(event: CdkDragDrop<string[]>): void {
        moveItemInArray(this.remarkClassifications, event.previousIndex, event.currentIndex);
        this.updatePriority();
    }

    /**
     * Create remark classification
     * @returns {Promise<any>}
     */
    public async updatePriority(): Promise<any> {
        this.spinnerService.show();
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["remark", "classification", "update_priority"],
            this.remarkClassifications.map((rc: IRemarkClassification, index: number): any => {
                return {
                    id: rc.id,
                    priority: index + 1
                };
            }));
        this.toastService.show(response.message, response.type as string);
        this.spinnerService.hide();
    }

    public async edit(data: IRemarkClassification): Promise<any> {
        await this.modalService.open(RemarksClassificationsFormComponent, {data});
        this.prepareData();
    }

    public async delete(data: IRemarkClassification): Promise<any> {
        if (!await this.confirm.confirm("This action cannot be reversed!")) {
            return;
        }
        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
            ["remark", "classification", "" + data.id]);
        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.prepareData();
        }
    }

    public async add(): Promise<any> {
        await this.modalService.open(RemarksClassificationsFormComponent);
        this.prepareData();
    }

    public ngOnInit(): void {
        this.prepareData();
        this.canEdit = this.userService.validatePermissions("edit_order_remark_classifications");
        this.canDelete = this.userService.validatePermissions("delete_order_remark_classifications");
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "remarks-classifications",
            actions: {
                "browse": ["browse_order_remark_classifications"]
            }
        };
    }
}
