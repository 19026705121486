<common-confirm></common-confirm>
<h1 class="container-heading">Dashboard</h1>
<mat-card>
    <mat-card-content>
        <ng-container [ngSwitch]="templateType">
            <section-dashboard-template-threepl *ngSwitchCase="'threepl'" [state]="state">ThreePL</section-dashboard-template-threepl>
            <section-dashboard-template-warehouse *ngSwitchCase="'warehouse'" [state]="state">Warehouse</section-dashboard-template-warehouse>
            <section-dashboard-template-default *ngSwitchDefault [state]="state"></section-dashboard-template-default>
        </ng-container>
    </mat-card-content>
</mat-card>
