import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Router} from "@angular/router";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {
    AbstractWizardComponent,
    Wizard,
    WizardStepFactory
} from "../../../../../../../common/interfaces/wizard.interface";
import {RemarksSidebarComponent} from "../../../../../common/components/order";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {
    WarehouseAttachmentsListComponent,
    WarehouseProceduresWizardInboundScanBoxesComponent, WarehouseProceduresWizardInboundScanComponent,
    WarehouseProceduresWizardInboundScanDefaultComponent,
    WarehouseProceduresWizardLockdownComponent,
    WarehouseProceduresWizardOrderSearchComponent,
    WarehouseProceduresWizardOutboundSelectShipmentComponent,
    WarehouseProceduresWizardScanBoxResultsComponent,
    WarehouseProceduresWizardScanDefaultResultsComponent
} from "../../index";
import {Modal, ModalService} from "../../../../../../services/modal.service";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {Api3Service} from "../../../../../../../common/services/api3.service";


@Component({
    selector: "section-procedures-inbound",
    templateUrl: "wizard.component.html",
    styleUrls: [
        "../common/wizard.component.scss",
        "wizard.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class WarehouseProceduresWizardInboundComponent extends AbstractWizardComponent
    implements OnInit, AfterViewInit {

    @ViewChild("sidebar", {static: false})
    public sidebarRef: RemarksSidebarComponent;

    public status: any;

    public remarksCount: number = 0;

    public attachmentsCount: number = 0;

    public readonly state: Base.IState;

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private modalService: ModalService,
        private spinnerService: SpinnerService
    ) {
        super(changeDetectorRef);
    }

    /**
     * Get order remarks count
     */
    private async getRemarksCount(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `${this.state.section}/orders/${this.data.orderId}/remarks`, {}, {
                data_structure: "count"
            });
        if (data) {
            this.remarksCount = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }


    protected handleSetupEvent(event: Wizard.IStepResult, stepIndex: number): void {


        if (this.steps[stepIndex].title === "Select shipment") {

            switch (event.value.scanType) {
                case "universal":
                    this.steps.splice(2);

                    this.steps.push(
                        new WizardStepFactory("Scan items", WarehouseProceduresWizardInboundScanComponent));
                    this.steps.push(
                        new WizardStepFactory("Scan results",
                            WarehouseProceduresWizardScanDefaultResultsComponent));

                    break;

                case "box":
                    this.steps.splice(2);
                    this.steps.push(
                        new WizardStepFactory("Scan boxes", WarehouseProceduresWizardInboundScanBoxesComponent));
                    this.steps.push(
                        new WizardStepFactory("Free boxes", WarehouseProceduresWizardInboundScanBoxesComponent));
                    this.steps.push(
                        new WizardStepFactory("Scan results", WarehouseProceduresWizardScanBoxResultsComponent));

                    break;

                default:
                    this.steps.splice(2);
                    this.steps.push(
                        new WizardStepFactory("Scan items", WarehouseProceduresWizardInboundScanDefaultComponent));
                    this.steps.push(
                        new WizardStepFactory("Scan items results",
                            WarehouseProceduresWizardScanDefaultResultsComponent));

                    break;
            }
        }

    }


    protected async handleLockdown(event: Wizard.IStepResult, stepIndex: number): Promise<any> {
        if (!this.isLockdown) {
            this.isLockdown = true;
            const response: Modal.IResponse = await this.modalService
                .open(WarehouseProceduresWizardLockdownComponent, {lockdown: event.value.lockdown});
            if (response && response.name === "skip") {
                this.isLockdown = false;
                delete event.value.lockdown;
            }
            this.handleStepEvent(event, stepIndex);
        }
    }

    public async getAttachmentsCount(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `${this.state.section}/orders/${this.data.orderId}/attachments`, {}, {
                data_structure: "count"
            });
        if (data) {
            this.attachmentsCount = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }


    public async handleStepEvent(event: Wizard.IStepResult, stepIndex: number): Promise<any> {
        if (event.value && event.value.orderRef) {
            setTimeout((): void => {
                this.getRemarksCount();
                this.getAttachmentsCount();
            }, 3000);
        }
        super.handleStepEvent(event, stepIndex);
    }

    /**
     * Show remarks in sidebar
     * @param {boolean} force
     */
    public showSidebar(force: boolean = false): void {
        if (this.data.orderRef) {
            this.sidebarRef.show(this.data.orderId, this.data.orderRef, force);
        }
    }

    public async showAttachments(): Promise<any> {
        await this.modalService.open(WarehouseAttachmentsListComponent, {
            orderRef: this.data.orderRef,
            modalWidth: "95%"
        });
    }

    /**
     * Finish wizard and redirect back to order
     */
    public finish(): void {
        this.router.navigate([
            this.state.section,
            "warehouse-orders-open"
        ]);
    }


    public ngOnInit(): void {
        super.ngOnInit();
        this.data.wizardType = "inbound";

        this.steps.push(
            new WizardStepFactory("Search order", WarehouseProceduresWizardOrderSearchComponent));
        this.steps.push(
            new WizardStepFactory("Select shipment", WarehouseProceduresWizardOutboundSelectShipmentComponent));

        this.changeDetectorRef.markForCheck();
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "procedures-inbound",
            actions: {
                "browse": ["read_reports"],
                "wizard": ["read_reports"],
            }
        };
    }

}
