<common-confirm></common-confirm>
<mat-card class="no-shadow">
    <mat-tab-group>

        <ng-template [ngIf]="orderData.service_level.order_type.slug === 'regular_rma'">
            <mat-tab *ngIf="canInbound">
                <ng-template mat-tab-label>
                    <mat-icon color="success">add_circle</mat-icon>
                    Inbound lines
                </ng-template>
                <section-order-view-items-inbound
                        #inboundTable
                        [partmasters]="partmasters"
                        [order]="orderData"
                        [state]="state"
                        (action)="handleItemAction($event.action,$event.type)"
                        (canFindBySlug)="onCanFindBySlug($event,'inbound')"
                        (onGenerateReport)="report()"
                ></section-order-view-items-inbound>
            </mat-tab>

            <mat-tab *ngIf="canOutbound">
                <ng-template mat-tab-label>
                    <mat-icon color="warn">remove_circle</mat-icon>
                    Outbound lines
                </ng-template>
                <section-order-view-items-outbound
                        #outboundTable
                        [partmasters]="partmasters"
                        [order]="orderData"
                        [state]="state"
                        (action)="handleItemAction($event.action,$event.type)"
                        (canFindBySlug)="onCanFindBySlug($event,'outbound')"
                        (onGenerateReport)="report()"
                ></section-order-view-items-outbound>
            </mat-tab>
        </ng-template>


        <ng-template [ngIf]="orderData.service_level.order_type.slug !== 'regular_rma'">
            <mat-tab *ngIf="canOutbound">
                <ng-template mat-tab-label>
                    <mat-icon color="warn">remove_circle</mat-icon>
                    Outbound lines
                </ng-template>
                <section-order-view-items-outbound
                        #outboundTable
                        [partmasters]="partmasters"
                        [order]="orderData"
                        [state]="state"
                        (action)="handleItemAction($event.action,$event.type)"
                        (canFindBySlug)="onCanFindBySlug($event,'outbound')"
                        (onGenerateReport)="report()"
                ></section-order-view-items-outbound>
            </mat-tab>

            <mat-tab *ngIf="canInbound">
                <ng-template mat-tab-label>
                    <mat-icon color="success">add_circle</mat-icon>
                    Inbound lines
                </ng-template>
                <section-order-view-items-inbound
                        #inboundTable
                        [partmasters]="partmasters"
                        [order]="orderData"
                        [state]="state"
                        (action)="handleItemAction($event.action,$event.type)"
                        (canFindBySlug)="onCanFindBySlug($event,'inbound')"
                        (onGenerateReport)="report()"
                ></section-order-view-items-inbound>
            </mat-tab>
        </ng-template>


    </mat-tab-group>
</mat-card>
