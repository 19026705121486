import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, Validators} from "@angular/forms";
import {Base} from "../../../../../../../../common/interfaces/base.interfaces";
import {ConfirmComponent} from "../../../../../../../../common/components/confirm/confirm.component";
import {Warehouse} from "../../../../../../../../common/interfaces/warehouse.interface";
import {IPagination} from "../../../../../../../../common/components/pagination/pagination.component";
import {Api} from "../../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../../common/services/toast.service";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../../common/interfaces/wizard.interface";
import {SpinnerService} from "../../../../../../../../common/services/spinner.service";
import {Api3Service} from "../../../../../../../../common/services/api3.service";
import {WarehouseService} from "../../../../../../../../common/services/warehouse.service";

@Component({
    selector: "section-warehouse-order-wizard-shuffle-packages",
    templateUrl: "shuffle.component.html",
    styleUrls: [
        "shuffle.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class WarehouseProceduresWizardShufflePackagesComponent extends AbstractWizardStepComponent
    implements OnDestroy {

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    public shipment: Warehouse.IShipment;

    public state: Base.IState;

    public transactions: IPagination<Warehouse.ITransaction>;

    public packages: any[] = [];

    public selected_tab: number = 0;

    public packageSelected: FormControl = new FormControl(null, [Validators.required]);


    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private spinnerService: SpinnerService,
    ) {
        super(changeDetectorRef);
    }


    /**
     * Get package list
     */
    private async getPackages(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.get(
            `${this.state.section}/shipments/${this.shipment.id}/packages`);
        this.spinnerService.hide();
        if (data) {
            this.packages = data;

            this.packageSelected.setValue(this.packages[0]?.id);
            this.changeDetectorRef.markForCheck();
        }
    }


    public async linkAll(): Promise<any> {
        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.api3Service.post(
            `${this.state.section}/warehouse-packages/${this.packageSelected.value}/link-all-transactions`);
        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
        }
    }

    /**
     * Initialize details step
     * @returns {Promise<any>}
     * @param data
     */
    public async init(data: Wizard.IData): Promise<any> {
        this.state = data.state;
        this.shipment = data.shipment;

        if (WarehouseService.warehouse?.properties?.default_shuffle_type == "box") {
            this.selected_tab = 1;
        }
        this.changeDetectorRef.markForCheck();


        this.getPackages();

        this.result.emit({
            action: "result",
            value: true
        });
    }


    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

}
