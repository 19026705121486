<div class="shipments">
    <mat-card *ngFor="let shipment of shipments; let index = index">
        <div>
            <mat-icon>airplanemode_active</mat-icon>
            <b>{{shipment.ref}}</b>
            ({{shipment.weight}}{{shipment.mass_unit}})
            <mat-radio-group [formControl]="selectedShipment" name="shipment_select">
                <mat-radio-button value="{{index}}"></mat-radio-button>
            </mat-radio-group>
        </div>

        <div>
            <mat-icon>flight_takeoff</mat-icon>
            <ng-template [ngIf]="shipment.from_address.formatted_address">
                {{shipment.from_address.formatted_address}},
            </ng-template>
            <ng-template [ngIf]="!shipment.from_address.formatted_address">
                {{shipment.from_address.address}},
                {{shipment.from_address.city}},
                {{shipment.from_address.country}}
            </ng-template>

        </div>

        <div>
            <mat-icon>flight_land</mat-icon>
            <ng-template [ngIf]="shipment.to_address.formatted_address">
                {{shipment.to_address.formatted_address}},
            </ng-template>
            <ng-template [ngIf]="!shipment.to_address.formatted_address">
                {{shipment.to_address.address}},
                {{shipment.to_address.city}},
                {{shipment.to_address.country}}
            </ng-template>
        </div>
    </mat-card>
</div>