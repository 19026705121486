<h1 class="container-heading">Inspection</h1>

<mat-card>
    <form *ngIf="formGroup" [formGroup]="formGroup" (submit)="submit()">
        <div class="group flex">
            <mat-form-field class="full">
                <mat-label>Form name</mat-label>
                <input matInput formControlName="name" required>
            </mat-form-field>

            <mat-form-field class="half">
                <mat-label>Service levels</mat-label>
                <mat-select formControlName="service_level_id" multiple required>
                    <mat-option *ngFor="let service_level of service_levels" [value]="service_level.id">
                        {{service_level.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="half">
                <mat-label>Hubs</mat-label>
                <mat-select formControlName="warehouse_slug" multiple required>
                    <mat-option *ngFor="let warehouse of warehouses" [value]="warehouse">
                        {{warehouse | ucfirst | replace:"_":" ":true:"g"}}
                    </mat-option>
                </mat-select>
            </mat-form-field>


            <ng-template [ngIf]="section_type === 'warehouse'">
                <mat-form-field class="full">
                    <mat-label>Partner</mat-label>
                    <mat-select [formControl]="partnerSelect" required>
                        <mat-option *ngFor="let partner of partners" [value]="partner">
                            {{partner.display_name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-template>

            <mat-form-field class="full">
                <mat-label>Description</mat-label>
                <textarea matInput formControlName="description" rows="5"></textarea>
            </mat-form-field>
            <mat-form-field class="full">
                <mat-label>Ref field name</mat-label>
                <input matInput formControlName="ref_field">
            </mat-form-field>

        </div>

        <h2>Fields:</h2>

        <div formArrayName="fields" class="fields">
            <ng-template ngFor let-field let-i="index" [ngForOf]="formGroup.controls.fields.controls">
                <ng-template [ngIf]="field.value.type !== 'group'">
                    <mat-card>
                        <div>
                            <div class="sort pull-right">
                                Sort:
                                <button mat-icon-button [disabled]="i === 0" color="primary" (click)="moveUp(i)"
                                        matTooltip="Move up" type="button">
                                    <mat-icon>arrow_drop_up</mat-icon>
                                </button>
                                <button mat-icon-button [disabled]="i === formGroup.controls.fields.controls.length -1"
                                        color="primary" (click)="moveDown(i)" matTooltip="Move down" type="button">
                                    <mat-icon>arrow_drop_down</mat-icon>
                                </button>


                                <button mat-icon-button color="warn"
                                        [disabled]="!fieldTypes[field.value.type].delete"
                                        (click)="removeField(i)" matTooltip="Remove" type="button">
                                    <mat-icon>remove</mat-icon>
                                </button>
                            </div>
                            <div class="move pull-right">
                                <input type="text" matInput #moveToPos/>
                                <button mat-icon-button color="primary" (click)="moveTo(i, 0 + moveToPos.value)"
                                        matTooltip="Move down" type="button">
                                    <mat-icon>check</mat-icon>
                                </button>
                            </div>
                            <h3>Field {{i + 1}}</h3>
                            <div class="group flex" [formGroupName]="i">
                                <mat-form-field class="half">
                                    <mat-label>Label
                                        <span class="text-error" *ngIf="field.errors?.['not_unique']">
                                            (Must be unique)
                                        </span>
                                    </mat-label>
                                    <input matInput formControlName="label" required>
                                </mat-form-field>

                                <mat-form-field class="half">
                                    <mat-label>Name (readonly)</mat-label>
                                    <input matInput formControlName="name" readonly>
                                </mat-form-field>


                                <mat-form-field class="half" *ngIf="fieldTypes[field.value.type].type">
                                    <mat-label>Type</mat-label>
                                    <mat-select formControlName="type" required>
                                        <mat-option *ngFor="let type of (fieldTypes | keys)" [value]="type"
                                                    [disabled]="type === 'status'">
                                            {{type}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field class="half" *ngIf="!fieldTypes[field.value.type].type">
                                    <mat-label>Type</mat-label>
                                    <mat-select formControlName="type" required>
                                        <mat-option [value]="field.value.type">
                                            {{field.value.type}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field class="half">
                                    <mat-label>Size</mat-label>
                                    <mat-select formControlName="size" required>
                                        <mat-option *ngFor="let size of fieldSizes" [value]="size">
                                            {{size}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <div class="half" *ngIf="fieldTypes[field.value.type].required">
                                    <mat-checkbox formControlName="required">Required</mat-checkbox>
                                </div>

                                <div class="half" *ngIf="fieldTypes[field.value.type].multiple">
                                    <mat-checkbox formControlName="multiple">Multiple</mat-checkbox>
                                </div>

                                <mat-form-field class="full" *ngIf="fieldTypes[field.value.type].options">
                                    <mat-label>Values</mat-label>
                                    <mat-chip-grid #chipGrid>
                                        <mat-chip-row matChipRemove
                                                      *ngFor="let chip of formGroup.controls.fields.controls[i].get('values').value"
                                                      [removable]="true"
                                                      (removed)="removeValue(chip, i)">
                                            {{chip}}
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip-row>
                                        <input [matChipInputFor]="chipGrid"
                                               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                               [matChipInputAddOnBlur]="true"
                                               (matChipInputTokenEnd)="addValue($event, i)"/>
                                    </mat-chip-grid>
                                </mat-form-field>

                                <mat-form-field class="full" *ngIf="fieldTypes[field.value.type].url">
                                    <mat-label>URL</mat-label>
                                    <input matInput formControlName="url">
                                </mat-form-field>

                                <mat-form-field class="full" *ngIf="fieldTypes[field.value.type].description">
                                    <mat-label>Description</mat-label>
                                    <textarea matInput formControlName="description"
                                    ></textarea>
                                </mat-form-field>

                                <mat-form-field class="half" *ngIf="fieldTypes[field.value.type].pattern">
                                    <mat-label>Pattern</mat-label>
                                    <mat-select formControlName="pattern">
                                        <mat-option *ngFor="let pattern of inputPatterns" [value]="pattern.pattern">
                                            {{pattern.label}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                            </div>
                        </div>
                    </mat-card>
                </ng-template>
                <ng-template [ngIf]="field.value.type === 'group'">
                    <mat-card>
                        <div>
                            <div class="sort pull-right">
                                Sort:
                                <button mat-icon-button [disabled]="i === 0" color="primary" (click)="moveUp(i)"
                                        matTooltip="Move up" type="button">
                                    <mat-icon>arrow_drop_up</mat-icon>
                                </button>
                                <button mat-icon-button [disabled]="i === formGroup.controls.fields.controls.length -1"
                                        color="primary" (click)="moveDown(i)" matTooltip="Move down" type="button">
                                    <mat-icon>arrow_drop_down</mat-icon>
                                </button>


                                <button mat-icon-button color="warn"
                                        [disabled]="!fieldTypes[field.value.type].delete"
                                        (click)="removeField(i)" matTooltip="Remove" type="button">
                                    <mat-icon>remove</mat-icon>
                                </button>
                            </div>
                            <div class="move pull-right">
                                <input type="text" matInput #moveToPos/>
                                <button mat-icon-button color="primary" (click)="moveTo(i, 0 + moveToPos.value)"
                                        matTooltip="Move down" type="button">
                                    <mat-icon>check</mat-icon>
                                </button>
                            </div>
                            <h3>Field {{i + 1}} [Group]</h3>
                            <div class="group flex" [formGroupName]="i">
                                <mat-form-field class="full">
                                    <mat-label>Label
                                        <span class="text-error" *ngIf="field.errors?.['not_unique']">
                                            (Must be unique)
                                        </span>
                                    </mat-label>
                                    <input matInput formControlName="label" required>
                                </mat-form-field>
                                <mat-form-field class="half">
                                    <mat-label>Trigger index</mat-label>
                                    <input matInput formControlName="triggerIndex">
                                </mat-form-field>
                                <mat-form-field class="half">
                                    <mat-label>Trigger value</mat-label>
                                    <input matInput formControlName="triggerVal">
                                </mat-form-field>
                                <div class="full">
                                    <ng-template ngFor let-jField let-j="index"
                                                 [ngForOf]="formGroup.controls.fields.controls[i].controls.fields.controls">
                                        <mat-card formArrayName="fields">
                                            <h3>Field {{j + 1}}</h3>
                                            <div class="group flex" [formGroupName]="j">
                                                <mat-form-field class="half">
                                                    <mat-label>Label
                                                        <span class="text-error" *ngIf="jField.errors?.['not_unique']">
                                                            (Must be unique)
                                                        </span>
                                                    </mat-label>
                                                    <input matInput formControlName="label"
                                                           required>
                                                </mat-form-field>

                                                <mat-form-field class="half" *ngIf="fieldTypes[jField.value.type].type">
                                                    <mat-label>Type</mat-label>
                                                    <mat-select formControlName="type" required>
                                                        <mat-option *ngFor="let type of (fieldTypes | keys)"
                                                                    [value]="type"
                                                                    [disabled]="type === 'status'">
                                                            {{type}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>

                                                <mat-form-field class="half"
                                                                *ngIf="!fieldTypes[jField.value.type].type">
                                                    <mat-label>Type</mat-label>
                                                    <mat-select formControlName="type" required>
                                                        <mat-option [value]="jField.value.type">
                                                            {{jField.value.type}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>

                                                <mat-form-field class="half">
                                                    <mat-label>Size</mat-label>
                                                    <mat-select formControlName="size" required>
                                                        <mat-option *ngFor="let size of fieldSizes" [value]="size">
                                                            {{size}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>

                                                <div class="half" *ngIf="fieldTypes[jField.value.type].required">
                                                    <mat-checkbox formControlName="required">Required</mat-checkbox>
                                                </div>

                                                <div class="half" *ngIf="fieldTypes[jField.value.type].multiple">
                                                    <mat-checkbox formControlName="multiple">Multiple</mat-checkbox>
                                                </div>

                                                <mat-form-field class="full"
                                                                *ngIf="fieldTypes[jField.value.type].options">
                                                    <mat-label>Values</mat-label>
                                                    <mat-chip-grid #chipGrid>
                                                        <mat-chip-row matChipRemove
                                                                      *ngFor="let chip of formGroup.controls.fields.controls[i].controls.fields.controls[j].get('values').value"
                                                                      [removable]="true"
                                                                      (removed)="removeValue(chip, i)">
                                                            {{chip}}
                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                        </mat-chip-row>
                                                        <input [matChipInputFor]="chipGrid"
                                                               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                               [matChipInputAddOnBlur]="true"
                                                               (matChipInputTokenEnd)="addValue($event, i)"/>
                                                    </mat-chip-grid>
                                                </mat-form-field>

                                                <mat-form-field class="full" *ngIf="fieldTypes[jField.value.type].url">
                                                    <mat-label>URL</mat-label>
                                                    <input matInput formControlName="url">
                                                </mat-form-field>

                                                <mat-form-field class="full"
                                                                *ngIf="fieldTypes[jField.value.type].description">
                                                    <mat-label>Description</mat-label>
                                                    <textarea matInput formControlName="description"
                                                    ></textarea>
                                                </mat-form-field>

                                                <mat-form-field class="half"
                                                                *ngIf="fieldTypes[jField.value.type].pattern">
                                                    <mat-label>Pattern</mat-label>
                                                    <mat-select formControlName="pattern">
                                                        <mat-option *ngFor="let pattern of inputPatterns"
                                                                    [value]="pattern.pattern">
                                                            {{pattern.label}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>

                                            </div>
                                        </mat-card>
                                    </ng-template>
                                </div>
                                <div class="actions full">
                                    <button
                                            (click)="addField(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, i)"
                                            type="button" class="mat-mdc-raised-button mdc-button mat-primary">
                                        Add field
                                    </button>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </ng-template>
            </ng-template>
        </div>

        <div class="actions">
            <button (click)="addField()" type="button" class="mat-mdc-raised-button mdc-button mat-primary">Add field
            </button>
            <button (click)="addGroup()" type="button" class="mat-mdc-raised-button mdc-button mat-primary">Add group
            </button>
        </div>

        <div class="actions">
            <button type="submit" [disabled]="!formGroup.valid"
                    class="mat-mdc-raised-button mdc-button mat-primary main">Save
            </button>
            <button type="button" class="mat-mdc-raised-button mdc-button mat-primary pull-right" (click)="preview()">
                Preview
            </button>
        </div>


    </form>
</mat-card>
