import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from "@angular/core";
import {Router} from "@angular/router";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {UserService} from "../../../../../../common/services/user.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {environment} from "../../../../../../../environments/environment";
import {Api3Service} from "../../../../../../common/services/api3.service";
import {Table2Component} from "../../../../../../common/components/table2";
import {Table} from "../../../../../../common/interfaces/table.interface";

@Component({
    selector: "section-threepl-list",
    templateUrl: "list.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThreeplListComponent implements Base.IComponent, OnInit {

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    public listTable: Table.ISettings;

    public readonly state: Base.IState;

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private userService: UserService,
        private spinnerService: SpinnerService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        const actions: Table.IAction[] = [];
        if (this.userService.validatePermissions("edit_threepls")) {
            actions.push({
                name: "edit",
                title: "Edit",
                click: (row: any): void => {
                    this.router.navigate([this.state.section, this.state.component, "edit", "id", row.id]);
                }
            });
        }

        if (this.userService.validatePermissions("delete_threepls")) {
            actions.push({
                name: "delete",
                title: "Delete",
                click: (row: any): void => {
                    this.delete(row);
                }
            });
        }

        this.listTable = {
            table_id: "r6t8xbgl",
            actions,
            api: {
                url: ["admin", "threepls"],
                version: 3
            },
            columns: [
                {
                    data: "id",
                    title: "ID"
                },
                {
                    data: "display_name",
                    title: "Display name"
                },
                {
                    data: "slug",
                    title: "Code (slug)"
                },
                {
                    data: "created_at",
                    title: "Creation date"
                }
            ],
            search_default: ["id", "display_name"],
        };

        this.changeDetectorRef.markForCheck();
    }

    private async delete(row: any): Promise<any> {
        if (await this.confirmRef.confirm(`Do you want to delete "${row.display_name}?"`)) {
            this.spinnerService.show();
            const {data, message}: Api.IResponse =
                await this.apiService.request(Api.EMethod.Delete, ["threepl", row.id]);
            this.spinnerService.hide();
            if (data) {
                this.toastService.show(message, "success");
                this.tableRef.reload();
            }
        }
    }

    public async exportWarehouses(): Promise<any> {
        this.spinnerService.show();

        const {data, message}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get, "/admin/warehouses",
            {}, {
                export: "csv",
                sort: {
                    data: "name",
                    dir: "asc"
                },
                file_name: "warehouses",
                relations: [
                    "Threepl",
                    "address",
                    "contact"
                ],
                columns: [
                    ["ThreePL.display_name", "ThreePL"],
                    ["slug", "WH"],
                    ["name", "Name"],
                    ["type", "Type"],
                    ["timezone", "TimeZone"],
                    ["cut_off", "Cut off"],
                    ["address.address_name", "Address name"],
                    ["address.country_iso_2", "Country"],
                    ["address.address", "Address"],
                    ["address.address2", "Address 2"],
                    ["address.city", "City"],
                    ["address.zip", "Zip code"],
                    ["address.state", "State"],
                    ["contact.first_name", "Contact"],
                    ["contact.last_name", "Contact"],
                    ["contact.email", "Email"],
                    ["contact.phone", "Phone"],
                    ["contact.fax", "Fax"],
                    ["is_dc", "Distribution center"],
                    ["is_inspection", "Can manage inspections"],
                    ["is_sw_upgrade", "Can manage software upgrades"],
                    ["is_active", "IS active"],
                ]
            });

        this.spinnerService.hide();
        if (data) {
            if (data[0]) {
                window.open(environment.apiUrl + "/export_file/" + data[0], "_blank");
            }
        }
    }

    public ngOnInit(): void {
        this.prepareList();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "threepl",
            actions: {
                "browse": ["browse_threepls"]
            }
        };
    }

}
