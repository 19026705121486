import {ChangeDetectionStrategy, Component} from "@angular/core";
import {Modal} from "../../../section/services/modal.service";

@Component({
    selector: "common-modal-table2",
    template: `
        <h1 class="container-heading">
            {{modal.params.title}}
        </h1>
        <mat-card>
            <mat-card-content>
                <common-table2 [settings]="modal.params.settings"
                               [search]="!!modal.params.search"
                ></common-table2>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalTable2Component {

    public modal: Modal.IModal;

}
