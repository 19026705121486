import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, ViewEncapsulation} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../common/interfaces/wizard.interface";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {HelpersService} from "../../../../../../../common/services/helpers.service";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {ModalService} from "../../../../../../services/modal.service";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {takeUntil} from "rxjs/operators";
import {Warehouse} from "../../../../../../../common/interfaces/warehouse.interface";

@Component({
    selector: "section-partner-procedures-wizard-update-batch",
    templateUrl: "update-batch.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class PartnerProceduresWizardUpdateBatchComponent extends AbstractWizardStepComponent implements OnDestroy {

    private state: Base.IState;

    public selected_inventory_sum: number = 0;

    public part_masters: any[] = [];

    public hubs: { group: string, hubs: Warehouse.IHub[] }[] = [];

    public form: FormGroup = new FormGroup({
        inventory_id: new FormControl(null),
        part_master_id: new FormControl(null, [Validators.required]),
        inventory_conversion_id: new FormControl(null, [Validators.required]),
        batch: new FormControl(null),
        quantity: new FormControl(null, [Validators.required]),
        remark: new FormControl(null, [Validators.required]),
    });

    public inventories: { inventory: Warehouse.IInventory, sum: number } [];

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        public helperService: HelpersService,
        private apiService: ApiService,
        private toastService: ToastService,
        private modalService: ModalService,
        private spinnerService: SpinnerService
    ) {
        super(changeDetectorRef);
    }

    private async getPartMasters(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["partmaster"], {},
            {
                data_structure: "select",
                in_stock: true
            });
        if (data) {
            this.part_masters = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    public async getInventories(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["inventory", "available", "by_order_item"],
            {
                part_master_id: this.form.value.part_master_id,
                inventory_conversion_id: this.form.value.inventory_conversion_id,
                with_analogs: false,
                relations: [
                    "WarehouseLocation:id,location"
                ]
            });
        this.spinnerService.hide();
        if (data) {
            const inventories: { [key: string]: { inventory: Warehouse.IInventory, sum: number } } = {};
            for (const inventory of data) {
                const key: string = inventory.item + ":" + (inventory.configurations || "")
                    + ":" + (inventory.rev || "") + ":" + (inventory.batch || "")
                    + ":" + (inventory.warehouse_location_id || null);

                if (inventories[key]) {
                    inventories[key].sum += inventory.quantity;
                } else {
                    inventories[key] = {
                        inventory,
                        sum: inventory.quantity
                    };
                }
            }

            this.inventories = Object.values(inventories);

            this.changeDetectorRef.markForCheck();
        }
    }

    public onPMSelected(value: any): void {
        if (value) {
            this.form.get("part_master_id").setValue(value);
            this.form.get("quantity").reset();
            this.form.get("inventory_conversion_id").reset();
            this.inventories = null;
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Submit form
     */
    public async submit(): Promise<any> {
        this.spinnerService.show();
        const {type, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["inventory", "item-batch-change"], this.form.value);
        if (type as string === "success") {
            this.toastService.show(message, "success");
            this.form.reset();
            this.inventories = [];
            this.hubs = [];
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    public selectInventory(inventory: { inventory: Warehouse.IInventory, sum: number }): void {
        this.form.get("inventory_id").setValue(inventory.inventory.id);
        this.selected_inventory_sum = inventory.sum;

        this.changeDetectorRef.markForCheck();
    }

    /**
     * Initialize step
     * @param data
     * @returns {Promise<any>}
     */
    public async init(data: Wizard.IData): Promise<any> {
        this.state = data.state;

        this.result.emit({
            action: "result",
            value: true
        });

        this.getPartMasters();

        this.form.get("inventory_conversion_id").valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((id: number): void => {
                this.form.get("quantity").reset();
                if (id) {
                    setTimeout((): void => {
                        this.getInventories();
                    }, 10);
                }
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
