<h1 class="container-heading">{{action | ucfirst}} remark classification</h1>

<mat-card>
    <mat-card-content>
        <form *ngIf="formGroup" [formGroup]="formGroup" (submit)="submit()">
            <div class="group flex">
                <mat-form-field class="half">
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="name" required>
                </mat-form-field>

                <div class="half">
                    <common-form-select
                            label="Assigned team"
                            [options]="teams"
                            [value]="formGroup.value.team_id"
                            [search]="true"
                            [required]="true"
                            (valueChange)="formGroup.get('team_id').setValue($event)"></common-form-select>
                </div>
            </div>

            <div class="actions">
                <button mat-raised-button color="primary" type="submit" class="main" [disabled]="!formGroup.valid">
                    {{action | ucfirst}}
                </button>
                &nbsp;
                &nbsp;
                <button mat-button type="button" (click)="close()">Cancel</button>
            </div>
        </form>
    </mat-card-content>
</mat-card>
