<div class="container-columns">
    <mat-card class="left">
        <button mat-mini-fab color="success" class="pull-right" (click)="editParcel('add')">
            <mat-icon>add</mat-icon>
        </button>
        <h3>Shipment parcels</h3>
        <ng-template [ngIf]="parcels && parcels.data">
            <div class="parcels">
                <div class="parcel" *ngFor="let parcel of parcels.data; let index = index">
                    <div class="select-parcel pull-right">
                        <mat-radio-group [formControl]="selectedParcel" name="parcel_select">
                            <mat-radio-button [value]="parcel.id"> Select parcel</mat-radio-button>
                        </mat-radio-group>
                        <div>
                            <mat-icon (click)="editParcel('edit', parcel)" class="pull-right pointer">edit</mat-icon>
                        </div>
                    </div>
                    <div class="description">
                        <div>
                            <b>{{parcel.ref}}</b>
                        </div>
                        <div *ngIf="parcel.weight && parcel.height && parcel.length">
                            {{parcel.weight}}x{{parcel.height}}x{{parcel.length}}
                            {{parcel.distance_unit || ""}}
                        </div>
                        <div *ngIf="parcel.weight">
                            {{parcel.weight || 'N/A'}} kg
                        </div>

                    </div>

                    <ng-template [ngIf]="parcel.order_item_paginated && parcel.order_item_paginated.data">
                        <div class="items drag-bag id-{{parcel.id}}" [dragula]="dragulaBagName">
                            <ng-template ngFor let-item [ngForOf]="parcel.order_item_paginated.data">
                                <div class="item {{item.status.name.toLowerCase()}} id-{{item.id}}">
                                    <div class="title">
                                        <span>Item: <b>{{item.item}}</b></span>
                                        <span>Serial: <b>{{item.serial}}</b></span>
                                        <span>Qty: <b>{{item.quantity}}</b></span>
                                        <span>Type: <b>{{item.type}}</b></span>
                                        <span>Warehouse: <b>{{item.inventory_conversion.warehouse_slug}}</b></span>
                                        <span>Sub-inventory: <b>{{item.inventory_conversion.sub_inventory}}</b></span>
                                        <span>
                            GB:
                            <span class="chip {{item.inventory_conversion.gb}}">
                                {{item.inventory_conversion.gb}}
                            </span>
                        </span>
                                    </div>
                                    <div>Status: <b>{{item.status.name}}</b></div>
                                    <div>Description: <b>{{item.part_master.description}}</b></div>
                                </div>
                            </ng-template>
                        </div>
                        <common-pagination [data]="parcel.order_item_paginated"
                                           pid="6lHSl55Ht"
                                           (goToPage)="getParcelItems(parcel, $event.page, $event.per_page)">
                        </common-pagination>
                    </ng-template>
                </div>
            </div>
            <common-pagination [data]="parcels"
                               pid="x2eCIl29"
                               (goToPage)="getShipmentParcels($event.page, $event.per_page)"></common-pagination>
        </ng-template>
    </mat-card>

    <mat-card class="right">
        <h3>Unlinked items</h3>
        <mat-form-field class="search">
            <mat-label>Search</mat-label>
<input matInput #freeItemsSearch type="search" >
        </mat-form-field>
        <div class="items drag-bag" [dragula]="dragulaBagName">

            <ng-template [ngIf]="freeItems">
                <div *ngFor="let item of freeItems.data"
                     class="item {{item.status.name.toLowerCase()}} id-{{item.id}}">
                    <div class="title">
                        <span>Item: <b>{{item.item}}</b></span>
                        <span>Serial: <b>{{item.serial}}</b></span>
                        <span>Qty: <b>{{item.quantity}}</b></span>
                        <span>Type: <b>{{item.type}}</b></span>
                        <span>Warehouse: <b>{{item.inventory_conversion.warehouse_slug}}</b></span>
                        <span>Sub-inventory: <b>{{item.inventory_conversion.sub_inventory}}</b></span>
                        <span>
                            GB:
                            <span class="chip {{item.inventory_conversion.gb}}">
                                {{item.inventory_conversion.gb}}
                            </span>
                        </span>
                    </div>
                    <div>Status: <b>{{item.status.name}}</b></div>
                    <div>Description: <b>{{item.part_master.description}}</b></div>
                </div>
            </ng-template>
        </div>
        <common-pagination *ngIf="freeItems" [data]="freeItems"
                           pid="V9lapL74"
                           (goToPage)="getShipmentParcels($event.page, $event.per_page)"></common-pagination>
    </mat-card>
</div>
