import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {Base} from "../interfaces/base.interfaces";
import {AppStateService} from "./app-state.service";
import {UserService} from "./user.service";
import {Identify, init, identify, track} from "@amplitude/analytics-browser";

@Injectable()
export class AmplitudeService {

    constructor(private userService: UserService) {
        setTimeout(() => {
            const user: any = this.userService.data;
            init(environment.amplitude.key, user.id, {minIdLength: 1});

            const identifyObj: Identify = new Identify();
            identifyObj.set("environment", environment.server);
            identifyObj.set("user_name", user.name);
            identifyObj.set("role_id", user.roles[0].id);
            identifyObj.set("role_name", user.roles[0].name);

            const state: Base.IState = AppStateService.getState();
            identifyObj.set("section", state.section_type);
            identifyObj.set(state.section_type + "_slug", state.section_slug);
            identify(identifyObj);
        }, 500);
    }

    public static eventClick(message, properties: any = {}): void {
        const state: Base.IState = AppStateService.getState();
        properties["section"] = state.section_type;
        properties[state.section_type + "_slug"] = state.section_slug;
        properties["environment"] = environment.server;
        properties["page_component"] = state.component;

        track(message, properties);
    }

}
