import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from "@angular/core";
import {Router} from "@angular/router";
import {ConfirmComponent} from "../../../../../common/components/confirm/confirm.component";
import {Api, ApiService} from "../../../../../common/services/api.service";
import {ToastService} from "../../../../../common/services/toast.service";
import {UntypedFormBuilder, FormGroup, Validators} from "@angular/forms";
import {SpinnerService} from "../../../../../common/services/spinner.service";

@Component({
    selector: "section-sub-warehouse-list",
    templateUrl: "sub-warehouse.component.html",
    styles: [
        "h3{margin-top:30px}"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubWarehouseListComponent implements OnInit {

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    public newForm: FormGroup;

    public items: any[] = [];

    public constructor(
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        private formBuilder: UntypedFormBuilder,
        private apiService: ApiService,
        private toastService: ToastService,
        private spinnerService: SpinnerService
    ) {
    }

    /**
     * Service level form "constructor"
     * @returns {FormGroup}
     * @param opts
     */
    private item(opts: {
        id: number,
        sub_inventory: string
    }): FormGroup {
        return this.formBuilder.group({
            id: [opts.id],
            sub_inventory: [opts.sub_inventory, [Validators.required]],
        });
    }

    /**
     * Add service level to array
     * @param opts
     */
    private addItem(opts: {
        id: number,
        sub_inventory: string
    }): void {
        this.items.push(this.item(opts));
    }

    private async getItems(): Promise<any> {
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["warehouse", "sub"]);

        this.items = [];
        if (response.data) {
            for (const item of response.data) {
                this.addItem({
                    id: item.id,
                    sub_inventory: item.sub_inventory
                });
            }
            this.changeDetectorRef.markForCheck();
        }
    }


    /**
     * Prepare form to add new service level
     */
    private prepareForm(): void {
        this.newForm = this.formBuilder.group({
            sub_inventory: [null, [Validators.required]],
        });
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Submit new service level
     * @returns {Promise<any>}
     */
    public async submitAdd(): Promise<any> {
        this.spinnerService.show();

        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Post, ["warehouse", "sub"],
            {...this.newForm.value});
        this.toastService.show(response.message, response.type as string);
        if (response.data) {
            this.addItem({
                id: response.data.id,
                sub_inventory: this.newForm.value.sub_inventory
            });
            this.newForm.reset();
            this.changeDetectorRef.markForCheck();
        }

        this.spinnerService.hide();
    }

    /**
     * Update existing service level
     * @param {number} index
     * @returns {Promise<any>}
     */
    public async submitUpdate(index: number): Promise<any> {
        this.spinnerService.show();
        const id: number = this.items[index].value.id;

        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["warehouse", "sub", String(id)], {...this.items[index].value});
        this.toastService.show(response.message, response.type as string);
        if (response.data) {
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Delete service level
     * @param {number} index
     * @returns {Promise<any>}
     */
    public async submitDelete(index: number): Promise<any> {
        if (!await this.confirmRef.confirm("Are you sure want to delete this sub-warehouse?")) {
            return;
        }
        this.spinnerService.show();
        const id: number = this.items[index].value.id;

        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
            ["warehouse", "sub", String(id)]);
        this.toastService.show(response.message, response.type as string);
        if (response.data) {
            this.items.splice(index, 1);
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }


    public ngOnInit(): void {
        this.prepareForm();
        this.getItems();
    }
}
