<div class="row flex">
    <mat-icon style="width: 36px; height: 36px; font-size: 36px; margin-right: 10px;">home</mat-icon>
    <div class="max">
        {{ shipment.warehouse?.name }} ({{ shipment.warehouse?.type }})
        <br>
        Hub: <b>{{ shipment.parcel?.order_item?.inventory_conversion?.customers_inventory_name }}</b>
    </div>

    <button *ngIf="shipment?.warehouse?.contacts?.length"
            mat-mini-fab type="button" matTooltip="Contact on shift"
            color="primary" (click)="showContactInfo()">
        <mat-icon>call</mat-icon>
    </button>
</div>

<br>

<div>
    <div *ngIf="shipment.warehouse?.threepl">
        Vendor/Three PL:
        <b>{{ shipment.warehouse.threepl.display_name }}</b>
    </div>

    <div *ngIf="shipment.warehouse?.cut_off">
        Cut-off:
        <b>{{ shipment.warehouse.cut_off }}</b>
    </div>

    <div *ngIf="shipment.warehouse?.timezone">
        Local time:
        <b>{{ localTime| async }}</b>
    </div>
</div>

<br>

<div>
    Internal:
    <ng-container *ngIf="!editInternalRef; else editInternalRefInput">
        <ng-container *ngIf="shipment.warehouse_order?.internal_ref">
            <b>{{ shipment.warehouse_order.internal_ref }}</b>
            <mat-icon class="icon-small pointer" color="primary" (click)="editInternalRef = true">edit</mat-icon>
        </ng-container>

        <ng-container *ngIf="!shipment.warehouse_order?.internal_ref">
            <mat-icon color="primary" class="icon-small pointer" (click)="editInternalRef = true">add</mat-icon>
        </ng-container>
    </ng-container>

    <ng-template #editInternalRefInput>
        <mat-form-field>
            <input type="text" matInput [formControl]="internalRefInput">
            <span matSuffix>
                <mat-icon *ngIf="internalRefInput.valid" class="text-success pointer" (click)="updateInternalRef()">
                    save
                </mat-icon>
                <mat-icon class="text-error pointer" (click)="clearInternalRef()">close</mat-icon>
            </span>
        </mat-form-field>
    </ng-template>

    <br>
    Status: <b>{{ shipment.warehouse_order?.status?.name }}</b><br/>

    <ng-template [ngIf]="shipment.warehouse_order?.requested_on">
        <br/>Requested date: <b>{{ shipment.warehouse_order.requested_on }}</b>
    </ng-template>

    <ng-template [ngIf]="shipment.warehouse_order?.ref">
        Ref: <b>{{ shipment.warehouse_order.ref }}</b>
    </ng-template>

    <div *ngIf="shipment.warehouse_order.short_url">
        Viewed by WH: <b>{{ shipment.warehouse_order.short_url.clicks > 0 ? "Yes" : "No" }}</b>
    </div>

</div>
<br>
<div class="warehouse-order-buttons">

    <mat-icon *ngIf="shipment.type === 'inbound'"
              class="vertical-middle pointer"
              (click)="goToWarehouseScanWizard('inbound')"
              style="color: green"
              matTooltip="Inbound procedure">
        arrow_downward
    </mat-icon>


    <mat-icon *ngIf="shipment.type === 'outbound'"
              (click)="goToWarehouseScanWizard('outbound')"
              class="vertical-middle pointer"
              style="color:red"
              matTooltip="Outbound procedure">
        arrow_upward
    </mat-icon>

    &nbsp;
    &nbsp;
    &nbsp;

    <ng-container *userAllowed="'add_order_holds'">
        <a *ngIf="!hasHold()"
           class="mat-mdc-raised-button mdc-button mat-accent"
           (click)="setOnHold()">
            Set on-hold
        </a>
    </ng-container>

    <ng-container *userAllowed="'delete_order_holds'">
        <a *ngIf="hasHold()"
           class="mat-mdc-raised-button mdc-button mat-accent"
           (click)="removeMyHold()">
            Remove on-hold
        </a>
    </ng-container>

</div>
<br>
