<h1 class="container-heading">Label</h1>

<mat-card>
    <mat-card-content>
        <form [formGroup]="formGroup">
            <div class="group flex">
                <mat-form-field class="full">
                    <mat-label>Label name</mat-label>
                    <input type="text" matInput formControlName="name">
                </mat-form-field>
            </div>

            <mat-radio-group class="group flex" formControlName="size_type">
                <div class="third">
                    <mat-radio-button [value]="1">Select size</mat-radio-button>
                </div>
                <mat-form-field class="third2" formGroupName="size">
                    <mat-select formControlName="id">
                        <mat-select-trigger>
                            {{showSizeString(getSelectedSize(formGroup.value.size.id))}}
                        </mat-select-trigger>
                        <mat-option *ngFor="let size of labelSizes" [value]="size.id">
                            {{showSizeString(size)}}
                            <span class="pull-right line-height-inherit">
                                <mat-icon (click)="deleteSize(size.id)" color="warn"
                                          matTooltip="Delete"
                                          class="margin-right-0">delete</mat-icon>
                            </span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="full">
                    <mat-radio-button [value]="2">Custom size</mat-radio-button>
                </div>
            </mat-radio-group>

            <div class="group flex" formGroupName="size">
                <mat-form-field class="full">
                    <mat-label>Size name</mat-label>
                    <input type="text" matInput formControlName="name"
                           [readonly]="formGroup.value.size_type === 1">
                </mat-form-field>

                <mat-form-field class="half">
                    <mat-label>Width, mm</mat-label>
<input type="text" matInput formControlName="width"
                           [readonly]="formGroup.value.size_type === 1">
                </mat-form-field>

                <mat-form-field class="half">
                    <mat-label>Height, mm</mat-label>
<input type="text" matInput formControlName="height"
                           [readonly]="formGroup.value.size_type === 1">
                </mat-form-field>

                <div class="full text-right">
                    <button mat-raised-button color="accent" (click)="saveSize()"
                            [disabled]=" formGroup.value.size_type=== 1">
                        Save size
                    </button>
                </div>
            </div>

            <div class="group flex">
                <div class="full">
                    <common-form-editor [value]="editorInitialValue"
                                        (valueChange)="formGroup.get('body').setValue($event)"></common-form-editor>
                </div>
            </div>
        </form>

        <mat-divider [inset]="true" class="margin-bottom-20"></mat-divider>

        <h2 class="margin-bottom-20" style="max-width: 600px; margin: 0 auto;">Preview:</h2>

        <div class="label-preview" #sticker
             [style.width.mm]="formGroup.value.size.width"
             [style.height.mm]="formGroup.value.size.height"
             [innerHTML]="trustHtml(formGroup.value.body)">
        </div>

        <div class="actions padding-top-20">
            <button mat-raised-button color="accent" (click)="print()">
                <mat-icon>print</mat-icon>
                Print
            </button>
            &nbsp;
            &nbsp;
            <button mat-raised-button color="primary" (click)="saveLabel()">
                Save
            </button>
            &nbsp;
            &nbsp;
            <button *ngIf="state.params.id" mat-raised-button color="warn" (click)="deleteLabel()">
                Delete
            </button>
            &nbsp;
            &nbsp;
            <button mat-raised-button (click)="goToList()">
                Close
            </button>
        </div>

    </mat-card-content>
</mat-card>
<common-confirm></common-confirm>
