import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {UserService} from "../../../../../../common/services/user.service";
import {Router} from "@angular/router";
import {DragulaService} from "ng2-dragula";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Order} from "../../../../../../common/interfaces/order.interface";
import {takeUntil} from "rxjs/operators";
import {Warehouse} from "../../../../../../common/interfaces/warehouse.interface";

import {Modal, ModalService} from "../../../../../services/modal.service";
import {WarehouseTransactionFormComponent} from "../../../../threepl/warehouse/order";
import {FormControl} from "@angular/forms";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {ModalComponent} from "../../../../../../common/components/modal/modal.component";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {RemarksSidebarComponent} from "../../../../common/components/order";

@Component({
    selector: "section-adjust-transactions-view",
    templateUrl: "view.component.html",
    styleUrls: [
        "view.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class AdjustTransactionsViewComponent implements Base.IComponent, OnInit, OnDestroy {

    /**
     * Component destroy event emmiter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild("sidebar", {static: false})
    public sidebarRef: RemarksSidebarComponent;

    /**
     * Order data
     * @type {Order.IOrderData}
     */
    public orderData: Order.IOrderData;

    @Input()
    public orderId: string;

    @Input()
    public state: Base.IState;

    public orderItems: any[];

    public orderTransactions: Warehouse.ITransaction[];

    public showGoToAdmin: boolean = false;

    public tpl_title: string = null;

    public showInbound: FormControl = new FormControl(false);

    public selectedList: number[] = [];

    public allUnlinkedTransactionsSelected: boolean = false;

    public allOrderItemTransactionSelected: boolean[] = [];

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private title: Title,
        private apiService: ApiService,
        private userService: UserService,
        private dragulaService: DragulaService,
        private toastService: ToastService,
        private modalService: ModalService,
        private spinnerService: SpinnerService
    ) {

        if (!dragulaService.find("transaction-bag")) {
            dragulaService.createGroup("transaction-bag", {
                moves: (el, container, handle) => {
                    return handle.classList.contains("handle");
                }
            });
        }
        this.dragulaService.dropModel("transaction-bag").pipe(takeUntil(this.destroy$))
            .subscribe((value: any): void => {
                this.onDropModel(value);
            });
    }

    /**
     * Handle drop event
     * @returns {Promise<any>}
     */
    private async onDropModel(value: any): Promise<any> {
        const transaction: any = value.el;
        const target: any = value.target;
        const container: any = value.source;

        let transactionId: string;
        transaction.classList.forEach((className: string): void => {
            if (/id-[0-9]+/.test(className)) {
                transactionId = className.replace("id-", "");
            }
        });

        let targetId: number = null;
        target.classList.forEach((className: string): void => {
            if (/id-[0-9]+/.test(className)) {
                targetId = Number(className.replace("id-", ""));
            }
        });

        if (transactionId && this.selectedList.indexOf(Number(transactionId)) === -1) {
            this.selectedList = [Number(transactionId)];
        }

        if (this.selectedList.length) {
            this.spinnerService.show();
            for (const tId of this.selectedList) {
                const response: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
                    ["transaction", "" + tId, "link_order_item"],
                    {order_item_id: targetId});

                this.toastService.show(response.message, response.type as string);
            }
            this.spinnerService.hide();

            this.getOrderItems();
            this.getOrderTransactions();
            this.selectedList = [];
            this.checkAllSelected();

        }
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Get order data
     * @returns {Promise<any>}
     */
    private async getOrderData(): Promise<any> {
        if (!this.orderId) {
            return;
        }
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["order", this.orderId]);
        if (data) {
            this.orderData = data;
            this.title.setTitle(this.title.getTitle() + " " + this.orderData.ref);

            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private async getOrderItems(): Promise<any> {
        this.allOrderItemTransactionSelected = [];
        this.selectedList = [];

        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["order", this.orderData.ref, "items", this.showInbound.value ? "inbound" : "outbound"],
            {}, {
                with_transactions: true,
                with_count: [
                    "OrderRemarks"
                ]
            });
        this.spinnerService.hide();

        if (data) {
            this.orderItems = data;
            for (const item of this.orderItems) {
                this.allOrderItemTransactionSelected.push(false);
            }
        }

        this.changeDetectorRef.markForCheck();
    }

    private async getOrderTransactions(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["order", this.orderData.ref, "transactions", this.showInbound.value ? "inbound" : "outbound"],
            {}, {
                linked_to_order_item: false,
                relations: [
                    "InventoryConversion.Warehouse",
                    "NotSkippedValidationErrors",
                    "Order:id,ref"
                ]
            });
        if (data) {
            this.orderTransactions = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private checkAllSelected(): void {
        this.allUnlinkedTransactionsSelected = false;
        this.allOrderItemTransactionSelected = [];

        this.allUnlinkedTransactionsSelected = this.orderTransactions.length
            && this.orderTransactions.every((it: any) => this.selectedList.indexOf(it.id) > -1);

        for (const index in this.orderItems) {
            this.allOrderItemTransactionSelected[index] = this.orderItems[index].warehouse_transactions.length
                && this.orderItems[index].warehouse_transactions.every((it: any) =>
                    this.selectedList.indexOf(it.id) > -1);
        }

        this.changeDetectorRef.markForCheck();
    }

    public goToOrder(): void {
        this.router.navigate([
            this.state.section,
            "orders",
            "view",
            "id",
            this.orderId
        ]);
    }

    public showErrors(transaction: Warehouse.ITransaction): void {
        this.modalService.open(ModalComponent, {
            title: "Errors",
            template: transaction.not_skipped_validation_errors
                .map((e: { validation_errors: string }): string => e.validation_errors).join("<br>")
        });
    }

    /**
     * Show remarks in sidebar
     * @param {boolean} force
     */
    public showSidebar(force: boolean = false): void {
        this.sidebarRef.show(this.orderData.id, this.orderData.ref, force);
    }

    public async editTransaction(data: any): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(WarehouseTransactionFormComponent, {
            action: "edit",
            order_id: this.orderData.id,
            type: data.type,
            modalWidth: 600,
            data,
            partner: this.orderData.partner,
            state: this.state,
            warehouse_slug: data.inventory_conversion?.warehouse_slug
        });

        if (response) {
            this.getOrderTransactions();
        }
    }

    public selectedListUpdate(event: MatCheckboxChange): void {

        const itemId: number = Number(event.source.value);
        if (event.checked) {
            if (this.selectedList.indexOf(itemId) === -1) {
                this.selectedList.push(itemId);
            }
        } else {
            const index: number = this.selectedList.indexOf(itemId);
            if (index > -1) {
                this.selectedList.splice(index, 1);
            }
        }

        this.checkAllSelected();
    }

    public selectAll(event: MatCheckboxChange, itemsToSelect: any[]): void {
        this.selectedList = [];

        if (event.checked) {
            for (const item of itemsToSelect) {
                this.selectedList.push(item.id);
            }
        }
        this.checkAllSelected();
        this.changeDetectorRef.markForCheck();
    }

    public async ngOnInit(): Promise<any> {

        if (!this.orderId) {
            this.orderId = this.state.params.id;
        }

        if (this.userService.validatePermissions("browse_admin")) {
            this.showGoToAdmin = true;
        }

        this.changeDetectorRef.markForCheck();

        if (!this.orderData) {
            await this.getOrderData();
        }
        this.getOrderItems();
        this.getOrderTransactions();

        this.tpl_title = this.state.params.type === "adjust"
            ? "Adjust order transactions" : "Transactions without items";

        this.showInbound.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((): void => {
            this.getOrderItems();
            this.getOrderTransactions();
        });
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "adjust-transactions",
            actions: {
                "view": ["edit_warehouse_transactions"]
            }
        };
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
