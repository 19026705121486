import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {FileUploadComponent} from "../../../../../../common/components/file-upload/file-upload.component";
import {ToastService} from "../../../../../../common/services/toast.service";
import {RequestFormComponent} from "../../requests";
import {Order} from "../../../../../../common/interfaces/order.interface";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Router} from "@angular/router";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {User} from "../../../../../../common/interfaces/user.interface";
import {AmplitudeService} from "../../../../../../common/services/amplitude.service";
import {ConfirmComponent} from "src/modules/common/components/confirm/confirm.component";

@Component({
    selector: "common-section-remark",
    templateUrl: "remark.component.html",
    styleUrls: [
        "remark.component.scss",
    ],
    animations: [
        trigger("slideInOut", [
            state("full", style({
                height: "*",
            })),
            state("cut", style({
                height: "100px",
            })),
            state("hide", style({
                height: "0"
            })),
            transition("cut => full", animate("400ms ease-in-out")),
            transition("hide => full", animate("400ms ease-in-out")),
            transition("full => hide", animate("400ms ease-in-out"))
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class RemarkComponent implements AfterViewInit, OnInit {

    private textHeight: number = 0;

    @Input()
    public state: Base.IState;

    @Input()
    public data: any;

    @Input()
    public parentId: number;

    @Input()
    public remarkTypes: { id: number, name: string }[];

    @Input()
    public orderRef: string;

    @Input()
    public hideActions: boolean = false;

    @Input()
    public hideRequests: boolean = false;

    @Input()
    public maxId: number;

    @Input()
    public mentionUsers: User.IMentionUser[] = [];

    @Input()
    public readForAll: boolean = false;

    @Input()
    public showMarkRead: boolean = false;

    @Input()
    public readOnView: boolean = true;

    @Input()
    public showOrderButton: boolean = false;

    @Input()
    public showSidebarButton: boolean = false;

    @Input()
    public enableColors: boolean = true;

    @Input()
    public isChild: boolean = false;

    @Output()
    public reloadEvent: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public maxIdEvent: EventEmitter<number> = new EventEmitter<number>();

    @Output()
    public action: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild("text", {static: false})
    public textEl: ElementRef;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    public textCut: boolean = false;

    public textAnimationState: string = "cut";

    public showReply: string = "hide";

    public userDomain: string = "";

    public formGroup: FormGroup = new FormGroup({
        "subject": new FormControl(null, []),
        "order_remark_type_id": new FormControl(null, [Validators.required]),
        "message": new FormControl(null, [Validators.required]),
        "parent_id": new FormControl(null)
    });

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private modalService: ModalService,
        private toastService: ToastService,
        private spinnerService: SpinnerService,
        private router: Router,
    ) {
    }


    private async removeUnread(): Promise<any> {
        await this.apiService.request(Api.EMethod.Delete, ["activities", "unread"], {
            related_model: "App\\OrderRemark",
            related_model_id: this.data.id,
            read_for_all: this.readForAll
        });
    }

    /**
     * Show specific icon depending on attachment extension
     * @param {string} extension
     * @returns {string}
     */
    public getIconByExt(extension: string): string {
        switch (extension) {
            case "png":
            case "jpg":
            case "gif":
                return "image";
            default:
                return "insert_drive_file";
        }
    }

    /**
     * Expand text animation trigger
     */
    public expandCut(): void {
        this.textAnimationState = "full";
        this.textCut = false;
    }

    public getRemarkTypeName(id: number | string): string {
        for (const type of this.remarkTypes) {
            if (type.id === Number(id)) {
                return type.name;
            }
        }
        return null;
    }

    public async submitReply(): Promise<any> {
        this.spinnerService.show();
        const body: any = this.formGroup.value;
        body.parent_id = this.parentId ? this.parentId : body.parent_id;
        body.order_remark_type_id = this.data.order_remark_type_id;
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["remark", "order", this.orderRef], {...body});
        if (response.type as string === "success") {
            this.reloadEvent.emit();
        }

        this.spinnerService.hide();
    }

    public async changeRemarkType(type_id: number): Promise<any> {
        this.spinnerService.show();
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["remark", "order", this.data.id], {
                "subject": this.data.subject,
                "message": this.data.message,
                "order_remark_type_id": type_id,
                "parent_id": this.data.parent_id
            });
        if (response.type as string === "success") {
            this.reloadEvent.emit();
        }

        this.spinnerService.hide();
    }

    public async deleteRemark(): Promise<any> {
        if (!await this.confirmRef
            .confirm("Are you sure you want to delete this remark? This action cannot be undone.")) {
            return;
        }
        this.spinnerService.show();
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
            ["remark", "order", this.data.id]);
        if (response.type as string === "success") {
            this.reloadEvent.emit();
        }

        this.spinnerService.hide();
    }

    public async addAttachment(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(FileUploadComponent, {
            url: ["remark", "order", this.data.id, "attachment"]
        });

        if (response && response.value) {
            this.toastService.show(response.value.message, "success");
            this.reloadEvent.emit();
        }
    }

    public async convertToRequest(remark: Order.IRemark): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(RequestFormComponent, {
            remark_id: remark.id,
            order_remark_type_id: remark.order_remark_type_id,
        });

        if (response && response.name === "submit") {
            this.reloadEvent.emit();
        }
        AmplitudeService.eventClick("Convert to request (remarks)", {
            order: this.data.order.ref,
            orders_remarks: this.data.uid
        });
    }

    /**
     * Take id of item to parent component
     * @param {number} id
     * @returns {string}
     */
    public setMaxId(id: number): string {
        this.maxIdEvent.emit(id);
        return null;
    }

    public markRead(): void {
        this.spinnerService.show();
        this.removeUnread().then((): void => {
            this.spinnerService.hide();
            this.reloadEvent.emit();
        });
        AmplitudeService.eventClick("Mark read (remarks)", {
            order: this.data.order.ref,
            orders_remarks: this.data.uid
        });
    }

    public goToOrder(): void {
        this.router.navigate([
            "/partner",
            this.data.partner.slug,
            "orders",
            "view",
            "id",
            this.data.order.id
        ]);
        AmplitudeService.eventClick("Go to order (remarks)", {
            order: this.data.order.ref,
            orders_remarks: this.data.uid
        });
    }

    public showSidebar(): void {
        this.action.emit({
            action: "show_sidebar",
            value: this.data
        });
        AmplitudeService.eventClick("Show all (remarks)", {
            order: this.data.order.ref,
            orders_remarks: this.data.uid
        });
    }

    public ngOnInit(): void {
        this.formGroup.get("subject").setValue(this.data.subject);
        if (this.data.parent_id) {
            this.formGroup.get("parent_id").setValue(this.data.parent_id);
        } else {
            this.formGroup.get("parent_id").setValue(this.data.id);
        }
        this.formGroup.get("order_remark_type_id").setValue(this.data.order_remark_type_id);
        if (this.data.owner) {
            const split_mail: any = this.data.owner.email.split("@");
            if (split_mail.length > 1) {
                const split_domain: any = split_mail[1].split(".");
                this.userDomain = split_domain[0];
            }
        }

        if (this.showMarkRead) {
            this.readOnView = false;
        }

        if (this.data.unread && this.readOnView) {
            this.removeUnread();
        }
    }

    public ngAfterViewInit(): void {
        if (this.textEl) {
            this.textHeight = this.textEl.nativeElement.offsetHeight;
            if (this.textHeight > 100) {
                this.textCut = true;
            } else {
                this.textAnimationState = "full";
            }
            this.changeDetectorRef.markForCheck();
        }
    }

}
