<mat-card>
    <div>
        <button class="pull-right" matTooltip="Export pick list"
                type="button" mat-icon-button (click)="exportPackList('pdf')">
            <i class="fa fa-file-pdf-o"></i>
        </button>

        <button class="pull-right" matTooltip="Export pick list"
                type="button" mat-icon-button (click)="exportPackList('excel')">
            <i class="fa fa-file-excel-o"></i>
        </button>


        <h1 class="color-accent text-center">Select package and scan boxes</h1>

        <div class="search">
            <div class="info" *ngIf="packages">
                <mat-icon>move_to_inbox</mat-icon>
                <mat-form-field>
                    <mat-label>Package</mat-label>
                    <mat-select [formControl]="selectedPackage">
                        <mat-option *ngFor="let pack of packages" [value]="pack.id">
                            {{pack.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                &nbsp;
                <!--            <button type="button" mat-raised-button color="accent">Show contents</button>-->
                &nbsp;
                <button type="button" mat-raised-button color="primary" (click)="newPackadge()">Add package</button>
            </div>
            <div *ngIf="serialTemp" class="margin-bottom-10">
                Scanned serial <b class="color-accent">{{serialTemp}}</b>
                <mat-icon color="warn" class="pointer clear" (click)="serialTemp = null" matTooltip="Clear serial">
                    clear
                </mat-icon>
            </div>
            <div *ngIf="locationTemp" class="margin-bottom-10">
                Scanned location <b class="color-accent">{{locationTemp}}</b>
                <ng-template [ngIf]="boxTemp">
                    .&nbsp;Scanned box <b class="color-accent">{{boxTemp}}</b>
                </ng-template>
                <mat-icon color="warn" class="pointer clear" (click)="locationTemp = null; boxTemp = null"
                          matTooltip="Clear location">
                    clear
                </mat-icon>
            </div>
            <div class="search-field" *ngIf="selectedPackage.value">
                <mat-form-field>
                    <mat-label>{{searchInputLabel}}</mat-label>
                    <input matInput type="search"  [formControl]="searchInput"
                           [scannerOnly]="scannerOnly"
                           (keydown)="onKeydown($event)" #searchInputRef>
                </mat-form-field>
                <button type="button" mat-raised-button color="accent" (click)="find()">
                    <i class="fa fa-barcode"></i> Search
                </button>
            </div>
            Your scanner default key set as {{ scannerKey }}.
            <span *ngIf="scannerOnly" class="color-warn">
            Manual input disabled
        </span>

            <div>
                Total boxes: <b class="color-accent">{{totalBoxesInOrder}}</b>
            </div>
        </div>

        <div class="boxes-wrap" *ngIf="selectedPackage.value && locations">
            <common-pagination [data]="locations"
                               pid="B1nm6oR4"
                               (goToPage)="getLocations($event.page,$event.per_page)"></common-pagination>

            <div *ngFor="let location of locations.data">
                <div class="location-summary color-accent text-center margin-bottom-20" *ngIf="location">
                    <b>{{location.location}} ({{location.boxes.length || 0}})</b>
                    <ng-template
                            [ngIf]="location.warehouse_location_type.id === 2 && location.boxes.length === location.c">
                        &nbsp;
                        <button class="vertical-middle" mat-raised-button color="warn" [disabled]="true">
                            Outbound location
                        </button>
                    </ng-template>
                </div>
                <div class="boxes">
                    <ng-template ngFor let-box [ngForOf]="location.boxes" let-i="index">
                        <div class="box"
                             [class.green]="box.warehouse_transactions_count > 0"
                             [class.red]="showFreeBoxes">
                            <div class="ref">
                                {{box.ref}}
                            </div>
                            <div class="logo" *ngIf="box.partner">
                                <span></span>
                                <img [src]="box.partner.icon_path" alt="">
                            </div>
                            <!--<div class="items background-accent" *ngIf="!showFreeBoxes">-->
                            <!--{{box.warehouse_transactions_count}} items-->
                            <!--</div>-->
                            <div class="items background-accent">
                                {{box.inventory_allocations_count}} allocations
                            </div>
                            <div class="buttons">
                                <button mat-mini-fab color="primary"
                                        type="button" (click)="openBox(box)">
                                    <mat-icon>list_alt</mat-icon>
                                </button>
                                <button mat-mini-fab color="primary"
                                        type="button" (click)="printSticker(box)">
                                    <mat-icon>print</mat-icon>
                                </button>
                            </div>
                        </div>
                    </ng-template>
                </div>
                <div [class.shelf]="location.warehouse_location_type.id === 1"
                     [class.palet]="location.warehouse_location_type.id === 2"
                     [ngStyle]="{'background-image': 'url('+location.warehouse_location_type.icon+')'}"></div>
            </div>

            <h3 class="text-center color-accent" *ngIf="!locations.data.length">Nothing to scan</h3>
        </div>
    </div>
</mat-card>
