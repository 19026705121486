import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";

import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {Modal, ModalService} from "../../../../../../services/modal.service";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {Form} from "../../../../../../../common/interfaces/form.interface";
import {TeamService} from "../../../../../../../common/services/team.service";
import ISelectOption = Form.ISelectOption;


@Component({
    selector: "section-remarks-classifications-form",
    templateUrl: "form.component.html",
    styleUrls: [
        "form.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class RemarksClassificationsFormComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    /**
     * Form group / group with controls
     * @type {FormGroup}
     */
    public formGroup: FormGroup = new FormGroup({
        name: new FormControl(null, [Validators.required]),
        team_id: new FormControl(null, [Validators.required]),
    });

    public modal: Modal.IModal;

    public action: string = "add";

    public teams: ISelectOption[] = [];

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private toastService: ToastService,
        private modalService: ModalService,
        private router: Router,
        private http: HttpClient,
        private spinnerService: SpinnerService,
        private teamService: TeamService
    ) {
    }

    /**
     * Prepare form data
     */
    private prepareData(): void {
        if (this.modal.params && this.modal.params.data) {
            this.formGroup.patchValue(this.modal.params.data);
            this.action = "edit";
            this.changeDetectorRef.markForCheck();
        }
    }


    /**
     * Get teams list
     * @returns {Promise<any>}
     */
    private async getTeams(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.teamService.getTeams({
            data_structure: "select",
            category: "notification"
        });

        this.spinnerService.hide();
        if (data) {
            this.teams = data;
            this.changeDetectorRef.markForCheck();
        }
    }


    /**
     * Create remark classification
     * @returns {Promise<any>}
     */
    public async createRemarkClassification(): Promise<any> {
        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["remark", "classification"], this.formGroup.value);

        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.modal.response.emit({
                name: "ok"
            });
        }
    }

    /**
     * Update remark classification
     * @returns {Promise<any>}
     */
    public async updateRemarkClassification(): Promise<any> {
        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["remark", "classification", "" + this.modal.params.data.id], this.formGroup.value);
        this.spinnerService.hide();

        if (code === 200) {
            this.toastService.show(message, "success");
            this.modal.response.emit({
                name: "ok"
            });
        }
    }


    /**
     * Submit form
     */
    public submit(): void {
        if (this.modal.params && this.modal.params.data) {
            this.updateRemarkClassification();
        } else {
            this.createRemarkClassification();
        }
    }

    /**
     * Close modal
     */
    public close(): void {
        this.modal.response.emit();
    }

    public ngOnInit(): void {
        this.getTeams();
        this.prepareData();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
