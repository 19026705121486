import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: "prettyJson"
})
export class PrettyJsonPipe implements PipeTransform {
    public transform(value: any): string {
        if (typeof value === "string" || typeof value === "number") {
            return "" + value;
        }
        return JSON.stringify(value, null, 2);
    }
}
