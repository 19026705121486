import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit, Output, ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {User} from "../../../../../../common/interfaces/user.interface";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {debounceTime, takeUntil} from "rxjs/operators";
import {ToastService} from "../../../../../../common/services/toast.service";
import {ModalService} from "../../../../../services/modal.service";
import {UserService} from "../../../../../../common/services/user.service";
import {Contact} from "../../../../../../common/interfaces/contact.interface";
import {ReplaySubject} from "rxjs";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {ImageUploadService} from "../../../../../../common/services/image-upload.service";
import {CommonFormComponent} from "../../../../../../common/components/form";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {AdminUserService} from "../../../../admin/services/user.service";

@Component({
    selector: "section-user-view-settings",
    templateUrl: "settings.component.html",
    styleUrls: [
        "settings.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class UserViewSettingsComponent implements OnInit, OnDestroy {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild(ConfirmComponent, {static: true})
    public confirm: ConfirmComponent;

    @Input()
    public user: User.IData;

    @Input()
    public state: Base.IState;

    @Output()
    public dataUpdated: EventEmitter<any> = new EventEmitter<any>();

    public countries: Contact.ICountry[] = [];

    public countriesFiltered: ReplaySubject<Contact.ICountry[]> =
        new ReplaySubject<Contact.ICountry[]>(1);

    public countryFilter: FormControl = new FormControl(null);

    public proForm: FormGroup = new FormGroup({
        name: new FormControl(null, [Validators.required]),
        email: new FormControl(null, [Validators.required, Validators.email]),
        slack_name: new FormControl(null),
        avatar: new FormControl(null),
        phone: new FormControl(null),
        country: new FormControl(null),
        settings: new FormGroup({
            default_route: new FormControl(null, [Validators.required]),
            threepl_default_route: new FormControl(null, [Validators.required]),
            default_per_page: new FormControl(null),
        })
    });

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private userService: UserService,
        private modalService: ModalService,
        private imageUploadService: ImageUploadService,
        private spinnerService: SpinnerService,
        private adminUserService: AdminUserService,
    ) {
    }

    /**
     * Get roles list
     * @returns {Promise<any>}
     */
    private async getCountriesList(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["address", "country"]);
        if (data) {
            this.countries = data.filter((country: Contact.ICountry): boolean => country.phone_code !== null);
            this.countries
                .forEach((country: Contact.ICountry, index: number, self: Contact.ICountry[]): void => {
                    if (country.phone_code.indexOf(",") > 0) {
                        const codes: string[] = country.phone_code.split(",");
                        codes.forEach((code: string): void => {
                            const copy: Contact.ICountry = {...country};
                            copy.phone_code = code.trim();
                            self.push(copy);
                        });
                        self.splice(index, 1);
                    }
                });
            this.countriesFiltered.next(this.countries);
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }


    /**
     * Fill profile form fields with user data
     */
    public async prepareProfile(): Promise<any> {
        this.spinnerService.show();
        if (this.state.component !== "profile") {
            const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
                ["user", this.state.params.id]);
            if (data) {
                this.user = data;
            }
        } else {
            this.user = this.userService.data;
        }

        this.proForm.patchValue(this.user);

        if (this.user.phone) {
            const [code, phone]: string[] = this.user.phone.split(" ", 2);
            this.proForm.get("phone").setValue(phone);
            const codeNoPlus: string = code.replace("+", "");
            const country: Contact.ICountry = this.countries
                .find((c: Contact.ICountry): boolean => c.phone_code === codeNoPlus);
            this.proForm.get("country").setValue(country);
        }
        this.spinnerService.hide();
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Upload user avatar to cloudinary
     */
    public async uploadAvatar(): Promise<any> {
        const image_url: string = await this.imageUploadService.uploadAvatar();

        if (image_url) {
            this.proForm.get("avatar").setValue(image_url);
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Change user password
     */
    public async changePassword(): Promise<any> {
        const response: any = await this.modalService.open(CommonFormComponent, {
            formConfig: {
                id: 0,
                name: "Change password",
                description: "Please enter password and confirmation",
                fields: [
                    {
                        label: "Password",
                        name: "password",
                        size: "full",
                        type: "password",
                        required: true,
                    },
                    {
                        label: "Repeat password",
                        name: "password_confirmation",
                        size: "full",
                        type: "password",
                        required: true,
                    },
                    {
                        label: "",
                        name: "user_id",
                        size: "full",
                        type: "hidden",
                        required: true,
                    }
                ]
            },
            values: {
                user_id: this.user.id
            },
            submitUrl: ["user", "update_password"],
            method: Api.EMethod.Put
        });
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Save user profile
     */
    public async saveProfile(): Promise<any> {
        this.spinnerService.show();
        const body: any = {...this.proForm.value};
        if (this.proForm.value.country && this.proForm.value.phone) {
            const code: string = "+" + this.proForm.value.country.phone_code;
            const phone: string = this.proForm.value.phone;
            body.phone = code + " " + phone;
        }
        delete body.country;
        const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["user", "" + this.user.id], body);
        if (type as string === "success") {
            this.toastService.show(message, "success");
            this.prepareProfile();
        }
        this.spinnerService.hide();
    }

    public async reset2fa(): Promise<any> {
        if (this.state.section !== "admin") {
            return;
        }

        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.adminUserService.reset2FaUser(this.user.id);
        this.spinnerService.hide();

        if (code === 200) {
            this.toastService.show(message, "success");
            this.dataUpdated.emit();
        }

    }

    public async disable(): Promise<any> {
        if (this.state.section !== "admin") {
            return;
        }

        if (!(await this.confirm.confirm(`This action will remove user from all partners, threepls,
 teams and access tokens.<br> Action can not be reverted!<br><br><b>Proceed?</b>`))) {
            return;
        }

        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.adminUserService.deactivateUser(this.user.id);
        this.spinnerService.hide();

        if (code === 200) {
            this.toastService.show(message, "success");
            this.dataUpdated.emit();
        }

    }

    public async ngOnInit(): Promise<any> {

        if (this.state.section === "admin") {
            this.proForm.addControl("force2fa", new FormControl(false));
            this.proForm.addControl("force_change_password", new FormControl(false));
        }

        this.getCountriesList().then((): void => {
            this.prepareProfile();
        });

        this.countryFilter.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: string): void => {
                if (value) {
                    this.countriesFiltered.next(
                        this.countries.filter((country: Contact.ICountry): boolean => {
                            return country.country_name.toLowerCase().indexOf(value.toLowerCase()) > -1
                                || (country.phone_code && country.phone_code
                                    .indexOf(value.replace("+", ""))) > -1;
                        })
                    );
                } else {
                    this.countriesFiltered.next(this.countries);
                }
                this.changeDetectorRef.markForCheck();
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
