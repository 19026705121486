import {Directive, EventEmitter, HostBinding, HostListener, Input, Output, Type} from "@angular/core";
import {Modal, ModalService} from "../../section/services/modal.service";
import {NgControl} from "@angular/forms";

@Directive({
    selector: "[sectionControl]"
})
export class ControlDirective {

    /**
     * Is modal open
     * @type {boolean}
     */
    private isOpen: boolean = false;

    @Input()
    public sectionControl: { components?: { [key: string]: any }, params?: any } = {};

    @HostBinding("class.pointer")
    public classPointer: boolean = true;

    @Output()
    public update: EventEmitter<any> = new EventEmitter<any>(true);

    public constructor(private ngControl: NgControl, private modalService: ModalService) {
    }

    /**
     * Open modal (list or form)
     * @param {string} type
     * @returns {Promise<any>}
     */
    @HostListener("click")
    @HostListener("focus")
    private async open(type: string = "list"): Promise<any> {
        if (!this.isOpen && Object.keys(this.sectionControl.components || {}).length) {
            this.isOpen = true;
            const response: Modal.IResponse =
                await this.modalService.open(this.sectionControl.components[type], this.sectionControl.params || {});
            if (response) {
                if (response.name === "form") {
                    this.isOpen = false;
                    await this.open("form");
                }
                if (response.name === "list") {
                    this.isOpen = false;
                    await this.open();
                }
                if (response.name === "value") {
                    const {controlValue, viewValue}: any = response.value || {};
                    if (controlValue) {
                        this.ngControl.control.setValue(controlValue);
                    }
                    if (viewValue) {
                        this.ngControl.valueAccessor.writeValue(viewValue);
                    }
                    this.update.emit(response.value);
                }
            }
            this.isOpen = false;
        }
    }

}
