import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewEncapsulation} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Router} from "@angular/router";

@Component({
    selector: "section-inspection-form",
    template: `
        <common-form [configUrl]="['inspection', state.params.id]"
                     [submitUrl]="['inspection', 'form', state.params.id]"
                     [configSection]="'inspection'"
                     (onSubmit)="submit($event)"></common-form>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    styleUrls: [
        "form.component.scss"
    ],
})
export class InspectionFormComponent implements Base.IComponent {

    public readonly state: Base.IState;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private toastService: ToastService,
        private router: Router
    ) {
    }

    /**
     * Submit form
     * @return {Promise<any>}
     */
    public async submit(response: Api.IResponse): Promise<any> {
        if (response.code === 200) {
            this.router.navigate([
                this.state.section,
                "inspections"
            ]);
        }
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "inspections",
            actions: {
                "form": ["add_inspection_forms"]
            }
        };
    }
}
