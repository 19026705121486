import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {GoogleChartsService} from "../../../services/google-chart.service";
import {DrawerService} from "../../../../app/services/drawer.service";
import {debounceTime, takeUntil} from "rxjs/operators";

@Component({
    selector: "common-line-chart",
    template: `
        <div class="scroll-x">
            <div class="chart line" [class.ready]="readyClass" #chart></div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class LineChartComponent implements OnChanges, OnInit, OnDestroy {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input()
    public data: [Date, number][];

    @Input()
    public title: string;

    @Input()
    public hAxis: any;

    @Input()
    public vAxis: any;

    @Output()
    public init: EventEmitter<boolean> = new EventEmitter();

    @Output()
    public ready: EventEmitter<boolean> = new EventEmitter();

    @ViewChild("chart", {static: true})
    public div: ElementRef;

    public readyClass: boolean = false;

    public constructor(
        private chartsService: GoogleChartsService,
        private changeDetectorRef: ChangeDetectorRef,
        private drawerService: DrawerService
    ) {
    }

    private drawChart(): void {
        const dataTable: any = new this.chartsService.api.visualization.arrayToDataTable(this.data);
        if (this.div.nativeElement) {
            const chart: any = new this.chartsService.api.visualization.LineChart(this.div.nativeElement);
            this.chartsService.api.visualization.events.addListener(chart, "ready", (): void => {
                this.readyClass = true;
                this.ready.emit(true);
                this.changeDetectorRef.markForCheck();
            });
            try {
                chart.draw(dataTable, {
                    title: this.title,
                    backgroundColor: "transparent",
                    curveType: "function",
                    hAxis: this.hAxis,
                    vAxis: this.vAxis,
                    legend: {position: "none"}
                });
            } catch (e) {
                console.error(e);
            }
        }
    }

    public ngOnInit(): void {
        this.init.emit(true);
        this.drawerService.drawerLockState.pipe(takeUntil(this.destroy$), debounceTime(100))
            .subscribe((): void => {
                this.drawChart();
            });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.div && this.data && this.data.length > 1) {
            const callback: any = (): any => this.drawChart();
            this.chartsService.load(callback, ["corechart"], {
                mapsApiKey: "AIzaSyA5Oz8GT45WIGqkLA4DsLPvSoIY9B19KBQ"
            });
        }
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
