import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {User} from "../../../../../../common/interfaces/user.interface";
import {Order} from "../../../../../../common/interfaces/order.interface";
import {IPagination} from "../../../../../../common/components/pagination/pagination.component";
import {ToastService} from "../../../../../../common/services/toast.service";
import {UserService} from "../../../../../../common/services/user.service";
import {Team} from "../../../../../../common/interfaces/team.interface";
import {FormControl} from "@angular/forms";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {ReplaySubject} from "rxjs";

@Component({
    selector: "section-team-view",
    templateUrl: "view.component.html",
    styleUrls: [
        "view.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class TeamViewComponent implements Base.IComponent, OnInit {

    public showSection: number = 1;

    public user: User.IData;

    public userSearch: FormControl = new FormControl(null);
    public usersList: User.IData[] = [];
    public usersListFiltered: ReplaySubject<User.IData[]> = new ReplaySubject(1);

    public team: Team.ITeamData;

    public readonly state: Base.IState;

    public remarks: IPagination<Order.IRemark>;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private userService: UserService,
        private spinnerService: SpinnerService
    ) {
    }

    private async getData(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["team", this.state.params.id]);

        if (data) {
            this.team = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private async updateTeam(): Promise<any> {
        this.spinnerService.show();
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["team", "" + this.state.params.id, "link_users"], {
                users: this.team.users.map((user: any): number => Number(user.id))
            });
        if (response) {
            this.toastService.show(response.message, response.type as string);
            this.getData();
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private async getUsers(): Promise<any> {
        this.spinnerService.show();
        const [type, slug]: string[] = this.state.section.split("/", 2);
        let path: string[] = [type, "users"];
        if (type === "admin") {
            path = ["user", "list"];
        }
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, path);
        if (data) {
            this.usersList = data;
            this.usersListFiltered.next(this.usersList);
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    public addUser(event: MatAutocompleteSelectedEvent): void {
        this.team.users.push(event.option.value);
        this.userSearch.reset();
        this.updateTeam();
    }

    public removeUser(index: number): void {
        this.team.users.splice(index, 1);
        this.updateTeam();
    }

    public async ngOnInit(): Promise<any> {
        this.user = this.userService.data;
        this.getData();
        this.getUsers();
        this.userSearch.valueChanges.subscribe((value: string): void => {
            this.usersListFiltered.next(this.usersList.filter((user: User.IData): boolean =>
                user.email.includes(value)));
        });
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "team",
            actions: {
                "view": ["read_teams"]
            }
        };
    }


}
