import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {Table} from "../../../../../../common/components/table/table.component";
import {Modal} from "../../../../../services/modal.service";


@Component({
    selector: "section-order-track-content",
    template: `
        <h1 class="container-heading">Shipment content</h1>
        <mat-card>
            <mat-card-content>
                <common-table *ngIf="listTable" [options]="listTable" [data]="modal.params.data"></common-table>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})

export class OrderTrackContentComponent implements OnInit {

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.IOptions;

    public modal: Modal.IModal;

    public constructor(private changeDetectorRef: ChangeDetectorRef) {
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        const actions: Table.Action.ISettings[] = [];
        this.listTable = {
            actions,
            columns: [
                {
                    data: "item",
                    title: "Item"
                },
                {
                    data: "serial",
                    title: "Serial"
                },
                {
                    data: "configurations",
                    title: "Configurations"
                },
                {
                    data: "batch",
                    title: "Batch"
                },
                {
                    data: "quantity",
                    title: "Quantity"
                },
                {
                    data: "inventory_conversion.customers_inventory_name",
                    title: "Customers inventory name"
                }, {
                    data: "inventory_conversion.customers_sub_inventory",
                    title: "Customers sub inventory"
                },
                {
                    data: "inventory_conversion.gb",
                    title: "Gb"
                },
                {
                    data: "ref",
                    title: "Ref"
                },
                {
                    data: "ref2",
                    title: "Ref2"
                },
            ],
            order: [],
            search: false
        };
        this.changeDetectorRef.markForCheck();
    }

    public ngOnInit(): void {
        this.prepareList();
    }
}
