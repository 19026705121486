<h1 class="container-heading">Edit shipped at</h1>

<mat-card>
    <mat-card-content>
        <form *ngIf="formGroup" [formGroup]="formGroup" (submit)="handleFormSubmit()" autocomplete="off" novalidate>

            <div class="group flex">

                <div class="half" *ngIf="formGroup.get('shipped_at')">
                    <common-form-date-time
                            label="Shipped at"
                            [value]="formGroup.value.shipped_at"
                            [required]="true"
                            (valueChangeMoment)="formGroup.get('shipped_at').setValue($event)"
                    ></common-form-date-time>
                </div>

                <div class="half">
                    <common-form-select
                            label="Ship date timezone"
                            [options]="timezones"
                            [search]="true"
                            [required]="true"
                            [value]="formGroup.value.shipped_at_tz"
                            (valueChange)="formGroup.get('shipped_at_tz').setValue($event)"
                    ></common-form-select>
                </div>


                <div class="actions">
                    <button [disabled]="!this.formGroup || !this.formGroup.valid"
                            mat-raised-button color="primary" type="submit" class="main">
                        Save
                    </button>
                </div>
            </div>

        </form>
    </mat-card-content>
</mat-card>
