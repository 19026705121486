import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {UntypedFormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {LoginService} from "../../../common/services/login.service";
import {Api, ApiService} from "../../../common/services/api.service";
import {StorageService} from "../../../common/services/storage.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {User} from "../../../common/interfaces/user.interface";
import {UserService} from "../../../common/services/user.service";
import {CommonFormComponent} from "../../../common/components/form";
import {ModalService} from "../../../section/services/modal.service";
import {ToastService} from "../../../common/services/toast.service";
import {environment} from "../../../../environments/environment";

@Component({
    selector: "app-login",
    templateUrl: "login.component.html",
    styleUrls: [
        "login.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    /**
     * Login form control group
     * @type {FormGroup}
     */
    public form: FormGroup = this.formBuilder.group({
        username: [null, [Validators.required]],
        password: [null, [Validators.required]]
    });

    /**
     * Is progress bar visible
     * @type {boolean}
     */
    public progress: boolean = false;

    public is2fa: boolean = false;

    public force2fa: boolean = false;

    public user: User.IData;

    public iosOn: boolean = false;

    public showPass: boolean = false;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private title: Title,
        private formBuilder: UntypedFormBuilder,
        private loginService: LoginService,
        private apiService: ApiService,
        private storageService: StorageService,
        private router: Router,
        private userService: UserService,
        private activatedRoute: ActivatedRoute,
        private toastService: ToastService,
        private modalService: ModalService
    ) {
    }

    private show2fa(): void {
        this.form.addControl("secret",
            new FormControl(null, [Validators.required]));
        this.is2fa = true;
        this.changeDetectorRef.markForCheck();
    }

    private async openResetPasswordModal(email: string, token: string): Promise<any> {
        await this.modalService.open(CommonFormComponent, {
            formConfig: {
                id: 0,
                name: "New password",
                description: "Fill in new password and confirm password",
                fields: [
                    {
                        label: "Password",
                        name: "password",
                        size: "full",
                        type: "password",
                        required: true,
                    },
                    {
                        label: "Repeat password",
                        name: "password_confirmation",
                        size: "full",
                        type: "password",
                        required: true,
                    }
                ]
            },
            preconfiguredValues: {
                "email": email,
                "token": token
            },
            notCloseOnError: true,
            submitUrl: ["user", "reset_password"],
            method: Api.EMethod.Post
        });
    }

    public async openForgotPasswordModal(): Promise<any> {
        await this.modalService.open(CommonFormComponent, {
            formConfig: {
                id: 0,
                name: "Reset password",
                description: "Enter your email. Reset password message will be sent to this email.",
                fields: [
                    {
                        label: "Email",
                        name: "email",
                        size: "full",
                        type: "email",
                        required: true,
                    },
                ]
            },
            submitUrl: ["user", "forgot_password"],
            method: Api.EMethod.Post
        });
    }

    /**
     * Login
     * @returns {Promise<any>}
     */
    public async login(): Promise<any> {
        if (this.form.valid && !this.progress) {
            this.progress = true;
            this.storageService.clean();
            const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Post, "token", {
                grant_type: "password",
                client_id: environment.oauth.client_id,
                client_secret: environment.oauth.client_secret,
                ...this.form.value,
            });

            if (data && data["2fa"]) {
                this.show2fa();
            } else if (this.loginService.setToken(data)) {
                await this.userService.getUser();
                this.user = this.userService.data;
                if (this.user.force2fa && (!this.user.user2fa || !this.user.user2fa.enabled)) {
                    this.force2fa = true;
                } else {
                    this.goToEntrance();
                }
            }
            this.progress = false;
            this.changeDetectorRef.markForCheck();
        }
    }

    public async sendCodeToEmail(): Promise<any> {
        if (this.form.value.username && this.form.value.password && !this.progress) {
            this.progress = true;
            this.storageService.clean();
            const {code, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Post, "token", {
                grant_type: "password",
                send_key_to_email: true,
                ...this.form.value,
            });
            if (code === 200) {
                this.toastService.show(message, "success");
            }
        }
        this.progress = false;
        this.changeDetectorRef.markForCheck();
    }

    public goToEntrance(): void {
        this.router.navigateByUrl("/entrance");
    }

    public isIos(): any {
        const userAgent: any = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test(userAgent);
    }

    public isInStandaloneMode(): any {
        return ("standalone" in window.navigator) && (window.matchMedia("(display-mode: standalone)").matches);
    }

    public ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe((params: Params): void => {
            if (params.forgot && params.token && params.email) {
                this.openResetPasswordModal(params.email, params.token);
            }
        });
        this.title.setTitle("Logivice / Login");

        if (this.loginService.token) {
            this.user = this.userService.data;
            if (this.user.force2fa && (!this.user.user2fa || !this.user.user2fa.enabled)) {
                this.force2fa = true;
                this.changeDetectorRef.markForCheck();
            }
        }

        this.loginService.loginState.subscribe((loggedIn: boolean): void => {
            if (!loggedIn) {
                this.force2fa = false;
                this.changeDetectorRef.markForCheck();
            }
        });
        if (this.isIos() && !this.isInStandaloneMode()) {
            this.iosOn = true;
        }
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
