<mat-expansion-panel *ngIf="item && item.items" [expanded]="expanded">

    <mat-expansion-panel-header expandedHeight="48px" collapsedHeight="48px" (click)="toggle($event)">
        <mat-panel-title class="title">
            <i *ngIf="item.icon" class="material-icons">{{item.icon}}</i>
            <i *ngIf="item.iconFa" class="fa fa-{{item.iconFa}}"></i>
            {{item.title}}
        </mat-panel-title>
    </mat-expansion-panel-header>

    <ng-template ngFor let-childItem let-childIndex="index" [ngForOf]="item.items">
        <div *ngIf="allowed(childItem.permissions)  && canSee(childItem)" class="sub-items">

            <ng-template [ngIf]="childItem && childItem.modal" [ngIfElse]="elseBlock">
                <a routerLinkActive="active" class="link" [routerLinkActiveOptions]="{exact: true}"
                   (click)=" onChild(index, childItem); openModal(childItem)">
                    <i *ngIf="childItem.icon" class="material-icons">{{childItem.icon}}</i>
                    <i *ngIf="childItem.iconFa" class="fa fa-{{childItem.iconFa}}"></i>
                    {{childItem.title}}
                </a>
            </ng-template>

            <ng-template #elseBlock>
                <a *ngIf="childItem && childItem.link" [routerLink]="childItem.link"
                   routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                   class="link" (click)="onChild(index, childItem)">
                    <i *ngIf="childItem.icon" class="material-icons">{{childItem.icon}}</i>
                    <i *ngIf="childItem.iconFa" class="fa fa-{{childItem.iconFa}}"></i>
                    {{childItem.title}}
                </a>

                <ng-template [ngIf]="childItem && childItem.items">
                    <app-drawer-items [item]="childItem"
                                      [index]="childIndex"
                                      [section]="section"
                                      [expanded]="childItem.expanded"
                                      (childEvent)="onChild($event, childItem)"></app-drawer-items>
                </ng-template>
            </ng-template>
        </div>
    </ng-template>

</mat-expansion-panel>
