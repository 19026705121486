<common-confirm></common-confirm>

<h1 class="container-heading">{{state.action === "add" ? "Add" : "Edit"}} Branch</h1>
<mat-card>
    <mat-card-content>
        <div class="form">
            <mat-tab-group dynamicHeight>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon>description</mat-icon>
                        Main details
                    </ng-template>
                    <div class="content padding-top-20 padding-bottom-20">
                        <form [formGroup]="branch" autocomplete="off">

                            <mat-radio-group formControlName="term">
                                <div class="group flex">
                                    <div class="half">
                                        <div class="row flex no-wrap align-center">
                                            <div>
                                                <mat-radio-button value="EOR"></mat-radio-button>
                                            </div>
                                            <mat-form-field class="max">
                                                <mat-label *ngIf="branch.value.eor_name !=='EOR'">EOR</mat-label>
                                                <mat-select formControlName="eor_name">
                                                    <mat-option value="EOR">EOR</mat-option>
                                                    <mat-option value="Exporter">Exporter</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                    </div>
                                    <div class="half">
                                        <div class="row flex no-wrap align-center">
                                            <div>
                                                <mat-radio-button value="IOR"></mat-radio-button>
                                            </div>
                                            <mat-form-field class="max">
                                                <mat-label *ngIf="branch.value.ior_name !=='IOR'">IOR</mat-label>
                                                <mat-select formControlName="ior_name">
                                                    <mat-option value="IOR">IOR</mat-option>
                                                    <mat-option value="Importer">Importer</mat-option>
                                                    <mat-option value="Bill To">Bill To</mat-option>
                                                    <mat-option value="IOR/Bill To">IOR/Bill To</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-radio-group>

                            <div class="group flex">
                                <mat-form-field class="half">
                                    <mat-label>Display Name</mat-label>
                                    <input matInput formControlName="display_name" required>
                                </mat-form-field>
                                <mat-form-field class="half">
                                    <mat-label>Full Name</mat-label>
                                    <input matInput formControlName="full_name" required>
                                </mat-form-field>
                                <div formGroupName="include_to_invoice">
                                    <mat-checkbox formControlName="display_name">Display name is included to invoice
                                    </mat-checkbox>
                                </div>
                            </div>
                            <div formGroupName="address">
                                <h3>Address</h3>
                                <div class="group flex">
                                    <mat-form-field class="half">
                                        <mat-label>Address</mat-label>
                                        <input matInput formControlName="address" required>
                                    </mat-form-field>
                                    <mat-form-field class="half">
                                        <mat-label>Address 2</mat-label>
                                        <input matInput formControlName="address2">
                                    </mat-form-field>
                                </div>
                                <div class="group flex">
                                    <mat-form-field class="half">
                                        <mat-label>City</mat-label>
                                        <input matInput formControlName="city" required>
                                    </mat-form-field>
                                    <mat-form-field class="half">
                                        <mat-label>Zip</mat-label>
                                        <input matInput formControlName="zip" required>
                                    </mat-form-field>
                                </div>
                                <div class="group flex">
                                    <mat-form-field class="half">
                                        <mat-label>State</mat-label>
                                        <input matInput formControlName="state">
                                    </mat-form-field>

                                    <div class="half">
                                        <common-autocomplete *ngIf="countries"
                                                             [options]="countries"
                                                             placeholder="Country"
                                                             [allowFreeInput]="false"
                                                             (optionSelected)="onCountrySelected($event)"
                                                             [errorText]="'Select country'"
                                                             [required]="true"
                                                             [image]="{path:'assets/images/flags',ext:'svg'}"
                                                             [value]="branch.value.address ? branch.value.address.country : null">
                                        </common-autocomplete>
                                    </div>

                                </div>
                            </div>
                            <div formGroupName="include_to_invoice">
                                <mat-checkbox formControlName="address">Address is included to invoice</mat-checkbox>
                            </div>

                            <div class="group flex">
                                <div class="full">
                                    <h2>Incoterms</h2>
                                </div>
                            </div>
                            <mat-radio-group formControlName="incoterms">
                                <div class="group flex">
                                    <div class="quarter">
                                        <mat-radio-button [value]="null">None</mat-radio-button>
                                    </div>
                                    <div class="quarter">
                                        <mat-radio-button value="DDP">DDP</mat-radio-button>
                                    </div>
                                    <div class="quarter">
                                        <mat-radio-button value="DAP">DAP</mat-radio-button>
                                    </div>
                                    <div class="quarter">
                                        <mat-radio-button value="EXW">EXW</mat-radio-button>
                                    </div>
                                </div>
                                <div class="group flex">
                                    <div class="quarter">
                                        <mat-radio-button value="CIF">CIF</mat-radio-button>
                                    </div>
                                    <div class="quarter">
                                        <mat-radio-button value="custom">Other</mat-radio-button>
                                    </div>
                                    <mat-form-field class="half" *ngIf="branch.value.incoterms === 'custom'">
                                        <mat-label>Incoterm</mat-label>
                                        <input type="text" matInput
                                               formControlName="custom_incoterm">
                                    </mat-form-field>
                                    <div class="half" *ngIf="branch.value.incoterms !== 'custom'"></div>
                                </div>
                            </mat-radio-group>


                            <div class="group flex">
                                <div class="full">
                                    <h2>Custom fields</h2>
                                </div>
                            </div>
                            <div formArrayName="custom_fields">
                                <div *ngFor="let group of getArrayControls('custom_fields').controls; let i = index">
                                    <div class="group flex" [formGroupName]="i">
                                        <mat-form-field class="third">
                                            <mat-label>Name</mat-label>
                                            <input matInput formControlName="name">
                                        </mat-form-field>
                                        <mat-form-field class="third">
                                            <mat-label>Value</mat-label>
                                            <input matInput formControlName="value">
                                        </mat-form-field>
                                        <div class="third text-right">
                                            <button mat-raised-button color="warn" type="button"
                                                    (click)="removeCustomField(i)">
                                                Remove field
                                            </button>
                                        </div>
                                        <div>
                                            <mat-checkbox formControlName="include_to_invoice">Custom field is included
                                                to
                                                invoice
                                            </mat-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="text-right">
                                <button mat-raised-button color="accent" type="button" (click)="addCustomField()">
                                    Add field
                                </button>
                            </div>

                            <div class="group flex">
                                <div class="full">
                                    <h2>Notes</h2>
                                </div>
                            </div>

                            <div formArrayName="notes">
                                <div *ngFor="let note of getArrayControls('notes').controls; let i = index">
                                    <div class="group flex" [formGroupName]="i">
                                        <mat-form-field class="quarter3">
                                            <mat-label>Note</mat-label>
                                            <input matInput formControlName="note">
                                        </mat-form-field>
                                        <div class="quarter text-right">
                                            <button mat-raised-button color="warn" type="button"
                                                    (click)="removeNoteField(i)">
                                                Remove note
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="text-right">
                                <button mat-raised-button color="accent" type="button" (click)="addNoteField()">
                                    Add note
                                </button>
                            </div>
                        </form>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon>contacts</mat-icon>
                        Contacts
                    </ng-template>
                    <div class="content padding-top-20">
                        <div *ngIf="contacts.length < 1">
                            No contacts found
                        </div>
                        <mat-accordion *ngIf="contacts.length > 0">
                            <ng-template ngFor let-item let-i="index" [ngForOf]="contacts">
                                <mat-expansion-panel *ngIf="item">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                        <span>
                                            {{item.value.first_name}}
                                            {{item.value.last_name}}
                                        </span>
                                            &nbsp;|&nbsp;
                                            <small>
                                                {{item.value.email}}
                                            </small>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <form [formGroup]="item" autocomplete="off">
                                        <div class="group flex">
                                            <mat-form-field class="half">
                                                <mat-label>First Name</mat-label>
                                                <input matInput formControlName="first_name"
                                                       required>
                                            </mat-form-field>
                                            <mat-form-field class="half">
                                                <mat-label>Last Name</mat-label>
                                                <input matInput formControlName="last_name"
                                                       required>
                                            </mat-form-field>
                                        </div>
                                        <div class="group flex">
                                            <mat-form-field class="full">
                                                <mat-label>Email</mat-label>
                                                <input matInput formControlName="email">
                                            </mat-form-field>
                                        </div>
                                        <div class="group flex">
                                            <mat-form-field class="half">
                                                <mat-label>Phone</mat-label>
                                                <input matInput formControlName="phone">
                                            </mat-form-field>
                                            <mat-form-field class="half">
                                                <mat-label>Fax</mat-label>
                                                <input matInput formControlName="fax">
                                            </mat-form-field>
                                        </div>
                                        <button mat-raised-button class="mat-warn" (click)="deleteContact(i)">Delete
                                        </button>
                                    </form>
                                </mat-expansion-panel>
                            </ng-template>
                        </mat-accordion>
                        <br>
                        <br>
                        <h3 class="mat-h3">Add new Contact</h3>
                        <form [formGroup]="contactForm" autocomplete="off"
                              (submit)="newContact(contactForm.value, contactForm.valid)">
                            <div class="group flex">
                                <mat-form-field class="half">
                                    <mat-label>First Name</mat-label>
                                    <input matInput formControlName="first_name" required>
                                </mat-form-field>
                                <mat-form-field class="half">
                                    <mat-label>Last Name</mat-label>
                                    <input matInput formControlName="last_name" required>
                                </mat-form-field>
                            </div>
                            <div class="group flex">
                                <mat-form-field class="full">
                                    <mat-label>Email</mat-label>
                                    <input matInput formControlName="email">
                                </mat-form-field>
                            </div>
                            <div class="group flex">
                                <mat-form-field class="half">
                                    <mat-label>Phone</mat-label>
                                    <input matInput formControlName="phone">
                                </mat-form-field>
                                <mat-form-field class="half">
                                    <mat-label>Fax</mat-label>
                                    <input matInput formControlName="fax">
                                </mat-form-field>
                            </div>
                            <button mat-raised-button class="mat-primary contact-submit" type="submit"
                                    [disabled]="!contactForm.valid">
                                Add
                            </button>
                        </form>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon>business</mat-icon>
                        Countries
                    </ng-template>
                    <div class="content">
                        <div class="countries-block">
                            <ng-template ngFor let-item let-i="index" [ngForOf]="branch_countries">
                                <div class="country flex row no-wrap align-center space-between">
                                    <div class="country-flag margin-left-10">
                                        <img class="flag"
                                             [src]="'assets/images/flags/' + item.iso.toLowerCase() + '.svg'"/>
                                    </div>
                                    <div class="max">{{item.name}}</div>
                                    <div class="country-add">
                                        <mat-icon (click)="removeCountry(item.iso, item.name)">remove</mat-icon>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                        <mat-form-field>
                            <mat-label>search</mat-label>
                            <input type="text" matInput [formControl]="searchControl"/>
                        </mat-form-field>
                        <h3>Add country</h3>
                        <div class="clearfix"></div>
                        <div class="countries-block">
                            <ng-template ngFor let-item let-i="index" [ngForOf]="countries_filtered">
                                <div class="country flex row no-wrap align-center space-between">
                                    <div class="country-flag margin-left-10">
                                        <img class="flag"
                                             [src]="'assets/images/flags/' + item.value.toLowerCase() + '.svg'"/>
                                    </div>
                                    <div class="max">{{item.name}}</div>
                                    <div class="country-add">
                                        <mat-icon (click)="addCountry(item.value, item.name)">add</mat-icon>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon>house</mat-icon>
                        Hubs
                    </ng-template>
                    <div class="content" *ngIf="hubs && hubs.length">
                        <div class="countries-block">
                            <ng-template ngFor let-hub let-i="index" [ngForOf]="hubs">
                                <div class="country flex row no-wrap align-center space-between"
                                     *ngIf="branch_hubs.indexOf(hub.id) !== -1">
                                    <div class="max padding-left-10 ">
                                        {{hub.customers_inventory_name}}
                                        {{hub.customers_sub_inventory}}
                                    </div>
                                    <div class="country-add">
                                        <mat-icon (click)="removeHub(hub.id)">remove</mat-icon>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                        <mat-form-field>
                            <mat-label>search</mat-label>
                            <input type="text" matInput [formControl]="searchControl"/>
                        </mat-form-field>
                        <h3>Add hub</h3>
                        <div class="clearfix"></div>
                        <div class="countries-block">
                            <ng-template ngFor let-hub let-i="index" [ngForOf]="hubs">
                                <div class="country  flex row no-wrap align-center space-between"
                                     *ngIf="branch_hubs.indexOf(hub.id) === -1">
                                    <div class="max padding-left-10">
                                        {{hub.customers_inventory_name}}
                                        {{hub.customers_sub_inventory}}
                                    </div>
                                    <div class="country-add">
                                        <mat-icon (click)="addHub(hub.id)">add</mat-icon>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                    <div class="content" *ngIf="!hubs || !hubs.length">
                        <common-simple-spinner></common-simple-spinner>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
        <mat-card-actions>
            <button mat-raised-button class="mat-primary margin-right-10" (click)="updateBranch()">Save</button>
            <button mat-raised-button *ngIf="isEdit" (click)="deleteBranch()" class="mat-warn">Delete</button>
        </mat-card-actions>
    </mat-card-content>
</mat-card>
