import {ChangeDetectionStrategy, Component, ViewEncapsulation} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";


@Component({
    selector: "section-followups-list-my",
    template: `
        <h1 class="container-heading">My followups</h1>
        <mat-card>
            <mat-card-content>
                <section-followups-list [only_my]="true" [state]="state"></section-followups-list>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class FollowupsListMyComponent implements Base.IComponent {


    public readonly state: Base.IState;

    public constructor() {
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "followups-my",
            actions: {
                "browse": ["browse_requests"]
            }
        };
    }
}
