import {Injectable} from "@angular/core";
import {Api, ApiService} from "./api.service";
import { Api3Service } from "./api3.service";

@Injectable()
export class CourierService {

    public constructor(
        private apiService: ApiService,
        private api3Service: Api3Service
    ) {
    }

    public getCouriers(query: {} = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Get, ["courier"], {}, query);
    }

    public getCourierServices(query: {} = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Get, ["courier_service"], {}, query);
    }

    public getEntityServiceConversions(section: any, query: {} = {}): Promise<Api.IResponse> {
        return this.api3Service.request(Api.EMethod.Get, `${section}/courier-service-conversion`, query);
    }

    public createEntityServiceConversion(section: any, body: {} = {}): Promise<Api.IResponse> {
        return this.api3Service.request(Api.EMethod.Post, `${section}/courier-service-conversion`, body);
    }

    public updateEntityServiceConversion(section: any, id: number, body: {} = {}): Promise<Api.IResponse> {
        return this.api3Service.request(Api.EMethod.Put, `${section}/courier-service-conversion/${id}`, body);
    }

    public deleteEntityServiceConversion(section: any, id: number): Promise<Api.IResponse> {
        return this.api3Service.request(Api.EMethod.Delete, `${section}/courier-service-conversion/${id}`);
    }

    public deleteTransaction(id: number): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Delete, ["courier", "transactions", "" + id]);
    }


    public findByTrack(tracking_number: string): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Post, ["courier", "find-by-tracking-number"], {
            tracking_number
        });
    }
}
