import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import {Router} from "@angular/router";
import {Table, TableComponent} from "../../../../../../common/components/table/table.component";
import {ApiService} from "../../../../../../common/services/api.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";


@Component({
    selector: "section-courier-services-list",
    template: `
        <common-confirm></common-confirm>


        <h1 class="container-heading">Courier services</h1>

        <mat-card>
            <mat-card-content>
                <common-table *ngIf="listTable" [options]="listTable" (action)="handleListAction($event)">
                    <div data-actions>
                        <button mat-raised-button color="primary" class="main" (click)="showAddForm()">Add</button>
                    </div>
                </common-table>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CourierServicesListComponent implements Base.IComponent, OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    @ViewChild(TableComponent, {static: false})
    public tableRef: TableComponent;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.IOptions;

    public showExportButton: boolean = false;

    public readonly state: Base.IState;

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {

        this.listTable = {
            api: this.apiService.getUrl(["courier_service"]) + "?data_structure=dataTables" + (
                this.state.action === "filter" ? "&courier_slug=" + this.state.params.courier : ""
            ),
            actions: [
                {
                    name: "edit",
                    title: "Edit"
                }
            ],
            columns: [
                {
                    data: "service_name",
                    title: "Name"
                },
                {
                    data: "description",
                    title: "Description"
                },
                {
                    data: "slug",
                    title: "Slug"
                },
                {
                    data: "courier.display_name",
                    title: "Courier"
                },
                {
                    data: "delivery_type",
                    title: "Delivery type"
                }
            ],
            order: [],
            search: true
        };
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Handle list row action
     * @param {Table.Action.IResult} action
     * @returns {Promise<any>}
     */
    public async handleListAction(action: Table.Action.IResult): Promise<any> {
        if (action.name === "edit") {
            const pathArray: string[] = [
                this.state.section,
                this.state.component,
                "edit",
                "id",
                action.data.id
            ];
            if (this.state.action === "filter") {
                pathArray.push("courier");
                pathArray.push(this.state.params.courier);
            }
            this.router.navigate(pathArray);
        }
    }

    public showAddForm(): void {
        const pathArray: string[] = [
            this.state.section,
            this.state.component,
            "add"
        ];
        if (this.state.action === "filter") {
            pathArray.push("courier");
            pathArray.push(this.state.params.courier);
        }
        this.router.navigate(pathArray);
    }

    public ngOnInit(): void {
        this.prepareList();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "courier-services",
            actions: {
                "browse": ["browse_couriers_services"],
                "filter": ["browse_couriers_services"]
            }
        };
    }
}
