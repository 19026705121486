import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {Table} from "../../../../../../../common/components/table/table.component";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {IPagination} from "../../../../../../../common/components/pagination/pagination.component";
import {UserService} from "../../../../../../../common/services/user.service";

@Component({
    selector: "section-sla-tracking-list",
    templateUrl: "list.component.html",
    styleUrls: ["list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class SlaTrackingListComponent implements Base.IComponent, OnInit {


    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.IOptions;

    public readonly state: Base.IState;

    public data: IPagination<any>;

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private changeDetectorRef: ChangeDetectorRef,
        private userService: UserService
    ) {
    }

    /**
     * Get data from server
     * @returns {Promise<any>}
     */
    public async prepareData(page: number = 1, per_page: number = null): Promise<any> {
        if (per_page === null) {
            per_page = this.userService.data.settings.default_per_page;
        }
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            "service_level_tracking", {},
            {
                data_structure: "paginated",
                page,
                per_page
            });
        if (data) {
            this.data = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    public ngOnInit(): void {
        this.prepareData();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "sla-tracking",
            actions: {
                "browse": ["browse_service_level_trackings"]
            }
        };
    }


}
