<common-confirm></common-confirm>
<h1 class="container-heading">Followup Tasks</h1>
<mat-card>
    <mat-card-content>
        <common-table2 #table *ngIf="listTable" [settings]="listTable">
            <div>
                <mat-form-field>
                    <mat-label>Select Kind</mat-label>
                    <mat-select [formControl]="request_kind_id" panelClass="fit-content">
                        <mat-option [value]="null">All</mat-option>
                        <mat-option *ngFor="let item of kinds" [value]="item.value">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Select Team</mat-label>
                    <mat-select [formControl]="team_id" panelClass="fit-content">
                        <mat-option [value]="null">All</mat-option>
                        <mat-option *ngFor="let item of teams" [value]="item.value">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <span style="margin-right: 10px">
                    <mat-checkbox [formControl]="groupByOrder"> Group by order</mat-checkbox>
                </span>
                <span style="margin-right: 10px">
                    <mat-checkbox [formControl]="onlyOpen"> Only open</mat-checkbox>
                </span>
            </div>


            <div row1 class="flex row">
                <ng-container *ngIf="state.section_type === 'admin'">
                    <common-form-select
                            class="form-select"
                            [options]="partners"
                            [multiple]="true"
                            [selectAll]="true"
                            [search]="true"
                            (onClose)="partner_ids.setValue($event)"
                            label="Partner">
                    </common-form-select>
                </ng-container>
            </div>
        </common-table2>
    </mat-card-content>
</mat-card>