<common-confirm></common-confirm>


<h1 class="container-heading">Remark Types</h1>

<mat-card>
    <mat-card-content>

        <common-table *ngIf="listTable" [options]="listTable" (action)="handleListAction($event)">
            <div data-actions *ngIf="userService.validatePermissions('add_teams')">
                <a routerLink="add" mat-raised-button color="primary"
                   class="create">Add</a>
            </div>
        </common-table>

    </mat-card-content>
</mat-card>