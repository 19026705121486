<mat-card>

    <h1 class="color-accent">Select boxes to update</h1>

    <form [formGroup]="transferForm">
        <div class="group flex">
            <mat-form-field class="full">
                <mat-chip-grid #chipGrid>
                    <mat-chip-row matChipRemove *ngFor="let box of boxes"
                                  [removable]="true" (removed)="removeBox(box)">
                        {{box}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip-row>
                </mat-chip-grid>
                <input placeholder="Box" required
                       [matChipInputFor]="chipGrid"
                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                       [matChipInputAddOnBlur]="true"
                       (matChipInputTokenEnd)="addBoxes($event)">
            </mat-form-field>

            <mat-form-field class="full">
                <mat-label>Partner</mat-label>
<mat-select [formControl]="partnerSelect" >
                    <mat-option *ngFor="let partner of partners" [value]="partner">
                        {{partner.display_name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="full" *ngIf="subs.length">
                <mat-label>Sub-warehouse transfer to</mat-label>
<mat-select formControlName="sub_inventory"  required>
                    <mat-option *ngFor="let sub of subs" [value]="sub.sub_inventory">
                        {{sub.sub_inventory}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="full" *ngIf="subs.length">
                <mat-label>Warehouse location transfer to</mat-label>
                <input #trigger="matAutocompleteTrigger" type="text" matInput [formControl]="locationSearchInput"
                       [matAutocomplete]="auto" required>
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
                                  (optionSelected)="onOptionSelected($event)">
                    <mat-option *ngFor="let option of locations_filtered | async" [value]="option">
                        {{option.location}}
                    </mat-option>
                    <mat-option (click)="addLocation(locationSearchInput.value)">
                        <mat-icon>add</mat-icon>
                        Add location
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div class="actions">
            <button type="button" mat-raised-button color="accent" [disabled]="!transferForm.valid" (click)="submit()">
                Update
            </button>
        </div>
    </form>

</mat-card>
