import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Modal} from "../../../../../services/modal.service";
import {GMap} from "../../../../../../common/components/gmap/gmap.component";
import {MapInfoWindow, MapMarker} from "@angular/google-maps";
import {Contact} from "../../../../../../common/interfaces/contact.interface";
import IAddress = Contact.IAddress;
import {GoogleMapsApiService} from "../../../../../../common/services/google-maps-api.service";
import {Observable} from "rxjs";

@Component({
    selector: "section-order-track-progress-map",
    template: `
        <mat-card>
            <mat-card-content *ngIf="apiLoaded | async">
                <google-map height="450px"
                            width="1150px"
                            [center]="center"
                            [zoom]="zoom">

                    <ng-template ngFor let-marker [ngForOf]="markers">
                        <map-marker
                            #pointMarker="mapMarker"
                            [position]="{lat: marker.lat, lng: marker.lng}"
                            [options]="{icon: {url:marker.iconUrl}}"
                            (mapClick)="openInfoWindow(pointMarker, marker.data)"
                        ></map-marker>
                    </ng-template>

                    <map-polyline [path]="vertices" [options]="{strokeColor:'#F00'}"></map-polyline>

                    <map-info-window>
                        <ng-container *ngIf="infoPoint">
                            <div>
                                <i>{{infoPoint.created_at}}</i>
                            </div>
                            <br>
                            <div>
                                <b>{{infoPoint.location}}</b>
                            </div>
                            <div>
                                {{infoPoint.message}}
                            </div>
                        </ng-container>
                    </map-info-window>
                </google-map>
            </mat-card-content>
        </mat-card>

    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})

export class OrderTrackProgressMapComponent implements OnInit {

    private modal: Modal.IModal;

    private checkpoints: any[];

    private address_from: IAddress;

    private address_to: IAddress;

    public center: google.maps.LatLngLiteral;
    public zoom = 4;

    public markers: GMap.IMarker[] = [];
    public vertices: google.maps.LatLngLiteral[] = [];

    @ViewChild(MapInfoWindow)
    public infoWindow: MapInfoWindow;

    public infoPoint: any;

    public apiLoaded: Observable<any>;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private googleMapsApiService: GoogleMapsApiService
    ) {
    }

    private getPointCoordinates(point): string[] {
        if (!point) {
            return [];
        }
        if (Array.isArray(point.coordinates)) {
            return point.coordinates;
        } else {
            return point.coordinates.split(",");
        }
    }

    private setupMarkers(): void {
        if (this.address_from && this.address_from.lat && this.address_from.lng) {
            this.markers.push({
                lat: Number(this.address_from.lat),
                lng: Number(this.address_from.lng),
                iconUrl: "./assets/images/icons/warehouse-marker-green.png",
                data: {
                    created_at: "",
                    location: this.address_from.address + " " + this.address_from.city + ", "
                        + this.address_from.state + " " + this.address_from.zip + ", " + this.address_from.country,
                    message: "Origin"
                }
            });
        }

        for (const point of this.checkpoints) {

            const [lat, lng]: string[] = this.getPointCoordinates(point);

            if (!lat || !lng) {
                continue;
            }

            this.markers.push({
                lat: Number(lat),
                lng: Number(lng),
                iconUrl: "./assets/images/icons/car-marker.png",
                data: point
            });

            this.vertices.push({
                lat: Number(lat),
                lng: Number(lng),
            });
        }

        if (this.markers[this.markers.length - 1]) {
            this.markers[this.markers.length - 1].iconUrl = "./assets/images/icons/car-marker-yellow.png";
        }

        if (this.address_to && this.address_to.lat && this.address_to.lng) {
            this.markers.push({
                lat: Number(this.address_to.lat),
                lng: Number(this.address_to.lng),
                iconUrl: "./assets/images/icons/marker-red.png",
                data: {
                    created_at: "",
                    location: this.address_to.address + " " + this.address_to.city + ", "
                        + this.address_to.state + " " + this.address_to.zip + ", " + this.address_to.country,
                    message: "Destination"
                }
            });
        }

        const [clat, clng]: string[] = this.getPointCoordinates(this.checkpoints[0]);

        this.center = {
            lat: Number(clat || this.address_to?.lat || 0),
            lng: Number(clng || this.address_to?.lng || 0),
        };

        this.changeDetectorRef.markForCheck();
    }

    public openInfoWindow(marker: MapMarker, point: any): void {
        this.infoPoint = point;
        this.infoWindow.open(marker);
    }

    public ngOnInit(): void {
        this.checkpoints = this.modal.params.checkpoints;
        this.address_from = this.modal.params.address_from;
        this.address_to = this.modal.params.address_to;

        if (this.checkpoints) {
            this.setupMarkers();
        }

        this.apiLoaded = this.googleMapsApiService.load();
    }
}
