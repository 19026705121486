<mat-card>

    <h1 class="color-accent">Select pallet to update</h1>

    <form [formGroup]="transferForm" (submit)="submit()">
        <div class="group flex">
            <mat-form-field class="full">
                <mat-label>Partner</mat-label>
<mat-select formControlName="partner_id"  required>
                    <mat-option *ngFor="let partner of partners" [value]="partner.value">
                        {{partner.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="full">
                <common-form-select
                        label="Pallet"
                        [options]="pallets"
                        [search]="true"
                        [required]="true"
                        [value]="transferForm.value.pallet_id"
                        (valueChange)="transferForm.get('pallet_id').setValue($event)">
                </common-form-select>
            </div>

            <div class="full" *ngIf="subs.length">
                <common-form-select
                        label="Sub warehouse"
                        [options]="subs"
                        [required]="true"
                        [value]="transferForm.value.sub_inventory_id"
                        (valueChange)="transferForm.get('sub_inventory_id').setValue($event)">
                </common-form-select>
            </div>

            <div class="full" *ngIf="showLocations">
                <common-form-select
                        label="Warehouse location transfer to"
                        [options]="locations"
                        [search]="true"
                        [required]="true"
                        [value]="transferForm.value.warehouse_location_id"
                        (valueChange)="this.transferForm.get('warehouse_location_id').setValue($event)">
                    <mat-option (click)="addLocation(locationSearchInput.value)">
                        <mat-icon>add</mat-icon>
                        Add location
                    </mat-option>
                </common-form-select>
            </div>
        </div>


        <div class="actions">
            <button type="submit" mat-raised-button color="accent" [disabled]="!transferForm.valid">
                Update
            </button>
        </div>
    </form>

</mat-card>
