import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {Api} from "../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {Order} from "../../../../../../../common/interfaces/order.interface";
import {FormControl, FormGroup} from "@angular/forms";
import {OrderService} from "../../../../../../../common/services/order.service";
import {MilestonesService} from "../../../../../../../common/services/milestones.service";

@Component({
    selector: "section-order-sla",
    template: `
        <h2>
            SLA fulfillment
        </h2>

        <mat-accordion multi *ngIf="!showSpinner">
            <mat-expansion-panel *ngFor="let mls of milestones" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-icon [color]="mls.notified_at ? 'success' : 'disabled'">
                            feedback
                        </mat-icon>
                        &nbsp;
                        {{mls.milestone_type.name}}
                    </mat-panel-title>
                    <mat-panel-description>
                        milestone
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <form [formGroup]="forms[mls.id]">
                    <div class="group flex">
                        <div class="half">
                            <common-form-date
                                label="Estimated date"
                                [value]="mls.estimated_date"
                                (valueChange)="update(forms[mls.id], 'estimated_date', $event)">
                            </common-form-date>
                        </div>

                        <div class="half">
                            <common-form-date
                                label="Complete date"
                                [value]="mls.complete_date"
                                (valueChange)="update(forms[mls.id], 'complete_date', $event)">
                            </common-form-date>
                        </div>

                        <mat-form-field class="full">
                            <mat-label>Notes</mat-label>
<input matInput type="text"  formControlName="notes">
                        </mat-form-field>

                        <mat-form-field class="full">
                            <mat-label>Reason</mat-label>
<mat-select  formControlName="reason">
                                <mat-option *ngFor="let reason of mls.milestone_type.reasons" [value]="reason.name">
                                    {{ reason.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="actions">
                        <button mat-raised-button type="button" color="accent"
                                [disabled]="!forms[mls.id].valid || forms[mls.id].pristine"
                                (click)="submit(forms[mls.id])">
                            Save
                        </button>
                    </div>
                </form>
            </mat-expansion-panel>
        </mat-accordion>

        <div *ngIf="showSpinner">
            <common-simple-spinner></common-simple-spinner>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class OrderSLAComponent implements OnInit, OnDestroy {

    protected destroy$: EventEmitter<boolean> = new EventEmitter(false);

    @Input()
    public orderData: Order.IOrderData;

    public showSpinner: boolean = false;

    public milestones: any[] = [];
    public forms: any[] = [];

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private orderService: OrderService,
        private milestoneService: MilestonesService,
        private toastService: ToastService
    ) {
    }

    public async getData(): Promise<any> {
        this.showSpinner = true;

        this.forms = [];

        const {data}: Api.IResponse = await this.orderService.getOrderMilestones(this.orderData.ref);
        this.showSpinner = false;
        if (data) {
            this.milestones = data;
            for (const d of data) {
                this.forms[d.id] = new FormGroup({
                    id: new FormControl(d.id),
                    name: new FormControl(d.milestone_type.name),
                    notified_at: new FormControl(d.notified_at),
                    estimated_date: new FormControl(d.estimated_date),
                    complete_date: new FormControl(d.complete_date),
                    notes: new FormControl(d.notes),
                    reason: new FormControl(d.reason),
                });
            }
        }
        this.changeDetectorRef.markForCheck();
    }

    public update(milestone: FormGroup, controlName: string, value: string): void {
        const old: string = milestone.get(controlName).value;
        if (old !== value) {
            milestone.get(controlName).setValue(value);
            milestone.updateValueAndValidity();
            milestone.markAsDirty();
        }
    }

    public async submit(milestone: FormGroup): Promise<any> {

        if (milestone.value.complete_date && !milestone.value.notes) {
            this.toastService.show("Notes are required", "error");
            return;
        }
        this.showSpinner = true;

        const {code, message}: Api.IResponse = await this.milestoneService
            .updateMilestone(milestone.value.id, milestone.value);

        this.showSpinner = false;
        if (code === 200) {
            this.toastService.show(message, "success");
            this.getData();
        }
        this.changeDetectorRef.markForCheck();
    }

    public ngOnInit(): void {
        this.getData();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
