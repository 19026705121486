import {ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import Quagga from "quagga";
import {Modal} from "../../../section/services/modal.service";

@Component({
    selector: "common-barcode-reader",
    template: `
        <div id="interactive" class="viewport"></div>`,
    styleUrls: [
        "barcode-reader.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class BarcodeReaderComponent implements OnInit, OnDestroy {

    public modal: Modal.IModal;

    private setEventListeners(): void {

        Quagga.onProcessed((result: any): void => {
            const drawingCtx: any = Quagga.canvas.ctx.overlay;
            const drawingCanvas: any = Quagga.canvas.dom.overlay;

            if (result) {
                if (result.boxes) {
                    drawingCtx.clearRect(
                        0, 0,
                        parseInt(drawingCanvas.getAttribute("width")),
                        parseInt(drawingCanvas.getAttribute("height")));
                    result.boxes.filter((box: any): boolean => {
                        return box !== result.box;
                    }).forEach((box: any): void => {
                        Quagga.ImageDebug
                            .drawPath(box, {x: 0, y: 1}, drawingCtx, {color: "green", lineWidth: 2});
                    });
                }

                if (result.box) {
                    Quagga.ImageDebug
                        .drawPath(result.box, {x: 0, y: 1}, drawingCtx, {color: "#00F", lineWidth: 2});
                }

                if (result.codeResult && result.codeResult.code) {
                    Quagga.ImageDebug
                        .drawPath(result.line, {x: "x", y: "y"}, drawingCtx, {color: "red", lineWidth: 3});
                }
            }
        });


        Quagga.onDetected((data: any): void => {
            this.modal.response.emit({
                name: "value",
                value: data.codeResult.code
            });
        });
    }

    private barcodeRead(): void {
        Quagga.init({
            inputStream: {
                name: "Live",
                type: "LiveStream",
                constraints: {
                    width: {min: 640},
                    height: {min: 480},
                    aspectRatio: {min: 1, max: 100},
                    facingMode: "environment" // or user
                }
            },
            decoder: {
                readers: [
                    "code_128_reader",
                    "ean_reader"
                ]
            },
            numOfWorkers: 2,
            frequency: 10,
            locate: true,
            multiple: false,
            debug: {
                drawBoundingBox: true,
                showFrequency: true,
                drawScanline: true,
                showPattern: true
            },
            locator: {
                patchSize: "medium",
                halfSample: true
            },
        }, (err: any): void => {
            if (err) {
                // console.warn(err);
                return;
            }
            this.setEventListeners();
            Quagga.start();
        });
    }

    public ngOnInit(): void {
        this.barcodeRead();
    }

    public ngOnDestroy(): void {
        Quagga.stop();
    }
}
