<common-confirm></common-confirm>
<mat-card>
    <mat-card-content>
        <mat-tab-group>
            <mat-tab label="Details">
                <div class="columns-container">
                    <div class="left">
                        <div class="margin-bottom-40">
                            <img [src]="formGroup.get('image_path').value ? formGroup.get('image_path').value : (item && item.image_path ? item.image_path : 'assets/images/no-image.png')"
                                 alt="">
                            <br/>
                            <br/>
                            <button *ngIf="canEdit()" type="button" mat-raised-button color="accent"
                                    (click)="uploadImage()">
                                Upload image
                            </button>

                        </div>


                        <div *ngIf="item && item.analogs.length" class="text-left margin-bottom-20">
                            <h2>Analogs:</h2>
                            <div *ngFor="let analog of item.analogs" class="margin-bottom-10">
                                <a class="pointer text-underline" (click)="showAnotherItem(analog.id)"
                                   matTooltip="Open analog item" matTooltipPosition="right">
                                    {{ analog.item }}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="right">
                        <form [formGroup]="formGroup" (submit)="submitForm()">
                            <div class="group flex">
                                <div class="full" *ngIf="item">
                                    <h1>{{ item.item }}</h1>
                                </div>

                                <ng-container *ngIf="!item">
                                    <div class="full">
                                        <h1>Create new item</h1>
                                    </div>
                                    <mat-form-field class="full">
                                        <mat-label>Item</mat-label>
                                        <input matInput formControlName="item" required>
                                    </mat-form-field>
                                </ng-container>

                                <mat-form-field class="full">
                                    <mat-label>Description</mat-label>
                                    <input matInput formControlName="description"
                                           required>
                                </mat-form-field>

                                <mat-form-field class="full">
                                    <mat-label>Item id</mat-label>
                                    <input matInput type="number" formControlName="item_id">
                                </mat-form-field>

                                <mat-form-field class="full" floatLabel="always">
                                    <mat-label>Serial</mat-label>
                                    <mat-slide-toggle formControlName="serial"></mat-slide-toggle>
                                    <textarea matInput formControlName="serial" hidden></textarea>

                                    <ng-container *ngIf="formGroup.controls['serial'].value">
                                        <div class="full">
                                            <mat-checkbox formControlName="required_serial_wh_outbound">
                                                Required serial WH outbound
                                            </mat-checkbox>
                                        </div>

                                        <div class="full">
                                            <mat-checkbox formControlName="required_serial_order_creation_outbound">
                                                Required serial order creation outbound
                                            </mat-checkbox>
                                        </div>

                                        <div class="full">
                                            <mat-checkbox formControlName="required_serial_wh_inbound">
                                                Required serial WH inbound
                                            </mat-checkbox>
                                        </div>

                                        <div class="full">
                                            <mat-checkbox formControlName="required_serial_order_creation_inbound">
                                                Required serial order creation inbound
                                            </mat-checkbox>
                                        </div>
                                    </ng-container>
                                </mat-form-field>

                                <mat-form-field class="full" floatLabel="always">
                                    <mat-label>Batch</mat-label>
                                    <mat-slide-toggle formControlName="batch"></mat-slide-toggle>
                                    <textarea matInput formControlName="batch" hidden></textarea>
                                    <mat-error *ngIf="formGroup.controls['batch'].invalid"></mat-error>

                                    <ng-container *ngIf="formGroup.controls['batch'].value">
                                        <div class="half">
                                            <mat-checkbox formControlName="required_batch_outbound">
                                                Required batch outbound
                                            </mat-checkbox>
                                        </div>

                                        <div class="half">
                                            <mat-checkbox formControlName="required_batch_inbound">
                                                Required batch inbound
                                            </mat-checkbox>
                                        </div>
                                    </ng-container>
                                </mat-form-field>

                                <mat-form-field class="full" floatLabel="always">
                                    <mat-label>Revision</mat-label>
                                    <mat-slide-toggle formControlName="revision"></mat-slide-toggle>
                                    <textarea matInput formControlName="revision" hidden></textarea>
                                    <mat-error *ngIf="formGroup.controls['revision'].invalid"></mat-error>

                                    <ng-container *ngIf="formGroup.controls['revision'].value">
                                        <div class="half">
                                            <mat-checkbox formControlName="required_revision_outbound">
                                                Required revision outbound
                                            </mat-checkbox>
                                        </div>

                                        <div class="half">
                                            <mat-checkbox formControlName="required_revision_inbound">
                                                Required revision inbound
                                            </mat-checkbox>
                                        </div>
                                    </ng-container>
                                </mat-form-field>

                                <mat-form-field class="full" floatLabel="always">
                                    <mat-label>Lot</mat-label>
                                    <mat-slide-toggle formControlName="lot"></mat-slide-toggle>
                                    <textarea matInput formControlName="lot" hidden></textarea>
                                    <mat-error *ngIf="formGroup.controls['lot'].invalid"></mat-error>

                                    <ng-container *ngIf="formGroup.controls['lot'].value">
                                        <div class="half">
                                            <mat-checkbox formControlName="required_lot_outbound">
                                                Required lot outbound
                                            </mat-checkbox>
                                        </div>

                                        <div class="half">
                                            <mat-checkbox formControlName="required_lot_inbound">
                                                Required lot inbound
                                            </mat-checkbox>
                                        </div>
                                    </ng-container>
                                </mat-form-field>

                                <mat-form-field class="half">
                                    <mat-label>Manufacturer</mat-label>
                                    <input matInput formControlName="manufacturer">
                                </mat-form-field>

                                <mat-form-field class="half">
                                    <mat-label>License exception code</mat-label>
                                    <input matInput formControlName="license_exception_code"
                                    >
                                </mat-form-field>

                                <div class="full">
                                    <mat-checkbox formControlName="contains_battery">
                                        Contains Battery
                                    </mat-checkbox>
                                </div>

                                <mat-form-field class="half">
                                    <mat-label>Default quantity</mat-label>
                                    <input matInput type="number" formControlName="default_quantity"
                                    >
                                </mat-form-field>

                                <mat-form-field class="half">
                                    <mat-label>Family</mat-label>
                                    <input matInput formControlName="family">
                                </mat-form-field>

                                <mat-form-field class="full" appearance="fill">
                                    <mat-label>UOMs</mat-label>
                                    <mat-chip-grid #uomList>
                                        <mat-chip-row matChipRemove *ngFor="let uom of formGroup.value.uoms"
                                                      (removed)="removeUom(uom)">
                                            {{ uom }}
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip-row>
                                        <input placeholder="Add UOM..."
                                               [matChipInputFor]="uomList"
                                               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                               [matChipInputAddOnBlur]="true"
                                               (matChipInputTokenEnd)="addUom($event)">
                                    </mat-chip-grid>
                                </mat-form-field>

                                <mat-form-field class="full">
                                    <mat-label>Config</mat-label>
                                    <input matInput formControlName="config">
                                </mat-form-field>

                                <mat-form-field class="full">
                                    <mat-label>ECCN</mat-label>
                                    <input matInput formControlName="ECCN">
                                </mat-form-field>

                                <mat-form-field class="full">
                                    <mat-label>Group</mat-label>
                                    <input matInput formControlName="group">
                                </mat-form-field>

                                <mat-form-field class="full">
                                    <mat-label>HSC</mat-label>
                                    <input matInput formControlName="HSC">
                                </mat-form-field>

                                <common-autocomplete [options]="countries" placeholder="Country"
                                                     [allowFreeInput]="false"
                                                     class="full" (optionSelected)="onCountrySelected($event)"
                                                     [errorText]="'Select country'" [required]="true"
                                                     [image]="{path:'assets/images/flags',ext:'svg'}"
                                                     [value]="formGroup ? formGroup.value.country : null">
                                </common-autocomplete>

                                <mat-form-field class="third">
                                    <mat-label>Width</mat-label>
                                    <input matInput formControlName="width" required>
                                </mat-form-field>

                                <mat-form-field class="third">
                                    <mat-label>Height</mat-label>
                                    <input matInput formControlName="height" required>
                                </mat-form-field>

                                <mat-form-field class="third">
                                    <mat-label>Length</mat-label>
                                    <input matInput formControlName="length" required>
                                </mat-form-field>

                                <mat-form-field class="half">
                                    <mat-label>Weight</mat-label>
                                    <input matInput formControlName="weight" required>
                                </mat-form-field>

                                <mat-form-field class="half">
                                    <mat-label>Mass unit</mat-label>
                                    <mat-select formControlName="mass_unit" required>
                                        <mat-option value="kg">kg</mat-option>
                                        <mat-option value="lbs">lbs</mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field class="half">
                                    <mat-label>Customs value</mat-label>
                                    <input matInput formControlName="customs_value"
                                           required>
                                </mat-form-field>

                                <mat-form-field class="half">
                                    <mat-label>Currency</mat-label>
                                    <mat-select formControlName="customs_values_currency"
                                                required>
                                        <mat-option value="USD">USD</mat-option>
                                        <mat-option value="EUR">EUR</mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field class="half">
                                    <mat-label>Declared value</mat-label>
                                    <input matInput formControlName="declared_value">
                                </mat-form-field>

                                <mat-form-field class="half">
                                    <mat-label>Currency</mat-label>
                                    <mat-select formControlName="declared_value_currency"
                                                required>
                                        <mat-option value="USD">USD</mat-option>
                                        <mat-option value="EUR">EUR</mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field class="full">
                                    <mat-label>Documents URL</mat-label>
                                    <input matInput formControlName="documents_url">
                                </mat-form-field>

                                <mat-form-field class="full">
                                    <mat-label>Outbound Special instructions</mat-label>
                                    <textarea matInput formControlName="outbound_special_instructions"
                                              rows="5"></textarea>
                                </mat-form-field>

                                <mat-form-field class="full">
                                    <mat-label>Inbound Special instructions</mat-label>
                                    <textarea matInput formControlName="inbound_special_instructions"
                                              rows="5"></textarea>
                                </mat-form-field>

                                <mat-form-field class="half">
                                    <mat-label>Days to inspection</mat-label>
                                    <input matInput formControlName="days_to_inspection">
                                </mat-form-field>

                                <mat-form-field class="half">
                                    <mat-label>Days in stock to notification</mat-label>
                                    <input matInput formControlName="days_in_stock">
                                </mat-form-field>

                                <mat-form-field class="full">
                                    <mat-label>Allocation type</mat-label>
                                    <mat-select formControlName="allocation_type">
                                        <mat-option value="allocate_by_location_date">
                                            Allocate by location creation date
                                        </mat-option>
                                        <mat-option value="allocate_by_batch">
                                            Allocate by batch
                                        </mat-option>
                                        <mat-option value="allocate_by_batch_without_mixing">
                                            Allocate by batch without mixing
                                        </mat-option>
                                        <mat-option value="allocate_by_inventory_date">
                                            Allocate by inventory date
                                        </mat-option>
                                        <mat-option value="allocate_by_initial_date">
                                            Allocate by initial date
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>


                                <div formGroupName="properties">
                                    <ng-container *ngIf="state.section_slug ==='annapurna_labs'">
                                        <mat-form-field class="full">
                                            <mat-label>Type</mat-label>
                                            <input matInput formControlName="type">
                                        </mat-form-field>
                                        <mat-form-field class="full">
                                            <mat-label>Amazon PN</mat-label>
                                            <input matInput formControlName="amazon_pn">
                                        </mat-form-field>
                                        <mat-form-field class="full">
                                            <mat-label>Short Name</mat-label>
                                            <input matInput formControlName="short_name">
                                        </mat-form-field>
                                        <mat-form-field class="full">
                                            <mat-label>Silicon Family</mat-label>
                                            <input matInput formControlName="silicon_family">
                                        </mat-form-field>
                                    </ng-container>

                                    <div class="full">
                                        <mat-checkbox formControlName="allow_swap_serial_wh_outbound">
                                            Allow swap serial for outbound at warehouse
                                        </mat-checkbox>
                                    </div>
                                </div>

                                <div class="half">
                                    <mat-checkbox formControlName="is_virtual">
                                        Virtual
                                    </mat-checkbox>
                                </div>

                                <div class="half">
                                    <mat-checkbox formControlName="is_active">
                                        Active
                                    </mat-checkbox>
                                </div>

                                <div class="half">
                                    <mat-checkbox formControlName="return_instructions">
                                        Return instructions
                                    </mat-checkbox>
                                </div>

                                <div class="half">
                                    <mat-checkbox formControlName="dangerous_goods">
                                        Dangerous goods
                                    </mat-checkbox>
                                </div>

                            </div>

                            <div class="actions">
                                <button mat-raised-button type="submit" color="primary"
                                        [disabled]="!formGroup.valid || !canEdit()">
                                    Save
                                </button>
                                &nbsp;
                                <button
                                        *userAllowed="'delete_part_masters'"
                                        mat-raised-button (click)="deleteItem()" color="warn">
                                    Delete
                                </button>

                            </div>
                        </form>
                    </div>
                </div>
            </mat-tab>

            <mat-tab [label]="'Serial ' + serial.serial" *ngIf="serial">
                <mat-accordion>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <b>Properties</b>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div>
                            <table class="table" *ngIf="serial.properties && serial.properties.length; else noProps">
                                <tr *ngFor="let key of serial.properties | keys">
                                    <th style="vertical-align: top">{{ key }}:</th>
                                    <td style="vertical-align: top">
                                        <pre style="margin:0">{{ serial.properties[key] | prettyJson }}</pre>
                                    </td>
                                </tr>
                            </table>

                            <ng-template #noProps>
                                No properties for this serial
                            </ng-template>

                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <b>Orders</b>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div>
                            <table class="table">
                                <thead>
                                <tr>
                                    <th>Ref</th>
                                    <th>Service level</th>
                                    <th>Status</th>
                                    <th>Created at</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let order of serial.orders">
                                    <td>
                                        <button type="button"
                                                mat-raised-button
                                                class="button-200"
                                                color="accent"
                                                (click)="goToOrder(order.id)">
                                            {{ order.ref }}
                                        </button>
                                    </td>
                                    <td>{{ order.service_level.name }}</td>
                                    <td>{{ order.status.name }}</td>
                                    <td>{{ order.created_at }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <b>Transactions</b>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div>
                            <table class="table">
                                <thead>
                                <tr>
                                    <th>Ref</th>
                                    <th>Hub</th>
                                    <th>Type</th>
                                    <th>Created at</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let transaction of serial.warehouse_transactions">
                                    <td>
                                        <button type="button"
                                                mat-raised-button
                                                class="button-200"
                                                color="accent"
                                                (click)="goToOrder(transaction.order_id)">
                                            {{ transaction.ref }}
                                        </button>
                                    </td>
                                    <td>
                                        {{ transaction.inventory_conversion.customers_inventory_name }}
                                        {{ transaction.inventory_conversion.customers_sub_inventory }}
                                    </td>
                                    <td>
                                        <mat-icon color="success" *ngIf="transaction.type === 'inbound'">
                                            arrow_downward
                                        </mat-icon>
                                        <mat-icon color="warn" *ngIf="transaction.type === 'outbound'">
                                            arrow_upward
                                        </mat-icon>
                                        {{ transaction.type }}
                                    </td>
                                    <td>{{ transaction.created_at }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <b>Notes</b>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div>
                            <textarea [formControl]="notes" style="width: 100%" rows="10"></textarea>
                            <div>
                                <button type="button" mat-raised-button color="primary"
                                        (click)="saveSerialNotes()">
                                    Save
                                </button>
                            </div>
                        </div>
                    </mat-expansion-panel>

                </mat-accordion>

            </mat-tab>
            <mat-tab label="Documents" *ngIf="item">
                <div style="padding-top: 20px;">
                    <common-part-master-files-list [part_master]="item"></common-part-master-files-list>
                </div>
            </mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>
