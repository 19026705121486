<h1 class="container-heading">{{state.action === "add" ? "Add" : "Edit"}} courier</h1>

<mat-card>
    <mat-card-content>
        <form [formGroup]="formGroup" (submit)="formSubmit()">
            <div class="group flex">
                <mat-form-field class="half">
                    <mat-label>Display name</mat-label>
                    <input matInput formControlName="display_name" required>
                </mat-form-field>

                <mat-form-field class="half">
                    <mat-label>Other name</mat-label>
                    <input matInput formControlName="other_name" required>
                </mat-form-field>

                <mat-form-field class="half">
                    <mat-label>Phone</mat-label>
                    <input matInput formControlName="phone" required>
                </mat-form-field>

                <mat-form-field class="half">
                    <mat-label>Web url</mat-label>
                    <input matInput formControlName="web_url" required>
                </mat-form-field>
                <mat-form-field class="half">
                    <mat-label>ShippyPro slug</mat-label>
                    <input matInput formControlName="shippy_pro_slug">
                </mat-form-field>

                <mat-form-field class="half">
                    <mat-label>Aftership slug</mat-label>
                    <input matInput formControlName="aftership_slug">
                </mat-form-field>

                <div class="full">
                    <img
                            [src]="formGroup.get('icon_path').value ? formGroup.get('icon_path').value : 'assets/images/no-image.png'"
                            alt="">

                    <div>
                        <button mat-raised-button (click)="uploadImage()" type="button">Upload icon</button>
                    </div>
                </div>
            </div>

            <div class="actions">
                <button type="submit" mat-raised-button color="primary" [disabled]="!formGroup.valid">
                    Save
                </button>
                &nbsp;
                <button type="button" mat-raised-button (click)="goBack()">
                    Close
                </button>

            </div>
        </form>
    </mat-card-content>
</mat-card>
