import {Component, OnInit} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Order} from "../../../../../../common/interfaces/order.interface";
import {Router} from "@angular/router";


@Component({
    selector: "section-order-list-by-service-level",
    template: `
        <section-order-list [params]="params"></section-order-list>`
})
export class OrderListByServiceLevelComponent implements Base.IComponent, OnInit {

    public readonly state: Base.IState;

    public params: Order.IOrderListParams;

    public constructor(
        private router: Router
    ) {
    }

    public ngOnInit(): void {
        this.params = {
            title: "Orders list by service level",
            state: this.state,
            actions: {
                view: true,
                delete: true,
                add: false,
                export: true,
                remarks: true
            },
            query: {service_level: this.state.params.id}
        };
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "orders-by-service-level",
            actions: {
                "browse": ["browse_orders"]
            }
        };
    }

}
