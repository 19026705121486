<common-confirm></common-confirm>

<mat-card>
    <h1 class="color-accent">Shuffle packages</h1>
    <div>
        <div class="margin-bottom-20" *ngIf="packages.length">
            <h3>Select package:</h3>
            <mat-radio-group [formControl]="packageSelected">
                <div *ngFor="let pack of packages" class="radio-with-button">
                    <mat-radio-button [value]="pack.id"></mat-radio-button>
                    <span>
                        {{ pack.warehouse_package_type?.name }} <b class="color-accent">{{ pack.name }}</b>
                    </span>
                </div>
            </mat-radio-group>
        </div>
        <div class="clear"></div>

        <div>
            <button type="button" mat-raised-button color="accent"
                    (click)="linkAll()"
                    [disabled]="!packageSelected.value">
                Link all
            </button>
        </div>
    </div>


    <div class="clear margin-bottom-20"></div>

    <mat-tab-group *ngIf="shipment" [selectedIndex]="selected_tab">
        <mat-tab label="Items">
            <ng-template matTabContent>
                <section-warehouse-order-wizard-shuffle-items
                        [state]="state"
                        [shipment_id]="shipment.id"
                        [warehouse_package_id]="packageSelected.value">
                </section-warehouse-order-wizard-shuffle-items>
            </ng-template>
        </mat-tab>

        <mat-tab label="Boxes">
            <ng-template matTabContent>
                <section-warehouse-order-wizard-shuffle-boxes
                        [state]="state"
                        [shipment_id]="shipment.id"
                        [warehouse_package_id]="packageSelected.value">
                </section-warehouse-order-wizard-shuffle-boxes>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</mat-card>
