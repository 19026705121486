import {Component} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";


@Component({
    selector: "section-warehouse-transactions-deleted",
    template: `
        <section-warehouse-transactions-list
            [params]='{title: "Deleted transactions", type: "deleted", state: state}'>
        </section-warehouse-transactions-list>`
})
export class WarehouseTransactionsListDeletedComponent implements Base.IComponent {

    public readonly state: Base.IState;

    public ngConfig(): Base.IConfig {
        return {
            name: "transactions-deleted",
            actions: {
                "browse": ["browse_warehouse_transactions"]
            }
        };
    }

}
