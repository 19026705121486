import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {fromEvent, Subscription} from "rxjs";
import {Api, ApiService} from "../../services/api.service";
import {ToastService} from "../../services/toast.service";
import {HelpersService} from "../../services/helpers.service";
import {debounceTime, takeUntil} from "rxjs/operators";
import "datatables.net";
import "datatables.net-buttons";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import * as jQuery from "jquery";
import {StorageService} from "../../services/storage.service";
import {Router} from "@angular/router";
import {SpinnerService} from "../../services/spinner.service";
import {UserService} from "../../services/user.service";


export namespace Table {

    export namespace Action {

        export interface IResult {
            data: any;
            name: string;
        }

        export interface ISettings {
            name: string;
            title: string;
        }

    }

    export interface IDtEvent {
        hasData?: boolean;
    }

    export interface IOptions {
        actions?: Table.Action.ISettings[];
        api?: string;
        type?: Api.EMethod;
        buttons?: boolean | string[] | DataTables.ButtonsSettings | DataTables.ButtonSettings[];
        columns?: DataTables.ColumnSettings[];
        defs?: DataTables.ColumnDefsSettings[];
        length?: number;
        order?: [number, string][];
        search?: boolean;
        child?: {
            dataSet?: string;
            data: { name: string, title: string }[];
            params?: { [key: string]: any };
        };
        createdRow?: DataTables.FunctionCreateRow;
        select?: boolean;
    }

}

@Component({
    selector: "common-table",
    templateUrl: "table.component.html",
    styleUrls: [
        "table.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class TableComponent implements AfterViewInit, OnDestroy, OnChanges {
    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    private actionSubsctiption: Subscription;

    private processing: boolean = false;

    @Input()
    public data: { [key: string]: any }[];

    @Input()
    public options: Table.IOptions = {};

    @Input()
    public refreshButton: boolean = true;

    @ViewChild("search", {static: false})
    public searchElement: ElementRef;

    @ViewChild("table", {static: false})
    public tableElement: ElementRef;

    @Output()
    public action: EventEmitter<Table.Action.IResult> = new EventEmitter<Table.Action.IResult>(null);

    @Output()
    public onDtEvent: EventEmitter<Table.IDtEvent> = new EventEmitter<Table.IDtEvent>(true);

    @Output()
    public rowSelected: EventEmitter<any[]> = new EventEmitter<any[]>();

    /**
     * Table component instance/reference
     * @type {DataTables.Api}
     */
    public tableRef: DataTables.Api;

    public showButtons: boolean = false;

    /**
     * Options is ready to use
     * @returns {boolean}
     */
    public get ready(): any {
        return ((this.data || this.options.api) && Array.isArray(this.options.columns) && this.options.columns.length);
    }

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private elementRef: ElementRef,
        private apiService: ApiService,
        private toastService: ToastService,
        private storageService: StorageService,
        private router: Router,
        private userService: UserService,
        private spinnerService: SpinnerService
    ) {
    }

    /**
     * Prepare table actions column
     * @returns {void}
     */
    private prepareActions(): void {
        if (this.actionSubsctiption) {
            this.actionSubsctiption.unsubscribe();
        }
        this.actionSubsctiption = fromEvent(this.tableElement.nativeElement, "click")
            .pipe(takeUntil(this.destroy$))
            .subscribe((event: any): void => {
                let element: any = event.target;
                if (element.classList.contains("mat-icon")) {
                    element = event.target.parentNode;
                }

                if (element.classList.contains("tableAction")) {
                    const name: string = element.dataset.action;
                    const rowElement: HTMLElement = jQuery(element).parents("tr")[0] as HTMLElement;
                    const row: any = this.tableRef.row(rowElement);
                    const data: any = row.data();
                    this.action.emit({data, name});
                }
            });
    }

    /**
     * Format child rows
     * @param data
     * @returns {string}
     */
    private formatChild(data: any): string {
        const schema: any = this.options.child;
        let content: string = "";

        let rowData: any = [];
        if (schema.dataSet && data[schema.dataSet]) {
            rowData = data[schema.dataSet];
        } else {
            return "";
        }
        if (!Array.isArray(rowData)) {
            rowData = [rowData];
        }
        if (rowData.length === 0) {
            return "";
        }
        content += "<thead><tr>";
        for (const cell of schema.data) {
            content += "<th>" + cell.title + "</th>";
        }
        content += "</tr></thead>";
        content += "<tbody>";
        for (const row of rowData) {
            content += "<tr>";
            for (const cell of schema.data) {
                const value: any = HelpersService.dotToObjectPath(row, cell.name);
                if (value && Array.isArray(value)) {
                    content += "<td>" + value.join(", ") + "</td>";
                } else if (value) {
                    content += "<td>" + value + "</td>";
                } else {
                    content += "<td></td>";
                }

                content += "<td>" + (value ? value : "") + "</td>";
            }
            content += "</tr>";
        }
        content += "</tbody>";

        let wrapperClass: string = "";
        if (schema.params && schema.params.wrapperClassFromData) {
            const dataClass: string[] = (schema.params.wrapperClassFromData).split(".");
            try {
                let _data: any = data;
                for (const step of dataClass) {
                    _data = _data[step];
                }
                wrapperClass = _data;
            } catch (e) {
            }
        } else if (schema.params && schema.params.wrapperClass) {
            wrapperClass = schema.params.wrapperClass;
        }

        return "<div class='" + wrapperClass.toLowerCase() + "'><table class='table'>" + content + "</table></div>";
    }

    /**
     * Waiting for data in table && show child rows
     * @returns {void}
     */
    private prepareChild(): void {
        if (this.options.child) {
            const $this: any = this;
            this.tableRef.rows().every(function (): void {
                const row: any = $this.tableRef.row(this);
                if (row.data()) {
                    row.child($this.formatChild(row.data())).show();
                    jQuery(this.node()).addClass("show-child");
                }
            });
        }
    }

    /**
     * Waiting for data in table && show buttons if needed
     * @returns {void}
     */
    private prepareButtons(): void {
        if (this.options.buttons) {
            this.showButtons = true;
            const buttons: string[] = [];
            for (const i of Object.keys(this.options.buttons)) {
                if (typeof (this.options.buttons[i]) === "object") {
                    buttons.push(this.options.buttons[i].extend);
                } else if (typeof (this.options.buttons[i]) === "string") {
                    buttons.push(this.options.buttons[i]);
                }
            }
            this.options.buttons = buttons;
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Prepare table columns (configuration)
     * @returns {DataTables.ColumnSettings[]}
     */
    private prepareColumns(): DataTables.ColumnSettings[] {
        const columns: DataTables.ColumnSettings[] = this.options.columns || [];

        const selectColumn: DataTables.ColumnSettings[] = this.options.select ? [
            {
                orderable: false,
                searchable: false,
                title: "",
                className: "row-selections",
                defaultContent: "<span></span>"
            }
        ] : [];

        const actionColumn: DataTables.ColumnSettings[] =
            Array.isArray(this.options.actions) && this.options.actions.length > 0 ? [
                {
                    orderable: false,
                    searchable: false,
                    title: "",
                    className: "row-actions"
                }
            ] : [];
        return [...selectColumn, ...actionColumn, ...columns];
    }

    /**
     * Define/prepare custom table columns
     * @returns {DataTables.ColumnDefsSettings[]}
     */
    private prepareColumnDefs(): DataTables.ColumnDefsSettings[] {
        const columnDefs: DataTables.ColumnDefsSettings[] = this.options.defs || [];
        if (Array.isArray(this.options.actions) && this.options.actions.length > 0) {
            let content: string = "";
            this.options.actions.forEach((action: Table.Action.ISettings): void => {

                let color: string = "";
                let icon: string = "";
                switch (action.name) {
                    case "edit":
                        icon = "border_color";
                        color = "mat-primary";
                        break;
                    case "view":
                        icon = "visibility";
                        color = "mat-primary";
                        break;
                    case "select":
                    case "child":
                        icon = "input";
                        color = "mat-primary";
                        break;
                    case "add":
                        icon = "add";
                        color = "mat-accent";
                        break;
                    case "delete":
                        icon = "delete";
                        color = "mat-warn";
                        break;
                    case "wizard":
                        icon = "arrow_forward";
                        color = "mat-primary";
                        break;
                    case "remarks":
                        icon = "speaker_notes";
                        color = "mat-primary";
                        break;
                    case "star":
                        icon = "star";
                        color = "mat-accent";
                        break;
                    case "list":
                        icon = "list";
                        color = "mat-primary";
                        break;
                    case "print":
                        icon = "print";
                        color = "mat-accent";
                        break;
                    case "print-alt":
                        icon = "print";
                        color = "mat-primary";
                        break;
                    case "alarm":
                        icon = "alarm";
                        color = "mat-accent";
                        break;
                    default:
                        break;
                }

                content += `
                    <button data-action="${action.name}" type="button"
                    class="mat-mdc-mini-fab mdc-fab ${color} tableAction " title="${action.title}">
                        <mat-icon class="mat-icon material-icons">${icon}</mat-icon>
                    </button>
                `;
            });
            columnDefs.push({
                targets: this.options.select ? 1 : 0,
                data: null,
                defaultContent: content
            });
        }
        return columnDefs;
    }

    /**
     * Listen table ajax request errors (show toast message)
     * @returns {void}
     */
    private prepareErrorHandling(): void {
        (jQuery.fn as any).dataTable.ext.errMode = "none";
        this.tableRef.on("xhr.dt", (...params: any[]): void => {
            const response: XMLHttpRequest = params[3];
            if (response.statusText === "error") {
                let data: string = "Load table data error";
                try {
                    data = "";
                    data += (JSON.parse(response.responseText).message + "<br />" || "").toString();
                    data += (JSON.parse(response.responseText).data || "").toString();
                } catch (e) {
                }
                this.toastService.show(data, "error");
                if (response.status === 401) {
                    this.storageService.clean();
                    this.router.navigateByUrl("/");
                }
            }
        });
    }

    /**
     * Show/hide pagination. Listen pagination event for scroll to top
     * @returns {void}
     */
    private preparePagination(): void {
        this.tableRef.on("draw.dt", (): void => {
            if (!this.elementRef || !this.tableRef) {
                return;
            }
            const pages: number = this.tableRef.page.info().pages;
            const info: HTMLElement = this.elementRef.nativeElement.querySelector(".dataTables_info");
            const pagination: HTMLElement = this.elementRef.nativeElement.querySelector(".dataTables_paginate");
            (pages > 0) ? info.classList.remove("hidden") : info.classList.add("hidden");
            (pages > 1) ? pagination.classList.remove("hidden") : pagination.classList.add("hidden");
            this.prepareChild();
        });
        this.tableRef.on("page.dt", (): void => {
            if (jQuery(this.elementRef.nativeElement).parents(".modal")) {
                jQuery(this.elementRef.nativeElement).parents(".modal").scrollTop(0);
            }
        });
    }

    /**
     * Listen table processing status (show/hide spinnerService)
     * @returns {void}
     */
    private prepareProcessing(): void {
        this.tableRef.on("processing.dt", (e: any, settings: any, processing: any): void => {
            if (processing !== this.processing) {
                this.processing = processing;
                if (processing) {
                    this.spinnerService.show();
                } else {
                    this.spinnerService.hide();
                }
            }
        });
    }

    /**
     * Listen custom search input (for global search in table data)
     * @returns {void}
     */
    private prepareSearching(): void {
        if (this.options.search) {
            fromEvent(this.searchElement.nativeElement, "input")
                .pipe(takeUntil(this.destroy$), debounceTime(500))
                .subscribe((event: Event): void => {
                    this.tableRef.search((event.target as any).value).columns.adjust().draw();
                });
        }
    }

    /**
     * Initialize dataTable
     */
    private initDataTable(): void {
        if (this.tableElement && this.ready) {
            const options: DataTables.Settings = (!this.data) ? {
                ajax: this.options.api && {
                    url: this.options.api,
                    headers: this.apiService.getHeaders(true),
                    type: this.options.type || "GET"
                } as DataTables.AjaxSettings,
                buttons: this.options.buttons || false,
                columns: this.prepareColumns(),
                columnDefs: this.prepareColumnDefs(),
                createdRow: this.options.createdRow || null,
                lengthChange: true,
                lengthMenu: [[3, 5, 10, 25, 50, -1], [3, 5, 10, 25, 50, "All"]],
                pageLength: this.options.length ? this.options.length : this.userService.data.settings.default_per_page,
                order: this.options.order || [[]],
                processing: false,
                searching: this.options.search,
                serverSide: true,
                autoWidth: true,
                stateSave: true,
                stateSaveCallback: (settings: any, data: any): any => {
                    localStorage.setItem("DataTables_" + window.location.pathname, JSON.stringify(data));
                },
                stateLoadCallback: (settings: any): any => {
                    const data: any = JSON.parse(localStorage.getItem("DataTables_" + window.location.pathname));
                    if (this.options.search && data && data.search && data.search.search) {
                        this.searchElement.nativeElement.value = data.search.search;
                    }
                    return data;
                },
                drawCallback: function (settings: any): void {
                    const api: any = this.api();
                    setTimeout((): void => {
                        if (api && api.data().count() === 0 && api.page() && api.page() > 0) {
                            api.page("previous").draw("page");
                        }
                    }, 10);
                },
                dom: "<f<\"table-wrapper\"rt>iplB>",
            } as DataTables.Settings : {
                buttons: this.options.buttons || false,
                columns: this.prepareColumns(),
                columnDefs: this.prepareColumnDefs(),
                createdRow: this.options.createdRow || null,
                data: this.data,
                info: false,
                lengthChange: true,
                lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
                order: [],
                paging: false,
                searching: false,
                serverSide: false,
                autoWidth: true,
                stateSave: true,
                stateSaveCallback: (settings: any, data: any): any => {
                    localStorage.setItem("DataTables_" + window.location.pathname, JSON.stringify(data));
                },
                stateLoadCallback: (settings: any): any => {
                    const data: any = JSON.parse(localStorage.getItem("DataTables_" + window.location.pathname));
                    if (this.options.search && data && data.search && data.search.search) {
                        this.searchElement.nativeElement.value = data.search.search;
                    }
                    return data;
                },
                drawCallback: function (settings: any): void {
                    const api: any = this.api();
                    setTimeout((): void => {
                        if (api && api.data().count() === 0 && api.page() > 0) {
                            api.page("previous").draw("page");
                        }
                    }, 10);
                },
                dom: "<f<\"table-wrapper\"rt>iplB>"
            } as DataTables.Settings;

            this.tableRef = jQuery(this.tableElement.nativeElement).DataTable(options);
            this.prepareActions();

            this.tableRef.one("init.dt", (): void => {
                const dt: number = this.tableRef ? this.tableRef.data().count() : 0;
                if (dt > 0) {
                    this.prepareButtons();
                }
                this.onDtEvent.emit({hasData: Boolean(dt)});
            });

            if (this.options.select) {
                const tableRef: DataTables.Api = this.tableRef;
                const rowSelected: EventEmitter<any[]> = this.rowSelected;
                jQuery(this.tableElement.nativeElement)
                    .on("click", "tbody > tr > td:first-child", function (): void {
                        if (jQuery(this).parent().hasClass("selected")) {
                            jQuery(this).parent().removeClass("selected");
                        } else {
                            jQuery(this).parent().addClass("selected");
                        }

                        const selectedRows: any[] = [];
                        tableRef.rows(".selected").every(function (): void {
                            selectedRows.push(this.data());
                        });
                        rowSelected.emit(selectedRows);
                    });
            }

            if (!this.data) {
                this.prepareErrorHandling();
                this.spinnerService.hide();
                this.preparePagination();
                this.prepareProcessing();
                this.prepareSearching();
            }
        }

        this.spinnerService.hide();
    }

    /**
     * Destroy dataTable
     */
    private destroyDataTable(): void {
        this.tableRef.destroy();
        this.tableRef = null;
        jQuery(this.tableElement.nativeElement).empty();
    }

    /**
     * Fires dataTable button trigger
     * @param {string} type
     */
    public handleButtonAction(type: string): void {
        this.tableRef.button(".buttons-" + type).trigger();
    }

    /**
     * Is button exist (by type)
     * @param {string} type
     * @returns {boolean}
     */
    public isButtonExist(type: string): boolean {
        return Array.isArray(this.options.buttons) && (this.options.buttons as Array<any>).includes(type);
    }

    /**
     * Update table state (restart)
     * @returns {void}
     */
    public updateData(rows: any[]): void {
        this.tableRef.clear().draw();
        this.tableRef.rows.add(rows).draw();
    }

    /**
     * Reload dataTable`s data
     * @param {string} url
     */
    public reload(url?: string): void {
        url = (url || this.options.api);
        if (this.tableRef && url) {
            this.tableRef.ajax.url(url).load();
        }
    }

    /**
     * Clear table state & reload it
     */
    public clearState(): void {
        this.tableRef.state.clear();
        this.reload();
    }

    public ngAfterViewInit(): void {
        this.initDataTable();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.data && !changes.data.isFirstChange() && changes.data.currentValue && this.tableRef) {
            this.updateData(this.data);
        }
        if (changes.options && !changes.options.isFirstChange() && changes.options.currentValue && this.tableRef) {
            this.destroyDataTable();
            this.initDataTable();
        }
    }

    public ngOnDestroy(): void {
        if (this.tableRef) {
            jQuery(this.tableElement.nativeElement).off("click", "tr");
            this.tableRef.off("draw.dt");
            this.tableRef.off("page.dt");
            this.tableRef.off("processing.dt");
            this.tableRef.off("xhr.dt");
            this.tableRef.destroy();
            this.tableRef = null;
        }
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
