import {
    ComponentFactoryResolver,
    Directive,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewContainerRef
} from "@angular/core";
import {Wizard} from "../interfaces/wizard.interface";
import {takeUntil} from "rxjs/operators";

@Directive({selector: "[wizard-component-factory]"})
export class WizardComponentFactoryDirective implements OnInit, OnDestroy, OnChanges {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    private componentRef: any;

    @Input()
    public component: any;

    @Input()
    public data: any;

    @Output()
    public result: EventEmitter<Wizard.IStepResult> = new EventEmitter();


    public constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private viewContainerRef: ViewContainerRef
    ) {
    }

    public ngOnInit(): void {
        const factory: any = this.componentFactoryResolver.resolveComponentFactory(this.component);
        const containerRef: any = this.viewContainerRef.createComponent(factory);
        this.componentRef = containerRef.instance;
        this.result.next({action: "ready", value: this.componentRef});
        this.componentRef.data = this.data;
        this.componentRef.result.pipe(takeUntil(this.destroy$)).subscribe((data: Wizard.IStepResult): void => {
            this.result.next(data);
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.componentRef && changes.data) {
            this.componentRef.data = changes.data.currentValue;
            this.componentRef.ngOnChanges(changes);
        }
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
