import {ChangeDetectionStrategy, Component} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";


@Component({
    selector: "section-outbound-allocations-list",
    template: `
        <h1 class="container-heading">Allocations</h1>
        <mat-card>
            <mat-card-content>
                <section-allocations-list [state]="state"></section-allocations-list>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OutboundAllocationsListComponent implements Base.IComponent {

    public readonly state: Base.IState;

    public constructor() {
    }


    public ngConfig(): Base.IConfig {
        return {
            name: "allocations",
            actions: {
                "browse": ["browse_inventory_allocations"]
            }
        };
    }
}
