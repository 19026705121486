import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation
} from "@angular/core";
import * as SignaturePad from "signature_pad";

@Component({
    selector: "common-signature-pad",
    template: `
        <div class="signature"
             [style.width.px]="width"
             [style.height.px]="height"
             [class.saved]="saved"
             [class.required]="required">
            <div class="body" [style.width.px]="width-40" [style.height.px]="height-40">
                <canvas [width]="width-40" [height]="height-40" style="touch-action: none;"></canvas>
            </div>
        </div>
        <div class="controls">
            <div>
                <button type="button" class="mat-mdc-raised-button mdc-button mat-primary"
                        (click)="onSaveClick()">Save</button>
            </div>
            <div>
                <button type="button" class="mat-mdc-raised-button mdc-button" (click)="onClearClick()">Clear</button>
            </div>

        </div>
    `,
    styleUrls: ["signature.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class SignaturePadComponent implements AfterViewInit {

    private _fromDataURL: String;
    private _fromData: any;

    private canvas: any;
    private signaturePad: any;

    public saved: boolean = false;

    public width: number = 300;
    public height: number = 300;

    @Input()
    public required: boolean = false;

    @Output()
    public onSave: EventEmitter<string> = new EventEmitter();

    @Output()
    public onClear: EventEmitter<any> = new EventEmitter();

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private el: ElementRef
    ) {
    }

    @Input()
    public set formDataURL(value: string) {
        this._fromDataURL = value;
        this.signaturePad.fromDataURL(this._fromDataURL);
    }

    @Input()
    public set fromData(value: string) {
        this._fromData = value;
        this.signaturePad.fromData(this._fromData);
    }

    public onClearClick(): void {
        this.signaturePad.clear();
        this.saved = false;
        this.onClear.emit();
    }

    public onSaveClick(): void {
        this.saved = true;
        this.onSave.emit(this.signaturePad.toDataURL());
    }

    public ngAfterViewInit(): void {
        this.canvas = this.el.nativeElement.querySelector("canvas");
        // @ts-ignore This module is not supported anymore.
        // Using default is essential to get this working
        this.signaturePad = new SignaturePad.default(this.canvas);
    }

}
