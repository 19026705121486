<div class="group flex" [formGroup]="formGroup">
    <ng-template ngFor let-field [ngForOf]="fields">
        <ng-template [ngIf]="field.type === 'group' && isTriggered(field.triggerIndex, field.triggerVal)">
            <div class="full">
                <h2 *ngIf="field.label">{{field.label}}</h2>
            </div>
            <common-form-fields class="full"
                                [fields]="field.fields"
                                [values]="values && values[field.name] ? values[field.name] : null"
                                (valueChange)="formGroup.get(field.name).setValue($event)"></common-form-fields>
        </ng-template>

        <ng-template [ngIf]="field.type === 'input'">
            <mat-form-field class="{{field.size}}">
                <mat-label>{{field.label}}</mat-label>
                <input matInput [formControlName]="field.name" type="text"
                       [pattern]="patternTypeMappings[field.pattern]"
                       [required]="field.required">
            </mat-form-field>
        </ng-template>

        <ng-template [ngIf]="field.type === 'email'">
            <mat-form-field class="{{field.size}}">
                <mat-label>{{field.label}}</mat-label>
                <input matInput [formControlName]="field.name" type="email"
                       [pattern]="patternTypeMappings['email']"
                       [required]="field.required">
            </mat-form-field>
        </ng-template>

        <ng-template [ngIf]="field.type === 'email_inspection'">
            <mat-form-field class="{{field.size}}">
                <mat-label>{{field.label}}</mat-label>
                <input matInput [formControlName]="field.name" type="email"
                       [pattern]="patternTypeMappings['email']"
                       [required]="field.required">
            </mat-form-field>
        </ng-template>

        <ng-template [ngIf]="field.type === 'password'">
            <mat-form-field class="{{field.size}}" #fieldContext>
                <mat-label>{{field.label}}</mat-label>
                <input matInput [formControlName]="field.name" [type]="fieldContext?.showPass ? 'text':'password'"
                       [required]="field.required">
                <mat-icon matIconSuffix (click)="fieldContext.showPass = (fieldContext?.showPass ? false : true)">
                    {{fieldContext?.showPass ? 'visibility' : 'visibility_off'}}
                </mat-icon>
            </mat-form-field>
        </ng-template>

        <ng-template [ngIf]="field.type === 'text'">
            <mat-form-field class="{{field.size}}">
                <mat-label>{{field.label}}</mat-label>
                <textarea matInput
                          [formControlName]="field.name"
                          [required]="field.required">
                </textarea>
            </mat-form-field>
        </ng-template>

        <ng-template [ngIf]="field.type === 'number'">
            <mat-form-field class="{{field.size}}">
                <mat-label>{{field.label}}</mat-label>
                <input matInput [formControlName]="field.name" type="number"
                       [required]="field.required">
            </mat-form-field>
        </ng-template>

        <ng-template [ngIf]="field.type === 'select' || field.type === 'status'">
            <div class="{{field.size}}">
                <common-form-select [label]="field.label"
                                    [required]="field.required"
                                    [url]="field.url"
                                    [url3]="field.url3"
                                    [multiple]="field.multiple"
                                    [value]="formGroup.get(field.name).value"
                                    [options]="field.values"
                                    [search]="true"
                                    (valueChange)="formGroup.get(field.name).setValue($event)"
                                    [selectAll]="formSection === 'reports' || field.multiple"
                >
                </common-form-select>
            </div>
        </ng-template>

        <ng-template [ngIf]="field.type === 'hub_select'">
            <div class="{{field.size}}">
                <common-form-hub-select [label]="field.label"
                                        [url]="field.url"
                                        [required]="field.required"
                                        [multiple]="field.multiple"
                                        [value]="formGroup.get(field.name).value"
                                        (onChange)="formGroup.get(field.name).setValue($event)"
                                        [selectAll]="formSection === 'reports' || field.multiple"
                >
                </common-form-hub-select>
            </div>
        </ng-template>

        <ng-template [ngIf]="field.type === 'checkbox'">
            <div class="{{field.size}}">
                <common-form-checkbox [required]="field.required"
                                      [label]="field.label"
                                      [value]="formGroup.get(field.name).value"
                                      (valueChange)="formGroup.get(field.name).setValue($event)"></common-form-checkbox>
            </div>
        </ng-template>

        <ng-template [ngIf]="field.type === 'radio'">
            <mat-radio-group class="{{field.size}}" [formControlName]="field.name">
                <div><b>{{field.label}}</b></div>
                <div class="radio-block" *ngFor="let value of field.values">
                    <mat-radio-button [name]="field.name" [value]="value">
                        {{value}}
                    </mat-radio-button>
                </div>
            </mat-radio-group>
        </ng-template>

        <ng-template [ngIf]="field.type === 'file'">
            <div class="{{field.size}}">
                <common-form-file-upload
                        [label]="field.label"
                        [required]="field.required"
                        [accept]="field.accept"
                        [value]="formGroup.get(field.name).value"
                        (file)="formGroup.get(field.name).setValue($event)">
                </common-form-file-upload>
            </div>
        </ng-template>

        <ng-template [ngIf]="field.type === 'barcode'">
            <div class="{{field.size}}">
                <common-form-barcode
                        [label]="field.label"
                        [required]="field.required"
                        [multiple]="field.multiple"
                        (valueChange)="formGroup.get(field.name).setValue($event)">
                </common-form-barcode>
            </div>
        </ng-template>

        <ng-template [ngIf]="field.type === 'date_range'">
            <div class="full">
                <common-form-date-range [label]="field.label"
                                        [required]="field.required"
                                        [value]="formGroup.get(field.name).value"
                                        [min_date]="field.min_date"
                                        [max_date]="field.max_date"
                                        (valueChange)="formGroup.get(field.name).setValue($event)">
                </common-form-date-range>
            </div>
        </ng-template>

        <ng-template [ngIf]="field.type === 'date'">
            <div class="{{field.size}}">
                <common-form-date [label]="field.label"
                                  [required]="field.required"
                                  [value]="formGroup.get(field.name).value"
                                  [min_date]="field.min_date"
                                  [max_date]="field.max_date"
                                  (valueChange)="formGroup.get(field.name).setValue($event)">
                </common-form-date>
            </div>
        </ng-template>

        <ng-template [ngIf]="field.type === 'date_time'">
            <div class="{{field.size}}">
                <common-form-date-time [label]="field.label"
                                       [required]="field.required"
                                       [value]="formGroup.get(field.name).value"
                                       [min_date]="field.min_date"
                                       [max_date]="field.max_date"
                                       (valueChange)="formGroup.get(field.name).setValue($event)">
                </common-form-date-time>
            </div>
        </ng-template>

        <ng-template [ngIf]="field.type === 'date_period'">
            <div class="full">
                <common-form-date-period [required]="field.required"
                                         (valueChange)="formGroup.get(field.name).setValue($event)">
                </common-form-date-period>
            </div>
        </ng-template>

        <ng-template [ngIf]="field.type === 'search' && !field.multiple">
            <div class="{{field.size}}">
                <common-form-search [label]="field.label"
                                    [required]="field.required"
                                    [url]="field.url"
                                    [apiVersion]="field.api_version"
                                    [multiple]="field.multiple"
                                    [value]="formGroup.get(field.name).value"
                                    [formSection]="formSection"
                                    (valueChange)="formGroup.get(field.name).setValue($event)">
                </common-form-search>
            </div>
        </ng-template>

        <ng-template [ngIf]="field.type === 'search' && field.multiple">
            <div class="{{field.size}}">
                <common-form-search [label]="field.label"
                                    [required]="field.required"
                                    [url]="field.url"
                                    [apiVersion]="field.api_version"
                                    [multiple]="field.multiple"
                                    [multipleValue]="formGroup.get(field.name).value"
                                    [formSection]="formSection"
                                    (valueChange)="formGroup.get(field.name).setValue($event)">
                </common-form-search>
            </div>
        </ng-template>


        <ng-template [ngIf]="field.type === 'signature'">
            <div class="full">
                <div><b>{{field.label}} {{field.required ? "*" : ""}}</b></div>
                <common-signature-pad
                        [required]="field.required"
                        (onSave)="formGroup.get(field.name).setValue($event)"
                        (onClear)="formGroup.get(field.name).setValue(null)">
                </common-signature-pad>
            </div>
        </ng-template>

        <ng-template [ngIf]="field.type === 'link'">
            <div class="{{field.size}}">
                <a class="mat-mdc-raised-button mdc-button mat-primary" [href]="field.url" target="_blank">
                    {{field.label}}
                </a>
            </div>
        </ng-template>

        <ng-template [ngIf]="field.type === 'free_text'">
            <div class="{{field.size}}">
                {{field.description}}
            </div>
        </ng-template>

        <ng-template [ngIf]="field.type === 'filler'">
            <div class="{{field.size}} filler"></div>
        </ng-template>

        <ng-template [ngIf]="field.type === 'label'">
            <div class="label {{field.size}}">{{field.label}}</div>
        </ng-template>

        <ng-template [ngIf]="field.type === 'component'">
            <mat-form-field class="full">
                <mat-label>{{field.label}}</mat-label>
                <input matInput
                       [sectionControl]="field.params"
                       [formControlName]="field.name"
                       readonly
                       [value]="field['values'] ? field['values'].value : ''"
                       [required]="field.required">
            </mat-form-field>
        </ng-template>

    </ng-template>
</div>
