<common-confirm></common-confirm>
<h1 class="container-heading">{{document?.name}}</h1>
<mat-card>
    <mat-card-content>
        <ng-container *ngIf="document" [ngSwitch]="document.type">
            <ng-container *ngSwitchCase="'article'">
                <div class="ql-containers" style="border-width: 0;">
                    <div class="ql-editor" [innerHTML]="document.article">
                    </div>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="'document'">

                <div *ngIf="isImage(); else docViewer">
                    <img [src]="document.document_url" [alt]="document.name" style="max-width: 100%">
                </div>
                <ng-template #docViewer>
                    <ngx-doc-viewer [url]="document.document_url"
                                    [googleCheckContentLoaded]="true"
                                    viewer="google"
                                    style="width:100%;height:100vh;"></ngx-doc-viewer>
                </ng-template>
            </ng-container>
            <ng-container *ngSwitchCase="'link'">
                <iframe [src]="document.link | safe:'resourceUrl'" frameborder="0"
                        style="width: 100%; height: 80vh"></iframe>
            </ng-container>
        </ng-container>
    </mat-card-content>
</mat-card>
