import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from "@angular/core";
import {FormControl, Validators} from "@angular/forms";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {takeUntil} from "rxjs/operators";
import {Wizard} from "../../../../../../../common/interfaces/wizard.interface";
import {StorageService} from "../../../../../../../common/services/storage.service";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {
    AbstractSinglePageWizardStepComponent
} from "../../../../../../../common/interfaces/wizard-single-page.interface";
import {CourierService} from "../../../../../../../common/services/courier.service";
import {Courier} from "../../../../../../../common/interfaces/courier.interface";

@Component({
    selector: "section-order-wizard-courier-service",
    templateUrl: "courier-service.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderWizardCourierServiceComponent extends AbstractSinglePageWizardStepComponent
    implements OnInit, OnDestroy, OnChanges {

    public courier_service: FormControl = new FormControl(null, [Validators.required]);

    public services: { [key: string]: Courier.IService[] };

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        protected apiService: ApiService,
        protected storageService: StorageService,
        protected courierService: CourierService,
        protected spinnerService: SpinnerService
    ) {
        super(changeDetectorRef);
    }

    protected async getServices(service_level_id: number): Promise<any> {
        this.spinnerService.show();

        const {data}: Api.IResponse = await this.courierService.getCourierServices({
            data_structure: "grouped",
            service_level_ids: [service_level_id]
        });
        this.spinnerService.hide();
        if (data) {
            this.services = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    protected setup(data: Wizard.IData): void {
        if (data.serviceLevel && data.serviceLevel.id) {
            this.services = null;
            this.changeDetectorRef.markForCheck();
            this.getServices(data.serviceLevel.id);
        }
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this.courier_service.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value: any): void => {
                if (value) {
                    this.result.next({
                        action: "data",
                        value: {courierService: value}
                    });

                    this.result.next({
                        action: "result",
                        value: true
                    });
                } else {
                    this.result.next({action: "result", value: null});
                }
            });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.data) {
            const pv: any = changes.data.previousValue;
            const cv: any = changes.data.currentValue;

            if (
                (!pv.serviceLevel && cv.serviceLevel) ||
                (pv.serviceLevel && !cv.serviceLevel) ||
                (pv.serviceLevel && cv.serviceLevel && pv.serviceLevel.id !== cv.serviceLevel.id)
            ) {
                this.setup(cv);
            }
        }
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

}
