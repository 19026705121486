import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {Modal} from "../../../../../services/modal.service";
import {OrderService} from "../../../../../../common/services/order.service";
import {Warehouse} from "../../../../../../common/interfaces/warehouse.interface";
import {Order} from "../../../../../../common/interfaces/order.interface";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {FormControl, Validators} from "@angular/forms";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {TransactionService} from "../../../../../../common/services/transaction.service";

@Component({
    selector: "section-warehouse-transaction-order-item-modal",
    template: `
        <h1 class="container-heading">
            Select order item and release inventory
        </h1>
        <mat-card>
            <mat-card-content>

                <div *ngIf="transaction" class="margin-bottom-20">
                    <div>
                        <b>Transaction:</b> {{transaction.id}}
                        &nbsp;|&nbsp;
                        <span *ngIf="transaction.order">
                            <b>Order:</b> {{transaction.order.ref}}
                        </span>
                        &nbsp;|&nbsp;
                        <b>Hub:</b> {{transaction.inventory_conversion.customers_inventory_name}}
                        ({{transaction.inventory_conversion.customers_sub_inventory}})
                    </div>
                    <div>
                        <b>Item:</b> {{transaction.item}}
                        &nbsp;|&nbsp;
                        <span *ngIf="transaction.serial">
                            <b>Serial:</b> {{transaction.serial}}
                            &nbsp;|&nbsp;
                        </span>
                        <span *ngIf="transaction.configurations">
                            <b>Configurations:</b> {{transaction.configurations}}
                            &nbsp;|&nbsp;
                        </span>
                        <b>Type:</b> {{transaction.type}}
                        &nbsp;|&nbsp;
                        <b>Quantity:</b> {{transaction.quantity}}
                    </div>
                </div>

                <ng-container *ngIf="!transaction.order_id">
                    <div class="text-error">
                        This transaction not linked to any order. Please select order first!
                    </div>
                    <div class="order-search">
                        <common-form-search
                            label="Search for order"
                            [url]="'/order'"
                            (valueChange)="getOrder($event)"></common-form-search>
                    </div>
                </ng-container>

                <ng-container *ngIf="transaction.order">
                    <div class="pull-left padding-top-20">
                        Please select order item:
                    </div>
                    <div class="search pull-right">
                        <mat-form-field>
                            <mat-label>Search</mat-label>
<input type="search" matInput [formControl]="itemSearch" >
                        </mat-form-field>
                    </div>
                    <div *ngIf="items" class="clear">
                        <mat-radio-group aria-label="Select an option" [formControl]="selectedItem">
                            <div class="margin-bottom-10"
                                 *ngFor="let item of items | search: itemSearch.value: ['item','serial']">
                                <mat-radio-button [value]="item.id">
                                    {{item.item}}
                                    <ng-container *ngIf="item.serial">
                                        | <b>Serial:</b> {{item.serial}}
                                    </ng-container>

                                    <ng-container
                                        *ngIf="item.configurations
                                || (item.related_configurations && item.related_configurations.length)">
                                        | <b>Configurations:</b> {{item | getConfNames}}
                                    </ng-container>

                                    <ng-container *ngIf="item.batch">
                                        | <b>Batch:</b> {{item.batch}}
                                    </ng-container>

                                    | <b>quantity:</b> {{item.quantity}}

                                    <ng-container
                                        *ngIf="item.inventory_conversion && item.inventory_conversion.warehouse">
                                        | <b>Warehouse:</b> {{item.inventory_conversion.warehouse.name}}
                                    </ng-container>
                                    | <b>Status:</b> {{item.status.name}}
                                </mat-radio-button>
                            </div>

                        </mat-radio-group>
                        <p *ngIf="!items.length">No items to show</p>

                        <div class="padding-top-20">
                            <button mat-raised-button type="button" color="primary"
                                    (click)="updateItemInTransaction()"
                                    [disabled]="!selectedItem.valid">
                                Submit
                            </button>
                        </div>
                    </div>
                    <div *ngIf="!items">
                        <common-simple-spinner></common-simple-spinner>
                    </div>
                </ng-container>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderItemSelectModalComponent implements OnInit {

    public modal: Modal.IModal;

    public transaction: Warehouse.ITransaction;

    public items: Order.IItem[];

    public itemSearch: FormControl = new FormControl(null);

    public selectedItem: FormControl = new FormControl(null, [Validators.required]);

    public orders: Order.IOrderData[];

    public constructor(
        private orderService: OrderService,
        private transactionService: TransactionService,
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private spinnerService: SpinnerService
    ) {
    }

    private getOrderItems(): void {
        this.orderService.getItems(this.transaction.order.ref, this.transaction.type)
            .then(({data}: Api.IResponse): void => {
                this.items = data;
                this.changeDetectorRef.markForCheck();
            });

    }

    public async getOrder(id: number): Promise<any> {
        this.transaction.order = null;
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["order", "" + id]);
        this.spinnerService.hide();
        if (data) {
            this.transaction.order = data;
            this.changeDetectorRef.markForCheck();
            this.getOrderItems();
        }
    }

    public async updateItemInTransaction(): Promise<any> {
        this.spinnerService.show();
        this.transactionService.linkOrderItem(this.transaction.id, this.selectedItem.value, true)
            .then((res: Api.IResponse): void => {
                this.spinnerService.hide();
                if (res.code === 200) {
                    this.modal.response.emit({
                        name: null,
                        value: true
                    });
                }
            });
    }

    public ngOnInit(): void {
        this.transaction = this.modal.params.transaction;
        if (this.transaction.order) {
            this.getOrderItems();
        }
    }
}
