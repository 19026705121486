import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import {Router} from "@angular/router";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {UserService} from "../../../../../../common/services/user.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {ChangeStatusFormComponent} from "../../../../../../common/components/status/status.component";
import {CourierTransactionFormComponent} from "../../courier-transaction";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {Table2Component} from "../../../../../../common/components/table2";
import {FormControl, FormGroup} from "@angular/forms";
import {debounceTime, distinctUntilChanged, takeUntil} from "rxjs/operators";
import {Warehouse} from "../../../../../../common/interfaces/warehouse.interface";
import {Form} from "../../../../../../common/interfaces/form.interface";
import {ServiceLevelService} from "../../../../../../common/services/service-level.service";
import ISelectOption = Form.ISelectOption;

@Component({
    selector: "section-warehouse-order-list",
    template: `
        <common-confirm></common-confirm>

        <h1 class="container-heading">{{header}}</h1>
        <mat-card>
            <mat-card-content>
                <common-table2 *ngIf="listTable" [settings]="listTable" (onReset)="resetFilters()">
                    <div [formGroup]="filterForm" class="row flex align-center no-wrap">

                        <ng-container *ngIf="!type">
                            <mat-form-field>
                                <mat-label>Order type</mat-label>
                                <mat-select formControlName="typeSelect" multiple>
                                    <mat-option (click)="selectAllTypes()" [value]="null">All</mat-option>
                                    <mat-option value="sales"
                                                [disabled]="filterForm.value.lineSelect === 'inbound'">
                                        Sales
                                    </mat-option>
                                    <mat-option value="replenishment"
                                                [disabled]="filterForm.value.lineSelect === 'outbound'">
                                        Replenishment
                                    </mat-option>
                                    <mat-option value="rma">RMA</mat-option>
                                    <mat-option value="transfer">Warehouse transfer</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field>
                                <mat-label>Line type</mat-label>
                                <mat-select formControlName="lineSelect"
                                            [disabled]="pending_pickup">
                                    <mat-option value="all">All</mat-option>
                                    <mat-option value="inbound"
                                                [disabled]="filterForm.value.typeSelect === 'sales'">
                                        Inbound
                                    </mat-option>
                                    <mat-option value="outbound"
                                                [disabled]="filterForm.value.typeSelect === 'replenishment'">
                                        Outbound
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>

                        <common-form-select
                            *ngIf="partners"
                            label="Partners"
                            [multiple]="true"
                            [options]="partners"
                            [selectAll]="true"
                            [search]="true"
                            [value]="filterForm.value.partner_id"
                            (onClose)="filterForm.get('partner_id').setValue($event)"
                        ></common-form-select>

                        <common-form-select
                            *ngIf="service_levels"
                            label="Service level"
                            [multiple]="true"
                            [options]="service_levels"
                            [selectAll]="true"
                            [search]="true"
                            [value]="filterForm.value.service_level_id"
                            (onClose)="filterForm.get('service_level_id').setValue($event)"
                        ></common-form-select>

                        <common-form-date-range
                            label="Date"
                            [value]="filterForm.value.date_range"
                            (valueChangeObject)="filterForm.get('date_range').patchValue($event)"
                        ></common-form-date-range>

                    </div>

                    <div row1>

                    </div>

                </common-table2>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WarehouseOrderListComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input()
    public header: string;

    @Input()
    public type: string;

    @Input()
    public status: string;

    @Input()
    public pending_pickup: boolean = false;

    @Input()
    public state: Base.IState;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    public listTable: Table.ISettings;

    public defaultLineType: string = "all";

    public filterForm: FormGroup = new FormGroup({
        typeSelect: new FormControl([]),
        partner_id: new FormControl([]),
        service_level_id: new FormControl([]),
        lineSelect: new FormControl(this.defaultLineType),
        date_range: new FormGroup({
            start: new FormControl(null),
            end: new FormControl(null)
        })
    });

    public partners: { name: string, value: number };
    public service_levels: ISelectOption[] = [];

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private userService: UserService,
        private modalService: ModalService,
        private spinnerService: SpinnerService,
        private serviceLevelService: ServiceLevelService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    /**
     * Get warehouse data
     */
    private async getWarehouse(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["warehouse"]);
        if (data && data[0]) {
            const warehouse: Warehouse.IWarehouse = data[0];
            if (warehouse.properties.default_line_type) {
                this.defaultLineType = warehouse.properties.default_line_type;
            }
            this.filterForm.get("lineSelect").setValue(this.defaultLineType);
            this.changeDetectorRef.markForCheck();

            this.prepareList();
        }
        this.spinnerService.hide();
    }

    private async getServiceLevels(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.serviceLevelService.getServiceLevels();
        if (data) {
            this.service_levels = this.serviceLevelService.mapServiceLevelsToSelect(data);
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }


    private async getPartners(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["warehouse", "partners"], {}, {
                data_structure: "select"
            });
        if (data) {
            this.partners = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private getApi(): any {
        const query: any = {};
        if (this.status) {
            query["status_parameter_" + this.status] = true;
        }
        if (this.pending_pickup) {
            query["pending_for_pickup"] = true;
            this.filterForm.get("lineSelect").setValue("outbound", {emitEvent: false});
        }
        if (this.type) {
            query["order_type"] = this.type;
        } else if (this.filterForm.get("typeSelect").value.length) {
            query["order_type"] = this.filterForm.get("typeSelect").value;
        }

        if (this.filterForm.get("lineSelect").value !== "all") {
            query["line_type"] = this.filterForm.get("lineSelect").value;
        }

        if (this.filterForm.get("partner_id").value !== "all") {
            query["partner_id"] = this.filterForm.get("partner_id").value;
        }
        if (this.filterForm.get("service_level_id").value !== "all") {
            query["service_level_id"] = this.filterForm.get("service_level_id").value;
        }

        query["date_from"] = this.filterForm.value.date_range.start;
        query["date_to"] = this.filterForm.value.date_range.end;

        return {
            url: ["warehouse", "order"],
            query
        };
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {

        const tableColumns: Table.ICol[] = [
            {
                data: "shipment.type",
                name: "Shipment.type",
                title: "",
                render: (row: any): string => {
                    const type: string = HelpersService.dotToObjectPath(row, "shipment.type");
                    if (type === "inbound") {
                        return "" +
                            "<button data-action='inbound' type='button' " +
                            "class='mat-mdc-mini-fab mdc-fab mat-success' title='inbound'>" +
                            "<mat-icon class='mat-icon material-icons'>arrow_downward</mat-icon>" +
                            "</button>";
                    } else if (type === "outbound") {
                        return "" +
                            "<button data-action='outbound' type='button' " +
                            "class='mat-mdc-mini-fab mdc-fab mat-warn' title='outbound'>" +
                            "<mat-icon class='mat-icon material-icons'>arrow_upward</mat-icon>" +
                            "</button>";
                    }
                    return "";
                },
                click: (row: any): void => {
                    const path: string[] = [
                        "/warehouse",
                        row.warehouse.slug,
                        "procedures-" + row.shipment.type,
                        "wizard",
                        "order",
                        row.id,
                        "order_ref",
                        row.order.ref,
                        "order_id",
                        row.order.id,
                        "back_to",
                        btoa(this.router.url)
                    ];

                    this.router.navigate(path);
                },
                cssClass: "action",
                searchable: false,
                sortable: false
            },
            {
                data: "shipment.courier_transaction_with_label.label_url",
                name: "Shipment.CourierTransactionWithLabel.tracking_number",
                title: "",
                render: (row: any): string => {
                    if (HelpersService.dotToObjectPath(row, "shipment.courier_transaction_with_label.label_url")) {
                        return "" +
                            "<button data-action='print_label' type='button' " +
                            "class='mat-mdc-mini-fab mdc-fab mat-accent' title='Print label'>" +
                            "<mat-icon class='mat-icon material-icons'>list_alt</mat-icon>" +
                            "</button>";
                    }
                    return "";
                },
                click: (row: any): void => {
                    if (HelpersService.dotToObjectPath(row, "shipment.courier_transaction_with_label.label_url")) {
                        window.open(row.shipment.courier_transaction_with_label.label_url, "_blank");
                    }
                },
                cssClass: "action",
                searchable: false,
                sortable: false
            },
            {
                data: "status.id",
                name: "Status.id",
                title: "",
                render: (row: any): string => {
                    if (Number(row.status.id) === 83) {
                        return "" +
                            "<button data-action='courier_transaction' type='button' " +
                            "class='mat-mdc-mini-fab mdc-fab mat-accent' title='Add courier transaction'>" +
                            "<mat-icon class='mat-icon material-icons'>local_shipping</mat-icon>" +
                            "</button>";
                    }
                    return "";
                },
                click: (row: any): void => {
                    this.addCourierTransaction(row);
                },
                cssClass: "action",
                searchable: false,
                sortable: false
            },
            {
                data: "status.id",
                name: "Status.id",
                title: "",
                render: (row: any): string => {
                    if ((Number(row.status.id) === 64 || Number(row.status.id) === 82)
                        && row.shipment && row.shipment.type === "outbound") {
                        return "" +
                            "<button data-action='courier_transaction' type='button' " +
                            "class='mat-mdc-mini-fab mdc-fab mat-accent' title='Print pick list'>" +
                            "<i class='fa fa-file-pdf-o'></i>" +
                            "</button>";
                    }
                    return "";
                },
                click: async (row: any): Promise<any> => {
                    this.spinnerService.show();

                    if (row.shipment.partner_id == 14 || row.shipment.scan_type === "box") {
                        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
                            ["warehouse_location", "shipment", "" + row.shipment.id],
                            {}, {export: "pdf"});

                        if (data) {
                            window.open(data, "_blank");
                        }
                    } else {
                        const {code, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
                            ["inventory_allocation", "by_shipment", "" + row.shipment.id], {}, {
                                export: "pdf"
                            });

                        if (code === 200) {
                            this.toastService.show(message, "success");
                        }
                    }
                    this.spinnerService.hide();
                },
                cssClass: "action",
                searchable: false,
                sortable: false
            },
            {
                data: "order.partner.icon_path",
                name: "Order.Partner.icon_path",
                title: "Partner",
                render: (row: any): string => {
                    return "<img src='" + row.order.partner.icon_path + "' alt=''>";
                },
                searchable: false,
                sortable: false
            },
            {
                data: "order.ref",
                name: "Order.ref",
                title: "Ref",
                sortable: false
            },
            {
                data: "shipment.ref",
                name: "Shipment.ref",
                title: "Shipment ref",
                searchable: false,
                sortable: false
            },
            {
                data: "order.service_level.name",
                name: "Order.ServiceLevel.name",
                title: "Service level",
                sortable: false
            },
            {
                data: "order.service_level.order_type.display_name",
                name: "Order.ServiceLevel.orderType.display_name",
                title: "Order type",
                sortable: false
            },
            {
                data: "status.name",
                name: "Status.name",
                title: "Status",
                render: (row: any): string => {
                    if (this.userService.validatePermissions(["edit_statuses"])) {
                        return "<button class='mat-mdc-raised-button mdc-button button-150' " +
                            "data-action='status' type='button'>" + row.status.name + "</button>";
                    } else {
                        return row.status.name;
                    }
                },
                click: (row: any): void => {
                    if (this.userService.validatePermissions(["edit_statuses"])) {
                        this.changeStatus(row.id, row.status.id);
                    }
                },
                sortable: false
            },
            {
                data: "order.incoterms",
                name: "Order.incoterms",
                title: "Incoterms",
                sortable: false
            },
            {
                data: "order.status.name",
                name: "Order.Status.name",
                title: "Order status",
                sortable: false
            },
            {
                data: "warehouse_time_created_at",
                name: "warehouse_time_created_at",
                title: "Created at",
                sortable: false
            },
            {
                data: "requested_on",
                name: "requested_on",
                title: "Requested at",
                sortable: false
            },
        ];

        const exportColumns: Table.ICol[] = tableColumns.slice(4);

        for (const ind in exportColumns) {
            if (exportColumns[ind].title === "Partner") {
                exportColumns[ind].data = "order.partner.display_name";
                exportColumns[ind].name = "Order.Partner.display_name";
            }
        }

        this.listTable = {
            table_id: "FdfG08O34fz",
            api: this.getApi(),
            actions: [],
            columns: tableColumns,
            export: {
                file_name: "warehouse orders",
                columns: exportColumns,
            },
            search_in: {
                "Ref": "Order.ref",
                "Service level": "Order.ServiceLevel.name",
                "Order type": "Order.ServiceLevel.orderType.display_name",
                "Status": "Status.name",
                "Order status": "Order.Status.name",
                "Tracking number": "Shipment.CourierTransactions.tracking_number",
            }

        };
        this.changeDetectorRef.markForCheck();
    }


    /**
     * Open modal to change order status
     * @returns {Promise<any>}
     */
    private async changeStatus(order_id: number, status_id: number): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(ChangeStatusFormComponent, {
            id: order_id,
            type: "warehouse_order",
            status: status_id,
            modalWidth: 450
        });
        if (response) {
            this.tableRef.reload();
        }
    }

    /**
     * Create new courier transaction
     * @returns {Promise<any>}
     */
    private async addCourierTransaction(wh_order: any): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(CourierTransactionFormComponent, {
            type: wh_order.shipment.type,
            action: "add",
            state: this.state,
            shipmentId: wh_order.shipment.id,
            orderRef: wh_order.order.ref,
            show_link_warehouse_transactions: false,
            link_warehouse_transactions: false,
            partnerSlug: wh_order.order.partner.slug,
            orderData: wh_order.order
        });

        if (response) {
            this.tableRef.reload();
        }
    }

    public selectAllTypes(): void {
        const types: string[] = ["rma", "transfer"];
        if (this.filterForm.get("lineSelect").value !== "outbound") {
            types.push("replenishment");
        }
        if (this.filterForm.get("lineSelect").value !== "inbound") {
            types.push("sales");
        }
        this.filterForm.get("typeSelect").setValue(types);
    }

    public resetFilters(): void {
        this.filterForm.reset({
            typeSelect: [],
            lineSelect: this.defaultLineType,
            partner_id: [],
            service_level_id: [],
            date_range: {
                start: null,
                end: null
            }
        });
    }

    public ngOnInit(): void {
        this.getWarehouse();
        this.getPartners();
        this.getServiceLevels();

        this.filterForm.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged(), debounceTime(500))
            .subscribe((): void => {
                this.tableRef.reload(this.getApi());
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
