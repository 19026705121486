import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import {Router} from "@angular/router";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Table2Component} from "../../../../../../common/components/table2";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {PartnerService} from "../../../../../../common/services/partner.service";
import {CommonPartMasterViewComponent,} from "../../../../common/components/part-master";
import {AmplitudeService} from "../../../../../../common/services/amplitude.service";
import {FormControl} from "@angular/forms";
import {debounceTime, takeUntil} from "rxjs/operators";

@Component({
    selector: "partner-part-master-list",
    template: `
        <common-confirm></common-confirm>
        <h1 class="container-heading">Part master</h1>

        <mat-card>
            <mat-card-content>
                <common-table2 *ngIf="listTable" [settings]="listTable">
                    <div row1 class="row flex align-center">
                        <a *userAllowed="'add_part_masters'" mat-raised-button color="primary" (click)="addPart()">
                            Add New
                        </a>

                        <a *userAllowed="'add_part_masters'" mat-raised-button color="accent" (click)="upload()">
                            Upload CSV
                        </a>

                        <div *userAllowed="'browse_admin'">
                            <mat-checkbox [formControl]="show_only_active">Show only active</mat-checkbox>
                        </div>
                    </div>
                </common-table2>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartnerPartMasterListComponent implements OnInit, Base.IComponent, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    public readonly state: Base.IState;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.ISettings;
    /* Table options */

    public showExportButton: boolean = false;

    public show_only_active: FormControl = new FormControl(true);

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private modalService: ModalService,

    ) {
    }

    private getUrl(): Table.ITableApi {
        return {
            url: ["partner", PartnerService.partner.slug, "part-masters"],
            query: {
                is_active: this.show_only_active.value ? this.show_only_active.value : null
            },
            version: 3
        };
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {

        this.listTable = {
            table_id: "nOu831pUy9K53",
            actions: [
                {
                    name: "view",
                    title: "",
                    click: async (row: any): Promise<any> => {
                        await this.modalService.open(CommonPartMasterViewComponent, {
                            part_master_id: row.id,
                            add: false,
                            modalWidth: 900
                        });
                    }
                }
            ],
            columns: [
                {
                    "data": "item",
                    "title": "item",
                },
                {
                    "data": "item_id",
                    "title": "item id",
                },
                {
                    "data": "description",
                    "title": "description",
                },
                {
                    "data": "group",
                    "title": "group",
                },
                {
                    "data": "customs_value",
                    "title": "Customs value",
                },
                {
                    "data": "customs_values_currency",
                    "title": "Customs values currency",
                },
                {
                    "data": "declared_value",
                    "title": "Declared value",
                },
                {
                    "data": "declared_value_currency",
                    "title": "Declared value currency",
                },
                {
                    "data": "serial",
                    "title": "Serial",
                },
                {
                    "data": "ECCN",
                    "title": "ECCN",
                },
                {
                    "data": "length",
                    "title": "Length",
                },
                {
                    "data": "width",
                    "title": "Width",
                },
                {
                    "data": "height",
                    "title": "Height",
                },
                {
                    "data": "weight",
                    "title": "Weight",
                },
                {
                    "data": "mass_unit",
                    "title": "Mass Unit",
                },
                {
                    "data": "config",
                    "title": "Config",
                },
                {
                    "data": "lot",
                    "title": "Lot",
                },
                {
                    "data": "batch",
                    "title": "Batch",
                },
                {
                    "data": "revision",
                    "title": "Revision",
                },
                {
                    "data": "contains_battery",
                    "title": "Contains battery",
                },
                {
                    "data": "manufacturer",
                    "title": "Manufacturer",
                },
                {
                    "data": "license_exception_code",
                    "title": "License exception code",
                },
                {
                    "data": "country",
                    "title": "Country",
                },
                {
                    "data": "HSC",
                    "title": "HSC",
                },
                {
                    "data": "default_quantity",
                    "title": "Default quantity",
                },
                {
                    "data": "family",
                    "title": "Family",
                },
                {
                    "data": "is_virtual",
                    "title": "Is virtual",
                },
                {
                    "data": "is_active",
                    "title": "Is active",
                },
                {
                    "data": "required_serial_wh_outbound",
                    "title": "Required serial WH outbound",
                },
                {
                    "data": "required_serial_wh_inbound",
                    "title": "Required serial WH inbound",
                },
                {
                    "data": "required_serial_order_creation_outbound",
                    "title": "Required serial order creation outbound"
                },
                {
                    "data": "required_serial_order_creation_inbound",
                    "title": "Required serial order creation inbound"
                },
                {
                    "data": "required_batch_outbound",
                    "title": "Required batch outbound",
                },
                {
                    "data": "required_batch_inbound",
                    "title": "Required batch inbound",
                },
                {
                    "data": "required_revision_outbound",
                    "title": "Required revision outbound",
                },
                {
                    "data": "required_revision_inbound",
                    "title": "Required revision inbound",
                },
                {
                    "data": "required_lot_outbound",
                    "title": "Required lot outbound",
                },
                {
                    "data": "required_lot_inbound",
                    "title": "Required lot inbound",
                },
                {
                    "data": "return_instructions",
                    "title": "Return instructions",
                },
                {
                    "data": "billable",
                    "title": "Billable",
                },
                {
                    "data": "dangerous_goods",
                    "title": "Dangerous goods",
                },
                {
                    "data": "days_to_inspection",
                    "title": "Days to inspection",
                },
            ],
            api: this.getUrl(),
            export: {
                file_name: "Part-Masters",
            }
        };

        this.changeDetectorRef.markForCheck();
    }

    public async addPart(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(CommonPartMasterViewComponent, {
            add: true
        });
        if (response) {
            this.tableRef.reload();
        }
        AmplitudeService.eventClick("Part master add new");
    }

    public upload(): void {
        this.router.navigate([
            this.state.section,
            "csv-import",
            "wizard",
            "type",
            "partmaster",
            "back_to",
            btoa(this.router.url)
        ]);
        AmplitudeService.eventClick("Part master upload csv");
    }

    public ngOnInit(): void {
        this.prepareList();

        this.show_only_active.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(100))
            .subscribe((): void => {
                this.tableRef.reload(this.getUrl());
            });
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "part-master-list", // path
            actions: {
                "browse": ["browse_part_masters"]
            }
        };
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
