import {Subject} from "rxjs";
import {ISnackData, ToastService} from "../../services/toast.service";
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {Router} from "@angular/router";

import * as moment from "moment";
import {takeUntil} from "rxjs/operators";
import {HelpersService} from "../../services/helpers.service";
import {RequestFormComponent} from "../../../section/components/common/components/requests";
import {ModalService} from "../../../section/services/modal.service";

@Component({
    selector: "common-snackbar",
    template: `
        <div class="container snackbar {{data.type}}" [class.closed]="data.close"
             *ngFor="let data of messages; let i = index">
            <div class="message">
                <div *ngIf="data.message.url" [innerHTML]="data.message.message" class="text-underline pointer"
                     (click)="onClick(data.message)"></div>
                <div *ngIf="!data.message.url" [innerHTML]="data.message.message"></div>
                <div *ngIf="data.message.request">
                    <a class="request-button mat-mdc-raised-button mat-accent" (click)="request(data.message)">
                        <mat-icon class="vertical-middle">bug_report</mat-icon>
                        Report bug
                    </a>
                </div>
            </div>
            <div class="loader" [class.long]="data.duration > 7000" (click)="dismiss(data.id)">
                <mat-icon>close</mat-icon>
            </div>
        </div>`,
    styles: [
        `
                     .container {
                         display: flex;
                         flex-flow: row nowrap;
                         justify-content: space-between;
                         align-items: flex-start;
                         max-width: 500px;
                     }

                     .message {
                         padding: 10px;
                     }

                     .message .text-underline {
                         text-decoration: underline;
                     }

                     .message .request-button {
                         cursor: pointer;
                         margin-top: 5px;
                         background: rgba(0, 0, 0, 0.05);
                     }

                     .container .loader {
                         margin: 10px;
                         cursor: pointer;
                     }

                     .loader {
                         width: 26px;
                         height: 26px;
                         border-radius: 50%;
                         position: relative;
                         transform: translateZ(0);
                         background: #fff;
                         color: rgba(0, 0, 0, 0.87);
                         box-sizing: border-box;
                         padding: 1px 0 0 1px;
                     }

                     .loader:before {
                         width: 13px;
                         height: 26px;
                         background: #fff;
                         border-radius: 13px 0 0 13px;
                         position: absolute;
                         top: 0;
                         left: 0;
                         content: '';
                         box-sizing: border-box;
                         z-index: 2;
                         transform-origin: right;
                         animation: loader2 7s linear;
                     }

                     .loader.long:before {
                         animation: loader2 15s linear;
                     }

                     .loader:after {
                         width: 13px;
                         height: 26px;
                         background: rgba(0, 0, 0, 1);
                         border-radius: 13px 0 0 13px;
                         position: absolute;
                         top: 0;
                         left: 0;
                         box-sizing: border-box;
                         content: '';
                         z-index: 1;
                         transform-origin: right;
                         animation: loader1 7s linear;
                     }

                     .loader.long:after {
                         animation: loader1 15s linear;
                     }

                     .loader mat-icon {
                         position: relative;
                         background: rgba(255, 255, 255, 0.87);
                         z-index: 5;
                         border-radius: 50%;
                         display: block;
                         width: 24px;
                         height: 24px;
                         text-align: center;
                         line-height: 24px;

                     }

                     @keyframes loader1 {
                         0% {
                             transform: rotate(0deg);
                         }
                         50% {
                             transform: rotate(180deg);
                         }
                         100% {
                             transform: rotate(180deg);
                             z-index: 2;
                         }
                     }

                     @keyframes loader2 {
                         0% {
                             transform: rotate(0deg);
                         }
                         50% {
                             transform: rotate(0deg);
                             background: #fff;
                             z-index: 2;
                         }
                         50.01% {
                             background: rgba(0, 0, 0, 1);
                             transform: rotate(180deg);
                             z-index: 1;
                         }
                         100% {
                             transform: rotate(360deg);
                             background: rgba(0, 0, 0, 1);
                             z-index: 1;
                         }
                     }


                 `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SnackbarComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();


    public close: Subject<void> = new Subject();

    public messages: {
        message: ISnackData, type: string, onOpenCallback: any, close?: boolean, id?: string, duration?: number
    }[] = [];


    public constructor(
        private toastService: ToastService,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        private modalService: ModalService
    ) {

    }

    private fillFromQueue(): void {
        while (this.messages.length < 5) {
            const next: {
                message: ISnackData, type: string, onOpenCallback: any, close?: boolean, id?: string, duration?: number
            } = this.toastService.getNextFromQueue();
            if (!next) {
                break;
            }

            const id: string = HelpersService.randomString();
            next.id = id;

            next.duration = 7000;
            if (next.message.message.length > 50) {
                next.duration = 15000;
            }


            this.messages.push(next);
            this.changeDetectorRef.markForCheck();

            if (next.onOpenCallback) {
                next.onOpenCallback();
            }

            this.closeTimer(id, next.duration);
        }

        if (!this.messages.length) {
            this.close.next();
        }
    }

    private closeTimer(id: string, duration: number): void {
        setTimeout((): void => {
            this.dismiss(id);
        }, duration);
    }


    public onClick(data: ISnackData): void {
        if (Array.isArray(data.url)) {
            this.router.navigate(data.url);
        }
        return;
    }

    public request(data: ISnackData): void {
        let params: any = {};
        if (data.request.type === "error") {
            params = {
                url: window.location.pathname,
                title: "bug#" + moment().format("YYYY-MM-DD HH:mm:SS"),
                assignee_id: environment.bugRequestParams.assignee_id,
                request_kind_id: environment.bugRequestParams.request_kind_id,
                description: data.message
            };
        }

        this.modalService.open(RequestFormComponent, params);

        this.close.next();
    }

    public dismiss(id: string): void {
        this.messages.forEach((message: any, index: number, array: any[]): void => {
            if (message.id === id) {
                if (!message.close) {
                    message.close = true;
                    this.changeDetectorRef.markForCheck();
                    setTimeout((): void => {
                        this.dismiss(id);
                    }, 600);
                } else {
                    array.splice(index, 1);
                    this.changeDetectorRef.markForCheck();
                    this.fillFromQueue();
                }
            }
        });
    }

    public ngOnInit(): void {
        this.toastService.queueUpdated.pipe(takeUntil(this.destroy$)).subscribe((): void => {
            this.fillFromQueue();
        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
