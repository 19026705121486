import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Router} from "@angular/router";
import {ApiService} from "../../../../../../common/services/api.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {FormControl} from "@angular/forms";
import {takeUntil} from "rxjs/operators";
import {StorageService} from "../../../../../../common/services/storage.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {Table2Component} from "../../../../../../common/components/table2";
import {DomSanitizer} from "@angular/platform-browser";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {MilestoneFormComponent} from "../form/form.component";
import {MilestoneReasonFormComponent} from "../reason-form/reason-form.component";
import {AmplitudeService} from "../../../../../../common/services/amplitude.service";

@Component({
    selector: "section-milestones-list",
    templateUrl: "list.component.html",
    styleUrls: ["list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class MilestonesListComponent implements Base.IComponent, OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    public readonly state: Base.IState;


    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.ISettings;

    public showAll: FormControl = new FormControl(false);

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private storageService: StorageService,
        private spinnerService: SpinnerService,
        private modalService: ModalService,
        private sanitizer: DomSanitizer,
        private changeDetectorRef: ChangeDetectorRef
    ,
                       ) {
    }

    private getUrl(): any {
        return {
            url: ["milestones"],
            query: {
                show_all: this.showAll.value ? 1 : 0
            }
        };
    }

    private getColumns(): Table.ICol[] {
        return [
            {
                data: "milestone_type.name",
                name: "MilestoneType.name",
                title: "Name"
            },
            {
                data: "notes",
                name: "notes",
                title: "Notes",
            },
            {
                data: "estimated_date",
                name: "estimated_date",
                title: "Estimated date"
            },
            {
                data: "complete_date",
                name: "complete_date",
                title: "Complete date"
            },
            {
                data: "notified_at",
                name: "notified_at",
                title: "Notified at",
                render: (row: any): string => {
                    return `
                     <div class="notified ${!row.notified_at ? "gray" : ""}">
                        <div class="date">
                            ${row.notified_at || ""}
                        </div>
                    </div>
                `;
                }
            },
            {
                data: "order_ref",
                name: "order_ref",
                title: "Order ref",
                render: (row: any): string => {
                    return `
                     <button class="mat-mdc-raised-button mdc-button button-200">${row.order_ref || ""}</button>
                `;
                },
                click: (row: any): void => {
                    this.router.navigate([
                        this.state.section,
                        "orders",
                        "view",
                        "id",
                        row.order_id
                    ]);
                },
                sortable: false,
                searchable: false
            },
        ];
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {

        const columns: Table.ICol[] = this.getColumns();

        this.listTable = {
            table_id: "3gs244da",
            api: this.getUrl(),
            actions: [
                {
                    name: "edit",
                    title: "Edit",
                    click: (row: any): void => {
                        this.edit(row);
                    }
                }
            ],
            per_page: 50,
            columns
        };
        this.changeDetectorRef.markForCheck();
    }

    private prepareListeners(): void {
        this.showAll.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: boolean): void => {
            this.tableRef.reload(this.getUrl());
        });
    }

    public async edit(data: any): Promise<any> {
        const [type, slug]: string[] = this.state.section.split("/");
        const response: Modal.IResponse = await this.modalService.open(MilestoneFormComponent, {
            add: false,
            partner: {type: type, slug: slug},
            state: this.state,
            action: "edit",
            data: data
        });
        if (response) {
            this.tableRef.reload();
        }
    }

    public async reasons(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(MilestoneReasonFormComponent);
        AmplitudeService.eventClick("Milestones reasons");
    }

    public ngOnInit(): void {

        this.prepareList();
        this.prepareListeners();

    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "milestones",
            actions: {
                "browse": ["browse_milestones"]
            }
        };
    }
}
