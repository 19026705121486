import { Component, ChangeDetectionStrategy, OnInit,
    ChangeDetectorRef, OnDestroy, EventEmitter,ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { takeUntil } from "rxjs/operators";
import { ConfirmComponent } from "src/modules/common/components/confirm/confirm.component";
import { Table2Component } from "src/modules/common/components/table2";
import { Base } from "src/modules/common/interfaces/base.interfaces";
import { Form } from "src/modules/common/interfaces/form.interface";
import { Table } from "src/modules/common/interfaces/table.interface";
import { Warehouse } from "src/modules/common/interfaces/warehouse.interface";
import { Api, ApiService } from "src/modules/common/services/api.service";
import { Api3Service } from "src/modules/common/services/api3.service";
import { PartnerService } from "src/modules/common/services/partner.service";
import { SpinnerService } from "src/modules/common/services/spinner.service";
import { Modal, ModalService } from "src/modules/section/services/modal.service";
import { PackageBoxesAddItemFormComponent } from "../add-item-form/add-item-form.component";
import { Router } from "@angular/router";

@Component({
    selector: "partner-packages-boxes-edit",
    templateUrl: "edit.component.html",
    styleUrls: ["edit.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PackagesBoxesEditComponent implements OnInit, OnDestroy, Base.IComponent {
    public state: Base.IState;

    public box: any;

    public pallets: any[] = [];
    public palletsFiltered: Form.ISelectOption[] = [];
    public palletsSearch: FormControl = new FormControl(null);

    public hubs: any[] = [];
    public hubsFiltered: Form.ISelectOption[] = [];
    public hubsSearch: FormControl = new FormControl(null);

    public locations: any[] = [];
    public locationsFiltered: Form.ISelectOption[] = [];
    public locationsSearch: FormControl = new FormControl(null);

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    @ViewChild(ConfirmComponent, {static: true})
    public confirmComponentRef: ConfirmComponent;

    public item: any;

    public listTable: Table.ISettings;

    public formGroup: FormGroup = new FormGroup({
        ref: new FormControl(null, [Validators.required]),
        pallet_id: new FormControl(null),
        hub_id: new FormControl(null, [Validators.required]),
        warehouse_location_id: new FormControl(null, [Validators.required])
    });

    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    public isEdit: boolean = false;

    public isChangeLocationDisabled = false;

    public constructor(
        private apiV3Service: Api3Service,
        private changeDetectorRef: ChangeDetectorRef,
        private spinnerService: SpinnerService,
        private modalService: ModalService,
        private router: Router,
        private apiService: ApiService) {
    }

    private get tableUrl(): Table.ITableApi {
        return {
            url: ["partner", this.state.section_slug, "inventories"],
            query: {
                boxes: [this.box?.ref]
            },
            version: 3
        };
    }

    /**
     * Prepare table
     * @returns {void}
     */
    private prepareItemsLists(): void {
        const columns = [
            {
                data: "ref",
                title: "Ref"
            },
            {
                data: "item",
                title: "Item"
            },
            {
                data: "batch",
                title: "Batch"
            },
            {
                data: "serial",
                title: "Serial",
            },
            {
                data: "quantity",
                title: "Quantity"
            },
            {
                data: "created_at",
                name: "created_at",
                title: "Created At",
                searchable: false
            }
        ];

        this.listTable = {
            table_id: "box-edit-table",
            api: this.tableUrl,
            actions: [
                {
                    name: "unlink",
                    title: "Unlink",
                    click: (row: any) => {
                        this.unlink(row.id);
                    }
                }
            ],
            columns
        };
        this.changeDetectorRef.markForCheck();
    }

    private async getEmptyPalletsByLocation(locationId: number): Promise<void> {
        const result = await this.apiV3Service.request(Api.EMethod.Get, 
            `partner/${PartnerService.partner.slug}/pallets`, {}, 
            {
                location_id: locationId
            }
        );
        if (result?.data) {
            this.pallets = result.data;
            this.palletsFiltered = this.pallets;
        }
        this.changeDetectorRef.markForCheck();
    }

    private async getHubs(): Promise<any> {
        this.spinnerService.show();

        const threeplsData = await this.apiV3Service.request(Api.EMethod.Get,
            `partner/${PartnerService.partner.slug}/threepls`, {}, {
            });
        const threepls = threeplsData.data.map((item: any) => {
            return item.id;
        });

        const {data}: Api.IResponse = await this.apiV3Service.request(Api.EMethod.Get,
            `partner/${PartnerService.partner.slug}/hubs`, {}, {
                only_active: true,
                threepls,
                group_by: "customers_inventory_name",
                relations: [
                    "Warehouse:slug,type,address_id"
                ]
            });
        if (data) {
            this.hubs = data;
            this.hubsFiltered = this.hubs;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private async getLocations(hubId: number): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["hub", "" + hubId, "locations"], {}, {
                data_structure: "select"
            });
        if (data) {
            this.locations = data;
            this.locationsFiltered = this.locations;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private setDisabledControls(controls: string[]): void {
        for (const controlName of controls) {
            this.formGroup.controls[controlName].disable();
        }
    }

    private setEnabledControls(controls: string[]): void {
        for (const controlName of controls) {
            this.formGroup.controls[controlName].enable();
        }
    }

    private async getBoxData(box_id: number): Promise<void> {
        this.spinnerService.show();
        const response = await this.apiV3Service.request(Api.EMethod.Get,
            `partner/${PartnerService.partner.slug}/boxes/${box_id}`, {}, 
            {
                relations: ["WarehouseLocation.Warehouse", "Inventories"]
            }
        );

        if (response?.data?.length) {
            this.box = response.data[0];
            const formValues = this.box;
            const hubAddressId = this.box?.warehouse_location?.warehouse.address_id;
            if (hubAddressId) {
                const hub = this.hubs.find(hub => 
                    hub.warehouse.address_id == hubAddressId
                );
                if (hub) {
                    this.formGroup.controls["hub_id"].setValue(hub.id);
                    await this.getLocations(hub.id);
                    const locationId = this.box?.warehouse_location?.id;
                    if (locationId) {
                        this.formGroup.controls["warehouse_location_id"].setValue(locationId);
                        await Promise.all([
                            this.getEmptyPalletsByLocation(locationId)
                        ]);
                    }
                }
            }
            this.formGroup.patchValue(formValues);
        }
        this.spinnerService.hide();
    }

    public async submit(): Promise<void> {
        this.spinnerService.show();
        const formData = this.formGroup.getRawValue();
        if (!this.isEdit) {
            const response = await this.apiV3Service.request(Api.EMethod.Post,
                `partner/${PartnerService.partner.slug}/boxes`,
                 formData
           );
           if (response?.data?.id) {
                this.isEdit = true;
                this.box = response?.data;
                this.prepareItemsLists();
           }
        } else {
            await this.apiV3Service.request(Api.EMethod.Put,
                `partner/${PartnerService.partner.slug}/boxes/${this.box.id}`,
                 formData
           );
        }
        this.spinnerService.hide();
    }

    public async ngOnInit(): Promise<void> {
        if (this.state.action !== "add") {
            this.isEdit = true;
        }

        await this.getHubs();

        if (this.isEdit) {
            this.setDisabledControls(["warehouse_location_id", "pallet_id", "hub_id"]);
            this.isChangeLocationDisabled = true;
            await this.getBoxData(this.state.params.id);
            this.prepareItemsLists();
            if (!this.box?.inventories?.length) {
                this.isChangeLocationDisabled = false;
                this.setEnabledControls(["warehouse_location_id", "pallet_id", "hub_id"]);
            }
        }

        this.formGroup.controls["hub_id"].valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
            if (value) {
                this.getLocations(value);
                if (!this.isChangeLocationDisabled) {
                    this.setEnabledControls(["warehouse_location_id"]);
                }
            } else {
                this.setDisabledControls(["warehouse_location_id", "pallet_id"]);
            }
        });

        this.formGroup.controls["warehouse_location_id"].valueChanges.pipe(takeUntil(this.destroy$))
        .subscribe((value: any) => {
            if (value) {
                this.getEmptyPalletsByLocation(value);
                this.setEnabledControls(["pallet_id"]);
            } else {
                this.setDisabledControls(["pallet_id"]);
            }
        });

        this.palletsSearch.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value: string): void => {
                if (value) {
                    this.palletsFiltered = this.pallets.filter((pallet: { ref: string }): boolean =>
                        pallet.ref.toLowerCase().indexOf(value.toLowerCase()) > -1);
                } else {
                    this.palletsFiltered = this.pallets;
                }
                this.changeDetectorRef.markForCheck();
            });

        this.hubsSearch.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value: string): void => {
                if (value) {
                    this.hubsFiltered = this.hubs.filter((hub: { customers_inventory_name: string }): boolean =>
                        hub.customers_inventory_name.toLowerCase().indexOf(value.toLowerCase()) > -1);
                } else {
                    this.hubsFiltered = this.hubs;
                }
                this.changeDetectorRef.markForCheck();
            });
        
        this.locationsSearch.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value: string): void => {
                if (value) {
                    this.locationsFiltered = this.locations.filter((location: { name: string }): boolean =>
                        location.name.toLowerCase().indexOf(value.toLowerCase()) > -1);
                } else {
                    this.locationsFiltered = this.locations;
                }
                this.changeDetectorRef.markForCheck();
            });
    }

    public async addItem(): Promise<void> {
        if (this.formGroup.controls["warehouse_location_id"].value != this.box.warehouse_location_id) {
            const question = "'Add item' is not available until changes are saved. Would you like to save them now?";
            if (await this.confirmComponentRef.confirm(question)) {
                await this.submit();
            } else {
                return;
            }
        }
        
        const response: Modal.IResponse = await this.modalService.open(PackageBoxesAddItemFormComponent, {
            locationId: this.formGroup.controls["warehouse_location_id"].value,
            boxId: this.box.id
        });
        if (response) {
            this.isChangeLocationDisabled = true;
            this.setDisabledControls(["warehouse_location_id", "pallet_id", "hub_id"]);
            this.tableRef.reload(this.tableUrl);
        }
    }

    public async unlink(inventoryId: number): Promise<void> {
        if (!await this.confirmComponentRef.confirm("Are you sure want to unlink item from box?")) {
            return;
        }
        this.spinnerService.show();
        await this.apiV3Service.request(Api.EMethod.Post,
            `partner/${PartnerService.partner.slug}/boxes/${this.box.id}/detach_item`, {
                inventory_id: inventoryId
            });
        this.tableRef.reload(this.tableUrl);
        if (!this.tableRef?.tableData?.length) {
            this.isChangeLocationDisabled = false;
            this.setEnabledControls(["warehouse_location_id", "pallet_id", "hub_id"]);
        }
        this.spinnerService.hide();
    }

    public async unlinkAll(): Promise<void> {
        if (!await this.confirmComponentRef.confirm("Are you sure want to unlink all items from box?")) {
            return;
        }
        this.spinnerService.show();
        await this.apiV3Service.request(Api.EMethod.Post,
            `partner/${PartnerService.partner.slug}/boxes/${this.box.id}/detach_all`);
        this.tableRef.reload(this.tableUrl);
        this.isChangeLocationDisabled = false;
        this.setEnabledControls(["warehouse_location_id", "pallet_id", "hub_id"]);
        this.spinnerService.hide();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "packages-boxes",
            actions: {
                "edit": ["browse_part_masters"],
                "add": ["browse_part_masters"],
            }
        };
    }
}