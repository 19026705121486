import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input, OnChanges,
    OnInit,
    Output, SimpleChanges
} from "@angular/core";
import {Observable} from "rxjs";
import {GoogleMapsApiService} from "../../services/google-maps-api.service";
import {Api} from "../../services/api.service";
import {GMap} from "./gmap.component";

@Component({
    selector: "common-static-map",
    template: `
        <common-simple-spinner
            *ngIf="!staticMapUrl"></common-simple-spinner>

        <img *ngIf="staticMapUrl" [src]="staticMapUrl"
             [width]="this.width"
             [height]="this.height"
             alt="map">
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StaticMapComponent implements OnInit {

    @Input()
    public center: { lat: number, lng: number };

    @Input()
    public markers: GMap.IMarker[] = [];

    @Input()
    public zoom: number = 10;

    @Input()
    public width: number = 300;

    @Input()
    public height: number = 300;

    @Input()
    public scale: number = 1;

    public staticMapUrl: string = null;


    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private mapsService: GoogleMapsApiService
    ) {

    }

    private async getStaticMap(): Promise<any> {
        const {data}: Api.IResponse = await this.mapsService.getStaticMap(
            this.center,
            this.markers,
            {width: this.width, height: this.height},
            this.zoom,
            this.scale
        );

        if (data) {
            this.staticMapUrl = data.image;
            this.changeDetectorRef.markForCheck();
        }
    }

    public ngOnInit(): void {
        this.getStaticMap();
    }
}
