import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Router} from "@angular/router";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {takeUntil} from "rxjs/operators";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";

export interface ILabelSize {
    id: number;
    name: string;
    width: number;
    height: number;
}

@Component({
    selector: "section-custom-label-view",
    templateUrl: "view.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    styles: [
        `
            section-custom-label-view .label-preview {
                background-color: #fff;
                padding: 15px;
                border-radius: 5px;
                margin: 0 auto;
                color: #000;
                overflow: hidden;
            }

            section-custom-label-view .label-preview * {
                margin: 0;
                padding: 0;
            }

            section-custom-label-view h2 {
                border-bottom: none !important;
            }
        `
    ]
})

export class CustomLabelViewComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    public state: Base.IState;

    public labelSizes: ILabelSize[] = [];

    public formGroup: FormGroup = new FormGroup({
        name: new FormControl(null, [Validators.required]),
        size_type: new FormControl(1, [Validators.required]),
        size: new FormGroup({
            id: new FormControl(null, [Validators.required]),
            name: new FormControl(null),
            width: new FormControl(150, [Validators.required]),
            height: new FormControl(100, [Validators.required]),
        }),
        body: new FormControl("", [Validators.required]),
    });

    @ViewChild("sticker", {static: false})
    public sticker: ElementRef;

    @ViewChild(ConfirmComponent, {static: true})
    public confirm: ConfirmComponent;

    public editorInitialValue: string = null;


    public constructor(
        private apiService: ApiService,
        private spinnerService: SpinnerService,
        private toastService: ToastService,
        private sanitizer: DomSanitizer,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef
    ) {

    }

    private async getLabel(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["custom_labels", "" + this.state.params.id]);
        this.spinnerService.hide();
        if (data) {
            this.formGroup.patchValue(data);
            this.editorInitialValue = data.body;
            this.changeDetectorRef.markForCheck();
        }
    }

    private async getSizes(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["custom_labels", "sizes"]);
        this.spinnerService.hide();
        if (data) {
            this.labelSizes = data;
            if (!this.labelSizes.length) {
                this.formGroup.get("size_type").setValue(2);
            } else if (this.labelSizes.length === 1 || !this.state.params.id) {
                this.formGroup.get("size").get("id").setValue(this.labelSizes[0].id);
            }
            this.changeDetectorRef.markForCheck();
        }
    }

    public trustHtml(html: string): SafeHtml {
        if (html && html.trim()) {
            return this.sanitizer.bypassSecurityTrustHtml(html);
        }
        return "";
    }

    public print(): void {
        const printContents: any = this.sticker.nativeElement.innerHTML;
        const popupWin: any = window.open("", "_blank", "top=0,left=0,height=100%,width=auto");
        try {
            popupWin.focus();
            popupWin.document.open();
            popupWin.document.write(`
              <html lang="en-US">
                <head>
                  <title></title>
                  <style>
                      body {
                            border: 1px solid #ccc;
                            padding: 15px;
                            box-sizing: border-box;
                            margin: 0;
                        }
                  </style>
                </head>
                <body onload="window.print();window.close()"
                    style="width: ${this.formGroup.value.size.width}mm;height: ${this.formGroup.value.size.height}mm;">
                    ${printContents}
                </body>
              </html>`
            );

            popupWin.document.close();
        } catch (e) {
            alert("Pop-up Blocker is enabled! Please add this site to your exception list.");
        }
    }

    public getSelectedSize(id: number): any {
        if (!id) {
            return null;
        }
        return this.labelSizes.find((size: ILabelSize) => {
            return size.id === id;
        });
    }

    public showSizeString(size: ILabelSize): string {
        if (!size) {
            return "";
        }
        return `${size.name} (${size.width}mm x ${size.height}mm)`;
    }

    public async saveSize(): Promise<any> {
        this.spinnerService.show();

        const {message, code}: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["custom_labels", "sizes"], this.formGroup.value.size);

        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.getSizes();
        }
    }

    public async deleteSize(id: number): Promise<any> {
        if (!await this.confirm.confirm("Are you sure want to delete this size? This action cannot be undone!")) {
            return;
        }
        this.spinnerService.show();

        const {message, code}: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
            ["custom_labels", "sizes", "" + id]);

        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.getSizes();
        }
    }

    public async saveLabel(): Promise<any> {
        this.spinnerService.show();
        let res: Api.IResponse;

        if (this.state.params.id) {
            res = await this.apiService.request(Api.EMethod.Put,
                ["custom_labels", "" + this.state.params.id], this.formGroup.value);
        } else {
            res = await this.apiService.request(Api.EMethod.Post,
                ["custom_labels"], this.formGroup.value);
        }

        this.spinnerService.hide();
        if (res.data) {
            this.toastService.show(res.message, "success");
            if (!this.state.params.id) {
                this.router.navigate([
                    this.state.section,
                    this.state.component,
                    "view",
                    "id",
                    res.data.id
                ]);
            }
        }
    }

    public async deleteLabel(): Promise<any> {
        if (!await this.confirm.confirm("Are you sure want to delete this label? This action cannot be undone!")) {
            return;
        }

        this.spinnerService.show();

        const {message, code}: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
            ["custom_labels", "" + this.state.params.id]);

        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.goToList();
        }
    }

    public goToList(): void {
        this.router.navigate([
            this.state.section,
            this.state.component,
        ]);
    }

    public ngOnInit(): void {
        if (this.state.params.id) {
            this.getLabel();
        }
        this.getSizes();

        this.formGroup.get("size").get("id").valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value: number): void => {
                const size: ILabelSize = this.getSelectedSize(value);
                if (size) {
                    this.formGroup.get("size").get("name").setValue(size.name);
                    this.formGroup.get("size").get("width").setValue(size.width);
                    this.formGroup.get("size").get("height").setValue(size.height);
                    this.changeDetectorRef.markForCheck();
                }
            });
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "custom-labels",
            actions: {
                "view": "*",
                "add": "*"
            }
        };
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
