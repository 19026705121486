import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {AddressFormComponent, AddressListModalComponent} from "../../address";
import {Modal, ModalService} from "../../../../../services/modal.service";

@Component({
    selector: "section-country-instructions-form",
    templateUrl: "form.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class CountryInstructionsFormComponent implements Base.IComponent, OnInit, OnDestroy {
    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    private address_country: string = null;

    public readonly state: Base.IState;

    public formGroup: FormGroup = new FormGroup({
        country_iso_2: new FormControl(null),
        address_ids: new FormControl([]),
        address: new FormControl(null),
        message: new FormControl(null, [Validators.required]),
    });

    public countries: any[];

    public components_address: any = {
        form: AddressFormComponent,
        list: AddressListModalComponent
    };

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private helpers: HelpersService,
        private modalService: ModalService,
        private spinnerService: SpinnerService
    ) {
    }


    private async prepareForm(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["address", "country", "instructions", this.state.params.id]);

        this.spinnerService.hide();
        if (data) {
            data.address_ids = [data.address_id];
            this.address_country = data.country_iso_2;
            this.formGroup.patchValue(data);
            if (data.address) {
                this.formGroup.get("address")
                    .setValue(data.address.address + ", " + data.address.city + ", " + data.address.country);
            }
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Prepare countries (as control options)
     * @returns {Promise<any>}
     */
    private async prepareCountriesList(): Promise<any> {
        this.spinnerService.show();
        this.countries = await this.helpers.prepareCountriesList();
        this.spinnerService.hide();
        this.changeDetectorRef.markForCheck();
    }

    private async submitNew(): Promise<any> {
        this.spinnerService.show();
        const {data, message, code}: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["address", "country", "instructions"], this.formGroup.value);

        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.goToList();
        }
    }

    private async submitEdit(): Promise<any> {
        this.spinnerService.show();
        const data = this.formGroup.value;
        data.address_id = data.address_ids[0];
        const {code, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["address", "country", "instructions", this.state.params.id], data);

        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.goToList();
        }
    }

    /**
     * Handle autocomplete selected value event
     * @param event
     */
    public onCountrySelected(event: any): void {
        this.formGroup.get("country_iso_2").setValue(event.value);
        if (event.value && this.address_country !== event.value) {
            this.formGroup.get("address_ids").setValue([]);
            this.formGroup.get("address").setValue(null);
        }
        this.changeDetectorRef.markForCheck();
    }

    public async openAddressModal(type: string = "list"): Promise<any> {
        let response: Modal.IResponse;
        if (type === "list") {
            response = await this.modalService.open(AddressListModalComponent, {
                state: this.state,
                country: this.formGroup.controls["country_iso_2"].value,
                modalWidth:"95%"
            });
        } else if (type === "form") {
            response = await this.modalService.open(AddressFormComponent, {
                state: this.state,
                country: this.formGroup.controls["country_iso_2"].value,
            });
        }

        if (response && response.name) {
            if (response.name !== "value") {
                this.openAddressModal(response.name);
            } else {
                let value = !Array.isArray(response.value) ? [response.value] : response.value;
                this.formGroup.get("address_ids").setValue(value.map((item: any) => item.controlValue));
                let address = value.map((item: any) => item.viewValue).join("\n");
                this.formGroup.get("address").setValue(address);

                if (value[0].refValue && value.length === 1) {
                    this.address_country = value[0].refValue;
                    this.formGroup.get("country_iso_2").setValue(value[0].refValue);
                } else {
                    this.formGroup.get("country_iso_2").setValue(null);
                }
                this.changeDetectorRef.markForCheck();
            }
        }
    }

    public addressUpdate(event: any): void {
        if (event.refValue) {
            this.address_country = event.refValue;
            this.formGroup.get("country_iso_2").setValue(event.refValue);
            this.changeDetectorRef.markForCheck();
        }
    }

    public submit(): void {
        if (this.state.params.id) {
            this.submitEdit();
        } else {
            this.submitNew();
        }
    }

    public goToList(): void {
        this.router.navigate([
            this.state.section,
            this.state.component
        ]);
    }

    public ngOnInit(): void {
        if (this.state.params.id) {
            this.prepareForm();
        }

        this.prepareCountriesList();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "countries-instructions",
            actions: {
                "add": ["add_country_special_instructions"],
                "edit": ["edit_country_special_instructions"]
            }
        };
    }
}
