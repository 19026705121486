import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from "@angular/core";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {FormControl} from "@angular/forms";
import {User} from "../../../../../../common/interfaces/user.interface";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Table2Component} from "../../../../../../common/components/table2";
import {takeUntil} from "rxjs/operators";
import {ModalComponent} from "../../../../../../common/components/modal/modal.component";
import {ToastService} from "../../../../../../common/services/toast.service";
import {WarehouseTransactionFormComponent} from "../../../../threepl/warehouse/order";
import {OrderItemSelectModalComponent, TransactionReplenishmentStatusModalComponent} from "../index";
import {TransactionService} from "../../../../../../common/services/transaction.service";
import {Router} from "@angular/router";
import {CommonFormComponent} from "../../../../../../common/components/form";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {Api3Service} from "../../../../../../common/services/api3.service";
import {AmplitudeService} from "../../../../../../common/services/amplitude.service";

@Component({
    selector: "section-warehouse-transactions-list",
    template: `
        <common-confirm></common-confirm>

        <h1 class="container-heading">{{ params.title }}</h1>
        <mat-card>
            <mat-card-content>
                <common-table2 *ngIf="tableSettings" [settings]="tableSettings" [scrollHeightValue]="320"
                               (onRowsSelected)="setSelectedRows($event)">

                    <div class="flex row align-center">
                        <common-form-date-range label="Received" (valueChange)="dateRange.setValue($event)"
                                                class="margin-right-10"></common-form-date-range>

                        <common-form-select *ngIf="partners && partners.length"
                                            [options]="partners"
                                            [multiple]="true"
                                            class="margin-right-10"
                                            (onClose)="partnerSelect.setValue($event)"
                                            label="Partners"></common-form-select>

                        <mat-checkbox *ngIf="params.type === 'replenishment'"
                                      [formControl]="showAllCheckbox">Show all records
                        </mat-checkbox>


                        <mat-checkbox *ngIf="params.type === 'deleted'" [formControl]="showOnlyInvalidDeleted">
                            Show only invalid transactions
                        </mat-checkbox>


                        <button mat-raised-button type="button" color="primary"
                                *ngIf="params.type === 'replenishment'"
                                (click)="reportForPlanner()">
                            Report
                        </button>


                        <button mat-raised-button type="button" color="primary"
                                *ngIf="params.type === 'inbound' || params.type === 'outbound' || params.type === ''"
                                (click)="report()">
                            Report
                        </button>
                    </div>

                    <div row2 *ngIf="selectedRows.length">
                        <button type="button" mat-raised-button color="warn"
                                *ngIf="params.type !== 'deleted'"
                                (click)="deleteSelectedRows()">
                            {{ params.type === "replenishment" ? "Skip" : "Delete" }} selected
                        </button>

                        &nbsp;

                        <button type="button" mat-raised-button color="primary"
                                *ngIf="params.type === 'invalid' && selectedNoOrder.length"
                                (click)="linkSelected()">
                            Link to order
                        </button>
                    </div>
                </common-table2>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WarehouseTransactionListComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    @Input()
    public params: {
        title: string,
        type: string,
        state: Base.IState
    };

    @Output()
    public result: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    public tableSettings: Table.ISettings;

    public partnerSelect: FormControl = new FormControl([]);

    public dateRange: FormControl = new FormControl(null);

    public showAllCheckbox: FormControl = new FormControl(false);

    public showOnlyInvalidDeleted: FormControl = new FormControl(false);

    public partners: User.IPartner[] = [];

    public selectedRows: any[] = [];
    public selectedNoOrder: any[] = [];

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private modalService: ModalService,
        private spinnerService: SpinnerService,
        private transactionService: TransactionService,
        private toastService: ToastService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    private get url(): { url: string[], query?: { [key: string]: any } } {
        const relations: string[] = [
            "InventoryConversion",
            "WarehouseLocation:id,location",
            "Order.ServiceLevel",
            "Partner",
            "Box",
            "Box.Pallet"
        ];
        if (this.params.type === "invalid") {
            relations.push("NotSkippedValidationErrors");
        }
        if (this.params.type === "replenishment") {
            relations.push("Replenishments");
        }
        if (this.params.type === "deleted") {
            relations.push("DeletedBy:id,name");
        }
        if (this.params.state.section === "admin" || this.params.state.section.includes("warehouse/")) {
            relations.push("Partner");
        }

        return {
            url: ["transaction", this.params.type],
            query: {
                partners: this.partnerSelect.value,
                show_all: this.showAllCheckbox.value,
                show_only_invalid_deleted: this.showOnlyInvalidDeleted.value,
                relations,
                date_range: this.dateRange.value
            }
        };
    }

    /**
     * Get warehouse partners
     */
    private async getWarehousePartners(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["warehouse", "partners"]);
        if (data) {
            this.partners = data.map((partner: User.IPartner): any => {
                return {name: partner.display_name, value: partner.id};
            });
        }
        this.spinnerService.hide();
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {

        const actions: Table.IAction[] = [];
        if (this.params.type !== "deleted") {
            actions.push(
                {
                    name: "edit",
                    title: "Edit",
                    click: async (row: any): Promise<any> => {
                        const response: Modal.IResponse = await this.modalService
                            .open(WarehouseTransactionFormComponent, {
                                action: "edit",
                                order_id: row.order_id,
                                type: row.type,
                                modalWidth: 600,
                                data: row,
                                partner: row.partner,
                                state: this.params.state,
                                warehouse_slug: row.inventory_conversion?.warehouse_slug
                            });

                        if (response) {
                            this.tableRef.reload();
                        }
                        AmplitudeService.eventClick("Edit");
                    }
                }
            );
        }
        if (this.params.type === "deleted") {
            actions.push(
                {
                    name: "restore",
                    title: "Restore",
                    click: async (row: any): Promise<any> => {
                        this.restoreTransaction(row.id);
                    }
                }
            );
        }

        const columns: Table.ICol[] = [
            {
                data: "ref",
                title: "Ref",
            },
            {
                data: "order.ref",
                name: "Order.ref",
                title: "Order",
                sortable: false,
                render: (row: any): string => {
                    if (this.params.state.section_type !== "partner") {
                        return row.order?.ref;
                    }

                    return `<button class="mat-mdc-raised-button mdc-button button-150 ${!row.order ? "mat-warn" : ""}">
                            ${row.order ? row.order.ref : "Link order"}
                        </button>`;
                },
                click: (row: any): void => {
                    if (this.params.state.section_type !== "partner") {
                        return;
                    }

                    if (!row.order) {
                        this.router.navigate([
                            this.params.state.section,
                            "transactions-without-orders",
                            "view",
                            "id",
                            row.id,
                            "type",
                            row.type
                        ]);
                    } else {
                        this.router.navigate([
                            this.params.state.section,
                            "orders",
                            "view",
                            "id",
                            row.order.id
                        ]);
                    }

                }
            },
            {
                data: "type",
                title: "Type",
            },
            {
                data: "item",
                title: "Item",
            },
            {
                data: "serial",
                title: "Serial",
            },
            {
                data: "configurations",
                title: "Configurations",
            },
            {
                data: "batch",
                title: "Batch",
            },
            {
                data: "quantity",
                title: "Quantity",
            },
            {
                data: "warehouse_location.location",
                name: "WarehouseLocation.location",
                title: "Location",
                sortable: false
            },
            {
                data: "rev",
                title: "Rev"
            },
            {
                data: "order.service_level.name",
                name: "Order.ServiceLevel.name",
                title: "Service level",
                sortable: false
            },
            {
                data: "created_at",
                title: "Received at",
                searchable: false
            },
            {
                data: "inventory_conversion.warehouse_slug",
                name: "InventoryConversion.warehouse_slug",
                title: "Warehouse",
                sortable: false
            },
            {
                data: "inventory_conversion.sub_inventory",
                name: "InventoryConversion.sub_inventory",
                title: "Sub inventory",
                sortable: false
            },
            {
                data: "inventory_conversion.gb",
                name: "InventoryConversion.gb",
                title: "GB",
                sortable: false
            },
            {
                data: "box.ref",
                name: "Box.ref",
                title: "Box",
                sortable: false
            },
            {
                data: "box.pallet.ref",
                name: "Box.Pallet.ref",
                title: "Pallet",
                sortable: false
            },

        ];

        if (this.params.state.section === "admin" || this.params.state.section.includes("warehouse/")) {
            columns.splice(0, 0, {
                    data: "partner.display_name",
                    name: "Partner.display_name",
                    title: "Partner",
                    sortable: false
                },
                {
                    data: "partner.icon_path",
                    title: "",
                    render: (row: any): string => {
                        return "<img src='" + row.partner.icon_path + "' alt=''>";
                    },
                    searchable: false,
                    sortable: false,
                    exportable: false
                });
        }

        if (this.params.type === "invalid") {

            actions.push({
                title: "Delete",
                name: "delete",
                click: async (row: any): Promise<any> => {
                    if (!await this.confirmRef.confirm("Are you sure want to delete transaction?")) {
                        return;
                    }

                    this.deleteTransactions([row.id]);
                    AmplitudeService.eventClick("Delete");
                },
            });

            columns.splice(1, 0, {
                data: "ref",
                title: "Errors",
                sortable: false,
                searchable: false,
                exportable: false,
                render: (row: any): string => {
                    if (row.not_skipped_validation_errors && row.not_skipped_validation_errors.length) {
                        return `<button class="mat-mdc-raised-button mdc-button" type="button">Show</button>`;
                    }
                    return "";
                },
                click: (row: any): void => {
                    if (row.not_skipped_validation_errors && row.not_skipped_validation_errors.length) {
                        this.modalService.open(ModalComponent, {
                            title: "Errors",
                            template: row.not_skipped_validation_errors
                                .map((e: { validation_errors: string }): string => e.validation_errors).join("<br>")
                        });
                    }
                    AmplitudeService.eventClick("Show");
                },
            });
            columns.splice(2, 0, {
                data: "ref",
                title: "Release",
                sortable: false,
                searchable: false,
                exportable: false,
                render: (row: any): string => {
                    return `<button class="mat-mdc-mini-fab mdc-fab mat-success" type="button" title="Release">
                                <mat-icon class="material-icons mat-icon">refresh</mat-icon>
                            </button>`;
                },
                click: async (row: any): Promise<any> => {
                    if (await this.confirmRef
                        .confirm("Are you sure want to update inventory despite errors?")) {
                        const response: Modal.IResponse = await this.modalService
                            .open(OrderItemSelectModalComponent, {
                                transaction: row
                            });

                        if (response && response.value) {
                            this.release(row);
                        }
                    }
                    AmplitudeService.eventClick("Release");
                }
            });
        }

        if (this.params.type === "replenishment") {
            columns.splice(1, 0, {
                data: "replenishments.0.comment",
                name: "Replenishments.0.comment",
                title: "Comment",
                render: (row: any): string => {
                    return (row.replenishments[0]?.comment
                        ? row.replenishments[0].comment + " "
                        : "")
                        + "<mat-icon class='material-icons mat-icon pointer'>edit</mat-icon>";
                },
                click: (row: any): void => {
                    this.editParam(row.id, "comment", row.replenishments[0]?.comment);
                },
                sortable: false
            });
            columns.splice(1, 0, {
                data: "ref",
                title: "Notes",
                sortable: false,
                searchable: false,
                exportable: false,
                render: (row: any): string => {
                    if (row.order) {
                        return `<button class="mat-mdc-mini-fab mdc-fab mat-success" type="button" title="Release">
                                <mat-icon class="material-icons mat-icon">info</mat-icon>
                            </button>`;
                    } else {
                        return "";
                    }
                },
                click: async (row: any): Promise<any> => {
                    const res: Modal.IResponse =
                        await this.modalService.open(TransactionReplenishmentStatusModalComponent, {
                            transaction: row
                        });
                    if (res && res.name === "refresh") {
                        this.tableRef.reload(this.url);
                    }
                    AmplitudeService.eventClick("Notes");
                }
            });
            columns.splice(1, 0, {
                data: "replenishments.0.status",
                name: "Replenishments.0.status",
                title: "Replenishment status",
                sortable: false,
                searchable: false
            });
        }

        if (this.params.type === "deleted") {
            columns.splice(12, 0, {
                data: "deleted_at",
                title: "Deleted at",
            });
            columns.splice(13, 0, {
                data: "deleted_by.name",
                name: "DeletedBy.name",
                title: "Deleted By",
                render: (row: any): string => {
                    return row.deleted_by ? row.deleted_by.name : "System";
                },
                sortable: false
            });
        }

        this.tableSettings = {
            table_id: "S1Imn2j3",
            api: this.url,
            actions,
            columns,
            export: {
                file_name: "transactions",
                columns,
            },
            multi_select: ["replenishment", "invalid"].includes(this.params.type),
            infinity_scroll: true
        };
    }

    private async release(row: any): Promise<any> {
        this.spinnerService.show();

        const {code, message}: Api.IResponse = await this.transactionService.releaseInvalidTransactions(
            this.selectedRows.length ?
                this.selectedRows.map((r: any): number => r.id)
                : [row.id]
        );

        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.tableRef.reload();
        }
    }

    private async deleteTransactions(ids: number[]): Promise<any> {
        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.api3Service.request(Api.EMethod.Delete,
            `/${this.params.state.section}/warehouse-transactions/` + this.params.type,
            {
                transactions: ids
            });

        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.tableRef.reload();
        }
    }

    private async removeReplenishments(ids: number[]): Promise<any> {

        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.api3Service.request(Api.EMethod.Delete,
            `/${this.params.state.section}/warehouse-transactions/` + this.params.type,
            {
                transactions: ids
            });

        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.tableRef.reload();
        }
    }

    private async restoreTransaction(id: number): Promise<any> {
        if (!await this.confirmRef.confirm("Confirm transaction restore")) {
            return;
        }

        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.api3Service.request(Api.EMethod.Put,
            `/${this.params.state.section}/warehouse-transactions/${id}/restore`,);

        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.tableRef.reload();
        }
    }

    public setSelectedRows(rows: any): void {
        this.selectedRows = rows;

        this.selectedNoOrder = rows.filter((row: any) => {
            return !row.order_id;
        });

        this.changeDetectorRef.markForCheck();
    }

    public async linkSelected(): Promise<any> {
        const res: Modal.IResponse = await this.modalService.open(CommonFormComponent, {
            formConfig: {
                id: 0,
                name: "Select order",
                fields: [
                    {
                        label: "Order ref",
                        name: "order_id",
                        size: "full",
                        type: "search",
                        required: true,
                        url: "order"
                    },
                    {
                        label: "Link and open order",
                        name: "open_order",
                        size: "full",
                        type: "checkbox",
                    },
                ]
            },
        });

        if (!res?.value?.order_id) {
            return;
        }

        const {code, message}: Api.IResponse = await this.api3Service.put(
            `${this.params.state.section}/warehouse-transactions/link-to-order`, {
                order_id: res.value.order_id,
                transactions: this.selectedNoOrder.map((transaction: any) => {
                    return transaction.id;
                })
            });

        if (code === 200) {
            this.toastService.show(message, "success");
            if (res.value.open_order) {
                this.router.navigate([
                    this.params.state.section,
                    "orders",
                    "view",
                    "id",
                    res.value.order_id
                ]);
            } else {
                this.tableRef.reload(this.url);
            }
        }

    }

    public async reportForPlanner(): Promise<any> {

        this.modalService.open(CommonFormComponent, {
            configUrl: ["report", "order-list-report-for-planner"],
            submitUrl: ["report", "order-list-report-for-planner"],
            asyncKey: HelpersService.randomString()
        });
        AmplitudeService.eventClick("Transaction replenishment report");
    }

    public async report(): Promise<any> {
        const type: string = !!this.params.type ? this.params.type : "outbound";

        await this.modalService.open(CommonFormComponent, {
            configUrl: ["report", "transactions-report-by-type-" + type],
            submitUrl: ["report", "transactions-report-by-type-" + type],
            asyncKey: HelpersService.randomString()
        });

        AmplitudeService.eventClick("Transaction report");
    }

    public async deleteSelectedRows(): Promise<any> {
        if (!await this.confirmRef.confirm((this.params.type === "replenishment" ? "Skip" : "Delete")
            + " selected?")) {
            return;
        }

        const ids: number[] = this.selectedRows.map((row: any) => {
            return Number(row.id);
        });

        if (this.params.type === "invalid") {
            this.deleteTransactions(ids);
        } else if (this.params.type === "replenishment") {
            this.removeReplenishments(ids);
        }
    }

    private async editParam(
        transaction_id: number,
        field: string,
        old_value: any = null
    ): Promise<any> {

        const {value}: Modal.IResponse = await this.modalService.open(CommonFormComponent, {
            formConfig: {
                id: 0,
                name: "Edit " + field,
                description: "",
                fields: [
                    {
                        label: "Value",
                        name: field,
                        size: "full",
                        type: "input",

                        required: true,
                    }
                ]
            },
            values: {
                [field]: old_value
            }
        });

        if (value && value[field]) {
            this.spinnerService.show();
            const {code, message}: Api.IResponse = await this.api3Service.patch(
                `/${this.params.state.section}/warehouse-transactions/${transaction_id}/comment`, value
            );

            this.spinnerService.hide();
            if (code === 200) {
                this.toastService.show(message, "success");
                this.tableRef.reload();
            }
        }
    }

    public ngOnInit(): void {

        if (this.params.state.section_type === "warehouse") {
            this.getWarehousePartners();
        }

        this.prepareList();

        this.partnerSelect.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((): void => {
            this.tableRef.reload(this.url);
        });

        this.dateRange.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((): void => {
            this.tableRef.reload(this.url);
        });

        this.showAllCheckbox.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((): void => {
            this.tableRef.reload(this.url);
        });

        this.showOnlyInvalidDeleted.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((): void => {
            this.tableRef.reload(this.url);
        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
