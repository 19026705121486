<mat-card>

    <h1 class="color-accent">Select locations</h1>

    <form [formGroup]="transferForm">
        <div class="group flex">

            <mat-form-field class="full">
                <mat-label>Warehouse location transfer from</mat-label>
                <input #triggerFrom="matAutocompleteTrigger" type="text" matInput [formControl]="old_location"
                       [matAutocomplete]="autoFrom" required>
                <mat-autocomplete #autoFrom="matAutocomplete" [displayWith]="displayFn"
                                  (optionSelected)="onOptionFromSelected($event)">
                    <mat-option *ngFor="let location of old_locations | async" [value]="location">
                        {{location.location}}
                    </mat-option>

                    <mat-option [disabled]="true">
                        Try the search if the desired location is not listed
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <ng-template [ngIf]="inventories.length">
                <mat-form-field class="quarter3">
                    <mat-label>Inventory</mat-label>
                    <mat-label class="color-accent" *ngIf="transferForm.get('inventory_ids')?.value?.length > 1">
                        * Will be moved whole inventories
                    </mat-label>
                    <mat-select formControlName="inventory_ids" required multiple>
                        <mat-option>
                            <ngx-mat-select-search
                                    [formControl]="inventories_search_input"
                                    [placeholderLabel]="'Find inventory'"
                                    [noEntriesFoundLabel]="'no matching inventory found'">
                                <mat-icon ngxMatSelectSearchClear>clear</mat-icon>
                            </ngx-mat-select-search>
                        </mat-option>

                        <mat-option *ngFor="let inventory of inventories_filtered | async" [value]="inventory.id">
                            <b>Item: </b>{{inventory.item}}
                            <ng-template [ngIf]="inventory.serial">
                                <b>SN:</b> {{inventory.serial}}
                            </ng-template>
                            <ng-template [ngIf]="inventory.configurations">
                                <b>Conf:</b> {{inventory.configurations}}
                            </ng-template>
                            <b>Qty:</b> {{inventory.quantity}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="quarter">
                    <mat-label>Quantity</mat-label>
                    <input matInput type="number" formControlName="quantity" [disabled]="transferForm.get('inventory_id')?.value?.length > 1"
                           [max]="max_qty" required>
                </mat-form-field>
            </ng-template>

            <mat-form-field class="full">
                <mat-label>Warehouse location transfer to</mat-label>
                <input #triggerTo="matAutocompleteTrigger" type="text" matInput [formControl]="new_location"
                       [matAutocomplete]="autoTo" required>
                <mat-autocomplete #autoTo="matAutocomplete" [displayWith]="displayFn"
                                  (optionSelected)="onOptionToSelected($event)">
                    <ng-container *ngFor="let location of new_locations | async">
                        <mat-option [value]="location"
                                    [disabled]="selected_inventory_hub_id && location.inventory && selected_inventory_hub_id !== location.inventory.inventory_conversion_id"
                                    *ngIf="transferForm.get('old_location_id').value !== location.id">
                            {{location.location}}
                            <ng-container
                                    *ngIf="selected_inventory_hub_id && location.inventory && selected_inventory_hub_id !== location.inventory.inventory_conversion_id">
                                &nbsp;
                                <i>(belongs to another hub)</i>
                            </ng-container>
                        </mat-option>
                    </ng-container>

                    <mat-option [disabled]="true">
                        Try the search if the desired location is not listed or add a new one
                    </mat-option>
                    <mat-option (click)="addLocation(new_location_search_input.value)">
                        <mat-icon>add</mat-icon>
                        Add location
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div class="actions">
            <button type="button" mat-raised-button color="accent" [disabled]="!transferForm.valid" (click)="submit()">
                Update
            </button>
        </div>
    </form>

</mat-card>
