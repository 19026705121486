<h1 class="container-heading">SLA Tracking</h1>
<mat-card>
    <mat-card-content>
        <div class="table-wrapper">
            <table class="table">
                <thead>
                <tr>
                    <th>Type</th>
                    <th>Group</th>
                    <th>Expectation</th>
                    <th>Causer</th>
                    <th>Started at</th>
                    <th>Expected at</th>
                    <th></th>
                    <th>Completed at</th>
                </tr>
                </thead>
                <tbody *ngIf="data && data.data">
                <tr *ngFor="let row of data.data">
                    <td>{{row.type}}</td>
                    <td>{{row.category}}</td>
                    <td>{{row.expectation}}</td>
                    <td>{{row.causer}}</td>
                    <td>{{row.started_at}}</td>
                    <td>{{row.expected_at}}</td>
                    <td>
                        <common-counter [startDate]="row.started_at" [hasProgress]="true"
                                        [endDate]="row.expected_at"></common-counter>
                    </td>
                    <td>{{row.completed_at}}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="clearfix" *ngIf="data">
            <common-pagination [data]="data" pid="3rfdg5hf"
                               (goToPage)="prepareData($event.page, $event.per_page)"></common-pagination>
        </div>
    </mat-card-content>
</mat-card>
