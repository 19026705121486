import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    Output,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {Api} from "../../../../../../../common/services/api.service";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {Warehouse} from "../../../../../../../common/interfaces/warehouse.interface";
import {Api3Service} from "../../../../../../../common/services/api3.service";
import {Form} from "../../../../../../../common/interfaces/form.interface";
import {takeUntil} from "rxjs/operators";
import {Courier} from "../../../../../../../common/interfaces/courier.interface";
import * as moment from "moment";
import ISelectOption = Form.ISelectOption;

@Component({
    selector: "section-shippy-pickup-wizard-step3",
    templateUrl: "step3.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [
        "step3.component.scss"
    ],
    encapsulation: ViewEncapsulation.None
})
export class ShippyProPickupWizardStep3Component implements OnDestroy {

    private state: Base.IState;

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);


    public formGroup: FormGroup = new FormGroup({
        from_address: new FormGroup({
            name: new FormControl(null, [Validators.required]),
            company: new FormControl(null, [Validators.required]),
            street1: new FormControl(null, [Validators.required]),
            city: new FormControl(null, [Validators.required]),
            state: new FormControl(null),
            zip: new FormControl(null, [Validators.required]),
            country: new FormControl(null, [Validators.required]),
            phone: new FormControl(null, [Validators.required]),
            email: new FormControl(null, [Validators.required])
        }),
        to_address: new FormGroup({
            name: new FormControl(null, [Validators.required]),
            company: new FormControl(null, [Validators.required]),
            street1: new FormControl(null, [Validators.required]),
            city: new FormControl(null, [Validators.required]),
            state: new FormControl(null),
            zip: new FormControl(null, [Validators.required]),
            country: new FormControl(null, [Validators.required]),
            phone: new FormControl(null, [Validators.required]),
            email: new FormControl(null, [Validators.required])
        }),

        packages: new FormControl([], [Validators.required]),

        courier_transaction_id: new FormControl(null),
        pickup_date: new FormControl(null, [Validators.required]),
        pickup_morning_min_time: new FormControl("08:00", [Validators.required]),
        pickup_morning_max_time: new FormControl("12:00", [Validators.required]),
        pickup_afternoon_min_time: new FormControl("13:00", [Validators.required]),
        pickup_afternoon_max_time: new FormControl("17:00", [Validators.required]),
        notes: new FormControl(null),
    });

    public shipment: Warehouse.IShipment;

    public morning_time_slots: ISelectOption[] = [];

    public afternoon_time_slots: ISelectOption[] = [];

    public min_date: string = new Date().toDateString();

    public courier_transaction: Courier.ITransaction;

    @Output()
    public result: EventEmitter<any> = new EventEmitter();

    public constructor(
        private spinnerService: SpinnerService,
        private changeDetectorRef: ChangeDetectorRef,
        private api3Service: Api3Service
    ) {
    }


    private generateMorningTimeSlots(): void {
        for (let h = 8; h < 13; h++) {
            for (let m = 0; m < 60; m += 15) {
                const value: string = `${("" + h).padStart(2, "0")}:${("" + m).padStart(2, "0")}`;
                this.morning_time_slots.push({
                    value,
                    name: value
                });
            }
        }

        this.morning_time_slots.push({
            value: "13:00",
            name: "13:00"
        });
    }

    private generateAfternoonTimeSlots(): void {
        for (let h = 12; h < 20; h++) {
            for (let m = 0; m < 60; m += 15) {
                const value: string = `${("" + h).padStart(2, "0")}:${("" + m).padStart(2, "0")}`;
                this.afternoon_time_slots.push({
                    value,
                    name: value
                });
            }
        }
    }

    private async getTransaction(): Promise<any> {
        this.spinnerService.show();

        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `${this.state.section}/courier-transactions/${this.state.params.track}`, {});

        this.spinnerService.hide();

        if (data) {
            this.courier_transaction = data;
            this.changeDetectorRef.markForCheck();
        }
    }


    /**
     * Initialize step
     * @returns {Promise<any>}
     * @param state
     * @param data
     */
    public async init(state: Base.IState, data: {
        shipment_id: number,
        packages: number[],
        from_address: any,
        to_address: any
    }): Promise<any> {
        this.state = state;

        this.generateMorningTimeSlots();
        this.generateAfternoonTimeSlots();

        this.formGroup.patchValue(data);

        this.formGroup.get("courier_transaction_id").setValue(this.state.params.track);

        this.formGroup.get("pickup_date").setValue(moment().add("days", 1).set({"hour": 10, "minute": 0}));

        this.changeDetectorRef.markForCheck();

        this.getTransaction();

        this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any): void => {
            setTimeout(() => {
                if (this.formGroup.valid) {
                    this.result.next(this.formGroup.value);
                } else {
                    this.result.next(null);
                }
            }, 100);

        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }


}
