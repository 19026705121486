import {Injectable} from "@angular/core";
import {Api, ApiService} from "./api.service";
import {Order} from "../interfaces/order.interface";
import {Form} from "../interfaces/form.interface";
import ISelectOption = Form.ISelectOption;


@Injectable()
export class ServiceLevelService {

    public constructor(private apiService: ApiService) {
    }

    public getServiceLevels(query: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Get, ["service_level"], {}, query);
    }

    public mapServiceLevelsToSelect(service_levels: Order.IServiceLevel[]): ISelectOption[] {
        return service_levels.map((level: Order.IServiceLevel): any => {
            return {value: level.id, name: `${level.name} (${level.order_type.display_name})`};
        });
    }

}
