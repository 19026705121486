<common-confirm></common-confirm>
<div class="container-columns">
    <mat-card class="left">
        <h3>Order items</h3>
        <div class="pull-right not-shipped">
            <mat-checkbox #notShipped [checked]="true">Hide completed</mat-checkbox>
        </div>
        <mat-form-field class="search">
            <mat-label>Search</mat-label>
            <input matInput [formControl]="itemSearch" type="search">
        </mat-form-field>
        <ng-template [ngIf]="items && items.data && items.data.length > 0">
            <div class="items">
                <ng-template ngFor let-item [ngForOf]="items.data
                                 | filter: 'status.name' : '!==': 'Shipped': notShipped.checked
                                 | filter: 'status.name' : '!==': 'Inbounded': notShipped.checked">
                    <div class="item {{item.status.name.toLowerCase()}}">
                        <div class="pull-right">
                            <button mat-mini-fab color="success" (click)="addTransaction('add',item)">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>

                        <div class="title">
                            <span>Item: <b>{{item.item}}</b></span>
                            <span>Serial: <b>{{item.serial}}</b></span>
                            <span>Qty: <b>{{item.quantity}}</b></span>
                            <span>Configurations: <b *ngIf="item.configurations">
                                <ng-template ngFor let-conf let-i="index" [ngForOf]="item.configurations">
                                    {{conf.name}}
                                    <ng-template [ngIf]="i < item.configurations.length-1">, </ng-template>
                                </ng-template>
                            </b></span>
                            <span>Type: <b>{{item.type}}</b></span>
                            <span>Warehouse: <b>{{item.inventory_conversion.warehouse_slug}}</b></span>
                            <span>Sub-inventory: <b>{{item.inventory_conversion.sub_inventory}}</b></span>
                            <span>
                                GB:
                                <span class="chip {{item.inventory_conversion.gb}}">
                                    {{item.inventory_conversion.gb}}
                                </span>
                            </span>
                        </div>
                        <div>Status: <b>{{item.status.name}}</b></div>
                        <div>Description: <b>{{item.part_master.description}}</b></div>
                        <ng-template
                                [ngIf]="item.warehouse_transactions_paginated && item.warehouse_transactions_paginated.data">
                            <div class="drag-bag transactions id-{{item.id}}"

                                 [dragula]="dragulaBagName">
                                <div class="transaction id-{{transaction.id}}"
                                     *ngFor="let transaction of item.warehouse_transactions_paginated.data">
                                    <mat-icon (click)="addTransaction('edit',transaction)" class="pull-right">
                                        edit
                                    </mat-icon>
                                    Transaction:
                                    <div class="title">
                                        <span>Item: <b>{{transaction.item}}</b></span>
                                        <span>Serial: <b>{{transaction.serial}}</b></span>
                                        <span>Qty: <b>{{transaction.quantity}}</b></span>
                                        <span>Configurations: <b *ngIf="transaction.configurations">
                                            <ng-template ngFor let-conf let-i="index"
                                                         [ngForOf]="transaction.configurations">
                                                {{conf.name}}
                                                <ng-template
                                                        [ngIf]="i < transaction.configurations.length-1">, </ng-template>
                                            </ng-template>
                                        </b></span>
                                        <span>Type: <b>{{transaction.type}}</b></span>
                                        <span>Warehouse: <b *ngIf="transaction.inventory_conversion">
                                    {{transaction.inventory_conversion.warehouse_slug}} -
                                            {{transaction.inventory_conversion.sub_inventory}}</b>
                                </span>
                                    </div>
                                </div>
                            </div>
                            <common-pagination [data]="item.warehouse_transactions_paginated"
                                               pid="6F7uOgk4"
                                               (goToPage)="getItemTransactions(item,
                                               $event.page,
                                               $event.per_page)"></common-pagination>
                        </ng-template>
                    </div>
                </ng-template>
            </div>
            <common-pagination [data]="items"
                               pid="KDz20sg1"
                               (goToPage)="getParcelItems($event.page, itemSearch.value, $event.per_page)">
            </common-pagination>
        </ng-template>
    </mat-card>

    <mat-card class="right">

        <button mat-mini-fab color="success" class="pull-right" [matMenuTriggerFor]="menu">
            <mat-icon>add</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <a mat-menu-item (click)="addTransaction('add')">Add transaction</a>
            <a mat-menu-item (click)="addBySerial()">Add by serial</a>
        </mat-menu>

        <h3>Unlinked transactions</h3>
        <mat-form-field class="search">
            <mat-label>Search</mat-label>
            <input matInput [formControl]="transactionSearch" type="search">
        </mat-form-field>
        <ng-template [ngIf]="transactions">
            <div class="transactions" [dragula]="dragulaBagName">
                <div class="transaction id-{{transaction.id}}"
                     *ngFor="let transaction of transactions.data">
                    <mat-icon (click)="addTransaction('edit',transaction)" class="pull-right">edit</mat-icon>
                    Transaction:
                    <div class="title">
                        <span>Item: <b>{{transaction.item}}</b></span>
                        <span>Serial: <b>{{transaction.serial}}</b></span>
                        <span>Qty: <b>{{transaction.quantity}}</b></span>
                        <span>Configurations: <b *ngIf="transaction.configurations">
                            <ng-template ngFor let-conf let-i="index"
                                         [ngForOf]="transaction.configurations">
                                {{conf.name}}
                                <ng-template
                                        [ngIf]="i < transaction.configurations.length-1">, </ng-template>
                            </ng-template>
                        </b></span>
                        <span>Type: <b>{{transaction.type}}</b></span>
                        <span>Warehouse: <b *ngIf="transaction.inventory_conversion">
                    {{transaction.inventory_conversion.warehouse_slug}} -
                            {{transaction.inventory_conversion.sub_inventory}}</b></span>
                    </div>
                    <div *ngIf="transaction.part_master">
                        Description: <b>{{transaction.part_master.description}}</b>
                    </div>
                </div>
            </div>
            <common-pagination [data]="transactions"
                               pid="4bDI6d5f"
                               (goToPage)="getOrderTransactions($event.page,
                               transactionSearch.value,
                               $event.per_page)">
            </common-pagination>
        </ng-template>

    </mat-card>
</div>
