import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {Api, ApiService} from "../../../../../../common/services/api.service";

@Component({
    selector: "section-dashboard-count-widget",
    template: `
        <mat-card *ngIf="data || preloadData" [ngClass]="{'selected': selected}">
            <button mat-fab [color]="color">
                <mat-icon>{{icon}}</mat-icon>
            </button>
            <h4>{{title}}</h4>
            <div class="data">{{data || preloadData}}</div>
            <div class="sub">{{subTitle}}</div>
        </mat-card>

        <mat-card *ngIf="!data && !preloadData">
            <common-simple-spinner></common-simple-spinner>
        </mat-card>
    `,
    styleUrls: [
        "count-widget.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class DashboardCountWidgetComponent implements OnInit {

    @Input()
    public url: string[];

    @Input()
    public query: any = {};

    @Input()
    public icon: string = "";

    @Input()
    public color: string = "primary";

    @Input()
    public title: string = "";

    @Input()
    public subTitle: string = "In total";

    @Input()
    public preloadData: number;

    @Input()
    public selected = false;

    public data: number = 0;

    public constructor(
        private apiService: ApiService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    public async getData(): Promise<any> {
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, this.url, {}, this.query);

        if (data) {
            this.data = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    public ngOnInit(): void {
        if (!this.preloadData) {
            this.getData();
        }
    }
}
