import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import {Router} from "@angular/router";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {ModalService} from "../../../../../services/modal.service";
import {Warehouse} from "../../../../../../common/interfaces/warehouse.interface";
import {FormControl} from "@angular/forms";
import {debounceTime, distinctUntilChanged, takeUntil} from "rxjs/operators";
import {CommonFormComponent} from "../../../../../../common/components/form";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {Table2Component} from "../../../../../../common/components/table2";
import {User} from "../../../../../../common/interfaces/user.interface";
import {Form} from "../../../../../../common/interfaces/form.interface";
import ISelectOption = Form.ISelectOption;
import {UserService} from "../../../../../../common/services/user.service";


@Component({
    selector: "section-stock-summarized",
    templateUrl: "stock.component.html",
    styleUrls: ["stock.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StockSummarizedComponent implements Base.IComponent, OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    private inventoryColumns: Table.ICol[] = [];

    private sectionType: string;

    private sectionSlug: string;

    public stockType: string = "summarized";

    public state: Base.IState;

    public hubList: ISelectOption[];

    public subWarehouses: ISelectOption[];

    public partners: User.IPartner[] = [];

    public hubSelect: FormControl = new FormControl([]);

    public subWarehouseSelect: FormControl = new FormControl([]);

    public partnerSelect: FormControl = new FormControl([]);

    public availableOnly: FormControl = new FormControl(true);

    public countries: any[] = [];

    public countrySelect: FormControl = new FormControl(null);

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.ISettings}
     */
    public listTable: Table.ISettings;

    public canAddInventory: boolean = true;

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private modalService: ModalService,
        private changeDetectorRef: ChangeDetectorRef,
        private userService: UserService,
        private spinnerService: SpinnerService,
        private helpers: HelpersService
    ) {
    }

    private get url(): { url: string[], query?: { [key: string]: any } } {
        return {
            url: ["inventory"],
            query: {
                type: "summarized",
                hubs: this.hubSelect.value,
                warehouse_sub_inventories: this.subWarehouseSelect.value,
                partners: this.partnerSelect.value,
                country: this.countrySelect.value,
                use_transformer: true,
                available_only: this.availableOnly.value,
                relations: this.getTableRelations()
            }
        };
    }

    private async getColumns(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["inventory"], {}, {
            data_structure: "datatables_columns",
            type: this.stockType
        });
        if (data) {
            this.inventoryColumns = data;
        }
        this.prepareList();
        this.spinnerService.hide();
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {

        const columns: Table.ICol[] = [];

        if (this.inventoryColumns.length > 0) {
            this.inventoryColumns.forEach((col: Table.ICol): void => {
                columns.push(col);
            });
        } else {

            columns.push({
                data: "item",
                title: "Item"
            });
            columns.push({
                data: "rev",
                title: "Rev"
            });
            columns.push({
                data: "part_master.description",
                name: "PartMaster.description",
                title: "Description",
                sortable: false
            });
            columns.push({
                data: "configurations",
                title: "Configurations"
            });
            columns.push({
                data: "batch",
                title: "Batch"
            });
            columns.push({
                data: "lot",
                title: "Lot"
            });

            columns.push({
                data: "sum_quantity",
                title: "Sum quantity",
                searchable: false
            });
            columns.push({
                data: "warehouse_location.location",
                name: "WarehouseLocation.location",
                title: "Box Location",
                sortable: false
            });
            columns.push({
                data: "warehouse_location.warehouse_location_type.name",
                name: "WarehouseLocation.WarehouseLocationType.name",
                title: "Location Type",
                sortable: false
            });
            columns.push({
                data: "box.ref",
                name: "Box.ref",
                title: "Box ref",
                sortable: false
            });

            this.addColumnsBySectionSlug(this.sectionSlug, columns);

            if (this.sectionType === "threepl" || this.sectionType === "warehouse") {
                columns.push({
                    data: "partner.display_name",
                    name: "Partner.display_name",
                    title: "Partner",
                    sortable: false
                });
                columns.push({
                    data: "inventory_conversion.warehouse_slug",
                    name: "InventoryConversion.warehouse_slug",
                    title: "Warehouse",
                    sortable: false
                });
                columns.push({
                    data: "inventory_conversion.sub_inventory",
                    name: "InventoryConversion.sub_inventory",
                    title: "Sub-inventory",
                    sortable: false
                });
            }

            if (this.sectionType === "partner") {
                columns.push({
                    data: "inventory_conversion.customers_inventory_name",
                    name: "InventoryConversion.customers_inventory_name",
                    title: "Hub",
                    sortable: false
                });
                columns.push({
                    data: "inventory_conversion.customers_sub_inventory",
                    name: "InventoryConversion.customers_sub_inventory",
                    title: "Hub sub-inventory",
                    sortable: false
                });
            }

            columns.push({
                data: "inventory_conversion.gb",
                name: "InventoryConversion.gb",
                title: "GB",
                sortable: false
            });
        }

        this.listTable = {
            table_id: "3L23Jy9hTxs",
            api: this.url,
            columns,
            export: {
                file_name: "Stock summarized",
                columns,
            },
            search_default: ["item", "serial"]
        };
        this.changeDetectorRef.markForCheck();
    }

    private addColumnsBySectionSlug(slug: string, columns: Table.ICol[]): void {
        let ins: Table.ICol[] = [];
        let insertBeforeIndex = columns.length;

        switch (slug) {
            case "highcon": {
                ins = [
                    {
                        data: "part_master.family",
                        name: "PartMaster.family",
                        title: "Family",
                        sortable: false
                    },

                    {
                        data: "part_master.group",
                        name: "PartMaster.group",
                        title: "Group",
                        sortable: false
                    },
                ];

                insertBeforeIndex = columns.findIndex((col: Table.ICol): boolean => {
                    return col.data === "configurations";
                });
                break;
            }

            case "mavenir_il": {
                ins = [
                    {
                        data: "in_transit_to_wh",
                        render: (row) => {
                            let allocationsSum = row.global_inbound_inventory_allocations.reduce((accumulator, obj) => {
                                return accumulator + Number.parseInt(obj.quantity);
                            }, 0);
                            let awaitingSum = row.global_order_items_awaiting_inbound.reduce((accumulator, obj) => {
                                return accumulator + Number.parseInt(obj.quantity);
                            }, 0);
                            return allocationsSum + awaitingSum;
                        },
                        title: "IN TRANSIT TO WH",
                        sortable: false,
                        searchable: false
                    }
                ];
                break;
            }

            case "asl_taiwan":
            case "fpt_virtual":
            case "thailand": {
                ins = [
                    {
                        data: "inventory_conversion.customers_inventory_name",
                        name: "InventoryConversion.customers_inventory_name",
                        title: "Customers inventory name",
                        sortable: false
                    },

                    {
                        data: "inventory_conversion.customers_sub_inventory",
                        name: "InventoryConversion.customers_sub_inventory",
                        title: "Customers sub-inventory",
                        sortable: false
                    }
                ];
                break;
            }
        }

        if (ins.length > 0) {
            columns.splice(insertBeforeIndex, 0, ...ins);
        }
    }

    private getTableRelations(): string[] {
        const relations: string[] = [
            "InventoryConversion",
            "PartMaster",
        ];

        switch (this.sectionSlug) {
            case  "mavenir_il":
                relations.push("GlobalInboundInventoryAllocations");
                relations.push("GlobalOrderItemsAwaitingInbound");
                relations.push("Box");
                relations.push("WarehouseLocation.WarehouseLocationType");
                break;
            case "annapurna_labs":
                relations.push("GlobalInboundInventoryAllocations");
                relations.push("GlobalOrderItemsAwaitingInbound");
                relations.push("GlobalOrderItemsPacked");
                break;
            default:
                relations.push("WarehouseLocation.WarehouseLocationType");
                relations.push("Box");
                break;
        }

        if (["threepl", "warehouse"].includes(this.sectionType)) {
            relations.push("Partner");
        }

        return relations;
    }

    private async getHubList(country = null): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["hub"], {}, {country});
        if (data) {
            this.hubList = data.map((hub: Warehouse.IHub): any => {
                return {
                    name: `${hub.customers_inventory_name} - ${hub.customers_sub_inventory}`,
                    value: hub.id
                };
            });
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get related sub-warehouses
     * @param warehouse_slug
     */
    private async getSubWarehouses(warehouse_slug: string): Promise<any> {
        this.spinnerService.show();
        this.apiService.setHeaders({warehouse: warehouse_slug});
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["warehouse", "sub"]);
        if (data) {
            this.subWarehouses = data.map((sub: any): any => {
                return {
                    name: `${sub.warehouse.name} - ${sub.sub_inventory}`,
                    value: sub.sub_inventory
                };
            });
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get warehouse partners
     */
    private async getWarehousePartners(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["warehouse", "partners"]);
        if (data) {
            this.partners = data.map((partner: User.IPartner): any => {
                return {name: partner.display_name, value: partner.id};
            });
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private async prepareCountriesList(): Promise<any> {
        this.spinnerService.show();
        this.countries = await this.helpers.prepareCountriesList();
        this.spinnerService.hide();
        this.changeDetectorRef.markForCheck();
    }

    public async report(): Promise<any> {
        await this.modalService.open(CommonFormComponent, {
            configUrl: ["report", "inventories-list"],
            submitUrl: ["report", "inventories-list"],
            asyncKey: HelpersService.randomString()
        });
    }

    public updateTotals($event): void {
        if ($event.page > 1) {
            return;
        }
    }

    public ngOnInit(): void {
        [this.sectionType, this.sectionSlug] = this.state.section.split("/", 2);

        this.canAddInventory = !!this.userService.data.roles.find((r) => r.name === "superadmin");

        if (this.sectionType === "warehouse") {
            this.getSubWarehouses(this.sectionSlug);
            this.getWarehousePartners();
        } else {
            this.getHubList();
            this.prepareCountriesList();
        }

        this.getColumns();

        this.subWarehouseSelect.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
            .subscribe((value: any[]): void => {
                this.tableRef.reload(this.url);
            });

        this.partnerSelect.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
            .subscribe((value: any[]): void => {
                this.tableRef.reload(this.url);
            });

        this.availableOnly.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
            .subscribe((value: any[]): void => {
                this.tableRef.reload(this.url);
            });

        this.hubSelect.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
            .subscribe((value: any[]): void => {
                this.tableRef.reload(this.url);
            });

        this.countrySelect.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged(), debounceTime(500))
            .subscribe(async (value: any): Promise<void> => {
                if (this.countries.findIndex((country: any) => {
                    return country === value;
                })) {
                    await this.getHubList(value);
                    this.tableRef.reload(this.url);
                }
            });

    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "stock-summarized",
            actions: {
                "browse": ["browse_inventories"]
            }
        };
    }
}
