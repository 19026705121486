import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren
} from "@angular/core";
import {FormControl} from "@angular/forms";
import {CdkPortal, CdkPortalOutlet, PortalOutlet, TemplatePortal} from "@angular/cdk/portal";
import {Schema, Validator, ValidatorResult} from "jsonschema";

export namespace Field {

    export interface IMetadata {
        classes?: string;
        data?: any[];
        hidden?: boolean;
        label: string;
        name: string;
        required?: boolean;
        selected?: any;
        type: string;
    }

    export const FieldSchema: Schema = {
        properties: {
            classes: {type: "string"},
            data: {
                items: {
                    properties: {
                        "id": {type: ["string", "integer"]},
                        "name": {type: "string"},
                        "value": {type: ["string", "integer"]}
                    },
                    required: ["name"],
                    type: "object"
                },
                minItems: 0,
                type: ["array", "any"]
            },
            hidden: {type: "boolean"},
            label: {type: "string"},
            name: {type: "string"},
            required: {type: ["boolean"]},
            selected: {type: ["string", "integer"]},
            type: {type: "string"}
        },
        required: ["label", "name", "type"],
        type: "object"
    };

}

@Component({
    selector: "common-field",
    template: `
        <ng-template cdkPortal #inputPortal="cdkPortal">
            <mat-form-field [ngClass]="meta.classes" style="width: 100%">
                <mat-label>{{meta.label}}</mat-label>
                <input matInput [formControl]="control" [required]="meta.required">
            </mat-form-field>
        </ng-template>

        <ng-template cdkPortal #selectPortal="cdkPortal">
            <mat-form-field [ngClass]="meta.classes" style="width: 100%">
                <mat-label>{{meta.label}}</mat-label>
                <mat-select [formControl]="control" [required]="meta.required">
                    <mat-option *ngFor="let option of (meta.data || [])" [value]="option.value || option.id">
                        <ng-template [ngIf]="option">{{option.name}}</ng-template>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-template>

        <ng-template [ngIf]="meta && !meta.hidden" cdkPortalOutlet></ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldComponent implements OnInit, OnDestroy {

    @Input()
    public control: FormControl;

    @Input()
    public meta: Field.IMetadata;

    @ViewChild(CdkPortalOutlet, {static: false})
    public portalOutlet: PortalOutlet;

    @ViewChildren(CdkPortal)
    public portalTemplates: QueryList<TemplatePortal<any>>;

    public constructor(private changeDetectorRef: ChangeDetectorRef) {

    }

    /**
     * Get portal template (by name)
     * @param {string} type
     * @returns {TemplatePortal<any>}
     */
    private get(type: string): TemplatePortal<any> {
        const templates: Array<TemplatePortal<any>> = this.portalTemplates.toArray();
        if (templates.length) {
            switch (type) {
                case "input":
                    return templates[0];
                case "select":
                    return templates[1];
            }
        }
        return null;
    }

    /**
     * Attach portal to outlet
     * @returns {void}
     */
    private attach(): void {
        const {valid}: ValidatorResult = new Validator().validate(this.meta, Field.FieldSchema);
        if (valid) {
            const template: TemplatePortal<any> = this.get(this.meta.type);
            if (template) {
                if (this.portalOutlet.hasAttached()) {
                    this.portalOutlet.detach();
                }
                new TemplatePortal(template.templateRef, template.viewContainerRef).attach(this.portalOutlet);
            }
            this.changeDetectorRef.markForCheck();
        }
    }

    public ngOnInit(): void {
        if (this.control && this.meta) {
            Promise.resolve(null).then((): void => this.attach());
        }
    }

    public ngOnDestroy(): void {
        if (this.portalOutlet) {
            this.portalOutlet.detach();
            this.portalOutlet = null;
        }
    }

}
