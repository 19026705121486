import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Request} from "../../../../../../common/interfaces/request.interface";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {takeUntil} from "rxjs/operators";
import {DragulaService} from "ng2-dragula";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {User} from "../../../../../../common/interfaces/user.interface";
import * as moment from "moment-timezone";
import {Moment} from "moment-timezone";
import {ConfirmComponent} from "src/modules/common/components/confirm/confirm.component";
import {Form} from "src/modules/common/interfaces/form.interface";
import {ModalService} from "src/modules/section/services/modal.service";
import {CommonFormComponent} from "src/modules/common/components/form";
import ISelectOption = Form.ISelectOption;
import {TIME_ZONES} from "../../../../../../common/common.module";
import { RequestTaskEditFormComponent } from "../task-edit-form/task-edit-form.component";

@Component({
    selector: "section-request-tasks",
    templateUrl: "tasks.component.html",
    styleUrls: [
        "tasks.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class RequestTasksComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input()
    public editable: boolean = true;

    @Input()
    public request_id: number;

    @Input()
    public mentionUsers: User.IMentionUser[] = [];

    @Input()
    public timezone: string;

    @Output()
    public onChanges: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;


    public tasks: Request.ITask[] = [];

    public bagName: string = "";

    public now: string = new Date().toISOString();

    public taskDeadlineOptions = [
        -30, -15, 0, 15, 30, 45, 60, 75, 90
    ];

    public eventStatuses: Form.ISelectOption[] = [];

    public closeTaskEvents: Form.ISelectOption[] = [
        {value: "AWBAdded", name: "AWB Added"},
        {value: "AWBDelivered", name: "AWB Delivered"},
        {value: "OrderStatusChanged", name: "Order status changed"}
    ];

    public timeZones: ISelectOption[] = [];

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private spinnerService: SpinnerService,
        private dragulaService: DragulaService,
        private modalService: ModalService
    ) {

        this.bagName = "task-bag-" + HelpersService.randomString();

        if (!dragulaService.find(this.bagName)) {
            dragulaService.createGroup(this.bagName, {
                moves: (el, container, handle) => {
                    return handle.className == "drag-handle material-icons";
                  }
            });
        }
        this.dragulaService.dropModel(this.bagName).pipe(takeUntil(this.destroy$))
            .subscribe((value: any): void => {
                this.onDropModel(value);
            });
    }

    /**
     * Handle drop event
     * @returns {Promise<any>}
     */
    private async onDropModel(value: any): Promise<any> {

        if (!this.editable) {
            return;
        }

        const body: { id: number, order: number }[] = [];

        value.targetModel.forEach((task: Request.ITask, index: number): void => {
            body.push({
                id: task.id,
                order: index
            });
        });

        this.spinnerService.show();
        const {code}: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["request", "" + this.request_id, "task", "sort"], {tasks: body});

        this.spinnerService.hide();
        if (code === 200) {
            this.getTasks();
        }

        this.changeDetectorRef.markForCheck();
    }

    /**
     * Get task list for request
     */
    private async getTasks(): Promise<any> {
        this.spinnerService.show();

        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["request", "" + this.request_id, "task"]);

        if (data) {
            this.tasks = data;
            await this.getActivities();
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private async getStatuses(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["status"], {}, {
            data_structure: "select",
            type: "order"
        });
        if (data) {
            this.eventStatuses = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Change task state
     * @param event
     * @param task
     */
    public async checkTask(event: MatCheckboxChange, task: Request.ITask): Promise<void> {
        task.complete = event.checked;
        this.spinnerService.show();
        const { data, message }: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["request", "" + task.request_id, "task", "" + task.id], task);
        this.spinnerService.hide();
        if (data) {
            this.onChanges.emit(true);
        }

    }

    /**
     * Set task for edit
     * @param task
     */
    public async setTaskEdit(task: Request.ITask): Promise<void> {
        const result = await this.modalService.open(RequestTaskEditFormComponent, {
            task,
            timezone: this.timezone
        });
        if (result?.name == "update") {
            this.getTasks();
            this.onChanges.emit(true);
        }
    }

    /**
     * Set task for edit
     */
    public async setTaskNew(): Promise<void> {
        const result = await this.modalService.open(RequestTaskEditFormComponent, {
            request_id: this.request_id,
            timezone: this.timezone
        });
        if (result?.name == "create") {
            this.getTasks();
            this.onChanges.emit(true);
        }
    }

    /**
     * Delete task
     * @param task
     * @param convert_to_message
     */
    public async delete(task: Request.ITask, convert_to_message: boolean = false): Promise<any> {
        if (!await this.confirmRef.confirm("Are you sure want to delete this task?")) {
            return;
        }
        this.spinnerService.show();
        const {code}: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
            ["request", "" + this.request_id, "task", "" + task.id], {
                convert_to_message
            });
        this.spinnerService.hide();
        if (code === 200) {
            this.getTasks();
            this.onChanges.emit(true);
        }
    }

    public dueDateClass(taskDate: string): string {
        const to: Moment = moment.tz(taskDate, "YYYY-MM-DD HH-mm-ss", "Asia/Jerusalem");
        const now: Moment = moment.tz("Asia/Jerusalem");
        const left: number = to.diff(now, "minutes");

        if (left > 60) {
            return "green";
        } else if (left > 30) {
            return "yellow";
        }
        return "red";
    }

    public async getActivities(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["request", "" + this.request_id, "tasks-activities"], {}, {});

        if (data) {
            this.tasks.forEach(task => {
                task.activities = data.filter(a => task.id == a.task_id);
            });
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    public ngOnInit(): void {
        this.getStatuses();
        this.getTasks();

        if (!this.timezone) {
            this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        }

        this.timeZones = Object.keys(TIME_ZONES).map((item) => {
            return {value: item, name: item};
        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    protected readonly window = window;
}
