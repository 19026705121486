import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation
} from "@angular/core";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {Order} from "../../../../../../../common/interfaces/order.interface";
import {OrderService} from "../../../../../../../common/services/order.service";
import {MilestonesService} from "../../../../../../../common/services/milestones.service";
import {Warehouse} from "../../../../../../../common/interfaces/warehouse.interface";
import {Router} from "@angular/router";
import {Observable, Observer} from "rxjs";
import {Modal, ModalService} from "../../../../../../services/modal.service";
import {OrderSetOnHoldModalComponent} from "../hold-modal/hold-modal.component";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {UserService} from "../../../../../../../common/services/user.service";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {FormControl, Validators} from "@angular/forms";
import {ModalComponent} from "../../../../../../../common/components/modal/modal.component";
import {Contact} from "../../../../../../../common/interfaces/contact.interface";

@Component({
    selector: "section-order-warehouse-order",
    templateUrl: "warehouse-order.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class OrderWarehouseOrderComponent implements OnInit, OnDestroy {

    protected destroy$: EventEmitter<boolean> = new EventEmitter(false);

    @Input()
    public orderData: Order.IOrderData;

    @Input()
    public shipment: Warehouse.IShipment;

    public localTime: Observable<string>;

    @Output()
    public updateOrder: EventEmitter<any> = new EventEmitter<any>();

    public editInternalRef: boolean = false;

    public internalRefInput: FormControl = new FormControl(null, [Validators.required]);


    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private orderService: OrderService,
        private milestoneService: MilestonesService,
        private router: Router,
        private modalService: ModalService,
        private userService: UserService,
        private apiService: ApiService,
        private spinnerService: SpinnerService,
        private toastService: ToastService
    ) {
    }

    private makeLocalTime(): void {
        this.localTime = new Observable((observer: Observer<string>): void => {
            if (this.shipment.warehouse.timezone) {
                const interval: any = setInterval((): void => {
                    try {
                        let aestTime: any = new Date()
                            .toLocaleString("en-US", {timeZone: this.shipment.warehouse.timezone});
                        aestTime = new Date(aestTime);
                        observer.next(aestTime.toLocaleString());
                    } catch (e) {
                        observer.next(e.message);
                        observer.complete();
                        clearInterval(interval);
                    }
                }, 1000);
            } else {
                observer.next(null);
            }
        });
    }

    public async updateInternalRef(): Promise<any> {
        this.spinnerService.show();

        const {message, code, data}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["warehouse", "order", "" + this.shipment.warehouse_order.id], {
                internal_ref: this.internalRefInput.value
            });

        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.shipment.warehouse_order.internal_ref = data.internal_ref;
            this.editInternalRef = false;
            this.changeDetectorRef.markForCheck();
        }

    }

    public clearInternalRef(): void {
        this.editInternalRef = false;
        this.internalRefInput.setValue(this.shipment.warehouse_order.internal_ref);
    }

    public goToWarehouseScanWizard(procedure: string): void {
        this.router.navigate([
            "/warehouse",
            this.shipment.warehouse.slug,
            "procedures-" + procedure,
            "wizard",
            "order",
            this.shipment.warehouse_order.id,
            "order_ref",
            this.orderData.ref,
            "order_id",
            this.orderData.id,
            "back_to",
            btoa(this.router.url)
        ]);
    }

    public hasHold(): boolean {
        if (this.orderData && this.orderData.holds && this.orderData.holds.length) {
            for (const hold of this.orderData.holds) {
                if (Number(hold.warehouse_order_id) === Number(this.shipment.warehouse_order.id)
                    && Number(hold.user_id) === this.userService.data.id) {
                    return true;
                }
            }
        }
        return false;
    }

    public async setOnHold(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(OrderSetOnHoldModalComponent, {
            warehouse_order_id: this.shipment.warehouse_order.id,
            order_ref: this.orderData.ref
        });
        if (response) {
            this.updateOrder.emit();
        }
    }

    public async removeMyHold(): Promise<any> {
        this.spinnerService.show();
        const {message, code}: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
            ["order", this.orderData.ref, "hold"], {
                warehouse_order_id: this.shipment.warehouse_order.id
            });

        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.updateOrder.emit();
        }
    }

    public showContactInfo(): void {
        let template = ``;

        this.shipment.warehouse.contacts.forEach((contact: Contact.IContact): void => {
            template += `<div>${contact.first_name + contact.last_name}
                    ${this.shipment.warehouse.contact_on_shift?.id == contact.id ? "(On shift right now)" : ""}</div>`;

            if (contact.phone) {
                template += `<div><b>Tel.:</b> ${contact.phone || ""}</div>`;
            }

            if (contact.mobile_phone) {
                template += `<div><b>Mob.:</b> ${contact.mobile_phone || ""}</div>`;
            }

            if (contact.email) {
                template += `<div><b>Email:</b> ${contact.email}</div>`;
            }

            if (contact.pivot?.role) {
                template += `<div><b>Role:</b> ${contact.pivot.role}</div>`;
            }

            template = `<div class="margin-bottom-20">` + template + `</div>`;
        });


        this.modalService.open(ModalComponent, {
            title: "Contact Information",
            template
        });
    }

    public ngOnInit(): void {
        this.makeLocalTime();

        this.internalRefInput.setValue(this.shipment.warehouse_order.internal_ref);
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
