import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {FormControl, FormGroup} from "@angular/forms";
import {takeUntil} from "rxjs/operators";
import {StorageService} from "../../../../../../common/services/storage.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {KnowledgeCenterFormComponent} from "../index";
import {HttpClient} from "@angular/common/http";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Table2Component} from "src/modules/common/components/table2";
import {Table} from "src/modules/common/interfaces/table.interface";
import {PartnerService} from "src/modules/common/services/partner.service";
import {WarehouseService} from "src/modules/common/services/warehouse.service";
import {ThpeeplService} from "src/modules/common/services/thpeepl.service";
import {UserService} from "src/modules/common/services/user.service";
import ISelectOption = Form.ISelectOption;
import {Form} from "src/modules/common/interfaces/form.interface";
import {User} from "src/modules/common/interfaces/user.interface";
import {ModalComponent} from "src/modules/common/components/modal/modal.component";
import {AmplitudeService} from "../../../../../../common/services/amplitude.service";


@Component({
    selector: "section-knowledge-center-list",
    template: `
        <common-confirm></common-confirm>
        <common-table2 *ngIf="listTable" [settings]="listTable">
            <div row1 class="flex row" [formGroup]="filters">
                <ng-container *ngIf="state.section_type === 'admin' || state.section_type === 'warehouse'">
                    <common-form-select
                        class="form-select"
                        [options]="partners"
                        [multiple]="true"
                        [selectAll]="true"
                        [search]="true"
                        (onClose)="filters.get('partner_id').setValue($event)"
                        label="Partner">
                    </common-form-select>
                </ng-container>
                <ng-container *ngIf="state.section_type === 'admin' || state.section_type === 'partner'">
                    <common-form-select
                        class="form-select"
                        [options]="threepls"
                        [multiple]="true"
                        [selectAll]="true"
                        [search]="true"
                        (onClose)="filters.get('threepl_id').setValue($event)"
                        label="ThreePL">
                    </common-form-select>
                </ng-container>
            </div>
            <div row1>
                <button mat-raised-button type="button" color="accent" (click)="addNew()">
                    Add
                </button>
            </div>
        </common-table2>
    `,
    styleUrls: ["list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class KnowledgeCenterListComponent implements OnInit, OnDestroy {
    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    @Input()
    public state: Base.IState;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.ISettings;

    public sortBy: FormControl = new FormControl(
        this.storageService.get("requests_sortby") || "created_at");

    public partners: ISelectOption[] = [];

    public threepls: ISelectOption[] = [];

    public filters: FormGroup = new FormGroup({
        partner_id: new FormControl(null),
        threepl_id: new FormControl(null)
    });

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private storageService: StorageService,
        private modalService: ModalService,
        private http: HttpClient,
        private toastService: ToastService,
        private spinnerService: SpinnerService,
        private changeDetectorRef: ChangeDetectorRef,
        private warehouseService: WarehouseService,
        private userService: UserService,

    ) {
    }

    private prepareList(): void {
        const actions: Table.IAction[] = [
            {
                name: "view",
                title: "View",
                click: (row: any): void => {
                    this.router.navigate([
                        this.state.section,
                        "support-knowledge-center",
                        "view",
                        "id",
                        row.id
                    ]);
                    AmplitudeService.eventClick("Knowledge center view");
                }
            },
            {
                name: "edit",
                title: "Edit",
                click: (row: any): void => {
                    this.edit(row);
                    AmplitudeService.eventClick("Knowledge center edit");
                },
                disabledFn: (row: any) => {
                    return !this.canEdit(row);
                }
            },
            {
                name: "delete",
                title: "Delete",
                click: (row: any): void => {
                    this.delete(row);
                    AmplitudeService.eventClick("Knowledge center delete");
                },
                disabledFn: (row: any) => {
                    return !this.canDelete(row);
                }
            },
            {
                name: "get",
                title: "Get",
                click: (row: any): void => {
                    this.openLink(row);
                    AmplitudeService.eventClick("Knowledge center download");
                },
                iconFn: (row: any) => {
                    return row.type === "link" ? "launch" : "download";
                },
                disabledFn: (row: any) => {
                    switch (row.type) {
                        case "link":
                        case "document":
                            return false;
                        default:
                            return true;
                    }
                }
            }
        ];
        const columns: Table.ICol[] = [
            {
                data: "name",
                title: "Document Name"
            },
            {
                data: "",
                title: "Creator Side",
                searchable: false,
                render: (row: any): string => {
                    if (row.partner_id) {
                        return `Partner (${row.partner.full_name})`;
                    }
                    if (row.warehouse_id) {
                        return `Warehouse (${row.warehouse.name})`;
                    }
                    return "Logivice";
                }
            },
            {
                data: "type",
                title: "Type"
            },
            {
                data: "created_at",
                name: "created_at",
                title: "Creation date",
                render: (row: any): string => {
                    return row.created_at.split(" ")[0];
                }
            },
        ];

        let visibleWarehousesColumn: Table.ICol = {
            data: "is_visible_warehouses",
            title: "Warehouses can see",
            searchable: false,
            render: (row: any): string => {
                let visible_for_threepls = [];
                visible_for_threepls.push(row.visible_for_threepls.length > 0 ? "Yes" : "No");
                if (row.visible_for_threepls.length > 0) {
                    visible_for_threepls.push("<mat-icon class='mat-icon material-icons'>info</mat-icon>");
                }
                return visible_for_threepls.join(" ");
            },
            click: (row: any): void => {
                if (row.visible_for_threepls.length == 0) {
                    return;
                }
                this.showList("Threepls", row.visible_for_threepls.map(t => t.display_name));
            },
            cssClassFn: (row: any) => {
                if (row.visible_for_threepls.length == 0) {
                    return;
                }
                return "showListAction";
            }
        };

        let visiblePartnersColumn: Table.ICol = {
            data: "is_visible_partners",
            title: "Partners can see",
            searchable: false,
            render: (row: any): string => {
                let visible_for_partners = [];
                visible_for_partners.push(row.visible_for_partners.length > 0 ? "Yes" : "No");
                if (row.visible_for_partners.length > 0) {
                    visible_for_partners.push("<mat-icon class='mat-icon material-icons'>info</mat-icon>");
                }
                return visible_for_partners.join(" ");
            },
            click: (row: any): void => {
                if (row.visible_for_partners.length == 0) {
                    return;
                }
                this.showList("Partners", row.visible_for_partners.map(t => t.display_name));
            },
            cssClassFn: (row: any) => {
                if (row.visible_for_partners.length == 0) {
                    return;
                }
                return "showListAction";
            }
        };

        let creatorColumn = {

        };

        switch (this.state.section_type) {
            case "partner": {
                columns.splice(2, 0, visibleWarehousesColumn);
                break;
            }
            case "warehouse": {
                columns.splice(2, 0, visiblePartnersColumn);
                break;
            }
            default:
                columns.splice(2, 0,
                    ...[visibleWarehousesColumn, visiblePartnersColumn]
                );
        }

        this.listTable = {
            table_id: "knowledge_center_table",
            actions,
            api: {
                url: ["knowledge_center", "documents"],
                query: {
                    sort_by: this.sortBy.value,
                    ...this.filters.value
                }
            },
            columns,
        };
        this.changeDetectorRef.markForCheck();
    }

    private canEdit(row: any): boolean {
        return (this.userService.validatePermissions("edit_knowledge_center_documents") && this.isDocumentOwner(row))
            || this.state.section_type == "admin";
    }

    private canDelete(row: any): boolean {
        return (this.userService.validatePermissions("delete_knowledge_center_documents") && this.isDocumentOwner(row))
            || this.state.section_type == "admin";
    }

    private isDocumentOwner(row: any): boolean {
        switch (this.state.section_type) {
            case "partner":
                return PartnerService.partner && row.partner_id == PartnerService.partner.id;
            case "warehouse":
                return WarehouseService.warehouse && row.warehouse_id == WarehouseService.warehouse.id;
            case "threepl":
                return ThpeeplService.threepl && row.threepl_id == ThpeeplService.threepl.id;
            case "admin":
                return !row.partner_id && !row.warehouse_id && !row.threepl_id;
            default:
                false;
        }
    }

    private async getPartners(): Promise<any> {
        let data = [];
        if (this.state.section_type === "admin") {
            data = (await this.apiService.request(Api.EMethod.Get, "partner")).data;
        } else {
            data = this.userService.data.partners;
        }
        this.partners = data.map((partner: User.IPartner): any => {
            return {value: partner.id, name: partner.display_name};
        });
    }

    private async getThreepls(): Promise<any> {
        let data = [];
        if (this.state.section_type === "admin") {
            data = (await this.apiService.request(Api.EMethod.Get, "threepl")).data;
        } else {
            data = this.userService.data.threepls;
        }
        this.threepls = data.map((threepl: User.IThreepl): any => {
            return {value: threepl.id, name: threepl.display_name};
        });
    }

    public async addNew(): Promise<any> {
        const response: Modal.IResponse = await
            this.modalService.open(KnowledgeCenterFormComponent, {
                url: window.location.pathname,
                state: this.state
            });
        if (response && response.name && response.name === "submit") {
            this.tableRef.reload();
        }
        AmplitudeService.eventClick("Add knowledge center");
    }

    public async edit(document: any): Promise<any> {
        const response: Modal.IResponse = await
            this.modalService.open(KnowledgeCenterFormComponent, {
                url: window.location.pathname,
                state: this.state,
                id: document.id
            });
        if (response && response.name && response.name === "submit") {
            this.tableRef.reload();
        }
    }

    public async delete(document: any): Promise<any> {
        if (await this.confirmRef.confirm(`Do you want to delete "${document.name}?"`)) {
            this.spinnerService.show();
            const {data, message}: Api.IResponse =
                await this.apiService.request(Api.EMethod.Delete, ["knowledge_center", "documents", document.id]);
            this.spinnerService.hide();
            if (data) {
                this.toastService.show(message, "success");
                this.tableRef.reload();
            }
        }
    }

    public openLink(row: any): void {
        switch (row.type) {
            case "document":
                window.open(row.document_url, "_blank");
                break;
            case "link":
                window.open(row.link, "_blank");
                break;
        }
    }

    public async showList(title: string, items: any): Promise<any> {
        await this.modalService.open(ModalComponent, {
            title,
            template: items.join("<br>")
        });
    }

    public async ngOnInit(): Promise<any> {
        this.getPartners();
        this.getThreepls();
        if (this.state.section_type === "warehouse") {
            if (!WarehouseService.warehouse) {
                let response = await this.warehouseService.getWarehouseBySlug(this.state.section_slug);
                WarehouseService.warehouse = response.data;
            }
        }
        this.prepareList();

        this.filters.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any): void => {
            this.prepareList();
        });
        this.sortBy.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((): void => {
            this.storageService.set("requests_sortby", this.sortBy.value);
            this.prepareList();
        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
