import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {Router} from "@angular/router";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {AdminReportService} from "../../../services/report.service";
import {Api} from "../../../../../../common/services/api.service";
import {User} from "../../../../../../common/interfaces/user.interface";
import {Warehouse} from "../../../../../../common/interfaces/warehouse.interface";
import {AdminPartnerService} from "../../../services/partner.service";
import {AdminThreeplService} from "../../../services/thpeepl.service";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {SCHEDULER_DATES_OPTIONS} from "../../../../../../common/components/scheduler";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {CommonFormComponent} from "../../../../../../common/components/form";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Api3Service} from "../../../../../../common/services/api3.service";
import IThreepl = User.IThreepl;

enum ViewSection {
    Partners = 1,
    Warehouses = 2,
    Tasks = 3
}

@Component({
    selector: "section-admin-report-view",
    templateUrl: "view.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    styleUrls: [
        "view.component.scss"
    ]
})
export class AdminReportViewComponent implements Base.IComponent, OnInit, OnDestroy {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    private warehouses_count: number = 0;

    public readonly state: Base.IState;

    /**
     * Selector for user info section
     * @type {ViewSection}
     */
    public showSection: ViewSection = ViewSection.Partners;

    public report: any;

    public partners: User.IPartner[];

    public threepls: User.IThreepl[];

    public scheduledTasksTable: Table.ISettings;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private reportService: AdminReportService,
        private partnerService: AdminPartnerService,
        private threeplService: AdminThreeplService,
        private toastService: ToastService,
        private router: Router,
        private modalService: ModalService,
        private spinnerService: SpinnerService,
        private api3Service: Api3Service,
    ) {
    }

    private async getPartners(): Promise<any> {
        const {data}: Api.IResponse = await this.partnerService.getPartners();

        this.partners = data;
        this.changeDetectorRef.markForCheck();
    }

    private async getThreepls(): Promise<any> {
        const {data}: Api.IResponse = await this.threeplService.getThreepls({
            relations: [
                "Warehouses:id,3pl_id,name"
            ]
        });

        this.threepls = data;

        this.countWarehouses();

        this.changeDetectorRef.markForCheck();
    }

    private countWarehouses(): void {
        this.threepls.forEach((threepl: User.IThreepl): void => {
            this.warehouses_count += threepl.warehouses.length;
            threepl.properties.checked_count = 0;

            threepl.warehouses.forEach((wh: Warehouse.IWarehouse): void => {
                if (this.hasWarehouse(wh.id)) {
                    threepl.properties.checked_count++;
                }
            });
        });
    }

    /**
     * Get partner data
     * @returns {Promise<any>}
     */
    private async getData(): Promise<any> {
        const {data}: Api.IResponse = await this.reportService.getReport(this.state.params.id, {
            relations: [
                "Partners:id",
                "Warehouses:id"
            ]
        });

        this.report = data;
        this.showSection = this.report.warehouse_can_see
            ? ViewSection.Warehouses
            : this.report.partner_can_see
                ? ViewSection.Partners
                : ViewSection.Tasks;
        this.changeDetectorRef.markForCheck();

        this.prepareScheduledTasksTable();
    }

    private prepareScheduledTasksTable(): void {

        const date_options = SCHEDULER_DATES_OPTIONS;

        this.scheduledTasksTable = {
            table_id: "5tgcHjkf68g6sy6d",
            columns: [
                {
                    data: "partner.display_name",
                    name: "Partner.display_name",
                    title: "Partner",
                    render: (row: any): string => {
                        if (row.partner) {
                            return `<img src="${row.partner.icon_path}" alt="" class="logo">`;
                        } else {
                            return "";
                        }
                    },
                },
                {
                    data: "user.name",
                    name: "User.name",
                    title: "User"
                },
                {
                    data: "repeat",
                    title: "Repeat",
                    render: (row: any): string => {
                        switch (row.repeat) {
                            case "once":
                                return `once ${row.year}-${date_options.month[row.month - 1]}-${String(row.date)
                                    .padStart(2, "0")}
                at ${String(row.hour).padStart(2, "0")}:${String(row.min).padStart(2, "0")}`;
                            case "yearly":
                                return `every year ${date_options.month[row.month - 1]}-
                                ${String(row.date).padStart(2, "0")}
                at ${String(row.hour).padStart(2, "0")}:${String(row.min).padStart(2, "0")}`;
                            case "monthly":
                                return `every month ${row.date}
                at ${String(row.hour).padStart(2, "0")}:${String(row.min).padStart(2, "0")}`;
                            case "weekly":
                                return `every week on ${date_options.day[row.day]}
                at ${String(row.hour).padStart(2, "0")}:${String(row.min).padStart(2, "0")}`;
                            case "daily":
                                return `every day
                at ${String(row.hour).padStart(2, "0")}:${String(row.min).padStart(2, "0")}`;
                            case "hourly":
                                return `every hour at ${String(row.min).padStart(2, "0")} min`;
                            default:
                                return "";
                        }
                    },
                    searchable: false,
                    sortable: false
                },
                {
                    data: "created_at",
                    title: "Created at",
                    searchable: false,
                },
            ],
            api: {
                url: ["admin", "reports", "" + this.report.id, "schedule"],
                query: {
                    relations: [
                        "User:id,name",
                        "Partner:id,display_name,icon_path"
                    ]
                },
                version: 3
            }
        };
    }

    public cancel(): void {
        this.router.navigate([
            "admin",
            "reports"
        ]);
    }

    public hasAllPartners(): boolean {
        return this.report && this.partners && this.report.partners.length === this.partners.length;
    }

    public hasPartner(id: number): boolean {
        if (!id) {
            return false;
        }
        return this.report && this.report.partners.find((partner: { id: number }) => {
            return partner.id === id;
        });
    }

    public async selectPartners(event: MatSlideToggleChange, ids: number[]): Promise<any> {
        const attach = event.checked;
        const body: any = {
            relations: [
                "Partners:id",
                "Warehouses:id"
            ]
        };

        const {message, data}: Api.IResponse = attach
            ? await this.reportService.attachPartners(this.report.id, ids, body)
            : await this.reportService.detachPartners(this.report.id, ids, body);

        if (data) {
            this.report = data;
            this.toastService.show(message, "success");
            this.changeDetectorRef.markForCheck();
        }
    }

    public selectAllPartners(event: MatSlideToggleChange): void {
        const partners_ids = this.partners.map((p: User.IPartner) => {
            return p.id;
        });

        this.selectPartners(event, partners_ids);
    }

    public hasAllWarehouses(threepl_id: number = null): boolean {
        if (threepl_id) {
            const threepl = this.threepls.find((threepl: IThreepl) => {
                return threepl.id === threepl_id;
            });

            const whs: number[] = threepl.warehouses.map((w: Warehouse.IWarehouse) => {
                return w.id;
            });

            const filtered = whs.filter((id) => {
                return this.report.warehouses.map(w => w.id).includes(id);
            });

            return whs.length === filtered.length;
        }
        return this.report.warehouses.length > 0 && this.report.warehouses.length === this.warehouses_count;
    }

    public hasWarehouse(id: number): boolean {
        if (!id) {
            return false;
        }
        if (!this.report) {
            return false;
        }
        return this.report.warehouses.find((wh: { id: number }) => {
            return wh.id === id;
        });
    }

    public async selectWarehouses(event: MatSlideToggleChange, ids: number[]): Promise<any> {
        const attach = event.checked;
        const body: any = {
            relations: [
                "Partners:id",
                "Warehouses:id"
            ]
        };

        const {data, message}: Api.IResponse = attach
            ? await this.reportService.attachWarehouses(this.report.id, ids, body)
            : await this.reportService.detachWarehouses(this.report.id, ids, body);

        if (data) {
            this.report = data;
            this.toastService.show(message, "success");

            this.countWarehouses();
            this.changeDetectorRef.markForCheck();
        }
    }

    public selectAllWarehouses(event: MatSlideToggleChange, threepl_id: number = null): void {

        let warehouses_ids = [];
        if (threepl_id) {
            const threepl = this.threepls.find((threepl: IThreepl) => {
                return threepl.id === threepl_id;
            });
            warehouses_ids = threepl.warehouses.map((w: Warehouse.IWarehouse) => {
                return w.id;
            });
        } else {
            this.threepls.forEach((t: User.IThreepl) => {
                t.warehouses.forEach((w: Warehouse.IWarehouse) => {
                    warehouses_ids.push(w.id);
                });
            });
        }

        this.selectWarehouses(event, warehouses_ids);
    }

    public async export(): Promise<any> {

        this.report.fields = this.report.fields.filter((f) => {
            return f.admin_can_see === true;
        });

        const result: Modal.IResponse = await this.modalService.open(CommonFormComponent, {
            formConfig: this.report,
            reports: true,
            scheduler: true,
            scheduler_type: "App\\Report",
            scheduler_type_id: this.report.id
        });

        if (!result || !result.value) {
            return;
        }

        this.spinnerService.show();

        const {code, message}: Api.IResponse = await this.api3Service.request(Api.EMethod.Post,
            `/admin/reports/${this.report.id}`, result.value);

        this.spinnerService.hide();

        if (code === 200) {
            this.toastService.show(message, "success");
        }

    }

    public async ngOnInit(): Promise<any> {
        await this.getData();
        this.getPartners();
        this.getThreepls();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "reports",
            actions: {
                "view": ["browse_admin"]
            }
        };
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
