<mat-card>
    <div>
        <div class="margin-bottom-20">
            <common-table2 #resultsTable *ngIf="resultsTableSettings" [settings]="resultsTableSettings">
                <div row1>
                    <h2>
                        Inventory count scan errors
                    </h2>
                    <mat-checkbox [formControl]="onlyExceptions">Only exceptions</mat-checkbox>
                </div>
            </common-table2>
        </div>
        <div>
            <common-table2 #exceptionsTable *ngIf="exceptionsTableSettings" [settings]="exceptionsTableSettings">
                <div row1>
                    <h2>
                        Exceptions
                    </h2>
                </div>
            </common-table2>
        </div>
    </div>
</mat-card>