import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Field} from "../../../../../../../common/components/field/field.component";
import {ApiService} from "../../../../../../../common/services/api.service";
import {ModalService} from "../../../../../../services/modal.service";
import {UserService} from "../../../../../../../common/services/user.service";
import {Wizard} from "../../../../../../../common/interfaces/wizard.interface";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {OrderWizardDetailsComponent} from "./details.component";
import {AddressService} from "../../../../../../../common/services/address.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {Api3Service} from "../../../../../../../common/services/api3.service";

@Component({
    selector: "section-order-wizard-details",
    templateUrl: "details.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderWizardDetailsMavenirComponent extends OrderWizardDetailsComponent
    implements OnDestroy, OnInit, OnChanges {

    /**
     * Detail form group
     * @type {FormGroup}
     */
    public formGroup: FormGroup = new FormGroup({
        "ref": new FormControl(null,
            [Validators.required, this.refValidationPattern(), this.refExistsValidator()]),
        "ref2": new FormControl(null),
        "sr": new FormControl(null),
        "address_id": new FormControl(null, [Validators.required]),
        "address_billto_id": new FormControl(null),
        "contact_address_id": new FormControl(null, [Validators.required]),
        "contact_address_billto_id": new FormControl(null),
        "customer_id": new FormControl(null, [Validators.required]),
        "requestor_id": new FormControl(null),
        "requestor_contact_id": new FormControl(null),
        "out_incoterms": new FormControl(null),
        "in_incoterms": new FormControl(null),
    });

    /**
     * Custom / dynamic fields metadata
     * @type {Field.IMetadata[]}
     */
    public meta: Field.IMetadata[];


    public refPlaceholder: string = "";
    public srPlaceholder: string = "";

    public refObject: { country: string, customer: string } = {
        country: null,
        customer: null
    };

    public haveShipment: boolean = true;

    public canInbound: boolean = true;
    public canOutbound: boolean = true;

    public shipmentName: string = "Shipping";


    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        protected apiService: ApiService,
        protected api3Service: Api3Service,
        protected modalService: ModalService,
        protected userService: UserService,
        protected addressService: AddressService,
        protected toastService: ToastService,
        protected spinnerService: SpinnerService
    ) {
        super(changeDetectorRef, apiService, api3Service, modalService, userService,
            addressService, toastService, spinnerService);
    }


    protected setup(data: Wizard.IData): void {
        this.state = this.data.state;
        this.ready = true;
        this.result.next({
            action: "result",
            value: false
        });
        this.changeDetectorRef.markForCheck();
        this.getPartnerName();
        this.getCustomFields();
    }


    public ngOnInit(): void {
        super.ngOnInit();
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        // override changes from parent component
        return;
    }

}
