<common-confirm></common-confirm>

<h1 class="container-heading">Branches</h1>
<mat-card>
    <mat-card-content>

        <common-table2 *ngIf="listTable" [settings]="listTable">
            <div row1>
                <form [formGroup]="filterForm">
                    <button mat-raised-button color="primary" (click)="add_new()" type="button">New Branch</button>
                    <mat-form-field>
                        <mat-label>Term</mat-label>
                        <mat-select formControlName="term">
                            <mat-option [value]="null">All</mat-option>
                            <mat-option value="EOR">EOR</mat-option>
                            <mat-option value="IOR">IOR</mat-option>
                        </mat-select>
                    </mat-form-field>

                </form>
            </div>
        </common-table2>
    </mat-card-content>
</mat-card>
