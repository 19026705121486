<common-confirm></common-confirm>
<div class="pull-left  margin-bottom-20">
    <form [formGroup]="searchForm" (submit)="getFollowups()">
        <table>
            <tr>
                <td>
                    <mat-form-field class="half">
                        <mat-label>Search</mat-label>
                        <input matInput type="search" formControlName="search_by">
                    </mat-form-field>
                </td>
                <td>
                    &nbsp;
                    IN
                    &nbsp;
                </td>
                <td>
                    <common-form-select
                            [options]="searchInOptions"
                            [required]="true"
                            [multiple]="true"
                            [value]="searchForm.value.search_in"
                            (close)="searchForm.get('search_in').setValue($event)"
                    ></common-form-select>
                </td>
                <td>
                    <button
                            [disabled]="!searchForm.valid"
                            mat-raised-button type="submit" color="success">
                        Search
                    </button>
                </td>
            </tr>
        </table>
    </form>
</div>
<div *ngIf="followups; else noFollowups">
    <div class="tiles clear">
        <div class="third" *ngFor="let data of followups.data">
            <mat-card>
                <div class="favorite">
                    <button class="mat-mdc-mini-fab mdc-fab" type="button"
                            [class.mat-accent]="data.fav_user ? 'accent':''"
                            (click)="favoriteToggle(data)">
                        <mat-icon>star</mat-icon>
                    </button>
                </div>
                <div class="mark {{data.status | lowercase}}">
                    {{data.status}}
                </div>

                <div class="owner" *ngIf="data.creator">
                    <div class="avatar"
                         [ngStyle]="{'background-image': 'url(' + (data.creator.avatar || 'assets/images/default-avatar.png') + ')'}">
                    </div>
                    <div class="name">
                        {{data.creator.name}} <{{data.creator.email}}>
                    </div>
                </div>

                <h3 class="subject type_{{data.priority | lowercase}}">
                    <ng-container *ngIf="data.order">
                        <img class="flag" height="24" style="box-shadow:0 0 1px rgba(0,0,0,0.87);"
                             *ngIf="data.order.main_address && data.order.main_address.country_iso_2"
                             src="assets/images/flags/{{data.order.main_address.country_iso_2.toLowerCase().trim()}}.svg"/>
                        &nbsp;
                        <span style="font-weight: 400;">
                        Order {{data.order.ref}}
                    </span>
                        <br>
                        <br>
                    </ng-container>
                    {{data.title}}
                </h3>

                <div class="pull-left">
                    <small class="text-disabled">
                        <mat-icon class="mat-icon material-icons vertical-middle">
                            low_priority
                        </mat-icon>
                        <b [class.mat-warn]="['High', 'Critical'].indexOf(data.priority) > -1">
                            {{data.priority}}
                        </b>
                        &emsp;
                        <mat-icon class="mat-icon material-icons vertical-middle">
                            message
                        </mat-icon>
                        {{data.request_messages_count}}
                        &nbsp;
                        <mat-icon class="mat-icon material-icons vertical-middle">
                            priority_high
                        </mat-icon>
                        {{data.request_tasks_incomplete_count}}/{{data.request_tasks_count}}
                    </small>
                </div>

                <div class="date text-right pull-right">
                    <b>
                        {{data.created_at}}
                    </b>
                </div>

                <div class="clear text" [innerHTML]="data.description | linebreaks | url2link"></div>

                <div>
                    <br>
                    <b>Due date:</b>
                    <common-timeline [from]="data.timeline_from ?? data.created_at" [to]="data.timeline_to"
                                     (onChange)="setTimeline(data, $event)"></common-timeline>
                </div>

                <div class="tasks">
                    <b>Tasks</b>
                    <section-request-tasks [request_id]="data.id" (onChanges)="getFolowups()" [editable]="false">
                    </section-request-tasks>
                </div>

                <div class="actions">
                    <button mat-raised-button type="button" color="primary" (click)="viewFollowup(data)">
                        View
                    </button>

                    &nbsp;
                    &nbsp;

                    <button *ngIf="data.order"
                            mat-raised-button type="button" color="accent" (click)="goToOrder(data)"
                            [matTooltip]="'Order ' + data.order.ref">
                        Go to order
                    </button>

                    &nbsp;
                    &nbsp;

                    <button mat-raised-button type="button" color="success" (click)="solve(data)">
                        Solve
                    </button>
                </div>
            </mat-card>
        </div>
    </div>
    <common-pagination [data]="followups"
                       pid="xYu885y1GJ"
                       (goToPage)="getFollowups($event.page, $event.per_page)">
    </common-pagination>
</div>
<ng-template #noFollowups>
    <mat-card>
        <common-simple-spinner class="clear"></common-simple-spinner>
    </mat-card>
</ng-template>
