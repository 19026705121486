import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation
} from "@angular/core";
import {IActivity} from "src/modules/common/interfaces/activity.interface";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Router} from "@angular/router";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {UserService} from "../../../../../../common/services/user.service";


@Component({
    selector: "section-activity-view",
    templateUrl: "view.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    styles: [
        `
            section-activity-view .avatar {
                width: 24px;
                height: 24px;
                display: inline-block;
                vertical-align: middle;
                margin-right: 10px;
                background-position: 50% 50%;
                background-size: cover;
                background-repeat: no-repeat;
                border-radius: 50%;
            }

            section-activity-view .unread {
                border-left: 3px solid #80C883;
                padding-left: 5px;
            }
        `
    ]
})

export class ActivityViewComponent implements OnInit {

    @Input()
    public activity: IActivity;

    @Input()
    public showMarkRead: boolean = false;

    @Input()
    public state: Base.IState;

    @Output()
    public updates: EventEmitter<any> = new EventEmitter<any>();

    public showOrderLink: boolean = false;

    public is_unread: boolean = false;

    public constructor(
        private apiService: ApiService,
        private spinnerService: SpinnerService,
        private toastService: ToastService,
        private router: Router,
        private userService: UserService
    ) {

    }

    private async getOrder(id: number): Promise<any> {
        if (!id) {
            return;
        }
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["order", "" + id]);
        this.spinnerService.hide();
        if (data) {
            this.router.navigate([
                this.state.section,
                "orders",
                "view",
                "id",
                data.id
            ]);
        }
    }

    private async getRemark(id: number): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["remark", "" + id]);
        this.spinnerService.hide();
        if (data && data.order) {
            this.router.navigate([
                this.state.section,
                "orders",
                "view",
                "id",
                data.order.id
            ]);
        }
    }

    public async markRead(): Promise<any> {
        this.spinnerService.show();
        const {code}: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
            ["activities", "unread"], {
                activity_id: this.activity.activity_id,
                activity_model: this.activity.activity_model
            });

        this.spinnerService.hide();
        if (code === 200) {
            this.updates.emit(true);
        }
    }

    public async goToOrder(): Promise<any> {
        if (this.activity.related_model === "App\\Order") {
            this.getOrder(this.activity.related_model_id);
        } else if (this.activity.related_model === "App\\RemarkActivity") {
            this.getRemark(this.activity.related_model_id);
        }
    }

    public ngOnInit(): void {
        this.showOrderLink = ["App\\Order", "App\\OrderRemark"].includes(this.activity.related_model);

        this.is_unread = this.activity.unreads && this.activity.unreads.length > 0;
    }
}
