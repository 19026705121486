import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewEncapsulation} from "@angular/core";
import {FormControl} from "@angular/forms";
import * as _moment from "moment";
import {Moment} from "moment";
import {Api, ApiService} from "../../../../../../../../common/services/api.service";
import {MatDatepicker} from "@angular/material/datepicker";
import {Base} from "../../../../../../../../common/interfaces/base.interfaces";
import {Router} from "@angular/router";
import {AmplitudeService} from "../../../../../../../../common/services/amplitude.service";

const moment: any = _moment;

@Component({
    selector: "section-dashboard-template-default-personal-tab",
    template: `
        <div class="padding-top-20"
             style="flex-direction: row; flex-wrap: wrap; display: flex;justify-content: space-evenly;">
            <section-dashboard-count-widget
                    *ngIf="settings.showTransactionsWithoutItemsCount"
                    [url]='["transaction", "no-item"]'
                    [query]='{data_structure: "count"}'
                    icon="report_problem"
                    color="warn"
                    title="Transactions without items"
                    (click)="navigateTo([state.section,'transactions-without-items'])">
            </section-dashboard-count-widget>

            <section-dashboard-count-widget
                    *ngIf="settings.showTransactionsWithoutOrdersCount"
                    [url]='["transaction", "no-order"]'
                    [query]='{data_structure: "count"}'
                    icon="report_problem"
                    color="warn"
                    title="Transactions without orders"
                    (click)="navigateTo([state.section,'transactions-without-orders'])">
            </section-dashboard-count-widget>

            <section-dashboard-count-widget
                    *ngIf="settings.showInvalidTransactionsCount"
                    [url]='["transaction", "invalid"]'
                    [query]='{data_structure: "count"}'
                    icon="report_problem"
                    color="warn"
                    title="Invalid transactions"
                    (click)="navigateTo([state.section,'transactions-invalid'])">
            </section-dashboard-count-widget>

            <section-dashboard-count-widget
                    *ngIf="settings.showFailedWhSla"
                    [url]='["order", "sla"]'
                    [query]='{data_structure: "count", wh_failed: true}'
                    icon="report_problem"
                    color="warn"
                    title="Failed WH SLA">
            </section-dashboard-count-widget>

            <section-dashboard-count-widget
                    *ngIf="settings.showFailedDeliverySla"
                    [url]='["order", "sla"]'
                    [query]='{data_structure: "count", delivery_failed: true}'
                    icon="report_problem"
                    color="warn"
                    title="Failed delivery SLA">
            </section-dashboard-count-widget>
        </div>

        <mat-card [class.closed]="!showOrders">
            <div class="row flex space-between" *ngIf="!showOrders">
                <h2 class="border-bottom-none" (click)="loadOrders()">
                    Orders
                </h2>
                <div>
                    <button mat-fab color="success" (click)="loadOrders()">
                        <mat-icon>dvr</mat-icon>
                    </button>
                </div>
            </div>

            <div class="flex row space-between" *ngIf="showOrders">
                <h2 class="border-bottom-none">Orders</h2>
                <div>
                    <mat-icon (click)="showOrders = !showOrders">remove_circle_outline</mat-icon>
                </div>
            </div>


            <mat-card-content *ngIf="showOrders" style="flex-direction: column; align-items: center">

                <div class="flex-row padding-top-20">
                    <mat-form-field (click)="ptdf.open()">
                        <mat-label>Month from</mat-label>
                        <input matInput [ngxMatDatetimePicker]="ptdf" [formControl]="dateFrom" readonly>
                        <mat-icon matSuffix>today</mat-icon>

                        <ngx-mat-datetime-picker
                                #ptdf
                                [touchUi]="true"
                                [showSpinners]="true"
                                startView="year"
                                panelClass="month-picker"
                                (monthSelected)="chosenMonthHandler($event, ptdf, dateFrom, true)">
                            <ng-template>
                                <span>Apply</span>
                            </ng-template>
                        </ngx-mat-datetime-picker>

                    </mat-form-field>
                    &nbsp;
                    &nbsp;
                    <mat-form-field (click)="ptdt.open()">
                        <mat-label>Month to</mat-label>
                        <input matInput [ngxMatDatetimePicker]="ptdt" [formControl]="dateTo" readonly>
                        <mat-icon matSuffix>event</mat-icon>

                        <ngx-mat-datetime-picker
                                #ptdt
                                [touchUi]="true"
                                [showSpinners]="true"
                                startView="year"
                                panelClass="month-picker"
                                (monthSelected)="chosenMonthHandler($event, ptdt, dateTo, false)">
                            <ng-template>
                                <span>Apply</span>
                            </ng-template>
                        </ngx-mat-datetime-picker>

                    </mat-form-field>
                </div>

                <common-simple-spinner *ngIf="showOrderSpinner"></common-simple-spinner>

                <common-combo-chart *ngIf="ordersBySl" [class.hidden]="showOrderSpinner"
                                    [data]="ordersBySl" [options]="chartOptions"
                                    (ready)="showOrderSpinner = false"
                                    textColor="#000000"></common-combo-chart>

                <mat-divider [inset]="true"></mat-divider>

                <common-simple-spinner *ngIf="showTransferSpinner"></common-simple-spinner>

                <div class="flex-row" style="justify-content: space-evenly" *ngIf="!showTransferSpinner">
                    <mat-card class="shipment-by-status" style="min-width:300px; margin-top: 20px"
                              *ngIf="transferItemsCount">
                        <h3>Transfer orders items</h3>
                        <table class="top">
                            <tr *ngFor="let item of transferItemsCount; let i = index">
                                <td>
                                    <span style="cursor: pointer"
                                          (click)="navigateTo([state.section,'orders','view', 'ref', item.ref])">
                                        <b>{{item.ref}}</b>
                                    </span>
                                </td>
                                <td>{{item.outbound_order_items_count}}</td>
                            </tr>
                        </table>
                    </mat-card>
                </div>

            </mat-card-content>
        </mat-card>
    `,
    styleUrls: ["../default.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})

export class DashboardDefaultPersonalTabComponent {

    @Input()
    public settings: any;

    @Input()
    public state: Base.IState;

    public showOrders: boolean = false;

    public dateFrom: FormControl = new FormControl(moment().subtract(1, "months").startOf("month"));

    public dateTo: FormControl = new FormControl(moment());

    public transferItemsCount: any;

    public serviceLevels: any;

    public ordersBySl: any;

    public showOrderSpinner: boolean = false;

    public showTransferSpinner: boolean = false;

    public chartOptions: any = {
        width: 700,
        height: 700,
        chartArea: {width: "80%", height: "85%"},
        legend: "bottom",
        title: "Orders quantity by service levels",
        orientation: "vertical",
        bar: {
            groupWidth: "90%"
        }
    };


    public constructor(
        private apiService: ApiService,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router
    ) {
    }

    private async getTransferOrdersItems(): Promise<any> {
        this.showTransferSpinner = true;
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["order", "items"], {}, {
            date_from: this.dateFrom.value.format("DD/MM/YYYY"),
            date_to: this.dateTo.value.format("DD/MM/YYYY"),
            order_type: "transfer"
        });

        this.showTransferSpinner = false;
        if (response.data) {
            this.transferItemsCount = response.data;
        }
        this.changeDetectorRef.markForCheck();
    }

    private ordersGroupByWeeks(orders: any): any {
        return orders.reduce(function (val: any, obj: any): any {
            const comp: any = moment(obj["created_at"], "YYYY-MM-DD h:i:s").format("w/gggg");
            (val[comp] = val[comp] || []).push(obj);
            return val;
        }, {});
    }

    private ordersGroupBySl(orders: any): any {
        return orders.reduce((val: any, obj: any): any => {
            const comp: any = obj.service_level.name;
            if (!this.serviceLevels.includes(comp)) {
                this.serviceLevels.push(comp);
            }
            val[comp] = val[comp] + 1 || 1;
            return val;
        }, {});
    }

    public chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<any>,
                              formControl: FormControl, from: boolean
    ): any {
        const ctrlValue: any = formControl.value;

        ctrlValue.month(normalizedMonth.month());
        ctrlValue.year(normalizedMonth.year());

        if (from) {
            formControl.setValue(ctrlValue.startOf("month"));
        } else {
            formControl.setValue(ctrlValue.endOf("month"));
        }

        datepicker.close();

        this.getOrders();
        this.getTransferOrdersItems();
    }

    public async getOrders(): Promise<any> {
        this.showOrderSpinner = true;

        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["order", "sl"], {}, {
            date_from: this.dateFrom.value.format("DD/MM/YYYY"),
            date_to: this.dateTo.value.format("DD/MM/YYYY")
        });
        if (response.data) {
            const byWeeks: any = this.ordersGroupByWeeks(response.data);
            this.serviceLevels = [];

            for (const week in byWeeks) {
                if (byWeeks.hasOwnProperty(week)) {
                    byWeeks[week] = this.ordersGroupBySl(byWeeks[week]);
                }
            }

            this.serviceLevels.unshift("Week");
            this.ordersBySl = [this.serviceLevels];

            for (const week in byWeeks) {
                if (byWeeks.hasOwnProperty(week)) {
                    const weekArray: any = [week];
                    for (const sl of this.serviceLevels) {
                        if (sl !== "Week") {
                            weekArray.push(byWeeks[week].hasOwnProperty(sl) ? byWeeks[week][sl] : 0);
                        }
                    }
                    this.ordersBySl.push(weekArray);
                }
            }

            this.showOrderSpinner = false;
            this.changeDetectorRef.markForCheck();
        }
    }

    public navigateTo(url: string[]): void {
        const mes = url[1].replace(/-/g, " ");
        const message = mes[0].toUpperCase() + mes.slice(1).toLowerCase();
        AmplitudeService.eventClick(message);
        // console.log('message',message);
        this.router.navigate(url);
    }

    public loadOrders(): void {
        this.showOrders = true;
        this.changeDetectorRef.markForCheck();
        this.getOrders();
        this.getTransferOrdersItems();

    }
}
