<h1 class="container-heading">{{action | ucfirst}} client</h1>

<mat-card>
    <mat-card-content>

        <form *ngIf="formGroup" [formGroup]="formGroup"
              (submit)="submit()">

            <div class="group flex">

                <mat-form-field class="full">
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="name" required>
                </mat-form-field>

                <mat-form-field class="full">
                    <mat-label>Redirect url</mat-label>
                    <input matInput formControlName="redirect" required>
                </mat-form-field>

            </div>

            <div class="actions">
                <button mat-raised-button color="primary" type="submit" class="main" [disabled]="!formGroup.valid">
                    {{action === 'edit' ? 'Update' : 'Create'}}
                </button>
                <button mat-button type="button" (click)="close()">Cancel</button>
            </div>

        </form>

    </mat-card-content>
</mat-card>
