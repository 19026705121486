import {Injectable} from "@angular/core";
import {Api, ApiService} from "./api.service";


@Injectable()
export class PickupRequestService {

    public constructor(private apiService: ApiService) {
    }

    public create(body: any = {}, query: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Post, ["pickup_request"], body, query);
    }

    public update(id: number, body: any = {}, query: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Put, ["pickup_request", "" + id], body, query);
    }

    public async delete(id: number, body: any = {}, query: any = {}): Promise<any> {
        return this.apiService.request(Api.EMethod.Delete, ["pickup_request", "" + id], body, query);
    }

    public async getStatusList(query: any = {}): Promise<any> {
        return this.apiService.request(Api.EMethod.Get, ["pickup_request", "statuses"], {}, query);
    }

    public async getDistance(body: any = {}, query: any = {}): Promise<any> {
        return this.apiService.request(Api.EMethod.Post, ["pickup_request", "distance"], body, query);
    }
}
