import {ChangeDetectionStrategy, Component, ViewEncapsulation} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";


@Component({
    selector: "section-announcements-list-all",
    template: `
        <h1 class="container-heading">Announcements</h1>
        <mat-card>
            <mat-card-content>
                <section-announcements-list [state]="state"></section-announcements-list>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class AnnouncementsListAllComponent implements Base.IComponent {


    public readonly state: Base.IState;

    public constructor() {
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "support-announcements-all",
            actions: {
                "browse": ["browse_announcements"]
            }
        };
    }
}
