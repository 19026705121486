import {Injectable} from "@angular/core";

declare const google: any;

@Injectable()
export class GoogleChartsService {

    private scriptPromise: Promise<any>;

    public api: any;

    public constructor() {
        if (!this.scriptPromise) {
            this.scriptPromise = new Promise((resolve: any): void => {
                const body: HTMLElement = document.getElementsByTagName("body")[0] as HTMLElement;
                const script: any = document.createElement("script");
                script.type = "text/javascript";
                script.onload = (): void => {
                    this.api = google;
                    this.api.charts.load("current", {"packages": ["corechart"]});
                    this.api.charts.setOnLoadCallback((): void => {
                        resolve();
                    });
                };
                script.src = "https://www.gstatic.com/charts/loader.js";
                body.appendChild(script);
            });
        }
    }

    public load(callback: any, type?: string[] | string, params?: { [key: string]: any }): Promise<any> {
        return this.scriptPromise.then((): void => {
            if (type) {
                if (!Array.isArray(type)) {
                    type = [type];
                }
                this.api.charts.load("current", {"packages": type, ...params});
                this.api.charts.setOnLoadCallback((): void => {
                    callback();
                });
            } else {
                callback();
            }
        });
    }
}
