import {ChangeDetectionStrategy, Component, OnInit, ViewChild} from "@angular/core";
import {Base} from "../../../../../common/interfaces/base.interfaces";
import {PartnerFormStep3Component} from "../../../admin/components/partner/form/step3/step3.component";

@Component({
    selector: "section-partner-service-levels",
    template: `
        <h1 class="container-heading">Service levels</h1>
        <mat-card>
            <mat-card-content>
                <section-partner-form-step3></section-partner-form-step3>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PartnerServiceLevelsComponent implements Base.IComponent, OnInit {

    @ViewChild(PartnerFormStep3Component, {static: true})
    public serviceLevelsRef: PartnerFormStep3Component;

    public readonly state: Base.IState;

    public ngOnInit(): void {
        this.serviceLevelsRef.init(this.state.section);
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "service-level",
            actions: {
                "browse": ["browse_service_levels"]
            }
        };
    }
}
