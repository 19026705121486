<h1 class="container-heading">Reasons</h1>

<mat-card>
    <mat-card-content>
        <mat-accordion>
            <ng-template ngFor let-type let-i="index" [ngForOf]="types">
                <mat-expansion-panel *ngIf="type">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{ type.name }}
                        </mat-panel-title>
                        <mat-panel-description>
                            {{ getByType(type.id).length }}
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <mat-list>
                        <ng-template ngFor let-reason [ngForOf]="getByType(type.id)">
                            <mat-list-item role="listitem">{{ reason.name }}</mat-list-item>
                        </ng-template>
                    </mat-list>

                </mat-expansion-panel>
            </ng-template>

            <mat-expansion-panel *ngIf="formGroup">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <button type="button" mat-raised-button color="accent">
                            Add
                        </button>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <form *ngIf="formGroup" [formGroup]="formGroup" (submit)="handleFormSubmit()" autocomplete="off"
                      novalidate>

                    <div class="group flex">

                        <mat-form-field class="full">
                            <mat-label>Name</mat-label>
                            <input matInput formControlName="name" required>
                        </mat-form-field>

                        <mat-form-field class="full" *ngIf="types">
                            <mat-label>Milestone type</mat-label>
<mat-select formControlName="milestone_type_id"  required>
                                <mat-option *ngFor="let type of types" [value]="type.id">
                                    {{type.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="actions">
                        <button [disabled]="!formGroup.valid" mat-raised-button color="primary" type="submit"
                                class="main">
                            Submit
                        </button>
                        <button mat-raised-button type="button"
                                (click)="formGroup.reset()">
                            Reset
                        </button>
                    </div>

                </form>
            </mat-expansion-panel>
        </mat-accordion>

    </mat-card-content>
</mat-card>
