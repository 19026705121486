import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {UntypedFormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Modal, ModalService} from "../../../../../../services/modal.service";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {HelpersService} from "../../../../../../../common/services/helpers.service";
import {MatChipInputEvent} from "@angular/material/chips";
import {UserService} from "../../../../../../../common/services/user.service";
import {
    ItemConfigurationFormComponent,
    ItemConfigurationsListComponent
} from "../../../../../common/components/inventory/configurations";
import {debounceTime, distinctUntilChanged, takeUntil} from "rxjs/operators";
import {ReplaySubject} from "rxjs";
import {ConfirmComponent} from "../../../../../../../common/components/confirm/confirm.component";
import {Warehouse} from "../../../../../../../common/interfaces/warehouse.interface";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {OrderItemsInfoComponent} from "../../../../../partner/components/order";
import {WarehouseService} from "../../../../../../../common/services/warehouse.service";
import {Form} from "../../../../../../../common/interfaces/form.interface";
import {ModalComponent} from "../../../../../../../common/components/modal/modal.component";
import ISelectOption = Form.ISelectOption;
import {Api3Service} from "../../../../../../../common/services/api3.service";
import {OrderItemSelectModalComponent} from "../../../../../common/components/transactions";
import {TransactionService} from "../../../../../../../common/services/transaction.service";
import {Inventory} from "../../../../../../../common/interfaces/Inventory.interface";

@Component({
    selector: "section-warehouse-transaction-form",
    templateUrl: "transaction.component.html",
    styleUrls: [
        "transaction.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class WarehouseTransactionFormComponent implements OnInit, OnDestroy, AfterViewInit {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    private partMasters: any[];

    private batchesMaxQty: { [key: string]: number } = {};

    public submitInProgress: boolean = false;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild("serialsField")
    public serialsField: ElementRef;

    /**
     * Form group / group with controls
     * @type {FormGroup}
     */
    public formGroup: FormGroup = this.formBuilder.group({
        order_id: new FormControl(null),
        wh_ref: new FormControl(null),
        type: new FormControl(null, [Validators.required]),
        part_master_id: new FormControl(null, [Validators.required]),
        batch: new FormControl(null),
        serials: new FormControl([]),
        quantity: new FormControl(null, [Validators.required]),
        courier_transaction_id: new FormControl(null),
        order_item_id: new FormControl(null),
        shipment_id: new FormControl(null),
        warehouse_order_id: new FormControl(null),
        location_id: new FormControl(null),
        package_id: new FormControl(null),
        inventory_allocation_id: new FormControl(null),
        box_id: new FormControl(null),
        rev: new FormControl(null),
        lot: new FormControl(null),
        rebuild_inventory: new FormControl(true),
        inner_boxes: new FormControl(null)
    });

    public countries: any[];

    public modal: Modal.IModal;

    public action: string = "add";

    public serials: string[] = [];

    public subWh: any[] = [];

    public partMastersSearch: FormControl = new FormControl(null);

    public partMastersFiltered: ReplaySubject<any> = new ReplaySubject<any>(1);

    public configurations: string[] = [];

    public qrScan: FormControl = new FormControl(null);

    public rebuildInventory: FormControl = new FormControl(true);

    public hubs: Warehouse.IHub[] = [];

    public needQty: number;

    public maxQty: number;

    public requiredSerialInfo: string = null;

    public availableLocations: Warehouse.ILocation[];

    public availableLocationsOptions: ISelectOption[];

    public availableBatchOptions: ISelectOption[];

    public batchMaxQty: number = -1;

    public can_delete: boolean = false;

    public partMaster: Inventory.IPartMaster;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private formBuilder: UntypedFormBuilder,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private toastService: ToastService,
        public helpers: HelpersService,
        private userService: UserService,
        private modalService: ModalService,
        private warehouseService: WarehouseService,
        private spinnerService: SpinnerService,
        private renderer: Renderer2,
        private transactionService: TransactionService,
    ) {
    }

    /**
     * Prepare form group (create)
     * @returns {void}
     */
    private prepareForm(): void {

        this.formGroup.get("order_id").setValue(this.modal.params.order_id || null);
        this.formGroup.get("type").setValue(this.modal.params.type);

        if (this.modal.params.location_id) {
            this.formGroup.get("location_id").setValue(this.modal.params.location_id);
        }

        if (this.modal.params.package_id) {
            this.formGroup.get("package_id").setValue(this.modal.params.package_id);
        }

        if (this.modal.params.inventory_allocation_id) {
            this.formGroup.get("inventory_allocation_id").setValue(this.modal.params.inventory_allocation_id);
        }

        if (this.modal.params.data) {

            this.formGroup.patchValue(this.modal.params.data);

            if (this.modal.params.data.inventory_conversion_id && this.modal.params.warehouse_slug) {
                this.formGroup.get("inventory_conversion_id")
                    .setValue(Number(this.modal.params.data.inventory_conversion_id));
            }

            if (this.modal.params.data.warehouse_location_id) {
                this.formGroup.get("location_id")
                    .setValue(Number(this.modal.params.data.warehouse_location_id));
            }

            if (this.modal.params.action === "edit" && this.modal.params.data.id) {
                this.formGroup.addControl("id", new FormControl(this.modal.params.data.id));
            }

            if (this.modal.params.data.order_item_id) {
                this.formGroup.get("order_item_id").setValue(Number(this.modal.params.data.order_item_id));
            }

            if (this.modal.params.data.serial) {
                this.serials.push(this.modal.params.data.serial);

                this.formGroup.get("serials").setValue(this.serials);
                this.formGroup.get("serials").setValidators([Validators.required]);

                this.formGroup.get("quantity").setValidators([
                    Validators.required,
                    Validators.max(this.modal.params.total_item_quantity)
                ]);
                this.formGroup.get("quantity").setValue(this.serials.length);
                this.maxQty = this.modal.params.total_item_quantity;

            } else if (this.action === "add") {
                if (this.modal.params.data.quantity) {
                    this.getOrderItemTransactionsCount(this.modal.params.data);
                }
                this.maxQty = Number(this.modal.params.data.quantity);

                this.formGroup.get("quantity").setValidators([Validators.required, Validators.max(this.maxQty)]);
                this.formGroup.get("quantity").setValue(null);
            }
            this.formGroup.get("quantity").updateValueAndValidity();

            if (this.modal.params.data.related_configurations && this.modal.params.data.related_configurations.length) {
                this.configurations = this.modal.params.data.related_configurations.map((conf: any): string => {
                    return conf.name;
                });
            } else if (this.modal.params.data.configurations) {
                this.configurations = [this.modal.params.data.configurations];
            }


            if (this.modal.params.partner
                && "partner/" + this.modal.params.partner.slug === this.modal.params.state.section) {
                this.formGroup.get("inventory_conversion_id")
                    .setValue(this.modal.params.data.inventory_conversion?.id);
            } else if (this.modal.params.data.inventory_conversion
                && this.modal.params.data.inventory_conversion.sub_warehouse) {
                this.formGroup.get("sub_warehouse_id")
                    .setValue(this.modal.params.data.inventory_conversion.sub_warehouse.id);
            }

            this.formGroup.updateValueAndValidity();
        }
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Get sub-warehouses list
     * @returns {Promise<any>}
     */
    private async getSubWh(): Promise<any> {
        this.spinnerService.show();
        this.apiService.setHeaders({partner: this.modal.params.partner.slug});
        if (this.modal.params.warehouse_slug) {
            this.apiService.setHeaders({warehouse: this.modal.params.warehouse_slug});
        }
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["warehouse", "sub"]);
        this.spinnerService.hide();
        if (data) {
            this.subWh = data;
            if (this.subWh.length === 1) {
                this.formGroup.get("sub_warehouse_id").setValue(this.subWh[0].id);
            }
            this.changeDetectorRef.markForCheck();
        }
    }

    private async getHubs(): Promise<any> {
        this.spinnerService.show();
        this.apiService.setHeaders({partner: this.modal.params.partner.slug});
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["hub"]);
        this.spinnerService.hide();
        if (data) {
            this.hubs = data;
            if (this.hubs.length === 1) {
                this.formGroup.get("inventory_conversion_id").setValue(this.hubs[0].id);
            }
            this.changeDetectorRef.markForCheck();
        }
    }

    private quantitySubscribe(): void {
        this.formGroup.get("quantity").valueChanges.pipe(takeUntil(this.destroy$), debounceTime(100))
            .subscribe((value: string): void => {
                if (this.serials.length > 0 && value !== "" + this.serials.length) {
                    this.formGroup.get("quantity").setValue(this.serials.length);
                }
            });
    }

    private async getItems(): Promise<any> {
        this.spinnerService.show();
        this.apiService.setHeaders({partner: this.modal.params.partner.slug});
        if (this.modal.params.warehouse_slug) {
            this.apiService.setHeaders({
                warehouse: this.modal.params.warehouse_slug,
                partner: this.modal.params.partner.slug
            });
        }
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["partmaster"]);
        if (data) {
            this.partMasters = data;
            this.partMastersFiltered.next(this.partMasters);
            if (this.modal && this.modal.params.data && this.modal.params.data.part_master_id) {
                this.formGroup.get("part_master_id").setValue(Number(this.modal.params.data.part_master_id));
            }
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Parse scanned data to fulfill form
     * @param value
     */
    private async onBarcodeScanned(value: string): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["transaction", "parse-data"], {
                data: value.trim(),
                partner_id: this.modal.params.partner.id
            });
        if (data) {
            this.formGroup.patchValue(data);
        }
        this.spinnerService.hide();
    }

    private async getOrderItemTransactionsCount(item: any): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["order_item", "transaction", "" + item.order_item_id], {}, {
                data_structure: "sum",
                column: "quantity"
            });
        this.spinnerService.hide();
        if (data) {
            const total: number = !!this.modal.params.total_item_quantity
                ? Number(this.modal.params.total_item_quantity)
                : Number(item.quantity);
            this.needQty = total - Number(data);
            this.changeDetectorRef.markForCheck();
        }

    }

    private prepareListeners(): void {
        this.partMastersSearch.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value: string): void => {
                if (value) {
                    this.partMastersFiltered.next(
                        this.partMasters.filter((pm: { item: string }): boolean =>
                            pm.item.toLowerCase().indexOf(value) > -1)
                    );
                } else {
                    this.partMastersFiltered.next(this.partMasters);
                }
            });

        this.formGroup.get("part_master_id").valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((id: number): void => {

                this.partMasters.forEach((pm: any): void => {
                    if (pm.id === id) {
                        this.partMaster = pm;
                    }
                });

                if (this.partMaster) {
                    this.requiredSerialInfo = null;
                    if (this.formGroup.value.type === "inbound") {
                        if (this.partMaster.required_serial_wh_inbound) {
                            this.formGroup.get("serials").setValidators([Validators.required]);
                            this.requiredSerialInfo = "This item requires serial scanning on inbound";
                        } else {
                            if (!this.modal.params.data.serial) {
                                this.formGroup.get("serials").clearValidators();
                            }
                        }
                        if (this.partMaster.required_batch_inbound) {
                            this.formGroup.get("batch").setValidators([Validators.required]);
                        } else {
                            this.formGroup.get("batch").clearValidators();
                        }

                        if (this.partMaster.required_revision_inbound) {
                            this.formGroup.get("rev").setValidators([Validators.required]);
                        } else {
                            this.formGroup.get("rev").clearValidators();
                        }

                        if (this.partMaster.required_lot_inbound) {
                            this.formGroup.get("lot").setValidators([Validators.required]);
                        } else {
                            this.formGroup.get("lot").clearValidators();
                        }


                    } else if (this.formGroup.value.type === "outbound") {
                        if (this.partMaster.required_serial_wh_outbound) {
                            this.formGroup.get("serials").setValidators([Validators.required]);
                            this.requiredSerialInfo = "This item requires serial scanning on outbound";
                        } else {
                            if (!this.modal.params.data.serial) {
                                this.formGroup.get("serials").clearValidators();
                            }
                        }
                        if (this.partMaster.required_batch_outbound) {
                            this.formGroup.get("batch").setValidators([Validators.required]);
                        } else {
                            this.formGroup.get("batch").clearValidators();
                        }

                        if (this.partMaster.required_revision_outbound) {
                            this.formGroup.get("rev").setValidators([Validators.required]);
                        } else {
                            this.formGroup.get("rev").clearValidators();
                        }

                        if (this.partMaster.required_lot_outbound) {
                            this.formGroup.get("lot").setValidators([Validators.required]);
                        } else {
                            this.formGroup.get("lot").clearValidators();
                        }
                    }

                    this.formGroup.get("serials").updateValueAndValidity();
                    this.formGroup.get("batch").updateValueAndValidity();
                    this.formGroup.get("rev").updateValueAndValidity();
                    this.formGroup.get("lot").updateValueAndValidity();
                    this.changeDetectorRef.markForCheck();
                }
            });

        this.qrScan.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
            .subscribe((value: string): void => {
                if (value) {
                    this.onBarcodeScanned(value);
                }
            });

        this.rebuildInventory.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe(async (value: boolean): Promise<any> => {
                if (value && await this.confirmRef.confirm("Do you wish to rebuild inventory?")) {
                    this.formGroup.get("rebuild_inventory").setValue(true);
                } else {
                    this.formGroup.get("rebuild_inventory").setValue(false);
                    this.rebuildInventory.setValue(false, {emitEvent: false});
                    this.changeDetectorRef.markForCheck();
                }
            });

        const m_data: any = this.modal.params.data;
        if (
            this.modal.params.type === "outbound"
            && m_data && m_data.part_master && m_data.part_master.required_batch_outbound
        ) {
            this.formGroup.get("location_id").valueChanges.pipe(takeUntil(this.destroy$))
                .subscribe((location_id: number): void => {
                    if (location_id) {
                        this.getAvailableBatches(location_id);
                    }
                });

            this.formGroup.get("batch").valueChanges.pipe(takeUntil(this.destroy$))
                .subscribe((batch: string): void => {
                    this.batchMaxQty = this.batchesMaxQty[batch];
                    this.changeDetectorRef.markForCheck();

                    this.checkSelectedBatch(batch);
                });
        }
    }

    private async checkSelectedBatch(batch: string): Promise<any> {
        if (this.modal.params.data && this.modal.params.data.batch !== batch) {
            if (!await this.confirmRef.confirm(
                "You've selected batch [" + batch + "] instead of batch ["
                + this.modal.params.data.batch + "] in order " + this.modal.params.data.ref
            )) {
                this.formGroup.get("batch").setValue(this.modal.params.data.batch);
            }
        }
    }

    private getItemNameById(id: number): string {
        const partmaster: any = this.partMasters.find((pm: any): boolean => {
            return pm.id === id;
        });
        if (!partmaster) {
            return null;
        }
        return partmaster.item;
    }

    private getAvailableBatches(location_id: number): void {
        const selectedLocation: any =
            this.availableLocations.find((location: any): boolean => location.id === location_id);

        if (!selectedLocation) {
            return;
        }

        this.availableBatchOptions = [];

        for (const inventory of selectedLocation.inventories) {
            const b: string = inventory.batch ? inventory.batch : "-- no-batch --";
            this.availableBatchOptions.push({value: b, name: b});
            this.batchesMaxQty[b] = inventory.total_quantity;
        }

        this.changeDetectorRef.markForCheck();
    }

    private async getLocationsForOutbound(): Promise<any> {
        this.spinnerService.show();
        if (this.modal.params.data && this.modal.params.state.section.includes("partner")) {
            this.apiService.setHeaders({warehouse: this.modal.params.data.inventory_conversion?.warehouse_slug});
        }
        const {data}: Api.IResponse = await this.warehouseService
            .getLocationsForItem(this.formGroup.value.part_master_id, {
                group_batches: true
            });
        this.spinnerService.hide();
        if (data) {
            this.availableLocations = data;
            this.availableLocationsOptions = data.map((location: Warehouse.ILocation): ISelectOption => {
                return {value: location.id, name: location.location};
            });

            if ((!this.modal.params.data.warehouse_location || !this.modal.params.data.warehouse_location.id)
                && this.availableLocationsOptions.length === 1) {
                this.formGroup.get("location_id").setValue(this.availableLocationsOptions[0].value);
            }

            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Add serial chip
     * @param {MatChipInputEvent} event
     */
    public addSerial(event: MatChipInputEvent): void {
        const input: any = event.input;
        const values: string[] = event.value.split(/[\s,\t]+/);

        const item: string = this.getItemNameById(this.formGroup.value.part_master_id);

        for (let value of values) {
            value = (value || "").trim();

            if (value && this.serials.indexOf(value) === -1 && value !== item) {
                this.serials.push(value.trim());
                this.formGroup.get("quantity").setValue(this.serials.length);
                this.formGroup.get("serials").setValue(this.serials);
            }
        }
        if (input) {
            input.value = "";
        }
    }

    /**
     * Remove serial chip
     * @param {string} serial
     */
    public removeSerial(serial: string): void {
        const index: number = this.serials.indexOf(serial);

        if (index >= 0) {
            this.serials.splice(index, 1);
            this.formGroup.get("quantity").setValue(this.serials.length > 0 ? this.serials.length : 1);
            this.formGroup.get("serials").setValue(this.serials);
        }
    }

    /**
     * Add configuration
     * @param {string} action
     * @returns {Promise<any>}
     */
    public async addConfiguration(action?: string): Promise<any> {
        let response: Modal.IResponse;
        if (!action || action === "list") {
            response = await this.modalService.open(ItemConfigurationsListComponent, {
                item_id: this.formGroup.get("part_master_id").value
            });
        } else if (action === "form") {
            response = await this.modalService.open(ItemConfigurationFormComponent, {
                item_id: this.formGroup.get("part_master_id").value,
                modalWidth: 650
            });
        }

        if (response) {
            if (response.name === "form") {
                return this.addConfiguration("form");
            } else if (response.name === "list") {
                return this.addConfiguration("list");
            } else if (response.name === "value") {
                this.configurations.push(response.value);
                this.changeDetectorRef.markForCheck();
            }
        }
    }

    /**
     * Remove configuration chip
     * @param conf
     */
    public removeConfiguration(conf: string): void {
        const index: number = this.configurations.indexOf(conf);
        if (index >= 0) {
            this.configurations.splice(index, 1);
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Submit form
     * @returns {Promise<any>}
     */
    public async handleFormSubmit(): Promise<any> {
        if (this.submitInProgress) {
            return;
        }

        if (this.maxQty >= 0 && parseInt(this.formGroup.value.quantity) > this.maxQty) {
            this.toastService.show("Error! Quantity exceeds maximum quantity value", "error");
            return;
        }

        if (this.batchMaxQty >= 0 && parseInt(this.formGroup.value.quantity) > this.batchMaxQty) {
            this.toastService.show("Error! Quantity exceeds maximum batch quantity value", "error");
            return;
        }

        if (this.needQty
            && parseInt(this.formGroup.value.quantity) > this.needQty
            && !(await this.confirmRef.confirm("You're exiting required quantity. Proceed?"))) {
            return;
        }

        this.submitInProgress = true;
        this.spinnerService.show();
        this.changeDetectorRef.markForCheck();

        if (this.modal.params.warehouse_slug) {
            this.apiService.setHeaders({warehouse: this.modal.params.warehouse_slug});
        }
        const method: Api.EMethod = this.action === "edit" ? Api.EMethod.Put : Api.EMethod.Post;

        const url: string = `${this.modal.params.state.section}/warehouse-transactions/`
            + (this.action === "edit" ? this.formGroup.value.id : "");

        const response: Api.IResponse = await this.api3Service.request(method, url, {
            ...this.formGroup.value,
            configurations:
            this.configurations,
            batch: this.formGroup.value.batch === "-- no-batch --" ? null : this.formGroup.value.batch
        });
        if (response) {
            this.toastService.show(response.message, response.type as string);
            if (response.type as string === "success") {
                this.modal.response.next({
                    name: "value",
                    value: {}
                });
            }
        }
        this.submitInProgress = false;
        this.spinnerService.hide();
        this.changeDetectorRef.markForCheck();

    }

    /**
     * Remove transaction
     */
    public async delete(): Promise<any> {
        if (this.modal.params.warehouse_slug) {
            this.apiService.setHeaders({warehouse: this.modal.params.warehouse_slug});
        }
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
            ["transaction", this.formGroup.value.id]);

        if (response) {
            this.toastService.show(response.message, response.type as string);
            if (response.type as string === "success") {
                this.modal.response.next({
                    name: "value",
                    value: null
                });
            }
        }
    }

    /**
     * Unlink transaction from order
     */
    public async unlink(): Promise<any> {
        if (this.modal.params.warehouse_slug) {
            this.apiService.setHeaders({warehouse: this.modal.params.warehouse_slug});
        }
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["transaction", "link_order"], {
                id: this.formGroup.value.id,
                order_ref: null
            });

        if (response) {
            this.toastService.show(response.message, response.type as string);
            if (response.type as string === "success") {
                this.modal.response.next({
                    name: "value",
                    value: null
                });
            }
        }
    }

    public async markInvalid(): Promise<any> {

        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["transaction", "mark_invalid"], {
                id: this.formGroup.value.id,
            });
        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.modal.response.next({
                name: "value",
                value: null
            });
        }
    }

    public async release(): Promise<any> {
        if (!await this.confirmRef.confirm("Are you sure want to update inventory despite errors?")) {
            return;
        }
        const response: Modal.IResponse = await this.modalService.open(OrderItemSelectModalComponent, {
            transaction: this.modal.params.data
        });

        if (response && response.value) {
            this.spinnerService.show();

            const {code, message}: Api.IResponse = await this.transactionService
                .releaseInvalidTransactions([this.formGroup.value.id]);

            this.spinnerService.hide();
            if (code === 200) {
                this.toastService.show(message, "success");

            }
        }
    }


    /**
     * Get property from user
     * @param {string} name
     * @param defaultValue
     * @returns {any}
     */
    public getProperty(name: string, defaultValue: any): any {
        return this.userService.getProperty(name, this.modal.params.state, defaultValue);
    }


    public async showList(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(OrderItemsInfoComponent, {
            data: this.modal.params.data,
            url: {
                url: ["order_item", "transaction", "" + this.modal.params.data.order_item_id]
            },
            unlink_transactions: true
        });

        if (response && response.name === "update") {
            this.getOrderItemTransactionsCount(this.modal.params.data);
        }
    }

    public showErrors(): void {
        this.modalService.open(ModalComponent, {
            title: "Errors",
            template: this.modal.params.data.not_skipped_validation_errors
                .map((e: { validation_errors: string }): string => e.validation_errors).join("<br>")
        });
    }


    public ngOnInit(): void {

        this.action = this.modal && this.modal.params.action;

        if (!this.modal.params.warehouse_slug && this.modal.params.state.section_type !== "partner") {
            this.getSubWh();
            this.formGroup.addControl("sub_warehouse_id",
                new FormControl(null, [Validators.required]));
        } else {
            this.getHubs();
            this.formGroup.addControl("inventory_conversion_id",
                new FormControl(null, [Validators.required]));
        }

        this.formGroup.updateValueAndValidity();

        this.getItems();
        this.prepareForm();
        this.quantitySubscribe();
        this.prepareListeners();
        if (this.modal.params.type === "outbound") {
            this.getLocationsForOutbound();
        }


        this.can_delete = this.modal.params.action === "edit" && !this.modal.params.data.inventory_id;
    }

    public ngAfterViewInit(): void {
        setTimeout((): void => {
            this.renderer.selectRootElement(this.serialsField.nativeElement).focus();
        }, 500);
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
