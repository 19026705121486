import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {Wizard} from "../../../../../../../common/interfaces/wizard.interface";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {
    AbstractSinglePageWizardStepComponent
} from "../../../../../../../common/interfaces/wizard-single-page.interface";
import {ContractService} from "../../../../../../../common/services/contract.service";
import {Form} from "../../../../../../../common/interfaces/form.interface";
import {FormControl, Validators} from "@angular/forms";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: "section-order-wizard-contract",
    templateUrl: "contract.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderWizardContractComponent extends AbstractSinglePageWizardStepComponent implements OnInit, OnDestroy {

    public contracts: Form.ISelectOption[];

    public selectedContract: FormControl = new FormControl(null, [Validators.required]);

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private contractService: ContractService,
        private spinnerService: SpinnerService
    ) {
        super(changeDetectorRef);
    }

    /**
     * Prepare contract step (wizard)
     * @return {Promise<any>}
     */
    private async prepareContractStep(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.contractService.getContracts({
            data_structure: "select"
        });
        this.spinnerService.hide();
        if (data) {
            if (data && data.length) {
                this.contracts = data;
                this.changeDetectorRef.markForCheck();
            } else {
                this.result.next({action: "destroy"});
            }
        }
    }

    protected setup(data: Wizard.IData): void {
    }

    public ngOnInit(): void {
        this.prepareContractStep();
        this.selectedContract.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: number): void => {
            if (value) {
                this.result.next({
                    action: "data",
                    value: {contract_id: value}
                });
                this.result.next({
                    action: "result",
                    value: true
                });
            } else {
                this.result.next({
                    action: "result",
                    value: null
                });
            }
        });
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}
