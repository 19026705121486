import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Api} from "../../../../../../common/services/api.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {OrderService} from "../../../../../../common/services/order.service";

@Component({
    selector: "section-custom-fields-edit-modal",
    template: `
        <h1 class="container-heading">{{ modal.params.window_title }}</h1>
        <mat-card>
            <mat-card-content>
                <form [formGroup]="formGroup">
                    <div class="group flex">
                        <mat-form-field *ngFor="let field of (custom_fields || [])" class="full">
                            <mat-label>{{ field.key }}</mat-label>
                            <input matInput type="text" [formControlName]="field.key">
                        </mat-form-field>

                        <div class="full color-info">
                            Leave field blank to remove it
                        </div>
                    </div>
                </form>

                <form [formGroup]="newField" (submit)="addNewField()">
                    <div class="group flex">
                        <mat-form-field class="max margin-right-10">
                            <mat-label>Field name</mat-label>
                            <input matInput type="text" formControlName="key">
                        </mat-form-field>
                        <div>
                            <button mat-raised-button type="submit" [disabled]="!newField.valid" color="accent">
                                Add field
                            </button>
                        </div>
                    </div>
                </form>

                <div>
                    <button mat-raised-button type="button" color="primary" class="main" (click)="submit()">
                        Save
                    </button>
                </div>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomFieldsEditModalComponent implements OnInit {

    public modal: Modal.IModal;

    public custom_fields: { key: string, value: string }[];

    public formGroup: FormGroup = new FormGroup({});

    public newField: FormGroup = new FormGroup({
        key: new FormControl(null, [Validators.required]),
    });

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
    ) {
    }

    private prepareForm(): void {

        this.custom_fields = this.modal.params.custom_fields;

        for (const field of this.custom_fields) {
            if (typeof field.value === "boolean") {
                this.formGroup.addControl(field.key, new FormControl(field.value ? "true" : "false"));
            } else if (!field.value) {
                this.formGroup.addControl(field.key, new FormControl(null));
            } else if (typeof field.value === "string") {
                this.formGroup.addControl(field.key, new FormControl(field.value));
            } else {
                this.formGroup.addControl(field.key, new FormControl(JSON.stringify(field.value)));
            }
        }

        this.changeDetectorRef.markForCheck();
    }

    public addNewField(): void {
        this.formGroup.addControl(this.newField.value.key, new FormControl(null));
        this.custom_fields.push({key: this.newField.value.key, value: null});
        this.newField.reset();
        this.changeDetectorRef.markForCheck();
    }

    public async submit(): Promise<any> {
        const body: any = {};

        for (const field in this.formGroup.value) {
            if (this.formGroup.value[field]) {
                if (this.formGroup.value[field] == "true") {
                    body[field] = true;
                    continue;
                } else if (this.formGroup.value[field] == "false") {
                    body[field] = false;
                    continue;
                } else if (this.formGroup.value[field].startsWith("{") && this.formGroup.value[field].endsWith("}")) {
                    try {
                        this.formGroup.value[field] = JSON.parse(this.formGroup.value[field]);
                    } catch ($e) {
                    }
                }

                body[field] = this.formGroup.value[field];
            }
        }


        this.modal.response.emit({
            name: "custom_fields",
            value: body
        });
    }

    public ngOnInit(): void {
        this.prepareForm();
    }

}
