import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {Modal} from "../../../../../../services/modal.service";
import {GMap} from "../../../../../../../common/components/gmap/gmap.component";
import {Courier} from "../../../../../../../common/interfaces/courier.interface";
import {Contact} from "../../../../../../../common/interfaces/contact.interface";

@Component({
    selector: "section-order-address-map-modal",
    template: `

        <div class="map">
            <common-static-map *ngIf="mapMarkers && mapMarkers.length === 1"
                               [center]="mapMarkers[0]"
                               [markers]="mapMarkers"
                               [zoom]="11"
                               width="500"
                               height="400"
            ></common-static-map>

            <common-gmap *ngIf="mapMarkers && mapMarkers.length > 1" [maxZoom]="12"
                         [center]="{lat: mapMarkers[0].lat, lng: mapMarkers[0].lng}"
                         [markers]="mapMarkers"
                         [scrollWheel]="true"
            ></common-gmap>
        </div>


    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class OrderMapModalComponent implements OnInit {

    public modal: Modal.IModal;
    public main_address: Contact.IAddress;
    public mapMarkers: GMap.IMarker[];

    private orderTracks: Courier.ITransaction[] = [];

    constructor(private changeDetectorRef: ChangeDetectorRef) {
    }

    public setupMapMarkers(): void {
        if (!this.main_address) {
            return;
        }
        this.mapMarkers = [];
        if (this.main_address.lat && this.main_address.lng) {
            this.mapMarkers.push({
                lat: Number(this.main_address.lat),
                lng: Number(this.main_address.lng),
            });
        }
        for (const track of this.orderTracks) {
            this.mapMarkers.push({
                lat: Number(track.lat),
                lng: Number(track.lng),
                iconUrl: track.status === "delivered"
                    ? "/assets/images/icons/check-marker-green.png"
                    : "/assets/images/icons/plain-marker-green.png",
            });
        }

        this.changeDetectorRef.markForCheck();
    }

    public ngOnInit(): void {
        this.main_address = this.modal.params.mainAddress;
        this.orderTracks = this.modal.params.orderTracks;

        this.setupMapMarkers();
    }


}
