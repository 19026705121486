import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {UntypedFormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {ReplaySubject} from "rxjs";
import {debounceTime, takeUntil} from "rxjs/operators";
import {UserService} from "../../../../../../common/services/user.service";
import {
    ItemConfigurationFormComponent,
    ItemConfigurationsListComponent
} from "../../inventory/configurations";
import {MatChipInputEvent} from "@angular/material/chips";
import {SpinnerService} from "../../../../../../common/services/spinner.service";

export namespace PartMaster {
    export interface IItem {
        id: number;
        item: string;
        description: string;
        image_path: string;
        config: string;
        customs_value: number;
        customs_values_currency: string;
        eccn: string;
        hsc: string;
        width: number;
        height: number;
        length: number;
        weight: number;
        mass_unit: null;
        serial: boolean;
        batch: boolean;
        country: string;
    }
}


@Component({
    selector: "section-stock-form",
    templateUrl: "form.component.html",
    styleUrls: [
        "form.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class StockFormComponent implements OnInit {

    private items: any[];
    private inventoryConversion: any[];
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    public modal: Modal.IModal;
    public item: PartMaster.IItem;
    public formGroup: FormGroup;
    public edit: boolean = false;
    public countries: any[];
    public action: string = "add";
    public serials: string[] = [];
    public configurations: string[] = [];
    public serial: any;
    public itemSearch: FormControl = new FormControl(null);
    public itemsFiltered: ReplaySubject<any> = new ReplaySubject<any>(1);
    public inventoryConversionSearch: FormControl = new FormControl(null);
    public inventoryConversionFiltered: ReplaySubject<any> = new ReplaySubject<any>(1);

    public inventoryConversionWh: any = null;
    public whLocationExists: boolean = false;
    public locationTypes: any = [];

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private formBuilder: UntypedFormBuilder,
        private helpers: HelpersService,
        private toastService: ToastService,
        private userService: UserService,
        private modalService: ModalService,
        private spinnerService: SpinnerService
    ) {
    }

    /**
     * Prepare form group (create)
     * @returns {void}
     */
    private prepareForm(): void {
        this.formGroup = this.formBuilder.group({
            part_master_id: new FormControl(null, [Validators.required]),
            inventory_conversion_id: new FormControl(null, [Validators.required]),
            batch: new FormControl(null),
            quantity: new FormControl(null, [Validators.required]),
            location_id: new FormControl(null, [Validators.required]),
            location_type: new FormControl(null),
            box_ref: new FormControl(null),
            rev: new FormControl(null),
            bin: new FormControl(null),
            lot: new FormControl(null)
        });
        if (this.modal.params.data) {
            for (const name of Object.keys(this.modal.params.data)) {
                if (this.formGroup.value.hasOwnProperty(name)) {
                    this.formGroup.get(name).setValue(this.modal.params.data[name]);
                }
            }
            if (this.modal.params.action === "edit" && this.modal.params.data.id) {
                this.formGroup.addControl("id", new FormControl(this.modal.params.data.id));
                this.formGroup.addControl("serial", new FormControl(this.modal.params.data.serial));
            }

            if (this.modal.params.data.serial && this.modal.params.data.serial !== null) {
                this.serials.push(this.modal.params.data.serial);
            }
            this.getLocationData();
        }

        this.changeDetectorRef.markForCheck();
    }

    private async getLocationData(): Promise<any> {
        await this.getConversion(this.formGroup.get("inventory_conversion_id").value);
        if (this.inventoryConversionWh) {
            this.getLocation(this.formGroup.get("location_id").value);
        }
    }

    private async getLocationTypes(): Promise<any> {
        this.apiService.setHeaders({partner: this.modal.params.partner.slug});
        const {data}: Api.IResponse = await
            this.apiService.request(Api.EMethod.Get, ["warehouse_location", "type"], {}, {data_structure: "select"});
        if (data) {
            this.locationTypes = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    private async getInventoryConversion(): Promise<any> {
        this.apiService.setHeaders({partner: this.modal.params.partner.slug});
        const {data}: Api.IResponse = await
            this.apiService.request(Api.EMethod.Get, ["hub"], {}, {data_structure: "select"});
        if (data) {
            this.inventoryConversion = data;
            this.inventoryConversionFiltered.next(this.inventoryConversion);
            this.changeDetectorRef.markForCheck();
        }
    }

    private async getItems(): Promise<any> {
        this.apiService.setHeaders({partner: this.modal.params.partner.slug});
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["partmaster"], {}, {data_structure: "select"});
        if (data) {
            this.items = data;
            this.itemsFiltered.next(this.items);
            this.changeDetectorRef.markForCheck();
        }
    }

    private async getLocation(ref: string): Promise<any> {
        this.spinnerService.show();
        this.apiService.setHeaders({partner: this.modal.params.partner.slug});
        this.apiService.setHeaders({warehouse: this.inventoryConversionWh});
        const {code}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["warehouse_location", "find"], {ref: ref});
        if (code) {
            this.whLocationExists = code === 200;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private async getConversion(id: string): Promise<any> {
        this.spinnerService.show();
        this.apiService.setHeaders({partner: this.modal.params.partner.slug});
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["inventory", "conversion", id]);
        if (data && data.warehouse_slug) {
            this.inventoryConversionWh = data.warehouse_slug;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private quantitySubscribe(): void {
        this.formGroup.get("quantity").valueChanges.pipe(takeUntil(this.destroy$), debounceTime(100))
            .subscribe((value: string): void => {
                if (this.serials.length > 0 && value !== "" + this.serials.length) {
                    this.formGroup.get("quantity").setValue(this.serials.length);
                }
            });
    }

    /**
     * Get property from user
     * @param {string} name
     * @param defaultValue
     * @returns {any}
     */
    public getProperty(name: string, defaultValue: any): any {
        return this.userService.getProperty(name, this.modal.params.state, defaultValue);
    }

    /**
     * Add configuration
     * @param {string} action
     * @returns {Promise<any>}
     */
    public async addConfiguration(action?: string): Promise<any> {
        let response: Modal.IResponse;
        if (!action || action === "list") {
            response = await this.modalService.open(ItemConfigurationsListComponent, {
                item_id: this.formGroup.get("part_master_id").value
            });
        } else if (action === "form") {
            response = await this.modalService.open(ItemConfigurationFormComponent, {
                item_id: this.formGroup.get("part_master_id").value,
                modalWidth: 650
            });
        }

        if (response) {
            if (response.name === "form") {
                return this.addConfiguration("form");
            } else if (response.name === "list") {
                return this.addConfiguration("list");
            } else if (response.name === "value") {
                this.configurations.push(response.value);
                this.changeDetectorRef.markForCheck();
            }
        }
    }

    /**
     * Remove configuration chip
     * @param conf
     */
    public removeConfiguration(conf: string): void {
        const index: number = this.configurations.indexOf(conf);
        if (index >= 0) {
            this.configurations.splice(index, 1);
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Add serial chip
     * @param {MatChipInputEvent} event
     */
    public addSerial(event: MatChipInputEvent): void {
        const input: any = event.input;
        const values: string[] = event.value.split(/[\s,\t]+/);

        for (const value of values) {
            if ((value || "").trim() && this.serials.indexOf(value.trim()) === -1) {
                this.serials.push(value.trim());
                this.formGroup.get("quantity").setValue(this.serials.length);
            }
        }
        if (input) {
            input.value = "";
        }
    }

    /**
     * Remove serial chip
     * @param {string} serial
     */
    public removeSerial(serial: string): void {
        const index: number = this.serials.indexOf(serial);

        if (index >= 0) {
            this.serials.splice(index, 1);
            this.formGroup.get("quantity").setValue(this.serials.length > 0 ? this.serials.length : 1);
        }
    }

    /**
     * Submit form
     * @returns {Promise<any>}
     */
    public async handleFormSubmit(): Promise<any> {
        this.spinnerService.show();
        this.apiService.setHeaders({partner: this.modal.params.partner.slug});
        const method: Api.EMethod = this.action === "edit" ? Api.EMethod.Put : Api.EMethod.Post;
        const url: string[] = this.action === "edit" ? ["inventory", this.formGroup.value.id] : ["inventory"];
        const response: Api.IResponse = await this.apiService.request(method,
            url, {...this.formGroup.value, serials: this.serials, configurations: this.configurations});
        if (response) {
            this.toastService.show(response.message, response.type as string);
            if (response.type as string === "success") {
                this.modal.response.next({
                    name: "value",
                    value: {}
                });
            }
        }
        this.spinnerService.hide();
    }

    public ngOnInit(): void {
        this.action = this.modal.params.add
            ? "add" : "edit";
        // Actual changes
        this.spinnerService.show();
        this.getLocationTypes();
        this.getInventoryConversion();
        this.getItems();
        this.prepareForm();
        this.spinnerService.hide();
        this.quantitySubscribe();

        this.itemSearch.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value: string): void => {
                if (value) {
                    value = value.toLowerCase();
                    this.itemsFiltered.next(
                        this.items.filter((item: { name: string }): boolean =>
                            item.name.toLowerCase().includes(value)
                        ));
                } else {
                    this.itemsFiltered.next(this.items);
                }
            });
        this.inventoryConversionSearch.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value: string): void => {
                if (value) {
                    value = value.toLowerCase();
                    this.inventoryConversionFiltered.next(
                        this.inventoryConversion.filter((customers_inventory_name: { name: string }): boolean =>
                            customers_inventory_name.name.toLowerCase().includes(value)
                        ));
                } else {
                    this.inventoryConversionFiltered.next(this.inventoryConversion);
                }
            });
        this.formGroup.get("location_id").valueChanges.pipe(debounceTime(1000))
            .pipe(takeUntil(this.destroy$)).subscribe((): void => {
            this.getLocation(this.formGroup.get("location_id").value);
        });
        this.formGroup.get("inventory_conversion_id").valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((): void => {
                this.getConversion(this.formGroup.get("inventory_conversion_id").value);
            });

    }
}
