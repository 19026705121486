import {ChangeDetectionStrategy, Component, OnInit} from "@angular/core";
import {Modal} from "../../../../../services/modal.service";
import {Warehouse} from "../../../../../../common/interfaces/warehouse.interface";


@Component({
    selector: "modal-pickup-info",
    template: `
        <div class="pull-right background-accent" style="padding:10px" *ngIf="request.distance > 320">
            Remote Site PU –
            <br>
            please consult account manager
        </div>
        <h1 class="container-heading">Pickup request info</h1>
        <mat-card class="mat-elevation-z0">
            <mat-card-content>

                <table class="table">
                    <tr *ngIf="request.customer">
                        <td class="gu-unselectable"><b>Customer</b></td>
                        <td>{{request.customer.name}}</td>
                    </tr>
                    <tr>
                        <td class="gu-unselectable"><b>Pickup address</b></td>
                        <div *ngIf="request.address; else noAddress">
                            <td *ngIf="request.address.formatted_address">
                             {{request.address.formatted_address}}
                            </td>
                            <td *ngIf="!request.address.formatted_address">
                                {{request.address.address}},
                                {{request.address.city}},
                             {{request.address.country}}
                                {{request.address.zip}}
                            </td>
                        </div>
                        <ng-template #noAddress>
                            <td>
                                {{request.address_text}}
                            </td>
                        </ng-template>
                    </tr>
                    <tr>
                        <td class="gu-unselectable"><b>Contact</b></td>
                        <td>
                            <div *ngIf="request.contact; else noContact">
                                <div>
                                    <mat-icon class="gu-unselectable">account_box</mat-icon>
                                    {{request.contact.first_name}} {{request.contact.last_name}}
                                </div>
                                <div>
                                    <mat-icon class="gu-unselectable">phone</mat-icon>
                                    {{request.contact.phone}}
                                </div>
                                <div>
                                    <mat-icon class="gu-unselectable">email</mat-icon>
                                 {{request.contact.email}}
                                </div>
                            </div>
                            <ng-template #noContact>
                                <div>
                                    {{request.contact_text}}
                                </div>
                            </ng-template>
                        </td>

                    </tr>
                    <tr>
                        <td class="gu-unselectable"><b>Schedule</b></td>
                        <td>{{request.from_date}} - {{request.to_date}}</td>
                    </tr>
                    <tr>
                        <td class="gu-unselectable"><b>Type</b></td>
                        <td>{{request.type}}</td>
                    </tr>
                    <tr>
                        <td class="gu-unselectable"><b>Remark</b></td>
                        <td>
                            {{request.remarks}}
                        </td>
                    </tr>
                    <tr>
                        <td class="gu-unselectable"><b>Pickup reference</b></td>
                        <td>
                            {{request.ref}}
                        </td>
                    </tr>

                    <ng-container *ngIf="request.shippy_pro_pickup">
                        <tr>
                            <td colspan="2">
                                <div class="text-center">
                                    <b>Shippy Pro Pickup</b>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="gu-unselectable"><b>ShippyPro ID</b></td>
                            <td>
                                {{request.shippy_pro_pickup.shippy_pro_id}}
                            </td>
                        </tr>
                        <tr>
                            <td class="gu-unselectable"><b>Date</b></td>
                            <td>
                                {{request.shippy_pro_pickup.date_time | date:'dd.MM.YYYY HH:mm'}}
                            </td>
                        </tr>
                        <tr>
                            <td class="gu-unselectable"><b>Time</b></td>
                            <td>
                                {{request.shippy_pro_pickup.pickup_morning_min_time}}
                                - {{request.shippy_pro_pickup.pickup_morning_max_time}}
                                <br>
                                {{request.shippy_pro_pickup.pickup_afternoon_min_time}}
                                - {{request.shippy_pro_pickup.pickup_afternoon_max_time}}
                            </td>
                        </tr>
                    </ng-container>
                </table>

            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PickupRequestInfoModalComponent implements OnInit {

    public modal: Modal.IModal;

    public request: Warehouse.IPickupRequest;

    public constructor() {
    }

    public ngOnInit(): void {
        this.request = this.modal.params.pickup_request;
    }
}
