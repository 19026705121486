<common-confirm></common-confirm>

<h1 class="container-heading">{{action | ucfirst}} batch</h1>

<mat-card>
    <mat-card-content>

        <form *ngIf="formGroup" [formGroup]="formGroup" (submit)="handleFormSubmit()" autocomplete="off" novalidate>

            <div class="group flex">

                <mat-form-field class="full">
                    <mat-label>Batch</mat-label>
                    <input matInput formControlName="batch">
                </mat-form-field>

                <div class="full">
                    <common-form-select label="Part master"
                                        url="/partmaster"
                                        [search]="true"
                                        [required]="true"
                                        [value]="formGroup.value.part_master_id"
                                        (valueChange)="formGroup.get('part_master_id').setValue($event)"
                    ></common-form-select>
                </div>

                <div class="full">
                    <common-form-date-time label="Expiry date"
                                           [required]="true"
                                           [value]="formGroup.value.expiry_date"
                                           (valueChange)="formGroup.get('expiry_date').setValue($event)"
                    ></common-form-date-time>
                </div>
            </div>

            <div class="actions">
                <button mat-raised-button color="primary" type="submit" class="main" [disabled]="!formGroup.valid">
                    {{action | ucfirst}}
                </button>
                <ng-container *ngIf="modal.params.id">
                    &nbsp;
                    <button mat-raised-button *userAllowed="['delete_batches']"
                            type="button" color="warn" (click)="delete()">
                        Delete
                    </button>
                </ng-container>
                &nbsp;
                <button mat-button type="button" (click)="modal.response.emit()">Cancel</button>
            </div>

        </form>

    </mat-card-content>
</mat-card>
