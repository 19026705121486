import {ChangeDetectionStrategy, Component, ViewEncapsulation} from "@angular/core";
import {FormControl, Validators} from "@angular/forms";
import {Modal} from "../../../../../../../services/modal.service";

@Component({
    selector: "section-warehouse-procedures-wizard-select-locker-cell",
    templateUrl: "select.component.html",
    styleUrls: [
        "select.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class WarehouseProceduresWizardSelectLockerCellModalComponent {

    public modal: Modal.IModal;

    public select: FormControl = new FormControl(null, [Validators.required]);


    public selectItem(): void {
        this.modal.response.emit({
            name: "response",
            value: this.select.value.warehouse_location
        });
    }
}
