import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Modal} from "../../../../../services/modal.service";
import {UntypedFormBuilder, FormGroup, Validators} from "@angular/forms";
import {ToastService} from "../../../../../../common/services/toast.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";

@Component({
    selector: "section-location-type-view",
    templateUrl: "view.component.html",
    styleUrls: [
        "view.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class LocationTypeViewComponent implements OnInit {

    public modal: Modal.IModal;
    public formGroup: FormGroup;
    public edit: boolean = false;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private formBuilder: UntypedFormBuilder,
        private toastSevice: ToastService,
        private spinnerService: SpinnerService
    ) {
    }

    private prepareForm(): void {
        this.formGroup = this.formBuilder.group({
            name: ["", [Validators.required]],
        });
    }

    /**
     * Handler for form submit
     */
    public async submitForm(): Promise<any> {
        this.spinnerService.show();
        if (this.modal.params.add === true) {
            const response: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
                ["warehouse_location", "type"], {...this.formGroup.value});
            if (response) {
                this.toastSevice.show(response.message, response.type as string);
                if (response.type as string === "success") {
                    this.modal.response.emit({
                        name: "closed",
                        value: true
                    });
                }
            }
        }
        this.spinnerService.hide();
    }

    public ngOnInit(): void {
        this.prepareForm();
    }
}
