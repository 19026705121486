import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Modal} from "../../../../../services/modal.service";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Warehouse} from "../../../../../../common/interfaces/warehouse.interface";
import {User} from "../../../../../../common/interfaces/user.interface";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";


@Component({
    selector: "section-box-sticker",
    template: `

        <div class="pull-right">
            <button mat-raised-button color="accent" (click)="print()">
                <mat-icon>print</mat-icon>
                Print
            </button>
        </div>
        <h1 class="container-heading">Box sticker</h1>
        <mat-card>
            <mat-card-content #sticker>
                <div class="sticker">
                    <table>
                        <tr *ngIf="partner && partner.logo_path">
                            <td colspan="3" class="text-center">
                                <img [src]="partner.logo_path" alt="" class="logo">
                            </td>
                        </tr>
                        <tr>
                            <td class="text-center">
                                <div>Box#: <b>{{box.ref}}</b></div>
                                <common-barcode [bcValue]="box.ref" [bcDisplayValue]="false"
                                                bcWidth="1"
                                                [bcMarginTop]="1"
                                                [bcMarginLeft]="1"
                                                [bcMarginRight]="1"
                                                [bcMarginBottom]="1"
                                                bcHeight="30"></common-barcode>
                            </td>
                            <td *ngIf="items" class="text-center">
                                <div>Item: <b>{{items[0].item}}</b></div>
                                <common-barcode [bcValue]="items[0].item" [bcDisplayValue]="false"
                                                bcWidth="1"
                                                [bcMarginTop]="1"
                                                [bcMarginLeft]="1"
                                                [bcMarginRight]="1"
                                                [bcMarginBottom]="1"
                                                bcHeight="30"></common-barcode>
                            </td>
                            <td *ngIf="items" class="text-center">
                                <div>Qty: <b>{{qty}}</b></div>
                                <common-barcode [bcValue]="qty"
                                                bcWidth="1"
                                                [bcMarginTop]="1"
                                                [bcMarginLeft]="1"
                                                [bcMarginRight]="1"
                                                [bcMarginBottom]="1"
                                                [bcDisplayValue]="false"
                                                bcHeight="30"></common-barcode>
                            </td>
                        </tr>
                    </table>
                    <table *ngIf="serials && serials.length">
                        <tr>
                            <td colspan="3">
                                Serial numbers:
                            </td>
                        </tr>
                        <ng-template ngFor let-serialChunk [ngForOf]="serials">
                            <tr>
                                <ng-template ngFor let-serial [ngForOf]="serialChunk">
                                    <td class="text-center">
                                        <div>{{serial.serial}}</div>
                                        <common-barcode [bcValue]="serial.serial" [bcDisplayValue]="false"
                                                        [bcMarginTop]="1"
                                                        [bcMarginLeft]="1"
                                                        [bcMarginRight]="1"
                                                        [bcMarginBottom]="1"
                                                        bcWidth="1"
                                                        bcHeight="20"></common-barcode>
                                    </td>
                                </ng-template>
                            </tr>
                        </ng-template>
                    </table>
                </div>
            </mat-card-content>
        </mat-card>
    `,
    styleUrls: [
        "sticker.component.scss"
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoxStickerComponent implements OnInit {

    public modal: Modal.IModal;

    public box: Warehouse.IBox;

    public partner: User.IPartner;

    public items: Warehouse.IInventoryAllocation[] | Warehouse.ITransaction[];

    public serials: any[] = [];

    public qty: number = 0;

    @ViewChild("sticker", {static: false})
    public sticker: ElementRef;

    public constructor(
        private apiService: ApiService,
        private changeDetectorRef: ChangeDetectorRef,
        private spinnerService: SpinnerService
    ) {
    }

    private async getPartner(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["partner", "" + this.modal.params.box.partner_id]);
        if (data) {
            this.partner = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private async getBoxItems(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["box", "" + this.modal.params.box.id, "inventories"]);
        if (data) {
            this.items = data;
            this.qty = data.length;

            if (this.items) {
                let i: number, j: number, tempArray: any[];
                const chunk: number = 3;
                for (i = 0, j = this.items.length; i < j; i += chunk) {
                    tempArray = this.items.slice(i, i + chunk);
                    this.serials.push(tempArray);
                }
            }
        }
        this.changeDetectorRef.markForCheck();
        this.spinnerService.hide();
    }

    public print(): void {
        const printContents: any = this.sticker.nativeElement.innerHTML;
        HelpersService.stickerPopUp(printContents);
    }


    public ngOnInit(): void {
        this.box = this.modal.params.box;

        this.getBoxItems();
        this.getPartner();
    }

}
