import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {AddressFormComponent} from "../index";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {ToastService} from "../../../../../../common/services/toast.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {Table2Component} from "../../../../../../common/components/table2";
import {FormControl} from "@angular/forms";
import {debounceTime, takeUntil} from "rxjs/operators";
import {Contact} from "src/modules/common/interfaces/contact.interface";

@Component({
    selector: "section-address-list-modal",
    template: `
        <common-confirm></common-confirm>
        <h1 class="container-heading">Addresses list</h1>
        <mat-card>
            <div>
                <common-table2 *ngIf="listTable" [settings]="listTable" (onRowsSelected)="selectedRows = $event">
                    <div row1 class="row flex align-center">
                        <button mat-raised-button color="primary" class="main margin-right-10" (click)="showAddForm()">
                            Add
                        </button>

                        <button type="button" mat-raised-button color="primary" *ngIf="showMultiSelect"
                                [disabled]="!selectedRows.length"
                                (click)="addSelectedRows()">
                            Add selected
                        </button>
                    </div>

                </common-table2>
            </div>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressListModalComponent implements OnInit, OnDestroy {

    protected destroy$: EventEmitter<boolean> = new EventEmitter(false);

    public listTable: Table.ISettings;

    public modal: Modal.IModal;

    public show_only_active: FormControl = new FormControl(true);

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    public selectedRows: any[] = [];

    public showMultiSelect = false;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private modalService: ModalService,
        private toastService: ToastService,
        private spinnerService: SpinnerService
    ) {
    }

    private getUrl(): Table.ITableApi {
        const url: string[] = ["address"];
        const query: any = {};


        if (this.modal && this.modal.params) {
            if (this.modal.params.type) {
                url.push("type");
                url.push(this.modal.params.type);
            }
            if (this.modal.params.customer?.id) {
                query["customer_id"] = this.modal.params.customer.id;
            } else if (this.modal.params.customer) {
                query["customer_id"] = this.modal.params.customer;
            }

            if (this.modal.params.country) {
                query["country"] = this.modal.params.country;
            }
        }

        return {
            url,
            query
        };
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        const columns: Table.ICol[] = [
            {
                data: "address_name",
                title: "Site name"
            },
            {
                data: "ref",
                title: "Ref"
            },
            {
                data: "ref2",
                title: "Ref2"
            },
            {
                data: "address",
                title: "Address"
            },
            {
                data: "city",
                title: "City"
            },
            {
                data: "zip",
                title: "ZIP code"
            },
            {
                data: "state",
                title: "State"
            },
            {
                data: "country",
                title: "Country"
            },
            {
                data: "author.name",
                name: "Author.name",
                title: "Created/Changed by"
            },
            {
                data: "created_at",
                title: "Created at"
            },
            {
                data: "updated_at",
                title: "Updated at"
            },
        ];

        this.apiService.setHeaders({Partner: this.modal.params.partner});

        this.listTable = {
            table_id: "sndukjdrgyk",
            actions: [
                {
                    name: "edit",
                    title: "Edit",
                    click: (row: any): void => {
                        this.showEditForm(row);
                    },
                    disabledFn: (row: any): boolean => {
                        return !row.customer?.id;
                    }
                },
                {
                    name: "select",
                    title: "Select",
                    click: (row: any): void => {
                        this.modal.response.next({
                            name: "value",
                            value: {
                                controlValue: row.id,
                                viewValue: row.address + ", " + row.city + ", " + row.country,
                                refValue: row.country_iso_2,
                                address: row
                            }
                        });
                    }
                }
            ],
            api: this.getUrl(),
            columns,
            multi_select: this.showMultiSelect,
            search_default: [
                "address_name",
                "ref2",
                "ref"
            ]
        };
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Delete address
     * @param id
     */
    public async delete(id: number): Promise<any> {
        if (await this.confirmRef.confirm("Are you sure want to delete current address?")) {
            this.spinnerService.show();
            const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
                ["address", "" + id], {
                    exclude_instance: "customer",
                    exclude_instance_id: this.modal.params.customer.id
                });
            if (type as string === "success") {
                this.toastService.show(message, "success");
                this.tableRef.reload();
            }
            this.spinnerService.hide();
        }
    }

    /**
     * Show add new address form
     * @returns {void}
     */
    public async showAddForm(): Promise<any> {
        this.modal.response.next({
            name: "form"
        });
    }

    private async showEditForm(address: Contact.IAddress): Promise<any> {
        if (!address.customer?.id) {
            return;
        }
        await this.modalService.open(AddressFormComponent, {
            data: address,
            customer: address.customer.id,
            action: "edit",
            getData: true,
            canEdit: true,
            canDelete: false,
            state: this.modal.params?.state
        });

        this.tableRef.reload();
    }

    public async addSelectedRows(): Promise<any> {
        const values = this.selectedRows.map((row: any) => <any>{
            controlValue: row.id,
            viewValue: row.address + ", " + row.city + ", " + row.country,
            refValue: row.country_iso_2,
            note: row.note
        });
        this.modal.response.next({
            name: "value",
            value: values
        });
    }

    public ngOnInit(): void {
        this.showMultiSelect = this.modal.params?.state?.action === "add"
            && this.modal.params?.state?.component === "countries-instructions";
        this.prepareList();

        this.show_only_active.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe(() => {
                this.tableRef.reload(this.getUrl());
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
