import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, UrlSegment} from "@angular/router";
import {LoginService} from "./login.service";
import {UserService} from "./user.service";
import {DrawerService} from "../../app/services/drawer.service";
import {AppLockService} from "./app-lock.service";
import {User} from "../interfaces/user.interface";

@Injectable()
export class GuardService implements CanActivate {

    /**
     * Get list of protected routes
     * @returns {string[]}
     */
    private get protectedPaths(): string[] {
        return [
            "admin",
            "entrance",
            "warehouse",
            "change-password"
        ]
            .concat((this.userService.data.partners || []).map((partner: User.IPartner): string => {
                return "partner/" + partner.slug;
            }))
            .concat((this.userService.data.threepls || []).map((threepl: User.IPartner): string => {
                return "threepl/" + threepl.slug;
            }));
    }

    public constructor(
        private router: Router,
        private loginService: LoginService,
        private userService: UserService,
        private drawerService: DrawerService,
        private lockService: AppLockService
    ) {
    }

    public async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        if (this.lockService.locked && !await this.lockService.askForUnlock()) {
            return false;
        } else {
            this.lockService.unlock();
        }

        const user: User.IData = this.userService.data;

        let path: string = route.url[0] instanceof UrlSegment && route.url[0].path;
        if (path === "login") {
            if (this.loginService.token && (!user.force2fa || (user.user2fa && user.user2fa.enabled))) {
                this.router.navigate(["entrance"]);
                return false;
            }
            return true;
        } else {
            if (user.force2fa && (!user.user2fa || !user.user2fa.enabled)) {
                this.router.navigate(["login"]);
                return false;
            }

            if (user.force_change_password) {
                this.router.navigate(["change-password"]);
                return false;
            }

            this.drawerService.setItems(path);
            if (path === "partner" || path === "threepl") {
                path = path + "/" + (route.url[1] instanceof UrlSegment && route.url[1].path);
            }


            let url: string[] = [];
            if (route.children[0]) {
                url = route.children[0].url.map((segment: UrlSegment): string => segment.path);
            }

            if (path === "warehouse" && this.loginService.token) {
                path = path + "/" + (route.url[1] instanceof UrlSegment && route.url[1].path);
                route.params = {section: path, url};
                return true;
            }
            if (this.loginService.token && this.protectedPaths.includes(path)) {
                route.params = {section: path, url};
                return true;
            }
            this.router.navigate(["login"]);
            return false;
        }
    }


}
