<common-confirm></common-confirm>
<ng-template [ngIf]="data">
    <h1 class="container-heading">Inventory <b>{{data.item}}</b></h1>
    <div class="actions">Sum inventory: <b>{{sum_inventory || '0'}}</b></div>
    <div class="description">Description: <b>{{data.part_master.description}}</b></div>
    <div class="info" *ngIf="data.updated_at">Updated at: <b>{{data.updated_at}}</b></div>
    <div class="info" *ngIf="!data.updated_at">Recieved at: <b>{{data.recieved_at}}</b></div>
</ng-template>

<mat-card>
    <mat-tab-group>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon>description</mat-icon>
                Info
            </ng-template>

            <div *ngIf="inventory">
                <br>
                <mat-form-field class="pull-right">
                    <mat-label>Search</mat-label>
                    <input matInput type="search" [formControl]="inventorySearch">
                </mat-form-field>
                <table class="table" mat-table [dataSource]="inventory.data">

                    <ng-container matColumnDef="item">
                        <th mat-header-cell *matHeaderCellDef>Item</th>
                        <td mat-cell *matCellDef="let element">{{element.item}}</td>
                    </ng-container>

                    <ng-container matColumnDef="serial">
                        <th mat-header-cell *matHeaderCellDef>Serial</th>
                        <td mat-cell *matCellDef="let element">{{element.serial}}</td>
                    </ng-container>

                    <ng-container matColumnDef="inventory_name">
                        <th mat-header-cell *matHeaderCellDef>Inventory name</th>
                        <td mat-cell *matCellDef="let element">
                            {{element.inventory_conversion.customers_inventory_name}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="sub_inventory">
                        <th mat-header-cell *matHeaderCellDef>Sub-inventory</th>
                        <td mat-cell *matCellDef="let element">
                            {{element.inventory_conversion.customers_sub_inventory}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="gb">
                        <th mat-header-cell *matHeaderCellDef>GB</th>
                        <td mat-cell *matCellDef="let element">{{element.inventory_conversion.gb}}</td>
                    </ng-container>

                    <ng-container matColumnDef="logo">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <img [src]="element.partner.logo_path" alt="" width="80">
                        </td>
                    </ng-container>

                    <tr mat-header-row
                        *matHeaderRowDef="['item', 'serial', 'inventory_name','sub_inventory', 'gb', 'logo']"></tr>
                    <tr mat-row
                        *matRowDef="let row; columns: ['item', 'serial', 'inventory_name', 'sub_inventory', 'gb', 'logo'];">
                    </tr>
                </table>
                <common-pagination [data]="inventory"
                                   pid="3SZa523cXn"
                                   (goToPage)="getInventory($event.page, inventorySearch.value, $event.per_page)"></common-pagination>

            </div>

            <h2 class="total-count">Total: {{ "" + (total.inbound - total.outbound)}}</h2>
            <div class="container-columns">
                <div class="left" *ngIf="transactions.inbound">
                    <mat-form-field class="pull-right">
                        <mat-label>Search</mat-label>
                        <input matInput type="search" [formControl]="inboundSearch">
                    </mat-form-field>
                    <h2>Inbound ({{qty.inbound}})</h2>
                    <div class="transactions">
                        <mat-card class="transaction"
                                  *ngFor="let trans of transactions.inbound.data">
                            <div *ngIf="trans.ref">Ref: <b>{{trans.ref}}</b></div>
                            <div *ngIf="trans.type">Type: <b>{{trans.type}}</b></div>
                            <div *ngIf="trans.item">Item: <b>{{trans.item}}</b></div>
                            <div *ngIf="trans.serial">Serial: <b>{{trans.serial}}</b></div>
                            <div *ngIf="trans.configurations">Configurations: <b>{{trans.configurations}}</b></div>
                            <div *ngIf="trans.batch">Batch: <b>{{trans.batch}}</b></div>
                            <div *ngIf="trans.quantity">Quantity: <b>{{trans.quantity}}</b></div>
                            <div *ngIf="trans.received_at">Received at: <b>{{trans.received_at}}</b></div>
                            <div class="order" *ngIf="trans.order">
                                <button mat-raised-button color="primary" type="button"
                                        (click)="goToOrder(trans.order)">View order
                                </button>
                            </div>
                        </mat-card>
                    </div>

                    <common-pagination [data]="transactions.inbound"
                                       pid="Ojt81vP81B"
                                       (goToPage)="getTransactions('inbound', $event.page, inboundSearch.value, $event.per_page)">
                    </common-pagination>
                </div>
                <div class="right" *ngIf="transactions.outbound">
                    <mat-form-field class="pull-right">
                        <mat-label>Search</mat-label>
                        <input matInput type="search" [formControl]="outboundSearch">
                    </mat-form-field>
                    <h2>Outbound ({{qty.outbound}})</h2>
                    <div class="transactions">
                        <mat-card class="transaction"
                                  *ngFor="let trans of transactions.outbound.data">
                            <div *ngIf="trans.ref">Ref: <b>{{trans.ref}}</b></div>
                            <div *ngIf="trans.type">Type: <b>{{trans.type}}</b></div>
                            <div *ngIf="trans.item">Item: <b>{{trans.item}}</b></div>
                            <div *ngIf="trans.serial">Serial: <b>{{trans.serial}}</b></div>
                            <div *ngIf="trans.configurations">Configurations: <b>{{trans.configurations}}</b></div>
                            <div *ngIf="trans.batch">Batch: <b>{{trans.batch}}</b></div>
                            <div *ngIf="trans.quantity">Quantity: <b>{{trans.quantity}}</b></div>
                            <div *ngIf="trans.received_at">Sent at: <b>{{trans.received_at}}</b></div>
                            <div class="order" *ngIf="trans.order">
                                <button mat-raised-button color="primary" type="button"
                                        (click)="goToOrder(trans.order)">View order
                                </button>
                            </div>
                        </mat-card>
                    </div>
                    <common-pagination [data]="transactions.outbound"
                                       pid="zsPUbH1919"
                                       (goToPage)="getTransactions('outbound', $event.page, outboundSearch.value, $event.per_page)">
                    </common-pagination>
                </div>
            </div>
        </mat-tab>
        <mat-tab *ngIf="!allocationsHide">
            <ng-template mat-tab-label>
                <mat-icon>security</mat-icon>
                Allocations
            </ng-template>
            <div>
                <br>
                <common-table #allocDt [options]="allocations" (action)="handleAllocationsAction($event)"
                              (onDtEvent)="onAllocationsDtEvent($event)"></common-table>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon>history</mat-icon>
                History
            </ng-template>
            <div *ngIf="transactions.history">
                <br>
                <button type="button" mat-raised-button color="primary" (click)="exportCsv()">Export CSV</button>
                <mat-form-field class="pull-right">
                    <mat-label>Search</mat-label>
                    <input matInput type="search" [formControl]="historySearch">
                </mat-form-field>
                <div class="transactions">
                    <mat-card class="transaction"
                              *ngFor="let trans of transactions.history.data">
                        <div class="typicon {{trans.type}}">
                            <mat-icon>{{trans.type === 'inbound' ? 'arrow_downward' : 'arrow_upward' }}</mat-icon>
                        </div>
                        <div *ngIf="trans.ref">Ref: <b>{{trans.ref}}</b></div>
                        <div *ngIf="trans.type">Type: <b>{{trans.type}}</b></div>
                        <div *ngIf="trans.item">Item: <b>{{trans.item}}</b></div>
                        <div *ngIf="trans.serial">Serial: <b>{{trans.serial}}</b></div>
                        <div *ngIf="trans.configurations">Configurations: <b>{{trans.configurations}}</b></div>
                        <div *ngIf="trans.batch">Batch: <b>{{trans.batch}}</b></div>
                        <div *ngIf="trans.quantity">Quantity: <b>{{trans.quantity}}</b></div>
                        <div *ngIf="trans.received_at">Received at: <b>{{trans.received_at}}</b></div>
                        <div class="order" *ngIf="trans.order">
                            <button mat-raised-button color="primary" type="button"
                                    (click)="goToOrder(trans.order)">View order
                            </button>
                        </div>
                    </mat-card>
                </div>
                <common-pagination [data]="transactions.history"
                                   pid="sOij701K0F"
                                   (goToPage)="getTransactions(null, $event.page, $event.per_page)">
                </common-pagination>
            </div>
        </mat-tab>
    </mat-tab-group>

</mat-card>
