import {Injectable} from "@angular/core";
import {StorageService} from "./storage.service";
import {Observable, Subject} from "rxjs";
import {Push} from "../interfaces/push.interface";

@Injectable()
export class NotificationService {

    private unreadCount: number = 0;

    private newNotificationsSubject: Subject<number> = new Subject<number>();

    public readonly newNotifications: Observable<number> = this.newNotificationsSubject.asObservable();

    public constructor(private storageService: StorageService) {

        for (const n of this.storageService.get("notifications", [])) {
            if (!n.read) {
                this.unreadCount++;
            }
        }
    }


    /**
     * Add notification
     * @param notification
     */
    public addNotification(notification: Push.IData): void {
        const notifications: Push.IData[] = this.storageService.get("notifications", []);

        // console.warn(notifications, notification);

        for (const index of Object.keys(notifications)) {
            if (notifications[index] && notifications[index].job_id === notification.job_id) {
                notifications.splice(Number(index), 1);
            }
        }

        notifications.unshift({...notification, read: false});
        notifications.splice(10);

        this.storageService.set("notifications", notifications);
        if (notification.job_type !== "progressbar") {
            this.unreadCount++;
            this.newNotificationsSubject.next(this.unreadCount);
        }
    }

    /**
     * Set all notifications read
     */
    public markAsRead(): void {
        const notifications: Push.IData[] = this.storageService.get("notifications", []);
        for (const i of Object.keys(notifications)) {
            notifications[i].read = true;
        }
        this.storageService.set("notifications", notifications);

        this.unreadCount = 0;

        this.newNotificationsSubject.next(this.unreadCount);
    }

    /**
     * Get notifications list
     * @return {Push.IData[]}
     */
    public getNotifications(): Push.IData[] {
        return this.storageService.get("notifications");
    }

    /**
     * Get unread notifications count
     * @return number
     */
    public getNotificationsCount(): number {
        this.newNotificationsSubject.next(this.unreadCount);
        return this.unreadCount;
    }

    public clear(): void {
        this.storageService.set("notifications", []);
    }
}
