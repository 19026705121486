import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from "@angular/core";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {Wizard} from "../../../../../../../common/interfaces/wizard.interface";
import {StorageService} from "../../../../../../../common/services/storage.service";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {ContractService} from "../../../../../../../common/services/contract.service";
import {OrderWizardServiceLevelComponent} from "./service-level.component";
import {AddressService} from "../../../../../../../common/services/address.service";
import {takeUntil} from "rxjs/operators";
import {FormControl} from "@angular/forms";
import {Order} from "../../../../../../../common/interfaces/order.interface";

@Component({
    selector: "section-order-wizard-service-level-mavenir",
    templateUrl: "service-level.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderWizardServiceLevelMavenirComponent extends OrderWizardServiceLevelComponent
    implements OnInit, OnDestroy, OnChanges {

    private details: any;

    protected setupIndex: number = 3;

    protected address_id: number;

    public show_all: FormControl = new FormControl(false);

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        protected apiService: ApiService,
        protected storageService: StorageService,
        protected contractService: ContractService,
        protected addressService: AddressService,
        protected spinnerService: SpinnerService
    ) {
        super(changeDetectorRef, apiService, storageService, contractService, spinnerService);
    }

    private async getServiceLevelsByAddress(address_id: number): Promise<any> {
        this.spinnerService.show();

        this.serviceLevels = null;

        const {data}: Api.IResponse = await this.addressService.getServiceLevels(address_id, {
            data_structure: "grouped",
        });

        this.spinnerService.hide();

        if (data) {
            this.serviceLevels = data;

            const levels: any[] = Object.values(this.serviceLevels);

            if (levels.length === 1 && levels[0].length === 1) {

                this.service_level.setValue(Object.values(this.serviceLevels)[0][0]);

            } else if (this.data.address && this.data.address.default_service_level_id) {

                for (const group of levels) {
                    for (const level of group) {
                        if (level.id === this.data.address.default_service_level_id) {
                            this.service_level.setValue(level);
                        }
                    }
                }
            }
            this.changeDetectorRef.markForCheck();
        }
    }

    private async getServiceAllLevels(): Promise<any> {
        this.spinnerService.show();

        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Put, ["order"]);

        if (data) {
            this.serviceLevels = data.service_levels;
            if (Object.values(this.serviceLevels).length === 1 && Object.values(this.serviceLevels)[0].length === 1) {
                this.service_level.setValue(Object.values(this.serviceLevels)[0][0]);
            }
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private setupCustomField(value: boolean): void {
        if (value) {
            if (!this.details.custom_fields) {
                this.details.custom_fields = {};
            }
            this.details.custom_fields.service_level_selected = "manually";
        } else {
            if (this.details.custom_fields && this.details.custom_fields.service_level_selected) {
                delete this.details.custom_fields.service_level_selected;
            }
        }
        this.result.next({
            action: "data",
            value: {details: this.details}
        });
    }

    protected setup(data: Wizard.IData): void {
        if (data.details) {
            this.details = data.details;
            this.getServiceLevelsByAddress(this.address_id = data.details.address_id);
        }

        this.storageService.remove("preferredHub");
    }

    public ngOnInit(): void {
        this.setup(this.data);

        this.service_level.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((level: Order.IServiceLevel): void => {
                if (level) {
                    if (level.order_type && level.order_type.slug) {
                        this.result.next({
                            action: "setup",
                            value: {
                                path: "serviceLevel." + level.order_type.slug
                            }
                        });
                    }

                    this.result.next({
                        action: "data",
                        value: {serviceLevel: level}
                    });

                    this.result.next({
                        action: "result",
                        value: true
                    });
                } else {
                    this.result.next({action: "result", value: null});
                }
            });

        this.show_all.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val: boolean): void => {
            if (val) {
                this.getServiceAllLevels();
            } else {
                this.getServiceLevelsByAddress(this.address_id);
            }
            this.setupCustomField(val);
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {

        if (changes.data && changes.data.currentValue && changes.data.currentValue.details) {

            const pv: string = JSON.stringify(changes.data.previousValue.details);
            const cv: string = JSON.stringify(changes.data.currentValue.details);

            if (pv !== cv) {
                this.setup(changes.data.currentValue);
            }
        }
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

}
