import { Location } from "@angular/common";
import { Component, ChangeDetectionStrategy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Table } from "src/modules/common/interfaces/table.interface";
import { Modal } from "src/modules/section/services/modal.service";

@Component({
    selector: "partner-box-warehouse-transactions-list",
    templateUrl: "warehouse-transactions-modal.component.html",
    //styleUrls: ["form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartnerBoxWarehouseTransactionsModalComponent implements OnInit {
    public modal: Modal.IModal;
    public boxTransactionsTable: Table.ISettings;

    public constructor(
        private router: Router,
        private location: Location) {
    }

    private prepareBoxTransactionsTable(): void {

        this.boxTransactionsTable = {
            table_id: "view-box-transactions-table",
            columns: [
                {
                    data: "order.ref",
                    name: "Order.ref",
                    title: "Order ref",
                    render: (row: any): string => {
                        return `<button type="button" class="mat-mdc-raised-button mdc-button button-150">
                                ${row.order.ref}
                            </button>`;
                    },
                    click: (row: any): void => {
                        const urlTree = this.router.createUrlTree([
                            "partner",
                            this.modal.params.partner,
                            "orders",
                            "view",
                            "id",
                            row.order.id
                          ]);
                          const url = this.location.prepareExternalUrl(urlTree.toString());
                          window.open(url, "_blank");
                    }
                },
                {
                    data: "type",
                    title: "Type"
                },
                {
                    data: "item",
                    title: "Item"
                },
                {
                    data: "serial",
                    title: "Serial"
                },
                {
                    data: "created_at",
                    title: "Created_at",
                },
            ],
            api: {
                url: ["partner", this.modal.params.partner, "warehouse-transactions"],
                query: {
                    box_id: [this.modal.params.box_id],
                    relations: ["Order:id,ref"]
                },
                version: 3
            }
        };
    }

    ngOnInit(): void {
        this.prepareBoxTransactionsTable();
    }
}