import {ChangeDetectionStrategy, Component} from "@angular/core";
import {Modal} from "../../../section/services/modal.service";

@Component({
    selector: "common-modal-table",
    template: `
        <h1 class="container-heading" [ngStyle]="modal.params.titleStyles || {}">
            {{modal.params.title}}
        </h1>
        <mat-card>
            <mat-card-content>
                <common-table2 [settings]="modal.params.table"></common-table2>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalTableComponent {

    public modal: Modal.IModal;

}
