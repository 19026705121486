import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, ViewEncapsulation} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../common/interfaces/wizard.interface";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {HelpersService} from "../../../../../../../common/services/helpers.service";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {ModalService} from "../../../../../../services/modal.service";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {debounceTime, distinctUntilChanged, takeUntil} from "rxjs/operators";
import {Warehouse} from "../../../../../../../common/interfaces/warehouse.interface";
import {ReplaySubject} from "rxjs";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";

@Component({
    selector: "section-partner-procedures-wizard-update-configuration-for-serial",
    templateUrl: "update-configuration.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class PartnerProceduresWizardUpdateConfigurationForSerialComponent extends AbstractWizardStepComponent
    implements OnDestroy {

    private state: Base.IState;

    public validSerial: boolean = false;

    public hubs: { group: string, hubs: Warehouse.IHub[] }[] = [];

    public form: FormGroup = new FormGroup({
        configurations: new FormControl(null),
        inventory_id: new FormControl(null, [Validators.required]),
        remark: new FormControl(null, [Validators.required]),
    });

    public options: ReplaySubject<Warehouse.IInventory[]> = new ReplaySubject<Warehouse.IInventory[]>(1);

    public search: FormControl = new FormControl(null);

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        public helperService: HelpersService,
        private apiService: ApiService,
        private toastService: ToastService,
        private modalService: ModalService,
        private spinnerService: SpinnerService
    ) {
        super(changeDetectorRef);
    }

    public async checkSerial(serial: string): Promise<any> {
        this.spinnerService.show();
        this.validSerial = false;
        this.options.next([]);
        this.changeDetectorRef.markForCheck();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["inventory", "serials"], {serials: [serial]});

        this.spinnerService.hide();
        if (data) {
            this.options.next(data);
        }
        this.changeDetectorRef.markForCheck();
    }


    public onOptionSelected($event: MatAutocompleteSelectedEvent): void {
        const inventory: Warehouse.IInventory = $event.option.value;
        this.search.setValue(inventory.item + " - " + inventory.serial, {emitEvent: false});
        this.form.get("inventory_id").setValue(inventory.id);
        this.validSerial = true;
        this.changeDetectorRef.markForCheck();
    }


    /**
     * Submit form
     */
    public async submit(): Promise<any> {
        this.spinnerService.show();
        const {type, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["inventory", "serial-configurations-change"], this.form.value);
        if (type as string === "success") {
            this.toastService.show(message, "success");
            this.form.reset();
            this.search.reset();
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Initialize step
     * @param data
     * @returns {Promise<any>}
     */
    public async init(data: Wizard.IData): Promise<any> {
        this.state = data.state;

        this.result.emit({
            action: "result",
            value: true
        });

        this.search.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(1500), distinctUntilChanged())
            .subscribe((val: string): void => {
                if (val && typeof val === "string") {
                    this.checkSerial(val.trim());
                }
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
