import {Injectable} from "@angular/core";
import {User} from "../interfaces/user.interface";


@Injectable()
export class ThpeeplService {

    private static _threepl: User.IThreepl;

    public constructor() {
    }

    public static get threepl(): User.IThreepl {
        return this._threepl;
    }

    public static set threepl(value: User.IThreepl) {
        this._threepl = value;
    }


}
