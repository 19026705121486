import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ConfirmComponent} from "../../../../../../../common/components/confirm/confirm.component";
import {Modal, ModalService} from "../../../../../../services/modal.service";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {distinctUntilChanged} from "rxjs";
import {takeUntil} from "rxjs/operators";


@Component({
    selector: "section-pm-analog-form",
    templateUrl: "form.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class PartMasterAnalogFormComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;


    public formGroup: FormGroup = new FormGroup({
        part_master_id: new FormControl(null, [Validators.required]),
        analogs: new FormControl([], [Validators.required]),
        reverse_links: new FormControl(false, [Validators.required]),

    });

    public modal: Modal.IModal;

    public action: string = "add";

    public part_masters: { name: string, value: any }[] = [];

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private formBuilder: FormBuilder,
        private apiService: ApiService,
        private toastService: ToastService,
        private modalService: ModalService,
        private spinnerService: SpinnerService
    ) {
    }

    private async getPartMasters(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["partmaster"], null, {data_structure: "select"});
        if (data) {
            this.part_masters = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private async getData(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["partmaster", this.modal.params.item]);
        if (data) {
            this.action = "edit";

            //todo
            this.formGroup.patchValue({
                part_master_id: data.id,
                analogs: data.analogs.map((a: any): number => a.id)
            });
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    public async submit(): Promise<any> {
        this.spinnerService.show();

        const {code, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["partmaster", "analogs"], this.formGroup.value);

        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.modal.response.emit(null);
        }
    }

    public async delete(): Promise<any> {
        if (!await this.confirmRef.confirm("Are you sure want to delete this analog?")) {
            return;
        }
        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
            ["partmaster", "analogs"], this.formGroup.value);
        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.modal.response.emit(null);
        }
    }

    public ngOnInit(): void {
        this.getPartMasters();

        if (this.modal.params.item) {
            this.getData();
        }

        this.formGroup.get("part_master_id").valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
            .subscribe((value: any): void => {
                if (value) {
                    this.modal.params.item = value;
                    this.getData();
                }
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

}
