import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../../common/interfaces/wizard.interface";

import {Api, ApiService} from "../../../../../../../../common/services/api.service";
import {ModalService} from "../../../../../../../services/modal.service";
import {Base} from "../../../../../../../../common/interfaces/base.interfaces";
import {OrderItemsInfoComponent, OrderItemStickerComponent} from "../../../../../../partner/components/order";
import {HelpersService} from "../../../../../../../../common/services/helpers.service";
import {Table} from "src/modules/common/interfaces/table.interface";
import {Table2Component} from "../../../../../../../../common/components/table2";
import {StorageService} from "../../../../../../../../common/services/storage.service";
import {Warehouse} from "../../../../../../../../common/interfaces/warehouse.interface";
import {Api3Service} from "../../../../../../../../common/services/api3.service";

@Component({
    selector: "section-warehouse-procedures-wizard-scan-default-results",
    template: `
        <mat-card>
            <div>
                <div class="pull-right">
                    <button mat-raised-button [matTooltip]="(autoSync ? 'Disable': 'Enable' ) + ' auto-sync'"
                            [color]="autoSync ? 'accent': ''"
                            (click)="toggleAutoSync()">
                        <mat-icon class="margin-right-0 margin-left-0">sync</mat-icon>
                    </button>
                </div>

                <h1 class="text-center color-accent">Scan results</h1>

                <common-table2 *ngIf="listTable" [settings]="listTable">
                    <div style="font-size: 150%" row1>
                        <p>
                            <span class="color-accent">
                                Scans in queue: <b>{{ (scanFeed | filter: 'status':'==':'success').length }}</b>
                                &nbsp;
                                <mat-icon class="spin"
                                          *ngIf="processScans
                                          && (scanFeed | filter: 'status':'==':'success').length > 0">
                                    settings
                                </mat-icon>
                            </span>
                            &nbsp;
                            Scans failed: <b>{{ (scanFeed | filter: 'status':'==':'error').length }}</b>
                        </p>

                    </div>
                </common-table2>
            </div>
        </mat-card>
    `,
    styles: [
        `mat-icon.spin {
            animation: spin linear 2s infinite;
        }`
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class WarehouseProceduresWizardScanDefaultResultsComponent extends AbstractWizardStepComponent
    implements OnDestroy {

    private orderRef: string;

    private warehouseOrderId: number;

    private state: Base.IState;

    private timeout: any;

    private line_type: string = "outbound";

    public shipment: Warehouse.IShipment;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.ISettings}
     */
    public listTable: Table.ISettings;

    public autoSync: boolean = true;

    public scanFeed: any[] = [];

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    public processScans: boolean = false;

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private modalService: ModalService,
        private storageService: StorageService
    ) {
        super(changeDetectorRef);
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    /**
     * Get items
     * @returns {void}
     */
    private prepareList(): void {
        this.listTable = {
            per_page: 50,
            actions: [
                {
                    title: "Print sticker",
                    name: "print",
                    click: (row: any): void => {
                        this.printSticker(row);
                    }
                }
            ],
            api: {
                url: [
                    "warehouse",
                    this.state.section_slug,
                    "scan",
                    this.line_type,
                    "results",
                    "" + this.shipment.id,
                ],
                query: {
                    with_count_transactions: true,
                    relations: [
                        "Order:id,ref",
                        "InventoryConversion:id,customers_inventory_name,customers_sub_inventory",
                        "Status:id,name",
                    ]
                },
                version: 3
            },
            columns: [
                {
                    data: "item",
                    title: "Items"
                },
                {
                    data: "serial",
                    title: "Serial number"
                },
                {
                    data: "inventory_allocations_count",
                    title: "",
                    render: (row: any): string => {
                        if (row.inventory_allocations_count > 0) {
                            return "<button class='mat-mdc-raised-button mdc-button mat-badge mat-badge-warn"
                                + " mat-badge-overlap mat-badge-above mat-badge-after mat-badge-medium mat-accent' "
                                + "data-action='allocations' type='button'>"
                                + "<span class='mat-badge-content mat-badge-active'>"
                                + row.inventory_allocations_count + "</span>"
                                + "Allocations"
                                + "</button>";
                        } else {
                            return "";
                        }
                    },
                    click: (row: any): void => {
                        this.modalService.open(OrderItemsInfoComponent, {
                            data: row,
                            order: row.order,
                            state: this.state,
                        });
                    },
                    searchable: false,
                    sortable: false
                },
                {
                    data: "warehouse_transactions_count",
                    title: "",
                    render: (row: any): string => {
                        if (row.warehouse_transactions_count > 0) {
                            return "<button class='mat-mdc-raised-button mdc-button mat-badge mat-badge-accent"
                                + " mat-badge-overlap mat-badge-above mat-badge-after mat-badge-medium" +
                                " mat-primary status "
                                + ((row.status && row.status.name && row.status.name !== "")
                                    ? row.status.name.toLowerCase() : "")
                                + "' "
                                + "data-action='transactions' type='button'>"
                                + "<span class='mat-badge-content mat-badge-active'>"
                                + row.warehouse_transactions_count + "</span>"
                                + "Transactions"
                                + "</button>";
                        } else {
                            return "";
                        }
                    },
                    click: (row: any): void => {
                        this.modalService.open(OrderItemsInfoComponent, {
                            data: row,
                            order: row.order,
                            state: this.state,
                            openTab: "transactions",
                        });
                    },
                    searchable: false,
                    sortable: false
                },
                {
                    data: "batch",
                    title: "Batch"
                },
                {
                    data: "configurations",
                    title: "Configurations",
                    render: (row: any): string => {
                        return HelpersService.getConfigNames(row);
                    }
                },
                {
                    data: "quantity",
                    title: "Quantity"
                },
                {
                    data: "inventory_conversion.customers_inventory_name",
                    name: "InventoryConversion.customers_inventory_name",
                    title: "Hub",
                    render: (row: any): string => {
                        return row.inventory_conversion.customers_inventory_name
                            + " " + row.inventory_conversion.customers_sub_inventory;
                    }
                },
                {
                    data: "ref",
                    title: "Ref"
                },
                {
                    data: "status.name",
                    name: "Status.name",
                    title: "Status"
                }
            ]
        };

        this.changeDetectorRef.markForCheck();
    }

    private initTimer(): void {
        if (this.timeout) {
            clearInterval(this.timeout);
        }
        this.timeout = setInterval(() => {
            this.tableRef.reload();
            this.getScanBuffer();
        }, 10000);
    }

    private async getScanBuffer(): Promise<any> {
        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `${this.state.section}/scan/${this.line_type}/${this.shipment.id}`, {}, {});

        if (data) {
            this.scanFeed = data;

            if (this.scanFeed.length == 0 && this.timeout) {
                clearInterval(this.timeout);
            }
            this.changeDetectorRef.markForCheck();
        }
    }

    private async printSticker(row: any): Promise<void> {
        row.order_ref = this.orderRef;
        const data = await this.apiService.request(Api.EMethod.Get,
            ["order_item", "transaction", row.id], {}
        );
        if (data) {
            row.transactions = data.data;
        } else {
            row.transactions = [];
        }

        const locations = {};
        for (const tr of row.transactions) {
            if (tr.warehouse_location) {
                if (!locations[tr.warehouse_location.id]) {
                    locations[tr.warehouse_location.id] = {
                        location: tr.warehouse_location.location,
                        quantity: tr.quantity
                    };
                } else {
                    locations[tr.warehouse_location.id].qantity += tr.qantity;
                }
            }
        }

        this.modalService.open(OrderItemStickerComponent, {
            item: row,
            serials: row.transactions.map(t => t.serial).filter(s => !!s),
            locations: Object.values(locations),
        });
    }

    public toggleAutoSync(): void {
        if (this.autoSync) {
            this.storageService.set("wh_scan_results_auto_sync", false);
            this.autoSync = false;
            if (this.timeout) {
                clearInterval(this.timeout);
            }
        } else {
            this.storageService.set("wh_scan_results_auto_sync", true);
            this.autoSync = true;

            this.initTimer();
        }

        this.changeDetectorRef.markForCheck();
    }

    /**
     * Initialize step
     * @param data
     * @returns {Promise<any>}
     */
    public async init(data: Wizard.IData): Promise<any> {
        this.state = data.state;
        this.orderRef = data.orderRef;
        this.warehouseOrderId = data.warehouseOrderId;
        this.line_type = data.wizardType;
        this.shipment = data.shipment;
        this.processScans = data.processScans;
        this.prepareList();
        this.getScanBuffer();
        this.result.emit({
            action: "result",
            value: true
        });

        this.autoSync = this.storageService.get("wh_scan_results_auto_sync", true);
        if (this.autoSync) {
            this.initTimer();
        }

        this.changeDetectorRef.markForCheck();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();

        if (this.timeout) {
            clearInterval(this.timeout);
        }
    }

}
