import {Injectable} from "@angular/core";
import {Api, ApiService} from "./api.service";


@Injectable()
export class RequestService {

    public constructor(private apiService: ApiService) {
    }

    public getRequests(query: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Get, ["request"], {}, query);
    }

}
