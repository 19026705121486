<mat-card *ngIf="form" [formGroup]="form">
    <div class="sort pull-right">
        Sort:
        <button mat-icon-button color="primary" (click)="onMoveUp.emit(true)"
                [disabled]="index === 0"
                matTooltip="Move up" type="button">
            <mat-icon>arrow_drop_up</mat-icon>
        </button>
        <button mat-icon-button color="primary" (click)="onMoveDown.emit(true)"
                matTooltip="Move down" type="button">
            <mat-icon>arrow_drop_down</mat-icon>
        </button>


        <button mat-icon-button color="warn" (click)="onDelete.emit(true)"
                matTooltip="Remove" type="button">
            <mat-icon>remove</mat-icon>
        </button>
    </div>
    <div class="move pull-right">
        <input type="text" matInput #moveToPos/>
        <button mat-icon-button color="primary" (click)="onMoveTo.emit(moveToPos.value * 1)"
                matTooltip="Move down" type="button">
            <mat-icon>check</mat-icon>
        </button>
    </div>
    <h3>Field {{index + 1}}</h3>

    <div class="group flex">
        <mat-form-field class="half">
            <mat-label>Label</mat-label>
            <input matInput formControlName="label" required>
        </mat-form-field>

        <mat-form-field class="half">
            <mat-label>Name (readonly)</mat-label>
            <input matInput formControlName="name" readonly>
        </mat-form-field>

        <mat-form-field class="half">
            <mat-label>Type</mat-label>
            <mat-select formControlName="type" required>
                <mat-option *ngFor="let type of (fieldTypes | keys)" [value]="type">
                    {{type}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="half">
            <mat-label>Size</mat-label>
            <mat-select formControlName="size" required>
                <mat-option *ngFor="let size of fieldSizes" [value]="size">
                    {{size}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div class="half" *ngIf="fieldTypes[form.value.type].required">
            <mat-checkbox formControlName="required">Required</mat-checkbox>
        </div>

        <div class="half" *ngIf="fieldTypes[form.value.type].multiple">
            <mat-checkbox formControlName="multiple">Multiple</mat-checkbox>
        </div>

        <mat-form-field class="full" *ngIf="fieldTypes[form.value.type].options">
            <mat-label>Values</mat-label>
            <mat-chip-grid #chipGrid>
                <mat-chip-row matChipRemove
                              *ngFor="let chip of form.value.values"
                              [removable]="true"
                              (removed)="removeValue(chip)">
                    {{chip}}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip-row>
                <input [matChipInputFor]="chipGrid"
                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                       [matChipInputAddOnBlur]="true"
                       (matChipInputTokenEnd)="addValue($event)"/>
            </mat-chip-grid>
        </mat-form-field>

        <mat-form-field class="full" *ngIf="fieldTypes[form.value.type].url">
            <mat-label>URL</mat-label>
            <input matInput formControlName="url">
        </mat-form-field>

        <mat-form-field class="full" *ngIf="fieldTypes[form.value.type].description">
            <mat-label>Description</mat-label>
            <textarea matInput formControlName="description"></textarea>
        </mat-form-field>
    </div>
</mat-card>
