<h1 class="container-heading">{{this.modal.params.edit ? "Edit" : "New"}} message</h1>

<mat-card>
    <mat-card-content>

        <form *ngIf="formGroup" [formGroup]="formGroup" (submit)="submit()">

            <div class="group flex">
                <mat-form-field class="full" *ngIf="!this.modal.params.edit && !this.modal.params.hideContacts">
                    <mat-label>To</mat-label>
                    <mat-chip-grid #chipGridTo>
                        <mat-chip-row matChipRemove *ngFor="let email of formGroup.get('to').value" [removable]="true"
                                      (removed)="removeChip(email,'to')">
                            {{email}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip-row>
                        <input [matChipInputFor]="chipGridTo"
                               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                               [matChipInputAddOnBlur]="true"
                               (matChipInputTokenEnd)="addChip($event, 'to')">
                    </mat-chip-grid>
                </mat-form-field>

                <mat-form-field class="full" *ngIf="!this.modal.params.edit && !this.modal.params.hideContacts">
                    <mat-label>Cc</mat-label>
                    <mat-chip-grid #chipGridCc>
                        <mat-chip-row matChipRemove *ngFor="let email of formGroup.get('cc').value" [removable]="true"
                                      (removed)="removeChip(email,'to')">
                            {{email}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip-row>
                        <input [matChipInputFor]="chipGridCc"
                               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                               [matChipInputAddOnBlur]="true"
                               (matChipInputTokenEnd)="addChip($event, 'cc')">
                    </mat-chip-grid>
                </mat-form-field>

                <mat-form-field class="full" *ngIf="!this.modal.params.edit && !this.modal.params.hideContacts">
                    <mat-label>Bcc</mat-label>
                    <mat-chip-grid #chipGridBcc>
                        <mat-chip-row matChipRemove *ngFor="let email of formGroup.get('bcc').value" [removable]="true"
                                      (removed)="removeChip(email,'bcc')">
                            {{email}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip-row>
                        <input [matChipInputFor]="chipGridBcc"
                               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                               [matChipInputAddOnBlur]="true"
                               (matChipInputTokenEnd)="addChip($event, 'bcc')">
                    </mat-chip-grid>
                </mat-form-field>

                <mat-form-field class="half" *ngIf="!this.modal.params.edit">
                    <mat-label>Who can see</mat-label>
                    <mat-select formControlName="order_remark_type_id"
                                required>
                        <mat-option *ngFor="let option of remarkTypes" [value]="option.id">
                            {{option.name}}
                        </mat-option>
                    </mat-select>
                    <span matPrefix><mat-icon class="vertical-middle">remove_red_eyes</mat-icon>&nbsp;</span>
                </mat-form-field>

                <div class="half" *ngIf="formGroup.get('solution') && !this.modal.params.edit">
                    <mat-checkbox formControlName="solution">
                        Mark as solution
                    </mat-checkbox>
                </div>

                <div class="full">
                    <common-form-editor
                            [value]="editorInitialValue"
                            [required]="true"
                            (valueChange)="formGroup.get('message').setValue($event)">
                    </common-form-editor>
                </div>
            </div>

            <div class="actions">
                <button mat-raised-button color="primary" type="submit" class="main"
                        [disabled]="!formGroup.valid">
                    Send
                </button>
                <button mat-button type="button" (click)="close()">Cancel</button>
            </div>

        </form>

    </mat-card-content>
</mat-card>
