import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from "@angular/core";
import {Table, TableComponent} from "../../../../../../common/components/table/table.component";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {CustomerAccountFormComponent} from "../index";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {ToastService} from "../../../../../../common/services/toast.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";

@Component({
    selector: "section-customer-account-list",
    template: `
        <common-confirm></common-confirm>

        <h1 class="container-heading">Customer accounts{{modal.params.type ? " " + modal.params.type : ""}} list</h1>
        <mat-card>
            <mat-card-content>
                <common-table *ngIf="listTable" [options]="listTable" (action)="handleListAction($event)">
                    <div data-actions>
                        <button mat-raised-button color="primary" class="main" (click)="showAddForm()">Add</button>
                    </div>
                </common-table>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerAccountListComponent implements OnInit {

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.IOptions;

    public modal: Modal.IModal;

    @ViewChild(TableComponent, {static: false})
    public tableRef: TableComponent;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    public constructor(
        private apiService: ApiService,
        private modalService: ModalService,
        private toastService: ToastService,
        private spinnerService: SpinnerService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        const actions: Table.Action.ISettings[] = [];
        let url: string[] = ["customer_account"];
        const query: string = "?data_structure=dataTables";

        if (this.modal.params.type === "company") {
            url = ["customer_account", "courier_companies"];
        } else if (this.modal.params.type === "service") {
            url = ["customer_account", "service_levels"];
        }

        if (this.modal.params) {
            if (this.modal.params.canEdit) {
                actions.push({
                    name: "view",
                    title: "View"
                });
            } else {
                actions.push({
                    name: "select",
                    title: "Select"
                });
            }

            if (this.modal.params.canDelete) {
                actions.push({
                    name: "delete",
                    title: "Delete"
                });
            }
        }

        this.listTable = {
            actions,
            api: this.apiService.getUrl(url) + query,
            columns: [
                {
                    data: "name",
                    title: "Name"
                },
                {
                    data: "created_at",
                    title: "Created at"
                }
            ],
            order: [],
            search: true
        };
        this.changeDetectorRef.markForCheck();
    }


    /**
     * Show contact form
     * @param id
     */
    private async showEditForm(id: number): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(CustomerAccountFormComponent, {
            data: {id},
            action: "edit",
            getData: true,
            canDelete: true,
            type: this.modal.params.type
        });
        if (response) {
            this.tableRef.reload();
        }
    }

    /**
     * Delete contact
     * @param contact
     */
    public async delete(contact: any): Promise<any> {
        if (await this.confirmRef.confirm("Are you sure want to delete current customer account?")) {
            this.spinnerService.show();
            const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
                ["customer_account", "" + contact.id]);
            if (type as string === "success") {
                this.toastService.show(message, "success");
                this.tableRef.reload();
            }
            this.spinnerService.hide();
        }
    }

    /**
     * Handle list row action
     * @param {Table.Action.IResult} action
     * @returns {Promise<any>}
     */
    public async handleListAction(action: Table.Action.IResult): Promise<any> {
        if (action.name === "select" && action.data.id) {
            this.modal.response.next({
                name: "value",
                value: {
                    controlValue: action.data.id,
                    viewValue: action.data.name
                }
            });
        } else if (action.name === "view") {
            this.showEditForm(action.data.id);
        } else if (action.name === "delete") {
            this.delete(action.data);
        }
    }

    /**
     * Show add new address form
     * @returns {void}
     */
    public async showAddForm(): Promise<any> {
        if (this.modal.params.canEdit) {
            const response: Modal.IResponse = await this.modalService.open(CustomerAccountFormComponent, {
                action: "add",
                address: this.modal.params.order_id,
                type: this.modal.params.type
            });
            if (response) {
                this.tableRef.reload();
            }
        } else {
            this.modal.response.next({
                name: "form"
            });
        }
    }

    public ngOnInit(): void {
        this.prepareList();
    }

}
