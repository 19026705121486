import {Injectable, isDevMode} from "@angular/core";
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {StorageService} from "./storage.service";
import {ToastService} from "./toast.service";

import {environment} from "../../../environments/environment";
import {AppLockService} from "./app-lock.service";
import {SpinnerService} from "./spinner.service";
import {Api} from "./api.service";

@Injectable()
export class Api3Service {


    /**
     * Default headers map
     * @type {{Object}}
     */
    private staticHeaders: Object = {
        "Accept": "application/json",
        "Content-Type": "application/json; charset=UTF-8"
    };

    /**
     * Host url
     * @type {string}
     */
    private host: string = environment.apiUrl;

    private baseUrl: string = "/api/v3/";


    /**
     * Authorization token
     * @return {string}
     */
    private get token(): string {
        return this.storageService.get("token");
    }

    private logoutTimer: any;

    public constructor(
        private httpClient: HttpClient,
        private activatedRoute: ActivatedRoute,
        private storageService: StorageService,
        private toastService: ToastService,
        private spinnerService: SpinnerService,
        private router: Router,
        private lockService: AppLockService
    ) {
    }

    private logoutSequence(): void {
        /*if (!environment.prodServer.includes(window.location.hostname)) {
            return;
        }*/

        clearTimeout(this.logoutTimer);
        this.logoutTimer = setTimeout((): void => {
            this.request(Api.EMethod.Get, "logout");
            this.storageService.clean();
            this.router.navigateByUrl("/");
            this.toastService.show("App was logged out due long inactivity", "success");
            clearTimeout(this.logoutTimer);
        }, 3600000 * 6);
    }

    /**
     * Get prepared http headers
     * @param {boolean} asObject
     * @returns {HttpHeaders | Object}
     */
    public getHeaders(asObject?: boolean): HttpHeaders | Object {
        const headers: Object = {...this.staticHeaders};

        if (this.token) {
            headers["Authorization"] = this.token;
        }

        headers["Front-Referer"] = window.location.href;

        if (asObject) {
            return headers;
        }
        let httpHeaders: HttpHeaders = new HttpHeaders();
        Object.keys(headers).forEach((name: string): any =>
            name && (httpHeaders = httpHeaders.set(name, headers[name])));
        return httpHeaders;
    }

    /**
     * Get prepared http url params
     * @param {Object} params
     * @returns {HttpParams}
     */
    public getParams(params?: Object): HttpParams {
        let httpParams: HttpParams = new HttpParams();
        if (params) {
            for (const name of Object.keys(params)) {
                if (typeof params[name] === "object") {
                    if (params[name]) {
                        for (const i of Object.keys(params[name])) {
                            httpParams = httpParams.set(name + "[" + i + "]", params[name][i]);
                        }
                    }
                } else {
                    httpParams = httpParams.set(name, params[name]);
                }
            }
        }
        return httpParams;
    }

    /**
     * Prepare http error response (with console output & toast message)
     * @param {HttpErrorResponse} response
     * @returns {Api.IResponse}
     */
    public prepareErrorResponse({error}: HttpErrorResponse): Api.IResponse {

        this.spinnerService.hide();

        if (!error) {
            return;
        }

        if (error.code === 418) {
            this.lockService.unlock();
            this.router.navigateByUrl("/change-password");
            return;
        }

        const message: string = (error && typeof error !== "string" && !(error instanceof ProgressEvent) && !error.text)
            ? `${error.message}<br>
                ${error.data && Array.isArray(error.data) ? error.data.join("<br>") : ""}`
            : "Request error";

        if (!error.code || error.code === 500 || !error.type) {
            this.toastService.show({message, request: {type: "error"}}, "error");
        } else {
            this.toastService.show(message, error.type);
        }

        if (error.code === 401 || error.code === 403) {
            this.lockService.unlock();
            this.router.navigateByUrl("/");
        }

        if (error.code === 401) {
            this.storageService.clean();
        }

        return {data: null, error, message: error.message, type: Api.EResponseType.error, code: error.code};
    }

    /**
     * Prepare http success response (with toast message)
     * @param {HttpResponse<any>} response
     * @return {Api.IResponse}
     */
    public prepareSuccessResponse({body, headers}: HttpResponse<any>): Api.IResponse {
        return {
            data: (body && body.data ? body.data : body),
            error: null,
            message: (body && body.message ? body.message : ""),
            type: (body && body.type ? body.type : ""),
            code: (body && body.code ? body.code : 200),
            id: headers.get("Request-ID")
        };
    }

    /**
     * Send request (via GET, POST, PUT, DELETE methods)
     * @param {Api.EMethod} method
     * @param {string[]} url
     * @param {Object} body
     * @param {Object} params
     * @return {Promise<Api.IResponse>}
     */
    public async request(method: Api.EMethod, url: string, body?: Object, params?: Object): Promise<Api.IResponse> {
        params = this.getParams(params) as HttpParams;
        const headers: HttpHeaders = this.getHeaders() as HttpHeaders;
        const options: Object = {body: body || {}, headers, observe: "response", params, responseType: "json"};

        this.logoutSequence();

        if (Array.isArray(url)) {
            url = url.join("/");
        }

        const path: string = this.host + this.baseUrl + (url.replace(/^\/|\/$/g, ""));

        try {
            const response: HttpResponse<any> =
                await this.httpClient.request(method as string, path, options).toPromise() as any;
            return this.prepareSuccessResponse(response);
        } catch (response) {
            if (isDevMode()) {
                console.error(method, path, response);
            }
            return this.prepareErrorResponse(response);
        }
    }

    public async get(url: string, query?: Object): Promise<Api.IResponse> {
        return this.request(Api.EMethod.Get, url, {}, query);
    }

    public async post(url: string, body?: Object, query?: Object): Promise<Api.IResponse> {
        return this.request(Api.EMethod.Post, url, body, query);
    }

    public async put(url: string, body?: Object, query?: Object): Promise<Api.IResponse> {
        return this.request(Api.EMethod.Put, url, body, query);
    }

    public async patch(url: string, body?: Object, query?: Object): Promise<Api.IResponse> {
        return this.request(Api.EMethod.Patch, url, body, query);
    }

    public async delete(url: string, body?: Object, query?: Object): Promise<Api.IResponse> {
        return this.request(Api.EMethod.Delete, url, body, query);
    }

}
