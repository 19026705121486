import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../../common/interfaces/wizard.interface";
import {Base} from "../../../../../../../../common/interfaces/base.interfaces";
import {Warehouse} from "../../../../../../../../common/interfaces/warehouse.interface";
import {Api, ApiService} from "../../../../../../../../common/services/api.service";
import {Modal, ModalService} from "../../../../../../../services/modal.service";
import {ToastService} from "../../../../../../../../common/services/toast.service";
import {Contact} from "../../../../../../../../common/interfaces/contact.interface";
import {AddressFormComponent, AddressListModalComponent} from "../../../../../../partner/components/address";
import {Order} from "../../../../../../../../common/interfaces/order.interface";
import {ContactFormComponent} from "../../../../../../partner/components/contact";
import {SpinnerService} from "../../../../../../../../common/services/spinner.service";
import {ConfirmComponent} from "../../../../../../../../common/components/confirm/confirm.component";
import {AlertComponent} from "../../../../../../../../common/components/alert/alert.component";
import {FormControl, FormGroup} from "@angular/forms";
import {Table} from "../../../../../../../../common/interfaces/table.interface";
import {Api3Service} from "../../../../../../../../common/services/api3.service";
import {WarehouseService} from "../../../../../../../../common/services/warehouse.service";
import {Table2Component} from "../../../../../../../../common/components/table2";
import { ModalComponent } from "src/modules/common/components/modal/modal.component";

@Component({
    selector: "section-warehouse-order-wizard-shipment-creation",
    templateUrl: "release-documents.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    styleUrls: [
        "release-documents.component.scss"
    ],
})

export class WarehouseProceduresWizardReleaseDocumentsComponent extends AbstractWizardStepComponent
    implements OnDestroy {

    private shipment: Warehouse.IShipment;

    public state: Base.IState;

    public warehouse: Warehouse.IWarehouse;

    public order: Order.IOrderData;

    public orderRef: string;

    public orderId: number;

    public warehouseOrderId: number;

    public isInboundSchenker = false;

    public orderAaddresses: any[] = [];

    @ViewChild("amznTableRef", {static: false})
    public amznTableRef: Table2Component;

    @ViewChild(ConfirmComponent, {static: true})
    public confirmRef: ConfirmComponent;

    @ViewChild(AlertComponent, {static: true})
    public alertRef: AlertComponent;

    public formGroup: FormGroup = new FormGroup({
        sendTo: new FormControl(false),
        pl_remark: new FormControl(null)
    });

    public amznBookingTableSettings: Table.ISettings;

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private modalService: ModalService,
        private toastService: ToastService,
        private spinnerService: SpinnerService
    ) {
        super(changeDetectorRef);
    }

    private getSpecialInstruction(): string | null {

        if ((this.shipment.from_address.country_iso_2 == this.shipment.to_address.country_iso_2)
            ||
            (this.shipment.from_address.country_detailed?.in_eu && this.shipment.to_address.country_detailed?.in_eu)) {
            return null;
        }


        if (this.order.main_address.country_special_instructions) {
            let instruction: Contact.ICountrySpecialInstruction;
            instruction = this.order.main_address.country_special_instructions.find(
                (item: Contact.ICountrySpecialInstruction) => {
                    return item.address_id === this.order.main_address.id;
                }
            );
            if (instruction) {
                return instruction.message;
            }

            instruction = this.order.main_address.country_special_instructions.find(
                (item: Contact.ICountrySpecialInstruction) => {
                    return !item.address_id;
                }
            );

            if (instruction) {
                return instruction.message;
            }
        }

        if (this.order?.partner?.slug === "mavenir_il") {
            if (this.order?.partner?.properties?.inform_on_out_of_the_country) {
                if (this.shipment.from_address.country_iso_2 == "US"
                    && [6352, 14521].includes(this.order?.main_address?.customer?.id)) {
                    return null;
                }
                return "Special country!";
            }
        }

        return null;
    }

    private prepareAmazonBookingTable(): void {
        this.amznBookingTableSettings = {
            columns: [
                {
                    data: "booking_id",
                    title: "Booking ID"
                },
                {
                    data: "status",
                    title: "Status"
                },
                {
                    data: "created_at",
                    title: "Date"
                },
                {
                    data: "response",
                    title: "Response",
                    render: (row: any): string => {
                        if (!row.response) {
                            return "Unknown";
                        }
                        if (Array.isArray(row.response)) {
                            return "<button type='button' class='mat-mdc-raised-button mdc-button button-200'>" +
                            "Show errors</button>";
                        }
                        return row.response;
                    },
                    click: (row: any): void => {
                        this.showErrors(row.response);
                    }
                },
                {
                    data: "supplier_id",
                    title: "Supplier ID"
                }
            ],
            api: {
                url: [this.state.section, "shipments", "" + this.shipment.id, "amazon-booking"],
                version: 3
            }
        };
    }

    public async releaseDocs(): Promise<any> {
        const instruction = this.getSpecialInstruction();
        if (instruction) {
            const msg: string = instruction
                + "<br><br>Proceed?";

            if (this.order.partner && this.order.partner.slug === "mavenir_il") {
                await this.alertRef.show(
                    instruction
                    + " Order will moved to 'Pending Unilog' folder"
                    + " and to be on hold until further instruction received from Unilog",
                    "Destination country has special instructions!");
            } else if (!await this.confirmRef.confirm(msg, "Destination country has special instructions!")) {
                return;
            }
        }

        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.api3Service.post(
            `${this.state.section}/shipments/${this.shipment.id}/release-documents`, this.formGroup.value);
        this.spinnerService.hide();

        if (code === 200) {
            this.toastService.show(message, "success");
        }
    }

    public async validateAddress(id: number): Promise<any> {
        this.spinnerService.show();
        this.apiService.setHeaders({Partner: this.order.partner.slug});
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["address", "" + id, "validate"]);
        this.toastService.show(response.message, response.type as string);
        if (response.type as string === "success") {
            this.getData();
        }
        this.spinnerService.hide();
    }

    public async getData(): Promise<any> {
        if (!this.orderId) {
            return;
        }
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["order", "" + this.orderId], null, {
                warehouse_order_id: this.warehouseOrderId
            });
        if (data) {
            this.order = data;
            this.order.main_address.contacts = this.order.contacts;
            this.orderAaddresses = [this.order.main_address, this.order.bill_to_address].filter(Boolean);
            this.isInboundSchenker = this.order.inbound_order_item?.inventory_conversion?.warehouse?.threepl.slug
                == "schenker";
            this.changeDetectorRef.markForCheck();
        }
    }

    public number(data: any): number {
        return Number(data);
    }

    public async amznBookingSend(type: string): Promise<any> {
        if (!await this.confirmRef.confirm("Send booking request?")) {
            return;
        }

        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.api3Service.post(
            `${this.state.section}/shipments/${this.shipment.id}/amazon-booking`, {type});
        this.spinnerService.hide();

        if (code === 200) {
            this.toastService.show(message, "success");
            setTimeout(() => {
                this.amznTableRef.reload();
            }, 2000);
        }
    }

    public async showErrors(response: string[]): Promise<void> {
        await this.modalService.open(ModalComponent, {
            title: "Response",
            template: response.join("<br>")
        });
    }

    /**
     * Initialize details step
     * @returns {Promise<any>}
     * @param data
     */
    public async init(data: Wizard.IData): Promise<any> {
        this.state = data.state;
        this.shipment = data.shipment;
        this.orderRef = data.orderRef;
        this.orderId = data.order_id;
        this.warehouseOrderId = Number(data.warehouseOrderId);
        this.warehouse = WarehouseService.warehouse;

        this.getData();

        if (this.shipment.partner_id === 14) {
            this.prepareAmazonBookingTable();
        }

        this.result.emit({
            action: "result",
            value: true
        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
