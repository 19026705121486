import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Router} from "@angular/router";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {Table, TableComponent} from "../../../../../../common/components/table/table.component";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {UserService} from "../../../../../../common/services/user.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {SpinnerService} from "../../../../../../common/services/spinner.service";

@Component({
    selector: "section-inspection-list",
    template: `
        <common-confirm></common-confirm>

        <h1 class="container-heading">Inspections list</h1>
        <mat-card>
            <mat-card-content>

                <!--<common-table *ngIf="listTable" [options]="listTable" (action)="handleListAction($event)">-->
                <!--<div data-actions>-->
                <!--<a routerLink="add" mat-raised-button color="primary" class="create">Add</a>-->
                <!--</div>-->
                <!--</common-table>-->
                <a routerLink="add" mat-raised-button color="primary" class="create">Add</a>
                <div class="inspections">
                    <div class="inspections-list">
                        <div *ngIf="inspections">
                            <div class="inspection mat-elevation-z2" *ngFor="let inspection of inspections">
                                <div class="inspection-actions">
                                    <mat-icon [matMenuTriggerFor]="menu">more_vert</mat-icon>
                                </div>
                                <div class="inspection-text" (click)="add(inspection.id)">
                                    <mat-icon>description</mat-icon>
                                    <div class="title">
                                        {{inspection.name}}
                                        <small></small>
                                    </div>
                                </div>

                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item
                                            *ngIf="this.userService.validatePermissions('add_inspection_forms')"
                                            color="success" type="button" (click)="add(inspection.id)">
                                        <mat-icon>add</mat-icon>
                                        Add
                                    </button>
                                    <button mat-menu-item
                                            *ngIf="this.userService.validatePermissions('edit_inspections')"
                                            color="primary" type="button"
                                            (click)="edit(inspection.id)">
                                        <mat-icon>border_color</mat-icon>
                                        Edit
                                    </button>
                                    <button mat-menu-item
                                            *ngIf="this.userService.validatePermissions('delete_inspections')"
                                            color="warn" type="button"
                                            (click)="delete(inspection.id, inspection.name)">
                                        <mat-icon>delete</mat-icon>
                                        Delete
                                    </button>
                                </mat-menu>

                            </div>
                        </div>
                    </div>
                </div>

            </mat-card-content>
        </mat-card>
    `,
    styleUrls: [
        "list.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class InspectionListComponent implements Base.IComponent, OnInit {

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild(TableComponent, {static: false})
    public tableRef: TableComponent;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.IOptions;

    public readonly state: Base.IState;

    public inspections: any;

    public constructor(
        private router: Router, private apiService: ApiService,
        private toastService: ToastService, public userService: UserService,
        private changeDetectorRef: ChangeDetectorRef,
        private spinnerService: SpinnerService
    ) {
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        const actions: Table.Action.ISettings[] = [];

        if (this.userService.validatePermissions("add_inspection_forms")) {
            actions.push({
                name: "add",
                title: "Add"
            });
        }

        if (this.userService.validatePermissions("edit_inspections")) {
            actions.push({
                name: "edit",
                title: "Edit"
            });
        }

        if (this.userService.validatePermissions("delete_inspections")) {
            actions.push({
                name: "delete",
                title: "Delete"
            });
        }
        this.listTable = {
            actions,
            api: this.apiService.getUrl(["inspection"]),
            columns: [
                {
                    data: "name",
                    title: "Name"
                },
                {
                    data: "user.name",
                    title: "Created by"
                },
                {
                    data: "partner.icon_path",
                    title: "Partner",
                    render: (data: string): string => {
                        return "<img src='" + data + "' alt=''>";
                    }
                },
                {
                    data: "created_at",
                    title: "Creation date"
                }
            ],
            order: [],
            search: true
        };
        this.changeDetectorRef.markForCheck();
    }

    public edit(id: number): void {
        this.router.navigate([
            this.state.section,
            this.state.component,
            "edit",
            "id",
            id
        ]);
        this.getData();
    }

    public add(id: number): void {
        this.router.navigate([
            this.state.section,
            this.state.component,
            "form",
            "id",
            id
        ]);
        this.getData();
    }

    public async delete(id: number, name: string): Promise<any> {
        if (await this.confirmRef.confirm(`Do you want to delete "${name}?"`)) {
            this.spinnerService.show();
            const {data, message}: Api.IResponse =
                await this.apiService.request(Api.EMethod.Delete, ["inspection", "" + id]);
            this.spinnerService.hide();
            if (data) {
                this.toastService.show(message, "success");
                this.getData();
            }
        }
    }

    public async getData(): Promise<any> {
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["inspection"]);
        this.inspections = data;
        this.changeDetectorRef.markForCheck();
    }

    public ngOnInit(): void {
        this.prepareList();
        this.getData();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "inspections",
            actions: {
                "browse": ["browse_inspections"]
            }
        };
    }
}
