<h1 class="container-heading">{{state.action === "add" ? "Add" : "Edit"}} country rules</h1>

<mat-card>
    <mat-card-content>
        <form [formGroup]="formGroup" (submit)="submit()">
            <div class="group flex">
                <div class="full">
                    <common-autocomplete [options]="countries" placeholder="Country" [allowFreeInput]="false"
                                         class="full" (optionSelected)="onCountrySelected($event)"
                                         [errorText]="'Select country'" [required]="false"
                                         [image]="{path:'assets/images/flags',ext:'svg'}"
                                         [value]="formGroup.value.country_iso_2"></common-autocomplete>
                </div>

                <mat-form-field class="full">
                    <mat-label>Address</mat-label>
<textarea matInput
                              (focus)="openAddressModal()"
                              formControlName="address"

                              readonly></textarea>
                </mat-form-field>


                <mat-form-field class="full">
                    <mat-label>Instructions</mat-label>
                    <textarea matInput formControlName="message"
                              rows="5"
                              required></textarea>
                </mat-form-field>
            </div>

            <div class="actions">
                <button type="submit" mat-raised-button color="primary" [disabled]="!formGroup.valid">
                    Save
                </button>
                <button type="button" mat-raised-button (click)="goToList()">
                    Close
                </button>
            </div>
        </form>
    </mat-card-content>
</mat-card>
