<div class="hubs margin-bottom-20">
    <div class="from">
        <mat-form-field class="pull-right">
            <mat-label>Search</mat-label>
            <input matInput type="search" #fromSearch>
        </mat-form-field>
        <h3 matSubheader>Source: </h3>
        <div *ngIf="whFrom" class="clear hub-list">
            <mat-card class="hub" *ngFor="let group of whFrom | values | search: fromSearch.value :['group_name']"
                      (click)="setFrom(group)"
                      [class.active]="activeFrom === group.group_name">
                <div>
                    <div [ngSwitch]="group.hubs[0].warehouse.type">
                        <mat-icon *ngSwitchCase="'warehouse'">home</mat-icon>
                        <mat-icon *ngSwitchCase="'site'">location_on</mat-icon>
                        <mat-icon *ngSwitchCase="'person'">person</mat-icon>
                        <mat-icon *ngSwitchDefault>star</mat-icon>
                    </div>

                    <div class="title margin-bottom-10">
                        <b>{{group.hubs[0].warehouse.type | ucfirst}}</b>
                        <br>
                        <b>{{group.group_name}}</b>
                    </div>

                    <div class="text-left padding-left-10">
                        Available hubs:
                    </div>
                    <div class="hub-list">
                        <div *ngFor="let hub of group.hubs">
                            <small> {{hub.customers_inventory_name}} - {{ hub.customers_sub_inventory}}</small>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
    <div class="arrow">
        <div>
            <mat-icon>arrow_forward_ios</mat-icon>
            <mat-icon>arrow_forward_ios</mat-icon>
        </div>
    </div>

    <div class="to">
        <mat-form-field class="pull-right">
            <mat-label>Search</mat-label>
            <input matInput type="search" #toSearch>
        </mat-form-field>
        <h3 matSubheader>Destination: </h3>
        <div *ngIf="whTo" class="clear hub-list">
            <mat-card class="hub"
                      *ngFor="let group of whTo | values | search: toSearch.value :['group_name']"
                      (click)="setTo(group)"
                      [class.active]="activeTo === group.group_name">
                <div>
                    <div [ngSwitch]="group.hubs[0].warehouse.type">
                        <mat-icon *ngSwitchCase="'warehouse'">home</mat-icon>
                        <mat-icon *ngSwitchCase="'site'">location_on</mat-icon>
                        <mat-icon *ngSwitchCase="'person'">person</mat-icon>
                        <mat-icon *ngSwitchDefault>star</mat-icon>
                    </div>

                    <div class="title margin-bottom-10">
                        <b>{{group.hubs[0].warehouse.type | ucfirst}}</b>
                        <br>
                        <b>{{group.group_name}}</b>
                    </div>

                    <div class="text-left padding-left-10">
                        Available hubs:
                    </div>
                    <div class="hub-list">
                        <div *ngFor="let hub of group.hubs">
                            <small> {{hub.customers_inventory_name}} - {{ hub.customers_sub_inventory}}</small>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>

    </div>
</div>

<form *ngIf="formGroup" [formGroup]="formGroup">

    <h3 class="mat-h3">Order details</h3>

    <div class="group flex">

        <mat-form-field class="third">
            <mat-label>{{refPlaceholder}} ref</mat-label>
            <input matInput formControlName="ref" required>
            <span matSuffix role="button">
                <mat-icon (click)="randomRef()" title="Generate">autorenew</mat-icon>
            </span>
            <mat-error *ngIf="!formGroup.get('ref').valid && formGroup.get('ref').errors.refPattern">
                Special chars are not allowed
            </mat-error>
            <mat-error *ngIf="!formGroup.get('ref').valid && formGroup.get('ref').errors.refExists">
                {{formGroup.get('ref').errors.refExists}}
            </mat-error>
        </mat-form-field>

        <mat-form-field class="third">
            <mat-label>Case / SR number</mat-label>
            <input matInput formControlName="sr">
        </mat-form-field>

        <mat-form-field class="third">
            <mat-label>REF2</mat-label>
            <input matInput formControlName="ref2">
        </mat-form-field>

    </div>

</form>
