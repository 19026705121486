import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from "@angular/core";
import {Router} from "@angular/router";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {Table, TableComponent} from "../../../../../../common/components/table/table.component";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {UserService} from "../../../../../../common/services/user.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {SCHEDULER_DATES_OPTIONS} from "../../../../../../common/components/scheduler";
import {SpinnerService} from "../../../../../../common/services/spinner.service";


@Component({
    selector: "section-user-tasks",
    templateUrl: "tasks.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class UserTasksComponent implements Base.IComponent, OnInit {

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild(TableComponent, {static: false})
    public tableRef: TableComponent;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.IOptions;

    public readonly state: Base.IState;
    public options: any = SCHEDULER_DATES_OPTIONS;

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private userService: UserService,
        private spinnerService: SpinnerService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        const actions: Table.Action.ISettings[] = [
            {
                name: "delete",
                title: "Delete"
            }
        ];

        const columns: DataTables.ColumnSettings[] = [
            {
                data: "ref",
                title: "Ref"
            },
            {
                data: "type",
                title: "",
                render: (data: string, d: any, row: any): string => {
                    if (row.partner) {
                        return `<img src="${row.partner.icon_path}" alt="" class="logo">`;
                    } else {
                        return "";
                    }
                },
                searchable: false,
                orderable: false
            },
            {
                data: "repeat",
                title: "Repeat",
                render: (data: string, d: any, row: any): string => {
                    switch (data) {
                        case "once":
                            return `once ${row.year}-${this.options.month[row.month]}-
                            ${("" + row.date).padStart(2, "0")}
                at ${("" + row.hour).padStart(2, "0")}:${("" + row.min).padStart(2, "0")}`;
                        case "yearly":
                            return `every year ${this.options.month[row.month]}-${("" + row.date).padStart(2, "0")}
                at ${("" + row.hour).padStart(2, "0")}:${("" + row.min).padStart(2, "0")}`;
                        case "monthly":
                            return `every month ${row.date}
                at ${("" + row.hour).padStart(2, "0")}:${("" + row.min).padStart(2, "0")}`;
                        case "weekly":
                            return `every week on ${this.options.day[row.day]}
                at ${("" + row.hour).padStart(2, "0")}:${("" + row.min).padStart(2, "0")}`;
                        case "daily":
                            return `every day
                at ${("" + row.hour).padStart(2, "0")}:${("" + row.min).padStart(2, "0")}`;
                        case "hourly":
                            return `every hour at ${("" + row.min).padStart(2, "0")} min`;
                        default:
                            return "";
                    }
                }
            },
            {
                data: "created_at",
                title: "Created at"
            },
        ];

        if (this.userService.validatePermissions("browse_admin")) {
            columns.push({
                data: "user.name",
                name: "User.name",
                title: "User"
            });
        }

        this.listTable = {
            actions,
            api: this.apiService.getUrl(["user", "tasks", "all"]) + "?data_structure=dataTables",
            columns: columns,
            order: [],
            search: true
        };

        this.changeDetectorRef.markForCheck();
    }

    /**
     * Delete address
     * @param id
     */
    public async delete(id: number): Promise<any> {
        if (await this.confirmRef.confirm("Are you sure want to delete current task?")) {
            this.spinnerService.show();
            const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
                ["user", "tasks", "" + id]);
            if (type as string === "success") {
                this.toastService.show(message, "success");
                this.tableRef.reload();
            }
            this.spinnerService.hide();
        }
    }

    /**
     * Handle list row action
     * @param {Table.Action.IResult} action
     * @returns {Promise<any>}
     */
    public async handleListAction(action: Table.Action.IResult): Promise<any> {
        if (action.name === "delete") {
            this.delete(action.data.id);
        }
    }

    public ngOnInit(): void {
        this.prepareList();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "user-tasks",
            actions: {
                "browse": ["browse_users"]
            }
        };
    }

}
