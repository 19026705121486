import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {Router} from "@angular/router";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";


@Component({
    selector: "section-integrations-list",
    templateUrl: "list.component.html",
    styleUrls: [
        "list.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class IntegrationsListComponent implements Base.IComponent, OnInit {

    public readonly state: Base.IState;

    public items: any[] = [];

    public constructor(
        private apiService: ApiService,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private spinnerService: SpinnerService
    ) {
    }

    private async getIntegrationsList(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["integration"]);

        if (data) {
            this.items = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    public showItem(id: number): void {
        this.router.navigate([
            this.state.section,
            this.state.component,
            "view",
            "id",
            id
        ]);
    }

    public goToLogs(): void {
        this.router.navigate([this.state.section, "http-logs"]);
    }


    public ngOnInit(): void {
        this.getIntegrationsList();
    }


    public ngConfig(): Base.IConfig {
        return {
            name: "integrations",
            actions: {
                "browse": ["browse_integrations"]
            }
        };
    }

}
