import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {UntypedFormBuilder, FormControl} from "@angular/forms";
import {Router} from "@angular/router";
import {takeUntil} from "rxjs/operators";
import {Base} from "../../../../../../../../common/interfaces/base.interfaces";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../../common/interfaces/wizard.interface";
import {User} from "../../../../../../../../common/interfaces/user.interface";
import {Api, ApiService} from "../../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../../common/services/toast.service";
import {StorageService} from "../../../../../../../../common/services/storage.service";
import {Modal, ModalService} from "../../../../../../../services/modal.service";
import {WarehouseTransactionFormComponent} from "../../../../order";
import {
    AvailableLocationsModalComponent,
    FindLocationByItemModalComponent,
    InventoryChangeLocationModalComponent,
    WarehouseProceduresWizardSelectOrderItemModalComponent
} from "../../..";
import {IPagination} from "../../../../../../../../common/components/pagination/pagination.component";
import {Warehouse} from "../../../../../../../../common/interfaces/warehouse.interface";
import {Table} from "../../../../../../../../common/interfaces/table.interface";
import {Table2Component} from "../../../../../../../../common/components/table2";
import {Order} from "../../../../../../../../common/interfaces/order.interface";
import {SpinnerService} from "../../../../../../../../common/services/spinner.service";
import {
    CourierTransactionDeliveredFormComponent,
    CourierTransactionFormComponent
} from "../../../../courier-transaction";
import {Courier} from "../../../../../../../../common/interfaces/courier.interface";
import {WarehouseLocationFormComponent} from "../../../../locations";
import {BoxFormComponent, BoxSticker2Component} from "../../../../boxes";
import {UserService} from "../../../../../../../../common/services/user.service";
import {SoundsService} from "../../../../../../../../common/services/sounds.service";
import {Api3Service} from "../../../../../../../../common/services/api3.service";

@Component({
    selector: "section-warehouse-procedures-wizard-inbound-scan-default",
    templateUrl: "scan.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class WarehouseProceduresWizardInboundScanDefaultComponent extends AbstractWizardStepComponent
    implements OnDestroy {

    private state: Base.IState;

    private orderRef: string;

    private orderId: number;

    private warehouse_slug: string;

    private prevKey: string = null;

    private isModalOpen: boolean = false;

    public shipment: Warehouse.IShipment;

    public notFoundRefTemp: string = null;

    public serialTemp: string = null;

    @ViewChild("locationCountInput", {static: false})
    public locationCountInput: ElementRef;

    @ViewChild("searchInputRef", {static: false})
    public searchInputRef: ElementRef;

    @ViewChild("newLocationName", {static: false})
    public newLocationNameInput: ElementRef;

    public warehouseOrderId: string | number;

    public scannerKey: string;

    public partner: User.IPartner;

    public searchInput: FormControl = new FormControl(null);

    public searchInputLabel: string = "Location";

    public location: {
        name: string,
        id: number,
        image: string,
        type_id: number,
        warehouse_transactions_count: number;
        hub?: Warehouse.IHub;
    };

    public usedLocations: any[] = [];

    public listTable: Table.ISettings;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    public boxList: IPagination<Warehouse.IBox>;

    public viewSwitch: FormControl = new FormControl(false);

    public courier_transactions: Courier.ITransaction[];

    public scannerOnly: boolean = false;

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private storageService: StorageService,
        private modalService: ModalService,
        public soundsService: SoundsService,
        private spinnerService: SpinnerService,
        private userService: UserService
    ) {
        super(changeDetectorRef);
    }


    private async getLocationsByOrder(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `/${this.state.section}/orders/${this.orderId}/warehouse-locations`);
        if (data) {
            this.usedLocations = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get shipment
     */
    private async getShipment(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["shipment", "" + this.shipment.id]);
        if (data) {
            this.shipment = data;
            this.scannerOnly = this.shipment && this.shipment.partner
                ? this.shipment.partner.properties.disable_manual_scanning
                : false;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Find location or box by its ref
     */
    private async findItem(): Promise<any> {
        if (!this.location && this.notFoundRefTemp && this.searchInput.value === this.notFoundRefTemp) {
            this.addLocation(this.searchInput.value);
            return;
        }
        this.notFoundRefTemp = null;
        this.spinnerService.show();
        this.apiService.setOnErrorCallback(() => {
            setTimeout(() => {
                if (!this.isModalOpen) {
                    this.searchInputRef.nativeElement.focus();
                }
            }, 1000);
        });
        const {data, code, message, error}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["box", "warehouse_order", "" + this.warehouseOrderId, "scan_default"], {
                ref: this.searchInput.value,
                location: this.location.name,
                serial: this.serialTemp
            }, {type: "inbound"});

        this.spinnerService.hide();

        if (code === 424) {
            if (error.data.type === "wrong_location") {
                this.findLocationByItem(error.data.item);
            }
        } else if (code === 422) {
            this.notFoundRefTemp = this.searchInput.value;
        } else if (data) {
            if (data.type === "order_item") {
                await this.inboundByOrderItem(data.item_details, this.searchInput.value);
            } else if (data.type === "part_master") {
                await this.addTransactionFromPartMaster(data.part_master_details);
            } else if (data.location) {
                this.location = {
                    name: data.location.location,
                    id: data.location.id,
                    image: data.location.warehouse_location_type.icon,
                    type_id: data.location.warehouse_location_type_id,
                    warehouse_transactions_count: data.warehouse_transactions_by_inventory_count
                };
            }

            if (this.viewSwitch.value) {
                this.getBoxes();
            } else {
                this.getInventoriesInLocation();
            }

            if (!this.location.hub) {
                this.getLocationHub();
            }
            this.getLocationsByOrder();
            this.toastService.show(message, "success", () => {
                setTimeout(() => {
                    if (!this.isModalOpen) {
                        this.searchInputRef.nativeElement.focus();
                    }
                }, 1000);
            });
        }

        this.soundsService.textToSpeech(message);
        this.searchInput.reset();
        this.searchInputRef.nativeElement.focus();
        this.changeDetectorRef.markForCheck();

    }

    /**
     * Get inventory list for specified location
     */
    private getInventoriesInLocation(): void {
        if (!this.location) {
            return;
        }

        const columns: Table.ICol[] = [
            {
                data: "item",
                title: "Item",
            },
            {
                data: "serial",
                title: "Serial number",
            },
            {
                data: "configurations",
                title: "Configurations",
            },
            {
                data: "quantity",
                title: "Quantity",
            },
            {
                data: "partner.icon_path",
                title: "Partner",
                render: (data: any): string => {
                    return "<img class='logo' src='" + data.partner.icon_path + "' alt=''>";
                },
                searchable: false,
                sortable: false
            },
            {
                data: "inventory_conversion.customers_inventory_name",
                name: "InventoryConversion.customers_inventory_name",
                title: "Hub"
            }
        ];

        const api: any = {
            url: ["warehouse_location", "" + this.location.id, "inventories"],
            query: {
                warehouse_order_id: this.warehouseOrderId
            }
        };

        if (this.listTable && this.tableRef) {
            this.tableRef.reload(api);
        } else {
            this.listTable = {
                table_id: "bjsd9ZH711F",
                api,
                columns: columns,
                export: {
                    file_name: "Invenories",
                    columns: columns
                }
            };
        }
        this.changeDetectorRef.markForCheck();
    }

    private async getLocationHub(): Promise<any> {
        if (this.location.hub) {
            return;
        }
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["warehouse_location", "" + this.location.id, "hub"]);
        if (data && data.customers_sub_inventory) {
            this.location.hub = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private async addTransactionFromItem(item: any, total_item_quantity: number): Promise<any> {
        this.isModalOpen = true;
        const response: Modal.IResponse = await this.modalService.open(WarehouseTransactionFormComponent,
            {
                action: "add",
                order_id: item.order_id,
                data: item,
                type: "inbound",
                modalWidth: 600,
                state: this.state,
                scannerKey: this.scannerKey,
                partner: item.partner,
                warehouse_slug: this.warehouse_slug,
                location_id: this.location.id,
                location_name: this.location.name,
                total_item_quantity
            });
        this.isModalOpen = false;

        if (response) {

            if (this.viewSwitch.value) {
                this.getBoxes();
            } else {
                this.getInventoriesInLocation();
            }

            this.toastService.show("Item inbound", "success");
            this.soundsService.textToSpeech("Item inbound");
        }

        this.searchInput.reset();
        this.searchInputRef.nativeElement.focus();
    }

    private async addTransactionFromPartMaster(part_master: any): Promise<any> {
        this.isModalOpen = true;
        await this.modalService.open(WarehouseTransactionFormComponent, {
            action: "add",
            warehouse_order_id: this.warehouseOrderId,
            data: {part_master_id: part_master.id},
            type: "inbound",
            modalWidth: 600,
            state: this.state,
            scannerKey: this.scannerKey,
            partner: part_master.partner,
            warehouse_slug: this.warehouse_slug,
            location_id: this.location.id,
            location_name: this.location.name
        });
        this.isModalOpen = false;

        if (this.viewSwitch.value) {
            this.getBoxes();
        } else {
            this.getInventoriesInLocation();
        }

        this.toastService.show("Item inbound", "success");
        this.soundsService.textToSpeech("Item inbound");
        this.searchInput.reset();
        this.searchInputRef.nativeElement.focus();
    }

    private async inboundByOrderItem(item_details: any, ref: string): Promise<any> {

        if (await this.inboundByOrderItemAllocations(item_details, ref)) {
            return;
        }

        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["order", item_details.order.id, "items", "inbound"], {}, {
                with_count_transactions: true,

            });

        this.spinnerService.hide();
        if (data) {
            let items: Order.IItem[] = data.filter((item: Order.IItem): boolean => item.item === item_details.item);

            items = items.filter((item: Order.IItem): boolean =>
                !item.warehouse_transactions_count || Number(item.quantity) > Number(item.warehouse_transactions_count)
            );

            if (items.length === 1) {
                const item: any = items[0];
                item.partner = item_details.partner;
                item.order_item_id = item.id;
                if (item_details.part_master?.conversion?.conversion_part_master?.item
                    && ref === item_details.part_master?.conversion?.conversion_part_master.item) {

                    item.item = item_details.part_master?.conversion?.conversion_part_master.item;
                    item.part_master_id = item_details.part_master?.conversion?.conversion_part_master.id;
                    if (item_details.part_master?.conversion.clear_serial) {
                        item.serial = null;
                    }
                }

                this.addTransactionFromItem(item, item_details.quantity);
            } else if (items.length > 1) {
                if (item_details.part_master?.conversion?.conversion_part_master?.item
                    && ref === item_details.part_master?.conversion?.conversion_part_master.item) {

                    items = items.map((item: any): any => {
                        item.item = item_details.part_master?.conversion?.conversion_part_master.item;
                        item.part_master_id = item_details.part_master?.conversion?.conversion_part_master.id;
                        if (item_details.part_master?.conversion.clear_serial) {
                            item.serial = null;
                        }

                        return item;
                    });
                }
                this.selectOrderItems(items, item_details.partner, item_details.quantity);
            }
        }
    }

    private async inboundByOrderItemAllocations(item_details: any, ref: string): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `${this.state.section}/shipments/${this.shipment.id}/allocations`);

        this.spinnerService.hide();
        if (data) {
            let items: Warehouse.IInventoryAllocation[] =
                data.filter((item: Warehouse.IInventoryAllocation): boolean => item.item === item_details.item);

            items = items.filter((item: Warehouse.IInventoryAllocation): boolean => item.type === "inbound");

            if (items.length === 1) {
                const item: any = items[0];
                item.partner = item_details.partner;
                if (item_details.part_master?.conversion?.conversion_part_master?.item
                    && ref === item_details.part_master?.conversion?.conversion_part_master.item) {

                    item.item = item_details.part_master?.conversion?.conversion_part_master.item;
                    item.part_master_id = item_details.part_master?.conversion?.conversion_part_master.id;
                    if (item_details.part_master?.conversion.clear_serial) {
                        item.serial = null;
                    }
                }

                this.addTransactionFromItem(item, item_details.quantity);

                return true;
            } else if (items.length > 1) {
                if (item_details.part_master?.conversion?.conversion_part_master?.item
                    && ref === item_details.part_master?.conversion?.conversion_part_master.item) {

                    items = items.map((item: any): any => {
                        item.item = item_details.part_master?.conversion?.conversion_part_master.item;
                        item.part_master_id = item_details.part_master?.conversion?.conversion_part_master.id;
                        if (item_details.part_master?.conversion.clear_serial) {
                            item.serial = null;
                        }

                        return item;
                    });
                }
                this.selectOrderItems(items, item_details.partner, item_details.quantity);
                return true;
            }
        }

        return false;
    }

    private async selectOrderItems(items: any[], partner: User.IPartner, total_item_quantity: number): Promise<any> {
        const response: Modal.IResponse = await this.modalService
            .open(WarehouseProceduresWizardSelectOrderItemModalComponent, {
                items,
                partner
            });
        if (response && response.value) {
            const item: any = response.value;
            item.partner = partner;
            item.order_item_id = item.order_item_id ? item.order_item_id : item.id;
            this.addTransactionFromItem(item, total_item_quantity);
        }
    }

    /**
     * Get courier transactions for selected shipment
     */
    private async getCourierTransactions(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["shipment", "" + this.shipment.id, "courier_transactions"]);
        this.spinnerService.hide();
        if (data) {
            this.courier_transactions = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Find location if none is specified
     */
    public async findLocation(): Promise<any> {
        if (!this.location && this.notFoundRefTemp && this.searchInput.value === this.notFoundRefTemp) {
            this.addLocation(this.searchInput.value);
            return;
        }
        this.notFoundRefTemp = null;
        this.spinnerService.show();
        const {data, code, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["warehouse_location", "find"], {ref: this.searchInput.value || this.location.name});

        this.spinnerService.hide();

        if (code === 424) {
            this.notFoundRefTemp = this.searchInput.value;
        } else if (data) {
            this.location = {
                name: this.searchInput.value || this.location.name,
                id: data.id,
                image: data.warehouse_location_type.icon,
                type_id: data.warehouse_location_type_id,
                warehouse_transactions_count: data.warehouse_transactions_by_inventory_count
            };
            this.getLocationHub();
            this.searchInputLabel = "Location or box";

            if (this.viewSwitch.value) {
                this.getBoxes();
            } else {
                this.getInventoriesInLocation();
            }
        }
        this.soundsService.textToSpeech(message);
        this.searchInput.reset();
        this.searchInputRef.nativeElement.focus();
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Find location or box
     */
    public async find(): Promise<any> {
        if (!this.location) {
            return this.findLocation();
        } else {
            this.findItem();
        }
    }

    /**
     * Add new location and select it
     */
    public async addLocation(value: string): Promise<any> {
        this.notFoundRefTemp = null;
        const response: Modal.IResponse = await this.modalService.open(WarehouseLocationFormComponent, {
            location_name: value
        });
        if (response && response.value) {
            this.location = {
                name: response.value.location,
                id: response.value.id,
                image: response.value.warehouse_location_type.icon,
                type_id: response.value.warehouse_location_type_id,
                warehouse_transactions_count: response.value.warehouse_transactions_by_inventory_count
            };

            this.soundsService.textToSpeech("Location set");

            this.searchInputLabel = "Location or box";

            if (this.viewSwitch.value) {
                this.getBoxes();
            } else {
                this.getInventoriesInLocation();
            }

            this.searchInput.reset();
            this.searchInputRef.nativeElement.focus();
            this.changeDetectorRef.markForCheck();
        }
    }


    /**
     * Listen scanner activation key event
     * @param event
     */
    public onKeydown(event: any): void {
        if (this.prevKey !== "Control" && this.prevKey !== "Shift") {
            if (event.key === "Enter" || event.key === "Tab") {
                this.find();
            }
        }

        if ((this.prevKey === "Control" || this.prevKey === "Shift" || this.prevKey === "Alt")
            && event.key === "Enter") {
            this.searchInput.setValue(this.searchInput.value + ",");
        }

        this.prevKey = event.key;
    }

    /**
     * Set location from used list
     * @param data
     */
    public setLocationFromList(data: any): void {
        this.location = {
            name: data.location,
            id: data.id,
            image: data.warehouse_location_type.icon,
            type_id: data.warehouse_location_type_id,
            warehouse_transactions_count: data.warehouse_transactions_by_inventory_count
        };
        this.getLocationHub();
        if (this.viewSwitch.value) {
            this.getBoxes();
        } else {
            this.getInventoriesInLocation();
        }
        this.soundsService.textToSpeech("Location set");
        this.searchInputLabel = "Location or box";
        this.searchInput.reset();
        this.searchInputRef.nativeElement.focus();
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Clear location
     */
    public clearLocation(): void {
        this.location = null;
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Clear scanned serial
     */
    public clearSerial(): void {
        this.serialTemp = null;
        this.changeDetectorRef.markForCheck();
    }

    public async findLocationByItem(item: string = null): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(FindLocationByItemModalComponent, {
            item,
            orderRef: this.orderRef,
            modalWidth: 1200
        });

        if (response && response.name === "set") {
            response.value.hub = response.value.inventory_conversion;
            this.setLocationFromList(response.value);
        }
    }

    public async locationList(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(AvailableLocationsModalComponent, {
            orderRef: this.orderRef,
            modalWidth: 1200,
            state: this.state,
            partner_id: this.shipment.partner_id
        });

        if (response) {
            if (response.name === "set") {
                this.setLocationFromList(response.value);
            } else if (response.name === "add") {
                this.addLocation("");
            }
        }
    }

    public async addBox(): Promise<any> {
        if (!this.location) {
            return;
        }
        const response: Modal.IResponse = await this.modalService.open(BoxFormComponent, {
            box: {
                warehouse_location_id: this.location.id
            },
            warehouse_order_id: this.warehouseOrderId
        });

        if (response) {
            this.getBoxes();
        }
    }

    public async getBoxes(page: number = 1, per_page: number = null): Promise<any> {
        if (!this.location) {
            return;
        }
        this.spinnerService.show();
        if (per_page === null) {
            per_page = this.userService.data.settings.default_per_page;
        }
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["box", "warehouse_location", "" + this.location.id], {}, {
                page,
                per_page,
                data_structure: "paginated"
            });

        this.spinnerService.hide();

        if (data) {
            this.boxList = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    public async printSticker(box: any): Promise<any> {
        await this.modalService.open(BoxSticker2Component, {
            box,
            orderRef: this.orderRef,
            locationName: this.location.name,
            warehouseName: this.shipment.warehouse.name
        });
    }

    public async move(item: any): Promise<any> {
        await this.modalService.open(InventoryChangeLocationModalComponent, {
            inventory: item
        });

        if (this.viewSwitch.value) {
            this.getBoxes();
        } else {
            this.getInventoriesInLocation();
        }
    }

    public async addTracking(): Promise<any> {
        await this.modalService.open(CourierTransactionFormComponent, {
            action: "add",
            shipmentId: this.shipment.id,
            orderRef: this.orderRef,
            state: this.state,
            orderData: {id: this.orderId},
            type: "inbound",
            partnerSlug: this.shipment.partner.slug
        });

        this.getCourierTransactions();
    }

    public async markDelivered(id: number): Promise<any> {
        await this.modalService.open(CourierTransactionDeliveredFormComponent, {id});

        this.getCourierTransactions();
    }


    /**
     * Initialize step
     * @param data
     * @returns {Promise<any>}
     */
    public async init(data: Wizard.IData): Promise<any> {
        this.state = data.state;
        this.warehouseOrderId = data.warehouseOrderId;
        this.orderRef = data.orderRef;
        this.orderId = data.orderId;
        this.scannerKey = data.scannerKey;
        this.location = null;
        this.shipment = data.shipment;

        const [section, warehouse_slug]: string[] = this.state.section.split("/");
        this.warehouse_slug = warehouse_slug;

        this.changeDetectorRef.markForCheck();

        this.getShipment();
        this.getLocationsByOrder();

        this.getCourierTransactions();

        this.result.emit({
            action: "result",
            value: this.warehouseOrderId
        });

        this.searchInputLabel = "Location";

        this.soundsService.textToSpeech("Please scan location");

        this.viewSwitch.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val: boolean): void => {
            if (val) {
                this.getBoxes();
            } else {
                this.getInventoriesInLocation();
            }
        });

        this.searchInputRef.nativeElement.focus();
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

}
