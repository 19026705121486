import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild} from "@angular/core";
import {Api} from "../../../../../../common/services/api.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {ToastService} from "../../../../../../common/services/toast.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {Table2Component} from "../../../../../../common/components/table2/table.component";
import {AttachmentService} from "../../../../../../common/services/attachment.service";

@Component({
    selector: "section-attachments-list",
    template: `

        <common-confirm></common-confirm>
        <h1 class="container-heading" *ngIf="modal">{{title}}</h1>
        <mat-card>
            <mat-card-content>
                <h3 matSubheader *ngIf="!modal">{{title}}</h3>
                <common-table2 *ngIf="listTable" [settings]="listTable"></common-table2>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachmentsListComponent implements OnInit {

    public listTable: Table.ISettings;

    public modal: Modal.IModal;

    @Input()
    public url: string[];

    @Input()
    public title: string;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        protected attachmentService: AttachmentService,
        protected modalService: ModalService,
        protected toastService: ToastService,
        protected spinnerService: SpinnerService
    ) {
    }

    protected getTableId(): string {
        return "fdj87GFsdf";
    }

    protected getActions(): Table.IAction[] {
        return [
            {
                name: "delete",
                title: "Delete",
                click: (row: any): void => {
                    this.delete(row.id);
                }
            }
        ];
    }

    protected getColumns(): Table.ICol[] {
        return [
            {
                data: "path",
                title: "",
                render: (row: any): string => {
                    return `<a href="${row.path}" target="_blank" class="mat-mini-fab mat-accent">
                                    <mat-icon class="mat-icon material-icons">cloud_download</mat-icon>
                                </a>`;
                },
                sortable: false,
                searchable: false
            },
            {
                data: "name",
                title: "Name"
            },
            {
                data: "size",
                title: "Size"
            },
            {
                data: "content_type",
                title: "Content type"
            },
            {
                data: "category",
                title: "Category"
            },
            {
                data: "created_at",
                title: "Created at"
            },
        ];
    }

    protected getUrl(): Table.ITableApi {
        return {
            url: this.url
        };
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    protected prepareList(): void {
        this.listTable = {
            table_id: this.getTableId(),
            actions: this.getActions(),
            api: this.getUrl(),
            columns: this.getColumns()
        };
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Delete attachment
     * @param id
     */
    public async delete(id: number): Promise<any> {
        if (await this.confirmRef.confirm("Are you sure want to delete this attachment?")) {
            this.spinnerService.show();
            const {message, type}: Api.IResponse =
                await await this.attachmentService.deleteAttachment(this.getUrl().url, id);
            if (type as string === "success") {
                this.toastService.show(message, "success");
                this.tableRef.reload();
            }
            this.spinnerService.hide();
        }
    }

    public ngOnInit(): void {
        if (this.modal && this.modal.params.url) {
            this.url = this.modal.params.url;
        }
        if (this.modal && this.modal.params.title) {
            this.title = this.modal.params.title;
        }
        this.prepareList();
    }

}
