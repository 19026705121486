import {Injectable} from "@angular/core";
import {Api3Service} from "../../../../common/services/api3.service";
import {Api} from "../../../../common/services/api.service";


@Injectable()
export class AdminThreeplService {

    public constructor(private apiService: Api3Service) {
    }

    public getThreepls(query: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Get, "admin/threepls", {}, query);
    }

}
