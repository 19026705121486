import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Router} from "@angular/router";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {Table, TableComponent} from "../../../../../../common/components/table/table.component";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {UserService} from "../../../../../../common/services/user.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {SpinnerService} from "../../../../../../common/services/spinner.service";

@Component({
    selector: "section-model-fields-list",
    templateUrl: "list.component.html",
    styleUrls: [
        "list.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class ModelFieldsListComponent implements Base.IComponent, OnInit {

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild(TableComponent, {static: false})
    public tableRef: TableComponent;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.IOptions;

    public readonly state: Base.IState;

    public models: any;

    public constructor(
        private router: Router, private apiService: ApiService,
        private toastService: ToastService, public userService: UserService,
        private changeDetectorRef: ChangeDetectorRef,
        private spinnerService: SpinnerService
    ) {
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        const actions: Table.Action.ISettings[] = [];

        if (this.userService.validatePermissions("edit_model_fields")) {
            actions.push({
                name: "edit",
                title: "Edit"
            });
        }

        if (this.userService.validatePermissions("delete_model_fields")) {
            actions.push({
                name: "delete",
                title: "Delete"
            });
        }
        this.listTable = {
            actions,
            api: this.apiService.getUrl(["model_field", "all"]),
            columns: [
                {
                    data: "model",
                    title: "Model"
                },
                {
                    data: "type",
                    title: "Type"
                },
                {
                    data: "created_at",
                    title: "Creation date"
                }
            ],
            order: [],
            search: false
        };
        this.changeDetectorRef.markForCheck();
    }

    public add(id: number): void {
        this.router.navigate([
            this.state.section,
            this.state.component,
            "form",
            "id",
            id
        ]);
        this.tableRef.reload();
    }

    /**
     * Handle list row action
     * @param {Table.Action.IResult} action
     * @returns {Promise<any>}
     */
    public async handleListAction(action: Table.Action.IResult): Promise<any> {
        if (action.name === "edit" && action.data.id) {
            this.router.navigate([this.state.section, "model-fields", "edit", "id", action.data.id]);
        }
        if (action.name === "delete" && action.data.id) {
            if (await this.confirmRef.confirm(`Do you want to delete "${action.data.model}?"`)) {
                this.spinnerService.show();
                const {data, message}: Api.IResponse =
                    await this.apiService.request(Api.EMethod.Delete, ["model_field", action.data.id]);
                this.spinnerService.hide();
                if (data) {
                    this.toastService.show(message, "success");
                    this.tableRef.tableRef.draw();
                }
            }
        }
    }

    public ngOnInit(): void {
        this.prepareList();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "model-fields",
            actions: {
                "browse": ["browse_model_fields"]
            }
        };
    }
}
