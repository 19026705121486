import {ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit} from "@angular/core";
import {Modal} from "../../../../section/services/modal.service";
import {Api, ApiService} from "../../../../common/services/api.service";
import {ToastService} from "../../../../common/services/toast.service";
import {SpinnerService} from "../../../../common/services/spinner.service";
import {FormControl} from "@angular/forms";
import {distinctUntilChanged} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: "common-supervisor-select-modal",
    template: `
        <h1 class="container-heading">
            Select partner supervisor
        </h1>
        <mat-card>
            <mat-card-content>
                <mat-radio-group [formControl]="selectedManager">
                    <mat-list>
                        <ng-template ngFor let-manager [ngForOf]="modal.params.managers">
                            <mat-list-item>
                                <mat-radio-button [value]="manager.value">{{manager.name}}</mat-radio-button>
                            </mat-list-item>
                            <mat-divider></mat-divider>
                        </ng-template>
                    </mat-list>
                </mat-radio-group>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SupervisorModalComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    public modal: Modal.IModal;

    public selectedManager: FormControl = new FormControl(null);

    public constructor(
        private apiService: ApiService,
        private toastService: ToastService,
        private spinnerService: SpinnerService
    ) {
    }

    public async selectManager(manager_id: number): Promise<any> {
        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["partner", this.modal.params.partner.id], {
                in_charge_manager_id: manager_id
            });
        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");

            this.modal.response.next({
                name: "reload"
            });
        }
    }

    public ngOnInit(): void {

        this.selectedManager.setValue(this.modal.params.partner.in_charge_manager_id);

        this.selectedManager.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$))
            .subscribe((value: number): void => {
                this.selectManager(value);
            });
    }


    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
