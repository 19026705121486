import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {Api3Service} from "../../../../../../common/services/api3.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Api} from "../../../../../../common/services/api.service";

@Component({
    selector: "common-settings-address-form",
    template: `
        <div [formGroup]="form">
            <div class="flex row align-top space-between">
                <mat-card class="half">
                    <mat-card-title>Address</mat-card-title>
                    <div formGroupName="address" class="row flex align-center">
                        <div class="third">
                            <mat-checkbox formControlName="required">Required</mat-checkbox>
                        </div>
                        <div class="third">
                            <mat-checkbox formControlName="unique">Unique</mat-checkbox>
                        </div>

                        <mat-form-field class="third">
                            <mat-label>Max length</mat-label>
                            <input matInput formControlName="maxLength" type="number">
                        </mat-form-field>

                    </div>
                </mat-card>

                <mat-card class="half">
                    <mat-card-title>Address 2</mat-card-title>
                    <div formGroupName="address2" class="row flex align-center">
                        <div class="third">
                            <mat-checkbox formControlName="required">Required</mat-checkbox>
                        </div>
                        <div class="third">
                            <mat-checkbox formControlName="unique">Unique</mat-checkbox>
                        </div>

                        <mat-form-field class="third">
                            <mat-label>Max length</mat-label>
                            <input matInput formControlName="maxLength" type="number">
                        </mat-form-field>

                    </div>
                </mat-card>

                <mat-card class="half">
                    <mat-card-title>City</mat-card-title>
                    <div formGroupName="city" class="row flex align-center">
                        <div class="third">
                            <mat-checkbox formControlName="required">Required</mat-checkbox>
                        </div>

                        <mat-form-field class="third">
                            <mat-label>Max length</mat-label>
                            <input matInput formControlName="maxLength" type="number">
                        </mat-form-field>

                    </div>
                </mat-card>

                <mat-card class="half">
                    <mat-card-title>Zip</mat-card-title>
                    <div formGroupName="zip" class="row flex align-center">
                        <div class="third">
                            <mat-checkbox formControlName="required">Required</mat-checkbox>
                        </div>

                        <mat-form-field class="third">
                            <mat-label>Max length</mat-label>
                            <input matInput formControlName="maxLength" type="number">
                        </mat-form-field>

                    </div>
                </mat-card>

                <mat-card class="half">
                    <mat-card-title>State</mat-card-title>
                    <div formGroupName="state" class="row flex align-center">
                        <div class="third">
                            <mat-checkbox formControlName="required">Required</mat-checkbox>
                        </div>

                        <mat-form-field class="third">
                            <mat-label>Max length</mat-label>
                            <input matInput formControlName="maxLength" type="number">
                        </mat-form-field>

                    </div>
                </mat-card>

                <mat-card class="half">
                    <mat-card-title>Country</mat-card-title>
                    <div formGroupName="country" class="row flex align-center">
                        <div class="third">
                            <mat-checkbox formControlName="required">Required</mat-checkbox>
                        </div>
                    </div>
                </mat-card>

                <mat-card class="half">
                    <mat-card-title>Lattitude</mat-card-title>
                    <div formGroupName="lat" class="row flex align-center">
                        <div class="third">
                            <mat-checkbox formControlName="required">Required</mat-checkbox>
                        </div>
                    </div>
                </mat-card>

                <mat-card class="half">
                    <mat-card-title>Longtitude</mat-card-title>
                    <div formGroupName="lng" class="row flex align-center">
                        <div class="third">
                            <mat-checkbox formControlName="required">Required</mat-checkbox>
                        </div>
                    </div>
                </mat-card>

                <mat-card class="half">
                    <mat-card-title>Ref</mat-card-title>
                    <div formGroupName="ref" class="row flex align-center">
                        <div class="third">
                            <mat-checkbox formControlName="required">Required</mat-checkbox>
                        </div>
                        <div class="third">
                            <mat-checkbox formControlName="unique">Unique</mat-checkbox>
                        </div>

                        <mat-form-field class="third">
                            <mat-label>Max length</mat-label>
                            <input matInput formControlName="maxLength" type="number">
                        </mat-form-field>

                    </div>
                </mat-card>

                <mat-card class="half">
                    <mat-card-title>Ref 2</mat-card-title>
                    <div formGroupName="ref2" class="row flex align-center">
                        <div class="third">
                            <mat-checkbox formControlName="required">Required</mat-checkbox>
                        </div>
                        <div class="third">
                            <mat-checkbox formControlName="unique">Unique</mat-checkbox>
                        </div>

                        <mat-form-field class="third">
                            <mat-label>Max length</mat-label>
                            <input matInput formControlName="maxLength" type="number">
                        </mat-form-field>

                    </div>
                </mat-card>

                <mat-card class="half">
                    <mat-card-title>Site name</mat-card-title>
                    <div formGroupName="address_name" class="row flex align-center">
                        <div class="third">
                            <mat-checkbox formControlName="required">Required</mat-checkbox>
                        </div>
                        <div class="third">
                            <mat-checkbox formControlName="unique">Unique</mat-checkbox>
                        </div>

                        <mat-form-field class="third">
                            <mat-label>Max length</mat-label>
                            <input matInput formControlName="maxLength" type="number">
                        </mat-form-field>

                    </div>
                </mat-card>

                <mat-card class="half">
                    <mat-card-title>Address type</mat-card-title>
                    <div formGroupName="address_type" class="row flex align-center">
                        <div class="third">
                            <mat-checkbox formControlName="required">Required</mat-checkbox>
                        </div>
                    </div>
                </mat-card>

                <mat-card class="half">
                    <mat-card-title>Out incoterms</mat-card-title>
                    <div formGroupName="out_incoterms" class="row flex align-center">
                        <div class="third">
                            <mat-checkbox formControlName="required">Required</mat-checkbox>
                        </div>
                    </div>
                </mat-card>

                <mat-card class="half">
                    <mat-card-title>In incoterms</mat-card-title>
                    <div formGroupName="in_incoterms" class="row flex align-center">
                        <div class="third">
                            <mat-checkbox formControlName="required">Required</mat-checkbox>
                        </div>
                    </div>
                </mat-card>

                <mat-card class="half">
                    <mat-card-title>Note</mat-card-title>
                    <div formGroupName="note" class="row flex align-center">
                        <div class="third">
                            <mat-checkbox formControlName="required">Required</mat-checkbox>
                        </div>

                        <mat-form-field class="third">
                            <mat-label>Max length</mat-label>
                            <input matInput formControlName="maxLength" type="number">
                        </mat-form-field>

                    </div>
                </mat-card>

                <mat-card class="half">
                    <mat-card-title>Default outbound hub</mat-card-title>
                    <div formGroupName="outbound_hub_id" class="row flex align-center">
                        <div class="third">
                            <mat-checkbox formControlName="required">Required</mat-checkbox>
                        </div>
                    </div>
                </mat-card>

                <mat-card class="half">
                    <mat-card-title>Default inbound hub</mat-card-title>
                    <div formGroupName="inbound_hub_id" class="row flex align-center">
                        <div class="third">
                            <mat-checkbox formControlName="required">Required</mat-checkbox>
                        </div>
                    </div>
                </mat-card>

                <mat-card class="half">
                    <mat-card-title>Default service level</mat-card-title>
                    <div formGroupName="default_service_level_id" class="row flex align-center">
                        <div class="third">
                            <mat-checkbox formControlName="required">Required</mat-checkbox>
                        </div>
                    </div>
                </mat-card>

                <mat-card class="half">
                    <mat-card-title>Default courier service</mat-card-title>
                    <div formGroupName="default_courier_service_id" class="row flex align-center">
                        <div class="third">
                            <mat-checkbox formControlName="required">Required</mat-checkbox>
                        </div>
                    </div>
                </mat-card>

            </div>

            <div>
                <button type="button" mat-raised-button (click)="updateConfig()" color="accent">
                    Save
                </button>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    styleUrls: [
        "./form.scss"
    ]

})
export class SettingsAddressFormComponent implements OnInit, OnDestroy {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input()
    public state: Base.IState;

    public form: FormGroup = new FormGroup({
        address: new FormGroup({
            required: new FormControl({value: true, disabled: true}, [Validators.required]),
            unique: new FormControl(false),
            maxLength: new FormControl(null),
        }),
        address2: new FormGroup({
            required: new FormControl(false),
            unique: new FormControl(false),
            maxLength: new FormControl(null),
        }),
        city: new FormGroup({
            required: new FormControl({value: true, disabled: true}, [Validators.required]),
            maxLength: new FormControl(null),
        }),
        zip: new FormGroup({
            required: new FormControl({value: true, disabled: true}, [Validators.required]),
            maxLength: new FormControl(null),
        }),
        state: new FormGroup({
            required: new FormControl(false),
            maxLength: new FormControl(null),
        }),
        country: new FormGroup({
            required: new FormControl({value: true, disabled: true}, [Validators.required]),

        }),
        lat: new FormGroup({
            required: new FormControl(false),

        }),
        lng: new FormGroup({
            required: new FormControl(false),

        }),
        ref: new FormGroup({
            required: new FormControl(false),
            unique: new FormControl(false),
            maxLength: new FormControl(null),
        }),
        ref2: new FormGroup({
            required: new FormControl(false),
            unique: new FormControl(false),
            maxLength: new FormControl(null),
        }),
        address_name: new FormGroup({
            required: new FormControl(false),
            unique: new FormControl(false),
            maxLength: new FormControl(null),
        }),
        address_type: new FormGroup({
            required: new FormControl({value: true, disabled: true}, [Validators.required]),
        }),
        out_incoterms: new FormGroup({
            required: new FormControl(false),
        }),
        in_incoterms: new FormGroup({
            required: new FormControl(false),
        }),
        note: new FormGroup({
            required: new FormControl(false),
            maxLength: new FormControl(null),
        }),

        outbound_hub_id: new FormGroup({
            required: new FormControl(false),
        }),
        inbound_hub_id: new FormGroup({
            required: new FormControl(false),
        }),
        default_service_level_id: new FormGroup({
            required: new FormControl(false),
        }),
        default_courier_service_id: new FormGroup({
            required: new FormControl(false),
        }),
    });

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private spinnerService: SpinnerService
    ) {
    }

    private async getConfig(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.get(
            `${this.state.section}/forms-validation-rules/address`,
        );
        this.spinnerService.hide();

        if (data) {
            this.form.patchValue(data);
            this.changeDetectorRef.markForCheck();
        }
    }

    public async updateConfig(): Promise<any> {
        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.api3Service.put(
            `${this.state.section}/forms-validation-rules/address`, this.form.getRawValue(),
        );
        this.spinnerService.hide();

        if (code === 200) {
            this.toastService.show(message, "success");
        }
    }

    public async ngOnInit(): Promise<any> {
        this.getConfig();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
