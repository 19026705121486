import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Warehouse} from "../../../../../../common/interfaces/warehouse.interface";
import {Courier} from "../../../../../../common/interfaces/courier.interface";
import {ReplaySubject} from "rxjs";
import {debounceTime, takeUntil} from "rxjs/operators";
import {StorageService} from "../../../../../../common/services/storage.service";
import {AppStateService} from "../../../../../../common/services/app-state.service";

@Component({
    selector: "modal-link-hubs-to-courier-services",
    templateUrl: "link-to-courier-services.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class LinkHubsToCourierServicesModalComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    private favorite_hubs: string[] = [];

    public modal: Modal.IModal;

    public hubs: Warehouse.IHub[] = [];

    public hubs_filtered: ReplaySubject<any> = new ReplaySubject<any>(1);

    public hub_search: FormControl = new FormControl(null);

    public services: { value: any, name: string }[] = [];

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private toastService: ToastService,
        private modalService: ModalService,
        private storageService: StorageService,
        private spinnerService: SpinnerService
    ) {
    }

    private prepareForm(hub: Warehouse.IHub): FormGroup {
        const services: number[] = hub.couriers_services
            ? hub.couriers_services.map((cs: Courier.IService): number => cs.id)
            : [];
        return new FormGroup({
            service_level_id: new FormControl(this.modal.params.service_level_id),
            inventory_conversion_id: new FormControl(hub.id),
            courier_service_id: new FormControl(services)
        });
    }

    private async getHubs(): Promise<any> {
        this.spinnerService.show();
        if (AppStateService.getState().section === "admin") {
            this.apiService.setPartner(this.modal.params.partner_slug);
        }
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["hub"], {}, {
            courier_services: true,
            service_level_id: this.modal.params.service_level_id
        });
        this.spinnerService.hide();
        if (data) {
            this.hubs = data;
            this.hubs_filtered.next(this.sortByFavorite(this.hubs));
            this.changeDetectorRef.markForCheck();
        }
    }

    private async getServiceLevel(): Promise<any> {
        this.spinnerService.show();
        if (AppStateService.getState().section === "admin") {
            this.apiService.setPartner(this.modal.params.partner_slug);
        }

        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["service_level", this.modal.params.service_level_id]);
        this.spinnerService.hide();
        if (data && data.courier_services) {
            this.services = data.courier_services.map((service: Courier.IService): any => {
                return {value: service.id, name: `${service.service_name} (${service.slug})`};
            });
            this.changeDetectorRef.markForCheck();
        }
    }

    private sortByFavorite(hubs: Warehouse.IHub[]): any {

        const sorted_hubs: any = hubs.sort((hub_a: Warehouse.IHub, hub_b: Warehouse.IHub) => {
            const key_a: string = hub_a.customers_inventory_name + "_" + hub_a.customers_sub_inventory;
            const key_b: string = hub_b.customers_inventory_name + "_" + hub_b.customers_sub_inventory;

            if (this.favorite_hubs.includes(key_a) && this.favorite_hubs.includes(key_b)) {
                if (key_a > key_b) {
                    return 1;
                }
                if (key_b > key_a) {
                    return -1;
                }
            } else if (this.favorite_hubs.includes(key_a) && !this.favorite_hubs.includes(key_b)) {
                return -1;
            } else if (!this.favorite_hubs.includes(key_a) && this.favorite_hubs.includes(key_b)) {
                return 1;
            } else if (!this.favorite_hubs.includes(key_a) && !this.favorite_hubs.includes(key_b)) {
                if (key_a > key_b) {
                    return 1;
                }
                if (key_b > key_a) {
                    return -1;
                }
            }
            return 0;

        });

        for (const hub of sorted_hubs) {
            hub.formGroup = this.prepareForm(hub);
        }

        return sorted_hubs;
    }

    public isFavorite(hub: Warehouse.IHub): boolean {
        const key: string = hub.customers_inventory_name + "_" + hub.customers_sub_inventory;

        return this.favorite_hubs.includes(key);
    }

    public toggleFavorite(hub: Warehouse.IHub): void {
        const key: string = hub.customers_inventory_name + "_" + hub.customers_sub_inventory;

        if (this.isFavorite(hub)) {
            const index: number = this.favorite_hubs.indexOf(key);
            this.favorite_hubs.splice(index, 1);
        } else {
            this.favorite_hubs.push(key);
        }
        this.storageService.set("service_level-favorite-hubs", this.favorite_hubs);

        this.hubs = this.sortByFavorite(this.hubs);

        if (this.hub_search.value) {
            const filtered: Warehouse.IHub[] = this.hubs.filter((hub: Warehouse.IHub) => {
                return hub.customers_inventory_name.toLowerCase().includes(this.hub_search.value.toLowerCase());
            });
            this.hubs_filtered.next(filtered);
        } else {
            this.hubs_filtered.next(this.hubs);
        }
    }

    public async submit(formGroup: FormGroup): Promise<any> {
        this.spinnerService.show();
        if (AppStateService.getState().section === "admin") {
            this.apiService.setPartner(this.modal.params.partner_slug);
        }
        const {code, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["hub", "link-courier-services"], formGroup.value);
        if (code === 200) {
            this.toastService.show(message, "success");
            this.getHubs();
        }
        this.spinnerService.hide();
    }


    public ngOnInit(): void {
        this.favorite_hubs = this.storageService.get("service_level-favorite-hubs", []);
        this.getHubs();
        this.getServiceLevel();

        this.hub_search.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(100))
            .subscribe((val: string): void => {
                if (val) {
                    const filtered: Warehouse.IHub[] = this.hubs.filter((hub: Warehouse.IHub) => {
                        return hub.customers_inventory_name.toLowerCase().includes(val.toLowerCase());
                    });
                    this.hubs_filtered.next(this.sortByFavorite(filtered));
                } else {
                    this.hubs_filtered.next(this.sortByFavorite(this.hubs));
                }
                this.changeDetectorRef.markForCheck();
            });

    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
