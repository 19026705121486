import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {GoogleChartsService} from "../../../services/google-chart.service";
import {DrawerService} from "../../../../app/services/drawer.service";
import {debounceTime, takeUntil} from "rxjs/operators";

@Component({
    selector: "common-calendar-chart",
    template: `
        <div class="scroll-x">
            <div class="chart calendar" [class.ready]="readyClass" #chart></div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class CalendarChartComponent implements OnChanges, OnInit, OnDestroy {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input()
    public data: [Date, number][];

    @Input()
    public size: number = 1;

    @Output()
    public init: EventEmitter<boolean> = new EventEmitter();

    @Output()
    public ready: EventEmitter<boolean> = new EventEmitter();

    @ViewChild("chart", {static: true})
    public div: ElementRef;

    public readyClass: boolean = false;

    public constructor(
        private chartsService: GoogleChartsService,
        private changeDetectorRef: ChangeDetectorRef,
        private drawerService: DrawerService
    ) {
    }

    private drawChart(): void {
        const dataTable: any = new this.chartsService.api.visualization.DataTable();
        dataTable.addColumn({type: "date", id: "Date"});
        dataTable.addColumn({type: "number", id: "Count"});
        this.data.map((obj: any): void => {
            if (obj[1]) {
                obj[1] = Number(obj[1]);
            }
        });
        dataTable.addRows(this.data);
        if (this.div.nativeElement) {
            const chart: any = new this.chartsService.api.visualization.Calendar(this.div.nativeElement);

            this.chartsService.api.visualization.events.addListener(chart, "ready", (): void => {
                this.readyClass = true;
                this.ready.emit(true);
                this.changeDetectorRef.markForCheck();
            });

            try {
                chart.draw(dataTable, {
                    height: 180 * this.size,
                    width: 915
                });
            } catch (e) {
                console.error(e);
            }
        }
    }

    public ngOnInit(): void {
        this.init.emit(true);
        this.drawerService.drawerLockState.pipe(takeUntil(this.destroy$), debounceTime(100))
            .subscribe((): void => {
                this.drawChart();
            });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.div && this.data) {
            const callback: any = (): any => this.drawChart();
            this.chartsService.load(callback, ["calendar"]);
        }
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
