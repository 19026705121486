import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, ViewEncapsulation} from "@angular/core";
import {UntypedFormBuilder, FormControl, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";

import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {DragulaService} from "ng2-dragula";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {Modal, ModalService} from "../../../../../../services/modal.service";
import {Warehouse} from "../../../../../../../common/interfaces/warehouse.interface";
import {Order} from "../../../../../../../common/interfaces/order.interface";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../common/interfaces/wizard.interface";
import {takeUntil} from "rxjs/operators";
import {IPagination} from "../../../../../../../common/components/pagination/pagination.component";
import {ParcelFormComponent} from "../../../parcel";
import {HelpersService} from "../../../../../../../common/services/helpers.service";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {UserService} from "../../../../../../../common/services/user.service";


@Component({
    selector: "section-warehouse-order-wizard-select-parcel",
    templateUrl: "select-parcel.component.html",
    styleUrls: [
        "select-parcel.component.scss",
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class WarehouseOrderWizardSelectParcelComponent extends AbstractWizardStepComponent implements OnDestroy {

    private state: Base.IState;

    private boundType: string;

    private order: Order.IOrderData;

    public shipment: Warehouse.IShipment;

    public parcels: IPagination<Warehouse.IParcel>;

    public freeItems: IPagination<Order.IItem>;

    public selectedParcel: FormControl = new FormControl(null, [Validators.required]);

    /**
     * Randomize bag name to avoid duplication when we have
     * two ore more component instances
     */
    public dragulaBagName: string;

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private dragulaService: DragulaService,
        private modalService: ModalService,
        private spinnerService: SpinnerService,
        private userService: UserService
    ) {

        super(changeDetectorRef);

        this.dragulaBagName = "warehouse-parcels-bag-" + HelpersService.randomString();

        if (!dragulaService.find(this.dragulaBagName)) {
            dragulaService.createGroup(this.dragulaBagName, {
                copy: true,
                copyItem: (item: any): any => ({...item})
            });
        }
        dragulaService.drop(this.dragulaBagName).pipe(takeUntil(this.destroy$))
            .subscribe((value: any): void => {
                this.onDrop(value);
            });
    }

    /**
     * Handle drop event
     * @returns {Promise<any>}
     */
    private async onDrop(value: any): Promise<any> {
        const {name, el, target}: any = value;
        if (name !== this.dragulaBagName) {
            return;
        }
        this.dragulaService.find(this.dragulaBagName).drake.cancel(true);
        let itemId: number = null;
        if (el) {
            el.classList.forEach((className: string): void => {
                if (/id-[0-9]+/.test(className)) {
                    itemId = Number(className.replace("id-", ""));
                }
            });
        }
        let targetId: number = null;
        if (target) {
            target.classList.forEach((className: string): void => {
                if (/id-[0-9]+/.test(className)) {
                    targetId = Number(className.replace("id-", ""));
                }
            });
        }

        if (target !== null && itemId) {
            this.spinnerService.show();
            const response: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
                ["parcel"], {
                    order_item_id: itemId,
                    parcel_id: targetId
                });

            this.getShipmentParcels();
            this.getUnlinkedItems();
            this.spinnerService.hide();
            this.toastService.show(response.message, response.type as string);
        }
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Get shipment
     * @returns {Promise<any>}
     */
    private async getShipment(id: number = null): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["shipment", "" + (id || this.shipment.id)]);
        if (data) {
            this.shipment = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get shipment parcels
     * @returns {Promise<any>}
     */
    private async getShipmentParcels(page: number = 1, per_page: number = null): Promise<any> {
        this.spinnerService.show();
        if (per_page === null) {
            per_page = this.userService.data.settings.default_per_page;
        }
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["shipment", "" + this.shipment.id, "parcels"], {}, {
                data_structure: "paginated",
                page,
                per_page,
                type: this.shipment.type
            });
        this.spinnerService.hide();
        if (data) {
            this.parcels = data;
            this.changeDetectorRef.markForCheck();
            for (const parcel of this.parcels.data) {
                this.getParcelItems(parcel, 1);
            }
            if (this.parcels.data.length > 0) {
                this.selectedParcel.setValue(this.parcels.data[0].id);
            }
        }
    }

    /**
     * Get items in parcels
     * @param {Warehouse.IParcel} parcel
     * @param {number} page
     * @param per_page
     * @returns {Promise<any>}
     */
    private async getParcelItems(parcel: Warehouse.IParcel, page: number = 1, per_page: number = null): Promise<any> {
        this.spinnerService.show();
        if (per_page === null) {
            per_page = this.userService.data.settings.default_per_page;
        }
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["parcel", "" + parcel.id, "items"], {}, {
                data_structure: "paginated",
                page,
                per_page
            });
        if (data) {
            parcel.order_item_paginated = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get unlinked items
     * @returns {Promise<any>}
     */
    private async getUnlinkedItems(page: number = 1): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["order", this.order.ref, "items", this.shipment.type], {}, {
                linked_to_parcel: false,
                data_structure: "paginated",
                page
            });
        this.spinnerService.hide();
        if (data) {
            this.freeItems = data;
            this.changeDetectorRef.markForCheck();
        }
    }


    public async editParcel(action: string, parcel?: any): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(ParcelFormComponent, {
            action,
            parcel,
            order: this.order.ref,
            type: this.boundType,
            modalWidth: "660px",
            shipmentId: this.shipment.id
        });

        if (response) {
            this.getShipmentParcels();
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Initialize details step
     * @param data
     * @returns {Promise<any>}
     */
    public async init(data: Wizard.IData): Promise<any> {
        this.state = data.state;

        switch (data.state.params.type) {
            case "replenishment":
                this.boundType = "inbound";
                break;
            case "sales":
                this.boundType = "outbound";
                break;
            default:
                break;
        }
        this.order = data.orderData;
        this.getShipment(data.shipmentId).then((): void => {
            this.getShipmentParcels();
            this.getUnlinkedItems();
        });

        this.selectedParcel.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: string): void => {
            this.result.emit({
                action: "result",
                value: {parcelId: value}
            });
        });

        this.changeDetectorRef.markForCheck();
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}
