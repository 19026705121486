<common-confirm></common-confirm>

<h1 class="container-heading">Stock list {{stockType}}</h1>
<mat-card>
    <mat-card-content>

        <common-table2 *ngIf="listTable" [settings]="listTable" (gotData)="updateTotals($event)"
                       (onSearchChange)="searchParams = $event">

            <div row0>
                <mat-checkbox [formControl]="availableOnly"
                              *ngIf="stockType === 'detailed' || stockType === 'summarized'">
                    Show available only
                </mat-checkbox>
            </div>
            <div row1 class="flex row align-center">
                <common-form-select *ngIf="!subWarehouses && hubList && hubList.length"
                                    [options]="hubList"
                                    [multiple]="true"
                                    [selectAll]="true"
                                    [search]="true"
                                    (onClose)="hubSelect.setValue($event)"
                                    class="margin-right-10"
                                    style="width: 250px"
                                    label="Hubs"></common-form-select>

                <common-form-select *ngIf="subWarehouses && subWarehouses.length"
                                    [options]="subWarehouses"
                                    [multiple]="true"
                                    [selectAll]="true"
                                    [search]="true"
                                    class="margin-right-10"
                                    (onClose)="subWarehouseSelect.setValue($event)"
                                    label="Sub-warehouses"></common-form-select>

                <common-form-select *ngIf="partners && partners.length"
                                    [options]="partners"
                                    [multiple]="true"
                                    [selectAll]="true"
                                    (onClose)="partnerSelect.setValue($event)"
                                    label="Partners"></common-form-select>

                <common-autocomplete *ngIf="!subWarehouses"
                                     [options]="countries" placeholder="Country" [allowFreeInput]="false"
                                     class="margin-right-10" style="width: 250px"
                                     (optionSelected)="countrySelect.setValue($event.value)"
                                     [errorText]="'Select country'" [required]="false"
                                     [image]="{path:'assets/images/flags',ext:'svg'}"
                                     [value]="countrySelect.value"></common-autocomplete>

                <ng-container *ngIf="canAddInventory">
                    <ng-container *ngIf="stockType!=='summarized'">
                        <a *userAllowed="'add_inventories'" mat-raised-button color="primary" (click)="addPart()">
                            Add New
                        </a>
                    </ng-container>
                    <a mat-raised-button color="accent" (click)="upload()" *ngIf="stockType === 'detailed'">
                        Upload CSV
                    </a>
                </ng-container>
            </div>
        </common-table2>
    </mat-card-content>
</mat-card>
