import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {distinctUntilChanged, takeUntil} from "rxjs/operators";
import {Form} from "../../interfaces/form.interface";

@Component({
    selector: "common-form-fields",
    templateUrl: "form-fields.component.html",
    styleUrls: [
        "form-fields.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class CommonFormFieldsComponent implements OnInit, OnDestroy, OnChanges {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input()
    public formSection: string;

    @Input()
    public fields: Form.IField[];

    @Input()
    public values: { [key: string]: any };

    @Output()
    public valueChange: EventEmitter<{ [key: string]: any }> = new EventEmitter<{ [key: string]: any }>();

    @Output()
    public isValid: EventEmitter<boolean> = new EventEmitter<boolean>();

    public formGroup: FormGroup;

    public patternTypeMappings = {
        email: "^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
        phone: "^[0-9]{10,11}$",
        zip: "^[0-9]{3}-[0-9]{4}$"
    };

    public constructor(private changeDetectorRef: ChangeDetectorRef) {
    }

    /**
     * Prepare form
     */
    private prepareForm(fields: Form.IField[], values: { [key: string]: any }): FormGroup {
        const form: FormGroup = new FormGroup({});
        for (const field of fields) {
            if (["filler", "link", "free_text", "label"].indexOf(field.type) === -1) {
                form.addControl(field.name,
                    new FormControl(null, field.required ? [Validators.required] : [])
                );
            }
        }
        return form;
    }

    private getDefaultFieldsValues(fields: Form.IField[], values: { [key: string]: any }): any {
        const default_values: any = {};
        for (const field of fields) {
            if (["group", "filler", "link", "free_text", "label"].indexOf(field.type) === -1) {
                if (field.type === "checkbox") {
                    default_values[field.name] = field.values ?? false;
                } else if (Array.isArray(field.values)) {
                    if (field.values.length && field.required) {
                        if (typeof field.values[0] === "string"
                            || typeof field.values[0] === "number"
                            || field.values[0] === null) {
                            default_values[field.name] = field.values[0];
                        } else {
                            default_values[field.name] = (field.values[0] as any).value;
                        }
                    } else {
                        default_values[field.name] = null;
                    }
                } else if (!field.values) {
                    default_values[field.name] = null;
                } else {
                    default_values[field.name] = field.values;
                }

                if (values && values[field.name] !== undefined) {
                    default_values[field.name] = values[field.name];
                }
            }
        }
        return default_values;
    }

    public check(): void {
        this.changeDetectorRef.markForCheck();
    }

    public isTriggered(index: string, val: string): boolean {
        if (!index) {
            return true;
        }
        index = (Number(index) - 1).toString();
        for (const ind of Object.keys(this.formGroup.controls)) {
            if (ind.endsWith("_" + index)) {
                if (this.formGroup.controls[ind].value === val) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Reset fields
     */
    public reset(): void {
        this.formGroup.reset();

        this.formGroup.patchValue(this.getDefaultFieldsValues(this.fields, this.values));

        this.isValid.emit(this.formGroup.valid);
        this.valueChange.emit(this.formGroup.value);
    }

    public ngOnInit(): void {
        this.formGroup = this.prepareForm(this.fields, this.values);
        this.formGroup.patchValue(this.getDefaultFieldsValues(this.fields, this.values));
        this.formGroup.updateValueAndValidity();
        this.changeDetectorRef.markForCheck();

        this.isValid.emit(this.formGroup.valid);
        this.valueChange.emit(this.formGroup.value);

        this.formGroup.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
            .subscribe((val: any): void => {
                this.isValid.emit(this.formGroup.valid);
                this.valueChange.emit(val);
            });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.formGroup && changes.values
            && JSON.stringify(changes.values.previousValue) !== JSON.stringify(changes.values.currentValue)) {
            this.formGroup.patchValue(this.getDefaultFieldsValues(this.fields, changes.values.currentValue));
            this.formGroup.updateValueAndValidity();
            this.changeDetectorRef.markForCheck();
        }
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }


}
