import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {Modal, ModalService} from "../../../../../../../services/modal.service";
import {ApiService} from "../../../../../../../../common/services/api.service";
import {CommonFormComponent} from "../../../../../../../../common/components/form";
import {HelpersService} from "../../../../../../../../common/services/helpers.service";
import {Table} from "../../../../../../../../common/interfaces/table.interface";
import {AppStateService} from "../../../../../../../../common/services/app-state.service";

@Component({
    selector: "section-box-items-list",
    template: `
        <h1 class="container-heading">Box content</h1>
        <mat-card>
            <mat-card-content>
                <common-table2 *ngIf="listTable" [settings]="listTable"></common-table2>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoxItemsListComponent implements OnInit {

    public listTable: Table.ISettings;

    public modal: Modal.IModal;

    public constructor(
        private apiService: ApiService,
        private modalService: ModalService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        const actions: Table.IAction[] = [];

        this.listTable = {
            table_id: "di7iuks7",
            actions,
            api: {
                url: [
                    AppStateService.getState().section,
                    "boxes",
                    this.modal.params.box.id,
                    this.modal.params.itemsType
                ],
                version: 3
            },
            columns: [
                {
                    data: "item",
                    title: "Item"
                },
                {
                    data: "serial",
                    title: "Serial"
                },
                {
                    data: "configurations",
                    title: "Configurations"
                },
                {
                    data: "batch",
                    title: "Batch"
                },
                {
                    data: "quantity",
                    title: "Quantity"
                }
            ],
            export: {
                file_name: "box_items"
            }
        };
        this.changeDetectorRef.markForCheck();
    }

    public ngOnInit(): void {
        this.prepareList();
    }

}
