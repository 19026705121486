import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {GoogleChartsService} from "../../../services/google-chart.service";
import {DrawerService} from "../../../../app/services/drawer.service";
import {debounceTime, takeUntil} from "rxjs/operators";

@Component({
    selector: "common-combo-chart",
    template: `
        <div class="scroll-x">
            <div class="chart combo" [class.ready]="readyClass" #chart></div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class ComboChartComponent implements OnChanges, OnInit, OnDestroy {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    private colors: string[] = [
        "#40A4D8",
        "#33BEB7",
        "#B2C224",
        "#FECC2F",
        "#F66320",
        "#DB3937",
        "#EE6579",
        "#A364D9",
        "#607D8B",
        "#9E9E9E",
    ];

    @Input()
    public textColor: string = "#ffffff";

    @Input()
    public data: any[];

    @Input()
    public vAxis: string;

    @Input()
    public hAxis: string;

    @Input()
    public options: any;

    @Input()
    public averageColumn: number;

    @Output()
    public init: EventEmitter<boolean> = new EventEmitter();

    @Output()
    public ready: EventEmitter<boolean> = new EventEmitter();

    @ViewChild("chart", {static: true})
    public div: ElementRef;

    public readyClass: boolean = false;

    public constructor(
        private chartsService: GoogleChartsService,
        private changeDetectorRef: ChangeDetectorRef,
        private drawerService: DrawerService
    ) {
    }

    private drawChart(): void {
        const dataTable: any = new this.chartsService.api.visualization.arrayToDataTable(this.data);
        if (this.div.nativeElement) {
            const chart: any = new this.chartsService.api.visualization.ComboChart(this.div.nativeElement);
            this.chartsService.api.visualization.events.addListener(chart, "ready", (): void => {
                this.readyClass = true;
                this.ready.emit(true);
                this.changeDetectorRef.markForCheck();
            });

            try {
                chart.draw(dataTable, {
                    backgroundColor: "transparent",
                    vAxis: {
                        title: this.vAxis,
                        titleTextStyle: {color: this.textColor},
                        textStyle: {color: this.textColor},
                    },
                    hAxis: {
                        title: this.hAxis,
                        titleTextStyle: {color: this.textColor},
                        textStyle: {color: this.textColor},
                    },
                    seriesType: "bars",
                    series: {[this.averageColumn]: {type: "line"}},
                    colors: this.colors
                    , ...this.options
                });
            } catch (e) {
                console.error(e);
            }
        }
    }

    public ngOnInit(): void {
        this.init.emit(true);
        this.drawerService.drawerLockState.pipe(takeUntil(this.destroy$), debounceTime(100))
            .subscribe((): void => {
                this.drawChart();
            });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.div && this.data) {
            setTimeout((): void => {
                this.chartsService.load((): void => this.drawChart());
            }, 1000);

        }
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
