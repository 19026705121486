import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {Api} from "../../../common/services/api.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UserService} from "../../../common/services/user.service";
import {SpinnerService} from "../../../common/services/spinner.service";
import {ToastService} from "../../../common/services/toast.service";
import {Router} from "@angular/router";

@Component({
    selector: "app-change-password",
    template: `
        <mat-card class="mat-elevation-z2">
            <img src="assets/images/logo.png" class="logo">

            <h2>Change password</h2>

            <form [formGroup]="form" (submit)="submit()">
                <div class="group flex">
                    <mat-form-field class="full">
                        <mat-label>Password</mat-label>
                        <input matInput formControlName="password" type="password"
                        >
                    </mat-form-field>

                    <mat-form-field class="full">
                        <mat-label>Password confirmation</mat-label>
<input matInput formControlName="password_confirmation" type="password"
                               >
                    </mat-form-field>
                </div>

                <div class="actions">
                    <button mat-raised-button color="primary" type="submit">
                        Submit
                    </button>
                </div>
            </form>
        </mat-card>
    `,
    styleUrls: [
        "change-password.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class ChangePasswordComponent implements OnInit {

    public form: FormGroup = new FormGroup({
        password: new FormControl(null, [Validators.required]),
        password_confirmation: new FormControl(null, [Validators.required])
    });

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private userService: UserService,
        private spinnerService: SpinnerService,
        private toastService: ToastService,
        private router: Router,
        private title: Title
    ) {
    }

    public async submit(): Promise<any> {
        this.spinnerService.show();
        const {message, code}: Api.IResponse = await this.userService.updatePassword(this.form.value);
        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.router.navigate([
                "entrance"
            ]);
        }
    }

    public ngOnInit(): void {
        this.title.setTitle("Logivice / Change password");
    }
}
