import {ChangeDetectionStrategy, Component, ViewEncapsulation} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {FormControl, Validators} from "@angular/forms";
import {Modal} from "../../../section/services/modal.service";

@Component({
    selector: "common-prompt-modal",
    templateUrl: "prompt.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class PromptModalComponent {

    public modal: Modal.IModal;

    public promptControl: FormControl = new FormControl(null, [Validators.required]);

    public constructor(public matDialog: MatDialog) {
    }

}
