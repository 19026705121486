import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Router} from "@angular/router";
import {ModalService} from "../../../../../services/modal.service";
import {AbstractControl, UntypedFormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {COMMA, ENTER, SEMICOLON} from "@angular/cdk/keycodes";
import {MatChipInputEvent} from "@angular/material/chips";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {ImageUploadService} from "../../../../../../common/services/image-upload.service";

@Component({
    selector: "section-integrations-form",
    templateUrl: "form.component.html",
    styleUrls: [
        "form.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class IntegrationsFormComponent implements Base.IComponent, OnInit {

    public readonly state: Base.IState;

    public formGroup: FormGroup = new FormGroup({
        name: new FormControl(null, [Validators.required]),
        description: new FormControl(null, [Validators.required]),
        instructions: new FormControl(null),
        icon: new FormControl(null),
        internal: new FormControl(null),
        properties: new UntypedFormArray([])
    });

    public fieldTypes: { [key: string]: any } = {
        input: {
            options: true,
            delete: true,
            type: true,
            required: true,
        },
        select: {
            options: true,
            delete: true,
            type: true,
            required: true,
            multiple: true
        },
        checkbox: {
            options: false,
            delete: true,
            required: true,
        },
        radio: {
            options: true,
            delete: true,
            type: true,
            required: true,
        },
        text: {
            options: true,
            delete: true,
            type: true,
            required: true,
        },
        search: {
            delete: true,
            type: true,
            required: false,
            multiple: true,
            url: true
        },
        file: {
            options: false,
            delete: true,
            type: true,
            required: true,
        },
        barcode: {
            options: false,
            delete: true,
            type: true,
            required: true,
            multiple: true
        },
        signature: {
            options: false,
            delete: true,
            type: true,
            required: true,
        },
        status: {
            options: true,
            delete: true,
            type: false,
            required: true,
        },
        filler: {
            options: false,
            delete: true,
            type: true,
            required: false,
        },
        date: {
            options: false,
            delete: true,
            type: true,
            required: true,
        },
        date_range: {
            options: false,
            delete: true,
            type: true,
            required: true,
        },
        link: {
            options: false,
            delete: true,
            type: true,
            required: false,
            url: true
        },
        free_text: {
            options: false,
            delete: true,
            type: true,
            description: true
        },
        label: {
            options: false,
            delete: true,
            type: true,
            required: true,
        }
    };

    public fieldSizes: string[] = ["full", "half", "third"];
    public separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON];

    public editorInitialValue: string = null;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private toastService: ToastService,
        private router: Router,
        private modalService: ModalService,
        private imageUploadService: ImageUploadService,
        private spinnerService: SpinnerService
    ) {
    }

    /**
     * Prepare form
     * @return {Promise<any>}
     */
    private async prepareForm(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["integration", this.state.params.id]);
        this.spinnerService.hide();
        if (data) {
            this.formGroup.get("name").setValue(data.name);
            this.formGroup.get("description").setValue(data.description);
            this.formGroup.get("icon").setValue(data.icon);
            this.formGroup.get("internal").setValue(data.internal);
            this.formGroup.get("instructions").setValue(data.instructions);

            this.editorInitialValue = data.instructions;

            const properties: any = data.properties;
            if (properties) {
                for (const prop of properties) {
                    this.addField(prop.label, prop.type, prop.values,
                        prop.required, prop.multiple, prop.size, prop.url);
                }
            }
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Make names from labels
     */
    private writeNames(): void {
        for (const index of Object.keys(this.formGroup.value.properties)) {
            const label: string = (this.formGroup.get("properties") as UntypedFormArray)
                .controls[index].value.label;
            (this.formGroup.get("properties") as UntypedFormArray).controls[index]
                .get("name").setValue(this.labelToName(label, Number(index)));
        }
    }

    /**
     * Convert string to snake_case
     * @param {string} label
     * @param {number} index
     * @param {boolean} group
     * @param {boolean} sign
     * @return {string}
     */
    private labelToName(label: string, index: number, group?: boolean, sign?: boolean): string {
        return label ? (label.toLowerCase()
                .replace(/ /g, "_")
                .replace(/[^a-z0-9_]/g, "")
            + (sign ? "sign_" : "")
            + "_" + index + (group ? "_g" : "")) : null;
    }

    /**
     * Add new field
     * @param {string} label
     * @param {string} type
     * @param {string[]} values
     * @param {boolean} required
     * @param multiple
     * @param {string} size
     * @param url
     * @param description
     */
    public addField(
        label: string = null,
        type: string = "input",
        values: string[] = [],
        required: boolean = false,
        multiple: boolean = false,
        size: string = "full",
        url: string = null,
        description: string = null
    ): void {
        (this.formGroup.controls.properties as UntypedFormArray).push(
            new FormGroup({
                label: new FormControl(label, [Validators.required]),
                name: new FormControl(this.labelToName(label,
                    (this.formGroup.controls.properties as UntypedFormArray).length, false,
                    type === "signature")),
                type: new FormControl(type, [Validators.required]),
                values: new FormControl(values),
                required: new FormControl(required),
                multiple: new FormControl(multiple),
                size: new FormControl(size, [Validators.required]),
                url: new FormControl(url),
                description: new FormControl(description)
            })
        );
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Remove field
     * @param {number} index
     */
    public removeField(index: number): void {
        (this.formGroup.controls.properties as UntypedFormArray).removeAt(index);
        this.changeDetectorRef.markForCheck();
    }

    /**
     * move field up
     * @param {number} index
     */
    public moveUp(index: number): void {
        const field: AbstractControl = (this.formGroup.controls.properties as UntypedFormArray)
            .controls[index];
        (this.formGroup.controls.properties as UntypedFormArray).removeAt(index);
        (this.formGroup.controls.properties as UntypedFormArray).insert(index - 1, field);
    }

    /**
     * Move field down
     * @param {number} index
     */
    public moveDown(index: number): void {
        const field: AbstractControl = (this.formGroup.controls.properties as UntypedFormArray)
            .controls[index];
        (this.formGroup.controls.properties as UntypedFormArray).removeAt(index);
        (this.formGroup.controls.properties as UntypedFormArray).insert(index + 1, field);
    }

    public moveTo(index: number, to: number | string): void {
        const field: AbstractControl = (this.formGroup.controls.properties as UntypedFormArray).controls[index];
        (this.formGroup.controls.properties as UntypedFormArray).removeAt(index);
        (this.formGroup.controls.properties as UntypedFormArray).insert(Number(to), field);
    }

    /**
     *
     * Add field value
     * @param {MatChipInputEvent} event
     * @param {number} index
     */
    public addValue(event: MatChipInputEvent, index: number): void {
        const input: HTMLInputElement = event.input;
        const value: string = event.value;
        if ((value || "").trim()) {
            const val: string[] = ((this.formGroup.controls.properties as UntypedFormArray)
                .controls[index] as FormGroup)
                .get("values").value;

            val.push(value.trim().substr(0, 30));

            ((this.formGroup.controls.properties as UntypedFormArray)
                .controls[index] as FormGroup)
                .get("values").setValue(val);
        }
        if (input) {
            input.value = "";
        }
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Remove field value
     * @param value
     * @param {number} index
     */
    public removeValue(value: any, index: number): void {
        const valueIndex: number = ((this.formGroup.controls.properties as UntypedFormArray)
            .controls[index] as FormGroup)
            .get("values").value.indexOf(value);

        if (index >= 0) {
            const val: string[] = ((this.formGroup.controls.properties as UntypedFormArray)
                .controls[index] as FormGroup)
                .get("values").value;

            val.splice(valueIndex, 1);

            ((this.formGroup.controls.properties as UntypedFormArray)
                .controls[index] as FormGroup).get("values").setValue(val);
        }

        this.changeDetectorRef.markForCheck();
    }

    /**
     * Submit form
     * @return {Promise<any>}
     */
    public async submit(): Promise<any> {
        this.spinnerService.show();

        this.writeNames();

        const response: Api.IResponse = await this.apiService.request(
            this.state.action === "add" ? Api.EMethod.Post : Api.EMethod.Put,
            this.state.action === "add" ? ["integration"] : ["integration", this.state.params.id],
            this.formGroup.value);

        if (response) {
            this.toastService.show(response.message, response.type as string);
            if (response.data && response.data.id) {
                this.router.navigate([
                    this.state.section,
                    "integrations",
                    "view",
                    "id",
                    response.data.id
                ]);
            }
        }
        this.spinnerService.hide();
    }


    public async uploadAvatar(): Promise<any> {
        const image_url: string = await this.imageUploadService.uploadAvatar();

        if (image_url) {
            this.formGroup.get("icon").setValue(image_url);
            this.changeDetectorRef.markForCheck();
        }
    }

    public ngOnInit(): void {
        if (this.state.action === "edit") {
            this.prepareForm();
        }
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "integrations",
            actions: {
                "add": ["add_integrations"],
                "edit": ["edit_integrations"]
            }
        };
    }
}
