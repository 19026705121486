import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {ReplaySubject} from "rxjs";
import {takeUntil} from "rxjs";
import {StorageService} from "../../../../../../common/services/storage.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {UserService} from "../../../../../../common/services/user.service";
import {Router} from "@angular/router";
import * as moment from "moment";
import {HttpClient} from "@angular/common/http";
import {FileUploadComponent} from "../../../../../../common/components/file-upload/file-upload.component";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {AppStateService} from "../../../../../../common/services/app-state.service";
import {RequestKindFormComponent} from "../../requests";


@Component({
    selector: "section-followup-form",
    templateUrl: "form.component.html",
    styleUrls: [
        "form.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class FollowupFormComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    /**
     * Form group / group with controls
     * @type {FormGroup}
     */
    public formGroup: FormGroup = new FormGroup({
        title: new FormControl(null, [Validators.required]),
        description: new FormControl(null),
        assignee_id: new FormControl(null),
        team_id: new FormControl(null, [Validators.required]),
        inform_team: new FormControl(false),
        request_kind_id: new FormControl(null, [Validators.required]),
        order_remark_type_id: new FormControl(4, [Validators.required]),
        priority: new FormControl("Low", [Validators.required]),
        status: new FormControl("New"),
        url: new FormControl(window.location.pathname),
        order_id: new FormControl(null),
        timeline_from: new FormControl(null),
        timeline_to: new FormControl(null, [Validators.required]),
        file_url: new FormControl(null),
        countries: new FormControl([])
    });

    public state: Base.IState;

    public modal: Modal.IModal;

    public action: string = "add";

    public users: { name: string, value: any }[] = [];

    public teams: { name: string, value: any }[] = [];

    public remarkTypes: { name: string, value: any }[] = [];

    public selectUserSearch: FormControl = new FormControl(null);

    public usersFiltered: ReplaySubject<any> = new ReplaySubject<any>(1);

    public kinds: { name: string, value: any }[] = [];

    public showOrderLink: boolean = false;

    public minimizeButton: boolean = false;

    public minFromDate: string = moment().format("YYYY-MM-DD");
    public minTODate: string = moment().add(1, "hour").format("YYYY-MM-DD HH:mm");
    public uploadedFile: { name: string, path: string }[] = [];
    public editorInstance: any;

    public editorInitialValue: string = null;


    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private toastService: ToastService,
        private modalService: ModalService,
        private storageService: StorageService,
        private userService: UserService,
        private router: Router,
        private http: HttpClient,
        private spinnerService: SpinnerService
    ) {
    }

    /**
     * Prepare form data
     */
    private prepareData(): void {

        if (this.modal && this.modal.params) {
            this.formGroup.patchValue(this.modal.params);
            this.editorInitialValue = this.modal.params.description;

            if (this.modal.params.remark_id) {
                this.formGroup.removeControl("description");
                this.formGroup.updateValueAndValidity();
                this.formGroup.addControl("order_remark_id", new FormControl(this.modal.params.remark_id));
            }
        }

        if (this.state.component === "orders" && this.state.action === "view") {
            this.showOrderLink = true;
            this.formGroup.get("order_id").setValue(this.state.params.id);
        }

        if (this.storageService.get("followUpFormMinimized")) {
            this.formGroup.patchValue(this.storageService.get("followUpFormMinimized"));
            this.editorInitialValue = this.formGroup.value.description;
        }
    }


    /**
     * Get users list
     * @returns {Promise<any>}
     */
    private async getUsers(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["user", "list"], {}, {data_structure: "select"});
        if (data) {
            this.users = data;
            this.usersFiltered.next(this.users);
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get teams list
     * @returns {Promise<any>}
     */
    private async getTeams(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["team"], {}, {
                data_structure: "select",
                category: "followup"
            });
        if (data) {
            this.teams = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get list of kinds
     * @returns {Promise<any>}
     */
    private async getKinds(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["request", "kind"], {}, {
                data_structure: "select",
                category: "followup"
            });
        if (data) {
            this.kinds = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get remark types
     * @returns {Promise<any>}
     */
    private async getRemarkTypes(): Promise<any> {
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["remark", "order", "types"]);
        if (data) {
            this.remarkTypes = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    public updateForm(): void {
        this.formGroup.get("description").setValue(this.editorInstance.root.innerHTML);
        this.formGroup.updateValueAndValidity();
    }

    public async uploadFile(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(FileUploadComponent, {
            url: ["user", "file"],
            accept: [
                "gif", "png", "jpg", "jpeg", "tiff", "doc",
                "docx", "xls", "xlsx", "csv", "ppt", "pptx", "msg", "txt", "pdf", "xlsb"
            ]
        });
        if (response && response.value && response.value.data && response.value.data.path) {
            const uploaded: any = {name: response.value.data.name, url: response.value.data.path};
            this.uploadedFile.push(uploaded);
            this.changeDetectorRef.markForCheck();
        }
    }

    public deleteFile(index: number): void {
        this.uploadedFile.splice(index, 1);
    }

    /**
     * Assign to current user
     */
    public assignToMe(): void {
        const id: number = this.userService.data.id;
        this.formGroup.get("assignee_id").setValue(id);
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Submit form
     */
    public async submit(): Promise<any> {
        this.spinnerService.show();

        const body: any = {...this.formGroup.value};

        body.files = this.uploadedFile;

        if (this.formGroup.value.timeline_from) {
            body.timeline_from = this.formGroup.value.timeline_from.format("YYYY-MM-DD");
        }
        if (this.formGroup.value.timeline_to) {
            body.timeline_to = this.formGroup.value.timeline_to.format("YYYY-MM-DD HH:mm:ss");
        }

        body.type = "followup";

        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["request"], body);
        if (response && response.type as string === "success") {
            this.toastService.show(response.message, response.type as string);
            if (this.modal) {
                this.modal.response.emit({
                    name: "submit",
                    value: response.data
                });
            } else {
                this.router.navigate([
                    this.state.section,
                    this.state.component
                ]);
            }
        }
        this.spinnerService.hide();
    }

    /**
     * Close modal
     */
    public close(): void {
        this.modal.response.emit();
    }

    public minimizeFollowUp(): void {
        this.minimizeButton = true;
        this.storageService.set("followUpFormMinimized", this.formGroup.value);
        this.modal.response.emit();
    }

    public ngOnInit(): void {
        if (!this.state) {
            this.state = AppStateService.getState();
        }

        this.getTeams();
        this.getKinds();

        this.selectUserSearch.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value: string): void => {
                if (value) {
                    this.usersFiltered.next(
                        this.users.filter((user: { name: string }): boolean =>
                            user.name.toLowerCase().indexOf(value) > -1)
                    );
                } else {
                    this.usersFiltered.next(this.users);
                }
            });

        this.formGroup.get("request_kind_id").valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe(async (value: string | number): Promise<any> => {
                if (value === "add_kind") {
                    const response: Modal.IResponse = await this.modalService.open(RequestKindFormComponent,
                        {
                            category: "followup"
                        });
                    if (response && response.value) {
                        this.getKinds();
                        this.formGroup.get("request_kind_id").setValue(response.value);
                    } else {
                        this.formGroup.get("request_kind_id").setValue(null);
                    }
                }
            });


        this.prepareData();
    }

    public ngOnDestroy(): void {
        if (!this.minimizeButton) {
            this.storageService.remove("followUpFormMinimized");
        }
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
