<form [formGroup]="formGroup" class="padding-0">
    <div class="group flex">

        <mat-form-field class="quarter">
            <mat-label>Repeat</mat-label>
            <mat-select formControlName="repeat" required>
                <mat-option value="once">Once</mat-option>
                <mat-option value="yearly">Yearly</mat-option>
                <mat-option value="monthly">Monthly</mat-option>
                <mat-option value="weekly">Weekly</mat-option>
                <mat-option value="daily">Daily</mat-option>
                <mat-option value="hourly">Hourly</mat-option>
            </mat-select>
        </mat-form-field>

        <div class="full">
            <b>Execute on:</b>
        </div>

        <mat-form-field class="quarter" *ngIf="required[formGroup.get('repeat').value].includes('year')">
            <mat-label>Year</mat-label>
            <mat-select formControlName="year" required>
                <mat-option *ngFor="let year of options.year" [value]="year">
                    {{year}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="quarter" *ngIf="required[formGroup.get('repeat').value].includes('month')">
            <mat-label>Month</mat-label>
            <mat-select formControlName="month" required>
                <mat-option *ngFor="let month of options.month; let i = index" [value]="i+1">
                    {{month}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="quarter" *ngIf="required[formGroup.get('repeat').value].includes('date')">
            <mat-label>Date</mat-label>
            <mat-select formControlName="date" required>
                <mat-option *ngFor="let date of options.date" [value]="date">
                    {{date | strPad:'start':2:'0'}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="quarter" *ngIf="required[formGroup.get('repeat').value].includes('day')">
            <mat-label>Day</mat-label>
            <mat-select formControlName="day" required>
                <mat-option *ngFor="let day of options.day; let i = index" [value]="i">
                    {{day}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div *ngIf="['once'].includes(formGroup.get('repeat').value)" class="quarter filler"></div>

        <mat-form-field class="quarter" *ngIf="required[formGroup.get('repeat').value].includes('hour')">
            <mat-label>Hour</mat-label>
            <mat-select formControlName="hour" required>
                <mat-option *ngFor="let hour of options.hour" [value]="hour">
                    {{hour | strPad:'start':2:'0'}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="quarter" *ngIf="required[formGroup.get('repeat').value].includes('min')">
            <mat-label>Minute</mat-label>
            <mat-select formControlName="min" required>
                <mat-option *ngFor="let min of options.min" [value]="min">
                    {{min | strPad:'start':2:'0'}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div *ngIf="['monthly', 'weekly', 'hourly'].includes(formGroup.get('repeat').value)"
             class="quarter filler"></div>
        <div *ngIf="['daily','once', 'hourly'].includes(formGroup.get('repeat').value)" class="half filler"></div>
    </div>

    <ng-container *ngIf="type === 'App\\Report'">
        <div class="group flex">
            <div class="third">
                <mat-checkbox [formControl]="assignTeam">Assign to a team</mat-checkbox>
            </div>
            <div class="third2">
                <mat-form-field class="full" *ngIf="assignTeam.value">
                    <mat-label>Team</mat-label>
                    <mat-select formControlName="team_id" [required]="assignTeam.value">
                        <mat-option *ngFor="let team of teams" [value]="team.id">
                            {{team.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </ng-container>

    <div class="actions">
        <button type="button" [disabled]="!formGroup.valid || !params" (click)="submit()"
                class="mat-mdc-raised-button mdc-button mat-primary main">
            Submit
        </button>
    </div>
</form>
