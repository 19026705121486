<form *ngIf="formGroup" [formGroup]="formGroup" (submit)="submit()">

    <div class="group flex">

        <mat-form-field class="full">
            <mat-label>Title</mat-label>
            <input matInput formControlName="title" required>
        </mat-form-field>

        <mat-form-field class="half">
            <mat-label>Category</mat-label>
<mat-select formControlName="request_kind_id"  required>
                <mat-option *ngFor="let kind of kinds" [value]="kind.value">
                    {{kind.name}}
                </mat-option>
            </mat-select>
            <span matPrefix><mat-icon>ballot</mat-icon>&nbsp;</span>
        </mat-form-field>

        <mat-form-field class="half">
            <mat-label>Priority</mat-label>
            <mat-select formControlName="priority"  required>
                <mat-option value="Low">Low</mat-option>
                <mat-option value="Normal">Normal</mat-option>
                <mat-option value="High">High</mat-option>
                <mat-option value="Critical">Critical</mat-option>
            </mat-select>
            <span matPrefix><mat-icon>low_priority</mat-icon>&nbsp;</span>
        </mat-form-field>

        <div class="half" *ngIf="showOrderLink">
            <mat-checkbox [checked]="true" [disabled]="true">Link to this order</mat-checkbox>
        </div>
        <div class="full" *ngIf="!showOrderLink">
            <common-form-search
                    url="/order"
                    [value]="formGroup.value.order_id"
                    label="Link order"
                    (valueChange)="formGroup.get('order_id').setValue($event)"
            ></common-form-search>
        </div>

        <mat-form-field class="half">
            <mat-label>Participants</mat-label>
            <mat-select formControlName="participants" [multiple]="true">
                <mat-option>
                    <ngx-mat-select-search [formControl]="selectParticipantSearch" [placeholderLabel]="'Search'"
                                           [clearSearchInput]="true"
                                           [noEntriesFoundLabel]="'No items found'">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option [disabled]="true" [selected]="true" [value]="currentUser.id">
                    {{ currentUser.name }} - {{ currentUser.email }}
                </mat-option>
                <mat-option *ngFor="let user of participantsFiltered" [value]="user.value">
                    {{user.name}}
                </mat-option>
            </mat-select>
            <span matPrefix><mat-icon>person</mat-icon>&nbsp;</span>
        </mat-form-field>

        <div class="full">
            <p><b>Description *</b></p>
            <common-form-editor label="Enter description here..."
                                [value]="editorInitialValue"
                                (valueChange)="formGroup.get('description').setValue($event)"
                                [minLength]="15"
                                (minLengthValid)="descriptionValid=$event"
            ></common-form-editor>
        </div>
        <small class="minNumberTextMessage">Min number of characters is 15</small>

        <div class="full padding-top-20" *ngIf="showExpectedBehaviour">
            <p><b>Expected behaviour *</b></p>
            <common-form-editor label="Describe expected behaviour here..."
                                [value]="expectedBehaviourValue"
                                (valueChange)="formGroup.get('expectedBehaviour').setValue($event)"
            ></common-form-editor>
        </div>

        <div class="full padding-top-20">
            <button type="button" mat-raised-button class="full margin-bottom-10" (click)="uploadFile()">
                Upload a file
            </button>
        </div>

        <div class="full">
            <ng-template ngFor let-file let-index="index" [ngForOf]="uploadedFile">
                <div class="attachment"
                     [matTooltip]="file.name"
                     [ngStyle]="{ 'background-image': (file.url.includes('.jpeg') ? 'url('+file.url+')' : '/assets/images/logo-semi-transparent.png') }">
                    <mat-icon class="delete" matTooltip="Delete" (click)="deleteFile(index)" color="warn">
                        delete
                    </mat-icon>
                    <div class="title">
                        {{file.name}}
                    </div>
                </div>
            </ng-template>
        </div>
    </div>

    <div class="actions flex row space-between">
        <button mat-raised-button color="primary" type="submit" class="main" [disabled]="!validateForm()">
            Submit
        </button>
        &nbsp;
        &nbsp;
        <div>
            <button mat-button type="button" (click)="close()">Cancel</button>
            <button mat-button type="button" (click)="minimize()">Minimize</button>
        </div>
    </div>

</form>