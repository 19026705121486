import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {AbstractControl, UntypedFormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Router} from "@angular/router";
import {COMMA, ENTER, SEMICOLON} from "@angular/cdk/keycodes";
import {MatChipInputEvent} from "@angular/material/chips";
import {ModalService} from "../../../../../services/modal.service";
import {CommonFormComponent} from "../../../../../../common/components/form";
import {Form} from "../../../../../../common/interfaces/form.interface";
import {SpinnerService} from "../../../../../../common/services/spinner.service";

@Component({
    selector: "section-model-fields-view",
    templateUrl: "view.component.html",
    styleUrls: [
        "view.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class ModelFieldsViewComponent implements Base.IComponent, OnInit {

    public readonly state: Base.IState;

    public fieldTypes: { [key: string]: any } = Form.FIELD_TYPES;

    public fieldSizes: string[] = ["full", "half", "third"];

    public formGroup: FormGroup = new FormGroup({
        model: new FormControl(null, [Validators.required]),
        type: new FormControl(null, [Validators.required]),
        fields: new UntypedFormArray([])
    });

    public separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON];

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private title: Title,
        private apiService: ApiService,
        private toastService: ToastService,
        private router: Router,
        private modalService: ModalService,
        private spinnerService: SpinnerService
    ) {
    }

    /**
     * Prepare form
     * @return {Promise<any>}
     */
    private async prepareForm(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["model_field", "view", this.state.params.id]);

        if (data) {
            this.formGroup.get("model").setValue(data.model);
            this.formGroup.get("type").setValue(data.type);

            const fields: any = data.fields;

            for (const field of fields) {
                this.addField(field.label, field.type, field.name, field.values,
                    field.required, field.multiple, field.size, field.url);
            }
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Make names from labels
     */
    private writeNames(): void {
        for (const index of Object.keys(this.formGroup.value.fields)) {
            const label: string = (this.formGroup.get("fields") as UntypedFormArray).controls[index].value.label;
            if ((this.formGroup.get("fields") as UntypedFormArray).controls[index]
                .get("name").value === null) {
                (this.formGroup.get("fields") as UntypedFormArray).controls[index]
                    .get("name").setValue(this.labelToName(label));
            }
        }
    }

    /**
     * Convert string to snake_case
     * @param {string} label
     * @return {string}
     */
    private labelToName(label: string): string {
        return label ? (label.toLowerCase()
            .replace(/ /g, "_")
            .replace(/[^a-z0-9_]/g, "")) : null;
    }

    /**
     * Add new field
     * @param {string} label
     * @param {string} type
     * @param {string} name
     * @param {string[]} values
     * @param {boolean} required
     * @param multiple
     * @param {string} size
     * @param {string} description
     * @param url
     */
    public addField(
        label: string = null,
        type: string = "input",
        name: string = null,
        values: string[] = [],
        required: boolean = false,
        multiple: boolean = false,
        size: string = "full",
        url: string = null,
        description: string = null
    ): void {
        (this.formGroup.controls.fields as UntypedFormArray).push(
            new FormGroup({
                label: new FormControl(label, [Validators.required]),
                name: new FormControl(name || this.labelToName(label)),
                type: new FormControl(type, [Validators.required]),
                values: new FormControl(values),
                required: new FormControl(required),
                multiple: new FormControl(multiple),
                size: new FormControl(size, [Validators.required]),
                url: new FormControl(url),
                description: new FormControl(description)
            })
        );
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Remove field
     * @param {number} index
     */
    public removeField(index: number): void {
        (this.formGroup.controls.fields as UntypedFormArray).removeAt(index);
        this.changeDetectorRef.markForCheck();
    }

    /**
     * move field up
     * @param {number} index
     */
    public moveUp(index: number): void {
        const field: AbstractControl = (this.formGroup.controls.fields as UntypedFormArray).controls[index];
        (this.formGroup.controls.fields as UntypedFormArray).removeAt(index);
        (this.formGroup.controls.fields as UntypedFormArray).insert(index - 1, field);
    }

    public moveTo(index: number, to: number): void {
        const field: AbstractControl = (this.formGroup.controls.fields as UntypedFormArray).controls[index];
        (this.formGroup.controls.fields as UntypedFormArray).removeAt(index);
        (this.formGroup.controls.fields as UntypedFormArray).insert(to, field);
    }

    /**
     * Move field down
     * @param {number} index
     */
    public moveDown(index: number): void {
        const field: AbstractControl = (this.formGroup.controls.fields as UntypedFormArray).controls[index];
        (this.formGroup.controls.fields as UntypedFormArray).removeAt(index);
        (this.formGroup.controls.fields as UntypedFormArray).insert(index + 1, field);
    }

    /**
     *
     * Add field value
     * @param {MatChipInputEvent} event
     * @param {number} index
     */
    public addValue(event: MatChipInputEvent, index: number): void {
        const input: HTMLInputElement = event.input;
        const value: string = event.value;
        if ((value || "").trim()) {
            const val: string[] = ((this.formGroup.controls.fields as UntypedFormArray)
                .controls[index] as FormGroup)
                .get("values").value;

            val.push(value.trim().substr(0, 30));

            ((this.formGroup.controls.fields as UntypedFormArray).controls[index] as FormGroup)
                .get("values").setValue(val);
        }
        if (input) {
            input.value = "";
        }
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Remove field value
     * @param value
     * @param {number} index
     */
    public removeValue(value: any, index: number): void {
        const valueIndex: number = ((this.formGroup.controls.fields as UntypedFormArray)
            .controls[index] as FormGroup)
            .get("values").value.indexOf(value);

        if (index >= 0) {
            const val: string[] = ((this.formGroup.controls.fields as UntypedFormArray)
                .controls[index] as FormGroup)
                .get("values").value;

            val.splice(valueIndex, 1);

            ((this.formGroup.controls.fields as UntypedFormArray)
                .controls[index] as FormGroup).get("values").setValue(val);
        }

        this.changeDetectorRef.markForCheck();
    }

    /**
     * Submit form
     * @return {Promise<any>}
     */
    public async submit(): Promise<any> {
        this.spinnerService.show();

        this.writeNames();

        const response: Api.IResponse = await this.apiService.request(
            this.state.action === "edit" ? Api.EMethod.Put : Api.EMethod.Post,
            this.state.action === "edit" ? ["model_field", this.state.params.id] : ["model_field"],
            this.formGroup.value);

        if (response) {
            this.toastService.show(response.message, response.type as string);
            if (response.data && response.data.id) {
                this.router.navigate([
                    this.state.section,
                    "model-fields"
                ]);
            }
        }
        this.spinnerService.hide();
    }

    /**
     * Preview form
     * @return {Promise<any>}
     */
    public async preview(): Promise<any> {
        this.writeNames();
        await this.modalService.open(CommonFormComponent, {
            formConfig: this.formGroup.value,
            noSubmit: true
        });
    }

    public ngOnInit(): void {

        if (this.state.action === "edit") {
            this.prepareForm();
        }
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "model-fields",
            actions: {
                "add": ["browse_model_fields"],
                "edit": ["edit_model_fields"]
            }
        };
    }

}
