import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, Validators} from "@angular/forms";
import {debounceTime, takeUntil} from "rxjs/operators";
import {FormBuilder} from "../builder.component";

@Component({
    selector: "common-form-builder-group",
    templateUrl: "group.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class FormBuilderGroupComponent extends FormBuilder implements OnInit, OnDestroy {

    @Input()
    public index: number = 0;

    @Input()
    public options: {
        label: string,
        type: string,
        fields: any[]
        name?: string
    } = {label: null, type: "group", fields: []};

    @Output()
    public onChanges: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public onMoveUp: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public onMoveDown: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public onMoveTo: EventEmitter<number> = new EventEmitter<number>();

    @Output()
    public onDelete: EventEmitter<any> = new EventEmitter<any>();


    public label: FormControl = new FormControl(null, [Validators.required]);

    public constructor(private changeDetectorRef: ChangeDetectorRef) {
        super();
    }


    /**
     * Add new field
     */
    public addField(): any {
        this.options.fields.push(this.newField);
        this.changeDetectorRef.markForCheck();

        this.onChanges.emit(this.options);
    }

    /**
     * Add new group
     */
    public addGroup(): any {
        this.options.fields.push(this.newGroup);
        this.changeDetectorRef.markForCheck();
        this.onChanges.emit(this.options);
    }

    public onMoveUpChild(index: number): void {
        const field: any = this.options.fields[index];
        this.options.fields.splice(index, 1);
        this.options.fields.splice(index - 1, 0, field);
        this.changeDetectorRef.markForCheck();

        this.onChanges.emit(this.options);
    }

    public onMoveDownChild(index: number): void {
        if (index === this.options.fields.length - 1) {
            return;
        }
        const field: any = this.options.fields[index];
        this.options.fields.splice(index, 1);
        this.options.fields.splice(index + 1, 0, field);
        this.changeDetectorRef.markForCheck();

        this.onChanges.emit(this.options);
    }

    public onDeleteChild(index: number): void {
        this.options.fields.splice(index, 1);
        this.changeDetectorRef.markForCheck();

        this.onChanges.emit(this.options);
    }

    public onChangesChild(index: number, event: any): void {
        this.options.fields[index] = event;
        this.changeDetectorRef.markForCheck();

        this.onChanges.emit(this.options);
    }

    public onMoveToChild(index: number, event: number): void {
        const field: any = this.options.fields[index];
        this.options.fields.splice(index, 1);
        this.options.fields.splice(event, 0, field);
        this.changeDetectorRef.markForCheck();
    }


    public ngOnInit(): void {
        this.label.setValue(this.options.label);

        this.label.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: string): void => {
                this.options.label = value;
                this.options.name = this.labelToName(value);
                this.onChanges.emit(this.options);
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
