<common-confirm></common-confirm>
<mat-card class="remark" *ngIf="data" [class.noBp]="data.child && data.child.length > 0"
          [class.unread]="data.unread && enableColors"
          [class.emergency]="data.emergency === 1"
          [class.latest]="maxId === data.id && enableColors">
    {{setMaxId(data.id)}}

    <mat-card-content>
        <div class="mark unread" *ngIf="data.unread">
            New
        </div>

        <div class="mark latest" *ngIf="maxId === data.id">
            Latest
        </div>

        <div class="pull-right" *ngIf="!hideRequests" style="width: auto">
            <button type="button" mat-mini-fab color="accent" matTooltip="Convert to request"
                    (click)="convertToRequest(data)">
                <mat-icon>open_in_browser</mat-icon>
            </button>
        </div>

        <div class="owner" *ngIf="data.owner">
            <div class="avatar"
                 [ngStyle]="{'background-image': 'url(' + (data.owner.avatar || 'assets/images/default-avatar.png') + ')'}">
            </div>
            <div class="name">
                {{data.owner.name}} {{!["gmail", "walla"].includes(userDomain) ? "(" + userDomain + ")" : ""}}
            </div>
        </div>

        <div class="owner" *ngIf="!data.owner">
            <div class="avatar"
                 style="background-image: url('../../../../../../../assets/images/default-avatar.png')"></div>
            <div class="name">{{data.sender_name}}</div>
        </div>

        <h3 class="subject type_{{data.order_remark_type_id}}">

        <span style="font-weight: 400;" *ngIf="showOrderButton && data.order">
            Order {{data.order.ref}}
            <br>
            <br>
        </span>
            {{data.subject}} <span class="priority" *ngIf="data.priority">[{{data.priority_name}}]</span>
        </h3>
        <div class="date">{{data.created_at}}</div>
        <div class="text-cutter" [@slideInOut]="textAnimationState">
            <div #text class="text" [innerHTML]="data.message | linebreaks | url2link"></div>
            <div class="text-footer" *ngIf="textCut" (click)="expandCut()">
                Read more
                <mat-icon>keyboard_arrow_down</mat-icon>
            </div>
        </div>

        <div class="actions" *ngIf="!hideActions">
            <div (click)="deleteRemark()" matTooltip="Delete">
                <mat-icon>delete</mat-icon>
            </div>
            <div [matMenuTriggerFor]="typeMenu" matTooltip="Set visibility">
                <ng-template [ngIf]="remarkTypes">
                    {{getRemarkTypeName(data.order_remark_type_id)}}
                </ng-template>
                <mat-icon>remove_red_eye</mat-icon>
            </div>
            <mat-menu #typeMenu="matMenu">
                <a mat-menu-item *ngFor="let option of remarkTypes" (click)="changeRemarkType(option.id)">
                    {{option.name}}
                </a>
            </mat-menu>
            <!--        <div>-->
            <!--            <mat-icon>bookmark_border</mat-icon>-->
            <!--        </div>-->
            <div (click)="addAttachment()" matTooltip="Add attachment">
                <mat-icon>attach_file</mat-icon>
            </div>
            <!--        <div>-->
            <!--            <mat-icon>thumb_up</mat-icon>-->
            <!--        </div>-->
            <div (click)="showReply = 'full'" matTooltip="Reply">
                <mat-icon>reply</mat-icon>
            </div>
        </div>

        <div class="actions clear padding-0">
            <div *ngIf="showOrderButton && data.order">
                <button mat-raised-button type="button" (click)="goToOrder()" [matTooltip]="'Order ' + data.order.ref">
                    Go to order
                </button>
            </div>

            <div *ngIf="showMarkRead">
                <button mat-raised-button type="button" color="accent" (click)="markRead()">
                    Mark read
                </button>
            </div>

            <div *ngIf="showSidebarButton">
                <button mat-raised-button type="button" color="primary" (click)="showSidebar()">
                    Show all
                </button>
            </div>
        </div>

        <div *ngIf="!hideRequests && data.requests && data.requests.length > 0" class="text-right requests">
            <div *ngFor="let request of data.requests"
                 [class.mat-acent]="['Solved','Closed'].indexOf(request.status)" class="chip">
                <mat-icon class="vertical-middle">headset_mic</mat-icon>
                {{request.request_kind.name | ucfirst}} - request available
            </div>
        </div>

        <div class="attachments" *ngIf="data.attachments && data.attachments.length > 0">
            <h3>Attachments:</h3>
            <div class="attachment" *ngFor="let attachment of data.attachments">
                <a href="{{attachment.path}}" target="_blank">
                    <mat-icon>{{getIconByExt(attachment.extension)}}</mat-icon>
                    <span>{{attachment.name}}</span>
                </a>
            </div>
            <div class="clear"></div>
        </div>


        <form class="reply-form clear" [@slideInOut]="showReply"
              *ngIf="!hideActions && formGroup" [formGroup]="formGroup" (submit)="submitReply()">
            <div>
                <h4>Reply</h4>
                <div class="group">
                    <mat-form-field class="full">
                        <mat-label>Title</mat-label>
                        <input matInput [formControlName]="'subject'">
                    </mat-form-field>

                    <mat-form-field class="full">
                        <mat-label>Text</mat-label>
                        <textarea matInput [formControlName]="'message'"
                                  matTextareaAutosize matAutosizeMinRows="2"
                                  matAutosizeMaxRows="5" required mentions [mentionUsers]="mentionUsers"></textarea>
                    </mat-form-field>
                </div>

                <button mat-raised-button color="primary" type="submit">
                    Submit
                </button>
                &nbsp;
                <button mat-raised-button type="button" (click)="formGroup.reset(); showReply = 'hide'">
                    Cancel
                </button>
            </div>
        </form>
        <div class="childrens" *ngIf="data.child && data.child.length > 0">
            <ng-template ngFor let-child [ngForOf]="data.child">
                <common-section-remark [data]="child"
                                       [parentId]="data.id"
                                       [remarkTypes]="remarkTypes"
                                       [orderRef]="orderRef"
                                       [maxId]="maxId"
                                       [mentionUsers]="mentionUsers"
                                       [showSidebarButton]="showSidebarButton"
                                       [showMarkRead]="showMarkRead"
                                       [readForAll]="readForAll"
                                       [readOnView]="readOnView"
                                       [isChild]="true"
                                       [showOrderButton]="showOrderButton"
                                       [state]="state"
                                       [enableColors]="enableColors"
                                       (maxIdEvent)="setMaxId($event)"
                                       (reloadEvent)="reloadEvent.emit()"></common-section-remark>
            </ng-template>
        </div>
    </mat-card-content>

</mat-card>
