<common-confirm></common-confirm>

<div id="selection">
    <mat-nav-list class="mat-elevation-z1 light-background">

        <ng-template [ngIf]="progress">
            <mat-progress-bar [color]="'primary'" [mode]="'indeterminate'" class="progress"></mat-progress-bar>
        </ng-template>

        <img src="assets/images/logo.png" class="logo">

        <mat-divider></mat-divider>

        <mat-list-item *ngIf="data && ((!data.partners && !data.threepls)
            || (data.partners.length === 0 && data.threepls.length === 0))">
            <h2 class="text-center blue">Your account awaiting confirmation</h2>
        </mat-list-item>

        <ng-template [ngIf]="data && (data.partners || data.threepls)
                && (data.partners.length > 0 || data.threepls.length > 0)">
            <mat-list-item>
                <h2>Enter as:</h2>
            </mat-list-item>

            <mat-divider></mat-divider>
            <mat-divider></mat-divider>
            <mat-divider></mat-divider>

            <div class="columns-container">
                <div class="column" *ngIf="data && data.partners && data.partners.length > 0">
                    <div class="group flex">
                        <mat-list-item class="half">
                            <h4>Partners</h4>
                        </mat-list-item>

                        <mat-form-field class="search half" [hidden]="showCount >= data.partners.length">
                            <mat-label>Search</mat-label>
                            <input matInput type="search"
                                   (input)="filterPartners($event.target.value)">
                        </mat-form-field>
                    </div>

                    <mat-divider></mat-divider>

                    <mat-list-item>
                        <mat-checkbox [formControl]="showHistory">Show history records</mat-checkbox>
                    </mat-list-item>

                    <div *ngFor="let partner of filteredPartners">
                        <mat-divider></mat-divider>
                        <mat-list-item>
                            <a matListItemTitle (click)="goToPartner(partner.slug)" class="item">
                                <button mat-icon-button>
                                    <mat-icon>navigate_next</mat-icon>
                                </button>
                                <i>
                                    <img *ngIf="partner.icon_path" [src]="partner.icon_path" alt="">
                                </i>
                                <span>{{partner.display_name}}</span>
                            </a>
                        </mat-list-item>
                    </div>
                </div>

                <div class="column" *ngIf="data && data.threepls && data.threepls.length > 0">
                    <div class="group flex">
                        <mat-list-item class="half">
                            <h4>ThreePLs</h4>
                        </mat-list-item>

                        <mat-form-field class="search half" [hidden]="showCount >= data.threepls.length">
                            <mat-label>Search</mat-label>
                            <input matInput type="search"
                                   (input)="filterThreepls($event.target.value)">
                        </mat-form-field>
                    </div>

                    <div *ngFor="let threepl of filteredThreepls">
                        <mat-divider></mat-divider>
                        <mat-list-item>
                            <a matListItemTitle (click)="goToThreepl(threepl.slug)" class="item">
                                <button mat-icon-button>
                                    <mat-icon>navigate_next</mat-icon>
                                </button>
                                <i [title]="threepl.type" class="vertical-middle">
                                    <img *ngIf="threepl.icon_path" [src]="threepl.icon_path" alt="">
                                    <ng-template [ngIf]="!threepl.icon_path">
                                        <mat-icon *ngIf="threepl.type === 'ThreePL'">home</mat-icon>
                                        <i class="fa fa-cogs" *ngIf="threepl.type === 'OEM'"></i>
                                    </ng-template>
                                </i>
                                <span>{{threepl.display_name}}</span>
                            </a>
                        </mat-list-item>
                    </div>
                </div>


            </div>

        </ng-template>
        <mat-divider></mat-divider>
        <mat-divider></mat-divider>
        <mat-divider></mat-divider>

        <ng-template [ngIf]="admin">
            <mat-list-item>
                <h2>Or:</h2>
            </mat-list-item>

            <mat-divider></mat-divider>
            <mat-divider></mat-divider>
            <mat-divider></mat-divider>
        </ng-template>

        <div class="columns-container">
            <div class="column" *ngIf="admin">
                <mat-list-item>
                    <a matListItemTitle [routerLink]="['/admin']" class="item">
                        <button mat-icon-button>
                            <mat-icon>navigate_next</mat-icon>
                        </button>
                        <i>
                            <mat-icon>fingerprint</mat-icon>
                        </i>
                        <span>Administrator</span>
                    </a>
                </mat-list-item>
            </div>

            <div class="column">
                <mat-list-item (click)="logout()">
                    <a matListItemTitle class="item">
                        <button mat-icon-button>
                            <mat-icon>navigate_next</mat-icon>
                        </button>
                        <i>
                            <mat-icon>logout</mat-icon>
                        </i>
                        <span>Log out</span>
                    </a>
                </mat-list-item>
            </div>
        </div>


    </mat-nav-list>
</div>

<common-test></common-test>
