import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import {Router} from "@angular/router";
import {Api} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {ModalService} from "../../../../../services/modal.service";
import {Warehouse} from "../../../../../../common/interfaces/warehouse.interface";
import {FormControl, FormGroup} from "@angular/forms";
import {debounceTime, takeUntil} from "rxjs/operators";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Form} from "../../../../../../common/interfaces/form.interface";
import {Observable} from "rxjs";
import {MapInfoWindow, MapMarker} from "@angular/google-maps";
import {GoogleMapsApiService} from "../../../../../../common/services/google-maps-api.service";
import {Api3Service} from "../../../../../../common/services/api3.service";
import ISelectOption = Form.ISelectOption;


@Component({
    selector: "section-stock-map",
    template: `
        <h1 class="container-heading">Stock map</h1>
        <mat-card>
            <div>
                <div [formGroup]="filters" class="flex row margin-right-0 margin-left-0 align-center">
                    <common-form-select class="margin-right-10" style="width:500px;"
                                        label="Items"
                                        [search]="true"
                                        [disabled]="!part_masters.length"
                                        [options]="part_masters"
                                        [multiple]="true"
                                        (onClose)="filters.get('part_master_id').setValue($event)"
                    ></common-form-select>

                    <mat-checkbox formControlName="available_only">Show available only</mat-checkbox>
                </div>
            </div>
            <div>
                <ng-container *ngIf="apiLoaded | async">
                    <google-map [options]="options" height="550px" width="100%">

                        <map-marker *ngFor="let warehouse of warehouses"
                                    #marker="mapMarker"
                                    [position]="
                                    {lat: number(warehouse.address.lat), lng: number(warehouse.address.lng)}"
                                    [options]="{icon:
                                        {url:selectMarkerIcon(warehouse)},
                                        zIndex: 2}"
                                    (mapClick)="openInfoWindow(marker, warehouse)">
                        </map-marker>


                        <map-info-window>
                            <common-simple-spinner *ngIf="!infoWindowData"></common-simple-spinner>
                            <div class="info-window-content" *ngIf="infoWindowData">
                                <h2 class="text-center margin-bottom-0">{{infoWindowData.warehouse.name}}</h2>
                                <table class="table" *ngIf="infoWindowData.stock.length">
                                    <tr>
                                        <th>Item</th>
                                        <th>Quantity</th>
                                        <th>Batch</th>
                                        <th>Hub</th>
                                    </tr>
                                    <tr *ngFor="let item of infoWindowData.stock"
                                        [class.background-success]="item.inventory_conversion.gb==='good'"
                                        [class.background-error]="item.inventory_conversion.gb==='defective'">
                                        <td>{{item.item}}</td>
                                        <td class="text-center">
                                            {{item.sum_quantity}}
                                        </td>
                                        <td>{{item.batch}}</td>
                                        <td>
                                            {{item.inventory_conversion.customers_inventory_name}}
                                            ({{item.inventory_conversion.customers_sub_inventory}})
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </map-info-window>

                    </google-map>
                </ng-container>
            </div>
        </mat-card>
    `,
    styles: [
        `
            .info-window-content {
                max-height: 300px;
                overflow: auto;
            }
        `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockMapComponent implements Base.IComponent, OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    public state: Base.IState;
    public apiLoaded: Observable<any>;

    public warehouses: Warehouse.IWarehouse[] = [];

    public options: google.maps.MapOptions;

    @ViewChild(MapInfoWindow)
    public infoWindow: MapInfoWindow;

    public infoWindowData: { warehouse: Warehouse.IWarehouse, stock: any[] } = null;

    public center: { lat: number, lng: number } = {lat: 0, lng: 0};

    public filters: FormGroup = new FormGroup({
        part_master_id: new FormControl([]),
        available_only: new FormControl(true)
    });

    public part_masters: ISelectOption[] = [];

    public constructor(
        private router: Router,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private modalService: ModalService,
        private changeDetectorRef: ChangeDetectorRef,
        private spinnerService: SpinnerService,
        private googleMapsApiService: GoogleMapsApiService
    ) {
    }

    private async getPartMasters(): Promise<any> {
        const {data}: Api.IResponse = await this.api3Service.get(`/partner/${this.state.section_slug}/part-masters`, {
            in_stock: true,
            data_structure: "select"
        });

        if (data) {
            this.part_masters = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    private async getWarehouses(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `/partner/${this.state.section_slug}/warehouses`, {}, {
                relations: [
                    "Address"
                ],
                appends: ["has_good_stock", "has_bad_stock"],
                ...this.filters.value
            });

        this.spinnerService.hide();

        if (data) {
            this.warehouses = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    public number(data: string | number): number {
        return Number(data);
    }

    public selectMarkerIcon(warehouse: any): string {
        let color: string;
        if (warehouse.has_good_stock && warehouse.has_bad_stock) {
            color = "green-red";
        } else if (warehouse.has_good_stock && !warehouse.has_bad_stock) {
            color = "green-grey";
        } else if (!warehouse.has_good_stock && warehouse.has_bad_stock) {
            color = "grey-red";
        } else {
            color = "grey";
        }

        return "/assets/images/icons/warehouse-marker-" + color + ".png";
    }

    public openInfoWindow(marker: MapMarker, warehouse: Warehouse.IWarehouse): void {
        this.infoWindowData = null;
        this.changeDetectorRef.markForCheck();
        this.infoWindow.open(marker);

        this.api3Service.request(Api.EMethod.Get,
            `/partner/${this.state.section_slug}/warehouses/${warehouse.id}/inventories/summarized`, {}, {
                relations: [
                    "InventoryConversion:id,gb,customers_inventory_name,customers_sub_inventory"
                ],
                ...this.filters.value
            })
            .then(({data}: Api.IResponse) => {
                this.infoWindowData = {
                    warehouse: warehouse,
                    stock: data
                };
                this.changeDetectorRef.markForCheck();
            });
    }

    public ngOnInit(): void {
        this.getWarehouses();
        this.getPartMasters();

        this.options = {
            center: this.center,
            zoom: 3,
            maxZoom: 15,
            scrollwheel: true,
            gestureHandling: "cooperative",
            disableDefaultUI: true,
            streetViewControl: false,
            fullscreenControl: true,
            zoomControl: true,
        };

        this.apiLoaded = this.googleMapsApiService.load();


        this.filters.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(50)).subscribe((value: any) => {

            this.getWarehouses();
        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "stock-map",
            actions: {
                "browse": ["browse_inventories"]
            }
        };
    }
}
