<h1 class="container-heading">Parcels list</h1>
<mat-card>
    <mat-card-content>
        <ng-template [ngIf]="parcels && parcels.data && parcels.data.length > 0">

            <mat-form-field>
                <mat-label>Search parcel</mat-label>
<input matInput type="search"  [formControl]="search">
            </mat-form-field>

            <div class="parcels">
                <div class="parcel" *ngFor="let parcel of parcels.data">
                    <button class="list" mat-icon-button (click)="listParcelItems(parcel)">
                        <mat-icon title="Items">format_list_bulleted</mat-icon>
                    </button>
                    <button class="edit" mat-icon-button (click)="editParcel(parcel)">
                        <mat-icon title="Edit">edit</mat-icon>
                    </button>
                    <div>
                        <img src="../../../../../../../../assets/images/parcel.png" alt="{{parcel.ref}}">
                    </div>
                    <div>
                        <b>{{parcel.ref}}</b>
                    </div>
                    <div *ngIf="parcel.weight && parcel.height && parcel.length">
                        {{parcel.weight}}x{{parcel.height}}x{{parcel.length}}
                        {{parcel.distance_unit || ""}}
                    </div>
                    <div *ngIf="parcel.weight">
                        {{parcel.weight || 'N/A'}} kg
                    </div>
                </div>
            </div>
            <common-pagination [data]="parcels"
                               pid="Mhb726eAG"
                               (goToPage)="getShipmentParcels($event.page, search.value, $event.per_page)"></common-pagination>
        </ng-template>
    </mat-card-content>
</mat-card>

