import {enableProdMode, isDevMode} from "@angular/core";
import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";
import * as Sentry from "@sentry/angular-ivy";

import {AppModule} from "./modules/app/app.module";
import {environment} from "./environments/environment";

if (environment.production) {
    enableProdMode();

    Sentry.init({
        dsn: "https://f61229662cfaf5aa381ea61afa0d93e5@o111583.ingest.us.sentry.io/4507130016759808",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        tracesSampleRate: 1.0,
        tracePropagationTargets: ["localhost"],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 0.1,
    });

}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err: Error): void => isDevMode() && console.error(err));
