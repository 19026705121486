import {Injectable} from "@angular/core";
import {Api, ApiService} from "./api.service";


@Injectable()
export class ShipmentService {

    public constructor(private apiService: ApiService) {
    }

    public update(id: number, body: any = {}, query: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Put, ["shipment", "" + id], body, query);
    }
}
