import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {Modal, ModalService} from "../../../../../../services/modal.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {Router} from "@angular/router";
import {ParcelFormComponent, ParcelItemsListModalComponent} from "../../../../../threepl/warehouse/parcel";
import {Warehouse} from "../../../../../../../common/interfaces/warehouse.interface";
import {Order} from "../../../../../../../common/interfaces/order.interface";
import {IPagination} from "../../../../../../../common/components/pagination/pagination.component";
import {debounceTime, takeUntil} from "rxjs/operators";
import {FormControl} from "@angular/forms";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {UserService} from "../../../../../../../common/services/user.service";

@Component({
    selector: "section-order-view-parcels",
    templateUrl: "parcels.component.html",
    styleUrls: [
        "parcels.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})

export class OrderParcelsComponent implements OnInit, OnDestroy {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    public modal: Modal.IModal;

    public parcels: IPagination<Warehouse.IParcel>;

    public search: FormControl = new FormControl(null);

    public constructor(
        private apiService: ApiService,
        private changeDetectorRef: ChangeDetectorRef,
        private modalService: ModalService,
        private toastService: ToastService,
        private router: Router,
        private spinnerService: SpinnerService,
        private userService: UserService
    ) {
    }

    /**
     * Get order shipments
     * @returns {Promise<any>}
     */
    private async getShipmentParcels(
        page: number = 1, search_by: string = null, per_page: number = null): Promise<any> {
        this.spinnerService.show();
        if (per_page === null) {
            per_page = this.userService.data.settings.default_per_page;
        }
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["shipment", this.modal.params.shipmentId, "parcels"], {}, {
                data_structure: "paginated",
                page,
                search_by,
                per_page,
                with_order_items: true
            });
        if (data) {
            this.parcels = data;
        }
        this.changeDetectorRef.markForCheck();
        this.spinnerService.hide();
    }

    /**
     * Edit parcel
     * @param {Order.IParcel} parcel
     * @returns {Promise<any>}
     */
    public async editParcel(parcel: Warehouse.IParcel): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(ParcelFormComponent, {
            action: "edit",
            parcel: parcel,
            order: this.modal.params.state.params.order,
            modalWidth: "660px"
        });
        // console.warn("edit", response);
        if (response) {
            this.getShipmentParcels();
        }
    }

    /**
     * Show parcel items list
     * @param {Warehouse.IParcel} parcel
     * @returns {Promise<any>}
     */
    public async listParcelItems(parcel: Warehouse.IParcel): Promise<any> {
        await this.modalService.open(ParcelItemsListModalComponent, {
            data: parcel.order_items,
        });
    }

    /**
     * Go to add transaction form
     * @param {Order.IShipment} shipment
     */
    public addTransaction(shipment: Warehouse.IShipment): void {
        this.router.navigate([
            this.modal.params.state.section,
            "transaction",
            "add",
            "order",
            this.modal.params.orderRef,
            "shipment",
            this.modal.params.shipmentId
        ]);
    }

    public ngOnInit(): void {
        this.getShipmentParcels();


        this.search.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: string): void => {
                this.getShipmentParcels(1, value);
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
