<div>
    <mat-form-field>
        <mat-label>Shipment type</mat-label>
<mat-select  [formControl]="shipmentsType">
            <mat-option value="inbound">
                Inbound
            </mat-option>
            <mat-option value="outbound">
                Outbound
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>