<mat-drawer-container>

    <mat-drawer #drawer [mode]="'over'" [opened]="true" [position]="'start'" class="mat-elevation-z1">

        <div class="drawer">

            <mat-icon id="lock_drawer" (click)="onLockDrawer()">{{lockDrawer ? 'lock' : 'lock_open'}}</mat-icon>

            <div class="logo icon">
                <img *ngIf="logo_icon" [src]="logo_icon" alt="">
            </div>
            <div class="logo">
                <img *ngIf="logo" [src]="logo" alt="">
            </div>

            <ul *ngIf="items && items.length">
                <ng-template ngFor let-item let-index="index" [ngForOf]="items">
                    <li *ngIf="allowed(item.permissions) && canSee(item)">

                        <ng-template [ngIf]="item && item.modal" [ngIfElse]="elseBlock">
                            <a routerLinkActive="active" class="link" [routerLinkActiveOptions]="{exact: true}"
                               (click)="onChildEvent({index:index,state:true}); openModal(item)">
                                <i *ngIf="item.icon" class="material-icons">{{item.icon}}</i>
                                <i *ngIf="item.iconFa" class="fa fa-{{item.iconFa}}"></i>
                                {{item.title}}
                            </a>
                        </ng-template>

                        <ng-template #elseBlock>
                            <ng-template [ngIf]="item && item.link">
                                <a [routerLink]="item.link" routerLinkActive="active"
                                   [routerLinkActiveOptions]="{exact: true}"
                                   class="link" (click)="onChildEvent({index:index,state:true})">
                                    <i *ngIf="item.icon" class="material-icons">{{item.icon}}</i>
                                    <i *ngIf="item.iconFa" class="fa fa-{{item.iconFa}}"></i>
                                    <span>{{item.title}}</span>
                                </a>
                            </ng-template>

                            <ng-template [ngIf]="item && item.items">
                                <app-drawer-items [item]="item" [index]="index"
                                                  [expanded]="item.expanded"
                                                  [section]="section"
                                                  (childEvent)="onChildEvent($event, item)">
                                </app-drawer-items>
                            </ng-template>
                        </ng-template>
                    </li>
                </ng-template>

            </ul>

        </div>

    </mat-drawer>

    <ng-content></ng-content>

</mat-drawer-container>
