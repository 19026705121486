import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import {Warehouse} from "../../../../../../common/interfaces/warehouse.interface";
import {TransactionService} from "../../../../../../common/services/transaction.service";
import {Modal} from "../../../../../services/modal.service";
import {FormControl} from "@angular/forms";
import {debounceTime, takeUntil} from "rxjs/operators";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";

@Component({
    selector: "common-modal",
    template: `
        <h1 class="container-heading">Split transaction</h1>
        <mat-card>
            <div>
                Note: This action will split transaction into two with specified quantities
            </div>
            <mat-card-content>
                <div>Item: <b>{{transaction.item}}</b></div>
                <div>Type: <b>{{transaction.type}}</b></div>
                <div *ngIf="transaction.inventory_conversion">
                    Hub: <b>{{transaction.inventory_conversion.customers_inventory_name}}
                    ({{transaction.inventory_conversion.customers_sub_inventory}})</b>
                </div>

                <div class="text-center padding-top-20 margin-bottom-20">
                    <mat-form-field>
                        <mat-label>Current transaction quantity</mat-label>
<input type="number" [formControl]="quantityInput_1"
                               [min]="1"
                               [max]="transaction.quantity-1"
                               matInput >
                    </mat-form-field>
                    /
                    <mat-form-field>
                        <mat-label>New transaction quantity</mat-label>
<input type="number"
                               [min]="1"
                               [max]="transaction.quantity-1"
                               [formControl]="quantityInput_2"
                               matInput >
                    </mat-form-field>
                </div>

                <div>
                    <button mat-raised-button color="primary" (click)="split()">
                        Submit
                    </button>
                </div>
            </mat-card-content>
        </mat-card>
        <common-confirm></common-confirm>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionTransactionSplitModalComponent implements OnInit, OnDestroy {
    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    public modal: Modal.IModal;

    public transaction: Warehouse.ITransaction;

    public quantityInput_1: FormControl = new FormControl(null);
    public quantityInput_2: FormControl = new FormControl(null);

    @ViewChild(ConfirmComponent, {static: true})
    public confirmRef: ConfirmComponent;

    public constructor(
        private transactionService: TransactionService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    public async split(): Promise<any> {
        if (!await this.confirmRef.confirm("Operation can not be reverted! Proceed?")) {
            return;
        }

        this.transactionService.splitTransaction(this.transaction.id, this.quantityInput_1.value)
            .then(() => {
                this.modal.response.emit({
                    name: "OK"
                });
            });
    }


    public ngOnInit(): void {
        this.transaction = this.modal.params.transaction;

        this.quantityInput_1.setValue(1);
        this.quantityInput_2.setValue(this.transaction.quantity - 1);

        this.quantityInput_1.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: number) => {
                this.quantityInput_2.setValue(this.transaction.quantity - value, {emitEvent: false});
                this.changeDetectorRef.markForCheck();
            });
        this.quantityInput_2.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: number) => {
                this.quantityInput_1.setValue(this.transaction.quantity - value, {emitEvent: false});
                this.changeDetectorRef.markForCheck();
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
