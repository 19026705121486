<mat-card>
    <div>
        <div class="pull-left">
            <div class="margin-bottom-10">
                <button type="button" mat-raised-button color="primary" (click)="findLocationByItem()">
                    <mat-icon>search</mat-icon>
                    Find location by item
                </button>
            </div>
            <div class="margin-bottom-10">
                <button type="button" mat-raised-button color="primary" (click)="locationList()">
                    Available locations
                </button>
            </div>

            <div *ngIf="usedLocations.length">
                <h3>Used locations:</h3>
                <div *ngFor="let usedLocation of usedLocations">
                    <b class="color-accent pointer"
                       (click)="setLocationFromList(usedLocation)">{{usedLocation.location}}</b>
                </div>
            </div>
        </div>

        <ng-template [ngIf]="!showFreeBoxes">
            <h1 *ngIf="location" class="color-accent text-center">Scan boxes</h1>
            <h1 *ngIf="!location" class="color-accent text-center">Select location</h1>
        </ng-template>

        <h1 *ngIf="showFreeBoxes" class="color-accent">Fulfill missing boxes</h1>

        <div class="search margin-bottom-20">
            <div class="info" *ngIf="location">
                <mat-icon>move_to_inbox</mat-icon>
                <div>
                    Your current location is: <b class="color-accent">{{location.name}}</b>
                    <mat-icon color="warn" class="pointer clear" (click)="clearLocation()"
                              matTooltip="Clear location">
                        clear
                    </mat-icon>
                    <br>
                    Inbound items: <b class="color-accent">{{location.warehouse_transactions_count}}</b>&nbsp;<mat-icon
                        class="pointer" (click)="findLocation()" matTooltip="Refresh location">refresh
                </mat-icon>
                    <ng-template [ngIf]="serialTemp">
                        <br>
                        Scanned serial <b class="color-accent">{{serialTemp}}</b>
                        <mat-icon color="warn" class="pointer clear" (click)="clearSerial()"
                                  matTooltip="Clear serial">
                            clear
                        </mat-icon>
                    </ng-template>
                </div>
            </div>
            <div class="search-field">
                <mat-form-field>
                    <mat-label>{{searchInputLabel}}</mat-label>
                    <input matInput type="search"
                           [formControl]="searchInput"
                           [scannerOnly]="scannerOnly"
                           (keydown)="onKeydown($event)" #searchInputRef>
                </mat-form-field>
                <button type="button" mat-raised-button color="accent" (click)="find()">
                    <i class="fa fa-barcode"></i> Search
                </button>
            </div>
            Your scanner default key set as {{ scannerKey }}.
            <span *ngIf="scannerOnly" class="color-warn">
            Manual input disabled
        </span>
        </div>

        <div *ngIf="showAddFrom === 1" class="add-buttons">
            <button type="button" mat-raised-button color="primary" (click)="showAddFrom = 2">
                Add location
            </button>
            &emsp;
            <button type="button" mat-raised-button color="accent" (click)="showAddFrom = 3">
                Add box
            </button>
        </div>

        <form [formGroup]="locationFrom" *ngIf="showAddFrom === 2" (submit)="addLocation()" class="add-form">
            <h3>Add location</h3>
            <div class="group flex">
                <mat-form-field class="half">
                    <mat-select formControlName="warehouse_location_type_id">
                        <mat-option *ngFor="let type of locationTypes" [value]="type.value">
                            {{type.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="half">
                    <mat-label>Location</mat-label>
                    <input matInput type="text"
                           formControlName="location"
                           #newLocationName>
                </mat-form-field>
            </div>
            <div class="actions">
                <button type="submit" mat-raised-button color="primary" [disabled]="!locationFrom.valid">
                    Submit
                </button>

                <button type="submit" mat-raised-button (click)="locationFrom.reset();showAddFrom = 0">
                    Cancel
                </button>
            </div>
        </form>

        <form [formGroup]="boxForm" *ngIf="showAddFrom === 3" (submit)="addBox()" class="add-form">
            <h3>Add box</h3>
            <div class="group flex">
                <mat-form-field class="full">
                    <mat-label>Ref</mat-label>
                    <input matInput type="text" formControlName="ref">
                </mat-form-field>
            </div>
            <div class="actions">
                <button type="submit" mat-raised-button color="primary" [disabled]="!boxForm.valid">
                    Submit
                </button>

                <button type="submit" mat-raised-button (click)="boxForm.reset();showAddFrom = 0">
                    Cancel
                </button>
            </div>
        </form>

        <div *ngIf="(location || showFreeBoxes) && boxList" class="boxes-wrap">
            <common-pagination [data]="boxList"
                               pid="7VIea89c"
                               (goToPage)="getBoxesPaginated($event.page,$event.per_page)"></common-pagination>
            <div class="boxes" [class.no_shelf]="showFreeBoxes">
                <ng-template ngFor let-box [ngForOf]="boxList.data" let-i="index">
                    <div class="box"
                         [class.green]="box.warehouse_transactions_count > 0"
                         [class.red]="showFreeBoxes">
                        <div class="ref">
                            {{box.ref}}
                        </div>
                        <div class="logo" *ngIf="box.partner">
                            <span></span>
                            <img [src]="box.partner.icon_path" alt="">
                        </div>
                        <div class="items background-accent" *ngIf="!showFreeBoxes">
                            {{box.warehouse_transactions_count}} items
                        </div>
                        <div class="items background-accent" *ngIf="showFreeBoxes">
                            {{box.inventory_allocations_count}} allocations
                        </div>
                        <div class="buttons">
                            <button mat-mini-fab color="primary"
                                    type="button" (click)="openBox(box)">
                                <mat-icon>list_alt</mat-icon>
                            </button>
                            <button *ngIf="location"
                                    type="button" mat-mini-fab
                                    (click)="addTransaction(box)" matTooltip="Add items">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>
                    </div>
                </ng-template>
            </div>
            <div *ngIf="!showFreeBoxes" [class.shelf]="location.type_id === 1"
                 [class.palet]="location.type_id === 2"
                 [ngStyle]="{'background-image': 'url('+location.image+')'}"></div>

            <div class="location-summary color-accent text-center" *ngIf="location">
                <b>{{location.name}}</b> -
                <span *ngIf="!location.hub" class="text-success">
                No related sub inventory
            </span>
                <span *ngIf="location.hub" class="text-error">
               {{location.hub.customers_sub_inventory}}
            </span>
            </div>
        </div>
    </div>
</mat-card>
