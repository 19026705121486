import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {
    AbstractControl,
    FormArray,
    FormControl,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators
} from "@angular/forms";
import {Order} from "../../../../../../common/interfaces/order.interface";
import {Warehouse} from "../../../../../../common/interfaces/warehouse.interface";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Router} from "@angular/router";
import {COMMA, ENTER, SEMICOLON} from "@angular/cdk/keycodes";
import {MatChipInputEvent} from "@angular/material/chips";
import {ModalService} from "../../../../../services/modal.service";
import {CommonFormComponent} from "../../../../../../common/components/form";
import {Form} from "../../../../../../common/interfaces/form.interface";
import {debounceTime, distinctUntilChanged, takeUntil} from "rxjs/operators";
import {User} from "../../../../../../common/interfaces/user.interface";
import {SpinnerService} from "../../../../../../common/services/spinner.service";

@Component({
    selector: "section-inspection-builder",
    templateUrl: "builder.component.html",
    styleUrls: [
        "builder.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class InspectionBuilderComponent implements Base.IComponent, OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    private labels: string[] = [];

    public readonly state: Base.IState;

    public formGroup: FormGroup = new FormGroup({
        name: new FormControl(null, [Validators.required]),
        partner_id: new FormControl(null),
        description: new FormControl(null),
        ref_field: new FormControl(null),
        service_level_id: new FormControl(null),
        warehouse_slug: new FormControl(null),
        fields: new FormArray([])
    });

    public partners: any[] = [];

    public partnerSelect: FormControl = new FormControl();

    public section_type: string;

    public fieldTypes: { [key: string]: any } = Form.FIELD_TYPES;

    public fieldSizes: string[] = ["full", "half", "third"];

    public service_levels: Order.IServiceLevel[] = [];

    public warehouses: string[] = [];

    public separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON];

    public inputPatterns = [
        {
            label: "None",
            pattern: null
        },
        {
            label: "Email",
            pattern: "email"
        },
        {
            label: "Phone",
            pattern: "phone"
        },
        {
            label: "Zip",
            pattern: "zip"
        },
    ];

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private title: Title,
        private apiService: ApiService,
        private toastService: ToastService,
        private router: Router,
        private modalService: ModalService,
        private spinnerService: SpinnerService
    ) {
    }

    /**
     * Get service levels list
     * @return {Promise<any>}
     */
    private async getServiceLevels(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["service_level"]);
        if (data) {
            this.service_levels = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private async getPartners(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["warehouse", "partners"]);
        if (data) {
            this.partners = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get hubs list
     * @return {Promise<any>}
     */
    private async getHubs(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["hub"]);
        if (data) {
            this.warehouses = data.map((value: Warehouse.IHub): string => value.warehouse_slug)
                .filter((value: Warehouse.IHub, index: number, self: any): boolean => self.indexOf(value) === index);
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Prepare form
     * @return {Promise<any>}
     */
    private async prepareForm(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["inspection", this.state.params.id]);

        if (data) {
            this.formGroup.get("name").setValue(data.name);
            this.formGroup.get("description").setValue(data.description);
            this.formGroup.get("ref_field").setValue(data.ref_field);
            this.formGroup.get("service_level_id")
                .setValue(data.service_levels.map((service_level: Order.IServiceLevel): number => service_level.id));
            this.formGroup.get("warehouse_slug")
                .setValue(data.warehouses.map((warehouse: Warehouse.IWarehouse): string => warehouse.slug));

            const fields: any = data.fields;

            let hasStatus: boolean = false;
            let hasSign: boolean = false;

            for (const field of fields) {
                if (field.type === "group") {
                    this.addGroup(field.label, field.type, field.fields, field.triggerIndex, field.triggerVal);
                } else {
                    this.addField(field.label, field.type, field.values,
                        field.required, field.multiple, field.size, field.url, null, null, field.pattern);
                }
                if (field.type === "status") {
                    hasStatus = true;
                }
                if (field.type === "signature") {
                    hasSign = true;
                }
            }
            // if (!hasStatus) {
            //     this.addField(
            //         "Inspection status",
            //         "status",
            //         ["Failed", "Passed"],
            //         true,
            //         false,
            //         "full",
            //     );
            // }
            if (!hasSign) {
                this.addField(
                    "Sign",
                    "signature",
                    [],
                    true,
                    false,
                    "full"
                );
            }
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }


    /**
     * Make names from labels
     */
    private writeNames(): void {
        this.labels = [];

        for (const index of Object.keys(this.formGroup.value.fields)) {
            const label: string = (this.formGroup.get("fields") as FormArray).controls[index].value.label;
            const type: string = (this.formGroup.get("fields") as FormArray).controls[index].value.type;
            const name: string = (this.formGroup.get("fields") as FormArray).controls[index].value.name;

            if (this.labels.includes(label)) {
                (this.formGroup.get("fields") as FormArray).controls[index].setErrors({not_unique: true});
                this.formGroup.updateValueAndValidity();
                return;
            }

            this.labels.push(label);

            const label_name = this.labelToName(label, type);

            if (!name || name !== label_name) {
                (this.formGroup.get("fields") as FormArray).controls[index]
                    .get("name").setValue(label_name);
            }

            if ((this.formGroup.get("fields") as FormArray).controls[index].value.type === "group") {
                for (const ind of Object.keys((this.formGroup.get("fields") as FormArray)
                    .controls[index].value.fields)) {

                    const child_label: string = (this.formGroup.get("fields") as FormArray)
                        .controls[index].controls.fields.controls[ind].value.label;
                    const child_type: string = (this.formGroup.get("fields") as FormArray)
                        .controls[index].controls.fields.controls[ind].value.type;

                    if (this.labels.includes(label)) {
                        (this.formGroup.get("fields") as FormArray)
                            .controls[index].controls.fields.controls[ind].setErrors({not_unique: true});
                        this.formGroup.updateValueAndValidity();
                        return;
                    }

                    this.labels.push(child_label);

                    (this.formGroup.get("fields") as FormArray)
                        .controls[index].controls.fields
                        .controls[ind].get("name").setValue(this.labelToName(child_label, child_type));
                }
            }
        }

        this.changeDetectorRef.markForCheck();
    }

    /**
     * Convert string to snake_case
     * @param {string} label
     * @param type
     * @param sign
     * @return {string}
     */
    private labelToName(label: string, type: string, sign?: boolean): string {
        return label ? "field_input_" + (label.toLowerCase()
            .replace(/ /g, "_")
            .replace(/[^a-z0-9_]/g, "")) : null;
    }

    /**
     * Add new field
     * @param {string} label
     * @param {string} type
     * @param {string[]} values
     * @param {boolean} required
     * @param multiple
     * @param {string} size
     * @param url
     * @param description
     * @param group
     * @param pattern
     */
    public addField(
        label: string = null,
        type: string = "input",
        values: string[] = [],
        required: boolean = false,
        multiple: boolean = false,
        size: string = "full",
        url: string = null,
        description: string = null,
        group: number = null,
        pattern: string = null
    ): void {
        if (group !== null) {
            (((this.formGroup.controls.fields as FormArray).controls[group] as FormGroup)
                .controls.fields as FormArray).push(
                new FormGroup({
                    label: new FormControl(label, [Validators.required]),
                    name: new FormControl(this.labelToName(label, type, true)),
                    type: new FormControl(type, [Validators.required]),
                    values: new FormControl(values),
                    required: new FormControl(required),
                    multiple: new FormControl(multiple),
                    size: new FormControl(size, [Validators.required]),
                    url: new FormControl(url),
                    description: new FormControl(description),
                    pattern: new FormControl(pattern)
                })
            );
            this.changeDetectorRef.markForCheck();
        } else {
            (this.formGroup.controls.fields as FormArray).push(
                new FormGroup({
                    label: new FormControl(label, [Validators.required]),
                    name: new FormControl(this.labelToName(label, type, type === "signature")),
                    type: new FormControl(type, [Validators.required]),
                    values: new FormControl(values),
                    required: new FormControl(required),
                    multiple: new FormControl(multiple),
                    size: new FormControl(size, [Validators.required]),
                    url: new FormControl(url),
                    description: new FormControl(description),
                    pattern: new FormControl(pattern)
                })
            );
        }
        this.changeDetectorRef.markForCheck();
    }

    public addGroup(
        label: string = null,
        type: string = "group",
        fields: any = null,
        triggerIndex: string = null,
        triggerVal: string = null
    ): void {
        (this.formGroup.controls.fields as FormArray).push(
            new FormGroup({
                label: new FormControl(label, [Validators.required]),
                name: new FormControl(this.labelToName(label, type)),
                type: new FormControl(type, [Validators.required]),
                triggerIndex: new FormControl(triggerIndex),
                triggerVal: new FormControl(triggerVal),
                fields: new FormArray([])
            })
        );
        if (fields) {
            for (const field of fields) {
                this.addField(
                    field.label, field.type, field.values,
                    field.required, field.multiple, field.size,
                    field.url, field.description,
                    (this.formGroup.controls.fields as FormArray).length - 1);
            }
        }
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Remove field
     * @param {number} index
     */
    public removeField(index: number): void {
        (this.formGroup.controls.fields as FormArray).removeAt(index);
        this.changeDetectorRef.markForCheck();
    }

    /**
     * move field up
     * @param {number} index
     */
    public moveUp(index: number): void {
        const field: AbstractControl = (this.formGroup.controls.fields as FormArray).controls[index];
        (this.formGroup.controls.fields as FormArray).removeAt(index);
        (this.formGroup.controls.fields as FormArray).insert(index - 1, field);
    }

    public moveTo(index: number, to: number): void {
        const field: AbstractControl = (this.formGroup.controls.fields as FormArray).controls[index];
        (this.formGroup.controls.fields as FormArray).removeAt(index);
        (this.formGroup.controls.fields as FormArray).insert(to, field);
    }

    /**
     * Move field down
     * @param {number} index
     */
    public moveDown(index: number): void {
        const field: AbstractControl = (this.formGroup.controls.fields as FormArray).controls[index];
        (this.formGroup.controls.fields as FormArray).removeAt(index);
        (this.formGroup.controls.fields as FormArray).insert(index + 1, field);
    }

    /**
     *
     * Add field value
     * @param {MatChipInputEvent} event
     * @param {number} index
     */
    public addValue(event: MatChipInputEvent, index: number): void {
        const input: HTMLInputElement = event.input;
        const value: string = event.value;
        if ((value || "").trim()) {
            const val: string[] = ((this.formGroup.controls.fields as FormArray)
                .controls[index] as FormGroup)
                .get("values").value;

            val.push(value.trim().substr(0, 90));

            ((this.formGroup.controls.fields as FormArray).controls[index] as FormGroup)
                .get("values").setValue(val);
        }
        if (input) {
            input.value = "";
        }
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Remove field value
     * @param value
     * @param {number} index
     */
    public removeValue(value: any, index: number): void {
        const valueIndex: number = ((this.formGroup.controls.fields as FormArray)
            .controls[index] as FormGroup)
            .get("values").value.indexOf(value);

        if (index >= 0) {
            const val: string[] = ((this.formGroup.controls.fields as FormArray)
                .controls[index] as FormGroup)
                .get("values").value;

            val.splice(valueIndex, 1);

            ((this.formGroup.controls.fields as FormArray).controls[index] as FormGroup)
                .get("values").setValue(val);
        }

        this.changeDetectorRef.markForCheck();
    }

    /**
     * Submit form
     * @return {Promise<any>}
     */
    public async submit(): Promise<any> {
        this.spinnerService.show();

        const response: Api.IResponse = await this.apiService.request(
            this.state.action === "edit" ? Api.EMethod.Put : Api.EMethod.Post,
            this.state.action === "edit" ? ["inspection", this.state.params.id] : ["inspection"],
            this.formGroup.value);

        if (response) {
            this.toastService.show(response.message, response.type as string);
            if (response.data && response.data.id) {
                this.router.navigate([
                    this.state.section,
                    "inspections",
                    "edit",
                    "id",
                    response.data.id
                ]);
            }
        }
        this.spinnerService.hide();
    }

    /**
     * Preview form
     * @return {Promise<any>}
     */
    public async preview(): Promise<any> {
        await this.modalService.open(CommonFormComponent, {
            formConfig: this.formGroup.value,
            noSubmit: true
        });
    }

    public ngOnInit(): void {
        this.section_type = this.state.section.split("/")[0];
        if (this.section_type === "warehouse") {
            this.getPartners();
        }
        this.getServiceLevels();
        this.getHubs();

        if (this.state.action === "edit") {
            this.prepareForm();
        } else {
            this.addField(
                "Inspection status",
                "status",
                ["Failed", "Passed"],
                true,
                false,
                "full"
            );
            this.addField(
                "Sign",
                "signature",
                [],
                true,
                false,
                "full"
            );
        }
        this.partnerSelect.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value: User.IPartner): void => {
                if (value) {
                    this.formGroup.get("partner_id").setValue(value.id);
                }
            });

        this.formGroup.get("fields").valueChanges
            .pipe(takeUntil(this.destroy$), debounceTime(100), distinctUntilChanged())
            .subscribe(() => {
                this.writeNames();
            });
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "inspections",
            actions: {
                "add": ["add_inspections"],
                "edit": ["edit_inspections"]
            }
        };
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
