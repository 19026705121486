import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation
} from "@angular/core";

import * as moment from "moment";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {distinctUntilChanged, takeUntil} from "rxjs/operators";
import {Api, ApiService} from "../../../services/api.service";
import {SpinnerService} from "../../../services/spinner.service";
import {ToastService} from "../../../services/toast.service";

export const SCHEDULER_DATES_OPTIONS: any = {
    year: Array.from({length: 11}, (v: any, k: number): number => k + moment().year()),
    month: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"],
    day: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    date: Array.from({length: 31}, (v: any, k: number): number => k + 1),
    hour: Array.from({length: 24}, (v: any, k: number): number => k),
    min: Array.from({length: 60}, (v: any, k: number): number => k)
};

@Component({
    selector: "common-scheduler-form",
    templateUrl: "form.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class SchedulerFormComponent implements OnInit, OnDestroy {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    private id: number;

    @Input()
    public ref: string;

    @Input()
    public type: string;

    @Input()
    public type_id: number = null;

    @Input()
    public params: any = null;

    @Output()
    public result: EventEmitter<any> = new EventEmitter();

    public required: { [key: string]: string[] } = {
        hourly: ["min"],
        daily: ["hour", "min"],
        weekly: ["day", "hour", "min"],
        monthly: ["date", "hour", "min"],
        yearly: ["month", "date", "hour", "min"],
        once: ["year", "month", "date", "hour", "min"]
    };

    public teams: any[] = [];

    public options: any = SCHEDULER_DATES_OPTIONS;

    public assignTeam: FormControl = new FormControl(false);

    public formGroup: FormGroup = new FormGroup({
        repeat: new FormControl("once", [Validators.required]),
        year: new FormControl(null, [Validators.required]),
        month: new FormControl(null, [Validators.required]),
        date: new FormControl(null, [Validators.required]),
        day: new FormControl(null),
        hour: new FormControl(null, [Validators.required]),
        min: new FormControl(null, [Validators.required]),
        team_id: new FormControl(null)
    });

    public constructor(
        private apiService: ApiService,
        private spinnerService: SpinnerService,
        private toastService: ToastService
    ) {

    }

    private setValidators(value: string): void {
        for (const key of Object.keys(this.formGroup.value)) {
            this.formGroup.get(key).clearValidators();
            this.formGroup.get(key).updateValueAndValidity();
        }
        for (const key of ["repeat", ...this.required[value]]) {
            this.formGroup.get(key).setValidators([Validators.required]);
            this.formGroup.get(key).updateValueAndValidity();
        }
    }

    private async getTeams(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["team"]);
        if (data) {
            this.teams = data;
        }
        this.spinnerService.hide();
    }


    public async submit(): Promise<any> {
        this.spinnerService.show();
        const {data, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["user", "tasks"], {
                ref: this.type + " " + this.ref,
                type: this.type,
                type_id: this.type_id,
                params: this.params,
                ...this.formGroup.value
            });
        if (data) {
            this.id = data.id;
            this.toastService.show(message, "success");

            this.result.emit(true);
        }
        this.spinnerService.hide();
    }

    public ngOnInit(): void {
        if (this.type === "App\\Report") {
            this.getTeams();
        }
        this.formGroup.get("repeat").valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
            .subscribe((value: string): void => {
                this.setValidators(value);
            });

        this.assignTeam.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((checked: boolean): void => {
            if (!checked) {
                this.formGroup.get("team_id").reset();
            }
        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
