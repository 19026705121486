<h1 class="container-heading">Integrations</h1>
<mat-card>
    <mat-card-content>

        <div class="integrations">
            <div *ngFor="let item of items" [matTooltip]="item.description" (click)="showItem(item.id)">
                <img [src]="item.icon" alt="">
                <div>{{item.name}}</div>
                <span><small>{{item.description}}</small></span>
            </div>
            <div matTooltip="Http Logs" (click)="goToLogs()">
                <img src="/src/assets/images/logs.png" alt="Http Logs">
                <div>Http Logs</div>
                <span><small>Review http logs</small></span>
            </div>
        </div>

    </mat-card-content>
</mat-card>
