import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    ViewEncapsulation
} from "@angular/core";


export class FormBuilder {
    protected destroy$: EventEmitter<boolean> = new EventEmitter(false);


    protected get newField(): {
        label: string,
        type: string,
        values: string[],
        required: boolean,
        multiple: boolean,
        size: string,
        url: string,
        description: string
    } {
        return {
            label: null,
            type: "input",
            values: [],
            required: false,
            multiple: false,
            size: "full",
            url: null,
            description: null
        };
    }

    protected get newGroup(): {
        label: string,
        type: string,
        fields: any[]
    } {
        return {label: null, type: "group", fields: []};
    }

    /**
     * Convert string to snake_case
     * @param {string} label
     * @return {string}
     */
    protected labelToName(label: string): string {
        return label
            ? label.toLowerCase().replace(/ /g, "_")
                .replace(/[^a-z0-9_]/g, "")
            : null;
    }


}

@Component({
    selector: "common-form-builder",
    templateUrl: "builder.component.html",
    styleUrls: [
        "builder.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class FormBuilderComponent extends FormBuilder implements OnDestroy {


    @Input()
    public fields: any[] = [];

    @Output()
    public onFormChange: EventEmitter<any> = new EventEmitter<any>();


    public constructor(private changeDetectorRef: ChangeDetectorRef) {
        super();
    }

    /**
     * Add new field
     */
    public addField(): any {
        this.fields.push(this.newField);
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Add new group
     */
    public addGroup(): any {
        this.fields.push(this.newGroup);
        this.changeDetectorRef.markForCheck();
    }

    public onMoveUp(index: number): void {
        const field: any = this.fields[index];
        this.fields.splice(index, 1);
        this.fields.splice(index - 1, 0, field);
        this.changeDetectorRef.markForCheck();
    }

    public onMoveDown(index: number): void {
        if (index === this.fields.length - 1) {
            return;
        }
        const field: any = this.fields[index];
        this.fields.splice(index, 1);
        this.fields.splice(index + 1, 0, field);
        this.changeDetectorRef.markForCheck();
    }

    public onDelete(index: number): void {
        this.fields.splice(index, 1);
        this.changeDetectorRef.markForCheck();
    }

    public onChanges(index: number, event: any): void {
        this.fields[index] = event;
        this.changeDetectorRef.markForCheck();
    }

    public onMoveTo(index: number, event: number): void {
        const field: any = this.fields[index];
        this.fields.splice(index, 1);
        this.fields.splice(event, 0, field);
        this.changeDetectorRef.markForCheck();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
