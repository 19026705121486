import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../common/interfaces/wizard.interface";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {HelpersService} from "../../../../../../../common/services/helpers.service";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {ModalService} from "../../../../../../services/modal.service";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {Form} from "../../../../../../../common/interfaces/form.interface";
import {ConfirmComponent} from "../../../../../../../common/components/confirm/confirm.component";
import {debounceTime, takeUntil} from "rxjs/operators";
import ISelectOption = Form.ISelectOption;

@Component({
    selector: "section-partner-procedures-wizard-serial-to-qty",
    templateUrl: "serial-to-qty.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class PartnerProceduresWizardSerialToQtyComponent extends AbstractWizardStepComponent implements OnDestroy {

    private state: Base.IState;

    public part_masters: any[] = [];

    public warehouses: ISelectOption[] = [{value: null, name: "Select item first"}];

    public form: FormGroup = new FormGroup({
        part_master_id: new FormControl(null, [Validators.required]),
        warehouse_id: new FormControl(null, [Validators.required]),
        remark: new FormControl(null, [Validators.required]),
    });

    @ViewChild(ConfirmComponent, {static: true})
    public confirm: ConfirmComponent;

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        public helperService: HelpersService,
        private apiService: ApiService,
        private toastService: ToastService,
        private modalService: ModalService,
        private spinnerService: SpinnerService
    ) {
        super(changeDetectorRef);
    }

    private async getPartMasters(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["partmaster"], {},
            {
                data_structure: "select",
                no_serial: false,
                in_stock: true
            });
        if (data) {
            this.part_masters = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private async getWarehouses(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["warehouse"], {},
            {
                part_master_id: this.form.value.part_master_id,
                relations: [
                    "Threepl"
                ]
            });
        if (data) {
            this.warehouses = [];
            for (const wh of data) {
                this.warehouses.push({value: wh.id, name: wh.threepl.display_name + " - " + wh.name});
            }
            if (this.warehouses.length === 0) {
                this.warehouses.push({value: null, name: "No warehouses for selected item"});
            }
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }


    /**
     * Submit form
     */
    public async submit(): Promise<any> {
        if (await this.confirm
            .confirm("Are you sure want to change this item to quantity based? This action is unrevartable!")) {
            this.spinnerService.show();
            const {type, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
                ["inventory", "serial-to-qty-change"], this.form.value);
            if (type as string === "success") {
                this.toastService.show(message, "success");
                this.form.reset();
                this.changeDetectorRef.markForCheck();
            }
            this.spinnerService.hide();
        }
    }

    /**
     * Initialize step
     * @param data
     * @returns {Promise<any>}
     */
    public async init(data: Wizard.IData): Promise<any> {
        this.state = data.state;

        this.result.emit({
            action: "result",
            value: true
        });

        this.getPartMasters();

        this.form.get("part_master_id").valueChanges.pipe(takeUntil(this.destroy$), debounceTime(100))
            .subscribe((): void => {
                this.getWarehouses();
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
