import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, ViewEncapsulation} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../common/interfaces/wizard.interface";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {HelpersService} from "../../../../../../../common/services/helpers.service";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {ModalService} from "../../../../../../services/modal.service";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {debounceTime, takeUntil} from "rxjs/operators";
import {Warehouse} from "../../../../../../../common/interfaces/warehouse.interface";
import {Api3Service} from "../../../../../../../common/services/api3.service";
import {Form} from "../../../../../../../common/interfaces/form.interface";
import ISelectOption = Form.ISelectOption;

@Component({
    selector: "section-partner-procedures-wizard-update-hub",
    templateUrl: "update-hub.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class PartnerProceduresWizardUpdateHubComponent extends AbstractWizardStepComponent implements OnDestroy {

    private state: Base.IState;

    private maxQuantities: { [key: string]: number } = {};

    private serials: { [key: string]: string[] } = {};

    public hubs: { group: string, hubs: Warehouse.IHub[] }[] = [];

    public available_hubs: { group: string, hubs: Warehouse.IHub[] }[];

    public available_serials: string[] = [];

    public old_hub: FormControl = new FormControl(null, [Validators.required]);

    public maxQty: number = 1;


    public form: FormGroup = new FormGroup({
        inventory_conversion_id: new FormControl(null, [Validators.required]),
        inventory_id: new FormControl(null, [Validators.required]),
        quantity: new FormControl(null, [Validators.required, Validators.min(1)]),
        remark: new FormControl(null, [Validators.required]),
        serials: new FormControl([]),
    });

    public inventories: { name: string, value: any }[] = [];

    public part_masters: ISelectOption[];

    public part_master_id: FormControl = new FormControl(null);

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        public helperService: HelpersService,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private spinnerService: SpinnerService
    ) {
        super(changeDetectorRef);
    }

    private async getPartMasters(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.get(`${this.state.section}/part-masters`,
            {
                data_structure: "select",
                in_stock: true
            });
        if (data) {
            this.part_masters = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    public async getInventories(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.get(`${this.state.section}/inventories`,
            {
                hub_id: this.old_hub.value,
                part_master_id: this.part_master_id.value,
                available_only: true,
                relations: [
                    "WarehouseLocation:id,location"
                ]
            });
        this.spinnerService.hide();
        if (data) {
            const inventories: {
                [key: string]:
                    { inventory: Warehouse.IInventory, sum: number, serials: string[] }
            } = {};
            for (const inventory of data) {
                const key: string = inventory.item + ":" + (inventory.configurations || "")
                    + ":" + (inventory.rev || "") + ":" + (inventory.batch || "")
                    + ":" + (inventory.warehouse_location_id || "");

                if (inventories[key]) {
                    inventories[key].sum += inventory.quantity;
                    inventories[key].serials.push(inventory.serial);
                } else {
                    inventories[key] = {
                        inventory,
                        sum: inventory.quantity,
                        serials: [inventory.serial]
                    };
                }
            }

            this.inventories = [];
            this.serials = {};
            this.maxQuantities = {};

            for (const row of Object.values(inventories)) {

                const location: string = row.inventory.warehouse_location
                    ? "| Location: " + row.inventory.warehouse_location.location
                    : "";

                this.inventories.push({
                    name: (`${row.inventory.item} ${HelpersService.getConfigNames(row.inventory)}
                        ${row.inventory.rev || ""} ${row.inventory.batch || ""} ${location} | Qty: ${row.sum}`)
                        .replace(/\s\s/g, " "),
                    value: row.inventory.id
                });

                this.serials["" + row.inventory.id] = row.serials.filter((serial: string): boolean => !!serial);

                this.maxQuantities["" + row.inventory.id] = row.sum;
            }
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Submit form
     */
    public async submit(): Promise<any> {
        this.spinnerService.show();
        const {type, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["inventory", "item-hub-change"], this.form.value);
        if (type as string === "success") {
            this.toastService.show(message, "success");
            this.form.reset();
            this.inventories = null;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Initialize step
     * @param data
     * @returns {Promise<any>}
     */
    public async init(data: Wizard.IData): Promise<any> {
        this.state = data.state;

        this.result.emit({
            action: "result",
            value: true
        });

        this.getPartMasters();

        this.old_hub.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(100))
            .subscribe((val: number): void => {
                if (val) {
                    this.getInventories();
                }
                this.form.get("inventory_conversion_id").reset();
            });

        this.part_master_id.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(100))
            .subscribe((val: number): void => {
                if (val) {
                    this.getInventories();
                }
            });

        this.form.get("inventory_id").valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((val: number): void => {
                this.form.get("serials").setValue([]);
                this.form.get("quantity").setValue(null);
                this.form.get("quantity").setValidators([
                    Validators.required,
                    Validators.min(1),
                    Validators.max(this.maxQuantities[val])
                ]);

                this.maxQty = this.maxQuantities[val];

                if (this.serials["" + val] && this.serials["" + val].length) {
                    this.available_serials = this.serials["" + val];
                } else {
                    this.available_serials = [];
                }
                this.changeDetectorRef.markForCheck();
            });

        this.form.get("serials").valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((serials: string[]): void => {
                this.form.get("quantity").setValue(serials.length);
                this.changeDetectorRef.markForCheck();
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
