import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Router} from "@angular/router";
import {SpinnerComponent} from "../../../../../../common/components/spinner/spinner.component";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {
    WarehouseOrderWizardCourierTransactionsComponent,
    WarehouseOrderWizardCourierTransactionsToShipmentComponent,
    WarehouseOrderWizardSelectParcelComponent,
    WarehouseOrderWizardSelectShipmentComponent,
    WarehouseOrderWizardShipmentsTypeComponent,
    WarehouseOrderWizardWarehouseTransactionsComponent,
} from "../index";
import {RemarksSidebarComponent} from "../../../../common/components/order";
import {UserService} from "../../../../../../common/services/user.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {ChangeStatusFormComponent} from "../../../../../../common/components/status/status.component";
import {Order} from "../../../../../../common/interfaces/order.interface";
import {AbstractWizardComponent, Wizard, WizardStepFactory} from "../../../../../../common/interfaces/wizard.interface";
import {User} from "../../../../../../common/interfaces/user.interface";
import {FileUploadComponent} from "../../../../../../common/components/file-upload/file-upload.component";
import {SpinnerService} from "../../../../../../common/services/spinner.service";

@Component({
    selector: "section-warehouse-order-wizard",
    templateUrl: "wizard.component.html",
    styleUrls: [
        "wizard.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class WarehouseOrderWizardComponent extends AbstractWizardComponent
    implements Base.IComponent, OnInit, AfterViewInit {

    private stepTypes: { [key: string]: Wizard.IStep } = {
        "Shipment type": new WizardStepFactory("Shipment type", WarehouseOrderWizardShipmentsTypeComponent),
        "Order shipments": new WizardStepFactory("Order shipments", WarehouseOrderWizardSelectShipmentComponent),
        "Order parcels": new WizardStepFactory("Order parcels", WarehouseOrderWizardSelectParcelComponent),
        "Warehouse transactions":
            new WizardStepFactory("Warehouse transactions", WarehouseOrderWizardWarehouseTransactionsComponent),
        "Courier transactions":
            new WizardStepFactory("Courier transactions", WarehouseOrderWizardCourierTransactionsToShipmentComponent),
    };

    @ViewChild("sidebar", {static: false})
    public sidebarRef: RemarksSidebarComponent;

    public canGoToOrder: boolean = false;

    public orderData: Order.IOrderData;

    public status: any;

    public readonly state: Base.IState;

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private apiService: ApiService,
        private userService: UserService,
        private modalService: ModalService,
        private spinnerService: SpinnerService
    ) {
        super(changeDetectorRef);
    }


    /**
     * Check if user has access to order partner
     */
    private checkUserHasPartner(): void {
        const partner: User.IPartner = this.userService.data.partners
            .find((item: User.IPartner): boolean => {
                return item.slug === this.data.orderData.partner.slug;
            });
        if (partner) {
            this.canGoToOrder = true;
        }
    }

    /**
     * Get order data
     * @returns {Promise<any>}
     */
    private async getOrder(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["warehouse", "order", this.state.params.order]);
        if (data) {
            this.orderData = data.order;
            this.data.orderData = data.order;

            this.status = data.status;
            this.showSidebar();
            this.checkUserHasPartner();
        }
        this.spinnerService.hide();
        this.changeDetectorRef.markForCheck();
    }

    protected handleSetupEvent(event: Wizard.IStepResult, stepIndex: number): void {
        if (this.state.params.type === "rma") {
            this.steps.splice(2, 3);
            if (event.value === "inbound") {
                this.steps.push(
                    new WizardStepFactory("Courier transactions",
                        WarehouseOrderWizardCourierTransactionsToShipmentComponent));
                this.steps.push(
                    new WizardStepFactory("Order parcels", WarehouseOrderWizardSelectParcelComponent));
                this.steps.push(
                    new WizardStepFactory("Warehouse transactions",
                        WarehouseOrderWizardWarehouseTransactionsComponent));
            } else {
                this.steps.push(
                    new WizardStepFactory("Order parcels", WarehouseOrderWizardSelectParcelComponent));
                this.steps.push(
                    new WizardStepFactory("Warehouse transactions",
                        WarehouseOrderWizardWarehouseTransactionsComponent));
                this.steps.push(
                    new WizardStepFactory("Courier transactions", WarehouseOrderWizardCourierTransactionsComponent));
            }
        }

        if (this.state.params.type === "transfer") {
            if (event.value === "outbound" && this.steps[stepIndex + 1].title === "Courier transactions") {
                this.steps.splice(stepIndex + 1, 1);
                this.steps.push(
                    new WizardStepFactory("Courier transactions",
                        WarehouseOrderWizardCourierTransactionsToShipmentComponent));
            }
        }
    }


    /**
     * Finish wizard and redirect back to order
     */
    public finish(): void {
        const url: string[] = atob(this.state.params.back_to).split("/")
            .filter((item: string): boolean => {
                return item.trim() !== "";
            });
        this.router.navigate(url);
    }

    /**
     * Open sidebar with remarks
     * @param {boolean} force
     */
    public showSidebar(force: boolean = false): void {
        this.sidebarRef.show(this.data.orderId, this.data.orderData.ref, force);
    }

    /**
     * Open modal to change order status
     * @returns {Promise<any>}
     */
    public async changeStatus(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(ChangeStatusFormComponent, {
            id: this.state.params.order,
            type: "warehouse_order",
            status: this.status.id,
            modalWidth: 450
        });
        if (response) {
            this.getOrder();
        }
    }


    public async uploadWhTransactions(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(FileUploadComponent, {
            templatePath: async (spinnerService: SpinnerComponent, button: HTMLElement): Promise<any> => {
                spinnerService.show();
                const url: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
                    ["order", this.data.orderData.ref, "open_items"]);
                if (url.data && url.data.length > 0) {
                    button.innerHTML = "<a href='" + url.data[0] + "' target='_blank' " +
                        "class='mat-mdc-raised-button mdc-button mat-accent'>Download</a>";
                }
                spinnerService.hide();
            },
            templateLabel: "Get list of open items",
            accept: ".csv",
            async: true,
            url: ["transaction"]
        });
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public ngAfterViewInit(): void {
        this.getOrder().then((): void => {
            for (const orderStep of this.data.orderData.service_level.order_type.steps) {
                const step: Wizard.IStep = this.stepTypes[orderStep.name];
                step.title = orderStep.name;
                step.description = orderStep.description;
                step.icon = orderStep.icon;
                this.steps[orderStep.step] = step;
            }
            this.changeDetectorRef.markForCheck();
            setTimeout((): void => {
                this.stepper.selectedIndex = 0;
                this.handleStepChanges();
            }, 100);
        });
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "warehouse-order",
            actions: {
                "add": ["edit_warehouse_orders"]
            }
        };
    }

}
