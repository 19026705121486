import {Injectable} from "@angular/core";
import {Api} from "../../../../common/services/api.service";
import {Api3Service} from "../../../../common/services/api3.service";


@Injectable()
export class AdminPartnerCarrierService {

    public constructor(private api3Service: Api3Service) {
    }

    public async updateList(partner_id: number, state: boolean, list: any): Promise<Api.IResponse> {
        return this.api3Service.request(state ? Api.EMethod.Put : Api.EMethod.Delete,
            `/admin/partners/${partner_id}/carriers/`, list);
    }

    public async getList(partner_id: number): Promise<Api.IResponse> {
        return await this.api3Service.request(Api.EMethod.Get, `admin/partners/${partner_id}/carriers`);
    }
}
