export namespace Form {

    export interface IConfig {
        id: number;
        name: string;
        description: string;
        fields: IField[];
        values?: { [key: string]: any };
    }

    export interface IField {
        label: string;
        name: string;
        size: string;
        type: string;
        required: boolean;
        values?: string[] | { name: string, value: any }[];
        url?: string;
        url3?: string;
        api_version?: number;
        multiple?: boolean;
        fields?: IField[];
        min_date?: string;
        max_date?: string;
        params?: any;
        accept?: any;
    }

    export interface ISelectOption {
        name: string;
        value: any;
        disabled?: boolean;
    }


    export const FIELD_TYPES: { [key: string]: any } = {
        input: {
            options: true,
            delete: true,
            type: true,
            required: true,
            pattern: true
        },
        select: {
            options: true,
            delete: true,
            type: true,
            required: true,
            multiple: true
        },
        checkbox: {
            options: false,
            delete: true,
            required: true,
        },
        radio: {
            options: true,
            delete: true,
            type: true,
            required: true,
        },
        text: {
            options: true,
            delete: true,
            type: true,
            required: true,
        },
        search: {
            delete: true,
            type: true,
            required: false,
            multiple: true,
            url: true
        },
        file: {
            options: false,
            delete: true,
            type: true,
            required: true,
        },
        barcode: {
            options: false,
            delete: true,
            type: true,
            required: true,
            multiple: true
        },
        signature: {
            options: false,
            delete: true,
            type: true,
            required: true,
        },
        status: {
            options: true,
            delete: true,
            type: false,
            required: true,
        },
        filler: {
            options: false,
            delete: true,
            type: true,
            required: false,
        },
        date: {
            options: false,
            delete: true,
            type: true,
            required: true,
            min_date: true,
            max_date: true,
        },
        date_range: {
            options: false,
            delete: true,
            type: true,
            required: true,
            min_date: true,
            max_date: true,
        },
        link: {
            options: false,
            delete: true,
            type: true,
            required: false,
            url: true
        },
        free_text: {
            options: false,
            delete: true,
            type: true,
            description: true
        },
        label: {
            options: false,
            delete: true,
            type: true,
            required: true,
        },
        group: {
            options: false,
            delete: true,
            type: true,
            required: false,
        },
        email_inspection: {
            required: true,
            delete: true,
            type: true,
        },
    };

}
