<h1 class="container-heading">{{modal.params.data.name}}</h1>

<mat-card>
    <mat-card-content *ngIf="this.modal.params.action !== 'json'">
        <div class="half-part">
            <cdk-virtual-scroll-viewport itemSize="5" class="scroll-viewport">
                <ng-template #recursiveAccordion let-list>
                    <mat-accordion>
                        <mat-expansion-panel [@.disabled]="true">
                            <mat-expansion-panel-header>
                                <b>{{list.key}}</b>
                            </mat-expansion-panel-header>
                            <ng-template ngFor let-exp_value [ngForOf]="list.value | keyvalue">
                                <ng-template [ngIf]="typo(exp_value.value) !== 'object'">
                                    <b>{{exp_value.key}}</b>: {{exp_value.value}}<br/>
                                </ng-template>
                                <ng-template [ngIf]="typo(exp_value.value) === 'object'">
                                    <ng-container
                                            *ngTemplateOutlet="recursiveAccordion; context:{ $implicit: exp_value }"></ng-container>
                                </ng-template>
                            </ng-template>
                        </mat-expansion-panel>
                    </mat-accordion>
                </ng-template>
                <div *cdkVirtualFor="let item of request | keyvalue" class="scroll-item-detail">
                    <ng-template [ngIf]="typo(item.value) !== 'object'">
                        <b>{{item.key}}</b>: {{item.value}}
                    </ng-template>
                    <ng-template [ngIf]="typo(item.value) === 'object'">
                        <ng-container
                                *ngTemplateOutlet="recursiveAccordion; context:{ $implicit: item }"></ng-container>
                    </ng-template>
                </div>
            </cdk-virtual-scroll-viewport>
        </div>
        <div class="half-part">
            <cdk-virtual-scroll-viewport itemSize="5" class="scroll-viewport">
                <div *cdkVirtualFor="let item of response | keyvalue" class="scroll-item-detail">
                    <ng-template [ngIf]="typo(item.value) !== 'object'">
                        <b>{{item.key}}</b>: {{item.value}}
                    </ng-template>
                    <ng-template [ngIf]="typo(item.value) === 'object'">
                        <ng-container
                                *ngTemplateOutlet="recursiveAccordion; context:{ $implicit: item }"></ng-container>
                    </ng-template>
                </div>
            </cdk-virtual-scroll-viewport>
        </div>
    </mat-card-content>
    <mat-card-content *ngIf="this.modal.params.action === 'json'">
        <div class="full-part">
            <h2>Request</h2>
            <cdk-virtual-scroll-viewport itemSize="5" class="scroll-viewport">
                {{request}}
            </cdk-virtual-scroll-viewport>
        </div>
        <div class="full-part">
            <h2>Response</h2>
            <cdk-virtual-scroll-viewport itemSize="5" class="scroll-viewport">
                {{response}}
            </cdk-virtual-scroll-viewport>
        </div>
    </mat-card-content>
</mat-card>
