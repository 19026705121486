import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    EventEmitter,
    Type,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from "@angular/core";
import {take} from "rxjs/operators";
import {Modal} from "../../../section/services/modal.service";
import {Subject} from "rxjs";

@Component({
    selector: "common-sidebar",
    template: `
        <div *ngIf="showOverlay" id="sidebar_overlay" (click)="openSubject.next()"></div>
        <div id="sidebar" class="{{isOpen ? 'open' : ''}}">
            <div id="sb_flag" (click)="openSubject.next()">
                <mat-icon>keyboard_arrow_right</mat-icon>
            </div>
            <div class="content">
                <ng-template #container></ng-template>
            </div>
        </div>
    `,
    styleUrls: [
        "sidebar.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class SidebarComponent {

    public showOverlay: boolean = false;
    public isOpen: boolean = false;

    public openSubject: Subject<void> = new Subject();

    @ViewChild("container", {read: ViewContainerRef, static: false})
    public componentContainer: ViewContainerRef;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private componentFactoryResolver: ComponentFactoryResolver
    ) {
    }

    public async show(C: Type<any>, params: any): Promise<any> {
        this.componentContainer.clear();
        const componentFactory: any = this.componentFactoryResolver.resolveComponentFactory(C);
        const componentContainerRef: any = this.componentContainer.createComponent(componentFactory);
        const component: any = componentContainerRef.instance;

        component.modal = {params, response: new EventEmitter<Modal.IResponse>()};
        component.modal.params = params;
        this.isOpen = true;
        this.changeDetectorRef.markForCheck();

        return new Promise((resolve: Function): void => {
            component.modal.response.pipe(take(1)).subscribe((response: Modal.IResponse): void => {
                resolve(response);
            });

            this.openSubject.asObservable().pipe(take(1)).subscribe((): void => {
                resolve();
                this.isOpen = false;
                this.changeDetectorRef.markForCheck();
            });
        });
    }
}
