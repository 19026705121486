<common-confirm></common-confirm>

<h3 class="mat-h3">Partner service levels</h3>
<div *ngIf="formGroupArray.length < 1">
    No any data
    <br>
    <br>
</div>
<mat-accordion *ngIf="formGroupArray.length > 0">
    <ng-template ngFor let-form let-i="index" [ngForOf]="formGroupArray">
        <mat-expansion-panel *ngIf="form">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <b>{{form.value.display_name}}</b>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <form [formGroup]="form" autocomplete="off" (submit)="submitCoverage(i)">
                <div class="group flex">
                    <mat-form-field class="full">
                        <mat-label>Display name</mat-label>
<input matInput formControlName="display_name"  required>
                    </mat-form-field>

                    <button mat-raised-button [disabled]="!form.valid || !form.dirty" color="primary" type="submit">
                        Update
                    </button>

                    <button mat-raised-button color="warn" class="delete-button" type="button"
                            (click)="submitDelete(i)">Delete
                    </button>
                </div>
            </form>
        </mat-expansion-panel>
    </ng-template>
</mat-accordion>


<h3 class="mat-h3">Add new partner service level</h3>
<form *ngIf="newCoverageForm" [formGroup]="newCoverageForm" autocomplete="off" (submit)="submitNewCoverage()">
    <div class="group flex">
        <mat-form-field class="full">
            <mat-label>Display name</mat-label>
<input matInput formControlName="display_name"  required>
        </mat-form-field>
    </div>
    <div class="actions">
        <button mat-raised-button [disabled]="!newCoverageForm.valid" color="primary" type="submit">Submit</button>
        <button mat-raised-button (click)="newCoverageForm.reset()" type="button">Reset</button>
    </div>
</form>


