<div class="margin-bottom-10">
    <mat-checkbox [formControl]="checked">
        Print me
    </mat-checkbox>
</div>

<div #sticker>
    <div class="sticker">
        <table style="margin-bottom: 15px">
            <tr *ngIf="partner">
                <td colspan="2" class="text-center">
                    <div class="title">{{partner.display_name}}</div>
                </td>
            </tr>
            <tr>
                <td style="width:45%">
                    <div>
                        Order ref: <b>{{orderRef}}</b><br/>
                        Pallet ID: <b>{{partner ? partner.display_name === 'Annapurna Labs'
                            ? "ANPA" : "" : ''}}{{pack.id}}</b><br/>
                        Size:
                        L<b>{{pack.length || '0'}}</b>
                        x
                        W<b>{{pack.width || '0'}}</b>
                        x
                        H<b>{{pack.height || '0'}}</b>
                        cm<br/>
                        Weight: <b>{{pack.weight || '0'}}</b> {{pack.mass_unit}}<br/>
                        Package №: <b>{{pack_number}} of {{pack_total}}</b>
                    </div>
                    <common-barcode *ngIf="partner && partner.display_name === 'Annapurna Labs'; else notANPA"
                                    [bcValue]="'ANPA'+pack.id" [bcDisplayValue]="false"
                                    bcWidth="1"
                                    [bcMarginTop]="1"
                                    [bcMarginLeft]="1"
                                    [bcMarginRight]="1"
                                    [bcMarginBottom]="1"
                                    bcHeight="30"></common-barcode>
                    <ng-template #notANPA>
                        <common-barcode [bcValue]="pack.id" [bcDisplayValue]="false"
                                        bcWidth="1"
                                        [bcMarginTop]="1"
                                        [bcMarginLeft]="1"
                                        [bcMarginRight]="1"
                                        [bcMarginBottom]="1"
                                        bcHeight="30"></common-barcode>
                    </ng-template>
                </td>
                <td>
                    <div>
                        Boxes qty: <b>{{pack.boxes.length}}</b><br/><br/>
                        <b>From:</b><br/>
                        {{from_address.address}}<br/>
                        {{from_address.address2}}
                        {{from_address.city}}, {{from_address.zip}}, {{from_address.country}}<br/>
                        <b>To:</b><br/>
                        {{to_address.address}}<br/>
                        {{to_address.address2}}
                        {{to_address.city}}, {{to_address.zip}}, {{to_address.country}}<br/>
                    </div>
                    <common-barcode [bcValue]="pack.boxes.length" [bcDisplayValue]="false"
                                    bcWidth="1"
                                    [bcMarginTop]="1"
                                    [bcMarginLeft]="1"
                                    [bcMarginRight]="1"
                                    [bcMarginBottom]="1"
                                    bcHeight="30"></common-barcode>
                </td>
            </tr>
        </table>
        <table>
            <tr *ngFor="let item of items | keys">
                <td>
                    <div>{{partner ? partner.display_name === 'Annapurna Labs'
                            ? "Annapurna's " : partner.display_name + "'s " : ''}}PN : <b>{{item}}</b></div>
                    <common-barcode [bcValue]="item" [bcDisplayValue]="false"
                                    bcWidth="1"
                                    [bcMarginTop]="1"
                                    [bcMarginLeft]="1"
                                    [bcMarginRight]="1"
                                    [bcMarginBottom]="1"
                                    bcHeight="20"></common-barcode>
                </td>
                <td>
                    <div>Qty: <b>{{items[item]}}</b></div>
                    <common-barcode [bcValue]="items[item]" [bcDisplayValue]="false"
                                    bcWidth="1"
                                    [bcMarginTop]="1"
                                    [bcMarginLeft]="1"
                                    [bcMarginRight]="1"
                                    [bcMarginBottom]="1"
                                    bcHeight="20"></common-barcode>

                </td>
            </tr>
            <tr>
                <td colspan="2">
                    <ng-container *ngIf="partner && partner.display_name === 'Annapurna Labs'">
                        Made in Taiwan{{to_address.country_iso_2 === 'CN' ? ', China' : ''}}
                    </ng-container>
                </td>
            </tr>
        </table>
    </div>
</div>
