import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    Output,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {GoogleMapsApiService} from "../../../services/google-maps-api.service";


@Component({
    selector: "common-gmap-places-autocomplete",
    template: `
        <mat-form-field class="full">
            <mat-label>Enter your address</mat-label>
<input matInput #search >
        </mat-form-field>
    `,
    styles: [
        `
        common-gmap-places-autocomplete {
            width: 100%;
        }
    `
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GmapPlacesAutocompleteComponent implements AfterViewInit, OnDestroy {

    private latitude: number;

    private longitude: number;

    private place: google.maps.places.PlaceResult;

    public autocomplete: any;

    @ViewChild("search", {static: false})
    public searchElementRef: ElementRef;

    /**
     * Address model example
     * addressModel: any = {
     *   street_number: "short_name",
     *   route: "long_name",
     *   locality: "long_name",
     *   administrative_area_level_1: "short_name",
     *   country: "short_name",
     *   postal_code: "short_name"
     * };
     */
    @Input()
    public addressModel: any;

    @Output()
    public result: EventEmitter<any> = new EventEmitter<any>();

    public constructor(
        private googleMapsApiService: GoogleMapsApiService,
        private ngZone: NgZone
    ) {
    }

    private fillInAddress(): void {
        const response: any = {};
        for (const address_component of this.place.address_components) {
            const addressType: string = address_component.types[0];
            if (this.addressModel[addressType]) {
                response[addressType] = address_component[this.addressModel[addressType]];
            }
        }
        this.result.emit({place: this.place, model: response, lat: this.latitude, lng: this.longitude});
    }

    private getPlaceAutocomplete(): void {
        const ac: any = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
            types: ["address"]
        });
        this.autocomplete = ac.addListener("place_changed", (): void => {
            this.ngZone.run((): void => {
                this.place = ac.getPlace();
                if (this.place.geometry === undefined || this.place.geometry === null) {
                    return;
                }
                this.latitude = this.place.geometry.location.lat();
                this.longitude = this.place.geometry.location.lng();

                this.fillInAddress();
            });
        });
    }

    public ngAfterViewInit(): void {
        this.googleMapsApiService.load().subscribe((): void => {
            this.getPlaceAutocomplete();
        });
    }

    public ngOnDestroy(): void {
        if (this.autocomplete) {
            this.autocomplete.remove();
        }
    }
}
