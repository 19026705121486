import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import {Router} from "@angular/router";
import {Table, TableComponent} from "../../../../../../common/components/table/table.component";
import {ApiService} from "../../../../../../common/services/api.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";


@Component({
    selector: "section-couriers-list",
    template: `
        <common-confirm></common-confirm>

        <h1 class="container-heading">Couriers</h1>
        <mat-card>
            <mat-card-content>
                <common-table *ngIf="listTable" [options]="listTable" (action)="handleListAction($event)">
                    <div data-actions>
                        <button mat-raised-button color="primary" class="main" (click)="showAddForm()">Add</button>
                    </div>
                </common-table>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CouriersListComponent implements Base.IComponent, OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    @ViewChild(TableComponent, {static: false})
    public tableRef: TableComponent;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.IOptions;

    public showExportButton: boolean = false;

    public readonly state: Base.IState;

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {

        this.listTable = {
            api: (this.state.action === "filter"
                    ? this.apiService.getUrl(["shipping_mode", this.state.params["shipping-mode"], "couriers"])
                    : this.apiService.getUrl(["courier"]))
                + "?data_structure=dataTables",
            actions: [
                {
                    name: "edit",
                    title: "Edit"
                },
            ],
            columns: [
                {
                    data: "display_name",
                    title: "Name"
                },
                {
                    data: "slug",
                    title: "Slug"
                },
                {
                    data: "icon_path",
                    title: "",
                    render: (data: string): string => {
                        return data ? "<img class='icon' src='" + data + "' " + "alt=''>" : "";
                    },
                    orderable: false,
                    searchable: false
                },
                {
                    data: "id",
                    title: "",
                    render: (): string => {
                        return "<button class='tableAction mat-mdc-raised-button button-150' " +
                            "data-action='services' type='button'>"
                            + "Services"
                            + "</button>";
                    },
                    searchable: false,
                    orderable: false
                },
            ],
            order: [],
            search: true
        };

        this.changeDetectorRef.markForCheck();
    }

    /**
     * Handle list row action
     * @param {Table.Action.IResult} action
     * @returns {Promise<any>}
     */
    public async handleListAction(action: Table.Action.IResult): Promise<any> {
        if (action.name === "edit") {
            const pathArray: string[] = [
                this.state.section,
                this.state.component,
                "edit",
                "id",
                action.data.id
            ];
            if (this.state.action === "filter") {
                pathArray.push("shipping-mode");
                pathArray.push(this.state.params["shipping-mode"]);
            }
            this.router.navigate(pathArray)
            ;
        } else if (action.name === "services") {
            this.router.navigate([
                this.state.section,
                "courier-services",
                "filter",
                "courier",
                action.data.slug
            ]);
        }
    }

    public showAddForm(): void {
        const pathArray: string[] = [
            this.state.section,
            this.state.component,
            "add"
        ];
        if (this.state.action === "filter") {
            pathArray.push("shipping-mode");
            pathArray.push(this.state.params["shipping-mode"]);
        }
        this.router.navigate(pathArray);
    }

    public ngOnInit(): void {
        this.prepareList();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "couriers",
            actions: {
                "browse": ["browse_couriers"],
                "filter": ["browse_couriers"]
            }
        };
    }
}
