import {ComponentRef, EventEmitter, Injectable, Type} from "@angular/core";
import {Overlay, OverlayConfig, OverlayRef} from "@angular/cdk/overlay";
import {ComponentPortal} from "@angular/cdk/portal";
import {Router} from "@angular/router";
import {take} from "rxjs/operators";
import {User} from "../../common/interfaces/user.interface";
import {PartnerService} from "../../common/services/partner.service";
import {StorageService} from "../../common/services/storage.service";
import moment from "moment";

export namespace Modal {

    export interface IResponse {
        name: string;
        value?: any;
    }

    export interface IModal {
        params: any;
        response: EventEmitter<IResponse>;
        close_all: EventEmitter<IResponse>;
    }

}

@Injectable()
export class ModalService {

    public static modals: { [key: string]: OverlayRef } = {};

    public constructor(
        private overlay: Overlay,
        private router: Router,
        private storageService: StorageService
    ) {
    }


    private getThemeClass(): string {
        const segments: string[] = this.router.url.split("/");
        let section: string;
        if (segments[0] === "") {
            section = segments[1] === "partner" ? segments[2] : segments[1];
        } else {
            section = segments[0];
        }
        let partner: User.IPartner = null;
        if (PartnerService.partner) {
            partner = PartnerService.partner;
        }
        return "theme-" + (partner ? partner.style_path : "grey");
    }

    /**
     * Get overlay config (modal window)
     * @returns {OverlayConfig}
     */
    private getOverlayConfig(params: any): OverlayConfig {
        const themeClass: string = this.getThemeClass();
        return new OverlayConfig({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            scrollStrategy: this.overlay.scrollStrategies.block(),
            maxHeight: params.modalHeight || "calc(100% - 250px)",
            minHeight: params.modalHeight || 300,
            maxWidth: params.modalWidth || 1000,
            minWidth: params.modalWidth || 600,
            hasBackdrop: true,
            backdropClass: "cdk-overlay-dark-backdrop",
            panelClass: ["modal", themeClass],
        });
    }

    /**
     * Open modal with component
     * @param {Type<any>} component
     * @param {Object} params
     * @returns {Promise<any>}
     */
    public open(component: Type<any>, params: Object = {}): Promise<any> | null {
        const portal: ComponentPortal<any> = new ComponentPortal(component);
        if (portal) {
            const key: string = "" + moment().format("x");

            ModalService.modals[key] = this.overlay.create({...this.getOverlayConfig(params)});

            const componentRef: ComponentRef<any> = ModalService.modals[key].attach(portal);
            const instance: { modal: Modal.IModal } = componentRef.instance;

            ModalService.modals[key].detachments().pipe(take(1))
                .subscribe((): void => ModalService.modals[key].dispose());

            instance.modal = {
                params,
                response: new EventEmitter<Modal.IResponse>(),
                close_all: new EventEmitter<Modal.IResponse>()
            };

            return new Promise((resolve: Function): void => {
                instance.modal.response.pipe(take(1)).subscribe((response: Modal.IResponse): void => {
                    resolve(response);
                    ModalService.modals[key].detach();
                    delete ModalService.modals[key];
                });

                instance.modal.close_all.pipe(take(1)).subscribe((): void => {
                    resolve(null);
                    for (const k of Object.keys(ModalService.modals)) {
                        ModalService.modals[k].detach();
                        ModalService.modals[k].dispose();
                        delete ModalService.modals[k];
                    }
                });

                ModalService.modals[key].backdropClick().subscribe((event: MouseEvent): void => {
                    if (event.clientY <= 50 && event.clientX > event.view.window.innerWidth - 50) {
                        resolve(null);
                        this.storageService.set("isRequestMinimized", false);
                        ModalService.modals[key].detach();
                        delete ModalService.modals[key];
                    }
                });

                ModalService.modals[key].keydownEvents().subscribe(event => {
                    if (event.key === "Escape") {
                        resolve(null);
                        this.storageService.set("isRequestMinimized", false);
                        ModalService.modals[key].detach();
                        delete ModalService.modals[key];
                    }
                });
            });
        } else {
            return null;
        }
    }

}
