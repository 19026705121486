<mat-card>
    <div>
        <button class="pull-right" matTooltip="Export pick list"
                type="button" mat-icon-button (click)="exportPackList('pdf')">
            <i class="fa fa-file-pdf-o"></i>
        </button>

        <button class="pull-right" matTooltip="Export pick list"
                type="button" mat-icon-button (click)="exportPackList('excel')">
            <i class="fa fa-file-excel-o"></i>
        </button>

        <h1 class="color-accent text-center">Select package and scan items</h1>

        <div class="search">
            <div class="info" *ngIf="packages">
                <mat-icon>move_to_inbox</mat-icon>
                <mat-form-field>
                    <mat-label>Package</mat-label>
                    <mat-select [formControl]="selectedPackage">
                        <mat-option *ngFor="let pack of packages" [value]="pack.id">
                            {{pack.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                &nbsp;
                <!--            <button *ngIf="selectedPackage.value" type="button" mat-raised-button color="accent">Show contents</button>-->
                &nbsp;
                <button type="button" mat-raised-button color="primary" (click)="newPackadge()">Add package</button>
            </div>

            <div *ngIf="serialTemp" class="margin-bottom-10">
                Scanned serial <b class="color-accent">{{serialTemp}}</b>
                <mat-icon color="warn" class="pointer clear" (click)="serialTemp = null" matTooltip="Clear serial">
                    clear
                </mat-icon>
            </div>
            <div *ngIf="locationTemp" class="margin-bottom-10">
                Scanned location <b class="color-accent">{{locationTemp}}</b>
                <ng-template [ngIf]="boxTemp">
                    .&nbsp;Scanned box <b class="color-accent">{{boxTemp}}</b>
                </ng-template>
                <mat-icon color="warn" class="pointer clear" (click)="locationTemp = null; boxTemp = null"
                          matTooltip="Clear location">
                    clear
                </mat-icon>
            </div>
            <div class="search-field" *ngIf="selectedPackage.value">
                <mat-form-field>
                    <mat-label>{{searchInputLabel}}</mat-label>
                    <input matInput type="search" [formControl]="searchInput"
                           [scannerOnly]="scannerOnly"
                           (keydown)="onKeydown($event)" #searchInputRef>
                </mat-form-field>
                <button type="button" mat-raised-button color="accent" (click)="find()">
                    <i class="fa fa-barcode"></i> Search
                </button>
            </div>
            Your scanner default key set as {{ scannerKey }}.
            <span *ngIf="scannerOnly" class="color-warn">
            Manual input disabled
        </span>
            <div *ngIf="isViewOnly">
            <span class="color-warn">
                Manual input disabled for closed order
            </span>
            </div>
        </div>

        <div class="text-right">
            <button mat-raised-button type="button" (click)="getAllocationsByShipment()">
                <i class="fa fa-refresh"></i>
                Refresh items list
            </button>
        </div>

        <div class="items" *ngIf="items">
            <ng-template ngFor let-item_key [ngForOf]="items | keys">
                <mat-card class="item flex row margin-bottom-10"
                          *ngIf="(items[item_key].locations | keys ).length">
                    <div class="item-info flex row">
                        <div class="image">
                            <img [id]="items[item_key] ? 'pmimg-' + items[item_key].id : ''" [src]="items[item_key].part_master && items[item_key].part_master.image_path
                         ? items[item_key].part_master.image_path : 'assets/images/no-image.png'"
                                 alt=""
                                 (click)="uploadAvatar(items[item_key] ? items[item_key].id : null, items[item_key].part_master ? items[item_key].part_master.id : null)"
                                 style="cursor: pointer">
                        </div>
                        <div style="min-width: 250px">
                            <div>
                                Item:
                                <b>{{items[item_key].item}}</b>
                            </div>
                            <div *ngIf="items[item_key].serial">
                                Serial:
                                <b>{{items[item_key].serial}}</b>
                            </div>
                            <div *ngIf="items[item_key].configurations">
                                Configurations:
                                <b>{{items[item_key].configurations}}</b>
                            </div>
                            <div *ngIf="items[item_key].batch">
                                Batch:
                                <b>{{items[item_key].batch}}</b>
                            </div>
                            <div>
                                Quantity:
                                <b>{{items[item_key].quantity}}</b>
                            </div>
                            <div *ngIf="items[item_key].status">
                                Status:
                                <b>{{items[item_key].status.name}}</b>
                            </div>
                            <div *ngIf="items[item_key].inventory_conversion">
                                Hub:
                                <b>
                                    {{items[item_key].inventory_conversion.customers_inventory_name}}
                                    -
                                    {{items[item_key].inventory_conversion.customers_sub_inventory}}
                                </b>
                            </div>
                        </div>
                    </div>

                    <div>
                        <mat-card class="location"
                                  *ngFor="let location_key of items[item_key].locations | keys">
                            <h3 class="text-center text-disabled">Location:
                                <b class="color-accent">
                                    {{items[item_key].locations[location_key].location}}
                                    <mat-icon class="vertical-bottom">system_update_alt</mat-icon>
                                </b>
                            </h3>
                            <h3>Allocations:</h3>
                            <div class="flex row wrap" style="justify-content: flex-start"
                                 *ngIf="items[item_key].locations[location_key].allocations">
                                <mat-card class="allocation" [class.slide-out-top]="allocation.deleted"
                                          *ngFor="let allocation of items[item_key].locations[location_key].allocations">
                                    <div>
                                        Ref:
                                        <b>{{allocation.ref}}</b>
                                    </div>
                                    <div>
                                        Item:
                                        <b>{{allocation.item}}</b>
                                    </div>
                                    <div *ngIf="allocation.serial">
                                        Serial:
                                        <b>{{allocation.serial}}</b>
                                    </div>
                                    <div *ngIf="allocation.configurations">
                                        Configurations:
                                        <b>{{allocation.configurations}}</b>
                                    </div>
                                    <div *ngIf="allocation.batch">
                                        Batch:
                                        <b>{{allocation.batch}}</b>
                                    </div>
                                    <div>
                                        Quantity:
                                        <b>{{allocation.quantity}}</b>
                                    </div>
                                    <div *ngIf="allocation.inventory_conversion">
                                        Hub:
                                        <b>
                                            {{allocation.inventory_conversion.customers_inventory_name}}
                                            -
                                            {{allocation.inventory_conversion.customers_sub_inventory}}
                                        </b>
                                    </div>
                                </mat-card>
                            </div>
                        </mat-card>
                    </div>
                </mat-card>
            </ng-template>
        </div>


        <h3 class="text-center color-accent" *ngIf="!hasItems">Nothing to scan</h3>

    </div>
</mat-card>
