import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {takeUntil} from "rxjs/operators";
import {StorageService} from "../../../../../../common/services/storage.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {IPagination} from "../../../../../../common/components/pagination/pagination.component";
import {Request} from "../../../../../../common/interfaces/request.interface";
import {DomSanitizer} from "@angular/platform-browser";
import {RequestService} from "../../../../../../common/services/request.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import * as moment from "moment";
import {Form} from "../../../../../../common/interfaces/form.interface";
import ISelectOption = Form.ISelectOption;
import {UserService} from "../../../../../../common/services/user.service";

@Component({
    selector: "section-followups-tiles",
    templateUrl: "tiles.component.html",
    styleUrls: ["tiles.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class FollowupsTilesComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    @Input()
    public filters: FormGroup;

    @Input()
    public state: Base.IState;

    @Input()
    public only_my: boolean = false;

    @Input()
    public favorite: boolean = false;

    @ViewChild(ConfirmComponent, {static: true})
    public confirmRef: ConfirmComponent;

    public followups: IPagination<Request.IData>;

    public searchForm: FormGroup = new FormGroup({
        search_by: new FormControl(null),
        search_in: new FormControl(["Order.ref"], [Validators.required])
    });

    public searchInOptions: ISelectOption[] = [
        {
            value: "Order.ref",
            name: "Order"
        },
        {
            value: "title",
            name: "Title"
        },
        {
            value: "timeline_to",
            name: "Due date",
        },
        {
            value: "status",
            name: "Status",
        },
        {
            value: "RequestKind.name",
            name: "Kind",
        },
        {
            value: "Order.MainAddress.country",
            name: "Country",
        },
        {
            value: "Order.Courier service.service_name",
            name: "Courier service",
        },
        {
            value: "Order.Outbound Shipment.estimated_delivery_date",
            name: "ETA",
        },
        {
            value: "RequestLastActivity.User.name",
            name: "Updated by",
        },
        {
            value: "RequestLastActivity.created_at",
            name: "Update date",
        },
        {
            value: "created_at",
            name: "Creation date",
        },
        {
            value: "Creator.name",
            name: "Created by",
        }
    ];

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private storageService: StorageService,
        private spinnerService: SpinnerService,
        private sanitizer: DomSanitizer,
        private requestService: RequestService,
        private toastService: ToastService,
        private changeDetectorRef: ChangeDetectorRef,
        private userService: UserService
    ) {
    }

    public async getFollowups(page: number = 1, per_page: number = null): Promise<any> {
        this.followups = null;
        this.changeDetectorRef.markForCheck();
        if (per_page === null) {
            per_page = this.userService.data.settings.default_per_page;
        }

        const {data}: Api.IResponse = await this.requestService.getRequests({
            ...this.filters.value,
            ...this.searchForm.value,
            data_structure: "paginated",
            type: "followup",
            page,
            per_page,
            only_my: this.only_my,
            only_favorite: this.favorite,
            order_id: this.state.params.order || null,
        });
        if (data) {
            this.followups = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    public viewFollowup(data: any): void {
        this.router.navigate([
            this.state.section,
            "followups",
            "view",
            "id",
            data.id
        ]);
    }


    public async setTimeline(request: Request.IData, dateRange: string): Promise<any> {
        this.spinnerService.show();
        const [from, to] = dateRange.split(" ~ ");
        const {code, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["request", "" + request.id, "timeline"], {
                timeline_from: moment(from).format("YYYY-MM-DD HH:mm"),
                timeline_to: moment(to).format("YYYY-MM-DD HH:mm")
            });
        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.getFollowups();
        }
    }

    public goToOrder(data: any): void {
        this.router.navigate([
            this.state.section,
            "orders",
            "view",
            "id",
            data.order.id
        ]);
    }

    public async solve(data: any): Promise<any> {
        if (!await this.confirmRef.confirm("Solve this followup?")) {
            return;
        }

        this.spinnerService.show();
        const {message, code}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["request", data.id, "status"], {
                status: "Solved"
            });
        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.getFollowups(1, 10);
        }
    }

    public async favoriteToggle(data: Request.IData): Promise<any> {
        this.spinnerService.show();
        const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["request", "" + data.id, "favorite"]);
        this.spinnerService.hide();

        if (type as string === "success") {
            this.toastService.show(message, "success");
            this.getFollowups();
        }
    }

    public ngOnInit(): void {

        this.getFollowups();

        this.filters.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((): void => {
            this.getFollowups();
        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
