<mat-card>
    <mat-card-content>
        <form [formGroup]="formGroup">
            <mat-form-field class="full">
                <mat-label>Name</mat-label>
                <input matInput formControlName="name"
                       required>
            </mat-form-field>
            <button mat-raised-button (click)="submitForm()" class="pull-right" color="primary">Add</button>
        </form>
    </mat-card-content>
</mat-card>
