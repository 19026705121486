import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from "@angular/core";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {ToastService} from "../../../../../../common/services/toast.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {Table2Component} from "../../../../../../common/components/table2/table.component";
import {ResellerFormComponent} from "../form/form.component";

@Component({
    selector: "section-reseller-list",
    template: `

        <common-confirm></common-confirm>
        <h1 class="container-heading">Resellers list</h1>
        <mat-card>
            <mat-card-content>
                <common-table2 *ngIf="listTable" [settings]="listTable">
                    <div>
                        <button mat-raised-button color="primary" class="main" (click)="showAddForm()">Add</button>
                    </div>
                </common-table2>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResellerListComponent implements OnInit, Base.IComponent {

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.ISettings;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    public modal: Modal.IModal;

    public readonly state: Base.IState;

    public constructor(
        private apiService: ApiService,
        private modalService: ModalService,
        private toastService: ToastService,
        private spinnerService: SpinnerService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        const actions: Table.IAction[] = [];

        actions.push({
            name: "view",
            title: "View",
            click: (row: any): void => {
                this.showEditForm(row.id);
            }
        });
        actions.push({
            name: "delete",
            title: "Delete",
            click: (row: any): void => {
                this.delete(row.id);
            }
        });

        this.listTable = {
            table_id: "byilydxg7547",
            actions,
            api: {
                url: ["modix_resellers"]
            },
            columns: [
                {
                    data: "name",
                    title: "Name"
                },
                {
                    data: "email",
                    title: "Email"
                },
                {
                    data: "created_at",
                    title: "Created at"
                },
            ],
        };
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Show customer form
     * @returns {void}
     */
    private async showEditForm(id: number): Promise<any> {
        await this.modalService.open(ResellerFormComponent, {
            id,
            canDelete: true,
            state: this.state
        });
        this.tableRef.reload();
    }

    /**
     * Delete customer
     * @param id
     */
    public async delete(id: number): Promise<any> {
        if (await this.confirmRef.confirm("Are you sure want to delete this reseller?")) {
            this.spinnerService.show();
            const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
                ["modix_resellers", "" + id]);
            if (type as string === "success") {
                this.toastService.show(message, "success");
                this.tableRef.reload();
            }
            this.spinnerService.hide();
        }
    }

    /**
     * Show new customer form
     * @returns {void}
     */
    public async showAddForm(): Promise<any> {
        await this.modalService.open(ResellerFormComponent, {
            canEdit: true,
            canDelete: true,
            state: this.state
        });
        this.tableRef.reload();
    }


    public ngOnInit(): void {
        this.prepareList();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "resellers",
            actions: {
                "browse": ["browse_modix_resellers"]
            }
        };
    }

}
