import {Directive, Input, TemplateRef, ViewContainerRef} from "@angular/core";
import {UserService} from "../services/user.service";


@Directive({
    selector: "[userNotAllowed]"
})
export class UserNotAllowedDirective {
    private _context: boolean = false;

    @Input()
    public set userNotAllowed(permissions: string | string[]) {
        this._context = this.userService.validatePermissions(permissions);
        this._updateView();
    }

    public constructor(private viewContainer: ViewContainerRef, private templateRef: TemplateRef<any>,
                       private userService: UserService
    ) {
    }

    private _updateView(): void {
        if (!this._context) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}
