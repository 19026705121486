<h1 class="container-heading">Link hubs and couriers services</h1>
<mat-card>
    <mat-card-content>
        <div class="hubs" *ngIf="hubs">
            <div class="form">
                <mat-form-field>
                    <mat-label>Search hub</mat-label>
                    <input type="text" matInput [formControl]="hub_search">
                </mat-form-field>
            </div>
            <div class="hub" *ngFor="let hub of hubs_filtered | async; let i = index">
                <form [formGroup]="hub.formGroup">
                    <div class="group flex margin-bottom-0">
                        <div class="half">
                            <mat-icon matTooltip="Favorite"
                                      class="vertical-bottom"
                                      [class.mat-accent]="isFavorite(hub)"
                                      (click)="toggleFavorite(hub)">
                                star
                            </mat-icon>
                            {{hub.customers_inventory_name}} - {{hub.customers_sub_inventory}}
                        </div>
                        <div class="half" *ngIf="services">
                            <common-form-select [multiple]="true"
                                                label="Courier services"
                                                [value]="hub.formGroup.get('courier_service_id').value"
                                                [options]="services"
                                                [search]="true"
                                                (valueChange)="hub.formGroup.get('courier_service_id').setValue($event)"
                                                (onClose)="submit(hub.formGroup)">
                            </common-form-select>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </mat-card-content>
</mat-card>
