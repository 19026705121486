import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: "linebreaks"
})
export class LineBreaksPipe implements PipeTransform {

    public transform(value: string): string {
        if (!value) {
            return "";
        }
        return value.replace(/\n/g, "<br>");
    }

}
