import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import {Router} from "@angular/router";
import {Table, TableComponent} from "../../../../../../common/components/table/table.component";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {UserService} from "../../../../../../common/services/user.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {CommonFormComponent} from "../../../../../../common/components/form";
import {SchedulerListComponent} from "../../../../../../common/components/scheduler";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Api3Service} from "../../../../../../common/services/api3.service";


@Component({
    selector: "section-reports-list",
    template: `
        <common-confirm></common-confirm>

        <h1 class="container-heading">Reports</h1>
        <mat-card>
            <mat-card-content>
                <common-table *ngIf="listTable" [options]="listTable" (action)="handleListAction($event)">
                </common-table>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportsListComponent implements Base.IComponent, OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    private favorite: boolean = false;

    @ViewChild(TableComponent, {static: false})
    public tableRef: TableComponent;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.IOptions;

    public showExportButton: boolean = false;

    public readonly state: Base.IState;

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private userService: UserService,
        private modalService: ModalService,
        private spinnerService: SpinnerService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {

        this.listTable = {
            api: this.apiService.getUrl(["report"]) + "?data_structure=dataTables&favorite=" + this.favorite,
            actions: [
                {
                    name: "wizard",
                    title: "Generate"
                },
                {
                    name: "alarm",
                    title: "Schedule"
                }
            ],
            columns: [
                {
                    data: "datatable",
                    title: "",
                    orderable: false,
                    searchable: false,
                    render: (data: any, action: any, row: any): string => {
                        let content: string = "";
                        content += `<button data-action="star" type="button"
                                class="mat-mdc-mini-fab mdc-fab tableAction ${row.is_favorite ? "mat-accent" : ""}"
                                title="View in table">
                                <mat-icon class="mat-icon material-icons">star</mat-icon>
                            </button>`;
                        return content;
                    }
                },
                {
                    data: "name",
                    title: "Name"
                },
                {
                    data: "description",
                    title: "Description"
                },
                {
                    data: "icon",
                    title: "",
                    render: (data: string): string => {
                        return data ? "<img class='icon' src='" + data + "' " + "alt=''>" : "";
                    },
                    orderable: false,
                    searchable: false
                }
            ],
            order: [],
            search: true,
        };
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Add/Remove favorite report
     */
    private async setFavorite(report: { id: number, is_favorite: boolean }): Promise<any> {
        this.spinnerService.show();
        const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["report", "favorite", "" + report.id, report.is_favorite ? "remove" : "add"]);
        if (type as string === "success") {
            this.toastService.show(message, "success");
            this.tableRef.reload();
        }
        this.spinnerService.hide();
    }

    private async export(report): Promise<any> {

        report.fields = report.fields.filter((f) => {
            return f[this.state.section_type + "_can_see"] === true;
        });

        const result: Modal.IResponse = await this.modalService.open(CommonFormComponent, {
            formConfig: report,
            reports: true,
            scheduler: true,
            scheduler_type: "App\\Report",
            scheduler_type_id: report.id
        });

        if (!result || !result.value) {
            return;
        }

        this.spinnerService.show();

        const {code, message}: Api.IResponse = await this.api3Service.request(Api.EMethod.Post,
            `/${this.state.section}/reports/${report.id}`, result.value);

        this.spinnerService.hide();

        if (code === 200) {
            this.toastService.show(message, "success");
        }

    }

    /**
     * Handle list row action
     * @param {Table.Action.IResult} action
     * @returns {Promise<any>}
     */
    public async handleListAction(action: Table.Action.IResult): Promise<any> {
        if (action.name === "wizard") {
            this.export(action.data);
        } else if (action.name === "alarm") {
            await this.modalService.open(SchedulerListComponent, {
                data: action.data.user_tasks
            });
        } else if (action.name === "view") {
            this.router.navigate([
                this.state.section,
                this.state.component,
                "view",
                "key",
                action.data.key
            ]);
        } else if (action.name === "star") {
            this.setFavorite(action.data);
        }
    }

    public ngOnInit(): void {
        this.favorite = this.state.action === "favorite";
        this.prepareList();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "reports",
            actions: {
                "browse": ["browse_reports"],
                "favorite": ["browse_reports"]
            }
        };
    }
}
