import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Base} from "../../../../../common/interfaces/base.interfaces";
import {Api, ApiService} from "../../../../../common/services/api.service";
import {Router} from "@angular/router";
import {SidebarComponent} from "../../../../../common/components/sidebar/sidebar.component";
import {FormControl} from "@angular/forms";
import {takeUntil} from "rxjs/operators";
import {StorageService} from "../../../../../common/services/storage.service";
import {SpinnerService} from "../../../../../common/services/spinner.service";

@Component({
    selector: "section-analytics",
    templateUrl: "analytics.component.html",
    styleUrls: [
        "analytics.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class AnalyticsComponent implements Base.IComponent, OnInit, OnDestroy {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    private chartsInitCounter: number = 0;

    @ViewChild(SidebarComponent, {static: false})
    public sidebar: SidebarComponent;

    public readonly state: Base.IState;

    /*
     * Calendar data
     */
    public calendarSize: number[] = [];
    public ordersByDate: [Date, number][] = [];

    /**
     * Map data
     * @type {[string, number | string][]}
     */
    public ordersByCountries: [string, number | string][] = [];

    /**
     * Dashboard type to show
     * @type {string}
     */
    public dashType: string = "admin";

    /*
     * Top stats params
     */
    public ordersOpen: number = null;
    public ordersOnTheWay: number = null;
    public ordersTotal: number = null;
    public partmasters: number = null;

    public ordersByStatus: [string, number | string][] = [];
    public donutDaysCount: FormControl = new FormControl("30");

    public remarks: any[] = [];

    public ordersAvg: any[] = [];
    public ordersAvgColumn: number;


    public constructor(
        private apiService: ApiService,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private storageService: StorageService,
        private spinnerService: SpinnerService
    ) {
    }

    /**
     * Get data for orders calendar chart
     * @returns {Promise<any>}
     */
    private async getOrdersByDate(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["order"], {}, {
                data_structure: "calendar_chart"
            });
        this.spinnerService.hide();
        if (data) {
            data.splice(0, 1);
            this.ordersByDate = data.map((item: any[]): [Date, number] => {
                if (!this.calendarSize.includes(item[0])) {
                    this.calendarSize.push(item[0]);
                }
                return [
                    new Date(Number(item[0]), Number(item[1]) - 1, Number(item[2])),
                    Number(item[3])
                ];
            });
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Get data for orders geo chart
     * @returns {Promise<any>}
     */
    private async getOrdersByCountries(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["order"], {}, {
                data_structure: "geo_chart_by_address"
            });
        this.spinnerService.hide();
        if (data) {
            this.ordersByCountries = data.filter((row: any): any => {
                return row !== null;
            });
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Get open orders count
     * @returns {Promise<any>}
     */
    private async getOrdersOpen(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["order"], {}, {
                data_structure: "count",
                status_parameter_open: true
            });
        this.spinnerService.hide();
        if (data) {
            this.ordersOpen = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Get orders on the way count
     * @returns {Promise<any>}
     */
    private async getOrdersOnTheWay(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["courier", "transactions"], {}, {
                data_structure: "count",
                statuses: ["in transit", "pending"]
            });
        this.spinnerService.hide();
        if (data) {
            this.ordersOnTheWay = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Get total orders count
     * @returns {Promise<any>}
     */
    private async getOrdersTotal(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["order"], {}, {
                data_structure: "count"
            });
        this.spinnerService.hide();
        if (data) {
            this.ordersTotal = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Get partmasters count
     * @returns {Promise<any>}
     */
    private async getPartmasters(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["partmaster"], {}, {
                data_structure: "count"
            });
        this.spinnerService.hide();
        if (data) {
            this.partmasters = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Get ordersby status
     * @returns {Promise<any>}
     */
    private async getOrdersByStatus(days_back: number): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["courier", "transactions"], {}, {
                data_structure: "donut_chart",
                days_back
            });
        this.spinnerService.hide();
        if (data) {
            this.ordersByStatus = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    private donutChartSetup(): void {
        if (!this.storageService.get(this.state.section + "-" + "dash-donut-filter-days")) {
            this.storageService.set(this.state.section + "-" + "dash-donut-filter-days", "30");
            this.donutDaysCount.setValue("30");
        } else {
            this.donutDaysCount.setValue(
                this.storageService.get(this.state.section + "-" + "dash-donut-filter-days")
            );
        }

        this.getOrdersByStatus(this.donutDaysCount.value);

        this.donutDaysCount.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: number): void => {
            this.storageService.set(this.state.section + "-" + "dash-donut-filter-days", value);
            this.getOrdersByStatus(value);
            this.changeDetectorRef.markForCheck();
        });

    }


    private async getRemarks(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["remark", "order"], {}, {data_structure: "paginated", per_page: 3});
        this.spinnerService.hide();
        if (data.data) {
            this.remarks = data.data;
            this.changeDetectorRef.markForCheck();
        }
    }

    private async getOrdersAverage(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["order"], {}, {data_structure: "combo_chart"});
        this.spinnerService.hide();
        if (data) {
            const columns: string[] = Object.values(data.columns);
            this.ordersAvgColumn = columns.length;
            columns.unshift("Month");
            columns.push("Average");
            for (const month of Object.keys(data.rows)) {
                let array: any[] = [];
                array.push(month);
                const row: number[] = Object.values(data.rows[month]);
                array = array.concat(row);
                array.push(this.arrayAvg(row));
                this.ordersAvg.push(array);
            }
            this.ordersAvg.reverse();
            this.ordersAvg.unshift(columns);
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Finds array average
     * @param {number[]} array
     * @returns {number}
     */
    private arrayAvg(array: number[]): number {
        return array.reduce((a: number, b: number): any => a + b, 0) / array.length;
    }

    public ngOnInit(): void {
        if (this.state.section !== "admin") {
            const [type, slug]: string[] = this.state.section.split("/");
            this.dashType = type;
        }


        switch (this.dashType) {
            case "admin":
                this.getOrdersByDate();
                this.getOrdersAverage();
                this.getOrdersByCountries();
                this.getOrdersOpen();
                this.getOrdersOnTheWay();
                this.getOrdersTotal();
                this.getPartmasters();
                this.donutChartSetup();
                this.getRemarks();

                break;
            case "partner":
                this.getOrdersByDate();
                this.getOrdersByCountries();
                this.getOrdersOpen();
                this.getOrdersOnTheWay();
                this.getOrdersTotal();
                this.getPartmasters();
                this.donutChartSetup();
                this.getRemarks();

                break;
            case "threepl":
                this.router.navigate([this.state.section, "warehouses"]);
                break;
            case "warehouse":
                break;
            default:
                break;
        }


    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "analytics",
            actions: {
                "browse": "*"
            }
        };
    }

}
