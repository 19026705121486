<div class="pull-right margin-top-10 margin-right-20 margin-bottom-20" id="wizard_style_toggle"
     *ngIf="partner?.slug === 'mavenir_il'">
    <mat-label>Wizard style:</mat-label>
    <div>
        <common-form-switch [value]="customWizardStyle" width="250px"
                            labelOff="Default"
                            [labelOn]="partner.display_name"
                            [trueFalse]="false"
                            (valueChange)="switchWizardStyle($event)">
        </common-form-switch>
    </div>

</div>

<h1 *ngIf="wizardHeading" class="container-heading"
    [class.padding-top-10]="partner?.slug === 'mavenir_il'"
    [innerHTML]="wizardHeading"></h1>

<mat-card *ngFor="let step of steps; let i = index" class="mat-elevation-z2">
    <h2>
        {{i + 1}}.
        <ng-container *ngIf="step.icon">
            {{step.icon}}
        </ng-container>
        {{step.title}}
    </h2>

    <div class="description" *ngIf="step.description">
        {{step.description}}
    </div>

    <ng-template wizard-component-factory
                 [component]="step.component"
                 [data]="data"
                 (result)="stepEvent($event, i)">
    </ng-template>
</mat-card>
<mat-card>
    <div class="actions clear padding-top-20">
        <button [disabled]="!valid" (click)="finish()" mat-raised-button color="primary" type="button"
                class="main">
            Finish
        </button>
    </div>
</mat-card>
