<h1 class="container-heading">{{window_title}}</h1>

<form [formGroup]="formGroup" (submit)="save()">

    <div class="group flex">
        <mat-form-field class="half">
            <mat-label>Ref</mat-label>
            <input matInput formControlName="ref" required>
        </mat-form-field>
        <div class="half">
            <mat-checkbox [formControl]="addDims">Add dims</mat-checkbox>
        </div>
    </div>


    <div class="group flex" *ngIf="addDims.value">
        <mat-form-field class="third">
            <mat-label>Length</mat-label>
            <input matInput formControlName="length" required>
        </mat-form-field>

        <mat-form-field class="third">
            <mat-label>Width</mat-label>
            <input matInput formControlName="width" required>
        </mat-form-field>

        <mat-form-field class="third">
            <mat-label>Height</mat-label>
            <input matInput formControlName="height" required>
        </mat-form-field>

        <mat-form-field class="third">
            <mat-label>Distance unit</mat-label>
<mat-select formControlName="distance_unit"  required>
                <mat-option *ngFor="let unit of distanceUnits" [value]="unit">
                    {{unit}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="group flex" *ngIf="addDims.value">
        <mat-form-field class="third">
            <mat-label>Weight</mat-label>
            <input matInput formControlName="weight" required>
        </mat-form-field>

        <mat-form-field class="third">
            <mat-label>Mass unit</mat-label>
<mat-select formControlName="mass_unit"  required>
                <mat-option *ngFor="let unit of massUnits" [value]="unit">
                    {{unit}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div class="third"></div>
    </div>


    <div class="actions">
        <button [disabled]="!formGroup.valid" mat-raised-button color="primary" type="submit" class="main">
            Save
        </button>
        <button mat-raised-button color="warn" class="main pull-right" *ngIf="modal.params.action === 'edit'"
                type="button" (click)="delete()">
            Delete
        </button>
    </div>
</form>
