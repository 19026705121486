import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Table2Component} from "../../../../../../common/components/table2/table.component";
import {ModalService} from "../../../../../services/modal.service";
import {InTransitModalComponent} from "./in-transit-modal.component";
import {FormControl} from "@angular/forms";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {distinctUntilChanged, takeUntil} from "rxjs/operators";
import {Warehouse} from "../../../../../../common/interfaces/warehouse.interface";


@Component({
    selector: "section-in-transit-list",
    template: `
        <h1 class="container-heading">In transit</h1>
        <mat-card>
            <mat-card-content>
                <common-confirm></common-confirm>
                <common-table2 *ngIf="listTable" [settings]="listTable" [scrollHeightValue]="260">
                    <div row1>
                        <common-form-select *ngIf="hubList && hubList.length"
                                            [options]="hubList"
                                            [multiple]="true"
                                            [selectAll]="true"
                                            [search]="true"
                                            style="width: 250px"
                                            (onClose)="warehouseSelect.setValue($event)"
                                            label="Hubs"></common-form-select>
                    </div>
                </common-table2>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InTransitListComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    public readonly state: Base.IState;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.ISettings;
    public warehouseSelect: FormControl = new FormControl([]);
    public hubList: { name: string, value: any }[];

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private modalService: ModalService,
        private spinnerService: SpinnerService,
        private apiService: ApiService
    ) {
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {

        const actions: Table.IAction[] = [];

        const columns: Table.ICol[] = [
            {
                data: "item",
                title: "Item",
                render: (row: any): string => {
                    return `<button type="button"
                            class="mat-mdc-raised-button mdc-button" title="Search by item">
                            ${row.item}
                        </button> `;
                },
                click: (row: any): void => {
                    this.tableRef.performSearch(row.item);
                }
            },
            {
                data: "inventory_conversion.customers_inventory_name",
                title: "Inventory"
            },
            {
                data: "inventory_conversion.customers_sub_inventory",
                title: "Sub inventory"
            },
            {
                data: "globalOrderItemsInTransitToWHCount",
                title: "In transit to WH",
                render: (row: any): string => {
                    return `<button type="button"
                            class="mat-mdc-raised-button mdc-button" title="Show detailed">
                            ${row.globalOrderItemsInTransitToWHCount}
                        </button> `;
                },
                click: async (row: any): Promise<any> => {
                    this.allocates_items_modal(row, "wh");
                },
                sortable: false
            },
            {
                data: "globalOrderItemsInTransitToClientCount",
                title: "In transit to client",
                render: (row: any): string => {
                    return `<button type="button"
                            class="mat-mdc-raised-button mdc-button" title="Show detailed">
                            ${row.globalOrderItemsInTransitToClientCount}
                        </button> `;
                },
                click: async (row: any): Promise<any> => {
                    this.allocates_items_modal(row, "client");
                },
                sortable: false
            },
            {
                data: "inventoryByInventoryKeySum",
                title: "Inventory",
                sortable: false
            },
        ];
        const export_data: any = {
            type: "csv",
            columns: [
                {
                    data: "item",
                    title: "Item"
                },
                {
                    data: "inventory_conversion.warehouse.name",
                    title: "Warehouse"
                },
                {
                    data: "inventory_conversion.sub_warehouse.sub_inventory",
                    title: "Sub warehouse"
                },
                {
                    data: "globalOrderItemsInTransitToWHCount",
                    title: "In transit to WH"
                },
                {
                    data: "globalOrderItemsInTransitToClientCount",
                    title: "In transit to client"
                }
            ],
            file_name: "in_transit"
        };


        this.listTable = {
            table_id: "z0WdVJjj2Rg",
            api: this.url,
            actions,
            columns,
            export: export_data,
            infinity_scroll: true
        };
        this.changeDetectorRef.markForCheck();
    }

    private async allocates_items_modal(row: any, transit: string): Promise<any> {
        await this.modalService.open(InTransitModalComponent, {
            state: this.state,
            id: row.id,
            inventory_conversion_id: row.inventory_conversion_id,
            transit: transit,
            modalWidth: 1000
        });
    }

    private get url(): { url: string[], query?: { [key: string]: any } } {
        return {
            url: ["order_item", "in_transit"],
            query: {
                hubs: this.warehouseSelect.value,
                appends: [
                    "inventoryByInventoryKeySum",
                    "globalOrderItemsInTransitToClientCount",
                    "globalOrderItemsInTransitToWHCount"
                ]
            }
        };
    }

    private async getHubList(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["hub"]);
        if (data) {
            this.hubList = data.map((hub: Warehouse.IHub): any => {
                return {
                    name: `${hub.customers_inventory_name} - ${hub.customers_sub_inventory}`,
                    value: hub.id
                };
            });
        }
        this.spinnerService.hide();
    }

    public ngOnInit(): void {
        this.getHubList();
        this.prepareList();
        this.warehouseSelect.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
            .subscribe((value: any[]): void => {
                this.tableRef.reload(this.url);
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }


    public ngConfig(): Base.IConfig {
        return {
            name: "in-transit",
            actions: {
                "browse": ["browse_orders_items"]
            }
        };
    }
}
