<common-confirm></common-confirm>
<mat-card>
    <h3 matSubheader>Two Factor Authentication</h3>
    <div>
        <p>
            Two factor authentication (2FA) strengthens access security by
            requiring two methods (also referred to as factors) to verify your identity.
            Two factor authentication protects against phishing, social engineering
            and password brute force attacks and secures your logins from attackers exploiting
            weak or stolen credentials.
        </p>
        <p>
            To Enable Two Factor Authentication on your Account, you need to do following steps:
        </p>

        <ol>
            <li>
                Click on Generate Secret Button , To Generate a Unique secret QR code for your
                profile
            </li>
            <li>
                Verify the OTP from Google Authenticator Mobile App
            </li>
        </ol>
    </div>

    <ng-template [ngIf]="!user.user2fa || !user.user2fa.enabled">
        <div *ngIf="data2fa.generateSuccess; else elseGenBlock">
            <mat-card class="background-success margin-bottom-20">
                {{ data2fa.generateSuccess }}
            </mat-card>

            <div class="text-center">
                <b>1. Scan this barcode with your Google Authenticator App:</b>
                <br>
                <div *ngIf="data2fa.qrCode.includes('data:image')">
                    <img [src]="data2fa.qrCode" alt="">
                </div>
                <div *ngIf="data2fa.qrCode.includes('<svg ')"
                     [innerHTML]="trustHtml(data2fa.qrCode)"></div>
                <br>
                <b>or enter key manually</b>
                <br>
                <span class="sCode">{{ data2fa.secretCode }}</span>
                <br>
                <br>
                <b>2.Enter the pin the code to Enable 2FA</b>
                <br>
                <br>
                <div>
                    <mat-form-field>
                        <mat-label>Authenticator Code</mat-label>
                        <input matInput type="text" [formControl]="data2fa.authCode" autocomplete="off"
                               required>
                    </mat-form-field>
                    <div class="actions">
                        <button mat-raised-button color="success" [disabled]="!data2fa.authCode.valid"
                                (click)="enable2fa()">
                            Enable 2FA
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <ng-template #elseGenBlock>
            <div class="text-center">
                <button (click)="generate2faSecret()" mat-raised-button color="accent">
                    Generate Secret
                </button>
            </div>
        </ng-template>

    </ng-template>
    <ng-template [ngIf]="user.user2fa && user.user2fa.enabled">
        <mat-card class="background-success margin-bottom-20">
            2FA is currently <b>enabled</b> for your account
        </mat-card>

        <div>
            <p>
                If you are looking to disable Two Factor Authentication.
                Please confirm your password and Click Disable 2FA Button.
            </p>
        </div>

        <div class="form">
            <div class="group flex">
                <mat-form-field class="full">
                    <mat-label>Current Password</mat-label>
                    <input matInput type="password" [formControl]="data2fa.password"
                           required autocomplete="off">
                </mat-form-field>
            </div>
            <div class="actions">
                <button mat-raised-button color="success" [disabled]="!data2fa.password.valid"
                        (click)="disable2fa()">
                    Disable 2FA
                </button>
            </div>
        </div>
    </ng-template>
    <div class="apps">
        <div>
            Watch guide video:
        </div>
        <a href="https://youtu.be/gf9Du_nTCco"
           target="_blank" class="youtube"></a>
    </div>
    <div class="apps">
        <div>
            Download Google Authenticator Mobile App:
        </div>
        <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
           target="_blank" class="android"></a>
        <a href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8"
           target="_blank" class="ios"></a>
    </div>
    <div class="text-right" *ngIf="showLogoutButton">
        <a (click)="logout()" class="pointer">Logout</a>
    </div>
</mat-card>
