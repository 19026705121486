import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {FormControl} from "@angular/forms";
import {takeUntil} from "rxjs/operators";
import {StorageService} from "../../../../../../common/services/storage.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {AnnouncementFormComponent} from "../index";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {Table2Component} from "../../../../../../common/components/table2";
import {AmplitudeService} from "../../../../../../common/services/amplitude.service";


@Component({
    selector: "section-announcements-list",
    template: `
        <common-table2 *ngIf="listTable" [settings]="listTable">
            <div row1>
                <button mat-raised-button type="button" color="accent" (click)="addNew()">
                    Add
                </button>
            </div>
        </common-table2>
    `,
    styleUrls: ["list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class AnnouncementsListComponent implements OnInit, OnDestroy {

    public static columns: Table.ICol[] = [
        {
            data: "title",
            title: "",
            render: (row: any): string => {
                return `
                        <div class="multicol">
                            <span class="tableAction title pointer" data-action="wizard">
                                ${row.title}
                            </span>
                            &nbsp;
                            <small class="text-disabled">${row.description.substring(0, 30)}</small>
                            <div>
                                ${row.announcement_kind.name}
                            </div>
                        </div>
                        `;
            }
        },
        {
            data: "creator.name",
            name: "Creator.name",
            title: "Created by",
            render: (row: any): string => {
                return "<img class='vertical-middle round' src='"
                    + (row.creator.avatar ? row.creator.avatar : "/assets/images/default-avatar.png")
                    + "' alt=''> " + row.creator.name;
            }
        },
        {
            data: "created_at",
            name: "created_at",
            title: "Creation date",
            render: (row: any): string => {
                return row.created_at.split(" ")[0];
            }
        },
    ];

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    @Input()
    public only_my: boolean = false;

    @Input()
    public favorite: boolean = false;

    @Input()
    public state: Base.IState;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    public showStatusSelectAll: boolean = false;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.ISettings;

    public showExportButton: boolean = false;

    public statusQuery: string = "";

    public selectedRows: any[] = [];

    public showStatus: FormControl = new FormControl([]);
    public sortBy: FormControl = new FormControl(
        this.storageService.get("requests_sortby") || "created_at");

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private storageService: StorageService,
        private modalService: ModalService,
        private spinnerService: SpinnerService,
        private toastService: ToastService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    private getIrl(): Table.ITableApi {
        const status: string[] = [];
        if (this.showStatus.value.length > 0) {
            this.showStatus.value.forEach((value: any, index: any) => {
                status.push(value);
            });
        }

        return {
            url: ["announcement"],
            query: {
                only_my: this.only_my,
                only_favorite: this.favorite,
                sort_by: this.sortBy.value,
                status
            }
        };
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {

        const columns: Table.ICol[] = AnnouncementsListComponent.columns.slice();

        if (this.state.section === "admin") {
            columns.splice(1, 0, {
                data: "partner.icon_path",
                title: "Partner",
                render: (row: any): string => {
                    return row.partner.icon_path ? "<img src='" + row.partner.icon_path + "' alt=''>" : "";
                }
            });
        }

        this.listTable = {
            table_id: "dsgdhyur56",
            api: this.getIrl(),
            actions: [
                {
                    name: "wizard",
                    title: "View",
                    click: (row: any): void => {
                        this.router.navigate([
                            this.state.section,
                            "support-announcements",
                            "view",
                            "id",
                            row.id
                        ]);
                        AmplitudeService.eventClick("Announcement view");
                    }
                },
                {
                    name: "star",
                    title: "Add to favorites",
                    click: async (row: any): Promise<any> => {
                        this.spinnerService.show();
                        const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
                            ["announcement", "" + row.id, "favorite"]);
                        this.spinnerService.hide();

                        if (type as string === "success") {
                            this.toastService.show(message, "success");
                            this.tableRef.reload();
                        }
                        AmplitudeService.eventClick("Announcement add to favourite");
                    },
                    cssClassFn: (row: any): string => {
                        return row.fav_user ? "mat-accent" : "";
                    }
                }
            ],
            columns,
            export: {
                file_name: "announcements",
                columns: [
                    {
                        data: "status",
                        title: "Status"
                    },
                    {
                        data: "partner.slug",
                        title: "Partner"
                    },
                    {
                        data: "title",
                        title: "Title"
                    },
                    {
                        data: "announcement_kind.name",
                        title: "Kind"
                    },
                    {
                        data: "priority",
                        title: "Priority"
                    },
                    {
                        data: "creator.name",
                        title: "Created by"
                    },
                    {
                        data: "assignee.name",
                        title: "Assignee"
                    },
                    {
                        data: "created_at",
                        title: "Created at"
                    },
                    {
                        data: "closed_on",
                        title: "Closed on"
                    }
                ]
            }
        };
        this.changeDetectorRef.markForCheck();
    }


    public async addNew(): Promise<any> {
        const response: Modal.IResponse = await
            this.modalService.open(AnnouncementFormComponent, {
                url: window.location.pathname
            });
        if (response && response.name && response.name === "submit") {
            this.tableRef.reload();
        }
        AmplitudeService.eventClick(this.only_my ?
            "Add new (my announcements)" :
            "Add new (all announcements)");
    }


    public selectAll(form: string): void {
        if (form === "showStatus") {
            if (!this.showStatusSelectAll) {
                this.showStatus.setValue(
                    ["New", "Open", "Pending", "On-hold", "Solved", "Closed", "In-progress"]);
            } else {
                this.showStatus.setValue([]);
            }
            this.showStatusSelectAll = !this.showStatusSelectAll;
        }
    }

    public ngOnInit(): void {
        this.prepareList();
        this.showStatus.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((): void => {
            this.prepareList();
        });
        this.sortBy.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((): void => {
            this.storageService.set("requests_sortby", this.sortBy.value);
            this.prepareList();
        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
