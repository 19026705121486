<div class="pull-right" *userAllowed="'add_it_requests'">
    <common-form-switch
            labelOn="IT"
            labelOff="Operations"
            [value]="itForm"
            (valueChange)="itForm = $event"
            width="180px"
            [trueFalse]="false"
    ></common-form-switch>
</div>

<div class="flex">
    <h1 class="container-heading">
        {{action | ucfirst}} request
    </h1>
    <div class="padding-top-10 padding-left-10">
        <button type="button"
                mat-icon-button
                (click)="minimizeRequest()"
                matTooltip="Minimize request">
            <mat-icon class="fa-rotate-270"> call_received</mat-icon>
        </button>
    </div>
</div>

<mat-card>
    <mat-card-content>
        <section-operations-request-form *ngIf="!itForm"
                                         (onClose)="close()"
                                         (onMinimize)="minimizeRequest()"
                                         [data]="modal.params.data"
                                         (onSubmit)="close()"></section-operations-request-form>

        <section-it-request-form *ngIf="itForm"
                                 (onClose)="close()"
                                 (onMinimize)="minimizeRequest()"
                                 [data]="modal.params.data"
                                 (onSubmit)="close()"></section-it-request-form>
    </mat-card-content>
</mat-card>
