<h1 class="container-heading">Profile</h1>

<mat-card>
    <mat-card-content>
        <div class="columns-container" *ngIf="user">
            <div class="column-1-3">
                <mat-card>

                    <div class="avatar">
                        <img *ngIf="user.avatar" [src]="user.avatar" alt="">
                    </div>

                    <div class="name">
                        {{user.name}}
                        <div class="contact">{{user.email}}</div>
                        <div class="contact">{{user.phone}}</div>
                    </div>


                    <mat-list class="nav-list">
                        <mat-divider></mat-divider>
                        <mat-list-item (click)="showSection = 1" [class.active]="showSection === 1">
                            <mat-icon matListItemIcon>home</mat-icon>
                            <p matListItemTitle>Overview</p>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item (click)="showSection = 2" [class.active]="showSection === 2">
                            <mat-icon matListItemIcon>people</mat-icon>
                            <p matListItemTitle>Partners</p>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item (click)="showSection = 3" [class.active]="showSection === 3">
                            <mat-icon matListItemIcon>group_work</mat-icon>
                            <p matListItemTitle>ThreePLs</p>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item (click)="showSection = 6" [class.active]="showSection === 6">
                            <mat-icon matListItemIcon>attachment</mat-icon>
                            <p matListItemTitle>Files</p>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item (click)="showSection = 4" [class.active]="showSection === 4">
                            <mat-icon matListItemIcon>settings</mat-icon>
                            <p matListItemTitle>Settings</p>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item (click)="showSection = 5" [class.active]="showSection === 5">
                            <mat-icon matListItemIcon>security</mat-icon>
                            <p matListItemTitle>Security</p>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item (click)="showSection = 7" [class.active]="showSection === 7">
                            <mat-icon matListItemIcon>alarm</mat-icon>
                            <p matListItemTitle>Scheduled tasks</p>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <ng-container *ngIf="state.section_type ==='partner'">
                            <mat-list-item (click)="showSection = 8" [class.active]="showSection === 8">
                                <mat-icon matListItemIcon>notification_important</mat-icon>
                                <p matLine>Notifications</p>
                            </mat-list-item>
                            <mat-divider></mat-divider>
                        </ng-container>

                        <mat-list-item (click)="showSection = 9" [class.active]="showSection === 9">
                            <mat-icon matListItemIcon>vpn_lock</mat-icon>
                            <p matListItemTitle>Tokens</p>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                    </mat-list>
                </mat-card>
            </div>

            <div class="column-2-3" *ngIf="showSection === 1">
                <mat-card>
                    <mat-tab-group>
                        <mat-tab label="Orders">
                            <mat-form-field>
                                <mat-label>Order search</mat-label>
                                <input matInput type="search"
                                       [formControl]="orderSearch">
                            </mat-form-field>
                            <mat-list *ngIf="userOrders; else ordersElse">
                                <ng-template ngFor let-order [ngForOf]="userOrders.data">
                                    <mat-list-item>
                                        <div matListItemTitle>Ref: <b>{{order.ref}}</b></div>
                                        <div>Status:
                                            <span class="chip {{order.status.name | lowercase}}">
                                                {{order.status.name}}
                                            </span>
                                        </div>
                                        <div>Created at: <b>{{order.created_at}}</b></div>
                                        <div>Confirmed at: <b>{{order.confirmed_on}}</b></div>
                                        <div class="text-right">
                                            <a [routerLink]="[
                                            '/partner',
                                            order.partner.slug,
                                            'orders',
                                            'view',
                                            'ref',
                                            order.ref,
                                            'type',
                                            order.service_level.order_type.slug
                                            ]">
                                                View order
                                            </a>
                                        </div>
                                    </mat-list-item>
                                    <mat-divider></mat-divider>
                                </ng-template>
                                <common-pagination [data]="userOrders"
                                                   pid="9mn8Gj43SH"
                                                   (goToPage)="getUserOrders($event.page,
                                                   orderSearch.value,
                                                   $event.per_page)">
                                </common-pagination>
                            </mat-list>
                            <ng-template #ordersElse>
                                <p>You have no orders yet</p>
                            </ng-template>

                        </mat-tab>
                        <mat-tab label="Remarks">
                            <mat-form-field>
                                <mat-label>Remark search</mat-label>
                                <input matInput type="search"
                                       [formControl]="remarkSearch">
                            </mat-form-field>
                            <mat-list *ngIf="remarks; else remarksElse">
                                <ng-template ngFor let-remark [ngForOf]="remarks.data">
                                    <section-user-remark [remark]="remark"></section-user-remark>
                                </ng-template>
                                <common-pagination [data]="remarks"
                                                   pid="7Ih6MCg16c"
                                                   (goToPage)="getUserRemarks($event.page,
                                                   remarkSearch.value,
                                                   $event.per_page)">
                                </common-pagination>
                            </mat-list>
                            <ng-template #remarksElse>
                                <p>You have no remarks yet</p>
                            </ng-template>
                        </mat-tab>
                        <mat-tab label="Requests">
                            <div class="requests">
                                <common-table2 #requestsTable
                                               *ngIf="requestsListTable" [settings]="requestsListTable">
                                </common-table2>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </mat-card>
            </div>

            <div class="column-2-3" *ngIf="showSection === 2">
                <mat-card>
                    <h3 matSubheader>Partners</h3>
                    <mat-tab-group>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                Permitted
                            </ng-template>
                            <div class="content">
                                <mat-list *ngIf="user.partners && user.partners.length > 0; else noPartners">
                                    <ng-template ngFor let-partner let-i="index" [ngForOf]="user.partners">
                                        <mat-list-item>
                                            <img matListItemIcon [src]="partner.icon_path" alt="">
                                            <h4 matListItemTitle>{{partner.display_name}}</h4>
                                        </mat-list-item>
                                        <mat-divider></mat-divider>
                                    </ng-template>
                                </mat-list>
                                <ng-template #noPartners>
                                    <p>Nothing to show</p>
                                </ng-template>
                            </div>
                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                Orders
                            </ng-template>
                            <div class="content">
                                Content 2
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </mat-card>
            </div>

            <div class="column-2-3" *ngIf="showSection === 3">
                <mat-card>
                    <h3 matSubheader>ThreePLs</h3>
                    <mat-tab-group>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                Permitted
                            </ng-template>
                            <div class="content">
                                <mat-list *ngIf="user.threepls && user.threepls.length > 0;else noThreepls">
                                    <ng-template ngFor let-threepl let-i="index" [ngForOf]="user.threepls">
                                        <mat-list-item>
                                            <img matListItemIcon *ngIf="threepl.icon_path"
                                                 [src]="threepl.icon_path" alt="">
                                            <h4 matListItemTitle>{{threepl.display_name}}</h4>
                                        </mat-list-item>
                                        <mat-divider></mat-divider>
                                    </ng-template>
                                </mat-list>
                                <ng-template #noThreepls>
                                    <p>Nothing to show</p>
                                </ng-template>
                            </div>
                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                Orders
                            </ng-template>
                            <div class="content">
                                Content 2
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </mat-card>
            </div>

            <div class="column-2-3" *ngIf="showSection === 4">
                <section-user-view-settings [user]="user" [state]="state"></section-user-view-settings>
            </div>

            <div class="column-2-3" *ngIf="showSection === 5">
                <common-google2fa [user]="user"></common-google2fa>
            </div>

            <div class="column-2-3" *ngIf="showSection === 6">
                <section-attachments-list [url]="['user','attachments']" title="My files"></section-attachments-list>
            </div>
            <div class="column-2-3" *ngIf="showSection === 7">
                <h3 matSubheader>Scheduled tasks</h3>
                <common-scheduler-list></common-scheduler-list>
            </div>
            <div class="column-2-3" *ngIf="showSection === 8 && state.section_type ==='partner'">
                <section-user-view-notifications [user]="user" [state]="state"></section-user-view-notifications>
            </div>

            <div class="column-2-3" *ngIf="showSection === 9">
                <section-tokens-settings [user]="user" [state]="state"></section-tokens-settings>
            </div>
        </div>

    </mat-card-content>
</mat-card>
