import {ChangeDetectionStrategy, Component, ViewEncapsulation} from "@angular/core";
import {Modal} from "../../../../../../services/modal.service";
import {FormControl, Validators} from "@angular/forms";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {Api3Service} from "../../../../../../../common/services/api3.service";
import {AppStateService} from "../../../../../../../common/services/app-state.service";


@Component({
    selector: "section-order-cancel-modal",
    template: `

        <h1 class="container-heading">
            Do you want to{{ modal.params.softCancel ? ' soft' : '' }} cancel order {{ modal.params.orderData.ref }}?
        </h1>

        <mat-card>
            <mat-card-content>
                <div class="form">
                    <div class="group flex">
                        <mat-form-field class="full">
                            <mat-label>Message</mat-label>
                            <textarea matInput [formControl]="message" required></textarea>
                        </mat-form-field>

                        <div class="text-error full margin-bottom-20" *ngIf="message.valid">
                            <b>Warning! This action can not be reversed.</b>
                        </div>
                    </div>

                    <div class="actions">
                        <button type="submit" mat-raised-button color="warn"
                                [disabled]="!message.valid"
                                (click)="submit()">
                            Cancel order
                        </button>
                        <button type="button" mat-raised-button (click)="modal.response.emit(null)">Cancel</button>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>


    `,

    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class OrderCancelModalComponent {

    public message: FormControl = new FormControl(null, [Validators.required]);

    public modal: Modal.IModal;

    public constructor(
        private toastService: ToastService,
        private api3Service: Api3Service,
        private spinnerService: SpinnerService
    ) {
    }

    public async submit(): Promise<any> {
        this.spinnerService.show();
        const {data, message}: Api.IResponse =
            await this.api3Service.delete(
                `${this.modal.params.state.section}/orders/${this.modal.params.orderData.id}`, {
                    message: this.message.value,
                    soft_cancel: this.modal.params.softCancel
                });
        this.spinnerService.hide();
        if (data) {
            this.toastService.show(message, "success");
            this.modal.response.emit({
                name: "response",
                value: true
            });
        }
    }
}

