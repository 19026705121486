<h1 class="container-heading">Model Fields</h1>

<mat-card>
    <form *ngIf="formGroup" [formGroup]="formGroup" (submit)="submit()">
        <div class="group flex">
            <mat-form-field class="full">
                <mat-label>Model</mat-label>
                <input matInput formControlName="model" required>
            </mat-form-field>

            <mat-form-field class="full">
                <mat-label>Type</mat-label>
                <input matInput formControlName="type" rows="5" required>
            </mat-form-field>

        </div>

        <h2>Fileds:</h2>

        <div formArrayName="fields" class="fields">
            <ng-template ngFor let-field let-i="index" [ngForOf]="formGroup.controls.fields.controls">
                <mat-card>
                    <div class="sort pull-right">
                        Sort:
                        <button mat-icon-button [disabled]="i === 0" color="primary" (click)="moveUp(i)"
                                matTooltip="Move up" type="button">
                            <mat-icon>arrow_drop_up</mat-icon>
                        </button>
                        <button mat-icon-button [disabled]="i === formGroup.controls.fields.controls.length -1"
                                color="primary" (click)="moveDown(i)" matTooltip="Move down" type="button">
                            <mat-icon>arrow_drop_down</mat-icon>
                        </button>


                        <button mat-icon-button color="warn"
                                [disabled]="!fieldTypes[field.value.type].delete"
                                (click)="removeField(i)" matTooltip="Remove" type="button">
                            <mat-icon>remove</mat-icon>
                        </button>
                    </div>
                    <div class="move pull-right">
                        <input type="text" matInput #moveToPos/>
                        <button mat-icon-button color="primary" (click)="moveTo(i, 0 + moveToPos.value)"
                                matTooltip="Move down" type="button">
                            <mat-icon>check</mat-icon>
                        </button>
                    </div>
                    <h3>Field {{i + 1}}</h3>
                    <div class="group flex" [formGroupName]="i">
                        <mat-form-field class="half">
                            <mat-label>Label</mat-label>
                            <input matInput formControlName="label" required>
                        </mat-form-field>

                        <mat-form-field class="half" *ngIf="fieldTypes[field.value.type].type">
                            <mat-label>Type</mat-label>
                            <mat-select formControlName="type" required>
                                <mat-option *ngFor="let type of (fieldTypes | keys)" [value]="type"
                                            [disabled]="type === 'status'">
                                    {{type}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="half" *ngIf="!fieldTypes[field.value.type].type">
                            <mat-label>Type</mat-label>
                            <mat-select formControlName="type" required>
                                <mat-option [value]="field.value.type">
                                    {{field.value.type}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="half">
                            <mat-label>Size</mat-label>
                            <mat-select formControlName="size" required>
                                <mat-option *ngFor="let size of fieldSizes" [value]="size">
                                    {{size}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="half">
                            <mat-label>Name</mat-label>
                            <input matInput formControlName="name" required>
                        </mat-form-field>

                        <div class="half" *ngIf="fieldTypes[field.value.type].required">
                            <mat-checkbox formControlName="required">Required</mat-checkbox>
                        </div>

                        <div class="half" *ngIf="fieldTypes[field.value.type].multiple">
                            <mat-checkbox formControlName="multiple">Multiple</mat-checkbox>
                        </div>

                        <mat-form-field class="full" *ngIf="fieldTypes[field.value.type].options">
                            <mat-label>Values</mat-label>
                            <mat-chip-grid #chipGrid>
                                <mat-chip-row matChipRemove
                                              *ngFor="let chip of formGroup.controls.fields.controls[i].get('values').value"
                                              [removable]="true"
                                              (removed)="removeValue(chip, i)">
                                    {{chip}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip-row>
                                <input [matChipInputFor]="chipGrid"
                                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                       [matChipInputAddOnBlur]="true"
                                       (matChipInputTokenEnd)="addValue($event, i)"/>
                            </mat-chip-grid>
                        </mat-form-field>

                        <mat-form-field class="full" *ngIf="fieldTypes[field.value.type].url">
                            <mat-label>URL</mat-label>
                            <input matInput formControlName="url">
                        </mat-form-field>

                        <mat-form-field class="full" *ngIf="fieldTypes[field.value.type].description">
                            <mat-label>Description</mat-label>
                            <textarea matInput formControlName="description"
                            ></textarea>
                        </mat-form-field>

                    </div>
                </mat-card>
            </ng-template>
        </div>

        <div class="actions">
            <button (click)="addField()" type="button" class="mat-mdc-raised-button mdc-button mat-primary">Add field
            </button>
        </div>

        <div class="actions">
            <button type="submit" [disabled]="!formGroup.valid"
                    class="mat-mdc-raised-button mdc-button mat-primary main">Save
            </button>
            <button type="button" class="mat-mdc-raised-button mdc-button mat-primary pull-right" (click)="preview()">
                Preview
            </button>
        </div>


    </form>
</mat-card>
