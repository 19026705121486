import {Injectable} from "@angular/core";
import {Api, ApiService} from "./api.service";


@Injectable()
export class OrderService {

    public constructor(private apiService: ApiService) {
    }

    public getItems(orderRef: string, type: string = null, query: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Get, ["order", orderRef, "items", type], {}, query);
    }

    public getOrderTypes(query: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Get, ["order_type"], {}, query);
    }

    public getOrderMilestones(ref: string, query: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Get, ["milestones", "order", ref], {}, query);
    }


    public updateOrder(id: number, body: any): Promise<Api.IResponse> {
        if (!id) {
            return;
        }
        return this.apiService.request(Api.EMethod.Put, ["order", "" + id], body);
    }

}
