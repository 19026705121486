import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {Router} from "@angular/router";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {ApiService} from "../../../../../../../common/services/api.service";
import {Table} from "../../../../../../../common/interfaces/table.interface";
import {UserService} from "../../../../../../../common/services/user.service";
import {Modal, ModalService} from "../../../../../../services/modal.service";
import {HttpLogsViewComponent} from "../view/view.component";


@Component({
    selector: "section-http-logs",
    templateUrl: "http-logs.component.html",
    styleUrls: [
        "http-logs.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class HttpLogsComponent implements Base.IComponent, OnInit {

    public readonly state: Base.IState;
    public listTable: Table.ISettings;

    public constructor(
        private apiService: ApiService,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private modalService: ModalService,
        public userService: UserService
    ) {
    }

    private async viewAction(row: any): Promise<any> {
        if (row && row.id) {
            const response: Modal.IResponse = await this.modalService.open(HttpLogsViewComponent, {
                data: row,
                state: this.state,
                action: "view"
            });
        }
    }

    private async selectAction(row: any): Promise<any> {
        if (row && row.id) {
            const response: Modal.IResponse = await this.modalService.open(HttpLogsViewComponent, {
                data: row,
                state: this.state,
                action: "json"
            });
        }
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        const actions: Table.IAction[] = [];
        if (this.userService.validatePermissions("read_http_logs")) {
            actions.push({
                name: "view",
                title: "View",
                click: (row: any): void => {
                    this.viewAction(row);
                }
            });
            actions.push({
                name: "select",
                title: "View json",
                click: (row: any): void => {
                    this.selectAction(row);
                }
            });
        }
        this.listTable = {
            table_id: "0xhaJ7EaE59",
            actions,
            api: {
                url: ["logs"],
            },
            columns: [
                {
                    data: "code",
                    title: "Code"
                },
                {
                    data: "message",
                    title: "Message"
                },
                {
                    data: "name",
                    title: "Name"
                },
                {
                    data: "ref",
                    title: "Ref"
                },
                {
                    data: "type",
                    title: "Type"
                },
                {
                    data: "created_at",
                    title: "Created at"
                },
                {
                    data: "user.name",
                    title: "User"
                },
                {
                    data: "loggable_type",
                    title: "Loggable"
                }
            ]
        };
        this.changeDetectorRef.markForCheck();
    }

    public ngOnInit(): void {
        this.prepareList();
    }


    public ngConfig(): Base.IConfig {
        return {
            name: "http-logs",
            actions: {
                "browse": ["browse_http_logs"]
            }
        };
    }

}
