import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Modal} from "../../../../../services/modal.service";
import {UntypedFormBuilder, FormGroup, Validators, FormControl} from "@angular/forms";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {TIME_ZONES} from "../../../../../../common/common.module";
import {Form} from "../../../../../../common/interfaces/form.interface";
import ISelectOption = Form.ISelectOption;

@Component({
    selector: "section-tracking-shipped-at",
    templateUrl: "shipped-at.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class TrackingShippedAtComponent implements OnInit, OnDestroy {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    public modal: Modal.IModal;

    public formGroup: FormGroup;

    public countries: any[];

    public timezones: ISelectOption[] = [];

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private formBuilder: UntypedFormBuilder,
        public helpers: HelpersService,
        private toastService: ToastService,
        private spinnerService: SpinnerService
    ) {
    }

    /**
     * Prepare form group (create)
     * @returns {void}
     */
    private prepareForm(): void {
        const data: any = this.modal.params.data ? this.modal.params.data : {};

        this.formGroup = this.formBuilder.group({
            shipped_at: [data.shipped_at, [Validators.required]],
            shipped_at_tz: new FormControl("Asia/Jerusalem", [Validators.required]),
        });

        this.changeDetectorRef.markForCheck();
    }

    /**
     * Prepare form body data
     * @returns {any}
     */
    private prepareBody(): any {
        return {
            shipped_at: this.formGroup.value.shipped_at.format("YYYY-MM-DD HH:mm:ss"),
            shipped_at_tz: this.formGroup.value.shipped_at_tz
        };
    }

    /**
     * Submit form
     * @returns {Promise<any>}
     */
    public async handleFormSubmit(): Promise<any> {
        if (this.formGroup && this.formGroup.valid) {
            this.spinnerService.show();
            const response: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
                ["courier", "transactions", this.modal.params.data.id], this.prepareBody());
            this.spinnerService.hide();
            if (response) {
                this.toastService.show(response.message, response.type);

                this.modal.response.next({
                    name: "shipped_at",
                    value: response.data.shipped_at
                });
            }

        }
    }


    public ngOnInit(): void {
        this.prepareForm();

        for (const tz of Object.keys(TIME_ZONES)) {
            this.timezones.push({
                value: tz,
                name: TIME_ZONES[tz]
            });
        }
        this.changeDetectorRef.markForCheck();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
