import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: "search",
    pure: false
})
export class SearchPipe implements PipeTransform {
    public transform(items: any[], value: string, fields: string[]): any[] {
        if (!items) {
            return [];
        }
        if (value) {
            value = value.toLowerCase();
        } else {
            return items;
        }

        return items.filter((option: any): boolean => {
            let valid: boolean = false;

            for (const field of fields) {
                let option_iterated: any = option;
                const field_splitted: any = field.split(".");

                for (const part of field_splitted) {
                    if (option_iterated[part]) {
                        option_iterated = option_iterated[part];
                    }
                }

                if (option_iterated && typeof option_iterated === "string"
                    && option_iterated.toLowerCase().indexOf(value) !== -1) {
                    valid = true;
                }
            }
            return valid;
        });
    }
}
