<common-confirm></common-confirm>

<div class="row flex pull-right nowrap" [formGroup]="filters">
    <mat-form-field class="margin-right-20">
        <mat-label>Group</mat-label>
        <mat-select formControlName="hub_group_id">
            <mat-option [value]="null">All</mat-option>
            <mat-option *ngFor="let group of groups" [value]="group.value">
                {{ group.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Search hub...</mat-label>
        <input matInput type="search" formControlName="search">
    </mat-form-field>
</div>


<h3 class="mat-h3">Hubs</h3>
<div class="clear"></div>
<div *ngIf="showSpinner">
    <common-simple-spinner></common-simple-spinner>
</div>
<div *ngIf="!hubs || !hubs.data || hubs.data.length < 1">
    No any data
    <br>
    <br>
</div>
<mat-accordion *ngIf="hubs && hubs.data && hubs.data.length > 0 && !showSpinner">
    <ng-template ngFor let-item let-i="index" [ngForOf]="hubs.data">
        <mat-expansion-panel *ngIf="item" (opened)="prepareItemForm(item); openedItem = i">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span>
                         <b>{{ item.customers_inventory_name }}</b>
                        {{ item.customers_sub_inventory }}
                    </span>
                    &nbsp;|&nbsp;
                    <small>
                        {{ item.warehouse_slug }} / {{ item.sub_inventory }}
                    </small>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <form *ngIf="openedItem === i" [formGroup]="itemForm" autocomplete="off" (submit)="submitUpdate()">
                <div class="group flex">
                    <mat-form-field class="full">
                        <mat-label>ThreePl</mat-label>
                        <mat-select formControlName="threepl" required>
                            <mat-option>None</mat-option>
                            <mat-option *ngFor="let option of threepls || []" [value]="option.value">
                                {{ option.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="group flex">
                    <mat-form-field class="half">
                        <mat-label>Warehouse</mat-label>
                        <mat-select formControlName="warehouse_slug" required>
                            <mat-option>None</mat-option>
                            <mat-option *ngFor="let option of warehouses.item" [value]="option.value">
                                {{ option.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field *ngIf="itemForm.get('warehouse_slug').valid" class="half">
                        <mat-label>Customers inventory name</mat-label>
                        <input matInput formControlName="customers_inventory_name"
                               required>
                    </mat-form-field>
                </div>
                <div class="group flex" *ngIf="itemForm.get('warehouse_slug').valid">
                    <mat-form-field class="half">
                        <mat-label>Sub-inventory</mat-label>
                        <mat-select formControlName="sub_inventory" required>
                            <mat-option>None</mat-option>
                            <mat-option *ngFor="let option of subWarehouses.item || []"
                                        [value]="option.value">
                                {{ option.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field *ngIf="itemForm.get('sub_inventory').valid" class="half">
                        <mat-label>Customers sub-inventory name</mat-label>
                        <input matInput formControlName="customers_sub_inventory"
                               required>
                    </mat-form-field>
                </div>
                <div class="group flex">
                    <mat-form-field class="half">
                        <mat-label>GB</mat-label>
                        <mat-select formControlName="gb" required>
                            <mat-option>None</mat-option>
                            <mat-option value="good">Good</mat-option>
                            <mat-option value="defective">Defective</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="half">
                        <mat-label>Group</mat-label>
                        <mat-select formControlName="hub_group_id">
                            <mat-option [value]="null">None</mat-option>
                            <mat-option *ngFor="let group of groups" [value]="group.value">
                                {{ group.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="group flex">
                    <div class="third">
                        <button type="button" mat-raised-button color="accent" (click)="editProperties()"
                                matTooltip="Warning! Advanced stuff, proceed with caution."
                                matTooltipClass="background-error"
                                matTooltipPosition="after">
                            Edit properties
                        </button>
                    </div>
                    <div class="third"></div>
                    <div class="third text-right">
                        <mat-slide-toggle formControlName="is_active"
                                          (change)="amplitudeClick($event.checked?'Hub active':'Hub not active')">
                            Active
                        </mat-slide-toggle>
                    </div>
                </div>

                <div class="actions">
                    <button [disabled]="!itemForm.valid" mat-raised-button color="primary" type="submit">
                        Update
                    </button>

                    <!--                    <button mat-raised-button color="warn" class="delete-button" type="button" (click)="submitDelete()">-->
                    <!--                        Delete-->
                    <!--                    </button>-->

                </div>
            </form>
        </mat-expansion-panel>
    </ng-template>
    <common-pagination [data]="hubs"
                       pid="5Ts3eT6xN"
                       (goToPage)="getItems($event.page,$event.per_page, filters.value)"></common-pagination>
</mat-accordion>

<br>
<h3 class="mat-h3">Add new Hub</h3>
<form [formGroup]="newForm" autocomplete="off" (submit)="submitNew()">
    <div class="group flex">
        <mat-form-field class="full">
            <mat-label>ThreePl</mat-label>
            <mat-select formControlName="threepl" required>
                <mat-option>None</mat-option>
                <mat-option *ngFor="let option of threepls || []" [value]="option.value">
                    {{ option.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="group flex">
        <mat-form-field class="half">
            <mat-label>Warehouse</mat-label>
            <mat-select formControlName="warehouse_slug" required>
                <mat-option>None</mat-option>
                <mat-option *ngFor="let option of warehouses.new || []" [value]="option.value">
                    {{ option.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="newForm.get('warehouse_slug').valid" class="half">
            <mat-label>Customers inventory name</mat-label>
            <input matInput formControlName="customers_inventory_name"
                   required>
        </mat-form-field>
    </div>
    <div class="group flex" *ngIf="newForm.get('warehouse_slug').valid">
        <mat-form-field class="half">
            <mat-label>Sub-inventory</mat-label>
            <mat-select formControlName="sub_inventory" required>
                <mat-option>None</mat-option>
                <mat-option *ngFor="let option of subWarehouses.new || []" [value]="option.value">
                    {{ option.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="newForm.get('sub_inventory').valid" class="half">
            <mat-label>Customers sub-inventory name</mat-label>
            <input matInput formControlName="customers_sub_inventory"
                   required>
        </mat-form-field>
    </div>
    <div class="group flex">
        <mat-form-field class="half">
            <mat-label>GB</mat-label>
            <mat-select formControlName="gb" required>
                <mat-option>None</mat-option>
                <mat-option value="good">Good</mat-option>
                <mat-option value="defective">Defective</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="half">
            <mat-label>Group</mat-label>
            <mat-select formControlName="hub_group_id">
                <mat-option [value]="null">None</mat-option>
                <mat-option *ngFor="let group of groups" [value]="group.value">
                    {{ group.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="group flex">
        <div class="third" formGroupName="properties">
            <mat-checkbox formControlName="special_confirmation">Special confirmation</mat-checkbox>
        </div>
        <div class="third"></div>
        <div class="third text-right">
            <mat-slide-toggle formControlName="is_active">Active</mat-slide-toggle>
        </div>
    </div>

    <div class="actions">
        <button [disabled]="!newForm.valid" mat-raised-button color="primary" type="submit">Submit</button>
        <button mat-raised-button type="button" (click)="newForm.reset()">Reset</button>
    </div>

</form>

<mat-divider></mat-divider>

<div *ngIf="logsTableSettings" style="padding-top: 30px">
    <h3 class="mat-h3">Change log</h3>
    <common-table2 #logTable [settings]="logsTableSettings" [search]="false"></common-table2>
</div>

