import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {User} from "../../../../../../../common/interfaces/user.interface";
import {Contact} from "../../../../../../../common/interfaces/contact.interface";
import {FormControl} from "@angular/forms";
import {takeUntil} from "rxjs";
import {debounceTime} from "rxjs/operators";


@Component({
    selector: "section-package-sticker-default",
    templateUrl: "sticker.component.html",
    styleUrls: [
        "sticker.component.scss"
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PackageStickerDefaultComponent implements OnInit, AfterViewInit, OnDestroy {

    protected destroy$: EventEmitter<boolean> = new EventEmitter(false);

    @Input()
    public pack: any;

    @Input()
    public partner: User.IPartner;

    @Input()
    public pack_number: number;

    @Input()
    public pack_total: number;

    @Input()
    public from_address: Contact.IAddress;

    @Input()
    public to_address: Contact.IAddress;

    @Input()
    public orderRef: string;

    public items: any = {};

    @ViewChild("sticker", {static: false})
    public sticker: ElementRef;

    @Output()
    public ready: EventEmitter<string> = new EventEmitter<string>();

    public checked: FormControl = new FormControl(true);

    public ngOnInit(): void {
        for (const item of this.pack.warehouse_transactions) {
            if (!this.items.hasOwnProperty(item.item)) {
                this.items[item.item] = 0;
            }
            this.items[item.item] += Number(item.quantity);
        }

        this.checked.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(100))
            .subscribe((checked: boolean): void => {
            if (checked) {
                this.ready.emit(this.sticker.nativeElement.innerHTML);
            } else {
                this.ready.emit(null);
            }
        });
    }

    public ngAfterViewInit(): void {
        this.checked.setValue(true);
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
