<common-confirm></common-confirm>

<h1 class="container-heading">PartMasters conversions</h1>

<mat-card>
    <mat-card-content>

        <common-table2 *ngIf="listTable" [settings]="listTable">
            <div row1>
                <a *userAllowed="'add_part_master_conversions'"
                   mat-raised-button
                   color="primary"
                   (click)="add()">
                    Add
                </a>
            </div>
        </common-table2>

    </mat-card-content>
</mat-card>
