import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewEncapsulation
} from "@angular/core";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Order} from "../../../../../../common/interfaces/order.interface";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {IPagination} from "../../../../../../common/components/pagination/pagination.component";
import {ModalService} from "../../../../../services/modal.service";
import {ModalComponent} from "../../../../../../common/components/modal/modal.component";
import {UserService} from "../../../../../../common/services/user.service";

@Component({
    selector: "section-order-jobs",
    template: `

        <mgl-timeline *ngIf="data" [alternate]="false" mobileWidthThreshold="800">
            <mgl-timeline-entry *ngFor="let item of data.data">
                <mgl-timeline-entry-header>
                    {{item.job_title}}
                </mgl-timeline-entry-header>
                <mgl-timeline-entry-content>
                    <div>
                        <b>Status:</b> {{item.status}}
                    </div>
                    <div>
                        <b>Last update:</b> {{item.complete_at}}
                    </div>

                    <div class="padding-top-20">
                        <button *ngIf="item.payload.input" mat-raised-button (click)="showData('input',item)">
                            Show input
                        </button>
                        &nbsp;
                        <button *ngIf="item.payload.output" mat-raised-button (click)="showData('output',item)">
                            Show output
                        </button>

                        <p *ngIf="!item.payload.input && !item.payload.output">
                            No additional info
                        </p>
                    </div>
                    <ng-container *ngIf="item.status === 'failed'">
                        <p>
                            {{item.payload.failure_reason}}
                        </p>
                        <button mat-raised-button color="accent" type="button" (click)="restartJob(item)">
                            Restart
                        </button>
                    </ng-container>
                </mgl-timeline-entry-content>
                <mgl-timeline-entry-dot [size]="48" [class]="getStatusColor(item.status)">
                    <div>
                        <mat-icon class="vertical-middle">{{getStatusIcon(item.status)}}</mat-icon>
                    </div>
                </mgl-timeline-entry-dot>
            </mgl-timeline-entry>
        </mgl-timeline>

        <common-pagination *ngIf="data"
                           [data]="data"
                           pid="Vh4BZcc67"
                           (goToPage)="getData($event.page, $event.per_page)"></common-pagination>
    `,
    styleUrls: [
        "jobs.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class OrderJobsComponent implements OnInit, OnChanges {

    @Input()
    public orderData: Order.IOrderData;

    @Input()
    public orderRef: string;

    @Input()
    public orderId: number;

    public data: IPagination<{
        job_id: string,
        job: string,
        job_title: string,
        status: string,
        complete_at: string,
        tags: {
            name: string,
            value: string
        }[];
        payload: {
            input: string,
            output: string,
            failure_reason: string,
        };
    }>;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private toastService: ToastService,
        private spinnerService: SpinnerService,
        private modalService: ModalService,
        private userService: UserService
    ) {
    }

    public async getData(page: number = 1, per_page: number = null): Promise<any> {
        this.spinnerService.show();
        if (per_page === null) {
            per_page = this.userService.data.settings.default_per_page;
        }
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["order", "" + this.orderId, "jobs-logs"], {}, {
                page,
                per_page,
                data_structure: "paginated"
            });

        this.spinnerService.hide();
        if (data) {
            this.data = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    public getStatusIcon(status: string): string {
        switch (status) {
            case "finished":
                return "done";
            case "failed":
                return "report_problem";
            default:
                return "schedule";
        }
    }

    public getStatusColor(status: string): string {
        switch (status) {
            case "finished":
                return "primary";
            case "failed":
                return "warn";
            default:
                return "accent";
        }
    }

    public async restartJob(job: any): Promise<any> {
        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Post, ["jobs", "restart"],
            {
                job_id: job.job_id
            });

        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.getData();
        }
    }


    public async showData(type: "input" | "output", job: any): Promise<any> {
        this.modalService.open(ModalComponent, {
            template: job.payload[type],
            title: "Job " + type
        });
    }

    public ngOnInit(): void {
        if (this.orderData) {
            this.orderRef = this.orderData.ref;
            this.orderId = this.orderData.id;
        }
        if (this.orderData) {
            this.getData();
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.orderData && !changes.orderData.isFirstChange()) {
            this.orderRef = this.orderData.ref;
            this.getData();
        }
    }
}
