import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, ViewEncapsulation} from "@angular/core";
import {FormControl} from "@angular/forms";
import {takeUntil} from "rxjs/operators";
import {AbstractWizardStepComponent, Wizard} from "src/modules/common/interfaces/wizard.interface";
import {AmplitudeService} from "../../../../../../../common/services/amplitude.service";

@Component({
    selector: "section-partner-procedures-wizard-type",
    template: `
        <mat-card>
            <h1>Procedure type</h1>
            <div class="form">
                <mat-radio-group [formControl]="scanType">
                    <div *ngFor="let elem of groups">
                            <span class="title">{{elem.group}}</span>
                        <mat-card class="tile mat-elevation-z5" *ngFor="let option of elem.options"
                                  (click)="selectOption(option)">
                            <div>
                                <mat-radio-button [value]="option.value">
                                    <b>{{option.name}}</b>
                                </mat-radio-button>
                            </div>
                        </mat-card>
                    </div>
                </mat-radio-group>
            </div>
        </mat-card>
    `,
    styleUrls: [
        "type.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class PartnerProceduresWizardTypeComponent extends AbstractWizardStepComponent implements OnDestroy {

    private data: any;

    public scanType: FormControl = new FormControl(null);

    public wizardType: string;

    public groups: { group: string, options: { value: any, name: string }[] }[];

    public constructor(protected changeDetectorRef: ChangeDetectorRef,
                       ) {
        super(changeDetectorRef);
    }

    private getData(value: any): any {
        switch (this.wizardType) {
            case "procedures":
                return {scanType: value};
            default:
                return null;
        }
    }

    public selectOption(option): void {
        this.scanType.setValue(option.value);
        AmplitudeService.eventClick(option.name);
    }

    /**
     * Initialize step
     * @param data
     * @returns {Promise<any>}
     */
    public async init(data: Wizard.IData): Promise<any> {

        this.wizardType = data.wizardType;

        this.scanType.setValue(null);


        if (data.stepParams.groups) {
            this.groups = data.stepParams.groups;
        }

        if (data.state.params.type) {
            this.result.emit({
                action: "setup",
                value: data.state.params.type
            });
            this.result.emit({
                action: "result",
                value: this.getData(data.state.params.type),
                goToNextStep: true
            });

        } else {
            this.result.emit({
                action: "setup",
                value: this.scanType.value
            });
            this.result.emit({
                action: "result",
                value: this.getData(this.scanType.value),
            });

            this.scanType.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: string): void => {
                this.result.emit({
                    action: "setup",
                    value
                });
                this.result.emit({
                    action: "result",
                    value: this.getData(value),
                });
            });

            this.changeDetectorRef.markForCheck();
        }
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

}
