import {Injectable} from "@angular/core";
import {StorageService} from "./storage.service";


@Injectable()
export class PaginationService {

    protected paginators: { [key: string]: { page: number, per_page: number } };

    protected defaults: { page: number, per_page: number } = {page: 1, per_page: 10};

    public constructor(private storageService: StorageService) {
        this.paginators = this.storageService.get("pagination_state", {});
    }


    public setPageById(id: string, page: number): void {
        if (!id) {
            return;
        }
        if (!this.paginators[id] || typeof this.paginators[id] === "number") {
            this.paginators[id] = this.defaults;
        }
        this.paginators[id].page = page;

        this.storageService.set("pagination_state", this.paginators);
    }

    public getPageById(id: string, $default: number = 1): number {
        if (!this.paginators[id]) {
            return $default;
        }
        return this.paginators[id].page || $default;
    }

    public setPerPageById(id: string, per_page: number): void {
        if (!id) {
            return;
        }
        if (!this.paginators[id] || typeof this.paginators[id] === "number") {
            this.paginators[id] = this.defaults;
        }

        this.paginators[id].per_page = per_page;

        this.storageService.set("pagination_state", this.paginators);
    }

    public getPerPageById(id: string, $default: number = 10): number {
        if (!this.paginators[id]) {
            return $default;
        }
        return this.paginators[id].per_page || $default;
    }

}
