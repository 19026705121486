import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit} from "@angular/core";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {PartMasterService} from "../../../../../../common/services/part-master.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Api} from "../../../../../../common/services/api.service";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {FormControl} from "@angular/forms";
import {IPagination} from "../../../../../../common/components/pagination/pagination.component";
import {AddressService} from "../../../../../../common/services/address.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {debounceTime, takeUntil} from "rxjs/operators";
import {Router} from "@angular/router";
import {PromptModalComponent} from "../../../../../../common/components/prompt/prompt.component";
import {UserService} from "../../../../../../common/services/user.service";

@Component({
    selector: "section-address-link-part-masters",
    template: `
        <h1 class="container-heading">
            Linked part-masters
        </h1>
        <mat-card>
            <mat-card-content>
                <div>
                    <button (click)="import()"
                            mat-raised-button type="button" color="accent">Import
                    </button>
                </div>
                <div>
                    <mat-form-field>
                        <mat-label>Search</mat-label>
<input matInput [formControl]="search"  type="search">
                    </mat-form-field>
                </div>
                <ng-container *ngIf="part_masters">
                    <div *ngFor="let p of part_masters.data; let i = index">
                        <mat-checkbox [value]="p.id" [checked]="checkPM(p)"
                                      [disableRipple]="true"
                                      (change)="togglePM($event)">
                            <b>{{ p.item}}</b> | {{ p.description}}
                        </mat-checkbox>
                    </div>
                    <common-pagination [data]="part_masters"
                                       pid="4dkH8FoP0"
                                       (goToPage)="getPms($event.page,$event.per_page,search.value)">
                    </common-pagination>
                </ng-container>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkPartMastersToAddressComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    public modal: Modal.IModal;

    public part_masters: IPagination<any>;

    public search: FormControl = new FormControl(null);

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private spinnerService: SpinnerService,
        private toastService: ToastService,
        private addressService: AddressService,
        private modalService: ModalService,
        private router: Router,
        private partMasterService: PartMasterService,
        private userService: UserService
    ) {
    }

    public async getPms(page: number = 1, per_page: number = null, search_by: string = null): Promise<any> {
        this.spinnerService.show();
        if (per_page === null) {
            per_page = this.userService.data.settings.default_per_page;
        }
        const {data}: Api.IResponse = await this.partMasterService.getPartMasters({
            data_structure: "paginated",
            page,
            per_page,
            search_by,
            search_in: ["item"],
            with_address: true
        });

        this.spinnerService.hide();

        if (data) {
            this.part_masters = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    public checkPM(part_master: { addresses: any[] }): boolean {
        if (!part_master.addresses) {
            return false;
        }
        return !!part_master.addresses.find((address: any): boolean => {
            return address.id === this.modal.params.address_id;
        });
    }

    public async togglePM(event: MatCheckboxChange): Promise<any> {
        const id: number = Number(event.source.value);

        let max_qty: number = 0;
        if (event.checked) {
            const modalResponse: Modal.IResponse = await this.modalService.open(PromptModalComponent, {
                title: "Set max quantity for item",
                description: "0 for unlimited",
                fieldType: "number"
            });
            if (modalResponse && modalResponse.value) {
                max_qty = Number(modalResponse.value);
            } else {
                event.source.checked = false;
                return;
            }
        }

        const {code, message}: Api.IResponse =
            await this.addressService.linkPartMaster(this.modal.params.address_id, id, max_qty);

        if (code === 200) {
            this.toastService.show(message, "success");
        }
    }

    public import(): void {
        this.modal.close_all.emit(null);

        this.router.navigate([
            this.modal.params.state.section,
            "csv-import",
            "wizard",
            "type",
            "address_partmaster"
        ]);
    }

    public ngOnInit(): void {
        this.getPms();

        this.search.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: string): void => {
                this.getPms(1, 20, value);
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
