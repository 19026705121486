import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild} from "@angular/core";
import {Router} from "@angular/router";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {Table, TableComponent} from "../../../../../../common/components/table/table.component";
import {ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {UserService} from "../../../../../../common/services/user.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {PackageTypeViewComponent} from "../view/view.component";
import {SpinnerService} from "../../../../../../common/services/spinner.service";

@Component({
    selector: "section-package-type-list",
    template: `
        <common-confirm></common-confirm>

        <mat-card>
            <mat-card-content>
                <common-table *ngIf="listTable" [options]="listTable"
                              (action)="handleListAction($event)">
                    <div data-actions>
                        <a mat-raised-button color="primary" (click)="addType()">
                            Add New
                        </a>
                    </div>
                </common-table>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PackageTypeListComponent implements OnInit {
    @Input()
    public state: Base.IState;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild(TableComponent, {static: false})
    public tableRef: TableComponent;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.IOptions;

    /* Table options */

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private userService: UserService,
        private modalService: ModalService,
        private spinnerService: SpinnerService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        const actions: Table.Action.ISettings[] = [];
        /* Search for inventory in paths object within api.service*/
        const url: string = this.apiService.getUrl(["warehouse_package", "type"])
            + "?data_structure=dataTables";

        /* Setup columns for dataTable typed ColumnSettings */
        const columns: DataTables.ColumnSettings[] = [
            {
                data: "name",
                title: "Name"
            },
            {
                data: "created_at",
                title: "Created"
            },
            {
                data: "updated_at",
                title: "Updated"
            }
        ];

        this.listTable = {
            actions,
            api: url,
            columns,
            order: [],
            search: true
        };
        this.changeDetectorRef.markForCheck();
    }

    public async addType(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(PackageTypeViewComponent, {
            add: true
        });
        if (response) {
            this.tableRef.reload();
        }
    }

    /**
     * Handle list row action
     * @param {Table.Action.IResult} action
     * @returns {Promise<any>}
     */
    public async handleListAction(action: Table.Action.IResult): Promise<any> {
    }

    public ngOnInit(): void {
        this.spinnerService.show();
        this.prepareList();
        this.spinnerService.hide();
    }
}
