<common-confirm></common-confirm>

<h1 class="container-heading">{{action | ucfirst}} PartMaster analog</h1>

<mat-card>
    <mat-card-content>

        <form *ngIf="formGroup" [formGroup]="formGroup" (submit)="submit()" autocomplete="off" novalidate>

            <div class="group flex">
                <div class="full">
                    <common-form-select
                            label="Select item"
                            [options]="part_masters"
                            [required]="true"
                            [search]="true"
                            [value]="formGroup.value.part_master_id"
                            (valueChange)="formGroup.get('part_master_id').setValue($event)">
                    </common-form-select>
                </div>

                <div class="full">
                    <common-form-select
                            label="Select analogs"
                            [multiple]="true"
                            [options]="part_masters"
                            [required]="true"
                            [search]="true"
                            [value]="formGroup.value.analogs"
                            (onClose)="formGroup.get('analogs').setValue($event)">
                    </common-form-select>
                </div>

                <div class="full">
                    <mat-checkbox formControlName="reverse_links">
                        Make reverse links
                    </mat-checkbox>
                </div>

            </div>

            <div class="actions">
                <button mat-raised-button color="primary" type="submit" class="main"
                        [disabled]="!formGroup.valid">
                    Save
                </button>
                &nbsp;
                <ng-container *ngIf="action !== 'add'">
                    <button mat-raised-button *userAllowed="['delete_part_master_conversions']"
                            type="button" color="warn" (click)="delete()">
                        Delete
                    </button>
                    &nbsp;
                </ng-container>

                <button mat-button type="button" (click)="modal.response.emit(null)">
                    Cancel
                </button>
            </div>

        </form>

    </mat-card-content>
</mat-card>
