import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, Validators} from "@angular/forms";
import {takeUntil} from "rxjs/operators";
import {HelpersService} from "../../../services/helpers.service";
import * as moment from "moment";
import {Moment} from "moment";
import {NGX_MAT_DATE_FORMATS} from "@angular-material-components/datetime-picker";


@Component({
    selector: "common-form-date-time",
    template: `
        <mat-form-field (click)="picker.open()">
            <mat-label>{{label}}</mat-label>
            <input matInput
                   [ngxMatDatetimePicker]="picker"
                   [formControl]="date"
                   [required]="required"
                   [min]="min"
                   [max]="max"
                   readonly>
            <mat-icon matSuffix>today</mat-icon>
            <ngx-mat-datetime-picker #picker [touchUi]="true" [showSpinners]="true" [stepSecond]="30">
                <ng-template>
                    <span>Apply</span>
                </ng-template>
            </ngx-mat-datetime-picker>
        </mat-form-field>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    styles: [
        `
            common-form-date-time .mat-mdc-form-field {
                width: 100%;
                display: block;
            }
        `
    ],
    providers: [
        {
            provide: NGX_MAT_DATE_FORMATS, useValue: {
                display: {
                    dateInput: "DD.MM.YYYY HH:mm",
                    monthYearLabel: "MMM YYYY",
                    dateA11yLabel: "YYYY.MM.DD",
                    monthYearA11yLabel: "MMMM YYYY",
                },
                parse: {
                    dateInput: "YYYY-MM-DD HH:mm:ss",
                }
            }
        }
    ]
})
export class CommonFormDateTimeComponent implements OnInit, OnDestroy, OnChanges {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    public date: FormControl;

    @Input()
    public label: string;

    @Input()
    public value: string = null;

    @Input()
    public required: boolean = false;

    @Output()
    public valueChange: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    public valueChangeMoment: EventEmitter<Moment> = new EventEmitter<Moment>();

    @Input()
    public reports: boolean;

    @Input()
    public min_date: string;

    @Input()
    public max_date: string;

    public min: Date = null;

    public max: Date = null;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        public helpers: HelpersService
    ) {
    }

    public ngOnInit(): void {

        if (this.min_date) {
            this.min = new Date(this.min_date);
        }

        if (this.max_date) {
            this.max = new Date(this.max_date);
        }

        this.date = new FormControl(null, this.required ? [Validators.required] : []);

        this.date.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val: any): void => {
            val = moment(val);
            if (val && val._isValid) {
                this.valueChange.emit(val.format("YYYY-MM-DD HH:mm:ss"));
                this.valueChangeMoment.emit(val);
            } else {
                this.valueChange.emit(null);
                this.valueChangeMoment.emit(null);
            }
        });

        if (this.value) {
            this.date.setValue(moment(this.value));
        }
        this.changeDetectorRef.markForCheck();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.min_date && !changes.min_date.firstChange && changes.min_date.currentValue) {
            this.min = new Date(changes.min_date.currentValue);
        }

        if (changes.max_date && !changes.max_date.firstChange && changes.max_date.currentValue) {
            this.max = new Date(changes.max_date.currentValue);
        }

        if (changes.value && !changes.value.firstChange && moment(changes.value.currentValue) !== this.date.value) {
            this.date.setValue(moment(changes.value.currentValue));
        }
    }
}
