import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    EventEmitter,
    Output,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from "@angular/core";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {OrderTrackProgressComponent} from "../../../../partner/components/order";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {TrackingChangeComponent, TrackingStatusComponent} from "../index";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {CourierService} from "../../../../../../common/services/courier.service";

@Component({
    selector: "section-tracking-sidebar",
    template: `
        <common-confirm></common-confirm>
        <div *ngIf="showOverlay" id="sidebar_overlay" (click)="isOpen = false"></div>
        <div id="sidebar" class="{{isOpen ? 'open' : ''}}">
            <div id="sb_flag" (click)="isOpen = false">
                <mat-icon>keyboard_arrow_right</mat-icon>
            </div>
            <div class="content">
                <div class="track-menu" *ngIf="data">
                    <div class="options">
                        <button mat-raised-button [matMenuTriggerFor]="menu">
                            <mat-icon>more_horiz</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <a mat-menu-item (click)="actionHandler('add_status')">
                                <mat-icon>add</mat-icon>
                                Add status
                            </a>
                            <a mat-menu-item (click)="actionHandler('change')">
                                <mat-icon>edit</mat-icon>
                                Change tracking
                            </a>
                            <a mat-menu-item *userAllowed="'edit_tracking_price'"
                               (click)="actionHandler('edit_price')">
                                <mat-icon>monetization_on</mat-icon>
                                Edit price
                            </a>
                            <a mat-menu-item *userAllowed="'browse_admin'" (click)="actionHandler('delete')">
                                <mat-icon>delete</mat-icon>
                                Remove tracking
                            </a>
                        </mat-menu>
                    </div>
                    {{data.tracking_number}}

                </div>
                <mat-tab-group>
                    <mat-tab label="Track progress">
                        <ng-template #tp1></ng-template>
                    </mat-tab>
                    <mat-tab label="Track info" *ngIf="data">
                        <h2>Order</h2>
                        <div><b>Ref:</b> {{data.order.ref}}</div>
                        <div><b>Service Level:</b> {{data.order.service_level.name}}</div>
                        <div><b>Status:</b> {{data.order.status.name}}</div>

                        <h2>Items:</h2>
                        <div class="item" *ngFor="let item of data.warehouse_transactions">
                            <div><b>PN:</b> {{item.item}}</div>
                            <div><b>SN:</b> {{item.serial}}</div>
                            <div><b>quantity:</b> {{item.serial}}</div>
                            <ng-template [ngIf]="item.inventory_conversion">
                                <div>
                                    <b>H:</b>
                                    {{item.inventory_conversion.customers_inventory_name}}
                                </div>
                                <div>
                                    <b>Sub WH:</b>
                                    {{item.inventory_conversion.customers_sub_inventory}}
                                </div>
                            </ng-template>
                        </div>

                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    `,
    styleUrls: [
        "sidebar.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class TrackingSidebarComponent {

    public showOverlay: boolean = false;
    public isOpen: boolean = false;

    @ViewChild("tp1", {read: ViewContainerRef, static: false})
    public tp1: any;

    @ViewChild(ConfirmComponent, {static: true})
    public confirmRef: ConfirmComponent;

    public data: { [key: string]: any };

    @Output()
    public onUpdate: EventEmitter<any> = new EventEmitter<any>();

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private apiService: ApiService,
        private modalService: ModalService,
        private spinnerService: SpinnerService,
        private courierService: CourierService,
        private toastService: ToastService
    ) {
    }

    private async delete(): Promise<any> {
        if (!await this.confirmRef.confirm("Do you want to delete this tracking?")) {
            return;
        }
        this.spinnerService.show();
        const response: Api.IResponse = await this.courierService.deleteTransaction(this.data.id);
        this.spinnerService.hide();
        if (response) {
            this.toastService.show(response.message, response.type);
            this.onUpdate.emit(true);
            this.isOpen = false;
            this.changeDetectorRef.markForCheck();
        }
    }

    public async show(id: number): Promise<any> {

        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["courier", "transactions", "" + id]);

        if (data) {
            this.data = data;

            this.tp1.clear();
            const component1Factory: any = this.componentFactoryResolver
                .resolveComponentFactory(OrderTrackProgressComponent);
            const container1Ref: any = this.tp1.createComponent(component1Factory);
            (container1Ref.instance as OrderTrackProgressComponent).data = this.data;


            this.isOpen = true;
            this.changeDetectorRef.markForCheck();
        }

    }


    public async actionHandler(action: string): Promise<any> {

        let component: any;

        switch (action) {
            case "add_status":
                component = TrackingStatusComponent;
                break;
            case "change":
            case "edit_price":
                component = TrackingChangeComponent;
                break;
            case "delete":
                this.delete();
                return;
            default:
                return;
        }

        const response: Modal.IResponse = await this.modalService.open(component, {
            data: this.data,
            action,
            modalWidth: "600px"
        });

        if (response) {
            this.show(this.data.id);
            if (response.value === "edit_price") {
                this.onUpdate.emit(true);
            }
        }


    }

}
