<div class="row nowrap stretch margin-bottom-20 flex">
    <mat-card class="col-left">
        <h2 class="border-bottom-none">Scan location</h2>

        <div class="bordered-box margin-bottom-20" [class.bordered-box-active]="!location">
            <table>
                <tr>
                    <td width="60">
                        <i class="fa fa-barcode fa-4x text-success"></i>
                    </td>
                    <td>
                        <mat-form-field style="width: 100%">
                            <mat-label>Location</mat-label>
                            <input matInput [formControl]="searchLocation">
                        </mat-form-field>
                    </td>
                    <td width="110">
                        <button type="button" mat-raised-button color="success"
                                [disabled]="!searchLocation.value"
                                (click)="findLocation()">
                            <i class="fa fa-barcode"></i>
                            Search
                        </button>
                    </td>
                </tr>
            </table>

            <div class="text-center">
                Your scanner default key set as {{ scannerKey }}.
            </div>
        </div>

        <div class="margin-bottom-10">
            <button type="button" mat-raised-button color="primary" class="pull-right" (click)="addLocation('')">
                Add location
                <mat-icon>add</mat-icon>
            </button>
            <div class="clear"></div>
        </div>

        <div class="bordered-box margin-bottom-20">
            <div>
                <table>
                    <tr>
                        <td width="30">
                            <mat-icon>location_city</mat-icon>
                        </td>
                        <td>
                            <h2>Selected location</h2>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <b *ngIf="location" class="text-success" style="font-size: 140%;">
                                {{ location.location }} -
                                {{ location.warehouse_location_type?.name }}
                            </b>
                            <b *ngIf="!location" class="color-warn" style="font-size: 140%;">
                                not selected
                            </b>
                        </td>
                    </tr>
                </table>
            </div>
            <div>
                <table>
                    <tr>
                        <td width="30">
                            <mat-icon>location_city</mat-icon>
                        </td>
                        <td>
                            <h2>Used locations</h2>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <div *ngIf="usedLocations">
                                <table *ngFor="let location of usedLocations" class="bordered">
                                    <tr>
                                        <td>
                                            {{ location.location }}
                                        </td>
                                        <td width="40">
                                            <div (click)="setLocationFromList(location)" class="pointer chipsa">
                                                Select
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

    </mat-card>

    <mat-card class="col-center max margin-right-10 margin-left-10">
        <h2 class="border-bottom-none">
            Scan items
        </h2>

        <div class="bordered-box margin-bottom-20" [class.bordered-box-active]="location">
            <div *ngIf="!location" class="text-center">
                <h2 class="color-warn">Select location first</h2>
            </div>
            <table class="search-field" *ngIf="location">
                <tr>
                    <td width="60">
                        <i class="fa fa-barcode fa-4x text-success"></i>
                    </td>
                    <td>
                        <mat-form-field style="width: 100%">
                            <mat-label>Item, serial, box, pallet</mat-label>
                            <input matInput type="search"
                                   [formControl]="searchInput"
                                   (keydown)="onKeydown($event)" #searchInputRef>
                        </mat-form-field>
                    </td>
                    <td width="110">
                        <button type="button" mat-raised-button color="success" (click)="search()"
                                [disabled]="!searchInput.value">
                            <i class="fa fa-barcode"></i> Search
                        </button>
                    </td>
                </tr>
            </table>
            <div class="text-center">
                Your scanner default key set as {{ scannerKey }}.
            </div>

        </div>

        <div class="bordered-box" *ngIf="scanFeedTable">
            <common-table2 [settings]="scanFeedTable">
                <div row1>
                    <h2 class="border-bottom-none">Scanned values</h2>
                </div>
            </common-table2>
        </div>

    </mat-card>
</div>