import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {ModalService} from "../../../../../services/modal.service";
import {Warehouse} from "../../../../../../common/interfaces/warehouse.interface";
import {FormControl} from "@angular/forms";
import {debounceTime, distinctUntilChanged, takeUntil} from "rxjs/operators";
import {CommonFormComponent} from "../../../../../../common/components/form";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {Table2Component} from "../../../../../../common/components/table2";
import {Form} from "../../../../../../common/interfaces/form.interface";
import ISelectOption = Form.ISelectOption;


@Component({
    selector: "section-stock-summarized-anpa",
    templateUrl: "stock.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StockSummarizedAnnaPurnaComponent implements Base.IComponent, OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    public state: Base.IState;

    public hubList: ISelectOption[];

    public hubSelect: FormControl = new FormControl([]);

    public availableOnly: FormControl = new FormControl(false);

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.ISettings}
     */
    public listTable: Table.ISettings;

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private modalService: ModalService,
        private changeDetectorRef: ChangeDetectorRef,
        private spinnerService: SpinnerService
    ) {
    }

    private get url(): Table.ITableApi {
        return {
            url: [this.state.section, "inventories"],
            query: {
                type: "summarized",
                hubs: this.hubSelect.value,
                relations: [
                    "InventoryConversion:id,customers_inventory_name,customers_sub_inventory,gb",
                    "PartMaster:id,properties,item,default_quantity,customs_value",
                    // "GlobalInboundInventoryAllocations:part_master_id,inventory_conversion_id,quantity",
                    // "GlobalOrderItemsAwaitingInbound:part_master_id,inventory_conversion_id,quantity",
                    // "GlobalOrderItemsPacked:part_master_id,inventory_conversion_id,quantity"
                ],
                only_available: this.availableOnly.value
            },
            version: 3
        };
    }


    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {

        const columns: Table.ICol[] = [
            {
                "data": "part_master.properties.type",
                "name": "PartMaster.properties.type",
                "title": "Type",
                "sortable": false,
                searchable: false
            },
            {
                "data": "part_master.properties.short_name",
                "name": "PartMaster.properties.short_name",
                "title": "Short name",
                "sortable": false,
                searchable: false
            },
            {
                "data": "part_master.properties.silicon_family",
                "name": "PartMaster.properties.silicon_family",
                "title": "Silicon Family",
                "sortable": false,
                searchable: false
            },
            {
                "data": "item",
                "name": "item",
                "title": "Item",
            },
            {
                "data": "inventory_conversion.customers_inventory_name",
                "name": "InventoryConversion.customers_inventory_name",
                "title": "Warehouse",
                "sortable": false
            },
            {
                "data": "inventory_conversion.customers_sub_inventory",
                "name": "InventoryConversion.customers_sub_inventory",
                "title": "Sub inventory",
                "sortable": false
            },
            {
                "data": "inventory_conversion.gb",
                "name": "InventoryConversion.gb",
                "title": "Good/Defective",
                "sortable": false
            },
            {
                "data": "sum_quantity",
                "name": "sum_quantity",
                "title": "Quantity on hand",
                "searchable": false,
            },
            {
                "data": "global_inbound_inventory_allocations",
                "title": "IN TRANSIT TO WH",
                "name": "global_inbound_inventory_allocations",
                "searchable": false,
            },
            {
                "data": "global_order_items_packed",
                "title": "IN TRANSIT TO CUSTOMER",
                "name": "global_order_items_packed",
                "searchable": false,
            },
            {
                "data": "part_master.default_quantity",
                "name": "PartMaster.default_quantity",
                "title": "Quantity per box",
            },
            {
                "data": "part_master.default_quantity",
                "name": "annapurna.quantity_of_boxes",
                "title": "Quantity of boxes",
                "searchable": false,
                "sortable": false,
                render: (row: any): string => {
                    if (row.part_master.default_quantity) {
                        return "" + (row.sum_quantity / row.part_master.default_quantity);
                    }
                    return "No default quantity in part";
                }
            },
            {
                "data": "part_master.customs_value",
                "name": "PartMaster.customs_value",
                "title": "Selling price per unit(USD)",
                "sortable": false,
                render: (row: any): string => {
                    if (row.part_master.customs_value) {
                        return "" + Number(row.part_master.customs_value).toFixed(2);
                    }
                    return "No customs value in part";
                }
            },
            {
                "data": "part_master.customs_value",
                "name": "annapurna.total_value",
                "title": "Total Value (USD)",
                "searchable": false,
                "sortable": false,
                render: (row: any): string => {
                    if (row.part_master.customs_value) {
                        return "" + Number(row.sum_quantity * row.part_master.customs_value).toFixed(2);
                    }
                    return "No customs value in part";
                }
            },
        ];


        this.listTable = {
            table_id: "3L23Jy9hTxsaNpa",
            api: this.url,
            columns,
            export: {
                file_name: "Stock summarized",
                columns,
            },
            infinity_scroll: true,
            search_default: ["item"]
        };
        this.changeDetectorRef.markForCheck();
    }


    private async getHubList(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["hub"]);
        if (data) {
            this.hubList = data.map((hub: Warehouse.IHub): any => {
                return {
                    name: `${hub.customers_inventory_name} - ${hub.customers_sub_inventory}`,
                    value: hub.id
                };
            });
            this.hubSelect.setValue([this.hubList[0].value]);
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    public async report(): Promise<any> {
        await this.modalService.open(CommonFormComponent, {
            configUrl: ["report", "inventories-list"],
            submitUrl: ["report", "inventories-list"],
            asyncKey: HelpersService.randomString()
        });
    }

    public ngOnInit(): void {

        this.getHubList().then(() => {
            this.prepareList();

            this.hubSelect.valueChanges.pipe(takeUntil(this.destroy$))
                .subscribe((value: any[]): void => {
                    this.tableRef.reload(this.url);
                });

            this.availableOnly.valueChanges.pipe(takeUntil(this.destroy$))
                .subscribe((value: any[]): void => {
                    this.tableRef.reload(this.url);
                });
        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "stock-summarized-for-annapurna",
            actions: {
                "browse": ["browse_inventories"]
            }
        };
    }
}
