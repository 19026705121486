<h1 class="container-heading">{{modal.params.title}}</h1>
<mat-card>
    <mat-card-content>
        <div class="form">
            <div class="margin-bottom-20" [innerHTML]="modal.params.description"></div>
            <div class="row flex">
                <mat-form-field class="full">
                    <mat-label>Value</mat-label>
<input matInput [type]="modal.params.fieldType || 'text'"
                           [formControl]="promptControl"
                           >
                </mat-form-field>
            </div>
            <div class="actions">
                <button mat-raised-button color="primary"
                        [disabled]="!promptControl.valid"
                        (click)="modal.response.emit({name:'', value:promptControl.value} )">
                    Ok
                </button>

                <button mat-button (click)="modal.response.emit(null)">
                    Cancel
                </button>
            </div>

        </div>
    </mat-card-content>
</mat-card>
