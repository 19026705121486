<h1 class="container-heading">{{state.action === "add" ? "Add" : "Edit"}} label</h1>

<mat-card>
    <mat-card-content>

        <mat-horizontal-stepper #stepper [linear]="true" (selectionChange)="handleStepChanges($event)">

            <ng-template matStepperIcon="edit">
                <mat-icon>done</mat-icon>
            </ng-template>

            <ng-template matStepperIcon="done">
                <mat-icon>done_all</mat-icon>
            </ng-template>

            <ng-template matStepperIcon="number" let-active="active" let-index="index">
                <mat-icon *ngIf="active; else elseIcon">create</mat-icon>
                <ng-template #elseIcon>{{index + 1}}</ng-template>
            </ng-template>

            <mat-step [editable]="step1.control.valid" [stepControl]="step1.control">

                <ng-template matStepLabel>Check shipment info</ng-template>

                <div class="step">
                    <section-shippy-pickup-wizard-step1
                            (result)="step1.control.setValue($event)"></section-shippy-pickup-wizard-step1>
                    <br>
                    <div class="actions">
                        <button *ngIf="step1.control.valid"
                                matStepperNext
                                mat-raised-button
                                color="primary"
                                type="button"
                                class="main">
                            Next
                        </button>
                    </div>
                </div>

            </mat-step>


            <mat-step
                    [editable]="step2.control.valid"
                    [stepControl]="step2.control">

                <ng-template matStepLabel>Choose packages</ng-template>

                <div class="step">
                    <section-shippy-pickup-wizard-step2
                            (result)="step2.control.setValue($event)"></section-shippy-pickup-wizard-step2>
                    <br>
                    <div class="actions">
                        <button matStepperPrevious mat-raised-button type="button">Previous</button>
                        &nbsp;
                        <button *ngIf="step2.control.valid"
                                matStepperNext
                                mat-raised-button
                                color="primary"
                                type="button"
                                class="main">
                            Next
                        </button>
                    </div>
                </div>
            </mat-step>

            <mat-step
                    [editable]="step3.control.valid"
                    [stepControl]="step3.control">

                <ng-template matStepLabel>Choose service level</ng-template>

                <div class="step">
                    <section-shippy-pickup-wizard-step3
                            (result)="step3.control.setValue($event)"></section-shippy-pickup-wizard-step3>
                    <br>
                    <div class="actions">
                        <button matStepperPrevious mat-raised-button type="button">Previous</button>
                        &nbsp;

                        <button *ngIf="step3.control.valid" (click)="finish()"
                                mat-raised-button
                                color="primary"
                                type="button"
                                class="main">
                            Create pickup
                        </button>
                    </div>
                </div>
            </mat-step>
        </mat-horizontal-stepper>

    </mat-card-content>
</mat-card>
