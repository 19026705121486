<form *ngIf="formGroup" [formGroup]="formGroup">
    <div class="group">
        <div class="full">
            <h3 class="mat-h3">Warehouse instructions</h3>
        </div>
        <mat-form-field class="full">
            <textarea matInput formControlName="warehouse_instructions" [rows]="2"></textarea>
        </mat-form-field>

        <div class="full">
            <h3 class="mat-h3">Shipping instructions</h3>
        </div>
        <mat-form-field class="full">
            <textarea matInput formControlName="shipping_instructions" [rows]="2"></textarea>
        </mat-form-field>

        <div class="full">
            <h3 class="mat-h3">Remarks</h3>
        </div>
        <mat-form-field class="full">
            <textarea matInput formControlName="order_remarks" [rows]="2"></textarea>
        </mat-form-field>

        <div class="full">
            <common-form-date
                    label="Expected delivery date"
                    [min_date]="min"
                    (valueChange)="formGroup.get('deliver_on').setValue($event)"
            ></common-form-date>
        </div>

<!--        <ng-template [ngIf]="service_level && service_level.properties.confirm !=='none'">-->
<!--            <div class="third">-->
<!--                <common-form-date-time-->
<!--                        label="Confirm on"-->
<!--                        [min_date]="min"-->
<!--                        [value]="confirm_on"-->
<!--                        (valueChange)="formGroup.get('confirm_on').setValue($event)"-->
<!--                ></common-form-date-time>-->
<!--            </div>-->
<!--            <div class="full">-->
<!--                <div class="mat-error">-->
<!--                    Please note that in case of filling of the confirmation date, this order will be executed-->
<!--                    automatically-->
<!--                </div>-->
<!--            </div>-->
<!--        </ng-template>-->
    </div>
</form>
