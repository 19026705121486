<div class="heading">
    <div class="actions">
        <ng-content select="[data-actions]"></ng-content>
        <ng-template [ngIf]="ready && showButtons">
            <button *ngIf="isButtonExist('csv')" mat-raised-button color="primary" (click)="handleButtonAction('csv')">
                Export CSV
            </button>
        </ng-template>
        <button *ngIf="refreshButton" mat-mini-fab color="warn" (click)="clearState()">
            <mat-icon>refresh</mat-icon>
        </button>
    </div>
    <ng-template [ngIf]="ready && options.search">
        <mat-form-field class="search">
            <mat-label>Search</mat-label>
<input #search matInput type="search" >
        </mat-form-field>
    </ng-template>

</div>

<table #table class="table" width="100%"></table>