<common-confirm></common-confirm>

<mat-card>
    <h1 class="color-accent">Find related {{wizardType}} order</h1>
    <div class="search">
        <div class="info">
            <mat-icon class="pull-left">info</mat-icon>
            You may use reference for one of the following categories:<br/>
            Order ref, Box ref, serial number, Tracking number
        </div>
        <div class="search-field">
            <mat-form-field>
                <mat-label>Reference</mat-label>
                <input matInput type="search" #searchinput (keydown)="onKeydown($event)">
            </mat-form-field>
            <button type="button" mat-raised-button color="accent" (click)="findOrder()">
                <i class="fa fa-barcode"></i> Search
            </button>
        </div>

        Your scanner default key set as {{ scannerKey }}
    </div>

    <ng-template [ngIf]="foundOrders.length > 0">
        <br>
        <br>
        <h2>{{message}}:</h2>
        <table class="table orders">
            <thead>
            <tr>
                <th>Partner</th>
                <th>Order ref</th>
                <th>Warehouse</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <ng-template ngFor let-wHorder [ngForOf]="foundOrders">
                <tr>
                    <td class="partner">
                        <img [src]="wHorder.order.partner.icon_path" [alt]="wHorder.order.partner.display_name">
                    </td>
                    <td class="ref">
                        {{wHorder.order.ref}}
                    </td>
                    <td class="warehouse">
                        {{wHorder.warehouse.name}}
                    </td>
                    <td class="barcode">
                        <common-barcode [bcValue]="'WHO::'+wHorder.id +'~'+wHorder.order.ref+'~'+wHorder.order.id"
                                        (click)="goToOrder(wHorder.id,wHorder.order.ref,wHorder.order.id)"
                                        [bcDisplayValue]="false"
                                        [bc-height]="50" [bc-width]="1">
                        </common-barcode>
                    </td>
                </tr>
                <tr *ngIf="wHorder.holds && wHorder.holds.length">
                    <td colspan="4">
                        <div *ngFor="let hold of wHorder.holds" class="color-accent text-left margin-bottom-10"
                             style="white-space:normal">
                            NOTE!
                            <div>{{hold.message}}</div>
                            <div *ngIf="hold.can_be_skipped">(this hold can be skipped)</div>
                        </div>
                    </td>
                </tr>

                <tr>
                    <td colspan="2">
                        <div class="text-left">
                            <b>Remarks:</b>
                            {{wHorder.order.remarks}}
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="text-left"
                             *ngIf="wHorder.order.custom_fields && wHorder.order.custom_fields['test_items'] ==='Yes'">
                            <span class="background-accent"
                                  style="color:#000; line-height:24px; padding:3px 5px; display:inline-block;">
                                <mat-icon style="vertical-align: middle; color: #f44336">error</mat-icon>
                                <b>Test items:</b> Yes
                            </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <table class="table text-left">
                            <thead>
                            <tr>
                                <th *ngIf="wHorder.order.shipments.length > 0">
                                    Shipments
                                </th>
                                <th>
                                    Details
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td *ngIf="wHorder.order.shipments.length > 0">
                                    <div class="relative" *ngFor="let shipment of wHorder.order.shipments">
                                        <div *ngIf="shipment.from_address">
                                            <mat-icon>flight_takeoff</mat-icon>
                                            {{shipment.from_address.address}}
                                            {{shipment.from_address.city}}
                                            {{shipment.from_address.zip}}
                                            {{shipment.from_address.state}}
                                            {{shipment.from_address.country_iso_2}}
                                        </div>
                                        <div *ngIf="shipment.to_address">
                                            <mat-icon>flight_land</mat-icon>
                                            {{shipment.to_address.address}}
                                            {{shipment.to_address.city}}
                                            {{shipment.to_address.zip}}
                                            {{shipment.to_address.state}}
                                            {{shipment.to_address.country_iso_2}}
                                        </div>
                                        <mat-divider></mat-divider>
                                    </div>
                                </td>
                                <td class="vertical-top">
                                    <div>
                                        <b>Confirmed on:</b>
                                        {{wHorder.order.confirmed_on}}
                                    </div>
                                    <div>
                                        <b>Service level:</b>
                                        {{wHorder.order.service_level.name}}
                                    </div>

                                    <div class="tracking" *ngIf="wHorder.order.courier_transactions">
                                        <div *ngFor="let track of wHorder.order.courier_transactions">
                                            <b>Tracking #:</b>
                                            &nbsp;
                                            <a [href]="track.tracking_url"
                                               target="_blank">{{track.tracking_number}}</a>
                                            <img [src]="track.courier.icon_path" class="vertical-middle"
                                                 [alt]="track.courier.display_name">
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </ng-template>
            </tbody>
        </table>
    </ng-template>
</mat-card>
