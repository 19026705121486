<mat-card>
    <div class="subheader">
        <h3 matSubheader>OAuth Clients</h3>
        <button mat-mini-fab color="primary" aria-label="Create" (click)="createClient()">
            <mat-icon>add</mat-icon>
        </button>
    </div>
    <mat-table class="mat-elevation-z3" [dataSource]="oAuthDataSource">

        <!-- Client ID Column -->
        <ng-container matColumnDef="client_id">
            <mat-header-cell *matHeaderCellDef> Client ID</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef> Name</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
        </ng-container>

        <!-- Secret Column -->
        <ng-container matColumnDef="secret">
            <mat-header-cell *matHeaderCellDef> Secret</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.secret}} </mat-cell>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let element">
                <button mat-mini-fab color="warn" aria-label="Delete" (click)="deleteClient(element)">
                    <mat-icon>delete</mat-icon>
                </button>
                <button mat-mini-fab color="primary" aria-label="Edit" (click)="editClient(element)">
                    <mat-icon>edit</mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="noData">
            <mat-footer-cell *matFooterCellDef [attr.colspan]="oAuthDisplayedColumns.length">
                No records found.
            </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="loading">
            <mat-footer-cell *matFooterCellDef [attr.colspan]="oAuthDisplayedColumns.length">
                Loading, please wait...
            </mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="oAuthDisplayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: oAuthDisplayedColumns;"></mat-row>
        <mat-footer-row *matFooterRowDef="['noData']"
                        [hidden]="oAuthDataSource === undefined || oAuthDataSource.length > 0"></mat-footer-row>
        <mat-footer-row *matFooterRowDef="['loading']" [hidden]="! oAuthLoading"></mat-footer-row>

    </mat-table>

    <h3 matSubheader>Authorized Applications</h3>

    <mat-table class="mat-elevation-z3" [dataSource]="applicationsDataSource">

        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef> Name</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.client.name}} </mat-cell>
        </ng-container>

        <!-- Scopes Column -->
        <ng-container matColumnDef="scopes">
            <mat-header-cell *matHeaderCellDef> Scopes</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.scopes.join(', ')}} </mat-cell>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let element">
                <button mat-mini-fab color="warn" aria-label="Revoke" (click)="revokeApplication(element)">
                    <mat-icon>block</mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="noData">
            <mat-footer-cell *matFooterCellDef [attr.colspan]="applicationsDisplayedColumns.length">
                No records found.
            </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="loading">
            <mat-footer-cell *matFooterCellDef [attr.colspan]="applicationsDisplayedColumns.length">
                Loading, please wait...
            </mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="applicationsDisplayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: applicationsDisplayedColumns;"></mat-row>
        <mat-footer-row *matFooterRowDef="['noData']"
                        [hidden]="applicationsDataSource === undefined || applicationsDataSource.length > 0"></mat-footer-row>
        <mat-footer-row *matFooterRowDef="['loading']" [hidden]="! applicationsLoading"></mat-footer-row>

    </mat-table>

    <div class="subheader">
        <h3 matSubheader>Personal Access Tokens</h3>
        <button mat-mini-fab color="primary" aria-label="Create" (click)="createPersonalAccessToken()">
            <mat-icon>add</mat-icon>
        </button>
    </div>

    <mat-table class="mat-elevation-z3" [dataSource]="personalDataSource">

        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef> Name</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let element">
                <button mat-mini-fab color="warn" aria-label="Delete" (click)="deletePersonalAccessToken(element)">
                    <mat-icon>delete</mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="noData">
            <mat-footer-cell *matFooterCellDef [attr.colspan]="personalDisplayedColumns.length">
                No records found.
            </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="loading">
            <mat-footer-cell *matFooterCellDef [attr.colspan]="personalDisplayedColumns.length">
                Loading, please wait...
            </mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="personalDisplayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: personalDisplayedColumns;"></mat-row>
        <mat-footer-row *matFooterRowDef="['noData']"
                        [hidden]="personalDataSource === undefined || personalDataSource.length > 0"></mat-footer-row>
        <mat-footer-row *matFooterRowDef="['loading']" [hidden]="! personalLoading"></mat-footer-row>

    </mat-table>
</mat-card>
