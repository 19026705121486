<common-confirm></common-confirm>

<h1 class="container-heading">PartMaster kit</h1>

<mat-card>
    <mat-card-content>

        <form *ngIf="formGroup" [formGroup]="formGroup" (submit)="submit()" autocomplete="off" novalidate>

            <div class="group flex">
                <div class="full">
                    <common-form-select
                            label="Select item"
                            [options]="part_masters"
                            [required]="true"
                            [search]="true"
                            [value]="formGroup.value.part_master_id"
                            (valueChange)="formGroup.get('part_master_id').setValue($event)">
                    </common-form-select>
                </div>

            </div>

            <ng-container formArrayName="child_parts"
                          *ngFor="let part of formGroup.get('child_parts').controls; let i = index">
                <div class="group flex" [formGroupName]="i">
                    <div class="third2">
                        <common-form-select
                                label="Select item"
                                [options]="part_masters"
                                [required]="true"
                                [search]="true"
                                [value]="formGroup.value.child_parts[i].part_master_id"
                                (valueChange)="setChildPartId(i, $event)">
                        </common-form-select>
                    </div>

                    <mat-form-field class="quarter">
                        <mat-label>Quantity</mat-label>
                        <input type="number" matInput formControlName="quantity" required>
                    </mat-form-field>

                    <div class="min">
                        <button class="pull-right" mat-icon-button color="warn" type="button" (click)="deleteChild(i)">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                </div>

            </ng-container>

            <div class="group flex">
                <button mat-raised-button color="accent" type="button" (click)="addChild()">
                    Add child part
                </button>
            </div>

            <div class="group flex" *ngIf="hasDuplicates">
                <div class="full">
                    <mat-error>
                        Kit has duplicates or circular dependencies!
                    </mat-error>
                </div>
            </div>
            <div class="actions">
                <button mat-raised-button color="primary" type="submit" class="main"
                        [disabled]="!formGroup.valid">
                    Save
                </button>
                &nbsp;
                <button mat-button type="button" (click)="modal.response.emit(null)">
                    Cancel
                </button>
            </div>

        </form>

    </mat-card-content>
</mat-card>
