import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {Api, ApiService} from "../../../../../../../common/services/api.service";

import * as moment from "moment";
import {Wizard} from "../../../../../../../common/interfaces/wizard.interface";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {Warehouse} from "../../../../../../../common/interfaces/warehouse.interface";
import {debounceTime, takeUntil} from "rxjs/operators";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {AbstractSinglePageWizardStepComponent}
    from "../../../../../../../common/interfaces/wizard-single-page.interface";
import {Api3Service} from "../../../../../../../common/services/api3.service";
import {PartnerService} from "../../../../../../../common/services/partner.service";
import {ToastService} from "../../../../../../../common/services/toast.service";

@Component({
    selector: "section-order-wizard-transfer-details",
    templateUrl: "transfer-details.component.html",
    styleUrls: [
        "transfer-details.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class OrderWizardTransferDetailsComponent extends AbstractSinglePageWizardStepComponent
    implements OnDestroy, OnInit {

    public state: Base.IState;

    public refPlaceholder: string = "";

    /**
     * Detail form group
     * @type {FormGroup}
     */
    public formGroup: FormGroup = new FormGroup({
        service_level_id: new FormControl(null),
        ref: new FormControl(null,
            [Validators.required, this.refValidationPattern(), this.refExistsValidator()]),
        sr: new FormControl(null),
        ref2: new FormControl(null),
        origin_warehouse_id: new FormControl(null, [Validators.required]),
        destination_warehouse_id: new FormControl(null, [Validators.required])
    });

    public whFrom: { [key: string]: { group_name: string, hubs: Warehouse.IHub[] } };
    public whTo: { [key: string]: { group_name: string, hubs: Warehouse.IHub[] } };

    public activeFrom: string;
    public activeTo: string;

    public refExists: string = null;

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private toastService: ToastService,
        protected spinnerService: SpinnerService,
    ) {
        super(changeDetectorRef);
    }

    protected refValidationPattern(): ValidatorFn {
        const pattern: RegExp = /^[a-zA-Z0-9\-]+$/;

        return (control: AbstractControl): { [key: string]: any } | null => {
            return control.value && !pattern.test(control.value) ? {"refPattern": "true"} : null;
        };
    };

    protected refExistsValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            return this.refExists ? {"refExists": this.refExists} : null;
        };
    }

    protected async checkOrderExists(ref: string): Promise<any> {
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["order", ref, "check_exists"],
            {}, {
                except_id: this.data.order_id ? this.data.order_id : null
            });
        if (data) {
            if (data.id) {
                if (data.service_level_id === this.data.serviceLevel.id && data.order_status_id === 100) {
                    this.data.order_id = data.id;
                    this.refExists = null;
                } else {
                    if (data.order_status_id === 100) {
                        this.refExists = "Draft order exists for service level " + data.service_level.name;
                    } else {
                        this.refExists = "Order exists";
                    }
                    this.formGroup.get("ref").updateValueAndValidity({emitEvent: false});
                    this.toastService.show(this.refExists, "error");
                }
            } else {
                this.refExists = null;
                this.formGroup.get("ref").updateValueAndValidity({emitEvent: false});
            }
            this.changeDetectorRef.markForCheck();
        }
    }

    public async getWarehouses(): Promise<any> {
        this.spinnerService.show();

        const {data}: Api.IResponse = await this.api3Service.get(`${this.state.section}/hubs`, {
            relations: [
                "Warehouse:id,slug,type"
            ],
            only_active: true
        });

        if (data) {
            this.whFrom = {};
            this.whTo = {};

            data.map((hub: Warehouse.IHub) => {
                if (!hub.warehouse) {
                    console.warn(hub);
                    return;
                }

                if (!this.whFrom[hub.customers_inventory_name]) {
                    this.whFrom[hub.customers_inventory_name] = {
                        group_name: hub.customers_inventory_name,
                        hubs: []
                    };
                    this.whTo[hub.customers_inventory_name] = {
                        group_name: hub.customers_inventory_name,
                        hubs: []
                    };
                }

                this.whFrom[hub.customers_inventory_name].hubs.push(hub);
                this.whTo[hub.customers_inventory_name].hubs.push(hub);
            });

            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    public async setFrom(group: { group_name: string, hubs: Warehouse.IHub[] }): Promise<any> {
        this.activeFrom = group.group_name;
        this.formGroup.get("origin_warehouse_id").setValue(group.hubs[0].warehouse.id);

        const hubs_from: any = {};
        hubs_from[group.group_name] = group.hubs;

        this.result.next({
            action: "data",
            value: {hubs_from}
        });

        this.changeDetectorRef.markForCheck();
    }

    public async setTo(group: { group_name: string, hubs: Warehouse.IHub[] }): Promise<any> {
        this.activeTo = group.group_name;
        this.formGroup.get("destination_warehouse_id").setValue(group.hubs[0].warehouse.id);

        const hubs_to: any = {};
        hubs_to[group.group_name] = group.hubs;

        this.result.next({
            action: "data",
            value: {hubs_to}
        });

        this.changeDetectorRef.markForCheck();
    }

    public setup(data: Wizard.IData): void {
        this.state = data.state;
        this.refPlaceholder = PartnerService.partner.display_name;

        this.getWarehouses();
        this.changeDetectorRef.markForCheck();

        this.formGroup.get("service_level_id").setValue(data.serviceLevel.id);
    }


    /**
     * Generate random string for order ref
     */
    public randomRef(): void {
        const ref: string =
            (this.activeFrom ? this.activeFrom.replace(/[^a-zA-Z0-9]/g, "")
                .substring(0, 3).trim() : "")
            + (this.activeTo ? this.activeTo.replace(/[^a-zA-Z0-9]/g, "")
                .substring(0, 3).trim() : "")
            + moment().format("MMDDHHmm");

        this.formGroup.get("ref").setValue(ref);
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this.formGroup.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(1500))
            .subscribe((value: any): void => {
                if (this.formGroup.valid) {
                    this.result.next({
                        action: "data",
                        value: {details: this.formGroup.value}
                    });
                    this.result.next({
                        action: "result",
                        value: true
                    });
                } else {
                    this.result.next({
                        action: "result",
                        value: false
                    });
                }
            });

        this.formGroup.get("ref").valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: string): void => {
                if (value) {
                    this.refExists = null;
                    this.checkOrderExists(value);
                }
            });
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}
