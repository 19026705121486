import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {FormControl} from "@angular/forms";
import {takeUntil} from "rxjs/operators";
import * as moment from "moment";
import {Moment} from "moment";
import {StorageService} from "../../../../../../common/services/storage.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {Table2Component} from "../../../../../../common/components/table2";
import {ToastService} from "../../../../../../common/services/toast.service";
import {AmplitudeService} from "../../../../../../common/services/amplitude.service";


@Component({
    selector: "section-requests-list",
    template: `
        <common-table2 #table *ngIf="listTable" [settings]="listTable">
            <div row0 class="row flex align-center">
                <mat-form-field>
                    <mat-label>Select Status</mat-label>
                    <mat-select [formControl]="showStatus" multiple>
                        <button mat-button style="width: 100%" color="success" (click)="selectAll('showStatus')">
                            Select All
                        </button>
                        <mat-option value="New">New</mat-option>
                        <mat-option value="Open">Open</mat-option>
                        <mat-option value="Pending">Pending</mat-option>
                        <mat-option value="In-progress">In-progress</mat-option>
                        <mat-option value="On-hold">On-hold</mat-option>
                        <ng-container *ngIf="!onlyOpen.value">
                            <mat-option value="Solved">Solved</mat-option>
                            <mat-option value="Closed">Closed</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Select Kind</mat-label>
                    <mat-select [formControl]="showKind" multiple>
                        <button mat-button style="width: 100%" color="success" (click)="selectAll('showKind')">
                            Select All
                        </button>
                        <mat-option *ngFor="let item of kinds" [value]="item">{{item}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Sort by</mat-label>
                    <mat-select [formControl]="sortBy">
                        <mat-option value="updated_at">Update date</mat-option>
                        <mat-option value="created_at">Creation date</mat-option>
                        <mat-option value="status">Status</mat-option>
                        <mat-option value="timeline_to">Timeline</mat-option>
                        <mat-option value="urgency">Urgency</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Select Priority</mat-label>
                    <mat-select [formControl]="showPriority" multiple>
                        <button mat-button style="width: 100%" color="success" (click)="selectAll('showPriority')">
                            Select All
                        </button>
                        <mat-option value="Low">Low</mat-option>
                        <mat-option value="Normal">Normal</mat-option>
                        <mat-option value="High">High</mat-option>
                        <mat-option value="Critical">Critical</mat-option>
                    </mat-select>
                </mat-form-field>
                <span style="margin-right: 10px">
                    <mat-checkbox [formControl]="onlyOpen"> Only open</mat-checkbox>
                </span>
            </div>

            <div row1 class="row flex align-center">
                <common-form-date-range
                   label="Creation date"
                   (valueChange)="createdAtDateRange.setValue($event)">
                </common-form-date-range>
            </div>
        </common-table2>
    `,
    styleUrls: ["list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class RequestsListComponent implements OnInit, OnDestroy {


    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    @Input()
    public only_my: boolean = false;

    @Input()
    public favorite: boolean = false;

    @Input()
    public state: Base.IState;

    @Input()
    public type: string[] = ["request"];

    @ViewChild("table", {static: false})
    public tableRef: Table2Component;

    public showStatusSelectAll: boolean = false;
    public showKindSelectAll: boolean = false;
    public showPrioritySelectAll: boolean = false;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.ISettings;

    public showExportButton: boolean = false;

    public statusQuery: any;

    public selectedRows: any[] = [];

    public showStatus: FormControl = new FormControl(
        this.storageService.get("requests_show_status") || "");
    public showKind: FormControl = new FormControl(
        this.storageService.get("requests_show_kind") || "");
    public kinds: any[] = [];

    public showPriority: FormControl = new FormControl(
        this.storageService.get("requests_show_priority") || "");

    public createdAtDateRange: FormControl = new FormControl(null);

    public sortBy: FormControl =
        new FormControl(this.storageService.get("requests_sortby", "created_at"));

    public onlyOpen: FormControl =
        new FormControl(this.storageService.get("requests_only_open", true));

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private storageService: StorageService,
        private spinnerService: SpinnerService,
        private toastService: ToastService,
        private changeDetectorRef: ChangeDetectorRef,

    ) {
    }

    public static getColumns(state: Base.IState, router: Router): Table.ICol[] {
        return [
            {
                data: "status",
                title: "Status",
                render: (row: any): string => {
                    return "<div class='status " + row.status + "'>" + row.status + "</div>";
                }
            },
            {
                data: "status",
                title: "",
                render: (row: any): string => {
                    if (row.id) {
                        return `<mat-icon class="mat-icon material-icons pointer" title="Open in new tab">
                            open_in_new
                            </mat-icon>`;
                    }
                    return "";
                },
                click: (row: any): void => {
                    if (row.id) {
                        window.open("/" + state.section + "/support-requests/view/id/" + row.id, "_blank");
                    }
                },
                cssClass: "action",
                sortable: false,
                searchable: false
            },
            {
                data: "title",
                title: "Title",
                render: (row: any): string => {
                    return `
                        <div class="multicol">
                            <div class="tableAction title pointer" data-action="wizard">
                                ${row.title}
                            </div>
                            <small class="text-disabled">
                                ${row.description
                        .replace(/<[^>]*>?/gm, " ").trim()
                        .substring(0, 30)}
                            </small>
                            <div>
                                ${row.request_kind.name}
                            </div>
                            <div>
                                <small class="text-disabled">
                                    <mat-icon class="mat-icon material-icons vertical-middle">
                                        low_priority
                                    </mat-icon>
                                   <b class="${["High", "Critical"].indexOf(row.priority) > -1
                        ? "mat-warn" : ""}">
                                    ${row.priority}
                                    </b>
                                   &emsp;
                                    <mat-icon class="mat-icon material-icons vertical-middle">
                                        message
                                    </mat-icon>
                                    ${row.request_messages_count}
                                    &emsp;
                                    ${row.order ? " Order: <b>" + row.order.ref + "</b>" : ""}
                                </small>
                            </div>
                        </div>
                        `;
                },
                click: (row: any): void => {
                    router.navigate([
                        state.section,
                        "support-requests",
                        "view",
                        "id",
                        row.id
                    ]);
                }
            },
            {
                data: "order.ref",
                name: "Order.ref",
                title: "Order",
                render: (row: any): string => {
                    if (row.order) {
                        return `<button class="mat-mdc-raised-button mdc-button mat-accent button-150">
                            ${row.order.ref}</button>`;
                    }
                    return "";
                },
                click: (row: any): void => {
                    if (row.order) {
                        router.navigate([
                            "/partner",
                            row.partner.slug,
                            "orders",
                            "view",
                            "id",
                            row.order.id
                        ]);
                    }
                }
            },
            {
                data: "lticket",
                name: "lticket",
                title: "Ticket #"
            },
            {
                data: "creator.name",
                name: "Creator.name",
                title: "Created by",
                render: (row: any): string => {
                    return "<img class='vertical-middle round' src='"
                        + (row.creator.avatar ? row.creator.avatar : "/assets/images/default-avatar.png")
                        + "' alt=''> " + row.creator.name;
                }
            },
            {
                data: "created_at",
                name: "created_at",
                title: "Creation date",
                render: (row: any): string => {
                    return row.created_at.split(" ")[0];
                }
            },
            {
                data: "created_at",
                title: "Timeline",
                render: (row: any): string => {
                    if (row.timeline_from && row.timeline_to) {
                        const from: Moment = moment(row.timeline_from).startOf("day");
                        const to: Moment = moment(row.timeline_to).startOf("day");
                        const now: Moment = moment().startOf("day");
                        const diff100: number = to.diff(from, "days");
                        const diffNow: number = now.diff(from, "days");
                        let progress: number = diffNow / (diff100 / 100);
                        progress = progress > 0 ? Math.ceil(progress) : 0;
                        progress = progress < 100 ? progress : 100;
                        return `
                    <div class="timeline">
                        <div class="progress" style="width:${progress}%"></div>
                        <div class="dates">
                            ${from.format("MMM D")} - ${to.format("MMM D")}
                        </div>
                        <div class="distance">
                            ${diff100 - diffNow > 0 ? diff100 - diffNow : 0}d left
                        </div>
                    </div>
                `;
                    } else {
                        return `
                     <div class="timeline">
                     <div class="dates">
                            -
                        </div>
                        <div class="distance">
                            -
                        </div>
                    </div>
                `;
                    }
                }
            },
            {
                data: "updated_at",
                title: "Last update",
            },
            {
                data: "assignee.name",
                name: "Assignee.name",
                title: "Assignee",
                render: (row: any): string => {
                    if (row.assignee) {
                        return "<img class='vertical-middle round' src='"
                            + (row.assignee.avatar ? row.assignee.avatar : "/assets/images/default-avatar.png")
                            + "' alt=''> " + row.assignee.name;
                    }
                    return "";
                }
            },
            {
                data: "billable_hours",
                title: "Billable hours",
            }
        ];
    }

    private getUrl(): any {
        this.statusQuery = {
            status: [],
            kind: []
        };
        if (this.showStatus.value.length > 0) {
            this.showStatus.value.forEach((value: any): void => {
                this.statusQuery.status.push(value);
            });
        }

        if (this.showKind.value.length > 0) {
            this.showKind.value.forEach((value: any): void => {
                this.statusQuery.kind.push(value);
            });
        }
        if (this.state.params.order) {
            this.statusQuery.order_id = this.state.params.order;
        }
        return {
            url: ["request"],
            query: {
                type: this.type,
                only_my: this.only_my,
                only_favorite: this.favorite,
                sort_by: this.sortBy.value,
                only_open: this.onlyOpen.value,
                priority: this.showPriority.value,
                created_at: this.createdAtDateRange.value,
                ...this.statusQuery
            }
        };
    }

    private async loadKinds(): Promise<any> {
        const response: any = await this.apiService.request(Api.EMethod.Get,
            this.apiService.getUrl(["request", "kind"]), {}, {});
        response.data.forEach((value: any): void => {
            this.kinds.push(value.name);
        });
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {

        const columns: Table.ICol[] = RequestsListComponent.getColumns(this.state, this.router);

        if (this.state.section === "admin") {
            columns.splice(1, 0, {
                data: "partner.icon_path",
                title: "Partner",
                render: (row: any): string => {
                    return row.partner ? "<img src='" + row.partner.icon_path + "' alt=''>" : "";
                }
            });
        }

        this.listTable = {
            table_id: "5gcR8vJ7",
            api: this.getUrl(),
            actions: [
                {
                    name: "wizard",
                    title: "View",
                    click: (row: any): void => {
                        this.router.navigate([
                            this.state.section,
                            "support-requests",
                            "view",
                            "id",
                            row.id
                        ]);
                    }
                },
                {
                    name: "star",
                    title: "Add to favorites",
                    click: async (row: any): Promise<any> => {
                        this.spinnerService.show();
                        const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
                            ["request", "" + row.id, "favorite"]);
                        this.spinnerService.hide();

                        if (type as string === "success") {
                            this.toastService.show(message, "success");
                            this.tableRef.reload();
                        }
                        AmplitudeService.eventClick("Favourite button");
                    },
                    cssClassFn: (row: any): string => {
                        return row.fav_user ? "mat-accent" : "";
                    }
                }

            ],
            columns,
            search_default: ["title", "status"],
            export: {
                file_name: "Requests",
                columns: [
                    {
                        data: "status",
                        title: "Status",
                    },
                    {
                        data: "partner.slug",
                        title: "Partner",
                    },
                    {
                        data: "title",
                        title: "Title",
                    },
                    {
                        data: "description",
                        title: "Description",
                    },
                    {
                        data: "lticket",
                        title: "Ticket #",
                    },
                    {
                        data: "request_kind.name",
                        title: "Kind",
                    },
                    {
                        data: "priority",
                        title: "Priority",
                    },
                    {
                        data: "creator.name",
                        title: "Created by",
                    },
                    {
                        data: "assignee.name",
                        title: "Assignee",
                    },
                    {
                        data: "billable_hours",
                        title: "Billable hours",
                    },
                    {
                        data: "created_at",
                        title: "Creation date",
                    },
                    {
                        data: "closed_on",
                        title: "Closed on",
                    }
                ]
            }
        };
        this.changeDetectorRef.markForCheck();
    }

    public selectAll(form: string): void {
        switch (form) {
            case "showStatus": {
                if (!this.showStatusSelectAll) {
                    this.showStatus.setValue(
                        ["New", "Open", "Pending", "On-hold", "Solved", "Closed", "In-progress"]);
                } else {
                    this.showStatus.setValue([]);
                }
                this.showStatusSelectAll = !this.showStatusSelectAll;
                break;
            }
            case "showKind": {
                if (!this.showKindSelectAll) {
                    this.showKind.setValue(
                        this.kinds);
                } else {
                    this.showKind.setValue([]);
                }
                this.showKindSelectAll = !this.showKindSelectAll;
                break;
            }
            case "showPriority": {
                if (!this.showPrioritySelectAll) {
                    this.showPriority.setValue(
                        ["Low", "Normal", "High", "Critical"]
                    );
                } else {
                    this.showPriority.setValue([]);
                }
                this.showPrioritySelectAll = !this.showPrioritySelectAll;
            }
            default:
        }
    }

    public ngOnInit(): void {
        this.loadKinds();
        this.prepareList();

        this.showStatus.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: string): void => {
            this.storageService.set("requests_show_status", value);
            this.tableRef.reload(this.getUrl());
        });

        this.showKind.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: string): void => {
            this.storageService.set("requests_show_kind", value);
            this.tableRef.reload(this.getUrl());
        });

        this.sortBy.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: string): void => {
            this.storageService.set("requests_sortby", value);
            this.tableRef.reload(this.getUrl());
        });

        this.onlyOpen.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: boolean): void => {
            this.storageService.set("requests_only_open", value);
            this.tableRef.reload(this.getUrl());
        });

        this.showPriority.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: string): void => {
            this.storageService.set("requests_show_priority", value);
            this.tableRef.reload(this.getUrl());
        });

        this.createdAtDateRange.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: string): void => {
            this.tableRef.reload(this.getUrl());
        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
