import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {COMMA, ENTER, SEMICOLON} from "@angular/cdk/keycodes";
import {MatChipInputEvent} from "@angular/material/chips";
import {debounceTime, takeUntil} from "rxjs/operators";
import {Form} from "../../../../interfaces/form.interface";

@Component({
    selector: "common-form-builder-field",
    templateUrl: "field.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class FormBuilderFieldComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    @Input()
    public index: number = 0;

    @Input()
    public options: {
        label: string,
        type: string,
        values: string[],
        required: boolean,
        multiple: boolean,
        size: string,
        url: string,
        description: string
    };

    @Output()
    public onChanges: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public onMoveUp: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public onMoveDown: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public onMoveTo: EventEmitter<number> = new EventEmitter<number>();

    @Output()
    public onDelete: EventEmitter<any> = new EventEmitter<any>();


    public fieldTypes: { [key: string]: any } = Form.FIELD_TYPES;

    public fieldSizes: string[] = ["full", "half", "third"];

    public separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON];

    public form: FormGroup = new FormGroup({
        label: new FormControl(null, [Validators.required]),
        name: new FormControl(null),
        type: new FormControl("input", [Validators.required]),
        values: new FormControl([]),
        required: new FormControl(false),
        multiple: new FormControl(false),
        size: new FormControl("full", [Validators.required]),
        url: new FormControl(null),
        description: new FormControl(null)
    });


    public constructor(private changeDetectorRef: ChangeDetectorRef) {
    }


    /**
     * Convert string to snake_case
     * @param {string} label
     * @return {string}
     */
    private labelToName(label: string): string {
        return label
            ? label.toLowerCase().replace(/ /g, "_")
                .replace(/[^a-z0-9_]/g, "")
            : null;
    }


    /**
     *
     * Add field value
     * @param {MatChipInputEvent} event
     */
    public addValue(event: MatChipInputEvent): void {
        const input: HTMLInputElement = event.input;
        const value: string = event.value;
        if ((value || "").trim()) {
            const val: string[] = this.form.get("values").value;

            val.push(value.trim().substr(0, 30));

            this.form.get("values").setValue(val);
        }
        if (input) {
            input.value = "";
        }
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Remove field value
     * @param value
     */
    public removeValue(value: any): void {
        const valueIndex: number = this.form.get("values").value.indexOf(value);
        const val: string[] = this.form.get("values").value;
        val.splice(valueIndex, 1);
        this.form.get("values").setValue(val);
        this.changeDetectorRef.markForCheck();
    }


    public ngOnInit(): void {

        this.form.patchValue(this.options);

        this.form.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((values: any): void => {
                values.name = this.labelToName(values.label);
                this.onChanges.emit(values);
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
