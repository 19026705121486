import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {Modal, ModalService} from "../../../../../../services/modal.service";
import {
    EditShipmentEtaComponent,
    OrderParcelsComponent,
    OrderTrackContentComponent,
    OrderTrackProgressComponent, ShipmentFormComponent
} from "../../index";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {Router} from "@angular/router";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {Warehouse} from "../../../../../../../common/interfaces/warehouse.interface";
import {Order} from "../../../../../../../common/interfaces/order.interface";
import {IPagination} from "../../../../../../../common/components/pagination/pagination.component";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {CourierTransactionDeliveredFormComponent} from "../../../../../threepl/warehouse/courier-transaction";
import {ShipmentService} from "../../../../../../../common/services/shipment.service";
import {
    TrackingChangeComponent,
    TrackingShippedAtComponent,
    TrackingStatusComponent
} from "../../../../../common/components/tracking";
import {CourierService} from "../../../../../../../common/services/courier.service";
import {ConfirmComponent} from "../../../../../../../common/components/confirm/confirm.component";
import {Courier} from "../../../../../../../common/interfaces/courier.interface";

import {UserService} from "../../../../../../../common/services/user.service";
import {PickupRequestFormComponent} from "../../../pickup-requests/form/form.component";
import {Api3Service} from "../../../../../../../common/services/api3.service";
import {PickupRequestInfoModalComponent}
    from "../../../../../common/components/pickup-requests/info/info-modal.component";
import {CommonFormComponent} from "../../../../../../../common/components/form";

@Component({
    selector: "section-order-view-shipments",
    templateUrl: "shipments.component.html",
    styleUrls: [
        "shipments.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})

export class OrderShipmentsComponent implements OnInit {

    @Input()
    public orderData: Order.IOrderData;

    @Input()
    public state: Base.IState;

    @Input()
    public transferDir: {
        inbound: { name: string, type: string, warehouse_slug: string, hub_id: string },
        outbound: { name: string, type: string, warehouse_slug: string, hub_id: string }
    };

    @Output()
    public gotData: EventEmitter<any> = new EventEmitter();

    @ViewChild(ConfirmComponent, {static: true})
    public confirmRef: ConfirmComponent;

    public shipments: IPagination<Warehouse.IShipment>;

    public schedule_date_range: string;

    public from_address_prefix: string = "";
    public to_address_prefix: string = "";

    public constructor(
        private apiService: ApiService,
        private api3Service: Api3Service,
        private changeDetectorRef: ChangeDetectorRef,
        private modalService: ModalService,
        private toastService: ToastService,
        private router: Router,
        private courierService: CourierService,
        private spinnerService: SpinnerService,
        private userService: UserService
    ) {
    }

    /**
     * Get order shipments
     * @returns {Promise<any>}
     */


    /**
     * Get pacrels count in shipment
     * @returns {Promise<any>}
     * @param shipment
     */
    private async parcelsCount(shipment: Warehouse.IShipment): Promise<any> {
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["shipment", "" + shipment.id, "parcels"], {}, {data_structure: "count"});
        if (data) {
            shipment.parcels_count = data[0];
            this.changeDetectorRef.markForCheck();
        }
    }

    private async deleteTracking(data: Courier.ITransaction): Promise<any> {
        if (!await this.confirmRef.confirm("Do you want to delete this tracking?")) {
            return;
        }
        this.spinnerService.show();
        const response: Api.IResponse = await this.courierService.deleteTransaction(data.id);
        this.spinnerService.hide();
        if (response) {
            this.getOrderShipments();
        }
    }

    public async getOrderShipments(page: number = 1, per_page: number = null): Promise<any> {
        if (per_page === null) {
            per_page = this.userService.data.settings.default_per_page;
        }

        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `/${this.state.section}/orders/${this.orderData.id}/shipments`, {},
            {
                data_structure: "paginated",
                page,
                per_page,
                relations: [
                    "FromAddress",
                    "ToAddress.CountryDetailed",
                    "PickupRequests",
                    "PickupRequests.Status",
                    "PickupRequests.Customer",
                    "PickupRequests.Address",
                    "PickupRequests.Contact",
                    "PickupRequests.CourierTransaction",
                    "PickupRequests.User",
                    "CourierServicePrice",
                    "CourierTransactions.ShippyProLabels",
                    "CourierTransactions.ShippyProPickup",
                    "CourierTransactions.CourierTransactionCheckpoints",
                    "CourierTransactions.Courier",
                    "CourierTransactions.CourierService",
                    "CourierTransactions.WarehouseTransactions.InventoryConversion",
                    "WarehouseOrder.Status",
                    "WarehouseOrder.ShortUrl:id,clicks",
                    "Parcel.OrderItem.InventoryConversion",
                    "Warehouse:id,slug,timezone,on_shift_contact_id,3pl_id,name,type,cut_off",
                    "Warehouse.ContactOnShift:id,first_name,last_name,phone,email",
                    "Warehouse.Contacts",
                    "Warehouse.Threepl:id,display_name",
                ],
                sort: {
                    data: "type",
                    dir: "asc"
                }
            });

        if (data) {
            this.shipments = data;

            if (this.shipments.data) {
                this.gotData.next(this.shipments.data.length > 0);

                let origin: string = "";
                let destination: string = "";

                if (this.transferDir) {
                    origin = this.transferDir.outbound.name;
                    destination = this.transferDir.inbound.name;
                }

                for (const shipment of this.shipments.data) {
                    this.parcelsCount(shipment);

                    if (shipment.type === "outbound") {
                        if (!this.transferDir) {
                            origin = shipment.parcel?.order_item?.inventory_conversion?.customers_inventory_name;
                            destination = this.orderData.main_address?.customer?.name;
                        }

                        shipment.from_address.address = (origin ? "<b>" + origin + "</b>" + ": " : "")
                            + shipment.from_address?.address;

                        shipment.to_address.address = (destination ? "<b>" + destination + "</b>" + ": " : "")
                            + shipment.to_address?.address;
                    } else {
                        if (!this.transferDir) {
                            origin = this.orderData.main_address?.customer?.name;
                            destination = shipment.parcel?.order_item?.inventory_conversion?.customers_inventory_name;
                        }

                        shipment.from_address.address = (origin ? "<b>" + origin + "</b>" + ": " : "")
                            + shipment.from_address?.address;

                        shipment.to_address.address = (destination ? "<b>" + destination + "</b>" + ": " : "")
                            + shipment.to_address?.address;


                        const rad: number = Math.PI / 180;
                        //Calculate distance from latitude and longitude
                        const theta: number = Number(shipment.from_address.lng)
                            - Number(shipment.to_address.lng);

                        const dist: number = Math.sin(Number(shipment.from_address.lat) * rad)
                            * Math.sin(Number(shipment.to_address.lat) * rad)
                            + Math.cos(Number(shipment.from_address.lat) * rad)
                            * Math.cos(Number(shipment.to_address.lat) * rad)
                            * Math.cos(theta * rad);

                        (shipment as any).shipping_distance = Math.round(Math.acos(dist) / rad * 60 * 1.853);
                    }
                }
            }
        } else {
            this.gotData.next(false);
        }
        this.changeDetectorRef.markForCheck();
    }


    /**
     * Show track shipment content
     * @param {number} trackIndex
     * @param {number} courierIndex
     */
    public showTrackContent(trackIndex: number, courierIndex: number): void {
        this.modalService.open(OrderTrackContentComponent, {
            data: this.shipments.data[trackIndex].courier_transactions[courierIndex].warehouse_transactions
        });
    }

    /**
     * Show track shipment progress
     * @param {number} trackIndex
     * @param {number} courierIndex
     */
    public showTrackProgress(trackIndex: number, courierIndex: number): void {
        this.modalService.open(OrderTrackProgressComponent, {
            data: this.shipments.data[trackIndex].courier_transactions[courierIndex],
            address_from: this.shipments.data[trackIndex].from_address,
            address_to: this.shipments.data[trackIndex].to_address,
            modalWidth: 600
        });
    }

    /**
     * Mark shipment as delivered
     * @param {number} transaction_id
     */
    public async markAsDelivered(transaction_id: number): Promise<any> {
        await this.modalService.open(CourierTransactionDeliveredFormComponent, {id: transaction_id});
        this.getOrderShipments();
    }

    public async requestPickup(shipment: any, pickup?: any): Promise<any> {
        await this.modalService.open(PickupRequestFormComponent, {
            data: pickup,
            shipment,
            order_ref: this.orderData.ref

        });
        this.getOrderShipments();
    }

    public async createShippyProPickup(shipment, track: Courier.ITransaction): Promise<any> {
        this.router.navigate([
            this.state.section,
            "shippy-pickup",
            "add",
            "order",
            this.orderData.id,
            "shipment",
            shipment.id,
            "track",
            track.id,
            "back_to",
            btoa(this.router.url)
        ]);
    }

    public createLabel(shipment: any): void {
        this.router.navigate([
            this.state.section,
            "labels",
            "add",
            "order",
            this.orderData.id,
            "shipment", shipment.id,
            "type",
            shipment.type
        ]);
    }

    public openLabel(labels: any[]): void {
        for (const label of labels) {
            window.open(label.label_url, "_blank");
        }
    }


    /**
     * Edit shipment
     * @returns {Promise<any>}
     * @param shipment
     */
    public async editShipment(shipment: Warehouse.IShipment): Promise<any> {

        const response: Modal.IResponse = await this.modalService.open(ShipmentFormComponent, {
            action: "edit",
            shipment: shipment,
            order_id: this.orderData.id,
            modalWidth: "1000px",
            type: shipment.type
        });
        // console.warn("edit", response);
        if (response) {
            for (const index of Object.keys(this.shipments.data)) {
                if (this.shipments.data[index].id === response.value.shipment.id) {

                    if (response.value.action === "edit") {
                        this.shipments.data[index] = response.value.shipment;

                    } else if (response.value.action === "delete") {

                        this.spinnerService.show();
                        this.shipments.data[index].parcels?.forEach(async (item: any): Promise<any> => {
                            const unlinkParcel: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
                                ["shipment"], {shipment_id: null, parcel_id: item.id});
                            this.toastService.show(unlinkParcel.message, unlinkParcel.type as string);
                        });
                        this.spinnerService.hide();

                        this.getOrderShipments();
                    }
                    this.changeDetectorRef.markForCheck();
                    break;
                }
            }
        }
    }


    public async showParcels(shipmentId: number): Promise<any> {
        await this.modalService.open(OrderParcelsComponent, {
            shipmentId,
            orderRef: this.orderData.ref,
            state: this.state
        });
    }


    /**
     * Go to add transaction form
     * @param {Order.IShipment} shipment
     */
    public addTransaction(shipment: Warehouse.IShipment): void {
        this.router.navigate([
            this.state.section, "transaction", "add", "order", this.orderData.ref, "shipment", shipment.id
        ]);
    }

    public async editEta(shipment: Warehouse.IShipment): Promise<any> {
        const res: Modal.IResponse = await this.modalService.open(EditShipmentEtaComponent, {
            shipment
        });

        if (res) {
            this.getOrderShipments();
        }
    }

    public async trackingActionHandler(action: string, data: Courier.ITransaction): Promise<any> {

        let component: any;

        switch (action) {
            case "add_status":
                component = TrackingStatusComponent;
                break;
            case "change_shipped_at":
                component = TrackingShippedAtComponent;
                break;
            case "change":
            case "edit_price":
                component = TrackingChangeComponent;
                break;
            case "delete":
                this.deleteTracking(data);
                return;
            default:
                return;
        }

        const response: Modal.IResponse = await this.modalService.open(component, {
            data,
            action,
            modalWidth: "600px"
        });

        if (response) {
            this.getOrderShipments();
        }
    }

    public async showInfo(pickup_request: Warehouse.IPickupRequest): Promise<any> {
        await this.modalService.open(PickupRequestInfoModalComponent, {
            pickup_request
        });
    }

    public async createWarehouseOrder(shipment: Warehouse.IShipment): Promise<any> {
        const {code, message}: Api.IResponse = await this.api3Service.request(Api.EMethod.Post,
            `${this.state.section}/shipments/${shipment.id}/warehouse-order`);
        if (code === 200) {
            this.toastService.show(message, "success");
            this.getOrderShipments();
        }
    }

    public async sendPrealert(shipment: Warehouse.IShipment): Promise<any> {
        if (!await this.confirmRef.confirm("This will send pre-alert request for shipment to 3PL")) {
            return;
        }

        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.api3Service.request(Api.EMethod.Put,
            `${this.state.section}/shipments/${shipment.id}/send-prealert`);

        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
        }
    }

    public async sendChoicePU(shipment: Warehouse.IShipment): Promise<any> {
        const res: Modal.IResponse = await this.modalService.open(CommonFormComponent, {
            formConfig: {
                id: 0,
                name: "Select service",
                description: "",
                fields: [
                    {
                        label: "Service level",
                        name: "service_level",
                        size: "full",
                        type: "select",
                        required: true,
                        values: [
                            "RUSH-RETURN",
                            "SCHD-RETURN"
                        ]
                    },
                ]
            }
        });

        if (!res.value) {
            return;
        }

        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.api3Service.request(Api.EMethod.Put,
            `${this.state.section}/shipments/${shipment.id}/send-pickup-choice`, {
                service_level: res.value.service_level
            });

        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.getOrderShipments();
        }
    }

    public ngOnInit(): void {
        this.getOrderShipments();

        if (this.orderData.custom_fields) {
            this.orderData.custom_fields.forEach((field: { key: string, value: string }): void => {
                if (field.key === "Schedule date range") {
                    this.schedule_date_range = field.value;
                }
            });
        }
    }
}