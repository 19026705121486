import {Injectable} from "@angular/core";

import AES from "crypto-js/aes";

import Utf8 from "crypto-js/enc-utf8";

@Injectable()
export class StorageService {

    /**
     * Stored data
     * @type {Object}
     */
    private data: Object;

    /**
     * Secret phrase
     * @type {string}
     */
    private phrase: string = "~!@#$%^&*()_+";

    public constructor() {
        this.restore();
        // if (isDevMode()) {
        //     (global as any).storage = {
        //         all: (): any => this.data,
        //         clean: this.clean.bind(this),
        //         get: this.get.bind(this),
        //         remove: this.remove.bind(this),
        //         set: this.set.bind(this)
        //     };
        // }
    }

    /**
     * Restore data from cache (local storage)
     * @returns {void}
     */
    private restore(): void {
        const encrypted: string = window.localStorage.getItem("cache");
        if (encrypted) {
            const decrypted: string = AES.decrypt(encrypted, this.phrase).toString(Utf8);
            try {
                this.data = JSON.parse(decrypted);
            } catch (e) {
            }
        } else {
            this.data = {};
        }
    }

    /**
     * Store data in cache (local storage)
     * @returns {void}
     */
    private store(): void {
        if (Object.keys(this.data).length) {
            window.localStorage.setItem("cache", AES.encrypt(JSON.stringify(this.data), this.phrase).toString());
        }
    }

    /**
     * Clean storage
     * @returns {void}
     */
    public clean(): void {
        this.data = {};
        window.localStorage.clear();
    }

    /**
     * Get value from storage
     * @param {string} key
     * @param defaultValue
     * @returns {any}
     */
    public get(key: string, defaultValue: any = null): any {
        return this.data[key] !== undefined ? this.data[key] : defaultValue;
    }

    /**
     * Remove value from storage
     * @param {string} key
     * @returns {void}
     */
    public remove(key: string): void {
        if (key) {
            delete this.data[key];
        }
    }

    /**
     * Set value to storage
     * @param {string} key
     * @param value
     * @returns {void}
     */
    public set(key: string, value: any): void {
        this.data[key] = value;
        this.store();
    }

}
