import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {catchError, map} from "rxjs/operators";
import {of} from "rxjs/internal/observable/of";
import {Api, ApiService} from "./api.service";
import {GMap} from "../components/gmap/gmap.component";

@Injectable()
export class GoogleMapsApiService {

    private loaded: boolean = false;

    public constructor(
        private http: HttpClient,
        private apiService: ApiService
    ) {
    }

    public load(): Observable<any> {
        if (this.loaded) {
            return of(true);
        } else {
            return this.http.jsonp("https://maps.googleapis.com/maps/api/js?libraries=places&key="
                + environment.gmap.apiKey,
                "callback")
                .pipe(
                    map((): boolean => {
                        this.loaded = true;
                        return true;
                    }),
                    catchError((): any => of(false)),
                );
        }
    }

    public async getStaticMap(
        center: { lat: number, lng: number },
        markers: GMap.IMarker[],
        size: { width: number, height: number },
        zoom: number = 10,
        scale: number = 1,
    ): Promise<any> {
        return this.apiService.request(Api.EMethod.Post, ["maps", "static"], {
            center,
            markers,
            zoom,
            size,
            scale
        });
    }

}
