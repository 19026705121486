import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild
} from "@angular/core";
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {Field} from "../../../../../../../common/components/field/field.component";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {ModalService} from "../../../../../../services/modal.service";
import {UserService} from "../../../../../../../common/services/user.service";

import * as moment from "moment";
import {debounceTime, takeUntil} from "rxjs/operators";
import {Wizard} from "../../../../../../../common/interfaces/wizard.interface";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {User} from "../../../../../../../common/interfaces/user.interface";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {
    AbstractSinglePageWizardStepComponent
} from "../../../../../../../common/interfaces/wizard-single-page.interface";
import {ContactFormComponent, ContactListComponent} from "../../../contact";
import {PartnerService} from "../../../../../../../common/services/partner.service";
import {AddressService} from "../../../../../../../common/services/address.service";
import {Form} from "../../../../../../../common/interfaces/form.interface";
import {INCOTERMS} from "../../../../../../../common/constants/incoterms-list.constant";
import {CustomerAccountFormComponent, CustomerAccountListComponent} from "../../../customer-account";
import {Order} from "../../../../../../../common/interfaces/order.interface";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {ModalComponent} from "../../../../../../../common/components/modal/modal.component";
import ISelectOption = Form.ISelectOption;
import {Api3Service} from "../../../../../../../common/services/api3.service";
import {Contact} from "../../../../../../../common/interfaces/contact.interface";

@Component({
    selector: "section-order-wizard-details",
    templateUrl: "details.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderWizardDetailsComponent extends AbstractSinglePageWizardStepComponent
    implements OnDestroy, OnInit, OnChanges {

    private address: Contact.IAddress;

    public state: Base.IState;

    public refExists: string = null;

    public ready: boolean = false;

    public showIncoterms: boolean = false;

    public incoterms: ISelectOption[] = INCOTERMS;

    public canOutbound: boolean = false;
    public canInbound: boolean = false;

    public showRequestor: boolean = false;
    public showExw: boolean = false;

    /**
     * Detail form group
     * @type {FormGroup}
     */
    public formGroup: FormGroup = new FormGroup({
        "ref": new FormControl(null,
            [Validators.required, this.refValidationPattern(), this.refExistsValidator()]),
        "ref2": new FormControl(null),
        "sr": new FormControl(null),
        "address_id": new FormControl(null, [Validators.required]),
        "address_billto_id": new FormControl(null),
        "contact_address_id": new FormControl(null, [Validators.required]),
        "contact_address_billto_id": new FormControl(null),
        "customer_id": new FormControl(null, [Validators.required]),
        "requestor_id": new FormControl(null),
        "requestor_contact_id": new FormControl(null),
        "out_incoterms": new FormControl(null),
        "in_incoterms": new FormControl(null),
        "exw_service_level": new FormControl(null),
        "exw_account_number": new FormControl(null),
        "exw_courier_company": new FormControl(null),
        "customer_account_id": new FormControl(null),
        "customer_account_courier_company_id": new FormControl(null),
        "customer_account_service_level_id": new FormControl(null),
    });

    /**
     * Custom / dynamic fields metadata
     * @type {Field.IMetadata[]}
     */
    public meta: Field.IMetadata[];

    public partner: User.IPartner;

    public refPlaceholder: string = "";
    public srPlaceholder: string = "";

    public refObject: { country: string, customer: string } = {
        country: null,
        customer: null
    };

    public haveShipment: boolean = false;

    public shipmentName: string = "Shipping";

    public components: any = {
        contact: {
            form: ContactFormComponent,
            list: ContactListComponent
        },
        customer_account: {
            form: CustomerAccountFormComponent,
            list: CustomerAccountListComponent
        }
    };

    @ViewChild("requestor_id", {static: false})
    public addressIdRef: ElementRef;

    @ViewChild("requestor_contact_id", {static: false})
    public contactAddressIdRef: ElementRef;

    public srExists: string = null;

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        protected apiService: ApiService,
        protected api3Service: Api3Service,
        protected modalService: ModalService,
        protected userService: UserService,
        protected addressService: AddressService,
        protected toastService: ToastService,
        protected spinnerService: SpinnerService
    ) {
        super(changeDetectorRef);
    }

    protected refValidationPattern(): ValidatorFn {
        const pattern: RegExp = /^[a-zA-Z0-9\-\.\,]+$/;

        return (control: AbstractControl): { [key: string]: any } | null => {
            return control.value && !pattern.test(control.value) ? {"refPattern": "true"} : null;
        };
    };

    protected refExistsValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            return this.refExists ? {"refExists": this.refExists} : null;
        };
    }

    /**
     * Prepare details form group
     * @returns {void}
     */
    protected prepareDetailsStepForm(): void {

        if (!this.haveShipment) {
            this.formGroup.removeControl("address_id");
            this.formGroup.removeControl("address_billto_id");
            this.formGroup.removeControl("contact_address_id");
            this.formGroup.removeControl("contact_address_billto_id");
            this.formGroup.removeControl("customer_id");
        } else {
            this.formGroup.addControl("address_id",
                new FormControl(null, [Validators.required]));
            this.formGroup.addControl("address_billto_id",
                new FormControl(null));
            this.formGroup.addControl("contact_address_id",
                new FormControl(null, [Validators.required]));
            this.formGroup.addControl("contact_address_billto_id",
                new FormControl(null));
            this.formGroup.addControl("customer_id",
                new FormControl(null, [Validators.required]));
        }

        this.ready = true;
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Get partner`s name
     */
    protected getPartnerName(): void {

        this.partner = PartnerService.partner;

        this.refPlaceholder = this.partner.display_name
            + " " + this.userService.getProperty("ref_name", this.state, "ref");
        this.showRequestor = !!this.partner.properties.order_form_show_requestor;
        this.showExw = !!this.partner.properties.order_form_show_exw;
        this.showIncoterms = !!this.partner.properties.show_incoterms;
        this.srPlaceholder =
            this.userService.getProperty("case_sr_number_name", this.state, "Case / SR number");

        if (this.partner.slug === "modix") {
            this.formGroup.addControl("modix_reseller", new FormControl(null));
        }

        this.changeDetectorRef.markForCheck();
    }


    protected async checkOrderExists(ref: string): Promise<any> {
        if (!ref) {
            return;
        }

        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["order", ref, "check_exists"],
            {}, {
                except_id: this.data.order_id ? this.data.order_id : null
            });
        if (data) {
            if (data.id) {
                if (data.order_status_id === 100) {
                    this.data.order_id = data.id;
                    this.refExists = null;
                } else {
                    this.refExists = "Order exists";
                    this.formGroup.get("ref").updateValueAndValidity({emitEvent: false});
                    this.toastService.show(this.refExists, "error");
                }
            } else {
                this.refExists = null;
                this.formGroup.get("ref").updateValueAndValidity({emitEvent: false});
            }
            this.changeDetectorRef.markForCheck();
        }
    }

    protected async checkSrExists(ref: string): Promise<any> {
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["service-request", ref], {}, {
                relations: ["Orders"]
            });
        this.srExists = null;
        if (data && data.orders && data.orders.length) {
            this.srExists = data.orders.map((o: Order.IOrderData): string => o.ref).join(", ");
        }
        this.changeDetectorRef.markForCheck();
    }

    protected async getCustomFields(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.get(`${this.state.section}/orders/custom-fields`);
        this.spinnerService.hide();

        this.formGroup.removeControl("custom_fields");
        this.formGroup.addControl("custom_fields", new FormGroup({}));

        if (data) {
            this.meta = data;
            for (const field of data) {
                (this.formGroup.get("custom_fields") as FormGroup)
                    .addControl(field.name, new FormControl(field.selected || null));
            }
        }
        this.changeDetectorRef.markForCheck();
    }

    protected setup(data: Wizard.IData): void {

        this.result.next({
            action: "result",
            value: false
        });

        this.result.next({
            action: "data",
            value: {details: null}
        });

        this.state = this.data.state;

        this.haveShipment = this.data.serviceLevel.order_type.have_shipment;
        this.shipmentName = this.data.serviceLevel.properties.shipment_name
            ? this.data.serviceLevel.properties.shipment_name
            : this.data.serviceLevel.order_type.shipment_name;

        this.canInbound = data.serviceLevel.order_type.can_inbound;
        this.canOutbound = data.serviceLevel.order_type.can_outbound;

        this.getCustomFields();
        this.prepareDetailsStepForm();
        this.getPartnerName();

        if (this.showIncoterms) {
            this.formGroup.get("out_incoterms").setValue(this.canOutbound ? "DAP" : null);
            this.formGroup.get("in_incoterms").setValue(this.canInbound ? "DAP" : null);
        }
    }

    public async formGroupAddressUpdate(e: any): Promise<any> {
        if (e.address_id) {
            const {data}: Api.IResponse = await this.addressService.getAddress(e.address_id);
            if (data) {
                this.address = data;

                if (data.out_incoterms) {
                    e.out_incoterms = data.out_incoterms;
                }
                if (data.in_incoterms) {
                    e.in_incoterms = data.in_incoterms;
                }
                this.result.next({
                    action: "data",
                    value: {address: data}
                });


                (this.formGroup.get("custom_fields") as FormGroup).removeControl("delivery_reference");
                this.meta = this.meta.filter(function (field) {
                    return field.name !== "delivery_reference";
                });

                if (this.address.delivery_ref) {
                    this.meta.push({
                        label: "Delivery reference",
                        name: "delivery_reference",
                        required: false,
                        type: "input"
                    });
                    (this.formGroup.get("custom_fields") as FormGroup)
                        .addControl("delivery_reference", new FormControl(null));
                }

                this.changeDetectorRef.markForCheck();
            }
        }
        this.formGroup.patchValue(e);
    }

    /**
     * Generate random string for order ref
     */
    public randomRef(): void {
        const ref: string =
            (this.refObject.country ? this.refObject.country : "")
            + (this.refObject.customer ? this.refObject.customer
                .replace(/[^a-zA-Z0-9]/g, "").substring(0, 3).trim() : "")
            + moment().format("MMDDHHmm");

        this.formGroup.get("ref").setValue(ref);
    }

    public whatIsIncoterm(): void {
        this.modalService.open(ModalComponent, {
            title: "What is Incoterm?",
            template: `<p>Incoterms are international import and export conditions. These terms define the competences
and responsibilities of the parts involved in an international transaction.</p>
            <h2>What do DAP, DDP, EXW mean?</h2>
            <p class="margin-bottom-10"><b>DAP</b> (Delivered At Place, DDU – Delivery Duty Unpaid before), means
             that the buyer will pay for all
            customs duties at import in his country.</p>
            <p class="margin-bottom-10"><b>DDP</b> (Delivered Duty Paid), means that the vendor pays all custom
            duties charged at export and import
            in the country of destination.</p>
            <p class="margin-bottom-10"><b>EXW</b> (Ex Works), means that the costs will be in charge of a third
            party code of that Carrier, by selecting EXW it will appear an additional field where to include the client
            code to be charged of duties.</p>`
        });
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this.formGroup.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(1500))
            .subscribe((value: any): void => {
                if (this.formGroup.valid) {
                    this.result.next({
                        action: "data",
                        value: {details: this.formGroup.value}
                    });
                    this.result.next({
                        action: "result",
                        value: true
                    });
                } else {
                    this.result.next({
                        action: "result",
                        value: false
                    });
                    this.result.next({
                        action: "data",
                        value: {details: null}
                    });
                }
            });

        this.formGroup.get("ref").valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: string): void => {
                if (value) {
                    this.refExists = null;
                    this.checkOrderExists(value);
                }
            });

        this.formGroup.get("sr").valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: string): void => {
                if (value) {
                    this.checkSrExists(value);
                }
            });

    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.data) {
            const pv: any = changes.data.previousValue;
            const cv: any = changes.data.currentValue;

            if (!pv.serviceLevel || pv.serviceLevel.id !== cv.serviceLevel.id) {
                this.setup(cv);
            }
        }
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}
