<h1 class="container-heading">Remarks</h1>

<div class="info">
    Refresh in <b>{{refresh}}</b> seconds
</div>

<mat-card>
    <mat-card-content>
        <form [formGroup]="filters" style="max-width: none; display: inline-block">
            <mat-form-field class="margin-right-10">
                <mat-label>Search</mat-label>
                <input type="search" matInput formControlName="search_by">
            </mat-form-field>
            <mat-form-field *ngIf="partners.length" class="margin-right-10">
                <mat-label>Partner</mat-label>
                <mat-select formControlName="partner_id" multiple>
                    <button mat-button style="width: 100%" color="success" (click)="selectAll()">
                        Select All
                    </button>
                    <mat-option *ngFor="let option of partners" [value]="option.value">
                        {{option.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="margin-right-10" *ngIf="remarkClassifications && remarkClassifications.length > 0">
                <mat-label>Classification</mat-label>
                <mat-select formControlName="order_remark_classification_ids" multiple>
                    <mat-option *ngFor="let option of remarkClassifications" [value]="option.id">
                        {{option.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="margin-right-10">
                <mat-label>
                    Who can see
                </mat-label>
                <mat-select formControlName="order_remark_type_ids" multiple>
                    <mat-option *ngFor="let option of remarkTypes" [value]="option.id">
                        {{option.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>

        <ng-container *ngIf="remarks && remarks.data.length">
            <div class="flex row space-between columns">
                <div class="third" *ngFor="let col of ['a','b','c']">
                    <common-section-remark *ngFor="let remark of remarks_columns[col]"
                                           [data]="remark"
                                           [orderRef]="remark.order.ref"
                                           [showMarkRead]="true"
                                           [readForAll]="true"
                                           [showOrderButton]="true"
                                           [state]="state"
                                           [enableColors]="false"
                                           [showSidebarButton]="true"
                                           [mentionUsers]="mentionUsers"
                                           [remarkTypes]="remarkTypes"
                                           (action)="onAction($event)"
                                           (reloadEvent)="getRemarks()"></common-section-remark>
                </div>
            </div>

            <div>
                <common-pagination [data]="remarks"
                                   pid="xMPj1yJ453"
                                   (goToPage)="getRemarks($event.page, $event.per_page)">
                </common-pagination>
            </div>

        </ng-container>

        <div *ngIf="!remarks">
            <common-simple-spinner></common-simple-spinner>
        </div>

        <div *ngIf="remarks && !remarks.data.length" class="text-center">
            <p>
                No remarks yet
            </p>
        </div>

    </mat-card-content>
</mat-card>

<section-remarks-sidebar #sidebar [markRemarkReadOnView]="false"></section-remarks-sidebar>
