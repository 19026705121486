import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from "@angular/core";
import {StorageService} from "../../services/storage.service";
import {Modal, ModalService} from "../../../section/services/modal.service";
import {CommonFormComponent} from "../form";
import {AppStateService} from "../../services/app-state.service";
import {Api3Service} from "../../services/api3.service";
import {Api} from "../../services/api.service";

@Component({
    selector: "common-load-config",
    template: `
        <div class="button-group">
            <button type="button" mat-raised-button [color]="color" (click)="onClick.emit()">
                {{label}}
            </button>
            <button type="button" mat-raised-button [color]="color"
                    [matMenuTriggerFor]="savedConfigsMenu" class="icon">
                <mat-icon class="vertical-middle">keyboard_arrow_down</mat-icon>
            </button>
        </div>

        <mat-menu #savedConfigsMenu="matMenu">
            <div mat-menu-item *ngFor="let config of configurations; let i = index" (click)="load(config)">
                {{config.name}}
                <mat-icon class="mat-menu-delete-button" (click)="delete(i, $event)">
                    close
                </mat-icon>
            </div>

            <div mat-menu-item (click)="saveNew()">
                <mat-icon>add</mat-icon>
                Save current
            </div>
        </mat-menu>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadConfigComponent implements OnInit {

    @Input()
    public configId: string;

    @Input()
    public color: string = "accent";

    @Input()
    public label: string;

    @Input()
    public config: any = null;

    @Output()
    public onClick: EventEmitter<any> = new EventEmitter();

    @Output()
    public onConfigSelect: EventEmitter<any> = new EventEmitter();

    public configurations: { name: string, conf: any }[] = [];

    public constructor(
        private modalService: ModalService,
        private changeDetector: ChangeDetectorRef,
        private api3Service: Api3Service
    ) {
    }

    private getStorageKey(): string {
        const section_slug = AppStateService.getState()?.section_slug ?? "";
        return AppStateService.getState().section_type + "_" + section_slug
            + "_" + this.configId;
    }

    private async getConfigurations(): Promise<any> {
        this.api3Service.get(
            `${AppStateService.getState().section}/users/filer-configurations/` + this.getStorageKey())
            .then(({data}: Api.IResponse) => {
                this.configurations = data;
                this.changeDetector.markForCheck();
            });
    }

    private async updateConfigurations(): Promise<any> {
        this.api3Service.post(
            `${AppStateService.getState().section}/users/filer-configurations/` + this.getStorageKey(), {
                configurations: this.configurations
            });
    }

    public delete(index: number, event: any): void {

        event.stopPropagation();

        this.configurations.splice(index, 1);

        this.updateConfigurations();
    }

    public async saveNew(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(CommonFormComponent, {
            formConfig: {
                name: "Save config",
                fields: [
                    {
                        label: "Name",
                        name: "name",
                        size: "full",
                        type: "input",
                        required: true,
                    },
                ]
            },
        });

        if (response && response.value) {
            this.configurations.push({
                name: response.value.name,
                conf: JSON.parse(JSON.stringify(this.config))
            });

            this.updateConfigurations();
        }
    }

    public load(configuration: { name: string, conf: any }): void {
        this.onConfigSelect.emit(configuration.conf);
    }

    public ngOnInit(): void {
        this.getConfigurations();
    }

}
