import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {Router} from "@angular/router";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {AbstractWizardComponent, WizardStepFactory} from "../../../../../../../common/interfaces/wizard.interface";
import {ApiService} from "../../../../../../../common/services/api.service";
import {
    WarehouseProceduresWizardFreeInboundResultsComponent,
    WarehouseProceduresWizardFreeInboundScanComponent,
    WarehouseProceduresWizardScanDefaultResultsComponent
} from "../../index";
import {ModalService} from "../../../../../../services/modal.service";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {Api3Service} from "../../../../../../../common/services/api3.service";


@Component({
    selector: "section-procedures-free-inbound",
    templateUrl: "wizard.component.html",
    styleUrls: [
        "../common/wizard.component.scss",
        "wizard.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class WarehouseProceduresWizardFreeInboundComponent extends AbstractWizardComponent
    implements OnInit, AfterViewInit {

    public status: any;

    public readonly state: Base.IState;

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private modalService: ModalService,
        private spinnerService: SpinnerService
    ) {
        super(changeDetectorRef);
    }


    /**
     * Finish wizard and redirect back to order
     */
    public finish(): void {
        this.router.navigate([
            this.state.section,
            "warehouse-orders-open"
        ]);
    }


    public ngOnInit(): void {
        super.ngOnInit();
        this.data.wizardType = "inbound";

        this.steps.push(
            new WizardStepFactory("Scan items", WarehouseProceduresWizardFreeInboundScanComponent));

        this.steps.push(
            new WizardStepFactory("Scan results", WarehouseProceduresWizardFreeInboundResultsComponent));

        this.changeDetectorRef.markForCheck();
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "procedures-free-inbound",
            actions: {
                "browse": ["read_reports"],
                "wizard": ["read_reports"],
            }
        };
    }

}
