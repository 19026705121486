import {ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {Table} from "../../../../../../../common/components/table/table.component";
import {UserService} from "../../../../../../../common/services/user.service";
import {Modal} from "../../../../../../services/modal.service";


@Component({
    selector: "section-http-logs-view",
    templateUrl: "view.component.html",
    styleUrls: [
        "view.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class HttpLogsViewComponent implements OnInit {

    public readonly state: Base.IState;
    public listTable: Table.IOptions;
    public modal: Modal.IModal;

    public request: any = {};
    public response: any = {};

    public constructor(public userService: UserService) {
    }

    public typo(val: any): string {
        return typeof (val);
    }


    public ngOnInit(): void {
        this.request = this.modal.params.action === "json"
            ? this.modal.params.data.request
            : JSON.parse(this.modal.params.data.request);
        this.response = this.modal.params.action === "json"
            ? this.modal.params.data.response
            : JSON.parse(this.modal.params.data.response);
    }

}
