<h1 class="container-heading">Shipment checkpoints</h1>

<div class="url">
    <a *ngIf="data.tracking_url"
       href="{{data.tracking_url}}"
       target="_blank"
       class="mat-mdc-raised-button mdc-button mat-primary">See on courier site</a>

    &nbsp;
    <button mat-raised-button color="accent" (click)="openMap()">See on map</button>
</div>


<mat-card>
    <mat-card-title [class]="data.status + ' mat-card-title'">
        <ng-template [ngIf]="data.courier">
            <div class="courier-logo">
                <img [src]="data.courier.icon_path">
            </div>
            <div class="courier-name">
                {{data.courier.display_name}}
            </div>
        </ng-template>
        <div class="tracking">
            <div class="number">Tr.№
                <b>{{data.tracking_number}}</b>
            </div>
            <div class="status">Status:
                <span class="chip {{data.status}}">
                  {{data.status}}
                </span>
            </div>
        </div>
    </mat-card-title>
    <mat-card-content>
        <div class="checkpoints">
            <ng-template ngFor let-checkpoint [ngForOf]="checkpoints">
                <div class="checkpoint">
                    <div class="date">
                        <b>{{checkpoint.checkpoint_time | date: "M/d/y"}}</b>
                        <br>
                        {{checkpoint.checkpoint_time | date: "h:mm a"}}
                    </div>

                    <div class="icon" [ngSwitch]="checkpoint.tag">
                        <span></span>
                        <mat-icon *ngSwitchCase="'Delivered'" class="Delivered">check_circle</mat-icon>
                        <mat-icon *ngSwitchDefault>radio_button_checked</mat-icon>
                        <span></span>
                    </div>
                    <div class="status ">
                        <b>{{checkpoint.message}}</b>
                        <br>
                        {{checkpoint.location}}
                    </div>
                </div>
            </ng-template>
        </div>
    </mat-card-content>
</mat-card>
