import {Injectable} from "@angular/core";
import {Api3Service} from "../../../../common/services/api3.service";
import {Api} from "../../../../common/services/api.service";

@Injectable()
export class AdminReportService {

    public constructor(private apiService: Api3Service) {
    }

    public getReports(query: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Get, "admin/reports", {}, query);
    }


    public getReport(id: number, query: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Get, "admin/reports/" + id, {}, query);
    }

    public attachPartners(report_id: number, attach_ids: number[], body: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Put, "admin/reports/" + report_id + "/attach/partners", {
            partners: attach_ids,
            ...body
        });
    }

    public attachWarehouses(report_id: number, attach_ids: number[], body: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Put, "admin/reports/" + report_id + "/attach/warehouses", {
            warehouses: attach_ids,
            ...body
        });
    }

    public detachPartners(report_id: number, detach_ids: number[], body: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Put, "admin/reports/" + report_id + "/detach/partners", {
            partners: detach_ids,
            ...body
        });
    }

    public detachWarehouses(report_id: number, detach_ids: number[], body: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Put, "admin/reports/" + report_id + "/detach/warehouses", {
            warehouses: detach_ids,
            ...body
        });
    }

}
