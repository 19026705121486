<div class="pull-right padding-top-10">
    <common-form-switch
            labelOn="Tiles"
            labelOff="List"
            [value]="tiles"
            (valueChange)="switchTemplate($event)"
    ></common-form-switch>
</div>

<div class="pull-left margin-bottom-20" style="margin-right: 20px;">
    <form *ngIf="showFilters" [formGroup]="filters" style="max-width: none; display: inline-block">
        <mat-form-field>
            <mat-label>Select Status</mat-label>
<mat-select  formControlName="status" multiple>
                <button mat-button style="width: 100%" color="success" (click)="selectAll('showStatus')">
                    Select All
                </button>
                <mat-option value="New">New</mat-option>
                <mat-option value="Open">Open</mat-option>
                <mat-option value="Pending">Pending</mat-option>
                <mat-option value="In-progress">In-progress</mat-option>
                <mat-option value="On-hold">On-hold</mat-option>
                <mat-option value="Solved">Solved</mat-option>
                <mat-option value="Closed">Closed</mat-option>
            </mat-select>
        </mat-form-field>
        &nbsp;
        <mat-form-field>
            <mat-label>Select Kind</mat-label>
<mat-select  formControlName="kind" multiple>
                <button mat-button style="width: 100%" color="success" (click)="selectAll('showKind')">
                    Select All
                </button>
                <mat-option *ngFor="let item of kinds" [value]="item">{{item}}</mat-option>
            </mat-select>
        </mat-form-field>
        &nbsp;
        <mat-form-field>
            <mat-label>Sort by</mat-label>
<mat-select  formControlName="sort_by">
                <mat-option value="updated_at">Update date</mat-option>
                <mat-option value="created_at">Creation date</mat-option>
                <mat-option value="status">Status</mat-option>
                <mat-option value="timeline_to">Timeline</mat-option>
                <mat-option value="urgency">Urgency</mat-option>
            </mat-select>
        </mat-form-field>
        &nbsp;
        <ng-container *ngIf="partners.length">
            <mat-form-field>
                <mat-label>Partner</mat-label>
<mat-select  formControlName="partner_id" multiple>
                    <button mat-button style="width: 100%" color="success" (click)="selectAll('showPartner')">
                        Select All
                    </button>
                    <mat-option *ngFor="let option of partners" [value]="option.value">
                        {{option.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            &nbsp;
        </ng-container>
        <span>
            <mat-checkbox formControlName="only_open"> Only open</mat-checkbox>
        </span>

    </form>

    <button type="button" mat-raised-button color="primary" (click)="goToActivities()">Recent activities</button>

    &nbsp;

    <button *ngIf="tiles" type="button" mat-raised-button color="accent" (click)="export()">Export</button>
</div>

<div *ngIf="!tiles; else tilesTpl" class="clear">
    <common-table2 *ngIf="listTable" [settings]="listTable"></common-table2>
</div>

<ng-template #tilesTpl>
    <section-followups-tiles [filters]="filters" [only_my]="only_my" [favorite]="favorite" [state]="state">
    </section-followups-tiles>
</ng-template>
