import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {Router} from "@angular/router";
import {Api} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {AppLockService} from "../../../../../../common/services/app-lock.service";
import {Wizard, WizardStepFactory} from "../../../../../../common/interfaces/wizard.interface";
import {StorageService} from "../../../../../../common/services/storage.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {
    OrderWizardContractComponent,
    OrderWizardCourierServiceComponent,
    OrderWizardDetailsComponent,
    OrderWizardDetailsMavenirComponent,
    OrderWizardInstructionsComponent,
    OrderWizardItemsReplenishmentComponent,
    OrderWizardItemsRmaComponent, OrderWizardItemsSalesComponent,
    OrderWizardItemsTransferComponent,
    OrderWizardOriginComponent,
    OrderWizardServiceLevelComponent,
    OrderWizardServiceLevelMavenirComponent,
    OrderWizardTransferDetailsComponent
} from "../index";
import {AbstractSinglePageWizardComponent} from "../../../../../../common/interfaces/wizard-single-page.interface";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {AmplitudeService} from "../../../../../../common/services/amplitude.service";
import {Api3Service} from "../../../../../../common/services/api3.service";
import {User} from "../../../../../../common/interfaces/user.interface";
import {PartnerService} from "../../../../../../common/services/partner.service";

@Component({
    selector: "section-order-wizard",
    templateUrl: "wizard.component.html",
    styleUrls: [
        "wizard.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class OrderWizardComponent extends AbstractSinglePageWizardComponent implements Base.IComponent, OnInit {

    private wizardConfig: any = {
        default: {
            init: [
                {
                    "title": "Order contract",
                    "component": OrderWizardContractComponent
                },
                {
                    "title": "Service level",
                    "component": OrderWizardServiceLevelComponent
                },
            ],
            serviceLevel: { // setup step name
                sales: [ // setup partner level
                    {
                        title: "Shipment details",
                        component: OrderWizardDetailsComponent
                    },
                    {
                        title: "Order items",
                        component: OrderWizardItemsSalesComponent
                    },
                    {
                        title: "Warehouse instructions",
                        component: OrderWizardInstructionsComponent
                    },
                ],
                replenishment: [
                    {
                        title: "Shipment details",
                        component: OrderWizardDetailsComponent
                    },
                    {
                        title: "Order items",
                        component: OrderWizardItemsReplenishmentComponent
                    },
                    {
                        title: "Warehouse instructions",
                        component: OrderWizardInstructionsComponent
                    },
                ],
                transfer: [
                    {
                        title: "Transfer details",
                        component: OrderWizardTransferDetailsComponent
                    },
                    {
                        title: "Order items",
                        component: OrderWizardItemsTransferComponent
                    },
                    {
                        title: "Warehouse instructions",
                        component: OrderWizardInstructionsComponent
                    },
                ],
                rma: [
                    {
                        title: "Shipment details",
                        component: OrderWizardDetailsComponent
                    },
                    {
                        title: "Order items",
                        component: OrderWizardItemsRmaComponent
                    },
                    {
                        title: "Warehouse instructions",
                        component: OrderWizardInstructionsComponent
                    },
                ],
                regular_rma: [
                    {
                        title: "Shipment details",
                        component: OrderWizardDetailsComponent
                    },
                    {
                        title: "Order items",
                        component: OrderWizardItemsRmaComponent
                    },
                    {
                        title: "Warehouse instructions",
                        component: OrderWizardInstructionsComponent
                    },
                ],
                return: [
                    {
                        title: "Shipment details",
                        component: OrderWizardDetailsComponent
                    },
                    {
                        title: "Order items",
                        component: OrderWizardItemsRmaComponent
                    },
                    {
                        title: "Warehouse instructions",
                        component: OrderWizardInstructionsComponent
                    },
                ],
                informative: [
                    {
                        title: "Origin address",
                        component: OrderWizardOriginComponent
                    },
                    {
                        title: "Destination address",
                        component: OrderWizardDetailsComponent
                    },
                ],
            }
        },
        mavenir_il: {
            init: [
                {
                    title: "Shipment details",
                    component: OrderWizardDetailsMavenirComponent
                },
                {
                    "title": "Order contract",
                    "component": OrderWizardContractComponent
                },
                {
                    "title": "Service level",
                    "component": OrderWizardServiceLevelMavenirComponent
                },
                {
                    "title": "Courier service",
                    "component": OrderWizardCourierServiceComponent
                },
            ],
            serviceLevel: {
                sales: [
                    {
                        title: "Order items",
                        component: OrderWizardItemsSalesComponent
                    },
                    {
                        title: "Warehouse instructions",
                        component: OrderWizardInstructionsComponent
                    },
                ],

                replenishment: [
                    {
                        title: "Order items",
                        component: OrderWizardItemsReplenishmentComponent
                    },
                    {
                        title: "Warehouse instructions",
                        component: OrderWizardInstructionsComponent
                    },
                ],
                transfer: [
                    {
                        title: "Order items",
                        component: OrderWizardItemsTransferComponent
                    },
                    {
                        title: "Warehouse instructions",
                        component: OrderWizardInstructionsComponent
                    },
                ],
                rma: [
                    {
                        title: "Order items",
                        component: OrderWizardItemsRmaComponent
                    },
                    {
                        title: "Warehouse instructions",
                        component: OrderWizardInstructionsComponent
                    },
                ],
                regular_rma: [
                    {
                        title: "Order items",
                        component: OrderWizardItemsRmaComponent
                    },
                    {
                        title: "Warehouse instructions",
                        component: OrderWizardInstructionsComponent
                    },
                ]
            }
        },
    };

    private finishInProgress: boolean = false;

    public customWizardStyle: boolean = false;

    public partner: User.IPartner;


    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        // private apiService: ApiService,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private appLock: AppLockService,
        private storageService: StorageService,
        private spinnerService: SpinnerService
    ) {
        super(changeDetectorRef);
    }

    protected setupEvent(event: Wizard.IStepResult, stepIndex: number): void {

        const eventPath: string = event.value.path ? event.value.path : event.value;

        const partner_config: any = this.partner_slug
            ? HelpersService.dotToObjectPath(this.wizardConfig, this.partner_slug + "." + eventPath)
            : null;

        let config: any;

        if (partner_config && this.customWizardStyle) {
            config = partner_config;
        } else {
            config = HelpersService.dotToObjectPath(this.wizardConfig, "default." + eventPath);
        }

        if (!config) {
            return;
        }

        const insert: Wizard.IStep[] = [];

        for (const step of config) {
            insert.push(new WizardStepFactory(step.title, step.component,
                true, null, null, step.params || null));
        }

        this.steps.splice(event.value.index ? event.value.index : stepIndex + 1);
        this.steps.push(...insert);
    }

    protected dataEvent(event: Wizard.IStepResult): void {
        super.dataEvent(event);

        setTimeout(() => {
            if (this.data.serviceLevel && this.data.details) {
                this.saveDraftOrder();
            }
        }, 100);

    }

    protected async saveDraftOrder(): Promise<any> {
        if (!this.data.serviceLevel) {
            return;
        }

        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.post(
            `${this.state.section}/orders/draft` + (this.data.order_id ? "/" + this.data.order_id : ""), {
                service_level_id: this.data.serviceLevel.id,
                ...this.data.details
            });

        this.spinnerService.hide();
        if (data) {
            this.data.order_id = data.id;
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Submit all steps values
     * @returns {Promise<any>}
     */
    public async finish(): Promise<any> {
        this.finishInProgress = true;
        if (!this.valid) {
            return;
        }

        const body: any = {
            ...this.data.details,
            confirm_on: this.data.confirm_on,
            remarks: this.data.order_remarks,
            warehouse_instructions: this.data.warehouse_instructions,
            shipping_instructions: this.data.shipping_instructions,
            deliver_on: this.data.deliver_on,
            origin_details: this.data.origin_details,
            service_level_id: this.data.serviceLevel.id,
        };

        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.api3Service.put(
            `${this.state.section}/orders/draft/${this.data.order_id}`, body);

        this.spinnerService.hide();

        if (code === 200) {
            this.toastService.show(message, "success");
            this.appLock.unlock();
            this.router.navigate([this.state.section, this.state.component, "view", "id", this.data.order_id]);
        }
        AmplitudeService.eventClick("Finish - on order creation");
    }

    public switchWizardStyle(set_custom: boolean): void {
        this.customWizardStyle = set_custom;
        this.storageService.set("order_wizard_type_custom", set_custom);

        this.setupEvent({action: "setup", value: "init"}, -1);
    }

    public ngOnInit(): void {
        this.partner = PartnerService.partner;

        if (this.partner?.slug === "mavenir_il") {
            this.customWizardStyle = this.storageService.get("order_wizard_type_custom", true);
        }

        this.changeDetectorRef.markForCheck();

        super.ngOnInit();

        this.wizardHeading = "Add order";
        this.storageService.remove("add_order");

    }

    public ngConfig(): Base.IConfig {
        return {
            name: "orders",
            actions: {
                "add": [
                    "add_addresses", "browse_addresses",
                    "add_contacts", "browse_contacts",
                    "browse_contracts", "read_contracts",
                    "add_customers", "browse_customers",
                    "browse_orders", "add_orders", "read_orders",
                ]
            }
        };
    }
}