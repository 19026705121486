<h1 class="container-heading">Change tracking</h1>

<mat-card>
    <mat-card-content>
        <form *ngIf="formGroup" [formGroup]="formGroup" (submit)="handleFormSubmit()" autocomplete="off" novalidate>

            <div class="group flex" *ngIf="modal.params.action === 'change'">
                <mat-form-field class="full">
                    <mat-label>Tracking number</mat-label>
<input matInput formControlName="tracking_number"  required>
                </mat-form-field>

                <common-form-select
                        class="half"
                        label="Courier slug"
                        [options]="couriers"
                        [required]="true"
                        [search]="true"
                        [value]="formGroup.value.courier_slug"
                        (valueChange)="formGroup.get('courier_slug').setValue($event)">
                </common-form-select>

                <mat-form-field class="half">
                    <mat-select placeholder="Courier service" formControlName="courier_service_slug" required>
                        <mat-option *ngFor="let service of courierServices" [value]="service.slug">
                            {{ service.service_name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="group flex" *ngIf="modal.params.action === 'edit_price'">
                <mat-form-field class="full">
                    <mat-label>Price</mat-label>
                    <input matInput formControlName="price" required type="number">
                </mat-form-field>
            </div>

            <div class="actions">
                <button [disabled]="!this.formGroup || !this.formGroup.valid"
                        mat-raised-button color="primary" type="submit" class="main">
                    Save
                </button>
            </div>

        </form>
    </mat-card-content>
</mat-card>
