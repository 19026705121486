<div class="container-columns">
    <mat-card class="left">
        <h3>Order shipment</h3>

        <div *ngIf="order && order.custom_fields?.delivery_reference">
            <b>Delivery ref:</b>
            {{order.custom_fields.delivery_reference}}
        </div>

        <mat-form-field class="search">
            <mat-label>Search</mat-label>
            <input matInput #trackSearch type="search">
        </mat-form-field>

        <div class="item shipment" *ngIf="shipment">
            <div class="flex row vertical-top">
                <div class="title max">
                    <div>
                        <mat-icon>flight_takeoff</mat-icon>
                        <ng-template [ngIf]="shipment.from_address.formatted_address">
                            {{shipment.from_address.formatted_address}},
                        </ng-template>
                        <ng-template [ngIf]="!shipment.from_address.formatted_address">
                            {{shipment.from_address.address}},
                            {{shipment.from_address.city}},
                            {{shipment.from_address.country}}
                        </ng-template>

                    </div>

                    <div class="margin-bottom-20">
                        <mat-icon>flight_land</mat-icon>
                        <ng-template [ngIf]="shipment.to_address.formatted_address">
                            {{shipment.to_address.formatted_address}},
                        </ng-template>
                        <ng-template [ngIf]="!shipment.to_address.formatted_address">
                            {{shipment.to_address.address}},
                            {{shipment.to_address.city}},
                            {{shipment.to_address.country}}
                        </ng-template>
                    </div>

                    <div style="color: #4caf50" *ngIf="order.courier_service?.service_name">
                        <b>Courier service: </b>
                        {{order.courier_service.description || order.courier_service.service_name}}
                    </div>

                    <div *ngIf="!order.courier_service?.service_name && order.service_level?.courier_service">
                        <b>Courier service: </b>
                        {{order.service_level.courier_service.service_name}}
                        - {{order.service_level.courier_service.description}}
                    </div>

                </div>

                <div class="text-right" *ngIf="!isViewOnly">
                    <button mat-mini-fab color="success" (click)="addCourierTransaction(shipment.id)">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
            </div>


            <div class="drag-bag transactions id-{{shipment.id}}"
                 *ngIf="shipment.courier_transactions"
                 [dragula]="dragulaBagName"
                 [(dragulaModel)]="shipment.courier_transactions">
                <div *ngFor="let transaction of shipment.courier_transactions | search: trackSearch.value : ['tracking_number']"
                     class="transaction id-{{transaction.id}}">
                    <div class="pull-right">
                        <div>
                            <mat-icon class="material-icons mat-icon"
                                      matTooltip="Edit"
                                      (click)="editCourierTransaction(transaction)">
                                create
                            </mat-icon>
                        </div>

                        <div>
                            <mat-icon *ngIf="transaction.label_url"
                                      matTooltip="Print label"
                                      (click)="openLabel(transaction.label_url)" aria-label="Print label">
                                ballot
                            </mat-icon>
                        </div>
                    </div>

                    Transaction:
                    <div class="pull-left">
                        <img [src]="transaction.courier.icon_path" alt="">
                    </div>
                    <div class="title">
                        <div>Tracking number: <b>{{transaction.tracking_number}}</b></div>
                        <div>Status: <span class="chip {{transaction.status}}">{{transaction.status}}</span></div>
                        <div>Courier: <b>{{transaction.courier.display_name}}</b></div>


                    </div>

                </div>
            </div>
        </div>
    </mat-card>
    <mat-card class="right">
        <div class="sticky">

            <!--            <button mat-mini-fab color="success" class="pull-right" (click)="addCourierTransaction()">-->
            <!--                <mat-icon>add</mat-icon>-->
            <!--            </button>-->

            <h3>Courier transactions</h3>
            <mat-form-field class="search">
                <mat-label>Search</mat-label>
                <input matInput #transactionsSearch type="search">
            </mat-form-field>
            <div class="transactions" *ngIf="freeTracks"
                 [dragula]="dragulaBagName"
                 [(dragulaModel)]="freeTracks">
                <div *ngFor="let transaction of freeTracks | search: transactionsSearch.value : ['tracking_number']"
                     class="transaction id-{{transaction.id}}">
                    <mat-icon class="material-icons mat-icon pull-right"
                              (click)="editCourierTransaction(transaction)">create
                    </mat-icon>
                    Transaction:
                    <div class="pull-left">
                        <img [src]="transaction.courier.icon_path" alt="">
                    </div>
                    <div class="title">
                        <div>Tracking number: <b>{{transaction.tracking_number}}</b></div>
                        <div>Status:
                            <span class="chip {{transaction.status}}">{{transaction.status}}</span>
                        </div>
                        <div>Courier: <b>{{transaction.courier.display_name}}</b></div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card>
</div>
