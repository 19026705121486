import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Modal} from "../../../../../services/modal.service";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Warehouse} from "../../../../../../common/interfaces/warehouse.interface";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import { User } from "src/modules/common/interfaces/user.interface";


@Component({
    selector: "section-pallet-sticker-2",
    template: `

        <div class="pull-right">
            <button mat-raised-button color="accent" (click)="print()">
                <mat-icon>print</mat-icon>
                Print
            </button>
        </div>
        <h1 class="container-heading">Pallet sticker</h1>
        <mat-card>
            <mat-card-content #sticker>

                <div class="sticker" style="padding-top: 10px; width: 10.16cm; height: 15.24cm;">
                    <div class="text-center" style="margin-bottom: 10px;">
                        <common-barcode [bcValue]="pallet.ref" [bcDisplayValue]="true"
                                        bcWidth="1"
                                        [bcMarginTop]="1"
                                        [bcMarginLeft]="1"
                                        [bcMarginRight]="1"
                                        [bcMarginBottom]="1"
                                        bcHeight="30"></common-barcode>
                    </div>

                    <table>
                        <tr>
                            <td>Warehouse:</td>
                            <td colspan="3">{{modal.params?.warehouseName}}</td>
                        </tr>
                        <tr>
                            <td>Location:</td>
                            <td colspan="3">{{modal.params?.locationName}}</td>
                        </tr>
                        <tr>
                            <td>Receipt Dt.:</td>
                            <td colspan="3">{{pallet.created_at | date: 'MM/dd/yyyy'}}</td>
                        </tr>

                    </table>

                </div>
            </mat-card-content>
        </mat-card>
    `,
    styleUrls: [
        "sticker.component.scss"
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PalletSticker2Component implements OnInit {

    public modal: Modal.IModal;

    public pallet: Warehouse.IPallet;

    public partner: User.IPartner;

    @ViewChild("sticker", {static: false})
    public sticker: ElementRef;

    public constructor(
        private apiService: ApiService,
        private changeDetectorRef: ChangeDetectorRef,
        private spinnerService: SpinnerService
    ) {
    }

    private async getPartner(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["partner", "" + this.modal.params.pallet.partner_id]);
        if (data) {
            this.partner = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    public print(): void {
        const printContents: any = this.sticker.nativeElement.innerHTML;
        HelpersService.stickerPopUp(printContents);
    }


    public ngOnInit(): void {
        this.pallet = this.modal.params.pallet;
        this.getPartner();
    }

}
