import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {HelpersService} from "../../../../../../../common/services/helpers.service";
import {PusherService} from "../../../../../../../common/services/pusher.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {Modal} from "../../../../../../services/modal.service";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {User} from "../../../../../../../common/interfaces/user.interface";
import {distinctUntilChanged, takeUntil} from "rxjs/operators";


@Component({
    selector: "section-inventory-count-form",
    templateUrl: "form.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WarehouseInventoryCountFormComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    public formGroup: FormGroup = new FormGroup({
        ref: new FormControl(null, [Validators.required]),
        partner_id: new FormControl(null, [Validators.required]),
        part_master_id: new FormControl([])
    });

    public partner: FormControl = new FormControl(null, [Validators.required]);

    public modal: Modal.IModal;

    public partners: User.IPartner[] = [];

    public partmasters: { name: string, value: any }[] = [];

    public constructor(
        private apiService: ApiService,
        private pusherService: PusherService,
        private toastService: ToastService,
        private changeDetectorRef: ChangeDetectorRef,
        private spinnerService: SpinnerService
    ) {
    }

    /**
     * Get partners list
     */
    private async getPartners(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["warehouse", "partners"]);
        if (data) {
            this.partners = data.map((partner: User.IPartner): any => {
                return {
                    name: partner.display_name,
                    value: partner
                };
            });
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get part masters list
     */
    private async getPartMasters(partner: string = null): Promise<any> {
        this.spinnerService.show();
        this.apiService.setPartner(partner);
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["partmaster"], null, {
                data_structure: "select",

            });
        if (data) {
            this.partmasters = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Submit form
     */
    public async submit(): Promise<any> {
        this.spinnerService.show();
        const asyncVal: string = HelpersService.randomString();
        this.apiService.setHeaders({
            async: asyncVal
        });
        const {type, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["inventory", "counts"], this.formGroup.value);

        if (type as string === "success") {
            this.toastService.show(message, "success");
            this.modal.response.emit({
                name: "value",
                value: asyncVal
            });
        }
        this.spinnerService.hide();
    }

    public ngOnInit(): void {
        this.getPartners();

        this.partner.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
            .subscribe((value: User.IPartner): void => {
                if (value) {
                    this.getPartMasters(value.slug);
                }
                this.formGroup.get("partner_id").setValue(value.id);
            });
    }


    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
