import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {InventoryConditionFormComponent} from "../index";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {ToastService} from "../../../../../../common/services/toast.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {Table2Component} from "../../../../../../common/components/table2";
import {FormControl, FormGroup} from "@angular/forms";
import {takeUntil} from "rxjs";
import {distinctUntilChanged} from "rxjs/operators";

@Component({
    selector: "section-icb-list",
    template: `
        <common-confirm></common-confirm>
        <h1 class="container-heading">Inventory Condition list</h1>
        <mat-card>
            <mat-card-content>
                <common-table2 #main *ngIf="listTable" [settings]="listTable" [search]="false">
                    <div [formGroup]="filterForm" class="flex row">
                        <common-form-search label="Item"
                                            url="partmaster"
                                            [multiple]="false"
                                            [value]="filterForm.value.part_master_id"
                                            (valueChange)="filterForm.get('part_master_id').setValue($event)">
                        </common-form-search>
                        &nbsp;
                        <mat-form-field>
                            <mat-label>State</mat-label>
<mat-select formControlName="active" >
                                <mat-option [value]="null">All</mat-option>
                                <mat-option [value]="1">Active</mat-option>
                                <mat-option [value]="0">Disabled</mat-option>
                            </mat-select>
                        </mat-form-field>
                        &nbsp;
                        <div>
                            <button mat-mini-fab type="button" color="warn" (click)="clearFilter()"
                                    matTooltip="Clear filter">
                                <mat-icon>close</mat-icon>
                            </button>
                        </div>
                        &nbsp;
                        <div>
                            <button mat-raised-button type="button" color="accent" (click)="add()">Add</button>
                        </div>
                    </div>
                </common-table2>

                <div class="padding-top-20">
                    <h2>History</h2>
                    <common-table2 #activities *ngIf="listActivitiesTable" [settings]="listActivitiesTable"
                                   [search]="false"></common-table2>
                </div>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InventoryConditionListComponent implements OnInit, Base.IComponent, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.ISettings}
     */
    public listTable: Table.ISettings;

    public listActivitiesTable: Table.ISettings;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild("activities", {static: false})
    public tableActivitiesRef: Table2Component;

    @ViewChild("main", {static: false})
    public tableMainRef: Table2Component;

    public modal: Modal.IModal;

    public readonly state: Base.IState;

    public filterForm: FormGroup = new FormGroup({
        part_master_id: new FormControl(null),
        active: new FormControl(null)
    });

    public constructor(
        private apiService: ApiService,
        private modalService: ModalService,
        private toastService: ToastService,
        private spinnerService: SpinnerService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    private getUrl(): Table.ITableApi {
        return {
            url: ["inventory", "condition-bias"],
            query: this.filterForm.value
        };
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        this.listTable = {
            table_id: "lomundrx",
            actions: [
                {
                    name: "edit",
                    title: "Edit",
                    click: async (row: any): Promise<any> => {
                        await this.modalService.open(InventoryConditionFormComponent, {
                            id: row.id,
                        });
                        this.tableMainRef.reload();
                        this.tableActivitiesRef.reload();
                    }
                },
                {
                    name: "delete",
                    title: "Delete",
                    click: async (row: any): Promise<any> => {
                        if (await this.confirmRef.confirm("Are you sure want to delete this record?")) {
                            this.spinnerService.show();
                            const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
                                ["inventory", "condition-bias", "" + row.id]);
                            if (type as string === "success") {
                                this.toastService.show(message, "success");
                                this.tableMainRef.reload();
                                this.tableActivitiesRef.reload();
                            }
                            this.spinnerService.hide();
                        }
                    }
                }
            ],
            api: this.getUrl(),
            columns: [
                {
                    data: "is_active",
                    title: "Active",
                    render: (row: any): string => {
                        return row.is_active ? "Yes" : "No";
                    },
                    searchable: false
                },
                {
                    data: "part_master.item",
                    name: "PartMaster.item",
                    title: "Item",
                    searchable: false
                },
                {
                    data: "rev",
                    title: "Rev"
                },
                {
                    data: "image_version",
                    title: "Image version"
                },
                {
                    data: "hub_from.customers_inventory_name",
                    name: "HubFrom.customers_inventory_name",
                    title: "Hub from",
                    render: (row: any): string => {
                        if (!row.hub_from) {
                            return "";
                        }
                        return row.hub_from.customers_inventory_name
                            + " (" + row.hub_from.customers_sub_inventory + ")";
                    },
                    searchable: false,
                    sortable: false
                },
                {
                    data: "hub_to.customers_inventory_name",
                    name: "HubTo.customers_inventory_name",
                    title: "Hub to",
                    render: (row: any): string => {
                        if (!row.hub_to) {
                            return "";
                        }
                        return row.hub_to.customers_inventory_name
                            + " (" + row.hub_to.customers_sub_inventory + ")";
                    },
                    searchable: false,
                    sortable: false
                },
                {
                    data: "user.name",
                    name: "User.name",
                    title: "Created by",
                    searchable: false
                },
                {
                    data: "created_at",
                    title: "Created at"
                },
                {
                    data: "status",
                    title: "Status"
                }
            ],
            export: {
                file_name: "inventory conditions"
            }
        };
        this.changeDetectorRef.markForCheck();
    }

    private prepareActivitiesList(): void {
        this.listActivitiesTable = {
            table_id: "lomundrxdd",
            actions: [],
            api: {
                url: ["inventory", "condition-bias", "activities"],
            },
            columns: [
                {
                    data: "log_name",
                    title: "Log"
                },
                {
                    data: "description",
                    title: "Description"
                },
                {
                    data: "properties.previous_state",
                    title: "Previous value"
                },
                {
                    data: "properties.current_state",
                    title: "Current value"
                },
                {
                    data: "user.name",
                    title: "Causer"
                },
                {
                    data: "created_at",
                    title: "Created at"
                },
            ],
            export: {
                file_name: "inventory conditions"
            }
        };
        this.changeDetectorRef.markForCheck();
    }

    public async add(): Promise<any> {
        await this.modalService.open(InventoryConditionFormComponent);
        this.tableMainRef.reload();
        this.tableActivitiesRef.reload();
    }

    public clearFilter(): void {
        this.filterForm.reset();
    }

    public ngOnInit(): void {
        this.prepareList();
        this.prepareActivitiesList();

        this.filterForm.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe((): void => {
            this.tableMainRef.reload(this.getUrl());
        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }


    public ngConfig(): Base.IConfig {
        return {
            name: "inventory-condition-bias",
            actions: {
                "browse": ["browse_inventory_conditions"]
            }
        };
    }

}
