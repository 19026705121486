<common-confirm></common-confirm>


<mat-accordion *ngIf="items">
    <ng-template ngFor let-item let-i="index" [ngForOf]="items">
        <mat-expansion-panel *ngIf="item">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span>
                         <b>
                            {{ item.value.first_name }}
                             {{ item.value.last_name }} -
                             {{ item.value.email }}
                        </b>
                    </span>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <form [formGroup]="item" autocomplete="off" (submit)="submitUpdate(i)">
                <div class="group flex">
                    <mat-form-field class="half">
                        <mat-label>First name</mat-label>
                        <input matInput formControlName="first_name" required>
                    </mat-form-field>

                    <mat-form-field class="half">
                        <mat-label>Last name</mat-label>
                        <input matInput formControlName="last_name" required>
                    </mat-form-field>

                    <mat-form-field class="full">
                        <mat-label>Email</mat-label>
                        <input matInput formControlName="email" required>
                    </mat-form-field>

                    <mat-form-field class="half">
                        <mat-label>Phone</mat-label>
                        <input matInput formControlName="phone">
                    </mat-form-field>

                    <mat-form-field class="half">
                        <mat-label>Fax</mat-label>
                        <input matInput formControlName="fax">
                    </mat-form-field>

                    <mat-form-field class="full">
                        <mat-label>Service levels</mat-label>
                        <mat-select formControlName="service_level_ids" multiple>
                            <mat-option *ngFor="let service of service_levels" [value]="service.value">
                                {{ service.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="half">
                        <mat-label>Role</mat-label>
                        <input matInput formControlName="role">
                    </mat-form-field>

                    <div class="half"></div>


                    <button [disabled]="!item.valid || !item.dirty" mat-raised-button
                            color="primary" type="submit">
                        Update
                    </button>
                    &nbsp;
                    <button mat-raised-button color="warn" class="delete-button"
                            type="button" (click)="submitDelete(i)">
                        Delete
                    </button>
                </div>
            </form>
        </mat-expansion-panel>
    </ng-template>
</mat-accordion>

<h3 class="mat-h3">Add new contact</h3>
<form *ngIf="newForm" [formGroup]="newForm" autocomplete="off" (submit)="submitAdd()">
    <div class="group flex">
        <mat-form-field class="half">
            <mat-label>First name</mat-label>
            <input matInput formControlName="first_name" required>
        </mat-form-field>

        <mat-form-field class="half">
            <mat-label>Last name</mat-label>
            <input matInput formControlName="last_name" required>
        </mat-form-field>

        <mat-form-field class="full">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" required>
        </mat-form-field>

        <mat-form-field class="half">
            <mat-label>Phone</mat-label>
            <input matInput formControlName="phone">
        </mat-form-field>

        <mat-form-field class="half">
            <mat-label>Fax</mat-label>
            <input matInput formControlName="fax">
        </mat-form-field>

        <mat-form-field class="full">
            <mat-label>Service levels</mat-label>
            <mat-select formControlName="service_level_ids" multiple>
                <mat-option *ngFor="let service of service_levels" [value]="service.value">
                    {{ service.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="half">
            <mat-label>Role</mat-label>
            <input matInput formControlName="role">
        </mat-form-field>

        <div class="half"></div>

        <button [disabled]="!newForm.valid" mat-raised-button color="primary" type="submit">Submit</button>
        &nbsp;
        <button mat-raised-button type="button" (click)="newForm.reset()">Reset</button>
    </div>
</form>
