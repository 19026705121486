import { ENTER, COMMA, SEMICOLON, TAB } from "@angular/cdk/keycodes";
import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef, OnDestroy, EventEmitter } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatChipInputEvent } from "@angular/material/chips";
import { takeUntil } from "rxjs/operators";
import { Form } from "src/modules/common/interfaces/form.interface";
import { Api } from "src/modules/common/services/api.service";
import { Api3Service } from "src/modules/common/services/api3.service";
import { PartnerService } from "src/modules/common/services/partner.service";
import { SpinnerService } from "src/modules/common/services/spinner.service";
import { Modal } from "src/modules/section/services/modal.service";

@Component({
    selector: "partner-packages-boxes-add-item-form",
    templateUrl: "add-item-form.component.html",
    styleUrls: ["add-item-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PackageBoxesAddItemFormComponent implements OnInit, OnDestroy {
    public modal: Modal.IModal;

    public inventories: any[] = [];
    public inventoriesFiltered: Form.ISelectOption[] = [];
    public inventoriesSearch: FormControl = new FormControl(null);
    public inventoriesSerials: {[key: string]: {id: number}[]} = {
    }

    public serials: any[] = [];

    public availableSerials: any[] = [];

    public readonly separatorKeysCodes: any[] = [ENTER, COMMA, SEMICOLON, TAB];

    public formGroup: FormGroup = new FormGroup({
        item: new FormControl(null, [Validators.required]),
        qty: new FormControl(0, [Validators.required, Validators.min(1)]),
        serials: new FormControl([])
    });

    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    public constructor(
        private apiV3Service: Api3Service,
        private changeDetectorRef: ChangeDetectorRef,
        private spinnerService: SpinnerService) {
    }

    private async getItems(locationId: number): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiV3Service.request(Api.EMethod.Get,
            `partner/${PartnerService.partner.slug}/inventories`, {}, {
                no_box: true,
                location_id: locationId,
                available_only: true,
                relations: ["PartMaster:id,item,serial"]
        });
        if (data && data.length) {
            this.inventories = Array.from(new Map(
                data.map(inventory => [inventory.part_master_id, inventory.part_master])
              ).values());
            this.inventoriesFiltered = this.inventories;
            for (const inventory of data) {
                if (!this.inventoriesSerials[inventory.part_master_id]) {
                    this.inventoriesSerials[inventory.part_master_id] = [];
                }
                if (inventory.serial) {
                    this.inventoriesSerials[inventory.part_master_id].push(inventory.serial);
                }
                this.availableSerials[inventory.part_master_id] = this.inventoriesSerials[inventory.part_master_id];
            }
        }
        this.changeDetectorRef.markForCheck();
        this.spinnerService.hide();
    }

    public async submit(): Promise<void> {
        await this.apiV3Service.request(Api.EMethod.Post,
             `partner/${PartnerService.partner.slug}/boxes/${this.modal.params.boxId}/attach_item`,
             this.formGroup.value
        );
        this.modal.response.next({name: "list"});
    }

    public close(): void {
        this.modal.response.next(null);
    }

    /**
     * Add serial chip
     * @param {MatChipInputEvent} event
     */
    public async addSerialChip(event: MatChipInputEvent): Promise<any> {
        this.spinnerService.show();
        const value: string = event.value ? event.value.trim() : null;

        if (event.input) {
            event.input.value = "";
            this.changeDetectorRef.markForCheck();
        }

        const inventoriesSerials = this.inventoriesSerials[this.formGroup.controls["item"].value]
            .map((item: any) => item);

        if (value) {
            const values: string[] = value.split(/[\s|\t|;|,]/);
            for (const val of values) {
                if (val
                    && !this.formGroup.controls["serials"].value.includes(val)
                    && inventoriesSerials.includes(val)
                ) {
                    if (!this.serials) {
                        this.serials = [];
                    }
                    this.serials.push(val);
                    this.formGroup.controls["serials"].setValue(this.serials);
                    this.formGroup.controls["qty"].setValue(this.serials.length);
                }
            }
        }
        this.spinnerService.hide();
        this.filterAvailableSerials();
        this.changeDetectorRef.markForCheck();
    }

    public removeSerialChip(serial: string): void {
        const index: number = this.serials.indexOf(serial);
        if (index >= 0) {
            this.serials.splice(index, 1);
            this.formGroup.controls["serials"].setValue(this.serials);
            this.formGroup.controls["qty"].setValue(this.serials.length);
        }
        this.filterAvailableSerials();
        this.changeDetectorRef.markForCheck();
    }

    public selectSerial(serial: string): void {
        if (this.formGroup.controls["serials"].value.includes(serial)) {
            return;
        }
        if (!this.serials) {
            this.serials = [];
        }
        this.serials.push(serial);
        this.formGroup.controls["serials"].setValue(this.serials);
        this.formGroup.controls["qty"].setValue(this.serials.length);
        this.filterAvailableSerials();
        this.changeDetectorRef.markForCheck();
    }

    private filterAvailableSerials(): void {
        this.availableSerials[this.formGroup.controls["item"].value] =
            this.inventoriesSerials[this.formGroup.controls["item"].value].filter((serial: any) => {
                return serial != null && !this.serials.includes(serial);
            });
    }

    public async ngOnInit(): Promise<void> {
        if (this.modal.params.locationId) {
            this.getItems(this.modal.params.locationId);
        };

        this.formGroup.controls["item"].valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
            const inventory = this.inventories.find(item => item.id === value);
            if (inventory?.serial) {
                this.formGroup.controls["serials"].enable();
                this.formGroup.controls["serials"].setValidators([Validators.required]);
                this.formGroup.controls["qty"].setValue(0);
                this.formGroup.controls["qty"].disable();
            } else {
                this.formGroup.controls["qty"].enable();
                this.formGroup.controls["qty"].setValidators([Validators.min(1)]);
                this.formGroup.controls["serials"].disable();
                this.formGroup.controls["serials"].clearValidators();
            }
            this.formGroup.controls["qty"].updateValueAndValidity();
            this.formGroup.controls["serials"].updateValueAndValidity();
            this.changeDetectorRef.markForCheck();
        });

        this.inventoriesSearch.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value: string): void => {
                if (value) {
                    this.inventoriesFiltered = this.inventories.filter((inventory: { item: string }): boolean =>
                        inventory.item.toLowerCase().indexOf(value.toLowerCase()) > -1);
                } else {
                    this.inventoriesFiltered = this.inventories;
                }
                this.changeDetectorRef.markForCheck();
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}