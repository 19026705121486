import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {FormBuilder, FormControl} from "@angular/forms";
import {Router} from "@angular/router";
import {Base} from "../../../../../../../../common/interfaces/base.interfaces";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../../common/interfaces/wizard.interface";
import {User} from "../../../../../../../../common/interfaces/user.interface";
import {Api, ApiService} from "../../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../../common/services/toast.service";
import {Modal, ModalService} from "../../../../../../../services/modal.service";
import {Warehouse} from "../../../../../../../../common/interfaces/warehouse.interface";
import {HelpersService} from "../../../../../../../../common/services/helpers.service";
import {
    BoxItemsListComponent,
    PalletItemsListComponent,
    WarehouseProceduresWizardSelectOrderItemModalComponent
} from "../../..";
import {SpinnerService} from "../../../../../../../../common/services/spinner.service";
import {PusherService} from "../../../../../../../../common/services/pusher.service";
import {PackageFormComponent} from "../../../../package";
import {ImageUploadService} from "../../../../../../../../common/services/image-upload.service";
import {SoundsService} from "../../../../../../../../common/services/sounds.service";
import {Api3Service} from "../../../../../../../../common/services/api3.service";
import {BoxStickerComponent} from "../../../../boxes";
import {ConfirmComponent} from "../../../../../../../../common/components/confirm/confirm.component";
import {WarehouseBufferScanFormComponent} from "../../common/buffer-scan/form.component";
import {ModalTableComponent} from "../../../../../../../../common/components/modal/modal-table.component";
import {takeUntil} from "rxjs";
import {OrderItemStickerComponent}
    from "src/modules/section/components/partner/components/order/item/sticker/sticker.component";


export interface IOutboundOrderLocation {
    matched: boolean;
    location: { id: number; location: string; warehouse_location_type_id: number };
    pallets: any[];
    boxes: any[];
    items: any[];
}

@Component({
    selector: "section-warehouse-procedures-wizard-outbound-scan",
    templateUrl: "scan.component.html",
    styleUrls: [
        "scan.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class WarehouseProceduresWizardOutboundScanComponent extends AbstractWizardStepComponent
    implements OnDestroy {

    private state: Base.IState;

    private warehouse_slug: string;

    private prevKey: string = null;

    public originalItemsData: any = null;

    public procedureType: string = null;

    public shipment: Warehouse.IShipment;

    @ViewChild("searchInputRef", {static: false})
    public searchInputRef: ElementRef;

    @ViewChild(ConfirmComponent, {static: false})
    public confirm: ConfirmComponent;

    public scannerKey: string;

    public partner: User.IPartner;

    public warehouse: Warehouse.IWarehouse;

    public searchInput: FormControl = new FormControl(null);

    public searchInputLabel: string = "Item, serial, box, pallet/ob";

    public selectedPackage: FormControl = new FormControl(null);

    public packages: any[] = [];

    public orderRef: string;

    public scannerOnly: boolean = false;

    public isViewOnly: boolean = false;

    public scanFeed: any[] = [];

    public checkedFeedItems: string[] = [];

    public locations: IOutboundOrderLocation[] = [];

    public outboundItemsSearch: FormControl = new FormControl(null);

    public showMatchedInList: FormControl = new FormControl(false);

    public showOnlyFailedScans: FormControl = new FormControl(false);

    public selectAll: FormControl = new FormControl(false);

    public totalToScan: any = {
        pallets: 0,
        boxes: 0,
        items: 0
    };

    public totalScanned: any = {
        pallets: 0,
        boxes: 0,
        items: 0
    };

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private modalService: ModalService,
        public soundsService: SoundsService,
        private spinnerService: SpinnerService,
        private pusherService: PusherService,
    ) {
        super(changeDetectorRef);
    }


    private connectToSockets(): void {

        if (!this.shipment) {
            return;
        }

        this.pusherService.echo.private("scan-feed-" + this.shipment.id)
            .listen(".App\\Events\\WarehouseBufferScanEvent", (data: any): void => {
                // console.warn("WarehouseBufferScanEvent", data);

                let update = false;
                for (const index in this.scanFeed) {
                    if (data.feed_id === this.scanFeed[index].feed_id) {
                        this.scanFeed[index] = data.data;
                        update = true;
                        break;
                    }
                }

                if (!update) {
                    this.addScan(data.data);
                }

                this.changeDetectorRef.markForCheck();

                this.getScanBuffer();
                // this.mapData(this.originalItemsData);
            });
    }

    private async getScanBuffer(): Promise<any> {
        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `${this.state.section}/scan/outbound/${this.shipment.id}`, {}, {});

        if (data) {
            this.scanFeed = data;
            this.changeDetectorRef.markForCheck();

            if (this.originalItemsData) {
                this.mapData(this.originalItemsData);
            }
            this.setFocus();
        }
    }

    private addScan(data: any): void {

        var newArray = this.scanFeed.slice();
        newArray.unshift(data);

        this.scanFeed = newArray;

        this.changeDetectorRef.markForCheck();
    }

    /**
     * Get package list
     */
    private async getPackages(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["warehouse_package", "shipment", "" + this.shipment.id]);
        this.spinnerService.hide();

        if (data) {
            this.packages = data;
            if (this.packages.length === 0) {
                this.newPackadge();
                this.soundsService.textToSpeech("Please create package");
            } else {
                this.selectedPackage.setValue(this.packages[0].id);
            }
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Get shipment
     */
    private async getShipment(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `${this.state.section}/shipments/${this.shipment.id}`, {}, {
                relations: [
                    "Partner", "Order:id,service_level_id"
                ]
            });

        if (data) {
            this.shipment = data;
            this.scannerOnly = this.shipment && this.shipment.partner
                ? this.shipment.partner.properties.disable_manual_scanning
                : false;
            this.changeDetectorRef.markForCheck();

            this.connectToSockets();
        }
        this.spinnerService.hide();
    }

    private async getItems(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `${this.state.section}/scan/outbound/${this.shipment.id}/items`);
        this.spinnerService.hide();

        if (data) {
            this.originalItemsData = data;
            this.mapData(this.originalItemsData);
            this.setFocus();
        }
    }

    private async getWarehouse(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["warehouse", "findBySlug", this.warehouse_slug]);
        if (data) {
            this.warehouse = data;
            this.showMatchedInList.setValue(
                this.warehouse.properties.default_show_fully_scanned_items ?? false
            );
        }
        this.spinnerService.hide();
    }

    private mapData(data: { pallets: any[], boxes: any[], items: any [] }): void {
        this.locations = [];

        this.totalToScan = {
            pallets: 0,
            boxes: 0,
            items: 0
        };

        const locations: any = {};

        this.totalToScan.pallets = data.pallets.length;

        for (let pallet of data.pallets) {
            pallet = {...pallet};

            pallet.matched = false;

            const location_id = pallet.warehouse_location?.id;

            if (!locations[location_id]) {
                locations[location_id] = {
                    pallets: [pallet],
                    boxes: [],
                    items: [],
                    location: pallet.warehouse_location,
                    matched: true,
                };
            } else {
                locations[location_id].pallets.push(pallet);
            }
        }

        this.totalToScan.boxes = data.boxes.length;

        for (let box of data.boxes) {
            box = {...box};

            box.matched = false;

            const location_id = box.warehouse_location?.id;

            if (!locations[location_id]) {
                locations[location_id] = {
                    pallets: [],
                    boxes: [box],
                    items: [],
                    location: box.warehouse_location,
                    matched: true,
                };
            } else {
                locations[location_id].boxes.push(box);
            }
        }

        for (let item of data.items) {
            item = {...item};

            item.matched = false;
            item.matched_qty = 0;
            this.totalToScan.items += item.quantity;

            const location_id = item.warehouse_location?.id;

            if (!locations[location_id]) {
                locations[location_id] = {
                    pallets: [],
                    boxes: [],
                    items: [item],
                    location: item.warehouse_location,
                    matched: true,
                };
            } else {
                locations[location_id].items.push(item);
            }
        }

        this.locations = Object.values(locations);

        this.changeDetectorRef.markForCheck();

        this.checkMatchingScans();
    }

    private checkMatchingScans(): void {

        this.totalScanned = {
            pallets: 0,
            boxes: 0,
            items: 0
        };

        if (!Object.keys(this.locations).length || !this.scanFeed) {
            return;
        }

        feedLoop: for (const scan of this.scanFeed) {
            if (scan.status !== "success") {
                continue;
            }
            for (const location of this.locations) {
                if (scan.type === "pallet") {
                    for (const pallet of location.pallets) {
                        if (pallet.id === scan.pallet_id) {
                            pallet.matched = true;
                            this.totalToScan.pallets--;
                            this.totalScanned.pallets++;
                            continue feedLoop;
                        }
                    }
                }

                if (scan.type === "box") {
                    for (const box of location.boxes) {
                        if (box.id === scan.box_id) {
                            box.matched = true;
                            this.totalToScan.boxes--;
                            this.totalScanned.boxes++;
                            continue feedLoop;
                        }
                    }
                }

                if (scan.type === "serial") {
                    for (const item of location.items) {
                        if (item.serial === scan.serial) {
                            item.matched_qty = 1;
                            this.totalToScan.items--;
                            this.totalScanned.items++;
                            continue feedLoop;
                        }
                    }
                }

                if (scan.type === "item") {
                    for (const item of location.items) {
                        if (item.serial) {
                            continue;
                        }

                        if (scan.part_master_id === item.part_master_id
                            && HelpersService.valOrNull(scan.configurations) === HelpersService
                                .valOrNull(item.configurations)
                            && HelpersService.valOrNull(scan.batch) === HelpersService.valOrNull(item.batch)
                            && item.quantity >= item.matched_qty + scan.quantity
                            && item.warehouse_location_id === scan.location_id) {

                            item.matched_qty += scan.quantity;
                            this.totalToScan.items -= scan.quantity;
                            this.totalScanned.items += scan.quantity;

                            continue feedLoop;
                        }
                    }
                }
            }
        }

        for (const location of this.locations) {

            for (const pallet of location.pallets) {
                if (!pallet.matched) {
                    location.matched = false;
                }
            }

            for (const box of location.boxes) {
                if (!box.matched) {
                    location.matched = false;
                }
            }

            for (const item of location.items) {
                if (item.matched_qty === item.quantity) {
                    item.matched = true;
                } else {
                    location.matched = false;
                }

            }
        }

        this.changeDetectorRef.markForCheck();
    }

    private outboundByOrderItem(order_item: Warehouse.IInventoryAllocation, feed_id: string): void {

        const items: any[] = [];

        for (const location of this.locations) {

            let insert_item: any = null;

            for (const item of location.items) {
                if (item.matched) {
                    continue;
                }

                if (item.part_master_id === order_item.part_master_id) {

                    let serial_required =  order_item.part_master.required_serial_wh_outbound;

                    if (this.shipment.order.service_level_id == 200
                        && this.shipment.partner_id == 31
                        && item.item.startsWith("SIMC")
                    ) {
                        serial_required = false; //ISSUE-6543
                    }


                    if (!insert_item) {
                        insert_item = {
                            id: `${item.id}-${location.location?.id}`,
                            part_master_id: item.part_master_id,
                            item: item.item,
                            serials: [],
                            serial_required,
                            has_serial: order_item.part_master.serial,
                            batch: item.batch,
                            has_batch: order_item.part_master.batch,
                            has_revision: order_item.part_master.revision,
                            revision_required: order_item.part_master.required_revision_inbound,
                            rev: item.rev,
                            lot: item.lot,
                            warehouse_location: {
                                location: location.location?.location,
                                id: location.location?.id
                            },
                            location_id: location.location?.id,
                            partner_id: order_item.partner_id,
                            partner: order_item.partner,
                            quantity: item.quantity - item.matched_qty,
                            order_id: order_item.order_id,
                            type: "outbound",
                            shipment_id: this.shipment.id,
                            warehouse_order_id: this.state.params.order,
                            package_id: this.selectedPackage.value,
                            feed_id,
                        };
                    } else {
                        insert_item.quantity += item.quantity - item.matched_qty;
                    }

                }
            }

            if (insert_item) {
                items.push(insert_item);
            }
        }

        if (items.length === 1) {
            const item: any = items[0];
            this.addTransactionFromItem(item);
        } else if (items.length > 1) {
            this.selectOrderItems(items, items[0].partner);
        } else {
            const message: string = "Item not found or must be scanned as a box or pallet";
            this.soundsService.textToSpeech(message);
            this.toastService.show(message, "warning");
        }
    }

    private async addTransactionFromItem(item: any): Promise<any> {

        const response: Modal.IResponse = await this.modalService.open(WarehouseBufferScanFormComponent,
            {
                data: item,
                modalWidth: 600,
                modalHeight: 500,
                type: "outbound",
                state: this.state,
                scannerKey: this.scannerKey,
                partner: item.partner,
                warehouse_slug: this.warehouse_slug,
                location_name: item.warehouse_location.location
            });

        if (response && response.value) {

            this.addScan({
                feed_id: item.feed_id,
                item: item.item,
                status: "pending",
                quantity: response.value.quantity,
                type: "item"
            });

            for (const location of this.locations) {
                for (const item of location.items) {
                    if (item.order_item_id === response.value.order_item_id) {
                        item.quantity -= response.value.quantity;
                    }
                }
            }

            // this.getScanBuffer();

            this.toastService.show("Item scanned", "success");
            this.soundsService.textToSpeech("Item scanned");
        }

        this.searchInput.reset();
        this.setFocus();
    }

    private setFocus(): void {
        setTimeout(() => {
            if (this.searchInputRef) {
                this.searchInputRef.nativeElement.focus();
            }
        }, 1000);
    }

    private async selectOrderItems(items: any[], partner: User.IPartner): Promise<any> {
        const response: Modal.IResponse = await this.modalService
            .open(WarehouseProceduresWizardSelectOrderItemModalComponent, {
                items,
                partner,
                type: "outbound"
            });
        if (response && response.value) {
            const item: any = response.value;
            this.addTransactionFromItem(item);
        }
    }

    private async exportBoxPackList(type: string): Promise<any> {
        this.spinnerService.show();
        const {data, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["warehouse_location", "shipment", "" + this.shipment.id], {}, {
                export: type
            });
        if (data && data.length) {
            window.open(data[0], "_blank");
        }

        if (message) {
            this.toastService.show(message, "success");
        }
        this.spinnerService.hide();
    }

    private async exportUniversalPackList(type: string): Promise<any> {
        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["inventory_allocation", "by_shipment", "" + this.shipment.id], {}, {
                export: type
            });

        this.spinnerService.hide();

        if (code === 200) {
            this.toastService.show(message, "success");
        }
    }

    public selectPackage(id: number): void {
        this.selectedPackage.setValue(id);
        this.changeDetectorRef.markForCheck();
        this.soundsService.textToSpeech("Package selected");
        this.toastService.show("Package selected: " + this.selectedPackageName(id), "success");
    }

    public selectedPackageName(id: number): string {
        const selected: any = this.packages.find((pack: { id: number }) => {
            return pack.id === id;
        });
        if (selected) {
            return `${selected.name} (${selected.warehouse_package_type?.name}) `;
        }
        return "";
    };

    /**
     * Show items in the box
     * @param box
     */
    public async openBox(box: any): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(BoxItemsListComponent,
            {
                box,
                modalWidth: 1000,
                itemsType: "allocations"
            });
    }

    public async openPallet(pallet: any): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(PalletItemsListComponent,
            {
                pallet,
                modalWidth: 1000,
                itemsType: "boxes"
            });
    }

    /**
     * Print box sticker
     * @param box
     */
    public async printBoxSticker(box: any): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(BoxStickerComponent, {
            box,
            orderRef: this.orderRef
        });
    }

    public async showAllocations(allocations: any[]): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(ModalTableComponent,
            {
                title: "Item allocations",
                table: {
                    table_id: "sadzf7sygw4v",
                    columns: [
                        {
                            data: "item",
                            title: "Item",
                            sortable: false
                        },
                        {
                            data: "batch",
                            title: "Batch",
                            sortable: false
                        },
                        {
                            data: "serial",
                            title: "Serial",
                            sortable: false
                        },
                        {
                            data: "configurations",
                            title: "Configurations",
                            sortable: false
                        },
                        {
                            data: "quantity",
                            title: "Quantity",
                            sortable: false
                        },
                        {
                            data: "box.ref",
                            title: "Box",
                            sortable: false
                        },
                    ],
                    table_data: allocations
                }
            });
    }

    /**
     * Listen scanner activation key event
     * @param event
     */
    public onKeydown(event: any): void {
        if (this.prevKey !== "Control" && this.prevKey !== "Shift") {
            if (event.key === "Enter" || event.key === "Tab") {
                this.find();
            }
        }

        if ((this.prevKey === "Control" || this.prevKey === "Shift" || this.prevKey === "Alt")
            && event.key === "Enter") {
            this.searchInput.setValue(this.searchInput.value + ",");
        }

        this.prevKey = event.key;
    }

    private clearValue(original:string): string{
        return original.trim().replace(/^_+|_+$/g, "");
    }

    /**
     * Find box
     */
    public async find(): Promise<any> {
        if (!this.searchInput.value) {
            return;
        }

        this.spinnerService.show();

        const feed_id: string = this.shipment.id + "-" + HelpersService.randomString();

        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Post,
            `/${this.state.section}/scan/outbound`, {
                shipment_id: this.shipment.id,
                value: this.clearValue(this.searchInput.value),
                package_id: this.selectedPackage.value,
                feed_id,

            }, {});

        this.spinnerService.hide();

        if (data) {
            switch (data.status) {
                case "error":
                    this.toastService.show(data.message, "error");
                    this.soundsService.textToSpeech(data.message);
                    this.addScan({
                        feed_id,
                        item: this.searchInput.value,
                        status: "error",
                        message: data.message,
                        quantity: 0,
                        type: "item"
                    });

                    break;
                case "pallet_found":
                    this.toastService.show(data.message, "success");
                    this.soundsService.textToSpeech(data.message);

                    this.addScan({
                        feed_id,
                        pallet: this.searchInput.value,
                        status: "pending",
                        quantity: 0,
                        type: "pallet"
                    });
                    break;

                case "box_found":
                    this.toastService.show(data.message, "success");
                    this.soundsService.textToSpeech(data.message);

                    this.addScan({
                        feed_id,
                        box: this.searchInput.value,
                        status: "pending",
                        quantity: 0,
                        type: "box"
                    });
                    break;

                case "serial_found":
                    this.toastService.show(data.message, "success");
                    this.soundsService.textToSpeech(data.message);

                    this.addScan({
                        feed_id,
                        serial: this.searchInput.value,
                        status: "pending",
                        quantity: 1,
                        type: "serial"
                    });
                    break;

                case "order_item_found":
                    this.outboundByOrderItem(data.data, feed_id);
                    break;
                default:
                    break;
            }
        }

        this.searchInput.reset();
        this.changeDetectorRef.markForCheck();
        this.setFocus();
    }

    /**
     * Create new package
     */
    public async newPackadge(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(PackageFormComponent,
            {
                shipmentId: this.shipment.id,
                warehouse: this.shipment.warehouse
            });

        if (response) {
            this.getPackages();
        }
    }

    public async editPackage(pack: any): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(PackageFormComponent, {
            package: pack,
            shipment: this.shipment
        });

        if (response) {
            this.getPackages();
        }
    }

    public async deleteScans(ids: string[]): Promise<any> {
        if (!await this.confirm.confirm("Delete scanned item(s)?")) {
            return;
        }

        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Delete,
            `${this.state.section}/scan/outbound/${this.shipment.id}`, {ids});
        this.spinnerService.hide();

        this.checkedFeedItems = [];
        this.getScanBuffer();
    }

    public selectScan(id: string): void {
        if (this.checkedFeedItems.includes(id)) {
            return;
        }
        this.checkedFeedItems.push(id);
    }

    public deSelectScan(id: string): void {
        const index: number = this.checkedFeedItems.indexOf(id);
        this.checkedFeedItems.splice(index, 1);
        this.selectAll.setValue(false, {emitEvent: false});
    }

    public async exportPackList(type: string): Promise<any> {
        if (this.shipment.partner_id === 14) {
            await this.exportBoxPackList(type);
        } else {
            await this.exportUniversalPackList(type);
        }
    }

    public async completeScanning(): Promise<any> {
        const has_invalid = this.scanFeed.find((scan) => scan.status === "error");

        if (has_invalid) {
            if (!await this.confirm.confirm("Make transactions? All invalid scans will be ignored")) {
                return;
            }
        }

        this.spinnerService.show();

        const {code, message}: Api.IResponse = await this.api3Service.request(Api.EMethod.Post,
            `${this.state.section}/scan/outbound/${this.shipment.id}/convert`, {}, {});
        this.spinnerService.hide();

        if (code === 200) {
            this.toastService.show(message, "success");
            this.result.emit({
                action: "result",
                value: true,
                goToNextStep: true
            });
        }
    }

    public async printSticker(item: any): Promise<void> {
        this.modalService.open(OrderItemStickerComponent, {
            item,
            serials: [item.serial]
        });
    }

    public async openLocationCell(location_id: number): Promise<any> {
        if (!await this.confirm.confirm("Open locker cell?")) {
            return;
        }

        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.api3Service.get(
            `${this.state.section}/locations/${location_id}/open-cell`);
        this.spinnerService.hide();

        if (code === 200) {
            this.toastService.show(message, "success");
        }

    }

    /**
     * Initialize step
     * @param data
     * @returns {Promise<any>}
     */
    public async init(data: Wizard.IData): Promise<any> {
        this.procedureType = data.procedureType;
        this.state = data.state;
        this.scannerKey = data.scannerKey;
        this.shipment = data.shipment;
        this.orderRef = data.orderRef;
        this.isViewOnly = data.isViewOnly;
        this.warehouse_slug = this.state.section_slug;
        this.changeDetectorRef.markForCheck();
        this.getShipment();
        this.getPackages();
        this.getItems();
        this.getScanBuffer();
        this.getWarehouse();

        if (this.selectedPackage.value) {
            this.setFocus();
        }
        if (this.isViewOnly) {
            this.searchInput.disable();
        }

        this.result.emit({
            action: "result",
            value: true
        });

        this.selectAll.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: boolean) => {
            for (const feed_item of this.scanFeed) {
                if (value) {
                    if (this.showOnlyFailedScans.value && feed_item.status !== "error") {
                        continue;
                    }
                    this.selectScan(feed_item.feed_id);
                } else {
                    this.deSelectScan(feed_item.feed_id);
                }
            }
            this.changeDetectorRef.markForCheck();
        });
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        if (this.shipment) {
            this.pusherService.echo.leave("scan-feed-" + this.shipment.id);
        }
    }

}
