<h1 class="container-heading" *ngIf="modal">{{ modal.params.data ? "Edit" : "Add" }} location</h1>

<mat-card>
    <mat-card-content>
        <form [formGroup]="locationFrom" (submit)="submit()" class="add-form">
            <div class="group flex">
                <mat-form-field class="half">
                    <mat-select formControlName="warehouse_location_type_id" placeholder="Type">
                        <mat-option *ngFor="let type of locationTypes" [value]="type.value">
                            {{type.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="half">
                    <mat-label>Location</mat-label>
                    <input matInput type="text" formControlName="location" #locationInputRef>
                </mat-form-field>
            </div>
            <div class="actions">
                <button type="submit" mat-raised-button color="primary" [disabled]="!locationFrom.valid">
                    Submit
                </button>

                <button type="button" mat-raised-button
                        (click)="locationFrom.reset(); reset.emit(true); modal ? modal.response.emit(null): null">
                    Cancel
                </button>
            </div>
        </form>
    </mat-card-content>
</mat-card>
