import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {ReplaySubject} from "rxjs";
import {take, takeUntil} from "rxjs";
import {StorageService} from "../../../../../../common/services/storage.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {UserService} from "../../../../../../common/services/user.service";
import {Router} from "@angular/router";
import * as Quill from "quill";
import {environment} from "../../../../../../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {FileUploadComponent} from "../../../../../../common/components/file-upload/file-upload.component";
import {UrlEntryComponent} from "../../requests/url-entry/url-entry.component";
import {SpinnerService} from "../../../../../../common/services/spinner.service";

const Delta: Quill.DeltaStatic = Quill.import("delta");

@Component({
    selector: "section-announcements-form",
    templateUrl: "form.component.html",
    styleUrls: [
        "form.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class AnnouncementFormComponent implements Base.IComponent, OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);
    /**
     * Form group / group with controls
     * @type {FormGroup}
     */
    public formGroup: FormGroup = new FormGroup({
        title: new FormControl(null, [Validators.required]),
        description: new FormControl(null, [Validators.required]),
        announcement_kind_id: new FormControl(null, [Validators.required]),
        order_remark_type_id: new FormControl(null, [Validators.required]),
        team_id: new FormControl(null),
        url: new FormControl(null)
    });

    public editorInitialValue: string = null;

    public readonly state: Base.IState;

    public modal: Modal.IModal;

    public action: string = "add";

    public teams: { name: string, value: any }[] = [];

    public kinds: { name: string, value: any }[] = [];

    public remarkTypes: { name: string, value: any }[] = [];

    public selectTeamsSearch: FormControl = new FormControl(null);

    public teamsFiltered: ReplaySubject<any> = new ReplaySubject<any>(1);

    public uploadedFile: { name: string, url: string }[] = [];

    public editorInstance: any;


    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private toastService: ToastService,
        private modalService: ModalService,
        private storageService: StorageService,
        private userService: UserService,
        private router: Router,
        private http: HttpClient,
        private spinnerService: SpinnerService
    ) {
    }

    /**
     * Prepare form data
     */
    private prepareData(): void {
        let data: any;
        if (this.state && this.state.params) {
            data = {...this.state.params, ...this.storageService.get("requestParams")};
            this.storageService.set("requestParams", null);
        } else if (this.modal && this.modal.params) {
            data = this.modal.params;
        }

        if (data.url) {
            this.formGroup.get("url").setValue(data.url);
        }

        if (data.title) {
            this.formGroup.get("title").setValue(data.title);
        }

        if (data.team_id) {
            this.formGroup.get("team_id").setValue(data.team_id);
        }

        if (data.description) {
            this.editorInitialValue = data.description;
        }
    }

    /**
     * Get list of kinds
     * @returns {Promise<any>}
     */
    private async getKinds(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["request", "kind"], {}, {data_structure: "select"});
        if (data) {
            this.kinds = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get remark types
     * @returns {Promise<any>}
     */
    private async getRemarkTypes(): Promise<any> {
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["remark", "order", "types"]);
        if (data) {
            this.remarkTypes = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Get users list
     * @returns {Promise<any>}
     */
    private async getTeams(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["team"], {}, {data_structure: "select"});
        if (data) {
            this.teams = data;
            this.teamsFiltered.next(this.teams);
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    public updateForm(): void {
        this.formGroup.patchValue({description: this.editorInstance.root.innerHTML});
        this.formGroup.updateValueAndValidity();
    }

    public getEditorInstance(editorInstance: any): void {
        this.editorInstance = editorInstance;
        const toolbar: any = editorInstance.getModule("toolbar");
        const self: any = this;
        toolbar.addHandler("image", function (): void {
            let fileInput: any = toolbar.container.querySelector("input");
            if (fileInput === null) {
                fileInput = document.createElement("input");
                fileInput.setAttribute("type", "file");
                fileInput.setAttribute(
                    "accept",
                    "image/png, image/gif, image/jpeg, image/bmp, image/x-icon"
                );
                fileInput.classList.add("ql-image");
                fileInput.addEventListener("change", (): void => {
                    if (fileInput.files != null && fileInput.files[0] != null) {
                        const formData: FormData = new FormData();
                        formData.append("file", fileInput.files[0], fileInput.files[0].name);
                        formData.set("upload_preset", environment.cloudinary.image_upload_preset);
                        let headers: HttpHeaders = new HttpHeaders();
                        headers = headers.set("X-Requested-With", "XMLHttpRequest");
                        headers = headers.set("Accept", "application/json");
                        self.http.request("post",
                            self.apiService.getUrl("https://api.cloudinary.com/v1_1/"
                                + environment.cloudinary.cloudName + "/upload"),
                            {
                                headers: headers,
                                body: formData
                            })
                            .pipe(take(1))
                            .subscribe((data: any): void => {
                                const fileUrl: any = data.secure_url || data.url;
                                const range: any = this.quill.getSelection(true);
                                editorInstance.updateContents(
                                    new Delta()
                                        .retain(range.index)
                                        .delete(range.length)
                                        .insert(
                                            {
                                                image: fileUrl
                                            },
                                            {
                                                width: "351",
                                                nameClass: "else",
                                                alt: "no working",
                                                offset: 3,
                                            }
                                        ));
                            });
                    }
                });
                this.container.appendChild(fileInput);
            }
            fileInput.click();
        });
    }

    public async uploadFile(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(FileUploadComponent, {
            url: ["user", "file"],
            accept: [
                "gif", "png", "jpg", "jpeg", "tiff", "doc",
                "docx", "xls", "xlsx", "csv", "ppt", "pptx", "msg", "txt", "pdf", "xlsb"
            ]
        });
        if (response && response.value && response.value.data && response.value.data.path) {
            const uploaded: any = {name: response.value.data.name, url: response.value.data.path};
            this.uploadedFile.push(uploaded);
            this.changeDetectorRef.markForCheck();
        }
    }

    public deleteFile(index: number): void {
        this.uploadedFile.splice(index, 1);
    }

    public async insertImage(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(UrlEntryComponent, {
            state: this.state,
            title: "Insert image url"
        });
        if (response && response.name === "value" && response.value !== "") {
            const fileUrl: any = response.value;
            const range: any = this.editorInstance.getSelection(true);
            this.editorInstance.updateContents(
                new Delta()
                    .retain(range.index)
                    .delete(range.length)
                    .insert(
                        {
                            image: fileUrl
                        },
                        {
                            width: "351",
                            nameClass: "else",
                            offset: 3,
                        }
                    ));
        }
    }

    /**
     * Submit form
     */
    public async submit(): Promise<any> {
        this.spinnerService.show();

        const body: any = {...this.formGroup.value};

        body.files = this.uploadedFile;

        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["announcement"], body);
        if (response && response.type as string === "success") {
            this.toastService.show(response.message, response.type as string);
            if (this.modal) {
                this.modal.response.emit({
                    name: "submit"
                });
            } else {
                this.router.navigate([
                    this.state.section,
                    this.state.component
                ]);
            }
        }
        this.spinnerService.hide();
    }

    /**
     * Close modal
     */
    public close(): void {
        this.modal.response.emit();
    }

    public ngOnInit(): void {
        this.getTeams();
        this.getKinds();
        this.getRemarkTypes();
        this.selectTeamsSearch.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value: string): void => {
                if (value) {
                    this.teamsFiltered.next(
                        this.teams.filter((team: { name: string }): boolean =>
                            team.name.toLowerCase().indexOf(value) > -1)
                    );
                } else {
                    this.teamsFiltered.next(this.teams);
                }
            });


        this.prepareData();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "support-announcements",
            actions: {
                "add": ["add_announcements"]
            }
        };
    }
}
