import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input, OnDestroy,
    Output,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {Modal, ModalService} from "../../../../../../services/modal.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {UserService} from "../../../../../../../common/services/user.service";
import {Order} from "../../../../../../../common/interfaces/order.interface";
import {HelpersService} from "../../../../../../../common/services/helpers.service";
import {CommonFormComponent} from "../../../../../../../common/components/form";
import {User} from "../../../../../../../common/interfaces/user.interface";
import {Table} from "../../../../../../../common/components/table/table.component";
import {
    OrderItemComponent,
    OrderItemsInboundComponent,
    OrderItemsInfoComponent,
    OrderItemsOutboundComponent
} from "../../index";
import {Router} from "@angular/router";
import {ConfirmComponent} from "../../../../../../../common/components/confirm/confirm.component";
import {CommonPartMasterViewComponent} from "../../../../../common/components/part-master";
import {Form} from "../../../../../../../common/interfaces/form.interface";
import {PartnerPartMasterService} from "../../../../services/part-master.service";
import ISelectOption = Form.ISelectOption;
import {AppStateService} from "../../../../../../../common/services/app-state.service";
import {PusherService} from "../../../../../../../common/services/pusher.service";

@Component({
    selector: "section-order-view-items",
    templateUrl: "items.component.html",
    styleUrls: [
        "items.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})

export class OrderItemsComponent implements OnDestroy {


    private findBySlug: { inbound: boolean, outbound: boolean } = {inbound: false, outbound: false};

    private refreshDelay: any = null;

    public partmasters: any[] = [];

    public partner: User.IPartner;

    public canInbound: boolean = false;
    public canOutbound: boolean = false;

    public stockMode: string = "free";
    public autoAllocate: string = "free";

    @Input()
    public state: Base.IState;

    @Input()
    public set order(orderData: Order.IOrderData) {
        if (orderData) {
            this.orderData = orderData;

            if (orderData.partner) {
                this.partner = orderData.partner;
            }

            if (orderData.service_level && orderData.service_level.order_type) {
                this.canInbound = orderData.service_level.order_type.can_inbound || false;
                this.canOutbound = orderData.service_level.order_type.can_outbound || false;
            }
            setTimeout(() => {
                this.getPrtmasters();
            }, 1000);

            this.connectToSockets();
        }
    }

    @Input()
    public transferDir: {
        inbound: { hub_id: string },
        outbound: { hub_id: string }
    };

    public orderData: Order.IOrderData;

    @Output()
    public itemsUpdate: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public canFindBySlug: EventEmitter<any> = new EventEmitter<boolean>();

    @ViewChild(ConfirmComponent, {static: true})
    public confirmRef: ConfirmComponent;

    @ViewChild("outboundTable", {static: false})
    public outboundTable: OrderItemsOutboundComponent;

    @ViewChild("inboundTable", {static: false})
    public inboundTable: OrderItemsInboundComponent;

    public createOutboundShipment: boolean = false;
    public createInboundShipment: boolean = false;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private modalService: ModalService,
        private toastService: ToastService,
        private router: Router,
        private partMasterService: PartnerPartMasterService,
        public userService: UserService,
        public pusherService: PusherService,
    ) {
    }

    private connectToSockets(): void {

        if (!this.orderData) {
            return;
        }

        this.pusherService.echo.private("broadcast-partner-" + AppStateService.getState().section_slug)
            .listen(".App\\Events\\AllocationStartEvent", (data: any): void => {
                console.warn("AllocationStartEvent", data);

                if (data.order_id == this.orderData.id) {
                    if (this.refreshDelay) {
                        return;
                    }
                    this.refreshDelay = setTimeout(() => {
                        this.updateTables(true);
                        this.refreshDelay = null;
                    }, 5000);
                }
            })
            .listen(".App\\Events\\AllocationFinishEvent", (data: any): void => {
                console.warn("AllocationFinishEvent", data);

                if (data.order_id == this.orderData.id) {
                    if (this.refreshDelay) {
                        return;
                    }
                    this.refreshDelay = setTimeout(() => {
                        this.updateTables(true);
                        this.refreshDelay = null;
                    }, 5000);
                }
            });
    }

    /**
     * Get partmasters
     * @returns {Promise<any>}
     */
    private async getPrtmasters(): Promise<any> {

        const {data}: Api.IResponse = await this.partMasterService.getPartMasters();

        if (data && data.length) {
            this.partmasters = data.map((part: any): ISelectOption => {
                return {
                    name: part.item + " - " + part.description + (!part.is_active ? " (Inactive)" : ""),
                    value: part.id,
                    disabled: !part.is_active
                };
            });

        }
    }

    private updateTables(silent = false): void {
        if (!silent) {
            this.itemsUpdate.emit(true);
        }
        if (this.inboundTable) {
            this.inboundTable.reload();
        }

        if (this.outboundTable) {
            this.outboundTable.reload();
        }
    }

    private addItem(type: string): void {
        this.modalService.open(OrderItemComponent, {
            action: "add",
            serviceLevel: this.orderData.service_level,
            partmasters: this.partmasters,
            orderRef: this.orderData.ref,
            order_id: this.orderData.id,
            shipToAddress: this.orderData.main_address ? this.orderData.main_address.id : null,
            lineType: type,
            state: this.state,
            modalWidth: type === "inbound" ? "600px" : "95%",
            modalHeight: type === "inbound" ? "auto" : "90%",
            transferDir: this.transferDir
        }).then((response: Modal.IResponse): void => {
            // console.warn("add", response, type);
            if (response) {
                this.updateTables();
            }
        });
    }

    private editItem(action: Table.Action.IResult, type: string): void {
        this.modalService.open(OrderItemComponent, {
            action: "edit",
            serviceLevel: this.orderData.service_level,
            data: action.data,
            partmasters: this.partmasters,
            shipToAddress: this.orderData.main_address ? this.orderData.main_address.id : null,
            lineType: type,
            state: this.state,
            orderRef: this.orderData.ref,
            order_id: this.orderData.id,
            modalWidth: type === "inbound" ? "600px" : "95%",
            modalHeight: type === "inbound" ? "auto" : "90%",
            transferDir: this.transferDir
        }).then((response: Modal.IResponse): void => {
            // console.warn("edit", response, type);
            this.updateTables();
        });
    }


    public async handleItemAction(action: Table.Action.IResult, type: string): Promise<any> {
        if (!action) {
            this.addItem(type);
            return;
        }

        let response: any;

        switch (action.name) {
            case "edit":
                this.editItem(action, type);
                break;
            case "delete":
                if (!await this.confirmRef.confirm(`Do you want to delete item "${action.data.item}?"`)) {
                    return;
                }
                const params = {
                    order_item_id: action.data.id,
                    use_sync: this.orderData.service_level.properties?.sync_items_lines ?? false
                };

                if (this.orderData.service_level.order_type.slug === "transfer"
                    && !this.orderData.service_level.properties?.sync_items_lines) {
                    const confirmSync = await this.confirmRef.confirm("Do you want sync lines?");
                    if (confirmSync) {
                        params.use_sync = true;
                    }
                }

                response = await
                    this.apiService.request(Api.EMethod.Delete, ["order_item"], [params]);
                if (response) {
                    this.updateTables();
                    this.toastService.show(response.message, <string>response.type);
                }
                break;
            case "shipment":
                this.router.navigate([
                    this.state.section, "shipments", "add", "order_id", "" + this.orderData.id, "type", type
                ]);
                break;
            case "item":
                await this.modalService.open(CommonPartMasterViewComponent, {
                    part_master_id: action.data.part_master_id,
                    add: false,
                    modalWidth: 900
                });

                break;
            case "transactions":
            case "allocations":
            case "box_allocations":
            case "pallet_allocations":
                await this.modalService.open(OrderItemsInfoComponent, {
                    data: action.data,
                    state: this.state,
                    order: this.orderData,
                    partner: this.partner,
                    modalWidth: "80%",
                    modalHeight: "75%",
                    openTab: action.name
                });

                this.updateTables(true);
                break;

            case "update_statuses":
                this.updateTables();
                break;

            default:
                break;
        }
    }

    public async report(): Promise<any> {
        await this.modalService.open(CommonFormComponent, {
            configUrl: ["report", "order-items"],
            submitUrl: ["report", "order-items"],
            values: {
                orders: [
                    {
                        value: this.orderData.id,
                        name: this.orderData.ref
                    }
                ]
            },
            asyncKey: HelpersService.randomString()
        });
    }

    public onCanFindBySlug($event: boolean, type: string): void {
        this.findBySlug[type] = $event;
        if (this.findBySlug.inbound && this.findBySlug.outbound) {
            this.canFindBySlug.emit(true);
        }
    }

    public ngOnDestroy(): void {
        this.pusherService.echo.private("broadcast-partner-" + AppStateService.getState().section_slug)
            .stopListening(".App\\Events\\AllocationStartEvent")
            .stopListening(".App\\Events\\AllocationFinishEvent");

        if (this.refreshDelay) {
            clearTimeout(this.refreshDelay);
        }
    }
}
