<h1 class="container-heading">{{action | ucfirst}} Document</h1>

<mat-card>
    <mat-card-content>

        <form *ngIf="formGroup" [formGroup]="formGroup"
              (submit)="submit()">

            <div class="group flex">

                <mat-form-field class="full">
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="name" required>
                </mat-form-field>

                <mat-form-field class="full">
                    <mat-label>Type</mat-label>
<mat-select  [(value)]="selectedType">
                        <mat-option [value]="'article'">
                            Article
                        </mat-option>
                        <mat-option [value]="'document'">
                            Document
                        </mat-option>
                        <mat-option [value]="'link'">
                            Link
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="full" *ngIf="formGroup.get('article') && selectedType === 'article'">
                    <quill-editor [placeholder]="'Article*'"
                                  (onEditorCreated)="getEditorInstance($event)" (onContentChanged)="updateForm()">
                        <div quill-editor-toolbar>
                            <span class="ql-formats">
                              <select class="ql-font">
                                <option selected></option>
                                <option value="serif"></option>
                                <option value="monospace"></option>
                              </select>
                              <select class="ql-size">
                                <option value="small"></option>
                                <option selected></option>
                                <option value="large"></option>
                                <option value="huge"></option>
                              </select>
                            </span>
                            <span class="ql-formats">
                              <button class="ql-bold" [title]="'Bold'"></button>
                            </span>
                            <span class="ql-formats">
                              <button class="ql-italic" [title]="'Italic'"></button>
                            </span>
                            <span class="ql-formats">
                              <button class="ql-underline" [title]="'Underline'"></button>
                            </span>
                            <span class="ql-formats">
                              <button class="ql-strike" [title]="'Strike'"></button>
                            </span>
                            <span class="ql-formats">
                                <select class="ql-align" ng-attr-title="Aligment">
                                    <option selected></option>
                                    <option value="center"></option>
                                    <option value="right"></option>
                                    <option value="justify"></option>
                                </select>
                            </span>
                            <span class="ql-formats">
                              <button class="ql-image"></button>
                            </span>
                            <span class="ql-formats">
                                <mat-icon matTooltip="Paste image from url" class="ql-button" (click)="insertImage()">insert_link</mat-icon>
                            </span>
                        </div>
                    </quill-editor>
                </div>
                <mat-form-field class="full" *ngIf="selectedType === 'link'">
                    <mat-label>Insert link here...</mat-label>
<input matInput formControlName="link" >
                </mat-form-field>
                <button type="button" mat-raised-button class="full"
                        *ngIf="uploadedFile.length === 0 && selectedType === 'document'"
                        (click)="uploadFile()">
                    Add document
                </button>
                <div class="full">
                    <ng-template ngFor let-file let-index="index" [ngForOf]="uploadedFile">
                        {{file.name}}
                        <button mat-icon-button matTooltip="Delete" (click)="deleteFile(index)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </ng-template>
                </div>

                <div class="half" *ngIf="modal.params.state.section_type === 'warehouse'">
                    <mat-checkbox formControlName="is_visible_partners">Is Visible for partners</mat-checkbox>
                </div>

                <div class="half" *ngIf="modal.params.state.section_type === 'partner'">
                    <mat-checkbox formControlName="is_visible_warehouses">Is Visible for warehouses</mat-checkbox>
                </div>

                <div class="half" *ngIf="isShowClientCanSeeCheckbox">
                    <mat-checkbox formControlName="is_public">Client can see</mat-checkbox>
                </div>

                <div *ngIf="modal.params.state.section_type === 'admin'">
                    <mat-form-field class="full">
                        <mat-label>Partners</mat-label>
<mat-select  multiple formControlName="visible_for_partners">
                            <mat-option>
                                <ngx-mat-select-search
                                        [formControl]="partnersSearch"
                                        placeholderLabel="Partner Search"
                                        noEntriesFoundLabel="no partners found">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let partner of filteredPartners" [value]="partner.id">
                                {{partner.display_name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="full">
                        <mat-label>Three PLs</mat-label>
<mat-select  multiple formControlName="visible_for_threepls">
                            <mat-option>
                                <ngx-mat-select-search
                                        [formControl]="threeplsSearch"
                                        placeholderLabel="Threepl Search"
                                        noEntriesFoundLabel="no threepls found">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let threeps of filteredThreepls" [value]="threeps.id">
                                {{threeps.display_name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

            </div>

            <div class="actions">
                <button mat-raised-button color="primary" type="submit" class="main" [disabled]="!formGroup.valid">
                    {{modal.params.id ? "Edit" : "Add"}}
                </button>
                <button mat-button type="button" (click)="close()">Cancel</button>
            </div>

        </form>

    </mat-card-content>
</mat-card>
