<mat-card>

    <h1>Select serial number to update</h1>

    <form [formGroup]="form" (submit)="submit()">
        <div class="group flex">
            <mat-form-field class="half">
                <mat-label>Serial</mat-label>
                <input matInput
                       required
                       [formControl]="search"
                       [matAutocomplete]="auto">
                <span matSuffix>
                    <mat-icon *ngIf="validSerial" class="text-success">check</mat-icon>
                </span>
            </mat-form-field>


            <mat-form-field class="half">
                <mat-label>New Configuration</mat-label>
                <input matInput formControlName="configurations">
            </mat-form-field>

            <mat-form-field class="full">
                <mat-label>Remark</mat-label>
<textarea row="5" matInput  required formControlName="remark"></textarea>
            </mat-form-field>

        </div>
        <div class="actions">
            <button type="submit" mat-raised-button color="accent" [disabled]="!form.valid">
                Update
            </button>
        </div>
    </form>

</mat-card>


<mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)" class="mat-elevation-z2">
    <mat-option *ngFor="let option of options | async" [value]="option">
        {{ option.item }} - {{option.serial}}
    </mat-option>
</mat-autocomplete>
