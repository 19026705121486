<mat-card>

    <h1 class="color-accent">Select locations</h1>

    <form [formGroup]="transferForm">
        <div class="group flex">

            <mat-form-field class="full">
                <mat-label>Partner</mat-label>
<mat-select [formControl]="partnerSelect" >
                    <mat-option *ngFor="let partner of partners" [value]="partner">
                        {{partner.display_name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="full" *ngIf="old_hubs.length">
                <mat-label>Hub transfer from</mat-label>
<mat-select [formControl]="oldHubSelect"
                             required>

                    <mat-option *ngFor="let hub of old_hubs" [value]="hub">
                        {{hub.customers_inventory_name}} ({{hub.customers_sub_inventory}})
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <ng-template [ngIf]="inventories.length">
                <mat-form-field class="quarter3">
                    <mat-label>Inventory</mat-label>
<mat-select formControlName="inventory_id"
                                 required>

                        <mat-option>
                            <ngx-mat-select-search
                                    [formControl]="inventories_search_input"
                                    [placeholderLabel]="'Find inventory'"
                                    [noEntriesFoundLabel]="'no matching inventory found'">
                                <mat-icon ngxMatSelectSearchClear>clear</mat-icon>
                            </ngx-mat-select-search>
                        </mat-option>

                        <mat-option *ngFor="let inventory of inventories_filtered | async" [value]="inventory.id">
                            <b>Item: </b>{{inventory.item}}
                            <ng-template [ngIf]="inventory.serial">
                                <b>SN:</b> {{inventory.serial}}
                            </ng-template>
                            <ng-template [ngIf]="inventory.configurations">
                                <b>Conf:</b> {{inventory.configurations}}
                            </ng-template>
                            <b>Qty:</b> {{inventory.quantity}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="quarter">
                    <mat-label>Quantity</mat-label>
                    <input matInput type="number" formControlName="quantity"
                           [max]="max_qty" required>
                </mat-form-field>
            </ng-template>

            <mat-form-field class="full" *ngIf="new_hubs.length">
                <mat-label>Hub transfer to</mat-label>
<mat-select formControlName="new_hub_id"
                             required>

                    <mat-option *ngFor="let hub of new_hubs" [value]="hub.id">
                        {{hub.customers_inventory_name}} ({{hub.customers_sub_inventory}})
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <common-autocomplete *ngIf="locations.length"
                                 placeholder="New location"
                                 class="full"
                                 label="New location"
                                 [options]="locations"
                                 [allowFreeInput]="false"
                                 (optionSelected)="transferForm.get('location_id').setValue($event.id)"
                                 [required]="false"
                                 [value]="transferForm.get('location_id').value"></common-autocomplete>
        </div>
        <div class="actions">
            <button type="button" mat-raised-button color="accent" [disabled]="!transferForm.valid" (click)="submit()">
                Update
            </button>
        </div>
    </form>

</mat-card>
