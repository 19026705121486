import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation
} from "@angular/core";

import * as moment from "moment-timezone";

@Component({
    selector: "common-counter",
    template: `
        <div *ngIf="hasProgress" class="progress" title="Progress">
            <div [style.width.%]="progress">{{progress}}%</div>
        </div>
        <div class="counter {{expired}}">
            <div class="days" *ngIf="showDays">
                <span>{{days}}</span>
                <span>Days</span>
            </div>
            <div class="hours">
                <span>{{hours}}</span>
                <span>Hours</span>
            </div>
            <div class="minutes">
                <span>{{minutes}}</span>
                <span>Minutes</span>
            </div>
            <div class="seconds">
                <span>{{seconds}}</span>
                <span>Seconds</span>
            </div>
        </div>
    `,
    styleUrls: ["counter.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class CounterComponent implements OnInit, OnDestroy {

    private timer: any;

    private expiredNotified: boolean = false;

    @Input()
    public hasProgress: boolean = false;

    @Input()
    public startDate: string;

    @Input()
    public endDate: string;

    @Input()
    public showDays: boolean = true;

    @Input()
    public stopWhenExpired: boolean = true;

    @Output()
    public onExpired: EventEmitter<any> = new EventEmitter();

    public days: string;
    public hours: string;
    public minutes: string;
    public seconds: string;
    public expired: string;

    public progress: number;

    public constructor(private changeDetectorRef: ChangeDetectorRef) {
    }

    private getTimeDiff(): void {
        const countDownDate: any = moment.tz(this.endDate, "YYYY-MM-DD HH-mm-ss", "Asia/Jerusalem");
        const now = moment.tz("Asia/Jerusalem");
        const distance: number = countDownDate.diff(now);
        const duration: any = moment.duration(distance, "milliseconds");


        this.days = "" + Math.abs(duration.days());
        this.hours = "" + Math.abs(duration.hours());
        this.minutes = "" + Math.abs(duration.minutes());
        this.seconds = "" + Math.abs(duration.seconds());

        if (distance < 0) {
            this.expired = "expired";
            if (!this.expiredNotified) {
                this.onExpired.emit();
                this.expiredNotified = true;
            }
            if (this.stopWhenExpired) {
                this.days = "0";
                this.hours = "0";
                this.minutes = "0";
                this.seconds = "0";
            }
        } else {
            this.expired = "";
        }

        this.changeDetectorRef.markForCheck();
    }

    private getProgress(): void {
        const startDate: any = moment.tz(this.startDate, "YYYY-MM-DD HH-mm-ss", "Asia/Jerusalem");
        const endDate: any = moment.tz(this.endDate, "YYYY-MM-DD HH-mm-ss", "Asia/Jerusalem").diff(startDate);
        const now: any = moment.tz("Asia/Jerusalem").diff(startDate);

        this.progress = Math.floor(now / (endDate / 100));
        if (this.progress > 100) {
            this.progress = 100;
        }
        this.changeDetectorRef.markForCheck();
    }


    public ngOnInit(): void {
        this.timer = setInterval((): void => {
            this.getTimeDiff();
            this.getProgress();
        }, 1000);

    }

    public ngOnDestroy(): void {
        clearInterval(this.timer);
    }


}
