import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from "@angular/core";
import {Table2Component} from "../../../../../../common/components/table2";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {ApiService} from "../../../../../../common/services/api.service";
import {Router} from "@angular/router";
import {AmplitudeService} from "../../../../../../common/services/amplitude.service";


@Component({
    selector: "section-no-order-transactions",
    template: `
        <h1 class="container-heading">Transactions without orders</h1>
        <mat-card>
            <mat-card-content>
                <common-table2 *ngIf="listTable" [settings]="listTable"></common-table2>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoOrderTransactionsComponent implements Base.IComponent, OnInit {

    public readonly state: Base.IState;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.ISettings;

    public constructor(
        private apiService: ApiService,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        const actions: Table.IAction[] = [
            {
                name: "view",
                title: "View",
                click: (row: any): void => {
                    this.router.navigate([
                        "partner",
                        row.partner.slug,
                        "transactions-without-orders",
                        "view",
                        "id",
                        row.id,
                        "ref",
                        row.ref || "_",
                        "type",
                        row.type
                    ]);
                    AmplitudeService.eventClick("Transactions without orders view");
                }
            }
        ];
        const columns: Table.ICol[] = [
            {
                data: "ref",
                title: "Ref"
            },
            {
                data: "type",
                title: "Type"
            },
            {
                data: "inventory_conversion.customers_inventory_name",
                name: "InventoryConversion.customers_inventory_name",
                title: "Hub"
            },
            {
                data: "count",
                title: "Count",
                searchable: false
            },
        ];

        this.listTable = {
            table_id: "5Mn80sCa",
            actions,
            api: {
                url: ["transaction", "no-order"],
                query: {
                    group_by: [
                        "ref",
                        "type"
                    ]
                }
            },

            columns,
            export: {
                file_name: "transactions wo order"
            },
            search_in: {
                Ref: "ref",
                Item: "item",
                Serial: "serial"
            }
        };

        this.changeDetectorRef.markForCheck();
    }


    public ngOnInit(): void {
        this.prepareList();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "transactions-without-orders",
            actions: {
                "browse": ["read_warehouse_transactions"]
            }
        };
    }

}
