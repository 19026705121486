import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from "@angular/core";
import {Router} from "@angular/router";
import {ApiService} from "../../../../../../common/services/api.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {ModalService} from "../../../../../services/modal.service";
import {BoxItemsListComponent} from "../../procedures";
//import {BoxSticker2Component, BoxStickerComponent} from "..";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import { WarehouseService } from "src/modules/common/services/warehouse.service";
import { Table2Component } from "src/modules/common/components/table2";
import { Table } from "src/modules/common/interfaces/table.interface";
import { Warehouse } from "src/modules/common/interfaces/warehouse.interface";
import { PalletStickerComponent } from "../sticker/sticker.component";
import { PalletSticker2Component } from "../sticker-2/sticker.component";

@Component({
    selector: "section-warehouse-pallets-list",
    template: `
        <common-confirm></common-confirm>
        <h1 class="container-heading">Pallets List</h1>

        <mat-card>
            <mat-card-content>
                <common-table2 *ngIf="listTable" [settings]="listTable" (onRowsSelected)="setSelectedRows($event)">
                <div row1 *ngIf="selectedRows.length">
                        <button type="button" mat-raised-button color="accent"
                                (click)="printSelected()">
                            Print selected
                        </button>
                    </div>
                </common-table2>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WarehousePalletsListComponent implements Base.IComponent, OnInit {

    public readonly state: Base.IState;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    public listTable: Table.ISettings;

    public selectedRows: any[] = [];

    /* Table options */

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private modalService: ModalService,
        private spinnerService: SpinnerService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        this.listTable = {
            actions: [
                {
                    name: "print",
                    title: "Print sticker",
                    click: (row: any) => {
                        this.getStickers([row], true);
                    },
                },
                {
                    name: "print-alt",
                    title: "Print sticker",
                    icon: "print",
                    click: (row: any) => {
                        this.getStickers([row]);
                    },
                }
            ],
            api: {
                url: [this.state.section, "pallets"],
                query: {
                    relations: ["WarehouseLocation.Warehouse", "Boxes", "Inventory.InventoryConversion", "Partner"],
                    counts: [
                        "Boxes",
                    ],
                },
                version: 3
            },
            columns: [
                {
                    "data": "ref",
                    "title": "Ref",
                },
                {
                    "data": "inventory.inventory_conversion.customers_inventory_full_name",
                    "name": "Inventory.InventoryConversion.customers_inventory_name",
                    "title": "Hub (from Inventory)",
                    "render": (row: any) => {
                        if (row.inventory?.inventory_conversion) {
                            return `
                            ${row.inventory.inventory_conversion.customers_inventory_name}
                            -
                            ${row.inventory.inventory_conversion.customers_sub_inventory}`;
                        } else {
                            return "";
                        }
                    }
                },
                {
                    "data": "warehouse_location.location",
                    "name": "WarehouseLocation.location",
                    "title": "Warehouse Location"
                },
                {
                    "data": "boxes_count",
                    "name": "boxes_count",
                    "title": "Boxes",
                    "searchable": false
                }
            ],
            export: {
                file_name: "Warehouse-Pallets-List",
            },
            multi_select: true
        };

        this.changeDetectorRef.markForCheck();
    }

    public setSelectedRows(rows: any): void {
        this.selectedRows = rows;
        this.changeDetectorRef.markForCheck();
    }

    public printSelected(): void {
        this.getStickers(this.selectedRows, true);
    }

    public async getStickers(pallets: Warehouse.IPallet[], withBoxes = false): Promise<void> {
        await this.modalService.open(withBoxes
                ? PalletStickerComponent
                : PalletSticker2Component, {
            pallet: pallets[0],
            pallets,
            state: this.state,
            warehouseName: pallets[0].warehouse_location.warehouse.name,
            locationName: pallets[0].warehouse_location.location
        });
    }

    public ngOnInit(): void {
        this.spinnerService.show();
        this.prepareList();
        this.spinnerService.hide();
    }


    public ngConfig(): Base.IConfig {
        return {
            name: "warehouse-pallets",
            actions: {
                "browse": ["browse_boxes"]
            }
        };
    }
}
