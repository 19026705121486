<div class="heading">
    <button mat-raised-button color="primary" (click)="add()">Add shipment</button>
</div>
<div class="container-columns">
    <div class="left">
        <mat-card class="drag-bag order-items">
            <div class="title">Order parcels</div>
            <div class="items free" *ngIf="parcels"
                 [dragula]='"shipment-bag"' [(dragulaModel)]="parcels">
                <div class="item id-{{parcel.id}}" *ngFor="let parcel of (parcels || [])">
                    {{parcel.ref}}
                    ({{parcel.weight}}x{{parcel.height}}x{{parcel.length}}{{parcel.distance_unit}},
                    {{parcel.weight}}kg)
                </div>
            </div>
        </mat-card>
    </div>
    <div class="right">
        <ng-template *ngIf="shipments" ngFor let-i="index" let-shipment [ngForOf]="shipments">
            <mat-card class="drag-bag parcel">
                <div class="title">
                    {{shipment.ref}}
                    ({{shipment.weight}}{{shipment.mass_unit}})
                    <button mat-icon-button (click)="edit(shipment)">
                        <mat-icon title="Edit">edit</mat-icon>
                    </button>
                </div>
                <div class="items box id-{{shipment.id}}" [dragula]='"shipment-bag"'
                     [(dragulaModel)]="shipment.parcels">
                    <div class="item id-{{parcel.id}}" *ngFor="let parcel of (shipment.parcels || [])">
                        {{parcel.ref}}
                        ({{parcel.weight}}x{{parcel.height}}x{{parcel.length}}{{parcel.distance_unit}},
                        {{parcel.weight}}kg)
                    </div>
                </div>
            </mat-card>
        </ng-template>
    </div>
</div>
