import {ChangeDetectionStrategy, Component} from "@angular/core";
import {Modal} from "../../../section/services/modal.service";

@Component({
    selector: "common-modal",
    template: `
        <h1 class="container-heading"
            [ngStyle]="modal.params.titleStyles || {}">
            {{modal.params.title}}
        </h1>
        <mat-card>
            <mat-card-content>
                <div [ngStyle]="modal.params.contentStyles || {} " [innerHTML]="modal.params.template"></div>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalComponent {

    public modal: Modal.IModal;

}
