import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {Modal, ModalService} from "../../../../../../services/modal.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {AttachmentService} from "../../../../../../../common/services/attachment.service";
import {AttachmentsListComponent} from "../../../../../common/components/attachments";
import {FileUploadComponent} from "../../../../../../../common/components/file-upload/file-upload.component";
import {Table} from "../../../../../../../common/interfaces/table.interface";
import {Api} from "../../../../../../../common/services/api.service";
import {Api3Service} from "../../../../../../../common/services/api3.service";

@Component({
    selector: "section-order-attachments-list",
    template: `
        <common-confirm></common-confirm>
        <h1 class="container-heading orderAttachmentsHeader">Order attachments</h1>
        <mat-card>
            <mat-card-content>
                <common-table2 *ngIf="listTable" [settings]="listTable">
                    <div>
                        <button mat-raised-button color="accent" [matMenuTriggerFor]="actionsMenu"
                                class="addAttachmentButton">
                            Add attachment
                            <mat-icon>keyboard_arrow_down</mat-icon>
                        </button>
                    </div>
                </common-table2>
            </mat-card-content>
        </mat-card>
        <mat-menu #actionsMenu="matMenu">
            <a mat-menu-item (click)="addAttachment('Warehouse attachment')">Warehouse attachment</a>
            <a mat-menu-item (click)="addAttachment('Invoice')">Invoice</a>
            <a mat-menu-item (click)="addAttachment('Packing list')">Packing list</a>
            <a mat-menu-item (click)="addAttachment()">Other</a>
        </mat-menu>
    `,
    styleUrls: ["list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderAttachmentsListComponent extends AttachmentsListComponent implements OnInit {

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        protected attachmentService: AttachmentService,
        protected modalService: ModalService,
        protected toastService: ToastService,
        protected spinnerService: SpinnerService,
        protected api3Service: Api3Service
    ) {
        super(changeDetectorRef, attachmentService, modalService, toastService, spinnerService);
    }

    protected getTableId(): string {
        return "fdj87GFEDdG";
    }

    protected getUrl(): Table.ITableApi {
        return {
            url: [this.modal.params.state.section, "orders", this.modal.params.order_id, "attachments"],
            version: 3
        };
    }

    public async delete(id: number): Promise<any> {
        if (await this.confirmRef.confirm("Are you sure want to delete this attachment?")) {
            this.spinnerService.show();
            const {message, code}: Api.IResponse = await this.api3Service.delete(
                `${this.modal.params.state.section}/orders/${this.modal.params.order_id}/attachments`, {id});

            if (code === 200) {
                this.toastService.show(message, "success");
                this.tableRef.reload();
            }
            this.spinnerService.hide();
        }
    }

    public async addAttachment(category: string = null): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(FileUploadComponent, {
            url: ["remark", "order", this.modal.params.orderRef, "remark_attachment"],
            body: {
                category
            }
        });

        if (response && response.value) {
            this.toastService.show(response.value.message, "success");
            this.tableRef.reload();
        }
    }

    public ngOnInit(): void {
        this.prepareList();
    }

}
