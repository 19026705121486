import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit} from "@angular/core";
import {Modal, ModalService} from "../../../../../../../services/modal.service";
import {Api, ApiService} from "../../../../../../../../common/services/api.service";
import {FormControl} from "@angular/forms";
import {Warehouse} from "../../../../../../../../common/interfaces/warehouse.interface";
import {takeUntil} from "rxjs/operators";
import {ToastService} from "../../../../../../../../common/services/toast.service";
import {WarehouseLocationFormComponent} from "../../../../locations";
import {SpinnerService} from "../../../../../../../../common/services/spinner.service";
import {SoundsService} from "../../../../../../../../common/services/sounds.service";

@Component({
    selector: "section-procedure-inbound-change-inventory-location",
    template: `

        <h1 class="container-heading">Scan new location</h1>
        <mat-card>
            <mat-card-content>
                <div class="form">
                    <div class="group flex">
                        <mat-form-field class="max">
                            <mat-label>Scan location</mat-label>
                            <input type="search" matInput [formControl]="searchInput"
                                   (keydown)="onKeydown($event)">
                        </mat-form-field>
                        &nbsp;
                        &nbsp;
                        <button type="button" mat-raised-button color="accent" (click)="searchLocation()">
                            <i class="fa fa-barcode"></i> Search
                        </button>
                    </div>
                </div>

                <h2 class="border-bottom-none color-accent">Inventory to move</h2>
                <div class="margin-bottom-20">
                    <div>
                        Item: <b>{{ inventory.item }}</b>
                    </div>
                    <div *ngIf="inventory.serial">
                        SN: <b>{{ inventory.serial }}</b>
                    </div>
                    <div *ngIf="inventory.configurations || inventory.related_configurations">
                        Configurations: <b>{{ inventory | getConfNames }}</b>
                    </div>
                    <div *ngIf="inventory.rev">
                        Rev: <b>{{ inventory.rev }}</b>
                    </div>
                    <div *ngIf="inventory.batch">
                        Batch: <b>{{ inventory.batch }}</b>
                    </div>
                    <div>
                        Quantity: <b>{{ inventory.quantity }}</b>
                    </div>
                </div>

                <div>
                    <button type="button" mat-raised-button color="accent" [disabled]="!newLocation"
                            (click)="moveInventory()">
                        Move
                    </button>
                </div>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InventoryChangeLocationModalComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    public newLocation: string = null;

    public modal: Modal.IModal;

    public searchInput: FormControl = new FormControl(null);

    public inventory: Warehouse.IInventory;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        public soundsService: SoundsService,
        private toastService: ToastService,
        private modalService: ModalService,
        private spinnerService: SpinnerService
    ) {
    }

    public async searchLocation(): Promise<any> {
        this.spinnerService.show();
        const {data, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["warehouse_location", "find"], {ref: this.searchInput.value});

        this.spinnerService.hide();
        this.newLocation = null;

        if (data && data.id) {
            this.newLocation = data.location;
            this.toastService.show(message, "success");
        } else {
            this.addLocation(this.searchInput.value);
        }
        this.soundsService.textToSpeech(message);
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Add new location and select it
     */
    public async addLocation(value: string): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(WarehouseLocationFormComponent, {
            location_name: value
        });
        if (response && response.value) {
            this.searchInput.setValue(response.value.location);
            this.newLocation = response.value.location;
            this.changeDetectorRef.markForCheck();

            this.soundsService.textToSpeech("Location set");
            this.toastService.show("Location set", "success");
        }
    }

    public onKeydown(event: any): void {
        if (event.key === "Enter" || event.key === "Tab") {
            this.searchLocation();
        }
    }

    public async moveInventory(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["inventory", "" + this.inventory.id], {
                inventory_conversion_id: this.inventory.inventory_conversion_id,
                quantity: this.inventory.quantity,
                part_master_id: this.inventory.part_master_id,
                location: this.newLocation
            });

        this.spinnerService.hide();
        if (data) {
            this.modal.response.emit({
                name: "response",
                value: data
            });
        }
    }

    public ngOnInit(): void {
        this.inventory = this.modal.params.inventory;
        this.changeDetectorRef.markForCheck();

        this.searchInput.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((): void => {
            this.newLocation = null;
        });

    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
