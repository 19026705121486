<common-confirm></common-confirm>

<mat-tab-group [dynamicHeight]="true">
    <mat-tab label="Carriers">

        <div *ngIf="showSpinner">
            <common-simple-spinner></common-simple-spinner>
        </div>

        <div>
            <div>
                <mat-form-field class="pull-right">
                    <mat-label>Search carrier...</mat-label>
                    <input matInput type="search" [formControl]="search">
                </mat-form-field>
                <div class="clear"></div>
            </div>

            <mat-accordion class="margin-bottom-20">
                <mat-expansion-panel
                        *ngFor="let carrier of carriers | filter: 'name': 'like': search.value: search.value">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <img src="{{carrier.logo}}" alt="" height="32">
                            &nbsp;
                            <b>{{carrier.name}}</b>
                            &nbsp;
                            ({{carrier.label}})
                            &nbsp;
                            {{carrier.service_name}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <ng-template matExpansionPanelContent>
                        <div>
                            <mat-card>

                                <div *ngIf="showWarehouseSpinner">
                                    <common-simple-spinner></common-simple-spinner>
                                </div>

                                <mat-accordion class="margin-bottom-20" *ngIf="warehousesGrouped">
                                    <mat-expansion-panel *ngFor="let threepl of warehousesGrouped | keys">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                {{threepl}}
                                            </mat-panel-title>
                                            <mat-panel-description class="text-right">
                                                Selected
                                                {{ selected_threepl[carrier.id + '-' + threepl] || 0}}
                                                of
                                                {{warehousesGrouped[threepl].warehouses.length}}
                                            </mat-panel-description>
                                        </mat-expansion-panel-header>

                                        <ng-template matExpansionPanelContent>
                                            <div class="flex row align-center">
                                                <div class="max">
                                                    <mat-form-field>
                                                        <mat-label>Search</mat-label>
                                                        <input type="text" matInput #warehouseSearch>
                                                    </mat-form-field>
                                                </div>

                                                <button mat-raised-button type="button" color="accent"
                                                        class="margin-right-10"
                                                        (click)="checkThreepl(true, carrier, threepl)">
                                                    Select all
                                                </button>

                                                <button mat-raised-button type="button" color="warn"
                                                        (click)="checkThreepl(false, carrier, threepl)">
                                                    Remove all
                                                </button>
                                            </div>

                                            <mat-card
                                                    *ngFor="let warehouse of warehousesGrouped[threepl].warehouses | filter: 'name':'like' : warehouseSearch.value "
                                                    class="margin-bottom-10">
                                                <div class="flex row align-center">
                                                    <div class="max">
                                                        {{warehouse.name}}
                                                    </div>

                                                    <mat-checkbox class="margin-right-10"
                                                                  [checked]="!!selected_warehouses_for_partner[carrier.id+'-'+warehouse.id]"
                                                                  (change)="checkWarehouse(true, carrier, warehouse.id, $event.checked)">
                                                        Partner side only
                                                    </mat-checkbox>

                                                    <mat-slide-toggle
                                                            [checked]="!!selected_warehouses[carrier.id+'-'+warehouse.id]"
                                                            (change)="checkWarehouse($event.checked, carrier, warehouse.id)"
                                                    ></mat-slide-toggle>
                                                </div>
                                            </mat-card>

                                        </ng-template>


                                    </mat-expansion-panel>
                                </mat-accordion>
                            </mat-card>
                        </div>
                    </ng-template>


                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </mat-tab>
    <mat-tab label="Carriers logs">
        <common-table2 #logTable *ngIf="logsTableSettings"
                       [settings]="logsTableSettings" [search]="false"></common-table2>

    </mat-tab>
</mat-tab-group>

<div class="padding-top-20"></div>

