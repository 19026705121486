import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Router} from "@angular/router";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {FormControl} from "@angular/forms";
import {takeUntil} from "rxjs/operators";
import {Table2Component} from "../../../../../../common/components/table2";
import {Warehouse} from "../../../../../../common/interfaces/warehouse.interface";
import {User} from "../../../../../../common/interfaces/user.interface";
import {CommonFormComponent} from "../../../../../../common/components/form";
import {ModalService} from "../../../../../services/modal.service";
import {TransactionService} from "../../../../../../common/services/transaction.service";

@Component({
    selector: "section-stock-transaction-all-list",
    template: `
        <common-confirm></common-confirm>

        <h1 class="container-heading">Warehouse transactions</h1>
        <mat-card>
            <div>
                <common-table2 *ngIf="tableSettings" [settings]="tableSettings" [scrollHeightValue]="320">
                    <div class="row flex align-center" row0>
                        <mat-form-field class="margin-right-10">
                            <mat-label>Type</mat-label>
                            <mat-select [formControl]="typeSelect">
                                <mat-option value="">All</mat-option>
                                <mat-option value="inbound">Inbound</mat-option>
                                <mat-option value="outbound">Outbound</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <common-form-hub-select label="Hubs"
                                                class="margin-right-10"
                                                [multiple]="true"
                                                (onClose)="hubSelect.setValue($event)"></common-form-hub-select>

                        <common-form-date-range label="Created"
                                                (valueChange)="dateRange.setValue($event)"></common-form-date-range>

                    </div>
                </common-table2>
            </div>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StockTransactionAllListComponent implements Base.IComponent, OnInit, OnDestroy {

    private tableColumns: Table.ICol[] = [];

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    public tableSettings: Table.ISettings;

    public readonly state: Base.IState;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    public hubSelect: FormControl = new FormControl([]);
    public typeSelect: FormControl = new FormControl([]);
    public dateRange: FormControl = new FormControl(null);


    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private changeDetectorRef: ChangeDetectorRef,
        private modalService: ModalService,
        private transactionService: TransactionService,
        private spinnerService: SpinnerService
    ) {
    }

    /**
     * Get columns from server
     * @returns {Promise<any>}
     *
     */
    private async getColumns(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["transaction"], {},
            {
                data_structure: "datatables_columns"
            });
        if (data) {
            this.tableColumns = data;
            this.prepareList();
        }
        this.spinnerService.hide();
    }

    private get url(): { url: string[], query?: { [key: string]: any } } {
        return {
            url: ["transaction"],
            query: {
                hubs: this.hubSelect.value,
                based_on: "transactions",
                use_transformer: true,
                type: this.typeSelect.value,
                date_range: this.dateRange.value
            }
        };
    }

    private async createReplenishmentForTransaction(id: number): Promise<any> {
        this.spinnerService.show();
        const {message, code}: Api.IResponse = await this.transactionService.transactionReplenishmentCreate({
            transaction_id: id
        });
        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show({message, url: [this.state.section, "transactions-replenishment"]}, "success");
            this.tableRef.reload();
        }
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {

        const columns: Table.ICol[] = [
            {
                data: "id",
                title: "",
                render: (row: any): string => {
                    if (row.type === "outbound" && row.replenishments_count === 0) {
                        return `<button type="button" class="mat-mdc-raised-button mdc-button mdc-button mat-accent">
                            Replenish</button>`;
                    }
                    return "";
                },
                click: (row: any): void => {
                    if (row.type === "outbound" && row.replenishments_count === 0) {
                        this.createReplenishmentForTransaction(row.id);
                    }
                },
                cssClass: "action",
                exportable: false
            },
            {
                data: "order.ref",
                name: "Order.ref",
                title: "Order ref",
                render: (row: any): string => {
                    if (row.order_id) {
                        if (row.order && !row.order_ref) {
                            row.order_ref = row.order.ref;
                        }
                        return `<button type="button"
                                class="mat-mdc-raised-button mdc-button mdc-button mat-primary button-150"
                                title="${row.order_ref}">${row.order_ref}</button>`;
                    }
                    return row.order_ref || "";
                },
                click: (row: any): void => {
                    if (row.order_id) {
                        this.router.navigate([
                            this.state.section,
                            "orders",
                            "view",
                            "id",
                            row.order_id
                        ]);
                    }
                }
            },
            {
                data: "order.ref",
                title: "",
                render: (row: any): string => {
                    if (row.order_id) {
                        return `<mat-icon class="mat-icon material-icons pointer" title="Open in new tab">
                            open_in_new
                            </mat-icon>`;
                    }
                    return "";
                },
                click: (row: any): void => {
                    if (row.order_id) {
                        window.open("/" + this.state.section + "/orders/view/id/" + row.order_id, "_blank");
                    }
                },
                cssClass: "action",
                sortable: false,
                searchable: false,
                exportable: false
            }
        ];
        const [type, slug]: string[] = this.state.section.split("/");

        if (this.tableColumns.length > 0) {
            this.tableColumns.forEach((col: Table.ICol): void => {
                columns.push(col);
            });
        } else {
            if (type === "threepl" || type === "warehouse") {
                columns.push({
                    data: "display_name",
                    title: "Partner"
                });
                columns.push({
                    data: "warehouse_slug",
                    title: "Warehouse"
                });
                columns.push({
                    data: "sub_inventory",
                    title: "Sub-inventory"
                });
            } else {
                columns.push({
                    data: "customers_inventory_name",
                    "name": "InventoryConversion.customers_inventory_name",
                    title: "Hub",
                    sortable: false
                });
                columns.push({
                    data: "customers_sub_inventory",
                    "name": "InventoryConversion.customers_sub_inventory",
                    title: "Hub sub-inventory",
                    sortable: false
                });
            }

            columns.push({
                data: "gb",
                title: "GB"
            });
            columns.push({
                data: "inventory_key",
                title: "Inventory key",
                render: (row: any): string => {
                    return "<button class='mat-mdc-raised-button mdc-button' type='button'>"
                        + row.inventory_key
                        + "</button>";
                },
                click: (row: any): void => {
                    this.router.navigate([
                        this.state.section,
                        "inventory",
                        "view",
                        "key",
                        row.inventory_key,
                        "backTo", this.state.component
                    ]);
                }
            });
            columns.push({
                data: "item",
                title: "Item"
            });
            columns.push({
                data: "description",
                title: "Description"
            });
            columns.push({
                data: "serial",
                title: "Serial"
            });
            columns.push({
                data: "configurations",
                title: "Configurations",
                render: (row: any): string => {
                    return HelpersService.getConfigNames(row);
                }
            });
            columns.push({
                data: "total_in_inv",
                title: "In inventory",
            });
            columns.push({
                data: "balance_stock",
                title: "On hand",
            });
            columns.push({
                data: "gap_stock",
                title: "Inventory Gap",
            });
            columns.push({
                data: "total_stock",
                title: "Inbounded",
            });
            columns.push({
                data: "total_sent",
                title: "Outbounded",
            });
        }

        this.tableSettings = {
            table_id: "e69sUyj01JI",
            columns,
            api: this.url,
            export: {
                file_name: "Stock list based on transaction",
            },
            search_default: ["ref", "serial", "item"],
            infinity_scroll: true
        };

        this.changeDetectorRef.markForCheck();
    }

    public async report(): Promise<any> {
        await this.modalService.open(CommonFormComponent, {
            configUrl: ["report", "heavy-transactions-report"],
            submitUrl: ["report", "heavy-transactions-report"],
            asyncKey: HelpersService.randomString()
        });
    }

    public ngOnInit(): void {
        this.getColumns();

        this.hubSelect.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((): void => {
            this.tableRef.reload(this.url);
        });

        this.typeSelect.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((): void => {
            this.tableRef.reload(this.url);
        });

        this.dateRange.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((): void => {
            this.tableRef.reload(this.url);
        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "transactions-stock-all",
            actions: {
                "browse": ["browse_warehouse_transactions"]
            }
        };
    }

}
