<common-confirm></common-confirm>

<h1 class="container-heading">{{ edit ? "Edit" : "Add" }} customer</h1>

<mat-card>
    <mat-card-content>

        <form *ngIf="formGroup" [formGroup]="formGroup" (submit)="handleFormSubmit()" autocomplete="off" novalidate>

            <div class="group flex">
                <mat-form-field class="full">
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="name">
                    <common-form-error [form]="formGroup" controlName="name"></common-form-error>
                </mat-form-field>

                <mat-form-field class="third">
                    <mat-label>VAT</mat-label>
                    <input matInput formControlName="vat">
                    <common-form-error [form]="formGroup" controlName="vat"></common-form-error>
                </mat-form-field>

                <mat-form-field class="third">
                    <mat-label>Ref</mat-label>
                    <input matInput formControlName="ref">
                    <common-form-error [form]="formGroup" controlName="ref"></common-form-error>
                </mat-form-field>

                <div class="third">
                    <common-form-hub-select
                            label="Default hub"
                            line_type="inbound"
                            [value]="formGroup.value.inventory_conversion_id"
                            [required]="formGroup.get('inventory_conversion_id').hasValidator(Validators.required)"
                            (onChange)="formGroup.get('inventory_conversion_id').setValue($event)">
                    </common-form-hub-select>
                </div>

                <mat-form-field class="third">
                    <mat-label>Out Incoterms</mat-label>
                    <mat-select formControlName="out_incoterms">
                        <mat-option [value]="null">None</mat-option>
                        <mat-option *ngFor="let incoterm of incoterms" [value]="incoterm.value">
                            {{ incoterm.name }}
                        </mat-option>
                    </mat-select>
                    <common-form-error [form]="formGroup" controlName="out_incoterms"></common-form-error>
                </mat-form-field>

                <mat-form-field class="third">
                    <mat-label>In Incoterms</mat-label>
                    <mat-select formControlName="in_incoterms">
                        <mat-option [value]="null">None</mat-option>
                        <mat-option *ngFor="let incoterm of incoterms" [value]="incoterm.value">
                            {{ incoterm.name }}
                        </mat-option>
                    </mat-select>
                    <common-form-error [form]="formGroup" controlName="in_incoterms"></common-form-error>
                </mat-form-field>

                <div class="third" *ngIf="serviceLevels">
                    <common-form-select
                            label="Default service level"
                            [value]="formGroup.value.default_service_level_id"
                            [options]="serviceLevels"
                            [required]="formGroup.get('default_service_level_id').hasValidator(Validators.required)"
                            (valueChange)="formGroup.get('default_service_level_id').setValue($event)"
                    ></common-form-select>
                </div>

                <mat-form-field class="full">
                    <mat-label>Courier service default</mat-label>
                    <mat-select formControlName="default_courier_service_id">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="selectSearch" [placeholderLabel]="'Search'"
                                                   [clearSearchInput]="true" [noEntriesFoundLabel]="'No items found'">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let courierService of courierServicesFiltered | async"
                                    [value]="courierService.value">
                            {{ courierService.name }}
                        </mat-option>
                    </mat-select>
                    <common-form-error [form]="formGroup" controlName="default_courier_service_id"></common-form-error>
                </mat-form-field>

                <div class="third">
                    <mat-checkbox formControlName="is_active">Is active</mat-checkbox>
                </div>

                <div class="third">
                    <mat-checkbox formControlName="delivery_ref">Delivery ref required</mat-checkbox>
                </div>

                <div class="third">
                    &nbsp;
                </div>
            </div>

            <div class="actions" *ngIf="edit">
                <button mat-raised-button color="accent" type="button" (click)="showContacts()">
                    Show contacts
                </button>
            </div>

            <div class="actions">
                <button mat-raised-button color="primary" type="submit" class="main">
                    {{ edit ? "Save" : "Add" }}
                </button>
                <button mat-raised-button *ngIf="modal.params.canDelete && edit"
                        type="button" color="warn" (click)="delete()">
                    Delete
                </button>
                <button mat-button type="button" (click)="showList()">Cancel</button>
            </div>

        </form>

    </mat-card-content>
</mat-card>
