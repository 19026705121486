import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {User} from "../../../../../../common/interfaces/user.interface";


@Component({
    selector: "section-oauth-token-form",
    templateUrl: "token-form.component.html",
    styleUrls: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class OAuthTokenFormComponent implements OnDestroy, OnInit {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);
    /**
     * Form group / group with controls
     * @type {FormGroup}
     */
    public formGroup: FormGroup;

    public readonly state: Base.IState;

    public user: User.IData;

    public modal: Modal.IModal;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private toastService: ToastService,
        private modalService: ModalService,
        private router: Router,
        private http: HttpClient,
        private spinnerService: SpinnerService
    ) {
    }

    /**
     * Prepare form group (create)
     * @returns {void}
     */
    private prepareForm(): void {
        this.formGroup = new FormGroup({
            name: new FormControl(null, [Validators.required])
        });
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Submit form
     */
    public async submit(): Promise<any> {
        this.spinnerService.show();

        const body: any = {...this.formGroup.value};

        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["user", String(this.user.id), "oauth/oauth_personal_access_tokens"], body);
        if (data) {
            this.toastService.show("Successfully created", "success");
            this.modal.response.emit({
                name: "success",
                value: data.accessToken
            });
        }
        this.spinnerService.hide();
    }

    /**
     * Close modal
     */
    public close(): void {
        this.modal.response.emit();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngOnInit(): void {
        this.user = this.modal.params.user;
        this.spinnerService.show();
        this.prepareForm();
        this.spinnerService.hide();
    }
}
