import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    Output,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {Router} from "@angular/router";
import {ConfirmComponent} from "../../../../../../../common/components/confirm/confirm.component";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {DragulaService} from "ng2-dragula";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {Modal, ModalService} from "../../../../../../services/modal.service";
import {ParcelFormComponent} from "../../../../../threepl/warehouse/parcel";
import {Warehouse} from "../../../../../../../common/interfaces/warehouse.interface";
import {takeUntil} from "rxjs/operators";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {HelpersService} from "../../../../../../../common/services/helpers.service";
import {MatCheckboxChange} from "@angular/material/checkbox";


@Component({
    selector: "section-order-shipment-wizard-step1",
    templateUrl: "step1.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class OrderShipmentWizardStep1Component implements OnDestroy {

    private state: Base.IState;

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    @Output()
    public result: EventEmitter<any> = new EventEmitter();

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    public bagName: string;

    /**
     * Order items
     */
    public items: any[] = [];

    public parcels: Warehouse.IParcel[] = [];

    public selectedList: number[] = [];

    public allFreeItemsSelected: boolean = false;

    public allParcelItemsSelected: boolean[] = [];

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private dragulaService: DragulaService,
        private modalService: ModalService,
        private spinnerService: SpinnerService
    ) {

        this.bagName = "parcel-bag_" + HelpersService.randomString();

        if (!dragulaService.find(this.bagName)) {
            dragulaService.createGroup(this.bagName, {});
        }
        dragulaService.dropModel(this.bagName).pipe(takeUntil(this.destroy$))
            .subscribe((value: any): void => {
                this.onDropModel(value);
            });
    }

    /**
     * Handle drop event
     * @returns {Promise<any>}
     */
    private async onDropModel(value: any): Promise<any> {

        const item: any = value.el;
        const target: any = value.target;
        const bag_name: any = value.name;

        if (bag_name !== this.bagName) {
            return;
        }
        let itemId: string;
        item.classList.forEach((className: string): void => {
            if (/id-[0-9]+/.test(className)) {
                itemId = className.replace("id-", "");
            }
        });

        let targetId: number = null;
        target.classList.forEach((className: string): void => {
            if (/id-[0-9]+/.test(className)) {
                targetId = Number(className.replace("id-", ""));
            }
        });

        if (itemId && this.selectedList.indexOf(Number(itemId)) === -1) {
            this.selectedList = [Number(itemId)];
        }

        if (this.selectedList.length) {
            this.spinnerService.show();
            const response: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
                ["parcel"], {parcel_id: targetId, order_items: this.selectedList});
            this.toastService.show(response.message, response.type as string);

            this.spinnerService.hide();

            this.getFreeOrderItems();
            this.getOrderParcels();
            this.result.next(this.parcels);

            this.selectedList = [];
            this.checkAllSelected();
        }

        this.changeDetectorRef.markForCheck();
    }

    /**
     * Get order parcels
     * @returns {Promise<any>}
     */
    private async getOrderParcels(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["order", this.state.params.order_id, "parcels", this.state.params.type], {}, {
                linked: true
            });
        this.spinnerService.hide();
        if (data) {
            this.parcels = data;
            for (const parcel of this.parcels) {
                this.allParcelItemsSelected.push(false);
            }
            if (this.parcels.length > 0) {
                this.result.next(this.parcels);
            }
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Get free order items
     * @returns {Promise<any>}
     */
    private async getFreeOrderItems(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse =
            await this.apiService.request(Api.EMethod.Get,
                ["order", this.state.params.order_id, "items", this.state.params.type],
                null, {
                    linked_to_parcel: "false"
                });
        this.spinnerService.hide();
        if (data) {
            this.items = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    private checkAllSelected(): void {
        this.allFreeItemsSelected = false;
        this.allParcelItemsSelected = [];

        this.allFreeItemsSelected = this.items.length
            && this.items.every((it: any) => this.selectedList.indexOf(it.id) > -1);

        for (const index in this.parcels) {
            this.allParcelItemsSelected[index] = this.parcels[index].order_items.length
                && this.parcels[index].order_items.every((it: any) => this.selectedList.indexOf(it.id) > -1);
        }

        this.changeDetectorRef.markForCheck();
    }

    /**
     * Initialize details step
     * @param state
     * @returns {Promise<any>}
     */
    public async init(state: Base.IState): Promise<any> {
        this.state = state;

        this.getFreeOrderItems();
        this.getOrderParcels();

        this.changeDetectorRef.markForCheck();

    }

    /**
     * Add new parcel
     * @returns {Promise<any>}
     */
    public async add(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(ParcelFormComponent, {
            action: "add",
            order_id: this.state.params.order_id,
            type: this.state.params.type,
            modalWidth: "660px"
        });
        // console.warn("add", response);
        if (response) {
            this.getFreeOrderItems();
            this.getOrderParcels();
        }
    }

    /**
     * Edit parcel
     * @param {Order.IParcel} parcel
     * @returns {Promise<any>}
     */
    public async edit(parcel: Warehouse.IParcel): Promise<any> {

        const response: Modal.IResponse = await this.modalService.open(ParcelFormComponent, {
            action: "edit",
            parcel: parcel,
            modalWidth: "660px"
        });

        if (response) {
            for (const index of Object.keys(this.parcels)) {
                if (this.parcels[index].id === response.value.parcel.id) {
                    if (response.value.action === "edit") {

                        this.parcels[index] = response.value.parcel;

                    } else if (response.value.action === "delete" &&
                        await this.confirmRef
                            .confirm(`Do you want to delete parcel "${this.parcels[index].ref}?"`)
                    ) {

                        if (this.parcels[index].order_item && this.parcels[index].order_item.length) {

                            this.items = this.items.concat(this.parcels[index].order_item);

                            this.parcels[index].order_item.forEach(async (item: any): Promise<any> => {
                                const unlinkItem: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
                                    ["parcel"], {parcel_id: null, order_item_id: item.id});

                                this.toastService.show(unlinkItem.message, unlinkItem.type as string);
                            });
                        }


                    }

                    this.getOrderParcels();
                    this.getFreeOrderItems();

                    this.changeDetectorRef.markForCheck();
                    break;
                }
            }
        }
    }

    public selectedListUpdate(event: MatCheckboxChange): void {

        const itemId: number = Number(event.source.value);
        if (event.checked) {
            if (this.selectedList.indexOf(itemId) === -1) {
                this.selectedList.push(itemId);
            }
        } else {
            const index: number = this.selectedList.indexOf(itemId);
            if (index > -1) {
                this.selectedList.splice(index, 1);
            }
        }

        this.checkAllSelected();
    }

    public selectAll(event: MatCheckboxChange, itemsToSelect: any[]): void {
        this.selectedList = [];

        for (const parcel of this.parcels) {
            this.allParcelItemsSelected.push(false);
        }

        if (event.checked) {
            for (const item of itemsToSelect) {
                this.selectedList.push(item.id);
            }
        }
        this.checkAllSelected();
        this.changeDetectorRef.markForCheck();
    }


    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
