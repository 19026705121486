import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {AbstractWizardComponent, Wizard, WizardStepFactory} from "../../../../../../common/interfaces/wizard.interface";
import {CsvImportWizardUploadFileComponent} from "./upload-file/upload-file.component";
import {CsvImportWizardMapDataComponent} from "./map-data/map-data.component";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {Router} from "@angular/router";


@Component({
    selector: "section-csv-import-wizard",
    templateUrl: "../../../../../../common/templates/wizard.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class CsvImportWizardComponent extends AbstractWizardComponent
    implements Base.IComponent, OnInit, AfterViewInit {

    public steps: Wizard.IStep[] = [
        new WizardStepFactory("Import file", CsvImportWizardUploadFileComponent),
        new WizardStepFactory("Match your column title with our standard column titles",
            CsvImportWizardMapDataComponent),
    ];

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        private spinnerService: SpinnerService,
        private apiService: ApiService,
        private toastService: ToastService,
        private router: Router
    ) {
        super(changeDetectorRef);
    }


    public async finish(): Promise<any> {
        this.spinnerService.show();
        this.apiService.setHeaders({async: HelpersService.randomString()});
        const {code, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["csv_import", this.data.wizardType], {
                ...this.data.mappingResult,
                skip_rows: [0]
            });
        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");

            if (this.state.params.back_to) {
                this.router.navigateByUrl(atob(this.state.params.back_to));
            } else {
                this.router.navigate([
                    this.state.section,
                    "dashboard"
                ]);
            }
        }
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.wizardHeading = "Import file";
        this.data.wizardType = this.state.params.type;
    }


    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }


    public ngConfig(): Base.IConfig {
        return {
            name: "csv-import",
            actions: {
                "browse": "*",
                "wizard": "*",
            }
        };
    }

}
