<mat-card>
    <h3 matSubheader>Notifications</h3>
    <form class="form" [formGroup]="proForm" (submit)="saveProfile()">
        <mat-checkbox formControlName="prevent_notify_new_remarks">Do not notify me about new remarks</mat-checkbox>
        <br/><br/>

        <div class="actions">
            <button type="submit" mat-raised-button class="accent" [disabled]="!proForm.valid">
                Save
            </button>

            <button type="button" mat-raised-button (click)="prepareProfile()">
                Cancel
            </button>
        </div>
    </form>
</mat-card>
