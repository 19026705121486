import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import {Modal} from "../../../../../../../services/modal.service";
import {Api, ApiService} from "../../../../../../../../common/services/api.service";
import {Table} from "../../../../../../../../common/interfaces/table.interface";
import {Table2Component} from "../../../../../../../../common/components/table2";
import {Order} from "../../../../../../../../common/interfaces/order.interface";
import {FormControl} from "@angular/forms";
import {takeUntil} from "rxjs";
import {debounceTime} from "rxjs/operators";
import {Api3Service} from "../../../../../../../../common/services/api3.service";
import {SpinnerService} from "../../../../../../../../common/services/spinner.service";
import {Warehouse} from "../../../../../../../../common/interfaces/warehouse.interface";
import {Form} from "../../../../../../../../common/interfaces/form.interface";
import ISelectOption = Form.ISelectOption;

@Component({
    selector: "section-procedure-available-locations",
    template: `
        <h1 class="container-heading">Available locations</h1>
        <mat-card>
            <mat-card-content>
                <div>
                    <common-table2 *ngIf="listTable" [settings]="listTable">
                        <div>
                            <button mat-raised-button type="button" (click)="addLocation()" color="accent">
                                Add location
                            </button>
                        </div>

                        <div row1 *ngIf="hubs.length">
                            <mat-form-field>
                                <mat-label>Hub</mat-label>
                                <mat-select [formControl]="hubFilter">
                                    <mat-option [value]="null">
                                        All
                                    </mat-option>
                                    <mat-option *ngFor="let hub of hubs" [value]="hub.value">
                                        {{hub.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </common-table2>
                </div>
                <h2 class="border-bottom-none" *ngIf="orderItems.length">Order items:</h2>
                <div class="margin-bottom-20" *ngIf="orderItems.length">
                    <table class="table">
                        <thead>
                        <tr>
                            <th>Item</th>
                            <th>Serial</th>
                            <th>Quantity</th>
                            <th>Hub</th>
                            <th>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let item of orderItems">
                            <td>{{item.item}}</td>
                            <td>{{item.serial}}</td>
                            <td>{{item.quantity}}</td>
                            <td>
                                <ng-template [ngIf]="item.inventory_conversion">
                                    {{item.inventory_conversion.customers_inventory_name}}
                                    -
                                    {{item.inventory_conversion.customers_sub_inventory}}
                                </ng-template>
                            </td>
                            <td>
                                {{item.status ? item.status.name : ""}}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvailableLocationsModalComponent implements OnInit, OnDestroy {

    protected destroy$: EventEmitter<boolean> = new EventEmitter(false);

    public listTable: Table.ISettings;

    public modal: Modal.IModal;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    public orderItems: Order.IItem[] = [];

    public hubFilter: FormControl = new FormControl([]);

    public hubs: ISelectOption[] = [];

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private spinnerService: SpinnerService,
    ) {
    }

    private getUrl(): Table.ITableApi {
        return {
            url: [this.modal.params.state.section, "warehouse-locations"],
            query: {
                hub_id: this.hubFilter.value,
                relations: [
                    "WarehouseLocationType",
                    "Inventory.InventoryConversion.SubWarehouse",
                ],
                partner_id: this.modal.params.partner_id || null
            },
            version: 3
        };
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {

        this.listTable = {
            table_id: "y8khmbe6iu",
            actions: [
                {
                    name: "wizard",
                    title: "Select",
                    click: (row: any): void => {
                        this.modal.response.emit({
                            name: "set",
                            value: row
                        });
                    }
                }
            ],
            api: this.getUrl(),
            columns: [
                {
                    data: "location",
                    title: "Location"
                },
                {
                    data: "warehouse_location_type.name",
                    name: "WarehouseLocationType.name",
                    title: "Type"
                },
                {
                    data: "inventory.inventory_conversion.customers_inventory_name",
                    name: "Inventory.InventoryConversion.customers_inventory_name",
                    title: "Hub",
                    render: (row: any): string => {
                        if (!row.inventory || !row.inventory?.inventory_conversion) {
                            return "";
                        }
                        return row.inventory?.inventory_conversion?.customers_inventory_name
                            + " - " +
                            row.inventory?.inventory_conversion?.customers_sub_inventory;
                    },
                    sortable: false
                },
            ],
        };
        this.changeDetectorRef.markForCheck();
    }

    private async getOrderItems(): Promise<any> {
        // this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["order", this.modal.params.orderRef, "items", "inbound"], {}, {
                with_count_transactions: true
            });

        // this.spinnerService.hide();
        if (data) {
            this.orderItems = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    private async getHubs(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.get(`${this.modal.params.state.section}/hubs`, {
            data_structure: "select",
            partner_id: this.modal.params.partner_id
        });

        this.spinnerService.hide();
        if (data) {
            this.hubs = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    public addLocation(): void {
        this.modal.response.emit({
            name: "add",
            value: null
        });
    }

    public ngOnInit(): void {
        this.prepareList();
        if( this.modal.params.orderRef){
            this.getOrderItems();
        }
        if(this.modal.params.partner_id) {
            this.getHubs();
        }

        this.hubFilter.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(100))
            .subscribe(() => {
                this.tableRef.reload(this.getUrl());
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
