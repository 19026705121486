<common-confirm></common-confirm>

<h1 class="container-heading">{{action | ucfirst}} customer account</h1>

<mat-card>
    <mat-card-content>

        <form *ngIf="formGroup" [formGroup]="formGroup" (submit)="handleFormSubmit()" autocomplete="off" novalidate>

            <div class="group">
                <mat-form-field>
                    <mat-label>placeholder</mat-label>
                    <input matInput formControlName="name" required>
                </mat-form-field>
            </div>

            <div class="actions">
                <button mat-raised-button color="primary" type="submit" class="main" [disabled]="!formGroup.valid">
                    {{action | ucfirst}}
                </button>
                <button mat-raised-button *ngIf="modal.params.canDelete"
                        type="button" color="warn" (click)="delete()">
                    Delete
                </button>
                <button mat-button type="button" (click)="showList()">Cancel</button>
            </div>

        </form>

    </mat-card-content>
</mat-card>
