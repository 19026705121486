import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {Router} from "@angular/router";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {AbstractWizardComponent, WizardStepFactory} from "../../../../../../../common/interfaces/wizard.interface";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {Warehouse} from "../../../../../../../common/interfaces/warehouse.interface";
import {
    WarehouseProceduresInventoryCountScanComponent,
} from "./scan/scan.component";
import {WarehouseProceduresInventoryCountScanResultsComponent} from "./results/results.component";



@Component({
    selector: "section-procedures-inventory-count-wizard",
    templateUrl: "../../../../../../../common/templates/wizard.component.html",
    styleUrls: [
        "../common/wizard.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class WarehouseProceduresInventoryCountWizardComponent extends AbstractWizardComponent implements OnInit {

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private apiService: ApiService,
        private spinnerService: SpinnerService
    ) {
        super(changeDetectorRef);
    }

    /**
     * Get warehouse data
     */
    private async getWarehouse(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["warehouse"]);
        if (data && data[0]) {
            const warehouse: Warehouse.IWarehouse = data[0];

            this.data.scannerKey = warehouse.properties.scanner_key;

            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }


    /**
     * Finish wizard and redirect back to order
     */
    public finish(): void {
        this.router.navigate([
            this.state.section,
            "inventory-count"
        ]);
    }


    public ngOnInit(): void {
        super.ngOnInit();

        this.wizardHeading = "Inventory count" + (this.state.params.ref ? "<b>" + this.state.params.ref + "</b>" : "");
        this.data.wizardType = "count";
        this.data.scannerKey = "";

        this.steps.push(new WizardStepFactory("Scan items", WarehouseProceduresInventoryCountScanComponent));
        this.steps.push(new WizardStepFactory("Scan results", WarehouseProceduresInventoryCountScanResultsComponent));
        this.changeDetectorRef.markForCheck();

        this.getWarehouse();

    }

    public ngConfig(): Base.IConfig {
        return {
            name: "inventory-count",
            actions: {
                "wizard": ["browse_inventory_counts"],
            }
        };
    }

}
