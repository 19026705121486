<div class="padding-top-20 dark-scheme" style="flex-direction: row; flex-wrap: wrap; display: flex;justify-content: space-evenly;">
    <section-dashboard-count-widget [url]='openOutboundOrdersConfig.url' [query]='openOutboundOrdersConfig.query'
        [selected]="currentTableFilters === openOutboundOrdersConfig"
        icon="arrow_upward" color="warn" title="Open Outbound Orders" (click)="onWidgetClick(openOutboundOrdersConfig)">
    </section-dashboard-count-widget>

    <section-dashboard-count-widget [url]='openUrgentOrdersConfig.url' [query]='openUrgentOrdersConfig.query' 
        [selected]="currentTableFilters === openUrgentOrdersConfig"
        icon="report_problem" color="warn" title="Open Urgent Orders" (click)="onWidgetClick(openUrgentOrdersConfig)">
    </section-dashboard-count-widget>

    <section-dashboard-count-widget [url]='openPackedOrdersConfig.url' [query]='openPackedOrdersConfig.query'
        [selected]="currentTableFilters === openPackedOrdersConfig"
        icon="trolley" color="warn" title="Open Packed Orders" (click)="onWidgetClick(openPackedOrdersConfig)">
    </section-dashboard-count-widget>

    <section-dashboard-count-widget [url]='openInboundOrdersConfig.url'
        [selected]="currentTableFilters === openInboundOrdersConfig"
        [query]='openInboundOrdersConfig.query' icon="arrow_downward" color="warn"
        title="Open Inbound Orders" (click)="onWidgetClick(openInboundOrdersConfig)">
    </section-dashboard-count-widget>

</div>

<mat-card>
    <mat-card-content>
        <common-table2 *ngIf="listTable" [settings]="listTable">
        </common-table2>
    </mat-card-content>
</mat-card>