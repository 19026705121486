import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from "@angular/core";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {ToastService} from "../../../../../../common/services/toast.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {Table2Component} from "../../../../../../common/components/table2";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Router} from "@angular/router";
import {FormControl, FormGroup} from "@angular/forms";
import {PartnerService} from "../../../../../../common/services/partner.service";
import {AmplitudeService} from "../../../../../../common/services/amplitude.service";

@Component({
    selector: "section-country-instructions-list",
    template: `

        <common-confirm></common-confirm>
        <h1 class="container-heading">Countries special instructions</h1>
        <mat-card>
            <mat-card-content>

                <mat-tab-group>
                    <mat-tab label="Countries list">
                        <div style="height: 20px"></div>
                        <common-table2 *ngIf="listTable" [settings]="listTable">
                            <div row1>
                                <button mat-raised-button color="primary"
                                        class="main" (click)="add()">
                                    Add
                                </button>
                            </div>
                        </common-table2>
                    </mat-tab>

                    <mat-tab label="Settings">
                        <h2>Settings</h2>
                        <form [formGroup]="settingsForm" (submit)="submit()">
                            <div class="group flex">
                                <div class="full">
                                    <common-form-checkbox
                                            label="Inform on out of the country"
                                            [value]="settingsForm.value.inform_on_out_of_the_country"
                                            (valueChange)="settingsForm.get('inform_on_out_of_the_country')
                                        .setValue($event)">
                                    </common-form-checkbox>
                                </div>
                            </div>

                            <div class="actions">
                                <button mat-raised-button type="submit" color="primary">Save</button>
                            </div>
                        </form>

                    </mat-tab>
                </mat-tab-group>


            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountriesInstructionsListComponent implements OnInit, Base.IComponent {

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.ISettings;

    public readonly state: Base.IState;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    @ViewChild(ConfirmComponent, {static: true})
    public confirmRef: ConfirmComponent;

    public settingsForm: FormGroup = new FormGroup({
        inform_on_out_of_the_country: new FormControl(false),
    });

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private router: Router,
        private toastService: ToastService,
        private partnerService: PartnerService,
        private spinnerService: SpinnerService,

    ) {
    }


    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {

        this.listTable = {
            table_id: "78H3TbyMyx5",
            columns: [
                {
                    data: "country.country_name",
                    title: "Country"
                },
                {
                    data: "address.address",
                    name: "Address.address",
                    title: "Address",
                    render: (row: any): string => {
                        if (!row.address) {
                            return "";
                        }
                        return row.address.address + ", " + row.address.city + ", " + row.address.country;
                    }
                },
                {
                    data: "message",
                    title: "Instructions"
                }
            ],
            actions: [
                {
                    name: "edit",
                    title: "Edit",
                    click: (row: any): void => {
                        this.router.navigate([
                            this.state.section,
                            this.state.component,
                            "edit",
                            "id",
                            row.id
                        ]);
                        AmplitudeService.eventClick("Edit");
                    }
                },
                {
                    name: "delete",
                    title: "Delete",
                    click: async (row: any): Promise<any> => {
                        if (await this.confirmRef.confirm(
                            "Are you sure want to delete current instructions?")) {
                            this.spinnerService.show();
                            const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
                                ["address", "country", "instructions", "" + row.id]);
                            if (type as string === "success") {
                                this.toastService.show(message, "success");
                                this.tableRef.reload();
                            }
                            this.spinnerService.hide();
                        }
                        AmplitudeService.eventClick("Delete");
                    }
                }
            ],
            api: {
                url: ["address", "country", "instructions"]
            }
        };

        this.changeDetectorRef.markForCheck();
    }

    public add(): void {
        this.router.navigate([
            this.state.section,
            this.state.component,
            "add"
        ]);
        AmplitudeService.eventClick("Add new countries special instructions");
    }

    /**
     * Save settings form
     */
    public async submit(): Promise<any> {
        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["address", "country", "instructions", "settings"], this.settingsForm.value);
        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.partnerService.refresh();
        }
    }


    public ngOnInit(): void {

        this.prepareList();

        this.settingsForm.get("inform_on_out_of_the_country")
            .setValue(PartnerService.partner.properties["inform_on_out_of_the_country"]);
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "countries-instructions",
            actions: {
                "browse": ["browse_country_special_instructions"]
            }
        };
    }

}
