import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: "commonFix",
    pure: true
})
export class FixPipe implements PipeTransform {

    public transform(value: string): string {
        return value ? value.replace(" ", "T") + "Z" : "";
    }

}