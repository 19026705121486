import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter, OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Modal} from "../../../../../services/modal.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ToastService} from "../../../../../../common/services/toast.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Form} from "../../../../../../common/interfaces/form.interface";
import ISelectOption = Form.ISelectOption;
import {takeUntil} from "rxjs/operators";

@Component({
    selector: "section-tracking-change",
    templateUrl: "change-tracking.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class TrackingChangeComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    public modal: Modal.IModal;

    public formGroup: FormGroup;

    public couriers: ISelectOption[] = [];

    public courierServices: any[] = [];

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private formBuilder: FormBuilder,
        private toastService: ToastService,
        private spinnerService: SpinnerService
    ) {
    }

    private prepareForm(): void {
        if (this.modal.params.action === "edit_price") {
            this.formGroup = new FormGroup({
                price: new FormControl(null, [Validators.required]),
            });
        } else {
            this.formGroup = new FormGroup({
                tracking_number: new FormControl(null, [Validators.required]),
                courier_slug: new FormControl(null, [Validators.required]),
                courier_service_slug: new FormControl(null, [Validators.required]),
            });

            this.formGroup.controls.courier_slug.valueChanges.pipe(takeUntil(this.destroy$))
                .subscribe((value: string): void => {
                    if (value) {
                        this.getCourierServices(value);
                    } else {
                        this.courierServices = [];
                        this.changeDetectorRef.markForCheck();
                    }
                });
        }

        if (this.modal.params.data) {
            this.formGroup.patchValue(this.modal.params.data);
        }

        this.changeDetectorRef.markForCheck();
    }

    private async getCouriers(): Promise<any> {
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["courier"], {}, {data_structure: "select"});
        if (data) {
            this.couriers = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    private async getCourierServices(slug: string): Promise<any> {
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["courier_service"], {}, {
            courier_slug: slug
        });
        if (data) {
            this.courierServices = data;
        }
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Submit form
     * @returns {Promise<any>}
     */
    public async handleFormSubmit(): Promise<any> {
        if (this.formGroup && this.formGroup.valid) {
            this.spinnerService.show();
            const response: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
                ["courier", "transactions", this.modal.params.data.id], this.formGroup.value);
            this.spinnerService.hide();
            if (response) {
                this.toastService.show(response.message, response.type);
                this.modal.params.data.id = response.data.id;
                this.modal.response.next({
                    name: "value",
                    value: this.modal.params.action
                });
            }
        }
    }

    public ngOnInit(): void {
        this.prepareForm();
        this.getCouriers();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
