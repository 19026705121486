import {Injectable} from "@angular/core";
import {Base} from "../interfaces/base.interfaces";


@Injectable()
export class AppStateService {

    private static _state: Base.IState;

    public static getState(): Base.IState {
        return AppStateService._state;
    }

    public static setState(state: Base.IState): void {
        AppStateService._state = state;
    }

}
