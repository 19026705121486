import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {Table} from "../../../../../../common/components/table/table.component";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {SpinnerService} from "../../../../../../common/services/spinner.service";

export namespace ServiceRequests {

    export interface IOrderAddress {
        address_type: string;
        address: string;
        address2: string;
        address3: string;
        country: string;
        city: string;
        state: string;
        zip: string;
    }

    export interface IOrderData {
        created_at: string | Date;
        incoterms: string;
        ordersaddress: ServiceRequests.IOrderAddress[];
        ref: string;
        ref2: string;
        remarks: string;
        rma: string;
        servicelevel: {
            coverage: {
                display_name: string;
            },
            ordertype: {
                display_name: string;
            },
            shippin_gmode: {
                display_name: string;
            },
            courier_service: {
                service_name: string;
            }
        };
        status: {
            name: string
        };
    }

}

@Component({
    selector: "section-service-request-view",
    templateUrl: "view.component.html",
    styleUrls: [
        "view.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class ServiceRequestViewComponent implements Base.IComponent, OnInit {

    /**
     * In no data of order
     * @type {boolean}
     */
    public isNoData: boolean;

    /**
     * Order data
     * @type {ServiceRequests.IOrderData}
     */
    public orderData: ServiceRequests.IOrderData;

    /**
     * Data & table / list options for order items
     * @type {{data: {inbound: any[], outbound: any[]}, options: Table.IOptions}}
     */
    public orderItemLists: { data: { inbound?: any[], outbound?: any[] }, options: Table.IOptions } = {
        data: {
            inbound: null,
            outbound: null
        },
        options: {
            columns: [
                {
                    data: "serial",
                    title: "Serial number"
                },
                {
                    data: "item",
                    title: "Items"
                },
                {
                    data: "batch",
                    title: "Batch"
                },
                {
                    data: "configurations",
                    title: "Configurations"
                },
                {
                    data: "quantity",
                    title: "Quantity"
                },
                {
                    data: "ref",
                    title: "Ref"
                },
                {
                    data: "servicelevelsubinventory.servicelevel.name",
                    title: "Service level"
                }
            ],
            order: [],
            search: false
        }
    };

    public readonly state: Base.IState;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private title: Title,
        private apiService: ApiService,
        private spinnerService: SpinnerService
    ) {
    }

    /**
     * Get order data
     * @returns {Promise<any>}
     */
    private async getOrderData(): Promise<any> {
        if (!this.state.params.id) {
            return;
        }
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["order", this.state.params.id]);
        this.spinnerService.hide();
        this.orderData = data;
        this.isNoData = !this.orderData;
        this.changeDetectorRef.markForCheck();

        this.getOrderItemsData();
        this.title.setTitle(this.title.getTitle() + " " + this.orderData.ref);
    }

    /**
     * Get order items (inbound &outbound) data
     * @returns {Promise<any>}
     */
    private async getOrderItemsData(): Promise<any> {
        this.spinnerService.show();
        const url: string[] = ["order", this.orderData.ref, "items"];
        const responses: Api.IResponse[] = await Promise.all([
            await this.apiService.request(Api.EMethod.Get, [...url, "inbound"]),
            await this.apiService.request(Api.EMethod.Get, [...url, "outbound"])
        ]);
        const data: any[] = responses.map((response: Api.IResponse): any => response.data);
        this.orderItemLists.data.inbound = data[0];
        this.orderItemLists.data.outbound = data[1];
        this.spinnerService.hide();
        this.changeDetectorRef.markForCheck();
    }

    public ngOnInit(): void {
        this.getOrderData();

    }

    public ngConfig(): Base.IConfig {
        return {
            name: "service-request",
            actions: {
                "read": ["read_orders", "read_service_requests"]
            }
        };
    }

}
