import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";

import * as moment from "moment";
import {FormControl} from "@angular/forms";
import {takeUntil} from "rxjs/operators";
import {HelpersService} from "../../services/helpers.service";
import {MatAccordion} from "@angular/material/expansion";
import {DateRangeMomentValue} from "../form/date/range.component";
import {AmplitudeService} from "../../services/amplitude.service";

@Component({
    selector: "common-filter",
    templateUrl: "filter.component.html",
    styleUrls: ["filter.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class FilterComponent implements OnInit, OnDestroy {
    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild(MatAccordion, {static: false})
    public accordion: MatAccordion;

    @Input()
    public filters: any = {};

    @Input()
    public componentName: string = "";

    @Input()
    public configId: string;

    @Output()
    public changeEvent: EventEmitter<any> = new EventEmitter<any>(true);

    public dateRange: FormControl = new FormControl(null);

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        public helpers: HelpersService,

    ) {
    }

    private setDates(): void {
        let from: any = null;
        let to: any = null;
        if (this.filters) {
            if (this.filters.date_from && this.filters.date_from[0].value) {
                from = moment(this.filters.date_from[0].value, "YYYY.MM.DD").startOf("day");
            }
            if (this.filters.date_to && this.filters.date_to[0].value) {
                to = moment(this.filters.date_to[0].value, "YYYY.MM.DD").endOf("day");
            }
        }

        this.dateRange.setValue({start: from, end: to});

        this.changeDetectorRef.markForCheck();
    }

    /**
     * On filter input changes
     */
    public onChange(event: any = null): void {
        if (event) {
            this.filters = event;
            this.setDates();
            this.changeDetectorRef.detectChanges();
        }
        setTimeout(() => {
            AmplitudeService.eventClick("Show "+this.componentName);
        this.changeEvent.emit(this.filters);
        }, 20);
    }

    /**
     * Deselect all filters options
     */
    public clearFilters(): void {
        if (this.filters) {
            this.filters.date_from = [{value: null, selected: false}];
            this.filters.date_to = [{value: null, selected: false}];
            for (const filter of Object.keys(this.filters)) {
                for (const option of this.filters[filter]) {
                    option.selected = false;
                }
            }
        }
        this.dateRange.setValue({start: null, end: null});
        AmplitudeService.eventClick("Clear filter "+this.componentName);
        this.changeEvent.emit(this.filters);
    }


    public ngOnInit(): void {

        this.setDates();

        this.dateRange.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value: DateRangeMomentValue): void => {

                this.filters.date_from = [
                    {
                        value: value.start ? value.start.format("YYYY.MM.DD") : null,
                        selected: !!value.start
                    }
                ];
                this.filters.date_to = [
                    {
                        value: value.end ? value.end.format("YYYY.MM.DD") : null,
                        selected: !!value.end
                    }
                ];
            });

        // setTimeout(() => {
        //     this.accordion.openAll();
        // }, 500);
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
