<h1 class="container-heading">Add status</h1>

<mat-card>
    <mat-card-content>
        <form *ngIf="formGroup" [formGroup]="formGroup" (submit)="handleFormSubmit()" autocomplete="off" novalidate>

            <div class="group">

                <mat-form-field class="full">
                    <mat-label>Status</mat-label>
                    <mat-select formControlName="status">
                        <mat-option *ngFor='let status of statuses' [value]="status.toLowerCase()">
                            {{ status }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="full">
                    <mat-label>Message</mat-label>
                    <input matInput formControlName="messages" required>
                </mat-form-field>

                <common-autocomplete [options]="countries" placeholder="Location" [allowFreeInput]="false"
                                     class="full" (optionSelected)="onCountrySelected($event)"
                                     [errorText]="'Select country'" [required]="true"
                                     [image]="{path:'assets/images/flags',ext:'svg'}"
                                     [value]="formGroup ? formGroup.value.location : null"></common-autocomplete>


                <div class="full">
                    <common-form-date
                            label="Expected delivery"
                            [value]="formGroup.value.expected_delivery"
                            [required]="true"
                            (valueChangeMoment)="formGroup.get('expected_delivery').setValue($event)"
                    ></common-form-date>
                </div>

                <mat-form-field class="full" *ngIf="formGroup.get('pod')">
                    <mat-label>Signed by</mat-label>
                    <input matInput formControlName="pod" required>
                </mat-form-field>

                <div class="full" *ngIf="formGroup.get('delivered_at')">
                    <common-form-date
                            label="Delivered at"
                            [value]="formGroup.value.delivered_at"
                            [required]="true"
                            (valueChangeMoment)="formGroup.get('delivered_at').setValue($event)"
                    ></common-form-date>
                </div>

                <div class="actions">
                    <button [disabled]="!this.formGroup || !this.formGroup.valid"
                            mat-raised-button color="primary" type="submit" class="main">
                        Save
                    </button>
                </div>
            </div>

        </form>
    </mat-card-content>
</mat-card>
