<mat-card>

    <h1>Convert serial based item to quantity based</h1>

    <form [formGroup]="form" (submit)="submit()">
        <div class="group flex">
            <div class="half" *ngIf="part_masters">
                <common-form-select [options]="part_masters"
                                    [search]="true"
                                    [required]="true"
                                    label="Item"
                                    (valueChange)="form.get('part_master_id').setValue($event)"
                ></common-form-select>
            </div>

            <div class="half" *ngIf="warehouses">
                <common-form-select [options]="warehouses"
                                    [search]="true"
                                    [required]="true"
                                    label="Warehouse"
                                    (valueChange)="form.get('warehouse_id').setValue($event)"
                ></common-form-select>
            </div>

            <mat-form-field class="full">
                <mat-label>Remark</mat-label>
<textarea row="5" matInput  required formControlName="remark"></textarea>
            </mat-form-field>

        </div>
        <div class="actions">
            <button type="submit" mat-raised-button color="accent" [disabled]="!form.valid">
                Update
            </button>
        </div>
    </form>

</mat-card>
<common-confirm></common-confirm>
