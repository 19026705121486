<mat-card>
    <h1 class="text-center">Match your column title with our standard column titles</h1>

    <div class="margin-bottom-10">
        If you uploaded the wrong csv file, you can go back and upload another one.
    </div>

    <div>
        <b>CSV Templates </b>
        <mat-form-field *ngIf="options">
            <mat-select [formControl]="templateSelect">
                <mat-option *ngFor="let key of options | keys" [value]="key">
                    {{key}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <form style="max-width: none" class="margin-left-0 margin-right-0" *ngIf="data && ready" [formGroup]="formGroup">
        <div class="group flex">
            <div class="third">
                <b>Your CSV column title</b>
            </div>
            <div class="third">
                <b>What information is this?</b>
            </div>
            <div class="third">
                <b>First data set of your CSV</b>
            </div>
        </div>
        <ng-template ngFor let-row_key [ngForOf]="data.mapData | keys">
            <div class="group flex margin-bottom-10">
                <div class="third">
                    {{row_key}}
                </div>

                <div class="third" *ngIf="options">
                    <common-form-select [options]="mappedOptions"
                                        [value]="formGroup.value[row_key]"
                                        [search]="true"
                                        (valueChange)="setValue(row_key, $event)"
                    ></common-form-select>

                    <div *ngIf="formGroup.get(row_key)" class="margin-bottom-10 text-disabled">
                        <small>{{getDescription(formGroup.get(row_key).value)}}</small>
                    </div>
                </div>

                <div class="third">
                    {{data.mapData[row_key]}}
                </div>
                <div class="full">
                    <mat-divider></mat-divider>
                </div>
            </div>
        </ng-template>

        <div class="actions padding-top-20">
            <button type="button" mat-raised-button color="accent" (click)="testRun()" [disabled]="!allowTest">
                Test run
            </button>
        </div>

        <div class="actions padding-top-20" *ngIf="showReplaceDataOption">
            <div class="margin-bottom-20 text-error ">
                File has lines already exist: <b>{{this.duplicatesList | join:', '}}</b>.
            </div>
            <mat-button-toggle-group [formControl]="drop_existing_lines">
                <mat-button-toggle [value]="false">Add lines</mat-button-toggle>
                <mat-button-toggle [value]="true">Replace existing lines</mat-button-toggle>
            </mat-button-toggle-group>

            <div *ngIf="!drop_existing_lines.value" class="text-error padding-top-20">
                Adds new lines for a new or existing order
            </div>

            <div *ngIf="drop_existing_lines.value" class="text-error padding-top-20">
                Updates existing lines and order
            </div>
        </div>


    </form>
</mat-card>
