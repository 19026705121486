<h1 class="container-heading">{{state.action === "add" ? "Add" : "Edit"}} integration</h1>

<mat-card>
    <mat-card-content>
        <form *ngIf="formGroup" [formGroup]="formGroup" (submit)="submit()">
            <div class="group flex">
                <mat-form-field class="half">
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="name" required>
                </mat-form-field>

                <mat-form-field class="half">
                    <mat-label>Description</mat-label>
                    <input matInput formControlName="description" required>
                </mat-form-field>

                <mat-checkbox formControlName="internal" color="primary">
                    Visible for partner only
                </mat-checkbox>
            </div>

            <div class="group flex">
                <div class="half">
                    <button type="button" mat-raised-button color="accent"
                            (click)="uploadAvatar()">
                        Upload icon
                    </button>
                </div>
                <div class="half">
                    <img [src]="formGroup.value.icon" alt="">
                </div>
            </div>

            <div class="group flex">
                <div class="full">
                    <b>Instructions:</b>
                </div>
                <div class="full">
                    <common-form-editor
                            [value]="editorInitialValue"
                            (valueChange)="formGroup.get('instructions').setValue($event)"></common-form-editor>
                </div>
            </div>

            <h2>Fileds:</h2>

            <div formArrayName="properties" class="properties">
                <ng-template ngFor let-field let-i="index" [ngForOf]="formGroup.controls.properties.controls">
                    <mat-card>
                        <div class="flex row space-between align-center">
                            <div class="max"><b>Field {{i + 1}}</b></div>
                            <div class="move">
                                <mat-form-field>
                                    <mat-label>Move to #</mat-label>
                                    <input type="text" matInput #moveToPos/>
                                    <mat-icon matSuffix class="pointer"
                                              color="primary"
                                              (click)="moveTo(i, 0 + moveToPos.value)">
                                        check
                                    </mat-icon>
                                </mat-form-field>
                            </div>
                            <div class="sort flex row align-center">
                                <div>
                                    Sort:
                                </div>
                                <button mat-icon-button [disabled]="i === 0" color="primary" (click)="moveUp(i)"
                                        matTooltip="Move up" type="button">
                                    <mat-icon>arrow_drop_up</mat-icon>
                                </button>
                                <button mat-icon-button
                                        [disabled]="i === formGroup.controls.properties.controls.length -1"
                                        color="primary" (click)="moveDown(i)" matTooltip="Move down" type="button">
                                    <mat-icon>arrow_drop_down</mat-icon>
                                </button>
                                <button mat-icon-button color="warn"
                                        [disabled]="!fieldTypes[field.value.type].delete"
                                        (click)="removeField(i)" matTooltip="Remove" type="button">
                                    <mat-icon>remove</mat-icon>
                                </button>
                            </div>
                        </div>
                        <div>
                            <div class="group flex" [formGroupName]="i">
                                <mat-form-field class="half">
                                    <mat-label>Label</mat-label>
                                    <input matInput formControlName="label" required>
                                </mat-form-field>

                                <mat-form-field class="half">
                                    <mat-label>Name (readonly)</mat-label>
                                    <input matInput formControlName="name" readonly>
                                </mat-form-field>

                                <mat-form-field class="half" *ngIf="fieldTypes[field.value.type].type">
                                    <mat-label>Type</mat-label>
                                    <mat-select formControlName="type" required>
                                        <mat-option *ngFor="let type of (fieldTypes | keys)" [value]="type"
                                                    [disabled]="type === 'status'">
                                            {{type}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field class="half" *ngIf="!fieldTypes[field.value.type].type">
                                    <mat-label>Type</mat-label>
                                    <mat-select formControlName="type" required>
                                        <mat-option [value]="field.value.type">
                                            {{field.value.type}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field class="half">
                                    <mat-label>Size</mat-label>
                                    <mat-select formControlName="size" required>
                                        <mat-option *ngFor="let size of fieldSizes" [value]="size">
                                            {{size}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <div class="half" *ngIf="fieldTypes[field.value.type].required">
                                    <mat-checkbox formControlName="required">Required</mat-checkbox>
                                </div>

                                <div class="half" *ngIf="fieldTypes[field.value.type].multiple">
                                    <mat-checkbox formControlName="multiple">Multiple</mat-checkbox>
                                </div>

                                <mat-form-field class="full" *ngIf="fieldTypes[field.value.type].options">
                                    <mat-label>Values</mat-label>
                                    <mat-chip-grid #chipGrid>
                                        <mat-chip-row matChipRemove
                                                      *ngFor="let chip of formGroup.controls.properties.controls[i].get('values').value"
                                                      [selectable]="true" [removable]="true"
                                                      (removed)="removeValue(chip, i)">
                                            {{chip}}
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip-row>
                                        <input [matChipInputFor]="chipGrid"
                                               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                               [matChipInputAddOnBlur]="true"
                                               (matChipInputTokenEnd)="addValue($event, i)"/>
                                    </mat-chip-grid>
                                </mat-form-field>

                                <mat-form-field class="full" *ngIf="fieldTypes[field.value.type].url">
                                    <mat-label>URL</mat-label>
                                    <input matInput formControlName="url">
                                </mat-form-field>

                                <mat-form-field class="full" *ngIf="fieldTypes[field.value.type].description">
                                    <mat-label>Description</mat-label>
                                    <textarea matInput formControlName="description"
                                    ></textarea>
                                </mat-form-field>

                            </div>
                        </div>
                    </mat-card>
                </ng-template>
            </div>

            <div class="actions">
                <button (click)="addField()" type="button" class="mat-mdc-raised-button mdc-button mat-primary">Add
                    field
                </button>
            </div>

            <div class="actions">
                <button type="submit" [disabled]="!formGroup.valid"
                        class="mat-mdc-raised-button mdc-button mat-primary main">Save
                </button>
            </div>
        </form>
    </mat-card-content>
</mat-card>
