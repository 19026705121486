import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Base} from "../../../../../common/interfaces/base.interfaces";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../common/services/api.service";
import {ToastService} from "../../../../../common/services/toast.service";
import {User} from "../../../../../common/interfaces/user.interface";
import {UserService} from "../../../../../common/services/user.service";
import {FormControl} from "@angular/forms";
import {debounceTime, takeUntil} from "rxjs/operators";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {ConfirmComponent} from "../../../../../common/components/confirm/confirm.component";
import {SpinnerService} from "../../../../../common/services/spinner.service";
import {Table} from "../../../../../common/interfaces/table.interface";
import {PartnerService} from "../../../../../common/services/partner.service";
import {SCHEDULER_DATES_OPTIONS} from "../../../../../common/components/scheduler";

@Component({
    selector: "section-partner-settings",
    templateUrl: "settings.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    styleUrls: [
        "settings.component.scss"
    ]
})
export class PartnerSettingsComponent implements Base.IComponent, OnInit, OnDestroy {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    public readonly state: Base.IState;

    public partner: User.IPartner;

    public clientSearch: FormControl = new FormControl(null);
    public clientsList: User.IData[] = [];

    public managerSearch: FormControl = new FormControl(null);
    public managersList: User.IData[] = [];

    /**
     * Selector for user info section
     * @type {number}
     */
    public showSection: number = 1;

    public usersHasAccess: User.IData[] = [];

    public userSearch: FormControl = new FormControl(null);

    public users: User.IData[] = [];

    public properties: any;

    public resetControl: FormControl = new FormControl([]);

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public attachmentsTable: Table.ISettings;

    public scheduledTasksTable: Table.ISettings;
    public options: any = SCHEDULER_DATES_OPTIONS;

    public resetData: { val: string, name: string }[] = [
        {val: "Addresses", name: "Addresses"},
        {val: "Branches", name: "Branches"},
        {val: "Contacts", name: "Contacts"},
        {val: "ServiceLevels", name: "Service Levels"},
        {val: "Boxes", name: "Boxes"},
        {val: "Configurations", name: "Configurations"},
        {val: "Contracts", name: "Contracts"},
        {val: "CourierTransactions", name: "Courier Transactions"},
        {val: "Coverages", name: "Coverages"},
        {val: "Customers", name: "Customers"},
        {val: "ExecutedItems", name: "Executed Items"},
        {val: "Inspections", name: "Inspections"},
        {val: "Inventories", name: "Inventories"},
        {val: "InventoryConversions", name: "Inventory Conversions"},
        {val: "InventoryAllocations", name: "Inventory Allocations"},
        {val: "Orders", name: "Orders"},
        {val: "OrdersItems", name: "Orders Items"},
        {val: "OrdersRemarks", name: "Orders Remarks"},
        {val: "OrdersRemarksAttachments", name: "Orders Remarks Attachments"},
        {val: "Parcels", name: "Parcels"},
        {val: "Partmasters", name: "Partmasters"},
        {val: "Requests", name: "Requests"},
        {val: "RequestsKinds", name: "Requests Kinds"},
        {val: "Serials", name: "Serials"},
        {val: "ServiceLevelsSubInventories", name: "Service Levels Sub Inventories"},
        {val: "ServiceLevelsTrackings", name: "Service Levels Trackings"},
        {val: "ServiceRequests", name: "Service Requests"},
        {val: "Shipments", name: "Shipments"},
        {val: "StockReservations", name: "Stock Reservations"},
        {val: "WarehouseOrders", name: "Warehouse Orders"},
        {val: "WarehouseTransactions", name: "Warehouse Transactions"},
    ];

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private userService: UserService,
        private partnerService: PartnerService,
        private spinnerService: SpinnerService
    ) {
    }

    /**
     * Get partner data
     * @returns {Promise<any>}
     */
    private async getData(): Promise<any> {
        this.spinnerService.show();
        const data = await this.partnerService.refresh();
        if (data) {
            this.partner = data;
            this.changeDetectorRef.markForCheck();
            this.getUsersHasAccess();
        }
        this.spinnerService.hide();
    }

    private async getPropertiesFields(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["model_field"], {}, {model: "App\\Partner"});

        if (data) {
            this.properties = data.fields;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get clients list for autocomplete
     * @param {string} search_by
     * @returns {Promise<any>}
     */
    private async getClients(search_by: string): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["user", "list"],
            {}, {search_by});
        if (data) {
            this.clientsList = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Update clients list in partner
     * @returns {Promise<any>}
     */
    private async updateClients(): Promise<any> {
        this.spinnerService.show();
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["partner", "link_clients", "" + this.partner.id], {
                clients: this.partner.clients.map((client: User.IData): number => Number(client.id))
            });
        if (response) {
            this.toastService.show(response.message, response.type as string);
            this.getData();
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get managers list for autocomplete
     * @param {string} search_by
     * @returns {Promise<any>}
     */
    private async getManagers(search_by: string): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["user", "list"],
            {}, {search_by});
        if (data) {
            this.managersList = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Update manaers list in partner
     * @returns {Promise<any>}
     */
    private async updateManagers(): Promise<any> {
        this.spinnerService.show();
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["partner", "link_managers", "" + this.partner.id], {
                managers: this.partner.managers.map((manager: User.IData): number => Number(manager.id))
            });
        if (response) {
            this.toastService.show(response.message, response.type as string);
            this.getData();
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get that has access to partner
     * @returns {Promise<any>}
     */
    private async getUsersHasAccess(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["partner", "" + this.partner.id, "users"]);
        if (data) {
            this.usersHasAccess = data;
        }
        this.spinnerService.hide();
    }

    /**
     * Get all available users
     * @returns {Promise<any>}
     */
    private async getAllUsers(search_by: string = null): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["user", "list"], {},
            {
                search_by
            });
        if (data) {
            const exclude: number[] = this.usersHasAccess.map((user: User.IData): number => user.id);
            this.users = data.filter((user: User.IData): boolean => !exclude.includes(user.id));
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Update users list in partner
     * @returns {Promise<any>}
     */
    private async updateUsers(): Promise<any> {
        this.spinnerService.show();
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["partner", "link_users", "" + this.partner.id], {
                users: this.usersHasAccess.map((user: User.IData): number => Number(user.id))
            });
        if (response) {
            this.toastService.show(response.message, response.type as string);
            this.getUsersHasAccess();
            this.users = [];
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private prepareAttachmentsTable(): void {
        const columns: Table.ICol[] = [
            {
                data: "path",
                title: "",
                name: "path",
                sortable: false,
                render: (row: any): string => {
                    return `<a href="` + row.path + `"><button type="button"
                        class="mat-mdc-mini-fab mdc-fab mat-primary" title="`
                        + row.name + `"><mat-icon class="mat-icon material-icons">cloud_download</mat-icon>
                        </button></a>`;
                }
            },
            {
                data: "name",
                title: "Name",
                name: "name",
                sortable: false,
            },
            {
                data: "content_type",
                title: "Type",
                name: "content_type",
                sortable: false
            },
            {
                data: "created_at",
                title: "Date",
                name: "created_at",
                sortable: false
            }
        ];
        this.attachmentsTable = {
            table_id: "3uZ8lV5my5U",
            columns,
            api: {
                url: ["remark", "partner", "attachments"],
                query: {}
            }
        };
    }

    private prepareScheduledTasksTable(): void {
        const columns: Table.ICol[] = [
            {
                data: "ref",
                title: "Ref"
            },
            {
                data: "taskable.name",
                title: "Name"
            },
            {
                data: "type",
                title: "Type"
            },
            {
                data: "type",
                title: "",
                render: (row: any): string => {
                    if (row.partner) {
                        return `<img src="${row.partner.icon_path}" alt="" class="logo">`;
                    } else {
                        return "";
                    }
                },
                searchable: false,
                sortable: false
            },
            {
                data: "repeat",
                title: "Repeat",
                render: (row: any): string => {
                    switch (row.repeat) {
                        case "once":
                            return `once ${row.year}-${this.options.month[row.month - 1]}-${row.date
                                .padStart(2, "0")}
                at ${row.hour.padStart(2, "0")}:${row.min.padStart(2, "0")}`;
                        case "yearly":
                            return `every year ${this.options.month[row.month - 1]}-${row.date.padStart(2, "0")}
                at ${row.hour.padStart(2, "0")}:${row.min.padStart(2, "0")}`;
                        case "monthly":
                            return `every month ${row.date}
                at ${row.hour.padStart(2, "0")}:${row.min.padStart(2, "0")}`;
                        case "weekly":
                            return `every week on ${this.options.day[row.day]}
                at ${row.hour.padStart(2, "0")}:${row.min.padStart(2, "0")}`;
                        case "daily":
                            return `every day
                at ${row.hour.padStart(2, "0")}:${row.min.padStart(2, "0")}`;
                        case "hourly":
                            return `every hour at ${row.min.padStart(2, "0")} min`;
                        default:
                            return "";
                    }
                }
            },
            {
                data: "created_at",
                title: "Created at"
            },
        ];

        if (this.userService.validatePermissions("browse_admin")) {
            columns.push({
                data: "user.name",
                name: "User.name",
                title: "User"
            });
        }

        this.scheduledTasksTable = {
            table_id: "5tgcHjkf68g",
            columns,
            api: {
                url: ["partner", "tasks"],
                query: {}
            }
        };
    }


    /**
     * Add selected client to partner
     * @param {MatAutocompleteSelectedEvent} event
     */
    public addClient(event: MatAutocompleteSelectedEvent): void {
        this.partner.clients.push(event.option.value);
        this.clientSearch.reset();
        this.updateClients();
    }

    /**
     * Remove client from partner
     * @param {number} index
     */
    public removeClient(index: number): void {
        this.partner.clients.splice(index, 1);
        this.updateClients();
    }

    /**
     * Add manager to partner
     * @param {MatAutocompleteSelectedEvent} event
     */
    public addManager(event: MatAutocompleteSelectedEvent): void {
        this.partner.managers.push(event.option.value);
        this.managerSearch.reset();
        this.updateManagers();
    }

    /**
     * Remove manager from partner
     * @param {number} index
     */
    public removeManager(index: number): void {
        this.partner.managers.splice(index, 1);
        this.updateManagers();
    }


    /**
     * Add user to partner
     * @param {MatAutocompleteSelectedEvent} event
     */
    public addUser(event: MatAutocompleteSelectedEvent): void {
        this.usersHasAccess.push(event.option.value);
        this.userSearch.reset();
        this.updateUsers();
    }

    /**
     * Remove user from partner
     * @param {number} index
     */
    public removeUser(index: number): void {
        this.usersHasAccess.splice(index, 1);
        this.updateUsers();
    }

    /**
     * Reset all partner data
     */
    public async reset(): Promise<any> {
        if (await this.confirmRef.confirm("Do you want to reset?")) {
            const response: Api.IResponse = await
                this.apiService.request(Api.EMethod.Post, ["partner", "reset"], {
                    partner_id: this.partner.id,
                    relations: this.resetControl.value
                });
            if (response) {
                this.toastService.show(response.message, <string>response.type);
                this.getData();
            }
        }
    }

    /**
     * Update partner properties
     * @param values
     */
    public async saveProperties(values: any): Promise<any> {
        this.spinnerService.show();
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["partner", "" + this.partner.id], {
                properties: values
            });
        if (response) {
            this.toastService.show(response.message, response.type as string);
            this.getData();
            this.userService.getUser();
            this.users = [];
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    public async ngOnInit(): Promise<any> {
        const [type, slug]: string[] = this.state.section.split("/");
        for (const partner of this.userService.data.partners) {
            if (partner.slug === slug) {
                this.partner = partner;
            }
        }

        this.prepareAttachmentsTable();
        this.prepareScheduledTasksTable();

        if (this.partner) {
            this.getPropertiesFields();
            this.getData();
        }

        this.clientSearch.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: string): void => {
                this.getClients(value);
            });

        this.managerSearch.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: string): void => {
                this.getManagers(value);
            });

        this.userSearch.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: string): void => {
                this.getAllUsers(value);
            });
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "settings",
            actions: {
                "browse": ["browse_admin"]
            }
        };
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
