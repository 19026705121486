import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from "@angular/core";
import {Router} from "@angular/router";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Table2Component} from "../../../../../../common/components/table2";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {CommonPartMasterViewComponent} from "../../../../common/components/part-master";

@Component({
    selector: "section-part-master-list",
    template: `
        <common-confirm></common-confirm>
        <h1 class="container-heading">Part master</h1>

        <mat-card>
            <mat-card-content>
                <common-table2 *ngIf="listTable" [settings]="listTable"></common-table2>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminPartMasterListComponent implements OnInit, Base.IComponent {

    public state: Base.IState;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.ISettings;
    /* Table options */

    public showExportButton: boolean = false;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private modalService: ModalService,
    ) {
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        this.listTable = {
            table_id: "nOu831pUy9K",
            actions: [
                // {
                //     name: "view",
                //     title: "",
                //     click: async (row: any): Promise<any> => {
                //         await this.modalService.open(CommonPartMasterViewComponent, {
                //             item: row.item,
                //             add: false,
                //             modalWidth: 900,
                //         });
                //     }
                // }
            ],
            columns: [
                {
                    data: "partner.icon_path",
                    name: "Partner.display_name",
                    title: "Partner",
                    render: (row: any): string => {
                        if (row.partner) {
                            return "<img src='" + row.partner.icon_path + "' alt=''>";
                        }
                        return "";
                    },
                },
                {
                    "data": "item",
                    "title": "item",
                },
                {
                    "data": "item_id",
                    "title": "item id",
                },
                {
                    "data": "description",
                    "title": "description",
                },
                {
                    "data": "group",
                    "title": "group",
                },
                {
                    "data": "customs_value",
                    "title": "Customs value",
                },
                {
                    "data": "customs_values_currency",
                    "title": "Customs values currency",
                },
                {
                    "data": "declared_value",
                    "title": "Declared value",
                },
                {
                    "data": "declared_value_currency",
                    "title": "Declared value currency",
                },
                {
                    "data": "serial",
                    "title": "Serial",
                },
                {
                    "data": "ECCN",
                    "title": "ECCN",
                },
                {
                    "data": "length",
                    "title": "Length",
                },
                {
                    "data": "width",
                    "title": "Width",
                },
                {
                    "data": "height",
                    "title": "Height",
                },
                {
                    "data": "weight",
                    "title": "Weight",
                },
                {
                    "data": "mass_unit",
                    "title": "Mass Unit",
                },
                {
                    "data": "config",
                    "title": "Config",
                },
                {
                    "data": "lot",
                    "title": "Lot",
                },
                {
                    "data": "batch",
                    "title": "Batch",
                },
                {
                    "data": "revision",
                    "title": "Revision",
                },
                {
                    "data": "contains_battery",
                    "title": "Contains battery",
                },
                {
                    "data": "manufacturer",
                    "title": "Manufacturer",
                },
                {
                    "data": "license_exception_code",
                    "title": "License exception code",
                },
                {
                    "data": "part_master_uom_id",
                    "title": "Part master uom id",
                },
                {
                    "data": "country",
                    "title": "Country",
                },
                {
                    "data": "HSC",
                    "title": "HSC",
                },
                {
                    "data": "default_quantity",
                    "title": "Default quantity",
                },
                {
                    "data": "family",
                    "title": "Family",
                },
                {
                    "data": "is_virtual",
                    "title": "Is virtual",
                },
                {
                    "data": "is_active",
                    "title": "Is active",
                },
                {
                    "data": "required_serial_wh_outbound",
                    "title": "Required serial WH outbound",
                },
                {
                    "data": "required_serial_wh_inbound",
                    "title": "Required serial WH inbound",
                },
                {
                    "data": "required_serial_order_creation_outbound",
                    "title": "Required serial order creation outbound"
                },
                {
                    "data": "required_serial_order_creation_inbound",
                    "title": "Required serial order creation inbound"
                },
                {
                    "data": "required_batch_outbound",
                    "title": "Required batch outbound",
                },
                {
                    "data": "required_batch_inbound",
                    "title": "Required batch inbound",
                },
                {
                    "data": "required_revision_outbound",
                    "title": "Required revision outbound",
                },
                {
                    "data": "required_revision_inbound",
                    "title": "Required revision inbound",
                },
                {
                    "data": "required_lot_outbound",
                    "title": "Required lot outbound",
                },
                {
                    "data": "required_lot_inbound",
                    "title": "Required lot inbound",
                },
                {
                    "data": "return_instructions",
                    "title": "Return instructions",
                },
                {
                    "data": "billable",
                    "title": "Billable",
                },
                {
                    "data": "dangerous_goods",
                    "title": "Dangerous goods",
                },
                {
                    "data": "days_to_inspection",
                    "title": "Days to inspection",
                },
            ],
            api: {
                url: ["admin", "part-masters"],
                query: {
                    relations: [
                        "Partner:id,icon_path,display_name"
                    ]
                },
                version: 3
            },
            export: {
                file_name: "Part-Masters",
            }
        };

        this.changeDetectorRef.markForCheck();
    }

    public async addPart(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(CommonPartMasterViewComponent, {
            add: true
        });
        if (response) {
            this.tableRef.reload();
        }
    }


    public upload(): void {
        this.router.navigate([
            this.state.section,
            "csv-import",
            "wizard",
            "type",
            "partmaster",
            "back_to",
            btoa(this.router.url)
        ]);
    }

    public ngOnInit(): void {
        this.prepareList();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "part-master-list", // path
            actions: {
                "browse": ["browse_part_masters"]
            }
        };
    }

}
