import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {UntypedFormBuilder, FormGroup, Validators} from "@angular/forms";
import {LoginService} from "../../../common/services/login.service";
import {Api, ApiService} from "../../../common/services/api.service";
import {ToastService} from "../../../common/services/toast.service";
import {Router} from "@angular/router";

@Component({
    selector: "app-register",
    templateUrl: "register.component.html",
    styleUrls: [
        "register.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class RegisterComponent implements OnInit, OnDestroy {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    /**
     * Login form control group
     * @type {FormGroup}
     */
    public form: FormGroup = this.formBuilder.group({
        name: [null, [Validators.required]],
        email: [null, [Validators.required]],
        password: [null, [Validators.required]],
        password_confirmation: [null, [Validators.required]],
        token: [null, [Validators.required]],
    });

    /**
     * Is progress bar visible
     * @type {boolean}
     */
    public progress: boolean = false;

    public showPass: boolean = false;
    public showConfirm: boolean = false;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private title: Title,
        private formBuilder: UntypedFormBuilder,
        private loginService: LoginService,
        private apiService: ApiService,
        private toastService: ToastService,
        private router: Router
    ) {
    }

    /**
     * Login
     * @returns {Promise<any>}
     */
    public async submit(): Promise<any> {
        this.progress = true;
        const {type, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["user", "register"], this.form.value);
        if (type as string === "success") {
            this.toastService.show(message, "success");
            this.router.navigate(["/login"]);
        }
        this.progress = false;
        this.changeDetectorRef.markForCheck();
    }


    public ngOnInit(): void {
        this.title.setTitle("Logivice / Register");
        if (this.loginService.token) {
            this.router.navigate(["/entrance"]);
        }
        const url_params: URLSearchParams = new URLSearchParams(window.location.search);
        this.form.get("token").setValue(url_params.get("token"));
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
