import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, ViewEncapsulation} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {Base} from "../../../../../../../../common/interfaces/base.interfaces";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../../common/interfaces/wizard.interface";
import {Api, ApiService} from "../../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../../common/services/toast.service";
import {HelpersService} from "../../../../../../../../common/services/helpers.service";
import {Warehouse} from "../../../../../../../../common/interfaces/warehouse.interface";
import {ModalService} from "../../../../../../../services/modal.service";
import {ReplaySubject} from "rxjs";
import {debounceTime, takeUntil} from "rxjs/operators";
import {SpinnerService} from "../../../../../../../../common/services/spinner.service";
import {User} from "../../../../../../../../common/interfaces/user.interface";

@Component({
    selector: "section-warehouse-procedures-wizard-transfer-hub-to-hub",
    templateUrl: "hub-to-hub.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class WarehouseProceduresWizardTransferHubToHubComponent
    extends AbstractWizardStepComponent implements OnDestroy {

    private state: Base.IState;

    private selectedPartner: User.IPartner;

    public warehouse: Warehouse.IWarehouse;

    public partners: any[] = [];

    public partnerSelect: FormControl = new FormControl();

    public oldHubSelect: FormControl = new FormControl();

    public old_hubs: Warehouse.IHub[] = [];

    public new_hubs: Warehouse.IHub[] = [];

    public locations: any[] = [];

    public inventories: Warehouse.IInventory[] = [];
    public inventories_filtered: ReplaySubject<any> = new ReplaySubject(1);
    public inventories_search_input: FormControl = new FormControl(null);

    public transferForm: FormGroup = new FormGroup({
        old_hub_id: new FormControl(null, [Validators.required]),
        new_hub_id: new FormControl(null, [Validators.required]),
        inventory_id: new FormControl(null, [Validators.required]),
        quantity: new FormControl(null, [Validators.required]),
        location_id: new FormControl(null),
    });


    public max_qty: number = 0;

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        public helperService: HelpersService,
        private apiService: ApiService,
        private toastService: ToastService,
        private router: Router,
        private modalService: ModalService,
        private spinnerService: SpinnerService
    ) {
        super(changeDetectorRef);
    }

    /**
     * Get all related partners
     */
    private async getPartners(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["warehouse", "partners"]);
        if (data) {
            this.partners = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get all partner hubs
     */
    private async getHubs(): Promise<any> {
        this.spinnerService.show();
        this.apiService.setHeaders({"Partner": this.selectedPartner.slug});
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["hub"]);
        if (data) {
            this.old_hubs = data;
            this.new_hubs = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get inventories in hub
     * @param hub
     */
    private async getInventoriesInHub(hub: Warehouse.IHub): Promise<any> {
        this.spinnerService.show();
        this.apiService.setHeaders({"Partner": this.selectedPartner.slug});
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["inventory", hub.customers_inventory_name, hub.customers_sub_inventory]);
        if (data) {
            this.inventories = data;
            this.inventories_filtered.next(this.inventories);

            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get all related locations
     */
    private async getLocationsInHub(id: number): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["hub", "" + id, "locations"], {}, {
                data_structure: "select"
            });
        if (data) {
            this.locations = data;

            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get max quantity of selected inventory and validate it
     * @param inventory_id
     */
    private getMaxQty(inventory_id: number): void {
        this.transferForm.get("quantity").clearValidators();
        for (const inventory of this.inventories) {
            if (inventory.id === inventory_id) {
                this.max_qty = inventory.quantity;
                this.transferForm.get("quantity")
                    .setValidators([Validators.required, Validators.max(this.max_qty)]);
                this.transferForm.get("quantity").updateValueAndValidity();
                this.changeDetectorRef.markForCheck();
                break;
            }
        }
    }

    /**
     * Submit form
     */
    public async submit(): Promise<any> {
        this.spinnerService.show();
        const {type, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["inventory", "move", "hub-to-hub"], this.transferForm.value);
        this.spinnerService.hide();
        if (type as string === "success") {
            this.toastService.show(message, "success");
            this.changeDetectorRef.markForCheck();
            this.getInventoriesInHub(this.oldHubSelect.value);
        }
        this.transferForm.reset();
        this.changeDetectorRef.markForCheck();
    }


    /**
     * Initialize step
     * @param data
     * @returns {Promise<any>}
     */
    public async init(data: Wizard.IData): Promise<any> {
        this.state = data.state;
        this.warehouse = data.warehouse;


        this.result.emit({
            action: "result",
            value: true
        });

        this.getPartners();

        this.partnerSelect.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value: User.IPartner): void => {
                if (value) {
                    this.selectedPartner = value;
                    this.getHubs();
                }
            });


        this.transferForm.updateValueAndValidity();
        this.changeDetectorRef.markForCheck();

        this.oldHubSelect.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((hub: Warehouse.IHub): void => {
                this.transferForm.get("old_hub_id").setValue(hub.id);
                this.new_hubs = this.old_hubs.filter((oHub: Warehouse.IHub): boolean => {
                    return oHub.id !== hub.id;
                });
                this.changeDetectorRef.markForCheck();
                this.getInventoriesInHub(hub);
            });

        this.inventories_search_input.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: string): void => {
                if (!value) {
                    this.inventories_filtered.next(this.inventories);
                } else {
                    value = value.toLocaleLowerCase();

                    this.inventories_filtered
                        .next(this.inventories.filter((inventory: Warehouse.IInventory): boolean => {
                            return inventory.item.toLowerCase().includes(value)
                                || (inventory.serial && inventory.serial.toLowerCase().includes(value));
                        }));
                }
            });

        this.transferForm.get("inventory_id").valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value: number): void => {
                this.getMaxQty(value);
            });

        this.transferForm.get("new_hub_id").valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((id: number): void => {
                if (id) {
                    this.getLocationsInHub(id);
                }
            });


    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}
