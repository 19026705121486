import {Injectable} from "@angular/core";
import {UserService} from "./user.service";
import {HelpersService} from "./helpers.service";

declare const MediaRecorder: any;

@Injectable()
export class MediaScreenService {

    private mediaRecorder: any;

    static recordState: boolean = false;

    public constructor(private userService: UserService) {
    }

    public async makeScreenshot(): Promise<{ name: string, url: string }> {

        const width = screen.width * (window.devicePixelRatio || 1);
        const height = screen.height * (window.devicePixelRatio || 1);

        const stream = await this.recordScreen({
            audio: false,
            video: {
                width,
                height,
                frameRate: 1,
            },
        });

        const video = document.createElement("video");
        const blob: any = await new Promise((resolve, reject) => {
            video.onloadedmetadata = () => {
                video.play();
                video.pause();
                const canvas = document.createElement("canvas");
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                const context = canvas.getContext("2d");

                context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

                canvas.toBlob(blob => {
                    resolve(blob);
                }, "image/jpeg", 1);
            };
            video.srcObject = stream;
        });

        setTimeout(() => {
            stream.getTracks().forEach(function (track) {
                track.stop();
            });
        }, 500);

        const file = new File([blob], HelpersService.randomString() + ".jpeg");

        return await this.userService.uploadFile(file);
    }

    public async startScreenRecord(): Promise<{ name: string, url: string }> {
        MediaScreenService.recordState = true;

        return new Promise(async (resolve): Promise<any> => {
            let stream = await this.recordScreen({
                audio: false,
                video: {mediaSource: "screen"}
            });
            this.mediaRecorder = new MediaRecorder(stream);

            let recordedChunks = [];

            this.mediaRecorder.ondataavailable = function (e) {
                if (e.data.size > 0) {
                    recordedChunks.push(e.data);
                }
            };
            this.mediaRecorder.onstop = async (): Promise<any> => {

                const blob = new Blob(recordedChunks, {
                    type: "video/webm"
                });

                const file = new File([blob], HelpersService.randomString() + ".webm");

                const path: { name: string, url: string } = await this.userService.uploadFile(file);

                recordedChunks = [];

                stream.getTracks().forEach(function (track) {
                    track.stop();
                });

                MediaScreenService.recordState = false;
                resolve(path);
            };
            this.mediaRecorder.start(200);
        });
    }

    public stopScreenRecord(): void {
        if (this.mediaRecorder.state !== "inactive") {
            this.mediaRecorder.stop();
        }
    }


    private async recordScreen(params: any = {}): Promise<any> {
        return await (navigator.mediaDevices as any).getDisplayMedia(params);
    }
}

