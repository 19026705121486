import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {StorageService} from "../../../../../../common/services/storage.service";
import {ModalService} from "../../../../../services/modal.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {IActivity} from "../../../../../../common/interfaces/activity.interface";
import {IPagination} from "../../../../../../common/components/pagination/pagination.component";
import {FormControl} from "@angular/forms";
import {debounceTime, takeUntil} from "rxjs/operators";
import {UserService} from "../../../../../../common/services/user.service";


@Component({
    selector: "section-activities-list",
    template: `
        <h1 class="container-heading">Unread activities</h1>
        <div class="actions">
            <button mat-raised-button type="button" color="accent"
                    (click)="readAll()">
                Read all
            </button>
        </div>
        <mat-card *ngIf="activities">
            <mat-card-content>
                <div>
                    <mat-form-field>
                        <mat-label>Search</mat-label>
<input type="search" matInput [formControl]="searchControl" >
                    </mat-form-field>
                </div>
                <mat-list>
                    <ng-template ngFor let-activity [ngForOf]="activities.data">
                        <section-activity-view [activity]="activity"
                                               [showMarkRead]="true"
                                               [state]="state"
                                               (updates)="getData()"></section-activity-view>
                        <mat-divider></mat-divider>
                    </ng-template>
                </mat-list>
                <common-pagination [data]="activities" pid="0z2UKPs2n9"
                                   (goToPage)="getData($event.page, $event.per_page, searchControl.value)">
                </common-pagination>

                <ng-container *ngIf="!activities.data.length">
                    <p class="text-center text-disabled">No activities yet</p>
                </ng-container>
            </mat-card-content>
        </mat-card>

        <mat-card *ngIf="!activities">
            <common-simple-spinner></common-simple-spinner>
        </mat-card>
    `,
    styleUrls: ["list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class ActivitiesListComponent implements OnInit, Base.IComponent, OnDestroy {

    protected destroy$: EventEmitter<boolean> = new EventEmitter(false);

    public readonly state: Base.IState;

    public activities: IPagination<IActivity>;

    public searchControl: FormControl = new FormControl(null);

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private storageService: StorageService,
        private modalService: ModalService,
        private spinnerService: SpinnerService,
        private changeDetectorRef: ChangeDetectorRef,
        private userService: UserService
    ) {
    }

    /**
     * Get order shipments
     * @returns {Promise<any>}
     */
    public async getData(page: number = 1, per_page: number = null, search_by: string = null): Promise<any> {
        if (per_page === null) {
            per_page = this.userService.data.settings.default_per_page;
        }
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["activities", "unread"], {}, {
                data_structure: "paginated",
                page,
                per_page: per_page,
                search_by,
                search_in: ["log_name", "description"]

            });
        if (data) {
            this.activities = data;
        }
        this.changeDetectorRef.markForCheck();
    }

    public async readAll(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
            ["activities", "unread"]);
        this.spinnerService.hide();
        if (data) {
            this.getData();
        }
    }


    public ngOnInit(): void {
        this.getData();

        this.searchControl.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(1500))
            .subscribe((value: string): void => {
                this.getData(1, 10, value);
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "activities",
            actions: {
                "browse": ["browse_announcements"]
            }
        };
    }
}
