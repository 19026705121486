import {takeUntil} from "rxjs/operators";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import {Router} from "@angular/router";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {Modal, ModalService} from "../../../../../../services/modal.service";
import {WarehouseInventoryCountFormComponent} from "../../index";
import {Push} from "../../../../../../../common/interfaces/push.interface";
import {PusherService} from "../../../../../../../common/services/pusher.service";
import {Table} from "../../../../../../../common/interfaces/table.interface";
import {Table2Component} from "../../../../../../../common/components/table2/table.component";
import {Api3Service} from "../../../../../../../common/services/api3.service";
import {ToastService} from "../../../../../../../common/services/toast.service";

@Component({
    selector: "section-inventory-count-list",
    template: `

        <h1 class="container-heading">Inventory counts</h1>
        <mat-card>
            <mat-card-content>
                <common-table2 *ngIf="listTable" [settings]="listTable">
                    <div row1>
                        <button mat-raised-button color="primary" (click)="addCount()">Add</button>
                    </div>
                </common-table2>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WarehouseInventoryCountListComponent implements Base.IComponent, OnInit, OnDestroy {
    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    public readonly state: Base.IState;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.ISettings;

    /* Table options */

    public constructor(
        private router: Router,
        private api3Service: Api3Service,
        private modalService: ModalService,
        private toastService: ToastService,
        private pusherService: PusherService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        this.listTable = {
            actions: [
                {
                    name: "wizard",
                    title: "Open",
                    click: (row: any): void => {
                        if (row.job_complete) {
                            this.router.navigate([
                                this.state.section,
                                this.state.component,
                                "wizard",
                                "count",
                                row.id
                            ]);
                        }
                    },
                    disabledFn: (row: any): boolean => {
                        return !row.job_complete;
                    }
                },
                {
                    name: "download",
                    title: "Full Report",
                    click: async (row: any): Promise<any> => {
                        const {message, code}: Api.IResponse = await this.api3Service.get(
                            `${this.state.section}/inventory-counts/${row.id}/report`, {
                                full_report: true
                            });
                        if (code === 200) {
                            this.toastService.show(message, "success");
                        }
                    }
                },
                {
                    name: "download",
                    title: "Errors Report",
                    cssClass: "mat-warn",
                    click: async (row: any): Promise<any> => {
                        const {message, code}: Api.IResponse = await this.api3Service.get(
                            `${this.state.section}/inventory-counts/${row.id}/report`);
                        if (code === 200) {
                            this.toastService.show(message, "success");
                        }
                    },
                    disabledFn: (row: any): boolean => {
                        if (!row.hasScans) {
                            return true;
                        }
                        return row.inventory_count_exceptions_count === 0 && row.inventory_snapshot_count === 0;
                    }
                },
            ],
            columns: [
                {
                    data: "ref",
                    title: "Ref"
                },
                {
                    data: "id",
                    title: "Status",
                    render: (row: any): string => {
                        if (row.hasScans
                            && (row.inventory_count_exceptions_count > 0 || row.inventory_snapshot_count > 0)) {
                            return "Has errors";
                        } else if (row.inventory_count_exceptions_count === 0 && row.inventory_snapshot_count === 0) {
                            return "Good";
                        } else {
                            return "Not scanned";
                        }
                    },
                    cssClassFn: (row: any): string => {
                        return row.inventory_count_exceptions_count > 0 || row.inventory_snapshot_count > 0
                            ? "text-warn" : "text-success";
                    },
                    searchable: false,
                    sortable: false

                },
                {
                    data: "part_masters.*.item",
                    name: "PartMasters.item",
                    title: "Inventory",
                    sortable: false
                },
                {
                    data: "partner.display_name",
                    name: "Partner.display_name",
                    title: "Partner",
                    sortable: false,
                    render: (row: any): string => {
                        return `<img src="${row.partner.icon_path}" alt="${row.partner.display_name}" />`;
                    }
                },
                {
                    data: "user.name",
                    name: "User.name",
                    title: "Creator",
                    sortable: false
                },
                {
                    data: "created_at",
                    title: "Created at"
                },

            ],
            api: {
                url: ["inventory", "counts"],
                query: {
                    appends: [
                        "hasScans"
                    ]
                }
            }
        };
        this.changeDetectorRef.markForCheck();
    }


    public async addCount(): Promise<any> {
        const result: Modal.IResponse = await this.modalService.open(WarehouseInventoryCountFormComponent);
        if (result && result.value) {
            this.pusherService.event.pipe(takeUntil(this.destroy$)).subscribe((event: Push.IData): void => {
                if (event.job_id === result.value) {
                    this.tableRef.reload();
                }
            });
        }
    }

    public ngOnInit(): void {
        this.prepareList();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "inventory-count",
            actions: {
                "browse": ["browse_inventory_counts"]
            }
        };
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
