<h1 class="container-heading">Analytics</h1>
<mat-card>
    <mat-card-content>
        <ng-template [ngIf]="dashType === 'partner' || dashType === 'admin'">

            <div class="orders-and-remarks">
                <div class="orders-donut" *ngIf="ordersByStatus && ordersByStatus.length > 0">
                    <h3 class="graph-title">Shipments status</h3>
                    <div>
                        <b>Filter:</b>
                        &nbsp;
                        <mat-form-field>
                            <mat-label>Show</mat-label>
                            <mat-select [formControl]="donutDaysCount">
                                <mat-option value="7">last 7 days</mat-option>
                                <mat-option value="14">last 14 days</mat-option>
                                <mat-option value="30">last 30 days</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <common-donut-chart [data]="ordersByStatus"></common-donut-chart>

                    <common-calendar-chart *ngIf="ordersByDate"
                                           [size]="calendarSize.length"
                                           [data]="ordersByDate"></common-calendar-chart>
                </div>
            </div>

            <ng-template [ngIf]="dashType === 'admin'">
                <mat-card *ngIf="ordersAvg">
                    <h3 class="graph-title">Partners activities</h3>
                    <common-combo-chart [data]="ordersAvg" [averageColumn]="ordersAvgColumn"
                                        [hAxis]="'Month'" [vAxis]="'Orders'"></common-combo-chart>
                </mat-card>
            </ng-template>

            <mat-card *ngIf="ordersByCountries && ordersByCountries.length > 1">
                <h3 class="graph-title">Orders by countries</h3>
                <common-geo-chart [data]="ordersByCountries"></common-geo-chart>
            </mat-card>

        </ng-template>

        <ng-template [ngIf]="dashType === 'warehouse'">

        </ng-template>

        <ng-template [ngIf]="dashType === 'threepl'">

        </ng-template>
    </mat-card-content>
</mat-card>
