import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Request} from "../../../../../../common/interfaces/request.interface";
import {FormControl, Validators} from "@angular/forms";
import {IPagination} from "../../../../../../common/components/pagination/pagination.component";
import {Order} from "../../../../../../common/interfaces/order.interface";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {ReplaySubject, Subscription} from "rxjs";
import {take, takeUntil} from "rxjs";
import * as moment from "moment-timezone";
import {Moment} from "moment-timezone";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {User} from "../../../../../../common/interfaces/user.interface";
import {UserService} from "../../../../../../common/services/user.service";
import {MessageFormComponent} from "../../../../../../common/components/message-form/form.component";
import {FileUploadComponent} from "../../../../../../common/components/file-upload/file-upload.component";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {MatDatepicker} from "@angular/material/datepicker";
import {Api3Service} from "../../../../../../common/services/api3.service";
import {TIME_ZONES} from "../../../../../../common/common.module";

@Component({
    selector: "section-requests-view",
    templateUrl: "view.component.html",
    styleUrls: ["view.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class RequestsViewComponent implements Base.IComponent, OnInit, OnDestroy {

    protected destroy$: EventEmitter<boolean> = new EventEmitter(false);

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild("iTickerRef", {static: false})
    public iTickerRef: ElementRef;

    @ViewChild("remindPicker", {static: false})
    public remindPicker: MatDatepicker<any>;

    @ViewChild("timelinePicker", {static: false})
    public timelinePicker: MatDatepicker<any>;

    public readonly state: Base.IState;

    public data: Request.IData;

    public remarkSearch: FormControl = new FormControl(null);

    public remarks: IPagination<Order.IRemark>;

    public messages: IPagination<any>;

    public messagesCount: number = 0;

    public messageSearch: FormControl = new FormControl(null);

    public requestTitle: FormControl = new FormControl(null, [Validators.required]);

    public requestTitleEdit: boolean = false;

    public requestStatus: FormControl = new FormControl(null, [Validators.required]);

    public requestStatusEdit: boolean = false;

    public requestAssignee: FormControl = new FormControl(null, [Validators.required]);

    public requestCreator: FormControl = new FormControl(null, [Validators.required]);

    public requestTeam: FormControl = new FormControl(null, [Validators.required]);

    public requestTeamEdit: boolean = false;

    public requestAssigneeEdit: boolean = false;

    public requestPriority: FormControl = new FormControl(null, [Validators.required]);

    public requestPriorityEdit: boolean = false;

    public requestCreatorEdit: boolean = false;

    public requestDescription: FormControl = new FormControl(null, [Validators.required]);

    public requestDescriptionEdit: boolean = false;

    public requestEstHoursEdit: boolean = false;

    public requestEstHours: FormControl = new FormControl(null, [Validators.required]);

    public requestHoursEdit: boolean = false;

    public requestHours: FormControl = new FormControl(null, [Validators.required]);

    public requestKind: FormControl = new FormControl(null, [Validators.required]);

    public requestKindEdit: boolean = false;

    public requestParticipants: FormControl = new FormControl([]);

    public requestParticipantsEdit: boolean = false;

    public participants: any[] = [];

    public participantsFiltered: any[] = [];

    public requestParticipantsSearch: FormControl = new FormControl(null);

    public users: { name: string, value: any }[] = [];

    public teams: { name: string, value: any }[] = [];

    public kinds: { name: string, value: any }[] = [];

    public mentionUsers: User.IMentionUser[] = [];

    public usersFiltered: ReplaySubject<any> = new ReplaySubject<any>(1);

    public requestUserSearch: FormControl = new FormControl(null);

    public activities: IPagination<any>;

    public remindDateMin: Moment = moment().add(1, "day");

    public remindDate: FormControl = new FormControl(null);

    public creationDate: FormControl = new FormControl(null);

    public closeDate: FormControl = new FormControl(null);

    public remarkTypes: { [key: string]: string } = {};

    public me: User.IData;

    public editorInitialValue: string = null;

    protected interval: any;

    public currentTime: string;

    public timezone: string;

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        protected router: Router,
        protected apiService: ApiService,
        protected api3Service: Api3Service,
        protected toastService: ToastService,
        protected modalService: ModalService,
        protected userService: UserService,
        protected helperService: HelpersService,
        protected spinnerService: SpinnerService
    ) {
        this.me = userService.data;
    }

    /**
     * Get request messages count
     * @returns {Promise<any>}
     */
    protected async getMessagesCount(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["request", this.state.params.id, "message"], {}, {
                data_structure: "count"
            });

        if (data) {
            this.messagesCount = data;
            this.changeDetectorRef.markForCheck();
            if (this.messagesCount > 0) {
                this.getMessages();
            }
        }
        this.spinnerService.hide();
    }


    /**
     * Get remark types
     * @returns {Promise<any>}
     */
    protected async getRemarkTypes(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["remark", "order", "types"]);
        if (data) {
            for (const d of data) {
                this.remarkTypes["" + d.id] = d.name;
            }
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    protected async getMentionUsers(): Promise<any> {
        this.mentionUsers = await this.helperService.getMentionUsers();
        this.changeDetectorRef.markForCheck();
    }

    private async getPaticipants(): Promise<any> {
        this.spinnerService.show();
        const [type, slug]: string[] = this.state.section.split("/", 2);
        let path: string[] = [type, "users"];
        if (type === "admin") {
            path = ["user", "list"];
        }
        this.participants = [];
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            path, {}, {});
        if (data) {
            this.participants = data;
        }
        this.participantsFiltered = this.participants;
        this.changeDetectorRef.markForCheck();
        this.spinnerService.hide();
    }

    protected prepareListeners(): void {
        this.creationDate.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: string): void => {
            if (value && value !== this.data.created_at) {
                this.update("created_at", value);
            }
        });

        this.closeDate.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: string): void => {
            if (value && value !== this.data.closed_on) {
                this.update("closed_on", value);
            }
        });
    }

    protected customSetup(): any {
        // some custom setup for child components
    }

    protected getTimeInTimezone(timezone: string): string {
        return moment.tz(timezone).format("DD.MM.yyyy HH:mm");
    }


    /**
     * Get request data
     * @returns {Promise<any>}
     */
    public async getData(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["request", this.state.params.id]);
        this.spinnerService.hide();
        if (data) {
            this.data = data;
            this.requestTitle.setValue(this.data.title);
            this.requestEstHours.setValue(this.data.estimated_billable_hours);
            this.requestHours.setValue(this.data.billable_hours);
            this.requestStatus.setValue(this.data.status);
            this.requestCreator.setValue(this.data.creator.id);
            this.requestTeam.setValue(this.data.team ? this.data.team.id : null);
            this.requestKind.setValue(this.data.request_kind ? this.data.request_kind_id : null);
            this.requestAssignee.setValue(this.data.assignee ? this.data.assignee.id : null);
            this.requestDescription.setValue(this.data.description);
            this.requestParticipants.setValue(this.data.participants ? this.data.participants.map(p => p.id) : []);

            this.editorInitialValue = this.data.description;

            this.requestPriority.setValue(this.data.priority);
            this.creationDate.setValue(this.data.created_at);
            this.closeDate.setValue(this.data.closed_on);
            this.changeDetectorRef.markForCheck();
            if (this.data.order) {
                this.getRemarks();
            }
            this.getActivities();
            this.getMessagesCount();

            if (this.data?.order?.main_address?.time_zone) {
                this.timezone = this.data.order.main_address.time_zone;
                this.currentTime = this.getTimeInTimezone(this.timezone);

                this.interval = setInterval(() => {
                    this.currentTime = this.getTimeInTimezone(this.timezone);
                }, 60000);

            } else {
                this.currentTime = "Unknown time";
            }
        }
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Get
     * @param {number} page
     * @returns {Promise<any>}
     */
    public async getActivities(page: number = 1): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["request", "" + this.data.id, "activities"], {}, {
                data_structure: "paginated",
                page,
                per_page: 5,
                appends: [
                    "activity_model",
                    "activity_id"
                ],
                sort: {
                    data: "created_at",
                    dir: "desc"
                }
            });

        if (data) {
            this.activities = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get users list
     * @returns {Promise<any>}
     */
    public async getUsers(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["user", "list"]);
        if (data) {
            this.users = data.map((user: User.IData): any => {
                return {name: user.name, value: user.id};
            });
            this.usersFiltered.next(this.users);
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get teams list
     * @returns {Promise<any>}
     */
    public async getTeams(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["team"], {}, {
                category: "request"
            });
        if (data) {
            this.teams = data.map((user: User.IData): any => {
                return {name: user.name, value: user.id};
            });
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    public async getKinds(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["request", "kind"], {}, {
                data_structure: "select",
                category: "request"
            });
        if (data) {
            this.kinds = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get order remarks
     * @param {number} page
     * @param {string} search
     * @param {number} per_page
     * @returns {Promise<any>}
     */
    public async getRemarks(page: number = 1, search: string = null, per_page: number = null): Promise<any> {
        this.spinnerService.show();
        if (per_page === null) {
            per_page = this.userService.data.settings.default_per_page;
        }

        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `${this.state.section}/orders/${this.data.order.id}/remarks`, {}, {
                data_structure: "paginated",
                page,
                per_page,
                search_by: this.remarkSearch.value,
                search_in: ["subject", "message"],
                show_system_remarks: true,
                relations: [
                    "OrderRemarkType:id,name",
                    "Owner:id,name,email,avatar",
                    "Child.Attachments",
                    "Child.Owner:id,name,email,avatar",
                    "Attachments",
                    "Requests:id,status,order_remark_id,request_kind_id",
                    "Requests.RequestKind:id,name"
                ]
            });


        if (data) {
            this.remarks = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get request messages paginated list
     * @param {number} page
     * @param {string} search
     * @param {number} per_page
     * @returns {Promise<any>}
     */
    public async getMessages(page: number = 1, search: string = null, per_page: number = null): Promise<any> {
        this.spinnerService.show();
        if (per_page === null) {
            per_page = this.userService.data.settings.default_per_page;
        }
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["request", this.state.params.id, "message"], {}, {
                data_structure: "paginated",
                page,
                per_page,
                search_by: search
            });

        if (data) {
            this.messages = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Update request params
     * @param key
     * @param {string} value
     * @returns {Promise<any>}
     */
    public async update(
        key: "status" | "status_quietly" | "title" | "assignee" | "creator" | "message" | "priority"
            | "kind" | "participants" | "estimated_billable_hours" | "billable_hours"
            | "created_at" | "closed_on" | "team" | "order",
        value: string | number
    ): Promise<any> {

        const body: any = {};
        switch (key) {
            case "assignee":
                body.assignee_id = value;
                break;
            case "creator":
                body.creator_id = value;
                break;
            case "team":
                body.team_id = value;
                break;
            case "status_quietly":
                key = "status";
                body[key] = value;
                body.quietly = true;
                break;
            case "order":
                body.order_ref = value;
                break;
            case "kind":
                body.request_kind_id = value;
                break;
            case "participants":
                body.participants_ids = value;
                break;
            default:
                body[key] = value;
        }

        this.spinnerService.show();

        const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["request", this.state.params.id, key], body);

        this.spinnerService.hide();

        if (type as string === "success") {
            this.toastService.show(message, "success");
            this.getData();
            return true;
        }
        return false;
    }


    /**
     * Copy ITicket to clipboard
     */
    public copyITicket(): void {
        this.iTickerRef.nativeElement.select();
        document.execCommand("Copy");
        this.toastService.show("Copied to clipboard", "success");
    }

    /**
     * Show new message form
     * @param params
     */
    public async newMessage(params?: any): Promise<any> {
        return new Promise<any>(async (resolve: any): Promise<any> => {
            const response: Modal.IResponse = await this.modalService.open(MessageFormComponent, {
                item_id: this.data.id,
                order_remark_type_id: this.data.order_remark_type_id,
                to: this.data.to,
                cc: this.data.cc,
                bcc: this.data.bcc,
                url: "request",
                hideContacts: true,
                request_id: this.state.params.id,
                ...params
            });
            if (response && response.name === "submit") {
                this.getMessagesCount();
                this.getData();
                resolve(true);
            } else {
                resolve(false);
            }
        });

    }

    /**
     * Add new attachment
     * @param params
     */
    public async newAttachment(params?: any): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(FileUploadComponent, {
            url: ["user", "file"],
            accept: [
                "gif", "png", "jpg", "jpeg", "tiff", "doc",
                "docx", "xls", "xlsx", "csv", "ppt", "pptx", "msg", "txt", "pdf", "xlsb"
            ]
        });
        if (response && response.value && response.value.data && response.value.data.path) {
            const uploaded: any = {name: response.value.data.name, url: response.value.data.path};
            const body: any = [];
            body.push(uploaded);
            const resp: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
                ["request", this.state.params.id, "attachment"], {files: body});
            this.getData();
            this.changeDetectorRef.markForCheck();
        }
    }

    public async deleteAttachment(id: number): Promise<any> {
        if (!await this.confirmRef.confirm("Delete attachment?")) {
            return;
        }

        this.spinnerService.show();

        const resp: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
            ["request", this.state.params.id, "attachment", "" + id]);

        this.spinnerService.hide();

        this.getData();
    }

    public async editMessage(message: any): Promise<any> {
        return new Promise<any>(async (resolve: any): Promise<any> => {
            message.item_id = message.request_id;
            message.edit = true;
            const response: Modal.IResponse = await this.modalService.open(MessageFormComponent, {
                ...message, url: "request"
            });
            if (response && response.name === "submit") {
                this.getMessagesCount();
                resolve(true);
            } else {
                resolve(false);
            }
        });

    }

    /**
     * Resolve with message
     */
    public resolveMessage(): void {
        this.newMessage({solution: true}).then((res: boolean): void => {
            if (res) {
                this.update("status", "Solved");
            }
        });
    }

    /**
     * Set on hold with message
     */
    public holdMessage(): void {
        this.newMessage().then((res: boolean): void => {
            if (res) {
                this.update("status", "On-hold");
            }
        });
    }

    /**
     * Set reminder
     * @param status
     */
    public reminder(status?: string): void {
        this.remindPicker.open();
        const subs: Subscription = this.remindDate.valueChanges.pipe(take(1))
            .subscribe(async (value: Moment): Promise<any> => {
                const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
                    ["request", "" + this.data.id, "reminder"], {
                        remind_date: value.format("YYYY-MM-DD HH:mm:ss")
                    });

                if (type as string === "success") {
                    this.toastService.show(message, "success");
                    if (status) {
                        this.update("status", status);
                    }
                }
            });
        this.remindPicker.closedStream.pipe(take(1)).subscribe((): void => subs.unsubscribe());

    }

    /**
     * Mark message as rquest solution
     * @param {number} id
     * @returns {Promise<any>}
     */
    public async markAsSolution(id: number): Promise<any> {
        this.spinnerService.show();
        const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["request", "message", "" + id, "solution"], {});

        if (type as string === "success") {
            this.toastService.show(message, "success");
            this.getData();
            this.getMessages();
        }
        this.spinnerService.hide();
    }

    /**
     * Add/remove request to favorites
     */
    public async favorite(): Promise<any> {
        this.spinnerService.show();
        const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["request", "" + this.data.id, "favorite"]);
        this.spinnerService.hide();

        if (type as string === "success") {
            this.toastService.show(message, "success");
            this.getData();
        }
    }

    /**
     * Delete request after confirm
     */
    public async delete(): Promise<any> {
        if (await this.confirmRef.confirm("Are you sure want to delete this request?")) {
            this.spinnerService.show();
            const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
                ["request", "" + this.data.id]);
            this.spinnerService.hide();

            if (type as string === "success") {
                this.toastService.show(message, "success");
                this.router.navigate([
                    this.state.section,
                    this.state.component === "followups" ? "followups-all" : "support-requests-all"
                ]);
            }
        }
    }

    /**
     * Redirect to linked order
     */
    public goToOrder(): void {
        this.router.navigate([
            "/partner",
            this.data.partner.slug,
            "orders",
            "view",
            "id",
            this.data.order.id
        ]);
    }

    public cancelRequestParticipantsEdit(): void {
        this.requestParticipants.setValue(this.data.participants ? this.data.participants.map(p => p.id) : []);
    }

    public async setTimeline(dateRange: string): Promise<any> {
        this.spinnerService.show();
        const [from, to] = dateRange.split(" ~ ");
        const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["request", "" + this.data.id, "timeline"], {
                timeline_from: moment(from).format("YYYY-MM-DD HH:mm"),
                timeline_to: moment(to).format("YYYY-MM-DD HH:mm")
            });

        this.spinnerService.hide();
        this.changeDetectorRef.markForCheck();
        if (type as string === "success") {
            this.toastService.show(message, "success");
            this.getData();
        }
    }

    get getPriorityColor(): string {
        switch (this.data.priority) {
            case "Normal":
                return "blue";
            case "High":
                return "orange";
            case "Critical":
                return "red";
            default:
                return "black";
        }
    }

    public ngOnInit(): void {
        this.getRemarkTypes();
        this.getUsers();
        this.getTeams();
        this.getKinds();
        this.getMentionUsers();
        this.getPaticipants();

        this.getData().then((): void => {
            this.prepareListeners();
            this.customSetup();
        });

        this.requestUserSearch.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value: string): void => {
                if (value) {
                    this.usersFiltered.next(
                        this.users.filter((user: { name: string }): boolean =>
                            user.name.toLowerCase().indexOf(value) > -1)
                    );
                } else {
                    this.usersFiltered.next(this.users);
                }
            });

        this.requestParticipantsSearch.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value: string): void => {
                if (value) {
                    this.participantsFiltered =
                        this.participants.filter((user: { name: string }): boolean =>
                            user.name.toLowerCase().indexOf(value) > -1);
                } else {
                    this.participantsFiltered = this.participants;
                }
            });
    }

    get sortedParticipants(): any[] {
        return this.participantsFiltered.sort((a, b) => {
            const aSelected = this.requestParticipants.value.includes(a.id);
            const bSelected = this.requestParticipants.value.includes(b.id);

            if (aSelected && !bSelected) {
                return -1;
            } else if (!aSelected && bSelected) {
                return 1;
            } else {
                return 0;
            }
        });
    }

    public ngOnDestroy(): void {
        clearInterval(this.interval);
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "support-requests",
            actions: {
                "view": ["read_requests"]
            }
        };
    }

    public async downloadAllAttachments(): Promise<any> {
        this.spinnerService.show();
        const {data, message} = await this.apiService.request(Api.EMethod.Get,
            ["request", "" + this.data.id, "attachments", "all"]);
        if (data) {
            this.toastService.show(message, "success");
        }
        this.spinnerService.hide();
    }
}
