import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, Validators} from "@angular/forms";
import {StepperSelectionEvent} from "@angular/cdk/stepper";
import {MatStepper} from "@angular/material/stepper";
import {Router} from "@angular/router";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Api} from "../../../../../../common/services/api.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Api3Service} from "../../../../../../common/services/api3.service";
import {ShippyProPickupWizardStep3Component} from "./step3/step3.component";
import {ShippyProPickupWizardStep1Component} from "./step1/step1.component";
import {ShippyProPickupWizardStep2Component} from "./step2/step2.component";

@Component({
    selector: "section-shippy-pro-pickup-wizard",
    templateUrl: "wizard.component.html",
    styleUrls: [
        "wizard.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class ShippyProPickupWizardComponent implements Base.IComponent, OnInit {

    @ViewChild(ShippyProPickupWizardStep1Component, {static: true})
    public step1Form: ShippyProPickupWizardStep1Component;

    @ViewChild(ShippyProPickupWizardStep2Component, {static: true})
    public step2Form: ShippyProPickupWizardStep2Component;

    @ViewChild(ShippyProPickupWizardStep3Component, {static: true})
    public step3Form: ShippyProPickupWizardStep3Component;

    @ViewChild("stepper", {static: true})
    public stepper: MatStepper;

    /**
     * Step1 step state
     * @type {{control: FormControl}}
     */
    public step1: { control: FormControl } = {
        control: new FormControl(null, [Validators.required])
    };

    /**
     * Step2 step state
     * @type {{control: FormControl}}
     */
    public step2: { control: FormControl } = {
        control: new FormControl(null, [Validators.required])
    };

    /**
     * Step3 step state
     * @type {{control: FormControl}}
     */
    public step3: { control: FormControl } = {
        control: new FormControl(null, [Validators.required])
    };

    public readonly state: Base.IState;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private toastService: ToastService,
        private spinnerService: SpinnerService,
        private api3Service: Api3Service
    ) {
    }


    /**
     * Handle step changes
     * @param {StepperSelectionEvent} event
     * @returns {void}
     */
    public async handleStepChanges(event?: StepperSelectionEvent): Promise<any> {
        const step: number = event && event.selectedIndex;
        switch (step) {
            case 1:
                this.step2Form.init(this.state, this.step1.control.value);
                break;
            case 2:
                this.step3Form.init(this.state, this.step2.control.value);
                break;
            default:
                this.step1Form.init(this.state);
                break;
        }
    }

    /**
     * Finish wizart and redirect back to order
     */
    public async finish(): Promise<any> {
        this.spinnerService.show();
        const {data, code}: Api.IResponse = await this.api3Service.request(Api.EMethod.Post,
            `${this.state.section}/shipping/pickup`, this.step3.control.value);

        this.spinnerService.hide();
        if (code === 200) {
            if (this.state.params.back_to) {
                this.router.navigate([
                    atob(this.state.params.back_to)
                ]);
            } else if (this.state.section_type === "partner") {
                this.router.navigate([
                    this.state.section, "orders", "view", "id", this.state.params.order
                ]);
            }
        }
    }

    public async ngOnInit(): Promise<any> {
        this.handleStepChanges();

        this.stepper.selectedIndex = 0;
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "shippy-pickup",
            actions: {
                "add": ["browse_shipments", "browse_parcels"],
                "edit": ["browse_shipments", "browse_parcels"]
            }
        };
    }

}
