<div class="margin-bottom-10">
    <mat-checkbox [formControl]="checked">
        Print me
    </mat-checkbox>
</div>

<div #sticker>
    <div class="sticker">
        <table>
            <tr *ngIf="partner">
                <td colspan="2" class="text-center">
                    <div class="title">{{partner.display_name}}</div>
                </td>
            </tr>

            <tr>
                <td>
                    <div>Pallet ID: <b>{{pack.id}}</b></div>
                    <!--                    <common-barcode [bcValue]="pack.id" [bcDisplayValue]="false"-->
                    <!--                                 bcWidth="1"-->
                    <!--                                 [bcMarginTop]="1"-->
                    <!--                                 [bcMarginLeft]="1"-->
                    <!--                                 [bcMarginRight]="1"-->
                    <!--                                 [bcMarginBottom]="1"-->
                    <!--                                 bcHeight="30"></common-barcode>-->
                </td>
                <td>
                    <div>Boxes qty: <b>{{pack.boxes.length}}</b></div>
                    <!--                    <common-barcode [bcValue]="pack.boxes.length" [bcDisplayValue]="false"-->
                    <!--                                 bcWidth="1"-->
                    <!--                                 [bcMarginTop]="1"-->
                    <!--                                 [bcMarginLeft]="1"-->
                    <!--                                 [bcMarginRight]="1"-->
                    <!--                                 [bcMarginBottom]="1"-->
                    <!--                                 bcHeight="30"></common-barcode>-->
                </td>
            </tr>
            <tr *ngFor="let item of items | keys">
                <td>
                    <div>{{partner ? partner.display_name === 'Annapurna Labs'
                            ? "Annapurna's " : partner.display_name + "'s " : ''}}PN : <b>{{item}}</b></div>
                    <!--                    <common-barcode [bcValue]="item" [bcDisplayValue]="false"-->
                    <!--                                 bcWidth="1"-->
                    <!--                                 [bcMarginTop]="1"-->
                    <!--                                 [bcMarginLeft]="1"-->
                    <!--                                 [bcMarginRight]="1"-->
                    <!--                                 [bcMarginBottom]="1"-->
                    <!--                                 bcHeight="20"></common-barcode>-->
                </td>
                <td>
                    <div>Qty: <b>{{items[item]}}</b></div>
                    <!--                    <common-barcode [bcValue]="items[item]" [bcDisplayValue]="false"-->
                    <!--                                 bcWidth="1"-->
                    <!--                                 [bcMarginTop]="1"-->
                    <!--                                 [bcMarginLeft]="1"-->
                    <!--                                 [bcMarginRight]="1"-->
                    <!--                                 [bcMarginBottom]="1"-->
                    <!--                                 bcHeight="20"></common-barcode>-->

                </td>
            </tr>
            <tr>
                <td colspan="2">
                    {{partner && partner.display_name === 'Annapurna Labs'
                        ? partner.properties.label_instructions : ''}}
                </td>
            </tr>
        </table>
    </div>
</div>
