import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, EventEmitter, OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Modal, ModalService} from "../../../../../../../services/modal.service";
import {Api, ApiService} from "../../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../../common/services/toast.service";
import {Router} from "@angular/router";
import {UserService} from "../../../../../../../../common/services/user.service";
import {HelpersService} from "../../../../../../../../common/services/helpers.service";
import {SpinnerService} from "../../../../../../../../common/services/spinner.service";
import {ConfirmComponent} from "../../../../../../../../common/components/confirm/confirm.component";
import {Table2Component} from "../../../../../../../../common/components/table2";
import {Table} from "../../../../../../../../common/interfaces/table.interface";
import {Api3Service} from "../../../../../../../../common/services/api3.service";
import {FormControl} from "@angular/forms";
import {debounceTime, takeUntil} from "rxjs/operators";


@Component({
    selector: "section-order-view-items-info",
    template: `
        <common-confirm></common-confirm>
        <mat-card>
            <mat-card-content>
                <div class="item-info">
                    <div>Item: <b>{{ item.item }}</b></div>
                    <div>Serial: <b>{{ item.serial }}</b></div>
                    <div>Configurations: <b>{{ item | getConfNames }}</b></div>
                    <div>Batch: <b>{{ item.batch }}</b></div>
                    <div>Quantity: <b>{{ item.quantity }}</b></div>
                    <div>Status: <b>{{ item.status ? item.status.name : "" }}</b></div>
                </div>

                <ng-template #removeAllocationsTemplate>
                    <button type="button"
                            mat-raised-button color="warn"
                            [disabled]="!modal.params.order || modal.params.order.confirmed"
                            (click)="removeAllocations()">
                        <mat-icon>delete</mat-icon>
                        Delete all allocations
                    </button>
                </ng-template>

                <div>
                    <mat-tab-group [selectedIndex]="openTab" dynamicHeight>
                        <mat-tab *ngIf="itemsTableSettings">
                            <ng-template mat-tab-label>
                                <span>Allocated items</span>
                            </ng-template>

                            <div>
                                <common-table2 [settings]="itemsTableSettings" #itemsTableRef
                                               (gotData)="showItemTab = $event.hasData">
                                    <div row1>
                                        <ng-container *ngTemplateOutlet="removeAllocationsTemplate"></ng-container>
                                        <mat-checkbox [formControl]="showAllSerials">
                                            Show all items
                                        </mat-checkbox>
                                    </div>
                                </common-table2>
                            </div>
                        </mat-tab>

                        <mat-tab *ngIf="boxTableSettings">
                            <ng-template mat-tab-label>
                                <span>Allocated boxes</span>
                            </ng-template>

                            <div>
                                <common-table2 [settings]="boxTableSettings" #boxTableRef
                                               (gotData)="showBoxTab = $event.hasData">
                                    <div row1>
                                        <ng-container *ngTemplateOutlet="removeAllocationsTemplate"></ng-container>
                                        <mat-checkbox [formControl]="showAllBoxes">
                                            Show all boxes
                                        </mat-checkbox>
                                    </div>
                                </common-table2>
                            </div>
                        </mat-tab>

                        <mat-tab *ngIf="palletTableSettings">
                            <ng-template mat-tab-label>
                                <span>Allocated pallets</span>
                            </ng-template>

                            <div>
                                <common-table2 [settings]="palletTableSettings" #palletTableRef
                                               (gotData)="showPalletTab = $event.hasData">
                                    <div row1>
                                        <ng-container *ngTemplateOutlet="removeAllocationsTemplate"></ng-container>
                                    </div>
                                </common-table2>
                            </div>
                        </mat-tab>

                        <mat-tab *ngIf="transactionsTableSettings" [disabled]="!showTransactionsTab">
                            <ng-template mat-tab-label>
                                <span>Transactions</span>
                            </ng-template>

                            <div>
                                <common-table2 [settings]="transactionsTableSettings" #transactionTableRef
                                               (gotData)="showTransactionsTab = $event.hasData">
                                    <div row1>
                                        <button color="warn"
                                                mat-raised-button
                                                type="button"
                                                (click)="unlinkTransactions()">
                                            <mat-icon>link_off</mat-icon>
                                            Unlink all transactions
                                        </button>
                                    </div>
                                </common-table2>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </mat-card-content>
        </mat-card>
    `,
    styles: [
        `
            section-order-view-items-info .item-info {
                margin-bottom: 20px;
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
            }

            section-order-view-items-info .item-info div {
                margin-bottom: 5px;
                padding-right: 10px;
                width: 30%;
            }
        `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})

export class OrderItemsInfoComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    public modal: Modal.IModal;

    @ViewChild("palletTableRef", {static: false})
    public palletTableRef: Table2Component;

    @ViewChild("boxTableRef", {static: false})
    public boxTableRef: Table2Component;

    @ViewChild("itemsTableRef", {static: false})
    public itemsTableRef: Table2Component;

    @ViewChild("transactionTableRef", {static: false})
    public transactionTableRef: Table2Component;

    @ViewChild(ConfirmComponent, {static: true})
    public confirmComponentRef: ConfirmComponent;

    public item: any;

    public openTab: number = 0;

    public showAllBoxes: FormControl = new FormControl(false);
    public showAllSerials: FormControl = new FormControl(false);

    public palletTableSettings: Table.ISettings;
    public boxTableSettings: Table.ISettings;
    public itemsTableSettings: Table.ISettings;

    public transactionsTableSettings: Table.ISettings;

    public showPalletTab: boolean = true;
    public showBoxTab: boolean = true;
    public showItemTab: boolean = true;

    public showTransactionsTab: boolean = true;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private modalService: ModalService,
        private toastService: ToastService,
        private router: Router,
        private userService: UserService,
        private spinnerService: SpinnerService
    ) {
    }

    private getItemsTableApi(): Table.ITableApi {
        return {
            url: [
                this.modal.params.state.section,
                "order-items",
                this.item.id,
                "allocations"
            ],
            query: {
                unboxed: !this.showAllSerials.value,
                relations: [
                    "Box:id,ref,pallet_id",
                    "Box.Pallet:id,ref",
                    "InventoryConversion:id,customers_sub_inventory,customers_inventory_name",
                    "WarehouseLocation:id,location"
                ]
            },
            version: 3
        };
    }

    private getBoxesTableApi(): Table.ITableApi {
        return {
            url: [
                this.modal.params.state.section,
                "order-items",
                this.item.id,
                "box-allocations"
            ],
            query: {
                off_pallet: !this.showAllBoxes.value,
                relations: [
                    "Box:id,warehouse_location_id",
                    "Pallet:id,ref",
                    "Box.WarehouseLocation:id,location"
                ],
            },
            version: 3
        };
    }

    /**
     * Prepare table
     * @returns {void}
     */
    private prepareItemsTable(): void {
        this.itemsTableSettings = {
            api: this.getItemsTableApi(),
            actions: [
                {
                    name: "unlink",
                    title: "Unlink",
                    click: (row: any) => {
                        this.removeAllocation(row.id);
                    },
                    disabledFn: (): boolean => {
                        if (this.modal.params.order.confirmed) {
                            return true;
                        }

                        return false;
                    }
                }
            ],
            columns: [
                {
                    data: "ref",
                    title: "Ref"
                },
                {
                    data: "item",
                    title: "Item"
                },
                {
                    data: "batch",
                    title: "Batch"
                },
                {
                    data: "rev",
                    title: "Rev"
                },
                {
                    data: "lot",
                    title: "Lot"
                },
                {
                    data: "batch_details.expiry_date",
                    title: "Expiry date",
                    searchable: false,
                },
                {
                    data: "serial",
                    title: "Serial",
                },
                {
                    data: "related_configurations",
                    title: this.userService.getProperty(
                        "configuration_name",
                        this.modal.params.state,
                        "Configurations"
                    ),
                    render: (row: any): string => {
                        return HelpersService.getConfigNames(row);
                    },
                    searchable: false,
                    sortable: false
                },
                {
                    data: "quantity",
                    title: "Quantity"
                },
                {
                    data: "inventory_conversion.customers_sub_inventory",
                    name: "InventoryConversion.customers_sub_inventory",
                    title: "Hub",
                    render: (row: any): string => {
                        if (row.inventory_conversion.customers_inventory_name) {
                            return `${row.inventory_conversion.customers_inventory_name}
                               ${row.inventory_conversion.customers_sub_inventory}`;
                        } else {
                            return `${row.inventory_conversion.customers_sub_inventory}`;
                        }
                    }
                },
                {
                    data: "box.ref",
                    name: "Box.ref",
                    title: "Box",
                },
                {
                    data: "box.pallet.ref",
                    name: "Box.Pallet.ref",
                    title: "Pallet"
                },
                {
                    data: "warehouse_location.location",
                    name: "WarehouseLocation.location",
                    title: "Location"
                },
                {
                    data: "created_at",
                    name: "created_at",
                    title: "Created At",
                    searchable: false
                }
            ],
            export: {file_name: "item info"}
        };
        this.changeDetectorRef.markForCheck();
    }

    private prepareBoxesTable(): void {
        this.boxTableSettings = {
            actions: [
                {
                    name: "unlink",
                    title: "Unlink",
                    click: (row: any) => {
                        this.removeBoxAllocation(row.box_id);
                    },
                }
            ],
            columns: [
                {
                    data: "box_ref",
                    title: "Box"
                },
                {
                    data: "quantity",
                    title: "Quantity"
                },
                {
                    data: "pallet.ref",
                    name: "Pallet.ref",
                    title: "Pallet",
                    sortable: false
                },
                {
                    data: "box.warehouse_location.location",
                    name: "Box.WarehouseLocation.location",
                    title: "Location",
                    sortable: false
                },
            ],
            api: this.getBoxesTableApi(),
            export: {file_name: "item info"}
        };
    }

    private preparePalletsTable(): void {
        this.palletTableSettings = {
            actions: [
                {
                    name: "unlink",
                    title: "Unlink",
                    click: (row: any) => {
                        this.removePalletAllocation(row.pallet_id);
                    },
                }
            ],
            columns: [
                {
                    data: "pallet_ref",
                    title: "Ref"
                },
                {
                    data: "quantity",
                    title: "Quantity"
                },
                {
                    data: "pallet.warehouse_location.location",
                    name: "Pallet.WarehouseLocation.location",
                    title: "Location",
                    sortable: false
                },
            ],
            api: {
                url: [
                    this.modal.params.state.section,
                    "order-items",
                    this.item.id,
                    "pallet-allocations"
                ],
                query: {
                    relations: [
                        "Pallet:id,warehouse_location_id",
                        "Pallet.WarehouseLocation:id,location"
                    ]
                },
                version: 3
            },
            export: {file_name: "item info"}
        };
    }

    private prepareTransactionsTable(): void {
        this.transactionsTableSettings = {
            // table_id: "soiu87934rmftz",
            api: {url: ["order_item", "transaction", this.item.id]},
            actions: [
                {
                    name: "unlink",
                    title: "Unlink",
                    click: (row: any) => {
                        this.unlinkTransactions(row.id);
                    }
                }
            ],
            columns: [
                {
                    data: "ref",
                    title: "Ref"
                },
                {
                    data: "item",
                    title: "Item"
                },
                {
                    data: "batch",
                    title: "Batch"
                },
                {
                    data: "rev",
                    title: "Rev"
                },
                {
                    data: "lot",
                    title: "Lot"
                },
                {
                    data: "batch_details.expiry_date",
                    title: "Expiry date",
                    searchable: false,
                },
                {
                    data: "serial",
                    title: "Serial",
                },
                {
                    data: "related_configurations",
                    title: this.userService.getProperty(
                        "configuration_name",
                        this.modal.params.state,
                        "Configurations"
                    ),
                    render: (row: any): string => {
                        return HelpersService.getConfigNames(row);
                    },
                    searchable: false,
                    sortable: false
                },
                {
                    data: "quantity",
                    title: "Quantity"
                },
                {
                    data: "inventory_conversion.customers_sub_inventory",
                    name: "InventoryConversion.customers_sub_inventory",
                    title: "Hub",
                    render: (row: any): string => {
                        if (row.inventory_conversion.customers_inventory_name) {
                            return `${row.inventory_conversion.customers_inventory_name}
                               ${row.inventory_conversion.customers_sub_inventory}`;
                        } else {
                            return `${row.inventory_conversion.customers_sub_inventory}`;
                        }
                    }
                },
                {
                    data: "box.ref",
                    name: "Box.ref",
                    title: "Box",
                },
                {
                    data: "box.pallet.ref",
                    name: "Box.Pallet.ref",
                    title: "Pallet"
                },
                {
                    data: "created_at",
                    name: "created_at",
                    title: "Created At",
                    searchable: false
                }
            ],
            export: {file_name: "item info"}
        };
        this.changeDetectorRef.markForCheck();
    }

    private async removePalletAllocation(pallet_id: number): Promise<any> {
        if (!this.modal.params.order || this.modal.params.order.confirmed) {
            return;
        }
        this.spinnerService.show();

        const {code, message}: Api.IResponse = await this.api3Service.delete(
            `${this.modal.params.state.section}/order-items/${this.item.id}/pallet-allocations/${pallet_id}`);

        this.spinnerService.hide();

        if (code === 200) {
            this.toastService.show(message, "success");
            this.palletTableRef?.reload();
        }
    }

    private async removeBoxAllocation(box_id: number): Promise<any> {
        if (!this.modal.params.order || this.modal.params.order.confirmed) {
            return;
        }
        this.spinnerService.show();

        const {code, message}: Api.IResponse = await this.api3Service.delete(
            `${this.modal.params.state.section}/order-items/${this.item.id}/box-allocations/${box_id}`);

        this.spinnerService.hide();

        if (code === 200) {
            this.toastService.show(message, "success");
            this.boxTableRef?.reload();
        }
    }

    private async removeAllocation(id: number): Promise<any> {
        if (!this.modal.params.order || this.modal.params.order.confirmed) {
            return;
        }
        this.spinnerService.show();

        const {code, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
            ["inventory_allocation", "by_id", "" + id]);

        this.spinnerService.hide();

        if (code === 200) {
            this.toastService.show(message, "success");
            this.itemsTableRef?.reload();
        }
    }

    public async unlinkTransactions(id: number = null): Promise<any> {
        let ask: string = "Do you want to unlink ";
        ask += id ? "this transaction" : "all transactions";
        ask += " in this item?";

        if (!await this.confirmComponentRef.confirm(ask)) {
            return;
        }

        this.spinnerService.show();

        const body = {
            transactions: []
        };

        if (id) {
            body.transactions.push(id);
        }

        const {code, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
            ["order_item", "transaction", this.item.id], body);

        this.spinnerService.hide();

        if (code === 200) {
            this.toastService.show(message, "success");
            this.transactionTableRef?.reload();
        }
    }

    public async removeAllocations(): Promise<any> {
        if (!this.modal.params.order || this.modal.params.order.confirmed) {
            return;
        }

        const remove_item_data: boolean = await this.confirmComponentRef.confirm("Remove serials from item?");

        this.spinnerService.show();

        const {code, message}: Api.IResponse = await this.api3Service.delete(
            `${this.modal.params.state.section}/order-items/${this.item.id}/allocations`, {
                remove_item_data
            });

        this.spinnerService.hide();

        if (code === 200) {
            this.toastService.show(message, "success");

            this.modal.response.emit();
        }
    }

    public ngOnInit(): void {
        this.item = this.modal.params.data;

        this.openTab = this.modal.params.openTab === "transactions" ? 3 : 0;

        this.prepareItemsTable();
        this.prepareBoxesTable();
        this.preparePalletsTable();

        this.prepareTransactionsTable();

        this.showAllBoxes.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(100))
            .subscribe(() => {
                this.boxTableRef.reload(this.getBoxesTableApi());
            });

        this.showAllSerials.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(100))
            .subscribe(() => {
                this.itemsTableRef.reload(this.getItemsTableApi());
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
