import {NgModule, Type} from "@angular/core";
import {AdminReportService} from "./services/report.service";
import {CourierServiceFormComponent, CourierServicesListComponent} from "./components/courier-services";
import {CouriersFormComponent, CouriersListComponent} from "./components/couriers";
import {ModelFieldsListComponent} from "./components/model-fields/list/list.component";
import {ModelFieldsViewComponent} from "./components/model-fields/view/view.component";
import {PartnerListComponent} from "./components/partner/list/list.component";
import {RemarkTypeFormComponent, RemarkTypeListComponent, RemarkTypeViewComponent} from "./components/remark-type";
import {RoleFormComponent, RoleListComponent} from "./components/roles";
import {ShippingModeListComponent} from "./components/shipping-mode";
import {UserListComponent, UserViewComponent} from "./components/user";
import {UserTasksComponent} from "./components/user/tasks/tasks.component";
import {AdminReportsListComponent, AdminReportViewComponent} from "./components/reports";
import {CommonModule} from "../../../common/common.module";
import {ThreeplFormComponent} from "./components/threepl/form/form.component";
import {ThreeplListComponent} from "./components/threepl/list/list.component";
import {AdminThreeplService} from "./services/thpeepl.service";
import {AdminPartnerService} from "./services/partner.service";
import {CommonSectionModule} from "../common/common-section.module";
import {PartnerFormComponent} from "./components/partner/form/form.component";
import {AdminPartMasterListComponent} from "./components/part-master/list/list.component";
import {EmailLogsListComponent} from "./components/email-logs";
import {AdminRoleService} from "./services/role.service";
import {AdminUserService} from "./services/user.service";
import {AdminPartnerCarrierService} from "./services/partner-carrier.service";


export const adminRoutingComponents: Type<any>[] = [
    CourierServiceFormComponent,
    CourierServicesListComponent,
    CouriersListComponent,
    CouriersFormComponent,
    ModelFieldsListComponent,
    ModelFieldsViewComponent,
    PartnerListComponent,
    RemarkTypeListComponent,
    RemarkTypeViewComponent,
    RemarkTypeFormComponent,
    RoleListComponent,
    RoleFormComponent,
    ShippingModeListComponent,
    UserListComponent,
    UserViewComponent,
    UserTasksComponent,
    AdminReportViewComponent,
    AdminReportsListComponent,
    ThreeplFormComponent,
    ThreeplListComponent,
    PartnerFormComponent,
    AdminPartMasterListComponent,
    EmailLogsListComponent
];


@NgModule({
    imports: [
        CommonModule,
        CommonSectionModule
    ],

    declarations: [
        ...adminRoutingComponents,
    ],
    providers: [
        AdminReportService,
        AdminThreeplService,
        AdminPartnerService,
        AdminRoleService,
        AdminUserService,
        AdminPartnerCarrierService
    ],
    exports: []
})
export class AdminSectionModule {
}
