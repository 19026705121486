<common-alert></common-alert>
<common-confirm></common-confirm>

<h1 class="container-heading">{{ window_title }}</h1>

<div class="row flex" [formGroup]="formGroup">
    <div [class.third]="itemType === 'outbound'" [class.full]="itemType === 'inbound'">
        <div class="row flex">
            <common-form-select class="quarter3"
                                label="Select item"
                                [required]="true"
                                [search]="true"
                                [exactSearch]="exactSearch.value"
                                [multiple]="false"
                                [options]="modal.params.partmasters"
                                [value]="formGroup.value.part_master_id"
                                panelClass="fit-content"
                                (valueChange)="onItemSelected($event)"
            ></common-form-select>

            <div class="padding-top-10 quarter text-nowrap">
                <mat-checkbox [formControl]="exactSearch">
                    Exact search
                </mat-checkbox>
            </div>
        </div>

        <div class="margin-bottom-20">
            <button mat-raised-button type="button" color="accent"
                    [disabled]="!formGroup.value.part_master_id"
                    (click)="showItemInfo()">
                Show item info
            </button>
            &nbsp;
            <div class="button-group">
                <button mat-raised-button type="button"
                        [color]="formGroup.value.configurations ? 'primary':''"
                        [disabled]="!formGroup.value.part_master_id" (click)="addConfiguration()">
                    <ng-container *ngIf="!formGroup.value.configurations">
                        Select {{ getProperty("configuration_name", "configurations") }}
                    </ng-container>

                    <ng-container *ngIf="formGroup.value.configurations">
                        {{ getProperty("configuration_name", "configurations") }}:
                        {{ formGroup.value.configurations }}
                    </ng-container>
                </button>
                <button *ngIf="formGroup.value.configurations" mat-raised-button color="primary"
                        type="button"
                        class="icon"
                        (click)="formGroup.get('configurations').setValue(null)">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>

        <div *ngIf="formType === 'transfer' && !modal?.params?.serviceLevel?.properties?.sync_items_lines"
             class="row flex margin-bottom-10 padding-top-10 padding-left-10 padding-right-10 padding-bottom-10"
             style="background-color: #f5f5f5; border-radius: 4px">
            <div class="padding-top-10">
                <b>Transfer direction:</b>
            </div>
            <div class="max">
                <mat-radio-group [formControl]="transferDirection">
                    <mat-radio-button value="both">Both</mat-radio-button>
                    <mat-radio-button value="outbound">Outbound</mat-radio-button>
                    <mat-radio-button value="inbound">Inbound</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <div class="row flex">

            <common-form-hub-select
                    #outboundHub
                    *ngIf="showOutboundHubSelect()"
                    label="Outbound Hub"
                    class="quarter3"
                    [required]="true"
                    [disabled]="disable_hub_select"
                    line_type="outbound"
                    [value]="formGroup.value.outbound_hub_id"
                    [part_master_id]="formGroup.value.part_master_id"
                    [part_master_in_stock]="stockMode !== 'free' && onlyInStockHubsForOutbound.value"
                    [empty_list_message]="stockMode !== 'free' ? 'No stock for selected item' : 'Hubs not found'"
                    [service_level_id]="modal.params.serviceLevel.id"
                    [address_id]="address_id"
                    [options]="options.hubs_from"
                    (onChange)="formGroup.get('outbound_hub_id').setValue($event)"></common-form-hub-select>

            <div class="padding-top-10 quarter" *ngIf="!disable_hub_select">
                <mat-checkbox *ngIf="showOutboundHubSelect()" [formControl]="onlyInStockHubsForOutbound">
                    Avail. only
                </mat-checkbox>
            </div>

        </div>

        <div class="row flex">
            <common-form-hub-select
                    #inboundHub
                    *ngIf="showInboundHubSelect()"
                    label="Inbound Hub"
                    [class.quarter3]="formType === 'rma' && !manualType"
                    [class.full]="!(formType === 'rma' && !manualType)"
                    [required]="true"
                    [disabled]="disable_hub_select"
                    line_type="inbound"
                    [value]="formGroup.value.inbound_hub_id"
                    [part_master_id]="formGroup.value.part_master_id"
                    [service_level_id]="modal.params.serviceLevel.id"
                    [address_id]="address_id"
                    [options]="options.hubs_to"
                    (onChange)="formGroup.get('inbound_hub_id').setValue($event)"></common-form-hub-select>

            <ng-container *ngIf="formType === 'rma' && !manualType">
                <div class="quarter3" *ngIf="rma_send_only.value">
                    <mat-form-field style="width: 100%">
                        <mat-label>Inbound Hub</mat-label>
                        <mat-select [disabled]="true"></mat-select>
                        <mat-icon matSuffix>
                            place
                        </mat-icon>
                    </mat-form-field>
                </div>

                <div class="padding-top-10 quarter">
                    <mat-checkbox [formControl]="rma_send_only">
                        Send only
                    </mat-checkbox>
                </div>
            </ng-container>
        </div>

        <mat-form-field
                *ngIf="!this.partner || !this.partner.properties || !this.partner.properties.orders_items_hide_ref">
            <mat-label>Ref</mat-label>
            <input matInput formControlName="ref">
        </mat-form-field>

        <mat-form-field
                *ngIf="!this.partner || !this.partner.properties || !this.partner.properties.orders_items_hide_ref">
            <mat-label>Ref 2</mat-label>
            <input matInput formControlName="ref2">
        </mat-form-field>

        <mat-form-field *ngIf="itemType ==='inbound' && info.has_serial">
            <mat-label>Serial</mat-label>
            <mat-chip-grid #chipGrid [required]="info.required_serial_inbound">
                <mat-chip-row *ngFor="let serial of selectedInventory.serial"
                              (removed)="removeSerialChip(serial)">
                    {{ serial.serial }}
                    <button matChipRemove>
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-chip-row>
                <input placeholder="Add serial"
                       [matChipInputFor]="chipGrid"
                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                       [matChipInputAddOnBlur]="true"
                       (matChipInputTokenEnd)="addSerialString($event)"/>
            </mat-chip-grid>
        </mat-form-field>

        <mat-form-field *ngIf="!disableBatch">
            <mat-label>Batch</mat-label>
            <input matInput formControlName="batch">
        </mat-form-field>

        <mat-form-field *ngIf="!disableRevision">
            <mat-label>Revision</mat-label>
            <input matInput formControlName="rev">
        </mat-form-field>

        <mat-form-field *ngIf="!disableLot">
            <mat-label>Lot</mat-label>
            <input matInput formControlName="lot">
        </mat-form-field>

        <div *ngIf="itemType === 'outbound'">
            <common-form-select
                    label="Location"
                    [required]="false"
                    [search]="true"
                    [multiple]="false"
                    [options]="locations"
                    [value]="formGroup.value.warehouse_location_id"
                    panelClass="fit-content"
                    (valueChange)="formGroup.get('warehouse_location_id').setValue($event)"
            ></common-form-select>
        </div>

        <mat-form-field>
            <mat-label>Quantity</mat-label>
            <input matInput formControlName="quantity" type="number" required [readonly]="disableQuantity" noscroll>
        </mat-form-field>

        <mat-form-field *ngIf="originQuantity > 0">
            <mat-label>
                Original quantity
            </mat-label>
            <input matInput type="number" [value]="originQuantity" [disabled]="true" noscroll>
        </mat-form-field>

        <mat-form-field *ngIf="itemStatuses">
            <mat-label>Status</mat-label>
            <mat-select formControlName="status_id" required>
                <mat-option [value]="15">Line created</mat-option>
                <mat-option *ngFor="let option of itemStatuses" [value]="option.value">
                    {{ option.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field
                *ngIf="!this.partner || !this.partner.properties || !this.partner.properties.orders_items_hide_price">
            <mat-label>Price</mat-label>
            <input matInput formControlName="price" type="number" noscroll>
        </mat-form-field>

    </div>


    <div class="third2 padding-left-20" *ngIf="itemType === 'outbound'">
        <div class="margin-bottom-20">
            <mat-accordion>

                <mat-expansion-panel *ngIf="!partner?.properties?.orders_items_hide_pallet">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Pallets
                        </mat-panel-title>
                        <mat-panel-description>
                            Selected: {{ selectedInventory.pallet.length }}
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div class="row flex" *ngIf="palletTable">
                        <div class="third">

                            <mat-form-field class="margin-bottom-20">
                                <input placeholder="Search selected pallets" matInput
                                       [formControl]="selectedPalletSearch"/>
                            </mat-form-field>

                            <div class="chips-block margin-bottom-10">
                                <div class="header">
                                    <b>Selected pallets</b>
                                </div>

                                <div class="contents">
                                    <mat-form-field>
                                        <input placeholder="Add pallet" matInput
                                               [matChipInputFor]="palletChipGrid"
                                               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                               [matChipInputAddOnBlur]="false"
                                               (matChipInputTokenEnd)="addPalletString($event)"/>
                                    </mat-form-field>
                                    <mat-chip-grid #palletChipGrid>
                                        <mat-chip-option
                                                *ngFor="let pallet of selectedInventory.pallet | search : selectedPalletSearch.value : ['ref']"
                                                [selectable]="false"
                                                (removed)="removePalletChip(pallet)">
                                            {{ pallet.ref }}: {{ pallet.inventories_count }}
                                            <button matChipRemove>
                                                <mat-icon>cancel</mat-icon>
                                            </button>
                                        </mat-chip-option>
                                    </mat-chip-grid>
                                </div>

                                <div class="text-right footer" *ngIf="selectedInventory.pallet.length">
                                    <a class="pointer" (click)="removeAllPallets()">Clear</a>
                                </div>

                            </div>

                        </div>
                        <div class="third2 padding-left-20">
                            <common-table2 [settings]="palletTable" #palletTableRef [search]="false"
                                           (onRowsSelected)="palletRowsSelected = $event">
                                <div>
                                    <button type="button" mat-raised-button color="primary"
                                            (click)="addMultiplePalletsFromTable()">
                                        Add selected
                                    </button>
                                </div>
                                <div class="max padding-left-20">
                                    <mat-form-field>
                                        <mat-label>Search in inventory</mat-label>
                                        <input type="text" matInput [formControl]="availablePalletsSearch">
                                    </mat-form-field>
                                </div>
                            </common-table2>
                        </div>
                    </div>
                    <div *ngIf="!palletTable">
                        <p>Select item and hub first</p>
                    </div>
                </mat-expansion-panel>

                <mat-expansion-panel *ngIf="!partner?.properties?.orders_items_hide_box">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Boxes
                        </mat-panel-title>
                        <mat-panel-description>
                            Selected: {{ selectedInventory.box.length }}
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div class="row flex" *ngIf="boxTable">
                        <div class="third">

                            <mat-form-field class="margin-bottom-20">
                                <input placeholder="Search selected boxes" matInput
                                       [formControl]="selectedBoxesSearch"/>
                            </mat-form-field>

                            <div class="chips-block margin-bottom-10">
                                <div class="header">
                                    <b>Selected boxes</b>
                                </div>

                                <div class="contents">
                                    <mat-form-field>
                                        <input placeholder="Add box" matInput
                                               [matChipInputFor]="boxChipGrid"
                                               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                               [matChipInputAddOnBlur]="false"
                                               (matChipInputTokenEnd)="addBoxString($event)"/>
                                    </mat-form-field>
                                    <mat-chip-grid #boxChipGrid>
                                        <mat-chip-option
                                                *ngFor="let box of selectedInventory.box  | search : selectedBoxesSearch.value : ['ref']"
                                                [selectable]="false"
                                                (removed)="removeBoxChip(box)">
                                            {{ box.ref }}: {{ box.inventories_count }}
                                            <button matChipRemove>
                                                <mat-icon>cancel</mat-icon>
                                            </button>
                                        </mat-chip-option>
                                    </mat-chip-grid>
                                </div>
                                <div class="text-right footer" *ngIf="selectedInventory.box.length">
                                    <a class="pointer" (click)="removeAllBoxes()">Clear</a>
                                </div>
                            </div>


                        </div>
                        <div class="third2 padding-left-20">
                            <common-table2 [settings]="boxTable" #boxTableRef [search]="false"
                                           (onRowsSelected)="boxRowsSelected = $event">
                                <div>
                                    <button type="button" mat-raised-button color="primary"
                                            (click)="addMultipleBoxesFromTable()">
                                        Add selected
                                    </button>
                                </div>
                                <div class="max padding-left-20">
                                    <mat-form-field>
                                        <mat-label>Search in inventory</mat-label>
                                        <input type="text" matInput [formControl]="availableBoxesSearch">
                                    </mat-form-field>
                                </div>
                            </common-table2>
                        </div>

                    </div>
                    <div *ngIf="!boxTable">
                        <p>Select item and hub first</p>
                    </div>
                </mat-expansion-panel>


                <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Items
                        </mat-panel-title>
                        <mat-panel-description *ngIf="info.has_serial">
                            Selected: {{ selectedInventory.serial.length }}
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div class="row flex" *ngIf="serialTable">
                        <div class="third" *ngIf="info.has_serial">

                            <mat-form-field class="margin-bottom-20">
                                <input placeholder="Search selected serial" matInput
                                       [formControl]="selectedSerialsSearch"/>
                            </mat-form-field>

                            <div class="chips-block margin-bottom-10">
                                <div class="header">
                                    <b>Selected serials</b>
                                </div>

                                <div class="contents">
                                    <mat-form-field>
                                        <input placeholder="Add serial" matInput
                                               [matChipInputFor]="serialChipGrid"
                                               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                               [matChipInputAddOnBlur]="false"
                                               (matChipInputTokenEnd)="addSerialString($event)"/>
                                    </mat-form-field>

                                    <mat-chip-grid #serialChipGrid>
                                        <mat-chip-option
                                                *ngFor="let serial of selectedInventory.serial | search : selectedSerialsSearch.value : ['serial']"
                                                [selected]="serial.type==='transaction'"
                                                [selectable]="false"
                                                [removable]="serial.type!=='transaction'"
                                                (removed)="removeSerialChip(serial)">
                                            {{ serial.serial }}
                                            <button matChipRemove *ngIf="serial.type!=='transaction'">
                                                <mat-icon>cancel</mat-icon>
                                            </button>
                                        </mat-chip-option>
                                    </mat-chip-grid>

                                </div>

                                <div class="text-right footer" *ngIf="selectedInventory.serial.length">
                                    <a class="pointer" (click)="removeAllSerials()">Clear</a>
                                </div>

                            </div>


                        </div>
                        <div [class.padding-left-20]="info.has_serial"
                             [class.third2]="info.has_serial"
                             [class.full]="!info.has_serial">
                            <common-table2 [settings]="serialTable" #serialTableRef [search]="false"
                                           (onRowsSelected)="serialRowsSelected = $event">
                                <div [class.half]="!info.has_serial">
                                    <button *ngIf="info.has_serial" type="button" mat-raised-button color="primary"
                                            (click)="addMultipleSerialsFromTable()">
                                        Add selected
                                    </button>
                                </div>
                                <div class="max padding-left-20">
                                    <mat-form-field>
                                        <mat-label>Search in inventory</mat-label>
                                        <input type="text" matInput [formControl]="availableItemsSearch">
                                    </mat-form-field>
                                </div>
                            </common-table2>
                        </div>
                    </div>
                    <div *ngIf="!serialTable">
                        <p>Select item and hub first</p>
                    </div>
                </mat-expansion-panel>


            </mat-accordion>
        </div>

        <div>
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Analogs
                        </mat-panel-title>
                        <mat-panel-description>

                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <ng-template matExpansionPanelContent>
                        <common-table2 *ngIf="analogsTable" [settings]="analogsTable" [search]="false"
                                       #analogsTableRef></common-table2>
                    </ng-template>
                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Allocated
                        </mat-panel-title>
                        <mat-panel-description>

                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <ng-template matExpansionPanelContent>
                        <common-table2 *ngIf="allocatedTable" [settings]="allocatedTable"></common-table2>

                    </ng-template>
                </mat-expansion-panel>
            </mat-accordion>
        </div>

    </div>
</div>

<div class="actions">
    <div class="margin-bottom-10" *ngIf="itemType === 'outbound' && modal.params.data">
        <mat-checkbox [formControl]="recreate_allocations">Recreate allocations</mat-checkbox>
    </div>
    <div>
        <button [disabled]="!formGroup.valid" mat-raised-button color="primary" type="button"
                (click)="save()"
                class="main">
            Save
        </button>
        &nbsp;
        <button mat-raised-button type="button" (click)="formReset()">
            Reset
        </button>
    </div>

</div>
