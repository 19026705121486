import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from "@angular/core";
import {Modal} from "../../../../../../../services/modal.service";
import {Api, ApiService} from "../../../../../../../../common/services/api.service";
import {FormControl} from "@angular/forms";
import {Order} from "../../../../../../../../common/interfaces/order.interface";
import {SpinnerService} from "../../../../../../../../common/services/spinner.service";
import {Table} from "../../../../../../../../common/interfaces/table.interface";
import {Table2Component} from "../../../../../../../../common/components/table2";

@Component({
    selector: "section-procedure-find-location-by-item",
    template: `

        <h1 class="container-heading">Location by item</h1>
        <mat-card>
            <mat-card-content>
                <div class="form">
                    <div class="group flex">
                        <mat-form-field class="max">
                            <mat-label>Scan box or item</mat-label>
<input type="search" matInput [formControl]="searchControl"
                                    (keydown)="onKeydown($event)">
                        </mat-form-field>
                        &nbsp;
                        &nbsp;
                        <button type="button" mat-raised-button color="accent" (click)="search()">
                            <i class="fa fa-barcode"></i> Search
                        </button>
                    </div>
                </div>

                <h2 *ngIf="listTable" class="border-bottom-none margin-bottom-0">Locations:</h2>

                <div>
                    <common-table2 *ngIf="listTable" [settings]="listTable" [search]="false"></common-table2>
                </div>

                <h2 class="border-bottom-none" *ngIf="orderItems.length">Order items:</h2>
                <div class="margin-bottom-20" *ngIf="orderItems.length">
                    <table class="table">
                        <thead>
                        <tr>
                            <th>Item</th>
                            <th>Serial</th>
                            <th>Quantity</th>
                            <th>Hub</th>
                            <th>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let item of orderItems">
                            <td>{{item.item}}</td>
                            <td>{{item.serial}}</td>
                            <td>{{item.quantity}}</td>
                            <td>
                                <ng-template [ngIf]="item.inventory_conversion">
                                    {{item.inventory_conversion.customers_inventory_name}}
                                    -
                                    {{item.inventory_conversion.customers_sub_inventory}}
                                </ng-template>
                            </td>
                            <td>
                                {{item.status ? item.status.name : ""}}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FindLocationByItemModalComponent implements OnInit {

    public listTable: Table.ISettings;

    public modal: Modal.IModal;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    public searchControl: FormControl = new FormControl(null);

    public orderItems: Order.IItem[] = [];

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        public spinnerService: SpinnerService
    ) {
    }

    private getUrl(): Table.ITableApi {
        return {
            url: ["warehouse_location", "by_box"],
            query: {
                ref: this.searchControl.value
            }
        };
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {

        this.listTable = {
            table_id: "sddr6aergVQ4",
            actions: [
                {
                    name: "wizard",
                    title: "Select",
                    click: (row: any): void => {
                        this.modal.response.emit({
                            name: "set",
                            value: row
                        });
                    }
                }
            ],
            api: this.getUrl(),
            columns: [
                {
                    data: "location",
                    title: "Location"
                },
                {
                    data: "warehouse_location_type.name",
                    name: "WarehouseLocationType.name",
                    title: "Type",
                    sortable: false
                },
                {
                    data: "inventory.inventory_conversion.customers_inventory_name",
                    name: "Inventory.InventoryConversion.customers_inventory_name",
                    title: "Hub",
                    render: (row: any): string => {
                        return row.inventory?.inventory_conversion?.customers_inventory_name
                            + " - " +
                            row.inventory?.inventory_conversion?.customers_sub_inventory;
                    },
                    sortable: false
                },
            ],
        };
        this.changeDetectorRef.markForCheck();
    }

    private async getOrderItems(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["order", this.modal.params.orderRef, "items", "inbound"], {}, {
                with_count_transactions: true
            });

        this.spinnerService.hide();
        if (data) {
            this.orderItems = data.filter((item: Order.IItem): boolean => item.item === this.searchControl.value);
            this.changeDetectorRef.markForCheck();
        }
    }

    public search(): void {
        if (!this.listTable || !this.tableRef) {
            this.prepareList();
        } else {
            this.tableRef.reload(this.getUrl());
        }

        this.getOrderItems();
    }

    public onKeydown(event: any): void {
        if (event.key === "Enter" || event.key === "Tab") {
            this.search();
        }
    }

    public ngOnInit(): void {
        if (this.modal && this.modal.params.item) {
            this.searchControl.setValue(this.modal.params.item);
            this.search();
        }
    }
}
