import {Component} from "@angular/core";
import {environment} from "../../../../environments/environment";

@Component({
    selector: "common-test",
    template: `
        <div *ngIf="!isProd" class="not-is-prod-server">
            <mat-icon class="vertical-middle">warning</mat-icon>
            You are using experimental test server!
        </div>
    `
})
export class CommonTestComponent {

    public isProd: boolean = environment.prodServer.indexOf(window.location.hostname) !== -1;

}
