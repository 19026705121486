import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import {ConfirmComponent} from "../../../../../common/components/confirm/confirm.component";
import {Api, ApiService} from "../../../../../common/services/api.service";
import {Base} from "../../../../../common/interfaces/base.interfaces";
import {FormControl} from "@angular/forms";
import {distinctUntilChanged, takeUntil} from "rxjs/operators";
import {SpinnerService} from "../../../../../common/services/spinner.service";
import {Table} from "../../../../../common/interfaces/table.interface";
import {Table2Component} from "../../../../../common/components/table2";
import {User} from "../../../../../common/interfaces/user.interface";


@Component({
    selector: "section-stock-summarized-by-location",
    template: `
        <common-confirm></common-confirm>

        <h1 class="container-heading">Stock list by location</h1>
        <mat-card>
            <div>
                <common-table2 *ngIf="listTable" [settings]="listTable">
                    <div class="flex row">
                        <common-form-select *ngIf="subWarehouses && subWarehouses.length"
                                            [options]="subWarehouses"
                                            [multiple]="true"
                                            [selectAll]="true"
                                            [search]="true"
                                            (onClose)="subWarehouseSelect.setValue($event)"
                                            label="Sub-warehouses" class="margin-right-10"></common-form-select>

                        <common-form-select *ngIf="partners && partners.length"
                                            [options]="partners"
                                            [multiple]="true"
                                            [selectAll]="true"
                                            (onClose)="partnerSelect.setValue($event)"
                                            label="Partners"></common-form-select>
                    </div>
                </common-table2>
            </div>
        </mat-card>

    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StockSummarizedByLocationComponent implements Base.IComponent, OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    private sectionType: string;

    private sectionSlug: string;

    public state: Base.IState;

    public subWarehouses: { name: string, value: any }[];

    public partners: User.IPartner[] = [];

    public subWarehouseSelect: FormControl = new FormControl([]);

    public partnerSelect: FormControl = new FormControl([]);

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.ISettings}
     */
    public listTable: Table.ISettings;

    public constructor(
        private apiService: ApiService,
        private changeDetectorRef: ChangeDetectorRef,
        private spinnerService: SpinnerService
    ) {
    }

    private get url(): { url: string[], query?: { [key: string]: any } } {
        return {
            url: ["inventory"],
            query: {
                type: "summarized",
                hubs: this.subWarehouseSelect.value,
                partners: this.partnerSelect.value,
                use_transformer: false,
                group_by_location: true,
                relations: [
                    "WarehouseLocation.WarehouseLocationType",
                    "WarehouseLocation.WarehouseLocationType",
                    "Partner",
                    "InventoryConversion"
                ]
            }
        };
    }


    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {

        const columns: Table.ICol[] = [];

        columns.push({
            data: "item",
            title: "Item"
        });
        columns.push({
            data: "sum_quantity",
            title: "Sum quantity",
            searchable: false
        });
        columns.push({
            data: "warehouse_location.location",
            name: "WarehouseLocation.location",
            title: "Box Location",
            sortable: false
        });
        columns.push({
            data: "warehouse_location.warehouse_location_type.name",
            name: "WarehouseLocation.WarehouseLocationType.name",
            title: "Location Type",
            sortable: false
        });

        columns.push({
            data: "partner.display_name",
            name: "Partner.display_name",
            title: "Partner",
            sortable: false
        });

        columns.push({
            data: "inventory_conversion.sub_inventory",
            name: "InventoryConversion.sub_inventory",
            title: "Sub-inventory",
            sortable: false
        });

        columns.push({
            data: "inventory_conversion.gb",
            name: "InventoryConversion.gb",
            title: "GB",
            sortable: false
        });

        this.addColumnsBySectionSlug(this.sectionSlug, columns);

        this.listTable = {
            table_id: "3L23Jy9hTxsBL",
            api: this.url,
            columns,
            export: {
                file_name: "inventories",
                columns,
            },
            search_default: ["item"]
        };
        this.changeDetectorRef.markForCheck();
    }

    private addColumnsBySectionSlug(slug: string, columns: Table.ICol[]): void {
        let ins: Table.ICol[] = [];
        let insertBeforeIndex = columns.length;

        switch (slug) {
            case "asl_taiwan":
            case "fpt_virtual":
            case "thailand": {
                ins = [
                    {
                        data: "inventory_conversion.customers_inventory_name",
                        name: "InventoryConversion.customers_inventory_name",
                        title: "Customers inventory name",
                        sortable: false
                    },

                    {
                        data: "inventory_conversion.customers_sub_inventory",
                        name: "InventoryConversion.customers_sub_inventory",
                        title: "Customers sub-inventory",
                        sortable: false
                    }
                ];
                break;
            }
        }

        if (ins.length > 0) {
            columns.splice(insertBeforeIndex, 0, ...ins);
        }
    }


    /**
     * Get related sub-warehouses
     */
    private async getSubWarehouses(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["warehouse", "sub"]);
        if (data) {
            this.subWarehouses = data.filter((sub: any): boolean => !!sub.inventory_conversion)
                .map((sub: any): any => {
                    return {name: `${sub.warehouse.name} - ${sub.sub_inventory}`, value: sub.inventory_conversion.id};
                });
        }
        this.spinnerService.hide();
    }

    /**
     * Get warehouse partners
     */
    private async getWarehousePartners(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["warehouse", "partners"]);
        if (data) {
            this.partners = data.map((partner: User.IPartner): any => {
                return {name: partner.display_name, value: partner.id};
            });
        }
        this.spinnerService.hide();
    }


    public ngOnInit(): void {
        [this.sectionType, this.sectionSlug] = this.state.section.split("/", 2);

        this.getSubWarehouses();
        this.getWarehousePartners();

        this.prepareList();

        this.subWarehouseSelect.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
            .subscribe((value: any[]): void => {
                this.tableRef.reload(this.url);
            });

        this.partnerSelect.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
            .subscribe((value: any[]): void => {
                this.tableRef.reload(this.url);
            });


    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "stock-summarized-by-location",
            actions: {
                "browse": ["browse_inventories"]
            }
        };
    }
}
