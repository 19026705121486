<common-confirm></common-confirm>


<h1 class="container-heading">ThreePLs List</h1>

<mat-card>
    <mat-card-content>

        <common-table2 *ngIf="listTable" [settings]="listTable">
            <div row1>
                <a routerLink="add" mat-raised-button color="primary" class="create">Add</a>
                <button type="button" mat-raised-button (click)="exportWarehouses()">
                    Export warehouses
                </button>
            </div>
        </common-table2>

    </mat-card-content>
</mat-card>