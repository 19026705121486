<common-confirm></common-confirm>
<mat-card>
    <h1 class="color-accent">Check packages</h1>

    <div class="margin-bottom-20">
        <button mat-raised-button (click)="printSticker()" color="accent">
            Print stickers
        </button>
    </div>

    <div class="packages">
        <mat-card *ngFor="let pack of packages">
            <div class="flex">
                <div>
                    <button type="button" mat-mini-fab color="accent" (click)="edit(pack)" [disabled]="isViewOnly">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>
                <div>
                    <button type="button" mat-mini-fab color="accent" (click)="printSticker(pack)">
                        <mat-icon>print</mat-icon>
                    </button>
                </div>
                <div>
                    <button type="button" mat-mini-fab color="accent" (click)="delete(pack)"
                            [disabled]="pack.warehouse_transactions && pack.warehouse_transactions.length > 0">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
                <div class="icon">
                    <div [class]="pack.warehouse_package_type?.name"></div>
                </div>
                <div class="title">
                    <h2>
                        {{ pack.warehouse_package_type?.name }} ID:
                        {{ shipment.partner_id === 14 ? "ANPA" : "" }}{{ pack.id }}
                        <b class="color-accent">
                            {{ pack.name }}
                        </b>
                    </h2>
                </div>
                <div class="sizes">
                    Sizes:
                    <b>
                        <ng-template [ngIf]="pack.length">
                            {{ pack.length }}
                        </ng-template>
                        <ng-template [ngIf]="!pack.length">
                            <span class="text-error">0</span>
                        </ng-template>
                        x
                        <ng-template [ngIf]="pack.width">
                            {{ pack.width }}
                        </ng-template>
                        <ng-template [ngIf]="!pack.width">
                            <span class="text-error">0</span>
                        </ng-template>
                        x
                        <ng-template [ngIf]="pack.height">
                            {{ pack.height }}
                        </ng-template>
                        <ng-template [ngIf]="!pack.height">
                            <span class="text-error">0</span>
                        </ng-template>
                        {{ pack.distance_unit }}
                    </b>

                </div>
                <div class="weight">
                    Weight (gross):
                    <b>
                        <ng-template [ngIf]="pack.weight">
                            {{ pack.weight }}
                        </ng-template>
                        <ng-template [ngIf]="!pack.weight">
                            <span class="text-error">0</span>
                        </ng-template>

                        <ng-template [ngIf]="pack.gross_weight">
                            ({{ pack.gross_weight }})
                        </ng-template>
                        <ng-template [ngIf]="!pack.gross_weight">
                            (<span class="text-error">0</span>)
                        </ng-template>
                        {{ pack.mass_unit }}
                    </b>
                </div>

            </div>
        </mat-card>
    </div>
</mat-card>
