import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewEncapsulation} from "@angular/core";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../common/interfaces/wizard.interface";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {environment} from "../../../../../../../../environments/environment";
import {AmplitudeService} from "../../../../../../../common/services/amplitude.service";

interface UITemplateData {
    title?: string,
    template: string
}

@Component({
    selector: "section-csv-import-wizard-upload-file",
    template: `
        <mat-card>
            <h1 class="text-center">Import file</h1>
            <div class="form" style="max-width: none">
                <div class="group flex">
                    <div class="half">
                        <common-file-upload *ngIf="data"
                                            (result)="onResult($event)"
                                            [accept]="['csv', 'xlsx']"
                                            [url]="['csv_import', data.wizardType]"
                        ></common-file-upload>
                    </div>
                    <div class="half">
                        <p>You can upload '.csv', '.xlsx', '.txt' files</p>
                        <p>Which columns does my file need to have?</p>
                        <p>You can order and name your columns any way you like,
                            you will be able to map them later on in the process.
                        </p>
                        <div *ngFor="let template of templates">
                            <a [href]="template.template" target="_blank"
                               (click)="amplitudeClick('Download a sample file',{template: template.template})">
                                Download a sample file {{ template.title }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class CsvImportWizardUploadFileComponent extends AbstractWizardStepComponent {

    public data: Wizard.IData;

    public state: Base.IState;

    public templates: UITemplateData[] = [];

    public constructor(protected changeDetectorRef: ChangeDetectorRef,
    ) {
        super(changeDetectorRef);
    }

    public getTemplatesData(): UITemplateData[] {
        if (!this.data) {
            return [];
        }
        switch (this.data.wizardType) {
            case "partmaster_analog":
                return [
                    {
                        template: "/assets/images/partmaster_conversion_template.csv"
                    }
                ];
            case "address_partmaster":
                return [
                    {
                        template: "/assets/images/address_partmaster_template.csv"
                    }
                ];
            case "partmaster":
                return [
                    {
                        template: environment.apiUrl + "/storage/templates/part_master.csv"
                    }
                ];
            case "order":
                return [
                    {
                        title: "full",
                        template: environment.apiUrl + "/storage/templates/new_order_example.xlsx"
                    },
                    {
                        title: "basic",
                        template: environment.apiUrl + "/storage/templates/import_order_basic.xlsx"
                    }
                ];
            case "warehouse_location":
                return [
                    {
                        template: "/assets/images/warehouse_location_template.csv"
                    }
                ];
            case "box":
                return [
                    {
                        template: environment.apiUrl + "/storage/templates/box_example.csv"
                    }
                ];
            case "pallet":
                return [
                    {
                        template: environment.apiUrl + "/storage/templates/pallet_example.csv"
                    }
                ];
            case "customer":
                return [
                    {
                        template: environment.apiUrl + "/storage/templates/customer_address_contact.csv"
                    }
                ];
            default:
                return [];
        }
    }

    public onResult(value: any): void {
        if (value.code === 200) {
            this.result.emit({
                action: "result",
                value: {
                    fileName: value.data.file_name,
                    mapData: value.data.map_data
                },
                goToNextStep: true
            });
        } else {
            this.result.emit({
                action: "result",
                value: null
            });
        }

    }

    public amplitudeClick(message: string, properties: any = {}): void {
        AmplitudeService.eventClick(message, properties);
    }


    public async init(data: Wizard.IData): Promise<any> {
        this.state = data.state;
        this.data = data;
        this.templates = this.getTemplatesData();
        this.changeDetectorRef.markForCheck();
    }

}
