import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {FormControl, Validators} from "@angular/forms";
import {IPagination} from "../../../../../../common/components/pagination/pagination.component";
import {Order} from "../../../../../../common/interfaces/order.interface";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {ReplaySubject} from "rxjs";
import {takeUntil} from "rxjs";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {User} from "../../../../../../common/interfaces/user.interface";
import {UserService} from "../../../../../../common/services/user.service";
import {Announcement} from "../../../../../../common/interfaces/announcement.interface";
import {MessageFormComponent} from "../../../../../../common/components/message-form/form.component";
import {SpinnerService} from "../../../../../../common/services/spinner.service";

@Component({
    selector: "section-announcements-view",
    templateUrl: "view.component.html",
    styleUrls: ["view.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class AnnouncementsViewComponent implements Base.IComponent, OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild("iTickerRef", {static: false})
    public iTickerRef: ElementRef;


    public readonly state: Base.IState;

    public data: Announcement.IData;

    public remarkSearch: FormControl = new FormControl(null);

    public remarks: IPagination<Order.IRemark>;

    public messages: IPagination<any>;

    public messagesCount: number = 0;

    public messageSearch: FormControl = new FormControl(null);

    public requestTitle: FormControl = new FormControl(null, [Validators.required]);

    public requestTitleEdit: boolean = false;

    public requestStatus: FormControl = new FormControl(null, [Validators.required]);

    public requestStatusEdit: boolean = false;

    public requestTeam: FormControl = new FormControl(null, [Validators.required]);

    public requestCreator: FormControl = new FormControl(null, [Validators.required]);

    public requestTeamEdit: boolean = false;

    public requestCreatorEdit: boolean = false;

    public requestDescription: FormControl = new FormControl(null, [Validators.required]);

    public requestDescriptionEdit: boolean = false;

    public users: { name: string, value: any }[] = [];
    public teams: { name: string, value: any }[] = [];

    public activities: IPagination<any>;

    public usersFiltered: ReplaySubject<any> = new ReplaySubject<any>(1);
    public teamsFiltered: ReplaySubject<any> = new ReplaySubject<any>(1);

    public requestUserSearch: FormControl = new FormControl(null);

    public requestTeamSearch: FormControl = new FormControl(null);

    public remarkTypes: { [key: string]: string } = {};

    public me: User.IData;

    public editorInitialValue: string = null;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private modalService: ModalService,
        private userService: UserService,
        private spinnerService: SpinnerService
    ) {
        this.me = userService.data;
    }

    /**
     * Get request messages count
     * @returns {Promise<any>}
     */
    private async getMessagesCount(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["announcement", this.state.params.id, "message"], {}, {
                data_structure: "count"
            });

        if (data) {
            this.messagesCount = data;
            this.changeDetectorRef.markForCheck();
            if (this.messagesCount > 0) {
                this.getMessages();
            }
        }
        this.spinnerService.hide();
    }


    /**
     * Get remark types
     * @returns {Promise<any>}
     */
    private async getRemarkTypes(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["remark", "order", "types"]);
        if (data) {
            for (const d of data) {
                this.remarkTypes["" + d.id] = d.name;
            }
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get request data
     * @returns {Promise<any>}
     */
    public async getData(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["announcement", this.state.params.id]);

        if (data) {
            this.data = data;
            this.requestTitle.setValue(this.data.title);
            if (this.data.team) {
                this.requestTeam.setValue(this.data.team.id);
            }
            this.requestDescription.setValue(this.data.description);
            this.editorInitialValue = this.data.description;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get users list
     * @returns {Promise<any>}
     */
    public async getUsers(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["user", "list"], {}, {data_structure: "select"});
        if (data) {
            this.users = data;
            this.usersFiltered.next(this.users);
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get users list
     * @returns {Promise<any>}
     */
    public async getTeams(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["team"], {}, {data_structure: "select"});
        if (data) {
            this.teams = data;
            this.teamsFiltered.next(this.teams);
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get request messages paginated list
     * @param {number} page
     * @param {string} search
     * @param {number} per_page
     * @returns {Promise<any>}
     */
    public async getMessages(page: number = 1, search: string = null, per_page: number = null): Promise<any> {
        this.spinnerService.show();
        if (per_page === null) {
            per_page = this.userService.data.settings.default_per_page;
        }
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["announcement", this.state.params.id, "message"], {}, {
                data_structure: "paginated",
                page,
                per_page,
                search_by: search
            });

        if (data) {
            this.messages = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Update request params
     * @param key
     * @param {string} value
     * @returns {Promise<any>}
     */
    public async update(
        key: "title" | "team" | "creator" | "message",
        value: string | number
    ): Promise<any> {
        this.spinnerService.show();
        const body: any = {};
        body[key === "team" ? "team_id" : key] = value;
        body[key === "creator" ? "creator_id" : key] = value;
        const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["announcement", this.state.params.id, key], body);

        this.spinnerService.hide();

        if (type as string === "success") {
            this.toastService.show(message, "success");
            this.getData();
            return true;
        }
        return false;
    }


    /**
     * Copy ITicket to clipboard
     */
    public copyITicket(): void {
        this.iTickerRef.nativeElement.select();
        document.execCommand("Copy");
        this.toastService.show("Copied to clipboard", "success");
    }

    /**
     * Show new message form
     * @param params
     */
    public async newMessage(params?: any): Promise<any> {
        return new Promise<any>(async (resolve: any): Promise<any> => {
            const response: Modal.IResponse = await this.modalService.open(MessageFormComponent, {
                item_id: this.data.id,
                order_remark_type_id: this.data.order_remark_type_id,
                to: this.data.to,
                cc: this.data.cc,
                bcc: this.data.bcc,
                url: "announcement",
                ...params
            });
            if (response && response.name === "submit") {
                this.getMessagesCount();
                resolve(true);
            } else {
                resolve(false);
            }
        });

    }

    public async editMessage(message: any): Promise<any> {
        return new Promise<any>(async (resolve: any): Promise<any> => {
            const response: Modal.IResponse = await this.modalService.open(MessageFormComponent, {
                ...message,
                url: "announcement"
            });
            if (response && response.name === "submit") {
                this.getMessagesCount();
                resolve(true);
            } else {
                resolve(false);
            }
        });

    }

    /**
     * Add/remove request to favorites
     */
    public async favorite(): Promise<any> {
        this.spinnerService.show();
        const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["announcement", "" + this.state.params.id, "favorite"]);
        this.spinnerService.hide();

        if (type as string === "success") {
            this.toastService.show(message, "success");
            this.getData();
        }
    }

    /**
     * Delete request after confirm
     */
    public async delete(): Promise<any> {
        if (await this.confirmRef.confirm("Are you sure want to delete this announcement?")) {
            this.spinnerService.show();
            const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
                ["announcement", "" + this.state.params.id]);
            this.spinnerService.hide();

            if (type as string === "success") {
                this.toastService.show(message, "success");
                this.router.navigate([
                    this.state.section,
                    "support-announcements-all"
                ]);
            }
        }
    }

    /**
     * Get
     * @param {number} page
     * @returns {Promise<any>}
     */
    public async getActivities(page: number = 1): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["announcement", "" + this.state.params.id, "activities"], {}, {
                data_structure: "paginated",
                page,
                per_page: 5
            });

        if (data) {
            this.activities = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    public ngOnInit(): void {
        this.getRemarkTypes();
        this.getData();
        this.getMessagesCount();
        this.getActivities();

        this.requestUserSearch.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value: string): void => {
                if (value) {
                    this.usersFiltered.next(
                        this.users.filter((user: { name: string }): boolean =>
                            user.name.toLowerCase().indexOf(value) > -1)
                    );
                } else {
                    this.usersFiltered.next(this.users);
                }
            });

        this.requestTeamSearch.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value: string): void => {
                if (value) {
                    this.teamsFiltered.next(
                        this.teams.filter((team: { name: string }): boolean =>
                            team.name.toLowerCase().indexOf(value) > -1)
                    );
                } else {
                    this.teamsFiltered.next(this.teams);
                }
            });

    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "support-announcements",
            actions: {
                "view": ["read_announcements"]
            }
        };
    }
}
