<h1 class="container-heading">{{state.action === "add" ? "Add" : "Edit"}} user role</h1>

<mat-card>
    <mat-card-content>
        <mat-tab-group>
            <mat-tab label="Settings">
                <form [formGroup]="formGroup" (submit)="formSubmit()" class="padding-top-20">
                    <div class="group flex">
                        <mat-form-field class="third">
                            <mat-label>Name</mat-label>
                            <input matInput formControlName="name" required>
                        </mat-form-field>

                        <mat-form-field class="third">
                            <mat-label>Display name</mat-label>
                            <input matInput formControlName="display_name" required>
                        </mat-form-field>

                        <div class="third">
                            <common-form-select
                                    label="Who can see"
                                    [options]="remark_types"
                                    [required]="true"
                                    [value]="formGroup.value.order_remark_type_id"
                                    (valueChange)="formGroup.get('order_remark_type_id').setValue($event)">
                            </common-form-select>
                        </div>
                    </div>

                    <common-form-fields [fields]="settingsFields"
                                        [values]="settingsValues"
                                        (valueChange)="formGroup.get('settings').setValue($event)"
                                        (isValid)="validateSettings($event)">
                    </common-form-fields>

                    <div class="actions">
                        <button type="submit" mat-raised-button color="primary"
                                [disabled]="disableSaveButton || !settingsIsValid">
                            Update
                        </button>
                    </div>
                </form>
            </mat-tab>

            <ng-container *ngIf="this.state.action === 'edit'">
                <mat-tab label="Permissions">
                    <div class="padding-top-20">
                        <mat-form-field class="pull-right">
                            <mat-label>Search</mat-label>
                            <input type="text" matInput #permissionsSearch>
                        </mat-form-field>
                        <div class="clear"></div>
                    </div>

                    <mat-grid-list cols="3" rowHeight="4:1" *ngIf="permissions && permissions.length > 0">
                        <mat-grid-tile
                                *ngFor="let perm of permissions | filter: 'key':'like' : permissionsSearch.value">
                            <div class="permission">
                                <mat-checkbox [value]="perm.id" [checked]="checkPermission(perm)"
                                              [disableRipple]="true"
                                              (change)="togglePermission($event)">
                                    {{ perm.key}}
                                </mat-checkbox>
                            </div>
                        </mat-grid-tile>
                    </mat-grid-list>
                </mat-tab>

                <mat-tab label="Logs">
                    <div class="padding-top-20">
                        <common-table2 *ngIf="logsTableSettings" [settings]="logsTableSettings"></common-table2>
                    </div>
                </mat-tab>
            </ng-container>
        </mat-tab-group>

    </mat-card-content>
</mat-card>
