import {ChangeDetectionStrategy, Component, ViewEncapsulation} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";

@Component({
    selector: "section-knowledge-center-list-all",
    template: `
        <h1 class="container-heading">Knowledge center</h1>
        <mat-card>
            <mat-card-content>
                <section-knowledge-center-list [state]="state"></section-knowledge-center-list>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class KnowledgeCenterListAllComponent implements Base.IComponent {

    public readonly state: Base.IState;

    public constructor() {
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "support-knowledge-center-all",
            actions: {
                "browse": ["browse_knowledge_center_documents"]
            }
        };
    }
}
