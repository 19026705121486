import {Component} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";


@Component({
    selector: "section-warehouse-transactions-invalid",
    template: `
        <section-warehouse-transactions-list
            [params]='{title: "Transactions replenishment", type: "replenishment", state: state}'>
        </section-warehouse-transactions-list>`
})
export class WarehouseTransactionsListReplenishmentComponent implements Base.IComponent {

    public readonly state: Base.IState;

    public ngConfig(): Base.IConfig {
        return {
            name: "transactions-replenishment",
            actions: {
                "browse": ["browse_warehouse_transaction_replenishes"]
            }
        };
    }

}
