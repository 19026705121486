import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import {Router} from "@angular/router";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {ModalService} from "../../../../../services/modal.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Table2Component} from "../../../../../../common/components/table2";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {UserService} from "../../../../../../common/services/user.service";
import {Warehouse} from "../../../../../../common/interfaces/warehouse.interface";
import {FormControl} from "@angular/forms";
import {Form} from "../../../../../../common/interfaces/form.interface";
import {PickupRequestService} from "../../../../../../common/services/pickup-request.service";
import {debounceTime, takeUntil} from "rxjs/operators";
import * as moment from "moment";
import {Moment} from "moment";
import {PickupRequestInfoModalComponent} from "../info/info-modal.component";
import {PickupRequestFormComponent} from "../../../../partner/components/pickup-requests/form/form.component";
import ISelectOption = Form.ISelectOption;
import {Api3Service} from "../../../../../../common/services/api3.service";

@Component({
    selector: "section-pickup-requests-list",
    template: `
        <common-confirm></common-confirm>

        <mat-card>
            <mat-card-content>
                <common-table2 *ngIf="listTable" [settings]="listTable">
                    <div class="flex row">
                        <div class="margin-right-10" *ngIf="statuses && statuses.length">
                            <common-form-select
                                label="Statuses"
                                [options]="statuses"
                                [multiple]="true"
                                [selectAll]="true"
                                [value]="statusSelect.value"
                                (onClose)="statusSelect.setValue($event)"
                            ></common-form-select>
                        </div>
                        <div *ngIf="partners && partners.length">
                            <common-form-select
                                label="Partnes"
                                [options]="partners"
                                [multiple]="true"
                                [selectAll]="true"
                                [value]="partnersSelect.value"
                                (onClose)="partnersSelect.setValue($event)"
                            ></common-form-select>
                        </div>
                    </div>
                </common-table2>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PickupRequestsListComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    private partnerColumns: Table.ICol[] = [];

    @Input()
    public state: Base.IState;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.ISettings;
    /* Table options */

    public showExportButton: boolean = false;

    public statusSelect: FormControl = new FormControl([]);
    public partnersSelect: FormControl = new FormControl([]);

    public statuses: ISelectOption[];
    public partners: ISelectOption[];

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private modalService: ModalService,
        private spinnerService: SpinnerService,
        private pickupRequestService: PickupRequestService,
        private userService: UserService
    ) {
    }

    private async getStatuses(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.pickupRequestService.getStatusList({
            data_structure: "select"
        });
        this.spinnerService.hide();
        if (data) {
            this.statuses = data;
        }
    }

    private async getColumns(): Promise<any> {
        if (this.state.section_type !== "partner") {
            this.prepareList();
            return;
        }

        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["pickup_request"], {}, {
            data_structure: "datatables_columns"
        });
        this.spinnerService.hide();
        if (data) {
            this.partnerColumns = data;
            this.prepareList();
        }
    }

    private async editPickup(pickup: Warehouse.IPickupRequest): Promise<any> {
        await this.modalService.open(PickupRequestFormComponent, {
            data: pickup,
            shipment: pickup.shipment
        });

        this.tableRef.reload();
    }

    private getUrl(): Table.ITableApi {
        return {
            url: [this.state.section, "pickup-requests"],
            query: {
                statuses: this.statusSelect.value,
                partners: this.partnersSelect.value,
                relations: [
                    "CourierTransaction",
                    "Address",
                    "Contact",
                    "Customer",
                    "User",
                    "Status",
                    "Shipment.Order.Partner"
                ]
            },
            version: 3
        };
    }

    private buildTimeline(data: any): string {
        if (data.to_date) {
            const from: Moment = moment(data.from_date).startOf("day");
            const to: Moment = moment(data.to_date);
            const now: Moment = moment();
            const diff100: number = to.diff(from, "hours");
            const diffNow: number = now.diff(from, "hours");
            let progress: number = diffNow / (diff100 / 100);
            progress = progress > 0 ? Math.ceil(progress) : 0;
            progress = progress < 100 ? progress : 100;

            const left: number = to.diff(now, "hours");
            let daysLeft: number = 0;
            let hoursLeft: number = 0;
            let cssClass: string = "red";

            if (left > 0) {
                daysLeft = Math.floor(left / 24);
                hoursLeft = left - daysLeft * 24;
                if (left > 1) {
                    cssClass = "green";
                } else if (left > 0.5) {
                    cssClass = "yellow";
                }
            }

            return `
                    <div class="timeline ${cssClass}">
                        <div class="progress" style="width:${progress}%"></div>
                        <div class="dates">
                          ${to.format("MMM D HH:mm")}
                        </div>
                        <div class="distance">
                            ${daysLeft}d ${hoursLeft}h left
                        </div>
                    </div>
                `;
        } else {
            return `
                     <div class="timeline">
                     <div class="dates">
                            -
                        </div>
                        <div class="distance">
                            -
                        </div>
                    </div>
                `;
        }
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {

        /* Setup columns for dataTable typed ColumnSettings */
        const columns: Table.ICol[] = [];

        this.partnerColumns.forEach((col: Table.ICol): void => {
            columns.push(col);
        });

        const actions: Table.IAction[] = [
            {
                name: "view",
                title: "View",
                click: (row: any): void => {
                    this.modalService.open(PickupRequestInfoModalComponent, {
                        pickup_request: row
                    });
                }
            }
        ];
        if (this.state.section_type === "partner") {
            actions.push({
                name: "edit",
                title: "Edit",
                click: (row: any): void => {
                    this.editPickup(row);
                }
            });
        }

        if (!this.partnerColumns || this.partnerColumns.length === 0) {
            columns.push(
                {
                    data: "shipment.order.ref",
                    title: "Order",
                    render: (data: any): string => {
                        if (this.userService.validatePermissions("read_orders")) {
                            if (data.shipment) {
                                return "<button class='mdc-button mat-mdc-raised-button button-200' type='button'>"
                                    + data.shipment.order.ref
                                    + "</button>";
                            } else {
                                return "";
                            }
                        }
                        return data;
                    },
                    click: (row: any): void => {
                        if (row.shipment) {
                            if (this.state) {
                                this.router.navigate([
                                    (this.state.section === "admin"
                                        ? "/partner/" + row.shipment.order.partner.slug
                                        : this.state.section),
                                    "orders",
                                    "view",
                                    "id",
                                    row.shipment.order.id,
                                ]);
                            }
                        }
                    }
                },
                {
                    data: "id",
                    title: "",
                    render: (row: any): string => {
                        if (row.id) {
                            return `<mat-icon class="mat-icon material-icons pointer" title="Open in new tab">
                            open_in_new
                            </mat-icon>`;
                        }
                        return "";
                    },
                    click: (row: any): void => {
                        if (row.id) {
                            window.open((this.state.section === "admin"
                                ? "/partner/" + row.shipment.order.partner.slug
                                : this.state.section) + "/orders/view/id/" + row.shipment.order.id, "_blank");
                        }
                    },
                    cssClass: "action",
                    sortable: false,
                    searchable: false
                },
                {
                    data: "customer.name",
                    title: "Customer"
                },
                {
                    data: "address.address",
                    title: "Address"
                },

                {
                    data: "status.name",
                    title: "Status"
                },
                {
                    data: "type",
                    title: "Type"
                },
                {
                    data: "from_date",
                    title: "From Date"
                },
                {
                    data: "to_date",
                    title: "To Date",
                    render: (row: any): string => {
                        return this.buildTimeline(row);
                    }
                },
                {
                    data: "user.name",
                    title: "Created by"
                },
                {
                    data: "created_at",
                    title: "Created at"
                }
            );
            if (this.state.section === "admin") {
                columns.push({
                    data: "shipment.order.partner.display_name",
                    title: "Partner"
                });
            }
        }

        const exportColumns: any = [...columns];

        this.listTable = {
            table_id: "0NR4rylD09i",
            actions,
            columns,
            api: this.getUrl(),
            export: {
                file_name: "Pickup-requests",
                columns: exportColumns
            }
        };

        this.changeDetectorRef.markForCheck();
    }

    private async getPartners(): Promise<any> {
        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `${this.state.section}/partners`, {}, {
                data_structure: "select"
            });
        if (data) {
            this.partners = data;
            this.changeDetectorRef.markForCheck();
        }
    }


    public ngOnInit(): void {
        this.getColumns();
        this.getStatuses();

        if (this.state.section === "admin") {
            this.getPartners();

            this.partnersSelect.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(100))
                .subscribe((): void => {
                    this.tableRef.reload(this.getUrl());
                });
        }

        this.statusSelect.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(100))
            .subscribe((): void => {
                this.tableRef.reload(this.getUrl());
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
