import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, Validators} from "@angular/forms";
import {distinctUntilChanged, takeUntil} from "rxjs/operators";

@Component({
    selector: "common-form-switch",
    template: `
        <div class="switch-wrap" [style.width]="width">
            <div class="switch"
                 [class.on]="value"
                 [class.off]="!value"
                 [class.trueFalse]="trueFalse">
                <div class="switch-item itemFirst"
                     [matTooltip]="tipOff"
                     (click)="formControl.setValue(false)">
                    <mat-icon *ngIf="iconOff">{{iconOff}}</mat-icon>
                    {{labelOff}}
                </div>
                <div class="switch-item itemSecond"
                     [matTooltip]="tipOn"
                     (click)="formControl.setValue(true)">
                    <mat-icon *ngIf="iconOn">{{iconOn}}</mat-icon>
                    {{labelOn}}
                </div>
                <div class="switch-indicator" [class.toggle]="value"></div>
                <div class="clear"></div>
            </div>
        </div>

    `,
    styleUrls: [
        "switch.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class CommonFormSwitchComponent implements OnInit, OnDestroy, OnChanges {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input()
    public width: string = null;

    @Input()
    public labelOn: string = null;

    @Input()
    public iconOn: string = null;

    @Input()
    public labelOff: string = null;

    @Input()
    public iconOff: string = null;

    @Input()
    public tipOff: string = null;

    @Input()
    public tipOn: string = null;

    @Input()
    public required: boolean = false;

    @Input()
    public value: boolean = false;

    @Input()
    public trueFalse: boolean = true;

    @Output()
    public valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    public formControl: FormControl =
        new FormControl(this.value, this.required ? [Validators.required] : []);

    public constructor(private changeDetectorRef: ChangeDetectorRef) {
    }

    public ngOnInit(): void {
        this.formControl.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
            .subscribe((checked: boolean): void => {
                this.value = checked;
                this.valueChange.emit(checked);
                this.changeDetectorRef.markForCheck();
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.formControl.setValue(changes.value.currentValue);
    }
}
