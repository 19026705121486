<h1 class="container-heading">View user</h1>

<mat-card>
    <mat-card-content>
        <div class="columns-container" *ngIf="user">
            <div class="column-1-3">
                <mat-card>
                    <div class="avatar">
                        <img *ngIf="user.avatar" [src]="user.avatar" alt="">
                    </div>
                    <div class="name">
                        {{ user.name}}
                        <div class="contact">{{user.email}}</div>
                        <div class="contact">{{user.phone}}</div>
                    </div>

                    <mat-list class="nav-list">
                        <mat-divider></mat-divider>
                        <mat-list-item (click)="showSection = 1" [class.active]="showSection === 1">
                            <mat-icon matListItemIcon>home</mat-icon>
                            <p matListItemTitle>Overview</p>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item (click)="showSection = 2" [class.active]="showSection === 2">
                            <mat-icon matListItemIcon>people</mat-icon>
                            <p matListItemTitle>Partners</p>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item (click)="showSection = 3" [class.active]="showSection === 3">
                            <mat-icon matListItemIcon>group_work</mat-icon>
                            <p matListItemTitle>ThreePLs</p>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item (click)="showSection = 4" [class.active]="showSection === 4">
                            <mat-icon matListItemIcon>settings_input_component</mat-icon>
                            <p matListItemTitle>Roles</p>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item (click)="showSection = 5" [class.active]="showSection === 5">
                            <mat-icon matListItemIcon>settings</mat-icon>
                            <p matListItemTitle>Settings</p>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <mat-list-item (click)="showSection = 6" [class.active]="showSection === 6">
                            <mat-icon matListItemIcon>vpn_lock</mat-icon>
                            <p matListItemTitle>Tokens</p>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                    </mat-list>
                </mat-card>
            </div>

            <div class="column-2-3" *ngIf="showSection === 1">
                <mat-card>
                    <mat-tab-group>
                        <mat-tab label="Orders">
                            <mat-form-field>
                                <mat-label>Order search</mat-label>
                                <input matInput type="search" [formControl]="orderSearch">
                            </mat-form-field>
                            <mat-list *ngIf="userOrders; else ordersElse">
                                <ng-template ngFor let-order [ngForOf]="userOrders.data">
                                    <mat-list-item class="padding-top-20">
                                        <div matListItemTitle>Ref: <b>{{order.ref}}</b></div>
                                        <div matListItemLine>
                                            Status:
                                            <span class="chip {{order.status.name | lowercase}}">
                                                {{order.status.name}}
                                            </span>
                                        </div>
                                        <div matListItemLine>Created at: <b>{{order.created_at}}</b></div>
                                        <div matListItemLine>Confirmed at: <b>{{order.confirmed_on}}</b></div>
                                        <div matListItemLine class="text-right">
                                            <a [routerLink]="[
                                            '/partner',
                                            order.partner.slug,
                                            'orders',
                                            'view',
                                            'ref',
                                            order.ref,
                                            'type',
                                            order.service_level.order_type.slug
                                            ]">
                                                View order
                                            </a>
                                        </div>
                                    </mat-list-item>
                                    <mat-divider></mat-divider>
                                </ng-template>
                                <common-pagination [data]="userOrders" pid="UEfj3j26S6"
                                                   (goToPage)="getUserOrders($event.page,
                                                   orderSearch.value,
                                                   $event.per_page)">
                                </common-pagination>
                            </mat-list>
                            <ng-template #ordersElse>
                                <p>You have no orders yet</p>
                            </ng-template>

                        </mat-tab>
                        <mat-tab label="Remarks">
                            <mat-form-field>
                                <mat-label>Remark search</mat-label>
                                <input matInput type="search"
                                       [formControl]="remarkSearch">
                            </mat-form-field>
                            <mat-list *ngIf="remarks; else remarksElse">
                                <ng-template ngFor let-remark [ngForOf]="remarks.data">
                                    <section-user-remark [remark]="remark"></section-user-remark>
                                </ng-template>
                                <common-pagination [data]="remarks" pid="6Ir90GyN6i"
                                                   (goToPage)="getUserRemarks($event.page,
                                                   remarkSearch.value,
                                                   $event.per_page)">
                                </common-pagination>
                            </mat-list>
                            <ng-template #remarksElse>
                                <p>You have no remarks yet</p>
                            </ng-template>
                        </mat-tab>
                        <mat-tab label="Logs">
                            <common-table2 *ngIf="logsTableSettings" [settings]="logsTableSettings"></common-table2>
                        </mat-tab>
                    </mat-tab-group>
                </mat-card>
            </div>

            <div class="column-2-3" *ngIf="showSection === 2">
                <mat-card>
                    <h3 matSubheader>Partners</h3>
                    <div class="content">
                        <div class="search-section">
                            <mat-form-field>
                                <mat-label>Partner search</mat-label>
                                <input matInput type="search"
                                       [formControl]="partnerSearch">
                            </mat-form-field>
                        </div>
                        <mat-list *ngIf="filteredPartners && filteredPartners.length > 0">
                            <cdk-virtual-scroll-viewport itemSize="50" class="scroll-viewport">
                                <div *cdkVirtualFor="let partner of filteredPartners; templateCacheSize: 0">
                                    <mat-list-item>
                                        <img matListItemAvatar [src]="partner.icon_path" alt="">
                                        <div matListItemTitle>
                                            {{partner.display_name}}
                                            <mat-slide-toggle class="pull-right" [checked]="partner.isActive"
                                                              (change)="togglePartner(partner, $event)"></mat-slide-toggle>

                                        </div>
                                    </mat-list-item>
                                    <mat-divider></mat-divider>
                                </div>
                            </cdk-virtual-scroll-viewport>
                        </mat-list>
                    </div>
                </mat-card>
            </div>

            <div class="column-2-3" *ngIf="showSection === 3">
                <mat-card>
                    <h3 matSubheader>ThreePLs</h3>
                    <div class="search-section">
                        <mat-form-field>
                            <mat-label>ThreePL search</mat-label>
                            <input matInput type="search"
                                   [formControl]="threePlSearch">
                        </mat-form-field>
                    </div>
                    <div class="content">
                        <mat-list *ngIf="filteredThreePls && filteredThreePls.length > 0">
                            <cdk-virtual-scroll-viewport itemSize="50" class="scroll-viewport">
                                <div *cdkVirtualFor="let threePl of filteredThreePls; templateCacheSize: 0">
                                    <mat-list-item>
                                        <img matListItemAvatar [src]="threePl.icon_path" alt="">
                                        <div matListItemTitle>{{threePl.display_name}}
                                            <mat-slide-toggle class="pull-right" [checked]="threePl.isActive"
                                                              (change)="toggleThreePl(threePl, $event)"></mat-slide-toggle>
                                        </div>
                                    </mat-list-item>
                                    <mat-divider></mat-divider>
                                </div>
                            </cdk-virtual-scroll-viewport>
                        </mat-list>
                    </div>
                </mat-card>
            </div>

            <div class="column-2-3" *ngIf="showSection === 4">
                <mat-card>
                    <h3 matSubheader>Roles</h3>
                    <div class="search-section">
                        <mat-form-field>
                            <mat-label>Role search</mat-label>
                            <input matInput type="search"
                                   [formControl]="roleSearch">
                        </mat-form-field>
                    </div>
                    <div class="content">
                        <mat-list *ngIf="filteredRoles && filteredRoles.length > 0">
                            <cdk-virtual-scroll-viewport itemSize="50" class="scroll-viewport">
                                <div *cdkVirtualFor="let role of filteredRoles; templateCacheSize: 0">
                                    <mat-list-item>
                                        <div matListItemTitle>{{role.display_name}}
                                            <mat-slide-toggle class="pull-right" [checked]="role.isActive"
                                                              (change)="toggleRole(role, $event)"></mat-slide-toggle>
                                        </div>
                                    </mat-list-item>
                                    <mat-divider></mat-divider>
                                </div>
                            </cdk-virtual-scroll-viewport>
                        </mat-list>
                    </div>
                </mat-card>
            </div>

            <div class="column-2-3" *ngIf="showSection === 5">
                <section-user-view-settings [user]="user" [state]="state" (dataUpdated)="init()"
                ></section-user-view-settings>
            </div>

            <div class="column-2-3" *ngIf="showSection === 6">
                <section-tokens-settings [user]="user" [state]="state"></section-tokens-settings>
            </div>

        </div>

    </mat-card-content>
</mat-card>
