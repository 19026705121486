import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Router} from "@angular/router";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {Api3Service} from "../../../../../../common/services/api3.service";
import {ToastService} from "../../../../../../common/services/toast.service";

@Component({
    selector: "section-inspection-view",
    templateUrl: "view.component.html",
    styleUrls: [
        "view.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class InspectionViewComponent implements Base.IComponent, OnInit {

    public formConfig: any;

    public submission: any;

    public formData: { [key: string]: { label: string, type: string, value: any } } = {};

    public readonly state: Base.IState;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private spinnerService: SpinnerService,
        private router: Router,
        public helpersService: HelpersService,
        public toastService: ToastService,
    ) {
    }


    /**
     * Get submission data
     * @return {Promise<any>}
     */
    private async getData(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.get(
            `${this.state.section}/inspections/results/${this.state.params.id}`, {
                relations: [
                    "User"
                ]
            });

        if (data) {
            this.submission = data;
            this.changeDetectorRef.markForCheck();
            this.getFormConfig();
        }
        this.spinnerService.hide();
    }

    private searchFormField(fieldName: string): string | boolean {
        const lastUnderscorePos: number = fieldName.lastIndexOf("_");
        let fieldNameNoCounter: string = fieldName;
        if (lastUnderscorePos) {
            fieldNameNoCounter = fieldName.substring(0, lastUnderscorePos);
        }
        for (const prop in this.formData) {
            if (this.formData.hasOwnProperty(prop)) {
                if (prop.includes(fieldNameNoCounter)) {
                    return prop;
                }
            }
        }
        return false;
    }

    /**
     * Get form config
     */
    private async getFormConfig(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["inspection", this.submission.inspection_id]);

        if (data) {
            this.formConfig = data;
            Object.keys(this.formConfig.fields).forEach((val: string): void => {
                if (this.formConfig.fields[val].type === "group") {
                    Object.keys(this.formConfig.fields[val].fields).forEach((value: string): void => {
                        this.formConfig.fields.push(this.formConfig.fields[val].fields[value]);
                    });
                    this.formConfig.fields[val] = null;
                }
            });
            this.changeDetectorRef.markForCheck();
            this.setFormData();
        }
        this.spinnerService.hide();
    }


    /**
     * Connect form config with submission data
     */
    private setFormData(): void {
        for (const field of this.formConfig.fields) {
            if (field !== null) {
                this.formData[field.name] = {
                    label: field.label,
                    type: field.type,
                    value: this.submission[field.name] || null
                };
            }
        }

        this.changeDetectorRef.markForCheck();
    }

    public edit(): void {
        this.router.navigate([
            this.state.section,
            this.state.component,
            "edit",
            "id",
            this.state.params.id
        ]);
    }

    public is_image(str: string): boolean {
        if (typeof str === "string") {
            return HelpersService.includes(str, [".jpg", ".png", ".jpeg", ".gif", ".webp", ".bmp"]);
        }
        return false;
    }

    public async recreatePdf(): Promise<any> {
        this.spinnerService.show();

        const {code, message}: Api.IResponse = await this.api3Service.put(
            `${this.state.section}/inspections/results/${this.state.params.id}/pdf`);

        this.spinnerService.hide();

        if (code === 200) {
            this.toastService.show(message, "success");
            this.getData();
        }
    }


    public ngOnInit(): void {
        this.getData();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "inspection-results",
            actions: {
                "view": ["browse_inspection_forms"]
            }
        };
    }
}
