<common-confirm></common-confirm>


<h1 class="container-heading">Partners List</h1>

<mat-card>
    <mat-card-content>

        <common-table2 *ngIf="listTable" [settings]="listTable">
            <div row1>
                <a *userAllowed="'add_partners'" routerLink="add" mat-raised-button color="primary"
                   class="create">Add</a>
            </div>
        </common-table2>

    </mat-card-content>
</mat-card>