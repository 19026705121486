import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit} from "@angular/core";
import {ReplaySubject} from "rxjs";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Modal} from "../../../../../services/modal.service";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";


@Component({
    selector: "section-box-form",
    templateUrl: "form.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoxFormComponent implements OnInit, OnDestroy {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    public packageTypes: ReplaySubject<any> = new ReplaySubject(1);

    public formGroup: FormGroup = new FormGroup({
        ref: new FormControl(null, [Validators.required]),
        warehouse_location_id: new FormControl(null),
        properties: new FormGroup({
            Lot: new FormControl(null),
            Po: new FormControl(null),
            Pieces: new FormControl(null),
            Weight: new FormControl(null),
            Dims: new FormControl(null),
            Carrier: new FormControl(null),
            "Pro #": new FormControl(null),
            Supplier: new FormControl(null),
        })
    });


    public modal: Modal.IModal;

    public constructor(
        private apiService: ApiService,
        private changeDetectorRef: ChangeDetectorRef,
        private spinnerService: SpinnerService
    ) {
    }


    /**
     * New package
     */
    private async submitNew(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["box", "warehouse_order", "" + this.modal.params.warehouse_order_id], this.formGroup.value);
        this.spinnerService.hide();
        if (data) {
            this.modal.response.emit({
                name: "value"
            });
        }
    }

    /**
     * Update package
     */
    private async submitUpdate(): Promise<any> {
        // this.spinnerService.show();
        // const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
        //     ["warehouse_package", "" + this.modal.params.box.id], this.formGroup.value);
        //
        // this.spinnerService.hide();
        // if (data) {
        this.modal.response.emit({
            name: "value"
        });
        // }
    }

    /**
     * Submit form
     */
    public submit(): void {
        if (this.modal.params.box && this.modal.params.box.id) {
            this.submitUpdate();
        } else {
            this.submitNew();
        }
    }

    public ngOnInit(): void {
        if (this.modal.params.box) {
            this.formGroup.patchValue(this.modal.params.box);
        }

    }


    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
