import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {Table2Component} from "../../../../../../common/components/table2";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {ToastService} from "../../../../../../common/services/toast.service";
import {FormControl, FormGroup} from "@angular/forms";
import {takeUntil} from "rxjs/operators";
import {WarehouseLocationFormComponent} from "../form/form.component";
import {Modal, ModalService} from "src/modules/section/services/modal.service";
import {Warehouse} from "../../../../../../common/interfaces/warehouse.interface";

@Component({
    selector: "section-warehouse-locations-list",
    template: `
        <common-confirm></common-confirm>
        <h1 class="container-heading">Warehouse locations</h1>
        <mat-card>
            <mat-card-content>
                <common-table2 *ngIf="listTable" [settings]="listTable">
                    <div row1 class="row flex align-center">
                        <div [formGroup]="filters" class="margin-right-10">
                            <common-form-select
                                class="form-select"
                                [options]="subInventories"
                                [multiple]="true"
                                [selectAll]="true"
                                [search]="true"
                                (onClose)="filters.get('sub_inventories_ids').setValue($event)"
                                label="Sub-inventories">
                            </common-form-select>
                        </div>
                        <button mat-raised-button type="button" color="primary" (click)="locationFrom()">
                            Create Location
                        </button>

                        <button mat-raised-button type="button" color="accent" (click)="exportCsv()">
                            Export CSV
                        </button>

                        <button mat-raised-button type="button" color="accent" (click)="importCsv()">
                            Import CSV
                        </button>
                    </div>
                </common-table2>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WarehouseLocationsListComponent implements Base.IComponent, OnInit, OnDestroy {

    public readonly state: Base.IState;

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    @ViewChild(ConfirmComponent, {static: true})
    public confirmComponent: ConfirmComponent;

    public listTable: Table.ISettings;

    public subInventories: any[] = [];

    public filters: FormGroup = new FormGroup({
        sub_inventories_ids: new FormControl([])
    });

    /* Table options */

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private spinnerService: SpinnerService,
        private changeDetectorRef: ChangeDetectorRef,
        private toastService: ToastService,
        private modalService: ModalService
    ) {
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        this.listTable = {
            table_id: "dsiu87uk.jm",
            actions: [
                {
                    name: "view",
                    title: "View",
                    click: (row: any) => {
                        this.router.navigate([
                            this.state.section,
                            this.state.component,
                            "view",
                            "id",
                            row.id,
                            "location",
                            row.location
                        ]);
                    }
                },
                {
                    name: "edit",
                    title: "Edit",
                    click: (row: any) => {
                        this.locationFrom(row);
                    }
                },
                {
                    name: "delete",
                    title: "Delete",
                    click: async (row: any): Promise<any> => {
                        if (!await this.confirmComponent.confirm(
                            `Are you sure to delete ${row.location} location?`)) {
                            return;
                        }

                        this.deleteLocation(row.id);
                    },
                    disabledFn: (row: any) => {
                        return false; //row.inventories_count !== 0 || row.boxes_count !== 0;
                    }
                }
            ],
            api: {
                url: ["warehouse_location"],
                query: {
                    ...this.filters.value
                }
            },
            columns: [
                {
                    data: "location",
                    title: "Name"
                },
                {
                    data: "inventory.inventory_conversion.sub_warehouse.sub_inventory",
                    name: "Inventory.InventoryConversion.SubWarehouse.sub_inventory",
                    title: "Sub Inventory"
                },
                {
                    data: "warehouse_location_type.name",
                    name: "WarehouseLocationType.name",
                    title: "Type"
                },
                {
                    data: "inventories_sum_quantity",
                    title: "Inventories count",
                    searchable: false,
                    render: (data: any): number => {
                        return data.inventories_sum_quantity ? data.inventories_sum_quantity : 0;
                    }
                },
                {
                    data: "boxes_count",
                    title: "Boxes count",
                    searchable: false,
                },
                {
                    data: "created_at",
                    title: "Created"
                },
                {
                    data: "updated_at",
                    title: "Updated"
                }
            ],
        };
        this.changeDetectorRef.markForCheck();
    }

    private async getSubWarehouses(): Promise<void> {
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["warehouse", "sub"], {}, {});
        if (response?.data) {
            this.subInventories = response.data.map((item: any): any => {
                return {name: item.sub_inventory, value: item.sub_inventory};
            });
        }
    }

    private async deleteLocation(id: number): Promise<any> {
        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
            ["warehouse_location", "" + id]);

        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.tableRef.reload();
        }
    }

    public importCsv(): void {
        this.router.navigate([
            this.state.section,
            "csv-import",
            "wizard",
            "type",
            "warehouse_location",
            "back_to",
            btoa(this.router.url)
        ]);
    }

    public async exportCsv(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            this.apiService.getUrl(["warehouse_location"]) + "?data_structure=dataTables");
        this.spinnerService.hide();
        const toCsv: { [key: string]: string }[] = [];
        for (const i in data) {
            if (!data.hasOwnProperty(i)) {
                continue;
            }
            const row: any = data[i];
            toCsv.push({
                "Name": "" + row.location,
                "Type": "" + row.warehouse_location_type.name,
                "Boxes count": "" + row.boxes_count,
                "Created": "" + row.created_at,
                "Updated": "" + row.updated_at
            });
        }
        HelpersService.exportCsv(toCsv, "Requests");
    }

    public async locationFrom(data: Warehouse.ILocation = null): Promise<void> {
        const response: Modal.IResponse = await this.modalService.open(WarehouseLocationFormComponent, {
            data
        });

        if (response && response.value) {
            this.tableRef.reload();
        }
    }


    public ngOnInit(): void {
        this.prepareList();
        this.getSubWarehouses();

        this.filters.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any): void => {
            this.prepareList();
        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }


    public ngConfig(): Base.IConfig {
        return {
            name: "warehouse-locations",
            actions: {
                "browse": ["browse_warehouse_locations"]
            }
        };
    }
}
