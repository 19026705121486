<div class="form" *ngIf="serviceLevels">
    <div class="group">
        <mat-form-field class="full">
            <mat-label>Service level</mat-label>
<mat-select [formControl]="service_level"  required>
                <mat-optgroup *ngFor="let group of serviceLevels | keys" [label]="group">
                    <mat-option *ngFor="let level of serviceLevels[group]" [value]="level">
                        {{ level.name }}
                        <small *ngIf="level.description">({{level.description}})</small>
                    </mat-option>
                </mat-optgroup>
            </mat-select>
        </mat-form-field>
        <div *ngIf="show_all">
            <mat-checkbox [formControl]="show_all">Not listed</mat-checkbox>
        </div>
    </div>
</div>


<ng-container *ngIf="!serviceLevels">
    <h3 class="text-center">Please fill previous step</h3>
</ng-container>
