import {ElementRef, Injectable} from "@angular/core";
import {StorageService} from "./storage.service";

@Injectable()
export class SoundsService {

    constructor(
        private storageService: StorageService
    ) {
    }

    /**
     * Text to speech
     * @param text
     */
    public textToSpeech(text: string): void {
        if (this.isSoundOn()) {
            speechSynthesis.cancel();
            const message: any = new SpeechSynthesisUtterance(text);
            let voices: any = speechSynthesis.getVoices();
            const interval: any = setInterval((): void => {
                voices = speechSynthesis.getVoices();
                if (voices.length) {
                    clearInterval(interval);
                } else {
                    return;
                }
                message.volume = 1;
                message.rate = 1;
                message.pitch = 1;
                message.voice = voices.find((voice: any): boolean => voice.name === "Google US English");
                speechSynthesis.speak(message);
            }, 10);
        }
    }

    public toggleSound(): void {
        this.storageService.set("soundOn", this.storageService.get("soundOn") === "on" ? "off" : "on");
    }

    public isSoundOn(): boolean {
        return this.storageService.get("soundOn", "on") === "on";
    }

}
