import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {UntypedFormBuilder, FormGroup, Validators} from "@angular/forms";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Modal} from "../../../../../services/modal.service";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {SpinnerService} from "../../../../../../common/services/spinner.service";

@Component({
    selector: "section-customer-account-form",
    templateUrl: "form.component.html",
    styleUrls: [
        "form.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class CustomerAccountFormComponent implements OnInit {

    private baseUrl: string[] = ["customer_account"];
    private idUrl: string[];

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    /**
     * Form group / group with controls
     * @type {FormGroup}
     */
    public formGroup: FormGroup;

    public modal: Modal.IModal;

    public action: string = "add";
    public placeholder: string = "Account number";

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private formBuilder: UntypedFormBuilder,
        private apiService: ApiService,
        private toastService: ToastService,
        private spinnerService: SpinnerService
    ) {
    }

    /**
     * Prepare form group (create)
     * @returns {void}
     */
    private prepareForm(): void {
        const data: any = this.modal && this.modal.params.data ? this.modal.params.data : {};
        this.formGroup = this.formBuilder.group({
            name: [data.name, [Validators.required]]
        });
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Get contact data
     */
    private async getData(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            this.idUrl);
        if (data) {
            this.modal.params.data = data;
            this.prepareForm();
        }
        this.spinnerService.hide();
    }

    /**
     * Submit form (add)
     * @returns {Promise<any>}
     */
    public async handleFormSubmit(): Promise<any> {
        this.spinnerService.show();

        if (this.action === "add") {
            const body: any = {...this.formGroup.value};
            const {data, message}: Api.IResponse = await this.apiService
                .request(Api.EMethod.Post, this.baseUrl, body);

            if (data) {
                this.toastService.show(message.replace("_", " "), "success");
                this.modal.response.next({
                    name: "value",
                    value: {
                        controlValue: data.id,
                        viewValue: data.name
                    }
                });
            }
        } else if (this.action === "edit") {
            const response: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
                this.idUrl, this.formGroup.value);

            if (response) {
                this.toastService.show(response.message, response.type);
                if (response.data) {
                    this.modal.response.next({
                        name: "value",
                        value: response.data
                    });
                }
            }
        }
        this.spinnerService.hide();
    }

    /**
     * Delete contact
     */
    public async delete(): Promise<any> {
        if (await this.confirmRef.confirm("Are you sure want to delete this contact?")) {
            this.spinnerService.show();
            const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
                ["contacts", this.modal.params.id]);
            if (type as string === "success") {
                this.toastService.show(message, "success");
            }
            this.spinnerService.hide();
        }
    }

    /**
     * Show add new address form
     * @returns {void}
     */
    public showList(): void {
        this.modal.response.next({
            name: "list"
        });
    }

    public ngOnInit(): void {
        this.action = this.modal && this.modal.params.action ? this.modal.params.action : "add";
        if (this.modal.params.type === "company") {
            this.placeholder = "Company name";
            this.baseUrl = ["customer_account", "courier_companies"];
            this.idUrl = this.action !== "add" ? ["customer_account", "courier_companies", this.modal.params.data.id]
                : [];
        } else if (this.modal.params.type === "service") {
            this.placeholder = "Service name";
            this.baseUrl = ["customer_account", "service_levels"];
            this.idUrl = this.action !== "add" ? ["customer_account", "service_levels", this.modal.params.data.id]
                : [];
        } else {
            this.idUrl = this.action !== "add" ? ["customer_account", this.modal.params.data.id] : [];
        }
        if (this.modal.params.getData) {
            this.getData();
        } else {
            this.prepareForm();
        }
    }

}
