import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Output,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {UntypedFormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {ConfirmComponent} from "../../../../../../../common/components/confirm/confirm.component";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";

@Component({
    selector: "section-partner-form-step2",
    templateUrl: "step2.component.html",
    styleUrls: [
        "step2.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class PartnerFormStep2Component {

    private slug: string;

    @Output()
    public result: EventEmitter<any> = new EventEmitter();

    @Output()
    public authFail: EventEmitter<any> = new EventEmitter();

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;


    public newCoverageForm: FormGroup = this.formBuilder.group({
        display_name: [null, [Validators.required]]
    });

    /**
     * Existing coverages
     * @type {any[]}
     */
    public formGroupArray: any[] = [];

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private spinnerService: SpinnerService
    ) {
    }

    /**
     * Coverage form "constructor"
     * @param {string} id
     * @param {string} display_name
     * @returns {FormGroup}
     */
    private coverage(id: string, display_name: string): FormGroup {
        return this.formBuilder.group({
            id: [id],
            display_name: [display_name, Validators.required]
        });
    }

    /**
     * Add coverage to existing list
     * @param {string} id
     * @param {string} display_name
     */
    private addCoverage(id: string, display_name: string): void {
        this.formGroupArray.push(this.coverage(id, display_name));
    }

    /**
     * Get data from server
     */
    private async prepareData(): Promise<any> {
        this.spinnerService.show();

        this.apiService.setPartner(this.slug);
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["coverage"], {},
            {data_structure: "select"});

        if (response.code === 401) {
            this.authFail.emit(true);
            return;
        }
        this.formGroupArray = [];
        if (response.data) {
            for (const item of response.data) {
                this.addCoverage(item.value, item.name);
            }

            if (this.formGroupArray.length > 0) {
                this.result.next(true);
            }
        }
        this.changeDetectorRef.markForCheck();
        this.spinnerService.hide();
    }

    /**
     * Initialize step
     * @returns {Promise<any>}
     * @param slug
     */
    public async init(slug: string): Promise<any> {
        this.slug = slug;
        this.prepareData();
    }

    /**
     * Submit new coverage
     * @returns {Promise<any>}
     */
    public async submitNewCoverage(): Promise<any> {
        this.spinnerService.show();
        this.apiService.setPartner(this.slug);
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Post, ["coverage"],
            {...this.newCoverageForm.value});

        this.toastService.show(response.message, response.type as string);
        if (response.data) {
            this.addCoverage(response.data.id, response.data.display_name);
            this.newCoverageForm.reset();
            this.result.next(true);
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Submit edit coverage
     * @param {number} index
     * @returns {Promise<any>}
     */
    public async submitCoverage(index: number): Promise<any> {
        this.spinnerService.show();
        const id: string = String(this.formGroupArray[index].value.id);
        this.apiService.setPartner(this.slug);
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Put, ["coverage", id],
            {
                display_name: this.formGroupArray[index].value.display_name
            });

        this.toastService.show(response.message, response.type as string);
        if (response.data) {
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Submit delete coverage
     * @param {number} index
     * @returns {Promise<any>}
     */
    public async submitDelete(index: number): Promise<any> {
        if (
            await this.confirmRef
                .confirm(`Do you want to delete "${this.formGroupArray[index].value.display_name}"?`)
        ) {
            this.spinnerService.show();
            const id: string = String(this.formGroupArray[index].value.id);
            this.apiService.setPartner(this.slug);
            const response: Api.IResponse = await this.apiService.request(Api.EMethod.Delete, ["coverage", id]);
            this.toastService.show(response.message, response.type as string);

            if (response.data) {
                this.formGroupArray.splice(index, 1);
                if (this.formGroupArray.length === 0) {
                    this.result.next(null);
                }
                this.changeDetectorRef.markForCheck();
            }
            this.spinnerService.hide();
        }
    }
}
