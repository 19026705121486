import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {GoogleChartsService} from "../../../services/google-chart.service";
import {DrawerService} from "../../../../app/services/drawer.service";
import {debounceTime, takeUntil} from "rxjs/operators";

@Component({
    selector: "common-donut-chart",
    template: `
        <div class="chart-container">
            <div class="chart donut" [class.ready]="readyClass" #chart></div>
            <div class="legend">
                <div *ngFor="let row of legend">
                    <div class="color" [style.background-color]="row.color"></div>
                    <div class="text" [innerHTML]="row.text"></div>
                    <div class="count">{{row.count}}%</div>
                </div>
            </div>
        </div>
    `,
    styleUrls: [
        "donut.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class DonutChartComponent implements OnChanges, OnInit, OnDestroy {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();
    private componentData: [string, number | string][];

    private colors: any = {
        0: {color: "#40A4D8", textStyle: {color: "#40A4D8"}},
        1: {color: "#33BEB7", textStyle: {color: "#33BEB7"}},
        2: {color: "#B2C224", textStyle: {color: "#B2C224"}},
        3: {color: "#FECC2F", textStyle: {color: "#FECC2F"}},
        4: {color: "#F66320", textStyle: {color: "#F66320"}},
        5: {color: "#DB3937", textStyle: {color: "#DB3937"}},
        6: {color: "#EE6579", textStyle: {color: "#EE6579"}},
        7: {color: "#A364D9", textStyle: {color: "#A364D9"}},
        8: {color: "#607D8B", textStyle: {color: "#607D8B"}},
        9: {color: "#9E9E9E", textStyle: {color: "#9E9E9E"}},
    };

    public legend: any[] = [];

    @Input()
    public data: [string, number | string][];

    @Output()
    public init: EventEmitter<boolean> = new EventEmitter();

    @Input()
    public title: string;

    @Output()
    public ready: EventEmitter<boolean> = new EventEmitter();

    @ViewChild("chart", {static: true})
    public div: ElementRef;

    public readyClass: boolean = false;

    public constructor(
        private chartsService: GoogleChartsService,
        private changeDetectorRef: ChangeDetectorRef,
        private drawerService: DrawerService
    ) {
    }

    private drawChart(): void {
        const dataTable: any = new this.chartsService.api.visualization.arrayToDataTable(this.componentData);
        if (this.div.nativeElement) {
            const chart: any = new this.chartsService.api.visualization.PieChart(this.div.nativeElement);

            this.chartsService.api.visualization.events.addListener(chart, "ready", (): void => {
                this.readyClass = true;
                this.ready.emit(true);
                this.changeDetectorRef.markForCheck();
                this.makeLegend();
            });

            try {
                chart.draw(dataTable, {
                    backgroundColor: "transparent",
                    enableInteractivity: false,
                    pieHole: 0.5,
                    title: this.title ? this.title : null,
                    legend: {position: "none"},
                    width: 300,
                    height: 300,
                    chartArea: {
                        width: "90%",
                        height: "90%"
                    },
                    slices: this.colors,
                    tooltip: {
                        trigger: "none"
                    }
                });
            } catch (e) {
                console.error(e);
            }
        }
    }

    private makeLegend(): void {
        const headers: any = this.componentData.splice(0, 1);
        let totalCount: number = 0;
        for (const row of this.componentData) {
            totalCount += row[1] as number;
        }
        this.legend = [];
        for (const key of Object.keys(this.componentData)) {
            this.legend.push({
                text: `<div>${(this.componentData[key][0].charAt(0).toUpperCase()
                    + this.componentData[key][0].substr(1, this.componentData[key][0].length - 1))}
                    </div>
                    <div class='sub'>${this.componentData[key][1] + " " + ("" + headers[0][1]).toLowerCase()}</div>`,
                color: this.colors[key].color,
                count: (this.componentData[key][1] / (totalCount / 100)).toFixed(2)
            });
        }
        this.changeDetectorRef.markForCheck();
    }

    public ngOnInit(): void {
        this.init.emit(true);
        this.drawerService.drawerLockState.pipe(takeUntil(this.destroy$), debounceTime(100))
            .subscribe((): void => {
                this.drawChart();
            });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.div && this.data) {
            this.componentData = [...this.data];
            const callback: any = (): any => this.drawChart();
            this.chartsService.load(callback);
        }
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
