import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {AbstractWizardComponent, Wizard, WizardStepFactory} from "../../../../../../common/interfaces/wizard.interface";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {PartnerProceduresWizardUpdateSerialComponent} from "./update-serial/update-serial.component";
import {
    PartnerProceduresWizardAddSerialComponent,
    PartnerProceduresWizardRemoveSerialComponent,
    PartnerProceduresWizardSerialToQtyComponent,
    PartnerProceduresWizardTypeComponent,
    PartnerProceduresWizardUpdateBatchComponent,
    PartnerProceduresWizardUpdateBoxHubComponent,
    PartnerProceduresWizardUpdateHubComponent,
    PartnerProceduresWizardUpdateItemComponent,
    PartnerProceduresWizardUpdateItemForSerialComponent,
    PartnerProceduresWizardUpdateConfigurationForSerialComponent,
    PartnerProceduresWizardUnlinkSerialFromBoxComponent,
    PartnerProceduresWizardLinkSerialToBoxComponent,
    PartnerProceduresWizardBoxRefChangeComponent,
    PartnerProceduresWizardPalletRefChangeComponent
} from "./index";
import {PartnerProceduresWizardUpdateQuantityComponent} from "./update-quantity/update-quantity.component";

@Component({
    selector: "section-partner-procedures",
    templateUrl: "wizard.component.html",
    styleUrls: [
        "./wizard.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})

export class PartnerProceduresWizardComponent extends AbstractWizardComponent implements OnInit, AfterViewInit {

    public status: any;

    public constructor(protected changeDetectorRef: ChangeDetectorRef) {
        super(changeDetectorRef);
    }

    protected handleSetupEvent(event: Wizard.IStepResult, stepIndex: number): void {
        if (this.steps[stepIndex].title === "Scan type") {
            this.steps.splice(1);
            if (event.value === "serial-add") {
                this.steps.push(
                    new WizardStepFactory("Add serial", PartnerProceduresWizardAddSerialComponent));
            } else if (event.value === "serial-change") {
                this.steps.push(
                    new WizardStepFactory("Update serial", PartnerProceduresWizardUpdateSerialComponent));
            } else if (event.value === "serial-remove") {
                this.steps.push(
                    new WizardStepFactory("Remove serial", PartnerProceduresWizardRemoveSerialComponent));
            } else if (event.value === "item-change") {
                this.steps.push(
                    new WizardStepFactory("Replace part number in inventory",
                        PartnerProceduresWizardUpdateItemComponent));
            } else if (event.value === "quantity-change") {
                this.steps.push(
                    new WizardStepFactory("Update item quantity", PartnerProceduresWizardUpdateQuantityComponent));
            } else if (event.value === "hub-change") {
                this.steps.push(
                    new WizardStepFactory("Hub transfer", PartnerProceduresWizardUpdateHubComponent));
            } else if (event.value === "box-hub-change") {
                this.steps.push(
                    new WizardStepFactory("Box sub inventory transfer", PartnerProceduresWizardUpdateBoxHubComponent));
            } else if (event.value === "item-change-serial") {
                this.steps.push(
                    new WizardStepFactory("Update item for serial",
                        PartnerProceduresWizardUpdateItemForSerialComponent));
            } else if (event.value === "serial-to-qty") {
                this.steps.push(
                    new WizardStepFactory("Convert serial based item to quantity based",
                        PartnerProceduresWizardSerialToQtyComponent));
            } else if (event.value === "item-batch") {
                this.steps.push(
                    new WizardStepFactory("Update item batch",
                        PartnerProceduresWizardUpdateBatchComponent));
            } else if (event.value === "item-change-configuration") {
                this.steps.push(
                    new WizardStepFactory("Update configuration for serial",
                        PartnerProceduresWizardUpdateConfigurationForSerialComponent)
                );
            } else if (event.value === "unlink-serial-from-box") {
                this.steps.push(
                    new WizardStepFactory("Unlink Serial from box",
                    PartnerProceduresWizardUnlinkSerialFromBoxComponent)
                );
            } else if (event.value === "link-serial-to-box") {
                this.steps.push(
                    new WizardStepFactory("Link serial to box",
                    PartnerProceduresWizardLinkSerialToBoxComponent)
                );
            } else if (event.value === "box-ref-change") {
                this.steps.push(
                    new WizardStepFactory("Box ref update",
                    PartnerProceduresWizardBoxRefChangeComponent)
                );
            } else if (event.value === "pallet-ref-change") {
                this.steps.push(
                    new WizardStepFactory("Pallet ref update",
                    PartnerProceduresWizardPalletRefChangeComponent)
                );
            }

            setTimeout(() => {
                this.stepper.next();
            }, 500);
        }
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.data.wizardType = "procedures";

        this.steps.push(
            new WizardStepFactory("Scan type", PartnerProceduresWizardTypeComponent,
                false, null, null, {
                    groups: [
                        {
                            options:
                                [
                                    {
                                        name: "Add Serial item to stock",
                                        value: "serial-add"
                                    },
                                    {
                                        name: "Remove Serial item from stock",
                                        value: "serial-remove"
                                    },
                                    {
                                        name: "Change Serial for item in stock",
                                        value: "serial-change"
                                    },
                                    {
                                        name: "Change Part Number for Serial item in stock",
                                        value: "item-change-serial"
                                    },
                                    {
                                        name: "Convert Serial item to a quantity managed item",
                                        value: "serial-to-qty"
                                    },
                                    {
                                        name: "Change Configuration for Serial item in stock",
                                        value: "item-change-configuration"
                                    },
                                    {
                                        name: "Unlink Serial from box",
                                        value: "unlink-serial-from-box"
                                    },
                                    {
                                        name: "Link serial to box",
                                        value: "link-serial-to-box"
                                    }
                                ],
                            group: "Items managed by Serial"
                        },

                        {
                            options:
                                [
                                    {
                                        name: "Change Part Number for Non Serial item in stock",
                                        value: "item-change"
                                    },
                                    {
                                        name: "Change Qty in stock for Non Serial item",
                                        value: "quantity-change"
                                    },
                                    {
                                        name: "Move Serial/ Non serial item between Sub inventories",
                                        value: "hub-change"
                                    }
                                ],
                            group: "Items managed by Qty"
                        },

                        {
                            options: [
                                {
                                    name: "Move boxes between Sub inventories",
                                    value: "box-hub-change"
                                }
                            ],
                            group: "Items managed by Box"
                        },

                        {
                            options: [
                                {
                                    name: "Update item batch",
                                    value: "item-batch"
                                }
                            ],
                            group: "Items managed by Batch"
                        },

                        {
                            options: [
                                {
                                    name: "Box Ref change",
                                    value: "box-ref-change"
                                },
                                {
                                    name: "Pallet Ref change",
                                    value: "pallet-ref-change"
                                }
                            ],
                            group: "Packages manage"
                        }
                    ],
                }));
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "procedures",
            actions: {
                "browse": ["read_reports"],
                "wizard": ["read_reports"],
            }
        };
    }

}
