import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, ViewEncapsulation} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../common/interfaces/wizard.interface";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {HelpersService} from "../../../../../../../common/services/helpers.service";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {ModalService} from "../../../../../../services/modal.service";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {map, startWith, takeUntil} from "rxjs/operators";
import {Warehouse} from "../../../../../../../common/interfaces/warehouse.interface";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {Form} from "../../../../../../../common/interfaces/form.interface";
import {Observable} from "rxjs";
import ISelectOption = Form.ISelectOption;

@Component({
    selector: "section-partner-procedures-wizard-update-box-hub",
    templateUrl: "update-box-hub.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class PartnerProceduresWizardUpdateBoxHubComponent extends AbstractWizardStepComponent implements OnDestroy {

    private state: Base.IState;

    public available_hubs: { group: string, hubs: Warehouse.IHub[] }[];

    public old_hub: FormControl = new FormControl(null, [Validators.required]);

    public form: FormGroup = new FormGroup({
        inventory_conversion_id: new FormControl(null, [Validators.required]),
        boxes: new FormControl([], [Validators.required]),
        remark: new FormControl(null, [Validators.required]),
    });

    public boxSearch: FormControl = new FormControl(null);
    public boxes: ISelectOption[] = [];
    public filteredBoxes: Observable<ISelectOption[]>;

    public errors: string[] = [];

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        public helperService: HelpersService,
        private apiService: ApiService,
        private toastService: ToastService,
        private modalService: ModalService,
        private spinnerService: SpinnerService
    ) {
        super(changeDetectorRef);
    }

    private async getBoxesInHub(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["box"], {}, {
                data_structure: "select",
                hub_id: this.old_hub.value,
            });
        this.spinnerService.hide();
        if (data) {
            this.boxes = data;
            if (!this.boxes.length) {
                this.errors = ["No boxes in hub."];
            }
            this.changeDetectorRef.markForCheck();
        }
    }

    private filterBoxes(value: string): ISelectOption[] {
        if (!value || typeof value !== "string") {
            return [];
        }
        const filterValue: string = value.toLowerCase();

        return this.boxes.filter((box: ISelectOption): boolean =>
            box.name.toLowerCase().includes(filterValue)).slice(0, 50);
    }

    public async validateBoxes(): Promise<any> {
        this.errors = [];
        this.changeDetectorRef.markForCheck();
        if (!this.form.value.boxes.length) {
            return;
        }

        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["box", "allocation"], {}, {
                boxes: this.form.value.boxes,
                hub_id: this.old_hub.value,
            });
        this.spinnerService.hide();
        if (data) {
            const used: string[] = [];
            for (const allocation of data) {
                if (used.indexOf(allocation.box.ref) > -1) {
                    continue;
                }
                this.errors.push(`Box ${allocation.box.ref} is assigned to order ${allocation.order.ref}.`);
                used.push(allocation.box.ref);
            }
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Submit form
     */
    public async submit(): Promise<any> {
        this.spinnerService.show();
        const {type, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["inventory", "box-hub-change"], this.form.value);
        if (type as string === "success") {
            this.toastService.show(message, "success");
            this.form.reset({
                inventory_conversion_id: null,
                boxes: [],
                remark: null
            });

            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Add existing boxes on Enter.
     * In case of copy/paste multiple boxes in search field
     * @param event
     */
    public onKeydown(event: any): void {
        if (event.key === "Enter" && this.boxSearch.value !== "") {
            const values: string[] = this.boxSearch.value.split(/[\s,;]/);

            const boxes: string[] = this.form.value.boxes;

            for (const value of values) {
                if (value && value.trim() !== "") {
                    const exists: any = this.boxes.find((box: ISelectOption): boolean => {
                        return box.name.toLowerCase() === value.trim().toLowerCase();
                    });

                    if (!!exists && !boxes.map(b => b.toLowerCase()).includes(value.trim().toLowerCase())) {
                        boxes.push(value.trim());
                    }
                }
            }
            this.form.get("boxes").setValue(boxes);

            this.boxSearch.reset();
            this.changeDetectorRef.markForCheck();
            this.validateBoxes();
        }
    }

    public addBoxChip(event: MatAutocompleteSelectedEvent): void {
        this.boxSearch.setValue("");

        const boxes: string[] = this.form.value.boxes;
        if (boxes.map(b => b.toLowerCase()).includes(event.option.value.toLowerCase())) {
            return;
        }

        boxes.push(event.option.value);
        this.form.get("boxes").setValue(boxes);

        this.changeDetectorRef.markForCheck();
        this.validateBoxes();
    }

    public removeBoxChip(ref: string): void {
        const boxes: string[] = this.form.value.boxes;
        const index: number = boxes.indexOf(ref);

        if (index >= 0) {
            boxes.splice(index, 1);
            this.form.get("boxes").setValue(boxes);
            this.validateBoxes();
        }
    }

    /**
     * Initialize step
     * @param data
     * @returns {Promise<any>}
     */
    public async init(data: Wizard.IData): Promise<any> {
        this.state = data.state;

        this.result.emit({
            action: "result",
            value: true
        });

        this.old_hub.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val: number): void => {
            if (val) {
                this.form.get("boxes").setValue([]);
                this.getBoxesInHub();
            }
        });

        this.filteredBoxes = this.boxSearch.valueChanges.pipe(
            startWith(""),
            map((value: string): any => this.filterBoxes(value))
        );
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
