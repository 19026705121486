<app-drawer class="{{theme}} {{lockDrawer ? ' locked':''}}">

    <mat-toolbar class="mat-elevation-z2">
        <app-toolbar #toolbarComponent [section]="section" [type]="type" [theme]="theme"
                     (openLivechat)="liveChatWidget.openChatWindow()"
                     [livechat]="!!(liveChat && liveChatLicenseId)"
                     class="mat-toolbar-row"></app-toolbar>
    </mat-toolbar>

    <div class="ribbon">
        <!--        <particles [style]="particleStyle" [width]="100" [height]="100" [params]="particlesParams"></particles>-->
    </div>

    <div class="container">
        <router-outlet #outlet="outlet" (activate)="onActivate()"></router-outlet>
    </div>

    <ng-container *ngIf="liveChat && liveChatLicenseId">
        <common-livechat #liveChatWidget
                         [licenseId]="liveChatLicenseId"
                         (onChatWindowOpened)="setOrderRefIfExistsInUrl()"
                         [visitor]="visitor"></common-livechat>
    </ng-container>

</app-drawer>

<common-test></common-test>
