import {Component, ComponentRef, Directive, ElementRef, EventEmitter, HostListener, Input, Output} from "@angular/core";
import {Overlay, OverlayConfig, OverlayRef} from "@angular/cdk/overlay";
import {ComponentPortal} from "@angular/cdk/portal";
import {take} from "rxjs/operators";
import {User} from "../interfaces/user.interface";

@Component({
    selector: "common-mentions-dropdown",
    template: `
        <div>
            <a mat-menu-item *ngFor="let user of users" (click)="select(user)">
                <span class="avatar" *ngIf="!user.isTeam"
                      [style.background-image]="'url(' +(user.avatar
                       ? user.avatar : '/assets/images/default-avatar.png')+')'">
                </span>
                <span class="avatar" *ngIf="user.isTeam">
                    {{user.name.substr(0, 3)}}
                </span>
                @{{user.nickname}} ({{user.name}})
            </a>
        </div>
    `,
    styles: [
        `
                         div {
                             background-color: #fff;
                             padding: 10px;
                             box-shadow: 5px 5px 5px #000;
                         }

                         .avatar {
                             width: 32px;
                             height: 32px;
                             display: inline-block;
                             vertical-align: middle;
                             margin-right: 10px;
                             background-position: 50% 50%;
                             background-size: cover;
                             background-repeat: no-repeat;
                             border-radius: 50%;
                             text-transform: uppercase;
                             text-align: center;
                             font-size: 12px;
                             line-height: 32px;
                             overflow: hidden;
                             background-color: #cccccc;
                         }
                     `
    ],
})
export class MentionsDropdownComponent {

    public users: User.IMentionUser[] = [];

    public optionSelect: EventEmitter<any> = new EventEmitter<any>();

    public select(user: User.IMentionUser): void {
        this.optionSelect.emit(user);
    }
}


@Directive({
    selector: "[mentions]",
})
export class MentionsDirective {

    private element: HTMLInputElement;

    private overlayRef: OverlayRef;

    private showing: boolean = false;

    private dropInstance: any;

    private lastMatch: string;

    private matchesHash: string = "";

    @Input()
    public mentionUsers: User.IMentionUser[] = [];

    @Input()
    public limit: number = 5;

    @Output()
    public onSelect: EventEmitter<any> = new EventEmitter();

    public constructor(
        private elRef: ElementRef,
        private overlay: Overlay
    ) {
        this.element = elRef.nativeElement;
    }

    private syncWidth(): void {
        if (!this.overlayRef) {
            return;
        }
        const refRect: ClientRect = this.element.getBoundingClientRect();
        this.overlayRef.updateSize({width: refRect.width});
    }

    private getOverlayConfig(): OverlayConfig {
        const positionStrategy: any = this.overlay.position()
            .flexibleConnectedTo(this.element)
            .withPush(false)
            .withPositions([
                {
                    originX: "start",
                    originY: "bottom",
                    overlayX: "start",
                    overlayY: "top"
                }, {
                    originX: "start",
                    originY: "top",
                    overlayX: "start",
                    overlayY: "bottom"
                }
            ]);

        return new OverlayConfig({
            positionStrategy: positionStrategy,
            hasBackdrop: false
        });
    }

    private show(): void {
        if (this.showing) {
            return;
        }
        this.showing = true;
        this.overlayRef = this.overlay.create(this.getOverlayConfig());

        const portal: ComponentPortal<any> = new ComponentPortal(MentionsDropdownComponent);

        const componentRef: ComponentRef<any> = this.overlayRef.attach(portal);
        this.dropInstance = componentRef.instance;

        this.syncWidth();

        this.dropInstance.optionSelect.pipe(take(1)).subscribe((response: User.IMentionUser): void => {
            this.hide();
            const rex: RegExp = new RegExp(this.lastMatch + "($|\W)");

            this.element.value = this.element.value.replace(rex, "@" + response.nickname + " ");
            this.element.focus();
        });

        this.overlayRef.detachments().pipe(take(1)).subscribe((): void => this.overlayRef.dispose());
    }

    private hide(): void {
        if (!this.showing) {
            return;
        }
        this.overlayRef.detach();
        this.showing = false;
    }

    private filterusers(value: string): User.IMentionUser[] {
        value = value.toLowerCase();
        if (value) {
            const filteredUsers: any = this.mentionUsers.filter((user: User.IMentionUser): boolean => {
                return user.nickname.toLowerCase().includes(value) || user.name.toLowerCase().includes(value);
            });
            return filteredUsers.slice(0, 5);
        } else {
            return this.mentionUsers.slice(0, 5);
        }
    }

    private findChangedMatch(newMatches: string[]): string {
        const oldMatches: string[] = JSON.parse(this.matchesHash);
        const diff: string = newMatches.find((match: string): boolean => {
            return oldMatches.indexOf(match) === -1;
        });
        return diff;
    }

    @HostListener("keypress", ["$event.key", "$event.target"])
    public onKeypress(key: string, input: HTMLInputElement): void {
        setTimeout((): void => {
            const matches: string[] = input.value.match(/@([a-z0-9_-]+)/gm);
            if (!matches) {
                this.hide();
                return;
            }

            const newMatchesHash: string = JSON.stringify(matches);
            if (this.matchesHash === newMatchesHash) {
                this.hide();
                return;
            }

            if (this.matchesHash) {
                this.lastMatch = this.findChangedMatch(matches);
            } else {
                this.lastMatch = matches.pop();
            }

            if (!this.lastMatch) {
                this.hide();
                return;
            }
            this.matchesHash = newMatchesHash;

            const users: any[] = this.filterusers(this.lastMatch.replace("@", ""));
            if (users.length) {
                this.show();
                this.dropInstance.users = users;
            } else {
                this.hide();
            }

        }, 10);
    }

    @HostListener("document:keydown.escape", ["$event"])
    public onEscape(event: KeyboardEvent): void {
        this.hide();
    }

    @HostListener("blur")
    public onBlur(event: KeyboardEvent): void {
        this.hide();
    }
}
