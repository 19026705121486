import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {Router} from "@angular/router";
import {Modal, ModalService} from "../../../../../../services/modal.service";
import {AbstractControl, UntypedFormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {COMMA, ENTER, SEMICOLON} from "@angular/cdk/keycodes";
import {MatChipInputEvent} from "@angular/material/chips";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {ImageUploadService} from "../../../../../../../common/services/image-upload.service";

@Component({
    selector: "section-integrations-functions-form",
    templateUrl: "form.component.html",
    styleUrls: [
        "form.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class IntegrationsFunctionsFormComponent implements OnInit {

    public readonly state: Base.IState;
    public modal: Modal.IModal;

    public formGroup: FormGroup = new FormGroup({
        name: new FormControl(null, [Validators.required]),
        description: new FormControl(null, [Validators.required]),
        icon: new FormControl(null),
        class_name: new FormControl(null, [Validators.required]),
        method_name: new FormControl(null, [Validators.required]),
        is_async: new FormControl(null),
        internal: new FormControl(null),
        can_be_scheduled: new FormControl(false),
        fields: new UntypedFormArray([])
    });

    public fieldTypes: { [key: string]: any } = {
        input: {
            options: true,
            delete: true,
            type: true,
            required: true,
        },
        select: {
            options: true,
            delete: true,
            type: true,
            required: true,
            multiple: true,
            url: true
        },
        checkbox: {
            options: false,
            delete: true,
            required: true,
        },
        radio: {
            options: true,
            delete: true,
            type: true,
            required: true,
        },
        text: {
            options: true,
            delete: true,
            type: true,
            required: true,
        },
        search: {
            delete: true,
            type: true,
            required: true,
            multiple: true,
            url: true
        },
        file: {
            options: false,
            accept: true,
            delete: true,
            type: true,
            required: true,
        },
        barcode: {
            options: false,
            delete: true,
            type: true,
            required: true,
            multiple: true
        },
        signature: {
            options: false,
            delete: true,
            type: true,
            required: true,
        },
        status: {
            options: true,
            delete: true,
            type: false,
            required: true,
        },
        filler: {
            options: false,
            delete: true,
            type: true,
            required: false,
        },
        date: {
            options: false,
            delete: true,
            type: true,
            required: true,
        },
        date_range: {
            options: false,
            delete: true,
            type: true,
            required: true,
        },
        link: {
            options: false,
            delete: true,
            type: true,
            required: false,
            url: true
        },
        free_text: {
            options: false,
            delete: true,
            type: true,
            description: true
        },
        label: {
            options: false,
            delete: true,
            type: true,
            required: true,
        }
    };

    public fieldSizes: string[] = ["full", "half", "third"];
    public separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON];

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private toastService: ToastService,
        private router: Router,
        private modalService: ModalService,
        private imageUploadService: ImageUploadService,
        private spinnerService: SpinnerService
    ) {
    }

    /**
     * Prepare form
     * @return {Promise<any>}
     */
    private async prepareForm(): Promise<any> {
        this.spinnerService.show();
        if (this.modal.params.func) {
            this.formGroup.get("name").setValue(this.modal.params.func.name);
            this.formGroup.get("description").setValue(this.modal.params.func.description);
            this.formGroup.get("icon").setValue(this.modal.params.func.icon);
            this.formGroup.get("class_name").setValue(this.modal.params.func.class_name);
            this.formGroup.get("method_name").setValue(this.modal.params.func.method_name);
            this.formGroup.get("is_async").setValue(this.modal.params.func.async);
            this.formGroup.get("internal").setValue(this.modal.params.func.partner_id !== null);


            const fields: any = this.modal.params.func.fields;

            for (const field of fields) {
                this.addField(field.label, field.name, field.type, field.values,
                    field.required, field.multiple, field.size, field.url, field.description, field.accept);
            }
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Make names from labels
     */
    private writeNames(): void {
        for (const index of Object.keys(this.formGroup.value.fields)) {
            const label: string = (this.formGroup.get("fields") as UntypedFormArray).controls[index].value.label;

            const name_field: FormControl = (this.formGroup.get("fields") as UntypedFormArray)
                .controls[index].get("name");

            if (!name_field.value) {
                name_field.setValue(this.labelToName(label, Number(index)));
            }
        }
    }

    /**
     * Convert string to snake_case
     * @param {string} label
     * @param {number} index
     * @param {boolean} group
     * @param {boolean} sign
     * @return {string}
     */
    private labelToName(label: string, index: number, group?: boolean, sign?: boolean): string {
        return label ? (label.toLowerCase()
                .replace(/ /g, "_")
                .replace(/[^a-z0-9_]/g, "")
            + (sign ? "sign_" : "")
            + "_" + index + (group ? "_g" : "")) : null;
    }

    /**
     * Add new field
     * @param {string} label
     * @param {string} name
     * @param {string} type
     * @param {string[]} values
     * @param {boolean} required
     * @param multiple
     * @param {string} size
     * @param url
     * @param description
     * @param accept
     */
    public addField(
        label: string = null,
        name: string = null,
        type: string = "input",
        values: string[] = [],
        required: boolean = false,
        multiple: boolean = false,
        size: string = "full",
        url: string = null,
        description: string = null,
        accept: string[] = []
    ): void {

        if (!name) {
            name = this.labelToName(label,
                (this.formGroup.controls.fields as UntypedFormArray).length, false, type === "signature");
        }

        (this.formGroup.controls.fields as UntypedFormArray).push(
            new FormGroup({
                label: new FormControl(label, [Validators.required]),
                name: new FormControl(name),
                type: new FormControl(type, [Validators.required]),
                values: new FormControl(values),
                required: new FormControl(required),
                multiple: new FormControl(multiple),
                size: new FormControl(size, [Validators.required]),
                url: new FormControl(url),
                description: new FormControl(description),
                accept: new FormControl(accept),
            })
        );
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Remove field
     * @param {number} index
     */
    public removeField(index: number): void {
        (this.formGroup.controls.fields as UntypedFormArray).removeAt(index);
        this.changeDetectorRef.markForCheck();
    }

    /**
     * move field up
     * @param {number} index
     */
    public moveUp(index: number): void {
        const field: AbstractControl = (this.formGroup.controls.fields as UntypedFormArray).controls[index];
        (this.formGroup.controls.fields as UntypedFormArray).removeAt(index);
        (this.formGroup.controls.fields as UntypedFormArray).insert(index - 1, field);
    }

    /**
     * Move field down
     * @param {number} index
     */
    public moveDown(index: number): void {
        const field: AbstractControl = (this.formGroup.controls.fields as UntypedFormArray).controls[index];
        (this.formGroup.controls.fields as UntypedFormArray).removeAt(index);
        (this.formGroup.controls.fields as UntypedFormArray).insert(index + 1, field);
    }

    public moveTo(index: number, to: number | string): void {
        const field: AbstractControl = (this.formGroup.controls.fields as UntypedFormArray).controls[index];
        (this.formGroup.controls.fields as UntypedFormArray).removeAt(index);
        (this.formGroup.controls.fields as UntypedFormArray).insert(Number(to), field);
    }

    /**
     *
     * Add chip
     * @param {MatChipInputEvent} event
     * @param {number} index
     * @param field
     */
    public addChip(event: MatChipInputEvent, index: number, field: string): void {
        const input: HTMLInputElement = event.input;
        const value: string = event.value;
        if ((value || "").trim()) {
            const val: string[] = ((this.formGroup.controls.fields as UntypedFormArray)
                .controls[index] as FormGroup)
                .get(field).value;

            val.push(value.trim().substr(0, 100));

            ((this.formGroup.controls.fields as UntypedFormArray).controls[index] as FormGroup)
                .get(field).setValue(val);
        }
        if (input) {
            input.value = "";
        }
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Remove chip
     * @param value
     * @param {number} index
     * @param field
     */
    public removeChip(value: any, index: number, field: string): void {
        const valueIndex: number = ((this.formGroup.controls.fields as UntypedFormArray)
            .controls[index] as FormGroup)
            .get(field).value.indexOf(value);

        if (index >= 0) {
            const val: string[] = ((this.formGroup.controls.fields as UntypedFormArray)
                .controls[index] as FormGroup)
                .get(field).value;

            val.splice(valueIndex, 1);

            ((this.formGroup.controls.fields as UntypedFormArray)
                .controls[index] as FormGroup).get(field).setValue(val);
        }

        this.changeDetectorRef.markForCheck();
    }

    /**
     * Submit form
     * @return {Promise<any>}
     */
    public async submit(): Promise<any> {
        this.spinnerService.show();

        this.writeNames();

        const response: Api.IResponse = await this.apiService.request(
            this.modal.params.action === "add" ? Api.EMethod.Post : Api.EMethod.Put,
            this.modal.params.action === "add"
                ? ["integration", this.modal.params.integration_id, "function"]
                : ["integration", "function", this.modal.params.func.id],
            this.formGroup.value);

        if (response) {
            this.modal.response.emit({
                name: "value",
                value: response
            });
        }
        this.spinnerService.hide();
    }


    public async uploadAvatar(): Promise<any> {
        const image_url: string = await this.imageUploadService.uploadAvatar();

        if (image_url) {
            this.formGroup.get("icon").setValue(image_url);
            this.changeDetectorRef.markForCheck();
        }
    }

    public ngOnInit(): void {
        if (this.modal.params.action === "edit") {
            this.prepareForm();
        }
    }
}
