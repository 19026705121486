import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {Contact} from "../../../../../../common/interfaces/contact.interface";
import {Modal} from "../../../../../services/modal.service";
import {User} from "../../../../../../common/interfaces/user.interface";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";


@Component({
    selector: "section-package-sticker",
    template: `
        <div class="pull-right">
            <button mat-raised-button color="accent" (click)="print()">
                <mat-icon>print</mat-icon>
                Print
            </button>
        </div>

        <h1 class="container-heading">Package sticker</h1>
        <mat-card>
            <ng-container *ngIf="partner">
                <ng-template ngFor let-p [ngForOf]="packages" let-i="index">
                    <div class="margin-bottom-10" *ngIf="!pack || pack.id === p.id">
                        <section-package-sticker-box
                            *ngIf="modal.params.type === 'box'"
                            [partner]="partner"
                            [pack]="p"
                            (ready)="stickers[i]=$event">

                        </section-package-sticker-box>

                        <section-package-sticker-default
                            *ngIf="modal.params.type !== 'box'"
                            [partner]="partner"
                            [pack_number]="i+1"
                            [pack_total]="packages.length"
                            [from_address]="from_address"
                            [to_address]="to_address"
                            [orderRef]="orderRef"
                            [pack]="p"
                            (ready)="stickers[i]=$event">

                        </section-package-sticker-default>
                    </div>
                </ng-template>
            </ng-container>
        </mat-card>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PackageStickerComponent implements OnInit {

    public modal: Modal.IModal;

    public from_address: Contact.IAddress;

    public to_address: Contact.IAddress;

    public orderRef: string;

    public pack: any;

    public partner: User.IPartner;

    public packages: any[] = [];

    public stickers: string[];

    public constructor(
        private apiService: ApiService,
        private spinnerService: SpinnerService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    private async getPartner(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["partner", "" + this.modal.params.shipment.partner_id]);
        if (data) {
            this.partner = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    public print(): void {
        let html = "";

        for (const sticker of this.stickers) {
            if (sticker) {
                html += sticker;
            }
        }
        HelpersService.stickerPopUp(html);
    }

    public ngOnInit(): void {
        this.from_address = this.modal.params.shipment.from_address;
        this.to_address = this.modal.params.shipment.to_address;
        this.orderRef = this.modal.params.orderRef;
        this.pack = this.modal.params.pack;
        this.packages = [...this.modal.params.packages].reverse();

        this.stickers = new Array(this.packages.length);

        this.getPartner();
    }

}
