<common-confirm></common-confirm>

<h1 class="container-heading">Hub groups</h1>
<mat-card>
    <div>
        <common-table2 *ngIf="listTable" [settings]="listTable">
            <div row1>
                <button mat-raised-button type="button" (click)="add_new()" color="accent">
                    Add group
                </button>
            </div>
        </common-table2>
    </div>
</mat-card>
