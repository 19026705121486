import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {UntypedFormArray, UntypedFormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ConfirmComponent} from "../../../../../../../common/components/confirm/confirm.component";
import {Modal, ModalService} from "../../../../../../services/modal.service";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {distinctUntilChanged} from "rxjs";
import {takeUntil} from "rxjs/operators";


@Component({
    selector: "section-pm-kit-form",
    templateUrl: "form.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class PartMasterKitFormComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<void> = new EventEmitter();

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;


    public formGroup: FormGroup = new FormGroup({
        part_master_id: new FormControl(null, [Validators.required]),
        child_parts: new UntypedFormArray([])
    });

    public modal: Modal.IModal;

    public action: string = "add";

    public part_masters: { name: string, value: any }[] = [];

    public hasDuplicates: boolean = false;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private formBuilder: UntypedFormBuilder,
        private apiService: ApiService,
        private toastService: ToastService,
        private modalService: ModalService,
        private spinnerService: SpinnerService
    ) {
    }

    private async getPartMasters(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["partmaster"], null, {data_structure: "select"});
        if (data) {
            this.part_masters = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private async getChilds(parent_id: number): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["partmaster", "kits", "" + parent_id]);
        if (data) {
            for (const child of data) {
                this.addChild(child.part_master.id, child.quantity, child.serial);
            }
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private prepareForm(): void {
        this.formGroup.get("part_master_id").setValue(this.modal.params.part_master.id);

        for (const child of this.modal.params.part_master.kit_child_parts) {
            this.addChild(child.child_part_master_id, child.child_quantity, child.child_serial);
        }

        this.changeDetectorRef.markForCheck();
    }

    private formValidate(): boolean {
        const items: number[] = [this.formGroup.value.part_master_id];
        for (const part of this.formGroup.value.child_parts) {
            items.push(part.part_master_id);
        }

        this.hasDuplicates = (new Set(items)).size !== items.length;

        this.changeDetectorRef.markForCheck();

        return !this.hasDuplicates;
    }

    public setChildPartId(index: number, event: any): void {
        (this.formGroup.get("child_parts") as UntypedFormArray).at(index).get("part_master_id").setValue(event);
    }

    public addChild(part_master_id: number = null, quantity: number = null, serial: string = null): void {
        (this.formGroup.get("child_parts") as UntypedFormArray).push(new FormGroup({
            part_master_id: new FormControl(part_master_id, [Validators.required]),
            quantity: new FormControl(quantity, [Validators.required]),
            serial: new FormControl(serial),
        }));
    }

    public deleteChild(index: number): void {
        (this.formGroup.get("child_parts") as UntypedFormArray).removeAt(index);
    }

    public async submit(): Promise<any> {

        if (!this.formValidate()) {
            return;
        }

        this.spinnerService.show();

        const {code, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["partmaster", "kits"], this.formGroup.value);

        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.modal.response.emit(null);
        }
    }

    public ngOnInit(): void {
        this.action = this.modal && this.modal.params.action ? this.modal.params.action : "add";
        this.getPartMasters();

        if (this.modal.params.part_master) {
            this.prepareForm();
        } else {
            this.formGroup.get("part_master_id").valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
                .subscribe((value: number): void => {
                    if (value) {
                        this.getChilds(value);
                    }
                });
        }
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

}
