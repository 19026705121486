<common-confirm></common-confirm>

<ng-container *ngIf="!this.details">
    <h3 class="text-center">Please fill previous step</h3>
</ng-container>

<ng-container *ngIf="this.details">
    <ng-container *ngIf="partmasters.length">
        <ng-container *ngIf="serviceLevel.order_type.slug === 'rma'; else singleButton">
            <div *ngIf="serviceLevel.properties.manual_type_selection; else singleButton">
                <button *ngIf="serviceLevel.order_type.can_outbound"
                        mat-raised-button
                        color="primary"
                        type="button"
                        class="main pull-right"
                        (click)="addItem('outbound')">
                    <mat-icon>add</mat-icon>
                    Add outbound item
                </button>

                <button *ngIf="serviceLevel.order_type.can_inbound"
                        mat-raised-button
                        color="primary"
                        type="button"
                        class="main pull-right margin-right-10"
                        (click)="addItem('inbound')">
                    <mat-icon>add</mat-icon>
                    Add inbound item
                </button>
            </div>
        </ng-container>

        <ng-template #singleButton>
            <div>
                <button mat-raised-button
                        color="primary"
                        type="button"
                        class="main pull-right"
                        (click)="addItem()">
                    <mat-icon>add</mat-icon>
                    Add item
                </button>
            </div>
        </ng-template>
    </ng-container>

    <div class="clear">
        <common-table2 #outboundTableRef *ngIf="outboundTable" [settings]="outboundTable" [search]="false">
            <div row1 class="row flex vertical-middle">
                <h2 class="margin-right-10">Outbound items</h2>

                <button mat-icon-button type="button" (click)="outboundTableRef.reload()">
                    <mat-icon>refresh</mat-icon>
                </button>
            </div>
        </common-table2>

        <common-table2 #inboundTableRef *ngIf="inboundTable" [settings]="inboundTable" [search]="false">
            <div row1 class="row flex vertical-middle">
                <h2 class="margin-right-10">Inbound items</h2>
                <button mat-icon-button type="button" (click)="inboundTableRef.reload()">
                    <mat-icon>refresh</mat-icon>
                </button>
            </div>
        </common-table2>

    </div>

    <table class="table" *ngIf="outboundTable">
        <tr>
            <td>
                <div *ngIf="stockMode !== 'hard'">
                    <button mat-raised-button color="accent" (click)="allocateInventory()">
                        Allocate all
                    </button>
                    &nbsp;
                    &nbsp;
                    <button mat-raised-button color="primary" (click)="skipAllocations()" [disabled]="_skipAllocations">
                        Skip allocations
                    </button>
                </div>
            </td>
            <td style="text-align: right;">
                <div *ngIf="stockMode !=='free' && allocationReleaseDate">
                    Please note, allocations will be canceled within three hours, if order will not be finished.
                    <br>
                    <common-counter class="pull-right" [endDate]="allocationReleaseDate" [showDays]="false"
                                    [stopWhenExpired]="true"></common-counter>
                </div>
            </td>
        </tr>
    </table>
</ng-container>
