import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Warehouse} from "../../../../../common/interfaces/warehouse.interface";
import {Base} from "../../../../../common/interfaces/base.interfaces";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../common/services/api.service";
import {Form} from "../../../../../common/interfaces/form.interface";
import {CommonFormFieldsComponent} from "../../../../../common/components/form";
import {ToastService} from "../../../../../common/services/toast.service";
import {SpinnerService} from "../../../../../common/services/spinner.service";
import {Api3Service} from "../../../../../common/services/api3.service";

@Component({
    selector: "section-warehouse-settings",
    templateUrl: "settings.component.html",
    styleUrls: [
        "settings.component.scss",
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class WarehouseSettingsComponent implements Base.IComponent, OnInit {

    @ViewChild("propertiesFields", {static: false})
    public propertiesFieldsRef: CommonFormFieldsComponent;

    public warehouse: Warehouse.IWarehouse;

    public readonly state: Base.IState;

    /**
     * Selector for user info section
     * @type {number}
     */
    public showSection: number = 1;

    public customFields: Form.IField[] = [];


    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private spinnerService: SpinnerService
    ) {
    }

    /**
     * Get user data
     * @returns {Promise<any>}
     */
    private async getData(): Promise<any> {
        this.spinnerService.show();

        const {data}: Api.IResponse = await this.api3Service.get(`${this.state.section}`, {
            relations: [
                "address",
                "contact",
                "contact.customer",
                "Threepl"
            ]
        });

        if (data) {
            this.warehouse = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get properties fields
     */
    private async getProperties(): Promise<any> {
        this.spinnerService.show();
        this.apiService.setHeaders({
            Warehouse: this.warehouse.slug
        });
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["model_field"], {}, {model: "App\\Warehouse"});
        if (data) {
            this.customFields = data.fields;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Submit properties form
     */
    public async saveProperties(): Promise<any> {
        this.spinnerService.show();
        const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["warehouse", "" + this.warehouse.id], {
                properties: this.propertiesFieldsRef.formGroup.value
            });
        if (type as string === "success") {
            this.toastService.show(message, "success");
        }
        this.spinnerService.hide();
    }


    public async ngOnInit(): Promise<any> {
        this.getData().then((): void => {
            this.getProperties();
        });
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "warehouse-settings",
            actions: {
                "browse": ["browse_settings"]
            }
        };
    }

}
