<h1 class="container-heading">Remark Classifications</h1>
<common-confirm></common-confirm>
<mat-card>
    <mat-card-content>

        <div *ngIf="remarkClassifications; else nocfc" class="dnd-container" cdkDropList
             (cdkDropListDropped)="drop($event)">
            <mat-card class="dnd-box"
                      *ngFor="let remarkClassification of remarkClassifications" cdkDrag cdkDragLockAxis="y">
                <div class="dnd-custom-placeholder" *cdkDragPlaceholder></div>
                <div>{{remarkClassification.name}}</div>
                <div class="dnd-handle">
                    <button *ngIf="canEdit" cdkDragHandle mat-mini-fab color="primary" aria-label="draggable"
                            (click)="edit(remarkClassification)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    &nbsp;
                    <button *ngIf="canDelete" cdkDragHandle mat-mini-fab color="warn" aria-label="draggable"
                            (click)="delete(remarkClassification)">
                        <mat-icon>delete</mat-icon>
                    </button>

                    <button class="dnd-drag" cdkDragHandle mat-icon-button aria-label="draggable">
                        <mat-icon>unfold_more</mat-icon>
                    </button>
                </div>
            </mat-card>
        </div>
        <ng-template #nocfc>
            <div>
                <common-simple-spinner></common-simple-spinner>
            </div>
        </ng-template>

        <div class="actions" *userAllowed="['add_order_remark_classifications']">
            <button mat-raised-button color="accent" (click)="add()">
                <mat-icon>add</mat-icon>
                Add
            </button>
        </div>
    </mat-card-content>
</mat-card>
