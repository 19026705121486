import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    Output,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {ConfirmComponent} from "../../../../../../../common/components/confirm/confirm.component";
import {Form} from "../../../../../../../common/interfaces/form.interface";
import {Order} from "../../../../../../../common/interfaces/order.interface";
import {debounceTime, takeUntil} from "rxjs/operators";
import {ReplaySubject} from "rxjs";
import {CommonFormFieldsComponent} from "../../../../../../../common/components/form";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {ModalService} from "../../../../../../services/modal.service";
import {LinkHubsToCourierServicesModalComponent} from "../../../../../partner/components/hubs";
import {Table} from "../../../../../../../common/interfaces/table.interface";
import {Table2Component} from "../../../../../../../common/components/table2";
import {AmplitudeService} from "../../../../../../../common/services/amplitude.service";
import {AppStateService} from "../../../../../../../common/services/app-state.service";

@Component({
    selector: "section-partner-form-step3",
    templateUrl: "step3.component.html",
    styleUrls: [
        "step3.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class PartnerFormStep3Component implements OnDestroy {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    private slug: string;

    @Output()
    public result: EventEmitter<any> = new EventEmitter();

    @Output()
    public authFail: EventEmitter<any> = new EventEmitter();

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild("newFormCustomFields", {static: false})
    public newFormCustomFields: CommonFormFieldsComponent;

    @ViewChild("logTable", {static: false})
    public logTableRef: Table2Component;

    public newForm: FormGroup;

    public resetAutocomplete: boolean = false;

    public serviceLevels: any[] = [];

    public teams: { name: string, value: any }[] = [];

    public coverages: { value: number, name: string }[] = [];

    public orderTypes: { value: number, name: string }[] = [];

    public shippingModes: { value: number, name: string }[] = [];

    public courierServices: { value: number, name: string }[] = [];

    public courierServicesNames: { [key: string]: string } = {};

    public courierServicesFiltered: ReplaySubject<{ value: number, name: string }[]> =
        new ReplaySubject<{ value: number, name: string }[]>(1);

    public customFields: Form.IField[] = [];

    public selectSearch: FormControl = new FormControl(null);

    public logsTableSettings: Table.ISettings;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private spinnerService: SpinnerService,
        private modalService: ModalService,
    ) {
    }

    /**
     * Service level form "constructor"
     * @returns {FormGroup}
     * @param opts
     */
    private serviceLevel(opts: { [key: string]: any } = {}): FormGroup {

        const properties: FormGroup = new FormGroup({});

        if (this.customFields) {
            for (const field of this.customFields) {
                if (field.type === "filler") {
                    continue;
                }
                const name: string = field.name;
                const value: any = Array.isArray(field.values) ? field.values[0] : field.values;
                const validators: ValidatorFn[] = field.required ? [Validators.required] : [];

                properties.addControl(name, new FormControl(value, validators));
            }
        }

        for (const fieldName of Object.keys(properties.value)) {
            if (opts.properties && opts.properties.hasOwnProperty(fieldName)) {
                properties.get(fieldName).setValue(opts.properties[fieldName]);
            }
        }
        delete opts.properties;


        const formGroup: FormGroup = new FormGroup({
            id: new FormControl(null),
            name: new FormControl(null, [Validators.required]),
            description: new FormControl(null, [Validators.required]),
            coverage_id: new FormControl(null, [Validators.required]),
            order_type_id: new FormControl(null, [Validators.required]),
            // shipping_mode_id: new FormControl(null, [Validators.required]),
            courier_services: new FormControl(null, [Validators.required]),
            courier_service_id: new FormControl(null, [Validators.required]),
            team_id: new FormControl(null),
            invoice_remark: new FormControl(null),
            properties: properties
        });


        for (const fieldName of Object.keys(formGroup.value)) {
            if (opts[fieldName]) {
                formGroup.get(fieldName).setValue(opts[fieldName]);
            }
        }
        return formGroup;
    }

    /**
     * Get users list
     * @returns {Promise<any>}
     */
    private async getTeams(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["team"], {}, {
                data_structure: "select",
                category: "notification"
            });
        if (data) {
            this.teams = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Add service level to array
     * @param opts
     */
    private addServiceLevel(opts: { [key: string]: any }): void {
        this.serviceLevels.push(this.serviceLevel(opts));
    }

    /**
     * Prepare form to add new service level
     */
    private prepareForm(): void {
        this.newForm = this.serviceLevel();
        this.changeDetectorRef.markForCheck();
    }

    private async getServiceLevels(): Promise<any> {
        this.spinnerService.show();
        this.apiService.setPartner(this.slug);
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["service_level"]);

        this.serviceLevels = [];
        if (this.checkAuth(response) && response.data) {

            const sls: any[] = response.data.map((service_level: Order.IServiceLevel): any => {
                const obj: any = {
                    ...service_level,
                    coverage_id: service_level.coverage ? service_level.coverage.id : null,
                    order_type_id: service_level.order_type ? service_level.order_type.id : null,
                    shipping_mode_id: service_level.shipping_mode ? service_level.shipping_mode.id : null,
                    courier_service_id: service_level.courier_service ? service_level.courier_service.id : null,
                    team_id: parseInt("" + service_level.team_id)
                };
                obj.courier_services = service_level.courier_services
                    .map((service: { id: number }): number => service.id);

                // console.log(service_level, obj);
                return obj;
            });

            for (const service_level of sls) {
                this.addServiceLevel(service_level);
            }
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
        if (this.serviceLevels.length > 0) {
            this.result.next(true);
        }
    }

    private async getCoverages(): Promise<any> {
        this.spinnerService.show();
        this.apiService.setPartner(this.slug);
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["coverage"], {},
            {data_structure: "select"});
        if (this.checkAuth(response) && response.data) {
            this.coverages = response.data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private async getOrderTypes(): Promise<any> {
        this.spinnerService.show();
        this.apiService.setPartner(this.slug);
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["order_type"], {},
            {data_structure: "select"});
        if (this.checkAuth(response) && response.data) {
            this.orderTypes = response.data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private async getShippingModes(): Promise<any> {
        this.spinnerService.show();
        this.apiService.setPartner(this.slug);
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["shipping_mode"], {},
            {data_structure: "select"});
        if (this.checkAuth(response) && response.data) {
            this.shippingModes = response.data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private async getCourierServices(): Promise<any> {
        this.spinnerService.show();
        this.apiService.setPartner(this.slug);
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["courier_service"],
            {}, {data_structure: "select"});
        if (this.checkAuth(response) && response.data) {
            this.courierServices = response.data;
            for (const cs of this.courierServices) {
                this.courierServicesNames[cs.value] = cs.name;
            }
            this.courierServicesFiltered.next(this.courierServices.slice());
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Check response from server
     * @param {Api.IResponse} response
     * @returns {boolean}
     */
    private checkAuth(response: Api.IResponse): boolean {
        if (response.code === 401) {
            this.authFail.emit(true);
            return false;
        }
        return true;
    }

    private async getCustomFields(): Promise<any> {
        this.spinnerService.show();
        this.apiService.setPartner(this.slug);
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["model_field"], {},
            {model: "App\\ServiceLevel"});
        if (this.checkAuth(response) && response.data) {
            this.customFields = response.data.fields;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private getLogsUrl(): Table.ITableApi {
        return {
            url: ["service_level", "logs"],
            query: {
                partner_id: AppStateService.getState().params.id // for admin partner edit wizard
            }
        };
    }

    private async prepareLogsTable(): Promise<any> {

        this.logsTableSettings = {
            table_id: "dfif77sdf",
            actions: [],
            columns: [
                {
                    data: "name",
                    title: "Log"
                },
                {
                    data: "description",
                    title: "Description"
                },
                {
                    data: "properties.previous_state",
                    title: "From"
                },
                {
                    data: "properties.current_state",
                    title: "To"
                },
                {
                    data: "user.name",
                    name: "User.name",
                    title: "By"
                },
                {
                    data: "created_at",
                    title: "At"
                },

            ],
            api: this.getLogsUrl(),
            sort_default: {
                data: "created_at",
                dir: "desc"
            }
        };
    }


    /**
     * Initialize step
     * @returns {Promise<any>}
     * @param slug
     */
    public async init(slug: string): Promise<any> {
        this.slug = slug;
        this.spinnerService.show();
        this.getCoverages();
        this.getOrderTypes();
        this.getTeams();
        // this.getShippingModes();
        this.getCourierServices();
        this.getCustomFields().then((): void => {
            this.prepareForm();
            this.getServiceLevels();
        });
        this.spinnerService.hide();
        this.prepareLogsTable();

        this.selectSearch.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: string): void => {
                if (!value) {
                    this.courierServicesFiltered.next(this.courierServices.slice());
                } else {
                    value = value.toLowerCase();
                    this.courierServicesFiltered.next(
                        this.courierServices.filter((service: { name: string }): boolean =>
                            service.name.toLowerCase().indexOf(value) > -1)
                    );
                }
            });
    }

    /**
     * Handle autocomplete selected value event
     * @param $event
     * @param index
     */
    public onCourierServiceSelected($event: any, index: number = null): void {
        if (index !== null) {
            this.serviceLevels[index].get("courier_service_id").setValue($event.value);
        } else {
            this.newForm.get("courier_service_id").setValue($event.value);
        }
    }


    public updateCustomFields(formGroup: FormGroup, values: { [key: string]: any }): void {
        for (const fieldName of Object.keys(formGroup.get("properties").value)) {
            if (values.hasOwnProperty(fieldName)) {
                formGroup.get("properties").get(fieldName).setValue(values[fieldName]);
            }
        }
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Submit new service level
     * @returns {Promise<any>}
     */
    public async submitNewServiceLevel(): Promise<any> {
        this.spinnerService.show();

        this.apiService.setPartner(this.slug);
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Post, ["service_level"],
            {...this.newForm.value});
        this.toastService.show(response.message, response.type as string);

        this.spinnerService.hide();

        if (response.data) {
            this.addServiceLevel({
                id: response.data.id,
                name: this.newForm.value.name,
                description: this.newForm.value.description,
                coverage_id: this.newForm.value.coverage_id,
                order_type_id: this.newForm.value.order_type_id,
                shipping_mode_id: this.newForm.value.shipping_mode_id,
                courier_service_id: this.newForm.value.courier_service_id,
                team_id: this.newForm.value.team_id,
            });
            this.newForm.reset();
            this.newFormCustomFields.reset();
            this.changeDetectorRef.markForCheck();
            this.result.next(true);
            this.logTableRef.reload(this.getLogsUrl());
        }

    }

    /**
     * Update existing service level
     * @param {number} index
     * @returns {Promise<any>}
     */
    public async updateServiceLevel(index: number): Promise<any> {
        AmplitudeService.eventClick("Update service level");
        this.spinnerService.show();
        const id: number = this.serviceLevels[index].value.id;
        this.apiService.setPartner(this.slug);

        const {code, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["service_level", String(id)], {...this.serviceLevels[index].value});

        this.spinnerService.hide();

        if (code === 200) {
            this.toastService.show(message, "success");
            this.changeDetectorRef.markForCheck();
            this.logTableRef.reload(this.getLogsUrl());
        }
    }

    /**
     * Delete service level
     * @param {number} index
     * @returns {Promise<any>}
     */
    public async submitDelete(index: number): Promise<any> {
        if (!await this.confirmRef.confirm("Are you sure want to delete this Service Level?")) {
            return;
        }
        AmplitudeService.eventClick("Delete service level");
        this.spinnerService.show();
        const id: number = this.serviceLevels[index].value.id;
        this.apiService.setPartner(this.slug);
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
            ["service_level", String(id)]);
        this.toastService.show(response.message, response.type as string);
        if (response.data) {
            this.serviceLevels.splice(index, 1);
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }


    public linkHubs(service_level_id: number): void {
        AmplitudeService.eventClick("Link courier services to hubs");
        this.modalService.open(LinkHubsToCourierServicesModalComponent, {
            service_level_id,
            modalWidth: 800,
            partner_slug: this.slug
        });
    }

    public amplitudeClick(message: string, properties: any = {}): void {
        AmplitudeService.eventClick(message, properties);
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
