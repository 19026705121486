import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {UserService} from "../../../../../../common/services/user.service";
import {Router} from "@angular/router";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {CommonFormComponent} from "../../../../../../common/components/form";
import {Api3Service} from "../../../../../../common/services/api3.service";
import {Table2Component} from "../../../../../../common/components/table2";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";

@Component({
    selector: "section-hub-groups-list",
    templateUrl: "list.component.html",
    styleUrls: [
        "list.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class HubGroupsListComponent implements Base.IComponent, OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();


    public readonly state: Base.IState;

    public listTable: Table.ISettings;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    @ViewChild(ConfirmComponent, {static: true})
    public confirm: ConfirmComponent;

    public constructor(
        private api3Service: Api3Service,
        private modalService: ModalService,
        private userService: UserService,
        private toastService: ToastService,
        private spinnerService: SpinnerService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    private prepareList(): void {

        this.listTable = {
            table_id: "dfsraes7rsy4g",
            actions: [
                {
                    name: "edit",
                    title: "Edit",
                    click: (row: any): void => {
                        this.edit(row);
                    }
                },
                {
                    name: "delete",
                    title: "Delete",
                    click: async (row: any): Promise<any> => {
                        this.delete(row);
                    }
                }
            ],
            api: {
                url: [this.state.section, "hub-groups"],
                query: {
                    counts: [
                        "Hubs"
                    ]
                },
                version: 3
            },
            columns: [
                {
                    data: "name",
                    title: "Name"
                },
                {
                    data: "hubs_count",
                    title: "Hubs count",
                    sortable: false,
                    searchable: false,
                },
                {
                    data: "created_at",
                    title: "Created",
                    searchable: false
                },
            ],
        };
        this.changeDetectorRef.markForCheck();
    }

    private async delete(group: { id: number }): Promise<any> {
        if (!await this.confirm.confirm("This action cannot be reversed! Delete group?")) {
            return;
        }

        this.spinnerService.show();

        const {code, message}: Api.IResponse = await this.api3Service.delete(
            `${this.state.section}/hub-groups/${group.id}`);

        this.spinnerService.hide()
        ;
        if (code === 200) {
            this.toastService.show(message, "success");
        }

        this.tableRef.reload();
    }

    public async add_new(): Promise<any> {
        const res: Modal.IResponse = await this.modalService.open(CommonFormComponent, {
            formConfig: {
                id: 0,
                name: "Add hub group",
                description: "",
                fields: [
                    {
                        label: "Name",
                        name: "name",
                        size: "full",
                        type: "input",
                        required: true,
                    }
                ],
            }
        });

        if (!res || !res.value) {
            return;
        }

        this.spinnerService.show();

        const {code, message}: Api.IResponse = await this.api3Service.post(`${this.state.section}/hub-groups`,
            res.value);

        this.spinnerService.hide();

        if (code === 200) {
            this.toastService.show(message, "success");
        }

        this.tableRef.reload();
    }

    public async edit(group: { id: number, name: string }): Promise<any> {
        const res: Modal.IResponse = await this.modalService.open(CommonFormComponent, {
            formConfig: {
                id: 0,
                name: "Edit hub group",
                description: "",
                fields: [
                    {
                        label: "Name",
                        name: "name",
                        size: "full",
                        type: "input",
                        required: true,
                    }
                ],
            },
            values: {
                name: group.name
            }
        });

        if (!res || !res.value) {
            return;
        }

        this.spinnerService.show();

        const {code, message}: Api.IResponse = await this.api3Service.put(
            `${this.state.section}/hub-groups/${group.id}`, res.value);

        this.spinnerService.hide()
        ;
        if (code === 200) {
            this.toastService.show(message, "success");
        }

        this.tableRef.reload();
    }


    public ngOnInit(): void {
        this.prepareList();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "hub-groups",
            actions: {
                "browse": ["browse_inventories_conversion"]
            }
        };
    }
}

