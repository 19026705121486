import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {AttachmentsListComponent} from "../../../../../../../common/components/attachments";
import {AttachmentService} from "../../../../../../../../../common/services/attachment.service";
import {ModalService} from "../../../../../../../../services/modal.service";
import {ToastService} from "../../../../../../../../../common/services/toast.service";
import {SpinnerService} from "../../../../../../../../../common/services/spinner.service";
import {Table} from "../../../../../../../../../common/interfaces/table.interface";

@Component({
    selector: "section-warehouse-attachments-list",
    template: `
        <common-confirm></common-confirm>
        <h1 class="container-heading">Order attachments</h1>
        <mat-card>
            <mat-card-content>
                <common-table2 *ngIf="listTable" [settings]="listTable"></common-table2>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WarehouseAttachmentsListComponent extends AttachmentsListComponent implements OnInit {

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        protected attachmentService: AttachmentService,
        protected modalService: ModalService,
        protected toastService: ToastService,
        protected spinnerService: SpinnerService
    ) {
        super(changeDetectorRef, attachmentService, modalService, toastService, spinnerService);
    }

    protected getTableId(): string {
        return "fdj87GFEDdG";
    }

    protected getActions(): Table.IAction[] {
        return [];
    }

    protected getUrl(): Table.ITableApi {
        return {
            url: ["order", this.modal.params.orderRef, "attachments"]
        };
    }

    public ngOnInit(): void {
        this.prepareList();
    }

}
