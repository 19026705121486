<common-confirm></common-confirm>
<common-simple-spinner *ngIf="!timeline"></common-simple-spinner>

<mgl-timeline *ngIf="timeline" [alternate]="true" mobileWidthThreshold="200">
    <ng-template ngFor let-step [ngForOf]="steps">
        <mgl-timeline-entry *ngIf="displayCondition(step.type)">
            <mgl-timeline-entry-header>
                {{step.name}}
            </mgl-timeline-entry-header>
            <mgl-timeline-entry-content>

                <ng-container *ngIf="timeline.steps && timeline.steps[step.type].length">
                    <div class="instance-entry margin-bottom-10" *ngFor="let entry of timeline.steps[step.type]">
                        <div>
                            Triggered by: <b>{{entry.user}}</b>
                        </div>
                        <div>
                            Triggered at: <b>{{entry.created_at}}</b>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!timeline.steps || !timeline.steps[step.type].length">
                    <p>No data</p>
                </ng-container>

                <div *ngIf="step.action">
                    <button mat-raised-button type="button" color="accent" (click)="action(step.type)">
                        {{step.action}}
                    </button>
                </div>

            </mgl-timeline-entry-content>

            <mgl-timeline-entry-dot [size]="48" [class]="timeline.steps && timeline.steps[step.type].length ? 'primary' : 'disabled'">
                <div class="step-icon">
                    <mat-icon class="vertical-middle">{{step.icon}}</mat-icon>
                </div>
            </mgl-timeline-entry-dot>
        </mgl-timeline-entry>
    </ng-template>

</mgl-timeline>
