<h1 class="container-heading">{{action | ucfirst}} announcement</h1>

<mat-card>
    <mat-card-content>

        <form *ngIf="formGroup" [formGroup]="formGroup"
              (submit)="submit()">

            <div class="group flex">

                <mat-form-field class="full">
                    <mat-label>Title</mat-label>
                    <input matInput formControlName="title" required>
                </mat-form-field>

                <div class="full" *ngIf="formGroup.get('description')">
                    <common-form-editor label="Description"
                                        [value]="editorInitialValue"
                                        (valueChange)="formGroup.get('description').setValue($event)"
                    ></common-form-editor>
                </div>
                <button type="button" mat-raised-button class="full"
                        (click)="uploadFile()">
                    {{"Upload a file"}}
                </button>
                <div class="full">
                    <ng-template ngFor let-file let-index="index" [ngForOf]="uploadedFile">
                        {{file.name}}
                        <button mat-icon-button matTooltip="Delete" (click)="deleteFile(index)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </ng-template>
                </div>

                <mat-form-field class="half">
                    <mat-label>Inform team</mat-label>
<mat-select formControlName="team_id" >
                        <mat-option>
                            <ngx-mat-select-search [formControl]="selectTeamsSearch" [placeholderLabel]="'Search'"
                                                   [clearSearchInput]="true"
                                                   [noEntriesFoundLabel]="'No items found'">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let team of teamsFiltered | async" [value]="team.value">
                            {{team.name}}
                        </mat-option>
                    </mat-select>
                    <span matPrefix><mat-icon>person</mat-icon>&nbsp;</span>
                </mat-form-field>

                <mat-form-field class="half">
                    <mat-label>Who can see</mat-label>
<mat-select formControlName="order_remark_type_id"
                                required>
                        <mat-option *ngFor="let option of remarkTypes" [value]="option.id">
                            {{option.name}}
                        </mat-option>
                    </mat-select>
                    <span matPrefix><mat-icon>remove_red_eyes</mat-icon>&nbsp;</span>
                </mat-form-field>

                <mat-form-field class="full">
                    <mat-label>Category</mat-label>
<mat-select formControlName="announcement_kind_id"  required>
                        <mat-option value="add_kind">
                            <mat-icon>add_circle</mat-icon>
                            Add new kind
                        </mat-option>
                        <mat-option *ngFor="let kind of kinds" [value]="kind.value">
                            {{kind.name}}
                        </mat-option>
                    </mat-select>
                    <span matPrefix><mat-icon>ballot</mat-icon>&nbsp;</span>
                </mat-form-field>

            </div>

            <div class="actions">
                <button mat-raised-button color="primary" type="submit" class="main" [disabled]="!formGroup.valid">
                    {{action | ucfirst}}
                </button>
                <button mat-button type="button" (click)="close()">Cancel</button>
            </div>

        </form>

    </mat-card-content>
</mat-card>
