<mat-card>
    <h1>Add serial</h1>

    <form [formGroup]="form" (submit)="submit()">
        <div class="group flex">

            <div class="half">
                <common-form-hub-select
                        label="Hub"
                        [required]="true"
                        line_type="inbound"
                        [value]="form.value.inventory_conversion_id"
                        (onChange)="form.get('inventory_conversion_id').setValue($event)"></common-form-hub-select>
            </div>

            <mat-form-field class="half" *ngIf="locations">
                <mat-label>Select location</mat-label>
                <mat-select formControlName="location_id" required>

                    <mat-option>
                        <ngx-mat-select-search [formControl]="location_search"
                                               [placeholderLabel]="'Search in '+locations.length+' locations'"
                                               noEntriesFoundLabel="0 locations found">
                        </ngx-mat-select-search>
                    </mat-option>

                    <mat-option *ngFor="let location of filtered_locations | async" [value]="location.value">
                        {{location.name}}
                    </mat-option>

                    <mat-option *ngIf="form.value.inventory_conversion_id" (click)="addLocation()">
                        <mat-icon>add</mat-icon>
                        Add location
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="full" *ngIf="part_masters">
                <common-form-select [options]="part_masters"
                                    [search]="true"
                                    [required]="true"
                                    label="Select item"
                                    [value]="form.value.part_master_id"
                                    (valueChange)="form.get('part_master_id').setValue($event)"
                ></common-form-select>
            </div>

            <mat-form-field class="full">
                <mat-chip-grid #itemchipGrid>
                    <mat-chip-row matChipRemove *ngFor="let serial of serials" [selectable]="false"
                                  [removable]="true" (removed)="removeSerialChip(serial)">
                        {{serial}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip-row>
                    <input [matChipInputFor]="itemchipGrid" placeholder="Serial *"
                           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                           [matChipInputAddOnBlur]="true"
                           (matChipInputTokenEnd)="addSerialChip($event)">
                </mat-chip-grid>
            </mat-form-field>

            <mat-form-field class="full" *ngIf="part_info?.batch">
                <mat-label>Batch</mat-label>
                <input matInput formControlName="batch">
            </mat-form-field>

            <mat-form-field class="full">
                <mat-label>Remark</mat-label>
                <textarea row="5" matInput required formControlName="remark"></textarea>
            </mat-form-field>

        </div>
        <div class="actions">
            <button type="submit" mat-raised-button color="accent" [disabled]="!form.valid">
                Submit
            </button>
        </div>
    </form>

</mat-card>
