import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, Validators} from "@angular/forms";
import {takeUntil} from "rxjs/operators";
import {COMMA, ENTER, SEMICOLON, TAB} from "@angular/cdk/keycodes";
import {MatChipInputEvent} from "@angular/material/chips";
import {Modal, ModalService} from "../../../../section/services/modal.service";
import {BarcodeReaderComponent} from "../../barcode-reader/barcode-reader.component";

@Component({
    selector: "common-form-barcode",
    template: `
        <mat-form-field>
            <mat-label>{{label}}</mat-label>
            <ng-template [ngIf]="multiple">
                <mat-chip-grid #chipGrid>
                    <mat-chip-row matChipRemove *ngFor="let chip of chips" [selectable]="false"
                                  [removable]="true" (removed)="removeChip(chip)">
                        {{chip}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip-row>
                    <input matInput [matChipInputFor]="chipGrid"
                           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                           [matChipInputAddOnBlur]="true"
                           (matChipInputTokenEnd)="addChip($event)">
                </mat-chip-grid>
            </ng-template>
            <ng-template [ngIf]="!multiple">
                <input type="text" matInput [formControl]="formControl" [required]="required">
            </ng-template>

            <mat-icon matSuffix (click)="barcodeRead()">
                photo_camera
            </mat-icon>
        </mat-form-field>
    `,
    styles: [
        `
            common-form-barcode .mat-form-field {
                width: 100%;
                display: block;
            }
        `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class CommonFormBarcodeComponent implements OnInit, OnDestroy, OnChanges {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    public chips: string[] = [];

    @Input()
    public label: string;

    @Input()
    public required: boolean = false;

    @Input()
    public multiple: boolean = false;

    @Input()
    public value: string | string[] = null;


    @Output()
    public valueChange: EventEmitter<string[] | string> = new EventEmitter<string[] | string>();

    public separatorKeysCodes: any[] = [ENTER, COMMA, SEMICOLON, TAB];

    public formControl: FormControl =
        new FormControl(this.value, this.required ? [Validators.required] : []);

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private modalService: ModalService
    ) {

    }

    /**
     * Add chip
     * @param event
     */
    public addChip(event: MatChipInputEvent): void {
        const value: string = event.value.trim();
        if (event.value) {
            event.value = "";
            this.changeDetectorRef.markForCheck();
        }
        if (value) {
            if (this.formControl.value === null) {
                this.formControl.setValue([]);
            }

            const values: string[] = value.split(/[\s|\t|;|,]/);
            for (const val of values) {
                if (val && !this.formControl.value.includes(val)) {
                    this.chips.push(val);
                }
            }
            this.formControl.setValue(this.chips);
        }
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Remove chip
     * @param chip
     */
    public removeChip(chip: string): void {
        const index: number = this.chips.indexOf(chip);
        if (index >= 0) {
            this.chips.splice(index, 1);
            this.formControl.setValue(this.chips);
        }
        this.changeDetectorRef.markForCheck();
    }


    /**
     * Get value from barcode reader
     * @returns {Promise<any>}
     */
    public async barcodeRead(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(BarcodeReaderComponent, {
            modalWidth: 680,
            modalHeight: 530
        });
        if (response) {
            if (this.multiple) {
                this.addChip(<MatChipInputEvent>{value: response.value, input: null});
            } else {
                this.formControl.setValue(response.value);
            }
        }
        this.changeDetectorRef.markForCheck();
    }


    public ngOnInit(): void {
        this.formControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: string[]): void => {
            this.valueChange.emit(value);
        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.value) {
            this.formControl.setValue(changes.value.currentValue);
            if (this.multiple && Array.isArray(changes.value.currentValue)) {
                for (const val of changes.value.currentValue) {
                    this.addChip(<MatChipInputEvent>{value: val, input: null});
                }
            }
        }
    }
}
