import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Request} from "../../../../../../common/interfaces/request.interface";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {takeUntil} from "rxjs/operators";
import {DragulaService} from "ng2-dragula";
import {ConfirmComponent} from "src/modules/common/components/confirm/confirm.component";
import {Form} from "src/modules/common/interfaces/form.interface";
import {Modal, ModalService} from "src/modules/section/services/modal.service";
import {CommonFormComponent} from "src/modules/common/components/form";
import ISelectOption = Form.ISelectOption;
import {TIME_ZONES} from "../../../../../../common/common.module";
import { ToastService } from "src/modules/common/services/toast.service";

interface Params {
    name: string;
    label: string;
    input_type: string;
    default_value: any;
    required: boolean;
    options?: Form.ISelectOption[];
}

interface EntityParams {
    event: string;
    params: Params[];
}

@Component({
    selector: "request-task-edit-form",
    templateUrl: "task-edit-form.component.html",
    styleUrls: [
        "task-edit-form.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class RequestTaskEditFormComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    public edit = false;

    @Input()
    public task: Request.ITask = null;

    @Input()
    public request_id: number;

    public modal: Modal.IModal;

    public timezone = "Asia/Jerusalem";

    public now: string = new Date().toISOString();

    @Output()
    public onChanges: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    public taskParams: EntityParams[] = [
        {
            event: "AWBAdded",
            params: [
                {
                    name: "shipment_type",
                    label: "Shipment Type",
                    input_type: "dropdown",
                    default_value: "both",
                    required: false,
                    options: [
                        {
                            name: "Both",
                            value: "both"
                        },
                        {
                            name: "Inbound",
                            value: "inbound"
                        },
                        {
                            name: "Outbound",
                            value: "outbound"
                        }
                    ]
                }
            ]
        },
        {
            event: "AWBDelivered",
            params: [
                {
                    name: "shipment_type",
                    label: "Shipment Type",
                    input_type: "dropdown",
                    default_value: "both",
                    required: false,
                    options: [
                        {
                            name: "Both",
                            value: "both"
                        },
                        {
                            name: "Inbound",
                            value: "inbound"
                        },
                        {
                            name: "Outbound",
                            value: "outbound"
                        }
                    ]
                }
            ]
        },
        {
            event: "OrderStatusChanged",
            params: [
                {
                    name: "order_status_close",
                    label: "Status for close",
                    input_type: "dropdown",
                    default_value: null,
                    options: [],
                    required: true
                }
            ]
        }
    ]

    public taskEdit: FormGroup = new FormGroup({
        text: new FormControl(null, [Validators.required]),
        due_date: new FormControl(null, [Validators.required]),
        due_date_tz: new FormControl(null, [Validators.required]),
        change_due_date_comment: new FormControl(null),
        comment: new FormControl(null),
        send_email: new FormControl(true),
        send_email_deadline: new FormControl(0),
        event_close: new FormControl(null),
        params: new FormGroup({})
    });

    public taskDeadlineOptions = [
        -30, -15, 0, 15, 30, 45, 60, 75, 90
    ];

    public eventStatuses: Form.ISelectOption[] = [];

    public closeTaskEvents: Form.ISelectOption[] = [
        {value: "AWBAdded", name: "AWB Added"},
        {value: "AWBDelivered", name: "AWB Delivered"},
        {value: "OrderStatusChanged", name: "Order status changed"}
    ];

    public timeZones: ISelectOption[] = [];

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private spinnerService: SpinnerService,
        private dragulaService: DragulaService,
        private modalService: ModalService,
        private toastService: ToastService,
    ) {}

    public async submit(): Promise<any> {
        if (!this.edit) {
            this.createTask();
        } else {
            this.updateTask();
        }
    }

    private async createTask(): Promise<void> {
        this.spinnerService.show();
            const {data, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["request", "" + this.modal.params.request_id, "task"], {
                "order": this.modal.params?.sort_order ?? 0,
                "text": this.taskEdit.value.text,
                "comment": this.taskEdit.value.comment,
                "due_date": this.taskEdit.value.due_date
                    ? this.taskEdit.value.due_date.format("Y-MM-DD HH:mm:ss") : null,
                "due_date_tz": "Asia/Jerusalem",
                "send_email": this.taskEdit.value.send_email,
                "send_email_deadline": this.taskEdit.value.send_email_deadline ?? 0,
                "event_close": this.taskEdit.value.event_close,
                "params": this.taskEdit.value.params
            });
         this.spinnerService.hide();
         if (data) {
            this.toastService.show(message, "success");
            this.modal.response.next({
                name: "create"
            });
         }
    }

    private async updateTask(): Promise<void> {
        let task = this.task;
        if (this.taskEdit.value.text !== null) {
            task.text = this.taskEdit.value.text;
        }
        if (this.taskEdit.value.due_date !== null) {
            if (task.due_date != this.taskEdit.value.due_date.format("Y-MM-DD HH:mm:ss")) {
                const modalResponse = await this.modalService.open(CommonFormComponent, {
                    formConfig: {
                        id: 0,
                        name: "Comment (optional)",
                        description: "Why due date was changed?",
                        fields: [
                            {
                                label: "Comment",
                                name: "comment",
                                size: "full",
                                type: "input",
                                required: false,
                            },
                        ]
                    },
                });
                if (modalResponse?.value) {
                    task.change_due_date_comment = modalResponse.value.comment;
                }
            }
            task.due_date = this.taskEdit.value.due_date.format("Y-MM-DD HH:mm:ss");

            task.due_date_tz = this.taskEdit.value.due_date_tz;
        }
        if (this.taskEdit.value.comment !== null) {
            task.comment = this.taskEdit.value.comment;
        }
        if (this.taskEdit.value.send_email !== null) {
            task.send_email = this.taskEdit.value.send_email;
        }
        if (this.taskEdit.value.params !== null) {
            task.params = this.taskEdit.value.params;
        }
        task.event_close = this.taskEdit.value.event_close;
        task.send_email_deadline = this.taskEdit.value.send_email_deadline ?? 0;

        this.spinnerService.show();
        const { data, message }: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["request", "" + this.task.request_id, "task", "" + task.id], task);
        this.spinnerService.hide();
        if (data) {
            this.toastService.show(message, "success");
            this.modal.response.next({
                name: "update"
            });
        }
    }

    private addTaskEventParams(taskForm: FormGroup, event: string, params: any = []): void {
        const taskParams = this.taskParams.find(ep => ep.event === event);
        let paramsControls = taskForm.get("params") as FormGroup;
        paramsControls.controls = {};
        if (!taskParams) {
            return;
        }
        taskParams.params.forEach(p => {
            let value = p.default_value;
            if (params && params[p.name]) {
                value = params[p.name];
            }
            let control = new FormControl(value);
            if (p.required) {
                control.setValidators([Validators.required]);
            }
            paramsControls.addControl(p.name, control);
        });
        taskForm.controls["params"] = paramsControls;
        this.changeDetectorRef.markForCheck();
    }

    private setupListeners(): void {
        this.taskEdit.get("event_close").valueChanges.pipe(takeUntil(this.destroy$))
        .subscribe((event: string): void => {
            this.addTaskEventParams(this.taskEdit, event);
        });
    }

    private async getStatuses(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["status"], {}, {
            data_structure: "select",
            type: "order"
        });
        if (data) {
            const taskParams = this.taskParams.find(tp => tp.event === "OrderStatusChanged");
            taskParams.params[0].options = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    public getTaskParamsForEvent(): Params[] | null {
        const event = this.taskEdit.get("event_close").value;
        return this.taskParams.find(tp => tp.event === event)?.params || null;
    }

    public close(): void {
        this.modal.response.next({
            name: "close"
        });
    }

    public ngOnInit(): void {
        this.getStatuses();

        if (this.modal?.params?.task) {
            this.edit = true;
            this.task = this.modal?.params?.task;
            this.addTaskEventParams(this.taskEdit, this.task.event_close);
            if (this.task.event_close === "OrderStatusChanged"
                && this.task.params?.order_status_close === "None") {
                    this.task.params.order_status_close = null;
            }
            this.taskEdit.patchValue(this.task);
        }

        this.setupListeners();

        this.timeZones = Object.keys(TIME_ZONES).map((item) => {
            return {value: item, name: item};
        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
