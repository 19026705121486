import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {StorageService} from "../../common/services/storage.service";
import {Drawer} from "../components/drawer/drawer.component";
import Items from "../components/drawer/drawer.config";
import {Api, ApiService} from "../../common/services/api.service";

@Injectable()
export class DrawerService {

    private drawerLockSubject: Subject<boolean> = new Subject<boolean>();
    private drawerItemsSubject: BehaviorSubject<Drawer.IItem[]> = new BehaviorSubject<Drawer.IItem[]>([]);

    public readonly drawerLockState: Observable<boolean> = this.drawerLockSubject.asObservable();
    public readonly drawerItems: Observable<Drawer.IItem[]> = this.drawerItemsSubject.asObservable();

    public constructor(
        private storageService: StorageService,
        private apiService: ApiService
    ) {
    }

    /**
     * Drawer lock state updates subscription
     * @param {boolean} state
     */
    public drawerLock(state: boolean): void {
        this.storageService.set("lockDrawer", state);
        this.drawerLockSubject.next(state);
    }

    public async getServiceLevelsInMenu(drawerItems: any, partner: string): Promise<any> {
        this.apiService.setHeaders({partner: partner});
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["drawer", "service_levels"]);
        if (Array.isArray(data) && data.length > 0) {
            const ordersSection: any = drawerItems.find((obj: any): boolean => obj.title === "Orders");
            if (ordersSection) {
                ordersSection.items = ordersSection.items.filter((value: any): boolean => {
                    return value.title !== "List by service level";
                });
                const byServiceLevel: any = {
                    title: "List by service level",
                    link: null,
                    expanded: false,
                    icon: "filter_list",
                    permissions: ["browse_orders"],
                    items: []
                };
                data.forEach((service_level: any): void => {
                    byServiceLevel.items.push({
                        title: service_level.name,
                        link: "orders-by-service-level/browse/id/" + service_level.id,
                        permissions: ["browse_orders"]
                    });
                });
                drawerItems.find((obj: any): boolean => obj.title === "Orders").items.push(byServiceLevel);
            }
        }
        this.drawerItemsSubject.next(drawerItems);
    }

    /**
     * Set visible menu items
     * @param {string | Drawer.IItem[]} section
     * @param {string} partner
     */
    public setItems(section: string | Drawer.IItem[], partner: string = null): void {
        if (typeof section === "string") {
            if (Object.keys(Items).includes(section)) {
                if (partner) {
                    const drawerItems: any = Items[section];
                    this.drawerItemsSubject.next(Items[section]);

                    this.getServiceLevelsInMenu(drawerItems, partner);

                } else {
                    this.drawerItemsSubject.next(Items[section]);
                }
            }
        } else {
            this.drawerItemsSubject.next(section);
        }
    }
}
