import {Component} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";


@Component({
    selector: "section-warehouse-transactions-outbound",
    template: `
        <section-warehouse-transactions-list
                [params]='{title: "Outbound transactions", type: "outbound", state: state}'>
        </section-warehouse-transactions-list>`
})
export class WarehouseTransactionsListOutboundComponent implements Base.IComponent {

    public readonly state: Base.IState;

    public ngConfig(): Base.IConfig {
        return {
            name: "transactions-outbound",
            actions: {
                "browse": ["browse_warehouse_transactions"]
            }
        };
    }

}
