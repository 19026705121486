import {Component, ChangeDetectionStrategy, ViewEncapsulation} from "@angular/core";
import {Base} from "../../../../../common/interfaces/base.interfaces";

@Component({
    selector: "section-error",
    template: `
        <h1 class="container-heading">Error</h1>
        <mat-card>
            <mat-card-content>
                <p *ngIf="state.params.type === 'denied'">Access denied</p>
                <div *ngIf="!state.params.type">
                    <div class="text-center">
                        <img src="./assets/images/404.png">
                    </div>
                    <p class="text-center">Page not found or under construction</p>
                </div>
            </mat-card-content>
        </mat-card>
    `,
    styles: [
        `
                         section-error mat-card-content {
                             display: flex !important;
                             justify-content: center;
                             align-items: center;
                         }
                     `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class ErrorComponent implements Base.IComponent {

    public readonly state: Base.IState;

    public ngConfig(): Base.IConfig {
        return {
            name: "error",
            actions: {
                "*": "*"
            }
        };
    }

}
