<common-confirm></common-confirm>

<h1 class="container-heading">Team Overview</h1>

<mat-card>
    <mat-card-content>
        <div class="columns-container" *ngIf="team">
            <div class="column-1-3">
                <mat-card>
                    <div class="logo">
                        <h1 *ngIf="team.name">{{team.name}}</h1>
                        <h3 *ngIf="team.description">{{team.description}}</h3>
                    </div>

                    <mat-list class="nav-list">
                        <mat-list-item (click)="showSection = 1" [class.active]="showSection === 1">
                            <mat-icon matListItemIcon>people</mat-icon>
                            <p matListItemTitle>Users</p>
                        </mat-list-item>
                    </mat-list>
                </mat-card>
            </div>

            <div class="column-2-3" *ngIf="showSection === 1">
                <mat-card>
                    <h3 matSubheader>Users</h3>
                    <ng-template mat-tab-label>
                        Clients
                    </ng-template>
                    <div class="content">
                        <mat-form-field class="search half">
                            <mat-label>Add user</mat-label>
                            <input type="text" matInput [formControl]="userSearch"

                                   [matAutocomplete]="cliSearch">
                        </mat-form-field>

                        <mat-autocomplete #cliSearch="matAutocomplete" (optionSelected)="addUser($event)">
                            <mat-option *ngFor="let option of usersListFiltered | async" [value]="option">
                                {{option.name}} ({{ option.email}})
                            </mat-option>
                        </mat-autocomplete>

                        <mat-list *ngIf="team.users && team.users.length > 0">
                            <ng-template ngFor let-userProfile let-i="index" [ngForOf]="team.users">
                                <mat-list-item>
                                    <div matListItemTitle>
                                        <button mat-icon-button color="warn" class="pull-right" (click)="removeUser(i)">
                                            <mat-icon>remove</mat-icon>
                                        </button>
                                        <div class="lh-48">
                                            {{userProfile.name}} <{{userProfile.email}}>
                                        </div>
                                    </div>
                                </mat-list-item>
                                <mat-divider></mat-divider>
                            </ng-template>
                        </mat-list>

                    </div>
                </mat-card>
            </div>

        </div>

    </mat-card-content>
</mat-card>
