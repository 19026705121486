import {Component, OnInit} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Order} from "../../../../../../common/interfaces/order.interface";


@Component({
    selector: "section-order-list-canceled",
    template: `
        <section-order-list [params]="params"></section-order-list>`
})
export class OrderListCanceledComponent implements Base.IComponent, OnInit {

    public readonly state: Base.IState;

    public params: Order.IOrderListParams;

    public constructor() {
    }

    public ngOnInit(): void {
        this.params = {
            title: "Canceled orders list",
            state: this.state,
            actions: {
                view: false,
                delete: false,
                add: false,
                export: true,
                remarks: false
            },
            query: {canceled: true}
        };
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "orders-canceled",
            actions: {
                "browse": ["browse_orders"]
            }
        };
    }

}
