import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild} from "@angular/core";
import {Table, TableComponent} from "../../table/table.component";
import {ConfirmComponent} from "../../confirm/confirm.component";
import {Api, ApiService} from "../../../services/api.service";
import {ToastService} from "../../../services/toast.service";
import {SCHEDULER_DATES_OPTIONS} from "../form/form.component";
import {Modal} from "../../../../section/services/modal.service";
import {UserService} from "../../../services/user.service";
import {SpinnerService} from "../../../services/spinner.service";

@Component({
    selector: "common-scheduler-list",
    template: `

        <common-confirm></common-confirm>
        <h1 class="container-heading" *ngIf="modal">Scheduled tasks</h1>
        <mat-card>
            <mat-card-content>
                <common-table *ngIf="listTable" [data]="data" [options]="listTable" (action)="handleListAction($event)">
                </common-table>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SchedulerListComponent implements OnInit {

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.IOptions;

    @ViewChild(TableComponent, {static: false})
    public tableRef: TableComponent;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @Input()
    public data: any;

    public modal: Modal.IModal;

    public options: any = SCHEDULER_DATES_OPTIONS;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private toastService: ToastService,
        private userService: UserService,
        private spinnerService: SpinnerService
    ) {
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        const actions: Table.Action.ISettings[] = [
            {
                name: "delete",
                title: "Delete"
            }
        ];

        const columns: DataTables.ColumnSettings[] = [
            {
                data: "ref",
                title: "Ref"
            },
            {
                data: "type",
                title: "Type"
            },
            {
                data: "type",
                title: "",
                render: (data: string, d: any, row: any): string => {
                    if (row.partner) {
                        return `<img src="${row.partner.icon_path}" alt="" class="logo">`;
                    } else {
                        return "";
                    }
                },
                searchable: false,
                orderable: false
            },
            {
                data: "repeat",
                title: "Repeat",
                render: (data: string, d: any, row: any): string => {
                    switch (data) {
                        case "once":
                            return `once
                            ${row.year}-${this.options.month[row.month - 1]}-${("" + row.date).padStart(2, "0")}
                            at ${("" + row.hour).padStart(2, "0")}:${("" + row.min).padStart(2, "0")}`;
                        case "yearly":
                            return `every year ${this.options.month[row.month - 1]}-${("" + row.date).padStart(2, "0")}
                            at ${("" + row.hour).padStart(2, "0")}:${("" + row.min).padStart(2, "0")}`;
                        case "monthly":
                            return `every month ${row.date}
                            at ${("" + row.hour).padStart(2, "0")}:${("" + row.min).padStart(2, "0")}`;
                        case "weekly":
                            return `every week on ${this.options.day[row.day]}
                             at ${("" + row.hour).padStart(2, "0")}:${("" + row.min).padStart(2, "0")}`;
                        case "daily":
                            return `every day at
                            ${("" + row.hour).padStart(2, "0")}:${("" + row.min).padStart(2, "0")}`;
                        case "hourly":
                            return `every hour at ${("" + row.min).padStart(2, "0")} min`;
                        default:
                            return "";
                    }
                }
            },
            {
                data: "created_at",
                title: "Created at"
            },
            {
                data: "user.name",
                name: "User.name",
                title: "User",
                orderable: false
            },
            {
                data: "team.name",
                name: "Team.name",
                title: "Team",
                orderable: false
            }
        ];

        this.listTable = {
            actions,
            api: this.apiService.getUrl(["user", "tasks"]) + "?data_structure=dataTables",
            columns,
            order: [],
            search: true
        };
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Delete address
     * @param id
     */
    public async delete(id: number): Promise<any> {
        if (await this.confirmRef.confirm("Are you sure want to delete current task?")) {
            this.spinnerService.show();
            const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
                ["user", "tasks", "" + id]);
            if (type as string === "success") {
                this.toastService.show(message, "success");
                this.tableRef.reload();
            }
            this.spinnerService.hide();
        }
    }

    /**
     * Handle list row action
     * @param {Table.Action.IResult} action
     * @returns {Promise<any>}
     */
    public async handleListAction(action: Table.Action.IResult): Promise<any> {
        if (action.name === "delete") {
            this.delete(action.data.id);
        }
    }

    public ngOnInit(): void {
        if (this.modal) {
            if (this.modal.params.data) {
                this.data = this.modal.params.data;
            }
        }
        this.prepareList();
    }

}
