import {APP_INITIALIZER, ErrorHandler, NgModule} from "@angular/core";
import {Router, RouterModule} from "@angular/router";
import {CommonModule, GuardService} from "../common/common.module";
import {OutletComponent, SectionModule} from "../section/section.module";
import {DrawerComponent} from "./components/drawer/drawer.component";
import {EntranceComponent} from "./components/entrance/entrance.component";
import {LoginComponent} from "./components/login/login.component";
import {SectionComponent} from "./components/section/section.component";
import {ToolbarComponent} from "./components/toolbar/toolbar.component";
import {MainComponent} from "./components/main.component";
import {DrawerItemsComponent} from "./components/drawer/items/items.component";
import {DrawerService} from "./services/drawer.service";
import {ServiceWorkerModule} from "@angular/service-worker";
import {environment} from "../../environments/environment";
import {RegisterComponent} from "./components/register/register.component";
import {ChangePasswordComponent} from "./components/change_password/change-password.component";
import {SupervisorModalComponent} from "./components/toolbar/supervisor-modal/supervisor-modal.component";
import * as Sentry from "@sentry/angular-ivy";

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: "register",
                component: RegisterComponent,
            },
            {
                path: "entrance",
                component: EntranceComponent,
                canActivate: [
                    GuardService
                ]
            },
            {
                path: "login",
                component: LoginComponent,
                canActivate: [
                    GuardService
                ]
            },
            {
                path: "change-password",
                component: ChangePasswordComponent,
                canActivate: [
                    GuardService
                ]
            },
            {
                path: "admin",
                component: SectionComponent,
                canActivate: [
                    GuardService
                ],
                children: [
                    {
                        path: "",
                        pathMatch: "full",
                        redirectTo: "dashboard"
                    },
                    {
                        path: "**",
                        component: OutletComponent
                    }
                ],
            },
            {
                path: "partner/:section",
                component: SectionComponent,
                canActivate: [
                    GuardService
                ],
                children: [
                    {
                        path: "",
                        pathMatch: "full",
                        redirectTo: "dashboard"
                    },
                    {
                        path: "**",
                        component: OutletComponent
                    }
                ],
            },
            {
                path: "threepl/:section",
                component: SectionComponent,
                canActivate: [
                    GuardService
                ],
                children: [
                    {
                        path: "",
                        pathMatch: "full",
                        redirectTo: "dashboard"
                    },
                    {
                        path: "**",
                        component: OutletComponent
                    }
                ],
            },
            {
                path: "warehouse/:section",
                component: SectionComponent,
                canActivate: [
                    GuardService
                ],
                children: [
                    {
                        path: "",
                        pathMatch: "full",
                        redirectTo: "dashboard"
                    },
                    {
                        path: "**",
                        component: OutletComponent
                    }
                ],
            },
            {
                path: "**",
                redirectTo: "login"
            },
        ]),
        CommonModule.forRoot(),
        SectionModule,
        ServiceWorkerModule.register("/ngsw-worker.js", {enabled: environment.production}),
    ],
    declarations: [
        DrawerComponent,
        DrawerItemsComponent,
        EntranceComponent,
        LoginComponent,
        MainComponent,
        SectionComponent,
        ToolbarComponent,
        RegisterComponent,
        ChangePasswordComponent,
        SupervisorModalComponent
    ],
    bootstrap: [
        MainComponent
    ],
    providers: [
        DrawerService,
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        }, {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
    ]
})
export class AppModule {
}
