import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from "@angular/core";
import {Router} from "@angular/router";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {Table} from "../../../../../../../common/interfaces/table.interface";
import {UserService} from "../../../../../../../common/services/user.service";
import {ConfirmComponent} from "../../../../../../../common/components/confirm/confirm.component";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {Table2Component} from "../../../../../../../common/components/table2";
import {ModalService} from "../../../../../../services/modal.service";
import {PartMasterConversionsFormComponent} from "../..";
import {Api3Service} from "../../../../../../../common/services/api3.service";
import {PartnerService} from "../../../../../../../common/services/partner.service";

@Component({
    selector: "section-pm-conversions-list",
    templateUrl: "list.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PartMasterConversionsListComponent implements Base.IComponent, OnInit {

    @ViewChild(ConfirmComponent, {static: true})
    public confirmRef: ConfirmComponent;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    public listTable: Table.ISettings;

    public readonly state: Base.IState;

    public constructor(
        private router: Router,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private userService: UserService,
        private spinnerService: SpinnerService,
        private modalService: ModalService,
        private partnerService: PartnerService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {

        const actions: Table.IAction[] = [];

        if (this.userService.validatePermissions("edit_part_master_conversions")) {
            actions.push({
                name: "edit",
                title: "Edit",
                click: async (row: any): Promise<any> => {
                    await this.modalService.open(PartMasterConversionsFormComponent, {
                        id: row.id,
                        action: "edit"
                    });

                    this.tableRef.reload();
                }
            });
        }

        if (this.userService.validatePermissions("delete_part_master_conversions")) {
            actions.push({
                name: "delete",
                title: "Delete",
                click: async (row: any): Promise<any> => {
                    if (!await this.confirmRef.confirm("Are you sure want to delete this conversion?")) {
                        return;
                    }
                    this.spinnerService.show();
                    const {code, message}: Api.IResponse = await this.api3Service.request(Api.EMethod.Delete,
                        `partner/${PartnerService.partner.slug}/part-master-conversions/${row.id}`);
                    this.spinnerService.hide();
                    if (code === 200) {
                        this.toastService.show(message, "success");
                        this.tableRef.reload();
                    }
                }
            });
        }

        this.listTable = {
            table_id: "part-master-conversions-table",
            columns: [
                {
                    data: "part_master.item",
                    name: "PartMaster.Item",
                    title: "Item"
                },
                {
                    data: "part_master.description",
                    name: "PartMaster.Description",
                    title: "Description",
                    searchable: false,
                    sortable: false
                },
                {
                    data: "conversion_part_master.item",
                    name: "ConversionPartMaster.Item",
                    title: "Conversion",
                    searchable: false,
                    sortable: false
                },
                {
                    data: "type",
                    title: "Type",
                    searchable: false,
                    sortable: false
                },
                {
                    data: "clear_serial",
                    title: "Clear Serial",
                    searchable: false,
                    sortable: false,
                    render: (row: any): string => {
                        return row.clear_serial ? "Yes" : "No";
                    }
                }
            ],
            actions,
            api: {
                url: ["partner", PartnerService.partner.slug, "part-master-conversions"],
                version: 3
            }
        };

        this.changeDetectorRef.markForCheck();
    }

    public async add(): Promise<any> {
        await this.modalService.open(PartMasterConversionsFormComponent);

        this.tableRef.reload();
    }

    public ngOnInit(): void {
        this.prepareList();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "part-master-conversions",
            actions: {
                "browse": ["browse_part_master_conversions"]
            }
        };
    }

}
