import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from "@angular/core";
import {Router} from "@angular/router";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {Table, TableComponent} from "../../../../../../common/components/table/table.component";
import {ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {UserService} from "../../../../../../common/services/user.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";


@Component({
    selector: "section-role-list",
    templateUrl: "list.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class RoleListComponent implements Base.IComponent, OnInit {

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild(TableComponent, {static: false})
    public tableRef: TableComponent;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.IOptions;

    public readonly state: Base.IState;

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private userService: UserService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        const actions: Table.Action.ISettings[] = [];
        if (this.userService.validatePermissions("edit_users")) {
            actions.push({
                name: "edit",
                title: "Edit"
            });
        }
        this.listTable = {
            actions,
            api: this.apiService.getUrl(["role"]) + "?data_structure=dataTables",
            columns: [
                {
                    data: "id",
                    title: "ID"
                },
                {
                    data: "display_name",
                    title: "Name"
                }
            ],
            order: [],
            search: true
        };
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Handle list row action
     * @param {Table.Action.IResult} action
     * @returns {Promise<any>}
     */
    public async handleListAction(action: Table.Action.IResult): Promise<any> {
        if (action.name === "edit" && action.data.id) {
            this.router.navigate([this.state.section, this.state.component, "edit", "id", action.data.id]);
        }
    }

    public ngOnInit(): void {
        this.prepareList();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "role",
            actions: {
                "browse": ["browse_users"]
            }
        };
    }

}
