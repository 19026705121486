<common-confirm></common-confirm>

<h1 class="container-heading">Remark type overview</h1>

<mat-card>
    <mat-card-content>
        <div class="columns-container" *ngIf="remark">
            <div class="column-1-3">
                <mat-card>
                    <div class="logo">
                        <h1 *ngIf="remark.name">{{remark.name}}</h1>
                    </div>

                    <mat-list class="nav-list">
                        <mat-list-item (click)="showSection = 1" [class.active]="showSection === 1">
                            <mat-icon matListItemIcon>people</mat-icon>
                            <p matListItemTitle>Roles</p>
                        </mat-list-item>
                    </mat-list>
                </mat-card>
            </div>

            <div class="column-2-3" *ngIf="showSection === 1">
                <mat-card>
                    <h3 matSubheader>Roles</h3>
                    <ng-template mat-tab-label>
                        Clients
                    </ng-template>
                    <div class="content">
                        <mat-form-field class="search half">
                            <mat-label>Add role</mat-label>
                            <input type="text" matInput [formControl]="rolesSearch"

                                   [matAutocomplete]="cliSearch">
                        </mat-form-field>

                        <mat-autocomplete #cliSearch="matAutocomplete" (optionSelected)="addRole($event)">
                            <mat-option *ngFor="let option of rolesList" [value]="option">
                                {{option.name}}
                            </mat-option>
                        </mat-autocomplete>

                        <mat-list *ngIf="remark.roles && remark.roles.length > 0">
                            <ng-template ngFor let-role let-i="index" [ngForOf]="remark.roles">
                                <mat-list-item>
                                    <button mat-icon-button color="warn" class="pull-right"
                                            (click)="removeRole(i)">
                                        <mat-icon>remove</mat-icon>
                                    </button>
                                    <h4 matListItemTitle>{{role.name}}</h4>
                                </mat-list-item>
                                <mat-divider></mat-divider>
                            </ng-template>
                        </mat-list>

                    </div>
                </mat-card>
            </div>

        </div>

    </mat-card-content>
</mat-card>
