<h1 class="container-heading margin-bottom-10">Select cell</h1>

<div class="clear">

    <mat-accordion>
        <mat-expansion-panel *ngFor="let locker of modal.params.lockers">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon>table</mat-icon>
                    &nbsp; {{ locker.name }}
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div>
                Available cells:
            </div>

            <div *ngFor="let cell of locker.empty_cells" class="padding-10 pointer cell"
                 (click)="select.setValue(cell)"
                 [class.selected]="select.value && cell.id === select.value.id">
                {{ cell.warehouse_location.location }}
                ({{ cell.width }} x {{ cell.height }} x {{ cell.depth }} {{ cell.measure_unit }})
            </div>

        </mat-expansion-panel>
    </mat-accordion>


    <div class="padding-top-10">
        <button type="button"
                mat-raised-button
                color="accent"
                [disabled]="!select.valid"
                (click)="selectItem()">
            Select cell
        </button>

        &nbsp;
        <b class="text-error">Cell will be opened after successful item/serial scan.</b>
    </div>
</div>