<common-confirm></common-confirm>
<h1 *ngIf="state.action === 'add'" class="container-heading">Add warehouse</h1>
<h1 *ngIf="state.action === 'edit'" [class.no-margin]="formData" class="container-heading">
    Edit warehouse
    <span *ngIf="formData" class="container-heading-name">{{ formData.name }}</span>
</h1>

<div *ngIf="formData" class="info">
    <span *ngIf="formData.created_at" class="item">
        Created at:
        <span class="value">{{ formData.created_at | commonFix | date: "dd/MM/y HH:mm" }}</span>
    </span>
    <span *ngIf="formData.updated_at" class="item">
        Updated at:
        <span class="value">{{ formData.updated_at | commonFix | date: "dd/MM/y HH:mm" }}</span>
    </span>
</div>

<mat-card>
    <mat-card-content>
        <mat-tab-group>
            <mat-tab label="Settings">
                <form *ngIf="formGroup" [formGroup]="formGroup" (submit)="handleFormSubmit()" autocomplete="off"
                      novalidate>

                    <div class="group custom flex">
                        <mat-form-field class="half">
                            <mat-label>Name</mat-label>
                            <input matInput formControlName="name" [required]="true">
                        </mat-form-field>

                        <mat-form-field class="half">
                            <mat-label>Type</mat-label>
                            <mat-select formControlName="type">
                                <mat-option value="warehouse">Warehouse</mat-option>
                                <mat-option value="site">Site</mat-option>
                                <mat-option value="OEM">OEM</mat-option>
                                <mat-option value="Virtual">Virtual</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="half">
                            <mat-label>Cut off</mat-label>
                            <input matInput formControlName="cut_off" type="time" [required]="true">
                        </mat-form-field>

                        <mat-form-field class="half">
                            <mat-label>Time Zone</mat-label>
                            <input matInput formControlName="timezone">
                        </mat-form-field>

                        <ng-container *ngIf="formGroup.value.type === 'Virtual'">
                            <mat-form-field class="half" formGroupName="properties">
                                <mat-label>Inbound transaction trigger</mat-label>
                                <mat-select formControlName="inbound_transaction_trigger"
                                >
                                    <mat-option [value]="null">None</mat-option>
                                    <mat-option value="pod">POD</mat-option>
                                    <mat-option value="confirmation">Confirmation</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field class="half" formGroupName="properties">
                                <mat-label>Outbound transaction trigger</mat-label>
                                <mat-select formControlName="outbound_transaction_trigger"
                                >
                                    <mat-option [value]="null">None</mat-option>
                                    <mat-option value="awb">AWB</mat-option>
                                    <mat-option value="pod">POD</mat-option>
                                    <mat-option value="confirmation">Confirmation</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>

                    </div>

                    <h3 class="mat-h3">Address</h3>
                    <div formGroupName="address" class="group address flex">
                        <div formGroupName="customer" class="group address flex">
                            <mat-form-field class="full">
                                <mat-label>Customer name</mat-label>
                                <input matInput formControlName="name" [required]="true">
                            </mat-form-field>
                        </div>

                        <common-autocomplete *ngIf="countries"
                                             placeholder="Country"
                                             class="full"
                                             [options]="countries"
                                             [allowFreeInput]="false"
                                             (optionSelected)="onCountrySelected($event)"
                                             [errorText]="'Select country'"
                                             [required]="true"
                                             [image]="{path:'assets/images/flags',ext:'svg'}"
                                             [value]="formGroup.get('address').get('country').value">

                        </common-autocomplete>

                        <mat-form-field class="full">
                            <mat-label>Address</mat-label>
                            <input matInput formControlName="address" [required]="true">
                        </mat-form-field>
                        <mat-form-field class="full">
                            <mat-label>Additional address information</mat-label>
                            <input matInput formControlName="address2">
                        </mat-form-field>
                        <mat-form-field class="third">
                            <mat-label>City</mat-label>
                            <input matInput formControlName="city" [required]="true">
                        </mat-form-field>
                        <mat-form-field class="third">
                            <mat-label>ZIP code</mat-label>
                            <input matInput formControlName="zip" [required]="true">
                        </mat-form-field>
                        <mat-form-field class="third">
                            <mat-label>State or district (if available)</mat-label>
                            <input matInput formControlName="state">
                        </mat-form-field>

                        <mat-form-field class="half">
                            <mat-label>Latitude</mat-label>
                            <input matInput formControlName="lat">
                        </mat-form-field>

                        <mat-form-field class="half">
                            <mat-label>Longitude</mat-label>
                            <input matInput formControlName="lng">
                        </mat-form-field>
                    </div>

                    <h3 class="mat-h3">Contact / Contact person</h3>
                    <div formGroupName="contact" class="group custom flex">
                        <mat-form-field class="half">
                            <mat-label>First name</mat-label>
                            <input matInput formControlName="first_name" [required]="true">
                        </mat-form-field>
                        <mat-form-field class="half">
                            <mat-label>Last name</mat-label>
                            <input matInput formControlName="last_name" [required]="true">
                        </mat-form-field>
                        <mat-form-field class="full">
                            <mat-label>Email address</mat-label>
                            <input matInput formControlName="email" [required]="true">
                        </mat-form-field>
                        <mat-form-field class="half">
                            <mat-label>Phone number (with local country code)</mat-label>
                            <input matInput formControlName="phone"
                                   [required]="true">
                        </mat-form-field>
                        <mat-form-field class="half">
                            <mat-label>Fax</mat-label>
                            <input matInput formControlName="fax">
                        </mat-form-field>
                    </div>

                    <div class="group center flex">
                        <mat-checkbox [formControlName]="'is_dc'" class="full">
                            Distribution Center
                        </mat-checkbox>
                        <mat-checkbox [formControlName]="'is_inspection'" class="full">
                            Can manage inspections
                        </mat-checkbox>
                        <mat-checkbox [formControlName]="'is_sw_upgrade'" class="full">
                            Can manage software upgrades
                        </mat-checkbox>
                    </div>

                    <div class="actions">
                        <button mat-raised-button color="primary" type="submit" class="main"
                                [disabled]="!formGroup.valid">
                            {{ state.action | ucfirst }}
                        </button>
                        <a [routerLink]="state.action === 'edit' ? '../../../' : '../'" mat-button type="button">
                            Cancel
                        </a>
                    </div>

                </form>
            </mat-tab>
            <mat-tab label="Couriers" *ngIf="couriers">
                <div class="text-center">
                    <mat-form-field>
                        <mat-label>Search</mat-label>
                        <input matInput type="search" #courierSearch>
                    </mat-form-field>
                </div>
                <div style="column-count: 3" class="padding-top-20">
                    <mat-card *ngFor="let courier of couriers | search:courierSearch.value:['display_name','slug']"
                              class="margin-bottom-10">
                        <div class="flex row space-between align-center">
                            <div><b>{{ courier.display_name }}</b><br>{{ courier.slug }}</div>
                            <div>
                                <mat-slide-toggle
                                        [checked]="selected_couriers.includes(courier.id)"
                                        (change)="toggleCourier(courier.id,$event)"></mat-slide-toggle>
                            </div>
                        </div>
                    </mat-card>
                </div>

            </mat-tab>
            <mat-tab label="Logs">
                <common-table2 *ngIf="logsTableSettings" [settings]="logsTableSettings"></common-table2>
            </mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>
