import {Directive, HostListener} from "@angular/core";


@Directive({
    selector: "[noscroll]"
})
export class NoScrollDirective {


    @HostListener("mousewheel", ["$event"])
    private onMouseWheelChrome(event: any): void {
        this.mouseWheelFunc(event);
    }

    @HostListener("DOMMouseScroll", ["$event"])
    private onMouseWheelFirefox(event: any): void {
        this.mouseWheelFunc(event);
    }

    @HostListener("onmousewheel", ["$event"])
    private onMouseWheelIE(event: any): void {
        this.mouseWheelFunc(event);
    }

    private mouseWheelFunc(event: any): void {
        // for IE
        event.returnValue = false;
        // for Chrome and Firefox
        if (event.preventDefault) {
            event.preventDefault();
        }
    }

}