<common-confirm></common-confirm>
<div class="flex space-between row">
    <div class="half">
        <mat-card>
            <div class="title"><b>Address From</b></div>
            <br>
            <form [formGroup]="fromAddress" *ngIf="fromAddress">
                <div class="group flex">
                    <ng-container *ngFor="let control of fromAddress.controls | keys">
                        <mat-form-field *ngIf="control !=='in_eu'" class="full">
                            <mat-label>{{ control | ucfirst }}</mat-label>
                            <input matInput type="text" [formControlName]="control">
                        </mat-form-field>
                    </ng-container>
                </div>

                <div class="group flex">
                    <ng-container *ngFor="let control of fromAddress.controls | keys">
                        <div class="full padding-bottom-10 padding-left-10 padding-right-10 padding-top-10 background-danger margin-bottom-10"
                             *ngIf="fromAddress.controls[control].errors">
                            <b>{{ control | ucfirst }}</b> field
                            <span *ngIf="fromAddress.controls[control].errors?.['required']">
                                is required.
                            </span>

                            <span *ngIf="fromAddress.controls[control].errors?.['maxlength']">
                                max length {{ fromAddress.controls[control].errors?.['maxlength']['requiredLength'] }}.
                            </span>

                            <span *ngIf="control === 'phone' && fromAddress.controls[control].errors?.['pattern']">
                                can contain +-() and numbers only.
                            </span>

                            <span *ngIf="control === 'email' && fromAddress.controls[control].errors?.['pattern']">
                                must be a valid email address.
                            </span>
                        </div>
                    </ng-container>
                </div>
            </form>
        </mat-card>
    </div>
    <div class="half">
        <mat-card>
            <div class="title"><b>Address To</b></div>
            <br>
            <form [formGroup]="toAddress" *ngIf="toAddress">
                <div class="group flex">
                    <ng-container *ngFor="let control of toAddress.controls | keys">
                        <mat-form-field class="full" *ngIf="control !=='in_eu'">
                            <mat-label>{{ control | ucfirst }}</mat-label>
                            <input matInput type="text" [formControlName]="control">
                        </mat-form-field>
                    </ng-container>
                </div>

                <div class="group flex">
                    <ng-container *ngFor="let control of toAddress.controls | keys">
                        <div class="full padding-bottom-10 padding-left-10 padding-right-10 padding-top-10 background-danger"
                             *ngIf="toAddress.controls[control].errors">
                            {{ control | ucfirst }} field
                            <span *ngIf="toAddress.controls[control].errors?.['required']">
                                is required.
                            </span>

                            <span *ngIf="toAddress.controls[control].errors?.['maxlength']">
                                max length {{ toAddress.controls[control].errors?.['maxlength']['requiredLength'] }}.
                            </span>

                            <span *ngIf="control === 'phone' && toAddress.controls[control].errors?.['pattern']">
                                 can contain only + - ( ) and numbers.
                            </span>

                            <span *ngIf="control === 'email' && toAddress.controls[control].errors?.['pattern']">
                                must be a valid email address.
                            </span>
                        </div>
                    </ng-container>
                </div>

            </form>
        </mat-card>
    </div>
</div>
