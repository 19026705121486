import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import {Router} from "@angular/router";
import {ApiService} from "../../../../../../common/services/api.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Table2Component} from "src/modules/common/components/table2/table.component";
import {Table} from "src/modules/common/interfaces/table.interface";
import {ModalService} from "src/modules/section/services/modal.service";
import {ModalComponent} from "src/modules/common/components/modal/modal.component";
import {FormControl} from "@angular/forms";
import {StorageService} from "src/modules/common/services/storage.service";
import {takeUntil} from "rxjs/operators";
import {DomSanitizer} from "@angular/platform-browser";
import * as moment from "moment";


@Component({
    selector: "email-logs-list",
    template: `
        <common-confirm></common-confirm>
        <mat-card>
            <mat-card-content>
                <common-table2 *ngIf="listTable" [settings]="listTable">
                    <div row1>
                        <common-form-date-range label="Sending" (valueChange)="dateRange.setValue($event)">
                        </common-form-date-range>
                    </div>
                </common-table2>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailLogsListComponent implements Base.IComponent, OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    public listTable: Table.ISettings;

    public readonly state: Base.IState;

    public dateRange: FormControl = new FormControl(null);

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private modalService: ModalService,
        private storageService: StorageService,
        private sanitizer: DomSanitizer,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    private get url(): { url: string[], query?: { [key: string]: any } } {
        return {
            url: ["logs_emails"],
            query: {
                date_range: this.dateRange.value
            }
        };
    }

    private showEmails(data: any): string {

        if (!data || !data.length) {
            return "";
        }

        const list: string[] = Object.values(data);
        if (list.length > 1) {
            return `<b>${list[0]}</b> + ${list.length - 1}`;
        }
        return list[0];
    }

    public showList(title: string, data: any): void {

        if (!data || !data.length) {
            return;
        }

        const list: string[] = Object.values(data);
        if (list.length > 1) {
            this.modalService.open(ModalComponent, {
                title,
                template: list.join("<br>")
            });
        }
    }

    private prepareList(): void {
        const actions: Table.IAction[] = [
            {
                name: "view",
                title: "View",
                click: async (row: any): Promise<void> => {
                    await this.modalService.open(ModalComponent, {
                        title: row.subject,
                        template: this.sanitizer.bypassSecurityTrustHtml(row.body),
                        modalWidth: "calc(100% - 250px)"
                    });
                }
            }
        ];

        const columns: Table.ICol[] = [
            {
                data: "subject",
                title: "Mail Subject"
            },
            {
                data: "to",
                title: "Sent to",
                render: (row: any): string => {
                    return this.showEmails(row.to);
                },
                click: (row: any): void => {
                    this.showList(row.subject + ". Sent to", row.to);
                },
                sortable: false,
                searchable: false
            },
            {
                data: "cc",
                title: "Sent cc",
                render: (row: any): string => {
                    return this.showEmails(row.cc);
                },
                click: (row: any): void => {
                    this.showList(row.subject + ". Sent cc", row.cc);
                },
                sortable: false,
                searchable: false,
            },
            {
                data: "bcc",
                title: "Sent bcc",
                render: (row: any): string => {
                    return this.showEmails(row.bcc);
                },
                click: (row: any): void => {
                    this.showList(row.subject + ". Sent bcc", row.bcc);
                },
                sortable: false,
                searchable: false
            },
            {
                data: "created_at",
                title: "Date of sending",
                searchable: false,
                render: (row: any): string => {
                    return moment(row.created_at).utcOffset(4).format("YYYY-MM-DD HH:mm:ss");
                }
            },
            {
                data: "user.name",
                name: "User.name",
                title: "User",
                sortable: false,
            }
        ];

        this.listTable = {
            table_id: "logs_emails_table",
            actions,
            api: this.url,
            columns,
            sort_default: {
                data: "created_at",
                dir: "desc"
            }
        };
        this.changeDetectorRef.markForCheck();
    }

    public ngOnInit(): void {
        this.prepareList();

        this.dateRange.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((): void => {
            this.tableRef.reload(this.url);
        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "email-logs",
            actions: {
                "browse": "*"
            }
        };
    }
}
