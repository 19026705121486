<common-confirm></common-confirm>
<mat-card>
    <mat-card-content>
    <form [formGroup]="formGroup" (submit)="submit()">
        <div class="group flex">
            <div class="full">
                <h1>{{isEdit ? "Edit" : "Add"}} Box</h1>
            </div>

            <mat-form-field class="full">
                <mat-label>Box Ref</mat-label>
                <input matInput formControlName="ref" required>
            </mat-form-field>

            <mat-form-field class="full">
                <mat-label>Customers Inventory Name</mat-label>
                <mat-select formControlName="hub_id">
                    <mat-option>
                        <ngx-mat-select-search [formControl]="hubsSearch"
                                                [placeholderLabel]="'Search'"
                                                [clearSearchInput]="true"
                                                [noEntriesFoundLabel]="'nothing found'">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let hub of hubsFiltered" [value]="hub.id">
                        {{hub.customers_inventory_name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="full">
                <mat-label>Location</mat-label>
                <mat-select formControlName="warehouse_location_id">
                    <mat-option>
                        <ngx-mat-select-search [formControl]="locationsSearch"
                                                [placeholderLabel]="'Search'"
                                                [clearSearchInput]="true"
                                                [noEntriesFoundLabel]="'nothing found'">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let location of locationsFiltered" [value]="location.value">
                        {{location.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="full">
                <mat-label>Pallet</mat-label>
                <mat-select formControlName="pallet_id">
                    <mat-option>
                        <ngx-mat-select-search [formControl]="palletsSearch"
                                                [placeholderLabel]="'Search'"
                                                [clearSearchInput]="true"
                                                [noEntriesFoundLabel]="'nothing found'">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option [value]="null">
                        None
                    </mat-option>
                    <mat-option *ngFor="let pallet of palletsFiltered" [value]="pallet.id">
                        {{pallet.ref}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="actions">
            <button mat-raised-button type="submit" color="primary"
                [disabled]="!formGroup.valid">
                    Save
            </button>
        </div>
    </form>
    </mat-card-content>
</mat-card>

<mat-card>
    <mat-card-content>
        <common-table2 *ngIf="listTable" [settings]="listTable">
            <div row1>

                <button type="button" mat-raised-button color="warn"
                    [disabled]="tableRef?.tableData?.length === 0"
                    (click)="unlinkAll()">
                    <mat-icon>delete</mat-icon>
                    Unlink all
                </button>

                <button type="button" mat-raised-button color="warn" (click)="addItem()">
                    <mat-icon>add</mat-icon>
                    Add new item
                </button>

            </div>
        </common-table2>
    </mat-card-content>
</mat-card>