import {AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {Subject} from "rxjs";
import {delay, filter} from "rxjs/operators";

@Directive({
    selector: "[observeVisibility]",
})
export class ObserveVisibilityDirective
    implements OnDestroy, OnInit, AfterViewInit {

    private observer: IntersectionObserver | undefined;

    private subject$: Subject<any> =
        new Subject<{ entry: IntersectionObserverEntry; observer: IntersectionObserver; }>();

    @Input()
    public debounceTime: number = 0;

    @Input()
    public emitOnce: boolean = true;

    @Input()
    public threshold: number = 1;

    @Output() visible: EventEmitter<any> = new EventEmitter<HTMLElement>();


    public constructor(private element: ElementRef) {
    }


    private isVisible(element: HTMLElement): Promise<any> {
        return new Promise(resolve => {
            const observer = new IntersectionObserver(([entry]) => {
                resolve(entry.intersectionRatio === 1);
                observer.disconnect();
            });

            observer.observe(element);
        });
    }

    private createObserver(): void {
        const options = {
            rootMargin: "0px",
            threshold: this.threshold,
        };

        const isIntersecting = (entry: IntersectionObserverEntry): boolean => {
            return entry.isIntersecting || entry.intersectionRatio > 0;
        };

        this.observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (isIntersecting(entry)) {
                    this.subject$.next({entry, observer});
                }
            });
        }, options);
    }

    private startObservingElements(): void {
        if (!this.observer) {
            return;
        }

        this.observer.observe(this.element.nativeElement);

        this.subject$.pipe(delay(this.debounceTime), filter(Boolean))
            .subscribe(async ({entry, observer}): Promise<any> => {
                const target = entry.target as HTMLElement;
                const isStillVisible = await this.isVisible(target);

                if (isStillVisible) {
                    this.visible.emit(target);
                    if (this.emitOnce) {
                        observer.unobserve(target);
                    }
                }
            });
    }


    public ngOnInit(): void {
        this.createObserver();
    }

    public ngAfterViewInit(): void {
        this.startObservingElements();
    }

    public ngOnDestroy(): void {
        if (this.observer) {
            this.observer.disconnect();
            this.observer = undefined;
        }

        this.subject$.next(null);
        this.subject$.complete();
    }

}