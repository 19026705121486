<mat-menu #appMenu="matMenu">
    <a mat-menu-item *ngIf="canGoToOrder"
       [routerLink]="['/partner', orderData.partner.slug, 'orders', 'view', 'ref', orderData.ref]">
        Show order
    </a>
    <a mat-menu-item (click)="showSidebar(true)">Remarks</a>
    <a mat-menu-item (click)="changeStatus()">Change status</a>
    <a mat-menu-item (click)="uploadWhTransactions()">Upload warehouse transactions</a>
</mat-menu>


<h1 class="container-heading">
    {{state.params.type | ucfirst}}
    <ng-template [ngIf]="orderData">
        <span class="pull-right text-right">
             <span *ngIf="orderData.partner">
                <img [src]="orderData.partner.icon_path" alt="" width="50" height="auto">
            </span>
            <br>
            <button mat-fab color="accent" [matMenuTriggerFor]="appMenu">
            <mat-icon>menu</mat-icon>
            </button>
        </span>
        <br>
        Ref: <b>{{orderData.ref}}</b>
        <br>
        Status: <b>{{ status ? status.name : ''}}</b>

    </ng-template>
</h1>

<mat-card>
    <mat-card-content>

        <h2 *ngIf="orderData">
            <ng-template [ngIf]="orderData.shipments && orderData.shipments.length > 0">
                <mat-icon>flight_takeoff</mat-icon>
                {{orderData.shipments[0].from_address.address}}
                {{orderData.shipments[0].from_address.city}}
                {{orderData.shipments[0].from_address.zip}}
                {{orderData.shipments[0].from_address.state}}
                {{orderData.shipments[0].from_address.country_iso_2}}
                <br>
                <mat-icon>flight_land</mat-icon>
                {{orderData.shipments[0].to_address.address}}
                {{orderData.shipments[0].to_address.city}}
                {{orderData.shipments[0].to_address.zip}}
                {{orderData.shipments[0].to_address.state}}
                {{orderData.shipments[0].to_address.country_iso_2}}
            </ng-template>
            <br>
            {{orderData.service_level ? orderData.service_level.name : ''}}
            <br>
            {{orderData.partner ? orderData.partner.display_name : ''}}
        </h2>

        <mat-horizontal-stepper #stepper [linear]="true" (selectionChange)="handleStepChanges($event)">

            <ng-template matStepperIcon="edit">
                <mat-icon>done</mat-icon>
            </ng-template>

            <ng-template matStepperIcon="done">
                <mat-icon>done_all</mat-icon>
            </ng-template>

            <ng-template matStepperIcon="number" let-active="active" let-index="index">
                <mat-icon *ngIf="active; else elseIcon">create</mat-icon>
                <ng-template #elseIcon>{{index + 1}}</ng-template>
            </ng-template>

            <ng-template ngFor [ngForOf]="steps" let-step let-i="index">
                <mat-step [editable]="steps[i].control.valid" [stepControl]="steps[i].control">
                    <ng-template matStepLabel>{{step.title}}</ng-template>

                    <div class="step-description" *ngIf="step.description">
                        <img *ngIf="step.icon" [src]="step.icon" alt="">
                        {{step.description}}
                    </div>

                    <ng-template wizard-component-factory [component]="step.component"
                                 (result)="handleStepEvent($event,i)">
                    </ng-template>

                    <div class="actions">
                        <button *ngIf="i > 0"
                                matStepperPrevious
                                mat-raised-button
                                type="button">
                            Prev
                        </button>
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        <button *ngIf="steps[i].control.valid && i < steps.length-1"
                                matStepperNext
                                mat-raised-button
                                color="primary"
                                type="button"
                                class="main">
                            Next
                        </button>
                        <button *ngIf="steps[i].control.valid && i === steps.length-1"
                                (click)="finish()"
                                mat-raised-button
                                color="primary"
                                type="button"
                                class="main">
                            Finish
                        </button>
                    </div>
                </mat-step>
            </ng-template>
        </mat-horizontal-stepper>
    </mat-card-content>
</mat-card>

<section-remarks-sidebar #sidebar></section-remarks-sidebar>
