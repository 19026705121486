import {ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {Modal} from "../../../../../../services/modal.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {Contact} from "../../../../../../../common/interfaces/contact.interface";


@Component({
    selector: "section-order-edit-address-modal",
    template: `

        <h1 class="container-heading">Edit order addresses</h1>

        <mat-card>
            <mat-card-content>
                <section-order-wizard-shipment-details
                    [state]="modal.params.state"
                    [values]="values"
                    (result)="formGroup.patchValue($event)"></section-order-wizard-shipment-details>

                <div class="padding-top-20">
                    <button type="button" [disabled]="!formGroup.valid" (click)="submit()"
                            mat-raised-button color="primary" class="main">
                        Submit
                    </button>

                    <button type="button" mat-raised-button (click)="modal.response.emit(null)">
                        Cancel
                    </button>
                </div>


            </mat-card-content>
        </mat-card>


    `,

    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class OrderEditAddressModalComponent implements OnInit {

    public formGroup: FormGroup = new FormGroup({
        address_id: new FormControl(null, [Validators.required]),
        address_billto_id: new FormControl(null),
        contact_address_id: new FormControl(null, [Validators.required]),
        contact_address_billto_id: new FormControl(null),
        customer_id: new FormControl(null, [Validators.required])

    });

    public modal: Modal.IModal;

    public values: Modal.IResponse = null;

    public constructor(
        private toastService: ToastService,
        private apiService: ApiService,
        private spinnerService: SpinnerService
    ) {
    }

    public async submit(): Promise<any> {
        this.spinnerService.show();

        const {message, code}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["order", this.modal.params.orderData.id, "shipping_info"], this.formGroup.value);

        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.modal.response.emit({name: "result", value: "order updated"});

        }
    }

    public ngOnInit(): void {
        if (this.modal.params.addresses) {

            const value: any = {};

            const mainAddress: Contact.IAddress = this.modal.params.addresses
                .find((address: Contact.IAddress): boolean => address.address_type === "main");

            if (mainAddress) {
                value.customer = {
                    controlValue: mainAddress.customer.id,
                    viewValue: mainAddress.customer.name
                };
                value.address = {
                    controlValue: mainAddress.id,
                    viewValue: mainAddress.address + " " + mainAddress.city + " " + mainAddress.country,
                };

                if (this.modal.params.orderData.main_contact) {
                    value.contact = {
                        controlValue: this.modal.params.orderData.main_contact.id,
                        viewValue: this.modal.params.orderData.main_contact.first_name
                            + " " + this.modal.params.orderData.main_contact.last_name
                            + (this.modal.params.orderData.main_contact.email
                                ? " - " + this.modal.params.orderData.main_contact.email : "")
                    };
                } else {
                    value.contact = {
                        controlValue: null,
                        viewValue: null
                    };
                }
            }

            const billAddress: Contact.IAddress = this.modal.params.addresses
                .find((address: Contact.IAddress): boolean => address && address.address_type === "Bill To");

            if (billAddress) {
                value.address_bill = {
                    controlValue: billAddress.id,
                    viewValue: billAddress.address
                };

                if (this.modal.params.orderData.main_bill_contact) {
                    value.contact_bill = {
                        controlValue: this.modal.params.orderData.main_bill_contact.id,
                        viewValue: this.modal.params.orderData.main_bill_contact.first_name
                            + " " + this.modal.params.orderData.main_bill_contact.last_name
                            + (this.modal.params.orderData.main_bill_contact.email ?
                                " - " + this.modal.params.orderData.main_bill_contact.email : "")
                    };
                } else {
                    value.contact_bill = {
                        controlValue: null,
                        viewValue: null
                    };
                }
            }

            this.values = {
                name: "value",
                value
            };
        }
    }
}

