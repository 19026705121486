import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from "@angular/core";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {ContactFormComponent} from "../index";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {ToastService} from "../../../../../../common/services/toast.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {Table2Component} from "../../../../../../common/components/table2/table.component";

@Component({
    selector: "section-contact-list",
    template: `
        <common-confirm></common-confirm>

        <h1 class="container-heading">Contacts list</h1>
        <mat-card>
            <mat-card-content>
                <common-table2 *ngIf="listTable" [settings]="listTable">
                    <div row1>
                        <button mat-raised-button color="primary" class="main" (click)="showAddForm()">Add</button>
                    </div>
                </common-table2>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactListComponent implements OnInit {

    public listTable: Table.ISettings;

    public modal: Modal.IModal;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    public constructor(
        private apiService: ApiService,
        private modalService: ModalService,
        private toastService: ToastService,
        private spinnerService: SpinnerService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    private getUrl(): Table.ITableApi {
        const query: any = {};
        if (this.modal && this.modal.params) {
            if (this.modal.params.address) {
                query["address_id"] = this.modal.params.address;
            }
            if (this.modal.params.customer) {
                query["customer_id"] = this.modal.params.customer;
            }
        }


        return {
            url: ["contact"],
            query
        };
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        const actions: Table.IAction[] = [];

        if (this.modal.params) {
            if (this.modal.params.canEdit) {
                actions.push({
                    name: "view",
                    title: "View",
                    click: (row: any): void => {
                        this.showEditForm(row.id);
                    }
                });
            }

            actions.push({
                name: "select",
                title: "Select",
                click: (row: any): void => {
                    this.modal.response.next({
                        name: "value",
                        value: {
                            controlValue: row.id,
                            viewValue: row.first_name + " " + row.last_name
                                + (row.email ? " - " + row.email : "")
                        }
                    });
                }
            });

            if (this.modal.params.canDelete) {
                actions.push({
                    name: "delete",
                    title: "Delete",
                    click: (row: any): void => {
                        this.delete(row);
                    }
                });
            }
        }

        this.listTable = {
            table_id: "9cnr89yslr",
            actions,
            api: this.getUrl(),
            columns: [
                {
                    data: "first_name",
                    title: "First name"
                },
                {
                    data: "last_name",
                    title: "Last name"
                },
                {
                    data: "email",
                    title: "Email"
                },
                {
                    data: "phone",
                    title: "Phone"
                },
                {
                    data: "mobile_phone",
                    title: "Mobile phone"
                },
                {
                    data: "author.name",
                    name: "Author.name",
                    title: "Created/Changed by"
                }
            ],
        };
        this.changeDetectorRef.markForCheck();
    }


    /**
     * Show contact form
     * @param id
     */
    private async showEditForm(id: number): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(ContactFormComponent, {
            data: {id},
            action: "edit",
            getData: true,
            canDelete: true
        });
        if (response) {
            this.tableRef.reload();
        }
    }

    /**
     * Delete contact
     * @param contact
     */
    public async delete(contact: any): Promise<any> {
        if (await this.confirmRef.confirm("Are you sure want to delete current contact?")) {
            this.spinnerService.show();
            const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
                ["contact", "" + contact.id], {
                    exclude_instance: "address",
                    exclude_instance_id: this.modal.params.address
                });
            if (type as string === "success") {
                this.toastService.show(message, "success");
                this.tableRef.reload();
            }
            this.spinnerService.hide();
        }
    }

    /**
     * Show add new address form
     * @returns {void}
     */
    public async showAddForm(): Promise<any> {
        if (this.modal.params.canEdit) {
            const response: Modal.IResponse = await this.modalService.open(ContactFormComponent, {
                action: "add",
                address: this.modal.params.address
            });
            if (response) {
                this.tableRef.reload();
            }
        } else {
            this.modal.response.next({
                name: "form"
            });
        }
    }

    public ngOnInit(): void {
        this.prepareList();
    }

}
