import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {FormControl} from "@angular/forms";
import {Modal} from "../../../section/services/modal.service";
import {Api, ApiService} from "../../services/api.service";
import {ToastService} from "../../services/toast.service";
import {PusherService} from "../../services/pusher.service";
import {SpinnerService} from "../../services/spinner.service";

@Component({
    selector: "common-status-form",
    template: `

        <h1 class="container-heading">Change status</h1>
        <mat-card>
            <mat-card-content>
                <div class="form">
                    <div class="group">
                        <mat-form-field class="full">
                            <mat-label>Status</mat-label>
<mat-select  [formControl]="status">
                                <mat-option *ngFor="let status of statuses" [value]="status.value">
                                    {{ status.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="actions">
                        <button type="button" mat-raised-button color="primary"
                                (click)="submit()" class="main">
                            Update
                        </button>
                        <button type="button" color="warn" mat-raised-button (click)="cancel()">Cancel</button>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class ChangeStatusFormComponent implements OnInit {

    public modal: Modal.IModal;

    public statuses: { name: string, value: string }[] = [];

    public status: FormControl = new FormControl([null]);

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private toastService: ToastService,
        private pusherService: PusherService,
        private spinnerService: SpinnerService
    ) {
    }

    private async getStatuses(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["status"], {}, {
            data_structure: "select",
            type: this.modal.params.type
        });
        if (data) {
            this.statuses = data;
            this.status.setValue(Number(this.modal.params.status));
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Submit new status
     * @returns {Promise<any>}
     */
    public async submit(): Promise<any> {
        this.spinnerService.show();

        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Put, ["status"],
            [
                {
                    status_id: this.status.value,
                    id: this.modal.params.id
                }
            ], {
                type: this.modal.params.type
            });
        if (response) {
            this.toastService.show(response.message, response.type as string);
            if (response.type as string === "success") {
                this.modal.response.next({
                    name: "value",
                    value: response.data
                });
            }
        }
        this.spinnerService.hide();
    }

    public cancel(): void {
        this.modal.response.next(null);
    }


    public ngOnInit(): void {
        this.getStatuses();
    }
}
