import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: "join"
})
export class JoinPipe implements PipeTransform {
    public transform(value: string | string[], connector: string): string {
        if (Array.isArray(value)) {
            return value.join(connector);
        } else {
            return value;
        }
    }
}
