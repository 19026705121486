import {ChangeDetectionStrategy, ChangeDetectorRef,
    Component, OnDestroy, ViewEncapsulation} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../common/interfaces/wizard.interface";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {HelpersService} from "../../../../../../../common/services/helpers.service";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {ModalService} from "../../../../../../services/modal.service";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {debounceTime, takeUntil} from "rxjs/operators";
import {Warehouse} from "../../../../../../../common/interfaces/warehouse.interface";
import {ENTER} from "@angular/cdk/keycodes";
import {MatChipInputEvent} from "@angular/material/chips";
import { Api3Service } from "src/modules/common/services/api3.service";
import { PartnerService } from "src/modules/common/services/partner.service";

@Component({
    selector: "section-partner-procedures-wizard-pallet-ref-change",
    templateUrl: "pallet-ref-change.component.html",
    styles: [
        `
                     section-partner-procedures-wizard-remove-serial .available-serial-list {
                         max-height: 500px;
                         overflow: auto;
                         padding: 5px;
                     }

                     section-partner-procedures-wizard-remove-serial .available-serial-list .mat-chip-list-wrapper {
                         justify-content: space-evenly;
                     }

                     section-partner-procedures-wizard-remove-serial .available-serial-list .mat-chip,
                     section-partner-procedures-wizard-remove-serial .available-serial-list
                     .mat-chip:not(.mat-basic-chip) + .mat-chip:not(.mat-basic-chip) {
                         margin: 0 2px 4px !important;
                         font-family: monospace;
                         cursor: pointer;
                     }
                 `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class PartnerProceduresWizardPalletRefChangeComponent extends AbstractWizardStepComponent implements OnDestroy {

    public form: FormGroup = new FormGroup({
        pallet_id: new FormControl(null, [Validators.required]),
        ref: new FormControl("", [Validators.required]),
        remark: new FormControl(null, [Validators.required]),
    });

    public pallets: {name: string, value: any}[] = [];

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        public helperService: HelpersService,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private modalService: ModalService,
        private spinnerService: SpinnerService
    ) {
        super(changeDetectorRef);
    }

    public async getPallets(): Promise<any> {
        this.spinnerService.show();
        const {data, message}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `partner/${PartnerService.partner.slug}/pallets`, {}, {
                only_inventory: true
            });
        if (data) { 
            this.pallets = data.map((item: any) => {
                return {name: item.ref, value: item.id};
            });
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Submit form
     */
    public async submit(): Promise<any> {
        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["inventory", "pallet-ref-change"], this.form.value);
        if (code === 200) {
            this.toastService.show(message, "success");
            this.form.reset();
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Initialize step
     * @param data
     * @returns {Promise<any>}
     */
    public async init(data: Wizard.IData): Promise<any> {
        this.result.emit({
            action: "result",
            value: true
        });

        this.getPallets();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
