<div class="fields">
    <div *ngFor="let field of fields; let i = index">

        <common-form-builder-group
                *ngIf="field.type === 'group'"
                [index]="i"
                [options]="field"
                (onChanges)="onChanges(i,$event)"
                (onMoveDown)="onMoveDown(i)"
                (onMoveUp)="onMoveUp(i)"
                (onMoveTo)="onMoveTo(i,$event)"
                (onDelete)="onDelete(i)"
        ></common-form-builder-group>


        <common-form-builder-field
                *ngIf="field.type !== 'group'"
                [index]="i"
                [options]="field"
                (onChanges)="onChanges(i,$event)"
                (onMoveDown)="onMoveDown(i)"
                (onMoveUp)="onMoveUp(i)"
                (onMoveTo)="onMoveTo(i,$event)"
                (onDelete)="onDelete(i)"
        ></common-form-builder-field>
    </div>


    <div class="actions full">
        <button (click)="addField()"
                type="button" mat-raised-button color="primary">
            Add field
        </button>
        &nbsp;
        <button (click)="addGroup()" type="button" mat-raised-button
                color="accent">
            Add group
        </button>
    </div>


</div>
