import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Table, TableComponent} from "../../../../../../common/components/table/table.component";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Router} from "@angular/router";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {SpinnerService} from "../../../../../../common/services/spinner.service";

@Component({
    selector: "section-stock-inventory-all-list",
    template: `
        <common-confirm></common-confirm>

        <h1 class="container-heading">Stock list based on inventory</h1>
        <mat-card>
            <mat-card-content>
                <common-table *ngIf="listTable" [options]="listTable" (action)="handleListAction($event)"
                              (onDtEvent)="onDtEvent($event)">
                    <div data-actions>
                        <ng-template [ngIf]="showExportButton">
                            <a mat-raised-button color="primary" class="create"
                               (click)="exportCsv()">Export CSV</a>
                        </ng-template>
                    </div>
                </common-table>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StockInventoryAllListComponent implements Base.IComponent, OnInit {

    public readonly state: Base.IState;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild(TableComponent, {static: false})
    public tableRef: TableComponent;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.IOptions;

    public showExportButton: boolean = false;

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private spinnerService: SpinnerService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        const actions: Table.Action.ISettings[] = [];

        const [type, slug]: string[] = this.state.section.split("/");

        const columns: DataTables.ColumnSettings[] = [];

        if (type === "threepl" || type === "warehouse") {
            columns.push({
                data: "display_name",
                title: "Partner"
            });
            columns.push({
                data: "warehouse_slug",
                title: "Warehouse"
            });
            columns.push({
                data: "sub_inventory",
                title: "Sub-inventory"
            });
        } else {
            columns.push({
                data: "customers_inventory_name",
                title: "Hub"
            });
            columns.push({
                data: "customers_sub_inventory",
                title: "Hub sub-inventory"
            });
        }

        columns.push({
            data: "gb",
            title: "GB"
        });
        columns.push({
            data: "inventory_key",
            title: "Inventory key",
            searchable: false,
            render: (data: string): string => {
                return "<button class='tableAction mat-mdc-raised-button' data-action='inventory' type='button'>"
                    + data
                    + "</button>";
            }
        });
        columns.push({
            data: "item",
            title: "Item"
        });
        columns.push({
            data: "description",
            title: "Description"
        });
        columns.push({
            data: "serial",
            title: "Serial"
        });
        columns.push({
            data: "configurations",
            title: "Configurations",
            render: (data: any, action: any, row: any): string => {
                return HelpersService.getConfigNames(row);
            }
        });
        columns.push({
            data: "total_in_inv",
            title: "In inventory",
            searchable: false
        });
        columns.push({
            data: "balance_stock",
            title: "On hand",
            searchable: false
        });
        columns.push({
            data: "gap_stock",
            title: "Inventory Gap",
            searchable: false
        });
        columns.push({
            data: "total_stock",
            title: "Inbounded",
            searchable: false
        });
        columns.push({
            data: "total_sent",
            title: "Outbounded",
            searchable: false
        });

        this.listTable = {
            actions,
            api: this.apiService.getUrl(["inventory", "based_on_transaction"])
                + "?data_structure=dataTables&based_on=inventory",
            columns,
            order: [],
            search: true
        };
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Handle list row action
     * @param {Table.Action.IResult} action
     * @returns {Promise<any>}
     */
    public async handleListAction(action: Table.Action.IResult): Promise<any> {
        if (action.name === "inventory") {
            this.router.navigate([
                this.state.section, "inventory", "view", "key", action.data.inventory_key,
                "backTo", this.state.component
            ]);
        }
    }

    public async exportCsv(): Promise<any> {
        this.spinnerService.show();
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["inventory", "based_on_transaction"]);
        this.spinnerService.hide();
        if (!response.data) {
            this.toastService.show(response.message, response.type as string);
            return;
        }
        const toCsv: { [key: string]: string }[] = [];

        const [type, slug]: string[] = this.state.section.split("/");

        for (const i in response.data) {
            if (!response.data.hasOwnProperty(i)) {
                continue;
            }
            const row: any = response.data[i];

            const csvObj: any = {};
            if (type === "threepl" || type === "warehouse") {
                csvObj["Partner"] = "" + row.display_name;
            }
            csvObj["Warehouse"] = "" + row.warehouse_slug;
            csvObj["Sub-inventory"] = "" + row.sub_inventory;
            if (type === "partner") {
                csvObj["Hub"] = "" + row.customers_inventory_name;
                csvObj["Hub sub-inventory"] = "" + row.customers_sub_inventory;
            }
            csvObj["Gb"] = "" + row.gb;
            csvObj["Inventory key"] = "" + row.inventory_key;
            csvObj["Item"] = "" + row.item;
            csvObj["Description"] = "" + row.description;
            csvObj["Serial"] = "" + row.serial;
            csvObj["Configurations"] = "" + row.configurations;
            csvObj["In inventory"] = "" + row.TotalInInv;
            csvObj["On hand"] = "" + row.BalanceStock;
            csvObj["Inventory Gap"] = "" + row.GapStock;
            csvObj["Inbounded"] = "" + row.TotalStock;
            csvObj["Outbounded"] = "" + row.TotalSent;

            toCsv.push(csvObj);
        }

        HelpersService.exportCsv(toCsv, "Stock list based on transaction");
    }

    public onDtEvent(event: Table.IDtEvent): void {
        this.showExportButton = event.hasData;
    }

    public ngOnInit(): void {
        this.prepareList();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "inventory-stock-all",
            actions: {
                "browse": ["browse_warehouse_transactions"]
            }
        };
    }

}
