import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {ModalService} from "../../../../../../../services/modal.service";
import {Api, ApiService} from "../../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../../common/services/toast.service";
import {UserService} from "../../../../../../../../common/services/user.service";
import {StorageService} from "../../../../../../../../common/services/storage.service";
import {HelpersService} from "../../../../../../../../common/services/helpers.service";
import {AbstractOrderItemComponent} from "../../../item/item";
import {SpinnerService} from "../../../../../../../../common/services/spinner.service";
import {FormControl} from "@angular/forms";
import {takeUntil} from "rxjs/operators";
import {Api3Service} from "../../../../../../../../common/services/api3.service";

@Component({
    selector: "section-order-wizard-item-transfer",
    templateUrl: "../../../item/item.component.html",
    styleUrls: [
        "../../../item/item.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class OrderWizardItemTransferComponent extends AbstractOrderItemComponent implements OnInit {

    public itemType: string = "outbound";

    public formType: string = "transfer";

    /**
     * Options (for item & hub selectors)
     */
    public options: any = {
        items: [],
    };

    public transferDirection: FormControl = new FormControl("both");


    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        protected apiService: ApiService,
        protected api3Service: Api3Service,
        protected toastService: ToastService,
        protected modalService: ModalService,
        protected userService: UserService,
        protected storageService: StorageService,
        protected spinnerService: SpinnerService
    ) {
        super(changeDetectorRef, apiService, api3Service, toastService, modalService, userService, storageService,
            spinnerService);
    }

    private setValidators(transferType: string): void {
        this.formGroup.get("inbound_hub_id").setValue(null);
        this.formGroup.get("outbound_hub_id").setValue(null);

        this.formGroup.get("inbound_hub_id").setValidators(this.hubValidators);
        this.formGroup.get("outbound_hub_id").setValidators(this.hubValidators);

        if (transferType === "outbound") {
            this.formGroup.get("inbound_hub_id").clearValidators();
        } else if (transferType === "inbound") {
            this.formGroup.get("outbound_hub_id").clearValidators();
        }

        this.formGroup.get("inbound_hub_id").updateValueAndValidity();
        this.formGroup.get("outbound_hub_id").updateValueAndValidity();

        this.changeDetectorRef.markForCheck();
    }


    protected prepareFormListeners(): void {
        super.prepareFormListeners();
        this.transferDirection.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: string): void => {
            if (value === "inbound") {
                this.itemType = "inbound";
            } else {
                this.itemType = "outbound";
            }
            this.setValidators(value);
        });

        this.setSerialValidation();
    }


    public showOutboundHubSelect(): boolean {
        return this.options.hubs_from && ["both", "outbound"].includes(this.transferDirection.value);
    }

    public showInboundHubSelect(): boolean {
        return this.options.hubs_to && ["both", "inbound"].includes(this.transferDirection.value);
    }

    /**
     * Add item (prepare & add modal response)
     */
    public async save(): Promise<any> {
        this.spinnerService.show();

        const item_key = HelpersService.randomString();

        const {code, message}: Api.IResponse = await this.api3Service.post(
            `${this.modal.params.state.section}/order-items`, {
                items: [
                    {
                        ...this.formGroup.value,
                        hub_id: this.formGroup.value.outbound_hub_id,
                        order_id: this.modal.params.order_id,
                        type: "outbound",
                        item_key
                    },
                    {
                        ...this.formGroup.value,
                        hub_id: this.formGroup.value.inbound_hub_id,
                        order_id: this.modal.params.order_id,
                        type: "inbound",
                        item_key
                    }
                ]
            });

        this.spinnerService.hide();

        this.modal.response.next({
            name: "value",
            value: true
        });

        if (code === 200) {
            this.toastService.show(message, "success");
        }
    }

    public formReset(): void {
        super.formReset();
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this.options.hubs_from = this.modal.params.hubs_from;
        this.options.hubs_to = this.modal.params.hubs_to;

        this.changeDetectorRef.markForCheck();
    }
}
