import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Observable} from "rxjs";
import {GoogleMapsApiService} from "../../services/google-maps-api.service";

export namespace GMap {
    export interface IMarker {
        lat: number;
        lng: number;
        iconUrl?: string;
        data?: any;
    }
}


@Component({
    selector: "common-gmap",
    template: `
        <ng-container *ngIf="apiLoaded | async">
            <google-map [options]="options" [height]="height" width="100%">
                <ng-template ngFor let-marker [ngForOf]="markers">
                    <map-marker
                            [position]="{lat: marker.lat, lng: marker.lng}"
                            [options]="{icon: {url:marker.iconUrl || '/assets/images/icons/marker-red.png'}}"
                            [clickable]="true"
                            (mapClick)="onMarkerClick(marker)"
                    ></map-marker>
                </ng-template>
            </google-map>
        </ng-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GmapComponent implements OnInit {

    @Input()
    public center: { lat: number, lng: number };

    @Input()
    public scrollWheel: boolean = true;

    @Input()
    public markers: GMap.IMarker[] = [];

    @Input()
    public mapStyles: any[] = [
        {
            "featureType": "all",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#000000"
                }
            ]
        },
        {
            "featureType": "all",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "administrative.province",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "all",
            "stylers": [
                {
                    "saturation": "-39"
                },
                {
                    "lightness": "35"
                },
                {
                    "gamma": "1.08"
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "geometry",
            "stylers": [
                {
                    "saturation": "0"
                }
            ]
        },
        {
            "featureType": "landscape.man_made",
            "elementType": "all",
            "stylers": [
                {
                    "saturation": "-100"
                },
                {
                    "lightness": "10"
                }
            ]
        },
        {
            "featureType": "landscape.man_made",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "saturation": "-100"
                },
                {
                    "lightness": "-14"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "all",
            "stylers": [
                {
                    "saturation": "-100"
                },
                {
                    "lightness": "10"
                },
                {
                    "gamma": "2.26"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "labels.text",
            "stylers": [
                {
                    "saturation": "-100"
                },
                {
                    "lightness": "-3"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "all",
            "stylers": [
                {
                    "saturation": "-100"
                },
                {
                    "lightness": "54"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "saturation": "-100"
                },
                {
                    "lightness": "-7"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "all",
            "stylers": [
                {
                    "saturation": "-100"
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "all",
            "stylers": [
                {
                    "saturation": "-100"
                },
                {
                    "lightness": "-2"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "all",
            "stylers": [
                {
                    "saturation": "-100"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "saturation": "-100"
                },
                {
                    "lightness": "100"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "saturation": "-100"
                },
                {
                    "lightness": "-100"
                }
            ]
        }
    ];

    @Input()
    public zoom: number = 10;

    @Input()
    public maxZoom: number = null;

    @Input()
    public height: number = 300;

    @Output()
    public markerClick: EventEmitter<any> = new EventEmitter<any>();

    public options: google.maps.MapOptions;

    public apiLoaded: Observable<any>;

    public constructor(private googleMapsApiService: GoogleMapsApiService) {

    }

    public onMarkerClick(marker: GMap.IMarker): void {
        this.markerClick.emit(marker);
    }

    public ngOnInit(): void {
        this.options = {
            center: this.center,
            zoom: this.zoom,
            maxZoom: this.maxZoom,
            scrollwheel: this.scrollWheel,
            gestureHandling: "cooperative",
            disableDefaultUI: true,
            streetViewControl: false,
            fullscreenControl: true,
            zoomControl: true,
            styles: this.mapStyles,
        };

        this.apiLoaded = this.googleMapsApiService.load();
    }


}
