import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {UntypedFormBuilder, FormControl} from "@angular/forms";
import {Router} from "@angular/router";
import {ConfirmComponent} from "../../../../../../../common/components/confirm/confirm.component";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";

import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {DragulaService} from "ng2-dragula";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {Modal, ModalService} from "../../../../../../services/modal.service";
import {WarehouseTransactionFormComponent, WarehouseTransactionSerialComponent} from "../../index";
import {Warehouse} from "../../../../../../../common/interfaces/warehouse.interface";
import {Order} from "../../../../../../../common/interfaces/order.interface";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../common/interfaces/wizard.interface";
import {debounceTime, takeUntil} from "rxjs/operators";
import {IPagination} from "../../../../../../../common/components/pagination/pagination.component";
import {HelpersService} from "../../../../../../../common/services/helpers.service";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {UserService} from "../../../../../../../common/services/user.service";


@Component({
    selector: "section-warehouse-order-wizard-warehouse-transactions",
    templateUrl: "warehouse-transactions.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class WarehouseOrderWizardWarehouseTransactionsComponent extends AbstractWizardStepComponent
    implements OnDestroy {

    private state: Base.IState;

    private boundType: string;

    private order: any;

    private trackingData: any;


    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    public parcel: Warehouse.IParcel;

    public items: IPagination<Order.IItem>;

    public itemSearch: FormControl = new FormControl(null);

    public transactions: IPagination<Warehouse.ITransaction>;

    public transactionSearch: FormControl = new FormControl(null);

    /**
     * Randomize bag name to avoid duplication when we have
     * two ore more component instances
     */
    public dragulaBagName: string;

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private dragulaService: DragulaService,
        private modalService: ModalService,
        private spinnerService: SpinnerService,
        private userService: UserService
    ) {
        super(changeDetectorRef);

        this.dragulaBagName = "warehouse-items-bag-" + HelpersService.randomString();
        if (!dragulaService.find(this.dragulaBagName)) {
            dragulaService.createGroup(this.dragulaBagName, {
                copy: true,
                copyItem: (item: any): any => ({...item})
            });
        }
        dragulaService.drop(this.dragulaBagName).pipe(takeUntil(this.destroy$))
            .subscribe((value: any): void => {
                this.onDrop(value);
            });
    }

    /**
     * Handle drop event
     * @returns {Promise<any>}
     */
    private async onDrop(value: any): Promise<any> {
        const {name, el, target}: any = value;
        if (name !== this.dragulaBagName) {
            return;
        }
        this.dragulaService.find(this.dragulaBagName).drake.cancel(true);
        let transactionId: string = null;
        if (el) {
            el.classList.forEach((className: string): void => {
                if (/id-[0-9]+/.test(className)) {
                    transactionId = className.replace("id-", "");
                }
            });
        }
        let targetId: number = null;
        if (target) {
            target.classList.forEach((className: string): void => {
                if (/id-[0-9]+/.test(className)) {
                    targetId = Number(className.replace("id-", ""));
                }
            });
        }

        if (targetId && transactionId && !await this.dropCheck(targetId, Number(transactionId))) {
            return;
        }
        if (target !== null && transactionId) {
            this.spinnerService.show();
            const response: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
                ["transaction", transactionId, "link_order_item"],
                {order_item_id: targetId});

            this.getParcelItems();
            this.getOrderTransactions();
            this.spinnerService.hide();
            this.toastService.show(response.message, response.type as string);
        }
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Check item & transaction are mach
     * @param {number} itemId
     * @param {number} transactionId
     * @returns {Promise<any>}
     */
    private async dropCheck(itemId: number, transactionId: number): Promise<any> {
        const item: Order.IItem = this.getItemById(itemId);
        const transaction: Warehouse.ITransaction = this.getTransactionById(transactionId);

        let itemQty: number = item.quantity;
        for (const wh_tr of item.warehouse_transactions_paginated.data) {
            itemQty -= wh_tr.quantity;
        }

        const isSameItem: boolean = item.item === transaction.item;
        const isSameSubWarehouse: boolean =
            item.inventory_conversion.sub_inventory === transaction.inventory_conversion.sub_inventory;

        if (!isSameItem || !isSameSubWarehouse || itemQty < transaction.quantity) {

            const warn1: string = !isSameItem ? "color-warn" : "";
            const warn2: string = !isSameSubWarehouse ? "color-warn" : "";
            const warn3: string = itemQty < transaction.quantity ? "color-warn" : "";

            return await this.confirmRef.confirm(`
                <table class="table">
                    <tr>
                        <th>Item</th>
                        <th>Transaction</th>
                    </tr>
                    <tr>
                        <td>Item<div>${item.item}</div></td>
                        <td>Item<div class="${warn1}">${transaction.item}</div></td>
                    </tr>
                     <tr>
                        <td>Sub-inventory<div>${item.inventory_conversion.sub_inventory}</div></td>
                        <td>Sub-inventory
                            <div class="${warn2}">${transaction.inventory_conversion.sub_inventory}</div></td>
                    </tr>
                    <tr>
                        <td>Quantity<div>${itemQty}</div></td>
                        <td>Quantity<div class="${warn3}">${transaction.quantity}</div></td>
                    </tr>
                </table>
                <p><b>Do you want to proceed?</b></p>
                `);
        }
        return true;
    }

    private getTransactionById(id: number): Warehouse.ITransaction {
        for (const transaction of this.transactions.data) {
            if (id === transaction.id) {
                return transaction;
            }
        }

        for (const item of this.items.data) {
            for (const transaction of item.warehouse_transactions_paginated.data) {
                if (id === transaction.id) {
                    return transaction;
                }
            }
        }
        return null;
    }

    private getItemById(id: number): Order.IItem {
        for (const item of this.items.data) {
            if (id === item.id) {
                return item;
            }
        }
        return null;
    }

    private async getParcel(id: number): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["parcel", "" + id]);
        this.spinnerService.hide();
        if (data) {
            this.parcel = data;
            this.getParcelItems().then((): void => {
                this.getOrderTransactions();
            });
            if (this.state.params.type === "rma") {
                this.getShipment();
            }
            this.changeDetectorRef.markForCheck();
        }

    }

    private async getParcelItems(page: number = 1, search_by: string = null, per_page: number = null):
        Promise<any> {
        this.spinnerService.show();
        if (per_page === null) {
            per_page = this.userService.data.settings.default_per_page;
        }
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["parcel", "" + this.parcel.id, "items"], {}, {
                data_structure: "paginated",
                page,
                per_page,
                search_by
            });
        this.spinnerService.hide();
        if (data) {
            this.items = data;
            if (this.items.data.length > 0) {
                if (this.state.params.type === "transfer") {
                    this.boundType = this.items.data[0].type;
                }
                for (const item of this.items.data) {
                    this.getItemTransactions(item);
                }
            }

            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Get shipment
     * @returns {Promise<any>}
     */
    private async getShipment(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["shipment", "" + this.parcel.shipment_id]);
        this.spinnerService.hide();
        if (data) {
            this.boundType = data.type;
        }
    }

    public async getItemTransactions(item: Order.IItem, page: number = 1, per_page: number = null): Promise<any> {
        this.spinnerService.show();
        if (per_page === null) {
            per_page = this.userService.data.settings.default_per_page;
        }
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["order_item", "transaction", "" + item.id], {}, {
                data_structure: "paginated",
                page,
                per_page
            });
        this.spinnerService.hide();
        if (data) {
            item.warehouse_transactions_paginated = data;
            if (item.warehouse_transactions_paginated.data && item.warehouse_transactions_paginated.data.length > 0) {
                this.result.next({
                    action: "result",
                    value: {
                        shipmentId: this.parcel.shipment_id
                    }
                });
            }
            this.changeDetectorRef.markForCheck();
        }

    }

    public async getOrderTransactions(page: number = 1, search_by: string = null): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["transaction", "order", this.order.id], {}, {
                linked_to_order_item: false,
                type: this.boundType,
                data_structure: "paginated",
                page,
                search_by
            });
        if (data) {
            this.transactions = data;
        }
        this.spinnerService.hide();
    }

    /**
     * Initialize details step
     * @param data
     * @returns {Promise<any>}
     */
    public async init(data: Wizard.IData): Promise<any> {
        this.state = data.state;

        switch (data.state.params.type) {
            case "replenishment":
                this.boundType = "inbound";
                break;
            case "sales":
                this.boundType = "outbound";
                break;
            default:
                break;
        }
        this.order = data.orderData;
        this.getParcel(data.parcelId);

        this.itemSearch.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: string): void => {
                this.getParcelItems(1, value);
            });

        this.transactionSearch.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: string): void => {
                this.getOrderTransactions(1, value);
            });

        this.changeDetectorRef.markForCheck();
    }

    public async addTransaction(action: string, data?: any): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(WarehouseTransactionFormComponent, {
            action,
            order_id: this.order.id,
            type: this.boundType,
            modalWidth: 600,
            data,
            partner: this.order.partner,
            state: this.state
        });

        if (response) {
            this.getParcelItems();
            this.getOrderTransactions();
            this.trackingData = response.value ? response.value : this.trackingData;
            this.changeDetectorRef.markForCheck();
        }
    }

    public async addBySerial(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(WarehouseTransactionSerialComponent, {
            modalWidth: 600
        });
        if (response && response.value) {
            this.addTransaction("add", response.value);
        }
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

}
