<mat-card>
    <mat-card-content>
    <form [formGroup]="formGroup">
        <div class="group flex">
            <div class="full">
                <h1>New Pallet</h1>
            </div>
            
            <mat-form-field class="full">
                <mat-label>Pallet Ref</mat-label>
                <input matInput formControlName="ref">
            </mat-form-field>

            <mat-form-field class="full">
                <mat-label>Customers Inventory Name</mat-label>
                <mat-select formControlName="hub_id">
                    <mat-option>
                        <ngx-mat-select-search [formControl]="hubsSearch"
                                                [placeholderLabel]="'Search'"
                                                [clearSearchInput]="true"
                                                [noEntriesFoundLabel]="'nothing found'">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let hub of hubsFiltered" [value]="hub.id">
                        {{hub.customers_inventory_name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="full">
                <mat-label>Location</mat-label>
                <mat-select formControlName="warehouse_location_id">
                    <mat-option>
                        <ngx-mat-select-search [formControl]="locationsSearch"
                                                [placeholderLabel]="'Search'"
                                                [clearSearchInput]="true"
                                                [noEntriesFoundLabel]="'nothing found'">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let location of locationsFiltered" [value]="location.value">
                        {{location.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="actions">
            <button mat-raised-button type="submit" color="primary"
                [disabled]="!formGroup.valid" (click)="submit()">
                    Save
            </button>
            <button mat-raised-button type="button" (click)="close()">
                Cancel
            </button>
        </div>
    </form>
    </mat-card-content>
</mat-card>