import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {UntypedFormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../common/services/api.service";
import {ToastService} from "../../../../../common/services/toast.service";
import {ConfirmComponent} from "../../../../../common/components/confirm/confirm.component";
import {IPagination} from "../../../../../common/components/pagination/pagination.component";
import {debounceTime, distinctUntilChanged, takeUntil} from "rxjs/operators";
import {SpinnerService} from "../../../../../common/services/spinner.service";
import {CourierService} from "../../../../../common/services/courier.service";
import {Base} from "../../../../../common/interfaces/base.interfaces";
import {UserService} from "../../../../../common/services/user.service";
import { HelpersService } from "src/modules/common/services/helpers.service";

@Component({
    selector: "courier-services-conversions",
    templateUrl: "list.component.html",
    styleUrls: [
        "list.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class CourierServicesConversionsComponent implements OnDestroy, OnInit, Base.IComponent {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    public conversions: any[] = [];

    public readonly state: Base.IState;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    public newForm: FormGroup = new FormGroup({
        name: new FormControl(null, [Validators.required]),
        courier_id: new FormControl(null, [Validators.required]),
        courier_service_id: new FormControl(null, [Validators.required]),
        direction: new FormControl(null, [Validators.required]),
        country: new FormControl(null),
    });

    public itemForm: FormGroup = new FormGroup({
        id: new FormControl(null, [Validators.required]),
        name: new FormControl(null, [Validators.required]),
        courier_id: new FormControl(null, [Validators.required]),
        courier_service_id: new FormControl(null, [Validators.required]),
        direction: new FormControl(null, [Validators.required]),
        country: new FormControl(null),
    });

    public items: FormGroup[] = [];

    public couriers: { value: any, name: string }[] = [];

    public couriers_services: { new: { value: number, name: string }[], item: { value: number, name: string }[] } =
        {new: [], item: []};

    public conversionSearch: FormControl = new FormControl(null);

    public directionOptions = ["receive", "send", "both"];

    public countries: any[] = [];

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private toastService: ToastService,
        private courierService: CourierService,
        private spinnerService: SpinnerService,
        private userService: UserService,
        private helpers: HelpersService,
    ) {
    }

    private getCouriers(): void {
        this.courierService.getCouriers().then(({data}: Api.IResponse): void => {
            this.couriers = data.map((cs: any): any => {
                return {name: cs.display_name, value: cs.id};
            });
            this.changeDetectorRef.markForCheck();
        });
    }

    private getCourierServices(courier_id: number, scope: "new" | "item"): void {
        this.spinnerService.show();
        this.courierService.getCourierServices({courier_id}).then(({data}: Api.IResponse): void => {
            this.spinnerService.hide();
            this.couriers_services[scope] = data.map((cs: any): any => {
                return {name: cs.service_name, value: cs.id};
            });
            this.changeDetectorRef.markForCheck();
        });
    }

    private async prepareCountriesList(): Promise<any> {
        this.spinnerService.show();
        this.countries = await this.helpers.prepareCountriesList();
        this.spinnerService.hide();
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Get items to display
     * @returns {Promise<any>}
     */
    public async getItems(page: number = 1, per_page: number = null, search: string = null): Promise<any> {
        this.spinnerService.show();
        if (per_page === null) {
            per_page = this.userService.data.settings.default_per_page;
        }
        const {data}: Api.IResponse = await this.courierService.getEntityServiceConversions(this.state.section, {
            data_structure: "paginated",
            page,
            per_page,
            search
        });
        this.spinnerService.hide();
        if (data) {
            this.conversions = data;
            this.changeDetectorRef.markForCheck();
        }

    }


    /**
     * Submit new item
     * @returns {Promise<any>}
     */
    public async submitNew(): Promise<any> {
        this.spinnerService.show();

        const {code, message}: Api.IResponse =
            await this.courierService.createEntityServiceConversion(this.state.section, this.newForm.value);

        this.spinnerService.hide();

        if (code === 200) {
            this.toastService.show(message, "success");
            this.getItems();
            this.newForm.reset();
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Update existing item
     * @returns {Promise<any>}
     */
    public async submitUpdate(): Promise<any> {
        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.courierService
            .updateEntityServiceConversion(this.state.section, this.itemForm.value.id, this.itemForm.value);
        if (code === 200) {
            this.toastService.show(message, "success");
            this.getItems();
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Delete item
     * @returns {Promise<any>}
     */
    public async submitDelete(): Promise<any> {
        if (!await this.confirmRef.confirm("Are you sure want to delete this Courier Service Conversion?")) {
            return;
        }
        this.spinnerService.show();

        const {code, message}: Api.IResponse =
            await this.courierService.deleteEntityServiceConversion(this.state.section, this.itemForm.value.id);
        if (code === 200) {
            this.toastService.show(message, "success");
            this.getItems();
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    public ngOnInit(): void {
        this.getItems();
        this.getCouriers();
        this.prepareCountriesList();
        this.conversionSearch.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: string): void => {
                this.getItems(1, 10, value);
            });

        this.itemForm.get("courier_id").valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
            .subscribe((value: number): void => {
                this.itemForm.get("courier_service_id").reset();
                if (value) {
                    this.getCourierServices(value, "item");
                }
                this.changeDetectorRef.markForCheck();
            });

        this.newForm.get("courier_id").valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
            .subscribe((value: number): void => {
                this.newForm.get("courier_service_id").reset();
                if (value) {
                    this.getCourierServices(value, "new");
                }
                this.changeDetectorRef.markForCheck();
            });
    }


    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "courier-services-conversions",
            actions: {
                "browse": ["browse_courier_service_conversions"]
            }
        };
    }


}
