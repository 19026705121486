import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {OrderTrackProgressMapComponent} from "./track-progress-map.component";
import {Contact} from "../../../../../../common/interfaces/contact.interface";
import IAddress = Contact.IAddress;
import {HelpersService} from "../../../../../../common/services/helpers.service";

@Component({
    selector: "section-order-track-progress",
    templateUrl: "track-progress.component.html",
    styleUrls: [
        "track-progress.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})

export class OrderTrackProgressComponent implements OnInit {

    private address_from: IAddress;
    private address_to: IAddress;

    public modal: Modal.IModal;

    public data: any;

    public checkpoints: any[];

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private modalService: ModalService
    ) {
    }

    public openMap(): void {
        this.modalService.open(OrderTrackProgressMapComponent, {
            checkpoints: this.checkpoints,
            address_from: this.address_from,
            address_to: this.address_to,
            modalWidth: 1200,
            modalHeight: 500
        });
    }


    public ngOnInit(): void {
        if (this.modal) {
            this.data = this.modal.params.data;
            this.address_from = this.modal.params.address_from;
            this.address_to = this.modal.params.address_to;
        }

        this.data.tracking_url = HelpersService.getTrackingUrl(this.data.courier.web_url, this.data.tracking_number);

        if (this.data.courier_transaction_checkpoints.length > 0) {
            this.checkpoints = this.data.courier_transaction_checkpoints[0].checkpoints;
        }

        this.changeDetectorRef.markForCheck();

    }
}
