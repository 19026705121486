import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Modal} from "../../../section/services/modal.service";
import {Api, ApiService} from "../../services/api.service";
import {ToastService} from "../../services/toast.service";
import {Form} from "../../interfaces/form.interface";
import {FormControl} from "@angular/forms";
import {SpinnerService} from "../../services/spinner.service";
import {StorageService} from "../../services/storage.service";
import {CommonFormFieldsComponent} from "./form-fields.component";

@Component({
    selector: "common-form",
    template: `
        <h1 *ngIf="formConfig" class="container-heading">{{formConfig.name}}</h1>

        <mat-card>
            <mat-card-content *ngIf="formConfig">
                <div *ngIf="formConfig.description" class="description">{{formConfig.description}}</div>

                <div class="form">
                    <common-form-fields [fields]="fields"
                                        (valueChange)="castValues($event)"
                                        (isValid)="isValid = $event"
                                        [values]="values"
                                        [formSection]="formSection"
                    ></common-form-fields>

                    <ng-container *ngIf="scheduler">
                        <ng-container *userAllowed="['schedule_reports']">
                            <mat-divider></mat-divider>
                            <div class="group flex">
                                <div class="full">
                                    <mat-checkbox [formControl]="showScheduler">Schedule report</mat-checkbox>
                                </div>
                            </div>
                            <common-scheduler-form *ngIf="showScheduler.value"
                                                   [ref]="formConfig.name"
                                                   [type]="modal.params.scheduler_type"
                                                   [type_id]="modal.params.scheduler_type_id"
                                                   [params]="schedulerParams"
                                                   (result)="onSchedulerResult($event)"
                            ></common-scheduler-form>
                        </ng-container>
                    </ng-container>

                    <div class="actions" *ngIf="!scheduler || !showScheduler.value">
                        <button type="button"
                                mat-raised-button
                                color="prymary"
                                [disabled]="!isValid || (modal && modal.params.noSubmit)"
                                (click)="submit()"
                                class="main">
                            Submit
                        </button>

                        <button type="button" [disabled]="modal && modal.params.noSubmit" (click)="reset()"
                                mat-raised-button color="warn">
                            Reset
                        </button>
                    </div>
                </div>

            </mat-card-content>
        </mat-card>
    `,
    styleUrls: [
        "form.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class CommonFormComponent implements OnInit, OnDestroy {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input()
    public formConfig: Form.IConfig;

    @Input()
    public configUrl: string[];

    @Input()
    public configSection: string;

    @Input()
    public submitUrl: string[];

    @Input()
    public values: { [key: string]: any };

    @Input()
    public asyncKey: string;

    @Input()
    public method: Api.EMethod = Api.EMethod.Post;

    @Output()
    public onSubmit: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild(CommonFormFieldsComponent, {static: false})
    public formFieldsRef: CommonFormFieldsComponent;

    public modal: Modal.IModal;

    public formSection: string;

    public fields: Form.IField[];

    public inputValues: { [key: string]: any };

    public isValid: boolean = false;

    public scheduler: boolean = false;

    public showScheduler: FormControl = new FormControl(false);

    public schedulerParams: any = null;

    public preconfiguredValues: { [key: string]: any };

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private toastService: ToastService,
        private spinnerService: SpinnerService,
        private storageService: StorageService
    ) {
    }

    /**
     * Get form config
     */
    private async getFormConfig(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, this.configUrl);

        if (data) {
            this.formConfig = data;
            this.fields = this.formConfig.fields;

            this.values = this.storageService.get("form_bkp_" + this.configUrl.join("."), this.values);

            if (!this.values) {
                this.values = this.formConfig.values;
            }

            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    public reset(): void {
        this.storageService.set("form_bkp_" + this.configUrl, null);
        this.formFieldsRef.reset();
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Submit form
     * @return {Promise<any>}
     */
    public async submit(): Promise<any> {
        this.spinnerService.show();
        /* TODO: We need this timeout, because common form fields
            fire valueChanges event with some delay. No solution on this for now */
        await setTimeout(async (): Promise<any> => {
            if (this.asyncKey) {
                this.apiService.setHeaders({async: this.asyncKey});
            }

            if (!this.submitUrl) {
                this.modal.response.emit({
                    name: "response",
                    value: {
                        ...this.inputValues,
                        ...this.preconfiguredValues
                    }
                });
                this.storageService.set("form_bkp_" + this.configUrl, null);

            } else {
                const response: Api.IResponse = await this.apiService.request(this.method, this.submitUrl,
                    {
                        ...this.inputValues,
                        ...this.preconfiguredValues
                    });

                if (response) {
                    this.toastService.show(response.message, response.type as string);
                    this.onSubmit.emit(response);
                    if (this.modal
                        && !(response.type && response.type === "error" && this.modal.params.notCloseOnError)) {
                        this.modal.response.emit({
                            name: "response",
                            value: response
                        });
                    }
                    this.storageService.set("form_bkp_" + this.configUrl, null);
                }
            }

            this.spinnerService.hide();
        }, 150);
    }

    public castValues($event: any): void {
        if (!$event.isTrusted) {
            this.inputValues = $event;

            Object.keys(this.inputValues).forEach((val: string): void => {
                if (typeof (this.inputValues[val]) === "object" && !Array.isArray(this.inputValues[val])) {
                    if (this.inputValues[val] !== null) {
                        Object.keys(this.inputValues[val]).forEach((key: string): void => {
                            this.inputValues[key] = this.inputValues[val][key];
                        });
                    }
                    delete this.inputValues[val];
                }
            });
            // timeout to get valid event fired
            setTimeout((): void => {
                this.schedulerParams = this.isValid ? {...this.inputValues, submitUrl: this.submitUrl} : null;
                this.changeDetectorRef.markForCheck();
            }, 10);

            this.storageService.set("form_bkp_" + this.configUrl, this.inputValues);
        }
    }

    /**
     * Handle to scheduler result
     * @param $event
     */
    public onSchedulerResult($event: any): void {
        if (this.modal) {
            this.modal.response.emit($event);
        }
    }

    public ngOnInit(): void {
        if (this.modal) {
            if (this.modal.params.formConfig) {
                this.formConfig = this.modal.params.formConfig;
            }
            if (this.modal.params.configUrl) {
                this.configUrl = this.modal.params.configUrl;
            }
            if (this.modal.params.method) {
                this.method = this.modal.params.method;
            }
            if (this.modal.params.submitUrl) {
                this.submitUrl = this.modal.params.submitUrl;
            }
            if (this.modal.params.asyncKey) {
                this.asyncKey = this.modal.params.asyncKey;
            }
            if (this.modal.params.values) {
                this.values = this.modal.params.values;
            }
            if (this.modal.params.scheduler) {
                this.scheduler = this.modal.params.scheduler;
            }
            if (this.modal.params.preconfiguredValues) {
                this.preconfiguredValues = this.modal.params.preconfiguredValues;
            }
        }

        if (this.modal && this.modal.params.reports) {
            this.formSection = "reports";
        } else if (this.configSection) {
            this.formSection = this.configSection;
        }

        if (!this.formConfig) {
            this.getFormConfig();
        } else {
            this.fields = this.formConfig.fields;
        }
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
