<h1 [class.no-margin]="orderData" class="container-heading">Service Request</h1>

<div *ngIf="orderData" class="references">
    <span class="ref">{{orderData.ref}}</span>
    <span class="ref2">
        <span *ngIf="orderData.ref2">({{orderData.ref2}})</span>
        <span>/</span>
        <span class="rma">{{orderData.rma}}</span>
    </span>
    <button mat-button class="edit" color="basic">
        <mat-icon>settings_application</mat-icon>
        Edit
    </button>
</div>

<div *ngIf="orderData" class="info">
    <span class="item">
        Created at:
        <span class="value">{{orderData.created_at | commonFix | date: "dd/MM/y HH:mm"}}</span>
    </span>
    <span class="item">
        Status:
        <span class="value">{{orderData.status.name}}</span>
    </span>
    <span class="item">
        Incoming terms:
        <span class="value">{{orderData.incoterms}}</span>
    </span>
</div>

<div class="container-columns">

    <div class="left">
        <mat-card>
            <mat-card-content>
                <common-spinner #orderSpinnerRef></common-spinner>
                <div *ngIf="isNoData" class="empty">
                    <p>No data</p>
                </div>
                <div *ngIf="orderData" class="content">

                    <h2 class="mat-h2">Address</h2>
                    <div *ngFor="let address of (orderData.ordersaddress || [])" class="address">
                        <div class="item">
                            <span class="label">Type: </span>
                            {{address.address_type}}
                        </div>
                        <div class="item">
                            <span class="label">Address: </span>
                            {{address.address}}, {{address.city}},
                            <ng-template [ngIf]="address.state">{{address.state}},</ng-template>
                            {{address.zip}}, {{address.country}}
                        </div>
                        <div *ngIf="address.address2" class="item">
                            <span class="label">Address 2: </span>
                            {{address.address2}}
                        </div>
                        <div *ngIf="address.address3" class="item">
                            <span class="label">Address 3: </span>
                            {{address.address3}}
                        </div>
                    </div>

                    <div class="divider"></div>

                    <h2 class="mat-h2">Service level </h2>
                    <mat-grid-list cols="4" gutterSize="10px" rowHeight="50px" class="service-level">
                        <mat-grid-tile colspan="1" rowspan="1">
                            <span class="label">Partner service level: </span>
                            <span>{{orderData.service_level.coverage.display_name}}</span>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <span class="label">Order type: </span>
                            <span>{{orderData.service_level.ordertype.display_name}}</span>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <span class="label">Shipping mode: </span>
                            <span>{{orderData.service_level.shippin_gmode.display_name}}</span>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <span class="label">Courier service: </span>
                            <span>{{orderData.service_level.courier_service.service_name}}</span>
                        </mat-grid-tile>
                    </mat-grid-list>

                    <ng-template [ngIf]="orderData.remarks">
                        <div class="divider"></div>
                        <h2 class="mat-h2">Remarks</h2>
                        <p>{{orderData.remarks}}</p>
                    </ng-template>

                </div>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="right">
        <mat-card>
            <mat-card-content>
                <mat-tab-group>
                    <mat-tab label="Remarks">
                        <div class="content">
                            <p>No remarks</p>
                        </div>
                    </mat-tab>
                    <mat-tab label="Progress timeline">
                        <div class="content">
                            <p>Empty timeline</p>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </mat-card-content>
        </mat-card>
    </div>

</div>

<mat-card>
    <mat-card-content>
        <common-spinner #itemListSpinnerRef></common-spinner>
        <h2 class="mat-h2">Order items</h2>
        <mat-tab-group>
            <mat-tab label="Outbound lines">
                <common-table *ngIf="orderItemLists.data.outbound"
                              [data]="orderItemLists.data.outbound"
                              [options]="orderItemLists.options">
                </common-table>
            </mat-tab>
            <mat-tab label="Inbound lines">
                <common-table *ngIf="orderItemLists.data.inbound"
                              [data]="orderItemLists.data.inbound"
                              [options]="orderItemLists.options">
                </common-table>
            </mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>