import {ChangeDetectionStrategy, Component, OnInit, ViewChild} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {PartnerFormStep4Component} from "../../../../admin/components/partner/form/step4/step4.component";

@Component({
    selector: "section-hubs-manage",
    template: `
        <h1 class="container-heading">Manage hubs</h1>
        <mat-card>
            <mat-card-content>
                <section-partner-form-step4 #step4Component></section-partner-form-step4>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HubsManageComponent implements Base.IComponent, OnInit {


    public readonly state: Base.IState;

    @ViewChild("step4Component", {static: true})
    public componentRef: PartnerFormStep4Component;

    public constructor() {
    }


    public ngOnInit(): void {
        const [type, slug]: string[] = this.state.section.split("/");
        this.componentRef.init(slug);
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "hubs",
            actions: {
                "browse": ["browse_inventories_conversion"]
            }
        };
    }

}
