import {Injectable} from "@angular/core";
import {Api, ApiService} from "./api.service";
import {Form} from "../interfaces/form.interface";


@Injectable()
export class BatchService {

    public constructor(private apiService: ApiService) {
    }

    public getBatch(id: number, query: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Get, ["batches", "" + id], {}, query);
    }

    public createBatch(body: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Post, ["batches"], body);
    }

    public updateBatch(id: number, body: any = {}): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Put, ["batches", "" + id], body);
    }

    public deleteBatch(id: number): Promise<Api.IResponse> {
        return this.apiService.request(Api.EMethod.Delete, ["batches", "" + id]);
    }

}
