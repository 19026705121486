import {ApplicationRef, ComponentFactoryResolver, Injectable} from "@angular/core";
import {ConfirmComponent} from "../components/confirm/confirm.component";
import {MainComponent} from "../../app/components/main.component";

@Injectable()
export class AppLockService {

    private lockState: boolean = false;

    public get locked(): boolean {
        return this.lockState;
    }

    public constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef
    ) {
    }

    public lock(): void {
        this.lockState = true;
    }

    public unlock(): void {
        this.lockState = false;
    }

    public async askForUnlock(): Promise<boolean> {
        const confirmComponent: ConfirmComponent = (this.appRef.components[0].instance as MainComponent).confirm;
        return await confirmComponent.confirm("Note: if you leave this section, your data will be lost");
    }
}
