import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Router} from "@angular/router";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Table2Component} from "../../../../../../common/components/table2/table.component";
import {User} from "../../../../../../common/interfaces/user.interface";


@Component({
    selector: "section-recent-jobs-list",
    template: `
        <h1 class="container-heading">Resent jobs</h1>
        <mat-card>
            <mat-card-content>
                <common-table2 *ngIf="listTable" [settings]="listTable"></common-table2>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class RecentJobsListComponent implements Base.IComponent, OnInit {

    private partners: User.IPartner[];

    public readonly state: Base.IState;

    public items: any[] = [];

    public listTable: Table.ISettings;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    public constructor(
        private apiService: ApiService,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private toastService: ToastService,
        private spinnerService: SpinnerService
    ) {
    }


    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        const actions: Table.IAction[] = [];

        const columns: Table.ICol[] = [
            {
                data: "status",
                title: "Status",
                searchable: false,
                sortable: false,
                render: (row: any): string => {
                    let icon: string;
                    let color: string;
                    switch (row.status) {
                        case "finished":
                            icon = "done";
                            color = "mat-primary";
                            break;
                        case "failed":
                            icon = "report_problem";
                            color = "mat-warn";
                            break;
                        default:
                            icon = "schedule";
                            color = "mat-accent";
                            break;
                    }
                    return `<button type="button" class="mat-mdc-mini-fab mdc-fab ${color}" title="${row.status}">
                            <mat-icon class="mat-icon material-icons">${icon}</mat-icon>
                            </button>`;

                },
                click: (row: any): void => {
                    if (row.status === "failed") {
                        this.restartJob(row);
                    }
                }
            },
            {
                data: "job_id",
                title: "Order",
                searchable: false,
                sortable: false,
                render: (row: any): string => {
                    const ref: string = this.getTagValue(row.tags, "App\\Order");
                    const id: string = this.getTagValue(row.tags, "OrderId");
                    if (ref && id) {
                        return "<button type='button' class='mat-mdc-raised-button mdc-button button-200'>"
                            + ref + "</button>";
                    } else if (ref) {
                        return ref;
                    }
                    return "";
                },
                click: (row: any): void => {
                    const id: string = this.getTagValue(row.tags, "OrderId");
                    if (id) {
                        this.router.navigate([
                            this.state.section,
                            "orders",
                            "view",
                            "id",
                            id
                        ]);
                    }
                }
            },
            {
                data: "job_id",
                title: "Job Id",
                render: (row: any): string => {
                    const array: string[] = row.job_id.split(":");
                    return array.pop();
                }
            },
            {
                data: "job_title",
                title: "Job",
            },
            {
                data: "complete_at",
                title: "Complete at"
            },
        ];

        if (this.state.section === "admin") {
            columns.splice(1, 0,
                {
                    data: "job_id",
                    title: "Partner",
                    searchable: false,
                    sortable: false,
                    render: (row: any): string => {
                        const partner_id: string = this.getTagValue(row.tags, "App\\Partner");
                        const partner: User.IPartner = this.getPartnerById(Number(partner_id));
                        return partner && partner.icon_path ?
                            `<img src="${partner.icon_path}" alt="" class="logo">`
                            : "";
                    }
                }
            );
        }

        this.listTable = {
            table_id: "y46hJDh9",
            actions,
            api: {
                url: ["jobs", "logs"],
            },
            columns
        };
        this.changeDetectorRef.markForCheck();
    }

    private getTagValue(tags: { name: string, value: string }[], tag_name: string): string {
        const found_tag: { name: string, value: string } =
            tags.find((tag: { name: string, value: string }): boolean => {
                return tag.name === tag_name;
            });
        return found_tag ? found_tag.value : null;
    }

    private async getPartnersList(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["partner"]);

        this.spinnerService.hide();
        if (data) {
            this.partners = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    private getPartnerById(id: number): User.IPartner {
        return this.partners.find((partner: User.IPartner): boolean => {
            return partner.id === id;
        });
    }

    private async restartJob(job: any): Promise<any> {
        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Post, ["jobs", "restart"],
            {
                job_id: job.job_id
            });

        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.tableRef.reload();
        }
    }


    public async ngOnInit(): Promise<any> {
        if (this.state.section === "admin") {
            await this.getPartnersList();
        }
        this.prepareList();
    }


    public ngConfig(): Base.IConfig {
        return {
            name: "recent-jobs",
            actions: {
                "browse": ["browse_integrations"]
            }
        };
    }

}
