import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, ViewEncapsulation} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../common/interfaces/wizard.interface";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {HelpersService} from "../../../../../../../common/services/helpers.service";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {ModalService} from "../../../../../../services/modal.service";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {debounceTime, takeUntil} from "rxjs/operators";
import {Warehouse} from "../../../../../../../common/interfaces/warehouse.interface";
import {ReplaySubject} from "rxjs";
import {Form} from "../../../../../../../common/interfaces/form.interface";
import ISelectOption = Form.ISelectOption;
import {Inventory} from "../../../../../../../common/interfaces/Inventory.interface";

@Component({
    selector: "section-partner-procedures-wizard-update-quantity",
    templateUrl: "update-quantity.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class PartnerProceduresWizardUpdateQuantityComponent extends AbstractWizardStepComponent implements OnDestroy {

    private state: Base.IState;

    private part_masters_data: Inventory.IPartMaster[];

    public selected_inventory_sum: number = 0;

    public part_masters: ISelectOption[] = [];

    public hubs: { group: string, hubs: Warehouse.IHub[] }[] = [];

    public form: FormGroup = new FormGroup({
        inventory_id: new FormControl(null),
        part_master_id: new FormControl(null, [Validators.required]),
        inventory_conversion_id: new FormControl(null, [Validators.required]),
        location_id: new FormControl({value: null, disabled: true},[Validators.required]),
        quantity: new FormControl(null, [
            Validators.required,
            Validators.pattern("^[0-9]*$")
        ]),
        remark: new FormControl(null, [Validators.required]),
        batch: new FormControl(null),
    });

    public inventories: { inventory: Warehouse.IInventory, sum: number } [];

    public locations: ISelectOption[] = [];

    public filtered_locations: ReplaySubject<any> = new ReplaySubject<any>(1);

    public location_search: FormControl = new FormControl(null);

    public show_locations: FormControl = new FormControl(false);

    public show_batch_field: boolean = false;

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        public helperService: HelpersService,
        private apiService: ApiService,
        private toastService: ToastService,
        private modalService: ModalService,
        private spinnerService: SpinnerService
    ) {
        super(changeDetectorRef);
    }

    private async getPartMasters(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["partmaster"], {},
            {
                no_serial: true
            });
        if (data) {
            this.part_masters_data = data;
            this.part_masters = data.map((part: Inventory.IPartMaster): ISelectOption => {
                return {value: part.id, name: `${part.item} - ${part.description}`};
            });
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private async getHubLocations(id: number): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["hub", "" + id, "locations"],
            {}, {
                data_structure: "select",
            });
        if (data) {
            this.locations = data;
            this.filtered_locations.next(this.locations.slice(0, 20));
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }


    public async getInventories(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["inventory", "available", "by_order_item"],
            {
                part_master_id: this.form.value.part_master_id,
                inventory_conversion_id: this.form.value.inventory_conversion_id,
                with_analogs: false,
                relations: [
                    "WarehouseLocation"
                ]
            });
        this.spinnerService.hide();
        if (data) {
            const inventories: { [key: string]: { inventory: Warehouse.IInventory, sum: number } } = {};
            for (const inventory of data) {
                const key: string = inventory.item + ":" + (inventory.configurations || "")
                    + ":" + (inventory.rev || "") + ":" + (inventory.batch || "")
                    + ":" + (inventory.warehouse_location_id || null);

                if (inventories[key]) {
                    inventories[key].sum += inventory.quantity;
                } else {
                    inventories[key] = {
                        inventory,
                        sum: inventory.quantity
                    };
                }
            }

            this.inventories = Object.values(inventories);

            this.changeDetectorRef.markForCheck();
        }
    }

    public onPMSelected(value: any): void {
        if (value) {
            this.form.get("part_master_id").setValue(value);
            this.form.get("quantity").reset();
            this.form.get("inventory_conversion_id").reset();
            this.inventories = null;

            this.show_batch_field = this.part_masters_data
                .find((part: Inventory.IPartMaster) => part.id === value)?.batch;

            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Submit form
     */
    public async submit(): Promise<any> {
        this.spinnerService.show();
        const {type, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["inventory", "item-quantity-change"], this.form.value);
        if (type as string === "success") {
            this.toastService.show(message, "success");
            this.form.reset();
            this.inventories = [];
            this.hubs = [];
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    public selectInventory(inventory: { inventory: Warehouse.IInventory, sum: number }): void {
        this.form.get("inventory_id").setValue(inventory.inventory.id);
        this.selected_inventory_sum = inventory.sum;

        if (inventory.inventory.warehouse_location_id) {
            this.form.get("location_id").setValue(inventory.inventory.warehouse_location_id);

            let slice = this.locations.slice(0, 20);
            const selected = this.locations.find((location: ISelectOption) => {
                return Number(location.value) === Number(inventory.inventory.warehouse_location_id);
            });
            if (selected) {
                slice.push(selected);
            }

            this.filtered_locations.next(slice);
        }

        this.changeDetectorRef.markForCheck();
    }

    /**
     * Initialize step
     * @param data
     * @returns {Promise<any>}
     */
    public async init(data: Wizard.IData): Promise<any> {
        this.state = data.state;

        this.result.emit({
            action: "result",
            value: true
        });

        this.getPartMasters();

        this.form.get("inventory_conversion_id").valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((id: number): void => {
                this.form.get("quantity").reset();
                if (id) {
                    setTimeout((): void => {
                        this.getInventories();
                    }, 10);
                    this.getHubLocations(id);
                }
            });

        this.form.get("quantity").valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((qty: number): void => {
                if (Number(qty) > this.selected_inventory_sum) {
                    this.form.get("location_id").enable();
                } else {
                    this.form.get("location_id").disable();
                }
                this.changeDetectorRef.markForCheck();
            });

        this.location_search.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: string): void => {
                if (value) {
                    this.filtered_locations.next(this.locations.filter((location: ISelectOption): boolean => {
                        return location.name.toLowerCase().includes(value.toLowerCase());
                    }));
                } else {
                    let slice = this.locations.slice(0, 20);
                    if (this.form.get("location_id").value) {
                        const selected = this.locations.find((location: ISelectOption) => {
                            return Number(location.value) === Number(this.form.get("location_id").value);
                        });
                        slice.push(selected);
                    }
                    this.filtered_locations.next(slice);
                }
                this.changeDetectorRef.markForCheck();
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
