<h1 class="container-heading">{{state.action === "add" ? "Add" : "Edit"}} team</h1>

<mat-card>
    <mat-card-content>

        <form *ngIf="formGroup" [formGroup]="formGroup" (submit)="submitForm()">
            <div class="group custom flex">
                <mat-form-field class="full">
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="name">
                </mat-form-field>
                <mat-form-field class="full">
                    <mat-label>Description</mat-label>
                    <input matInput formControlName="description">
                </mat-form-field>
                <mat-form-field class="full">
                    <mat-label>Category</mat-label>
<mat-select formControlName="category" >
                        <mat-option [value]="null">None</mat-option>
                        <mat-option value="client">Client</mat-option>
                        <mat-option value="request">Request</mat-option>
                        <mat-option value="notification">Notification</mat-option>
                        <mat-option value="followup">Follow-up</mat-option>
                        <mat-option value="admins">Administration</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="actions">
                <button mat-raised-button type="submit" color="primary" [disabled]="!formGroup.valid">
                    {{state.action === "add" ? "Add" : "Update"}}
                </button>
                &nbsp;
                <button mat-raised-button type="button" (click)="cancel()">Cancel</button>
            </div>
        </form>
    </mat-card-content>
</mat-card>
