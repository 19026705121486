import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation
} from "@angular/core";
import {Order} from "../../../../../../../common/interfaces/order.interface";
import {Modal, ModalService} from "../../../../../../services/modal.service";
import {OrderEditAddressModalComponent} from "../edit-address-modal/edit-address-modal.component";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {Contact} from "../../../../../../../common/interfaces/contact.interface";
import {Courier} from "../../../../../../../common/interfaces/courier.interface";
import {UserService} from "../../../../../../../common/services/user.service";
import {Api3Service} from "../../../../../../../common/services/api3.service";
import {AppStateService} from "../../../../../../../common/services/app-state.service";
import {OrderMapModalComponent} from "../map-modal/map-modal.component";
import {retry} from "rxjs";

@Component({
    selector: "section-order-address",
    template: `
        <ng-template #contactsTemplate let-contacts="contacts" let-addressType="addressType">
            <div class="margin-bottom-10" *ngIf="contacts.length">
                <mat-icon class="vertical-middle">alternate_email</mat-icon>
                <span class="label margin-bottom-10" style="margin-left: 10px;">CONTACTS</span>
            </div>

            <div *ngFor="let contact of filterContacts(contacts, addressType); let mContIndex = index"
                 class="margin-bottom-10 holiday-card padding-bottom-10
                             padding-left-10 padding-right-10 padding-top-10">

                <div class="pull-right">
                    <mat-icon *ngIf="contact.pivot?.is_main" color="accent">star</mat-icon>
                    <mat-icon *ngIf="!contact.pivot?.is_main" matTooltip="Mark as main"
                              class="pointer"
                              (click)="markContactAsMain(contact.id, contact.pivot.type)">
                        star
                    </mat-icon>
                </div>

                <b>{{ contact.pivot?.is_main ? 'Main contact' : 'Contact' }}: </b>
                {{ contact.last_name + ',' | if: !!contact.last_name }}
                {{ contact.first_name + ','| if: !!contact.first_name }}

                <div *ngIf="contact.phone">
                    <b>Tel.:</b> {{ contact.phone }},
                </div>
                <div *ngIf="contact.mobile_phone" style="word-break:break-all;">
                    <b>Mob.:</b> {{ contact.mobile_phone }},
                </div>
                <div *ngIf="contact.email" style="word-break:break-all;">
                    <b>Email:</b> {{ contact.email }}
                </div>
            </div>
        </ng-template>

        <ng-template #addressTemplate let-address="address" let-contacts="contacts">

            <div *ngIf="address.customer && address.address_type === 'main'" class="item">
                <mat-icon class="vertical-middle">group</mat-icon>
                <span class="label" style="margin-left: 10px;">CUSTOMER</span>
                <div style="margin-top: 5px;">
                    {{ address.customer.name }}
                </div>
            </div>
            <div class="address">
                <div class="col-left">
                    <div class="margin-bottom-10">
                        <mat-icon class="vertical-middle"> location_on</mat-icon>
                        <span class="label vertical-middle margin-left-10">ADDRESS</span>
                    </div>

                    <div class="item flag-section">
                        <div *ngIf="address.country_detailed?.in_eu" class="flag margin-right-0">
                            <img [src]="'assets/images/flags/eu.svg'">
                        </div>

                        <div *ngIf="address.country_iso_2" class="flag margin-left-5">
                            <img [src]="'assets/images/flags/' + address.country_iso_2.toLowerCase() + '.svg'">
                        </div>
                        <div class="label">{{ address.country + ',' | if: !!address.country }}
                            {{ address.city | if: !!address.city }}
                        </div>
                    </div>
                    <div class="item">
                        {{ address.address | if: !!address.address }},
                        {{ address.address2 + ', ' | if: !!address.address2 }}
                        {{ address.zip | if: !!address.city }}
                        {{ address.state | if: !!address.state }}

                        <div class="validation row flex">
                            <mat-chip [class]="address.valid ? 'background-success':'background-error'"
                                      class="vertical-middle margin-bottom-10 margin-right-10"
                                      style="margin-top:3px">
                                <mat-icon matChipAvatar>{{ address.valid ? 'check' : 'close' }}</mat-icon>
                                {{ 'Valid' | if:  address.valid: 'Invalid' }}
                            </mat-chip>

                            <button *ngIf="!address.valid"
                                    mat-raised-button
                                    type="button" class="margin-bottom-10 margin-right-10"
                                    (click)="validateAddress(address.id)">
                                Validate
                            </button>

                            <button *ngIf="!orderData.confirmed && userAllowed('edit_addresses')"
                                    color="accent"
                                    mat-raised-button
                                    type="button" class="margin-bottom-10 margin-right-10"
                                    (click)="editAddress()">
                                Edit
                            </button>

                            <button *ngIf="address.lat && address.lng"
                                    type="button"
                                    mat-raised-button
                                    color="primary"
                                    (click)="viewOnMap(address)">
                                View on the map
                            </button>

                        </div>
                    </div>

                    <div *ngIf="address.address2" class="item clear margin-bottom-20">
                        <mat-icon class="vertical-middle"> location_on</mat-icon>
                        <span class="label" style="margin-left: 10px;">ADDRESS 2 </span>
                        <div>{{ address.address2 }}</div>
                    </div>

                    <div *ngIf="address.address3" class="item">
                        <mat-icon class="vertical-middle"> location_on</mat-icon>
                        <span class="label" style="margin-left: 10px;">ADDRESS 3 </span>
                        <div>{{ address.address3 }}</div>
                    </div>

                    <div class="item contacts padding-top-20">
                        <ng-container
                            *ngTemplateOutlet="contactsTemplate; context: {contacts: contacts,
                            addressType:address.address_type}"></ng-container>
                    </div>

                    <div class="item contacts" *ngIf="orderData.requestor">
                        <mat-icon class="vertical-middle"> chat</mat-icon>
                        <span class="label" style="margin-left: 10px;">REQUESTER </span>

                        <div class="margin-bottom-10">
                            <span *ngIf="orderData.requestor.last_name">
                                {{ orderData.requestor.last_name }},
                            </span>
                            <span *ngIf="orderData.requestor.first_name">
                                {{ orderData.requestor.first_name }},
                            </span>
                            <div *ngIf="orderData.requestor.phone">
                                <b>Tel.: </b> {{ orderData.requestor.phone }},
                            </div>
                            <div *ngIf="orderData.requestor.mobile_phone">
                                <b>Mob.:</b> {{ orderData.requestor.mobile_phone }},
                            </div>
                            <div *ngIf="orderData.requestor.email">
                                <b>Email:</b> {{ orderData.requestor.email }}
                            </div>
                        </div>
                    </div>

                </div>

                <div class="col-right">
                    <mat-card class="other-info-card"
                              *ngIf="address.address_name || address.ref || address.ref2 || address.properties">
                        <div class="item" *ngIf="address.address_name">
                            <span class="label">Site name: </span>
                            {{ address.address_name.toUpperCase() }}
                        </div>
                        <div class="item" *ngIf="address.ref">
                            <span class="label">Ref: </span>
                            {{ address.ref }}
                        </div>
                        <div class="item" *ngIf="address.ref2">
                            <span class="label">Ref2: </span>
                            {{ address.ref2 }}
                        </div>
                        <ng-template *ngIf="address.properties" ngFor let-prop
                                     [ngForOf]="address.properties | keys">
                            <b>{{ prop | replace: "_":" " }}:</b> {{ address.properties[prop] }}
                            <br/>
                        </ng-template>
                    </mat-card>

                    <ng-container *ngIf="address.address_type === 'main'">

                        <mat-card class="shipping-card" *ngIf="orderData.shipping_instructions">
                            <div class="item">
                                <div class="label">SHIPPING INSTRUCTIONS</div>
                                <div>
                                    {{ orderData.shipping_instructions }}
                                </div>
                            </div>
                        </mat-card>

                        <mat-card class="shipping-card" *ngIf="orderData.main_address.main_notes?.length">
                            <div class="item">
                                <div class="label">NOTES</div>
                                <div *ngFor="let note of orderData.main_address.main_notes">
                                    {{ note.content }}
                                </div>
                            </div>
                        </mat-card>


                        <common-simple-spinner *ngIf="!holidays"></common-simple-spinner>
                        <mat-card class="holiday-card" *ngIf="holidays && holidays.length > 0">
                            <div class="item contacts">
                                <span class="label">UPCOMING HOLIDAYS </span>
                                <div *ngFor="let holiday of holidays; let contIndex = index"
                                     class="margin-bottom-10 flex row" style="justify-content: flex-start">
                                    <mat-icon class="pointer"
                                              [title]="holiday.public ? 'Public' : 'Not Public'"
                                              [style.color]="holiday.public ? 'darkred' : 'green'">
                                        event
                                    </mat-icon>
                                    <div>
                                        <span *ngIf="holiday.on_date">
                                            In
                                            <img height="15px"
                                                 [src]="'assets/images/flags/'+holiday.country_iso_2.toLowerCase()
                                                 +'.svg'"
                                                 [alt]="holiday.country_iso_2">
                                            On {{ holiday.on_date | commonFix | date:"MMM d, y":"Z" }}
                                        </span>
                                        <br>
                                        <span *ngIf="holiday.name">
                                            It will be {{ holiday.name }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </mat-card>
                    </ng-container>
                </div>
            </div>
        </ng-template>

        <common-simple-spinner *ngIf="!orderData"></common-simple-spinner>

        <div class="addresses" *ngIf="orderData">
            <div *ngIf="orderData.main_address">
                <h2 *ngIf="orderData.service_level?.properties?.shipment_name; else defaultShipmentName">
                    {{ orderData.service_level.properties.shipment_name }}:
                </h2>
                <ng-template #defaultShipmentName>
                    <h2>Ship To Address:</h2>
                </ng-template>
                <ng-container
                    *ngTemplateOutlet="addressTemplate; context: {address: orderData.main_address,
                    contacts:orderData.main_contacts}">
                </ng-container>
            </div>

            <div *ngIf="orderData.bill_to_address">
                <h2>Bill To Address:</h2>

                <ng-container
                    *ngTemplateOutlet="addressTemplate; context: {address: orderData.bill_to_address,
                    contacts:orderData.bill_contacts}">
                </ng-container>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class OrderAddressComponent implements OnInit, OnChanges {

    public state: Base.IState;

    public holidays: any[];

    public orderTracks: Courier.ITransaction[] = [];

    @Input()
    public orderData: Order.IOrderData;

    @Output()
    public onUpdate: EventEmitter<any> = new EventEmitter<any>();

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private modalService: ModalService,
        private toastService: ToastService,
        private spinnerService: SpinnerService,
        private userService: UserService,
    ) {
    }

    /**
     * Edit order address
     * @returns {Promise<any>}
     */
    public async editAddress(): Promise<any> {

        const response: Modal.IResponse = await this.modalService.open(OrderEditAddressModalComponent, {
            state: this.state,
            addresses: [this.orderData.main_address, this.orderData.bill_to_address],
            orderData: this.orderData
        });

        if (response) {
            this.onUpdate.emit(true);
        }
    }

    public async markContactAsMain(contact_id, type = "main"): Promise<any> {
        const {code, message}: Api.IResponse = await this.api3Service.put(
            `${this.state.section}/orders/${this.orderData.id}/contacts/${contact_id}/mark-as-main`, {
                type
            });


        if (code === 200) {
            this.toastService.show(message, "success");
            this.onUpdate.emit(true);
        }
    }

    public userAllowed(permissions: string): boolean {
        return this.userService.validatePermissions(permissions);
    }

    public async getHolidays(): Promise<any> {
        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `${this.state.section}/orders/${this.orderData.id}/holidays`);

        if (data) {
            this.holidays = data || [];
        }
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Validate order address
     * @param {number} id
     * @returns {Promise<any>}
     */
    public async validateAddress(id: number): Promise<any> {
        this.spinnerService.show();
        const response: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["address", "" + id, "validate"]);
        this.toastService.show(response.message, response.type as string);
        if (response.type as string === "success") {
            this.onUpdate.emit(true);
        }
        this.spinnerService.hide();
    }

    public viewOnMap(address): void {
        this.modalService.open(OrderMapModalComponent, {
            mainAddress: address,
            orderTracks: this.orderTracks,
            modalWidth: 550,
            modalHeight: 455
        });
    }

    /**
     * Get order courier transactions
     * @return {Promise<any>}
     */
    private async getOrderTracks(): Promise<any> {
        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `/${this.state.section}/orders/${this.orderData.id}/shipments`, {}, {
                relations: [
                    "CourierTransactions"
                ]
            });
        if (data) {
            for (const shipment of data) {
                if (shipment.courier_transactions) {
                    this.orderTracks = this.orderTracks.concat(
                        shipment.courier_transactions.filter((t: Courier.ITransaction): string => {
                            return t.lat && t.lng;
                        })
                    );
                }
            }
        }
        this.changeDetectorRef.markForCheck();
    }

    private init(): void {
        this.state = AppStateService.getState();

        if (this.userService.validatePermissions(["read_shipments"])) {
            this.getOrderTracks();
        }

        this.getHolidays();

        this.changeDetectorRef.markForCheck();
    }

    public filterContacts(contacts: Contact.IContact[], addressType: string): Contact.IContact[] {

        addressType = addressType === "main" ? "main" : "bill_to";

        return contacts.filter((c: Contact.IContact) => {
            return c.pivot.type === addressType;
        });
    }

    public ngOnInit(): void {
        this.init();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.orderData && !changes.orderData.firstChange) {
            this.init();
        }
    }

}