import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Router} from "@angular/router";
import {ApiService} from "../../services/api.service";
import {StorageService} from "../../services/storage.service";
import {SpinnerService} from "../../services/spinner.service";
import {DomSanitizer} from "@angular/platform-browser";
import {RequestService} from "../../services/request.service";
import * as moment from "moment";
import {Moment} from "moment";
import {FormControl} from "@angular/forms";
import {MatDatepicker} from "@angular/material/datepicker";
import {ModalService} from "src/modules/section/services/modal.service";
import {CommonFormComponent} from "../form";


@Component({
    selector: "common-timeline",
    template: `
        <div class="timeline {{color}}" *ngIf="timeLine" (click)="setTimeline()">
            <ng-template [ngIf]="timeLine.isActive">
                <div class="progress"
                     [ngStyle]="{ width: timeLine.progress + '%'}"></div>
                <div class="dates">
                    {{ timeLine.from.format("MMM D") }} - {{ timeLine.to.format("MMM D") }}
                </div>
                <div class="distance">
                    {{ daysLeft }}d
                    {{ hoursLeft }}h
                    {{ minutesLeft }}m
                    left
                </div>
            </ng-template>
            <ng-template [ngIf]="!timeLine.isActive">
                <div class="dates">-</div>
                <div class="distance">-</div>
            </ng-template>

            <div class="hidden">
                <input
                    [ngxMatDatetimePicker]="timelinePicker"
                    [formControl]="timeLine.endDate"
                    [min]="timeLine.now.format('YYYY-MM-DD')" readonly>
            </div>
            <ngx-mat-datetime-picker #timelinePicker [touchUi]="true" [showSpinners]="true">
                <ng-template>
                    <span>Apply</span>
                </ng-template>
            </ngx-mat-datetime-picker>

        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class TimelineComponent implements OnInit, OnChanges, OnDestroy {

    private timer: any;

    public timeLine: {
        from: Moment,
        to: Moment,
        now: Moment,
        diff100: number,
        diffNow: number,
        progress: number,
        isActive: boolean,
        endDate: FormControl,

    };

    @Output()
    public onChange: EventEmitter<string> = new EventEmitter();

    @Input()
    public from: string;

    @Input()
    public to: string;

    @ViewChild("timelinePicker", {static: false})
    public timelinePicker: MatDatepicker<any>;

    public color: string = "red";

    public daysLeft: number = 0;

    public hoursLeft: number = 0;

    public minutesLeft: number = 0;

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private storageService: StorageService,
        private spinnerService: SpinnerService,
        private sanitizer: DomSanitizer,
        private requestService: RequestService,
        private modalService: ModalService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }


    protected prepareTimeline(): void {
        this.timeLine = {
            from: null,
            to: null,
            now: moment(),
            diff100: null,
            diffNow: null,
            progress: 0,
            isActive: false,
            endDate: new FormControl(null)
        };
        if (this.from && this.to) {
            this.timeLine.from = moment(this.from);
            this.timeLine.to = moment(this.to);
            this.timeLine.diff100 = this.timeLine.to.diff(this.timeLine.from, "minutes");
            this.timeLine.diffNow = this.timeLine.now.diff(this.timeLine.from, "minutes");
            this.timeLine.progress = this.timeLine.diffNow / (this.timeLine.diff100 / 100);
            this.timeLine.progress = this.timeLine.progress > 0 ? Math.ceil(this.timeLine.progress) : 0;
            this.timeLine.progress = this.timeLine.progress < 100 ? this.timeLine.progress : 100;
            this.timeLine.isActive = true;
            const left: number = this.timeLine.to.diff(moment(), "minutes");

            if (left > 0) {
                this.daysLeft = Math.floor(left / 60 / 24);
                this.hoursLeft = Math.floor(left / 60 - this.daysLeft * 24);
                this.minutesLeft = Math.floor(left - this.hoursLeft * 60 - this.daysLeft * 60 * 24);

                if (left > 60) { // 1h
                    this.color = "green";
                } else if (left > 30) { // 0.5h
                    this.color = "yellow";
                } else {
                    this.color = "red";
                }
            } else {
                this.daysLeft = 0;
                this.hoursLeft = 0;
                this.minutesLeft = 0;
                this.color = "red";
            }
        } else {
            this.color = "green";
        }
        this.changeDetectorRef.markForCheck();
    }

    public async setTimeline(): Promise<void> {
        var result = await this.modalService.open(CommonFormComponent, {
            formConfig: {
                id: 0,
                name: "Set timeline date",
                description: "",
                fields: [
                    {
                        label: "timeline date",
                        name: "timeline",
                        size: "full",
                        type: "date_range",
                        required: true,
                        values: {
                            start: this.timeLine.from,
                            end: this.timeLine.to
                        }
                    }
                ],
            }
        });

        if (result?.value?.timeline) {
            this.onChange.emit(result.value.timeline);
            this.changeDetectorRef.markForCheck();
        }
    }

    public ngOnInit(): void {
        this.prepareTimeline();

        this.timer = setInterval(() => {
            this.prepareTimeline();
        }, 15000);
    }


    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.to && !changes.to.firstChange && changes.to.currentValue !== changes.to.previousValue) {
            this.to = changes.to.currentValue;
            this.prepareTimeline();
        }
    }

    public ngOnDestroy(): void {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }
}
