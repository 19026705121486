import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {FormControl} from "@angular/forms";
import {AbstractOrderItemComponent} from "../../../item/item";
import {SpinnerService} from "../../../../../../../../common/services/spinner.service";
import {Api, ApiService} from "../../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../../common/services/toast.service";
import {ModalService} from "../../../../../../../services/modal.service";
import {UserService} from "../../../../../../../../common/services/user.service";
import {StorageService} from "../../../../../../../../common/services/storage.service";
import {distinctUntilChanged, takeUntil} from "rxjs/operators";
import {HelpersService} from "../../../../../../../../common/services/helpers.service";
import {Api3Service} from "../../../../../../../../common/services/api3.service";


@Component({
    selector: "section-order-wizard-item-rma",
    templateUrl: "../../../item/item.component.html",
    styleUrls: [
        "../../../item/item.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class OrderWizardItemRmaComponent extends AbstractOrderItemComponent implements OnInit {

    public itemType: string = "outbound";

    /**
     * Options (for item & hub selectors)
     */
    public options: any = {
        items: [],
    };

    public formType: string = "rma";

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        protected apiService: ApiService,
        protected api3Service: Api3Service,
        protected toastService: ToastService,
        protected modalService: ModalService,
        protected userService: UserService,
        protected storageService: StorageService,
        protected spinnerService: SpinnerService
    ) {
        super(changeDetectorRef, apiService, api3Service, toastService, modalService, userService, storageService,
            spinnerService);
    }

    private setValidators(is_manual_type: boolean): void {
        if (is_manual_type && this.itemType === "outbound") {
            this.formGroup.get("inbound_hub_id").clearValidators();
        }
        if (is_manual_type && this.itemType === "inbound") {
            this.formGroup.get("outbound_hub_id").clearValidators();
        }
        this.formGroup.updateValueAndValidity();
    }

    private getOutboundHubs(): void {
        if (this.outboundHubRef) {
            this.outboundHubRef.getHubs();
        }
    }

    private getInboundHubs(): void {
        if (this.inboundHubRef) {
            this.inboundHubRef.getHubs();
        }
    }


    protected prepareFormListeners(): void {
        super.prepareFormListeners();
        this.rma_send_only.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
            .subscribe(async (checked: boolean): Promise<any> => {
                if (checked) {
                    this.formGroup.get("inbound_hub_id").clearValidators();
                } else {
                    this.formGroup.get("inbound_hub_id").setValidators(this.hubValidators);
                }
                this.formGroup.get("inbound_hub_id").updateValueAndValidity();
                this.changeDetectorRef.markForCheck();
            });
    }

    public onItemSelected($event: any): void {
        super.onItemSelected($event);

        if (!this.manualType) {
            this.getInboundHubs();
            this.getOutboundHubs();
        } else if (this.itemType === "outbound") {
            this.getOutboundHubs();
        } else if (this.itemType === "inbound") {
            this.getInboundHubs();
        }
    }

    public showOutboundHubSelect(): boolean {
        if (this.manualType && this.itemType === "inbound") {
            return false;
        }
        return true;
    }

    public showInboundHubSelect(): boolean {
        if ((this.manualType && this.itemType === "outbound") || this.rma_send_only.value) {
            return false;
        }
        return true;
    }

    /**
     * Add item (prepare & add modal response)
     */
    public async save(): Promise<any> {

        this.spinnerService.show();

        const items: any[] = [];

        if (this.manualType) {
            items.push({
                ...this.formGroup.value,
                hub_id: this.formGroup.value[this.itemType + "_hub_id"],
                order_id: this.modal.params.order_id,
                type: this.itemType
            });

        } else {
            items.push({
                ...this.formGroup.value,
                hub_id: this.formGroup.value.outbound_hub_id,
                order_id: this.modal.params.order_id,
                type: "outbound"
            });

            if (!this.rma_send_only.value) {
                items.push({
                    ...this.formGroup.value,
                    hub_id: this.formGroup.value.inbound_hub_id,
                    order_id: this.modal.params.order_id,
                    type: "inbound"
                });
            }
        }


        const {code, message}: Api.IResponse = await this.api3Service.post(
            `${this.modal.params.state.section}/order-items`, {items});

        this.spinnerService.hide();

        this.modal.response.next({
            name: "value",
            value: true
        });

        if (code === 200) {
            this.toastService.show(message, "success");
        }
    }


    public ngOnInit(): void {
        if (this.modal.params.type) {
            this.itemType = this.modal.params.type;
        }

        this.manualType = this.modal.params.serviceLevel.properties.manual_type_selection;

        this.formGroup.addControl("type", new FormControl(this.itemType));

        this.setValidators(this.manualType);

        this.changeDetectorRef.markForCheck();

        super.ngOnInit();
    }
}
