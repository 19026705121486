import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {ConfirmComponent} from "../../../../../../../common/components/confirm/confirm.component";
import {Table2Component} from "../../../../../../../common/components/table2";
import {Table} from "../../../../../../../common/interfaces/table.interface";
import {Router} from "@angular/router";
import * as _moment from "moment";
import {HelpersService} from "../../../../../../../common/services/helpers.service";
import {AmplitudeService} from "../../../../../../../common/services/amplitude.service";

const moment: any = _moment;

@Component({
    selector: "section-dashboard-template-warehouse",
    templateUrl: "warehouse.component.html",
    styleUrls: ["warehouse.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})

export class DashboardwarehouseComponent implements OnInit, OnDestroy {
    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild(ConfirmComponent, {static: true})
    public confirmRef: ConfirmComponent;

    public openOutboundOrdersConfig = {
        url: ["warehouse", "order"],
        query: {
            status_parameter_open: true,
            line_type: "outbound",
            data_structure: "count"
        }
    }

    public openUrgentOrdersConfig = {
        url: ["warehouse", "order"],
        query: {
            status_parameter_open: true,
            only_urgent: true,
            line_type: "outbound",
            data_structure: "count"
        }
    }

    public openPackedOrdersConfig = {
        url: ["warehouse", "order"],
        query: {
            status_parameter_packed: true,
            data_structure: "count"
        }
    }

    public openInboundOrdersConfig = {
        url: ["warehouse", "order"],
        query: {
            status_parameter_open: true,
            line_type: "inbound",
            data_structure: "count"
        }
    }

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    public listTable: Table.ISettings;

    public currentTableFilters;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,

    ) {
        this.currentTableFilters = this.openUrgentOrdersConfig;
    }

    private getApi(): any {
        const query: any = {...this.currentTableFilters.query};
        query.data_structure = "paginated";
        return {
            url: this.currentTableFilters.url,
            query
        };
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {

        const tableColumns: Table.ICol[] = [
            {
                data: "order.partner.icon_path",
                name: "Order.Partner.icon_path",
                title: "Partner",
                render: (row: any): string => {
                    return "<img src='" + row.order.partner.icon_path + "' alt=''>";
                },
                searchable: false,
                sortable: false
            },
            {
                data: "shipment.type",
                name: "Shipment.type",
                title: "",
                render: (row: any): string => {
                    const type: string = HelpersService.dotToObjectPath(row, "shipment.type");
                    if (type === "inbound") {
                        return "" +
                            "<button data-action='inbound' type='button' " +
                            "class='mat-mdc-mini-fab mdc-fab mat-success' title='inbound'>" +
                            "<mat-icon class='mat-icon material-icons'>arrow_downward</mat-icon>" +
                            "</button>";
                    } else if (type === "outbound") {
                        return "" +
                            "<button data-action='outbound' type='button' " +
                            "class='mat-mdc-mini-fab mdc-fab mat-warn' title='outbound'>" +
                            "<mat-icon class='mat-icon material-icons'>arrow_upward</mat-icon>" +
                            "</button>";
                    }
                    return "";
                },
                click: (row: any): void => {
                    const path: string[] = [
                        "/warehouse",
                        row.warehouse.slug,
                        "procedures-" + row.shipment.type,
                        "wizard",
                        "order",
                        row.id,
                        "order_ref",
                        row.order.ref,
                        "order_id",
                        row.order.id,
                        "back_to",
                        btoa(this.router.url)
                    ];

                    window.open(path.join("/"), "_blank");
                },
                exportable: false
            },
            {
                data: "order.ref",
                name: "Order.ref",
                title: "Order",
            },
            {
                data: "order.service_level.name",
                name: "Order.ServiceLevel.name",
                title: "Service level",
                sortable: false
            },
            {
                data: "status.name",
                name: "Status.name",
                title: "Status",
                sortable: false
            },
            {
                data: "threepl_notes",
                title: "Notes",
                sortable: false
            },
            {
                data: "created_at",
                name: "Order.created_at",
                title: "Created at"
            }
        ];

        const exportColumns: Table.ICol[] = tableColumns;

        for (const ind in exportColumns) {
            if (exportColumns[ind].title === "Partner") {
                exportColumns[ind].data = "order.partner.display_name";
                exportColumns[ind].name = "Order.Partner.display_name";
            }
        }

        this.listTable = {
            table_id: "dashboard_warehouse_table",
            api: this.getApi(),
            actions: [],
            columns: tableColumns,
            export: {
                file_name: "warehouse orders",
                columns: exportColumns,
            },
            search_in: {
                "Ref": "Order.ref",
                "Service level": "Order.ServiceLevel.name",
                "Order type": "Order.ServiceLevel.orderType.display_name",
                "Status": "Status.name",
                "Order status": "Order.Status.name",
                "Tracking number": "Shipment.CourierTransactions.tracking_number",
            }

        };
        this.changeDetectorRef.markForCheck();
    }

    public onWidgetClick(config: any): void {
        this.currentTableFilters = config;
        this.tableRef.reload(this.getApi());
    }

    public navigateTo(url: string[]): void {
        this.router.navigate(url);
    }

    public amplitudeClick(message: string):void{
        AmplitudeService.eventClick(message);
    }

    public ngOnInit(): void {
        this.prepareList();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
