<h1 class="container-heading">Invite member</h1>
<mat-card>
    <div class="form">
        <div class="group flex text-center">
            <h3 class="full">
                Type in email of person you want to invite. <br>
                He/She will receive email with registration link
            </h3>
        </div>
        <div class="group flex">
            <div class="third"></div>
            <mat-form-field class="third">
                <mat-label>Email</mat-label>
                <input matInput type="email" [formControl]="email" required>
            </mat-form-field>
            <div class="third"></div>
        </div>
        <div class="actions text-center">
            <button mat-raised-button [disabled]="!email.valid"
                    (click)="submitInvite()" type="button" color="primary">
                Invite
            </button>
        </div>
    </div>
</mat-card>
