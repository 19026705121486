import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from "@angular/core";
import {Router} from "@angular/router";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {UserService} from "../../../../../../common/services/user.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {Table2Component} from "../../../../../../common/components/table2/table.component";
import {PartnerService} from "../../../../../../common/services/partner.service";
import {WarehouseService} from "../../../../../../common/services/warehouse.service";


@Component({
    selector: "section-team-list",
    templateUrl: "list.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class TeamListComponent implements Base.IComponent, OnInit {

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.ISettings;

    public readonly state: Base.IState;

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        public userService: UserService,
        private spinnerService: SpinnerService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        const actions: Table.IAction[] = [];
        if (this.userService.validatePermissions("read_teams")) {
            actions.push({
                name: "view",
                title: "View",
                click: (row: any): void => {
                    this.router.navigate([this.state.section, this.state.component, "view", "id", row.id]);
                }
            });
        }


        this.listTable = {
            table_id: "nmkdffuft5",
            actions,
            api: {
                url: ["team"]
            },
            columns: [
                {
                    data: "id",
                    title: "",
                    render: (row: any): string => {
                        const btn: string = `<button type="button"
                                class="mat-mdc-mini-fab mdc-fab mat-accent" title="Edit">
                                    <mat-icon class="mat-icon material-icons">border_color</mat-icon>
                            </button>`;

                        if (this.userService.validatePermissions("edit_teams")) {
                            if (this.userService.validatePermissions("browse_admin")) {
                                return btn;
                            } else if (PartnerService.partner && row.partner_id === PartnerService.partner.id) {
                                return btn;
                            } else if (WarehouseService.warehouse
                                && row.warehouse_id === WarehouseService.warehouse.id) {
                                return btn;
                            }
                        }
                        return "";
                    },
                    click: (row: any): void => {
                        let click: boolean = false;
                        if (this.userService.validatePermissions("edit_teams")) {
                            if (this.userService.validatePermissions("browse_admin")) {
                                click = true;
                            } else if (PartnerService.partner && row.partner_id === PartnerService.partner.id) {
                                click = true;
                            } else if (WarehouseService.warehouse
                                && row.warehouse_id === WarehouseService.warehouse.id) {
                                click = true;
                            }
                        }

                        if (click) {
                            this.router.navigate([this.state.section, this.state.component, "edit", "id", row.id]);
                        }
                        return;
                    }
                },
                {
                    data: "name",
                    title: "Name"
                },
                {
                    data: "description",
                    title: "Description"
                },
                {
                    data: "category",
                    title: "Category"
                }
            ],
        };
        this.changeDetectorRef.markForCheck();
    }

    public ngOnInit(): void {
        this.prepareList();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "team",
            actions: {
                "browse": ["browse_teams"]
            }
        };
    }

}
