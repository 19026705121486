import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {StorageService} from "../../services/storage.service";
import {Modal} from "../../../section/services/modal.service";
import {ApiService} from "../../services/api.service";
import {take, takeUntil} from "rxjs/operators";
import {HelpersService} from "../../services/helpers.service";
import {PusherService} from "../../services/pusher.service";
import {Push} from "../../interfaces/push.interface";
import {Location} from "@angular/common";
import {NotificationService} from "../../services/notification.service";
import {SpinnerService} from "../../services/spinner.service";
import {ToastService} from "../../services/toast.service";

@Component({
    selector: "common-file-upload",
    template: `
        <div class="pull-right" #button>
            <ng-template [ngIf]="templatePath && templateTypeIsUrl">
                <a class="mat-mdc-raised-button mdc-button mdc-button mat-accent"
                   [href]="templatePath" download target="_blank">
                    {{templateLabel}}
                </a>
            </ng-template>

            <ng-template [ngIf]="templatePath && !templateTypeIsUrl">
                <button type="button" class="mat-mdc-raised-button mdc-button mat-accent"
                        (click)="templatePath(spinnerService, button)">
                    {{templateLabel}}
                </button>
            </ng-template>
        </div>
        <h1 *ngIf="modal" class="container-heading">Upload file</h1>
        <p *ngIf="templatePath">
            Please download and fill all required details from the template above<br>
            Then upload file to the dedicated field
        </p>
        <div *ngIf="errors && modal" [innerHTML]="errors" class="errors"></div>
        <mat-card>
            <mat-card-content>
                <div class="upload-area"
                     *ngIf="!uploading"
                     (click)="chooseFile()"
                     (dragover)="dragOver($event)"
                     (drop)="fileDrop($event)">
                    <span>
                        <mat-icon>add</mat-icon>
                        Drop file here or click to choose file from your computer
                        <br>
                         <small *ngIf="accept">
                             <i>
                                Only *.{{ accept | join: ', *.'}} files are accepted
                             </i>
                        </small>
                    </span>
                </div>
                <div *ngIf="uploading">
                    <common-simple-spinner>Uploading file...</common-simple-spinner>
                </div>
            </mat-card-content>
        </mat-card>
        <div *ngIf="footer" class="footer">
            <div [innerHTML]="footer"></div>
        </div>
        <input type="file" #file [accept]="accept ? accept: null"
               style="display: none;" (change)="fileChange($event)">
    `,
    styleUrls: [
        "file-upload.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class FileUploadComponent implements OnInit, OnDestroy {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    private job_id: string;

    public modal: Modal.IModal;

    public uploading: boolean = false;

    @Input()
    public templatePath: any;

    @Input()
    public accept: string[];

    @Input()
    public templateLabel: string = "Download template";

    @Input()
    public fileInputName: string = "uploadFile";

    @Input()
    public body: { [key: string]: any };

    @Input()
    public headers: { [key: string]: any };

    @Input()
    public method: string = "post";

    @Input()
    public removeDefaultHeaders: boolean = false;

    @Input()
    public url: string[];

    @Input()
    public file: File;

    @Input()
    public async: boolean = false;

    @Output()
    public result: EventEmitter<any> = new EventEmitter();

    @ViewChild("file", {static: false})
    public inputFile: ElementRef;

    @ViewChild("button", {static: false})
    public button: HTMLElement;

    public errors: string;

    public footer: string;

    public templateTypeIsUrl: boolean = true;

    public constructor(
        private apiService: ApiService,
        private http: HttpClient,
        private storageService: StorageService,
        private pusherService: PusherService,
        private location: Location,
        private notificationService: NotificationService,
        private toastService: ToastService,
        private changeDetectorRef: ChangeDetectorRef,
        public spinnerService: SpinnerService,
    ) {
    }

    private removeDragData(event: any): void {
        if (event.dataTransfer.items) {
            event.dataTransfer.items.clear();
        } else {
            event.dataTransfer.clearData();
        }
    }

    private upload(file: File): void {
        this.errors = null;
        this.uploading = true;

        this.changeDetectorRef.markForCheck();

        const formData: FormData = new FormData();
        formData.append(this.fileInputName, file, file.name);

        if (this.body) {
            for (const name of Object.keys(this.body)) {
                formData.set(name, this.body[name]);
            }
        }

        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.set("Accept", "application/json");
        if (this.headers) {
            for (const name of Object.keys(this.headers)) {
                headers = headers.set(name, this.headers[name]);
            }
        }

        if (!this.removeDefaultHeaders) {
            headers = headers.set("Authorization", this.storageService.get("token"));
            const [type, partner]: string[] = this.apiService.partner.split("/");
            if (type !== "admin") {
                headers = headers.set(type, partner);
            } else {
                headers = headers.set("Partner", type);
            }
        }

        if (this.async) {
            headers = headers.set("async", this.job_id);
            headers = headers.set("async-url", this.location.path());
        }

        this.http.request(this.method, this.apiService.getUrl(this.url), {
            headers: headers,
            body: formData
        })
            .pipe(take(1))
            .subscribe((data: any): void => {
                this.uploading = false;
                this.changeDetectorRef.markForCheck();
                if (this.modal) {
                    this.modal.response.emit({
                        name: "value",
                        value: data
                    });
                } else {
                    this.result.emit(data);
                }


                if (this.async) {
                    this.notificationService.addNotification({
                        job_id: this.job_id,
                        job_type: "JobAdded",
                        message: data.message,
                        message_type: "warning",
                        url: this.location.path(),
                        read: false
                    });
                }

                this.inputFile.nativeElement.value = null;

            }, (data: HttpErrorResponse): void => {
                this.uploading = false;
                this.changeDetectorRef.markForCheck();
                this.errors = data.error.message;
                if (Array.isArray(data.error.data)) {
                    this.errors += ":<br>" + data.error.data.join("<br>");
                } else {
                    this.errors += ":<br>" + data.error.data;
                }

                this.inputFile.nativeElement.value = null;
                this.toastService.show(this.errors, "error");
            });
    }

    public chooseFile(): void {
        this.inputFile.nativeElement.click();
    }

    public fileChange(event: any): void {
        const fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            this.upload(fileList[0]);
        }
    }

    public dragOver(event: any): void {
        event.preventDefault();
    }

    public fileDrop(event: any): void {
        event.preventDefault();
        if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
            if (event.dataTransfer.items[0].kind === "file") {
                this.upload(event.dataTransfer.items[0].getAsFile());
            }
        } else if (event.dataTransfer.files.length > 0) {
            this.upload(event.dataTransfer.files[0]);
        }
        this.removeDragData(event);
    }

    public ngOnInit(): void {

        if (this.modal) {

            if (this.modal.params.footer) {
                this.footer = this.modal.params.footer;
            }

            if (this.modal.params.templatePath) {
                this.templatePath = this.modal.params.templatePath;
                if (typeof this.templatePath === "function") {
                    this.templateTypeIsUrl = false;
                }
            }

            if (this.modal.params.accept) {
                this.accept = this.modal.params.accept;
            }

            if (this.modal.params.templateLabel) {
                this.templateLabel = this.modal.params.templateLabel;
            }

            if (this.modal.params.async) {
                this.async = this.modal.params.async;
            }

            if (this.modal.params.fileInputName) {
                this.fileInputName = this.modal.params.fileInputName;
            }

            if (this.modal.params.body) {
                this.body = this.modal.params.body;
            }

            if (this.modal.params.headers) {
                this.headers = this.modal.params.headers;
            }
            if (this.modal.params.method) {
                this.method = this.modal.params.method;
            }
            if (this.modal.params.removeDefaultHeaders) {
                this.removeDefaultHeaders = this.modal.params.removeDefaultHeaders;
            }
            if (this.modal.params.url) {
                this.url = this.modal.params.url;
            }

            if (this.modal.params.file) {
                this.upload(this.modal.params.file);
            }
        }

        this.job_id = HelpersService.randomString();

        this.pusherService.event.pipe(takeUntil(this.destroy$)).subscribe((data: Push.IData): void => {
            if (data.job_id === this.job_id) {
                this.spinnerService.hide();
                this.uploading = false;
            }
        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
